"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.JobDocumentMetadataDtoToJSON = exports.JobDocumentMetadataDtoFromJSONTyped = exports.JobDocumentMetadataDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function JobDocumentMetadataDtoFromJSON(json) {
    return JobDocumentMetadataDtoFromJSONTyped(json, false);
}
exports.JobDocumentMetadataDtoFromJSON = JobDocumentMetadataDtoFromJSON;
function JobDocumentMetadataDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'idsbankcoddoc': !runtime_1.exists(json, 'ids:bank:coddoc') ? undefined : json['ids:bank:coddoc'],
        'idsbankservizio': !runtime_1.exists(json, 'ids:bank:servizio') ? undefined : json['ids:bank:servizio'],
        'idsbankconto': !runtime_1.exists(json, 'ids:bank:conto') ? undefined : json['ids:bank:conto'],
        'idsbankfiliale': !runtime_1.exists(json, 'ids:bank:filiale') ? undefined : json['ids:bank:filiale'],
        'idsbankndgsco': !runtime_1.exists(json, 'ids:bank:ndgsco') ? undefined : json['ids:bank:ndgsco'],
        'Data_del_Documento': !runtime_1.exists(json, 'Data_del_Documento') ? undefined : json['Data_del_Documento'],
    };
}
exports.JobDocumentMetadataDtoFromJSONTyped = JobDocumentMetadataDtoFromJSONTyped;
function JobDocumentMetadataDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'ids:bank:coddoc': value.idsbankcoddoc,
        'ids:bank:servizio': value.idsbankservizio,
        'ids:bank:conto': value.idsbankconto,
        'ids:bank:filiale': value.idsbankfiliale,
        'ids:bank:ndgsco': value.idsbankndgsco,
        'Data_del_Documento': value.Data_del_Documento,
    };
}
exports.JobDocumentMetadataDtoToJSON = JobDocumentMetadataDtoToJSON;
