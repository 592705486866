"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formAppuntamento = exports.elencoArgomenti = exports.urlAcsFe = exports.elencoAppuntamenti = exports.appuntamentoEliminato = exports.appuntamentoModificato = exports.appuntamentoCreato = exports.disponibilitaConsulente = exports.consulente = void 0;
const reselect_1 = require("reselect");
const __1 = require("..");
const collaborationReducer = (state) => state;
exports.consulente = (0, reselect_1.createSelector)(collaborationReducer, (state) => {
    return state.consulente;
});
exports.disponibilitaConsulente = (0, reselect_1.createSelector)(collaborationReducer, (state) => {
    return state.disponibilitaConsulente || [];
});
exports.appuntamentoCreato = (0, reselect_1.createSelector)(collaborationReducer, (state) => {
    return state.appuntamentoCreato;
});
exports.appuntamentoModificato = (0, reselect_1.createSelector)(collaborationReducer, (state) => {
    return state.appuntamentoModificato;
});
exports.appuntamentoEliminato = (0, reselect_1.createSelector)(collaborationReducer, (state) => {
    return state.appuntamentoEliminato;
});
exports.elencoAppuntamenti = (0, reselect_1.createSelector)(collaborationReducer, (state) => {
    return state.elencoAppuntamenti;
});
exports.urlAcsFe = (0, reselect_1.createSelector)(collaborationReducer, (state) => {
    if (state.tokenEvento) {
        return __1.Environments[(0, __1.getCustomEnv)() || __1.selectedEnv].acsFe + '/acs-fe/?meetingId=' + state.tokenEvento;
    }
    else {
        return undefined;
    }
});
exports.elencoArgomenti = (0, reselect_1.createSelector)(collaborationReducer, (state) => {
    return state.argomentiAppuntamento;
});
exports.formAppuntamento = (0, reselect_1.createSelector)(collaborationReducer, (state) => {
    return state.form;
});
