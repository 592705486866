"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RataDtoToJSON = exports.RataDtoFromJSONTyped = exports.RataDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function RataDtoFromJSON(json) {
    return RataDtoFromJSONTyped(json, false);
}
exports.RataDtoFromJSON = RataDtoFromJSON;
function RataDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'giorno': !runtime_1.exists(json, 'giorno') ? undefined : json['giorno'],
        'mese': !runtime_1.exists(json, 'mese') ? undefined : json['mese'],
        'importo': !runtime_1.exists(json, 'importo') ? undefined : json['importo'],
        'idRipetitivo': !runtime_1.exists(json, 'idRipetitivo') ? undefined : json['idRipetitivo'],
    };
}
exports.RataDtoFromJSONTyped = RataDtoFromJSONTyped;
function RataDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'giorno': value.giorno,
        'mese': value.mese,
        'importo': value.importo,
        'idRipetitivo': value.idRipetitivo,
    };
}
exports.RataDtoToJSON = RataDtoToJSON;
