"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuotazioniTitoliDtoToJSON = exports.QuotazioniTitoliDtoFromJSONTyped = exports.QuotazioniTitoliDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function QuotazioniTitoliDtoFromJSON(json) {
    return QuotazioniTitoliDtoFromJSONTyped(json, false);
}
exports.QuotazioniTitoliDtoFromJSON = QuotazioniTitoliDtoFromJSON;
function QuotazioniTitoliDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'totaleQuotazioni': !runtime_1.exists(json, 'totaleQuotazioni') ? undefined : json['totaleQuotazioni'],
        'quotazioni': !runtime_1.exists(json, 'quotazioni') ? undefined : (json['quotazioni'].map(_1.QuotazioneTitoloDtoFromJSON)),
        'sort': !runtime_1.exists(json, 'sort') ? undefined : _1.QuotazioniSortDtoFromJSON(json['sort']),
    };
}
exports.QuotazioniTitoliDtoFromJSONTyped = QuotazioniTitoliDtoFromJSONTyped;
function QuotazioniTitoliDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'totaleQuotazioni': value.totaleQuotazioni,
        'quotazioni': value.quotazioni === undefined ? undefined : (value.quotazioni.map(_1.QuotazioneTitoloDtoToJSON)),
        'sort': _1.QuotazioniSortDtoToJSON(value.sort),
    };
}
exports.QuotazioniTitoliDtoToJSON = QuotazioniTitoliDtoToJSON;
