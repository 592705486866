"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommissioneDtoToJSON = exports.CommissioneDtoFromJSONTyped = exports.CommissioneDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function CommissioneDtoFromJSON(json) {
    return CommissioneDtoFromJSONTyped(json, false);
}
exports.CommissioneDtoFromJSON = CommissioneDtoFromJSON;
function CommissioneDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'tipo': !runtime_1.exists(json, 'tipo') ? undefined : json['tipo'],
        'importo': !runtime_1.exists(json, 'importo') ? undefined : json['importo'],
    };
}
exports.CommissioneDtoFromJSONTyped = CommissioneDtoFromJSONTyped;
function CommissioneDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'tipo': value.tipo,
        'importo': value.importo,
    };
}
exports.CommissioneDtoToJSON = CommissioneDtoToJSON;
