"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaglioRicaricaDtoToJSON = exports.TaglioRicaricaDtoFromJSONTyped = exports.TaglioRicaricaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function TaglioRicaricaDtoFromJSON(json) {
    return TaglioRicaricaDtoFromJSONTyped(json, false);
}
exports.TaglioRicaricaDtoFromJSON = TaglioRicaricaDtoFromJSON;
function TaglioRicaricaDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'idTaglio': !runtime_1.exists(json, 'idTaglio') ? undefined : json['idTaglio'],
        'importo': !runtime_1.exists(json, 'importo') ? undefined : json['importo'],
        'valuta': !runtime_1.exists(json, 'valuta') ? undefined : json['valuta'],
        'commissioni': !runtime_1.exists(json, 'commissioni') ? undefined : json['commissioni'],
        'totaleAddebito': !runtime_1.exists(json, 'totaleAddebito') ? undefined : json['totaleAddebito'],
    };
}
exports.TaglioRicaricaDtoFromJSONTyped = TaglioRicaricaDtoFromJSONTyped;
function TaglioRicaricaDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'idTaglio': value.idTaglio,
        'importo': value.importo,
        'valuta': value.valuta,
        'commissioni': value.commissioni,
        'totaleAddebito': value.totaleAddebito,
    };
}
exports.TaglioRicaricaDtoToJSON = TaglioRicaricaDtoToJSON;
