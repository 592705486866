"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FlagGenericiDtoToJSON = exports.FlagGenericiDtoFromJSONTyped = exports.FlagGenericiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function FlagGenericiDtoFromJSON(json) {
    return FlagGenericiDtoFromJSONTyped(json, false);
}
exports.FlagGenericiDtoFromJSON = FlagGenericiDtoFromJSON;
function FlagGenericiDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'flagOperatore': !runtime_1.exists(json, 'flagOperatore') ? undefined : json['flagOperatore'],
    };
}
exports.FlagGenericiDtoFromJSONTyped = FlagGenericiDtoFromJSONTyped;
function FlagGenericiDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'flagOperatore': value.flagOperatore,
    };
}
exports.FlagGenericiDtoToJSON = FlagGenericiDtoToJSON;
