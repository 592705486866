"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DestinatarioDtoToJSON = exports.DestinatarioDtoFromJSONTyped = exports.DestinatarioDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function DestinatarioDtoFromJSON(json) {
    return DestinatarioDtoFromJSONTyped(json, false);
}
exports.DestinatarioDtoFromJSON = DestinatarioDtoFromJSON;
function DestinatarioDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'denominazioneBanca': !runtime_1.exists(json, 'denominazioneBanca') ? undefined : json['denominazioneBanca'],
        'denominazioneSportello': !runtime_1.exists(json, 'denominazioneSportello') ? undefined : json['denominazioneSportello'],
        'codiceAbi': !runtime_1.exists(json, 'codiceAbi') ? undefined : json['codiceAbi'],
        'codiceCab': !runtime_1.exists(json, 'codiceCab') ? undefined : json['codiceCab'],
        'codiceBic': !runtime_1.exists(json, 'codiceBic') ? undefined : json['codiceBic'],
        'indirizzoSportello': !runtime_1.exists(json, 'indirizzoSportello') ? undefined : _1.IndirizzoSportelloDtoFromJSON(json['indirizzoSportello']),
    };
}
exports.DestinatarioDtoFromJSONTyped = DestinatarioDtoFromJSONTyped;
function DestinatarioDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'denominazioneBanca': value.denominazioneBanca,
        'denominazioneSportello': value.denominazioneSportello,
        'codiceAbi': value.codiceAbi,
        'codiceCab': value.codiceCab,
        'codiceBic': value.codiceBic,
        'indirizzoSportello': _1.IndirizzoSportelloDtoToJSON(value.indirizzoSportello),
    };
}
exports.DestinatarioDtoToJSON = DestinatarioDtoToJSON;
