import { currencyFormat } from "helpers/utilities";
import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";

const FinanzamentiTable: React.FC<iProps> = (props: iProps) => {
  const { t } = useTranslation();
  const { data, noItemsText, boldHeader, compactHeader, tableTitle, aggiungiPreferito, rimuoviPreferito, fiedlName, fiedlDescription, tipologia } = props;
  const [columns, setColumns] = useState<Column[]>([])


  const buildNoItemMesage = (noItemsText: any) => {
    if (noItemsText && noItemsText.length) {
      noItemsText.map((item: any) => {
        return <p>{item}</p>;
      });
    }
  };

  useEffect(() => {
    const fixedCol = {
      name: "intestatario",
      descrizione: "patrimonio.tableLabels.ibanIntestatario",
      formatter: "",
      bold: true,
      isIntestatario: true
    };
    if (!!tipologia) {
      switch (tipologia) {
        case "1": {
          setColumns([fixedCol, {
            name: "saldoContabile",
            descrizione: "patrimonio.tableLabels.saldo",
            bold: true,
            align: "right",
            isPrice: true
          }])
          break;
        }
        case "2": {
          setColumns([fixedCol, {
            name: "saldoCapitaleResiduo",
            descrizione: "patrimonio.tableLabels.debitoResiduo",
            bold: true,
            align: "right",
            isPrice: true
          }])
          break;
        }
      }
    }
  }, [tipologia])
  return (
    <div className="custom-table">
      <p className="form-title">{tableTitle}</p>
      {data && data.length > 0 ? (
        <Table hover borderless responsive>
          <thead>
            <tr>
              {columns.map((column: Column, index: number) => (
                <th
                  style={column.width ? { width: column.width } : {}}
                  key={index}
                  className={`${column.align ? `align-${column.align}` : ``}  ${compactHeader ? `compact-header` : ``
                    } small-header bold-text`}
                >
                  {column.descrizione ? t(column.descrizione) : ""}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.length > 0 &&
              data.map((item: any, index: number) => {
                const itemAsAny = item as any;
                return (
                  <tr key={index}>
                    {columns.map((column: Column, index: number) => {
                      const key = column.name;
                      return (
                        <td
                          key={index + column.name}
                          className={`${column.compact ? "compact " : ""}${column.align ? `align-${column.align} ` : ``
                            }`}
                        >

                          {column.isPrice ? `${currencyFormat(Math.abs(itemAsAny[key]))}  EUR` :
                            `${itemAsAny[key]} \n ${itemAsAny.iban}`
                          }
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </Table>
      ) : (
          <div>
            {noItemsText &&
              noItemsText.map((item: any, index: number) => (
                <p key={`infoText-${index}`}>{item}</p>
              ))}
          </div>
        )}
    </div>
  );
};

export interface Column {
  name: string;
  descrizione?: string;
  align?: "left" | "center" | "right";
  compact?: boolean;
  bold?: boolean;
  isPrice?: boolean;
  isIntestatario?: boolean;
  width?: number;
}

interface iProps {
  data?: any;
  noItemsText?: any;
  boldHeader?: boolean;
  compactHeader?: boolean;
  tableTitle?: string,
  aggiungiPreferito?: any,
  rimuoviPreferito?: any,
  fiedlName?: string,
  fiedlDescription?: string,
  tipologia?: string;
}

export default FinanzamentiTable;
