"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.JobAttributesDtoToJSON = exports.JobAttributesDtoFromJSONTyped = exports.JobAttributesDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function JobAttributesDtoFromJSON(json) {
    return JobAttributesDtoFromJSONTyped(json, false);
}
exports.JobAttributesDtoFromJSON = JobAttributesDtoFromJSON;
function JobAttributesDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'idsuidescription': !runtime_1.exists(json, 'ids:ui:description') ? undefined : json['ids:ui:description'],
        'idswfsendToEmail': !runtime_1.exists(json, 'ids:wf:sendToEmail') ? undefined : json['ids:wf:sendToEmail'],
        'idsallchannel': !runtime_1.exists(json, 'ids:all:channel') ? undefined : json['ids:all:channel'],
        'idsallownerHierarchy': !runtime_1.exists(json, 'ids:all:ownerHierarchy') ? undefined : json['ids:all:ownerHierarchy'],
        'idsalltype': !runtime_1.exists(json, 'ids:all:type') ? undefined : json['ids:all:type'],
    };
}
exports.JobAttributesDtoFromJSONTyped = JobAttributesDtoFromJSONTyped;
function JobAttributesDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'ids:ui:description': value.idsuidescription,
        'ids:wf:sendToEmail': value.idswfsendToEmail,
        'ids:all:channel': value.idsallchannel,
        'ids:all:ownerHierarchy': value.idsallownerHierarchy,
        'ids:all:type': value.idsalltype,
    };
}
exports.JobAttributesDtoToJSON = JobAttributesDtoToJSON;
