"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DettaglioPagamentoDtoToJSON = exports.DettaglioPagamentoDtoFromJSONTyped = exports.DettaglioPagamentoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function DettaglioPagamentoDtoFromJSON(json) {
    return DettaglioPagamentoDtoFromJSONTyped(json, false);
}
exports.DettaglioPagamentoDtoFromJSON = DettaglioPagamentoDtoFromJSON;
function DettaglioPagamentoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'idProgressivo': !runtime_1.exists(json, 'idProgressivo') ? undefined : json['idProgressivo'],
        'tipoDisposizione': !runtime_1.exists(json, 'tipoDisposizione') ? undefined : json['tipoDisposizione'],
        'disposizione': !runtime_1.exists(json, 'disposizione') ? undefined : json['disposizione'],
        'ripetibile': !runtime_1.exists(json, 'ripetibile') ? undefined : json['ripetibile'],
        'operazioneOneClick': !runtime_1.exists(json, 'operazioneOneClick') ? undefined : json['operazioneOneClick'],
        'annullabile': !runtime_1.exists(json, 'annullabile') ? undefined : json['annullabile'],
        'menuID': !runtime_1.exists(json, 'menuID') ? undefined : json['menuID'],
    };
}
exports.DettaglioPagamentoDtoFromJSONTyped = DettaglioPagamentoDtoFromJSONTyped;
function DettaglioPagamentoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'idProgressivo': value.idProgressivo,
        'tipoDisposizione': value.tipoDisposizione,
        'disposizione': value.disposizione,
        'ripetibile': value.ripetibile,
        'operazioneOneClick': value.operazioneOneClick,
        'annullabile': value.annullabile,
        'menuID': value.menuID,
    };
}
exports.DettaglioPagamentoDtoToJSON = DettaglioPagamentoDtoToJSON;
