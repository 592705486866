"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetDettaglioIndice = exports.resetIndici = exports.resetFormIndici = exports.setIndiciOffset = exports.setIndiceSelezionato = exports.setIndiciDenominazione = exports.setIndiciMercato = exports.setIndiciTipoGrafico = exports.getSchedaIndice = exports.getDatiStoriciIndice = exports.getDatiIntradayIndice = exports.getDettaglioIndice = exports.getElencoIndici = exports.getMercatiIndici = void 0;
const general_actions_1 = require("../general/general.actions");
const api_rest_1 = require("@sparkasse/api-rest");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
const indici_types_1 = require("./indici.types");
const moment = require("moment");
// Get mer Indici - Start
function getMercatiIndiciStart() {
    return {
        type: indici_types_1.EReduxIndiciActionTypes.INDICI_GET_MERCATI_START
    };
}
function getMercatiIndiciSuccess(payload) {
    return {
        type: indici_types_1.EReduxIndiciActionTypes.INDICI_GET_MERCATI_SUCCESS,
        payload
    };
}
function generalError(value) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value));
    };
}
const getMercatiIndici = () => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getMercatiIndiciStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getMercatiIndici().then((response) => {
                dispatch(getMercatiIndiciSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getMercatiIndici = getMercatiIndici;
// Get parametri indici - Fine
// Get elenco indici - Start
function getElencoIndiciStart() {
    return {
        type: indici_types_1.EReduxIndiciActionTypes.INDICI_GET_TITOLI_START
    };
}
function getElencoIndiciSuccess(payload, offset) {
    return {
        type: indici_types_1.EReduxIndiciActionTypes.INDICI_GET_TITOLI_SUCCESS,
        payload,
        offset
    };
}
const getElencoIndici = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getElencoIndiciStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        const indiciState = getState().indici;
        const request = {
            quotazioniFiltriInDto: {
                mercato: indiciState.form.mercato,
                cerca: indiciState.form.denominazione
            }
        };
        if (extraArguments.platform === 'mobile') {
            request.quotazioniFiltriInDto.offset = (indiciState.form.offset);
        }
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getQuotazioniIndici(request).then((response) => {
                dispatch(getElencoIndiciSuccess(response, indiciState.form.offset));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getElencoIndici = getElencoIndici;
// Get elenco indici - Fine
// Get dettaglio indice - Start
function getDettaglioIndiceStart() {
    return {
        type: indici_types_1.EReduxIndiciActionTypes.INDICI_GET_DETTAGLIO_INDICE_START
    };
}
function getDettaglioIndiceSuccess(payload) {
    return {
        type: indici_types_1.EReduxIndiciActionTypes.INDICI_GET_DETTAGLIO_INDICE_SUCCESS,
        payload,
    };
}
const getDettaglioIndice = () => async (dispatch, getState, extraArguments) => {
    var _a;
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getDettaglioIndiceStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        const indiciState = getState().indici;
        const request = {
            mercato: indiciState.form.mercato,
            codiceIndice: (_a = indiciState.form.indiceSelezionato.codice) !== null && _a !== void 0 ? _a : ''
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getQuotazioneIndiceDettaglio(request).then((response) => {
                dispatch(getDettaglioIndiceSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getDettaglioIndice = getDettaglioIndice;
// Get dettaglio indice - Fine
function aggiornaGraficoIndiceSuccess(payload, aggiornamento, tipo) {
    return {
        type: indici_types_1.EReduxIndiciActionTypes.INDICI_AGGIORNA_GRAFICO,
        payload,
        aggiornamento,
        tipo
    };
}
function aggiornaGraficoTitoloQuotatoEsteroSuccess(payload, aggiornamento, tipo) {
    return {
        type: indici_types_1.EReduxIndiciActionTypes.INDICI_ESTERI_AGGIORNA_GRAFICO,
        payload,
        aggiornamento,
        tipo
    };
}
// Get dati intraday Indici - Start
function getDatiIntradayIndiceStart() {
    return {
        type: indici_types_1.EReduxIndiciActionTypes.INDICI_GET_DATI_INTRADAY_INDICE_START
    };
}
function getDatiIntradayIndiceSuccess(payload, aggiornamento) {
    return {
        type: indici_types_1.EReduxIndiciActionTypes.INDICI_GET_DATI_INTRADAY_INDICE_SUCCESS,
        payload,
        aggiornamento
    };
}
const getDatiIntradayIndice = (refresh, tipo) => async (dispatch, getState, extraArguments) => {
    var _a;
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getDatiIntradayIndiceStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        const titoliState = getState().indici;
        const request = {
            datiIntradayIndiceInDto: {
                mercato: titoliState.form.mercato,
                codiceIndice: (_a = titoliState.form.indiceSelezionato.codice) !== null && _a !== void 0 ? _a : ''
            }
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getDatiIntradayIndice(request).then((response) => {
                if (refresh) {
                    dispatch(aggiornaGraficoIndiceSuccess(response, moment().toDate(), tipo !== null && tipo !== void 0 ? tipo : 0));
                }
                else {
                    dispatch(getDatiIntradayIndiceSuccess(response, moment().toDate()));
                }
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getDatiIntradayIndice = getDatiIntradayIndice;
// Get dati intraday Indice - Fine
// Get dati storici Indice - Start
function getDatiStoriciIndiceStart() {
    return {
        type: indici_types_1.EReduxIndiciActionTypes.INDICI_GET_DATI_STORICI_INDICE_START
    };
}
function getDatiStoriciIndiceSuccess(payload, aggiornamento, tipo, tipologiaGrafico) {
    return {
        type: indici_types_1.EReduxIndiciActionTypes.INDICI_GET_DATI_STORICI_INDICE_SUCCESS,
        payload,
        aggiornamento,
        tipo,
        tipologiaGrafico
    };
}
const getDatiStoriciIndice = (type, isEstero, refresh) => async (dispatch, getState, extraArguments) => {
    var _a;
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getDatiStoriciIndiceStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        const now = moment();
        const indiciState = getState().indici;
        const tipoGrafico = isEstero ? indici_types_1.TipoGraficoIndiciEsteri : indici_types_1.TipoGraficoIndici;
        const request = {
            datiIntradayIndiceInDto: {
                mercato: indiciState.form.mercato,
                codiceIndice: (_a = indiciState.form.indiceSelezionato.codice) !== null && _a !== void 0 ? _a : '',
                dataFinale: now.toDate(),
                dataIniziale: (type === tipoGrafico.Settimana1 ? now.subtract(7, "days") :
                    type === tipoGrafico.Mese1 ? now.subtract(1, "months") :
                        now.subtract(12, "months")).toDate()
            }
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getDatiStoriciIndice(request).then((response) => {
                if (refresh && isEstero) {
                    dispatch(aggiornaGraficoTitoloQuotatoEsteroSuccess(response, now.toDate(), type));
                }
                else if (refresh && !isEstero) {
                    dispatch(aggiornaGraficoIndiceSuccess(response, now.toDate(), type));
                }
                else {
                    dispatch(getDatiStoriciIndiceSuccess(response, now.toDate(), type, tipoGrafico));
                }
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getDatiStoriciIndice = getDatiStoriciIndice;
// Get dati Intraday Indice - Fine
// Get scheda Indice - Start
function getSchedaIndiceStart() {
    return {
        type: indici_types_1.EReduxIndiciActionTypes.INDICI_GET_SCHEDA_INDICE_START
    };
}
function getSchedaIndiceSuccess(payload) {
    return {
        type: indici_types_1.EReduxIndiciActionTypes.INDICI_GET_SCHEDA_INDICE_SUCCESS,
        payload,
    };
}
const getSchedaIndice = () => async (dispatch, getState, extraArguments) => {
    var _a, _b;
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getSchedaIndiceStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        const indiciState = getState().indici;
        const request = {
            mercato: (_a = indiciState.form.indiceSelezionato.mercato) !== null && _a !== void 0 ? _a : indiciState.form.mercato,
            codiceIndice: (_b = indiciState.form.indiceSelezionato.codice) !== null && _b !== void 0 ? _b : ''
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getSchedaIndice(request).then((response) => {
                dispatch(getSchedaIndiceSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getSchedaIndice = getSchedaIndice;
// Get scheda Indice - Fine
function setIndiciTipoGrafico(tipo) {
    return {
        type: indici_types_1.EReduxIndiciActionTypes.INDICI_SET_TIPO_GRAFICO,
        payload: tipo
    };
}
exports.setIndiciTipoGrafico = setIndiciTipoGrafico;
/* set form values - START */
function setIndiciMercato(txt) {
    return {
        type: indici_types_1.EReduxIndiciActionTypes.SET_INDICI_MERCATO,
        payload: txt
    };
}
exports.setIndiciMercato = setIndiciMercato;
function setIndiciDenominazione(txt) {
    return {
        type: indici_types_1.EReduxIndiciActionTypes.SET_INDICI_DENOMINAZIONE,
        payload: txt
    };
}
exports.setIndiciDenominazione = setIndiciDenominazione;
function setIndiceSelezionato(indice) {
    return {
        type: indici_types_1.EReduxIndiciActionTypes.INDICI_SET_INDICE_SELEZIONATO,
        payload: indice
    };
}
exports.setIndiceSelezionato = setIndiceSelezionato;
function setIndiciOffset(offset) {
    return {
        type: indici_types_1.EReduxIndiciActionTypes.INDICI_SET_OFFSET,
        payload: offset
    };
}
exports.setIndiciOffset = setIndiciOffset;
/* set form values - Fine */
// Reset Form Indici
function resetFormIndici() {
    return {
        type: indici_types_1.EReduxIndiciActionTypes.INDICI_RESET_FORM
    };
}
exports.resetFormIndici = resetFormIndici;
function resetIndici() {
    return {
        type: indici_types_1.EReduxIndiciActionTypes.INDICI_RESET
    };
}
exports.resetIndici = resetIndici;
// end Reset Form Indici
// Reset dettaglio indici
function resetDettaglioIndice() {
    return {
        type: indici_types_1.EReduxIndiciActionTypes.INDICI_RESET_DETTAGLIO
    };
}
exports.resetDettaglioIndice = resetDettaglioIndice;
// end Reset dettaglio indici
