"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DescrizioneDtoToJSON = exports.DescrizioneDtoFromJSONTyped = exports.DescrizioneDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function DescrizioneDtoFromJSON(json) {
    return DescrizioneDtoFromJSONTyped(json, false);
}
exports.DescrizioneDtoFromJSON = DescrizioneDtoFromJSON;
function DescrizioneDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'title': !runtime_1.exists(json, 'title') ? undefined : json['title'],
        'values': !runtime_1.exists(json, 'values') ? undefined : (json['values'].map(_1.KeyValeDtoFromJSON)),
    };
}
exports.DescrizioneDtoFromJSONTyped = DescrizioneDtoFromJSONTyped;
function DescrizioneDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'title': value.title,
        'values': value.values === undefined ? undefined : (value.values.map(_1.KeyValeDtoToJSON)),
    };
}
exports.DescrizioneDtoToJSON = DescrizioneDtoToJSON;
