"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.timeDepositReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const helpers_1 = require("../helpers");
const timeDeposit_types_1 = require("./timeDeposit.types");
const initialState = {
    filter: (0, timeDeposit_types_1.FilterTimeDepositFromJSONTyped)({}),
    parametri: (0, api_rest_1.ParametriTimeDepositDtoFromJSON)({}),
    parametriTimeDepositBox: (0, api_rest_1.ParametriTimeDepositDtoFromJSON)({}),
    timeDepositList: (0, api_rest_1.TimeDepositElencoDtoFromJSON)({}),
    timeDepositSingleList: [],
    timeDepositSelezionato: undefined,
    pdfElencoTimeDeposit: undefined,
    excelElencoTimeDeposit: undefined,
    listiniTimeDeposit: undefined,
    form: (0, timeDeposit_types_1.TimeDepositFormTypeFromJSONTyped)({}),
    esito: (0, api_rest_1.EsitoAutorizzazioneDisposizioneTimeDepositDtoToJSON)({}),
    infoAutenticazione: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
    riepilogo: (0, api_rest_1.TimeDepositDtoFromJSON)({}),
    pdfDettaglioTimeDeposit: undefined,
    riepilogoRevoca: (0, api_rest_1.TimeDepositDtoFromJSON)({}),
    infoAutenticazioneRevoca: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
    esitoRevoca: (0, api_rest_1.EsitoAutorizzazioneDisposizioneTimeDepositDtoToJSON)({}),
    formRevoca: (0, timeDeposit_types_1.TimeDepositFromRevocaFromJSONTyped)({}),
    pianoCedole: undefined
};
const timeDepositReducer = (state = initialState, action) => {
    var _a, _b, _c, _d, _e;
    switch (action.type) {
        case __1.EReduxActionTypes.TIME_DEPOSIT_GET_PARAMETRI_SUCCESS:
            return {
                ...state,
                parametri: action.payload,
                filter: {
                    ...initialState.filter,
                    dataDecorrenzaDa: action.payload.parametri && action.payload.parametri['DEFAULT.TIMEDEPOSITINFO.DATA_INIZIALE_DECORRENZA'] ? new Date(action.payload.parametri['DEFAULT.TIMEDEPOSITINFO.DATA_INIZIALE_DECORRENZA'].toString()) : undefined,
                    dataDecorrenzaA: action.payload.parametri && action.payload.parametri['DEFAULT.TIMEDEPOSITINFO.DATA_FINALE_DECORRENZA'] ? new Date(action.payload.parametri['DEFAULT.TIMEDEPOSITINFO.DATA_FINALE_DECORRENZA'].toString()) : undefined,
                    dataScadenzaDa: action.payload.parametri && action.payload.parametri['DEFAULT.TIMEDEPOSITINFO.DATA_INIZIALE_SCADENZA'] ? new Date(action.payload.parametri['DEFAULT.TIMEDEPOSITINFO.DATA_INIZIALE_SCADENZA'].toString()) : undefined,
                    dataScadenzaA: action.payload.parametri && action.payload.parametri['DEFAULT.TIMEDEPOSITINFO.DATA_FINALE_SCADENZA'] ? new Date(action.payload.parametri['DEFAULT.TIMEDEPOSITINFO.DATA_FINALE_SCADENZA'].toString()) : undefined
                }
            };
        case __1.EReduxActionTypes.TIME_DEPOSIT_GET_PARAMETRI_BOX_SUCCESS:
            return {
                ...state,
                parametriTimeDepositBox: action.payload,
                filter: {
                    ...initialState.filter,
                    dataDecorrenzaDa: action.payload.parametri && action.payload.parametri['DEFAULT.TIMEDEPOSITINFO.DATA_INIZIALE_DECORRENZA'] ? new Date(action.payload.parametri['DEFAULT.TIMEDEPOSITINFO.DATA_INIZIALE_DECORRENZA'].toString()) : undefined,
                    dataDecorrenzaA: action.payload.parametri && action.payload.parametri['DEFAULT.TIMEDEPOSITINFO.DATA_FINALE_DECORRENZA'] ? new Date(action.payload.parametri['DEFAULT.TIMEDEPOSITINFO.DATA_FINALE_DECORRENZA'].toString()) : undefined,
                    dataScadenzaDa: action.payload.parametri && action.payload.parametri['DEFAULT.TIMEDEPOSITINFO.DATA_INIZIALE_SCADENZA'] ? new Date(action.payload.parametri['DEFAULT.TIMEDEPOSITINFO.DATA_INIZIALE_SCADENZA'].toString()) : undefined,
                    dataScadenzaA: action.payload.parametri && action.payload.parametri['DEFAULT.TIMEDEPOSITINFO.DATA_FINALE_SCADENZA'] ? new Date(action.payload.parametri['DEFAULT.TIMEDEPOSITINFO.DATA_FINALE_SCADENZA'].toString()) : undefined
                }
            };
        case __1.EReduxActionTypes.TIME_DEPOSIT_GET_TIME_DEPOSIT_SUCCESS:
            return {
                ...state,
                timeDepositList: action.payload
            };
        case __1.EReduxActionTypes.TIME_DEPOSIT_RAPPORTO_SELEZIONATO:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    rapportoSelezionato: action.payload
                },
                form: {
                    ...state.form,
                    rapportoSelezionato: action.payload
                }
            };
        case __1.EReduxActionTypes.TIME_DEPOSIT_DATA_DECORRENZA_DA:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    dataDecorrenzaDa: new Date(action.payload),
                    formDirty: true
                }
            };
        case __1.EReduxActionTypes.TIME_DEPOSIT_DATA_DECORRENZA_A:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    dataDecorrenzaA: new Date(action.payload),
                    formDirty: true
                }
            };
        case __1.EReduxActionTypes.TIME_DEPOSIT_DATA_SCADENZA_DA:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    dataScadenzaDa: new Date(action.payload),
                    formDirty: true
                }
            };
        case __1.EReduxActionTypes.TIME_DEPOSIT_DATA_SCADENZA_A:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    dataScadenzaA: new Date(action.payload),
                    formDirty: true
                }
            };
        case __1.EReduxActionTypes.TIME_DEPOSIT_PERIODO:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    periodo: action.payload,
                    formDirty: true
                }
            };
        case __1.EReduxActionTypes.TIME_DEPOSIT_STATO:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    stato: action.payload,
                    formDirty: true
                }
            };
        case __1.EReduxActionTypes.TIME_DEPOSIT_RESET_FILTRI: {
            return {
                ...state,
                filter: {
                    ...initialState.filter,
                    dataDecorrenzaDa: state.parametri.parametri && state.parametri.parametri['DEFAULT.TIMEDEPOSITINFO.DATA_INIZIALE_DECORRENZA'] ? new Date(state.parametri.parametri['DEFAULT.TIMEDEPOSITINFO.DATA_INIZIALE_DECORRENZA'].toString()) : undefined,
                    dataDecorrenzaA: state.parametri.parametri && state.parametri.parametri['DEFAULT.TIMEDEPOSITINFO.DATA_FINALE_DECORRENZA'] ? new Date(state.parametri.parametri['DEFAULT.TIMEDEPOSITINFO.DATA_FINALE_DECORRENZA'].toString()) : undefined,
                    dataScadenzaDa: state.parametri.parametri && state.parametri.parametri['DEFAULT.TIMEDEPOSITINFO.DATA_INIZIALE_SCADENZA'] ? new Date(state.parametri.parametri['DEFAULT.TIMEDEPOSITINFO.DATA_INIZIALE_SCADENZA'].toString()) : undefined,
                    dataScadenzaA: state.parametri.parametri && state.parametri.parametri['DEFAULT.TIMEDEPOSITINFO.DATA_FINALE_SCADENZA'] ? new Date(state.parametri.parametri['DEFAULT.TIMEDEPOSITINFO.DATA_FINALE_SCADENZA'].toString()) : undefined,
                    rapportoSelezionato: state.filter.rapportoSelezionato,
                    stato: undefined
                }
            };
        }
        case __1.EReduxActionTypes.TIME_DEPOSIT_BOX_RESET_FILTRI: {
            return {
                ...state,
                filter: {
                    ...initialState.filter,
                    dataDecorrenzaDa: state.parametriTimeDepositBox.parametri && state.parametriTimeDepositBox.parametri['DEFAULT.TIMEDEPOSITINFO.DATA_INIZIALE_DECORRENZA'] ? new Date(state.parametriTimeDepositBox.parametri['DEFAULT.TIMEDEPOSITINFO.DATA_INIZIALE_DECORRENZA'].toString()) : undefined,
                    dataDecorrenzaA: state.parametriTimeDepositBox.parametri && state.parametriTimeDepositBox.parametri['DEFAULT.TIMEDEPOSITINFO.DATA_FINALE_DECORRENZA'] ? new Date(state.parametriTimeDepositBox.parametri['DEFAULT.TIMEDEPOSITINFO.DATA_FINALE_DECORRENZA'].toString()) : undefined,
                    dataScadenzaDa: state.parametriTimeDepositBox.parametri && state.parametriTimeDepositBox.parametri['DEFAULT.TIMEDEPOSITINFO.DATA_INIZIALE_SCADENZA'] ? new Date(state.parametriTimeDepositBox.parametri['DEFAULT.TIMEDEPOSITINFO.DATA_INIZIALE_SCADENZA'].toString()) : undefined,
                    dataScadenzaA: state.parametriTimeDepositBox.parametri && state.parametriTimeDepositBox.parametri['DEFAULT.TIMEDEPOSITINFO.DATA_FINALE_SCADENZA'] ? new Date(state.parametriTimeDepositBox.parametri['DEFAULT.TIMEDEPOSITINFO.DATA_FINALE_SCADENZA'].toString()) : undefined,
                    rapportoSelezionato: state.filter.rapportoSelezionato,
                    stato: undefined
                }
            };
        }
        case __1.EReduxActionTypes.TIME_DEPOSIT_RESET_TIME_DEPOSIT_SELEZIONATO: {
            return {
                ...state,
                timeDepositSelezionato: initialState.timeDepositSelezionato
            };
        }
        case __1.EReduxActionTypes.TIME_DEPOSIT_ESPORTA_EXCEL_SUCCESS: {
            return {
                ...state,
                excelElencoTimeDeposit: action.payload
            };
        }
        case __1.EReduxActionTypes.TIME_DEPOSIT_ESPORTA_PDF_SUCCESS: {
            return {
                ...state,
                pdfElencoTimeDeposit: action.payload
            };
        }
        case __1.EReduxActionTypes.TIME_DEPOSIT_ESPORTA_DETTAGLIO_PDF_SUCCESS: {
            return {
                ...state,
                pdfDettaglioTimeDeposit: action.payload
            };
        }
        case __1.EReduxActionTypes.TIME_DEPOSIT_GET_LISTINI_SUCCESS: {
            return {
                ...state,
                listiniTimeDeposit: action.payload
            };
        }
        case __1.EReduxActionTypes.TIME_DEPOSIT_GET_INFO_AUTENTICAZIONE_SUCCESS: {
            return {
                ...state,
                infoAutenticazione: action.payload,
                esito: initialState.esito
            };
        }
        case __1.EReduxActionTypes.TIME_DEPOSIT_AUTORIZZA_TIME_DEPOSIT_SUCCESS: {
            if (action.payload.stato === 'OK') {
                return {
                    ...state,
                    //riepilogo: initialState.riepilogo,
                    esito: action.payload
                };
            }
            else {
                return {
                    ...state,
                    infoAutenticazione: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoAutenticazione, action.payload.informazioniAutenticazioneDispositiva),
                    esito: action.payload
                };
            }
        }
        case __1.EReduxActionTypes.TIME_DEPOSIT_SIMULA_APERTURA_SUCCESS: {
            return {
                ...state,
                riepilogo: action.payload
            };
        }
        case __1.EReduxActionTypes.TIME_DEPOSIT_LISTINO_SELEZIONATO: {
            return {
                ...state,
                form: {
                    ...state.form,
                    listinoSelezionato: action.payload
                }
            };
        }
        case __1.EReduxActionTypes.TIME_DEPOSIT_IMPORTO_DEPOSITO: {
            return {
                ...state,
                form: {
                    ...state.form,
                    importoDeposito: action.payload,
                    importoDepositoDirty: true,
                    importoDepositoError: (0, helpers_1.validationImportiMultipli)(action.payload, (_b = (_a = state.form.listinoSelezionato) === null || _a === void 0 ? void 0 : _a.importoMultiplo) !== null && _b !== void 0 ? _b : 1, (_e = (_d = (_c = state.form.rapportoSelezionato) === null || _c === void 0 ? void 0 : _c.saldo) === null || _d === void 0 ? void 0 : _d.saldoDisponibile) !== null && _e !== void 0 ? _e : 0)
                }
            };
        }
        case __1.EReduxActionTypes.TIME_DEPOSIT_RESET_FORM: {
            return {
                ...state,
                form: {
                    ...initialState.form,
                    rapportoSelezionato: state.form.rapportoSelezionato
                },
                esito: initialState.esito,
                esitoRevoca: initialState.esitoRevoca,
                infoAutenticazione: initialState.infoAutenticazione,
                infoAutenticazioneRevoca: initialState.infoAutenticazioneRevoca,
                riepilogo: initialState.riepilogo
            };
        }
        case __1.EReduxActionTypes.TIME_DEPOSIT_GET_INFO_AUTENTICAZIONE_REVOCA_SUCCESS: {
            return {
                ...state,
                infoAutenticazioneRevoca: action.payload,
                esitoRevoca: initialState.esitoRevoca
            };
        }
        case __1.EReduxActionTypes.TIME_DEPOSIT_SIMULA_REVOCA_SUCCESS: {
            return {
                ...state,
                riepilogoRevoca: action.payload
            };
        }
        case __1.EReduxActionTypes.TIME_DEPOSIT_AUTORIZZA_REVOCA_SUCCESS: {
            if (action.payload.stato === 'OK') {
                return {
                    ...state,
                    //riepilogo: initialState.riepilogo,
                    esitoRevoca: action.payload
                };
            }
            else {
                return {
                    ...state,
                    infoAutenticazioneRevoca: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoAutenticazioneRevoca, action.payload.informazioniAutenticazioneDispositiva),
                    esitoRevoca: action.payload
                };
            }
        }
        case __1.EReduxActionTypes.TIME_DEPOSIT_GET_DETTAGLIO_SUCCESS: {
            return {
                ...state,
                timeDepositSelezionato: action.payload
            };
        }
        case __1.EReduxActionTypes.TIME_DEPOSIT_STEP: {
            return {
                ...state,
                form: {
                    ...state.form,
                    step: action.payload
                }
            };
        }
        case __1.EReduxActionTypes.TIME_DEPOSIT_RESET_STEP: {
            return {
                ...state,
                form: {
                    ...state.form,
                    step: initialState.form.step
                }
            };
        }
        case __1.EReduxActionTypes.TIME_DEPOSIT_RESET_LISTINI: {
            return {
                ...state,
                listiniTimeDeposit: undefined
            };
        }
        case __1.EReduxActionTypes.TIME_DEPOSIT_FORM_REVOCA_STEP: {
            return {
                ...state,
                formRevoca: {
                    ...state.formRevoca,
                    step: action.payload
                }
            };
        }
        case __1.EReduxActionTypes.TIME_DEPOSIT_RESET_REVOCA: {
            return {
                ...state,
                formRevoca: (0, timeDeposit_types_1.TimeDepositFromRevocaFromJSONTyped)({}),
                esitoRevoca: (0, api_rest_1.EsitoAutorizzazioneDisposizioneTimeDepositDtoToJSON)({}),
                riepilogoRevoca: (0, api_rest_1.TimeDepositDtoFromJSON)({}),
                infoAutenticazioneRevoca: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({})
            };
        }
        case __1.EReduxActionTypes.TIME_DEPOSIT_GET_PIANO_CEDOLE_SUCCESS: {
            return {
                ...state,
                pianoCedole: action.payload
            };
        }
        case __1.EReduxActionTypes.TIME_DEPOSIT_RESET_RIEPILOGO:
            return {
                ...state,
                riepilogo: initialState.riepilogo
            };
        case __1.EReduxActionTypes.TIME_DEPOSIT_RESET_CEDOLE:
            return {
                ...state,
                pianoCedole: initialState.pianoCedole
            };
        case __1.EReduxActionTypes.TIME_DEPOSIT_RESET:
            return {
                ...initialState
            };
        case __1.EReduxActionTypes.RESET_ALL: // action invocata dalla logout
            return {
                ...initialState
            };
        case __1.EReduxActionTypes.TIME_DEPOSIT_GET_TIME_DEPOSIT_SINGLE_LIST_SUCCESS:
            return {
                ...state,
                timeDepositSingleList: action.payload
            };
        default:
            return state;
    }
};
exports.timeDepositReducer = timeDepositReducer;
