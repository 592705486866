"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AlertPsd2DtoToJSON = exports.AlertPsd2DtoFromJSONTyped = exports.AlertPsd2DtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function AlertPsd2DtoFromJSON(json) {
    return AlertPsd2DtoFromJSONTyped(json, false);
}
exports.AlertPsd2DtoFromJSON = AlertPsd2DtoFromJSON;
function AlertPsd2DtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'psd2Warning': !runtime_1.exists(json, 'psd2Warning') ? undefined : json['psd2Warning'],
        'psd2WarningLabel': !runtime_1.exists(json, 'psd2WarningLabel') ? undefined : json['psd2WarningLabel'],
        'aspspId': !runtime_1.exists(json, 'aspspId') ? undefined : json['aspspId'],
        'nomeBanca': !runtime_1.exists(json, 'nomeBanca') ? undefined : json['nomeBanca'],
        'iban': !runtime_1.exists(json, 'iban') ? undefined : json['iban'],
    };
}
exports.AlertPsd2DtoFromJSONTyped = AlertPsd2DtoFromJSONTyped;
function AlertPsd2DtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'psd2Warning': value.psd2Warning,
        'psd2WarningLabel': value.psd2WarningLabel,
        'aspspId': value.aspspId,
        'nomeBanca': value.nomeBanca,
        'iban': value.iban,
    };
}
exports.AlertPsd2DtoToJSON = AlertPsd2DtoToJSON;
