"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NeosurancePetBirthdayDtoToJSON = exports.NeosurancePetBirthdayDtoFromJSONTyped = exports.NeosurancePetBirthdayDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function NeosurancePetBirthdayDtoFromJSON(json) {
    return NeosurancePetBirthdayDtoFromJSONTyped(json, false);
}
exports.NeosurancePetBirthdayDtoFromJSON = NeosurancePetBirthdayDtoFromJSON;
function NeosurancePetBirthdayDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'day': !runtime_1.exists(json, 'day') ? undefined : json['day'],
        'month': !runtime_1.exists(json, 'month') ? undefined : json['month'],
        'year': !runtime_1.exists(json, 'year') ? undefined : json['year'],
    };
}
exports.NeosurancePetBirthdayDtoFromJSONTyped = NeosurancePetBirthdayDtoFromJSONTyped;
function NeosurancePetBirthdayDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'day': value.day,
        'month': value.month,
        'year': value.year,
    };
}
exports.NeosurancePetBirthdayDtoToJSON = NeosurancePetBirthdayDtoToJSON;
