"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DatiEsecuzioneBollettinoDtoToJSON = exports.DatiEsecuzioneBollettinoDtoFromJSONTyped = exports.DatiEsecuzioneBollettinoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function DatiEsecuzioneBollettinoDtoFromJSON(json) {
    return DatiEsecuzioneBollettinoDtoFromJSONTyped(json, false);
}
exports.DatiEsecuzioneBollettinoDtoFromJSON = DatiEsecuzioneBollettinoDtoFromJSON;
function DatiEsecuzioneBollettinoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'commissioniAzienda': !runtime_1.exists(json, 'commissioniAzienda') ? undefined : json['commissioniAzienda'],
        'costiAggiuntiviBancaposta': !runtime_1.exists(json, 'costiAggiuntiviBancaposta') ? undefined : json['costiAggiuntiviBancaposta'],
        'commissioneSuOperazione': !runtime_1.exists(json, 'commissioneSuOperazione') ? undefined : json['commissioneSuOperazione'],
        'importoTotale': !runtime_1.exists(json, 'importoTotale') ? undefined : json['importoTotale'],
    };
}
exports.DatiEsecuzioneBollettinoDtoFromJSONTyped = DatiEsecuzioneBollettinoDtoFromJSONTyped;
function DatiEsecuzioneBollettinoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'commissioniAzienda': value.commissioniAzienda,
        'costiAggiuntiviBancaposta': value.costiAggiuntiviBancaposta,
        'commissioneSuOperazione': value.commissioneSuOperazione,
        'importoTotale': value.importoTotale,
    };
}
exports.DatiEsecuzioneBollettinoDtoToJSON = DatiEsecuzioneBollettinoDtoToJSON;
