"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.collaborationReducer = void 0;
const api_collaboration_1 = require("@sparkasse/api-collaboration");
const __1 = require("..");
const collaboration_types_1 = require("./collaboration.types");
const initialState = {
    config: undefined,
    consulente: undefined,
    disponibilitaConsulente: undefined,
    appuntamentoCreato: false,
    appuntamentoModificato: false,
    appuntamentoEliminato: false,
    elencoAppuntamenti: [],
    tokenEvento: undefined,
    argomentiAppuntamento: [],
    appuntamentoDaModificare: (0, api_collaboration_1.EventResponseFromJSON)({}),
    form: {
        note: '',
        modalitaIncontro: '',
        argomentoSelezionato: undefined
    }
};
const collaborationReducer = (state = initialState, action) => {
    switch (action.type) {
        case collaboration_types_1.EReduxCollaborationActionTypes.GET_CONFIG_COLLABORATION_SUCCESS: {
            return {
                ...state,
                config: action.payload
            };
        }
        case collaboration_types_1.EReduxCollaborationActionTypes.GET_CONSULENTE_SUCCESS: {
            return {
                ...state,
                consulente: action.payload,
                appuntamentoCreato: false,
                appuntamentoModificato: false,
                appuntamentoEliminato: false
            };
        }
        case collaboration_types_1.EReduxCollaborationActionTypes.GET_DISPONIBILITA_CONSULENTE_SUCCESS: {
            return {
                ...state,
                disponibilitaConsulente: action.payload,
                appuntamentoCreato: false,
                appuntamentoModificato: false,
                appuntamentoEliminato: false
            };
        }
        case collaboration_types_1.EReduxCollaborationActionTypes.CREA_APPUNTAMENTO_SUCCESS: {
            return {
                ...state,
                appuntamentoCreato: true
            };
        }
        case collaboration_types_1.EReduxCollaborationActionTypes.GET_ELENCO_APPUNTAMENTI_SUCCESS: {
            return {
                ...state,
                elencoAppuntamenti: action.payload || []
            };
        }
        case collaboration_types_1.EReduxCollaborationActionTypes.MODIFICA_APPUNTAMENTO_SUCCESS: {
            return {
                ...state,
                appuntamentoModificato: true
            };
        }
        case collaboration_types_1.EReduxCollaborationActionTypes.ELIMINA_APPUNTAMENTO_SUCCESS: {
            return {
                ...state,
                appuntamentoEliminato: true
            };
        }
        case collaboration_types_1.EReduxCollaborationActionTypes.RESET_APPUNTAMENTO_MODIFICATO: {
            return {
                ...state,
                appuntamentoModificato: false
            };
        }
        case collaboration_types_1.EReduxCollaborationActionTypes.RESET_APPUNTAMENTO_ELIMINATO: {
            return {
                ...state,
                appuntamentoEliminato: false
            };
        }
        case collaboration_types_1.EReduxCollaborationActionTypes.RESET_APPUNTAMENTO_CREATO: {
            return {
                ...state,
                appuntamentoCreato: false
            };
        }
        case collaboration_types_1.EReduxCollaborationActionTypes.GET_TOKEN_EVENTO_SUCCESS: {
            return {
                ...state,
                tokenEvento: action.payload.token
            };
        }
        case collaboration_types_1.EReduxCollaborationActionTypes.RESET_TOKEN_EVENTO: {
            return {
                ...state,
                tokenEvento: initialState.tokenEvento
            };
        }
        case collaboration_types_1.EReduxCollaborationActionTypes.GET_ARGOMENTI_EVENTO_SUCCESS: {
            return {
                ...state,
                argomentiAppuntamento: action.payload
            };
        }
        case collaboration_types_1.EReduxCollaborationActionTypes.SET_CREA_APPUNTAMENTO_NOTE: {
            return {
                ...state,
                form: {
                    ...state.form,
                    note: action.payload
                }
            };
        }
        case collaboration_types_1.EReduxCollaborationActionTypes.SET_CREA_APPUNTAMENTO_MODALITA_INCONTRO: {
            return {
                ...state,
                form: {
                    ...state.form,
                    modalitaIncontro: action.payload
                }
            };
        }
        case collaboration_types_1.EReduxCollaborationActionTypes.SET_CREA_APPUNTAMENTO_ARGOMENTO_SELEZIONATO: {
            return {
                ...state,
                form: {
                    ...state.form,
                    argomentoSelezionato: action.payload
                }
            };
        }
        case collaboration_types_1.EReduxCollaborationActionTypes.RESET_FORM_CREA_APPUNTAMENTO: {
            return {
                ...state,
                form: initialState.form
            };
        }
        case collaboration_types_1.EReduxCollaborationActionTypes.SET_APPUNTAMENTO_DA_MODIFICARE: {
            return {
                ...state,
                appuntamentoDaModificare: action.payload
            };
        }
        case __1.EReduxActionTypes.GENERAL_REFRESH_WIDGETS: // action invocata tramite pull to refresh
            return {
                ...initialState
            };
        case __1.EReduxActionTypes.RESET_ALL: // action invocata dalla logout
            return {
                ...initialState
            };
        default:
            return state;
    }
};
exports.collaborationReducer = collaborationReducer;
