"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.InfotUtentereqInDtoToJSON = exports.InfotUtentereqInDtoFromJSONTyped = exports.InfotUtentereqInDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function InfotUtentereqInDtoFromJSON(json) {
    return InfotUtentereqInDtoFromJSONTyped(json, false);
}
exports.InfotUtentereqInDtoFromJSON = InfotUtentereqInDtoFromJSON;
function InfotUtentereqInDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'ndg': !runtime_1.exists(json, 'ndg') ? undefined : json['ndg'],
        'numeroTelefono': !runtime_1.exists(json, 'numeroTelefono') ? undefined : json['numeroTelefono'],
    };
}
exports.InfotUtentereqInDtoFromJSONTyped = InfotUtentereqInDtoFromJSONTyped;
function InfotUtentereqInDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'ndg': value.ndg,
        'numeroTelefono': value.numeroTelefono,
    };
}
exports.InfotUtentereqInDtoToJSON = InfotUtentereqInDtoToJSON;
