"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormFidoIsiDispoDtoFromJSONTyped = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
function FormFidoIsiDispoDtoFromJSONTyped(json) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'rapportoSelezionato': !(0, api_rest_1.exists)(json, 'rapportoSelezionato') ? (0, api_rest_1.RapportoCompletoDtoFromJSON)({}) : json.rapportoSelezionato,
        'massimaleTransazione': !(0, api_rest_1.exists)(json, 'massimaleTransazione') ? undefined : json.massimaleTransazione,
        'massimaleTransazioneDirty': !(0, api_rest_1.exists)(json, 'massimaleTransazioneDirty') ? false : json.massimaleTransazioneDirty,
        'massimaleTransazioneError': !(0, api_rest_1.exists)(json, 'massimaleTransazioneError') ? '' : json.massimaleTransazioneError,
        'statoCivile': !(0, api_rest_1.exists)(json, 'statoCivile') ? undefined : json.statoCivile,
        'statoCivileDirty': !(0, api_rest_1.exists)(json, 'statoCivileDirty') ? false : json.statoCivileDirty,
        'statoCivileError': !(0, api_rest_1.exists)(json, 'statoCivileError') ? 'invalid.required' : json.statoCivileError,
        'numeroAdulti': !(0, api_rest_1.exists)(json, 'numeroAdulti') ? 1 : json.numeroAdulti,
        'numeroAdultiDirty': !(0, api_rest_1.exists)(json, 'numeroAdultiDirty') ? true : json.numeroAdultiDirty,
        'numeroAdultiError': !(0, api_rest_1.exists)(json, 'numeroAdultiError') ? '' : json.numeroAdultiError,
        'numeroFigli': !(0, api_rest_1.exists)(json, 'numeroFigli') ? 0 : json.numeroFigli,
        'numeroFigliDirty': !(0, api_rest_1.exists)(json, 'numeroFigliDirty') ? true : json.numeroFigliDirty,
        'numeroFigliError': !(0, api_rest_1.exists)(json, 'numeroFigliError') ? '' : json.numeroFigliError,
        'situazioneAbitativa': !(0, api_rest_1.exists)(json, 'situazioneAbitativa') ? undefined : json.situazioneAbitativa,
        'situazioneAbitativaDirty': !(0, api_rest_1.exists)(json, 'situazioneAbitativaDirty') ? false : json.situazioneAbitativaDirty,
        'situazioneAbitativaError': !(0, api_rest_1.exists)(json, 'situazioneAbitativaError') ? 'invalid.required' : json.situazioneAbitativaError,
        'categoriaProfessionale': !(0, api_rest_1.exists)(json, 'categoriaProfessionale') ? undefined : json.categoriaProfessionale,
        'categoriaProfessionaleDirty': !(0, api_rest_1.exists)(json, 'categoriaProfessionaleDirty') ? false : json.categoriaProfessionaleDirty,
        'categoriaProfessionaleError': !(0, api_rest_1.exists)(json, 'categoriaProfessionaleError') ? 'invalid.required' : json.categoriaProfessionaleError,
        'dettaglioProfessione': !(0, api_rest_1.exists)(json, 'dettaglioProfessione') ? undefined : json.dettaglioProfessione,
        'dettaglioProfessioneDirty': !(0, api_rest_1.exists)(json, 'dettaglioProfessioneDirty') ? false : json.dettaglioProfessioneDirty,
        'dettaglioProfessioneError': !(0, api_rest_1.exists)(json, 'dettaglioProfessioneError') ? 'invalid.required' : json.dettaglioProfessioneError,
        'categoriaInattivi': !(0, api_rest_1.exists)(json, 'categoriaInattivi') ? 0 : json.categoriaInattivi,
        'categoriaDipendenti': !(0, api_rest_1.exists)(json, 'categoriaDipendenti') ? 0 : json.categoriaDipendenti,
        'categoriaMilitari': !(0, api_rest_1.exists)(json, 'categoriaMilitari') ? 0 : json.categoriaMilitari,
        'categoriaDocenti': !(0, api_rest_1.exists)(json, 'categoriaDocenti') ? 0 : json.categoriaDocenti,
        'categoriaLiberiProfessionisti': !(0, api_rest_1.exists)(json, 'categoriaLiberiProfessionisti') ? 0 : json.categoriaLiberiProfessionisti,
        'categoriaAutonomi': !(0, api_rest_1.exists)(json, 'categoriaAutonomi') ? 0 : json.categoriaAutonomi,
        'datoreDiLavoro': !(0, api_rest_1.exists)(json, 'alias1Click') ? undefined : json.alias1Click,
        'datoreDiLavoroDirty': !(0, api_rest_1.exists)(json, 'datoreDiLavoroDirty') ? false : json.datoreDiLavoroDirty,
        'datoreDiLavoroError': !(0, api_rest_1.exists)(json, 'datoreDiLavoroError') ? 'invalid.required' : json.datoreDiLavoroError,
        'rapportoDiLavoro': !(0, api_rest_1.exists)(json, 'rapportoDiLavoro') ? undefined : json.rapportoDiLavoro,
        'rapportoDiLavoroDirty': !(0, api_rest_1.exists)(json, 'rapportoDiLavoroDirty') ? false : json.rapportoDiLavoroDirty,
        'rapportoDiLavoroError': !(0, api_rest_1.exists)(json, 'rapportoDiLavoroError') ? 'invalid.required' : json.rapportoDiLavoroError,
        'annoDiInizio': !(0, api_rest_1.exists)(json, 'annoDiInizio') ? '' : json.annoDiInizio,
        'annoDiInizioDirty': !(0, api_rest_1.exists)(json, 'annoDiInizioDirty') ? false : json.annoDiInizioDirty,
        'annoDiInizioError': !(0, api_rest_1.exists)(json, 'annoDiInizioError') ? 'invalid.required' : json.annoDiInizioError,
        'accreditoDomiciliatoPressoBanca': !(0, api_rest_1.exists)(json, 'accreditoDomiciliatoPressoBanca') ? false : json.accreditoDomiciliatoPressoBanca,
        'redditoNettoMensile': !(0, api_rest_1.exists)(json, 'redditoNettoMensile') ? undefined : json.redditoNettoMensile,
        'redditoNettoMensileDirty': !(0, api_rest_1.exists)(json, 'redditoNettoMensileDirty') ? false : json.redditoNettoMensileDirty,
        'redditoNettoMensileError': !(0, api_rest_1.exists)(json, 'redditoNettoMensileError') ? 'invalid.required' : json.redditoNettoMensileError,
        'altriRedditiMensili': !(0, api_rest_1.exists)(json, 'altriRedditiMensili') ? undefined : json.altriRedditiMensili,
        'altriRedditiMensiliDirty': !(0, api_rest_1.exists)(json, 'altriRedditiMensiliDirty') ? false : json.altriRedditiMensiliDirty,
        'altriRedditiMensiliError': !(0, api_rest_1.exists)(json, 'altriRedditiMensiliError') ? 'invalid.required' : json.altriRedditiMensiliError,
        'oneriMensiliCrediti': !(0, api_rest_1.exists)(json, 'oneriMensiliCrediti') ? undefined : json.oneriMensiliCrediti,
        'oneriMensiliCreditiDirty': !(0, api_rest_1.exists)(json, 'oneriMensiliCreditiDirty') ? false : json.oneriMensiliCreditiDirty,
        'oneriMensiliCreditiError': !(0, api_rest_1.exists)(json, 'oneriMensiliCreditiError') ? 'invalid.required' : json.oneriMensiliCreditiError,
        'impegniMensiliAffitti': !(0, api_rest_1.exists)(json, 'impegniMensiliAffitti') ? undefined : json.impegniMensiliAffitti,
        'impegniMensiliAffittiDirty': !(0, api_rest_1.exists)(json, 'impegniMensiliAffittiDirty') ? false : json.impegniMensiliAffittiDirty,
        'impegniMensiliAffittiError': !(0, api_rest_1.exists)(json, 'impegniMensiliAffittiError') ? 'invalid.required' : json.impegniMensiliAffittiError,
        'altriImpegni': !(0, api_rest_1.exists)(json, 'altriImpegni') ? undefined : json.altriImpegni,
        'altriImpegniDirty': !(0, api_rest_1.exists)(json, 'altriImpegniDirty') ? false : json.altriImpegniDirty,
        'altriImpegniError': !(0, api_rest_1.exists)(json, 'altriImpegniError') ? 'invalid.required' : json.altriImpegniError,
        'carteDiCreditoPossedute': !(0, api_rest_1.exists)(json, 'carteDiCreditoPossedute') ? undefined : json.carteDiCreditoPossedute,
        'carteDiCreditoPosseduteDirty': !(0, api_rest_1.exists)(json, 'carteDiCreditoPosseduteDirty') ? false : json.carteDiCreditoPosseduteDirty,
        'carteDiCreditoPosseduteError': !(0, api_rest_1.exists)(json, 'carteDiCreditoPosseduteError') ? 'invalid.required' : json.carteDiCreditoPosseduteError,
        'arrayStatoCivile': !(0, api_rest_1.exists)(json, 'arrayStatoCivile') ? [] : json.arrayStatoCivile,
        'arraySituazioneAbitativa': !(0, api_rest_1.exists)(json, 'arraySituazioneAbitativa') ? [] : json.arraySituazioneAbitativa,
        'arrayCategoriaProfessionale': !(0, api_rest_1.exists)(json, 'arrayCategoriaProfessionale') ? [] : json.arrayCategoriaProfessionale,
        'arrayDettaglioProfessione': !(0, api_rest_1.exists)(json, 'arrayDettaglioProfessione') ? [] : json.arrayDettaglioProfessione,
        'arrayCategoriaInattivi': !(0, api_rest_1.exists)(json, 'arrayCategoriaInattivi') ? [] : json.arrayCategoriaInattivi,
        'arrayCategoriaDipendenti': !(0, api_rest_1.exists)(json, 'arrayCategoriaDipendenti') ? [] : json.arrayCategoriaDipendenti,
        'arrayCategoriaMilitari': !(0, api_rest_1.exists)(json, 'arrayCategoriaMilitari') ? [] : json.arrayCategoriaMilitari,
        'arrayCategoriaDocenti': !(0, api_rest_1.exists)(json, 'arrayCategoriaDocenti') ? [] : json.arrayCategoriaDocenti,
        'arrayCategoriaLiberiProfessionisti': !(0, api_rest_1.exists)(json, 'arrayCategoriaLiberiProfessionisti') ? [] : json.arrayCategoriaLiberiProfessionisti,
        'arrayCategoriaAutonomi': !(0, api_rest_1.exists)(json, 'arrayCategoriaAutonomi') ? [] : json.arrayCategoriaAutonomi,
        'arrayRapportoDiLavoro': !(0, api_rest_1.exists)(json, 'arrayRapportoDiLavoro') ? [] : json.arrayRapportoDiLavoro,
        'arrayCartePossedute': !(0, api_rest_1.exists)(json, 'arrayCartePossedute') ? [] : json.arrayCartePossedute,
        'formSubmitted': !(0, api_rest_1.exists)(json, 'formSubmitted') ? false : json.formSubmitted,
        'step': !(0, api_rest_1.exists)(json, 'step') ? 0 : json.step,
        'presaVisioneInformativa': !(0, api_rest_1.exists)(json, 'presaVisioneInformativa') ? false : json.presaVisioneInformativa,
        'presaVisioneInformativaEnabled': !(0, api_rest_1.exists)(json, 'presaVisioneInformativaEnabled') ? false : json.presaVisioneInformativaEnabled,
        'dichiarazioneScopoChecked': !(0, api_rest_1.exists)(json, 'dichiarazioneScopoChecked') ? false : json.dichiarazioneScopoChecked,
        'massimaleMin': !(0, api_rest_1.exists)(json, 'massimaleMin') ? 0 : json.massimaleMin,
        'massimaleMax': !(0, api_rest_1.exists)(json, 'massimaleMax') ? 0 : json.massimaleMax,
        'stepMassimale': !(0, api_rest_1.exists)(json, 'stepMassimale') ? 0 : json.stepMassimale
    };
}
exports.FormFidoIsiDispoDtoFromJSONTyped = FormFidoIsiDispoDtoFromJSONTyped;
