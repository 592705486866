"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthenticationResponseMandatoryActionDtoToJSON = exports.AuthenticationResponseMandatoryActionDtoFromJSONTyped = exports.AuthenticationResponseMandatoryActionDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function AuthenticationResponseMandatoryActionDtoFromJSON(json) {
    return AuthenticationResponseMandatoryActionDtoFromJSONTyped(json, false);
}
exports.AuthenticationResponseMandatoryActionDtoFromJSON = AuthenticationResponseMandatoryActionDtoFromJSON;
function AuthenticationResponseMandatoryActionDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'actionAccessToken': !runtime_1.exists(json, 'actionAccessToken') ? undefined : json['actionAccessToken'],
        'obligatory': !runtime_1.exists(json, 'obligatory') ? undefined : json['obligatory'],
        'subtype': !runtime_1.exists(json, 'subtype') ? undefined : json['subtype'],
        'type': !runtime_1.exists(json, 'type') ? undefined : _1.MandatoryActionTypeEnumFromJSON(json['type']),
    };
}
exports.AuthenticationResponseMandatoryActionDtoFromJSONTyped = AuthenticationResponseMandatoryActionDtoFromJSONTyped;
function AuthenticationResponseMandatoryActionDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'actionAccessToken': value.actionAccessToken,
        'obligatory': value.obligatory,
        'subtype': value.subtype,
        'type': _1.MandatoryActionTypeEnumToJSON(value.type),
    };
}
exports.AuthenticationResponseMandatoryActionDtoToJSON = AuthenticationResponseMandatoryActionDtoToJSON;
