"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.newsReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const news_types_1 = require("./news.types");
const initialState = {
    news: (0, api_rest_1.ElencoNewsDtoFromJSON)({}),
    parametri: (0, api_rest_1.ParametriFinanzaDtoFromJSON)({}),
    dettaglioNews: (0, api_rest_1.ElencoNewsDtoFromJSON)({}),
    datiIntradayNews: (0, api_rest_1.DatiIntradayDtoFromJSON)({}),
    form: {
        periodo: '',
        denominazione: '',
        newsSelezionato: (0, api_rest_1.QuotazioneTitoloDtoFromJSON)({}),
        offset: 0
    }
};
const newsReducer = (state = initialState, action) => {
    switch (action.type) {
        case news_types_1.EReduxNewsActionTypes.NEWS_GET_PARAMETRI_SUCCESS:
            return {
                ...state,
                parametri: action.payload
            };
        case news_types_1.EReduxNewsActionTypes.NEWS_GET_NEWS_SUCCESS:
            return {
                ...state,
                news: !action.offset
                    ? action.payload
                    : {
                        ...state.news,
                        news: (state.news.news || []).concat(action.payload.news || [])
                    }
            };
        case news_types_1.EReduxNewsActionTypes.NEWS_GET_DETTAGLIO_NEWS_SUCCESS:
            return {
                ...state,
                dettaglioNews: action.payload
            };
        case news_types_1.EReduxNewsActionTypes.NEWS_GET_DATI_INTRADAY_NEWS_SUCCESS:
            return {
                ...state,
                datiIntradayNews: action.payload
            };
        case news_types_1.EReduxNewsActionTypes.SET_NEWS_PERIODO:
            return {
                ...state,
                form: {
                    ...state.form,
                    periodo: action.payload
                }
            };
        case news_types_1.EReduxNewsActionTypes.SET_NEWS_DENOMINAZIONE:
            return {
                ...state,
                form: {
                    ...state.form,
                    denominazione: action.payload
                }
            };
        case news_types_1.EReduxNewsActionTypes.NEWS_RESET_FORM:
            return {
                ...state,
                form: {
                    ...state.form,
                    periodo: initialState.form.periodo,
                    denominazione: initialState.form.denominazione,
                    offset: initialState.form.offset
                }
            };
        case news_types_1.EReduxNewsActionTypes.NEWS_SET_NEWS_SELEZIONATO:
            return {
                ...state,
                form: {
                    ...state.form,
                    newsSelezionato: action.payload
                }
            };
        case news_types_1.EReduxNewsActionTypes.NEWS_RESET:
            return {
                ...initialState
            };
        default:
            return state;
    }
};
exports.newsReducer = newsReducer;
exports.default = exports.newsReducer;
