"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filtriBozzeApplicati = exports.formBozze = exports.bozze = exports.parametriBozze = void 0;
const reselect_1 = require("reselect");
const bozzeReducer = (state) => state;
exports.parametriBozze = (0, reselect_1.createSelector)(bozzeReducer, (state) => {
    return state.parametri;
});
exports.bozze = (0, reselect_1.createSelector)(bozzeReducer, (state) => {
    return state.bozze;
});
exports.formBozze = (0, reselect_1.createSelector)(bozzeReducer, (state) => {
    return state.form;
});
exports.filtriBozzeApplicati = (0, reselect_1.createSelector)(bozzeReducer, (state) => {
    var _a, _b, _c;
    let r = false;
    if (!!state.form.descrizione || state.form.tipologia != 'BONIFICOSEPA') {
        r = true;
    }
    if (((_a = state.parametri) === null || _a === void 0 ? void 0 : _a.rapporti) && ((_c = (_b = state.parametri) === null || _b === void 0 ? void 0 : _b.rapporti) === null || _c === void 0 ? void 0 : _c.length) > 1 && !state.form.tuttiRapporti) {
        r = true;
    }
    return r;
});
