"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildPrestito = exports.formValidFidoIsiDispo = exports.esitoFidoIsiDispo = exports.infoDispositivaFidoIsiDispo = exports.riepilogoFidoIsiDispo = exports.parametriFidoIsiDispo = exports.formRichiediFidoIsiDispo = void 0;
const reselect_1 = require("reselect");
const helpers_1 = require("../helpers");
const richiediFidoIsiDispoReducer = (state) => state;
exports.formRichiediFidoIsiDispo = (0, reselect_1.createSelector)(richiediFidoIsiDispoReducer, (state) => {
    return state.form;
});
exports.parametriFidoIsiDispo = (0, reselect_1.createSelector)(richiediFidoIsiDispoReducer, (state) => {
    return state.parametri;
});
exports.riepilogoFidoIsiDispo = (0, reselect_1.createSelector)(richiediFidoIsiDispoReducer, (state) => {
    return state.riepilogo;
});
exports.infoDispositivaFidoIsiDispo = (0, reselect_1.createSelector)(richiediFidoIsiDispoReducer, (state) => {
    return state.infoDispositiva;
});
exports.esitoFidoIsiDispo = (0, reselect_1.createSelector)(richiediFidoIsiDispoReducer, (state) => {
    return state.esito;
});
exports.formValidFidoIsiDispo = (0, reselect_1.createSelector)(richiediFidoIsiDispoReducer, (state) => {
    let formValid = false;
    if (!state.form.massimaleTransazioneError &&
        !state.form.statoCivileError &&
        !state.form.situazioneAbitativaError &&
        !state.form.categoriaProfessionaleError &&
        !state.form.dettaglioProfessioneError &&
        !state.form.rapportoDiLavoroError &&
        !state.form.numeroAdultiError &&
        !state.form.numeroFigliError &&
        !state.form.datoreDiLavoroError &&
        !state.form.annoDiInizioError &&
        !state.form.redditoNettoMensileError &&
        !state.form.altriRedditiMensiliError &&
        !state.form.oneriMensiliCreditiError &&
        !state.form.impegniMensiliAffittiError &&
        !state.form.impegniMensiliAffittiError &&
        !state.form.altriImpegniError &&
        !state.form.carteDiCreditoPosseduteError) {
        formValid = true;
    }
    return formValid;
});
exports.buildPrestito = (0, reselect_1.createSelector)(richiediFidoIsiDispoReducer, (state) => {
    var _a;
    return {
        codiceRapporto: (_a = state.form.rapportoSelezionato.rapporto) === null || _a === void 0 ? void 0 : _a.id,
        importo: state.form.massimaleTransazione,
        statoCivile: state.form.statoCivile,
        numeroFamAdulti: state.form.numeroAdulti,
        numeroFigli: state.form.numeroFigli,
        situazioneAbitativa: state.form.situazioneAbitativa,
        categoriaProfessione: state.form.categoriaProfessionale,
        categoriaInattivi: state.form.categoriaInattivi,
        categoriaDipendenti: state.form.categoriaDipendenti,
        categoriaMilitari: state.form.categoriaMilitari,
        categoriaDocenti: state.form.categoriaDocenti,
        categoriaLiberiProfessionisti: state.form.categoriaLiberiProfessionisti,
        categoriaAutonomi: state.form.categoriaAutonomi,
        nomeDatoreLavoro: state.form.datoreDiLavoro,
        rapportoDiLavoro: state.form.rapportoDiLavoro,
        annoInizioAttivita: state.form.annoDiInizio,
        domiciliazioneStipendio: state.form.accreditoDomiciliatoPressoBanca ? '1' : '0',
        redditoNettoMensile: (0, helpers_1.formattedNumberToNumber)(state.form.redditoNettoMensile),
        altriRedditiMensili: (0, helpers_1.formattedNumberToNumber)(state.form.altriRedditiMensili),
        oneriMensiliCrediti: (0, helpers_1.formattedNumberToNumber)(state.form.oneriMensiliCrediti),
        oneriMensiliAffitto: (0, helpers_1.formattedNumberToNumber)(state.form.impegniMensiliAffitti),
        altriImpegniMensili: (0, helpers_1.formattedNumberToNumber)(state.form.altriImpegni),
        tipoCartaDiCredito: state.form.carteDiCreditoPossedute
    };
});
