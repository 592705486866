"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setPinningCertificates = exports.getPinningCertificates = void 0;
const env_1 = require("./env");
const environments_1 = require("./environments");
const getConfiguration = (Configuration, extraArguments, base, jwt, accept, server, lApiKey, apiKey) => {
    const c = new Configuration();
    const customEnv = (0, environments_1.getCustomEnv)();
    let host = (environments_1.Environments[customEnv || env_1.selectedEnv || 'DEV']).corebanking;
    if (server) {
        host = (environments_1.Environments[customEnv || env_1.selectedEnv || 'DEV'])[server];
    }
    let env = customEnv || env_1.selectedEnv;
    if (env && env != 'DEV' && env != 'MOCK' && extraArguments && extraArguments.platform == 'desktop' && window.location.hostname !== 'localhost') {
        host = window.location.origin;
    }
    const configuration = {
        basePath: host + (base !== null && base !== void 0 ? base : '/api'),
        middleware: c.middleware,
        fetchApi: c.fetchApi,
        queryParamsStringify: c.queryParamsStringify,
        headers: {},
        apiKey: {}
    };
    configuration.headers.accept = accept || 'application/json';
    if (jwt) {
        configuration.headers.Authorization = 'Bearer ' + jwt;
    }
    // if (extraArguments.mockBE) {
    if (((0, environments_1.getCustomEnv)() || env_1.selectedEnv) === 'MOCK') {
        configuration.headers['end-env'] = 'mock';
    }
    if (extraArguments.platform) {
        configuration.headers.canale = extraArguments.platform;
        if (extraArguments.platform === 'mobile' && extraArguments.deviceInfo) {
            configuration.headers['device-info'] = extraArguments.deviceInfo;
        }
        // il pinning dinamico è attivo per ogni ambiente
        // sono esclusi gli endpoint che scaricano documenti (Blob)
        // fetchApi è il metodo fetch del plugin del pinning dinamico
        // è esclusa la chiamata di get elenco certificati (endpoint Melli)
        // l'esclusione viene fatta nel middleware sslPinningMiddleware agendo sul flag disableAllSecurity
        if (extraArguments.platform === 'mobile' &&
            extraArguments.fetchApi &&
            !accept
        // if (extraArguments.platform === 'mobile' && extraArguments.fetchApi && !accept && env != 'DEV' && env != 'QA' && env != 'ADS' && env != 'MOCK') {
        // env == 'PROD'
        ) {
            configuration.middleware = [...configuration.middleware, sslPinningMiddleware];
            configuration.fetchApi = extraArguments.fetchApi;
        }
    }
    if (lApiKey) {
        configuration.headers['L-API-KEY'] = lApiKey;
    }
    if (apiKey == true) {
        configuration.apiKey = () => "demo"; // TODO da sistemare l'api DocumentScannerApi.ts e DocumentSessionApi.ts - è un WORKAROUND
    }
    return configuration;
};
const sslPinningMiddleware = {
    pre: (context) => {
        return new Promise(resolve => {
            context.init.timeoutInterval = 120000;
            context.init.pkPinning = true;
            context.init.disableAllSecurity = context.url.includes('/public/oauth/pinning');
            context.init.sslPinning = {
                certs: getPinningCertificates()
            };
            if ((context.init.method === 'PUT' || context.init.method === 'POST') && !context.init.body) {
                context.init.body = '{}';
            }
            resolve(context);
        });
    }
};
let pinningCertificates = [];
function getPinningCertificates() {
    return pinningCertificates;
}
exports.getPinningCertificates = getPinningCertificates;
function setPinningCertificates(certs) {
    pinningCertificates = certs;
}
exports.setPinningCertificates = setPinningCertificates;
exports.default = getConfiguration;
