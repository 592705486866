"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetQuestionnaireAnswersInDtoToJSON = exports.GetQuestionnaireAnswersInDtoFromJSONTyped = exports.GetQuestionnaireAnswersInDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function GetQuestionnaireAnswersInDtoFromJSON(json) {
    return GetQuestionnaireAnswersInDtoFromJSONTyped(json, false);
}
exports.GetQuestionnaireAnswersInDtoFromJSON = GetQuestionnaireAnswersInDtoFromJSON;
function GetQuestionnaireAnswersInDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'customerId': json['customerId'],
        'idQuestionnaire': json['idQuestionnaire'],
        'sectionCode': !runtime_1.exists(json, 'sectionCode') ? undefined : json['sectionCode'],
    };
}
exports.GetQuestionnaireAnswersInDtoFromJSONTyped = GetQuestionnaireAnswersInDtoFromJSONTyped;
function GetQuestionnaireAnswersInDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'customerId': value.customerId,
        'idQuestionnaire': value.idQuestionnaire,
        'sectionCode': value.sectionCode,
    };
}
exports.GetQuestionnaireAnswersInDtoToJSON = GetQuestionnaireAnswersInDtoToJSON;
