"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EsitoAutorizzazioneDisposizioneDtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneDtoToJSON = exports.EsitoAutorizzazioneDisposizioneDtoFromJSONTyped = exports.EsitoAutorizzazioneDisposizioneDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function EsitoAutorizzazioneDisposizioneDtoFromJSON(json) {
    return EsitoAutorizzazioneDisposizioneDtoFromJSONTyped(json, false);
}
exports.EsitoAutorizzazioneDisposizioneDtoFromJSON = EsitoAutorizzazioneDisposizioneDtoFromJSON;
function EsitoAutorizzazioneDisposizioneDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
        'codiceErrore': !runtime_1.exists(json, 'codiceErrore') ? undefined : json['codiceErrore'],
        'informazioniAutenticazioneDispositiva': !runtime_1.exists(json, 'informazioniAutenticazioneDispositiva') ? undefined : _1.InformazioniAutenticazioneDispositivaDtoFromJSON(json['informazioniAutenticazioneDispositiva']),
    };
}
exports.EsitoAutorizzazioneDisposizioneDtoFromJSONTyped = EsitoAutorizzazioneDisposizioneDtoFromJSONTyped;
function EsitoAutorizzazioneDisposizioneDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'stato': value.stato,
        'codiceErrore': value.codiceErrore,
        'informazioniAutenticazioneDispositiva': _1.InformazioniAutenticazioneDispositivaDtoToJSON(value.informazioniAutenticazioneDispositiva),
    };
}
exports.EsitoAutorizzazioneDisposizioneDtoToJSON = EsitoAutorizzazioneDisposizioneDtoToJSON;
/**
* @export
* @enum {string}
*/
var EsitoAutorizzazioneDisposizioneDtoStatoEnum;
(function (EsitoAutorizzazioneDisposizioneDtoStatoEnum) {
    EsitoAutorizzazioneDisposizioneDtoStatoEnum["OK"] = "OK";
    EsitoAutorizzazioneDisposizioneDtoStatoEnum["CREDENZIALE_ERRATA"] = "CREDENZIALE_ERRATA";
    EsitoAutorizzazioneDisposizioneDtoStatoEnum["ATTENDERE"] = "ATTENDERE";
    EsitoAutorizzazioneDisposizioneDtoStatoEnum["ACCOUNT_BLOCCATO"] = "ACCOUNT_BLOCCATO";
    EsitoAutorizzazioneDisposizioneDtoStatoEnum["ACCP"] = "ACCP";
    EsitoAutorizzazioneDisposizioneDtoStatoEnum["ACSC"] = "ACSC";
    EsitoAutorizzazioneDisposizioneDtoStatoEnum["ACSP"] = "ACSP";
    EsitoAutorizzazioneDisposizioneDtoStatoEnum["ACTC"] = "ACTC";
    EsitoAutorizzazioneDisposizioneDtoStatoEnum["ACWC"] = "ACWC";
    EsitoAutorizzazioneDisposizioneDtoStatoEnum["ACWP"] = "ACWP";
    EsitoAutorizzazioneDisposizioneDtoStatoEnum["RCVD"] = "RCVD";
    EsitoAutorizzazioneDisposizioneDtoStatoEnum["PDNG"] = "PDNG";
    EsitoAutorizzazioneDisposizioneDtoStatoEnum["RJCT"] = "RJCT";
    EsitoAutorizzazioneDisposizioneDtoStatoEnum["CANC"] = "CANC";
})(EsitoAutorizzazioneDisposizioneDtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneDtoStatoEnum || (exports.EsitoAutorizzazioneDisposizioneDtoStatoEnum = {}));
