"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.erroreSpecificoBancomatPayCellulareNonPresente = exports.formGestioneRapportiBancomatPayValid = exports.soglie = exports.buildForSoglie = exports.formGestioneRapportiBancomatPayAsFilter = exports.esitoRapportiBancomatPay = exports.infoAutenticazioneRapportiBancomatPay = exports.disposizioneVirtualeRapportiBancomatPay = exports.formMassimaliBancomatPayAsFilter = exports.disposizioneVirtualeMassimaliBancomatPay = exports.disposizioneVirtualeDisattivaBancomatPay = exports.listaServiziOperativiBancomatPay = exports.esitoDisattivaBancomatPay = exports.infoAutenticazioneDisattivaBancomatPay = exports.disposizioneVirtualeAttivazioneBancomatPay = exports.esitoAttivazioneBancomatPay = exports.infoAutenticazioneAttivazioneBancomatPay = exports.esitoMassimaliBancomatPay = exports.infoDispositivaMassimaliBancomatPay = exports.modificaImpostazioniBancomatPay = exports.esitoBancomatPay = exports.infoDispositivaBancomatPay = exports.disposizioneVirtualeBancomatPay = exports.formBancomatPayAdesioneValid = exports.statoUtenteBancomatPay = exports.parametriBancomatPay = exports.formBancomatPay = void 0;
const reselect_1 = require("reselect");
const bancomatPayReducer = (state) => state;
exports.formBancomatPay = (0, reselect_1.createSelector)(bancomatPayReducer, (state) => {
    return state.form;
});
exports.parametriBancomatPay = (0, reselect_1.createSelector)(bancomatPayReducer, (state) => {
    return state.parametri;
});
exports.statoUtenteBancomatPay = (0, reselect_1.createSelector)(bancomatPayReducer, (state) => {
    return state.statoUtente;
});
exports.formBancomatPayAdesioneValid = (0, reselect_1.createSelector)(bancomatPayReducer, (state) => {
    return !!state.form.accetta;
});
exports.disposizioneVirtualeBancomatPay = (0, reselect_1.createSelector)(bancomatPayReducer, (state) => {
    return state.disposizioneVirtuale;
});
exports.infoDispositivaBancomatPay = (0, reselect_1.createSelector)(bancomatPayReducer, (state) => {
    return state.infoDispositiva;
});
exports.esitoBancomatPay = (0, reselect_1.createSelector)(bancomatPayReducer, (state) => {
    return state.esito;
});
exports.modificaImpostazioniBancomatPay = (0, reselect_1.createSelector)(bancomatPayReducer, (state) => {
    return state.modificaImpostazioni;
});
exports.infoDispositivaMassimaliBancomatPay = (0, reselect_1.createSelector)(bancomatPayReducer, (state) => {
    return state.infoDispositivaMassimali;
});
exports.esitoMassimaliBancomatPay = (0, reselect_1.createSelector)(bancomatPayReducer, (state) => {
    return state.esitoMassimali;
});
exports.infoAutenticazioneAttivazioneBancomatPay = (0, reselect_1.createSelector)(bancomatPayReducer, (state) => {
    return state.infoAutenticazioneAttivazione;
});
exports.esitoAttivazioneBancomatPay = (0, reselect_1.createSelector)(bancomatPayReducer, (state) => {
    return state.esitoAttivazione;
});
exports.disposizioneVirtualeAttivazioneBancomatPay = (0, reselect_1.createSelector)(bancomatPayReducer, (state) => {
    return state.disposizioneVirtualeAttivazione;
});
exports.infoAutenticazioneDisattivaBancomatPay = (0, reselect_1.createSelector)(bancomatPayReducer, (state) => {
    return state.infoAutenticazioneDisattiva;
});
exports.esitoDisattivaBancomatPay = (0, reselect_1.createSelector)(bancomatPayReducer, (state) => {
    return state.esitoDisattiva;
});
exports.listaServiziOperativiBancomatPay = (0, reselect_1.createSelector)(bancomatPayReducer, (state) => {
    return state.listaServiziOperativi;
});
exports.disposizioneVirtualeDisattivaBancomatPay = (0, reselect_1.createSelector)(bancomatPayReducer, (state) => {
    return state.disposizioneVirtualeDisattiva;
});
exports.disposizioneVirtualeMassimaliBancomatPay = (0, reselect_1.createSelector)(bancomatPayReducer, (state) => {
    return state.disposizioneVirtualeMassimali;
});
exports.formMassimaliBancomatPayAsFilter = (0, reselect_1.createSelector)(bancomatPayReducer, (state) => {
    var _a, _b, _c, _d, _e, _f, _g;
    return {
        codiceRapporto: ((_c = (_b = (_a = state.form.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.id) === null || _c === void 0 ? void 0 : _c.toString()) || '',
        idSessioneChiamante: (_d = state.statoUtente) === null || _d === void 0 ? void 0 : _d.idSessioneChiamante,
        massimaleTransazione: (_e = state.form) === null || _e === void 0 ? void 0 : _e.massimaleTransazione,
        massimaleGiornaliero: (_f = state.form) === null || _f === void 0 ? void 0 : _f.massimaleGiornaliero,
        massimaleMensile: (_g = state.form) === null || _g === void 0 ? void 0 : _g.massimaleMensile
    };
});
exports.disposizioneVirtualeRapportiBancomatPay = (0, reselect_1.createSelector)(bancomatPayReducer, (state) => {
    return state.disposizioneVirtualeRapporti;
});
exports.infoAutenticazioneRapportiBancomatPay = (0, reselect_1.createSelector)(bancomatPayReducer, (state) => {
    return state.infoAutenticazioneRapporti;
});
exports.esitoRapportiBancomatPay = (0, reselect_1.createSelector)(bancomatPayReducer, (state) => {
    return state.esitoRapporti;
});
exports.formGestioneRapportiBancomatPayAsFilter = (0, reselect_1.createSelector)(bancomatPayReducer, (state) => {
    var _a, _b;
    return {
        idSessioneChiamante: (_a = state.statoUtente) === null || _a === void 0 ? void 0 : _a.idSessioneChiamante,
        servizi: (_b = state === null || state === void 0 ? void 0 : state.form) === null || _b === void 0 ? void 0 : _b.selectedListaServiziOperativi
    };
});
exports.buildForSoglie = (0, reselect_1.createSelector)(bancomatPayReducer, (state) => {
    var _a, _b, _c, _d;
    const { form } = state;
    const servizioSel = (_a = form === null || form === void 0 ? void 0 : form.selectedListaServiziOperativi) === null || _a === void 0 ? void 0 : _a.find(currentServizio => currentServizio.defaultInvio);
    return {
        idServizio: (servizioSel === null || servizioSel === void 0 ? void 0 : servizioSel.idServizio) || '',
        idStrumento: (servizioSel === null || servizioSel === void 0 ? void 0 : servizioSel.idStrumento) || '',
        codiceRapporto: ((_c = (_b = form.rapportoSelezionato) === null || _b === void 0 ? void 0 : _b.rapporto) === null || _c === void 0 ? void 0 : _c.id) || 0,
        idSessioneChiamante: ((_d = state.statoUtente) === null || _d === void 0 ? void 0 : _d.idSessioneChiamante) || '',
    };
});
exports.soglie = (0, reselect_1.createSelector)(bancomatPayReducer, (state) => {
    var _a, _b, _c;
    const { soglie } = state;
    return {
        giornaliera: ((_a = soglie === null || soglie === void 0 ? void 0 : soglie.rangeSoglie) === null || _a === void 0 ? void 0 : _a.find(soglia => soglia.tipoSoglia === 'G')) || {},
        mensile: ((_b = soglie === null || soglie === void 0 ? void 0 : soglie.rangeSoglie) === null || _b === void 0 ? void 0 : _b.find(soglia => soglia.tipoSoglia === 'M')) || {},
        transazione: ((_c = soglie === null || soglie === void 0 ? void 0 : soglie.rangeSoglie) === null || _c === void 0 ? void 0 : _c.find(soglia => soglia.tipoSoglia === 'SP')) || {},
    };
});
exports.formGestioneRapportiBancomatPayValid = (0, reselect_1.createSelector)(bancomatPayReducer, (state) => {
    var _a, _b;
    const { form } = state;
    return !!(((_a = form.selectedListaServiziOperativi) === null || _a === void 0 ? void 0 : _a.some(currentServizio => currentServizio.selectedInvio)) && ((_b = form.selectedListaServiziOperativi) === null || _b === void 0 ? void 0 : _b.some(currentServizio => currentServizio.defaultInvio)));
});
exports.erroreSpecificoBancomatPayCellulareNonPresente = (0, reselect_1.createSelector)(bancomatPayReducer, (state) => {
    return state.erroreSpecifico;
});
