"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.onboardingReducer = void 0;
const __1 = require("..");
const onboarding_types_1 = require("./onboarding.types");
const initialState = {
    esitoInviaNotificaPush: undefined,
    esitoCheck: undefined,
    esitoUpdate: undefined,
    updateNecessario: undefined
};
const onboardingReducer = (state = initialState, action) => {
    switch (action.type) {
        case onboarding_types_1.EReduxOnboardingActionTypes.ONBOARDING_INVIA_NOTIFICA_PUSH_SUCCESS: {
            return {
                ...state,
                esitoInviaNotificaPush: action.payload
            };
        }
        case onboarding_types_1.EReduxOnboardingActionTypes.ONBOARDING_CHECK_SUCCESS: {
            return {
                ...state,
                esitoCheck: action.payload
            };
        }
        case onboarding_types_1.EReduxOnboardingActionTypes.ONBOARDING_UPDATE_SUCCESS: {
            return {
                ...state,
                esitoCheck: action.payload
            };
        }
        case onboarding_types_1.EReduxOnboardingActionTypes.ONBOARDING_SET_UPDATE_NECESSARIO: {
            return {
                ...state,
                updateNecessario: action.payload
            };
        }
        case onboarding_types_1.EReduxOnboardingActionTypes.ONBOARDING_RESET_UPDATE_NECESSARIO: {
            return {
                ...state,
                updateNecessario: initialState.updateNecessario
            };
        }
        case __1.EReduxActionTypes.RESET_ALL: // action invocata dalla logout
            return {
                ...initialState,
                updateNecessario: state.updateNecessario
            };
        default:
            return state;
    }
};
exports.onboardingReducer = onboardingReducer;
