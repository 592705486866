"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EReduxBonificoEsteroActionTypes = exports.FormBonificoEsteroRicercaBICDtoFromJSONTyped = exports.FormBonificoEsteroDtoFromJSONTyped = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const helpers_1 = require("../helpers");
function FormBonificoEsteroDtoFromJSONTyped(json) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'beneficiario': !(0, helpers_1.exists)(json, 'beneficiario') ? undefined : json.beneficiario,
        'beneficiarioDirty': !(0, helpers_1.exists)(json, 'beneficiarioDirty') ? false : json.beneficiarioDirty,
        'beneficiarioError': !(0, helpers_1.exists)(json, 'beneficiarioError') ? 'invalid.required' : json.beneficiarioError,
        'iban': !(0, helpers_1.exists)(json, 'iban') ? undefined : json.iban,
        'ibanDirty': !(0, helpers_1.exists)(json, 'ibanDirty') ? false : json.ibanDirty,
        'ibanError': !(0, helpers_1.exists)(json, 'ibanError') ? 'invalid.required' : json.ibanError,
        'stato': !(0, helpers_1.exists)(json, 'stato') ? undefined : json.stato,
        'statoError': !(0, helpers_1.exists)(json, 'statoError') ? 'invalid.required' : json.statoError,
        'citta': !(0, helpers_1.exists)(json, 'citta') ? undefined : json.citta,
        'cittaDirty': !(0, helpers_1.exists)(json, 'cittaDirty') ? false : json.cittaDirty,
        'cittaError': !(0, helpers_1.exists)(json, 'cittaError') ? 'invalid.required' : json.cittaError,
        'bicSwift': !(0, helpers_1.exists)(json, 'bicSwift') ? undefined : json.bicSwift,
        'bicSwiftDirty': !(0, helpers_1.exists)(json, 'bicSwiftDirty') ? false : json.bicSwiftDirty,
        'bicSwiftError': !(0, helpers_1.exists)(json, 'bicSwiftError') ? 'invalid.required' : json.bicSwiftError,
        'alias': !(0, helpers_1.exists)(json, 'alias') ? undefined : json.alias,
        'aliasDirty': !(0, helpers_1.exists)(json, 'aliasDirty') ? false : json.aliasDirty,
        'aliasError': !(0, helpers_1.exists)(json, 'aliasError') ? 'invalid.required' : json.aliasError,
        'indirizzo': !(0, helpers_1.exists)(json, 'indirizzo') ? undefined : json.indirizzo,
        'indirizzoDirty': !(0, helpers_1.exists)(json, 'indirizzoDirty') ? false : json.indirizzoDirty,
        'indirizzoError': !(0, helpers_1.exists)(json, 'indirizzoError') ? 'invalid.required' : json.indirizzoError,
        'cap': !(0, helpers_1.exists)(json, 'cap') ? undefined : json.cap,
        'capDirty': !(0, helpers_1.exists)(json, 'capDirty') ? false : json.capDirty,
        'capError': !(0, helpers_1.exists)(json, 'capError') ? 'invalid.required' : json.capError,
        'provincia': !(0, helpers_1.exists)(json, 'provincia') ? undefined : json.provincia,
        'provinciaDirty': !(0, helpers_1.exists)(json, 'provinciaDirty') ? false : json.provinciaDirty,
        'provinciaError': !(0, helpers_1.exists)(json, 'provinciaError') ? 'invalid.required' : json.provinciaError,
        'importo': !(0, helpers_1.exists)(json, 'importo') ? undefined : json.importo,
        'importoDirty': !(0, helpers_1.exists)(json, 'importoDirty') ? false : json.importoDirty,
        'importoError': !(0, helpers_1.exists)(json, 'importoError') ? 'invalid.required' : json.importoError,
        'valuta': !(0, helpers_1.exists)(json, 'valuta') ? undefined : json.valuta,
        'valutaError': !(0, helpers_1.exists)(json, 'valutaError') ? 'invalid.required' : json.valutaError,
        'dataEsecuzione': !(0, helpers_1.exists)(json, 'dataEsecuzione') ? new Date() : json.dataEsecuzione,
        'dataEsecuzioneDirty': !(0, helpers_1.exists)(json, 'dataEsecuzioneDirty') ? false : json.dataEsecuzioneDirty,
        'dataEsecuzioneError': !(0, helpers_1.exists)(json, 'dataEsecuzioneError') ? '' : json.dataEsecuzioneError,
        'rapportoSelezionato': !(0, helpers_1.exists)(json, 'rapportoSelezionato') ? (0, api_rest_1.RapportoCompletoDtoFromJSON)({}) : json.rapportoSelezionato,
        'formSubmitted': !(0, helpers_1.exists)(json, 'formSubmitted') ? false : json.formSubmitted,
        'accordionDatiAggiuntiviOpened': !(0, helpers_1.exists)(json, 'accordionDatiAggiuntiviOpened') ? false : json.accordionDatiAggiuntiviOpened,
        'accordionDatiAnagraficiAggiuntiviOpened': !(0, helpers_1.exists)(json, 'accordionDatiAnagraficiAggiuntiviOpened') ? false : json.accordionDatiAnagraficiAggiuntiviOpened,
        'bozzaSalvata': !(0, helpers_1.exists)(json, 'bozzaSalvata') ? false : json.bozzaSalvata,
        'idOneClickCollegata': !(0, helpers_1.exists)(json, 'idOneClickCollegata') ? undefined : json.idOneClickCollegata,
        'modalitaAddebitoSpese': !(0, helpers_1.exists)(json, 'modalitaAddebitoSpese') ? '' : json.modalitaAddebitoSpese,
        'modalitaAddebitoSpeseError': !(0, helpers_1.exists)(json, 'modalitaAddebitoSpeseError') ? 'invalid.required' : json.modalitaAddebitoSpeseError,
        'causale': !(0, helpers_1.exists)(json, 'causale') ? '' : json.causale,
        'causaleDirty': !(0, helpers_1.exists)(json, 'causaleDirty') ? false : json.causaleDirty,
        'causaleError': !(0, helpers_1.exists)(json, 'causaleError') ? 'invalid.required' : json.causaleError,
        'salva1Click': !(0, helpers_1.exists)(json, 'salva1Click') ? undefined : json.salva1Click,
        'alias1Click': !(0, helpers_1.exists)(json, 'alias1Click') ? undefined : json.alias1Click,
        'ordinanteEffettivo': !(0, helpers_1.exists)(json, 'ordinanteEffettivo') ? '' : json.ordinanteEffettivo,
        'ordinanteEffettivoDirty': !(0, helpers_1.exists)(json, 'ordinanteEffettivoDirty') ? false : json.ordinanteEffettivoDirty,
        'ordinanteEffettivoError': !(0, helpers_1.exists)(json, 'ordinanteEffettivoError') ? 'invalid.required' : json.ordinanteEffettivoError,
        'step': !(0, helpers_1.exists)(json, 'step') ? 0 : json.step,
        'idContattoBeneficiario': !(0, helpers_1.exists)(json, 'idContattoBeneficiario') ? null : json.idContattoBeneficiario,
        'paeseDestinazione': !(0, helpers_1.exists)(json, 'paeseDestinazione') ? '' : json.paeseDestinazione,
        'cambioDivisa': !(0, helpers_1.exists)(json, 'cambioDivisa') ? (0, api_rest_1.CambioDivisaDtoFromJSON)({}) : json.cambioDivisa,
        'ripeti': !(0, helpers_1.exists)(json, 'ripeti') ? false : json.ripeti,
        'listLoader': !(0, helpers_1.exists)(json, 'listLoader') ? false : json.listLoader,
        'offset': !(0, helpers_1.exists)(json, 'offset') ? 0 : json.offset,
    };
}
exports.FormBonificoEsteroDtoFromJSONTyped = FormBonificoEsteroDtoFromJSONTyped;
function FormBonificoEsteroRicercaBICDtoFromJSONTyped(json) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nomeBanca': !(0, helpers_1.exists)(json, 'nomeBanca') ? undefined : json.nomeBanca,
        'paeseBic': !(0, helpers_1.exists)(json, 'paeseBic') ? undefined : json.paeseBic,
    };
}
exports.FormBonificoEsteroRicercaBICDtoFromJSONTyped = FormBonificoEsteroRicercaBICDtoFromJSONTyped;
var EReduxBonificoEsteroActionTypes;
(function (EReduxBonificoEsteroActionTypes) {
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_SET_BENEFICIARIO"] = "BONIFICO_ESTERO_SET_BENEFICIARIO";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_SET_IBAN"] = "BONIFICO_ESTERO_SET_IBAN";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_SET_STATO"] = "BONIFICO_ESTERO_SET_STATO";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_SET_CITTA"] = "BONIFICO_ESTERO_SET_CITTA";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_SET_BIC_SWIFT"] = "BONIFICO_ESTERO_SET_BIC_SWIFT";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_SET_ALIAS"] = "BONIFICO_ESTERO_SET_ALIAS";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_SET_INDIRIZZO"] = "BONIFICO_ESTERO_SET_INDIRIZZO";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_SET_CAP"] = "BONIFICO_ESTERO_SET_CAP";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_SET_PROVINCIA"] = "BONIFICO_ESTERO_SET_PROVINCIA";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_SET_IMPORTO"] = "BONIFICO_ESTERO_SET_IMPORTO";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_SET_VALUTA"] = "BONIFICO_ESTERO_SET_VALUTA";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_SET_DATA_ESECUZIONE"] = "BONIFICO_ESTERO_SET_DATA_ESECUZIONE";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_SET_MODALITA_ADDEBITO_SPESE"] = "BONIFICO_ESTERO_SET_MODALITA_ADDEBITO_SPESE";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_SET_CAUSALE"] = "BONIFICO_ESTERO_SET_CAUSALE";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_SET_ORDINANTE_EFFETTIVO"] = "BONIFICO_ESTERO_SET_ORDINANTE_EFFETTIVO";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_ACCORDION_DATI_AGGIUNTIVI_OPENED"] = "BONIFICO_ESTERO_ACCORDION_DATI_AGGIUNTIVI_OPENED";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_ACCORDION_DATI_ANAGRAFICI_AGGIUNTIVI_OPENED"] = "BONIFICO_ESTERO_ACCORDION_DATI_ANAGRAFICI_AGGIUNTIVI_OPENED";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_SET_STEP"] = "BONIFICO_ESTERO_SET_STEP";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_FORM_SUBMITTED"] = "BONIFICO_ESTERO_FORM_SUBMITTED";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_CONTROLLO_START"] = "BONIFICO_ESTERO_CONTROLLO_START";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_CONTROLLO_SUCCESS"] = "BONIFICO_ESTERO_CONTROLLO_SUCCESS";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_GET_PARAMETRI_START"] = "BONIFICO_ESTERO_GET_PARAMETRI_START";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_GET_PARAMETRI_SUCCESS"] = "BONIFICO_ESTERO_GET_PARAMETRI_SUCCESS";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_GET_INFO_DISPOSITIVA_START"] = "BONIFICO_ESTERO_GET_INFO_DISPOSITIVA_START";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_GET_INFO_DISPOSITIVA_SUCCESS"] = "BONIFICO_ESTERO_GET_INFO_DISPOSITIVA_SUCCESS";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_AUTORIZZA_START"] = "BONIFICO_ESTERO_AUTORIZZA_START";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_AUTORIZZA_SUCCESS"] = "BONIFICO_ESTERO_AUTORIZZA_SUCCESS";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_GET_PAESI_BIC_START"] = "BONIFICO_ESTERO_GET_PAESI_BIC_START";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_GET_PAESI_BIC_SUCCESS"] = "BONIFICO_ESTERO_GET_PAESI_BIC_SUCCESS";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_GET_BOZZA_BONIFICO_ESTERO_START"] = "BONIFICO_ESTERO_GET_BOZZA_BONIFICO_ESTERO_START";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_GET_BOZZA_BONIFICO_ESTERO_SUCCESS"] = "BONIFICO_ESTERO_GET_BOZZA_BONIFICO_ESTERO_SUCCESS";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_GET_BOZZE_BONIFICO_ESTERO_START"] = "BONIFICO_ESTERO_GET_BOZZE_BONIFICO_ESTERO_START";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_GET_BOZZE_BONIFICO_ESTERO_SUCCESS"] = "BONIFICO_ESTERO_GET_BOZZE_BONIFICO_ESTERO_SUCCESS";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_ELIMINA_BOZZA_BONIFICO_ESTERO_START"] = "BONIFICO_ESTERO_ELIMINA_BOZZA_BONIFICO_ESTERO_START";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_ELIMINA_BOZZA_BONIFICO_ESTERO_SUCCESS"] = "BONIFICO_ESTERO_ELIMINA_BOZZA_BONIFICO_ESTERO_SUCCESS";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_SALVA_BOZZA_BONIFICO_ESTERO_START"] = "BONIFICO_ESTERO_SALVA_BOZZA_BONIFICO_ESTERO_START";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_SALVA_BOZZA_BONIFICO_ESTERO_SUCCESS"] = "BONIFICO_ESTERO_SALVA_BOZZA_BONIFICO_ESTERO_SUCCESS";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_GET_OPERAZIONI_ONECLICK_BONIFICO_ESTERO_START"] = "BONIFICO_ESTERO_GET_OPERAZIONI_ONECLICK_BONIFICO_ESTERO_START";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_GET_OPERAZIONI_ONECLICK_BONIFICO_ESTERO_SUCCESS"] = "BONIFICO_ESTERO_GET_OPERAZIONI_ONECLICK_BONIFICO_ESTERO_SUCCESS";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_SALVA_OPERAZIONE_ONECLICK_BONIFICO_ESTERO_START"] = "BONIFICO_ESTERO_SALVA_OPERAZIONE_ONECLICK_BONIFICO_ESTERO_START";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_SALVA_OPERAZIONE_ONECLICK_BONIFICO_ESTERO_SUCCESS"] = "BONIFICO_ESTERO_SALVA_OPERAZIONE_ONECLICK_BONIFICO_ESTERO_SUCCESS";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_CERCA_BIC_BONIFICO_ESTERO_START"] = "BONIFICO_ESTERO_CERCA_BIC_BONIFICO_ESTERO_START";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_CERCA_BIC_BONIFICO_ESTERO_SUCCESS"] = "BONIFICO_ESTERO_CERCA_BIC_BONIFICO_ESTERO_SUCCESS";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_AGGIORNA_BOZZA_BONIFICO_ESTERO_START"] = "BONIFICO_ESTERO_AGGIORNA_BOZZA_BONIFICO_ESTERO_START";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_AGGIORNA_BOZZA_BONIFICO_ESTERO_SUCCESS"] = "BONIFICO_ESTERO_AGGIORNA_BOZZA_BONIFICO_ESTERO_SUCCESS";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_GET_BONIFICO_ESTERO_FROM_BOZZA"] = "BONIFICO_ESTERO_GET_BONIFICO_ESTERO_FROM_BOZZA";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_RESET_BONIFICO_ESTERO_INFO_DISPOSITIVA"] = "BONIFICO_ESTERO_RESET_BONIFICO_ESTERO_INFO_DISPOSITIVA";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_RESET_BONIFICO_ESTERO"] = "BONIFICO_ESTERO_RESET_BONIFICO_ESTERO";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_RESET_ESITO_BONIFICO_ESTERO"] = "BONIFICO_ESTERO_RESET_ESITO_BONIFICO_ESTERO";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_SET_RAPPORTO_SELEZIONATO"] = "BONIFICO_ESTERO_SET_RAPPORTO_SELEZIONATO";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_RESET_RIEPILOGO_BONIFICO_ESTERO"] = "BONIFICO_ESTERO_RESET_RIEPILOGO_BONIFICO_ESTERO";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_SET_NOME_BANCA"] = "BONIFICO_ESTERO_SET_NOME_BANCA";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_SET_PAESE_BIC_RICERCA"] = "BONIFICO_ESTERO_SET_PAESE_BIC_RICERCA";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_GET_CAMBIO_DIVISA_START"] = "BONIFICO_ESTERO_GET_CAMBIO_DIVISA_START";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_GET_CAMBIO_DIVISA_SUCCESS"] = "BONIFICO_ESTERO_GET_CAMBIO_DIVISA_SUCCESS";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_RESET_FILTRI_RICERCA_BIC"] = "BONIFICO_ESTERO_RESET_FILTRI_RICERCA_BIC";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_SET_ID_CONTATTO_BENEFICIARIO"] = "BONIFICO_ESTERO_SET_ID_CONTATTO_BENEFICIARIO";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_GET_PARAMETRI_PER_RIPETI_SUCCESS"] = "BONIFICO_ESTERO_GET_PARAMETRI_PER_RIPETI_SUCCESS";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_SET_LIST_LOADER"] = "BONIFICO_ESTERO_SET_LIST_LOADER";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_SET_OFFSET"] = "BONIFICO_ESTERO_SET_OFFSET";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_GET_ELENCO_DIVISE_START"] = "BONIFICO_ESTERO_GET_ELENCO_DIVISE_START";
    EReduxBonificoEsteroActionTypes["BONIFICO_ESTERO_GET_ELENCO_DIVISE_SUCCESS"] = "BONIFICO_ESTERO_GET_ELENCO_DIVISE_SUCCESS";
})(EReduxBonificoEsteroActionTypes = exports.EReduxBonificoEsteroActionTypes || (exports.EReduxBonificoEsteroActionTypes = {}));
