"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdeguataVerificaRapportiDtoToJSON = exports.AdeguataVerificaRapportiDtoFromJSONTyped = exports.AdeguataVerificaRapportiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function AdeguataVerificaRapportiDtoFromJSON(json) {
    return AdeguataVerificaRapportiDtoFromJSONTyped(json, false);
}
exports.AdeguataVerificaRapportiDtoFromJSON = AdeguataVerificaRapportiDtoFromJSON;
function AdeguataVerificaRapportiDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'giorniGracePeriod': !runtime_1.exists(json, 'giorniGracePeriod') ? undefined : json['giorniGracePeriod'],
        'giorniAllaScadenza': !runtime_1.exists(json, 'giorniAllaScadenza') ? undefined : json['giorniAllaScadenza'],
        'disabilitaRapporti': !runtime_1.exists(json, 'disabilitaRapporti') ? undefined : json['disabilitaRapporti'],
        'rapportiADV': !runtime_1.exists(json, 'rapportiADV') ? undefined : (json['rapportiADV'].map(_1.RapportoADVDtoFromJSON)),
    };
}
exports.AdeguataVerificaRapportiDtoFromJSONTyped = AdeguataVerificaRapportiDtoFromJSONTyped;
function AdeguataVerificaRapportiDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'giorniGracePeriod': value.giorniGracePeriod,
        'giorniAllaScadenza': value.giorniAllaScadenza,
        'disabilitaRapporti': value.disabilitaRapporti,
        'rapportiADV': value.rapportiADV === undefined ? undefined : (value.rapportiADV.map(_1.RapportoADVDtoToJSON)),
    };
}
exports.AdeguataVerificaRapportiDtoToJSON = AdeguataVerificaRapportiDtoToJSON;
