"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TelefonoFissoDtoToJSON = exports.TelefonoFissoDtoFromJSONTyped = exports.TelefonoFissoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function TelefonoFissoDtoFromJSON(json) {
    return TelefonoFissoDtoFromJSONTyped(json, false);
}
exports.TelefonoFissoDtoFromJSON = TelefonoFissoDtoFromJSON;
function TelefonoFissoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'prefisso': !runtime_1.exists(json, 'prefisso') ? undefined : json['prefisso'],
        'numero': !runtime_1.exists(json, 'numero') ? undefined : json['numero'],
    };
}
exports.TelefonoFissoDtoFromJSONTyped = TelefonoFissoDtoFromJSONTyped;
function TelefonoFissoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'prefisso': value.prefisso,
        'numero': value.numero,
    };
}
exports.TelefonoFissoDtoToJSON = TelefonoFissoDtoToJSON;
