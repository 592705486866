"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParametriAcquistoNexiDebitResponseDtoConsensoListEnum = exports.ParametriAcquistoNexiDebitResponseDtoToJSON = exports.ParametriAcquistoNexiDebitResponseDtoFromJSONTyped = exports.ParametriAcquistoNexiDebitResponseDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ParametriAcquistoNexiDebitResponseDtoFromJSON(json) {
    return ParametriAcquistoNexiDebitResponseDtoFromJSONTyped(json, false);
}
exports.ParametriAcquistoNexiDebitResponseDtoFromJSON = ParametriAcquistoNexiDebitResponseDtoFromJSON;
function ParametriAcquistoNexiDebitResponseDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'rapporti': !runtime_1.exists(json, 'rapporti') ? undefined : (json['rapporti'].map(_1.RapportoCompletoDtoFromJSON)),
        'parametri': !runtime_1.exists(json, 'parametri') ? undefined : json['parametri'],
        'ultimoAcquisto': !runtime_1.exists(json, 'ultimoAcquisto') ? undefined : _1.DatiCartaAcquistoDtoFromJSON(json['ultimoAcquisto']),
        'nomeUtente': !runtime_1.exists(json, 'nomeUtente') ? undefined : json['nomeUtente'],
        'contoAppoggio': !runtime_1.exists(json, 'contoAppoggio') ? undefined : (json['contoAppoggio'].map(_1.RapportoDtoFromJSON)),
        'codFiscale': !runtime_1.exists(json, 'codFiscale') ? undefined : json['codFiscale'],
        'professione': !runtime_1.exists(json, 'professione') ? undefined : json['professione'],
        'tae': !runtime_1.exists(json, 'tae') ? undefined : json['tae'],
        'personaPoliticamenteEsposta': !runtime_1.exists(json, 'personaPoliticamenteEsposta') ? undefined : json['personaPoliticamenteEsposta'],
        'cartaCondizioniList': !runtime_1.exists(json, 'cartaCondizioniList') ? undefined : (json['cartaCondizioniList'].map(_1.CartaCondizioniDtoFromJSON)),
        'residenza': !runtime_1.exists(json, 'residenza') ? undefined : _1.IndirizzoDatiAnagraficiDtoFromJSON(json['residenza']),
        'domicilio': !runtime_1.exists(json, 'domicilio') ? undefined : _1.IndirizzoDatiAnagraficiDtoFromJSON(json['domicilio']),
        'professioniList': !runtime_1.exists(json, 'professioniList') ? undefined : (json['professioniList'].map(_1.ChiaveValoreDtoFromJSON)),
        'taeList': !runtime_1.exists(json, 'taeList') ? undefined : (json['taeList'].map(_1.ChiaveValoreDtoFromJSON)),
        'provenienzaFondiList': !runtime_1.exists(json, 'provenienzaFondiList') ? undefined : (json['provenienzaFondiList'].map(_1.ProvenienzaFondiDtoFromJSON)),
        'scopoPrevalenteList': !runtime_1.exists(json, 'scopoPrevalenteList') ? undefined : (json['scopoPrevalenteList'].map(_1.ChiaveValoreDtoFromJSON)),
        'hrefFoglioInformativo': !runtime_1.exists(json, 'hrefFoglioInformativo') ? undefined : json['hrefFoglioInformativo'],
        'hrefInformativaFirma': !runtime_1.exists(json, 'hrefInformativaFirma') ? undefined : json['hrefInformativaFirma'],
        'consensoList': !runtime_1.exists(json, 'consensoList') ? undefined : json['consensoList'],
        'newAcquisto': !runtime_1.exists(json, 'newAcquisto') ? undefined : json['newAcquisto'],
    };
}
exports.ParametriAcquistoNexiDebitResponseDtoFromJSONTyped = ParametriAcquistoNexiDebitResponseDtoFromJSONTyped;
function ParametriAcquistoNexiDebitResponseDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'rapporti': value.rapporti === undefined ? undefined : (value.rapporti.map(_1.RapportoCompletoDtoToJSON)),
        'parametri': value.parametri,
        'ultimoAcquisto': _1.DatiCartaAcquistoDtoToJSON(value.ultimoAcquisto),
        'nomeUtente': value.nomeUtente,
        'contoAppoggio': value.contoAppoggio === undefined ? undefined : (value.contoAppoggio.map(_1.RapportoDtoToJSON)),
        'codFiscale': value.codFiscale,
        'professione': value.professione,
        'tae': value.tae,
        'personaPoliticamenteEsposta': value.personaPoliticamenteEsposta,
        'cartaCondizioniList': value.cartaCondizioniList === undefined ? undefined : (value.cartaCondizioniList.map(_1.CartaCondizioniDtoToJSON)),
        'residenza': _1.IndirizzoDatiAnagraficiDtoToJSON(value.residenza),
        'domicilio': _1.IndirizzoDatiAnagraficiDtoToJSON(value.domicilio),
        'professioniList': value.professioniList === undefined ? undefined : (value.professioniList.map(_1.ChiaveValoreDtoToJSON)),
        'taeList': value.taeList === undefined ? undefined : (value.taeList.map(_1.ChiaveValoreDtoToJSON)),
        'provenienzaFondiList': value.provenienzaFondiList === undefined ? undefined : (value.provenienzaFondiList.map(_1.ProvenienzaFondiDtoToJSON)),
        'scopoPrevalenteList': value.scopoPrevalenteList === undefined ? undefined : (value.scopoPrevalenteList.map(_1.ChiaveValoreDtoToJSON)),
        'hrefFoglioInformativo': value.hrefFoglioInformativo,
        'hrefInformativaFirma': value.hrefInformativaFirma,
        'consensoList': value.consensoList,
        'newAcquisto': value.newAcquisto,
    };
}
exports.ParametriAcquistoNexiDebitResponseDtoToJSON = ParametriAcquistoNexiDebitResponseDtoToJSON;
/**
* @export
* @enum {string}
*/
var ParametriAcquistoNexiDebitResponseDtoConsensoListEnum;
(function (ParametriAcquistoNexiDebitResponseDtoConsensoListEnum) {
    ParametriAcquistoNexiDebitResponseDtoConsensoListEnum["COMUNICAZIONE"] = "COMUNICAZIONE";
    ParametriAcquistoNexiDebitResponseDtoConsensoListEnum["OFFERTE"] = "OFFERTE";
    ParametriAcquistoNexiDebitResponseDtoConsensoListEnum["MIGLIORIE"] = "MIGLIORIE";
    ParametriAcquistoNexiDebitResponseDtoConsensoListEnum["DATI"] = "DATI";
})(ParametriAcquistoNexiDebitResponseDtoConsensoListEnum = exports.ParametriAcquistoNexiDebitResponseDtoConsensoListEnum || (exports.ParametriAcquistoNexiDebitResponseDtoConsensoListEnum = {}));
