"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EsitoAutorizzazioneDisposizioneSudtirolPassDtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneSudtirolPassDtoToJSON = exports.EsitoAutorizzazioneDisposizioneSudtirolPassDtoFromJSONTyped = exports.EsitoAutorizzazioneDisposizioneSudtirolPassDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function EsitoAutorizzazioneDisposizioneSudtirolPassDtoFromJSON(json) {
    return EsitoAutorizzazioneDisposizioneSudtirolPassDtoFromJSONTyped(json, false);
}
exports.EsitoAutorizzazioneDisposizioneSudtirolPassDtoFromJSON = EsitoAutorizzazioneDisposizioneSudtirolPassDtoFromJSON;
function EsitoAutorizzazioneDisposizioneSudtirolPassDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
        'codiceErrore': !runtime_1.exists(json, 'codiceErrore') ? undefined : json['codiceErrore'],
        'informazioniAutenticazioneDispositiva': !runtime_1.exists(json, 'informazioniAutenticazioneDispositiva') ? undefined : _1.InformazioniAutenticazioneDispositivaDtoFromJSON(json['informazioniAutenticazioneDispositiva']),
        'disposizione': !runtime_1.exists(json, 'disposizione') ? undefined : _1.RicaricaSudtirolPassDtoFromJSON(json['disposizione']),
    };
}
exports.EsitoAutorizzazioneDisposizioneSudtirolPassDtoFromJSONTyped = EsitoAutorizzazioneDisposizioneSudtirolPassDtoFromJSONTyped;
function EsitoAutorizzazioneDisposizioneSudtirolPassDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'stato': value.stato,
        'codiceErrore': value.codiceErrore,
        'informazioniAutenticazioneDispositiva': _1.InformazioniAutenticazioneDispositivaDtoToJSON(value.informazioniAutenticazioneDispositiva),
        'disposizione': _1.RicaricaSudtirolPassDtoToJSON(value.disposizione),
    };
}
exports.EsitoAutorizzazioneDisposizioneSudtirolPassDtoToJSON = EsitoAutorizzazioneDisposizioneSudtirolPassDtoToJSON;
/**
* @export
* @enum {string}
*/
var EsitoAutorizzazioneDisposizioneSudtirolPassDtoStatoEnum;
(function (EsitoAutorizzazioneDisposizioneSudtirolPassDtoStatoEnum) {
    EsitoAutorizzazioneDisposizioneSudtirolPassDtoStatoEnum["OK"] = "OK";
    EsitoAutorizzazioneDisposizioneSudtirolPassDtoStatoEnum["CREDENZIALE_ERRATA"] = "CREDENZIALE_ERRATA";
    EsitoAutorizzazioneDisposizioneSudtirolPassDtoStatoEnum["ATTENDERE"] = "ATTENDERE";
    EsitoAutorizzazioneDisposizioneSudtirolPassDtoStatoEnum["ACCOUNT_BLOCCATO"] = "ACCOUNT_BLOCCATO";
    EsitoAutorizzazioneDisposizioneSudtirolPassDtoStatoEnum["ACCP"] = "ACCP";
    EsitoAutorizzazioneDisposizioneSudtirolPassDtoStatoEnum["ACSC"] = "ACSC";
    EsitoAutorizzazioneDisposizioneSudtirolPassDtoStatoEnum["ACSP"] = "ACSP";
    EsitoAutorizzazioneDisposizioneSudtirolPassDtoStatoEnum["ACTC"] = "ACTC";
    EsitoAutorizzazioneDisposizioneSudtirolPassDtoStatoEnum["ACWC"] = "ACWC";
    EsitoAutorizzazioneDisposizioneSudtirolPassDtoStatoEnum["ACWP"] = "ACWP";
    EsitoAutorizzazioneDisposizioneSudtirolPassDtoStatoEnum["RCVD"] = "RCVD";
    EsitoAutorizzazioneDisposizioneSudtirolPassDtoStatoEnum["PDNG"] = "PDNG";
    EsitoAutorizzazioneDisposizioneSudtirolPassDtoStatoEnum["RJCT"] = "RJCT";
    EsitoAutorizzazioneDisposizioneSudtirolPassDtoStatoEnum["CANC"] = "CANC";
})(EsitoAutorizzazioneDisposizioneSudtirolPassDtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneSudtirolPassDtoStatoEnum || (exports.EsitoAutorizzazioneDisposizioneSudtirolPassDtoStatoEnum = {}));
