"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RicaricaSudtirolPassDtoToJSON = exports.RicaricaSudtirolPassDtoFromJSONTyped = exports.RicaricaSudtirolPassDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function RicaricaSudtirolPassDtoFromJSON(json) {
    return RicaricaSudtirolPassDtoFromJSONTyped(json, false);
}
exports.RicaricaSudtirolPassDtoFromJSON = RicaricaSudtirolPassDtoFromJSON;
function RicaricaSudtirolPassDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'idDisposizione': !runtime_1.exists(json, 'idDisposizione') ? undefined : json['idDisposizione'],
        'transactionId': !runtime_1.exists(json, 'transactionId') ? undefined : json['transactionId'],
        'commissioneSuOperazione': !runtime_1.exists(json, 'commissioneSuOperazione') ? undefined : json['commissioneSuOperazione'],
        'importoUtilizzabile': !runtime_1.exists(json, 'importoUtilizzabile') ? undefined : json['importoUtilizzabile'],
        'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
        'minAmount': !runtime_1.exists(json, 'minAmount') ? undefined : json['minAmount'],
        'maxAmount': !runtime_1.exists(json, 'maxAmount') ? undefined : json['maxAmount'],
        'descrizione': !runtime_1.exists(json, 'descrizione') ? undefined : json['descrizione'],
        'saldo': !runtime_1.exists(json, 'saldo') ? undefined : json['saldo'],
        'attivazioneAbilitata': !runtime_1.exists(json, 'attivazioneAbilitata') ? undefined : json['attivazioneAbilitata'],
        'rinnovoAbilitato': !runtime_1.exists(json, 'rinnovoAbilitato') ? undefined : json['rinnovoAbilitato'],
        'ricaricaAbilitata': !runtime_1.exists(json, 'ricaricaAbilitata') ? undefined : json['ricaricaAbilitata'],
        'scadenzaRinnovo': !runtime_1.exists(json, 'scadenzaRinnovo') ? undefined : (new Date(json['scadenzaRinnovo'])),
        'importoRinnovo': !runtime_1.exists(json, 'importoRinnovo') ? undefined : json['importoRinnovo'],
        'registrationTypeRinnovo': !runtime_1.exists(json, 'registrationTypeRinnovo') ? undefined : json['registrationTypeRinnovo'],
        'reasonRinnovo': !runtime_1.exists(json, 'reasonRinnovo') ? undefined : json['reasonRinnovo'],
        'scadenzaAttivazione': !runtime_1.exists(json, 'scadenzaAttivazione') ? undefined : (new Date(json['scadenzaAttivazione'])),
        'importoAttivazione': !runtime_1.exists(json, 'importoAttivazione') ? undefined : json['importoAttivazione'],
        'registrationTypeAttivazione': !runtime_1.exists(json, 'registrationTypeAttivazione') ? undefined : json['registrationTypeAttivazione'],
        'reasonAttivazione': !runtime_1.exists(json, 'reasonAttivazione') ? undefined : json['reasonAttivazione'],
        'reason': !runtime_1.exists(json, 'reason') ? undefined : json['reason'],
        'tagliRicarica': !runtime_1.exists(json, 'tagliRicarica') ? undefined : (json['tagliRicarica'].map(_1.TaglioRicaricaDtoFromJSON)),
        'importoRicarica': !runtime_1.exists(json, 'importoRicarica') ? undefined : json['importoRicarica'],
        'pan': !runtime_1.exists(json, 'pan') ? undefined : json['pan'],
        'codiceRapporto': !runtime_1.exists(json, 'codiceRapporto') ? undefined : json['codiceRapporto'],
        'tipoOperazione': !runtime_1.exists(json, 'tipoOperazione') ? undefined : json['tipoOperazione'],
        'idContattoBeneficiario': !runtime_1.exists(json, 'idContattoBeneficiario') ? undefined : json['idContattoBeneficiario'],
        'aggiungiRubrica': !runtime_1.exists(json, 'aggiungiRubrica') ? undefined : json['aggiungiRubrica'],
        'aggiungiWhitelist': !runtime_1.exists(json, 'aggiungiWhitelist') ? undefined : json['aggiungiWhitelist'],
        'aggiungi1click': !runtime_1.exists(json, 'aggiungi1click') ? undefined : json['aggiungi1click'],
        'contoAddebitoIban': !runtime_1.exists(json, 'contoAddebitoIban') ? undefined : json['contoAddebitoIban'],
        'contoAddebitoAlias': !runtime_1.exists(json, 'contoAddebitoAlias') ? undefined : json['contoAddebitoAlias'],
        'ordinanteIntestazione': !runtime_1.exists(json, 'ordinanteIntestazione') ? undefined : json['ordinanteIntestazione'],
        'importoTotale': !runtime_1.exists(json, 'importoTotale') ? undefined : json['importoTotale'],
        'dataOraInserimento': !runtime_1.exists(json, 'dataOraInserimento') ? undefined : (new Date(json['dataOraInserimento'])),
        'dataContabile': !runtime_1.exists(json, 'dataContabile') ? undefined : (new Date(json['dataContabile'])),
        'alias1Click': !runtime_1.exists(json, 'alias1Click') ? undefined : json['alias1Click'],
        'idOneClickCollegata': !runtime_1.exists(json, 'idOneClickCollegata') ? undefined : json['idOneClickCollegata'],
        'nomeBeneficiario': !runtime_1.exists(json, 'nomeBeneficiario') ? undefined : json['nomeBeneficiario'],
    };
}
exports.RicaricaSudtirolPassDtoFromJSONTyped = RicaricaSudtirolPassDtoFromJSONTyped;
function RicaricaSudtirolPassDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'idDisposizione': value.idDisposizione,
        'transactionId': value.transactionId,
        'commissioneSuOperazione': value.commissioneSuOperazione,
        'importoUtilizzabile': value.importoUtilizzabile,
        'stato': value.stato,
        'minAmount': value.minAmount,
        'maxAmount': value.maxAmount,
        'descrizione': value.descrizione,
        'saldo': value.saldo,
        'attivazioneAbilitata': value.attivazioneAbilitata,
        'rinnovoAbilitato': value.rinnovoAbilitato,
        'ricaricaAbilitata': value.ricaricaAbilitata,
        'scadenzaRinnovo': value.scadenzaRinnovo === undefined ? undefined : (value.scadenzaRinnovo.toISOString()),
        'importoRinnovo': value.importoRinnovo,
        'registrationTypeRinnovo': value.registrationTypeRinnovo,
        'reasonRinnovo': value.reasonRinnovo,
        'scadenzaAttivazione': value.scadenzaAttivazione === undefined ? undefined : (value.scadenzaAttivazione.toISOString()),
        'importoAttivazione': value.importoAttivazione,
        'registrationTypeAttivazione': value.registrationTypeAttivazione,
        'reasonAttivazione': value.reasonAttivazione,
        'reason': value.reason,
        'tagliRicarica': value.tagliRicarica === undefined ? undefined : (value.tagliRicarica.map(_1.TaglioRicaricaDtoToJSON)),
        'importoRicarica': value.importoRicarica,
        'pan': value.pan,
        'codiceRapporto': value.codiceRapporto,
        'tipoOperazione': value.tipoOperazione,
        'idContattoBeneficiario': value.idContattoBeneficiario,
        'aggiungiRubrica': value.aggiungiRubrica,
        'aggiungiWhitelist': value.aggiungiWhitelist,
        'aggiungi1click': value.aggiungi1click,
        'contoAddebitoIban': value.contoAddebitoIban,
        'contoAddebitoAlias': value.contoAddebitoAlias,
        'ordinanteIntestazione': value.ordinanteIntestazione,
        'importoTotale': value.importoTotale,
        'dataOraInserimento': value.dataOraInserimento === undefined ? undefined : (value.dataOraInserimento.toISOString()),
        'dataContabile': value.dataContabile === undefined ? undefined : (value.dataContabile.toISOString()),
        'alias1Click': value.alias1Click,
        'idOneClickCollegata': value.idOneClickCollegata,
        'nomeBeneficiario': value.nomeBeneficiario,
    };
}
exports.RicaricaSudtirolPassDtoToJSON = RicaricaSudtirolPassDtoToJSON;
