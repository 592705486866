"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CercaDocumentiInDtoToJSON = exports.CercaDocumentiInDtoFromJSONTyped = exports.CercaDocumentiInDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function CercaDocumentiInDtoFromJSON(json) {
    return CercaDocumentiInDtoFromJSONTyped(json, false);
}
exports.CercaDocumentiInDtoFromJSON = CercaDocumentiInDtoFromJSON;
function CercaDocumentiInDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'dataIniziale': !runtime_1.exists(json, 'dataIniziale') ? undefined : (new Date(json['dataIniziale'])),
        'dataFinale': !runtime_1.exists(json, 'dataFinale') ? undefined : (new Date(json['dataFinale'])),
        'codiceRapporto': !runtime_1.exists(json, 'codiceRapporto') ? undefined : json['codiceRapporto'],
        'tipologieComunicazione': !runtime_1.exists(json, 'tipologieComunicazione') ? undefined : json['tipologieComunicazione'],
    };
}
exports.CercaDocumentiInDtoFromJSONTyped = CercaDocumentiInDtoFromJSONTyped;
function CercaDocumentiInDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'dataIniziale': value.dataIniziale === undefined ? undefined : (value.dataIniziale.toISOString()),
        'dataFinale': value.dataFinale === undefined ? undefined : (value.dataFinale.toISOString()),
        'codiceRapporto': value.codiceRapporto,
        'tipologieComunicazione': value.tipologieComunicazione,
    };
}
exports.CercaDocumentiInDtoToJSON = CercaDocumentiInDtoToJSON;
