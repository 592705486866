"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SessioniDiFirmaDtoToJSON = exports.SessioniDiFirmaDtoFromJSONTyped = exports.SessioniDiFirmaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function SessioniDiFirmaDtoFromJSON(json) {
    return SessioniDiFirmaDtoFromJSONTyped(json, false);
}
exports.SessioniDiFirmaDtoFromJSON = SessioniDiFirmaDtoFromJSON;
function SessioniDiFirmaDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'jobIds': !runtime_1.exists(json, 'jobIds') ? undefined : json['jobIds'],
        'blockSize': !runtime_1.exists(json, 'blockSize') ? undefined : json['blockSize'],
        'filteredSessionCount': !runtime_1.exists(json, 'filteredSessionCount') ? undefined : json['filteredSessionCount'],
        'jobs': !runtime_1.exists(json, 'jobs') ? undefined : (json['jobs'].map(_1.JobDtoFromJSON)),
    };
}
exports.SessioniDiFirmaDtoFromJSONTyped = SessioniDiFirmaDtoFromJSONTyped;
function SessioniDiFirmaDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'jobIds': value.jobIds,
        'blockSize': value.blockSize,
        'filteredSessionCount': value.filteredSessionCount,
        'jobs': value.jobs === undefined ? undefined : (value.jobs.map(_1.JobDtoToJSON)),
    };
}
exports.SessioniDiFirmaDtoToJSON = SessioniDiFirmaDtoToJSON;
