"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfessioneControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class ProfessioneControllerApi extends runtime.BaseAPI {
    /**
     * Restituisce lista cae e tae associati alla professione
     */
    recuperaDatiProfessioneByCodiceProfessioneRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.codiceProfessione === null || requestParameters.codiceProfessione === undefined) {
                throw new runtime.RequiredError('codiceProfessione', 'Required parameter requestParameters.codiceProfessione was null or undefined when calling recuperaDatiProfessioneByCodiceProfessione.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/professione/listeaggiuntive/{codiceProfessione}`.replace(`{${"codiceProfessione"}}`, encodeURIComponent(String(requestParameters.codiceProfessione))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.DatiProfessioneDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce lista cae e tae associati alla professione
     */
    recuperaDatiProfessioneByCodiceProfessione(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.recuperaDatiProfessioneByCodiceProfessioneRaw(requestParameters);
            return yield response.value();
        });
    }
}
exports.ProfessioneControllerApi = ProfessioneControllerApi;
