"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bonificoSepaReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const moment = require("moment");
const __1 = require("..");
const helpers_1 = require("../helpers");
const bonifico_types_1 = require("./bonifico.types");
const initialState = {
    parametri: (0, api_rest_1.ParametriBonificoDtoFromJSON)({}),
    riepilogo: (0, api_rest_1.BonificoSepaDtoFromJSON)({}),
    infoDispositiva: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
    esito: (0, api_rest_1.EsitoAutorizzazioneDisposizioneBonificoDtoFromJSON)({}),
    oneclick: (0, api_rest_1.OperazioniOneClickDtoFromJSON)({}),
    form: (0, bonifico_types_1.FormBonificoDtoFromJSONTyped)({}),
    riepilogoRevoca: (0, api_rest_1.BonificoSepaDtoFromJSON)({}),
    formRevoca: (0, bonifico_types_1.FormRevocaBonificoDtoFromJSON)({}),
    infoRevoca: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
    esitoRevoca: (0, api_rest_1.EsitoAutorizzazioneDisposizioneBonificoDtoFromJSON)({}),
    bozzeBonifico: (0, api_rest_1.BonificiSepaDtoFromJSON)({}),
    currentBozza: (0, api_rest_1.BonificoSepaDtoFromJSON)({}),
    webViewLinkControllo: undefined,
    webViewLinkAutorizza: undefined,
    massimaleFirma: undefined
};
let rapportoSelezionato;
const bonificoSepaReducer = (state = initialState, action) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    switch (action.type) {
        case __1.EReduxActionTypes.BONIFICO_SEPA_GET_PARAMETRI_SUCCESS:
            return {
                ...state,
                parametri: action.payload
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_GET_PARAMETRI_PER_RIPETI_SUCCESS:
            return {
                ...initialState,
                parametri: action.payload,
                form: {
                    ...state.form,
                    addRubrica: false,
                    addWhiteList: false,
                    salva1Click: false,
                    alias1Click: '',
                    ripeti: true
                }
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_CONTROLLO_START:
            return {
                ...state,
                webViewLinkControllo: initialState.webViewLinkControllo
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_CONTROLLO_SUCCESS:
            return {
                ...state,
                riepilogo: action.payload
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_GET_INFO_DISPOSITIVA_SUCCESS:
            return {
                ...state,
                infoDispositiva: action.payload,
                esito: initialState.esito
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_AUTORIZZA_SUCCESS:
            if (action.payload.stato === 'OK') {
                return {
                    ...state,
                    //riepilogo: initialState.riepilogo,
                    esito: action.payload
                };
            }
            else {
                return {
                    ...state,
                    infoDispositiva: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoDispositiva, action.payload.informazioniAutenticazioneDispositiva),
                    esito: action.payload
                };
            }
        case __1.EReduxActionTypes.BONIFICO_SEPA_BENEFICIARIO:
            return {
                ...state,
                form: {
                    ...state.form,
                    beneficiario: action.payload,
                    beneficiarioDirty: true,
                    beneficiarioError: (0, helpers_1.validationField)(action.payload, '', false, 3),
                    addRubrica: false,
                    addWhiteList: false,
                    idContattoBeneficiario: undefined,
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_IBAN:
            return {
                ...state,
                form: {
                    ...state.form,
                    iban: action.payload,
                    ibanDirty: true,
                    ibanError: (0, helpers_1.validationField)(action.payload, '', true),
                    addRubrica: false,
                    addWhiteList: false,
                    idContattoBeneficiario: undefined,
                    bozzaSalvata: false,
                    istantaneo: false,
                }
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_ADD_RUBRICA:
            return {
                ...state,
                form: {
                    ...state.form,
                    addRubrica: action.payload,
                    addWhiteList: !action.payload && false
                }
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_ADD_WHITELIST:
            return {
                ...state,
                form: {
                    ...state.form,
                    addWhiteList: action.payload
                }
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_SET_ID_CONTATTO_BENEFICIARIO:
            return {
                ...state,
                form: {
                    ...state.form,
                    idContattoBeneficiario: action.payload
                }
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_IMPORTO:
            return {
                ...state,
                form: {
                    ...state.form,
                    importo: action.payload,
                    importoDirty: true,
                    importoError: (0, helpers_1.validationField)(action.payload, 0),
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_CF_PI_ORDINANTI:
            let ordinanti = state.form.codiceFiscalePIVAOrdinanti.map((cp, index) => ({
                cfpi: cp,
                error: state.form.codiceFiscalePIVAOrdinantiError[index]
            }));
            if (ordinanti.length <= action.index) {
                ordinanti.push({
                    cfpi: action.payload || '',
                    error: (0, helpers_1.validationCFPI)(true, action.payload)
                });
            }
            else if (action.payload !== undefined && action.payload !== null) {
                ordinanti[action.index].cfpi = action.payload;
                ordinanti[action.index].error = (0, helpers_1.validationCFPI)(true, action.payload);
            }
            else if (ordinanti.length > 1) {
                ordinanti.splice(action.index, 1);
            }
            return {
                // eliminare codiceFiscalePIVAOrdinantiDirty quando eliminato da mobile perche' inutile
                ...state,
                form: {
                    ...state.form,
                    codiceFiscalePIVAOrdinanti: ordinanti.map(o => o.cfpi),
                    codiceFiscalePIVAOrdinantiDirty: state.form.codiceFiscalePIVAOrdinantiDirty,
                    codiceFiscalePIVAOrdinantiArrayDirty: ordinanti.map((o, i) => !!o.cfpi ||
                        (state.form.codiceFiscalePIVAOrdinantiArrayDirty.length >= i + 1 && state.form.codiceFiscalePIVAOrdinantiArrayDirty[i]) ||
                        false),
                    codiceFiscalePIVAOrdinantiError: ordinanti.map(o => o.error),
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_CF_PI_BENEFICIARIO:
            return {
                ...state,
                form: {
                    ...state.form,
                    codiceFiscalePIVABeneficiario: action.payload,
                    codiceFiscalePIVABeneficiarioDirty: true,
                    codiceFiscalePIVABeneficiarioError: (0, helpers_1.validationCFPI)(true, action.payload),
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_CF_PI_AMMINISTRATORE:
            return {
                ...state,
                form: {
                    ...state.form,
                    codiceFiscalePIVAAmministratore: action.payload,
                    codiceFiscalePIVAAmministratoreDirty: true,
                    codiceFiscalePIVAAmministratoreError: (0, helpers_1.validationCFPI)(false, action.payload),
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_MOTIVAZIONE_PAGAMENTO:
            return {
                ...state,
                form: {
                    ...state.form,
                    motivazionePagamento: action.payload,
                    motivazionePagamentoDirty: true,
                    motivazionePagamentoError: (0, helpers_1.validationField)(action.payload, ''),
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_DATA_ESEC:
            if (state.form.istantaneo) {
                return {
                    ...state,
                    form: {
                        ...state.form,
                        dataEsecuzione: action.payload,
                        dataEsecuzioneDirty: true,
                        dataEsecuzioneError: (0, helpers_1.validationFieldDate)(action.payload, [], false)
                    }
                };
            }
            else {
                return {
                    ...state,
                    form: {
                        ...state.form,
                        dataEsecuzione: action.payload,
                        dataEsecuzioneDirty: true,
                        dataEsecuzioneError: (0, helpers_1.validationFieldDate)(action.payload, state.parametri && state.parametri.parametri ? state.parametri.parametri['BONIFICOSEPA.FESTIVI'] : [], true, ((_b = (_a = state.parametri) === null || _a === void 0 ? void 0 : _a.parametri) === null || _b === void 0 ? void 0 : _b['BONIFICOSEPA.PRIMADATAUTILE']) ? state.parametri.parametri['BONIFICOSEPA.PRIMADATAUTILE'].toString() : moment().format('YYYY-MM-DD'))
                    }
                };
            }
        case __1.EReduxActionTypes.BONIFICO_SEPA_DESCRIZIONE:
            return {
                ...state,
                form: {
                    ...state.form,
                    descrizione: action.payload,
                    descrizioneDirty: true,
                    descrizioneError: (0, helpers_1.validationField)(action.payload, '', false, 1, 140),
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_SAVE_1CLICK:
            return {
                ...state,
                form: {
                    ...state.form,
                    salva1Click: action.payload,
                    alias1Click: action.payload ? state.form.descrizione : '',
                    aliasDirty: false,
                    aliasError: action.payload
                        ? (0, helpers_1.validationField)(state.form.descrizione, '')
                        : ''
                }
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_ALIAS_1CLICK:
            return {
                ...state,
                form: {
                    ...state.form,
                    alias1Click: action.payload,
                    aliasDirty: true,
                    aliasError: (0, helpers_1.validationField)(action.payload, '')
                }
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_ORDINANTE_EFF:
            return {
                ...state,
                form: {
                    ...state.form,
                    ordinanteEffettivo: action.payload
                }
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_STEP:
            return {
                ...state,
                form: {
                    ...state.form,
                    step: action.payload
                }
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_INTESTATARIO:
            return {
                ...state,
                form: {
                    ...state.form,
                    intestatario: action.payload
                }
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_IBAN_INT:
            return {
                ...state,
                form: {
                    ...state.form,
                    IBANInt: action.payload
                }
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_SET_RAPPORTO_SELEZIONATO:
            return {
                ...state,
                form: {
                    ...state.form,
                    rapportoSelezionato: action.payload,
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.BONIFICO_ACCORDION_DATI_AGGIUNTIVI_OPENED:
            return {
                ...state,
                form: {
                    ...state.form,
                    accordionDatiAggiuntiviOpened: action.payload
                }
            };
        case __1.EReduxActionTypes.BONIFICO_FORM_SUBMITTED:
            return {
                ...state,
                form: {
                    ...state.form,
                    formSubmitted: action.payload
                }
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_RESET:
            return {
                ...initialState
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_GET_ONECLICK_SUCCESS:
            return {
                ...state,
                oneclick: action.payload
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_SET_RIEPILOGO_REVOCA:
            return {
                ...state,
                formRevoca: initialState.formRevoca,
                riepilogoRevoca: action.payload
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_SET_PROVENIENZA:
            return {
                ...state,
                formRevoca: {
                    ...state.formRevoca,
                    provenienza: action.payload
                }
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_STEP_REVOCA:
            return {
                ...state,
                formRevoca: {
                    ...state.formRevoca,
                    step: action.payload
                }
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_GET_INFO_REVOCA_SUCCESS:
            return {
                ...state,
                infoRevoca: action.payload,
                esitoRevoca: initialState.esito
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_AUTORIZZA_REVOCA_SUCCESS:
            if (action.payload.stato === 'OK') {
                return {
                    ...state,
                    esitoRevoca: action.payload
                };
            }
            else {
                return {
                    ...state,
                    parametri: initialState.parametri,
                    riepilogo: initialState.riepilogo,
                    esito: initialState.esito,
                    form: initialState.form,
                    riepilogoRevoca: initialState.riepilogoRevoca,
                    infoRevoca: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoDispositiva, action.payload.informazioniAutenticazioneDispositiva),
                    esitoRevoca: action.payload
                };
            }
        case __1.EReduxActionTypes.BONIFICO_SEPA_RESET_REVOCA:
            return {
                ...state,
                riepilogoRevoca: initialState.riepilogoRevoca,
                formRevoca: initialState.formRevoca,
                infoRevoca: initialState.infoRevoca,
                esitoRevoca: initialState.esitoRevoca
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_RESET_BOZZE:
            return {
                ...state,
                bozzeBonifico: initialState.bozzeBonifico
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_GET_BOZZE_SUCCESS:
            return {
                ...state,
                bozzeBonifico: action.payload
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_GET_BOZZA_SUCCESS: {
            rapportoSelezionato = (0, helpers_1.findInArrayRapporti)(state.parametri && state.parametri.rapporti, action.payload.contoAddebito);
            const data = (action.payload.dataEsecuzione && moment(action.payload.dataEsecuzione) >= moment((_d = (_c = state.parametri) === null || _c === void 0 ? void 0 : _c.parametri) === null || _d === void 0 ? void 0 : _d['BONIFICOSEPA.PRIMADATAUTILE'], 'YYYY-MM-DD'))
                ? moment(action.payload.dataEsecuzione).format('YYYY-MM-DD')
                : moment((_f = (_e = state.parametri) === null || _e === void 0 ? void 0 : _e.parametri) === null || _f === void 0 ? void 0 : _f['BONIFICOSEPA.PRIMADATAUTILE'], 'YYYY-MM-DD').format('YYYY-MM-DD');
            return {
                ...state,
                form: {
                    ...state.form,
                    beneficiario: action.payload.nomeBeneficiario,
                    beneficiarioDirty: false,
                    beneficiarioError: (0, helpers_1.validationField)(action.payload.nomeBeneficiario, '', false, 3),
                    iban: action.payload.ibanBeneficiario,
                    ibanDirty: false,
                    ibanError: (0, helpers_1.validationField)(action.payload.ibanBeneficiario, '', true),
                    importo: action.payload.importo,
                    importoDirty: false,
                    importoError: (0, helpers_1.validationField)(action.payload.importo, 0),
                    descrizione: action.payload.causale,
                    descrizioneDirty: false,
                    descrizioneError: (0, helpers_1.validationField)(action.payload.causale, '', false, 1, 140),
                    rapportoSelezionato: rapportoSelezionato,
                    idOneClickCollegata: action.payload.idOneClickCollegata,
                    istantaneo: action.payload.naturaMovimento === 'SCI',
                    ordinanteEffettivo: ((_g = action.payload.dettOrdi) === null || _g === void 0 ? void 0 : _g.intestazione) || '',
                    accordionDatiAggiuntiviOpened: !!((_h = action.payload.dettOrdi) === null || _h === void 0 ? void 0 : _h.intestazione),
                    dataEsecuzione: data,
                    dataEsecuzioneDirty: false,
                    dataEsecuzioneError: ''
                },
                currentBozza: action.payload
            };
        }
        case __1.EReduxActionTypes.BONIFICO_SEPA_GET_BONIFICO_FROM_BOZZA: {
            rapportoSelezionato = (0, helpers_1.findInArrayRapporti)(state.parametri && state.parametri.rapporti, action.payload.contoAddebito);
            const { nomeBeneficiario, ibanBeneficiario, importo, causale, idOneClickCollegata, motivazionePagamento, naturaMovimento, datiFiscali: { ordinanti: arrayOrdinanti, beneficiario, amministratore }, dettOrdi, dataEsecuzione } = {
                ...action.payload,
                datiFiscali: action.payload.datiFiscali || {
                    arrayOrdinanti: [],
                    beneficiario: '',
                    amministratore: ''
                }
            };
            const data = (dataEsecuzione && moment(dataEsecuzione) >= moment((_k = (_j = state.parametri) === null || _j === void 0 ? void 0 : _j.parametri) === null || _k === void 0 ? void 0 : _k['BONIFICOSEPA.PRIMADATAUTILE'], 'YYYY-MM-DD'))
                ? moment(dataEsecuzione).format('YYYY-MM-DD')
                : moment((_m = (_l = state.parametri) === null || _l === void 0 ? void 0 : _l.parametri) === null || _m === void 0 ? void 0 : _m['BONIFICOSEPA.PRIMADATAUTILE'], 'YYYY-MM-DD').format('YYYY-MM-DD');
            return {
                ...state,
                form: {
                    ...state.form,
                    beneficiario: nomeBeneficiario,
                    beneficiarioDirty: false,
                    beneficiarioError: (0, helpers_1.validationField)(nomeBeneficiario, '', false, 3),
                    iban: ibanBeneficiario,
                    ibanDirty: false,
                    ibanError: (0, helpers_1.validationField)(ibanBeneficiario, '', true),
                    importo: importo,
                    importoDirty: false,
                    importoError: (0, helpers_1.validationField)(importo, 0),
                    descrizione: causale,
                    descrizioneDirty: false,
                    descrizioneError: (0, helpers_1.validationField)(causale, '', false, 1, 140),
                    rapportoSelezionato: rapportoSelezionato,
                    idOneClickCollegata: idOneClickCollegata,
                    motivazionePagamento: motivazionePagamento,
                    motivazionePagamentoDirty: false,
                    motivazionePagamentoError: '',
                    codiceFiscalePIVAOrdinanti: arrayOrdinanti && arrayOrdinanti.length > 0 ? arrayOrdinanti.map(o => o) : [''],
                    codiceFiscalePIVAOrdinantiDirty: false,
                    codiceFiscalePIVAOrdinantiArrayDirty: arrayOrdinanti && arrayOrdinanti.length > 0 ? arrayOrdinanti.map(_ => false) : [false],
                    codiceFiscalePIVAOrdinantiError: arrayOrdinanti && arrayOrdinanti.length > 0 ? arrayOrdinanti.map(_ => '') : [''],
                    codiceFiscalePIVABeneficiario: beneficiario || '',
                    codiceFiscalePIVABeneficiarioDirty: false,
                    codiceFiscalePIVABeneficiarioError: '',
                    codiceFiscalePIVAAmministratore: amministratore || '',
                    codiceFiscalePIVAAmministratoreDirty: false,
                    codiceFiscalePIVAAmministratoreError: '',
                    istantaneo: naturaMovimento === 'SCI',
                    ordinanteEffettivo: (dettOrdi === null || dettOrdi === void 0 ? void 0 : dettOrdi.intestazione) || '',
                    accordionDatiAggiuntiviOpened: !!(dettOrdi === null || dettOrdi === void 0 ? void 0 : dettOrdi.intestazione),
                    dataEsecuzione: data,
                    dataEsecuzioneDirty: false,
                    dataEsecuzioneError: ''
                },
                currentBozza: action.noBozza ? initialState.currentBozza : action.payload
            };
        }
        case __1.EReduxActionTypes.BONIFICO_SEPA_ADD_BOZZA_SUCCESS:
            return {
                ...state,
                bozzeBonifico: {
                    ...state.bozzeBonifico,
                    bonifici: state.bozzeBonifico && state.bozzeBonifico.bonifici
                        ? [...state.bozzeBonifico.bonifici, action.payload]
                        : []
                },
                form: {
                    ...state.form,
                    bozzaSalvata: true
                },
                currentBozza: action.payload
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_DEL_BOZZA_SUCCESS:
            return {
                ...state,
                bozzeBonifico: {
                    ...state.bozzeBonifico,
                    bonifici: state.bozzeBonifico && state.bozzeBonifico.bonifici
                        ? state.bozzeBonifico.bonifici.filter((bonifico) => bonifico.id !== action.payload)
                        : []
                },
                form: {
                    ...state.form,
                    bozzaSalvata: state.currentBozza.id === action.payload ? false : state.form.bozzaSalvata
                },
                currentBozza: state.currentBozza.id === action.payload ? initialState.currentBozza : state.currentBozza
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_UPDATE_BOZZA_SUCCESS:
            return {
                ...state,
                bozzeBonifico: {
                    ...state.bozzeBonifico,
                    bonifici: state.bozzeBonifico && state.bozzeBonifico.bonifici
                        ? state.bozzeBonifico.bonifici.map(bonifico => bonifico.id === state.currentBozza.id ? action.payload : bonifico)
                        : []
                },
                form: {
                    ...state.form,
                    bozzaSalvata: true
                },
                currentBozza: action.payload
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_RESET_RIEPILOGO:
            return {
                ...state,
                riepilogo: initialState.riepilogo
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_RESET_ESITO:
            return {
                ...state,
                esito: initialState.esito
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_SET_ISTANTANEO:
            return {
                ...state,
                form: {
                    ...state.form,
                    istantaneo: action.payload
                }
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_ISTANTANEO_ERROR:
            return {
                ...state,
                form: {
                    ...state.form,
                    errorIstantaneo: action.payload
                }
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_ISTANTANEO_RESET_ERROR:
            return {
                ...state,
                form: {
                    ...state.form,
                    istantaneo: !state.form.istantaneo,
                    errorIstantaneo: null
                }
            };
        case __1.EReduxActionTypes.CARTA_CONTO_GET_PARAMETRI_SUCCESS:
            return {
                ...initialState,
                parametri: action.payload,
            };
        case __1.EReduxActionTypes.CARTA_CONTO_GET_PARAMETRI_PER_RIPETI_SUCCESS:
            return {
                ...initialState,
                parametri: action.payload,
                form: {
                    ...state.form,
                }
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_PSD2_WEBVIEW_LINK_CONTROLLO:
            return {
                ...state,
                webViewLinkControllo: action.payload
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_SET_USERNAME_PSD2:
            return {
                ...state,
                form: {
                    ...state.form,
                    usernamePSD2: action.payload,
                    usernamePSD2Dirty: true,
                    usernamePSD2Error: (0, helpers_1.validationField)(action.payload, '')
                }
            };
        case __1.EReduxActionTypes.BONIFICO_SEPA_RESET_INFO_DISPOSITIVA:
            return {
                ...state,
                infoDispositiva: initialState.infoDispositiva
            };
        case __1.EReduxActionTypes.MODIFICA_MASSIMALE_BONIFICO_ISTA_SUCCESS:
            return {
                ...state,
                massimaleFirma: action.payload
            };
        case __1.EReduxActionTypes.BONIFICO_DF_SET_CHECKBOX_PAGAMENTO_SPESE_SU_PARTI_COMUNI_CONDOMINIALI:
            return {
                ...state,
                form: {
                    ...state.form,
                    checkboxPagamentoSpeseSuPartiComuniCondominiali: action.payload
                }
            };
        case __1.EReduxActionTypes.RESET_ALL: // action invocata dalla logout
            return {
                ...initialState
            };
        default:
            return state;
    }
};
exports.bonificoSepaReducer = bonificoSepaReducer;
exports.default = exports.bonificoSepaReducer;
