import { ProfiloMenu } from "@sparkasse/commons";
import IDToPath from "component/IDToPath";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  areaItemsLvl1: ProfiloMenu[];
  areaItemsLvl2: ProfiloMenu[];
  areaLabelLvl1: string;
  areaLabelLvl2: string;
  isMenuExpanded: boolean;
  setAreaItemsLvl1: (v: ProfiloMenu[]) => void;
  setAreaItemsLvl2: (v: ProfiloMenu[]) => void;
  setAreaLabelLvl1: (v: string) => void;
  setAreaLabelLvl2: (v: string) => void;
  setMenuExpanded: (v: boolean) => any;
  navigateTo: (path: string) => void;
  onMenu: (e?: any) => void;
}

const useHandleAreaMenu = (props: Props) => {
  const { t } = useTranslation();
  const {
    areaItemsLvl1,
    areaItemsLvl2,
    areaLabelLvl1,
    areaLabelLvl2,
    isMenuExpanded,
    setAreaItemsLvl1,
    setAreaItemsLvl2,
    setAreaLabelLvl1,
    setAreaLabelLvl2,
    setMenuExpanded,
    navigateTo,
    onMenu,
  } = props;

  const areaItems = useMemo(() => {
    return areaItemsLvl2.length > 0 ? areaItemsLvl2 : areaItemsLvl1;
  }, [areaItemsLvl1, areaItemsLvl2]);

  const areaLabel = useMemo(() => {
    return areaLabelLvl2.length > 0 ? areaLabelLvl2 : areaLabelLvl1;
  }, [areaLabelLvl1, areaLabelLvl2]);

  const onClickBackArea = () => {
    if (areaItemsLvl2.length > 0) {
      setAreaItemsLvl2([]);
      setAreaLabelLvl2("");
    } else {
      setAreaItemsLvl1([]);
      setAreaLabelLvl1("");
    }
  };

  const onClickElement = (el: ProfiloMenu) => {
    if (el.submenu?.length && el.tipoSubmenu !== "tabMenu") {
      onClickSubMenu(el.submenu, t(el.id || ""));
    } else {
      IDToPath(el.label, navigateTo, setMenuExpanded, el);

      setAreaItemsLvl1([]);
      setAreaLabelLvl1("");
      setAreaItemsLvl2([]);
      setAreaLabelLvl2("");
    }
  };

  const onClickSubMenu = (items: ProfiloMenu[], label: string) => {
    setAreaItemsLvl2(items);
    setAreaLabelLvl2(label);
  };

  const resetAreaItemsAndLabels = () => {
    if (isMenuExpanded) {
      onMenu();
    }
    setAreaItemsLvl1([]);
    setAreaLabelLvl1("");
    setAreaItemsLvl2([]);
    setAreaLabelLvl2("");
  };

  return {
    areaItems,
    areaLabel,
    onClickBackArea,
    onClickElement,
    resetAreaItemsAndLabels,
  };
};

export default useHandleAreaMenu;
