"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RavsDtoToJSON = exports.RavsDtoFromJSONTyped = exports.RavsDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function RavsDtoFromJSON(json) {
    return RavsDtoFromJSONTyped(json, false);
}
exports.RavsDtoFromJSON = RavsDtoFromJSON;
function RavsDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'ravs': !runtime_1.exists(json, 'ravs') ? undefined : (json['ravs'].map(_1.RavDtoFromJSON)),
    };
}
exports.RavsDtoFromJSONTyped = RavsDtoFromJSONTyped;
function RavsDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'ravs': value.ravs === undefined ? undefined : (value.ravs.map(_1.RavDtoToJSON)),
    };
}
exports.RavsDtoToJSON = RavsDtoToJSON;
