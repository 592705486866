"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PosizioneInvestimentiDtoToJSON = exports.PosizioneInvestimentiDtoFromJSONTyped = exports.PosizioneInvestimentiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function PosizioneInvestimentiDtoFromJSON(json) {
    return PosizioneInvestimentiDtoFromJSONTyped(json, false);
}
exports.PosizioneInvestimentiDtoFromJSON = PosizioneInvestimentiDtoFromJSON;
function PosizioneInvestimentiDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'tipologia': !runtime_1.exists(json, 'tipologia') ? undefined : json['tipologia'],
        'tipologiaDesc': !runtime_1.exists(json, 'tipologiaDesc') ? undefined : json['tipologiaDesc'],
        'divisa': !runtime_1.exists(json, 'divisa') ? undefined : json['divisa'],
        'minusPlusVisibile': !runtime_1.exists(json, 'minusPlusVisibile') ? undefined : json['minusPlusVisibile'],
        'minusPlus': !runtime_1.exists(json, 'minusPlus') ? undefined : json['minusPlus'],
        'controvalore': !runtime_1.exists(json, 'controvalore') ? undefined : json['controvalore'],
        'investimentiContiList': !runtime_1.exists(json, 'investimentiContiList') ? undefined : (json['investimentiContiList'].map(_1.InvestimentiContoDtoFromJSON)),
        'investimentiContiListVisibile': !runtime_1.exists(json, 'investimentiContiListVisibile') ? undefined : json['investimentiContiListVisibile'],
        'totaleControvalore': !runtime_1.exists(json, 'totaleControvalore') ? undefined : json['totaleControvalore'],
    };
}
exports.PosizioneInvestimentiDtoFromJSONTyped = PosizioneInvestimentiDtoFromJSONTyped;
function PosizioneInvestimentiDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'tipologia': value.tipologia,
        'tipologiaDesc': value.tipologiaDesc,
        'divisa': value.divisa,
        'minusPlusVisibile': value.minusPlusVisibile,
        'minusPlus': value.minusPlus,
        'controvalore': value.controvalore,
        'investimentiContiList': value.investimentiContiList === undefined ? undefined : (value.investimentiContiList.map(_1.InvestimentiContoDtoToJSON)),
        'investimentiContiListVisibile': value.investimentiContiListVisibile,
        'totaleControvalore': value.totaleControvalore,
    };
}
exports.PosizioneInvestimentiDtoToJSON = PosizioneInvestimentiDtoToJSON;
