"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListaMovimentiNexiDtoToJSON = exports.ListaMovimentiNexiDtoFromJSONTyped = exports.ListaMovimentiNexiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ListaMovimentiNexiDtoFromJSON(json) {
    return ListaMovimentiNexiDtoFromJSONTyped(json, false);
}
exports.ListaMovimentiNexiDtoFromJSON = ListaMovimentiNexiDtoFromJSON;
function ListaMovimentiNexiDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'dataInizialeSaldo': !runtime_1.exists(json, 'dataInizialeSaldo') ? undefined : (new Date(json['dataInizialeSaldo'])),
        'dataFinaleSaldo': !runtime_1.exists(json, 'dataFinaleSaldo') ? undefined : (new Date(json['dataFinaleSaldo'])),
        'movimenti': !runtime_1.exists(json, 'movimenti') ? undefined : (json['movimenti'].map(_1.MovimentoCartaDtoFromJSON)),
        'totaleMovimenti': !runtime_1.exists(json, 'totaleMovimenti') ? undefined : json['totaleMovimenti'],
    };
}
exports.ListaMovimentiNexiDtoFromJSONTyped = ListaMovimentiNexiDtoFromJSONTyped;
function ListaMovimentiNexiDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'dataInizialeSaldo': value.dataInizialeSaldo === undefined ? undefined : (value.dataInizialeSaldo.toISOString()),
        'dataFinaleSaldo': value.dataFinaleSaldo === undefined ? undefined : (value.dataFinaleSaldo.toISOString()),
        'movimenti': value.movimenti === undefined ? undefined : (value.movimenti.map(_1.MovimentoCartaDtoToJSON)),
        'totaleMovimenti': value.totaleMovimenti,
    };
}
exports.ListaMovimentiNexiDtoToJSON = ListaMovimentiNexiDtoToJSON;
