"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConsensoNexiDtoToJSON = exports.ConsensoNexiDtoFromJSONTyped = exports.ConsensoNexiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function ConsensoNexiDtoFromJSON(json) {
    return ConsensoNexiDtoFromJSONTyped(json, false);
}
exports.ConsensoNexiDtoFromJSON = ConsensoNexiDtoFromJSON;
function ConsensoNexiDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'idConsenso': json['idConsenso'],
        'value': json['value'],
        'application': !runtime_1.exists(json, 'application') ? undefined : json['application'],
        'channel': !runtime_1.exists(json, 'channel') ? undefined : json['channel'],
        'dataInizioValidita': !runtime_1.exists(json, 'dataInizioValidita') ? undefined : (new Date(json['dataInizioValidita'])),
        'dataFineValidita': !runtime_1.exists(json, 'dataFineValidita') ? undefined : (new Date(json['dataFineValidita'])),
    };
}
exports.ConsensoNexiDtoFromJSONTyped = ConsensoNexiDtoFromJSONTyped;
function ConsensoNexiDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'idConsenso': value.idConsenso,
        'value': value.value,
        'application': value.application,
        'channel': value.channel,
        'dataInizioValidita': value.dataInizioValidita === undefined ? undefined : (value.dataInizioValidita.toISOString()),
        'dataFineValidita': value.dataFineValidita === undefined ? undefined : (value.dataFineValidita.toISOString()),
    };
}
exports.ConsensoNexiDtoToJSON = ConsensoNexiDtoToJSON;
