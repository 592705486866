"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoToJSON = exports.EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoFromJSONTyped = exports.EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoFromJSON(json) {
    return EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoFromJSONTyped(json, false);
}
exports.EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoFromJSON = EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoFromJSON;
function EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
        'codiceErrore': !runtime_1.exists(json, 'codiceErrore') ? undefined : json['codiceErrore'],
        'informazioniAutenticazioneDispositiva': !runtime_1.exists(json, 'informazioniAutenticazioneDispositiva') ? undefined : _1.InformazioniAutenticazioneDispositivaDtoFromJSON(json['informazioniAutenticazioneDispositiva']),
        'disposizione': !runtime_1.exists(json, 'disposizione') ? undefined : _1.RipetitivoDtoFromJSON(json['disposizione']),
    };
}
exports.EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoFromJSONTyped = EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoFromJSONTyped;
function EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'stato': value.stato,
        'codiceErrore': value.codiceErrore,
        'informazioniAutenticazioneDispositiva': _1.InformazioniAutenticazioneDispositivaDtoToJSON(value.informazioniAutenticazioneDispositiva),
        'disposizione': _1.RipetitivoDtoToJSON(value.disposizione),
    };
}
exports.EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoToJSON = EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoToJSON;
/**
* @export
* @enum {string}
*/
var EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoStatoEnum;
(function (EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoStatoEnum) {
    EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoStatoEnum["OK"] = "OK";
    EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoStatoEnum["CREDENZIALE_ERRATA"] = "CREDENZIALE_ERRATA";
    EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoStatoEnum["ATTENDERE"] = "ATTENDERE";
    EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoStatoEnum["ACCOUNT_BLOCCATO"] = "ACCOUNT_BLOCCATO";
    EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoStatoEnum["ACCP"] = "ACCP";
    EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoStatoEnum["ACSC"] = "ACSC";
    EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoStatoEnum["ACSP"] = "ACSP";
    EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoStatoEnum["ACTC"] = "ACTC";
    EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoStatoEnum["ACWC"] = "ACWC";
    EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoStatoEnum["ACWP"] = "ACWP";
    EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoStatoEnum["RCVD"] = "RCVD";
    EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoStatoEnum["PDNG"] = "PDNG";
    EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoStatoEnum["RJCT"] = "RJCT";
    EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoStatoEnum["CANC"] = "CANC";
})(EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoStatoEnum || (exports.EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoStatoEnum = {}));
