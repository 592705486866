"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RicercaAtmFiltriInDtoToJSON = exports.RicercaAtmFiltriInDtoFromJSONTyped = exports.RicercaAtmFiltriInDtoFromJSON = void 0;
function RicercaAtmFiltriInDtoFromJSON(json) {
    return RicercaAtmFiltriInDtoFromJSONTyped(json, false);
}
exports.RicercaAtmFiltriInDtoFromJSON = RicercaAtmFiltriInDtoFromJSON;
function RicercaAtmFiltriInDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'distance': json['distance'],
        'latitude': json['latitude'],
        'longitude': json['longitude'],
        'lingua': json['lingua'],
    };
}
exports.RicercaAtmFiltriInDtoFromJSONTyped = RicercaAtmFiltriInDtoFromJSONTyped;
function RicercaAtmFiltriInDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'distance': value.distance,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'lingua': value.lingua,
    };
}
exports.RicercaAtmFiltriInDtoToJSON = RicercaAtmFiltriInDtoToJSON;
