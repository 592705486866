"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormDatiPersonaliDtoFromJSONTyped = void 0;
const helpers_1 = require("../helpers");
// export interface FormDatiPersonaliDto {
//     residenzaLegale?: IndirizzoDatiAnagraficiDto;
//     provinciaDirty?: boolean;
//     provinciaError?: string;
//     comuneDirty?: boolean;
//     comuneError?: string;
//     localitaDirty?: boolean;
//     localitaError?: string;
//     capDirty?: boolean;
//     capError?: string;
//     via?: ViaDatiAnagraficiDto;
//     tipoDirty?: boolean;
//     tipoError?: string;
//     nomeDirty?: boolean;
//     nomeError?: string;
//     numeroDomicilioDirty?: boolean;
//     numeroDomicilioError?: string;
//     step: number;
//     formSubmitted: boolean;
// }
// export function FormDatiPersonaliDtoFromJSONTyped(json: any): FormDatiPersonaliDto {
//     if ((json === undefined) || (json === null)) {
//         return json;
//     }
//     return {
//         'provinciaDirty': !exists(json, 'provinciaDirty') ? false : json['provinciaDirty'],
//         'provinciaError': !exists(json, 'provinciaError') ? 'invalid.required' : json['provinciaError'],
//         'comuneDirty': !exists(json, 'comuneDirty') ? false : json['comuneDirty'],
//         'comuneError': !exists(json, 'comuneError') ? 'invalid.required' : json['comuneError'],
//         'localitaDirty': !exists(json, 'localitaDirty') ? false : json['localitaDirty'],
//         'localitaError': !exists(json, 'localitaError') ? 'invalid.required' : json['localitaError'],
//         'capDirty': !exists(json, 'capDirty') ? false : json['capDirty'],
//         'capError': !exists(json, 'capError') ? 'invalid.required' : json['capError'],
//         'tipoDirty': !exists(json, 'tipoDirty') ? false : json['tipoDirty'],
//         'tipoError': !exists(json, 'tipoError') ? 'invalid.required' : json['tipoError'],
//         'nomeDirty': !exists(json, 'nomeDirty') ? false : json['nomeDirty'],
//         'nomeError': !exists(json, 'nomeError') ? 'invalid.required' : json['nomeError'],
//         'numeroDomicilioDirty': !exists(json, 'numeroDomicilioDirty') ? false : json['numeroDomicilioDirty'],
//         'numeroDomicilioError': !exists(json, 'numeroDomicilioError') ? 'invalid.required' : json['numeroDomicilioError'],
//         'step': !exists(json, 'step') ? 0 : json['step'],
//         'formSubmitted': !exists(json, 'formSubmitted') ? false : json['formSubmitted'],
//     }
// }
function FormDatiPersonaliDtoFromJSONTyped(json) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'step': !(0, helpers_1.exists)(json, 'step') ? 0 : json.step,
        'email': !(0, helpers_1.exists)(json, 'email') ? undefined : json.email,
        'emailDirty': !(0, helpers_1.exists)(json, 'emailDirty') ? false : json.emailDirty,
        'emailError': !(0, helpers_1.exists)(json, 'emailError') ? '' : json.email,
        'cellulare': !(0, helpers_1.exists)(json, 'cellulare') ? undefined : json.cellulare,
        'cellulareDirty': !(0, helpers_1.exists)(json, 'cellulareDirty') ? false : json.cellulareDirty,
        'cellulareError': !(0, helpers_1.exists)(json, 'cellulareError') ? '' : json.cellulareError,
        'formSubmitted': !(0, helpers_1.exists)(json, 'formSubmitted') ? false : json.formSubmitted
    };
}
exports.FormDatiPersonaliDtoFromJSONTyped = FormDatiPersonaliDtoFromJSONTyped;
