"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrdineParametriSaldoContoToJSON = exports.OrdineParametriSaldoContoFromJSONTyped = exports.OrdineParametriSaldoContoFromJSON = void 0;
const runtime_1 = require("../runtime");
function OrdineParametriSaldoContoFromJSON(json) {
    return OrdineParametriSaldoContoFromJSONTyped(json, false);
}
exports.OrdineParametriSaldoContoFromJSON = OrdineParametriSaldoContoFromJSON;
function OrdineParametriSaldoContoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'divisa': !runtime_1.exists(json, 'divisa') ? undefined : json['divisa'],
        'saldo': !runtime_1.exists(json, 'saldo') ? undefined : json['saldo'],
    };
}
exports.OrdineParametriSaldoContoFromJSONTyped = OrdineParametriSaldoContoFromJSONTyped;
function OrdineParametriSaldoContoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'divisa': value.divisa,
        'saldo': value.saldo,
    };
}
exports.OrdineParametriSaldoContoToJSON = OrdineParametriSaldoContoToJSON;
