"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DatiRilevatiToJSON = exports.DatiRilevatiFromJSONTyped = exports.DatiRilevatiFromJSON = void 0;
const runtime_1 = require("../runtime");
function DatiRilevatiFromJSON(json) {
    return DatiRilevatiFromJSONTyped(json, false);
}
exports.DatiRilevatiFromJSON = DatiRilevatiFromJSON;
function DatiRilevatiFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'ndg': !runtime_1.exists(json, 'ndg') ? undefined : json['ndg'],
        'tipologiaDocumento': !runtime_1.exists(json, 'tipologiaDocumento') ? undefined : json['tipologiaDocumento'],
        'numero': !runtime_1.exists(json, 'numero') ? undefined : json['numero'],
        'dataEmissione': !runtime_1.exists(json, 'dataEmissione') ? undefined : json['dataEmissione'],
        'dataScadenza': !runtime_1.exists(json, 'dataScadenza') ? undefined : json['dataScadenza'],
        'luogoRilascio': !runtime_1.exists(json, 'luogoRilascio') ? undefined : json['luogoRilascio'],
        'tokenIdentifier': !runtime_1.exists(json, 'tokenIdentifier') ? undefined : json['tokenIdentifier'],
    };
}
exports.DatiRilevatiFromJSONTyped = DatiRilevatiFromJSONTyped;
function DatiRilevatiToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'ndg': value.ndg,
        'tipologiaDocumento': value.tipologiaDocumento,
        'numero': value.numero,
        'dataEmissione': value.dataEmissione,
        'dataScadenza': value.dataScadenza,
        'luogoRilascio': value.luogoRilascio,
        'tokenIdentifier': value.tokenIdentifier,
    };
}
exports.DatiRilevatiToJSON = DatiRilevatiToJSON;
