"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.datiGraficoCambi = exports.dettaglioCambio = exports.elencoCambi = exports.formCambi = exports.mercatiCambi = void 0;
const reselect_1 = require("reselect");
const cambi_types_1 = require("./cambi.types");
const moment = require("moment");
const cambiReducer = (state) => state;
exports.mercatiCambi = (0, reselect_1.createSelector)(cambiReducer, (state) => {
    return state.mercati;
});
exports.formCambi = (0, reselect_1.createSelector)(cambiReducer, (state) => {
    return state.form;
});
exports.elencoCambi = (0, reselect_1.createSelector)(cambiReducer, (state) => {
    return state.cambi;
});
exports.dettaglioCambio = (0, reselect_1.createSelector)(cambiReducer, (state) => {
    return state.cambioDettaglio;
});
exports.datiGraficoCambi = (0, reselect_1.createSelector)(cambiReducer, (state) => {
    var _a, _b, _c, _d, _e, _f;
    return {
        ...state.cambioDettaglio,
        graficoCorrente: (_f = (_e = (_d = (state.cambioDettaglio.tipoGrafico === cambi_types_1.TipoGraficoCambi.Settimana1 ? (_a = state.cambioDettaglio.datiSettimana) === null || _a === void 0 ? void 0 : _a.dati :
            state.cambioDettaglio.tipoGrafico === cambi_types_1.TipoGraficoCambi.Mese1 ? (_b = state.cambioDettaglio.datiMese) === null || _b === void 0 ? void 0 : _b.dati :
                (_c = state.cambioDettaglio.datiAnno) === null || _c === void 0 ? void 0 : _c.dati)) === null || _d === void 0 ? void 0 : _d.map(d => ({ data: d.data, valore: d.chiusura }))) === null || _e === void 0 ? void 0 : _e.sort((a, b) => (a.data && b.data && a.data < b.data ? -1 : 1))) === null || _f === void 0 ? void 0 : _f.map(d => ({ ...d, x: d.data ? moment(d.data).format("x") : "-" }))
    };
});
