"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.logOperationTOL = exports.resetCompravenditaDossier = exports.getCompravenditaCustomerDossier = exports.getCompravenditaDossierTitoliRapporti = exports.stampaPDFGlossario = exports.stampaPDFSchedaTitolo = exports.stampaPDFKiid = exports.autorizzaCompravenditaTitolo = exports.getInfoDispositivaCompravenditaTitolo = exports.controlloCompravenditaTitolo = exports.setAccordionBookOpenedCompravendita = exports.setPresaVisioneCostiCompravendita = exports.setPresaVisioneKiidCompravendita = exports.setPresaVisioneKiidEnabledCompravendita = exports.setPresaVisioneSchedaTitoloCompravendita = exports.setPresaVisioneSchedaTitoloEnabledCompravendita = exports.setFormSubmittedCompravendita = exports.setAccordionParametriAggiuntiviOpenedCompravendita = exports.resetCompravendita = exports.resetRiepilogoCompravendita = exports.setCompravenditaTipoQuantita = exports.setCompravenditaData = exports.setCompravenditaValidita = exports.setCompravenditaEsecuzione = exports.setCompravenditaPrezzoLimite = exports.setCompravenditaPrezzoStartStop = exports.setCompravenditaTipoPrezzo = exports.setCompravenditaQuantita = exports.setCompravenditaSegnoOrdine = exports.setCompravenditaMercatoOrdine = exports.setCompravenditaMercatoEsterno = exports.setCompravenditaCodiceIsin = exports.setCompravenditaDossierDepositoSelezionato = exports.setCompravenditaDossierRapportoSelezionato = exports.setCompravenditaSetStep = exports.getBookTitolo = exports.getQuotazioneDettaglioTitolo = exports.getParametriAvanzatiCompravendita = exports.getParametriCompravendita = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
const compravendita_selectors_1 = require("./compravendita.selectors");
const compravendita_types_1 = require("./compravendita.types");
/*
parametriTrading -> /private/finanza/parametritrading -> parametri = https://api-canali/api/{codiceIstituto}/{codiceUtente}/finanza/parametri/{codiceFunzione}
parametriAvanzati -> /private/finanza/ordine/parametri -> getOrdineParametriAvanzatiIsin = https://api-canali/api/{codiceIstituto}/{codiceUtente}/finanzamobile/trading2/rapporti/{codiceRapporto}/titoli/{mercatoEsterno}/ordineParametriAvanzatiIsin/{codiceIsin}
quotazioneDettaglioTitolo -> /private/finanza/titolo/quotazione/{mercato}/{codiceTitolo} -> getQuotazioneTitoloDettaglio = https://api-canali/api/{codiceIstituto}/{codiceUtente}/finanzamobile/titoli/{mercato}/quotazioni/{codiceTitolo}
bookTitolo -> /private/finanza/book/{mercato}/{codiceTitolo} -> getBookTitolo = https://api-canali/api/{codiceIstituto}/{codiceUtente}/finanzamobile/trading2/titoli/{mercato}/book/{codiceTitolo}
*/
/*
get parametri compravendita titoli - START
parametriTrading -> /private/finanza/parametritrading -> parametri = https://api-canali/api/{codiceIstituto}/{codiceUtente}/finanza/parametri/{codiceFunzione}
*/
function getParametriCompravenditaStart() {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_GET_PARAMETRI_COMPRAVENDITA_START
    };
}
function getParametriCompravenditaSuccess(payload) {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_GET_PARAMETRI_COMPRAVENDITA_SUCCESS,
        payload
    };
}
function generalError(value, showGenericModalError, sendErrorToMatomo) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value, showGenericModalError));
        !!sendErrorToMatomo && sendErrorToMatomo('COMPRAVENDITA', `error_code COMPRAVENDITA ${value.status} / ${value.message} / ${value.timestamp}`);
    };
}
const getParametriCompravendita = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getParametriCompravenditaStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametriTrading().then((response) => {
                dispatch(getParametriCompravenditaSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getParametriCompravendita = getParametriCompravendita;
/* get parametri compravendita titoli - FINE */
/*
get ordine parametri avanzati isin compravendita titoli - START
parametriAvanzati -> /private/finanza/ordine/parametri -> getOrdineParametriAvanzatiIsin = https://api-canali/api/{codiceIstituto}/{codiceUtente}/finanzamobile/trading2/rapporti/{codiceRapporto}/titoli/{mercatoEsterno}/ordineParametriAvanzatiIsin/{codiceIsin}
*/
function getParametriAvanzatiCompravenditaStart() {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_GET_PARAMETRI_AVANZATI_COMPRAVENDITA_START
    };
}
function getParametriAvanzatiCompravenditaSuccess(payload) {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_GET_PARAMETRI_AVANZATI_COMPRAVENDITA_SUCCESS,
        payload
    };
}
const getParametriAvanzatiCompravendita = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getParametriAvanzatiCompravenditaStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            const request = {
                ordineParametriAvanzatiIsinInDto: (0, compravendita_selectors_1.buildInputGetParametriAvanzati)(getState().compravendita)
            };
            new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametriAvanzati(request).then((response) => {
                dispatch(getParametriAvanzatiCompravenditaSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getParametriAvanzatiCompravendita = getParametriAvanzatiCompravendita;
/* get ordine parametri avanzati isin compravendita titoli - FINE */
/*
get quotazione dettaglio titolo compravendita titoli - START
quotazioneDettaglioTitolo -> /private/finanza/titolo/quotazione/{mercato}/{codiceTitolo} -> getQuotazioneTitoloDettaglio = https://api-canali/api/{codiceIstituto}/{codiceUtente}/finanzamobile/titoli/{mercato}/quotazioni/{codiceTitolo}
*/
function getQuotazioneDettaglioTitoloStart() {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_GET_QUOTAZIONE_DETTAGLIO_TITOLO_START
    };
}
function getQuotazioneDettaglioTitoloSuccess(payload) {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_GET_QUOTAZIONE_DETTAGLIO_TITOLO_SUCCESS,
        payload
    };
}
const getQuotazioneDettaglioTitolo = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getQuotazioneDettaglioTitoloStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            var _a;
            const request = {
                mercato: getState().compravendita.form.mercatoEsterno,
                codiceTitolo: (_a = getState().compravendita.parametriAvanzati.quotazioni) === null || _a === void 0 ? void 0 : _a.codiceTitolo
            };
            new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).quotazioneDettaglioTitolo(request).then((response) => {
                dispatch(getQuotazioneDettaglioTitoloSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getQuotazioneDettaglioTitolo = getQuotazioneDettaglioTitolo;
/* get quotazione dettaglio titolo compravendita titoli - FINE */
/*
get book titolo compravendita titoli - START
bookTitolo -> /private/finanza/book/{mercato}/{codiceTitolo} -> getBookTitolo = https://api-canali/api/{codiceIstituto}/{codiceUtente}/finanzamobile/trading2/titoli/{mercato}/book/{codiceTitolo}
*/
function getBookTitoloStart() {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_GET_BOOK_TITOLO_START
    };
}
function getBookTitoloSuccess(payload) {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_GET_BOOK_TITOLO_SUCCESS,
        payload
    };
}
const getBookTitolo = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getBookTitoloStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            var _a;
            const request = {
                mercato: getState().compravendita.form.mercatoEsterno,
                codiceTitolo: (_a = getState().compravendita.parametriAvanzati.quotazioni) === null || _a === void 0 ? void 0 : _a.codiceTitolo
            };
            new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).bookTitolo(request).then((response) => {
                dispatch(getBookTitoloSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getBookTitolo = getBookTitolo;
/* get book titolo compravendita titoli - FINE */
function setCompravenditaSetStepAction(i) {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_STEP,
        payload: i
    };
}
const setCompravenditaSetStep = (i) => async (dispatch, getState, extraArguments) => {
    dispatch(setCompravenditaSetStepAction(i));
    if (extraArguments.platform == 'mobile') {
        dispatch((0, general_actions_1.setBottomTabBarVisible)(i != 2));
    }
};
exports.setCompravenditaSetStep = setCompravenditaSetStep;
function setCompravenditaDossierRapportoSelezionato(dossierRapportoSelezionato) {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_DOSSIER_RAPPORTO_SELEZIONATO,
        payload: dossierRapportoSelezionato
    };
}
exports.setCompravenditaDossierRapportoSelezionato = setCompravenditaDossierRapportoSelezionato;
function setCompravenditaDossierDepositoSelezionato(deposito) {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_DOSSIER_DEPOSITO_SELEZIONATO,
        payload: deposito
    };
}
exports.setCompravenditaDossierDepositoSelezionato = setCompravenditaDossierDepositoSelezionato;
function setCompravenditaCodiceIsin(codiceIsin) {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_CODICE_ISIN,
        payload: codiceIsin
    };
}
exports.setCompravenditaCodiceIsin = setCompravenditaCodiceIsin;
function setCompravenditaMercatoEsterno(mercatoEsterno) {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_MERCATO_ESTERNO,
        payload: mercatoEsterno
    };
}
exports.setCompravenditaMercatoEsterno = setCompravenditaMercatoEsterno;
function setCompravenditaMercatoOrdine(mercatoOrdine) {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_MERCATO_ORDINE,
        payload: mercatoOrdine
    };
}
exports.setCompravenditaMercatoOrdine = setCompravenditaMercatoOrdine;
function setCompravenditaSegnoOrdine(segno) {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_SEGNO_ORDINE,
        payload: segno
    };
}
exports.setCompravenditaSegnoOrdine = setCompravenditaSegnoOrdine;
function setCompravenditaQuantita(quantita) {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_QUANTITA,
        payload: quantita
    };
}
exports.setCompravenditaQuantita = setCompravenditaQuantita;
function setCompravenditaTipoPrezzo(s) {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_TIPO_PREZZO,
        payload: s
    };
}
exports.setCompravenditaTipoPrezzo = setCompravenditaTipoPrezzo;
function setCompravenditaPrezzoStartStop(p) {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_PREZZO_START_STOP,
        payload: p
    };
}
exports.setCompravenditaPrezzoStartStop = setCompravenditaPrezzoStartStop;
function setCompravenditaPrezzoLimite(p) {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_PREZZO_LIMITE,
        payload: p
    };
}
exports.setCompravenditaPrezzoLimite = setCompravenditaPrezzoLimite;
function setCompravenditaEsecuzione(s) {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_ESECUZIONE,
        payload: s
    };
}
exports.setCompravenditaEsecuzione = setCompravenditaEsecuzione;
function setCompravenditaValidita(s) {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_VALIDITA,
        payload: s
    };
}
exports.setCompravenditaValidita = setCompravenditaValidita;
function setCompravenditaData(d) {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_DATA,
        payload: d
    };
}
exports.setCompravenditaData = setCompravenditaData;
function setCompravenditaTipoQuantita(s) {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_TIPO_QUANTITA,
        payload: s
    };
}
exports.setCompravenditaTipoQuantita = setCompravenditaTipoQuantita;
function resetRiepilogoCompravendita() {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_RESET_RIEPILOGO
    };
}
exports.resetRiepilogoCompravendita = resetRiepilogoCompravendita;
function resetCompravendita() {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_RESET
    };
}
exports.resetCompravendita = resetCompravendita;
function setAccordionParametriAggiuntiviOpenedCompravendita(opened) {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_ACCORDION_PARAMETRI_AGGIUNTIVI_OPENED,
        payload: opened
    };
}
exports.setAccordionParametriAggiuntiviOpenedCompravendita = setAccordionParametriAggiuntiviOpenedCompravendita;
function setFormSubmittedCompravendita(submitted) {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_FORM_SUBMITTED,
        payload: submitted
    };
}
exports.setFormSubmittedCompravendita = setFormSubmittedCompravendita;
function setPresaVisioneSchedaTitoloEnabledCompravendita(b) {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_PRESA_VISIONE_SCHEDA_TITOLO_ENABLED,
        payload: b
    };
}
exports.setPresaVisioneSchedaTitoloEnabledCompravendita = setPresaVisioneSchedaTitoloEnabledCompravendita;
function setPresaVisioneSchedaTitoloCompravendita(b) {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_PRESA_VISIONE_SCHEDA_TITOLO,
        payload: b
    };
}
exports.setPresaVisioneSchedaTitoloCompravendita = setPresaVisioneSchedaTitoloCompravendita;
function setPresaVisioneKiidEnabledCompravendita(b) {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_PRESA_VISIONE_KIID_ENABLED,
        payload: b
    };
}
exports.setPresaVisioneKiidEnabledCompravendita = setPresaVisioneKiidEnabledCompravendita;
function setPresaVisioneKiidCompravendita(b) {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_PRESA_VISIONE_KIID,
        payload: b
    };
}
exports.setPresaVisioneKiidCompravendita = setPresaVisioneKiidCompravendita;
function setPresaVisioneCostiCompravendita(b) {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_PRESA_VISIONE_COSTI,
        payload: b
    };
}
exports.setPresaVisioneCostiCompravendita = setPresaVisioneCostiCompravendita;
function setAccordionBookOpenedCompravendita(opened) {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_ACCORDION_BOOK_OPENED,
        payload: opened
    };
}
exports.setAccordionBookOpenedCompravendita = setAccordionBookOpenedCompravendita;
/* controllo compravendita titoli - START */
function controlloCompravenditaTitoloStart() {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_CONTROLLO_COMPRAVENDITA_TITOLO_START
    };
}
function controlloCompravenditaTitoloSuccess(payload) {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_CONTROLLO_COMPRAVENDITA_TITOLO_SUCCESS,
        payload
    };
}
const controlloCompravenditaTitolo = () => async (dispatch, getState, extraArguments) => {
    dispatch(setFormSubmittedCompravendita(true));
    const stateCompravendita = getState().compravendita;
    if ((0, compravendita_selectors_1.formCompravenditaValid)(stateCompravendita)) {
        dispatch((0, general_actions_1.setLoader)(true));
        dispatch(controlloCompravenditaTitoloStart());
        if (extraArguments.mock) {
            dispatch((0, general_actions_1.setLoader)(false));
        }
        else {
            extraArguments.getItem('jwt').then((jwt) => {
                const request = {
                    ordineTradingDto: (0, compravendita_selectors_1.buildControlloCompravenditaTitolo)(stateCompravendita)
                };
                new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).controllaOrdine(request).then((response) => {
                    dispatch(controlloCompravenditaTitoloSuccess(response));
                }).catch(async (error) => {
                    dispatch(generalError(await (0, helpers_1.formatError)(error)));
                }).finally(() => {
                    dispatch((0, general_actions_1.setLoader)(false));
                });
            });
        }
    }
};
exports.controlloCompravenditaTitolo = controlloCompravenditaTitolo;
/* controllo compravendita titoli - FINE */
/* getInfoDispositiva compravendita titoli - START */
function getInfoDispositivaCompravenditaTitoloStart() {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_GET_INFO_DISPOSITIVA_COMPRAVENDITA_TITOLO_START
    };
}
function getInfoDispositivaCompravenditaTitoloSuccess(payload) {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_GET_INFO_DISPOSITIVA_COMPRAVENDITA_TITOLO_SUCCESS,
        payload
    };
}
const getInfoDispositivaCompravenditaTitolo = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getInfoDispositivaCompravenditaTitoloStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            const request = {
                idDispositiva,
                chiamataUscente,
                eseguiInit,
                offline: !!offline
            };
            new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).infoAutenticazioneOrdine(request).then((response) => {
                dispatch(getInfoDispositivaCompravenditaTitoloSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getInfoDispositivaCompravenditaTitolo = getInfoDispositivaCompravenditaTitolo;
/* getInfoDispositiva compravendita titoli - FINE */
/* autorizza compravendita titoli - START */
function autorizzaCompravenditaTitoloStart() {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_AUTORIZZA_COMPRAVENDITA_TITOLO_START
    };
}
function autorizzaCompravenditaTitoloSuccess(payload) {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_AUTORIZZA_COMPRAVENDITA_TITOLO_SUCCESS,
        payload
    };
}
const autorizzaCompravenditaTitolo = (idDispositiva, credenziale) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(autorizzaCompravenditaTitoloStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            const request = {
                idDispositiva,
                credenzialeDispositivaDto: credenziale
            };
            new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).autorizzaOrdine(request).then((response) => {
                dispatch(autorizzaCompravenditaTitoloSuccess(response));
                extraArguments.sendEventToMatomo('COMPRAVENDITA', `esito dispositiva COMPRAVENDITA ${response.stato}`, 'ESITO_DISPOSITIVA');
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error), false, extraArguments.sendEventToMatomo));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.autorizzaCompravenditaTitolo = autorizzaCompravenditaTitolo;
/* autorizza compravendita titoli - FINE */
/* stampa PDF KIID - START */
function stampaPDFKiidStart() {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_STAMPA_PDF_KIID_START
    };
}
function stampaPDFKiidSuccess(payload) {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_STAMPA_PDF_KIID_SUCCESS,
        payload
    };
}
const stampaPDFKiid = (is2Step) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(stampaPDFKiidStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            const isin = getState().compravendita.form.codiceIsin;
            const request = { isin };
            new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf')).stampaPDFKiid(request).then((response) => {
                if (extraArguments.platform === 'desktop') {
                    (0, helpers_1.hadlePdfResponse)(response, (url) => dispatch((0, general_actions_1.popUpBlockedPdf)(url, true)));
                }
                if (extraArguments.platform === 'mobile') {
                    (0, helpers_1.download_sharePDF)(extraArguments, 'kiid_' + isin + '.pdf', response, false);
                }
                if (!!is2Step) {
                    dispatch(setPresaVisioneKiidEnabledCompravendita(true));
                }
                dispatch(stampaPDFKiidSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.stampaPDFKiid = stampaPDFKiid;
/* stampa PDF KIID - FINE */
/* stampa PDF scheda titolo - START */
// TODO CON I SERVIZI DI PROMETEIA
function stampaPDFSchedaTitoloStart() {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_STAMPA_PDF_SCHEDA_TITOLO_START
    };
}
function stampaPDFSchedaTitoloSuccess(payload) {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_STAMPA_PDF_SCHEDA_TITOLO_SUCCESS,
        payload
    };
}
const stampaPDFSchedaTitolo = (ISIN, is2Step) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(stampaPDFSchedaTitoloStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            const isin = ISIN ? ISIN : getState().compravendita.form.codiceIsin;
            const request = { codiceIsin: isin };
            new api_rest_1.PrometeiaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf')).exportSchedaTitolo(request).then((response) => {
                if (extraArguments.platform === 'desktop') {
                    (0, helpers_1.hadlePdfResponse)(response, (url) => dispatch((0, general_actions_1.popUpBlockedPdf)(url, true)));
                }
                if (extraArguments.platform === 'mobile') {
                    (0, helpers_1.download_sharePDF)(extraArguments, 'schedatitolo_' + isin + '.pdf', response, false);
                }
                if (!!is2Step) {
                    dispatch(setPresaVisioneSchedaTitoloEnabledCompravendita(true));
                }
                dispatch(stampaPDFSchedaTitoloSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.stampaPDFSchedaTitolo = stampaPDFSchedaTitolo;
/* stampa PDF scheda titolo - FINE */
/* stampa PDF Glossario - START */ //TIPIZARE TUTTA LA ACTION CORRETTAMENTE 
function stampaPDFGlossarioStart() {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_STAMPA_PDF_GLOSSARIO_START
    };
}
function stampaPDFGlossarioSuccess(payload) {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_STAMPA_PDF_GLOSSARIO_SUCCESS,
        payload
    };
}
const stampaPDFGlossario = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(stampaPDFGlossarioStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf')).getGlossarioPdf().then((response) => {
                if (extraArguments.platform === 'desktop') {
                    (0, helpers_1.hadlePdfResponse)(response, (url) => dispatch((0, general_actions_1.popUpBlockedPdf)(url)));
                }
                if (extraArguments.platform === 'mobile') {
                    (0, helpers_1.download_sharePDF)(extraArguments, 'Glossario-TOL.pdf', response, false);
                }
                dispatch(stampaPDFGlossarioSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.stampaPDFGlossario = stampaPDFGlossario;
/* stampa PDF Glossario - FINE */
/* get dossier Rapporti start */
function getCompravenditaDossierRapportiStart() {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_DOSSIER_TITOLI_GET_RAPPORTI_START
    };
}
function getCompravenditaDossierRapportiSuccess(payload) {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_DOSSIER_TITOLI_GET_RAPPORTI_SUCCESS,
        payload
    };
}
const getCompravenditaDossierTitoliRapporti = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getCompravenditaDossierRapportiStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/portafoglio_titoli_rapporti.json')).then(response => {
            dispatch(getCompravenditaDossierRapportiSuccess(response.map(r => (0, api_rest_1.DatiIntestatarioDtoFromJSON)(r))));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.PrometeiaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .soggettiOperantiPerFinanza().then((response) => {
                dispatch(getCompravenditaDossierRapportiSuccess(response));
            }).catch(async (error) => {
                dispatch((0, general_actions_1.setError)(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getCompravenditaDossierTitoliRapporti = getCompravenditaDossierTitoliRapporti;
/* get dossier Rapporti fine  */
/* get dossier Customer start */
function getCompravenditaCustomerDossierStart() {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_COSTUMER_DOSSIER_START
    };
}
function getCompravenditaCustomerDossierSuccess(payload) {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_COSTUMER_DOSSIER_SUCCESS,
        payload
    };
}
const getCompravenditaCustomerDossier = () => async (dispatch, getState, extraArguments) => {
    var _a;
    const compravendita = getState().compravendita;
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getCompravenditaCustomerDossierStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/portafoglio_titoli_rapporti.json')).then(response => {
            dispatch(getCompravenditaCustomerDossierSuccess((0, api_rest_1.DossierBODtoFromJSON)(response)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const rapportoSelezionato = (_a = compravendita.form) === null || _a === void 0 ? void 0 : _a.dossierRapportoSelezionato;
        if (!rapportoSelezionato) {
            return;
        }
        const customerCode = String(rapportoSelezionato === null || rapportoSelezionato === void 0 ? void 0 : rapportoSelezionato.ndg);
        const isRapportoDelegato = rapportoSelezionato.rapportoDelega;
        const accounts = isRapportoDelegato
            ? rapportoSelezionato.rapporti.map(r => {
                return r.toString().substring(r.toString().length - 8);
            })
            : [];
        const includeCoi = isRapportoDelegato ? true : false;
        const customerDossierRequest = {
            dossierRequestInDto: {
                customerCode,
                accounts,
                includeCoi
            }
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.PrometeiaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .getCustomerDossier(customerDossierRequest).then((response) => {
                dispatch(getCompravenditaCustomerDossierSuccess(response));
            }).catch(async (error) => {
                dispatch((0, general_actions_1.setError)(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getCompravenditaCustomerDossier = getCompravenditaCustomerDossier;
/* get dossier Customer fine  */
function resetCompravenditaDossier() {
    return {
        type: compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_RESET_DOSSIER
    };
}
exports.resetCompravenditaDossier = resetCompravenditaDossier;
const logOperationTOL = (type, value) => async (dispatch, getState, extraArguments) => {
    if (type === 'schedaTitolo_pdf') {
        dispatch((0, general_actions_1.logginOperation)({
            codiceFunzione: 'TRADING',
            esito: 'OK',
            evento: 'CLICK_PDF_SCHEDA_TITOLO',
            noteAggiuntive: value ? 'da step 2' : 'da step 1'
        }));
    }
    else if (type === 'kidKiid_pdf') {
        dispatch((0, general_actions_1.logginOperation)({
            codiceFunzione: 'TRADING',
            esito: 'OK',
            evento: 'CLICK_PDF_KID_KIID',
            noteAggiuntive: value ? 'da step 2' : 'da step 1'
        }));
    }
    else if (type === 'schedaTitolo_check') {
        dispatch((0, general_actions_1.logginOperation)({
            codiceFunzione: 'TRADING',
            esito: 'OK',
            evento: 'PRESA_VISIONE_SCHEDA_TITOLO',
            noteAggiuntive: value ? 'CHECKBOX_SELEZIONATO' : 'CHECKBOX_NON_SELEZIONATO'
        }));
    }
    else if (type === 'kidKiid_check') {
        dispatch((0, general_actions_1.logginOperation)({
            codiceFunzione: 'TRADING',
            esito: 'OK',
            evento: 'PRESA_VISIONE_KID_KIID',
            noteAggiuntive: value ? 'CHECKBOX_SELEZIONATO' : 'CHECKBOX_NON_SELEZIONATO'
        }));
    }
    else if (type === 'costi_check') {
        dispatch((0, general_actions_1.logginOperation)({
            codiceFunzione: 'TRADING',
            esito: 'OK',
            evento: 'PRESA_VISIONE_COSTI_OPERAZIONE',
            noteAggiuntive: value ? 'CHECKBOX_SELEZIONATO' : 'CHECKBOX_NON_SELEZIONATO'
        }));
    }
};
exports.logOperationTOL = logOperationTOL;
