import BankIcon from "component/Icon/icon.component";
import React, { useState } from "react";
import { Placement } from "react-bootstrap/esm/Overlay";
import { useTranslation } from "react-i18next";
import Colors from "styles/generated/colors";

export interface Props {
  id: number
  className?: string
  data?: string
  importo?: string
  importoLabel?: string
  /** Dovrebbe avere i valori di "D" (Dare) in caso di valore negativo e "A" (Avere) in caso di valore positivo */
  sign?: string
  divisa?: string
  importoEstero?: string
  /** Dovrebbe avere i valori di "D" (Dare) in caso di valore negativo e "A" (Avere) in caso di valore positivo */
  signEstero?: string
  divisaEstera?: string
  descrizione?: string
  descrizioneBold?: string
  selected?: boolean
  img?: any
  descrizioneStato?: string
  account?: string
  categoria?: string
  descrizioneCategoria?: string
  dataValuta?: string
  dataValutaLabel?: string
  dataLabel?: string
  apriDettaglio?: any
  item?: any
  CTA?: iCTA[]
  additionalInfo?: iAdditionalInfo[]
  stato?: string
  numeroOperazione?: string
  descrizioneAggiuntiva?: string
  esportaDettaglioMovimentoPDF?: any
  categorieFiltro?: any
  tipologiaMovimento?: string
  noIcon?: boolean
  testoTooltipCtaDisabilitata?: string
  tooltipPlacement?: Placement
  tooltipDescription?: iTooltipDescrizione | null
  isMovimentoNonContabilizzato?: boolean
}

const Card: React.FC<Props> = (props: Props) => {

  const { t } = useTranslation();

  const {
    id,
    className,
    data,
    importo,
    importoLabel,
    sign,
    divisa,
    importoEstero,
    signEstero,
    divisaEstera,
    descrizione,
    descrizioneBold,
    selected,
    img,
    descrizioneStato,
    account,
    categoria,
    descrizioneCategoria,
    dataValuta,
    dataValutaLabel,
    dataLabel,
    apriDettaglio,
    item,
    CTA,
    additionalInfo,
    noIcon,
    testoTooltipCtaDisabilitata,
    tooltipPlacement,
    categorieFiltro,
    esportaDettaglioMovimentoPDF,
    numeroOperazione,
    stato,
    descrizioneAggiuntiva,
    tipologiaMovimento,
    tooltipDescription,
    isMovimentoNonContabilizzato
  } = props;

  const mostraDettagli = false;

  const [expanded, setExpanded] = useState(false);

  const onClick = () => {
    setExpanded(!expanded);
    apriDettaglio && apriDettaglio(item);
  };

  return (
    <div
      style={isMovimentoNonContabilizzato ? { backgroundColor: Colors.lightgreyVariant, boxShadow: 'none' } : {}}
      className={(className || "") + (selected ? ` card-bozze-wrapper highlight` : ` card-bozze-wrapper`)}
      id={String(id)}
      role={"listitem"}
      tabIndex={0}
    >
      <div className="card-bozze-viewer">
        <div className={`card-view-container`}>

          <BankIcon title="mirino" className="card-view-container-corner" height={16} width={16} />

          <div className={`card-view card-view-${mostraDettagli ? "mostraDettagli" : "home"}`}>

            {!noIcon ? (
              !isMovimentoNonContabilizzato ?
                <div className={"icon-view"}>
                  <BankIcon
                    etichetta={sign === "D" || !sign ?
                      t("accessibilita.movimento.uscita")
                      : t("accessibilita.movimento.entrata")}
                    title={`${img || (sign === "D" || !sign ? "movimenti_uscite_uscite" : "movimenti_entrate_entrate")}`}
                    tooltip={descrizioneCategoria || categoria || ""}
                    onClick={apriDettaglio ? onClick : undefined}
                  />
                </div>
                :
                <div className={"icon-view"}>
                  <BankIcon
                    title="fiocco"
                    tooltip={descrizioneCategoria || categoria || ""}
                    onClick={apriDettaglio ? onClick : undefined}
                  />
                </div>
            ) : (
              <div className='ml-3'></div>
            )}
            <div className="card-view--body-view--full-col">
              {isMovimentoNonContabilizzato && <div><span className="card-view--body-view--date">
                {t("saldo_e_movimenti.movimento.nonContabilizzato")}
              </span></div>}
              <div className="card-view--body-view--full-row" >

                <div className="card-view--body-view" onClick={onClick}>
                  {!isMovimentoNonContabilizzato && <span className="card-view--body-view--date">
                    {data ? `${dataLabel || ""} ${data}` : ""}
                  </span>}
                  <span className={`card-view--body-view--description ${isMovimentoNonContabilizzato && "neutral"}`}>
                    {descrizioneBold &&
                      <span className="bold">
                        {descrizioneBold}
                      </span>
                    }
                    {descrizione || ""}
                    {tooltipDescription &&
                      <BankIcon
                        title={tooltipDescription.type}
                        tooltip={tooltipDescription.text}
                      />
                    }
                  </span>
                  {!mostraDettagli && account && (
                    <span className="card-view--body-view--account">
                      {account || ""}
                    </span>
                  )}
                </div>
                <div className={`card-view--body-view ${!dataValuta && !dataValutaLabel ? 'pt-4' : ''}`} onClick={onClick}>
                  {!isMovimentoNonContabilizzato &&
                    <span className="card-view--body-view--date-on-right">
                      {dataValuta === " "
                        ? <>&nbsp;</>
                        : dataValuta
                          ? `${dataValutaLabel || t("saldo_e_movimenti.dataValuta")} ${dataValuta}`
                          : ""
                      }
                    </span>}

                  {importoLabel !== undefined &&
                    <span className="card-view--body-view--date-on-right" >
                      {importoLabel}
                    </span>
                  }

                  {importo !== undefined &&
                    <div className="card-view--body-view--import-view" style={{ marginRight: isMovimentoNonContabilizzato ? "55px" : "" }}>
                      <label
                        className={`card-view--body-view--import-view--import is-saldo ${!isMovimentoNonContabilizzato ? (sign === "-" || sign === "D" ? "outcoming" : sign === "A" ? "incoming" : "") : 'neutral'}`}
                      >
                        {sign === "A" ? "+" : ""}{importo}
                      </label>
                      <label className={`card-view--body-view--import-view--currency ${isMovimentoNonContabilizzato ? " neutral" : ""}`}>
                        {divisa || "EUR"}
                      </label>
                    </div>
                  }

                  {importoEstero !== undefined &&
                    <div className="card-view--body-view--import-estero-view">
                      <label
                        className={`card-view--body-view--import-estero-view--import is-saldo`}
                      >
                        {signEstero === "A" ? "+" : ""}{importoEstero}
                      </label>
                      <label className="card-view--body-view--import-estero-view--currency">
                        {divisaEstera || "EUR"}
                      </label>
                    </div>
                  }
                  {/* {!mostraDettagli && account && (
                    <span className="txtAccount-item-card">&nbsp;</span>
                  )} */}
                </div>
              </div>
              {(!!additionalInfo?.length || descrizioneStato) &&
                <div className="card-view--body-view--full-row">
                  <div className="card-view--body-view--full-row--inner">

                    {!!additionalInfo?.length && additionalInfo?.length > 0 &&
                      <div className="card-view--body-view" onClick={onClick}>
                        <span className="card-view--body-view--additional-info-label">
                          {data && isMovimentoNonContabilizzato ? `${data} - ` : ""}{additionalInfo[0]?.label || ""}
                        </span>
                        <span className="card-view--body-view--additional-info-value">
                          {additionalInfo[0]?.value || ""}
                        </span>
                      </div>}
                    {!!additionalInfo?.length && additionalInfo?.length > 1 &&
                      <div className="card-view--body-view--spaced" onClick={onClick}>
                        <span className="card-view--body-view--additional-info-label">
                          {additionalInfo[1]?.label || ""}
                        </span>
                        <span className="card-view--body-view--additional-info-value">
                          {additionalInfo[1]?.value || ""}
                        </span>
                      </div>}
                    {!!additionalInfo?.length && additionalInfo?.length > 2 &&
                      <div className="card-view--body-view" onClick={onClick}>
                        <span className="card-view--body-view--additional-info-label">
                          {additionalInfo[2]?.label || ""}
                        </span>
                        <span className="card-view--body-view--additional-info-value">
                          {additionalInfo[2]?.value || ""}
                        </span>
                      </div>}
                    {descrizioneStato &&
                      <div className="card-view--body-view flex11" onClick={onClick}>
                        <span className="card-view--body-view--additional-info-value-on-right">
                          {descrizioneStato}
                        </span>
                        <span className="card-view--body-view--additional-info-value-on-right">
                        </span>
                      </div>}
                  </div>
                </div>
              }
            </div>
            {CTA && (
              <div className="card-view--body-view card-view--body-view--vertical-cta card-view-container">
                <div className="vertical-cta-items">
                  {CTA.map((cta, i) =>
                  (!cta.hide &&
                    <div key={`item-${i}`} className={`vertical-cta-item vertical-cta-item-not-clickable`}>
                      {cta.waiting
                        ?
                        <BankIcon
                          title="spinner"
                          placement="right"
                          keepSize
                        />
                        :
                        <BankIcon
                          title={`azioni.${cta.id}`}
                          etichetta={`${t(`azioni.${cta.id}`)} ${descrizione}`}
                          onClick={cta.active && cta.callback || undefined}
                          disabled={!cta.active}
                          placement={tooltipPlacement || "right"}
                          tooltip={cta.tooltip ||
                            (cta.active && cta.callback ?
                              t("pagamentiEffettuati.cta.esegui", { cta: ` ${t(`pagamentiEffettuati.cta.${cta.id}`)} ${descrizione}` }) :
                              testoTooltipCtaDisabilitata || t("pagamentiEffettuati.cta.nonConsentita", { cta: t(`pagamentiEffettuati.cta.${cta.id}`) }))}
                        />
                      }
                    </div>
                  )
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div >
  );
};

export interface iCTA {
  id: string
  active?: boolean
  waiting?: boolean
  callback?: any
  tooltip?: string
  hide?: boolean
}

export interface iAdditionalInfo {
  label: string
  value: string
}

export interface iTooltipDescrizione {
  text: string
  type: string
}

export default Card;
