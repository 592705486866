"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CredenzialeDispositivaDtoToJSON = exports.CredenzialeDispositivaDtoFromJSONTyped = exports.CredenzialeDispositivaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function CredenzialeDispositivaDtoFromJSON(json) {
    return CredenzialeDispositivaDtoFromJSONTyped(json, false);
}
exports.CredenzialeDispositivaDtoFromJSON = CredenzialeDispositivaDtoFromJSON;
function CredenzialeDispositivaDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'otp': !runtime_1.exists(json, 'otp') ? undefined : json['otp'],
        'otpPin': !runtime_1.exists(json, 'otpPin') ? undefined : json['otpPin'],
        'otpNuovoPin': !runtime_1.exists(json, 'otpNuovoPin') ? undefined : json['otpNuovoPin'],
        'otpNextTokenCode': !runtime_1.exists(json, 'otpNextTokenCode') ? undefined : json['otpNextTokenCode'],
        'passwordDispositiva': !runtime_1.exists(json, 'passwordDispositiva') ? undefined : json['passwordDispositiva'],
        'pin': !runtime_1.exists(json, 'pin') ? undefined : json['pin'],
        'captcha': !runtime_1.exists(json, 'captcha') ? undefined : json['captcha'],
        'captchaAnswer': !runtime_1.exists(json, 'captchaAnswer') ? undefined : json['captchaAnswer'],
        'caratteri': !runtime_1.exists(json, 'caratteri') ? undefined : json['caratteri'],
        'pwd': !runtime_1.exists(json, 'pwd') ? undefined : json['pwd'],
        'codiceDaVerificare': !runtime_1.exists(json, 'codiceDaVerificare') ? undefined : json['codiceDaVerificare'],
        'codiceConfermaRecapiti': !runtime_1.exists(json, 'codiceConfermaRecapiti') ? undefined : json['codiceConfermaRecapiti'],
        'offline': !runtime_1.exists(json, 'offline') ? undefined : json['offline'],
        'psd2PaymentDto': !runtime_1.exists(json, 'psd2PaymentDto') ? undefined : _1.Psd2PaymentDtoFromJSON(json['psd2PaymentDto']),
    };
}
exports.CredenzialeDispositivaDtoFromJSONTyped = CredenzialeDispositivaDtoFromJSONTyped;
function CredenzialeDispositivaDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'otp': value.otp,
        'otpPin': value.otpPin,
        'otpNuovoPin': value.otpNuovoPin,
        'otpNextTokenCode': value.otpNextTokenCode,
        'passwordDispositiva': value.passwordDispositiva,
        'pin': value.pin,
        'captcha': value.captcha,
        'captchaAnswer': value.captchaAnswer,
        'caratteri': value.caratteri,
        'pwd': value.pwd,
        'codiceDaVerificare': value.codiceDaVerificare,
        'codiceConfermaRecapiti': value.codiceConfermaRecapiti,
        'offline': value.offline,
        'psd2PaymentDto': _1.Psd2PaymentDtoToJSON(value.psd2PaymentDto),
    };
}
exports.CredenzialeDispositivaDtoToJSON = CredenzialeDispositivaDtoToJSON;
