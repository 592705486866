import { AdeguataVerificaRapportiDto, MessaggioAllegatoDto, MessaggioBodyDto, RapportoADVDto, segnaComeLettoMessaggi, setMessaggiSetAccettato } from "@sparkasse/commons";
import ButtonText2 from "component/buttonText2/buttonText2.component";
import { navigateToLogout } from "component/history/history.component";
import { downloadPDFFromMessaggio } from "helpers/utilities";
import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { AppState } from "../../reducers";

const MessaggiBloccantiComponent: React.FC<Props> = (props: Props) => {

  const { t } = useTranslation();

  const {
    setMessaggiSetAccettato,
    segnaComeLettoMessaggi,
    messaggio,
    onClose,
    show,
    isAdeguataVerifica,
    rapportiAdeguataVerifica
  } = props;

  const [terminiCondizioniDownloaded, setTerminiCondizioniDownloaded] = useState(false);

  const onDownloadTerminiCondizioni = (allegato: MessaggioAllegatoDto) => {
    downloadPDFFromMessaggio(allegato);
    setTerminiCondizioniDownloaded(true);
  };

  const accettaTerminiCondizioni = (id: number) => {
    segnaComeLettoMessaggi(id);
    setMessaggiSetAccettato(true);
  };

  const onExit = () => {
    navigateToLogout();
  };

  const onAvvisamiDiNuovo = (id: number) => {
    onClose(id);
  };

  const constructRapportiTable = (messaggio: MessaggioBodyDto) => {
    return (
      <>
        <div dangerouslySetInnerHTML={{
          __html: messaggio && messaggio?.bodyADV ? messaggio?.bodyADV[0] : "<p></p>",
        }} />
        <div>
          <Row className="font-size-12">
            <Col sm={4}><b>{t('messaggiBloccanti.adeguataVerifica.rapporto')}</b></Col>
            <Col sm={{ offset: 2 }}><b>{t('messaggiBloccanti.adeguataVerifica.intestazione')}</b></Col>
            <Col sm={{ offset: 1 }}><b>{t('messaggiBloccanti.adeguataVerifica.giorniMancanti')}</b></Col>
          </Row>
          {rapportiAdeguataVerifica && rapportiAdeguataVerifica.rapportiADV && rapportiAdeguataVerifica.rapportiADV.map((item: RapportoADVDto, i: number) => {
            return (
              <Row key={i} className="rapporti-table-row rapporti-table-text">
                <Col sm={6}>{item.iban}</Col>
                <Col sm={5}>{item.intestatario}</Col>
                <Col sm={1} >{item.giorniResidui}</Col>
              </Row>
            )
          })}
        </div>
        <div dangerouslySetInnerHTML={{
          __html: messaggio && messaggio?.bodyADV ? messaggio?.bodyADV[2] : "",
        }} />
      </>
    );
  };

  const backdrop = messaggio.impedisciAccesso ? 'static' : true;

  return (
    <Modal show={show} backdrop={backdrop} className="mesaggi-bloccanti-modal" onHide={() => onClose(messaggio.idMessaggio)}>

      <Modal.Header>
        <Modal.Title>{messaggio.titolo}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {isAdeguataVerifica ?
          constructRapportiTable(messaggio) :
          <div
            dangerouslySetInnerHTML={{
              __html: messaggio?.body || "<p></p>",
            }}
          />}

      </Modal.Body>

      {!!messaggio.allegato && (
        <ButtonText2
          descrizione={messaggio.allegato.name || t("messaggiBloccanti.button.downloadPDF")}
          onClick={() => onDownloadTerminiCondizioni(messaggio.allegato!)}
          imageOnLeft={true}
          img="azioni.esporta_pdf"
        />
      )}

      <Modal.Footer className="justify">

        { /* AVVISAMI DI NUOVO BUTTON  */}
        {(!!messaggio.showBtnMostraAllaProssimaVolta && !messaggio.impedisciAccesso && !isAdeguataVerifica) && (
          <button
            type="button"
            className="mesaggi-bloccanti-modal__close-button left"
            onClick={() => onAvvisamiDiNuovo(messaggio.idMessaggio!)}
          >
            {t("messaggiBloccanti.button.avvisamiDiNuovo").toUpperCase()}
          </button>
        )}

        { /* EXIT BUTTON  */}
        {((!messaggio.showBtnMostraAllaProssimaVolta && !messaggio.impedisciAccesso) || messaggio.impedisciAccesso) && (
          <button
            type="button"
            className="mesaggi-bloccanti-modal__close-button left"
            onClick={onExit}
          >
            {t("messaggiBloccanti.button.esci").toUpperCase()}
          </button>
        )}

        {/* OK BUTTON */}
        {!messaggio.impedisciAccesso && (
          <button
            type="button"
            className={"mesaggi-bloccanti-modal__close-button right ml-auto general-button " + ((messaggio.allegato && !terminiCondizioniDownloaded) ? 'general-button-disabled' : '')}
            onClick={() => isAdeguataVerifica ? onAvvisamiDiNuovo(messaggio.idMessaggio!) : accettaTerminiCondizioni(messaggio.idMessaggio!)}
            disabled={messaggio.allegato ? !terminiCondizioniDownloaded : false}
          >
            {t("messaggiBloccanti.button.ok").toUpperCase()}
          </button>
        )}

      </Modal.Footer>

    </Modal>
  );
};

interface MessaggiBloccantiProps {
  messaggio: MessaggioBodyDto
  onClose: (id: number | undefined) => void
  show: boolean
  rapportiAdeguataVerifica?: AdeguataVerificaRapportiDto
  isAdeguataVerifica: boolean
}

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      segnaComeLettoMessaggi,
      setMessaggiSetAccettato
    },
    dispatch
  );

export type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & MessaggiBloccantiProps;

export default connect(mapStateToProps, mapDispatchToProps)(MessaggiBloccantiComponent);
