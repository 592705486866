"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PNS Client API
 * PNS - Push Notification Service: Api per l\'invio di notifiche
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PNSResponseSubscriptionToJSON = exports.PNSResponseSubscriptionFromJSONTyped = exports.PNSResponseSubscriptionFromJSON = void 0;
const runtime_1 = require("../runtime");
function PNSResponseSubscriptionFromJSON(json) {
    return PNSResponseSubscriptionFromJSONTyped(json, false);
}
exports.PNSResponseSubscriptionFromJSON = PNSResponseSubscriptionFromJSON;
function PNSResponseSubscriptionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'deviceId': !runtime_1.exists(json, 'deviceId') ? undefined : json['deviceId'],
        'topics': !runtime_1.exists(json, 'topics') ? undefined : json['topics'],
        'timeRegistration': !runtime_1.exists(json, 'timeRegistration') ? undefined : json['timeRegistration'],
    };
}
exports.PNSResponseSubscriptionFromJSONTyped = PNSResponseSubscriptionFromJSONTyped;
function PNSResponseSubscriptionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'deviceId': value.deviceId,
        'topics': value.topics,
        'timeRegistration': value.timeRegistration,
    };
}
exports.PNSResponseSubscriptionToJSON = PNSResponseSubscriptionToJSON;
