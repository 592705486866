"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthenticationServiceControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class AuthenticationServiceControllerApi extends runtime.BaseAPI {
    /**
     * Modifica del pin del token utente
     */
    attivitaUtenteTokenPinRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.modificaTokenPinDto === null || requestParameters.modificaTokenPinDto === undefined) {
                throw new runtime.RequiredError('modificaTokenPinDto', 'Required parameter requestParameters.modificaTokenPinDto was null or undefined when calling attivitaUtenteTokenPin.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/public/login/v1/attivita_utente/token/pin`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ModificaTokenPinDtoToJSON(requestParameters.modificaTokenPinDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ModificaPinTokenRispostaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Modifica del pin del token utente
     */
    attivitaUtenteTokenPin(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.attivitaUtenteTokenPinRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Verifica dei consensi
     */
    authenticationConsentRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
                throw new runtime.RequiredError('sessionId', 'Required parameter requestParameters.sessionId was null or undefined when calling authenticationConsent.');
            }
            if (requestParameters.scopeListDto === null || requestParameters.scopeListDto === undefined) {
                throw new runtime.RequiredError('scopeListDto', 'Required parameter requestParameters.scopeListDto was null or undefined when calling authenticationConsent.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters.sessionId !== undefined && requestParameters.sessionId !== null) {
                headerParameters['session_id'] = String(requestParameters.sessionId);
            }
            const response = yield this.request({
                path: `/public/login/v1/authentication/consent`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ScopeListDtoToJSON(requestParameters.scopeListDto),
            });
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Verifica dei consensi
     */
    authenticationConsent(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.authenticationConsentRaw(requestParameters);
        });
    }
    /**
     * Richiesta dei consensi
     */
    authenticationConsentRequestedScopeRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
                throw new runtime.RequiredError('sessionId', 'Required parameter requestParameters.sessionId was null or undefined when calling authenticationConsentRequestedScope.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters.sessionId !== undefined && requestParameters.sessionId !== null) {
                headerParameters['session_id'] = String(requestParameters.sessionId);
            }
            const response = yield this.request({
                path: `/public/login/v1/authentication/consent/requested_scope`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ScopeListDtoFromJSON(jsonValue));
        });
    }
    /**
     * Richiesta dei consensi
     */
    authenticationConsentRequestedScope(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.authenticationConsentRequestedScopeRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Start the authentication flow
     */
    authenticationStartRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.authenticationStartRequestDto === null || requestParameters.authenticationStartRequestDto === undefined) {
                throw new runtime.RequiredError('authenticationStartRequestDto', 'Required parameter requestParameters.authenticationStartRequestDto was null or undefined when calling authenticationStart.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/public/login/v1/authentication/start`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.AuthenticationStartRequestDtoToJSON(requestParameters.authenticationStartRequestDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.AuthenticationResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Start the authentication flow
     */
    authenticationStart(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.authenticationStartRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Start the strong authentication
     */
    authenticationStrongauthRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
                throw new runtime.RequiredError('sessionId', 'Required parameter requestParameters.sessionId was null or undefined when calling authenticationStrongauth.');
            }
            if (requestParameters.strongAuthInitRequestDto === null || requestParameters.strongAuthInitRequestDto === undefined) {
                throw new runtime.RequiredError('strongAuthInitRequestDto', 'Required parameter requestParameters.strongAuthInitRequestDto was null or undefined when calling authenticationStrongauth.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters.sessionId !== undefined && requestParameters.sessionId !== null) {
                headerParameters['session_id'] = String(requestParameters.sessionId);
            }
            const response = yield this.request({
                path: `/public/login/v1/authentication/strong_auth`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.StrongAuthInitRequestDtoToJSON(requestParameters.strongAuthInitRequestDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.StrongAuthInfoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Start the strong authentication
     */
    authenticationStrongauth(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.authenticationStrongauthRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Verify step of the strong authentication
     */
    authenticationStrongauthVerifyRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
                throw new runtime.RequiredError('sessionId', 'Required parameter requestParameters.sessionId was null or undefined when calling authenticationStrongauthVerify.');
            }
            if (requestParameters.strongAuthVerifyRequestDto === null || requestParameters.strongAuthVerifyRequestDto === undefined) {
                throw new runtime.RequiredError('strongAuthVerifyRequestDto', 'Required parameter requestParameters.strongAuthVerifyRequestDto was null or undefined when calling authenticationStrongauthVerify.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters.sessionId !== undefined && requestParameters.sessionId !== null) {
                headerParameters['session_id'] = String(requestParameters.sessionId);
            }
            const response = yield this.request({
                path: `/public/login/v1/authentication/strong_auth/verify`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.StrongAuthVerifyRequestDtoToJSON(requestParameters.strongAuthVerifyRequestDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.AuthenticationResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Verify step of the strong authentication
     */
    authenticationStrongauthVerify(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.authenticationStrongauthVerifyRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Servizio di cambio credenziali
     */
    credentialChangeRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
                throw new runtime.RequiredError('sessionId', 'Required parameter requestParameters.sessionId was null or undefined when calling credentialChange.');
            }
            if (requestParameters.changePasswordRequestDto === null || requestParameters.changePasswordRequestDto === undefined) {
                throw new runtime.RequiredError('changePasswordRequestDto', 'Required parameter requestParameters.changePasswordRequestDto was null or undefined when calling credentialChange.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters.sessionId !== undefined && requestParameters.sessionId !== null) {
                headerParameters['session_id'] = String(requestParameters.sessionId);
            }
            const response = yield this.request({
                path: `/public/login/v1/credential/change`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ChangePasswordRequestDtoToJSON(requestParameters.changePasswordRequestDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.AuthenticationResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Servizio di cambio credenziali
     */
    credentialChange(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.credentialChangeRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Attivazione del token software
     */
    enrollmentStepRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
                throw new runtime.RequiredError('sessionId', 'Required parameter requestParameters.sessionId was null or undefined when calling enrollmentStep.');
            }
            if (requestParameters.step === null || requestParameters.step === undefined) {
                throw new runtime.RequiredError('step', 'Required parameter requestParameters.step was null or undefined when calling enrollmentStep.');
            }
            if (requestParameters.tokenSoftwareActivationRequestDto === null || requestParameters.tokenSoftwareActivationRequestDto === undefined) {
                throw new runtime.RequiredError('tokenSoftwareActivationRequestDto', 'Required parameter requestParameters.tokenSoftwareActivationRequestDto was null or undefined when calling enrollmentStep.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters.sessionId !== undefined && requestParameters.sessionId !== null) {
                headerParameters['session_id'] = String(requestParameters.sessionId);
            }
            const response = yield this.request({
                path: `/public/login/v1/enrollment/{step}`.replace(`{${"step"}}`, encodeURIComponent(String(requestParameters.step))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.TokenSoftwareActivationRequestDtoToJSON(requestParameters.tokenSoftwareActivationRequestDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.TokenSoftwareActivationResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Attivazione del token software
     */
    enrollmentStep(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.enrollmentStepRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Avvia una richiesta di autorizzazione per il flusso di login, in merito al cambio o reset del token software
     */
    loginTransactionStartRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.xUser === null || requestParameters.xUser === undefined) {
                throw new runtime.RequiredError('xUser', 'Required parameter requestParameters.xUser was null or undefined when calling loginTransactionStart.');
            }
            if (requestParameters.transactionStartInputDto === null || requestParameters.transactionStartInputDto === undefined) {
                throw new runtime.RequiredError('transactionStartInputDto', 'Required parameter requestParameters.transactionStartInputDto was null or undefined when calling loginTransactionStart.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters.xUser !== undefined && requestParameters.xUser !== null) {
                headerParameters['x-user'] = String(requestParameters.xUser);
            }
            const response = yield this.request({
                path: `/public/login/v1/authorization/transaction-start`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.TransactionStartInputDtoToJSON(requestParameters.transactionStartInputDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.TransactionOutputDtoFromJSON(jsonValue));
        });
    }
    /**
     * Avvia una richiesta di autorizzazione per il flusso di login, in merito al cambio o reset del token software
     */
    loginTransactionStart(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.loginTransactionStartRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Api per il reset del token software
     */
    tokenResetRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
                throw new runtime.RequiredError('sessionId', 'Required parameter requestParameters.sessionId was null or undefined when calling tokenReset.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters.sessionId !== undefined && requestParameters.sessionId !== null) {
                headerParameters['session_id'] = String(requestParameters.sessionId);
            }
            const response = yield this.request({
                path: `/public/login/v1/token/reset`,
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Api per il reset del token software
     */
    tokenReset(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.tokenResetRaw(requestParameters);
        });
    }
}
exports.AuthenticationServiceControllerApi = AuthenticationServiceControllerApi;
