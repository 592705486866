"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrdineParametriDtoToJSON = exports.OrdineParametriDtoFromJSONTyped = exports.OrdineParametriDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function OrdineParametriDtoFromJSON(json) {
    return OrdineParametriDtoFromJSONTyped(json, false);
}
exports.OrdineParametriDtoFromJSON = OrdineParametriDtoFromJSON;
function OrdineParametriDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codiceIsin': !runtime_1.exists(json, 'codiceIsin') ? undefined : json['codiceIsin'],
        'prezzoLimitato': !runtime_1.exists(json, 'prezzoLimitato') ? undefined : json['prezzoLimitato'],
        'mercato': !runtime_1.exists(json, 'mercato') ? undefined : json['mercato'],
        'mercati': !runtime_1.exists(json, 'mercati') ? undefined : (json['mercati'].map(_1.OrdineParametriMercatoDtoFromJSON)),
        'mercatiSelezione': !runtime_1.exists(json, 'mercatiSelezione') ? undefined : json['mercatiSelezione'],
        'descTitolo': !runtime_1.exists(json, 'descTitolo') ? undefined : json['descTitolo'],
        'divisa': !runtime_1.exists(json, 'divisa') ? undefined : json['divisa'],
        'saldoTitolo': !runtime_1.exists(json, 'saldoTitolo') ? undefined : json['saldoTitolo'],
        'saldoConto': !runtime_1.exists(json, 'saldoConto') ? undefined : _1.OrdineParametriSaldoContoFromJSON(json['saldoConto']),
        'quotazioni': !runtime_1.exists(json, 'quotazioni') ? undefined : _1.OrdineParametriQuotazioniFromJSON(json['quotazioni']),
        'parametriAvanzati': !runtime_1.exists(json, 'parametriAvanzati') ? undefined : _1.OrdineParametriAvanzatiFromJSON(json['parametriAvanzati']),
        'visualizzaSchedaTitolo': !runtime_1.exists(json, 'visualizzaSchedaTitolo') ? undefined : json['visualizzaSchedaTitolo'],
        'visualizzaPdfKiid': !runtime_1.exists(json, 'visualizzaPdfKiid') ? undefined : json['visualizzaPdfKiid'],
        'indicativoTitolo': !runtime_1.exists(json, 'indicativoTitolo') ? undefined : json['indicativoTitolo'],
        'titolo': !runtime_1.exists(json, 'titolo') ? undefined : json['titolo'],
        'mercatiOrdine': !runtime_1.exists(json, 'mercatiOrdine') ? undefined : json['mercatiOrdine'],
        'sePrezzoLimite': !runtime_1.exists(json, 'sePrezzoLimite') ? undefined : json['sePrezzoLimite'],
    };
}
exports.OrdineParametriDtoFromJSONTyped = OrdineParametriDtoFromJSONTyped;
function OrdineParametriDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codiceIsin': value.codiceIsin,
        'prezzoLimitato': value.prezzoLimitato,
        'mercato': value.mercato,
        'mercati': value.mercati === undefined ? undefined : (value.mercati.map(_1.OrdineParametriMercatoDtoToJSON)),
        'mercatiSelezione': value.mercatiSelezione,
        'descTitolo': value.descTitolo,
        'divisa': value.divisa,
        'saldoTitolo': value.saldoTitolo,
        'saldoConto': _1.OrdineParametriSaldoContoToJSON(value.saldoConto),
        'quotazioni': _1.OrdineParametriQuotazioniToJSON(value.quotazioni),
        'parametriAvanzati': _1.OrdineParametriAvanzatiToJSON(value.parametriAvanzati),
        'visualizzaSchedaTitolo': value.visualizzaSchedaTitolo,
        'visualizzaPdfKiid': value.visualizzaPdfKiid,
        'indicativoTitolo': value.indicativoTitolo,
        'titolo': value.titolo,
        'mercatiOrdine': value.mercatiOrdine,
        'sePrezzoLimite': value.sePrezzoLimite,
    };
}
exports.OrdineParametriDtoToJSON = OrdineParametriDtoToJSON;
