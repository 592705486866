"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.consensiReset = exports.consensiResetFormAction = exports.setStepConsensi = exports.getInfoAutenticazioneConsensi = exports.autorizzaConsensi = exports.controlloDisabilita = exports.getElencoConsensi = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
/* get Elenco Consensi - START */
function getElencoConsensiStart() {
    return {
        type: __1.EReduxActionTypes.ELENCO_CONSENSI_GET_CONSENSI_START
    };
}
function getElencoConsensiSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.ELENCO_CONSENSI_GET_CONSENSI_SUCCESS,
        payload
    };
}
function generalError(value, showGenericModalError) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value, showGenericModalError));
    };
}
const getElencoConsensi = () => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getElencoConsensiStart());
    if (extraArguments.mock) {
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.ConsensiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).listaConsensi().then((response) => {
                dispatch(getElencoConsensiSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getElencoConsensi = getElencoConsensi;
/* get Elenco Consensi - FINE */
/* controllo Disabilita - START */
function controlloDisabilitaStart() {
    return {
        type: __1.EReduxActionTypes.CONTROLLO_DISABILITA_CONSENSI_START
    };
}
function controlloDisabilitaSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.CONTROLLO_DISABILITA_CONSENSI_SUCCESS,
        payload
    };
}
const controlloDisabilita = (consenso) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(controlloDisabilitaStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/autorizza_rav.json')).then(parametri => {
            dispatch(controlloDisabilitaSuccess((0, api_rest_1.AutorizzaConsensoPsd2DtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const controlloDisabilitaRequest = {
            consensoPsd2Dto: consenso
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.ConsensiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).controlloDisabilita(controlloDisabilitaRequest).then((response) => {
                dispatch(controlloDisabilitaSuccess(response));
                dispatch((0, general_actions_1.setLoader)(false));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error), false));
                dispatch((0, general_actions_1.setLoader)(false));
            }).finally(() => {
                if (extraArguments.platform == 'mobile') {
                    dispatch((0, general_actions_1.setLoader)(false));
                }
            });
        });
    }
};
exports.controlloDisabilita = controlloDisabilita;
/* controllo Disabilita - FINE */
/* autorizza Consensi - START */
function autorizzaConsensiStart() {
    return {
        type: __1.EReduxActionTypes.AUTORIZZA_CONSENSI_START
    };
}
function autorizzaConsensiSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.AUTORIZZA_CONSENSI_SUCCESS,
        payload
    };
}
const autorizzaConsensi = (idDispositiva, credenziale) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(autorizzaConsensiStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/autorizza_rav.json')).then(parametri => {
            dispatch(autorizzaConsensiSuccess((0, api_rest_1.EsitoAutorizzazioneDisposizioneConsensoPsd2DtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const autorizzaConsensiRequest = {
            credenzialeDispositivaDto: credenziale,
            idDispositiva
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.ConsensiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).autorizzaConsensi(autorizzaConsensiRequest).then((response) => {
                dispatch(autorizzaConsensiSuccess(response));
                dispatch((0, general_actions_1.setLoader)(false));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error), false));
                dispatch((0, general_actions_1.setLoader)(false));
            }).finally(() => {
                if (extraArguments.platform == 'mobile') {
                    dispatch((0, general_actions_1.setLoader)(false));
                }
            });
        });
    }
};
exports.autorizzaConsensi = autorizzaConsensi;
/*  autorizza Consensi - FINE */
/* Get info autenticazione - START */
function getInfoAutenticazioneConsensiStart() {
    return {
        type: __1.EReduxActionTypes.GET_INFO_AUTENTICAZIONE_CONSENSI_START
    };
}
function getInfoAutenticazioneConsensiSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.GET_INFO_AUTENTICAZIONE_CONSENSI_SUCCESS,
        payload
    };
}
const getInfoAutenticazioneConsensi = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getInfoAutenticazioneConsensiStart());
    if (extraArguments.mock) {
    }
    else {
        const getInfoAutenticazioneConsensiRequest = {
            idDispositiva,
            chiamataUscente,
            eseguiInit,
            offline: !!offline
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.ConsensiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .informazioniAutenticazioneConsensi(getInfoAutenticazioneConsensiRequest).then((response) => {
                dispatch(getInfoAutenticazioneConsensiSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getInfoAutenticazioneConsensi = getInfoAutenticazioneConsensi;
/* Get info autenticazione - FINE */
/* Set form values - START */
function setStepConsensi(step) {
    return {
        type: __1.EReduxActionTypes.CONSENSI_SET_STEP,
        payload: step
    };
}
exports.setStepConsensi = setStepConsensi;
function consensiResetFormAction() {
    return {
        type: __1.EReduxActionTypes.CONSENSI_RESET_FORM
    };
}
exports.consensiResetFormAction = consensiResetFormAction;
function consensiReset() {
    return {
        type: __1.EReduxActionTypes.CONSENSI_RESET
    };
}
exports.consensiReset = consensiReset;
