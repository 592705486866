"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CertificatoInfoDtoToJSON = exports.CertificatoInfoDtoFromJSONTyped = exports.CertificatoInfoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function CertificatoInfoDtoFromJSON(json) {
    return CertificatoInfoDtoFromJSONTyped(json, false);
}
exports.CertificatoInfoDtoFromJSON = CertificatoInfoDtoFromJSON;
function CertificatoInfoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'sessionId': !runtime_1.exists(json, 'sessionId') ? undefined : json['sessionId'],
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'certificateId': !runtime_1.exists(json, 'certificateId') ? undefined : json['certificateId'],
        'certificateSn': !runtime_1.exists(json, 'certificateSn') ? undefined : json['certificateSn'],
        'institutionCode': !runtime_1.exists(json, 'institutionCode') ? undefined : json['institutionCode'],
        'type': !runtime_1.exists(json, 'type') ? undefined : json['type'],
        'releaseDate': !runtime_1.exists(json, 'releaseDate') ? undefined : (new Date(json['releaseDate'])),
        'activationDate': !runtime_1.exists(json, 'activationDate') ? undefined : (new Date(json['activationDate'])),
        'expireDate': !runtime_1.exists(json, 'expireDate') ? undefined : (new Date(json['expireDate'])),
        'expectedAutoRevocationDate': !runtime_1.exists(json, 'expectedAutoRevocationDate') ? undefined : (new Date(json['expectedAutoRevocationDate'])),
        'pauseDate': !runtime_1.exists(json, 'pauseDate') ? undefined : (new Date(json['pauseDate'])),
        'cellNumber': !runtime_1.exists(json, 'cellNumber') ? undefined : json['cellNumber'],
        'envelopeSerial': !runtime_1.exists(json, 'envelopeSerial') ? undefined : json['envelopeSerial'],
        'organization': !runtime_1.exists(json, 'organization') ? undefined : json['organization'],
        'organizationUnit': !runtime_1.exists(json, 'organizationUnit') ? undefined : json['organizationUnit'],
        'signerAddress': json['signerAddress'],
        'signerBirthCity': json['signerBirthCity'],
        'signerBirthCountry': !runtime_1.exists(json, 'signerBirthCountry') ? undefined : json['signerBirthCountry'],
        'signerBirthDate': json['signerBirthDate'],
        'signerBirthDistrict': !runtime_1.exists(json, 'signerBirthDistrict') ? undefined : json['signerBirthDistrict'],
        'signerCap': json['signerCap'],
        'signerCity': json['signerCity'],
        'signerCountry': json['signerCountry'],
        'signerDocumentEndDate': json['signerDocumentEndDate'],
        'signerDocumentIssuer': json['signerDocumentIssuer'],
        'signerDocumentNumber': json['signerDocumentNumber'],
        'signerDocumentType': json['signerDocumentType'],
        'signerEmail': !runtime_1.exists(json, 'signerEmail') ? undefined : json['signerEmail'],
        'signerFirstName': json['signerFirstName'],
        'signerFiscalCode': json['signerFiscalCode'],
        'signerFiscalCountry': json['signerFiscalCountry'],
        'signerLastName': json['signerLastName'],
        'signerSex': json['signerSex'],
        'title': !runtime_1.exists(json, 'title') ? undefined : json['title'],
    };
}
exports.CertificatoInfoDtoFromJSONTyped = CertificatoInfoDtoFromJSONTyped;
function CertificatoInfoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'sessionId': value.sessionId,
        'id': value.id,
        'certificateId': value.certificateId,
        'certificateSn': value.certificateSn,
        'institutionCode': value.institutionCode,
        'type': value.type,
        'releaseDate': value.releaseDate === undefined ? undefined : (value.releaseDate.toISOString()),
        'activationDate': value.activationDate === undefined ? undefined : (value.activationDate.toISOString()),
        'expireDate': value.expireDate === undefined ? undefined : (value.expireDate.toISOString()),
        'expectedAutoRevocationDate': value.expectedAutoRevocationDate === undefined ? undefined : (value.expectedAutoRevocationDate.toISOString()),
        'pauseDate': value.pauseDate === undefined ? undefined : (value.pauseDate.toISOString()),
        'cellNumber': value.cellNumber,
        'envelopeSerial': value.envelopeSerial,
        'organization': value.organization,
        'organizationUnit': value.organizationUnit,
        'signerAddress': value.signerAddress,
        'signerBirthCity': value.signerBirthCity,
        'signerBirthCountry': value.signerBirthCountry,
        'signerBirthDate': value.signerBirthDate,
        'signerBirthDistrict': value.signerBirthDistrict,
        'signerCap': value.signerCap,
        'signerCity': value.signerCity,
        'signerCountry': value.signerCountry,
        'signerDocumentEndDate': value.signerDocumentEndDate,
        'signerDocumentIssuer': value.signerDocumentIssuer,
        'signerDocumentNumber': value.signerDocumentNumber,
        'signerDocumentType': value.signerDocumentType,
        'signerEmail': value.signerEmail,
        'signerFirstName': value.signerFirstName,
        'signerFiscalCode': value.signerFiscalCode,
        'signerFiscalCountry': value.signerFiscalCountry,
        'signerLastName': value.signerLastName,
        'signerSex': value.signerSex,
        'title': value.title,
    };
}
exports.CertificatoInfoDtoToJSON = CertificatoInfoDtoToJSON;
