import React, { useEffect } from "react";
import { logoutOauth2} from "@sparkasse/commons";
import { navigateToLogin } from "component/history/history.component";
import { AppState } from "../../reducers";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { jwtToken } from "@sparkasse/commons";

const LogoutPage: React.FC<Props> = (props: Props) => {

  const {
    logoutOauth2,
    jwtToken
  } = props;

  useEffect(() => {
    logoutOauth2()
    sessionStorage.clear();
  }, []);

  useEffect(() => {
    if (!jwtToken) {
      navigateToLogin();
    }
  }, [jwtToken]);

  return null;
};

const mapStateToProps = (state: AppState) => ({
  jwtToken: jwtToken(state.login)
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      logoutOauth2,
      navigateToLogin,
    },
    dispatch
  );

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps;

export default connect(mapStateToProps, mapDispatchToProps)(LogoutPage);