"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PFMCalendarPatternDtoToJSON = exports.PFMCalendarPatternDtoFromJSONTyped = exports.PFMCalendarPatternDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function PFMCalendarPatternDtoFromJSON(json) {
    return PFMCalendarPatternDtoFromJSONTyped(json, false);
}
exports.PFMCalendarPatternDtoFromJSON = PFMCalendarPatternDtoFromJSON;
function PFMCalendarPatternDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'amount': !runtime_1.exists(json, 'amount') ? undefined : json['amount'],
        'currency': !runtime_1.exists(json, 'currency') ? undefined : json['currency'],
        'transactionType': !runtime_1.exists(json, 'transactionType') ? undefined : json['transactionType'],
        'otherAccount': !runtime_1.exists(json, 'otherAccount') ? undefined : json['otherAccount'],
        'status': !runtime_1.exists(json, 'status') ? undefined : json['status'],
        'statusDate': !runtime_1.exists(json, 'statusDate') ? undefined : json['statusDate'],
        'entryId': !runtime_1.exists(json, 'entryId') ? undefined : json['entryId'],
        'idAppuntamentoCorrispondente': !runtime_1.exists(json, 'idAppuntamentoCorrispondente') ? undefined : json['idAppuntamentoCorrispondente'],
        'operazioneAssociata': !runtime_1.exists(json, 'operazioneAssociata') ? undefined : json['operazioneAssociata'],
        'dataAppuntamentoCalendario': !runtime_1.exists(json, 'dataAppuntamentoCalendario') ? undefined : (new Date(json['dataAppuntamentoCalendario'])),
    };
}
exports.PFMCalendarPatternDtoFromJSONTyped = PFMCalendarPatternDtoFromJSONTyped;
function PFMCalendarPatternDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'amount': value.amount,
        'currency': value.currency,
        'transactionType': value.transactionType,
        'otherAccount': value.otherAccount,
        'status': value.status,
        'statusDate': value.statusDate,
        'entryId': value.entryId,
        'idAppuntamentoCorrispondente': value.idAppuntamentoCorrispondente,
        'operazioneAssociata': value.operazioneAssociata,
        'dataAppuntamentoCalendario': value.dataAppuntamentoCalendario === undefined ? undefined : (value.dataAppuntamentoCalendario.toISOString()),
    };
}
exports.PFMCalendarPatternDtoToJSON = PFMCalendarPatternDtoToJSON;
