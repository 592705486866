"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkUpdateBozzaCBill = exports.disableSalvaBozzaCBill = exports.formCBillValid = exports.buildCBill = exports.billersCBill = exports.codiciFiscaliCBill = exports.formValidForBozzaCBill = exports.bozzeCBill = exports.currentBozzaCBill = exports.salvaBozzaCBill = exports.esitoCBill = exports.infoAutenticazioneCBill = exports.riepilogoCBill = exports.formCBill = exports.parametriCBill = void 0;
const reselect_1 = require("reselect");
const helpers_1 = require("../helpers");
const cBillReducer = (state) => state;
exports.parametriCBill = (0, reselect_1.createSelector)(cBillReducer, (state) => {
    return state.parametri;
});
exports.formCBill = (0, reselect_1.createSelector)(cBillReducer, (state) => {
    return state.form;
});
exports.riepilogoCBill = (0, reselect_1.createSelector)(cBillReducer, (state) => {
    return state.riepilogo;
});
exports.infoAutenticazioneCBill = (0, reselect_1.createSelector)(cBillReducer, (state) => {
    return state.infoAutenticazioneCBill;
});
exports.esitoCBill = (0, reselect_1.createSelector)(cBillReducer, (state) => {
    return state.esito;
});
exports.salvaBozzaCBill = (0, reselect_1.createSelector)(cBillReducer, (state) => {
    return state.bozzeCBill ? state.bozzeCBill.sort((a, b) => b.id - a.id) : [];
});
exports.currentBozzaCBill = (0, reselect_1.createSelector)(cBillReducer, (state) => {
    return state.currentBozza;
});
exports.bozzeCBill = (0, reselect_1.createSelector)(cBillReducer, (state) => {
    return state.bozzeCBill ? state.bozzeCBill.sort((a, b) => b.id - a.id) : [];
});
exports.formValidForBozzaCBill = (0, reselect_1.createSelector)(cBillReducer, (state) => {
    let formValid = false;
    if (!state.form.codiceBillerError ||
        !state.form.numeroFatturaError ||
        !state.form.importoError) {
        formValid = true;
    }
    return formValid;
});
exports.codiciFiscaliCBill = (0, reselect_1.createSelector)(cBillReducer, (state) => {
    return state.codiciFiscali;
});
exports.billersCBill = (0, reselect_1.createSelector)(cBillReducer, (state) => {
    return state.billers;
});
exports.buildCBill = (0, reselect_1.createSelector)(cBillReducer, (state) => {
    var _a, _b;
    return {
        contoAddebito: ((_b = (_a = state.form.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.id) || 0,
        codiceFiscale: state.codiciFiscali[0],
        numeroFattura: state.form.numeroFattura,
        importo: (0, helpers_1.formattedNumberToNumber)(state.form.importo),
        descrizioneBiller: state.form.descrizioneBiller,
        codiceBiller: state.form.codiceBiller,
        idBozzaCollegata: state.currentBozza.id
    };
});
exports.formCBillValid = (0, reselect_1.createSelector)(cBillReducer, (state) => {
    let formValid = false;
    if (!state.form.codiceBillerError &&
        !state.form.importoError &&
        !state.form.numeroFatturaError &&
        !state.form.descrizioneBillerError) {
        formValid = true;
    }
    return formValid;
});
exports.disableSalvaBozzaCBill = (0, reselect_1.createSelector)(cBillReducer, (state) => {
    let isBozzaAccepted = !state.form.bozzaSalvata &&
        (state.form.numeroFattura && !state.form.numeroFatturaError);
    return !isBozzaAccepted;
});
exports.checkUpdateBozzaCBill = (0, reselect_1.createSelector)(cBillReducer, (state) => {
    var _a;
    return !!((_a = state.currentBozza) === null || _a === void 0 ? void 0 : _a.id);
});
