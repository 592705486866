"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ElencoMovimentiPdfCarteRicaricabiliTypeEnum = exports.CarteRicaricabiliControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class CarteRicaricabiliControllerApi extends runtime.BaseAPI {
    /**
     * Autorizzazione di una disposizione di bonifico
     */
    autorizzaRicaricaCartaRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling autorizzaRicaricaCarta.');
            }
            if (requestParameters.credenzialeDispositivaDto === null || requestParameters.credenzialeDispositivaDto === undefined) {
                throw new runtime.RequiredError('credenzialeDispositivaDto', 'Required parameter requestParameters.credenzialeDispositivaDto was null or undefined when calling autorizzaRicaricaCarta.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/cartericaricabili/autorizza/{idDispositiva}`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CredenzialeDispositivaDtoToJSON(requestParameters.credenzialeDispositivaDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.EsitoAutorizzazioneDisposizioneRicaricaCarteDtoFromJSON(jsonValue));
        });
    }
    /**
     * Autorizzazione di una disposizione di bonifico
     */
    autorizzaRicaricaCarta(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.autorizzaRicaricaCartaRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce una bozza dato l\'id per bonifico estero
     */
    bozzaRicaricaCartaRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling bozzaRicaricaCarta.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/cartericaricabili/bozze/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.RicaricaCarteDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce una bozza dato l\'id per bonifico estero
     */
    bozzaRicaricaCarta(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.bozzaRicaricaCartaRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce le bozze per ricarica carte
     */
    bozzeRicaricaCartaRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.bozzeInDto === null || requestParameters.bozzeInDto === undefined) {
                throw new runtime.RequiredError('bozzeInDto', 'Required parameter requestParameters.bozzeInDto was null or undefined when calling bozzeRicaricaCarta.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/cartericaricabili/ricarica/bozze`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.BozzeInDtoToJSON(requestParameters.bozzeInDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.RicaricheCarteDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce le bozze per ricarica carte
     */
    bozzeRicaricaCarta(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.bozzeRicaricaCartaRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Esegue il controllo di validità di una nuova ricarica carta in base ai valori specificati nell\'oggetto di richiesta
     */
    controlloRicaricaCartaRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.ricaricaCarteDto === null || requestParameters.ricaricaCarteDto === undefined) {
                throw new runtime.RequiredError('ricaricaCarteDto', 'Required parameter requestParameters.ricaricaCarteDto was null or undefined when calling controlloRicaricaCarta.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/cartericaricabili/controllo`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.RicaricaCarteDtoToJSON(requestParameters.ricaricaCarteDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.RicaricaCarteDtoFromJSON(jsonValue));
        });
    }
    /**
     * Esegue il controllo di validità di una nuova ricarica carta in base ai valori specificati nell\'oggetto di richiesta
     */
    controlloRicaricaCarta(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.controlloRicaricaCartaRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Export in pdf del dettaglio del movimento in base ai criteri inviati per il rapporto indicato
     */
    dettaglioMovimentiPdfCarteRicaricabiliRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.exportMovimentoCartaRicaricabileInDto === null || requestParameters.exportMovimentoCartaRicaricabileInDto === undefined) {
                throw new runtime.RequiredError('exportMovimentoCartaRicaricabileInDto', 'Required parameter requestParameters.exportMovimentoCartaRicaricabileInDto was null or undefined when calling dettaglioMovimentiPdfCarteRicaricabili.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/cartericaricabili/export/pdf`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ExportMovimentoCartaRicaricabileInDtoToJSON(requestParameters.exportMovimentoCartaRicaricabileInDto),
            });
            return new runtime.BlobApiResponse(response);
        });
    }
    /**
     * Export in pdf del dettaglio del movimento in base ai criteri inviati per il rapporto indicato
     */
    dettaglioMovimentiPdfCarteRicaricabili(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.dettaglioMovimentiPdfCarteRicaricabiliRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Elenco movimenti carta
     */
    elencoMovimentiCartaRicaricabileRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.elencoMovimentiFilterDto === null || requestParameters.elencoMovimentiFilterDto === undefined) {
                throw new runtime.RequiredError('elencoMovimentiFilterDto', 'Required parameter requestParameters.elencoMovimentiFilterDto was null or undefined when calling elencoMovimentiCartaRicaricabile.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/cartericaricabili/movimenti/elenco`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ElencoMovimentiFilterDtoToJSON(requestParameters.elencoMovimentiFilterDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.MovimentoCarteRicaricabileDtoFromJSON));
        });
    }
    /**
     * Elenco movimenti carta
     */
    elencoMovimentiCartaRicaricabile(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.elencoMovimentiCartaRicaricabileRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Export in pdf o excel dei movimenti in base ai criteri inviati per il rapporto indicato
     */
    elencoMovimentiPdfCarteRicaricabiliRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.type === null || requestParameters.type === undefined) {
                throw new runtime.RequiredError('type', 'Required parameter requestParameters.type was null or undefined when calling elencoMovimentiPdfCarteRicaricabili.');
            }
            if (requestParameters.elencoMovimentiFilterDto === null || requestParameters.elencoMovimentiFilterDto === undefined) {
                throw new runtime.RequiredError('elencoMovimentiFilterDto', 'Required parameter requestParameters.elencoMovimentiFilterDto was null or undefined when calling elencoMovimentiPdfCarteRicaricabili.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/cartericaricabili/movimenti/elenco/export/{type}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ElencoMovimentiFilterDtoToJSON(requestParameters.elencoMovimentiFilterDto),
            });
            return new runtime.BlobApiResponse(response);
        });
    }
    /**
     * Export in pdf o excel dei movimenti in base ai criteri inviati per il rapporto indicato
     */
    elencoMovimentiPdfCarteRicaricabili(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.elencoMovimentiPdfCarteRicaricabiliRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Eliminazione di una bozza dato l\'id
     */
    eliminaBozzaRicaricaCartaRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling eliminaBozzaRicaricaCarta.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/cartericaricabili/ricarica/bozze/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Eliminazione di una bozza dato l\'id
     */
    eliminaBozzaRicaricaCarta(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.eliminaBozzaRicaricaCartaRaw(requestParameters);
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva
     */
    informazioniAutenticazioneRicaricaCartaRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling informazioniAutenticazioneRicaricaCarta.');
            }
            const queryParameters = {};
            if (requestParameters.chiamataUscente !== undefined) {
                queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
            }
            if (requestParameters.eseguiInit !== undefined) {
                queryParameters['eseguiInit'] = requestParameters.eseguiInit;
            }
            if (requestParameters.offline !== undefined) {
                queryParameters['offline'] = requestParameters.offline;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/cartericaricabili/infoautenticazione/{idDispositiva}`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva
     */
    informazioniAutenticazioneRicaricaCarta(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.informazioniAutenticazioneRicaricaCartaRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce le operazioni per bonifico Estero
     */
    operazioniOneClickCarteRicaricabiliRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.operazioniOneClickInputDto === null || requestParameters.operazioniOneClickInputDto === undefined) {
                throw new runtime.RequiredError('operazioniOneClickInputDto', 'Required parameter requestParameters.operazioniOneClickInputDto was null or undefined when calling operazioniOneClickCarteRicaricabili.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/cartericaricabili/oneclick`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.OperazioniOneClickInputDtoToJSON(requestParameters.operazioniOneClickInputDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.OperazioniOneClickDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce le operazioni per bonifico Estero
     */
    operazioniOneClickCarteRicaricabili(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.operazioniOneClickCarteRicaricabiliRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Lettura parametri movimenti carta
     */
    parametriMovimentiCartaRicaricabileRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/cartericaricabili/movimenti/parametri`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ParametriRicaricaCarteDtoFromJSON(jsonValue));
        });
    }
    /**
     * Lettura parametri movimenti carta
     */
    parametriMovimentiCartaRicaricabile() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.parametriMovimentiCartaRicaricabileRaw();
            return yield response.value();
        });
    }
    /**
     * Lettura parametri ricarica carta
     */
    parametriRicaricaCartaRicaricabileRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/cartericaricabili/ricarica/parametri`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ParametriRicaricaCarteDtoFromJSON(jsonValue));
        });
    }
    /**
     * Lettura parametri ricarica carta
     */
    parametriRicaricaCartaRicaricabile() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.parametriRicaricaCartaRicaricabileRaw();
            return yield response.value();
        });
    }
    /**
     * Salva una bozza per ricarica carta
     */
    salvaBozzaRicaricaCartaRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.ricaricaCarteDto === null || requestParameters.ricaricaCarteDto === undefined) {
                throw new runtime.RequiredError('ricaricaCarteDto', 'Required parameter requestParameters.ricaricaCarteDto was null or undefined when calling salvaBozzaRicaricaCarta.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/cartericaricabili/ricarica/bozze/salvabozza`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.RicaricaCarteDtoToJSON(requestParameters.ricaricaCarteDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.RicaricaCarteDtoFromJSON(jsonValue));
        });
    }
    /**
     * Salva una bozza per ricarica carta
     */
    salvaBozzaRicaricaCarta(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.salvaBozzaRicaricaCartaRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Modifica di una bozza dato l\'id per bonifico Estero
     */
    updateBozzaRicaricaCartaRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling updateBozzaRicaricaCarta.');
            }
            if (requestParameters.ricaricaCarteDto === null || requestParameters.ricaricaCarteDto === undefined) {
                throw new runtime.RequiredError('ricaricaCarteDto', 'Required parameter requestParameters.ricaricaCarteDto was null or undefined when calling updateBozzaRicaricaCarta.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/cartericaricabili/bozze/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.RicaricaCarteDtoToJSON(requestParameters.ricaricaCarteDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.RicaricaCarteDtoFromJSON(jsonValue));
        });
    }
    /**
     * Modifica di una bozza dato l\'id per bonifico Estero
     */
    updateBozzaRicaricaCarta(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateBozzaRicaricaCartaRaw(requestParameters);
            return yield response.value();
        });
    }
}
exports.CarteRicaricabiliControllerApi = CarteRicaricabiliControllerApi;
/**
    * @export
    * @enum {string}
    */
var ElencoMovimentiPdfCarteRicaricabiliTypeEnum;
(function (ElencoMovimentiPdfCarteRicaricabiliTypeEnum) {
    ElencoMovimentiPdfCarteRicaricabiliTypeEnum["pdf"] = "pdf";
    ElencoMovimentiPdfCarteRicaricabiliTypeEnum["excel"] = "excel";
})(ElencoMovimentiPdfCarteRicaricabiliTypeEnum = exports.ElencoMovimentiPdfCarteRicaricabiliTypeEnum || (exports.ElencoMovimentiPdfCarteRicaricabiliTypeEnum = {}));
