import {
  NotificaMenuDto,
  ProfiloMenu,
  ProfiloMenuDto,
  selectedAbi,
} from "@sparkasse/commons";
import BankIcon from "component/Icon/icon.component";
import useHandleAreaMenu from "component/SideBarMenu/_hooks/useHandleAreaMenu";
import SidebarMenuNavigatorArea from "component/SideBarMenu/SidebarMenu-navigator-area";
import React, { useMemo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface Props {
  menu: ProfiloMenuDto;
  navigateTo: (path: string) => void;
  onChangeLanguage: (lingua: string) => void;
  currentLanguage: string;
  onLogout: () => void;
  openSubMenuArea: (items: ProfiloMenu[] | undefined, label: string) => void;
  areaLabelLvl1: string;
  isMenuComunicazioniVisible: boolean;
  isMenuProfiloVisible: boolean;
  isMenuAssistenzaVisible: boolean;
  isNotificaMenuComunicazioniVisible: boolean;
  isNotificaMenuProfiloVisible: boolean;
  isNotificaMenuAssistenzaVisible: boolean;
  areaItemsLvl1: ProfiloMenu[];
  setAreaItemsLvl1: (v: ProfiloMenu[]) => void;
  areaItemsLvl2: ProfiloMenu[];
  setAreaItemsLvl2: (v: ProfiloMenu[]) => void;
  setAreaLabelLvl1: (v: string) => void;
  areaLabelLvl2: string;
  setAreaLabelLvl2: (v: string) => void;
  notificheMenu: NotificaMenuDto[] | undefined;
  isMenuExpanded: boolean;
  setMenuExpanded: (v: boolean) => any;
  onMenu: (e?: any) => void;
}

const NavbarButtons = (props: Props) => {
  const { t } = useTranslation();

  const {
    onChangeLanguage,
    currentLanguage,
    onLogout,
    menu,
    openSubMenuArea,
    areaLabelLvl1,
    isMenuComunicazioniVisible,
    isMenuProfiloVisible,
    isMenuAssistenzaVisible,
    isNotificaMenuComunicazioniVisible,
    isNotificaMenuProfiloVisible,
    isNotificaMenuAssistenzaVisible,
    areaItemsLvl1,
    setAreaItemsLvl1,
    areaItemsLvl2,
    setAreaItemsLvl2,
    setAreaLabelLvl1,
    areaLabelLvl2,
    setAreaLabelLvl2,
    notificheMenu,
    navigateTo,
    isMenuExpanded,
    setMenuExpanded,
    onMenu,
  } = props;

  const renderLanguageTooltip = (props: any) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {t(`home.navbar.language.${props.language}`)}
      </Tooltip>
    );
  };

  const renderTooltip = (el: string) => {
    return <Tooltip id="button-tooltip">{el}</Tooltip>;
  };

  const renderLogoutTooltip = (props: any) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {t("home.navbar.logout")}
      </Tooltip>
    );
  };

  const selectedElements: [boolean, boolean, boolean] = useMemo(() => {
    return [
      areaLabelLvl1 == t("home.navbar.assistenza"),
      areaLabelLvl1 == t("home.navbar.areaPersonale"),
      areaLabelLvl1 == t("home.navbar.comunicazioni"),
    ];
  }, [areaLabelLvl1, t]);

  const {
    areaItems,
    areaLabel,
    onClickBackArea,
    onClickElement,
    resetAreaItemsAndLabels,
  } = useHandleAreaMenu({
    areaItemsLvl1,
    areaItemsLvl2,
    areaLabelLvl1,
    areaLabelLvl2,
    isMenuExpanded,
    setAreaItemsLvl1,
    setAreaItemsLvl2,
    setAreaLabelLvl1,
    setAreaLabelLvl2,
    setMenuExpanded,
    navigateTo,
    onMenu,
  });

  const clickOnWrapper = (e: any) => {
    if (e.target.className === "menu-wrapper-area") {
      resetAreaItemsAndLabels();
    }
  };

  const getAreaMenu = () => {
    console.log(areaItems, areaLabel);
    if (areaItemsLvl1.length > 0 && areaLabelLvl1?.length > 0) {
      return (
        <div className="menu-container">
          <div className="menu-wrapper-area" onClick={(e) => clickOnWrapper(e)}>
            <SidebarMenuNavigatorArea
              isFirstLevel={
                areaItemsLvl2.length == 0 && areaLabelLvl2.length == 0
              }
              items={areaItems}
              label={areaLabel}
              notificheMenu={notificheMenu}
              location="topbar-area"
              onClickBack={onClickBackArea}
              onClickElement={onClickElement}
            />
          </div>
        </div>
      );
    }
    return <></>;
  };

  return (
    <>
      <div className="header-buttons header-buttons-first-group">
        {isMenuComunicazioniVisible && (
          <>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip(t("home.navbar.comunicazioni"))}
            >
              <button
                id={"comunicazioni"}
                className={`${
                  isNotificaMenuComunicazioniVisible ? "has-notification" : ""
                } ${selectedElements[2] ? "is-selected" : ""}`}
                onClick={() =>
                  openSubMenuArea(
                    menu?.comunicazioniItems,
                    t("home.navbar.comunicazioni")
                  )
                }
                aria-label={t("home.navbar.comunicazioni")}
              >
                <BankIcon title="comunicazioni" etichetta="" />
              </button>
            </OverlayTrigger>
            {areaLabelLvl1 === t("home.navbar.comunicazioni") && getAreaMenu()}
          </>
        )}

        {isMenuProfiloVisible && (
          <>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip(t("home.navbar.areaPersonale"))}
            >
              <button
                id={"profilo"}
                className={`${
                  isNotificaMenuProfiloVisible ? "has-notification" : ""
                } ${selectedElements[1] ? "is-selected" : ""}`}
                onClick={() =>
                  openSubMenuArea(
                    menu?.profiloItems,
                    t("home.navbar.areaPersonale")
                  )
                }
                aria-label={t("home.navbar.areaPersonale")}
              >
                <BankIcon title="profilo" etichetta="" />
              </button>
            </OverlayTrigger>
            {areaLabelLvl1 === t("home.navbar.areaPersonale") && getAreaMenu()}
          </>
        )}

        {isMenuAssistenzaVisible && (
          <>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip(t("home.navbar.assistenza"))}
            >
              <button
                id={"assistenza"}
                className={`${
                  isNotificaMenuAssistenzaVisible ? "has-notification" : ""
                } ${selectedElements[0] ? "is-selected" : ""}`}
                onClick={() =>
                  openSubMenuArea(
                    menu?.assistenzaItems,
                    t("home.navbar.assistenza")
                  )
                }
                aria-label={t("home.navbar.assistenza")}
              >
                <BankIcon title="assistenza" etichetta="" />
              </button>
            </OverlayTrigger>
            {areaLabelLvl1 === t("home.navbar.assistenza") && getAreaMenu()}
          </>
        )}
      </div>

      <div className="header-buttons header-buttons-second-group">
        {selectedAbi === "06045" && (
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 250 }}
            overlay={renderLanguageTooltip({
              language: currentLanguage === "it" ? "de" : "it",
            })}
          >
            <button
              className="header-change-language"
              onClick={() =>
                onChangeLanguage(currentLanguage === "it" ? "de" : "it")
              }
              aria-label={t(
                `home.navbar.language.${currentLanguage === "it" ? "de" : "it"}`
              )}
            >
              <BankIcon
                title={`lingua-${currentLanguage === "it" ? "de" : "it"}`}
                etichetta=""
              />
            </button>
          </OverlayTrigger>
        )}

        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderLogoutTooltip}
        >
          <button
            className="header-logout"
            onClick={onLogout}
            aria-label={t("home.navbar.logout")}
          >
            <BankIcon title="logout" etichetta="" white />
          </button>
        </OverlayTrigger>
      </div>
    </>
  );
};

export default NavbarButtons;
