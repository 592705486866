"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NeosurancePoliciesOutDtoToJSON = exports.NeosurancePoliciesOutDtoFromJSONTyped = exports.NeosurancePoliciesOutDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function NeosurancePoliciesOutDtoFromJSON(json) {
    return NeosurancePoliciesOutDtoFromJSONTyped(json, false);
}
exports.NeosurancePoliciesOutDtoFromJSON = NeosurancePoliciesOutDtoFromJSON;
function NeosurancePoliciesOutDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nomePolizza': !runtime_1.exists(json, 'nomePolizza') ? undefined : json['nomePolizza'],
        'numeroPolizza': !runtime_1.exists(json, 'numeroPolizza') ? undefined : json['numeroPolizza'],
        'attivaDa': !runtime_1.exists(json, 'attivaDa') ? undefined : (new Date(json['attivaDa'])),
        'attivaA': !runtime_1.exists(json, 'attivaA') ? undefined : (new Date(json['attivaA'])),
        'validaDa': !runtime_1.exists(json, 'validaDa') ? undefined : (new Date(json['validaDa'])),
        'validaA': !runtime_1.exists(json, 'validaA') ? undefined : (new Date(json['validaA'])),
        'nomeCognomeContraente': !runtime_1.exists(json, 'nomeCognomeContraente') ? undefined : json['nomeCognomeContraente'],
        'nomeCognomeCompanions': !runtime_1.exists(json, 'nomeCognomeCompanions') ? undefined : json['nomeCognomeCompanions'],
        'codiceFiscaleCompanions': !runtime_1.exists(json, 'codiceFiscaleCompanions') ? undefined : json['codiceFiscaleCompanions'],
        'fineSegnalazionePolizza': !runtime_1.exists(json, 'fineSegnalazionePolizza') ? undefined : (new Date(json['fineSegnalazionePolizza'])),
        'setInformativo': !runtime_1.exists(json, 'setInformativo') ? undefined : json['setInformativo'],
        'allegato': !runtime_1.exists(json, 'allegato') ? undefined : json['allegato'],
        'certificatoDiPolizza': !runtime_1.exists(json, 'certificatoDiPolizza') ? undefined : json['certificatoDiPolizza'],
        'descrizione': !runtime_1.exists(json, 'descrizione') ? undefined : json['descrizione'],
        'premio': !runtime_1.exists(json, 'premio') ? undefined : json['premio'],
        'currency': !runtime_1.exists(json, 'currency') ? undefined : json['currency'],
        'email': !runtime_1.exists(json, 'email') ? undefined : json['email'],
        'codiceFiscale': !runtime_1.exists(json, 'codiceFiscale') ? undefined : json['codiceFiscale'],
        'indirizzo': !runtime_1.exists(json, 'indirizzo') ? undefined : json['indirizzo'],
        'mobile': !runtime_1.exists(json, 'mobile') ? undefined : json['mobile'],
        'urlPolizza': !runtime_1.exists(json, 'urlPolizza') ? undefined : json['urlPolizza'],
        'microchip': !runtime_1.exists(json, 'microchip') ? undefined : json['microchip'],
        'periodo': !runtime_1.exists(json, 'periodo') ? undefined : json['periodo'],
        'petName': !runtime_1.exists(json, 'petName') ? undefined : json['petName'],
        'petBirthdate': !runtime_1.exists(json, 'petBirthdate') ? undefined : _1.NeosurancePetBirthdayDtoFromJSON(json['petBirthdate']),
        'who': !runtime_1.exists(json, 'who') ? undefined : json['who'],
    };
}
exports.NeosurancePoliciesOutDtoFromJSONTyped = NeosurancePoliciesOutDtoFromJSONTyped;
function NeosurancePoliciesOutDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'nomePolizza': value.nomePolizza,
        'numeroPolizza': value.numeroPolizza,
        'attivaDa': value.attivaDa === undefined ? undefined : (value.attivaDa.toISOString()),
        'attivaA': value.attivaA === undefined ? undefined : (value.attivaA.toISOString()),
        'validaDa': value.validaDa === undefined ? undefined : (value.validaDa.toISOString()),
        'validaA': value.validaA === undefined ? undefined : (value.validaA.toISOString()),
        'nomeCognomeContraente': value.nomeCognomeContraente,
        'nomeCognomeCompanions': value.nomeCognomeCompanions,
        'codiceFiscaleCompanions': value.codiceFiscaleCompanions,
        'fineSegnalazionePolizza': value.fineSegnalazionePolizza === undefined ? undefined : (value.fineSegnalazionePolizza.toISOString()),
        'setInformativo': value.setInformativo,
        'allegato': value.allegato,
        'certificatoDiPolizza': value.certificatoDiPolizza,
        'descrizione': value.descrizione,
        'premio': value.premio,
        'currency': value.currency,
        'email': value.email,
        'codiceFiscale': value.codiceFiscale,
        'indirizzo': value.indirizzo,
        'mobile': value.mobile,
        'urlPolizza': value.urlPolizza,
        'microchip': value.microchip,
        'periodo': value.periodo,
        'petName': value.petName,
        'petBirthdate': _1.NeosurancePetBirthdayDtoToJSON(value.petBirthdate),
        'who': value.who,
    };
}
exports.NeosurancePoliciesOutDtoToJSON = NeosurancePoliciesOutDtoToJSON;
