"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TypeNoRapporti = void 0;
var TypeNoRapporti;
(function (TypeNoRapporti) {
    TypeNoRapporti["BILANCIO"] = "bilancio";
    TypeNoRapporti["SALDO_MOVIMENTI"] = "saldo_movimenti";
    TypeNoRapporti["DEPOBOX"] = "depobox";
    TypeNoRapporti["ANATOCISMO"] = "anatocismo";
    TypeNoRapporti["ASSEGNI"] = "assegni";
    TypeNoRapporti["ADDEBITI_DIRETTI"] = "addebiti_diretti";
    TypeNoRapporti["BOLLO_AUTO"] = "bollo_auto";
    TypeNoRapporti["TRIBUTI_LOCALI"] = "tributi_locali";
    TypeNoRapporti["RAV"] = "rav";
    TypeNoRapporti["MAV"] = "mav";
    TypeNoRapporti["RICARICA_SMARTCARD"] = "ricarica_smartcard";
    TypeNoRapporti["MOV_CONTO_DEPOSITO"] = "mov_conto_deposito";
    TypeNoRapporti["ELENCO_DEPO_VINCOLATI"] = "elenco_depo_vincolati";
    TypeNoRapporti["MUTUI"] = "mutui";
    TypeNoRapporti["FIDO"] = "fido";
    TypeNoRapporti["ELENCO_PAG"] = "elenco_pag";
    TypeNoRapporti["ELENCO_PAG_RIPETIVI"] = "elenco_pag_ripetitivi";
    TypeNoRapporti["BONIFICI_SEPA"] = "bonifici_sepa";
    TypeNoRapporti["BONIFICI_DETRAZIONI"] = "bonifici_detrazioni";
    TypeNoRapporti["BONIFICI_RIPETITIVI"] = "bonifici_ripetitivi";
    TypeNoRapporti["CBILL"] = "cbill";
    TypeNoRapporti["F24"] = "f24";
    TypeNoRapporti["RICARICA_TELEFONICA"] = "ricarica_telefonica";
    TypeNoRapporti["RICARICA_ALTOADIGE"] = "ricarica_altoadige";
    TypeNoRapporti["TRASFERIMENTO_BONIFICO"] = "trasferimento_bonifico";
    TypeNoRapporti["POLIZZE_INVESTIMENTI"] = "polizze_investimenti";
    TypeNoRapporti["PRESTITI"] = "prestiti";
    TypeNoRapporti["POLIZZE_ASSICURAZIONI"] = "polizze_assicurazioni";
    TypeNoRapporti["GENERIC"] = "generic";
    TypeNoRapporti["ADV"] = "adv";
    TypeNoRapporti["BONIFICI_ESTERI"] = "bonifici_esteri";
    TypeNoRapporti["RICARICA_CARTA"] = "ricarica_carta";
})(TypeNoRapporti = exports.TypeNoRapporti || (exports.TypeNoRapporti = {}));
