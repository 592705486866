"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.oneclickRicaricaAltoAdigePass = exports.esitoAltoAdigePass = exports.riepilogoAltoAdigePass = exports.infoDispositivaAltoAdigePass = exports.datiVerificaAltoAdigePass = exports.formValidAltoAdigePass = exports.buildVerificaNumeroAltoAdigePass = exports.buildRicaricaAltoAdigePass = exports.parametriRicaricaAltoAdigePass = exports.formAltoAdigePass = void 0;
const reselect_1 = require("reselect");
const helpers_1 = require("../helpers");
const altoAdigePass_types_1 = require("./altoAdigePass.types");
const altoAdigePassReducer = (state) => state;
exports.formAltoAdigePass = (0, reselect_1.createSelector)(altoAdigePassReducer, (state) => {
    return state.form;
});
exports.parametriRicaricaAltoAdigePass = (0, reselect_1.createSelector)(altoAdigePassReducer, (state) => {
    return state.parametri;
});
exports.buildRicaricaAltoAdigePass = (0, reselect_1.createSelector)(altoAdigePassReducer, (state) => {
    var _a, _b, _c, _d, _e, _f, _g;
    return {
        ...state.datiVerifica,
        codiceRapporto: ((_b = (_a = state.form.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.id) || 0,
        pan: state.form.numeroAltoAdigePass,
        nomeBeneficiario: state.form.nomeBeneficiario,
        tipoOperazione: state.form.tipoOperazione,
        importoRicarica: (0, helpers_1.formattedNumberToNumber)(state.form.taglioRicarica),
        idContattoBeneficiario: state.form.idContattoBeneficiario,
        aggiungiRubrica: state.form.aggiungiBeneficiarioRubrica,
        aggiungiWhitelist: state.form.beneficiarioAffidabile,
        idOneClickCollegata: state.form.idOneClickCollegata,
        aggiungi1click: state.form.tipoOperazione === 'RIC' ? state.form.aggiungi1click : false,
        alias1Click: state.form.tipoOperazione === 'RIC' ? state.form.alias1Click : undefined,
        contoAddebitoIban: ((_d = (_c = state.form.rapportoSelezionato) === null || _c === void 0 ? void 0 : _c.rapporto) === null || _d === void 0 ? void 0 : _d.iban) || '',
        ordinanteIntestazione: (_g = (_f = (_e = state.form.rapportoSelezionato) === null || _e === void 0 ? void 0 : _e.rapporto) === null || _f === void 0 ? void 0 : _f.intestazione) !== null && _g !== void 0 ? _g : ''
    };
});
exports.buildVerificaNumeroAltoAdigePass = (0, reselect_1.createSelector)(altoAdigePassReducer, (state) => {
    var _a, _b;
    return {
        codiceRapporto: ((_b = (_a = state.form.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.id) || 0,
        pan: state.form.numeroAltoAdigePass
    };
});
exports.formValidAltoAdigePass = (0, reselect_1.createSelector)(altoAdigePassReducer, (state) => {
    let formValid = false;
    if (state.form.tipoOperazione) {
        switch (state.form.tipoOperazione) {
            case altoAdigePass_types_1.TipoOperazioneRicaricaAltoAdigePass.RIC:
                if (!state.form.numeroAltoAdigePassError &&
                    !state.form.taglioRicaricaError &&
                    !state.form.aliasError &&
                    !(state.form.aggiungiBeneficiarioRubrica && state.form.nomeBeneficiarioError)) {
                    formValid = true;
                }
                break;
            case altoAdigePass_types_1.TipoOperazioneRicaricaAltoAdigePass.RIN:
            case altoAdigePass_types_1.TipoOperazioneRicaricaAltoAdigePass.ATT:
            default:
                if (!state.form.numeroAltoAdigePassError &&
                    !state.form.aliasError) {
                    formValid = true;
                }
        }
    }
    return formValid;
});
exports.datiVerificaAltoAdigePass = (0, reselect_1.createSelector)(altoAdigePassReducer, (state) => {
    return state.datiVerifica;
});
exports.infoDispositivaAltoAdigePass = (0, reselect_1.createSelector)(altoAdigePassReducer, (state) => {
    return state.infoDispositiva;
});
exports.riepilogoAltoAdigePass = (0, reselect_1.createSelector)(altoAdigePassReducer, (state) => {
    return state.riepilogo;
});
exports.esitoAltoAdigePass = (0, reselect_1.createSelector)(altoAdigePassReducer, (state) => {
    return state.esito;
});
exports.oneclickRicaricaAltoAdigePass = (0, reselect_1.createSelector)(altoAdigePassReducer, (state) => {
    return state.oneclick;
});
