"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTipoDocumentoPostlogin = exports.getListaComuniPostlogin = exports.getParametriPostlogin = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
const postlogin_types_1 = require("./postlogin.types");
function getParametriPostloginStart() {
    return {
        type: __1.EReduxActionTypes.POSTLOGIN_GET_PARAMETRI_START
    };
}
function getParametriPostloginSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.POSTLOGIN_GET_PARAMETRI_SUCCESS,
        payload
    };
}
function getParametriPostloginError(value) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value));
    };
}
function generalError(value, showGenericModalError) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value, showGenericModalError));
    };
}
const getParametriPostlogin = () => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getParametriPostloginStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/parametri_postlogin.json')).then(parametri => {
            dispatch(getParametriPostloginSuccess((0, postlogin_types_1.ParametriPostloginFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.PostLoginControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).postlogin().then((response) => {
                dispatch(getParametriPostloginSuccess(response));
            }).catch(async (error) => {
                dispatch(getParametriPostloginError(await (0, helpers_1.formatError)(error)));
                dispatch((0, general_actions_1.setPostLoginError)(true));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getParametriPostlogin = getParametriPostlogin;
function getListaComuniPostloginStart() {
    return {
        type: __1.EReduxActionTypes.POSTLOGIN_GET_LISTA_COMUNI_START
    };
}
function getListaComuniPostloginSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.POSTLOGIN_GET_LISTA_COMUNI_SUCCESS,
        payload
    };
}
const getListaComuniPostlogin = (provincia) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getListaComuniPostloginStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.PostLoginControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).recuperaComuniPerProvincia(provincia).then((response) => {
                dispatch(getListaComuniPostloginSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
                dispatch((0, general_actions_1.setPostLoginError)(true));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getListaComuniPostlogin = getListaComuniPostlogin;
function getTipoDocumentoPostloginStart() {
    return {
        type: __1.EReduxActionTypes.POSTLOGIN_GET_TIPO_DOCUMENTO_START
    };
}
function getTipoDocumentoPostloginSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.POSTLOGIN_GET_TIPO_DOCUMENTO_SUCCESS,
        payload
    };
}
const getTipoDocumentoPostlogin = () => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getTipoDocumentoPostloginStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.PostLoginControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).recuperaListaTipoDocumentiRiconoscimento().then((response) => {
                dispatch(getTipoDocumentoPostloginSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
                dispatch((0, general_actions_1.setPostLoginError)(true));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getTipoDocumentoPostlogin = getTipoDocumentoPostlogin;
