"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.urlMilanoFinanza = exports.datiGraficoMF = exports.formFinanzaAsFiltroTitoli = exports.formFinanza = exports.titoliInScadenzaFinanza = exports.parametriAvanzatiFinanza = exports.parametriFinanza = exports.parametriTradingFinanza = void 0;
const moment = require("moment");
const reselect_1 = require("reselect");
const finanza_types_1 = require("./finanza.types");
const finanzaReducer = (state) => state;
exports.parametriTradingFinanza = (0, reselect_1.createSelector)(finanzaReducer, (state) => {
    return state.parametriTrading;
});
exports.parametriFinanza = (0, reselect_1.createSelector)(finanzaReducer, (state) => {
    return state.parametri;
});
exports.parametriAvanzatiFinanza = (0, reselect_1.createSelector)(finanzaReducer, (state) => {
    return state.parametriAvanzati;
});
exports.titoliInScadenzaFinanza = (0, reselect_1.createSelector)(finanzaReducer, (state) => {
    return state.titoliInScadenza;
});
exports.formFinanza = (0, reselect_1.createSelector)(finanzaReducer, (state) => {
    return state.form;
});
exports.formFinanzaAsFiltroTitoli = (0, reselect_1.createSelector)(finanzaReducer, (state) => {
    var _a, _b, _c, _d, _e;
    return {
        codiceRapporto: ((_c = (_b = (_a = state === null || state === void 0 ? void 0 : state.form) === null || _a === void 0 ? void 0 : _a.rapportoSelezionato) === null || _b === void 0 ? void 0 : _b.rapporto) === null || _c === void 0 ? void 0 : _c.id) || 0,
        dataFinale: ((_d = state === null || state === void 0 ? void 0 : state.form) === null || _d === void 0 ? void 0 : _d.dataFinale) ? new Date(state.form.dataFinale) : undefined,
        causali: ((_e = state === null || state === void 0 ? void 0 : state.form) === null || _e === void 0 ? void 0 : _e.causale) ? state.form.causale : ['XH', 'XQ', 'XC', 'XD', 'XY']
    };
});
exports.datiGraficoMF = (0, reselect_1.createSelector)(finanzaReducer, (state) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const now = state.datiGrafico.aggiornamento || (new Date());
    const giorno = (_b = (_a = state.datiGrafico.datiIntraday) === null || _a === void 0 ? void 0 : _a.dati) === null || _b === void 0 ? void 0 : _b.map(d => ({ ...d, x: d.data ? moment(d.data).format("HH:mm") : "-" }));
    const ora1 = giorno === null || giorno === void 0 ? void 0 : giorno.filter(d => (d.data || now) <= moment(now).subtract(1, "hours").toDate());
    const ora4 = giorno === null || giorno === void 0 ? void 0 : giorno.filter(d => (d.data || now) <= moment(now).subtract(4, "hours").toDate());
    return {
        ...state.datiGrafico,
        datiGiornoPresenti: giorno ? !!(giorno === null || giorno === void 0 ? void 0 : giorno.length) : undefined,
        datiOraPresenti: ora1 ? !!(ora1 === null || ora1 === void 0 ? void 0 : ora1.length) : undefined,
        dati4OrePresenti: ora4 ? !!(ora4 === null || ora4 === void 0 ? void 0 : ora4.length) : undefined,
        graficoCorrente: state.datiGrafico.tipoGrafico === finanza_types_1.TipoGraficoTitoli.Ore1 ? ora1 :
            state.datiGrafico.tipoGrafico === finanza_types_1.TipoGraficoTitoli.Ore4 ? ora4 :
                state.datiGrafico.tipoGrafico === finanza_types_1.TipoGraficoTitoli.Giorni1 ? giorno :
                    (_h = (_g = (_f = (state.datiGrafico.tipoGrafico === finanza_types_1.TipoGraficoTitoli.Settimana1 ? (_c = state.datiGrafico.datiSettimana) === null || _c === void 0 ? void 0 : _c.dati :
                        state.datiGrafico.tipoGrafico === finanza_types_1.TipoGraficoTitoli.Mese1 ? (_d = state.datiGrafico.datiMese) === null || _d === void 0 ? void 0 : _d.dati :
                            (_e = state.datiGrafico.datiAnno) === null || _e === void 0 ? void 0 : _e.dati)) === null || _f === void 0 ? void 0 : _f.map(d => ({ data: d.data, valore: d.prezzoRif || d.prezzoUff || d.ultimoPrezzo || d.apertura || d.chiusura }))) === null || _g === void 0 ? void 0 : _g.sort((a, b) => (a.data && b.data && a.data < b.data ? -1 : 1))) === null || _h === void 0 ? void 0 : _h.map(d => ({ ...d, x: d.data ? moment(d.data).format("DD/MM/YY") : "-" }))
    };
});
exports.urlMilanoFinanza = (0, reselect_1.createSelector)(finanzaReducer, (state) => {
    return state.form.mfUrl;
});
