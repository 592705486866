"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.datiFilialeReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const initialState = {
    datiFiliali: (0, api_rest_1.DatiFilialeDtoFromJSON)({})
};
const datiFilialeReducer = (state = initialState, action) => {
    switch (action.type) {
        case __1.EReduxActionTypes.DATI_FILIALE_GET_DATI_SUCCESS:
            return {
                ...state,
                datiFiliali: action.payload
            };
        case __1.EReduxActionTypes.RESET_ALL: // action invocata dalla logout
            return {
                ...initialState
            };
        default:
            return state;
    }
};
exports.datiFilialeReducer = datiFilialeReducer;
