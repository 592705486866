"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.profiloReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_1 = require("../general");
const helpers_1 = require("../helpers");
const initialState = {
    esito: (0, api_rest_1.EsitoAutorizzazioneDisposizioneDtoFromJSON)({}),
    infoDispositiva: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
    profilo: (0, api_rest_1.ProfiloDtoFromJSON)({}),
    esitoImpostaAlias: (0, api_rest_1.EsitoAutorizzazioneDisposizioneProfiloDtoFromJSON)({}),
    infoDispositivaImpostaAlias: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
    disposizioneVirtualeGestioneCredenziali: (0, api_rest_1.DatiAnagraficiDtoFromJSON)({}),
    esitoModificaPassword: (0, api_rest_1.EsitoAutorizzazioneDisposizioneCredenzialiDtoFromJSON)({}),
    infoDispositivaModificaPassword: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
    credenziali: (0, api_rest_1.CredenzialiDtoFromJSON)({}),
    parametri: (0, api_rest_1.ParametriProfiloDtoFromJSON)({}),
    infoSessione: {
        backgroundDate: undefined,
        lunghezzaSessione: general_1.Environments[(0, general_1.getCustomEnv)() || general_1.selectedEnv].sessionLifetime
    },
    preferenzeUtente: undefined,
    tour: {
        hiddenHashes: undefined,
        currentCodiceFunzione: undefined,
        tour: {}
    },
    form: {
        stepImpostaAlias: 0,
        stepModificaPassword: 0,
        stepModificaPin: 0,
        stepModificaBiometria: 0,
        codiceAlias: '',
        codiceAliasDirty: false,
        codiceAliasError: 'invalid.required',
        telefonoFisso: (0, api_rest_1.TelefonoFissoDtoFromJSON)({}),
        indirizzoResidenza: (0, api_rest_1.IndirizzoAnagrafeDtoFromJSON)({}),
        indirizzoDomicilio: (0, api_rest_1.IndirizzoAnagrafeDtoFromJSON)({}),
        credentials: (0, api_rest_1.CredenzialeDispositivaDtoFromJSON)({}),
        rapporti: [],
        formSubmitted: false,
        infoImpostaAliasRecuperata: false,
        oldPassword: '',
        oldPasswordDirty: false,
        oldPasswordError: 'invalid.required',
        newPassword: '',
        newPasswordDirty: false,
        newPasswordError: 'invalid.required',
        confirmPassword: '',
        confirmPasswordDirty: false,
        confirmPasswordError: 'invalid.required',
        oldPIN: '',
        oldPINDirty: false,
        oldPINError: 'invalid.required',
        newPIN: '',
        newPINDirty: false,
        newPINError: 'invalid.required',
        confirmPIN: '',
        confirmPINDirty: false,
        confirmPINError: 'invalid.required',
        infoModificaPasswordRecuperata: false
    },
    jwtCambioPin: undefined,
    bloccoDatiBinario: undefined
};
const profiloReducer = (state = initialState, action) => {
    var _a, _b;
    switch (action.type) {
        case __1.EReduxActionTypes.PROFILO_GET_INFO_DISPOSITIVA_SUCCESS:
            return {
                ...state,
                infoDispositiva: action.payload,
                esito: initialState.esito
            };
        case __1.EReduxActionTypes.PROFILO_AUTORIZZA_BLOCCO_SUCCESS:
            if (action.payload.stato === 'OK') {
                return {
                    ...state,
                    //riepilogo: initialState.riepilogo,
                    esito: action.payload
                };
            }
            else {
                return {
                    ...state,
                    infoDispositiva: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoDispositiva, action.payload.informazioniAutenticazioneDispositiva),
                    esito: action.payload
                };
            }
        case __1.EReduxActionTypes.PROFILO_GET_PROFILO_SUCCESS:
            return {
                ...state,
                profilo: action.payload
            };
        case __1.EReduxActionTypes.PROFILO_GESTIONE_CREDENZIALI_SET_ID_VIRTUALE:
            return {
                ...state,
                form: {
                    ...state.form,
                    idVirtuale: action.payload
                }
            };
        case __1.EReduxActionTypes.PROFILO_GESTIONE_CREDENZIALI_SET_CODICE_ALIAS:
            return {
                ...state,
                form: {
                    ...state.form,
                    codiceAlias: action.payload,
                    codiceAliasDirty: true,
                    codiceAliasError: (0, helpers_1.validationField)(action.payload, '', false, 6, 20)
                }
            };
        case __1.EReduxActionTypes.PROFILO_GESTIONE_CREDENZIALI_SET_MODO_AUTENTICAZIONE:
            return {
                ...state,
                form: {
                    ...state.form,
                    modoAutenticazione: action.payload
                }
            };
        case __1.EReduxActionTypes.PROFILO_GESTIONE_CREDENZIALI_SET_CELULLARE:
            return {
                ...state,
                form: {
                    ...state.form,
                    cellulare: action.payload
                }
            };
        case __1.EReduxActionTypes.PROFILO_GESTIONE_CREDENZIALI_SET_EMAIL:
            return {
                ...state,
                form: {
                    ...state.form,
                    email: action.payload
                }
            };
        case __1.EReduxActionTypes.PROFILO_GESTIONE_CREDENZIALI_SET_TELEFONO_FISSO:
            return {
                ...state,
                form: {
                    ...state.form,
                    telefonoFisso: action.payload
                }
            };
        case __1.EReduxActionTypes.PROFILO_GESTIONE_CREDENZIALI_SET_INDIRIZZO_RESIDENZA:
            return {
                ...state,
                form: {
                    ...state.form,
                    indirizzoResidenza: action.payload
                }
            };
        case __1.EReduxActionTypes.PROFILO_GESTIONE_CREDENZIALI_SET_INDIRIZZO_DOMICILIO:
            return {
                ...state,
                form: {
                    ...state.form,
                    indirizzoDomicilio: action.payload
                }
            };
        case __1.EReduxActionTypes.PROFILO_GESTIONE_CREDENZIALI_SET_RAPPORTI:
            return {
                ...state,
                form: {
                    ...state.form,
                    rapporti: action.payload
                }
            };
        case __1.EReduxActionTypes.PROFILO_GESTIONE_CREDENZIALI_FORM_SUBMITTED:
            return {
                ...state,
                form: {
                    ...state.form,
                    formSubmitted: action.payload
                }
            };
        case __1.EReduxActionTypes.PROFILO_GESTIONE_CREDENZIALI_RESET:
            return {
                ...initialState
            };
        case __1.EReduxActionTypes.PROFILO_IMPOSTA_ALIAS_STEP:
            return {
                ...state,
                form: {
                    ...state.form,
                    stepImpostaAlias: action.payload
                }
            };
        /* da Davide Zanelotto:
         Abbiamo effettuato la verifica con Cedacri e condiviso gli esiti al nostro interno, in sintesi oauth2 ha le seguenti regole attive:
          durata massima in giorni = 780
          lunghezza minima = 8
          lunghezza massima = 20
          numero massimo di tentativi errati = 6
          obbligo di cambio password al primo accesso
          Abbiamo condiviso di farvi impostare sulla funzione di cambio psw a frontend le stesse regole, tenuto conto che il sistema é protetto da SCA.
         */
        case __1.EReduxActionTypes.PROFILO_MODIFICA_PASSWORD_SET_VECCHIA_PASSWORD:
            return {
                ...state,
                form: {
                    ...state.form,
                    oldPassword: action.payload,
                    oldPasswordDirty: true,
                    oldPasswordError: (0, helpers_1.validationPassword)(action.payload, 4, 20)
                }
            };
        case __1.EReduxActionTypes.PROFILO_MODIFICA_PASSWORD_SET_NUOVO_PASSWORD:
            return {
                ...state,
                form: {
                    ...state.form,
                    newPassword: action.payload,
                    newPasswordDirty: true,
                    newPasswordError: (0, helpers_1.validationPassword)(action.payload, 8, 20, state.form.oldPassword)
                }
            };
        case __1.EReduxActionTypes.PROFILO_MODIFICA_PASSWORD_SET_CONFERMA_PASSWORD:
            return {
                ...state,
                form: {
                    ...state.form,
                    confirmPassword: action.payload,
                    confirmPasswordDirty: true,
                    confirmPasswordError: (0, helpers_1.validationPassword)(action.payload, 8, 20, '', state.form.newPassword)
                }
            };
        case __1.EReduxActionTypes.PROFILO_MODIFICA_PIN_SET_VECCHIO_PIN:
            return {
                ...state,
                form: {
                    ...state.form,
                    oldPIN: action.payload,
                    oldPINDirty: true,
                    oldPINError: (0, helpers_1.validationPIN)(action.payload, 5)
                }
            };
        case __1.EReduxActionTypes.PROFILO_MODIFICA_PIN_SET_NUOVO_PIN:
            return {
                ...state,
                form: {
                    ...state.form,
                    newPIN: action.payload,
                    newPINDirty: true,
                    newPINError: (0, helpers_1.validationPIN)(action.payload, 5, state.form.oldPIN)
                }
            };
        case __1.EReduxActionTypes.PROFILO_MODIFICA_PIN_SET_CONFERMA_PIN:
            return {
                ...state,
                form: {
                    ...state.form,
                    confirmPIN: action.payload,
                    confirmPINDirty: true,
                    confirmPINError: (0, helpers_1.validationPIN)(action.payload, 5, '', state.form.newPIN)
                }
            };
        case __1.EReduxActionTypes.PROFILO_IMPOSTA_PIN_STEP:
            return {
                ...state,
                form: {
                    ...state.form,
                    stepModificaPin: action.payload
                }
            };
        case __1.EReduxActionTypes.PROFILO_IMPOSTA_ALIAS_CONTROLLO_SUCCESS:
            return {
                ...state,
                disposizioneVirtualeGestioneCredenziali: action.payload
            };
        case __1.EReduxActionTypes.PROFILO_IMPOSTA_ALIAS_GET_INFO_DISPOSITIVA_SUCCESS:
            return {
                ...state,
                infoDispositivaImpostaAlias: action.payload,
                esitoImpostaAlias: initialState.esitoImpostaAlias,
                form: {
                    ...state.form,
                    infoImpostaAliasRecuperata: true
                }
            };
        case __1.EReduxActionTypes.PROFILO_IMPOSTA_ALIAS_AUTORIZZA_SUCCESS:
            if (action.payload.stato === 'OK') {
                return {
                    ...state,
                    esitoImpostaAlias: action.payload,
                    form: {
                        ...state.form,
                        infoImpostaAliasRecuperata: initialState.form.infoImpostaAliasRecuperata
                    }
                };
            }
            else {
                return {
                    ...state,
                    infoDispositivaImpostaAlias: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoDispositivaImpostaAlias, action.payload.informazioniAutenticazioneDispositiva),
                    esitoImpostaAlias: action.payload
                };
            }
        case __1.EReduxActionTypes.PROFILO_MODIFICA_PASSWORD_STEP:
            return {
                ...state,
                form: {
                    ...state.form,
                    stepModificaPassword: action.payload
                }
            };
        case __1.EReduxActionTypes.PROFILO_MODIFICA_PASSWORD_GET_INFO_DISPOSITIVA_SUCCESS:
            return {
                ...state,
                infoDispositivaModificaPassword: action.payload,
                esitoModificaPassword: initialState.esitoModificaPassword,
                form: {
                    ...state.form,
                    infoModificaPasswordRecuperata: true
                }
            };
        case __1.EReduxActionTypes.PROFILO_MODIFICA_PASSWORD_CREDENZIALI_MODIFICABILI_SUCCESS:
            return {
                ...state,
                credenziali: action.payload
            };
        case __1.EReduxActionTypes.PROFILO_MODIFICA_PASSWORD_AUTORIZZA_SUCCESS:
            if (action.payload.stato === 'OK') {
                return {
                    ...state,
                    esitoModificaPassword: action.payload,
                    form: {
                        ...state.form,
                        infoModificaPasswordRecuperata: initialState.form.infoModificaPasswordRecuperata
                    }
                };
            }
            else {
                return {
                    ...state,
                    infoDispositivaModificaPassword: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoDispositivaModificaPassword, action.payload.informazioniAutenticazioneDispositiva),
                    esitoModificaPassword: action.payload
                };
            }
        case __1.EReduxActionTypes.PROFILO_GET_PARAMETRI_PROFILO_SUCCESS:
            return {
                ...state,
                parametri: action.payload
            };
        case __1.EReduxActionTypes.PROFILO_SET_HIDDEN_TOUR_HASHES:
            return {
                ...state,
                tour: {
                    ...state.tour,
                    hiddenHashes: action.payload
                }
            };
        case __1.EReduxActionTypes.PROFILO_GET_TOUR_SUCCESS:
            return {
                ...state,
                tour: {
                    ...state.tour,
                    currentCodiceFunzione: (((_b = (_a = action.tour) === null || _a === void 0 ? void 0 : _a.elementi) === null || _b === void 0 ? void 0 : _b.length) || 0) > 0 ? action.codiceFunzione : undefined,
                    tour: {
                        ...state.tour.tour,
                        [action.codiceFunzione]: action.tour
                    }
                }
            };
        case __1.EReduxActionTypes.PROFILO_SET_PREFERENZE_UTENTE_SUCCESS:
            return {
                ...state,
                preferenzeUtente: action.payload
            };
        case __1.EReduxActionTypes.PROFILO_RESET_CURRENT_CODICE_FUNZIONE:
            return {
                ...state,
                tour: {
                    ...state.tour,
                    currentCodiceFunzione: initialState.tour.currentCodiceFunzione
                }
            };
        case __1.EReduxActionTypes.PROFILO_ESTENDI_SESSIONE_SUCCESS:
            return {
                ...state,
                infoSessione: {
                    ...state.infoSessione,
                    intervalloCorrente: 0,
                }
            };
        case __1.EReduxActionTypes.PROFILO_RESET_INTERVALLI_INATTIVI:
            return {
                ...state,
                infoSessione: {
                    ...state.infoSessione,
                    intervalliInattivi: 0
                }
            };
        case __1.EReduxActionTypes.PROFILO_INCREMENTA_INTERVALLI:
            return {
                ...state,
                infoSessione: {
                    ...state.infoSessione,
                    intervalloCorrente: (state.infoSessione.intervalloCorrente || 0) + 1,
                    intervalliInattivi: (state.infoSessione.intervalliInattivi || 0) + 1,
                }
            };
        case __1.EReduxActionTypes.PROFILO_SET_INTERVALLI_SESSIONE:
            return {
                ...state,
                infoSessione: {
                    ...state.infoSessione,
                    intervalloCorrente: action.payload || 0,
                    intervalliInattivi: action.payload || 0
                }
            };
        case __1.EReduxActionTypes.PROFILO_GET_TOKEN_HUB_SUCCESS:
            return {
                ...state,
                jwtCambioPin: action.payload
            };
        case __1.EReduxActionTypes.PROFILO_MODIFICA_PIN_HUB_SUCCESS:
            return {
                ...state,
                bloccoDatiBinario: action.payload
            };
        case __1.EReduxActionTypes.RESET_RIEPILOGO_MODIFICA_PIN:
            return {
                ...state,
                form: {
                    ...state.form,
                    newPIN: initialState.form.newPIN,
                    newPINDirty: initialState.form.newPINDirty,
                    newPINError: initialState.form.newPINError,
                    oldPIN: initialState.form.oldPIN,
                    oldPINDirty: initialState.form.oldPINDirty,
                    oldPINError: initialState.form.oldPINError,
                    confirmPIN: initialState.form.confirmPIN,
                    confirmPINDirty: initialState.form.confirmPINDirty,
                    confirmPINError: initialState.form.confirmPINError
                },
                jwtCambioPin: initialState.jwtCambioPin,
                bloccoDatiBinario: initialState.bloccoDatiBinario
            };
        case __1.EReduxActionTypes.PROFILO_SET_BACKGROUND_DATE:
            return {
                ...state,
                infoSessione: {
                    ...state.infoSessione,
                    backgroundDate: action.payload
                }
            };
        case __1.EReduxActionTypes.RESET_ALL: // action invocata dalla logout
            return {
                ...initialState
            };
        default:
            return state;
    }
};
exports.profiloReducer = profiloReducer;
