"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.StrongAuthInitRequestDtoToJSON = exports.StrongAuthInitRequestDtoFromJSONTyped = exports.StrongAuthInitRequestDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function StrongAuthInitRequestDtoFromJSON(json) {
    return StrongAuthInitRequestDtoFromJSONTyped(json, false);
}
exports.StrongAuthInitRequestDtoFromJSON = StrongAuthInitRequestDtoFromJSON;
function StrongAuthInitRequestDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'options': !runtime_1.exists(json, 'options') ? undefined : _1.StrongAuthInitRequestOptionsDtoFromJSON(json['options']),
    };
}
exports.StrongAuthInitRequestDtoFromJSONTyped = StrongAuthInitRequestDtoFromJSONTyped;
function StrongAuthInitRequestDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'options': _1.StrongAuthInitRequestOptionsDtoToJSON(value.options),
    };
}
exports.StrongAuthInitRequestDtoToJSON = StrongAuthInitRequestDtoToJSON;
