"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BannerControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class BannerControllerApi extends runtime.BaseAPI {
    /**
     * Restituisce un\'oggetto contenente la lista dei dettagli dei banner identificati in base agli id passati in input.
     */
    bannerByIdsRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.bannerIdsDto === null || requestParameters.bannerIdsDto === undefined) {
                throw new runtime.RequiredError('bannerIdsDto', 'Required parameter requestParameters.bannerIdsDto was null or undefined when calling bannerByIds.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/banner/ids`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.BannerIdsDtoToJSON(requestParameters.bannerIdsDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.BannerWcmListDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce un\'oggetto contenente la lista dei dettagli dei banner identificati in base agli id passati in input.
     */
    bannerByIds(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.bannerByIdsRaw(requestParameters);
            return yield response.value();
        });
    }
}
exports.BannerControllerApi = BannerControllerApi;
