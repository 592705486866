"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConsulenteDtoToJSON = exports.ConsulenteDtoFromJSONTyped = exports.ConsulenteDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function ConsulenteDtoFromJSON(json) {
    return ConsulenteDtoFromJSONTyped(json, false);
}
exports.ConsulenteDtoFromJSON = ConsulenteDtoFromJSON;
function ConsulenteDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'matricolaFunzionario': !runtime_1.exists(json, 'matricolaFunzionario') ? undefined : json['matricolaFunzionario'],
        'nomeConsulente': !runtime_1.exists(json, 'nomeConsulente') ? undefined : json['nomeConsulente'],
        'emailConsulente': !runtime_1.exists(json, 'emailConsulente') ? undefined : json['emailConsulente'],
        'telefonoC1': !runtime_1.exists(json, 'telefonoC1') ? undefined : json['telefonoC1'],
        'telefonoC2': !runtime_1.exists(json, 'telefonoC2') ? undefined : json['telefonoC2'],
        'cellulare': !runtime_1.exists(json, 'cellulare') ? undefined : json['cellulare'],
    };
}
exports.ConsulenteDtoFromJSONTyped = ConsulenteDtoFromJSONTyped;
function ConsulenteDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'matricolaFunzionario': value.matricolaFunzionario,
        'nomeConsulente': value.nomeConsulente,
        'emailConsulente': value.emailConsulente,
        'telefonoC1': value.telefonoC1,
        'telefonoC2': value.telefonoC2,
        'cellulare': value.cellulare,
    };
}
exports.ConsulenteDtoToJSON = ConsulenteDtoToJSON;
