"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetRendimenti = exports.setRendimentiAccordionFiltriOpened = exports.setRendimentiTitoloSelectedDeposito = exports.setRendimentiTitoloTuttiRapporti = exports.setRendimentiTitoloRapportoSelezionato = exports.setRendimentiSetIncludiPosizioniChiuse = exports.setRendimentiSetPeriodo = exports.setRendimentiSetDataA = exports.setRendimentiSetDataDa = exports.setRendimentiResetFiltri = exports.stampaElencoRendimenti = exports.getRendimentiAccount = exports.getRendimentiRapporti = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
const rendimenti_types_1 = require("./rendimenti.types");
// import moment = require('moment');
function getRendimentiRapportiStart() {
    return {
        type: __1.EReduxActionTypes.RENDIMENTI_GET_RAPPORTI_START
    };
}
function getRendimentiRapportiSucces(payload) {
    return {
        type: __1.EReduxActionTypes.RENDIMENTI_GET_RAPPORTI_SUCCESS,
        payload
    };
}
const getRendimentiRapporti = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getRendimentiRapportiStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/soggettiOperanti.json')).then(_ => {
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.PrometeiaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .soggettiOperantiPerFinanza().then((response) => {
                dispatch(getRendimentiRapportiSucces(response.filter(r => { var _a; return r.rapportiInformativi && ((_a = r.rapportiInformativi) === null || _a === void 0 ? void 0 : _a.length) > 0; })));
            }).catch(async (error) => {
                dispatch((0, general_actions_1.setError)(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getRendimentiRapporti = getRendimentiRapporti;
function getRendimentiAccountRendimentiStart() {
    return {
        type: __1.EReduxActionTypes.RENDIMENTI_GET_ACCOUNT_START
    };
}
function getRendimentiAccountRendimentiSucces(payload) {
    return {
        type: __1.EReduxActionTypes.RENDIMENTI_GET_ACCOUNT_SUCCESS,
        payload
    };
}
const getRendimentiAccount = () => async (dispatch, getState, extraArguments) => {
    var _a;
    const rendimenti = getState().rendimenti;
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getRendimentiAccountRendimentiStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/performance.json')).then(_ => {
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const rapportoSelezionato = rendimenti.rapportoSelezionato;
        const customerId = String(rapportoSelezionato === null || rapportoSelezionato === void 0 ? void 0 : rapportoSelezionato.ndg);
        const isRapportoSelezionatoIntestato = !rapportoSelezionato.rapportoCointestato && !rapportoSelezionato.rapportoDelega;
        const isRapportoDelegato = rapportoSelezionato.rapportoDelega;
        const accounts = isRapportoDelegato
            ? rapportoSelezionato.rapporti.map(r => {
                return r.toString().substring(r.toString().length - 8);
            })
            : [];
        const includeCoi = isRapportoDelegato ? true : isRapportoSelezionatoIntestato ? (_a = rendimenti.form) === null || _a === void 0 ? void 0 : _a.tuttiRapporti : false;
        const performanceRequest = {
            performanceRequestInDto: {
                customerId: customerId,
                period: rendimenti.form.periodo || rendimenti_types_1.PeriodoRendimenti.free,
                startingDateString: rendimenti.form.periodo === rendimenti_types_1.PeriodoRendimenti.free ? rendimenti.form.dataDa : undefined,
                endingDateString: rendimenti.form.periodo === rendimenti_types_1.PeriodoRendimenti.free ? rendimenti.form.dataA : undefined,
                includeClosedPosition: rendimenti.form.includeClosedPosition,
                includeCoi: includeCoi,
                accounts: accounts
            }
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.PrometeiaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .getPerformance(performanceRequest).then((response) => {
                dispatch(getRendimentiAccountRendimentiSucces(response));
            }).catch(async (error) => {
                dispatch((0, general_actions_1.setError)(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getRendimentiAccount = getRendimentiAccount;
function stampaElencoRendimentiStart() {
    return {
        type: __1.EReduxActionTypes.RENDIMENTI_STAMPA_ELENCO_RENDIMENTI_START
    };
}
function stampaElencoRendimentiSucces(payload) {
    return {
        type: __1.EReduxActionTypes.RENDIMENTI_STAMPA_ELENCO_RENDIMENTI_SUCCESS,
        payload
    };
}
const stampaElencoRendimenti = (share, title, message, type = api_rest_1.StampaElencoRendimentiTypeEnum.pdf) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(stampaElencoRendimentiStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/performance.json')).then(_ => {
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const rendimenti = getState().rendimenti;
        const request = {
            type,
            accountBODto: rendimenti.accountRendimenti
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.PrometeiaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf'))
                .stampaElencoRendimenti(request).then((response) => {
                if (extraArguments.platform === 'desktop') {
                    if (type === api_rest_1.StampaElencoRendimentiTypeEnum.excel) {
                        (0, helpers_1.hadleExcelResponse)(response, 'Elenco rendimenti.xlsx');
                    }
                    else {
                        (0, helpers_1.hadlePdfResponse)(response, (url) => dispatch((0, general_actions_1.popUpBlockedPdf)(url)));
                    }
                }
                if (extraArguments.platform === 'mobile') {
                    (0, helpers_1.download_sharePDF)(extraArguments, 'Elenco rendimenti.pdf', response, share, title, message);
                }
                dispatch(stampaElencoRendimentiSucces(response));
            }).catch(async (error) => {
                dispatch((0, general_actions_1.setError)(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.stampaElencoRendimenti = stampaElencoRendimenti;
function setRendimentiResetFiltri() {
    return {
        type: __1.EReduxActionTypes.RENDIMENTI_RESET_FILTRI
    };
}
exports.setRendimentiResetFiltri = setRendimentiResetFiltri;
function setRendimentiSetDataDa(payload) {
    return {
        type: __1.EReduxActionTypes.RENDIMENTI_DATA_DA,
        payload
    };
}
exports.setRendimentiSetDataDa = setRendimentiSetDataDa;
function setRendimentiSetDataA(payload) {
    return {
        type: __1.EReduxActionTypes.RENDIMENTI_DATA_A,
        payload
    };
}
exports.setRendimentiSetDataA = setRendimentiSetDataA;
function setRendimentiSetPeriodo(payload) {
    return {
        type: __1.EReduxActionTypes.RENDIMENTI_PERIODO,
        payload
    };
}
exports.setRendimentiSetPeriodo = setRendimentiSetPeriodo;
function setRendimentiSetIncludiPosizioniChiuse(payload) {
    return {
        type: __1.EReduxActionTypes.RENDIMENTI_INCLUDI_POSIZIONI_CHIUSE,
        payload
    };
}
exports.setRendimentiSetIncludiPosizioniChiuse = setRendimentiSetIncludiPosizioniChiuse;
function setRendimentiTitoloRapportoSelezionato(payload) {
    return {
        type: __1.EReduxActionTypes.RENDIMENTI_SET_RAPPORTO_SELEZIONATO,
        payload
    };
}
exports.setRendimentiTitoloRapportoSelezionato = setRendimentiTitoloRapportoSelezionato;
function setRendimentiTitoloTuttiRapporti(payload) {
    return {
        type: __1.EReduxActionTypes.RENDIMENTI_SET_TUTTI_RAPPORTI,
        payload
    };
}
exports.setRendimentiTitoloTuttiRapporti = setRendimentiTitoloTuttiRapporti;
function setRendimentiTitoloSelectedDeposito(payload) {
    return {
        type: __1.EReduxActionTypes.RENDIMENTI_SET_SELECTED_DEPOSITO,
        payload
    };
}
exports.setRendimentiTitoloSelectedDeposito = setRendimentiTitoloSelectedDeposito;
function setRendimentiAccordionFiltriOpened(payload) {
    return {
        type: __1.EReduxActionTypes.RENDIMENTI_SET_ACCORDION_FILTRI_OPENED,
        payload
    };
}
exports.setRendimentiAccordionFiltriOpened = setRendimentiAccordionFiltriOpened;
function resetRendimenti() {
    return {
        type: __1.EReduxActionTypes.RENDIMENTI_RESET
    };
}
exports.resetRendimenti = resetRendimenti;
