"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckUserResponseDtoToJSON = exports.CheckUserResponseDtoFromJSONTyped = exports.CheckUserResponseDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function CheckUserResponseDtoFromJSON(json) {
    return CheckUserResponseDtoFromJSONTyped(json, false);
}
exports.CheckUserResponseDtoFromJSON = CheckUserResponseDtoFromJSON;
function CheckUserResponseDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'valid': !runtime_1.exists(json, 'valid') ? undefined : json['valid'],
    };
}
exports.CheckUserResponseDtoFromJSONTyped = CheckUserResponseDtoFromJSONTyped;
function CheckUserResponseDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'valid': value.valid,
    };
}
exports.CheckUserResponseDtoToJSON = CheckUserResponseDtoToJSON;
