"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportMovimentoCartaRequestDtoToJSON = exports.ExportMovimentoCartaRequestDtoFromJSONTyped = exports.ExportMovimentoCartaRequestDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ExportMovimentoCartaRequestDtoFromJSON(json) {
    return ExportMovimentoCartaRequestDtoFromJSONTyped(json, false);
}
exports.ExportMovimentoCartaRequestDtoFromJSON = ExportMovimentoCartaRequestDtoFromJSON;
function ExportMovimentoCartaRequestDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'cartaDto': !runtime_1.exists(json, 'cartaDto') ? undefined : _1.CartaNexiDtoFromJSON(json['cartaDto']),
        'movimentoDto': !runtime_1.exists(json, 'movimentoDto') ? undefined : _1.MovimentoCartaDtoFromJSON(json['movimentoDto']),
    };
}
exports.ExportMovimentoCartaRequestDtoFromJSONTyped = ExportMovimentoCartaRequestDtoFromJSONTyped;
function ExportMovimentoCartaRequestDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'cartaDto': _1.CartaNexiDtoToJSON(value.cartaDto),
        'movimentoDto': _1.MovimentoCartaDtoToJSON(value.movimentoDto),
    };
}
exports.ExportMovimentoCartaRequestDtoToJSON = ExportMovimentoCartaRequestDtoToJSON;
