"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProvinceDtoToJSON = exports.ProvinceDtoFromJSONTyped = exports.ProvinceDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function ProvinceDtoFromJSON(json) {
    return ProvinceDtoFromJSONTyped(json, false);
}
exports.ProvinceDtoFromJSON = ProvinceDtoFromJSON;
function ProvinceDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'sigla': !runtime_1.exists(json, 'sigla') ? undefined : json['sigla'],
        'descrizione': !runtime_1.exists(json, 'descrizione') ? undefined : json['descrizione'],
    };
}
exports.ProvinceDtoFromJSONTyped = ProvinceDtoFromJSONTyped;
function ProvinceDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'sigla': value.sigla,
        'descrizione': value.descrizione,
    };
}
exports.ProvinceDtoToJSON = ProvinceDtoToJSON;
