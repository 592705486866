"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.InfoMyBankDtoToJSON = exports.InfoMyBankDtoFromJSONTyped = exports.InfoMyBankDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function InfoMyBankDtoFromJSON(json) {
    return InfoMyBankDtoFromJSONTyped(json, false);
}
exports.InfoMyBankDtoFromJSON = InfoMyBankDtoFromJSON;
function InfoMyBankDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'rapporti': !runtime_1.exists(json, 'rapporti') ? undefined : (json['rapporti'].map(_1.RapportoCompletoDtoFromJSON)),
        'parametri': !runtime_1.exists(json, 'parametri') ? undefined : json['parametri'],
        'motivazioniPagamento': !runtime_1.exists(json, 'motivazioniPagamento') ? undefined : (json['motivazioniPagamento'].map(_1.MotivazionePagamentoDtoFromJSON)),
        'bonifico': !runtime_1.exists(json, 'bonifico') ? undefined : _1.BonificoSepaDtoFromJSON(json['bonifico']),
        'urlRedirect': !runtime_1.exists(json, 'urlRedirect') ? undefined : json['urlRedirect'],
        'expirationDate': !runtime_1.exists(json, 'expirationDate') ? undefined : (new Date(json['expirationDate'])),
    };
}
exports.InfoMyBankDtoFromJSONTyped = InfoMyBankDtoFromJSONTyped;
function InfoMyBankDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'rapporti': value.rapporti === undefined ? undefined : (value.rapporti.map(_1.RapportoCompletoDtoToJSON)),
        'parametri': value.parametri,
        'motivazioniPagamento': value.motivazioniPagamento === undefined ? undefined : (value.motivazioniPagamento.map(_1.MotivazionePagamentoDtoToJSON)),
        'bonifico': _1.BonificoSepaDtoToJSON(value.bonifico),
        'urlRedirect': value.urlRedirect,
        'expirationDate': value.expirationDate === undefined ? undefined : (value.expirationDate.toISOString()),
    };
}
exports.InfoMyBankDtoToJSON = InfoMyBankDtoToJSON;
