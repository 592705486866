"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.operazioniPreferiteCorrenti = exports.operazioniPreferite = void 0;
const reselect_1 = require("reselect");
const operazioniReducer = (state) => state;
exports.operazioniPreferite = (0, reselect_1.createSelector)(operazioniReducer, (state) => {
    return state.operazioniPreferite;
});
exports.operazioniPreferiteCorrenti = (0, reselect_1.createSelector)(operazioniReducer, (state) => {
    var _a;
    if (state.operazioniPreferite.tipoSelezioneUtente === 'DEFAULT') {
        return state.operazioniPreferite.elencoFunzionalitaDefault;
    }
    else {
        return (_a = state.operazioniPreferite.elencoFunzionalitaCustom) === null || _a === void 0 ? void 0 : _a.slice(0, 5); // workaroung service bug
    }
});
