import { Environments, error, getBloccantiMessaggi, getCustomEnv, getParametriPostlogin, getProfilo, getToken, jwtToken, loaderActive, profilo, resetError, selectedEnv, setBonificoMyBankToken, setError, StartAuthorizeWeb } from "@sparkasse/commons";
import ButtonText2 from "component/buttonText2/buttonText2.component";
import FullPageNotification from "component/fullPageNotification/fullPageNotification.component";
import { navigateToPage, WEBPAGE } from "component/history/history.component";
import Loader from "component/loader/loader.component";
import { getCustomError } from "helpers/utilities";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { getItem, setItem } from "../../index";
import logo from "../../logoSparkasse.svg";
import { AppState } from "../../reducers";

const FEATURE = {
  DEFAULT: "WEBLOGIN",
  MYBANK: "MYBANK",
  ONBOARDING: "ONBOARDING"
};

const PUBLIC_SITE_LOGIN_STATE = "public";
const ONBOARDING_ADMIN = "onboarding-admin";

const Login: React.FC<Props> = (props) => {

  const { t } = useTranslation();

  const [isMyBank, setIsMyBank] = useState(false);
  const [myBankToken, setMyBankToken] = useState("");

  const [isOnboarding, setIsOnboarding] = useState(false);

  type Parametri = {
    code?: string
    nonce?: string
    state?: string
    error?: string
    error_description?: string
    token?: string
    lang?: string
    prodottoMybank?: string
  };

  const recuperoParametri = () => {
    const arr = props.location.search
      ?.replace(/^\?/, "")
      .split("#")[0]
      .split("&");
    let _parametri: any = {};
    for (let i = 0; i < arr.length; i++) {
      let param = arr[i].split("=");
      _parametri[param[0]] = param.length > 0 ? param[1] : "";
    }
    return _parametri as Parametri;
  };

  useEffect(() => {
    // recupero i parametri in input
    setIsMyBank(false);
    setIsOnboarding(false);
    const parametri = recuperoParametri();
    if (parametri.error) {
      if (parametri.error === 'access_denied' && (parametri.error_description === 'login_missing' || parametri.error_description === 'auth_context_invalid')) {
        window.location.href = Environments[getCustomEnv() || selectedEnv].publicSite;
      } else {
        props.setError(getCustomError(`common.errors.${parametri.error}`, !!parametri.error_description ? `common.errors.${parametri.error_description}` : undefined));
      }
    }
    if (parametri.state === PUBLIC_SITE_LOGIN_STATE) {
      props.StartAuthorizeWeb({
        incomingState: PUBLIC_SITE_LOGIN_STATE,
        lang: parametri.lang || 'it'
      });
    } else if (parametri.state === ONBOARDING_ADMIN) {
      setIsOnboarding(true);
    } else if (parametri.code && parametri.state) {
      // ricevo Code, quindi torno da una auth
      getItem(parametri.state, true).then((_statoCorrente: any) => {
        const statoCorrente = JSON.parse(_statoCorrente);
        if (!statoCorrente) {
          // siamo ritornati alla nostra pagina, dopo aver fatto una login, ma senza essere partiti da qui
          // redirect al sito pubblico
          window.location.href = Environments[getCustomEnv() || selectedEnv].publicSite;
        } else {
          if (parametri.code && statoCorrente.nonce === parametri.nonce) {
            if (statoCorrente?.feature === FEATURE.MYBANK) {
              // TODO gestire dati obbligatori
              setIsMyBank(true);
              const token = parametri.token || statoCorrente?.token;
              token && setMyBankToken(token);
            } else if (statoCorrente?.feature === FEATURE.ONBOARDING) {
              // http://localhost:3000/oauthredirect?code=HnchyHgppXu4JtNnyia9Db2vFKECAe&nonce=DzEO4cMYzelu8yatdHEu&state=onboarding-adminoNRNw1ekhZ
              setIsOnboarding(true);
            }
            props.getToken(parametri.code);
          } else {
            props.setError(getCustomError("common.errors.access_denied"));
          }
        }
      });
    } else if (parametri.prodottoMybank === 'MYBANK') {

      // imposto lo stato da memorizzare allo start authorize
      setIsMyBank(true);
      // http://localhost:3000/mybank?abi=06045&prodottoMybank=MYBANK&token=77a2e1dd-2419-4574-a3ff-b35a9c2efe61
      props.StartAuthorizeWeb({
        feature: FEATURE.MYBANK,
        token: parametri.token,
        lang: parametri.lang || 'it'
      });

    } else {
      // faccio in modo di buttare fuori l'utente se prova ad accedere ad uno degli indirizzi non gestiti
      if (window.location.hostname !== 'localhost') {
        // servirà anche a fare in modo che la logout riporti l'utente al sito pubblico
        window.location.href = Environments[getCustomEnv() || selectedEnv].publicSite;
      }
    }
  }, []);

  useEffect(() => {
    if (!!props.jwtToken) {
      setItem("jwt", props.jwtToken);
      if (!!myBankToken) {
        props.getProfilo(true, true);
      } else {
        if (isOnboarding) {
          // navigateToPage(WEBPAGE.OnboardingAdmin.url);
        } else {
          !props.profilo?.datiUtente && props.getProfilo(true);
          props.getBloccantiMessaggi();
          props.getParametriPostlogin();
          navigateToPage(WEBPAGE.PostLoginMessages.url, { url: WEBPAGE.Home.url });
        }
      }
    }
  }, [props.jwtToken]);

  useEffect(() => {
    if (!!props.profilo?.datiUtente && !!myBankToken) {
      props.setBonificoMyBankToken(myBankToken);
      props.getBloccantiMessaggi();
      navigateToPage(WEBPAGE.PostLoginMessages.url);
    }
  }, [props.profilo]);

  return (
    <div className="App">
      {props.loaderActive && <Loader />}
      <FullPageNotification
        onReset={() => {
          props.resetError();
          window.location.href = Environments[getCustomEnv() || selectedEnv].publicSite;
          // History.push("/");
        }}
      />
      <header className="App-header">
        <img src={logo} alt="logo" style={{ marginTop: 40 }} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
          }}
        >
          {(selectedEnv as "ADS" | "DEV" | "QA" | "PROD" | "MOCK") !== 'PROD' && (
            <>
              <ButtonText2
                className="pt-3"
                descrizione={t("login.form.login")}
                onClick={() => props.StartAuthorizeWeb({})}
                showImageBack
              />

              {isOnboarding && (
                <ButtonText2
                  className="pt-3"
                  descrizione={t("start onboarding cliente")}
                  onClick={() => props.StartAuthorizeWeb({
                    feature: FEATURE.ONBOARDING,
                    incomingState: ONBOARDING_ADMIN
                  })}
                  showImageBack
                />
              )}
            </>
          )}

          {isMyBank && (
            <div style={{ fontSize: 16, color: "#000" }}>
              <img
                src={"/mybank.jpg"}
                style={{ width: 120 }}
              />
            </div>
          )}

        </div>
      </header>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  jwtToken: jwtToken(state.login),
  error: error(state.general),
  loaderActive: loaderActive(state.general),
  profilo: profilo(state.profilo),
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getToken,
      resetError,
      StartAuthorizeWeb,
      setError,
      getBloccantiMessaggi,
      getProfilo,
      getParametriPostlogin,
      setBonificoMyBankToken
    },
    dispatch
  );

interface RouteParams {
  id: string
}

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<RouteParams>;

export default connect(mapStateToProps, mapDispatchToProps)(Login);
