"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pushUrlFirmaDigitale = exports.urlJobSessioniDiFirma = exports.jobSelezionatoSessioniDiFirma = exports.formSessioniDiFirma = exports.sessioniDiFirma = exports.firmaDigitaleReducer = void 0;
const reselect_1 = require("reselect");
const firmaDigitaleReducer = (state) => state;
exports.firmaDigitaleReducer = firmaDigitaleReducer;
exports.sessioniDiFirma = (0, reselect_1.createSelector)(exports.firmaDigitaleReducer, (state) => {
    return state.sessioniDiFirma;
});
exports.formSessioniDiFirma = (0, reselect_1.createSelector)(exports.firmaDigitaleReducer, (state) => {
    return state.form;
});
exports.jobSelezionatoSessioniDiFirma = (0, reselect_1.createSelector)(exports.firmaDigitaleReducer, (state) => {
    return state.form.jobSelezionato;
});
exports.urlJobSessioniDiFirma = (0, reselect_1.createSelector)(exports.firmaDigitaleReducer, (state) => {
    return state.urlJob;
});
exports.pushUrlFirmaDigitale = (0, reselect_1.createSelector)(exports.firmaDigitaleReducer, (state) => {
    return state.pushUrl;
});
