"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreferenzeUtenteDtoToJSON = exports.PreferenzeUtenteDtoFromJSONTyped = exports.PreferenzeUtenteDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function PreferenzeUtenteDtoFromJSON(json) {
    return PreferenzeUtenteDtoFromJSONTyped(json, false);
}
exports.PreferenzeUtenteDtoFromJSON = PreferenzeUtenteDtoFromJSON;
function PreferenzeUtenteDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'mappaPreferenze': !runtime_1.exists(json, 'mappaPreferenze') ? undefined : json['mappaPreferenze'],
    };
}
exports.PreferenzeUtenteDtoFromJSONTyped = PreferenzeUtenteDtoFromJSONTyped;
function PreferenzeUtenteDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'mappaPreferenze': value.mappaPreferenze,
    };
}
exports.PreferenzeUtenteDtoToJSON = PreferenzeUtenteDtoToJSON;
