"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PieBarDataDtoToJSON = exports.PieBarDataDtoFromJSONTyped = exports.PieBarDataDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function PieBarDataDtoFromJSON(json) {
    return PieBarDataDtoFromJSONTyped(json, false);
}
exports.PieBarDataDtoFromJSON = PieBarDataDtoFromJSON;
function PieBarDataDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'finanziamento': !runtime_1.exists(json, 'finanziamento') ? undefined : _1.PieBarRowFromJSON(json['finanziamento']),
        'capitale': !runtime_1.exists(json, 'capitale') ? undefined : _1.PieBarRowFromJSON(json['capitale']),
        'interessi': !runtime_1.exists(json, 'interessi') ? undefined : _1.PieBarRowFromJSON(json['interessi']),
        'capitalePercentuale': !runtime_1.exists(json, 'capitalePercentuale') ? undefined : json['capitalePercentuale'],
        'interessiPercentuale': !runtime_1.exists(json, 'interessiPercentuale') ? undefined : json['interessiPercentuale'],
    };
}
exports.PieBarDataDtoFromJSONTyped = PieBarDataDtoFromJSONTyped;
function PieBarDataDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'finanziamento': _1.PieBarRowToJSON(value.finanziamento),
        'capitale': _1.PieBarRowToJSON(value.capitale),
        'interessi': _1.PieBarRowToJSON(value.interessi),
        'capitalePercentuale': value.capitalePercentuale,
        'interessiPercentuale': value.interessiPercentuale,
    };
}
exports.PieBarDataDtoToJSON = PieBarDataDtoToJSON;
