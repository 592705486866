"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EReduxNewsActionTypes = void 0;
var EReduxNewsActionTypes;
(function (EReduxNewsActionTypes) {
    EReduxNewsActionTypes["NEWS_GET_PARAMETRI_START"] = "NEWS_GET_PARAMETRI_START";
    EReduxNewsActionTypes["NEWS_GET_PARAMETRI_SUCCESS"] = "NEWS_GET_PARAMETRI_SUCCESS";
    EReduxNewsActionTypes["NEWS_GET_NEWS_START"] = "NEWS_GET_NEWS_START";
    EReduxNewsActionTypes["NEWS_GET_NEWS_SUCCESS"] = "NEWS_GET_NEWS_SUCCESS";
    EReduxNewsActionTypes["SET_NEWS_PERIODO"] = "SET_NEWS_PERIODO";
    EReduxNewsActionTypes["SET_NEWS_DENOMINAZIONE"] = "SET_NEWS_DENOMINAZIONE";
    EReduxNewsActionTypes["NEWS_RESET_FORM"] = "NEWS_RESET_FORM";
    EReduxNewsActionTypes["NEWS_GET_DETTAGLIO_NEWS_START"] = "NEWS_GET_DETTAGLIO_NEWS_START";
    EReduxNewsActionTypes["NEWS_GET_DETTAGLIO_NEWS_SUCCESS"] = "NEWS_GET_DETTAGLIO_NEWS_SUCCESS";
    EReduxNewsActionTypes["NEWS_SET_NEWS_SELEZIONATO"] = "NEWS_SET_NEWS_SELEZIONATO";
    EReduxNewsActionTypes["NEWS_GET_DATI_INTRADAY_NEWS_START"] = "NEWS_GET_DATI_INTRADAY_NEWS_START";
    EReduxNewsActionTypes["NEWS_GET_DATI_INTRADAY_NEWS_SUCCESS"] = "NEWS_GET_DATI_INTRADAY_NEWS_SUCCESS";
    EReduxNewsActionTypes["NEWS_RESET"] = "NEWS_RESET";
})(EReduxNewsActionTypes = exports.EReduxNewsActionTypes || (exports.EReduxNewsActionTypes = {}));
