"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RapportoCategoriaAttributiPsd2ConsensoEnum = exports.RapportoCategoriaAttributiPsd2ToJSON = exports.RapportoCategoriaAttributiPsd2FromJSONTyped = exports.RapportoCategoriaAttributiPsd2FromJSON = void 0;
const runtime_1 = require("../runtime");
function RapportoCategoriaAttributiPsd2FromJSON(json) {
    return RapportoCategoriaAttributiPsd2FromJSONTyped(json, false);
}
exports.RapportoCategoriaAttributiPsd2FromJSON = RapportoCategoriaAttributiPsd2FromJSON;
function RapportoCategoriaAttributiPsd2FromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'aspspId': !runtime_1.exists(json, 'aspspId') ? undefined : json['aspspId'],
        'nomeBanca': !runtime_1.exists(json, 'nomeBanca') ? undefined : json['nomeBanca'],
        'iconImage64': !runtime_1.exists(json, 'iconImage64') ? undefined : json['iconImage64'],
        'consenso': !runtime_1.exists(json, 'consenso') ? undefined : json['consenso'],
        'dataScadenza': !runtime_1.exists(json, 'dataScadenza') ? undefined : (new Date(json['dataScadenza'])),
    };
}
exports.RapportoCategoriaAttributiPsd2FromJSONTyped = RapportoCategoriaAttributiPsd2FromJSONTyped;
function RapportoCategoriaAttributiPsd2ToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'aspspId': value.aspspId,
        'nomeBanca': value.nomeBanca,
        'iconImage64': value.iconImage64,
        'consenso': value.consenso,
        'dataScadenza': value.dataScadenza === undefined ? undefined : (value.dataScadenza.toISOString()),
    };
}
exports.RapportoCategoriaAttributiPsd2ToJSON = RapportoCategoriaAttributiPsd2ToJSON;
/**
* @export
* @enum {string}
*/
var RapportoCategoriaAttributiPsd2ConsensoEnum;
(function (RapportoCategoriaAttributiPsd2ConsensoEnum) {
    RapportoCategoriaAttributiPsd2ConsensoEnum["ACTIVE"] = "ACTIVE";
    RapportoCategoriaAttributiPsd2ConsensoEnum["VALID"] = "VALID";
    RapportoCategoriaAttributiPsd2ConsensoEnum["INACTIVE"] = "INACTIVE";
    RapportoCategoriaAttributiPsd2ConsensoEnum["EXPIRING"] = "EXPIRING";
})(RapportoCategoriaAttributiPsd2ConsensoEnum = exports.RapportoCategoriaAttributiPsd2ConsensoEnum || (exports.RapportoCategoriaAttributiPsd2ConsensoEnum = {}));
