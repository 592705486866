"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pdfDettaglioDepositoRisparmi = exports.excelDepositoRisparmi = exports.pdfElencoDepositoRisparmi = exports.filtriApplicatiMovimentiDepositoRisparmi = exports.formElencoDepositoRisparmiAsFiltro = exports.formDepositoRisparmiValid = exports.formDepositoRisparmi = exports.elencoDepositoRisparmi = exports.parametriDepositoRisparmi = void 0;
const reselect_1 = require("reselect");
const api_rest_1 = require("@sparkasse/api-rest");
const helpers_1 = require("../helpers");
const IMPORTO_MOLTIPLICATORI_INTERVALLO = { MIN: 0.8, MAX: 1.2 };
const depositoRisparmiReducer = (state) => state;
exports.parametriDepositoRisparmi = (0, reselect_1.createSelector)(depositoRisparmiReducer, (state) => {
    return state.parametri;
});
exports.elencoDepositoRisparmi = (0, reselect_1.createSelector)(depositoRisparmiReducer, (state) => {
    return state.elenco;
});
exports.formDepositoRisparmi = (0, reselect_1.createSelector)(depositoRisparmiReducer, (state) => {
    return state.form;
});
exports.formDepositoRisparmiValid = (0, reselect_1.createSelector)(depositoRisparmiReducer, (state) => {
    var _a, _b;
    let formValid = false;
    if (!!((_b = (_a = state.form.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.id) &&
        !state.form.descrizioneError &&
        !state.form.dataDaError &&
        !state.form.dataAError &&
        !state.form.importoError) {
        formValid = true;
    }
    return formValid;
});
exports.formElencoDepositoRisparmiAsFiltro = (0, reselect_1.createSelector)(depositoRisparmiReducer, (state) => {
    var _a, _b;
    return {
        codiceRapporto: (_b = (_a = state.form.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.id,
        dataIniziale: new Date(state.form.dataDa),
        dataFinale: new Date(state.form.dataA),
        keyword: state.form.descrizione || undefined,
        importoDa: (0, helpers_1.formattedNumberToNumber)(state.form.importo) * IMPORTO_MOLTIPLICATORI_INTERVALLO.MIN || undefined,
        importoA: (0, helpers_1.formattedNumberToNumber)(state.form.importo) * IMPORTO_MOLTIPLICATORI_INTERVALLO.MAX || undefined,
        segno: state.form.entrate && state.form.uscite ? undefined : state.form.entrate ? api_rest_1.FiltroElencoDepositiRisparmioDtoSegnoEnum.A : api_rest_1.FiltroElencoDepositiRisparmioDtoSegnoEnum.D
    };
});
exports.filtriApplicatiMovimentiDepositoRisparmi = (0, reselect_1.createSelector)(depositoRisparmiReducer, (state) => {
    var _a;
    let r = false;
    const parametri = (_a = state.parametri) === null || _a === void 0 ? void 0 : _a.parametri;
    if (parametri) {
        if (state.form.periodo != '90' ||
            !!state.form.importo ||
            !!state.form.descrizione ||
            state.form.entrateUscite != '1') {
            r = true;
        }
    }
    return r;
});
exports.pdfElencoDepositoRisparmi = (0, reselect_1.createSelector)(depositoRisparmiReducer, (state) => {
    return state.pdfElenco;
});
exports.excelDepositoRisparmi = (0, reselect_1.createSelector)(depositoRisparmiReducer, (state) => {
    return state.excelElenco;
});
exports.pdfDettaglioDepositoRisparmi = (0, reselect_1.createSelector)(depositoRisparmiReducer, (state) => {
    return state.pdfDettaglioDeposito;
});
