"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.preloginReducer = void 0;
const __1 = require("..");
const initialState = {
    certificates: undefined,
    parametri: undefined,
    riferimenti: undefined,
    contatti: undefined,
};
const preloginReducer = (state = initialState, action) => {
    var _a;
    switch (action.type) {
        case __1.EReduxActionTypes.PRELOGIN_GET_PINNING_INFO_SUCCESS:
            // TODO: rimuovere le logiche di rimozione \r\n da frontend
            const certs = (_a = action.payload.certificates) === null || _a === void 0 ? void 0 : _a.map(cert => {
                var _a, _b;
                return {
                    certDer: (_a = cert.certDer) === null || _a === void 0 ? void 0 : _a.replace(/(\r\n|\n|\r)/gm, ""),
                    sha256: (_b = cert.sha256) === null || _b === void 0 ? void 0 : _b.replace(/(\r\n|\n|\r)/gm, "")
                };
            });
            return {
                ...state,
                certificates: certs
            };
        // TODO: questa è la logica corretta da mantenere
        // return {
        //   ...state,
        //   certificates: action.payload.certificates
        // };
        case __1.EReduxActionTypes.PRELOGIN_GET_PARAMETRI_SUCCESS:
            return {
                ...state,
                parametri: action.payload
            };
        case __1.EReduxActionTypes.PRELOGIN_BLOCCO_CARTE_GET_RIFERIMENTI_SUCCESS:
            return {
                ...state,
                riferimenti: action.payload
            };
        case __1.EReduxActionTypes.PRELOGIN_NUMERI_UTILI_SUCCESS:
            return {
                ...state,
                contatti: action.payload
            };
        default:
            return state;
    }
};
exports.preloginReducer = preloginReducer;
exports.default = exports.preloginReducer;
