"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParametriMutuiDtoToJSON = exports.ParametriMutuiDtoFromJSONTyped = exports.ParametriMutuiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ParametriMutuiDtoFromJSON(json) {
    return ParametriMutuiDtoFromJSONTyped(json, false);
}
exports.ParametriMutuiDtoFromJSON = ParametriMutuiDtoFromJSON;
function ParametriMutuiDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'mutui': !runtime_1.exists(json, 'mutui') ? undefined : (json['mutui'].map(_1.FinanziamentoDtoFromJSON)),
        'parametri': !runtime_1.exists(json, 'parametri') ? undefined : json['parametri'],
    };
}
exports.ParametriMutuiDtoFromJSONTyped = ParametriMutuiDtoFromJSONTyped;
function ParametriMutuiDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'mutui': value.mutui === undefined ? undefined : (value.mutui.map(_1.FinanziamentoDtoToJSON)),
        'parametri': value.parametri,
    };
}
exports.ParametriMutuiDtoToJSON = ParametriMutuiDtoToJSON;
