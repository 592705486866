"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RequestDelegheToJSON = exports.RequestDelegheFromJSONTyped = exports.RequestDelegheFromJSON = void 0;
const runtime_1 = require("../runtime");
function RequestDelegheFromJSON(json) {
    return RequestDelegheFromJSONTyped(json, false);
}
exports.RequestDelegheFromJSON = RequestDelegheFromJSON;
function RequestDelegheFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'dataContabile': !runtime_1.exists(json, 'dataContabile') ? undefined : (new Date(json['dataContabile'])),
        'codiceFiscaleContribuente': !runtime_1.exists(json, 'codiceFiscaleContribuente') ? undefined : json['codiceFiscaleContribuente'],
        'progressivoTecnico': !runtime_1.exists(json, 'progressivoTecnico') ? undefined : json['progressivoTecnico'],
        'codiceRapporto': !runtime_1.exists(json, 'codiceRapporto') ? undefined : json['codiceRapporto'],
        'provenienzaMovimento': !runtime_1.exists(json, 'provenienzaMovimento') ? undefined : json['provenienzaMovimento'],
        'dataInvio': !runtime_1.exists(json, 'dataInvio') ? undefined : (new Date(json['dataInvio'])),
        'progressivoDelegaInvio': !runtime_1.exists(json, 'progressivoDelegaInvio') ? undefined : json['progressivoDelegaInvio'],
        'progressivoInvio': !runtime_1.exists(json, 'progressivoInvio') ? undefined : json['progressivoInvio'],
    };
}
exports.RequestDelegheFromJSONTyped = RequestDelegheFromJSONTyped;
function RequestDelegheToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'dataContabile': value.dataContabile === undefined ? undefined : (value.dataContabile.toISOString()),
        'codiceFiscaleContribuente': value.codiceFiscaleContribuente,
        'progressivoTecnico': value.progressivoTecnico,
        'codiceRapporto': value.codiceRapporto,
        'provenienzaMovimento': value.provenienzaMovimento,
        'dataInvio': value.dataInvio === undefined ? undefined : (value.dataInvio.toISOString()),
        'progressivoDelegaInvio': value.progressivoDelegaInvio,
        'progressivoInvio': value.progressivoInvio,
    };
}
exports.RequestDelegheToJSON = RequestDelegheToJSON;
