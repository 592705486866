"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FaqWcmDtoToJSON = exports.FaqWcmDtoFromJSONTyped = exports.FaqWcmDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function FaqWcmDtoFromJSON(json) {
    return FaqWcmDtoFromJSONTyped(json, false);
}
exports.FaqWcmDtoFromJSON = FaqWcmDtoFromJSON;
function FaqWcmDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'titolo': !runtime_1.exists(json, 'titolo') ? undefined : json['titolo'],
        'testo': !runtime_1.exists(json, 'testo') ? undefined : json['testo'],
        'funzione': !runtime_1.exists(json, 'funzione') ? undefined : json['funzione'],
        'nonTrovato': !runtime_1.exists(json, 'nonTrovato') ? undefined : json['nonTrovato'],
        'messaggioId': !runtime_1.exists(json, 'messaggioId') ? undefined : json['messaggioId'],
        'lingua': !runtime_1.exists(json, 'lingua') ? undefined : json['lingua'],
        'immagine': !runtime_1.exists(json, 'immagine') ? undefined : json['immagine'],
        'nomeImmagine': !runtime_1.exists(json, 'nomeImmagine') ? undefined : json['nomeImmagine'],
        'contentType': !runtime_1.exists(json, 'contentType') ? undefined : json['contentType'],
        'linkUrl': !runtime_1.exists(json, 'linkUrl') ? undefined : json['linkUrl'],
        'idAllegato': !runtime_1.exists(json, 'idAllegato') ? undefined : json['idAllegato'],
    };
}
exports.FaqWcmDtoFromJSONTyped = FaqWcmDtoFromJSONTyped;
function FaqWcmDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'titolo': value.titolo,
        'testo': value.testo,
        'funzione': value.funzione,
        'nonTrovato': value.nonTrovato,
        'messaggioId': value.messaggioId,
        'lingua': value.lingua,
        'immagine': value.immagine,
        'nomeImmagine': value.nomeImmagine,
        'contentType': value.contentType,
        'linkUrl': value.linkUrl,
        'idAllegato': value.idAllegato,
    };
}
exports.FaqWcmDtoToJSON = FaqWcmDtoToJSON;
