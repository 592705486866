"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModificaMassimaleSepaIstantOutDtoToJSON = exports.ModificaMassimaleSepaIstantOutDtoFromJSONTyped = exports.ModificaMassimaleSepaIstantOutDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function ModificaMassimaleSepaIstantOutDtoFromJSON(json) {
    return ModificaMassimaleSepaIstantOutDtoFromJSONTyped(json, false);
}
exports.ModificaMassimaleSepaIstantOutDtoFromJSON = ModificaMassimaleSepaIstantOutDtoFromJSON;
function ModificaMassimaleSepaIstantOutDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'valore': !runtime_1.exists(json, 'valore') ? undefined : json['valore'],
        'firma': !runtime_1.exists(json, 'firma') ? undefined : json['firma'],
    };
}
exports.ModificaMassimaleSepaIstantOutDtoFromJSONTyped = ModificaMassimaleSepaIstantOutDtoFromJSONTyped;
function ModificaMassimaleSepaIstantOutDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'valore': value.valore,
        'firma': value.firma,
    };
}
exports.ModificaMassimaleSepaIstantOutDtoToJSON = ModificaMassimaleSepaIstantOutDtoToJSON;
