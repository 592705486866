"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RapportiControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class RapportiControllerApi extends runtime.BaseAPI {
    /**
     * Effettua l\'aggiornamento dei rapporti predefiniti dell\'utente e restituisce la lista aggiornata.
     */
    aggiornaRapportiPredefinitiRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.rapportoPerServizioDto === null || requestParameters.rapportoPerServizioDto === undefined) {
                throw new runtime.RequiredError('rapportoPerServizioDto', 'Required parameter requestParameters.rapportoPerServizioDto was null or undefined when calling aggiornaRapportiPredefiniti.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/rapporti/predefiniti/aggiorna`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters.rapportoPerServizioDto.map(models_1.RapportoPerServizioDtoToJSON),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.RapportoPerServizioDtoFromJSON));
        });
    }
    /**
     * Effettua l\'aggiornamento dei rapporti predefiniti dell\'utente e restituisce la lista aggiornata.
     */
    aggiornaRapportiPredefiniti(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.aggiornaRapportiPredefinitiRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce i termini e le condizioni.
     */
    condizioniEconomicheRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/rapporti/corrispondenze/condizioni`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.MessaggioBodyDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce i termini e le condizioni.
     */
    condizioniEconomiche() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.condizioniEconomicheRaw();
            return yield response.value();
        });
    }
    /**
     * Restituisce i termini e le condizioni dei Fondi.
     */
    condizioniFondiRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/rapporti/corrispondenze/fondi/condizioni`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.MessaggioBodyDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce i termini e le condizioni dei Fondi.
     */
    condizioniFondi() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.condizioniFondiRaw();
            return yield response.value();
        });
    }
    /**
     * Export in pdf lista corrispondenze fondi
     */
    corrispondenzaFondiPdfRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.corrispondenzaFondiDto === null || requestParameters.corrispondenzaFondiDto === undefined) {
                throw new runtime.RequiredError('corrispondenzaFondiDto', 'Required parameter requestParameters.corrispondenzaFondiDto was null or undefined when calling corrispondenzaFondiPdf.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/rapporti/corrispondenze/fondi/export/pdf`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters.corrispondenzaFondiDto.map(models_1.CorrispondenzaFondiDtoToJSON),
            });
            return new runtime.BlobApiResponse(response);
        });
    }
    /**
     * Export in pdf lista corrispondenze fondi
     */
    corrispondenzaFondiPdf(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.corrispondenzaFondiPdfRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Recupera le corrispondenze.
     */
    corrispondenzeRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.tipoEstrazione === null || requestParameters.tipoEstrazione === undefined) {
                throw new runtime.RequiredError('tipoEstrazione', 'Required parameter requestParameters.tipoEstrazione was null or undefined when calling corrispondenze.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/rapporti/corrispondenze/lista/{tipoEstrazione}`.replace(`{${"tipoEstrazione"}}`, encodeURIComponent(String(requestParameters.tipoEstrazione))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.CorrispondenzaDtoFromJSON));
        });
    }
    /**
     * Recupera le corrispondenze.
     */
    corrispondenze(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.corrispondenzeRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Recupera le corrispondenze per i fondi.
     */
    corrispondenzeFondiRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/rapporti/corrispondenze/fondi`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.CorrispondenzaFondiDtoFromJSON));
        });
    }
    /**
     * Recupera le corrispondenze per i fondi.
     */
    corrispondenzeFondi() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.corrispondenzeFondiRaw();
            return yield response.value();
        });
    }
    /**
     * Export in PDF del rapporto in base ai criteri inviati per il rapporto indicato
     */
    exportDettaglioRapportoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.rapportoDto === null || requestParameters.rapportoDto === undefined) {
                throw new runtime.RequiredError('rapportoDto', 'Required parameter requestParameters.rapportoDto was null or undefined when calling exportDettaglioRapporto.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/rapporti/export/dettaglio/pdf`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.RapportoDtoToJSON(requestParameters.rapportoDto),
            });
            return new runtime.BlobApiResponse(response);
        });
    }
    /**
     * Export in PDF del rapporto in base ai criteri inviati per il rapporto indicato
     */
    exportDettaglioRapporto(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.exportDettaglioRapportoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Export in PDF canali di comunicazione in base ai criteri inviati per il rapporto indicato
     */
    exportElencoCanaliDiComunicazioneRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.filtroCorrispondenzaDto === null || requestParameters.filtroCorrispondenzaDto === undefined) {
                throw new runtime.RequiredError('filtroCorrispondenzaDto', 'Required parameter requestParameters.filtroCorrispondenzaDto was null or undefined when calling exportElencoCanaliDiComunicazione.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/rapporti/export/elencocanali/pdf`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.FiltroCorrispondenzaDtoToJSON(requestParameters.filtroCorrispondenzaDto),
            });
            return new runtime.BlobApiResponse(response);
        });
    }
    /**
     * Export in PDF canali di comunicazione in base ai criteri inviati per il rapporto indicato
     */
    exportElencoCanaliDiComunicazione(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.exportElencoCanaliDiComunicazioneRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce le informazioni per l\'autenticazione dispositiva.
     */
    infoAutenticazioneCambioCanaliRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/rapporti/corrispondenze/infoautenticazione`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce le informazioni per l\'autenticazione dispositiva.
     */
    infoAutenticazioneCambioCanali() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.infoAutenticazioneCambioCanaliRaw();
            return yield response.value();
        });
    }
    /**
     * Effettua il cambiamento dei canali dei comunicazione.
     */
    operazioneAutenticataCambioCanaliRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.corrispondenzaRequestIn === null || requestParameters.corrispondenzaRequestIn === undefined) {
                throw new runtime.RequiredError('corrispondenzaRequestIn', 'Required parameter requestParameters.corrispondenzaRequestIn was null or undefined when calling operazioneAutenticataCambioCanali.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/rapporti/corrispondenze/cambioCanali`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CorrispondenzaRequestInToJSON(requestParameters.corrispondenzaRequestIn),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.EsitoAutorizzazioneDisposizioneRapportiDtoFromJSON(jsonValue));
        });
    }
    /**
     * Effettua il cambiamento dei canali dei comunicazione.
     */
    operazioneAutenticataCambioCanali(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.operazioneAutenticataCambioCanaliRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Recupera i parametri della corrispondenza.
     */
    parametriCorrispondenzaRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/rapporti/corrispondenze/parametri`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ParametriCorrispondenzaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera i parametri della corrispondenza.
     */
    parametriCorrispondenza() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.parametriCorrispondenzaRaw();
            return yield response.value();
        });
    }
    /**
     * Restituisce la lista dei rapporti predefiniti dell\'utente.
     */
    rapportiAdeguataVerificaRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/rapporti/adeguataverifica`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.AdeguataVerificaRapportiDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce la lista dei rapporti predefiniti dell\'utente.
     */
    rapportiAdeguataVerifica() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.rapportiAdeguataVerificaRaw();
            return yield response.value();
        });
    }
    /**
     * Restituisce le informazioni relative ai rapporti estinti dall\'utente.
     */
    rapportiEstintiRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.filtriEstintiDto === null || requestParameters.filtriEstintiDto === undefined) {
                throw new runtime.RequiredError('filtriEstintiDto', 'Required parameter requestParameters.filtriEstintiDto was null or undefined when calling rapportiEstinti.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/rapporti/estinti`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.FiltriEstintiDtoToJSON(requestParameters.filtriEstintiDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.RapportoDtoFromJSON));
        });
    }
    /**
     * Restituisce le informazioni relative ai rapporti estinti dall\'utente.
     */
    rapportiEstinti(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.rapportiEstintiRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce la lista dei rapporti predefiniti dell\'utente.
     */
    rapportiPredefinitiRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/rapporti/predefiniti`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.RapportoPerServizioDtoFromJSON));
        });
    }
    /**
     * Restituisce la lista dei rapporti predefiniti dell\'utente.
     */
    rapportiPredefiniti() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.rapportiPredefinitiRaw();
            return yield response.value();
        });
    }
    /**
     * Recupera tutti i rapporti dell\'utente suddivisi in categorie.
     */
    rapportiUtenteRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/rapporti/lista`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.RapportoCategoriaDtoFromJSON));
        });
    }
    /**
     * Recupera tutti i rapporti dell\'utente suddivisi in categorie.
     */
    rapportiUtente() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.rapportiUtenteRaw();
            return yield response.value();
        });
    }
    /**
     * Recupera tutti i rapporti dell\'utente suddivisi in categorie per la sezione documentale (Contratti, Documenti)
     */
    rapportiUtenteDocumentaleRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/rapporti/documentale/lista`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.RapportoCategoriaDtoFromJSON));
        });
    }
    /**
     * Recupera tutti i rapporti dell\'utente suddivisi in categorie per la sezione documentale (Contratti, Documenti)
     */
    rapportiUtenteDocumentale() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.rapportiUtenteDocumentaleRaw();
            return yield response.value();
        });
    }
    /**
     * Recupera il saldo complessivo tra tutti i rapporti.
     */
    saldiRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.psd2in !== undefined) {
                queryParameters['psd2in'] = requestParameters.psd2in;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/rapporti/saldo`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.SaldoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera il saldo complessivo tra tutti i rapporti.
     */
    saldi(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.saldiRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Recupera il saldo di un rapporto.
     */
    saldoRapportoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.codiceRapporto === null || requestParameters.codiceRapporto === undefined) {
                throw new runtime.RequiredError('codiceRapporto', 'Required parameter requestParameters.codiceRapporto was null or undefined when calling saldoRapporto.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/rapporti/{codiceRapporto}/saldo`.replace(`{${"codiceRapporto"}}`, encodeURIComponent(String(requestParameters.codiceRapporto))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.SaldoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera il saldo di un rapporto.
     */
    saldoRapporto(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.saldoRapportoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Recupera le corrispondenze.
     */
    salvaCorrispondenzeFondiRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.corrispondenzaFondiDto === null || requestParameters.corrispondenzaFondiDto === undefined) {
                throw new runtime.RequiredError('corrispondenzaFondiDto', 'Required parameter requestParameters.corrispondenzaFondiDto was null or undefined when calling salvaCorrispondenzeFondi.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/rapporti/corrispondenze/fondi`,
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters.corrispondenzaFondiDto.map(models_1.CorrispondenzaFondiDtoToJSON),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.CorrispondenzaFondiDtoFromJSON));
        });
    }
    /**
     * Recupera le corrispondenze.
     */
    salvaCorrispondenzeFondi(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.salvaCorrispondenzeFondiRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Aggiorna l\'alias del rapporto passato in input.
     */
    updateAliasRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.rapportoDto === null || requestParameters.rapportoDto === undefined) {
                throw new runtime.RequiredError('rapportoDto', 'Required parameter requestParameters.rapportoDto was null or undefined when calling updateAlias.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/rapporti/changealias`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.RapportoDtoToJSON(requestParameters.rapportoDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.RapportoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Aggiorna l\'alias del rapporto passato in input.
     */
    updateAlias(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateAliasRaw(requestParameters);
            return yield response.value();
        });
    }
}
exports.RapportiControllerApi = RapportiControllerApi;
