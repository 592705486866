"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkUpdateBozzaRicaricaCellulare = exports.formValidForBozzaRicaricaCellulare = exports.oneClickRicaricaCellulare = exports.bozzeRicaricaCellulare = exports.currentBozzaRicaricaCellulare = exports.salvaBozzaRicaricaCellulare = exports.tagliRicaricaCellulare = exports.gestoriRicaricaCellulare = exports.esitoRicaricaCellulare = exports.riepilogoRicaricaCellulare = exports.infoDispositivaRicaricaCellulare = exports.parametriRicaricaCellulare = exports.disableSalvaBozzaRicaricaCellulare = exports.formValid1ClickRicaricaCellulare = exports.formValidRicaricaCellulare = exports.buildRicaricaCellulare = exports.formRicaricaCellulare = void 0;
const reselect_1 = require("reselect");
const helpers_1 = require("../helpers");
const ricaricaCellulareReducer = (state) => state;
exports.formRicaricaCellulare = (0, reselect_1.createSelector)(ricaricaCellulareReducer, (state) => {
    return state.form;
});
exports.buildRicaricaCellulare = (0, reselect_1.createSelector)(ricaricaCellulareReducer, (state) => {
    var _a, _b, _c, _d, _e, _f, _g;
    return {
        codiceRapporto: ((_b = (_a = state.form.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.id) || 0,
        taglioRicarica: (0, helpers_1.formattedNumberToNumber)(state.form.taglioRicarica),
        numeroCellulare: state.form.numeroCellulare,
        nomeBeneficiario: state.form.nomeBeneficiario,
        codiceSia: state.form.codiceSia,
        idBozzaCollegata: state.currentBozza.id,
        aggiungiRubrica: state.form.aggiungiBeneficiarioRubrica,
        aggiungiWhitelist: state.form.beneficiarioAffidabile,
        aggiungi1click: state.form.salva_1Click,
        alias1Click: state.form.alias_1Click,
        idContattoBeneficiario: state.form.idContattoBeneficiario,
        idOneClickCollegata: state.form.idOneClickCollegata,
        contoAddebitoIban: ((_d = (_c = state.form.rapportoSelezionato) === null || _c === void 0 ? void 0 : _c.rapporto) === null || _d === void 0 ? void 0 : _d.iban) || '',
        ordinanteIntestazione: (_g = (_f = (_e = state.form.rapportoSelezionato) === null || _e === void 0 ? void 0 : _e.rapporto) === null || _f === void 0 ? void 0 : _f.intestazione) !== null && _g !== void 0 ? _g : '',
        nomeGestore: state.form.nomeGestore //
    };
});
exports.formValidRicaricaCellulare = (0, reselect_1.createSelector)(ricaricaCellulareReducer, (state) => {
    let formValid = false;
    if (!state.form.numeroCellulareError &&
        !state.form.codiceSiaError &&
        !state.form.taglioRicaricaError &&
        !state.form.alias_1ClickError &&
        !(state.form.aggiungiBeneficiarioRubrica && state.form.nomeBeneficiarioError)) {
        formValid = true;
    }
    return formValid;
});
exports.formValid1ClickRicaricaCellulare = (0, reselect_1.createSelector)(ricaricaCellulareReducer, (state) => {
    let formValid = false;
    if (!state.form.numeroCellulareError &&
        !state.form.codiceSiaError &&
        !state.form.taglioRicaricaError) {
        formValid = true;
    }
    return formValid;
});
exports.disableSalvaBozzaRicaricaCellulare = (0, reselect_1.createSelector)(ricaricaCellulareReducer, (state) => {
    let isBozzaAccepted = !state.form.bozzaSalvata &&
        (!!state.form.numeroCellulare && !state.form.numeroCellulareError) &&
        (!!state.form.codiceSia && !state.form.codiceSiaError) &&
        (!!state.form.taglioRicarica && !state.form.taglioRicaricaError);
    return !isBozzaAccepted;
});
exports.parametriRicaricaCellulare = (0, reselect_1.createSelector)(ricaricaCellulareReducer, (state) => {
    return state.parametri;
});
exports.infoDispositivaRicaricaCellulare = (0, reselect_1.createSelector)(ricaricaCellulareReducer, (state) => {
    return state.infoDispositiva;
});
exports.riepilogoRicaricaCellulare = (0, reselect_1.createSelector)(ricaricaCellulareReducer, (state) => {
    return state.riepilogo;
});
exports.esitoRicaricaCellulare = (0, reselect_1.createSelector)(ricaricaCellulareReducer, (state) => {
    return state.esito;
});
exports.gestoriRicaricaCellulare = (0, reselect_1.createSelector)(ricaricaCellulareReducer, (state) => {
    return state.gestori;
});
exports.tagliRicaricaCellulare = (0, reselect_1.createSelector)(ricaricaCellulareReducer, (state) => {
    return state.tagli;
});
exports.salvaBozzaRicaricaCellulare = (0, reselect_1.createSelector)(ricaricaCellulareReducer, (state) => {
    return state.bozzeRicaricaCellulare ? state.bozzeRicaricaCellulare.sort((a, b) => b.id - a.id) : [];
});
exports.currentBozzaRicaricaCellulare = (0, reselect_1.createSelector)(ricaricaCellulareReducer, (state) => {
    return state.currentBozza;
});
exports.bozzeRicaricaCellulare = (0, reselect_1.createSelector)(ricaricaCellulareReducer, (state) => {
    return state.bozzeRicaricaCellulare ? state.bozzeRicaricaCellulare.sort((a, b) => b.id - a.id) : [];
});
exports.oneClickRicaricaCellulare = (0, reselect_1.createSelector)(ricaricaCellulareReducer, (state) => {
    return state.oneclick;
});
exports.formValidForBozzaRicaricaCellulare = (0, reselect_1.createSelector)(ricaricaCellulareReducer, (state) => {
    let formValid = false;
    if (!state.form.numeroCellulareError) {
        formValid = true;
    }
    return formValid;
});
exports.checkUpdateBozzaRicaricaCellulare = (0, reselect_1.createSelector)(ricaricaCellulareReducer, (state) => {
    var _a;
    return !!((_a = state.currentBozza) === null || _a === void 0 ? void 0 : _a.id);
});
