"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhoneInfoQrcodeDtoToJSON = exports.PhoneInfoQrcodeDtoFromJSONTyped = exports.PhoneInfoQrcodeDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function PhoneInfoQrcodeDtoFromJSON(json) {
    return PhoneInfoQrcodeDtoFromJSONTyped(json, false);
}
exports.PhoneInfoQrcodeDtoFromJSON = PhoneInfoQrcodeDtoFromJSON;
function PhoneInfoQrcodeDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'contentType': !runtime_1.exists(json, 'contentType') ? undefined : json['contentType'],
        'image': !runtime_1.exists(json, 'image') ? undefined : json['image'],
    };
}
exports.PhoneInfoQrcodeDtoFromJSONTyped = PhoneInfoQrcodeDtoFromJSONTyped;
function PhoneInfoQrcodeDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'contentType': value.contentType,
        'image': value.image,
    };
}
exports.PhoneInfoQrcodeDtoToJSON = PhoneInfoQrcodeDtoToJSON;
