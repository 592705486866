"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BozzeInDtoTipoOrdinamentoEnum = exports.BozzeInDtoToJSON = exports.BozzeInDtoFromJSONTyped = exports.BozzeInDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function BozzeInDtoFromJSON(json) {
    return BozzeInDtoFromJSONTyped(json, false);
}
exports.BozzeInDtoFromJSON = BozzeInDtoFromJSON;
function BozzeInDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'numeroRisultati': !runtime_1.exists(json, 'numeroRisultati') ? undefined : json['numeroRisultati'],
        'naturaMovimento': !runtime_1.exists(json, 'naturaMovimento') ? undefined : json['naturaMovimento'],
        'motivazionePagamento': !runtime_1.exists(json, 'motivazionePagamento') ? undefined : json['motivazionePagamento'],
        'codiceRapporto': !runtime_1.exists(json, 'codiceRapporto') ? undefined : json['codiceRapporto'],
        'tipologia': !runtime_1.exists(json, 'tipologia') ? undefined : json['tipologia'],
        'tipoOrdinamento': !runtime_1.exists(json, 'tipoOrdinamento') ? undefined : json['tipoOrdinamento'],
        'billerEsclusi': !runtime_1.exists(json, 'billerEsclusi') ? undefined : json['billerEsclusi'],
    };
}
exports.BozzeInDtoFromJSONTyped = BozzeInDtoFromJSONTyped;
function BozzeInDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'numeroRisultati': value.numeroRisultati,
        'naturaMovimento': value.naturaMovimento,
        'motivazionePagamento': value.motivazionePagamento,
        'codiceRapporto': value.codiceRapporto,
        'tipologia': value.tipologia,
        'tipoOrdinamento': value.tipoOrdinamento,
        'billerEsclusi': value.billerEsclusi,
    };
}
exports.BozzeInDtoToJSON = BozzeInDtoToJSON;
/**
* @export
* @enum {string}
*/
var BozzeInDtoTipoOrdinamentoEnum;
(function (BozzeInDtoTipoOrdinamentoEnum) {
    BozzeInDtoTipoOrdinamentoEnum["ASC"] = "ASC";
    BozzeInDtoTipoOrdinamentoEnum["DESC"] = "DESC";
})(BozzeInDtoTipoOrdinamentoEnum = exports.BozzeInDtoTipoOrdinamentoEnum || (exports.BozzeInDtoTipoOrdinamentoEnum = {}));
