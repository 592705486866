"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BonificoEsteroDtoToJSON = exports.BonificoEsteroDtoFromJSONTyped = exports.BonificoEsteroDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function BonificoEsteroDtoFromJSON(json) {
    return BonificoEsteroDtoFromJSONTyped(json, false);
}
exports.BonificoEsteroDtoFromJSON = BonificoEsteroDtoFromJSON;
function BonificoEsteroDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'idBozzaCollegata': !runtime_1.exists(json, 'idBozzaCollegata') ? undefined : json['idBozzaCollegata'],
        'contoAddebito': json['contoAddebito'],
        'importo': json['importo'],
        'nomeBeneficiario': json['nomeBeneficiario'],
        'ibanBeneficiario': json['ibanBeneficiario'],
        'causale': json['causale'],
        'condivisione': !runtime_1.exists(json, 'condivisione') ? undefined : (json['condivisione'].map(_1.CondivisioneDtoFromJSON)),
        'bozza': !runtime_1.exists(json, 'bozza') ? undefined : _1.BozzaEsteroDtoFromJSON(json['bozza']),
        'inviato': !runtime_1.exists(json, 'inviato') ? undefined : _1.InviatoDtoFromJSON(json['inviato']),
        'datiEsecuzioneBonifico': !runtime_1.exists(json, 'datiEsecuzioneBonifico') ? undefined : _1.DatiEsecuzioneBonificoEsteroDtoFromJSON(json['datiEsecuzioneBonifico']),
        'contoAddebitoIban': !runtime_1.exists(json, 'contoAddebitoIban') ? undefined : json['contoAddebitoIban'],
        'contoAddebitoAlias': !runtime_1.exists(json, 'contoAddebitoAlias') ? undefined : json['contoAddebitoAlias'],
        'contoAddebitoIntestazione': !runtime_1.exists(json, 'contoAddebitoIntestazione') ? undefined : json['contoAddebitoIntestazione'],
        'idContattoBeneficiario': !runtime_1.exists(json, 'idContattoBeneficiario') ? undefined : json['idContattoBeneficiario'],
        'indirizzo': !runtime_1.exists(json, 'indirizzo') ? undefined : json['indirizzo'],
        'cap': !runtime_1.exists(json, 'cap') ? undefined : json['cap'],
        'provincia': !runtime_1.exists(json, 'provincia') ? undefined : json['provincia'],
        'paeseDestinazione': !runtime_1.exists(json, 'paeseDestinazione') ? undefined : json['paeseDestinazione'],
        'cambioValuta': json['cambioValuta'],
        'controvalore': !runtime_1.exists(json, 'controvalore') ? undefined : json['controvalore'],
        'spreadCambio': !runtime_1.exists(json, 'spreadCambio') ? undefined : json['spreadCambio'],
        'cambioBCE': !runtime_1.exists(json, 'cambioBCE') ? undefined : json['cambioBCE'],
        'codicePaese': json['codicePaese'],
        'divisa': json['divisa'],
        'localita': json['localita'],
        'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
        'spese': !runtime_1.exists(json, 'spese') ? undefined : (json['spese'].map(_1.SpesaBOEDtoFromJSON)),
        'commissioni': !runtime_1.exists(json, 'commissioni') ? undefined : (json['commissioni'].map(_1.SpesaBOEDtoFromJSON)),
        'valutaAddebito': !runtime_1.exists(json, 'valutaAddebito') ? undefined : (json['valutaAddebito'].map(_1.DataValutaBOEDtoFromJSON)),
        'valutaAccredito': !runtime_1.exists(json, 'valutaAccredito') ? undefined : (json['valutaAccredito'].map(_1.DataValutaBOEDtoFromJSON)),
        'mostraControvaloreSpread': !runtime_1.exists(json, 'mostraControvaloreSpread') ? undefined : json['mostraControvaloreSpread'],
        'circuitoSepa': !runtime_1.exists(json, 'circuitoSepa') ? undefined : json['circuitoSepa'],
        'tipoAddebito': json['tipoAddebito'],
        'idOneClickCollegata': !runtime_1.exists(json, 'idOneClickCollegata') ? undefined : json['idOneClickCollegata'],
        'aggiungiRubrica': !runtime_1.exists(json, 'aggiungiRubrica') ? undefined : json['aggiungiRubrica'],
        'aggiungi1click': !runtime_1.exists(json, 'aggiungi1click') ? undefined : json['aggiungi1click'],
        'aggiungiWhitelist': !runtime_1.exists(json, 'aggiungiWhitelist') ? undefined : json['aggiungiWhitelist'],
        'alias1Click': !runtime_1.exists(json, 'alias1Click') ? undefined : json['alias1Click'],
        'ibanFormattato': !runtime_1.exists(json, 'ibanFormattato') ? undefined : json['ibanFormattato'],
        'statoReport': !runtime_1.exists(json, 'statoReport') ? undefined : json['statoReport'],
    };
}
exports.BonificoEsteroDtoFromJSONTyped = BonificoEsteroDtoFromJSONTyped;
function BonificoEsteroDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'idBozzaCollegata': value.idBozzaCollegata,
        'contoAddebito': value.contoAddebito,
        'importo': value.importo,
        'nomeBeneficiario': value.nomeBeneficiario,
        'ibanBeneficiario': value.ibanBeneficiario,
        'causale': value.causale,
        'condivisione': value.condivisione === undefined ? undefined : (value.condivisione.map(_1.CondivisioneDtoToJSON)),
        'bozza': _1.BozzaEsteroDtoToJSON(value.bozza),
        'inviato': _1.InviatoDtoToJSON(value.inviato),
        'datiEsecuzioneBonifico': _1.DatiEsecuzioneBonificoEsteroDtoToJSON(value.datiEsecuzioneBonifico),
        'contoAddebitoIban': value.contoAddebitoIban,
        'contoAddebitoAlias': value.contoAddebitoAlias,
        'contoAddebitoIntestazione': value.contoAddebitoIntestazione,
        'idContattoBeneficiario': value.idContattoBeneficiario,
        'indirizzo': value.indirizzo,
        'cap': value.cap,
        'provincia': value.provincia,
        'paeseDestinazione': value.paeseDestinazione,
        'cambioValuta': value.cambioValuta,
        'controvalore': value.controvalore,
        'spreadCambio': value.spreadCambio,
        'cambioBCE': value.cambioBCE,
        'codicePaese': value.codicePaese,
        'divisa': value.divisa,
        'localita': value.localita,
        'stato': value.stato,
        'spese': value.spese === undefined ? undefined : (value.spese.map(_1.SpesaBOEDtoToJSON)),
        'commissioni': value.commissioni === undefined ? undefined : (value.commissioni.map(_1.SpesaBOEDtoToJSON)),
        'valutaAddebito': value.valutaAddebito === undefined ? undefined : (value.valutaAddebito.map(_1.DataValutaBOEDtoToJSON)),
        'valutaAccredito': value.valutaAccredito === undefined ? undefined : (value.valutaAccredito.map(_1.DataValutaBOEDtoToJSON)),
        'mostraControvaloreSpread': value.mostraControvaloreSpread,
        'circuitoSepa': value.circuitoSepa,
        'tipoAddebito': value.tipoAddebito,
        'idOneClickCollegata': value.idOneClickCollegata,
        'aggiungiRubrica': value.aggiungiRubrica,
        'aggiungi1click': value.aggiungi1click,
        'aggiungiWhitelist': value.aggiungiWhitelist,
        'alias1Click': value.alias1Click,
        'ibanFormattato': value.ibanFormattato,
        'statoReport': value.statoReport,
    };
}
exports.BonificoEsteroDtoToJSON = BonificoEsteroDtoToJSON;
