"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiltroElencoAvvisiDtoToJSON = exports.FiltroElencoAvvisiDtoFromJSONTyped = exports.FiltroElencoAvvisiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function FiltroElencoAvvisiDtoFromJSON(json) {
    return FiltroElencoAvvisiDtoFromJSONTyped(json, false);
}
exports.FiltroElencoAvvisiDtoFromJSON = FiltroElencoAvvisiDtoFromJSON;
function FiltroElencoAvvisiDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codiceRapporto': json['codiceRapporto'],
        'codiceFiscale': !runtime_1.exists(json, 'codiceFiscale') ? undefined : json['codiceFiscale'],
    };
}
exports.FiltroElencoAvvisiDtoFromJSONTyped = FiltroElencoAvvisiDtoFromJSONTyped;
function FiltroElencoAvvisiDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codiceRapporto': value.codiceRapporto,
        'codiceFiscale': value.codiceFiscale,
    };
}
exports.FiltroElencoAvvisiDtoToJSON = FiltroElencoAvvisiDtoToJSON;
