"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CanaleDtoToJSON = exports.CanaleDtoFromJSONTyped = exports.CanaleDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function CanaleDtoFromJSON(json) {
    return CanaleDtoFromJSONTyped(json, false);
}
exports.CanaleDtoFromJSON = CanaleDtoFromJSON;
function CanaleDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'enabled': !runtime_1.exists(json, 'enabled') ? undefined : json['enabled'],
        'selected': !runtime_1.exists(json, 'selected') ? undefined : json['selected'],
    };
}
exports.CanaleDtoFromJSONTyped = CanaleDtoFromJSONTyped;
function CanaleDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'enabled': value.enabled,
        'selected': value.selected,
    };
}
exports.CanaleDtoToJSON = CanaleDtoToJSON;
