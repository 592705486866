"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreAccountLevelBODtoToJSON = exports.PreAccountLevelBODtoFromJSONTyped = exports.PreAccountLevelBODtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function PreAccountLevelBODtoFromJSON(json) {
    return PreAccountLevelBODtoFromJSONTyped(json, false);
}
exports.PreAccountLevelBODtoFromJSON = PreAccountLevelBODtoFromJSON;
function PreAccountLevelBODtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'accountCode': !runtime_1.exists(json, 'accountCode') ? undefined : json['accountCode'],
        'averageIdleCapital': !runtime_1.exists(json, 'averageIdleCapital') ? undefined : json['averageIdleCapital'],
        'bankCode': !runtime_1.exists(json, 'bankCode') ? undefined : json['bankCode'],
        'branchCode': !runtime_1.exists(json, 'branchCode') ? undefined : json['branchCode'],
        'children': !runtime_1.exists(json, 'children') ? undefined : (json['children'].map(_1.PrePositionLevelBODtoFromJSON)),
        'contributionToPerformance': !runtime_1.exists(json, 'contributionToPerformance') ? undefined : json['contributionToPerformance'],
        'endDate': !runtime_1.exists(json, 'endDate') ? undefined : json['endDate'],
        'finalCapital': !runtime_1.exists(json, 'finalCapital') ? undefined : json['finalCapital'],
        'flowCouponsDividends': !runtime_1.exists(json, 'flowCouponsDividends') ? undefined : json['flowCouponsDividends'],
        'flowDeposit': !runtime_1.exists(json, 'flowDeposit') ? undefined : json['flowDeposit'],
        'flowWithdrawal': !runtime_1.exists(json, 'flowWithdrawal') ? undefined : json['flowWithdrawal'],
        'initialCapital': !runtime_1.exists(json, 'initialCapital') ? undefined : json['initialCapital'],
        'message': !runtime_1.exists(json, 'message') ? undefined : json['message'],
        'netReturn': !runtime_1.exists(json, 'netReturn') ? undefined : json['netReturn'],
        'performance': !runtime_1.exists(json, 'performance') ? undefined : json['performance'],
        'startDate': !runtime_1.exists(json, 'startDate') ? undefined : json['startDate'],
        'userId': !runtime_1.exists(json, 'userId') ? undefined : json['userId'],
    };
}
exports.PreAccountLevelBODtoFromJSONTyped = PreAccountLevelBODtoFromJSONTyped;
function PreAccountLevelBODtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'accountCode': value.accountCode,
        'averageIdleCapital': value.averageIdleCapital,
        'bankCode': value.bankCode,
        'branchCode': value.branchCode,
        'children': value.children === undefined ? undefined : (value.children.map(_1.PrePositionLevelBODtoToJSON)),
        'contributionToPerformance': value.contributionToPerformance,
        'endDate': value.endDate,
        'finalCapital': value.finalCapital,
        'flowCouponsDividends': value.flowCouponsDividends,
        'flowDeposit': value.flowDeposit,
        'flowWithdrawal': value.flowWithdrawal,
        'initialCapital': value.initialCapital,
        'message': value.message,
        'netReturn': value.netReturn,
        'performance': value.performance,
        'startDate': value.startDate,
        'userId': value.userId,
    };
}
exports.PreAccountLevelBODtoToJSON = PreAccountLevelBODtoToJSON;
