"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConsentTypeToJSON = exports.ConsentTypeFromJSONTyped = exports.ConsentTypeFromJSON = exports.ConsentType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var ConsentType;
(function (ConsentType) {
    ConsentType["account_list"] = "account-list";
    ConsentType["account_access"] = "account-access";
    ConsentType["global"] = "global";
})(ConsentType = exports.ConsentType || (exports.ConsentType = {}));
function ConsentTypeFromJSON(json) {
    return ConsentTypeFromJSONTyped(json, false);
}
exports.ConsentTypeFromJSON = ConsentTypeFromJSON;
function ConsentTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.ConsentTypeFromJSONTyped = ConsentTypeFromJSONTyped;
function ConsentTypeToJSON(value) {
    return value;
}
exports.ConsentTypeToJSON = ConsentTypeToJSON;
