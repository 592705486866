"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bonificoEsteroReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const bonificoEstero_types_1 = require("./bonificoEstero.types");
const moment = require("moment");
const initialState = {
    parametri: (0, api_rest_1.ParametriBonificoEsteroDtoFromJSON)({}),
    riepilogo: (0, api_rest_1.BonificoEsteroDtoFromJSON)({}),
    infoDispositiva: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
    esito: (0, api_rest_1.EsitoAutorizzazioneDisposizioneBonificoEsteroDtoFromJSON)({}),
    form: (0, bonificoEstero_types_1.FormBonificoEsteroDtoFromJSONTyped)({}),
    bozzeBonifico: (0, api_rest_1.BonificiEsteroDtoFromJSON)({}),
    currentBozza: (0, api_rest_1.BonificoEsteroDtoFromJSON)({}),
    formRicercaBIC: (0, bonificoEstero_types_1.FormBonificoEsteroRicercaBICDtoFromJSONTyped)({}),
    elencoBic: (0, api_rest_1.SearchBicResponseFromJSON)({}),
    elencoDivise: []
};
const bonificoEsteroReducer = (state = initialState, action) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    switch (action.type) {
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_GET_PARAMETRI_SUCCESS: {
            return {
                ...state,
                parametri: action.payload
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_CONTROLLO_SUCCESS: {
            return {
                ...state,
                riepilogo: action.payload
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_GET_INFO_DISPOSITIVA_SUCCESS: {
            return {
                ...state,
                infoDispositiva: action.payload,
                esito: initialState.esito
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_AUTORIZZA_SUCCESS: {
            if (action.payload.stato === 'OK') {
                return {
                    ...state,
                    esito: action.payload
                };
            }
            else {
                return {
                    ...state,
                    infoDispositiva: (0, __1.getInfoAutenticazioneDispositivaCommon)(state.infoDispositiva, action.payload.informazioniAutenticazioneDispositiva),
                    esito: action.payload
                };
            }
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_RAPPORTO_SELEZIONATO: {
            return {
                ...state,
                form: {
                    ...state.form,
                    rapportoSelezionato: action.payload
                }
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_LIST_LOADER: {
            return {
                ...state,
                form: {
                    ...state.form,
                    listLoader: action.payload
                }
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_OFFSET: {
            return {
                ...state,
                form: {
                    ...state.form,
                    offset: action.payload
                }
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_BENEFICIARIO: {
            return {
                ...state,
                form: {
                    ...state.form,
                    beneficiario: action.payload,
                    beneficiarioDirty: true,
                    beneficiarioError: (0, __1.validationField)(action.payload, ''),
                    idContattoBeneficiario: undefined,
                }
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_IBAN: {
            const isSepa = (_a = state.parametri.paesiDestinazioneSepa) === null || _a === void 0 ? void 0 : _a.some(p => p.iso === state.form.paeseDestinazione);
            return {
                ...state,
                form: {
                    ...state.form,
                    iban: action.payload,
                    ibanDirty: true,
                    ibanError: isSepa ? (0, __1.validationFieldIbanSepa)(action.payload, state.form.paeseDestinazione, true, true) : (0, __1.validationField)(action.payload, ''),
                    idContattoBeneficiario: undefined,
                    bozzaSalvata: false
                }
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_STATO: {
            const isSepa = (_b = state.parametri.paesiDestinazioneSepa) === null || _b === void 0 ? void 0 : _b.some(p => p.iso === state.form.paeseDestinazione);
            return {
                ...state,
                form: {
                    ...state.form,
                    stato: action.payload,
                    statoError: (0, __1.validationField)(action.payload, ''),
                    paeseDestinazione: (_e = (_d = (_c = state.parametri) === null || _c === void 0 ? void 0 : _c.paesiDestinazione) === null || _d === void 0 ? void 0 : _d.find(p => p.uic === action.payload)) === null || _e === void 0 ? void 0 : _e.iso,
                    valuta: initialState.form.valuta,
                    ibanError: isSepa ? (0, __1.validationFieldIbanSepa)(action.payload, state.form.paeseDestinazione, true, true) : (0, __1.validationField)(action.payload, '')
                }
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_CITTA: {
            return {
                ...state,
                form: {
                    ...state.form,
                    citta: action.payload,
                    cittaDirty: true,
                    cittaError: (0, __1.validationField)(action.payload, '')
                }
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_BIC_SWIFT: {
            return {
                ...state,
                form: {
                    ...state.form,
                    bicSwift: action.payload,
                    bicSwiftDirty: true,
                    bicSwiftError: (0, __1.validationField)(action.payload, '')
                }
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_ALIAS: {
            return {
                ...state,
                form: {
                    ...state.form,
                    alias: action.payload,
                    aliasDirty: true,
                    aliasError: (0, __1.validationField)(action.payload, '')
                }
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_INDIRIZZO: {
            return {
                ...state,
                form: {
                    ...state.form,
                    indirizzo: action.payload,
                    indirizzoDirty: true,
                    indirizzoError: (0, __1.validationField)(action.payload, '')
                }
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_CAP: {
            return {
                ...state,
                form: {
                    ...state.form,
                    cap: action.payload,
                    capDirty: true,
                    capError: (0, __1.validationField)(action.payload, '')
                }
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_PROVINCIA: {
            return {
                ...state,
                form: {
                    ...state.form,
                    provincia: action.payload,
                    provinciaDirty: true,
                    provinciaError: (0, __1.validationField)(action.payload, '')
                }
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_IMPORTO: {
            return {
                ...state,
                form: {
                    ...state.form,
                    importo: action.payload,
                    importoDirty: true,
                    importoError: (0, __1.validationField)(action.payload, '')
                }
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_VALUTA: {
            return {
                ...state,
                form: {
                    ...state.form,
                    valuta: action.payload,
                    valutaError: (0, __1.validationField)(action.payload, '')
                }
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_DATA_ESECUZIONE: {
            return {
                ...state,
                form: {
                    ...state.form,
                    dataEsecuzione: action.payload,
                    dataEsecuzioneError: (0, __1.validationFieldDate)(action.payload, state.parametri && state.parametri.parametri ? state.parametri.parametri['BONIFICOSEPA.FESTIVI'] : [], true, ((_g = (_f = state.parametri) === null || _f === void 0 ? void 0 : _f.parametri) === null || _g === void 0 ? void 0 : _g['BONIFICOSEPA.PRIMADATAUTILE']) ? state.parametri.parametri['BONIFICOSEPA.PRIMADATAUTILE'].toString() : moment().format('YYYY-MM-DD'))
                }
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_MODALITA_ADDEBITO_SPESE: {
            return {
                ...state,
                form: {
                    ...state.form,
                    modalitaAddebitoSpese: action.payload,
                    //modalitaAddebitoSpeseDirty: true,
                    modalitaAddebitoSpeseError: (0, __1.validationField)(action.payload, '')
                }
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_CAUSALE: {
            return {
                ...state,
                form: {
                    ...state.form,
                    causale: action.payload,
                    causaleDirty: true,
                    causaleError: (0, __1.validationField)(action.payload, '')
                }
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_ORDINANTE_EFFETTIVO: {
            return {
                ...state,
                form: {
                    ...state.form,
                    ordinanteEffettivo: action.payload,
                    ordinanteEffettivoDirty: true,
                    ordinanteEffettivoError: (0, __1.validationField)(action.payload, '')
                }
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_ACCORDION_DATI_AGGIUNTIVI_OPENED: {
            return {
                ...state,
                form: {
                    ...state.form,
                    accordionDatiAggiuntiviOpened: action.payload
                }
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_ACCORDION_DATI_ANAGRAFICI_AGGIUNTIVI_OPENED: {
            return {
                ...state,
                form: {
                    ...state.form,
                    accordionDatiAnagraficiAggiuntiviOpened: action.payload
                }
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_STEP: {
            return {
                ...state,
                form: {
                    ...state.form,
                    step: action.payload
                }
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_FORM_SUBMITTED: {
            return {
                ...state,
                form: {
                    ...state.form,
                    formSubmitted: action.payload
                }
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_CONTROLLO_SUCCESS: {
            return {
                ...state,
                riepilogo: action.payload
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_GET_PARAMETRI_SUCCESS: {
            return {
                ...state,
                parametri: action.payload
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_GET_INFO_DISPOSITIVA_SUCCESS: {
            return {
                ...state,
                infoDispositiva: action.payload
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_AUTORIZZA_SUCCESS: {
            return {
                ...state,
                esito: action.payload
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_GET_PAESI_BIC_SUCCESS: {
            return {
                ...state,
                paesiBic: action.payload
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_GET_BOZZA_BONIFICO_ESTERO_SUCCESS: {
            return {
                ...state,
                currentBozza: action.payload
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_GET_BOZZE_BONIFICO_ESTERO_SUCCESS: {
            return {
                ...state,
                bozzeBonifico: action.payload
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_ELIMINA_BOZZA_BONIFICO_ESTERO_SUCCESS: {
            return {
                ...state,
                bozzeBonifico: {
                    ...state.bozzeBonifico,
                    bonifici: state.bozzeBonifico && state.bozzeBonifico.bonifici
                        ? state.bozzeBonifico.bonifici.filter((bonifico) => bonifico.id !== action.payload)
                        : []
                },
                form: {
                    ...state.form,
                    bozzaSalvata: state.currentBozza.id === action.payload ? false : state.form.bozzaSalvata
                },
                currentBozza: state.currentBozza.id === action.payload ? initialState.currentBozza : state.currentBozza
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SALVA_BOZZA_BONIFICO_ESTERO_SUCCESS: {
            return {
                ...state,
                ...state,
                bozzeBonifico: {
                    ...state.bozzeBonifico,
                    bonifici: state.bozzeBonifico && state.bozzeBonifico.bonifici
                        ? [...state.bozzeBonifico.bonifici, action.payload]
                        : []
                },
                form: {
                    ...state.form,
                    bozzaSalvata: true
                },
                currentBozza: action.payload
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_CERCA_BIC_BONIFICO_ESTERO_SUCCESS: {
            return {
                ...state,
                elencoBic: !action.offset ? action.payload : { ...state.elencoBic, bicList: (((_h = state.elencoBic) === null || _h === void 0 ? void 0 : _h.bicList) || []).concat(action.payload.bicList || []) }
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_AGGIORNA_BOZZA_BONIFICO_ESTERO_SUCCESS: {
            return {
                ...state,
                bozzeBonifico: {
                    ...state.bozzeBonifico,
                    bonifici: state.bozzeBonifico && state.bozzeBonifico.bonifici
                        ? state.bozzeBonifico.bonifici.map(bonifico => bonifico.id === state.currentBozza.id ? action.payload : bonifico)
                        : []
                },
                form: {
                    ...state.form,
                    bozzaSalvata: true
                },
                currentBozza: action.payload
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_GET_BONIFICO_ESTERO_FROM_BOZZA: {
            const rapportoSelezionato = (0, __1.findInArrayRapporti)(state.parametri && state.parametri.rapporti, action.payload.contoAddebito);
            const { contoAddebito, importo, nomeBeneficiario, paeseDestinazione, codicePaese, ibanBeneficiario, localita, datiEsecuzioneBonifico, divisa, tipoAddebito, causale, indirizzo, cap, provincia, cambioValuta, idBozzaCollegata, idContattoBeneficiario } = action.payload;
            const isSepa = (_j = state.parametri.paesiDestinazioneSepa) === null || _j === void 0 ? void 0 : _j.some(p => p.uic === codicePaese);
            console.log('paise dest', paeseDestinazione);
            console.log('paise cod', codicePaese);
            console.log('issepa', isSepa);
            const data = ((datiEsecuzioneBonifico === null || datiEsecuzioneBonifico === void 0 ? void 0 : datiEsecuzioneBonifico.timestampInserimento) && moment(datiEsecuzioneBonifico === null || datiEsecuzioneBonifico === void 0 ? void 0 : datiEsecuzioneBonifico.timestampInserimento) >= moment((_l = (_k = state.parametri) === null || _k === void 0 ? void 0 : _k.parametri) === null || _l === void 0 ? void 0 : _l['BONIFICOESTERO.PRIMADATAUTILE'], 'YYYY-MM-DD'))
                ? moment(datiEsecuzioneBonifico.timestampInserimento).format('YYYY-MM-DD')
                : moment((_o = (_m = state.parametri) === null || _m === void 0 ? void 0 : _m.parametri) === null || _o === void 0 ? void 0 : _o['BONIFICOESTERO.PRIMADATAUTILE'], 'YYYY-MM-DD').format('YYYY-MM-DD');
            return {
                ...state,
                form: {
                    ...state.form,
                    beneficiario: nomeBeneficiario,
                    beneficiarioDirty: false,
                    beneficiarioError: (0, __1.validationField)(nomeBeneficiario, '', false, 3),
                    bicSwift: (datiEsecuzioneBonifico === null || datiEsecuzioneBonifico === void 0 ? void 0 : datiEsecuzioneBonifico.bic) || '',
                    bicSwiftDirty: true,
                    bicSwiftError: (0, __1.validationField)(datiEsecuzioneBonifico === null || datiEsecuzioneBonifico === void 0 ? void 0 : datiEsecuzioneBonifico.bic, ''),
                    stato: codicePaese,
                    statoError: (0, __1.validationField)(codicePaese, ''),
                    citta: localita,
                    cittaDirty: true,
                    cittaError: (0, __1.validationField)(localita, ''),
                    indirizzo: indirizzo || '',
                    indirizzoDirty: true,
                    indirizzoError: (0, __1.validationField)(indirizzo, ''),
                    cap: cap || '',
                    capDirty: true,
                    capError: (0, __1.validationField)(cap, ''),
                    provincia: provincia || '',
                    provinciaDirty: true,
                    provinciaError: (0, __1.validationField)(provincia, ''),
                    iban: ibanBeneficiario,
                    ibanDirty: true,
                    ibanError: isSepa ? (0, __1.validationFieldIbanSepa)(ibanBeneficiario, state.form.paeseDestinazione, true, true) : (0, __1.validationField)(ibanBeneficiario, ''),
                    importo: String(importo),
                    importoDirty: false,
                    importoError: (0, __1.validationField)(importo, 0),
                    valuta: divisa,
                    valutaError: (0, __1.validationField)(divisa, ''),
                    dataEsecuzione: data,
                    rapportoSelezionato: rapportoSelezionato || {},
                    modalitaAddebitoSpese: tipoAddebito,
                    modalitaAddebitoSpeseError: (0, __1.validationField)(tipoAddebito, ''),
                    causale: causale,
                    causaleDirty: false,
                    causaleError: (0, __1.validationField)(causale, '', false, 1, 140),
                    idContattoBeneficiario: idContattoBeneficiario
                },
                currentBozza: action.noBozza ? initialState.currentBozza : action.payload
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_PAESE_BIC_RICERCA: {
            return {
                ...state,
                formRicercaBIC: {
                    ...state.formRicercaBIC,
                    paeseBic: action.payload
                }
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_NOME_BANCA: {
            return {
                ...state,
                formRicercaBIC: {
                    ...state.formRicercaBIC,
                    nomeBanca: action.payload
                }
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_GET_CAMBIO_DIVISA_SUCCESS: {
            return {
                ...state,
                form: {
                    ...state.form,
                    cambioDivisa: action.payload
                }
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_ID_CONTATTO_BENEFICIARIO: {
            return {
                ...state,
                form: {
                    ...state.form,
                    idContattoBeneficiario: action.payload
                }
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_GET_PARAMETRI_PER_RIPETI_SUCCESS:
            return {
                ...initialState,
                elencoDivise: state.elencoDivise,
                parametri: action.payload,
                form: {
                    ...state.form,
                    ripeti: true
                }
            };
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_GET_ELENCO_DIVISE_SUCCESS: {
            return {
                ...state,
                elencoDivise: action.payload
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_RESET_BONIFICO_ESTERO_INFO_DISPOSITIVA: {
            return {
                ...state,
                infoDispositiva: initialState.infoDispositiva
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_RESET_ESITO_BONIFICO_ESTERO: {
            return {
                ...state,
                esito: initialState.esito
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_RESET_RIEPILOGO_BONIFICO_ESTERO: {
            return {
                ...state,
                riepilogo: initialState.riepilogo
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_RESET_FILTRI_RICERCA_BIC: {
            return {
                ...state,
                formRicercaBIC: {
                    ...initialState.formRicercaBIC
                }
            };
        }
        case bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_RESET_BONIFICO_ESTERO: {
            return {
                ...initialState
            };
        }
        default:
            return state;
    }
};
exports.bonificoEsteroReducer = bonificoEsteroReducer;
exports.default = exports.bonificoEsteroReducer;
