"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.altoAdigePassReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const helpers_1 = require("../helpers");
const altoAdigePass_types_1 = require("./altoAdigePass.types");
const initialState = {
    form: (0, altoAdigePass_types_1.FormRicaricaAltoAdigePassDtoFromJSONTyped)({}),
    parametri: (0, api_rest_1.ParametriDtoFromJSON)({}),
    infoDispositiva: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
    datiVerifica: (0, api_rest_1.RicaricaSudtirolPassDtoFromJSON)({}),
    riepilogo: (0, api_rest_1.RicaricaSudtirolPassDtoFromJSON)({}),
    esito: (0, api_rest_1.EsitoAutorizzazioneDisposizioneSudtirolPassDtoFromJSON)({}),
    oneclick: (0, api_rest_1.OperazioniOneClickDtoFromJSON)({})
};
const altoAdigePassReducer = (state = initialState, action) => {
    switch (action.type) {
        case __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_RESET:
            return {
                ...initialState
            };
        case __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_STEP:
            return {
                ...state,
                form: {
                    ...state.form,
                    step: action.payload
                }
            };
        case __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_SET_RAPPORTO_SELEZIONATO:
            return {
                ...state,
                form: {
                    ...state.form,
                    rapportoSelezionato: action.payload
                }
            };
        case __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_NUMERO_ALTO_ADIGE_PASS:
            return {
                ...state,
                form: {
                    ...state.form,
                    numeroAltoAdigePass: action.payload,
                    numeroAltoAdigePassDirty: true,
                    numeroAltoAdigePassError: (0, helpers_1.validationField)(action.payload, '', false, 12),
                    idContattoBeneficiario: undefined,
                    formSubmitted: false
                }
            };
        case __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_VERIFICA_ERROR:
            return {
                ...state,
                form: {
                    ...state.form,
                    numeroAltoAdigePassError: 'invalid.notValidAltoAdigePass'
                }
            };
        case __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_AGGIUNGI_BENEFICIARIO_RUBRICA:
            return {
                ...state,
                form: {
                    ...state.form,
                    aggiungiBeneficiarioRubrica: action.payload,
                    beneficiarioAffidabile: !action.payload && false,
                    nomeBeneficiario: '',
                    nomeBeneficiarioDirty: false,
                    nomeBeneficiarioError: 'invalid.required'
                }
            };
        case __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_NOME_BENIFICIARIO:
            return {
                ...state,
                form: {
                    ...state.form,
                    nomeBeneficiario: action.payload,
                    nomeBeneficiarioDirty: true,
                    nomeBeneficiarioError: (0, helpers_1.validationField)(action.payload, ''),
                    idContattoBeneficiario: undefined
                }
            };
        case __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_BENEFICIARIO_AFFIDABILE:
            return {
                ...state,
                form: {
                    ...state.form,
                    beneficiarioAffidabile: action.payload
                }
            };
        case __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_ID_CONTATTO_BENEFICIARIO:
            return {
                ...state,
                form: {
                    ...state.form,
                    idContattoBeneficiario: action.payload
                }
            };
        case __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_TAGLIO_RICARICA:
            return {
                ...state,
                form: {
                    ...state.form,
                    taglioRicarica: action.payload,
                    taglioRicaricaDirty: true,
                    taglioRicaricaError: (0, helpers_1.validationField)(action.payload, 0)
                }
            };
        case __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_TIPO_OPERAZIONE:
            return {
                ...state,
                form: {
                    ...state.form,
                    tipoOperazione: action.payload
                }
            };
        case __1.EReduxActionTypes.ALTO_ADIGE_PASS_GET_PARAMETRI_SUCCESS:
            return {
                ...state,
                parametri: action.payload
            };
        case __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_GET_PARAMETRI_PER_RIPETI_SUCCESS:
            return {
                ...initialState,
                parametri: action.payload,
                form: {
                    ...state.form,
                    aggiungiBeneficiarioRubrica: false,
                    beneficiarioAffidabile: false,
                    aggiungi1click: false,
                    alias1Click: '',
                    ripeti: true
                }
            };
        case __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_FORM_SUBMITTED:
            return {
                ...state,
                form: {
                    ...state.form,
                    formSubmitted: action.payload
                }
            };
        case __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_GET_INFO_DISPOSITIVA_SUCCESS:
            return {
                ...state,
                infoDispositiva: action.payload,
                esito: initialState.esito
            };
        case __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_CONTROLLO_SUCCESS:
            return {
                ...state,
                riepilogo: action.payload
            };
        case __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_AUTORIZZA_SUCCESS:
            if (action.payload.stato === 'OK') {
                return {
                    ...state,
                    esito: action.payload
                };
            }
            else {
                return {
                    ...state,
                    infoDispositiva: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoDispositiva, action.payload.informazioniAutenticazioneDispositiva),
                    esito: action.payload
                };
            }
        case __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_VERIFICA_START: {
            return {
                ...state,
                datiVerifica: initialState.datiVerifica
            };
        }
        case __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_VERIFICA_SUCCESS:
            return {
                ...state,
                datiVerifica: action.payload
            };
        case __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_GET_RICARICA_ALTO_ADIGE_PASS_FROM_BOZZA:
            const rapportoSelezionato = (0, helpers_1.findInArrayRapporti)(state.parametri && state.parametri.rapporti, action.payload.codiceRapporto);
            return {
                ...state,
                form: {
                    ...state.form,
                    numeroAltoAdigePass: action.payload.pan || '',
                    numeroAltoAdigePassDirty: false,
                    numeroAltoAdigePassError: (0, helpers_1.validationField)(action.payload, '', false, 12),
                    taglioRicarica: action.payload.importoRicarica,
                    taglioRicaricaDirty: false,
                    taglioRicaricaError: (0, helpers_1.validationField)(action.payload.importoRicarica, 0),
                    nomeBeneficiarioError: '',
                    tipoOperazione: (action.payload.tipoOperazione || altoAdigePass_types_1.TipoOperazioneRicaricaAltoAdigePass.RIC),
                    rapportoSelezionato: rapportoSelezionato,
                    idOneClickCollegata: action.payload.idOneClickCollegata
                }
            };
        case __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_RESET_FORM:
            return {
                ...state,
                form: {
                    ...initialState.form
                }
            };
        case __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_RESET_RIEPILOGO:
            return {
                ...state,
                riepilogo: initialState.riepilogo
            };
        case __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_RESET_TAGLIO_RICARICA:
            return {
                ...state,
                form: {
                    ...state.form,
                    taglioRicarica: initialState.form.taglioRicarica,
                    taglioRicaricaDirty: initialState.form.taglioRicaricaDirty,
                    taglioRicaricaError: initialState.form.taglioRicaricaError
                }
            };
        case __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_1CLICK:
            return {
                ...state,
                form: {
                    ...state.form,
                    aggiungi1click: action.payload,
                    alias1Click: action.payload ? state.form.numeroAltoAdigePass : '',
                    aliasDirty: false,
                    aliasError: action.payload
                        ? (0, helpers_1.validationField)(state.form.numeroAltoAdigePass, '')
                        : ''
                }
            };
        case __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_ALIAS_1CLICK:
            return {
                ...state,
                form: {
                    ...state.form,
                    alias1Click: action.payload,
                    aliasDirty: true,
                    aliasError: (0, helpers_1.validationField)(action.payload, '')
                }
            };
        case __1.EReduxActionTypes.RICARICA_ALTO_ADIGE_PASS_GET_ONECLICK_SUCCESS:
            return {
                ...state,
                oneclick: action.payload
            };
        case __1.EReduxActionTypes.RESET_ALL: // action invocata dalla logout
            return {
                ...initialState
            };
        default:
            return state;
    }
};
exports.altoAdigePassReducer = altoAdigePassReducer;
exports.default = exports.altoAdigePassReducer;
