"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AllegatoPrestitoToJSON = exports.AllegatoPrestitoFromJSONTyped = exports.AllegatoPrestitoFromJSON = void 0;
const runtime_1 = require("../runtime");
function AllegatoPrestitoFromJSON(json) {
    return AllegatoPrestitoFromJSONTyped(json, false);
}
exports.AllegatoPrestitoFromJSON = AllegatoPrestitoFromJSON;
function AllegatoPrestitoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'contentType': !runtime_1.exists(json, 'contentType') ? undefined : json['contentType'],
        'data': !runtime_1.exists(json, 'data') ? undefined : json['data'],
        'dataCreazione': !runtime_1.exists(json, 'dataCreazione') ? undefined : (new Date(json['dataCreazione'])),
        'nomeFile': !runtime_1.exists(json, 'nomeFile') ? undefined : json['nomeFile'],
        'titolo': !runtime_1.exists(json, 'titolo') ? undefined : json['titolo'],
    };
}
exports.AllegatoPrestitoFromJSONTyped = AllegatoPrestitoFromJSONTyped;
function AllegatoPrestitoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'contentType': value.contentType,
        'data': value.data,
        'dataCreazione': value.dataCreazione === undefined ? undefined : (value.dataCreazione.toISOString()),
        'nomeFile': value.nomeFile,
        'titolo': value.titolo,
    };
}
exports.AllegatoPrestitoToJSON = AllegatoPrestitoToJSON;
