"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DatiFirmatarioDtoToJSON = exports.DatiFirmatarioDtoFromJSONTyped = exports.DatiFirmatarioDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function DatiFirmatarioDtoFromJSON(json) {
    return DatiFirmatarioDtoFromJSONTyped(json, false);
}
exports.DatiFirmatarioDtoFromJSON = DatiFirmatarioDtoFromJSON;
function DatiFirmatarioDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'ndg': !runtime_1.exists(json, 'ndg') ? undefined : json['ndg'],
        'codiceUtente': !runtime_1.exists(json, 'codiceUtente') ? undefined : json['codiceUtente'],
        'nome': !runtime_1.exists(json, 'nome') ? undefined : json['nome'],
        'cognome': !runtime_1.exists(json, 'cognome') ? undefined : json['cognome'],
        'codiceFiscale': !runtime_1.exists(json, 'codiceFiscale') ? undefined : json['codiceFiscale'],
        'cittadinanza': !runtime_1.exists(json, 'cittadinanza') ? undefined : json['cittadinanza'],
        'sesso': !runtime_1.exists(json, 'sesso') ? undefined : json['sesso'],
        'email': !runtime_1.exists(json, 'email') ? undefined : json['email'],
        'cellulare': !runtime_1.exists(json, 'cellulare') ? undefined : json['cellulare'],
        'filiale': !runtime_1.exists(json, 'filiale') ? undefined : json['filiale'],
        'servizio': !runtime_1.exists(json, 'servizio') ? undefined : json['servizio'],
        'numeroConto': !runtime_1.exists(json, 'numeroConto') ? undefined : json['numeroConto'],
        'lingua': !runtime_1.exists(json, 'lingua') ? undefined : json['lingua'],
        'datiNascita': !runtime_1.exists(json, 'datiNascita') ? undefined : _1.InfoNascitaDtoFromJSON(json['datiNascita']),
        'residenza': !runtime_1.exists(json, 'residenza') ? undefined : _1.IndirizzoDatiAnagraficiDtoFromJSON(json['residenza']),
        'documentoRiconoscimento': !runtime_1.exists(json, 'documentoRiconoscimento') ? undefined : _1.DocumentoDatiAnagraficiDtoFromJSON(json['documentoRiconoscimento']),
    };
}
exports.DatiFirmatarioDtoFromJSONTyped = DatiFirmatarioDtoFromJSONTyped;
function DatiFirmatarioDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'ndg': value.ndg,
        'codiceUtente': value.codiceUtente,
        'nome': value.nome,
        'cognome': value.cognome,
        'codiceFiscale': value.codiceFiscale,
        'cittadinanza': value.cittadinanza,
        'sesso': value.sesso,
        'email': value.email,
        'cellulare': value.cellulare,
        'filiale': value.filiale,
        'servizio': value.servizio,
        'numeroConto': value.numeroConto,
        'lingua': value.lingua,
        'datiNascita': _1.InfoNascitaDtoToJSON(value.datiNascita),
        'residenza': _1.IndirizzoDatiAnagraficiDtoToJSON(value.residenza),
        'documentoRiconoscimento': _1.DocumentoDatiAnagraficiDtoToJSON(value.documentoRiconoscimento),
    };
}
exports.DatiFirmatarioDtoToJSON = DatiFirmatarioDtoToJSON;
