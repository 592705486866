"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ElencoRichiesteInDtoToJSON = exports.ElencoRichiesteInDtoFromJSONTyped = exports.ElencoRichiesteInDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function ElencoRichiesteInDtoFromJSON(json) {
    return ElencoRichiesteInDtoFromJSONTyped(json, false);
}
exports.ElencoRichiesteInDtoFromJSON = ElencoRichiesteInDtoFromJSON;
function ElencoRichiesteInDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'dataInoltroDa': !runtime_1.exists(json, 'dataInoltroDa') ? undefined : (new Date(json['dataInoltroDa'])),
        'dataInoltroA': !runtime_1.exists(json, 'dataInoltroA') ? undefined : (new Date(json['dataInoltroA'])),
        'descrizione': !runtime_1.exists(json, 'descrizione') ? undefined : json['descrizione'],
    };
}
exports.ElencoRichiesteInDtoFromJSONTyped = ElencoRichiesteInDtoFromJSONTyped;
function ElencoRichiesteInDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'dataInoltroDa': value.dataInoltroDa === undefined ? undefined : (value.dataInoltroDa.toISOString()),
        'dataInoltroA': value.dataInoltroA === undefined ? undefined : (value.dataInoltroA.toISOString()),
        'descrizione': value.descrizione,
    };
}
exports.ElencoRichiesteInDtoToJSON = ElencoRichiesteInDtoToJSON;
