"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FinanziamentiDashboardDtoToJSON = exports.FinanziamentiDashboardDtoFromJSONTyped = exports.FinanziamentiDashboardDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function FinanziamentiDashboardDtoFromJSON(json) {
    return FinanziamentiDashboardDtoFromJSONTyped(json, false);
}
exports.FinanziamentiDashboardDtoFromJSON = FinanziamentiDashboardDtoFromJSON;
function FinanziamentiDashboardDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'totaleFinanziamento': !runtime_1.exists(json, 'totaleFinanziamento') ? undefined : json['totaleFinanziamento'],
        'totaleCapitale': !runtime_1.exists(json, 'totaleCapitale') ? undefined : json['totaleCapitale'],
        'totaleInteressi': !runtime_1.exists(json, 'totaleInteressi') ? undefined : json['totaleInteressi'],
        'datiPieBar': !runtime_1.exists(json, 'datiPieBar') ? undefined : _1.PieBarDataDtoFromJSON(json['datiPieBar']),
        'datiLine': !runtime_1.exists(json, 'datiLine') ? undefined : (json['datiLine'].map(_1.LineDataRowDtoFromJSON)),
    };
}
exports.FinanziamentiDashboardDtoFromJSONTyped = FinanziamentiDashboardDtoFromJSONTyped;
function FinanziamentiDashboardDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'totaleFinanziamento': value.totaleFinanziamento,
        'totaleCapitale': value.totaleCapitale,
        'totaleInteressi': value.totaleInteressi,
        'datiPieBar': _1.PieBarDataDtoToJSON(value.datiPieBar),
        'datiLine': value.datiLine === undefined ? undefined : (value.datiLine.map(_1.LineDataRowDtoToJSON)),
    };
}
exports.FinanziamentiDashboardDtoToJSON = FinanziamentiDashboardDtoToJSON;
