"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CodiceAttivazioneGeneratoDtoToJSON = exports.CodiceAttivazioneGeneratoDtoFromJSONTyped = exports.CodiceAttivazioneGeneratoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function CodiceAttivazioneGeneratoDtoFromJSON(json) {
    return CodiceAttivazioneGeneratoDtoFromJSONTyped(json, false);
}
exports.CodiceAttivazioneGeneratoDtoFromJSON = CodiceAttivazioneGeneratoDtoFromJSON;
function CodiceAttivazioneGeneratoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codice': !runtime_1.exists(json, 'codice') ? undefined : json['codice'],
        'qrCodeImg': !runtime_1.exists(json, 'qrCodeImg') ? undefined : json['qrCodeImg'],
        'qrCodeLink': !runtime_1.exists(json, 'qrCodeLink') ? undefined : json['qrCodeLink'],
        'deepLink': !runtime_1.exists(json, 'deepLink') ? undefined : json['deepLink'],
        'dataScadenzaToken': !runtime_1.exists(json, 'dataScadenzaToken') ? undefined : (new Date(json['dataScadenzaToken'])),
    };
}
exports.CodiceAttivazioneGeneratoDtoFromJSONTyped = CodiceAttivazioneGeneratoDtoFromJSONTyped;
function CodiceAttivazioneGeneratoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codice': value.codice,
        'qrCodeImg': value.qrCodeImg,
        'qrCodeLink': value.qrCodeLink,
        'deepLink': value.deepLink,
        'dataScadenzaToken': value.dataScadenzaToken === undefined ? undefined : (value.dataScadenzaToken.toISOString()),
    };
}
exports.CodiceAttivazioneGeneratoDtoToJSON = CodiceAttivazioneGeneratoDtoToJSON;
