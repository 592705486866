"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CampiF24SPDtoStatoEnum = exports.CampiF24SPDtoToJSON = exports.CampiF24SPDtoFromJSONTyped = exports.CampiF24SPDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function CampiF24SPDtoFromJSON(json) {
    return CampiF24SPDtoFromJSONTyped(json, false);
}
exports.CampiF24SPDtoFromJSON = CampiF24SPDtoFromJSON;
function CampiF24SPDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codiceTipologia': json['codiceTipologia'],
        'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
        'importoCommissioni': !runtime_1.exists(json, 'importoCommissioni') ? undefined : json['importoCommissioni'],
        'canale': !runtime_1.exists(json, 'canale') ? undefined : json['canale'],
        'progressivoInserimento': !runtime_1.exists(json, 'progressivoInserimento') ? undefined : json['progressivoInserimento'],
        'annoRiferimento': !runtime_1.exists(json, 'annoRiferimento') ? undefined : json['annoRiferimento'],
        'importoOperazione': !runtime_1.exists(json, 'importoOperazione') ? undefined : json['importoOperazione'],
    };
}
exports.CampiF24SPDtoFromJSONTyped = CampiF24SPDtoFromJSONTyped;
function CampiF24SPDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codiceTipologia': value.codiceTipologia,
        'stato': value.stato,
        'importoCommissioni': value.importoCommissioni,
        'canale': value.canale,
        'progressivoInserimento': value.progressivoInserimento,
        'annoRiferimento': value.annoRiferimento,
        'importoOperazione': value.importoOperazione,
    };
}
exports.CampiF24SPDtoToJSON = CampiF24SPDtoToJSON;
/**
* @export
* @enum {string}
*/
var CampiF24SPDtoStatoEnum;
(function (CampiF24SPDtoStatoEnum) {
    CampiF24SPDtoStatoEnum["DA_AUTORIZZARE"] = "DA_AUTORIZZARE";
    CampiF24SPDtoStatoEnum["BOZZA"] = "BOZZA";
    CampiF24SPDtoStatoEnum["NON_AUTORIZZATO"] = "NON_AUTORIZZATO";
    CampiF24SPDtoStatoEnum["AUTORIZZATO"] = "AUTORIZZATO";
    CampiF24SPDtoStatoEnum["AUTORIZZATO_NON_REV"] = "AUTORIZZATO_NON_REV";
    CampiF24SPDtoStatoEnum["SOSPESO"] = "SOSPESO";
    CampiF24SPDtoStatoEnum["STORNATO"] = "STORNATO";
    CampiF24SPDtoStatoEnum["INVIATO"] = "INVIATO";
    CampiF24SPDtoStatoEnum["REVOCATO"] = "REVOCATO";
    CampiF24SPDtoStatoEnum["PAGATO"] = "PAGATO";
    CampiF24SPDtoStatoEnum["ANNULLATO"] = "ANNULLATO";
})(CampiF24SPDtoStatoEnum = exports.CampiF24SPDtoStatoEnum || (exports.CampiF24SPDtoStatoEnum = {}));
