"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ElencoMovimentiFilterDtoToJSON = exports.ElencoMovimentiFilterDtoFromJSONTyped = exports.ElencoMovimentiFilterDtoFromJSON = void 0;
function ElencoMovimentiFilterDtoFromJSON(json) {
    return ElencoMovimentiFilterDtoFromJSONTyped(json, false);
}
exports.ElencoMovimentiFilterDtoFromJSON = ElencoMovimentiFilterDtoFromJSON;
function ElencoMovimentiFilterDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'pan': json['pan'],
        'codiceRapporto': json['codiceRapporto'],
        'dataIniziale': (new Date(json['dataIniziale'])),
        'dataFinale': (new Date(json['dataFinale'])),
    };
}
exports.ElencoMovimentiFilterDtoFromJSONTyped = ElencoMovimentiFilterDtoFromJSONTyped;
function ElencoMovimentiFilterDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'pan': value.pan,
        'codiceRapporto': value.codiceRapporto,
        'dataIniziale': (value.dataIniziale.toISOString()),
        'dataFinale': (value.dataFinale.toISOString()),
    };
}
exports.ElencoMovimentiFilterDtoToJSON = ElencoMovimentiFilterDtoToJSON;
