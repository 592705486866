"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.InformazioniAutenticazioneDispositivaDtoToJSON = exports.InformazioniAutenticazioneDispositivaDtoFromJSONTyped = exports.InformazioniAutenticazioneDispositivaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function InformazioniAutenticazioneDispositivaDtoFromJSON(json) {
    return InformazioniAutenticazioneDispositivaDtoFromJSONTyped(json, false);
}
exports.InformazioniAutenticazioneDispositivaDtoFromJSON = InformazioniAutenticazioneDispositivaDtoFromJSON;
function InformazioniAutenticazioneDispositivaDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codiceCredenziale': !runtime_1.exists(json, 'codiceCredenziale') ? undefined : json['codiceCredenziale'],
        'otpPinRichiesto': json['otpPinRichiesto'],
        'otpNuovoPinRichiesto': json['otpNuovoPinRichiesto'],
        'otpNextTokenCodeRichiesto': json['otpNextTokenCodeRichiesto'],
        'otpLunghezzaTokenCode': json['otpLunghezzaTokenCode'],
        'tokenSerialNumber': !runtime_1.exists(json, 'tokenSerialNumber') ? undefined : json['tokenSerialNumber'],
        'tokenSoftware': !runtime_1.exists(json, 'tokenSoftware') ? undefined : json['tokenSoftware'],
        'dataScadenza': !runtime_1.exists(json, 'dataScadenza') ? undefined : (new Date(json['dataScadenza'])),
        'contatoreTentativiErratiTotali': json['contatoreTentativiErratiTotali'],
        'captchaRequired': !runtime_1.exists(json, 'captchaRequired') ? undefined : json['captchaRequired'],
        'lunghezzaMinimaCredenziale': json['lunghezzaMinimaCredenziale'],
        'lunghezzaMassimaCredenziale': !runtime_1.exists(json, 'lunghezzaMassimaCredenziale') ? undefined : json['lunghezzaMassimaCredenziale'],
        'lunghezzaPin': !runtime_1.exists(json, 'lunghezzaPin') ? undefined : json['lunghezzaPin'],
        'posizioniRichiestes': !runtime_1.exists(json, 'posizioniRichiestes') ? undefined : json['posizioniRichiestes'],
        'chiamataUscente': !runtime_1.exists(json, 'chiamataUscente') ? undefined : json['chiamataUscente'],
        'prefissoInternazionale': !runtime_1.exists(json, 'prefissoInternazionale') ? undefined : json['prefissoInternazionale'],
        'numeroDaChiamare': !runtime_1.exists(json, 'numeroDaChiamare') ? undefined : json['numeroDaChiamare'],
        'otp': !runtime_1.exists(json, 'otp') ? undefined : json['otp'],
        'codiceApplicativo': !runtime_1.exists(json, 'codiceApplicativo') ? undefined : json['codiceApplicativo'],
        'codiceApplicativoLabel': !runtime_1.exists(json, 'codiceApplicativoLabel') ? undefined : json['codiceApplicativoLabel'],
        'tempoRimanente': !runtime_1.exists(json, 'tempoRimanente') ? undefined : json['tempoRimanente'],
        'tempoTotale': !runtime_1.exists(json, 'tempoTotale') ? undefined : json['tempoTotale'],
        'qrCodeSecureCall': !runtime_1.exists(json, 'qrCodeSecureCall') ? undefined : json['qrCodeSecureCall'],
        'esitoDisponibile': !runtime_1.exists(json, 'esitoDisponibile') ? undefined : json['esitoDisponibile'],
        'token': !runtime_1.exists(json, 'token') ? undefined : json['token'],
        'produttore': !runtime_1.exists(json, 'produttore') ? undefined : json['produttore'],
        'modello': !runtime_1.exists(json, 'modello') ? undefined : json['modello'],
        'otpSignaturePayload': !runtime_1.exists(json, 'otpSignaturePayload') ? undefined : json['otpSignaturePayload'],
        'deployed': !runtime_1.exists(json, 'deployed') ? undefined : json['deployed'],
        'tipoToken': !runtime_1.exists(json, 'tipoToken') ? undefined : json['tipoToken'],
        'statoToken': !runtime_1.exists(json, 'statoToken') ? undefined : json['statoToken'],
        'qrCodeImmagine': !runtime_1.exists(json, 'qrCodeImmagine') ? undefined : json['qrCodeImmagine'],
        'qrCodeContentType': !runtime_1.exists(json, 'qrCodeContentType') ? undefined : json['qrCodeContentType'],
        'visualizzareCampoInput': !runtime_1.exists(json, 'visualizzareCampoInput') ? undefined : json['visualizzareCampoInput'],
        'offlineDisponibile': !runtime_1.exists(json, 'offlineDisponibile') ? undefined : json['offlineDisponibile'],
        'asincrona': !runtime_1.exists(json, 'asincrona') ? undefined : json['asincrona'],
        'tipoAsincrona': !runtime_1.exists(json, 'tipoAsincrona') ? undefined : json['tipoAsincrona'],
        'parametri': !runtime_1.exists(json, 'parametri') ? undefined : json['parametri'],
        'campiStrutturati': !runtime_1.exists(json, 'campiStrutturati') ? undefined : json['campiStrutturati'],
    };
}
exports.InformazioniAutenticazioneDispositivaDtoFromJSONTyped = InformazioniAutenticazioneDispositivaDtoFromJSONTyped;
function InformazioniAutenticazioneDispositivaDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codiceCredenziale': value.codiceCredenziale,
        'otpPinRichiesto': value.otpPinRichiesto,
        'otpNuovoPinRichiesto': value.otpNuovoPinRichiesto,
        'otpNextTokenCodeRichiesto': value.otpNextTokenCodeRichiesto,
        'otpLunghezzaTokenCode': value.otpLunghezzaTokenCode,
        'tokenSerialNumber': value.tokenSerialNumber,
        'tokenSoftware': value.tokenSoftware,
        'dataScadenza': value.dataScadenza === undefined ? undefined : (value.dataScadenza.toISOString()),
        'contatoreTentativiErratiTotali': value.contatoreTentativiErratiTotali,
        'captchaRequired': value.captchaRequired,
        'lunghezzaMinimaCredenziale': value.lunghezzaMinimaCredenziale,
        'lunghezzaMassimaCredenziale': value.lunghezzaMassimaCredenziale,
        'lunghezzaPin': value.lunghezzaPin,
        'posizioniRichiestes': value.posizioniRichiestes,
        'chiamataUscente': value.chiamataUscente,
        'prefissoInternazionale': value.prefissoInternazionale,
        'numeroDaChiamare': value.numeroDaChiamare,
        'otp': value.otp,
        'codiceApplicativo': value.codiceApplicativo,
        'codiceApplicativoLabel': value.codiceApplicativoLabel,
        'tempoRimanente': value.tempoRimanente,
        'tempoTotale': value.tempoTotale,
        'qrCodeSecureCall': value.qrCodeSecureCall,
        'esitoDisponibile': value.esitoDisponibile,
        'token': value.token,
        'produttore': value.produttore,
        'modello': value.modello,
        'otpSignaturePayload': value.otpSignaturePayload,
        'deployed': value.deployed,
        'tipoToken': value.tipoToken,
        'statoToken': value.statoToken,
        'qrCodeImmagine': value.qrCodeImmagine,
        'qrCodeContentType': value.qrCodeContentType,
        'visualizzareCampoInput': value.visualizzareCampoInput,
        'offlineDisponibile': value.offlineDisponibile,
        'asincrona': value.asincrona,
        'tipoAsincrona': value.tipoAsincrona,
        'parametri': value.parametri,
        'campiStrutturati': value.campiStrutturati,
    };
}
exports.InformazioniAutenticazioneDispositivaDtoToJSON = InformazioniAutenticazioneDispositivaDtoToJSON;
