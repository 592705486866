"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DatiFiscaliDtoToJSON = exports.DatiFiscaliDtoFromJSONTyped = exports.DatiFiscaliDtoFromJSON = void 0;
function DatiFiscaliDtoFromJSON(json) {
    return DatiFiscaliDtoFromJSONTyped(json, false);
}
exports.DatiFiscaliDtoFromJSON = DatiFiscaliDtoFromJSON;
function DatiFiscaliDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'beneficiario': json['beneficiario'],
        'amministratore': json['amministratore'],
        'ordinanti': json['ordinanti'],
    };
}
exports.DatiFiscaliDtoFromJSONTyped = DatiFiscaliDtoFromJSONTyped;
function DatiFiscaliDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'beneficiario': value.beneficiario,
        'amministratore': value.amministratore,
        'ordinanti': value.ordinanti,
    };
}
exports.DatiFiscaliDtoToJSON = DatiFiscaliDtoToJSON;
