"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.WatchlistItemInDtoToJSON = exports.WatchlistItemInDtoFromJSONTyped = exports.WatchlistItemInDtoFromJSON = void 0;
function WatchlistItemInDtoFromJSON(json) {
    return WatchlistItemInDtoFromJSONTyped(json, false);
}
exports.WatchlistItemInDtoFromJSON = WatchlistItemInDtoFromJSON;
function WatchlistItemInDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codiceWatchlist': json['codiceWatchlist'],
        'mercato': json['mercato'],
        'codice': json['codice'],
    };
}
exports.WatchlistItemInDtoFromJSONTyped = WatchlistItemInDtoFromJSONTyped;
function WatchlistItemInDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codiceWatchlist': value.codiceWatchlist,
        'mercato': value.mercato,
        'codice': value.codice,
    };
}
exports.WatchlistItemInDtoToJSON = WatchlistItemInDtoToJSON;
