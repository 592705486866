"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * API BFF Customer Web Collaboration
 * API BFF Customer Web Collaboration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SlotToJSON = exports.SlotFromJSONTyped = exports.SlotFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function SlotFromJSON(json) {
    return SlotFromJSONTyped(json, false);
}
exports.SlotFromJSON = SlotFromJSON;
function SlotFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'errorManagementResponse': !runtime_1.exists(json, 'errorManagementResponse') ? undefined : _1.ErrorManagementResponseFromJSON(json['errorManagementResponse']),
        'from': !runtime_1.exists(json, 'from') ? undefined : json['from'],
        'lstCounselor': !runtime_1.exists(json, 'lstCounselor') ? undefined : (json['lstCounselor'].map(_1.CounselorResponseFromJSON)),
        'range': !runtime_1.exists(json, 'range') ? undefined : json['range'],
        'to': !runtime_1.exists(json, 'to') ? undefined : json['to'],
    };
}
exports.SlotFromJSONTyped = SlotFromJSONTyped;
function SlotToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'errorManagementResponse': _1.ErrorManagementResponseToJSON(value.errorManagementResponse),
        'from': value.from,
        'lstCounselor': value.lstCounselor === undefined ? undefined : (value.lstCounselor.map(_1.CounselorResponseToJSON)),
        'range': value.range,
        'to': value.to,
    };
}
exports.SlotToJSON = SlotToJSON;
