import { currencyFormat } from "helpers/utilities";
import React from "react";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";

const InvestimentiTable: React.FC<iProps> = (props: iProps) => {
  const { t } = useTranslation();
  const {
    data,
    noItemsText,
    boldHeader,
    compactHeader,
    tableTitle,
    aggiungiPreferito,
    rimuoviPreferito,
  } = props;
  const columns = [
    {
      name: "descrizioneTitolo",
      descrizione: "patrimonio.tableLabels.denominazione",
      formatter: "",
      bold: true,
      isIntestatario: true,
    },
    {
      name: "divisoEmissione",
      descrizione: "patrimonio.tableLabels.divisa",
      bold: true,
      align: "right",
    },
    {
      name: "quantita",
      descrizione: "patrimonio.tableLabels.quote",
      bold: true,
      isPriceQuote: true,
      align: "right",
    },
    {
      name: "controvaloreEuro",
      descrizione: "patrimonio.tableLabels.controvalore",
      bold: true,
      align: "right",
      isPrice: true,
    },
  ] as Column[];

  const buildNoItemMesage = (noItemsText: any) => {
    if (noItemsText && noItemsText.length) {
      noItemsText.map((item: any) => {
        return <p>{item}</p>;
      });
    }
  };

  return (
    <div className="custom-table">
      {/* <p className="form-title">{tableTitle}</p>
       */}
      {data &&
        data.length > 0 &&
        data.map((item: any, index: number) => {
          return (
            <div key={index}>
              <div className="custom-table-header">
                <p className="custom-table-header_title">
                  {t("patrimonio.tableLabels.intestatario")}
                  <span className="custom-table-header_value">
                    {item.intestatario}
                  </span>
                </p>
                <p className="custom-table-header_title">
                  {t("patrimonio.tableLabels.rapporto")}
                  <span className="custom-table-header_value">{item.iban}</span>
                </p>
              </div>

              {item && item.saldiList.length > 0 ? (
                <Table hover borderless responsive>
                  <thead>
                    <tr>
                      {columns.map((column: Column, index: number) => (
                        <th
                          style={column.width ? { width: column.width } : {}}
                          key={index}
                          className={`${
                            column.align ? `align-${column.align}` : ``
                            }  ${
                            compactHeader ? `compact-header` : ``
                            } small-header bold-text`}
                        >
                          {column.descrizione ? t(column.descrizione) : ""}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {item.saldiList.length > 0 &&
                      item.saldiList.map((item: any, index: number) => {
                        const itemAsAny = item as any;
                        return (
                          <tr key={index}>
                            {columns.map((column: Column, index: number) => {
                              const key = column.name;
                              return (
                                <td
                                  key={index + column.name}
                                  className={`${
                                    column.compact ? "compact " : ""
                                    }${
                                    column.align ? `align-${column.align} ` : ``
                                    }`}
                                >
                                  {column.isPrice
                                    ? `${currencyFormat(itemAsAny[key])}  EUR`
                                    : column.isPriceQuote ? currencyFormat(itemAsAny[key]) :
                                      itemAsAny[key]}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              ) : (
                  <div>
                    {noItemsText &&
                      noItemsText.map((item: any, index: number) => (
                        <p key={`infoText-${index}`}>{item}</p>
                      ))}
                  </div>
                )}
            </div>
          );
        })}
    </div>
  );
};

export interface Column {
  name: string;
  descrizione?: string;
  align?: "left" | "center" | "right";
  compact?: boolean;
  bold?: boolean;
  isPrice?: boolean;
  isPriceQuote?: boolean;
  width?: number;
}

interface iProps {
  data?: any;
  noItemsText?: any;
  boldHeader?: boolean;
  compactHeader?: boolean;
  tableTitle?: string;
  aggiungiPreferito?: any;
  rimuoviPreferito?: any;
}

export default InvestimentiTable;
