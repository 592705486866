"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RipetitivoDtoStatoDisposizioneEnum = exports.RipetitivoDtoNaturaMovimentoEnum = exports.RipetitivoDtoTipoSupportoEnum = exports.RipetitivoDtoTipoMovimentoEnum = exports.RipetitivoDtoFormaTecnicaAccreditoEnum = exports.RipetitivoDtoFormaTecnicaAddebitoEnum = exports.RipetitivoDtoCanaleProvenienzaEnum = exports.RipetitivoDtoToJSON = exports.RipetitivoDtoFromJSONTyped = exports.RipetitivoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function RipetitivoDtoFromJSON(json) {
    return RipetitivoDtoFromJSONTyped(json, false);
}
exports.RipetitivoDtoFromJSON = RipetitivoDtoFromJSON;
function RipetitivoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'idVirtuale': !runtime_1.exists(json, 'idVirtuale') ? undefined : json['idVirtuale'],
        'dataRiferimento': !runtime_1.exists(json, 'dataRiferimento') ? undefined : (new Date(json['dataRiferimento'])),
        'dataInserimento': !runtime_1.exists(json, 'dataInserimento') ? undefined : (new Date(json['dataInserimento'])),
        'canaleProvenienza': !runtime_1.exists(json, 'canaleProvenienza') ? undefined : json['canaleProvenienza'],
        'chiaveSottosistema': !runtime_1.exists(json, 'chiaveSottosistema') ? undefined : json['chiaveSottosistema'],
        'riferimentoOperazioneIHB': !runtime_1.exists(json, 'riferimentoOperazioneIHB') ? undefined : json['riferimentoOperazioneIHB'],
        'dataRicenzioneOrdine': !runtime_1.exists(json, 'dataRicenzioneOrdine') ? undefined : (new Date(json['dataRicenzioneOrdine'])),
        'forzaturaInserimento': !runtime_1.exists(json, 'forzaturaInserimento') ? undefined : json['forzaturaInserimento'],
        'formaTecnicaAddebito': !runtime_1.exists(json, 'formaTecnicaAddebito') ? undefined : json['formaTecnicaAddebito'],
        'descrizioneFormaTecnicaAddebito': !runtime_1.exists(json, 'descrizioneFormaTecnicaAddebito') ? undefined : json['descrizioneFormaTecnicaAddebito'],
        'ordinante': !runtime_1.exists(json, 'ordinante') ? undefined : _1.OrdinanteDtoFromJSON(json['ordinante']),
        'disposizione': !runtime_1.exists(json, 'disposizione') ? undefined : _1.DisposizioneDtoFromJSON(json['disposizione']),
        'anagraficaOrdinanteOriginario': !runtime_1.exists(json, 'anagraficaOrdinanteOriginario') ? undefined : json['anagraficaOrdinanteOriginario'],
        'indirizzoOrdinanteOriginario': !runtime_1.exists(json, 'indirizzoOrdinanteOriginario') ? undefined : _1.IndirizzoDtoFromJSON(json['indirizzoOrdinanteOriginario']),
        'formaTecnicaAccredito': !runtime_1.exists(json, 'formaTecnicaAccredito') ? undefined : json['formaTecnicaAccredito'],
        'descrizioneFormaTecnicaAccredito': !runtime_1.exists(json, 'descrizioneFormaTecnicaAccredito') ? undefined : json['descrizioneFormaTecnicaAccredito'],
        'causaleAccredito': !runtime_1.exists(json, 'causaleAccredito') ? undefined : json['causaleAccredito'],
        'beneficiario': !runtime_1.exists(json, 'beneficiario') ? undefined : _1.BeneficiarioDtoFromJSON(json['beneficiario']),
        'anagraficaBeneficiarioOriginario': !runtime_1.exists(json, 'anagraficaBeneficiarioOriginario') ? undefined : json['anagraficaBeneficiarioOriginario'],
        'indirizzoBeneficiarioOriginario': !runtime_1.exists(json, 'indirizzoBeneficiarioOriginario') ? undefined : _1.IndirizzoDtoFromJSON(json['indirizzoBeneficiarioOriginario']),
        'tipoMovimento': !runtime_1.exists(json, 'tipoMovimento') ? undefined : json['tipoMovimento'],
        'tipoSupporto': !runtime_1.exists(json, 'tipoSupporto') ? undefined : json['tipoSupporto'],
        'naturaMovimento': !runtime_1.exists(json, 'naturaMovimento') ? undefined : json['naturaMovimento'],
        'motivazioneMovimento': !runtime_1.exists(json, 'motivazioneMovimento') ? undefined : json['motivazioneMovimento'],
        'destinatario': !runtime_1.exists(json, 'destinatario') ? undefined : _1.DestinatarioDtoFromJSON(json['destinatario']),
        'statoDisposizione': !runtime_1.exists(json, 'statoDisposizione') ? undefined : json['statoDisposizione'],
        'condivisione': !runtime_1.exists(json, 'condivisione') ? undefined : (json['condivisione'].map(_1.CondivisioneDtoFromJSON)),
        'bozza': !runtime_1.exists(json, 'bozza') ? undefined : _1.BozzaDtoFromJSON(json['bozza']),
        'inviato': !runtime_1.exists(json, 'inviato') ? undefined : _1.InviatoDtoFromJSON(json['inviato']),
        'idBozzaCollegata': !runtime_1.exists(json, 'idBozzaCollegata') ? undefined : json['idBozzaCollegata'],
        'provenienza': !runtime_1.exists(json, 'provenienza') ? undefined : json['provenienza'],
        'forzaturaVariazione': !runtime_1.exists(json, 'forzaturaVariazione') ? undefined : json['forzaturaVariazione'],
        'dataInserimentoRevoca': !runtime_1.exists(json, 'dataInserimentoRevoca') ? undefined : (new Date(json['dataInserimentoRevoca'])),
        'dataInserimentoSospensione': !runtime_1.exists(json, 'dataInserimentoSospensione') ? undefined : (new Date(json['dataInserimentoSospensione'])),
        'dataInizioSospensione': !runtime_1.exists(json, 'dataInizioSospensione') ? undefined : (new Date(json['dataInizioSospensione'])),
        'dataFineSospensione': !runtime_1.exists(json, 'dataFineSospensione') ? undefined : (new Date(json['dataFineSospensione'])),
        'dataRiattivazione': !runtime_1.exists(json, 'dataRiattivazione') ? undefined : (new Date(json['dataRiattivazione'])),
        'bloccoSelezioneRangePag': !runtime_1.exists(json, 'bloccoSelezioneRangePag') ? undefined : json['bloccoSelezioneRangePag'],
        'tipoUltimoAggiornamento': !runtime_1.exists(json, 'tipoUltimoAggiornamento') ? undefined : json['tipoUltimoAggiornamento'],
        'dataUltimoAggiornamento': !runtime_1.exists(json, 'dataUltimoAggiornamento') ? undefined : (new Date(json['dataUltimoAggiornamento'])),
        'causaleAbi': !runtime_1.exists(json, 'causaleAbi') ? undefined : json['causaleAbi'],
        'descrizioneCausale': !runtime_1.exists(json, 'descrizioneCausale') ? undefined : json['descrizioneCausale'],
        'descrizioneMotivazioneOrdine': !runtime_1.exists(json, 'descrizioneMotivazioneOrdine') ? undefined : json['descrizioneMotivazioneOrdine'],
        'descrizioneNaturaDisposizione': !runtime_1.exists(json, 'descrizioneNaturaDisposizione') ? undefined : json['descrizioneNaturaDisposizione'],
        'tipoAprossimazione': !runtime_1.exists(json, 'tipoAprossimazione') ? undefined : json['tipoAprossimazione'],
        'causaleAddebito': !runtime_1.exists(json, 'causaleAddebito') ? undefined : json['causaleAddebito'],
        'dataAddebitoPrecedente': !runtime_1.exists(json, 'dataAddebitoPrecedente') ? undefined : (new Date(json['dataAddebitoPrecedente'])),
        'dataAddebitoSuccessivo': !runtime_1.exists(json, 'dataAddebitoSuccessivo') ? undefined : (new Date(json['dataAddebitoSuccessivo'])),
        'noteInterne': !runtime_1.exists(json, 'noteInterne') ? undefined : json['noteInterne'],
        'semaforo': !runtime_1.exists(json, 'semaforo') ? undefined : json['semaforo'],
        'idContattoBeneficiario': !runtime_1.exists(json, 'idContattoBeneficiario') ? undefined : json['idContattoBeneficiario'],
        'aggiungiRubrica': !runtime_1.exists(json, 'aggiungiRubrica') ? undefined : json['aggiungiRubrica'],
        'aggiungi1click': !runtime_1.exists(json, 'aggiungi1click') ? undefined : json['aggiungi1click'],
        'aggiungiWhitelist': !runtime_1.exists(json, 'aggiungiWhitelist') ? undefined : json['aggiungiWhitelist'],
        'contoAddebitoIban': !runtime_1.exists(json, 'contoAddebitoIban') ? undefined : json['contoAddebitoIban'],
        'contoAddebitoAlias': !runtime_1.exists(json, 'contoAddebitoAlias') ? undefined : json['contoAddebitoAlias'],
        'ordinanteIntestazione': !runtime_1.exists(json, 'ordinanteIntestazione') ? undefined : json['ordinanteIntestazione'],
        'alias1Click': !runtime_1.exists(json, 'alias1Click') ? undefined : json['alias1Click'],
        'statoReport': !runtime_1.exists(json, 'statoReport') ? undefined : json['statoReport'],
        'psd2PaymentDto': !runtime_1.exists(json, 'psd2PaymentDto') ? undefined : _1.Psd2PaymentDtoFromJSON(json['psd2PaymentDto']),
        'revocabile': !runtime_1.exists(json, 'revocabile') ? undefined : json['revocabile'],
        'modificabile': !runtime_1.exists(json, 'modificabile') ? undefined : json['modificabile'],
        'sospendibile': !runtime_1.exists(json, 'sospendibile') ? undefined : json['sospendibile'],
        'riattivabile': !runtime_1.exists(json, 'riattivabile') ? undefined : json['riattivabile'],
    };
}
exports.RipetitivoDtoFromJSONTyped = RipetitivoDtoFromJSONTyped;
function RipetitivoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'idVirtuale': value.idVirtuale,
        'dataRiferimento': value.dataRiferimento === undefined ? undefined : (value.dataRiferimento.toISOString()),
        'dataInserimento': value.dataInserimento === undefined ? undefined : (value.dataInserimento.toISOString()),
        'canaleProvenienza': value.canaleProvenienza,
        'chiaveSottosistema': value.chiaveSottosistema,
        'riferimentoOperazioneIHB': value.riferimentoOperazioneIHB,
        'dataRicenzioneOrdine': value.dataRicenzioneOrdine === undefined ? undefined : (value.dataRicenzioneOrdine.toISOString()),
        'forzaturaInserimento': value.forzaturaInserimento,
        'formaTecnicaAddebito': value.formaTecnicaAddebito,
        'descrizioneFormaTecnicaAddebito': value.descrizioneFormaTecnicaAddebito,
        'ordinante': _1.OrdinanteDtoToJSON(value.ordinante),
        'disposizione': _1.DisposizioneDtoToJSON(value.disposizione),
        'anagraficaOrdinanteOriginario': value.anagraficaOrdinanteOriginario,
        'indirizzoOrdinanteOriginario': _1.IndirizzoDtoToJSON(value.indirizzoOrdinanteOriginario),
        'formaTecnicaAccredito': value.formaTecnicaAccredito,
        'descrizioneFormaTecnicaAccredito': value.descrizioneFormaTecnicaAccredito,
        'causaleAccredito': value.causaleAccredito,
        'beneficiario': _1.BeneficiarioDtoToJSON(value.beneficiario),
        'anagraficaBeneficiarioOriginario': value.anagraficaBeneficiarioOriginario,
        'indirizzoBeneficiarioOriginario': _1.IndirizzoDtoToJSON(value.indirizzoBeneficiarioOriginario),
        'tipoMovimento': value.tipoMovimento,
        'tipoSupporto': value.tipoSupporto,
        'naturaMovimento': value.naturaMovimento,
        'motivazioneMovimento': value.motivazioneMovimento,
        'destinatario': _1.DestinatarioDtoToJSON(value.destinatario),
        'statoDisposizione': value.statoDisposizione,
        'condivisione': value.condivisione === undefined ? undefined : (value.condivisione.map(_1.CondivisioneDtoToJSON)),
        'bozza': _1.BozzaDtoToJSON(value.bozza),
        'inviato': _1.InviatoDtoToJSON(value.inviato),
        'idBozzaCollegata': value.idBozzaCollegata,
        'provenienza': value.provenienza,
        'forzaturaVariazione': value.forzaturaVariazione,
        'dataInserimentoRevoca': value.dataInserimentoRevoca === undefined ? undefined : (value.dataInserimentoRevoca.toISOString()),
        'dataInserimentoSospensione': value.dataInserimentoSospensione === undefined ? undefined : (value.dataInserimentoSospensione.toISOString()),
        'dataInizioSospensione': value.dataInizioSospensione === undefined ? undefined : (value.dataInizioSospensione.toISOString()),
        'dataFineSospensione': value.dataFineSospensione === undefined ? undefined : (value.dataFineSospensione.toISOString()),
        'dataRiattivazione': value.dataRiattivazione === undefined ? undefined : (value.dataRiattivazione.toISOString()),
        'bloccoSelezioneRangePag': value.bloccoSelezioneRangePag,
        'tipoUltimoAggiornamento': value.tipoUltimoAggiornamento,
        'dataUltimoAggiornamento': value.dataUltimoAggiornamento === undefined ? undefined : (value.dataUltimoAggiornamento.toISOString()),
        'causaleAbi': value.causaleAbi,
        'descrizioneCausale': value.descrizioneCausale,
        'descrizioneMotivazioneOrdine': value.descrizioneMotivazioneOrdine,
        'descrizioneNaturaDisposizione': value.descrizioneNaturaDisposizione,
        'tipoAprossimazione': value.tipoAprossimazione,
        'causaleAddebito': value.causaleAddebito,
        'dataAddebitoPrecedente': value.dataAddebitoPrecedente === undefined ? undefined : (value.dataAddebitoPrecedente.toISOString()),
        'dataAddebitoSuccessivo': value.dataAddebitoSuccessivo === undefined ? undefined : (value.dataAddebitoSuccessivo.toISOString()),
        'noteInterne': value.noteInterne,
        'semaforo': value.semaforo,
        'idContattoBeneficiario': value.idContattoBeneficiario,
        'aggiungiRubrica': value.aggiungiRubrica,
        'aggiungi1click': value.aggiungi1click,
        'aggiungiWhitelist': value.aggiungiWhitelist,
        'contoAddebitoIban': value.contoAddebitoIban,
        'contoAddebitoAlias': value.contoAddebitoAlias,
        'ordinanteIntestazione': value.ordinanteIntestazione,
        'alias1Click': value.alias1Click,
        'statoReport': value.statoReport,
        'psd2PaymentDto': _1.Psd2PaymentDtoToJSON(value.psd2PaymentDto),
        'revocabile': value.revocabile,
        'modificabile': value.modificabile,
        'sospendibile': value.sospendibile,
        'riattivabile': value.riattivabile,
    };
}
exports.RipetitivoDtoToJSON = RipetitivoDtoToJSON;
/**
* @export
* @enum {string}
*/
var RipetitivoDtoCanaleProvenienzaEnum;
(function (RipetitivoDtoCanaleProvenienzaEnum) {
    RipetitivoDtoCanaleProvenienzaEnum["A"] = "A";
    RipetitivoDtoCanaleProvenienzaEnum["C"] = "C";
    RipetitivoDtoCanaleProvenienzaEnum["F"] = "F";
    RipetitivoDtoCanaleProvenienzaEnum["H"] = "H";
    RipetitivoDtoCanaleProvenienzaEnum["I"] = "I";
    RipetitivoDtoCanaleProvenienzaEnum["P"] = "P";
    RipetitivoDtoCanaleProvenienzaEnum["R"] = "R";
    RipetitivoDtoCanaleProvenienzaEnum["S"] = "S";
    RipetitivoDtoCanaleProvenienzaEnum["T"] = "T";
})(RipetitivoDtoCanaleProvenienzaEnum = exports.RipetitivoDtoCanaleProvenienzaEnum || (exports.RipetitivoDtoCanaleProvenienzaEnum = {}));
/**
* @export
* @enum {string}
*/
var RipetitivoDtoFormaTecnicaAddebitoEnum;
(function (RipetitivoDtoFormaTecnicaAddebitoEnum) {
    RipetitivoDtoFormaTecnicaAddebitoEnum["F11"] = "F11";
    RipetitivoDtoFormaTecnicaAddebitoEnum["F13"] = "F13";
    RipetitivoDtoFormaTecnicaAddebitoEnum["F15"] = "F15";
    RipetitivoDtoFormaTecnicaAddebitoEnum["F16"] = "F16";
    RipetitivoDtoFormaTecnicaAddebitoEnum["F17"] = "F17";
})(RipetitivoDtoFormaTecnicaAddebitoEnum = exports.RipetitivoDtoFormaTecnicaAddebitoEnum || (exports.RipetitivoDtoFormaTecnicaAddebitoEnum = {}));
/**
* @export
* @enum {string}
*/
var RipetitivoDtoFormaTecnicaAccreditoEnum;
(function (RipetitivoDtoFormaTecnicaAccreditoEnum) {
    RipetitivoDtoFormaTecnicaAccreditoEnum["F11"] = "F11";
    RipetitivoDtoFormaTecnicaAccreditoEnum["F13"] = "F13";
    RipetitivoDtoFormaTecnicaAccreditoEnum["F14"] = "F14";
    RipetitivoDtoFormaTecnicaAccreditoEnum["F17"] = "F17";
})(RipetitivoDtoFormaTecnicaAccreditoEnum = exports.RipetitivoDtoFormaTecnicaAccreditoEnum || (exports.RipetitivoDtoFormaTecnicaAccreditoEnum = {}));
/**
* @export
* @enum {string}
*/
var RipetitivoDtoTipoMovimentoEnum;
(function (RipetitivoDtoTipoMovimentoEnum) {
    RipetitivoDtoTipoMovimentoEnum["S"] = "S";
    RipetitivoDtoTipoMovimentoEnum["I"] = "I";
})(RipetitivoDtoTipoMovimentoEnum = exports.RipetitivoDtoTipoMovimentoEnum || (exports.RipetitivoDtoTipoMovimentoEnum = {}));
/**
* @export
* @enum {string}
*/
var RipetitivoDtoTipoSupportoEnum;
(function (RipetitivoDtoTipoSupportoEnum) {
    RipetitivoDtoTipoSupportoEnum["E"] = "E";
    RipetitivoDtoTipoSupportoEnum["C"] = "C";
})(RipetitivoDtoTipoSupportoEnum = exports.RipetitivoDtoTipoSupportoEnum || (exports.RipetitivoDtoTipoSupportoEnum = {}));
/**
* @export
* @enum {string}
*/
var RipetitivoDtoNaturaMovimentoEnum;
(function (RipetitivoDtoNaturaMovimentoEnum) {
    RipetitivoDtoNaturaMovimentoEnum["BON"] = "BON";
    RipetitivoDtoNaturaMovimentoEnum["BIR"] = "BIR";
    RipetitivoDtoNaturaMovimentoEnum["SCT"] = "SCT";
    RipetitivoDtoNaturaMovimentoEnum["SCI"] = "SCI";
})(RipetitivoDtoNaturaMovimentoEnum = exports.RipetitivoDtoNaturaMovimentoEnum || (exports.RipetitivoDtoNaturaMovimentoEnum = {}));
/**
* @export
* @enum {string}
*/
var RipetitivoDtoStatoDisposizioneEnum;
(function (RipetitivoDtoStatoDisposizioneEnum) {
    RipetitivoDtoStatoDisposizioneEnum["AUTORIZZATO"] = "AUTORIZZATO";
    RipetitivoDtoStatoDisposizioneEnum["AUTORIZZATO_NON_REV"] = "AUTORIZZATO_NON_REV";
    RipetitivoDtoStatoDisposizioneEnum["NON_AUTORIZZATO"] = "NON_AUTORIZZATO";
    RipetitivoDtoStatoDisposizioneEnum["IN_CORSO"] = "IN_CORSO";
    RipetitivoDtoStatoDisposizioneEnum["INVIATO"] = "INVIATO";
    RipetitivoDtoStatoDisposizioneEnum["ESITO_INCERTO"] = "ESITO_INCERTO";
    RipetitivoDtoStatoDisposizioneEnum["REVOCATO"] = "REVOCATO";
    RipetitivoDtoStatoDisposizioneEnum["DA_AUTORIZZARE"] = "DA_AUTORIZZARE";
    RipetitivoDtoStatoDisposizioneEnum["BOZZA"] = "BOZZA";
    RipetitivoDtoStatoDisposizioneEnum["SOSPESO"] = "SOSPESO";
    RipetitivoDtoStatoDisposizioneEnum["STORNATO"] = "STORNATO";
    RipetitivoDtoStatoDisposizioneEnum["PAGATO"] = "PAGATO";
    RipetitivoDtoStatoDisposizioneEnum["ANNULLATO"] = "ANNULLATO";
})(RipetitivoDtoStatoDisposizioneEnum = exports.RipetitivoDtoStatoDisposizioneEnum || (exports.RipetitivoDtoStatoDisposizioneEnum = {}));
