import logo from "logoSparkasse.svg";
import logoResp from "Pictogram.svg";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  onClick?: () => void
};

const Logo = (props: Props) => {
  const { t } = useTranslation();

  const {
    onClick
  } = props;

  return (
    <button onClick={onClick} style={{ border: 'hidden', backgroundColor: 'unset' }}>

      <img
        src={logo}
        alt={t("accessibilita.logo")}
        className={`bank-logo big ${onClick ? '' : 'disabled'}`}
      />

      <img
        src={logoResp}
        alt={t("accessibilita.logo")}
        className={`bank-logo small ${onClick ? '' : 'disabled'}`}
      />

    </button>
  );
};

export default Logo;
