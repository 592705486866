"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthenticationResponseDtoToJSON = exports.AuthenticationResponseDtoFromJSONTyped = exports.AuthenticationResponseDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function AuthenticationResponseDtoFromJSON(json) {
    return AuthenticationResponseDtoFromJSONTyped(json, false);
}
exports.AuthenticationResponseDtoFromJSON = AuthenticationResponseDtoFromJSON;
function AuthenticationResponseDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'authComplete': !runtime_1.exists(json, 'authComplete') ? undefined : json['authComplete'],
        'consentRequired': !runtime_1.exists(json, 'consentRequired') ? undefined : json['consentRequired'],
        'mandatoryAction': !runtime_1.exists(json, 'mandatoryAction') ? undefined : _1.AuthenticationResponseMandatoryActionDtoFromJSON(json['mandatoryAction']),
        'passwordChange': !runtime_1.exists(json, 'passwordChange') ? undefined : json['passwordChange'],
        'resetToken': !runtime_1.exists(json, 'resetToken') ? undefined : json['resetToken'],
        'sessionId': !runtime_1.exists(json, 'sessionId') ? undefined : json['sessionId'],
        'sessionTimeout': !runtime_1.exists(json, 'sessionTimeout') ? undefined : (new Date(json['sessionTimeout'])),
        'strongAuthType': !runtime_1.exists(json, 'strongAuthType') ? undefined : _1.StrongAuthTypeEnumFromJSON(json['strongAuthType']),
    };
}
exports.AuthenticationResponseDtoFromJSONTyped = AuthenticationResponseDtoFromJSONTyped;
function AuthenticationResponseDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'authComplete': value.authComplete,
        'consentRequired': value.consentRequired,
        'mandatoryAction': _1.AuthenticationResponseMandatoryActionDtoToJSON(value.mandatoryAction),
        'passwordChange': value.passwordChange,
        'resetToken': value.resetToken,
        'sessionId': value.sessionId,
        'sessionTimeout': value.sessionTimeout === undefined ? undefined : (value.sessionTimeout.toISOString()),
        'strongAuthType': _1.StrongAuthTypeEnumToJSON(value.strongAuthType),
    };
}
exports.AuthenticationResponseDtoToJSON = AuthenticationResponseDtoToJSON;
