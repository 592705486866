"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PostLoginControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class PostLoginControllerApi extends runtime.BaseAPI {
    /**
     * Restituisce le informazioni di postlogin
     */
    postloginRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/postlogin`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     * Restituisce le informazioni di postlogin
     */
    postlogin() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.postloginRaw();
            return yield response.value();
        });
    }
    /**
     * Restituisce i comuni associati alla provincia.
     */
    recuperaComuniPerProvinciaRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.provincia === null || requestParameters.provincia === undefined) {
                throw new runtime.RequiredError('provincia', 'Required parameter requestParameters.provincia was null or undefined when calling recuperaComuniPerProvincia.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/postlogin/comuni/{provincia}`.replace(`{${"provincia"}}`, encodeURIComponent(String(requestParameters.provincia))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.ChiaveValoreDtoFromJSON));
        });
    }
    /**
     * Restituisce i comuni associati alla provincia.
     */
    recuperaComuniPerProvincia(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.recuperaComuniPerProvinciaRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce la lista di tipologie documenti di riconoscimenti.
     */
    recuperaListaTipoDocumentiRiconoscimentoRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/postlogin/documentiriconoscimento/tipologie`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.ChiaveValoreDtoFromJSON));
        });
    }
    /**
     * Restituisce la lista di tipologie documenti di riconoscimenti.
     */
    recuperaListaTipoDocumentiRiconoscimento() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.recuperaListaTipoDocumentiRiconoscimentoRaw();
            return yield response.value();
        });
    }
}
exports.PostLoginControllerApi = PostLoginControllerApi;
