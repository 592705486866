"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cBillReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const helpers_1 = require("../helpers");
const cBill_types_1 = require("./cBill.types");
const initialState = {
    form: (0, cBill_types_1.FormCBillDtoDtoFromJSONTyped)({}),
    infoAutenticazioneCBill: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
    riepilogo: (0, api_rest_1.FatturaDtoFromJSON)({}),
    bozzeCBill: [],
    currentBozza: (0, api_rest_1.FatturaDtoFromJSON)({}),
    parametri: (0, api_rest_1.ParametriDtoFromJSON)({}),
    esito: (0, api_rest_1.EsitoAutorizzazioneDisposizioneCBillDtoFromJSON)({}),
    codiciFiscali: [],
    billers: []
};
let rapportoSelezionato;
let infoVeicolo;
const cBillReducer = (state = initialState, action) => {
    var _a, _b;
    switch (action.type) {
        case __1.EReduxActionTypes.CBILL_GET_PARAMETRI_SUCCESS:
            return {
                ...initialState,
                parametri: action.payload
            };
        case __1.EReduxActionTypes.CBILL_GET_PARAMETRI_PER_RIPETI_SUCCESS:
            return {
                ...initialState,
                parametri: action.payload,
                form: {
                    ...state.form,
                    ripeti: true
                }
            };
        case __1.EReduxActionTypes.CBILL_CONTROLLO_SUCCESS:
            return {
                ...state,
                riepilogo: action.payload
            };
        case __1.EReduxActionTypes.CBILL_GET_CODICI_FISCALI_SUCCESS:
            return {
                ...state,
                codiciFiscali: action.payload
            };
        case __1.EReduxActionTypes.CBILL_GET_BILLERS_SUCCESS:
            return {
                ...state,
                billers: action.payload
            };
        case __1.EReduxActionTypes.CBILL_RESET_BILLERS:
            return {
                ...state,
                billers: []
            };
        case __1.EReduxActionTypes.CBILL_AUTORIZZA_SUCCESS:
            if (action.payload.stato === 'OK') {
                return {
                    ...state,
                    //riepilogo: initialState.riepilogo,
                    esito: action.payload
                };
            }
            else {
                return {
                    ...state,
                    infoAutenticazioneCBill: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoAutenticazioneCBill, action.payload.informazioniAutenticazioneDispositiva),
                    esito: action.payload
                };
            }
        case __1.EReduxActionTypes.CBILL_SET_RAPPORTO_SELEZIONATO:
            return {
                ...state,
                form: {
                    ...state.form,
                    rapportoSelezionato: action.payload,
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.CBILL_SET_CODICE_BILLER:
            return {
                ...state,
                form: {
                    ...state.form,
                    codiceBiller: action.payload,
                    codiceBillerDirty: true,
                    codiceBillerError: (0, helpers_1.validationField)(action.payload, '', false),
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.CBILL_DESCRIZIONE_BILLER:
            return {
                ...state,
                form: {
                    ...state.form,
                    descrizioneBiller: action.payload,
                    descrizioneBillerDirty: true,
                    descrizioneBillerError: (0, helpers_1.validationField)(action.payload, ''),
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.CBILL_NUMERO_FATTURA:
            return {
                ...state,
                form: {
                    ...state.form,
                    numeroFattura: action.payload,
                    numeroFatturaDirty: true,
                    numeroFatturaError: (0, helpers_1.validationAsNumber)(action.payload, true, 0, 18),
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.CBILL_SET_IMPORTO:
            return {
                ...state,
                form: {
                    ...state.form,
                    importo: action.payload,
                    importoDirty: true,
                    importoError: (0, helpers_1.validationField)(action.payload, 0),
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.CBILL_SET_FACILITATORE:
            return {
                ...state,
                form: {
                    ...state.form,
                    facilitatore: action.payload,
                    facilitatoreDirty: true,
                    facilitatoreError: (0, helpers_1.validationField)(action.payload, ''),
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.CBILL_SET_INTESTAZIONE_BILLER:
            return {
                ...state,
                form: {
                    ...state.form,
                    intestazioneBiller: action.payload,
                    intestazioneBillerDirty: true,
                    intestazioneBillerError: (0, helpers_1.validationField)(action.payload, ''),
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.CBILL_SET_NOMINATIVO_CLIENTE:
            return {
                ...state,
                form: {
                    ...state.form,
                    nominativoCliente: action.payload,
                    nominativoClienteDirty: true,
                    nominativoClienteError: (0, helpers_1.validationField)(action.payload, ''),
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.CBILL_SET_ALIAS_1CLICK:
            return {
                ...state,
                form: {
                    ...state.form,
                    alias1Click: action.payload,
                    alias1ClickDirty: true,
                    alias1ClickError: (0, helpers_1.validationCompleteCharSet)(action.payload, true),
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.CBILL_SET_SALVA_1CLICK:
            return {
                ...state,
                form: {
                    ...state.form,
                    salva1Click: action.payload,
                    alias1Click: action.payload ? `Bollo${state.form.targa ? ' ' + state.form.targa : ''}` : '',
                    alias1ClickDirty: false,
                    alias1ClickError: ''
                }
            };
        case __1.EReduxActionTypes.CBILL_SET_FROM_SUBMITTED:
            return {
                ...state,
                form: {
                    ...state.form,
                    formSubmitted: action.payload
                }
            };
        case __1.EReduxActionTypes.CBILL_SET_TIPO_VEICOLO:
            return {
                ...state,
                form: {
                    ...state.form,
                    tipoVeicolo: action.payload,
                    tipoVeicoloDirty: true,
                    tipoVeicoloError: (0, helpers_1.validationField)(action.payload, ''),
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.CBILL_SET_TARGA:
            return {
                ...state,
                form: {
                    ...state.form,
                    targa: action.payload,
                    targaDirty: true,
                    targaError: state.form.tipoVeicolo === '3' || state.form.tipoVeicolo === '7' ? (0, helpers_1.validationTelaio)(action.payload, true) : (0, helpers_1.validationTarga)(action.payload, true),
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.CBILL_SET_NOME:
            return {
                ...state,
                form: {
                    ...state.form,
                    nome: action.payload,
                    nomeDirty: true,
                    nomeError: (0, helpers_1.validationField)(action.payload, ''),
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.CBILL_SET_AFFIDABILE:
            return {
                ...state,
                form: {
                    ...state.form,
                    affidabile: action.payload
                }
            };
        case __1.EReduxActionTypes.CBILL_SET_AGIUNGI_RUBRICA:
            return {
                ...state,
                form: {
                    ...state.form,
                    aggiungiRubrica: action.payload,
                    nomeError: ''
                }
            };
        case __1.EReduxActionTypes.CBILL_SET_STEP:
            return {
                ...state,
                form: {
                    ...state.form,
                    step: action.payload
                }
            };
        case __1.EReduxActionTypes.CBILL_RESET:
            return {
                ...initialState
            };
        case __1.EReduxActionTypes.CBILL_SET_BOZZA_SALVATA:
            return {
                ...state,
                form: {
                    ...state.form,
                    bozzaSalvata: action.payload
                }
            };
        case __1.EReduxActionTypes.CBILL_GET_BOZZE_SUCCESS:
            return {
                ...state,
                bozzeCBill: action.payload
            };
        case __1.EReduxActionTypes.CBILL_GET_BOZZA_SUCCESS:
            rapportoSelezionato = (0, helpers_1.findInArrayRapporti)(state.parametri && state.parametri.rapporti, action.payload.contoAddebito);
            infoVeicolo = (0, helpers_1.getInformazioniVeicolo)(((_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.numeroFattura) || '');
            return {
                ...state,
                form: {
                    ...state.form,
                    codiceBiller: action.payload.codiceBiller || '',
                    codiceBillerDirty: false,
                    codiceBillerError: (0, helpers_1.validationField)(action.payload.codiceBiller, ''),
                    importo: action.payload.importo || 0,
                    importoDirty: false,
                    importoError: (0, helpers_1.validationField)(action.payload.importo, 0),
                    facilitatore: action.payload.facilitatore || '',
                    facilitatoreDirty: false,
                    facilitatoreError: (0, helpers_1.validationField)(action.payload.facilitatore, ''),
                    intestazioneBiller: action.payload.intestazioneBiller || '',
                    intestazioneBillerDirty: false,
                    intestazioneBillerError: (0, helpers_1.validationField)(action.payload.intestazioneBiller, ''),
                    nominativoCliente: action.payload.nominativoCliente || '',
                    nominativoClienteDirty: false,
                    nominativoClienteError: (0, helpers_1.validationField)(action.payload, ''),
                    alias1ClickError: '',
                    salva1Click: false,
                    tipoVeicolo: infoVeicolo.tipoVeicolo,
                    tipoVeicoloError: '',
                    targa: infoVeicolo.targaVeicolo,
                    targaError: '',
                    nomeError: '',
                    numeroFattura: action.payload.numeroFattura,
                    numeroFatturaDirty: false,
                    numeroFatturaError: (0, helpers_1.validationAsNumber)(action.payload.numeroFattura || '', true, 0, 18),
                    rapportoSelezionato: rapportoSelezionato,
                    bozzaSalvata: true
                },
                currentBozza: action.payload
            };
        case __1.EReduxActionTypes.CBILL_GET_CBILL_FROM_BOZZA:
            rapportoSelezionato = (0, helpers_1.findInArrayRapporti)(state.parametri && state.parametri.rapporti, action.payload.contoAddebito);
            infoVeicolo = (0, helpers_1.getInformazioniVeicolo)(((_b = action === null || action === void 0 ? void 0 : action.payload) === null || _b === void 0 ? void 0 : _b.numeroFattura) || '');
            return {
                ...state,
                form: {
                    ...state.form,
                    codiceBiller: action.payload.codiceBiller || '',
                    codiceBillerDirty: false,
                    codiceBillerError: (0, helpers_1.validationField)(action.payload.codiceBiller, ''),
                    importo: action.payload.importo || 0,
                    importoDirty: false,
                    importoError: (0, helpers_1.validationField)(action.payload.importo, 0),
                    facilitatore: action.payload.facilitatore || '',
                    facilitatoreDirty: false,
                    facilitatoreError: (0, helpers_1.validationField)(action.payload.facilitatore, ''),
                    intestazioneBiller: action.payload.intestazioneBiller || action.payload.descrizioneBiller || '',
                    intestazioneBillerDirty: false,
                    intestazioneBillerError: (0, helpers_1.validationField)(action.payload.intestazioneBiller || action.payload.descrizioneBiller, ''),
                    descrizioneBiller: action.payload.intestazioneBiller || action.payload.descrizioneBiller || '',
                    descrizioneBillerDirty: false,
                    descrizioneBillerError: (0, helpers_1.validationField)(action.payload.intestazioneBiller || action.payload.descrizioneBiller, ''),
                    nominativoCliente: action.payload.nominativoCliente || '',
                    nominativoClienteDirty: false,
                    nominativoClienteError: (0, helpers_1.validationField)(action.payload, ''),
                    alias1ClickError: '',
                    salva1Click: false,
                    tipoVeicolo: infoVeicolo.tipoVeicolo,
                    tipoVeicoloError: '',
                    targa: infoVeicolo.targaVeicolo,
                    targaError: '',
                    nomeError: '',
                    numeroFattura: action.payload.numeroFattura || action.payload.numeroBolletta,
                    numeroFatturaDirty: false,
                    numeroFatturaError: (0, helpers_1.validationAsNumber)(action.payload.numeroFattura || action.payload.numeroBolletta || '', true, 0, 18),
                    rapportoSelezionato: rapportoSelezionato,
                    bozzaSalvata: true
                },
                currentBozza: action.noBozza ? initialState.currentBozza : action.payload
            };
        case __1.EReduxActionTypes.CBILL_ADD_BOZZA_SUCCESS:
            return {
                ...state,
                bozzeCBill: state.bozzeCBill ? [...state.bozzeCBill, action.payload] : [action.payload],
                form: {
                    ...state.form,
                    bozzaSalvata: true
                },
                currentBozza: action.payload
            };
        case __1.EReduxActionTypes.CBILL_DEL_BOZZA_SUCCESS:
            return {
                ...state,
                bozzeCBill: state.bozzeCBill
                    ? state.bozzeCBill.filter((cbill) => cbill.id !== action.payload)
                    : [],
                form: {
                    ...state.form,
                    bozzaSalvata: state.currentBozza.id === action.payload ? false : state.form.bozzaSalvata
                },
                currentBozza: state.currentBozza.id === action.payload ? initialState.currentBozza : state.currentBozza
            };
        case __1.EReduxActionTypes.CBILL_UPDATE_BOZZA_SUCCESS:
            return {
                ...state,
                bozzeCBill: state.bozzeCBill
                    ? state.bozzeCBill.map((cbill) => cbill.id === action.payload.id ? action.payload : cbill)
                    : [],
                form: {
                    ...state.form,
                    bozzaSalvata: true
                }
            };
        case __1.EReduxActionTypes.CBILL_SET_SALVA_BOZZA_VALIDATION:
            return {
                ...state,
                form: {
                    ...state.form,
                    numeroFatturaError: (0, helpers_1.validationAsNumber)(state.form.numeroFattura || '', true, 0, 18),
                    importoError: (0, helpers_1.validationField)(state.form.importo, 0),
                    codiceBillerError: (0, helpers_1.validationField)(state.form.codiceBiller, ''),
                    targaError: state.form.tipoVeicolo === '3' || state.form.tipoVeicolo === '7' ? (0, helpers_1.validationTelaio)(state.form.targa, true) : (0, helpers_1.validationTarga)(state.form.targa, true),
                    tipoVeicoloError: (0, helpers_1.validationField)(state.form.tipoVeicolo, '')
                }
            };
        case __1.EReduxActionTypes.CBILL_SET_CONTATTO_SELEZIONATO:
            return {
                ...state,
                form: {
                    ...state.form,
                    contattoSelezionato: action.payload
                }
            };
        case __1.EReduxActionTypes.CBILL_SET_SELECTED_BILLER:
            return {
                ...state,
                form: {
                    ...state.form,
                    isBillerSelected: action.payload,
                    codiceBillerError: action.payload ? '' : 'invalid.required'
                }
            };
        case __1.EReduxActionTypes.CBILL_GET_INFO_AUTENTICAZIONE_SUCCESS: {
            return {
                ...state,
                infoAutenticazioneCBill: action.payload,
                esito: initialState.esito
            };
        }
        case __1.EReduxActionTypes.RESET_ALL: // action invocata dalla logout
            return {
                ...initialState
            };
        default:
            return state;
    }
};
exports.cBillReducer = cBillReducer;
exports.default = exports.cBillReducer;
