"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PNS Client API
 * PNS - Push Notification Service: Api per l\'invio di notifiche
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PNSPlatformRegistrationDeviceOsEnum = exports.PNSPlatformRegistrationToJSON = exports.PNSPlatformRegistrationFromJSONTyped = exports.PNSPlatformRegistrationFromJSON = void 0;
const runtime_1 = require("../runtime");
function PNSPlatformRegistrationFromJSON(json) {
    return PNSPlatformRegistrationFromJSONTyped(json, false);
}
exports.PNSPlatformRegistrationFromJSON = PNSPlatformRegistrationFromJSON;
function PNSPlatformRegistrationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'registrationId': json['registrationId'],
        'deviceOs': !runtime_1.exists(json, 'deviceOs') ? undefined : json['deviceOs'],
    };
}
exports.PNSPlatformRegistrationFromJSONTyped = PNSPlatformRegistrationFromJSONTyped;
function PNSPlatformRegistrationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'registrationId': value.registrationId,
        'deviceOs': value.deviceOs,
    };
}
exports.PNSPlatformRegistrationToJSON = PNSPlatformRegistrationToJSON;
/**
* @export
* @enum {string}
*/
var PNSPlatformRegistrationDeviceOsEnum;
(function (PNSPlatformRegistrationDeviceOsEnum) {
    PNSPlatformRegistrationDeviceOsEnum["Android"] = "android";
    PNSPlatformRegistrationDeviceOsEnum["Ios"] = "ios";
    PNSPlatformRegistrationDeviceOsEnum["Web"] = "web";
})(PNSPlatformRegistrationDeviceOsEnum = exports.PNSPlatformRegistrationDeviceOsEnum || (exports.PNSPlatformRegistrationDeviceOsEnum = {}));
