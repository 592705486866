"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EsitoAutorizzazioneDisposizioneRicaricaCellulareDtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneRicaricaCellulareDtoToJSON = exports.EsitoAutorizzazioneDisposizioneRicaricaCellulareDtoFromJSONTyped = exports.EsitoAutorizzazioneDisposizioneRicaricaCellulareDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function EsitoAutorizzazioneDisposizioneRicaricaCellulareDtoFromJSON(json) {
    return EsitoAutorizzazioneDisposizioneRicaricaCellulareDtoFromJSONTyped(json, false);
}
exports.EsitoAutorizzazioneDisposizioneRicaricaCellulareDtoFromJSON = EsitoAutorizzazioneDisposizioneRicaricaCellulareDtoFromJSON;
function EsitoAutorizzazioneDisposizioneRicaricaCellulareDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
        'codiceErrore': !runtime_1.exists(json, 'codiceErrore') ? undefined : json['codiceErrore'],
        'informazioniAutenticazioneDispositiva': !runtime_1.exists(json, 'informazioniAutenticazioneDispositiva') ? undefined : _1.InformazioniAutenticazioneDispositivaDtoFromJSON(json['informazioniAutenticazioneDispositiva']),
        'disposizione': !runtime_1.exists(json, 'disposizione') ? undefined : _1.RicaricaCellulareDtoFromJSON(json['disposizione']),
    };
}
exports.EsitoAutorizzazioneDisposizioneRicaricaCellulareDtoFromJSONTyped = EsitoAutorizzazioneDisposizioneRicaricaCellulareDtoFromJSONTyped;
function EsitoAutorizzazioneDisposizioneRicaricaCellulareDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'stato': value.stato,
        'codiceErrore': value.codiceErrore,
        'informazioniAutenticazioneDispositiva': _1.InformazioniAutenticazioneDispositivaDtoToJSON(value.informazioniAutenticazioneDispositiva),
        'disposizione': _1.RicaricaCellulareDtoToJSON(value.disposizione),
    };
}
exports.EsitoAutorizzazioneDisposizioneRicaricaCellulareDtoToJSON = EsitoAutorizzazioneDisposizioneRicaricaCellulareDtoToJSON;
/**
* @export
* @enum {string}
*/
var EsitoAutorizzazioneDisposizioneRicaricaCellulareDtoStatoEnum;
(function (EsitoAutorizzazioneDisposizioneRicaricaCellulareDtoStatoEnum) {
    EsitoAutorizzazioneDisposizioneRicaricaCellulareDtoStatoEnum["OK"] = "OK";
    EsitoAutorizzazioneDisposizioneRicaricaCellulareDtoStatoEnum["CREDENZIALE_ERRATA"] = "CREDENZIALE_ERRATA";
    EsitoAutorizzazioneDisposizioneRicaricaCellulareDtoStatoEnum["ATTENDERE"] = "ATTENDERE";
    EsitoAutorizzazioneDisposizioneRicaricaCellulareDtoStatoEnum["ACCOUNT_BLOCCATO"] = "ACCOUNT_BLOCCATO";
    EsitoAutorizzazioneDisposizioneRicaricaCellulareDtoStatoEnum["ACCP"] = "ACCP";
    EsitoAutorizzazioneDisposizioneRicaricaCellulareDtoStatoEnum["ACSC"] = "ACSC";
    EsitoAutorizzazioneDisposizioneRicaricaCellulareDtoStatoEnum["ACSP"] = "ACSP";
    EsitoAutorizzazioneDisposizioneRicaricaCellulareDtoStatoEnum["ACTC"] = "ACTC";
    EsitoAutorizzazioneDisposizioneRicaricaCellulareDtoStatoEnum["ACWC"] = "ACWC";
    EsitoAutorizzazioneDisposizioneRicaricaCellulareDtoStatoEnum["ACWP"] = "ACWP";
    EsitoAutorizzazioneDisposizioneRicaricaCellulareDtoStatoEnum["RCVD"] = "RCVD";
    EsitoAutorizzazioneDisposizioneRicaricaCellulareDtoStatoEnum["PDNG"] = "PDNG";
    EsitoAutorizzazioneDisposizioneRicaricaCellulareDtoStatoEnum["RJCT"] = "RJCT";
    EsitoAutorizzazioneDisposizioneRicaricaCellulareDtoStatoEnum["CANC"] = "CANC";
})(EsitoAutorizzazioneDisposizioneRicaricaCellulareDtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneRicaricaCellulareDtoStatoEnum || (exports.EsitoAutorizzazioneDisposizioneRicaricaCellulareDtoStatoEnum = {}));
