import { ContattoDto } from "@sparkasse/commons";
import React, { useEffect, useState } from "react";
import RubricaCard from "./rubrica.card";

interface iProps {
  items: ContattoDto[]
  apriDettaglio: Function
  onElimina?: Function
  onModifica?: Function
}

const Contacts: React.FC<iProps> = (props: iProps) => {

  const {
    items,
    apriDettaglio,
    onElimina,
    onModifica
  } = props;

  const [orderedItems, setOrderedItems] = useState<Array<{ title: string, data: ContattoDto[] }>>([]);

  const onModificaHandler = (item: ContattoDto) => {
    item && onModifica && onModifica(item)
  };

  const onEliminaHandler = (item: ContattoDto) => {
    if (item.id && onElimina) {
      onElimina(item)
    }
  };

  useEffect(() => {
    setOrderedItems(sortContacts(items));
  }, [items]);

  const sortContacts = (items: ContattoDto[]): Array<{ title: string, data: ContattoDto[] }> => {
    const sortedItems: any = items.reduce((r, a) => {
      if (a.alias) {
        const prop = a.alias[0].toUpperCase();
        r[prop] = r[prop] || [];
        r[prop].push(a);
        return r;
      }
    }, Object.create(null));
    const sortedArray = [...Object.entries(sortedItems)].sort();
    const newArray: Array<{ title: string, data: ContattoDto[] }> = [];
    for (let i = 0; i < sortedArray.length; i++) {
      newArray.push({
        title: sortedArray[i][0],
        data: sortedArray[i][1] as ContattoDto[]
      });
    }
    return newArray;
  };

  return (
    <div>

      {!!orderedItems.length && orderedItems.map((item, i) => {
        return (
          <div key={`contatto-${i}`}>
            <div className="contact-divider"> <span>{item.title}</span> </div>
            {item.data.map((el: ContattoDto, ind: number) => {
              return (
                <RubricaCard
                  item={el}
                  key={`contact-${ind}-${el.id}`}
                  id={ind}
                  apriDettaglio={() => apriDettaglio(el)}
                  email={el.email}
                  descrizione={`${el.alias}`}
                  ibans={el.iban}
                  targhe={el.mezziTrasporto}
                  altoAdigePass={el.sudtirolPass}
                  dispensaCredenziale={el.dispensaCredenziale}
                  cf={{ cf: el.cf, dispensaCredenziale: !!el.dispensaF24 }}
                  piva={{ piva: el.piva, dispensaCredenziale: false }}
                  telefono={el.telefono}
                  ctaVerticali
                  callbackElimina={onEliminaHandler}
                  callbackModifica={onModificaHandler}
                />
              )
            })}
          </div>
        );
      })}

    </div>
  );
};

export default Contacts;
