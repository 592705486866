"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WidgetControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class WidgetControllerApi extends runtime.BaseAPI {
    /**
     * Parametri e widgets
     */
    parametriWidgetRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/widgets`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.WidgetWrapDtoFromJSON(jsonValue));
        });
    }
    /**
     * Parametri e widgets
     */
    parametriWidget() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.parametriWidgetRaw();
            return yield response.value();
        });
    }
    /**
     * Restituisce i widgets dell\'utente
     */
    salvaWidgetsRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.widgetsDto === null || requestParameters.widgetsDto === undefined) {
                throw new runtime.RequiredError('widgetsDto', 'Required parameter requestParameters.widgetsDto was null or undefined when calling salvaWidgets.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/widgets/updateWidgets`,
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.WidgetsDtoToJSON(requestParameters.widgetsDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.WidgetsDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce i widgets dell\'utente
     */
    salvaWidgets(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.salvaWidgetsRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce i widgets dell\'utente
     */
    widgetsUtenteRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/widgets/utente`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.WidgetsDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce i widgets dell\'utente
     */
    widgetsUtente() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.widgetsUtenteRaw();
            return yield response.value();
        });
    }
}
exports.WidgetControllerApi = WidgetControllerApi;
