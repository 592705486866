"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.esitoRevocaBonificoVersamentoDeposito = exports.infoRevocaBonificoVersamentoDeposito = exports.formRevocaBonificoVersamentoDeposito = exports.riepilogoRevocaBonificoVersamentoDeposito = exports.esitoBonificoVersamentoDeposito = exports.infoDispositivaBonificoVersamentoDeposito = exports.riepilogoBonificoVersamentoDeposito = exports.formBonificoVersamentoDepositoValid = exports.buildBonificoVersamentoDeposito = exports.formBonificoVersamentoDeposito = exports.parametriBonificoVersamentoDeposito = void 0;
const moment = require("moment");
const reselect_1 = require("reselect");
const helpers_1 = require("../helpers");
const bonificoVersamentoDepositoReducer = (state) => state;
exports.parametriBonificoVersamentoDeposito = (0, reselect_1.createSelector)(bonificoVersamentoDepositoReducer, (state) => {
    return state.parametri;
});
exports.formBonificoVersamentoDeposito = (0, reselect_1.createSelector)(bonificoVersamentoDepositoReducer, (state) => {
    return state.form;
});
exports.buildBonificoVersamentoDeposito = (0, reselect_1.createSelector)(bonificoVersamentoDepositoReducer, (state) => {
    var _a, _b, _c, _d, _e, _f;
    return {
        causale: state.form.causale,
        contoAddebito: ((_b = (_a = state.form.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.id) || 0,
        contoAddebitoIban: ((_d = (_c = state.form.rapportoSelezionato) === null || _c === void 0 ? void 0 : _c.rapporto) === null || _d === void 0 ? void 0 : _d.iban) || '',
        dataEsecuzione: moment(state.form.dataEsecuzione).toDate(),
        ibanBeneficiario: ((_e = state.form.contoAccredito) === null || _e === void 0 ? void 0 : _e.iban) || '',
        importo: (0, helpers_1.formattedNumberToNumber)(state.form.importo),
        motivazionePagamento: 'GIRO',
        naturaMovimento: 'SCT',
        nomeBeneficiario: ((_f = state.form.contoAccredito) === null || _f === void 0 ? void 0 : _f.intestazione) || '',
        datiEsecuzioneBonifico: {
            importoCommissioni: 0,
            importoTotale: 0,
            dataAddebito: new Date(),
            dataEsecuzioneAddebito: new Date(),
            dataValuta: new Date(),
            dataRegolamento: new Date(),
            denominazioneBanca: '',
            denominazioneSportello: '',
            revocabile: false,
            riferimentoOperazione: '',
            urgente: false
        }
    };
});
exports.formBonificoVersamentoDepositoValid = (0, reselect_1.createSelector)(bonificoVersamentoDepositoReducer, (state) => {
    let formValid = false;
    if (!state.form.contoAccreditoError &&
        !state.form.importoError &&
        !state.form.dataEsecuzioneError &&
        !state.form.causaleError) {
        formValid = true;
    }
    return formValid;
});
exports.riepilogoBonificoVersamentoDeposito = (0, reselect_1.createSelector)(bonificoVersamentoDepositoReducer, (state) => {
    return state.riepilogo;
});
exports.infoDispositivaBonificoVersamentoDeposito = (0, reselect_1.createSelector)(bonificoVersamentoDepositoReducer, (state) => {
    return state.infoDispositiva;
});
exports.esitoBonificoVersamentoDeposito = (0, reselect_1.createSelector)(bonificoVersamentoDepositoReducer, (state) => {
    return state.esito;
});
exports.riepilogoRevocaBonificoVersamentoDeposito = (0, reselect_1.createSelector)(bonificoVersamentoDepositoReducer, (state) => {
    return state.riepilogoRevoca;
});
exports.formRevocaBonificoVersamentoDeposito = (0, reselect_1.createSelector)(bonificoVersamentoDepositoReducer, (state) => {
    return state.formRevoca;
});
exports.infoRevocaBonificoVersamentoDeposito = (0, reselect_1.createSelector)(bonificoVersamentoDepositoReducer, (state) => {
    return state.infoRevoca;
});
exports.esitoRevocaBonificoVersamentoDeposito = (0, reselect_1.createSelector)(bonificoVersamentoDepositoReducer, (state) => {
    return state.esitoRevoca;
});
