"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PosizioneTitoliDtoToJSON = exports.PosizioneTitoliDtoFromJSONTyped = exports.PosizioneTitoliDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function PosizioneTitoliDtoFromJSON(json) {
    return PosizioneTitoliDtoFromJSONTyped(json, false);
}
exports.PosizioneTitoliDtoFromJSON = PosizioneTitoliDtoFromJSON;
function PosizioneTitoliDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'denominazione': !runtime_1.exists(json, 'denominazione') ? undefined : json['denominazione'],
        'ndg': !runtime_1.exists(json, 'ndg') ? undefined : json['ndg'],
        'minusResidue': !runtime_1.exists(json, 'minusResidue') ? undefined : json['minusResidue'],
        'lastUpdate': !runtime_1.exists(json, 'lastUpdate') ? undefined : (new Date(json['lastUpdate'])),
        'posizioniAnnoList': !runtime_1.exists(json, 'posizioniAnnoList') ? undefined : (json['posizioniAnnoList'].map(_1.PosizioneTitoliPerAnnoDtoFromJSON)),
    };
}
exports.PosizioneTitoliDtoFromJSONTyped = PosizioneTitoliDtoFromJSONTyped;
function PosizioneTitoliDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'denominazione': value.denominazione,
        'ndg': value.ndg,
        'minusResidue': value.minusResidue,
        'lastUpdate': value.lastUpdate === undefined ? undefined : (value.lastUpdate.toISOString().substr(0, 10)),
        'posizioniAnnoList': value.posizioniAnnoList === undefined ? undefined : (value.posizioniAnnoList.map(_1.PosizioneTitoliPerAnnoDtoToJSON)),
    };
}
exports.PosizioneTitoliDtoToJSON = PosizioneTitoliDtoToJSON;
