"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterFilialeVirtualeFromJSONTyped = exports.FormTypeFilialeVirtualeFromJSONTyped = void 0;
const helpers_1 = require("../helpers");
function FormTypeFilialeVirtualeFromJSONTyped(json) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        tipo: !(0, helpers_1.exists)(json, 'tipo') ? '' : json.tipo,
        step: !(0, helpers_1.exists)(json, 'step') ? 0 : json.step,
        formDirty: !(0, helpers_1.exists)(json, 'filterDirty') ? false : json.filterDirty,
        formSubmitted: !(0, helpers_1.exists)(json, 'formSubmitted') ? false : json.formSubmitted
    };
}
exports.FormTypeFilialeVirtualeFromJSONTyped = FormTypeFilialeVirtualeFromJSONTyped;
function FilterFilialeVirtualeFromJSONTyped(json) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'descrizione': !(0, helpers_1.exists)(json, 'descrizione') ? undefined : json.descrizione,
        'dataRichiestaDa': !(0, helpers_1.exists)(json, 'dataRichiestaDa') ? undefined : json.dataRichiestaDa,
        'dataRichiestaA': !(0, helpers_1.exists)(json, 'dataRichiestaA') ? undefined : json.dataRichiestaA,
        'filterDirty': !(0, helpers_1.exists)(json, 'filterDirty') ? false : json.filterDirty
    };
}
exports.FilterFilialeVirtualeFromJSONTyped = FilterFilialeVirtualeFromJSONTyped;
