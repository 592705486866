"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CorrispondenzaRequestInToJSON = exports.CorrispondenzaRequestInFromJSONTyped = exports.CorrispondenzaRequestInFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function CorrispondenzaRequestInFromJSON(json) {
    return CorrispondenzaRequestInFromJSONTyped(json, false);
}
exports.CorrispondenzaRequestInFromJSON = CorrispondenzaRequestInFromJSON;
function CorrispondenzaRequestInFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'credenziale': !runtime_1.exists(json, 'credenziale') ? undefined : _1.CredenzialeDispositivaDtoFromJSON(json['credenziale']),
        'corrispondenze': !runtime_1.exists(json, 'corrispondenze') ? undefined : (json['corrispondenze'].map(_1.CorrispondenzaDtoFromJSON)),
    };
}
exports.CorrispondenzaRequestInFromJSONTyped = CorrispondenzaRequestInFromJSONTyped;
function CorrispondenzaRequestInToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'credenziale': _1.CredenzialeDispositivaDtoToJSON(value.credenziale),
        'corrispondenze': value.corrispondenze === undefined ? undefined : (value.corrispondenze.map(_1.CorrispondenzaDtoToJSON)),
    };
}
exports.CorrispondenzaRequestInToJSON = CorrispondenzaRequestInToJSON;
