"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServizioDtoToJSON = exports.ServizioDtoFromJSONTyped = exports.ServizioDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ServizioDtoFromJSON(json) {
    return ServizioDtoFromJSONTyped(json, false);
}
exports.ServizioDtoFromJSON = ServizioDtoFromJSON;
function ServizioDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codiceArea': !runtime_1.exists(json, 'codiceArea') ? undefined : json['codiceArea'],
        'funzioneSmsAlert': !runtime_1.exists(json, 'funzioneSmsAlert') ? undefined : json['funzioneSmsAlert'],
        'funzioneIhb': !runtime_1.exists(json, 'funzioneIhb') ? undefined : json['funzioneIhb'],
        'sms': !runtime_1.exists(json, 'sms') ? undefined : _1.CanaleDtoFromJSON(json['sms']),
        'email': !runtime_1.exists(json, 'email') ? undefined : _1.CanaleDtoFromJSON(json['email']),
    };
}
exports.ServizioDtoFromJSONTyped = ServizioDtoFromJSONTyped;
function ServizioDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codiceArea': value.codiceArea,
        'funzioneSmsAlert': value.funzioneSmsAlert,
        'funzioneIhb': value.funzioneIhb,
        'sms': _1.CanaleDtoToJSON(value.sms),
        'email': _1.CanaleDtoToJSON(value.email),
    };
}
exports.ServizioDtoToJSON = ServizioDtoToJSON;
