"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetPatrimonio = exports.riepilogoPatrimonioPdf = exports.getRiepilogoPatrimonio = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
function getRiepilogoPatrimonioStart() {
    return {
        type: __1.EReduxActionTypes.PATRIMONIO_GET_RIEPILOGO_START
    };
}
function getRiepilogoPatrimonioSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.PATRIMONIO_GET_RIEPILOGO_SUCCESS,
        payload
    };
}
function generalError(value) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value));
    };
}
const getRiepilogoPatrimonio = (loaderEnabled = true) => async (dispatch, _, extraArguments) => {
    loaderEnabled && dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getRiepilogoPatrimonioStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/riepilogo_patrimonio.json')).then(response => {
            dispatch(getRiepilogoPatrimonioSuccess((0, api_rest_1.RiepilogoPatrimonioDtoFromJSON)(response)));
            loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.PatrimonioControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getRiepilogoPatrimonio().then((response) => {
                dispatch(getRiepilogoPatrimonioSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getRiepilogoPatrimonio = getRiepilogoPatrimonio;
function riepilogoPatrimonioPdfStart() {
    return {
        type: __1.EReduxActionTypes.PATRIMONIO_RIEPILOGO_PDF_START
    };
}
function riepilogoPatrimonioPdfSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.PATRIMONIO_RIEPILOGO_PDF_SUCCESS,
        payload
    };
}
const riepilogoPatrimonioPdf = (riepilogoPatrimonioDto, share, title, message) => async (dispatch, getState, extraArguments) => {
    dispatch(riepilogoPatrimonioPdfStart());
    dispatch((0, general_actions_1.setLoader)(true));
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        const riepilogoPatrimonioPdfRequest = {
            riepilogoPatrimonioDto: riepilogoPatrimonioDto
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.PatrimonioControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf')).riepilogoPatrimonioPdf(riepilogoPatrimonioPdfRequest).then((response) => {
                if (extraArguments.platform === 'desktop') {
                    (0, helpers_1.hadlePdfResponse)(response, (url) => dispatch((0, general_actions_1.popUpBlockedPdf)(url)));
                }
                if (extraArguments.platform === 'mobile') {
                    (0, helpers_1.download_sharePDF)(extraArguments, 'patrimonio.pdf', response, share, title, message);
                }
                dispatch(riepilogoPatrimonioPdfSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.riepilogoPatrimonioPdf = riepilogoPatrimonioPdf;
function resetPatrimonio() {
    return {
        type: __1.EReduxActionTypes.PATRIMONIO_RESET
    };
}
exports.resetPatrimonio = resetPatrimonio;
