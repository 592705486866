"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formNeosurance = exports.polizzeNeosurance = void 0;
const reselect_1 = require("reselect");
const neosuranceReducer = (state) => state;
exports.polizzeNeosurance = (0, reselect_1.createSelector)(neosuranceReducer, (state) => {
    return state.polizzeNeosurance;
});
exports.formNeosurance = (0, reselect_1.createSelector)(neosuranceReducer, (state) => {
    return state.form;
});
