"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ricercaFilialeAtmReducer = void 0;
const __1 = require("..");
const initialState = {
    listaFilialiAtm: undefined,
    searchResults: undefined
};
const ricercaFilialeAtmReducer = (state = initialState, action) => {
    switch (action.type) {
        case __1.EReduxActionTypes.RICERCA_FILIALE_ATM_GET_LISTA_SUCCESS:
            return {
                ...state,
                listaFilialiAtm: action.payload
            };
        case __1.EReduxActionTypes.RICERCA_FILIALE_ATM_GET_LISTA_BY_RADIUS_SUCCESS:
            return {
                ...state,
                listaFilialiAtm: action.payload
            };
        case __1.EReduxActionTypes.RICERCA_FILIALE_GET_TEXT_SEARCH_START:
            return {
                ...state,
                searchResults: initialState.searchResults
            };
        case __1.EReduxActionTypes.RICERCA_FILIALE_GET_TEXT_SEARCH_SUCCESS:
            return {
                ...state,
                searchResults: action.payload
            };
        default:
            return state;
    }
};
exports.ricercaFilialeAtmReducer = ricercaFilialeAtmReducer;
exports.default = exports.ricercaFilialeAtmReducer;
