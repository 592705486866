"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EReduxLoginActionTypes = exports.FormCambioPasswordObbligatorioDtoFromJSON = exports.FormAzioniSmartDtoFromJSON = exports.FormEnrollmentDtoFromJSON = exports.FormLoginDtoFromJSON = void 0;
const helpers_1 = require("../helpers");
function FormLoginDtoFromJSON(json) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'username': !(0, helpers_1.exists)(json, 'username') ? '' : json.username,
        'usernameDirty': !(0, helpers_1.exists)(json, 'usernameDirty') ? false : json.usernameDirty,
        'usernameError': !(0, helpers_1.exists)(json, 'usernameError') ? 'invalid.required' : json.usernameError,
        'password': !(0, helpers_1.exists)(json, 'password') ? '' : json.password,
        'passwordDirty': !(0, helpers_1.exists)(json, 'passwordDirty') ? false : json.passwordDirty,
        'passwordError': !(0, helpers_1.exists)(json, 'passwordError') ? 'invalid.required' : json.passwordError,
        'step': !(0, helpers_1.exists)(json, 'step') ? 1 : json.step,
        'websocket': !(0, helpers_1.exists)(json, 'websocket') ? undefined : json.websocket,
        'websocketClosed': !(0, helpers_1.exists)(json, 'websocketClosed') ? false : json.websocketClosed,
        'pin': !(0, helpers_1.exists)(json, 'pin') ? '' : json.pin,
        'pinDirty': !(0, helpers_1.exists)(json, 'pinDirty') ? false : json.pinDirty,
        'pinError': !(0, helpers_1.exists)(json, 'pinError') ? 'invalid.required' : json.pinError,
        'visualizzaQR': !(0, helpers_1.exists)(json, 'visualizzaQR') ? false : json.visualizzaQR,
        'otp': !(0, helpers_1.exists)(json, 'otp') ? '' : json.otp,
        'otpDirty': !(0, helpers_1.exists)(json, 'otpDirty') ? false : json.otpDirty,
        'otpError': !(0, helpers_1.exists)(json, 'otpError') ? 'invalid.required' : json.otpError,
        'otpHardware': !(0, helpers_1.exists)(json, 'otpHardware') ? '' : json.otpHardware,
        'otpHardwareDirty': !(0, helpers_1.exists)(json, 'otpHardwareDirty') ? false : json.otpHardwareDirty,
        'otpHardwareError': !(0, helpers_1.exists)(json, 'otpHardwareError') ? 'invalid.required' : json.otpHardwareError,
        'nascondiDatiSensibili': !(0, helpers_1.exists)(json, 'nascondiDatiSensibili') ? false : json.nascondiDatiSensibili,
        'error': !(0, helpers_1.exists)(json, 'error') ? false : json.error
    };
}
exports.FormLoginDtoFromJSON = FormLoginDtoFromJSON;
function FormEnrollmentDtoFromJSON(json) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'postEnrollmentSuccess': !(0, helpers_1.exists)(json, 'postEnrollmentSuccess') ? false : json.postEnrollmentSuccess,
        'pin': !(0, helpers_1.exists)(json, 'pin') ? '' : json.pin,
        'pinDirty': !(0, helpers_1.exists)(json, 'pinDirty') ? false : json.pinDirty,
        'pinError': !(0, helpers_1.exists)(json, 'pinError') ? 'invalid.required' : json.pinError,
        'ripetiPin': !(0, helpers_1.exists)(json, 'ripetiPin') ? '' : json.ripetiPin,
        'ripetiPinDirty': !(0, helpers_1.exists)(json, 'ripetiPinDirty') ? false : json.ripetiPinDirty,
        'ripetiPinError': !(0, helpers_1.exists)(json, 'ripetiPinError') ? 'invalid.required' : json.ripetiPinError,
        'sms': !(0, helpers_1.exists)(json, 'sms') ? '' : json.sms,
        'smsDirty': !(0, helpers_1.exists)(json, 'smsDirty') ? false : json.smsDirty,
        'smsError': !(0, helpers_1.exists)(json, 'smsError') ? 'invalid.required' : json.smsError
    };
}
exports.FormEnrollmentDtoFromJSON = FormEnrollmentDtoFromJSON;
function FormAzioniSmartDtoFromJSON(json) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'pin': !(0, helpers_1.exists)(json, 'pin') ? '' : json.pin,
        'pinDirty': !(0, helpers_1.exists)(json, 'pinDirty') ? false : json.pinDirty,
        'pinError': !(0, helpers_1.exists)(json, 'pinError') ? 'invalid.required' : json.pinError,
        'step': !(0, helpers_1.exists)(json, 'step') ? 0 : json.step
    };
}
exports.FormAzioniSmartDtoFromJSON = FormAzioniSmartDtoFromJSON;
function FormCambioPasswordObbligatorioDtoFromJSON(json) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nuovaPassword': !(0, helpers_1.exists)(json, 'nuovaPassword') ? '' : json.nuovaPassword,
        'nuovaPasswordDirty': !(0, helpers_1.exists)(json, 'nuovaPasswordDirty') ? false : json.nuovaPasswordDirty,
        'nuovaPasswordError': !(0, helpers_1.exists)(json, 'nuovaPasswordError') ? 'invalid.required' : json.nuovaPasswordError,
        'ripetiNuovaPassword': !(0, helpers_1.exists)(json, 'ripetiNuovaPassword') ? '' : json.ripetiNuovaPassword,
        'ripetiNuovaPasswordDirty': !(0, helpers_1.exists)(json, 'ripetiNuovaPasswordDirty') ? false : json.ripetiNuovaPasswordDirty,
        'ripetiNuovaPasswordError': !(0, helpers_1.exists)(json, 'ripetiNuovaPasswordError') ? 'invalid.required' : json.ripetiNuovaPasswordError,
        'vecchiaPassword': !(0, helpers_1.exists)(json, 'vecchiaPassword') ? '' : json.vecchiaPassword,
        'vecchiaPasswordDirty': !(0, helpers_1.exists)(json, 'vecchiaPasswordDirty') ? false : json.vecchiaPasswordDirty,
        'vecchiaPasswordError': !(0, helpers_1.exists)(json, 'vecchiaPasswordError') ? 'invalid.required' : json.vecchiaPasswordError
    };
}
exports.FormCambioPasswordObbligatorioDtoFromJSON = FormCambioPasswordObbligatorioDtoFromJSON;
var EReduxLoginActionTypes;
(function (EReduxLoginActionTypes) {
    EReduxLoginActionTypes["LOGIN_TOTAL_RESET"] = "LOGIN_TOTAL_RESET";
    EReduxLoginActionTypes["LOGIN_SET_USERNAME"] = "LOGIN_SET_USERNAME";
    EReduxLoginActionTypes["LOGIN_SET_PASSWORD"] = "LOGIN_SET_PASSWORD";
    EReduxLoginActionTypes["LOGIN_SET_NASCONDI_DATI_SENSIBILI"] = "LOGIN_SET_NASCONDI_DATI_SENSIBILI";
    EReduxLoginActionTypes["LOGIN_SET_STEP"] = "LOGIN_SET_STEP";
    EReduxLoginActionTypes["LOGIN_SET_WEBSOCKET_CLOSED"] = "LOGIN_SET_WEBSOCKET_CLOSED";
    EReduxLoginActionTypes["LOGIN_WEBSOCKET_OPENED"] = "LOGIN_WEBSOCKET_OPENED";
    EReduxLoginActionTypes["LOGIN_CLOSE_WEBSOCKET"] = "LOGIN_CLOSE_WEBSOCKET";
    EReduxLoginActionTypes["LOGIN_GET_AUTHENTICATION_CONTEXT_START"] = "LOGIN_GET_AUTHENTICATION_CONTEXT_START";
    EReduxLoginActionTypes["LOGIN_GET_AUTHENTICATION_CONTEXT_SUCCESS"] = "LOGIN_GET_AUTHENTICATION_CONTEXT_SUCCESS";
    EReduxLoginActionTypes["LOGIN_GET_AUTHENTICATION_CONTEXT_ERROR"] = "LOGIN_GET_AUTHENTICATION_CONTEXT_ERROR";
    EReduxLoginActionTypes["LOGIN_AUTHENTICATION_START_START"] = "LOGIN_AUTHENTICATION_START_START";
    EReduxLoginActionTypes["LOGIN_AUTHENTICATION_START_SUCCESS"] = "LOGIN_AUTHENTICATION_START_SUCCESS";
    EReduxLoginActionTypes["LOGIN_AUTHENTICATION_START_ERROR"] = "LOGIN_AUTHENTICATION_START_ERROR";
    EReduxLoginActionTypes["LOGIN_STRONG_AUTH_INIT_START"] = "LOGIN_STRONG_AUTH_INIT_START";
    EReduxLoginActionTypes["LOGIN_STRONG_AUTH_INIT_SUCCESS"] = "LOGIN_STRONG_AUTH_INIT_SUCCESS";
    EReduxLoginActionTypes["LOGIN_STRONG_AUTH_INIT_ERROR"] = "LOGIN_STRONG_AUTH_INIT_ERROR";
    EReduxLoginActionTypes["LOGIN_STRONG_AUTH_VERIFY_START"] = "LOGIN_STRONG_AUTH_VERIFY_START";
    EReduxLoginActionTypes["LOGIN_STRONG_AUTH_VERIFY_SUCCESS"] = "LOGIN_STRONG_AUTH_VERIFY_SUCCESS";
    EReduxLoginActionTypes["LOGIN_STRONG_AUTH_VERIFY_ERROR"] = "LOGIN_STRONG_AUTH_VERIFY_ERROR";
    EReduxLoginActionTypes["LOGIN_CONSENT_REQUESTED_GET_START"] = "LOGIN_CONSENT_REQUESTED_GET_START";
    EReduxLoginActionTypes["LOGIN_CONSENT_REQUESTED_GET_SUCCESS"] = "LOGIN_CONSENT_REQUESTED_GET_SUCCESS";
    EReduxLoginActionTypes["LOGIN_CONSENT_REQUESTED_GET_ERROR"] = "LOGIN_CONSENT_REQUESTED_GET_ERROR";
    EReduxLoginActionTypes["LOGIN_CONSENT_APPROVED_POST_START"] = "LOGIN_CONSENT_APPROVED_POST_START";
    EReduxLoginActionTypes["LOGIN_CONSENT_APPROVED_POST_SUCCESS"] = "LOGIN_CONSENT_APPROVED_POST_SUCCESS";
    EReduxLoginActionTypes["LOGIN_CONSENT_APPROVED_POST_ERROR"] = "LOGIN_CONSENT_APPROVED_POST_ERROR";
    EReduxLoginActionTypes["LOGIN_GET_TOKEN_START"] = "LOGIN_GET_TOKEN_START";
    EReduxLoginActionTypes["LOGIN_GET_TOKEN_SUCCESS"] = "LOGIN_GET_TOKEN_SUCCESS";
    EReduxLoginActionTypes["LOGIN_GET_TOKEN_ERROR"] = "LOGIN_GET_TOKEN_ERROR";
    EReduxLoginActionTypes["RESET_LOGIN_FLOW"] = "RESET_LOGIN_FLOW";
    EReduxLoginActionTypes["RESET_FORM_ENROLLMENT"] = "RESET_FORM_ENROLLMENT";
    EReduxLoginActionTypes["POST_ENROLLMENT_START"] = "POST_ENROLLMENT_START";
    EReduxLoginActionTypes["POST_ENROLLMENT_SUCCESS"] = "POST_ENROLLMENT_SUCCESS";
    EReduxLoginActionTypes["POST_ENROLLMENT_ERROR"] = "POST_ENROLLMENT_ERROR";
    EReduxLoginActionTypes["LOGIN_SET_PIN"] = "LOGIN_SET_PIN";
    EReduxLoginActionTypes["LOGIN_SET_VISUALIZZA_QR"] = "LOGIN_SET_VISUALIZZA_QR";
    EReduxLoginActionTypes["LOGIN_SET_OTP"] = "LOGIN_SET_OTP";
    EReduxLoginActionTypes["LOGIN_SET_OTP_HARDWARE"] = "LOGIN_SET_OTP_HARDWARE";
    EReduxLoginActionTypes["ENROLLMENT_SET_PIN"] = "ENROLLMENT_SET_PIN";
    EReduxLoginActionTypes["ENROLLMENT_SET_RIPETI_PIN"] = "ENROLLMENT_SET_RIPETI_PIN";
    EReduxLoginActionTypes["ENROLLMENT_SET_SMS"] = "ENROLLMENT_SET_SMS";
    EReduxLoginActionTypes["AZIONI_SMART_SET_PIN"] = "AZIONI_SMART_SET_PIN";
    EReduxLoginActionTypes["AZIONI_SMART_SET_STEP"] = "AZIONI_SMART_SET_STEP";
    EReduxLoginActionTypes["CAMBIO_PASSWORD_OBBLIGATORIO_START"] = "CAMBIO_PASSWORD_OBBLIGATORIO_START";
    EReduxLoginActionTypes["CAMBIO_PASSWORD_OBBLIGATORIO_SUCCESS"] = "CAMBIO_PASSWORD_OBBLIGATORIO_SUCCESS";
    EReduxLoginActionTypes["CAMBIO_PASSWORD_OBBLIGATORIO_ERROR"] = "CAMBIO_PASSWORD_OBBLIGATORIO_ERROR";
    EReduxLoginActionTypes["CAMBIO_PASSWORD_OBBLIGATORIO_SET_VECCHIA_PASSWORD"] = "CAMBIO_PASSWORD_OBBLIGATORIO_SET_VECCHIA_PASSWORD";
    EReduxLoginActionTypes["CAMBIO_PASSWORD_OBBLIGATORIO_SET_NUOVA_PASSWORD"] = "CAMBIO_PASSWORD_OBBLIGATORIO_SET_NUOVA_PASSWORD";
    EReduxLoginActionTypes["CAMBIO_PASSWORD_OBBLIGATORIO_SET_RIPETI_NUOVA_PASSWORD"] = "CAMBIO_PASSWORD_OBBLIGATORIO_SET_RIPETI_NUOVA_PASSWORD";
    EReduxLoginActionTypes["LOGOUT_OAUTH2_START"] = "LOGOUT_OAUTH2_START";
    EReduxLoginActionTypes["LOGOUT_OAUTH2_SUCCESS"] = "LOGOUT_OAUTH2_SUCCESS";
    EReduxLoginActionTypes["LOGIN_REFRESH_TOKEN_START"] = "LOGIN_REFRESH_TOKEN_START";
    EReduxLoginActionTypes["LOGIN_REFRESH_TOKEN_SUCCESS"] = "LOGIN_REFRESH_TOKEN_SUCCESS";
    EReduxLoginActionTypes["LOGIN_REFRESH_TOKEN_ERROR"] = "LOGIN_REFRESH_TOKEN_ERROR";
    EReduxLoginActionTypes["TOKEN_RESET_START"] = "TOKEN_RESET_START";
    EReduxLoginActionTypes["TOKEN_RESET_SUCCESS"] = "TOKEN_RESET_SUCCESS";
    EReduxLoginActionTypes["TOKEN_RESET_ERROR"] = "TOKEN_RESET_ERROR";
    EReduxLoginActionTypes["LOGIN_TRANSACTION_START_START"] = "LOGIN_TRANSACTION_START_START";
    EReduxLoginActionTypes["LOGIN_TRANSACTION_START_SUCCESS"] = "LOGIN_TRANSACTION_START_SUCCESS";
    EReduxLoginActionTypes["LOGIN_TRANSACTION_START_ERROR"] = "LOGIN_TRANSACTION_START_ERROR";
    EReduxLoginActionTypes["SELF_RESET_GET_STATUS_START"] = "SELF_RESET_GET_STATUS_START";
    EReduxLoginActionTypes["SELF_RESET_GET_STATUS_SUCCESS"] = "SELF_RESET_GET_STATUS_SUCCESS";
    EReduxLoginActionTypes["SELF_RESET_START_START"] = "SELF_RESET_START_START";
    EReduxLoginActionTypes["SELF_RESET_START_SUCCESS"] = "SELF_RESET_START_SUCCESS";
    EReduxLoginActionTypes["SELF_RESET_UPDATE_STATUS_START"] = "SELF_RESET_UPDATE_STATUS_START";
    EReduxLoginActionTypes["SELF_RESET_UPDATE_STATUS_SUCCESS"] = "SELF_RESET_UPDATE_STATUS_SUCCESS";
    EReduxLoginActionTypes["SELF_RESET_NASCONDI_MODALE_ENROLLMENT_PER_SESSIONE_SCADUTA"] = "SELF_RESET_NASCONDI_MODALE_ENROLLMENT_PER_SESSIONE_SCADUTA";
    EReduxLoginActionTypes["LOGIN_SET_MYBANK_LOGIN_TYPE"] = "LOGIN_SET_MYBANK_LOGIN_TYPE";
    EReduxLoginActionTypes["LOGIN_ANNULLA_LOGIN_MYBANK_START"] = "LOGIN_ANNULLA_LOGIN_MYBANK_START";
    EReduxLoginActionTypes["LOGIN_ANNULLA_LOGIN_MYBANK_SUCCESS"] = "LOGIN_ANNULLA_LOGIN_MYBANK_SUCCESS";
    EReduxLoginActionTypes["LOGIN_REDIRECT_LOGIN_MYBANK_START"] = "LOGIN_REDIRECT_LOGIN_MYBANK_START";
    EReduxLoginActionTypes["LOGIN_REDIRECT_LOGIN_MYBANK_SUCCESS"] = "LOGIN_REDIRECT_LOGIN_MYBANK_SUCCESS";
})(EReduxLoginActionTypes = exports.EReduxLoginActionTypes || (exports.EReduxLoginActionTypes = {}));
