import BankIcon from "component/Icon/icon.component";
import React from "react";

interface Props {
  descrizione: string;
  icon: any;
  onClick: any;
  className?: string;
}

const ItemWidget: React.FC<Props> = (props: Props) => {
  const { icon, descrizione, onClick, className } = props;

  const onGetAction = () => {
    onClick && onClick();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === " ") {
      e.preventDefault();
      onGetAction();
    }
  };

  return (
    <button
      className={`quick-operation ${className || ""}`}
      onClick={() => {
        onGetAction();
      }}
      style={{ border: "none" }}
      aria-label={descrizione}
      onKeyDown={handleKeyDown}
    >
      <div className="iconView-item-quickOpr">
        <BankIcon
          title={icon}
        />
      </div>
      <p className="txtWidgetView-item-quickOpr">{descrizione}</p>
    </button>
  );
};

export default ItemWidget;
