
import { Environments, getCustomEnv, selectedAbi, selectedEnv } from "@sparkasse/commons";
import { getQueryParams } from "helpers/utilities";
import logo from "logoSparkasse.svg";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { AppState } from "reducers";
import { AnyAction, bindActionCreators, Dispatch } from "redux";

const PageCorporate: React.FC<Props> = (props) => {
  const [tokenMyBank, setTokenMyBank] = useState<string>();

  useEffect(() => {
    const token = getQueryParams('token', window.document?.location?.href);
    if (!token) {
      window.open(Environments[getCustomEnv() || selectedEnv].publicSite)
    }
    setTokenMyBank(token);
  }, [])

  return (

    <div>
      <div className="main-header">
        <div className="header-logo-search-container">

          <img
            style={{ margin: 8, display: 'inherit' }}
            src={logo}
            alt="logo"
            className={`bank-logo big`}
          />
        </div>
        <div className="header-buttons-container">

          <div style={{ padding: 8 }}>
            <img
              src={"/mybank.jpg"}
              style={{ height: 44 }}
            />
          </div>
          <div className="header-buttons header-buttons-second-group" style={{ display: 'inherit' }}>


          </div>
        </div>
      </div>
      <div>

        <div className="main" style={{ height: '100vh' }}>

          <div className="core" style={{ flex: 1, display: 'flex', flexDirection: 'column', alignSelf: 'center' }} >

            <div style={{ display: 'flex', justifyContent: 'center' }} dangerouslySetInnerHTML={{
              __html: `<html><form name="formauth" method="post" action=${Environments[getCustomEnv() || selectedEnv].corporate}><p style="margin-bottom:0px">Postazione:</p><p><input style="border-bottom: 1px solid #181818; color: #181818; font-size: 16px; font-weight: 400; line-height: 20px; padding: 0px 8px 8px; type:"text" name="postazione"></p><p style="margin-bottom:0px">Alias</p><p><input style="border-bottom: 1px solid #181818; color: #181818; font-size: 16px; font-weight: 400; line-height: 20px; padding: 0px 8px 8px; type:"text" name="alias"></p><p style="margin-bottom:0px">Password:</p><p><input type="password" style="border-bottom: 1px solid #181818; color: #181818; font-size: 16px; font-weight: 400; line-height: 20px; padding: 0px 8px 8px; "type:"text" name="password"></p><input type="hidden" name="from" value="MyBank" /><input type="hidden" name="token" value=${tokenMyBank} /><input type="hidden" name="abi" value=${selectedAbi} /><input style="font-weight: bold; align-items: center; background-color: #0000; border-radius: 0; cursor: pointer; display: flex; flex-direction: row;transition: background-color .16s ease-in-out, border-color .16s ease-in-out; user-select: none; border-style: solid; border-width: 2px; justify-content: center; min-height: 48px; padding: 4px 8px ;color: #df0025; border-color: #df0025" type="submit" value="INVIA" /></form></html>`
            }} />
          </div>

        </div>
      </div>
    </div>
  )
};

const mapStateToProps = (state: AppState) => ({

});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({}, dispatch);

interface RouteParams { id: string }

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<RouteParams>;

export default connect(mapStateToProps, mapDispatchToProps)(PageCorporate);

