import React from "react";

interface Props {
  descrizione: string
  onClick: any
  disable?: boolean
  negativeFilled?: boolean
}

const ButtonPrimary: React.FC<Props> = (props: Props) => {

  const {
    onClick,
    descrizione,
    disable,
    negativeFilled
  } = props;

  return (
    <button
      type="button"
      className={`${negativeFilled ? 'filled' : 'empty'}_secondary_button`}
      onClick={disable ? undefined : onClick}
      disabled={disable}
      aria-label={descrizione}
      aria-disabled={disable}
    >
      {descrizione}
    </button>
  );
};

export default ButtonPrimary;
