"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EReduxElencoUltimiAccessiActionTypes = exports.FormFiltriUltimiAccessiDtoFromJSONTyped = void 0;
const helpers_1 = require("../helpers");
const moment = require("moment");
function FormFiltriUltimiAccessiDtoFromJSONTyped(json) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'intervallo': !(0, helpers_1.exists)(json, 'intervallo') ? undefined : json.intervallo,
        'dataDa': !(0, helpers_1.exists)(json, 'dataDa') ? moment().subtract(1, 'days').format('YYYY-MM-DD') : json.dataDa,
        'dataDaError': !(0, helpers_1.exists)(json, 'dataDaError') ? "" : json.dataDaError,
        'dataDaDirty': !(0, helpers_1.exists)(json, 'dataDaDirty') ? false : json.dataDaDirty,
        'dataA': !(0, helpers_1.exists)(json, 'dataA') ? moment().format('YYYY-MM-DD') : json.dataA,
        'dataAError': !(0, helpers_1.exists)(json, 'dataAError') ? "" : json.dataAError,
        'dataADirty': !(0, helpers_1.exists)(json, 'dataADirty') ? false : json.dataADirty,
        'numeroPagina': !(0, helpers_1.exists)(json, 'numeroPagina') ? 1 : json.numeroPagina,
        'listLoader': !(0, helpers_1.exists)(json, 'listLoader') ? false : json.listLoader
    };
}
exports.FormFiltriUltimiAccessiDtoFromJSONTyped = FormFiltriUltimiAccessiDtoFromJSONTyped;
;
;
;
;
var EReduxElencoUltimiAccessiActionTypes;
(function (EReduxElencoUltimiAccessiActionTypes) {
    EReduxElencoUltimiAccessiActionTypes["ULTIMI_ACCESSI_GET_ULTIMI_ACCESSI_START"] = "ULTIMI_ACCESSI_GET_ULTIMI_ACCESSI_START";
    EReduxElencoUltimiAccessiActionTypes["ULTIMI_ACCESSI_GET_ULTIMI_ACCESSI_SUCCESS"] = "ULTIMI_ACCESSI_GET_ULTIMI_ACCESSI_SUCCESS";
    EReduxElencoUltimiAccessiActionTypes["ULTIMI_ACCESSI_INTERVALLO"] = "ULTIMI_ACCESSI_INTERVALLO";
    EReduxElencoUltimiAccessiActionTypes["ULTIMI_ACCESSI_NUMERO_PAGINA"] = "ULTIMI_ACCESSI_NUMERO_PAGINA";
    EReduxElencoUltimiAccessiActionTypes["ULTIMI_ACCESSI_DATA_DA"] = "ULTIMI_ACCESSI_DATA_DA";
    EReduxElencoUltimiAccessiActionTypes["ULTIMI_ACCESSI_DATA_A"] = "ULTIMI_ACCESSI_DATA_A";
    EReduxElencoUltimiAccessiActionTypes["ULTIMI_ACCESSI_FORM_RESET"] = "ULTIMI_ACCESSI_FORM_RESET";
    EReduxElencoUltimiAccessiActionTypes["PARAMETRI_GET_PARAMETRI_START"] = "PARAMETRI_GET_PARAMETRI_START";
    EReduxElencoUltimiAccessiActionTypes["PARAMETRI_GET_PARAMETRI_SUCCESS"] = "PARAMETRI_GET_PARAMETRI_SUCCESS";
    EReduxElencoUltimiAccessiActionTypes["ULTIMI_ACCESSI_LIST_LOADER"] = "ULTIMI_ACCESSI_LIST_LOADER";
    EReduxElencoUltimiAccessiActionTypes["ULTIMI_ACCESSI_RESET_ELENCO"] = "ULTIMI_ACCESSI_RESET_ELENCO";
})(EReduxElencoUltimiAccessiActionTypes = exports.EReduxElencoUltimiAccessiActionTypes || (exports.EReduxElencoUltimiAccessiActionTypes = {}));
