"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetAccettazioniFunzioneEnum = exports.MessaggiControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class MessaggiControllerApi extends runtime.BaseAPI {
    /**
     * Restituisce la lista delle comunicazioni.
     */
    getAccettazioniRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.funzione === null || requestParameters.funzione === undefined) {
                throw new runtime.RequiredError('funzione', 'Required parameter requestParameters.funzione was null or undefined when calling getAccettazioni.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/messaggi/{funzione}/accettazioni`.replace(`{${"funzione"}}`, encodeURIComponent(String(requestParameters.funzione))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.MessaggioBodyDtoFromJSON));
        });
    }
    /**
     * Restituisce la lista delle comunicazioni.
     */
    getAccettazioni(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getAccettazioniRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce la lista delle comunicazioni.
     */
    getBloccantiRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/messaggi/bloccanti`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.MessaggioBodyDtoFromJSON));
        });
    }
    /**
     * Restituisce la lista delle comunicazioni.
     */
    getBloccanti() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getBloccantiRaw();
            return yield response.value();
        });
    }
    /**
     * Restituisce la lista delle comunicazioni.
     */
    getComunicazioniRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/messaggi/comunicazioni`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.MessaggioBodyDtoFromJSON));
        });
    }
    /**
     * Restituisce la lista delle comunicazioni.
     */
    getComunicazioni() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getComunicazioniRaw();
            return yield response.value();
        });
    }
    /**
     * Restituisce il numero di nuovi messaggi da leggere.
     */
    getNumeroMessaggiNonLettiRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/messaggi/nonLetti`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.TextApiResponse(response);
        });
    }
    /**
     * Restituisce il numero di nuovi messaggi da leggere.
     */
    getNumeroMessaggiNonLetti() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getNumeroMessaggiNonLettiRaw();
            return yield response.value();
        });
    }
    /**
     * Segna come letto un messaggio dato il suo id.
     */
    segnaComeLettoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idMessaggio === null || requestParameters.idMessaggio === undefined) {
                throw new runtime.RequiredError('idMessaggio', 'Required parameter requestParameters.idMessaggio was null or undefined when calling segnaComeLetto.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/messaggi/{idMessaggio}/letto`.replace(`{${"idMessaggio"}}`, encodeURIComponent(String(requestParameters.idMessaggio))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Segna come letto un messaggio dato il suo id.
     */
    segnaComeLetto(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.segnaComeLettoRaw(requestParameters);
        });
    }
}
exports.MessaggiControllerApi = MessaggiControllerApi;
/**
    * @export
    * @enum {string}
    */
var GetAccettazioniFunzioneEnum;
(function (GetAccettazioniFunzioneEnum) {
    GetAccettazioniFunzioneEnum["BONIFICORIPETITIVO"] = "BONIFICORIPETITIVO";
    GetAccettazioniFunzioneEnum["BONIFICOSEPA"] = "BONIFICOSEPA";
    GetAccettazioniFunzioneEnum["BONIFICOBIR"] = "BONIFICOBIR";
    GetAccettazioniFunzioneEnum["BONIFICOESTERO"] = "BONIFICOESTERO";
    GetAccettazioniFunzioneEnum["BONIFICOGIRO"] = "BONIFICOGIRO";
    GetAccettazioniFunzioneEnum["BONIFICOFISCALE"] = "BONIFICOFISCALE";
    GetAccettazioniFunzioneEnum["BONIFICODEPOSITO"] = "BONIFICODEPOSITO";
    GetAccettazioniFunzioneEnum["BONIFICOINFOCC_ANNULLO"] = "BONIFICOINFOCC_ANNULLO";
    GetAccettazioniFunzioneEnum["BONIFICOINFOCC"] = "BONIFICOINFOCC";
    GetAccettazioniFunzioneEnum["BONIFICOSEPA_ANNULLO"] = "BONIFICOSEPA_ANNULLO";
    GetAccettazioniFunzioneEnum["BOLLETTINOPOSTALE"] = "BOLLETTINOPOSTALE";
    GetAccettazioniFunzioneEnum["BOLLETTINOPOSTALE_ANNULLO"] = "BOLLETTINOPOSTALE_ANNULLO";
    GetAccettazioniFunzioneEnum["BOLLETTINOPOSTALEBIANCO"] = "BOLLETTINOPOSTALEBIANCO";
    GetAccettazioniFunzioneEnum["BOLLETTINOPOSTALEPREMARCATO"] = "BOLLETTINOPOSTALEPREMARCATO";
    GetAccettazioniFunzioneEnum["BONIFICOGIROCONTO"] = "BONIFICOGIROCONTO";
    GetAccettazioniFunzioneEnum["BONIFICOSEPADEPO"] = "BONIFICOSEPADEPO";
    GetAccettazioniFunzioneEnum["BONIFICOSEPADEPO_ANNULLO"] = "BONIFICOSEPADEPO_ANNULLO";
    GetAccettazioniFunzioneEnum["TIMEDEPOSITIACQUISTO"] = "TIMEDEPOSITIACQUISTO";
    GetAccettazioniFunzioneEnum["TIMEDEPOSITIACQUISTO_ANNULLO"] = "TIMEDEPOSITIACQUISTO_ANNULLO";
    GetAccettazioniFunzioneEnum["RICARICACELLULARE"] = "RICARICACELLULARE";
    GetAccettazioniFunzioneEnum["PAGMAV"] = "PAGMAV";
    GetAccettazioniFunzioneEnum["PAGMAVSP"] = "PAGMAVSP";
    GetAccettazioniFunzioneEnum["PAGMAVSP_ANNULLO"] = "PAGMAVSP_ANNULLO";
    GetAccettazioniFunzioneEnum["PAGRAV"] = "PAGRAV";
    GetAccettazioniFunzioneEnum["PAGRAVSP"] = "PAGRAVSP";
    GetAccettazioniFunzioneEnum["PAGRAVSP_ANNULLO"] = "PAGRAVSP_ANNULLO";
    GetAccettazioniFunzioneEnum["F24SEMPLIFICATO"] = "F24SEMPLIFICATO";
    GetAccettazioniFunzioneEnum["F24STANDARD"] = "F24STANDARD";
    GetAccettazioniFunzioneEnum["F24IDENTIFICATIVI"] = "F24IDENTIFICATIVI";
    GetAccettazioniFunzioneEnum["F24ACCISE"] = "F24ACCISE";
    GetAccettazioniFunzioneEnum["BOLLETTINOBANCARIO"] = "BOLLETTINOBANCARIO";
    GetAccettazioniFunzioneEnum["PAGACI"] = "PAGACI";
    GetAccettazioniFunzioneEnum["PAGEFFETTI"] = "PAGEFFETTI";
    GetAccettazioniFunzioneEnum["PAGEFFETTI_ANNULLO"] = "PAGEFFETTI_ANNULLO";
    GetAccettazioniFunzioneEnum["PAGTRIBUTI"] = "PAGTRIBUTI";
    GetAccettazioniFunzioneEnum["CARTARICARICABILEDISPO"] = "CARTARICARICABILEDISPO";
    GetAccettazioniFunzioneEnum["EBILLING"] = "EBILLING";
    GetAccettazioniFunzioneEnum["F24NONHB"] = "F24NONHB";
    GetAccettazioniFunzioneEnum["BODOMICILIATODISPO"] = "BODOMICILIATODISPO";
    GetAccettazioniFunzioneEnum["POSTEPAYDISPO"] = "POSTEPAYDISPO";
    GetAccettazioniFunzioneEnum["CCARDDISPO"] = "CCARDDISPO";
    GetAccettazioniFunzioneEnum["CARTACONTODISPO"] = "CARTACONTODISPO";
    GetAccettazioniFunzioneEnum["PLICK"] = "PLICK";
    GetAccettazioniFunzioneEnum["PCT"] = "PCT";
    GetAccettazioniFunzioneEnum["CARTACONTODISPOCATEGORIE"] = "CARTACONTODISPOCATEGORIE";
    GetAccettazioniFunzioneEnum["ONECLICK"] = "ONECLICK";
    GetAccettazioniFunzioneEnum["ONECLICK_AGGIORNA"] = "ONECLICK_AGGIORNA";
    GetAccettazioniFunzioneEnum["SUDTIROLPASS"] = "SUDTIROLPASS";
    GetAccettazioniFunzioneEnum["UTENZEAPERTURA"] = "UTENZEAPERTURA";
    GetAccettazioniFunzioneEnum["MANDATISDDDISPO_ANNULLO"] = "MANDATISDDDISPO_ANNULLO";
    GetAccettazioniFunzioneEnum["MANDATISDDINFO"] = "MANDATISDDINFO";
    GetAccettazioniFunzioneEnum["F24SP_ANNULLO"] = "F24SP_ANNULLO";
    GetAccettazioniFunzioneEnum["BOLLETTINOBANCARIOSP"] = "BOLLETTINOBANCARIOSP";
    GetAccettazioniFunzioneEnum["BOLLETTINOBANCARIOSP_ANNULLO"] = "BOLLETTINOBANCARIOSP_ANNULLO";
    GetAccettazioniFunzioneEnum["LOGIN"] = "LOGIN";
    GetAccettazioniFunzioneEnum["BOLLOACI"] = "BOLLOACI";
    GetAccettazioniFunzioneEnum["BILANCIO"] = "BILANCIO";
    GetAccettazioniFunzioneEnum["ASSEGNI"] = "ASSEGNI";
    GetAccettazioniFunzioneEnum["MANDATISDD"] = "MANDATISDD";
    GetAccettazioniFunzioneEnum["BOZZE"] = "BOZZE";
    GetAccettazioniFunzioneEnum["LIBRETTIRISPARMIO"] = "LIBRETTIRISPARMIO";
    GetAccettazioniFunzioneEnum["PRESTITOGREEN"] = "PRESTITOGREEN";
    GetAccettazioniFunzioneEnum["COFIDIS"] = "COFIDIS";
    GetAccettazioniFunzioneEnum["MUTUI"] = "MUTUI";
    GetAccettazioniFunzioneEnum["PASSWORD"] = "PASSWORD";
    GetAccettazioniFunzioneEnum["OTP_PIN"] = "OTP_PIN";
    GetAccettazioniFunzioneEnum["BLOCCAUTENZA"] = "BLOCCAUTENZA";
    GetAccettazioniFunzioneEnum["RAPPORTI"] = "RAPPORTI";
    GetAccettazioniFunzioneEnum["LIMITIOPERATIVI"] = "LIMITIOPERATIVI";
    GetAccettazioniFunzioneEnum["PATRIMONIO"] = "PATRIMONIO";
    GetAccettazioniFunzioneEnum["BLOCCOCARTE"] = "BLOCCOCARTE";
    GetAccettazioniFunzioneEnum["DOCUMENTALE"] = "DOCUMENTALE";
    GetAccettazioniFunzioneEnum["CONTRATTI"] = "CONTRATTI";
    GetAccettazioniFunzioneEnum["FAQ"] = "FAQ";
    GetAccettazioniFunzioneEnum["FIRMADIGITALE"] = "FIRMADIGITALE";
    GetAccettazioniFunzioneEnum["RICERCAATM"] = "RICERCAATM";
    GetAccettazioniFunzioneEnum["ANAGRAFICHEWL"] = "ANAGRAFICHEWL";
    GetAccettazioniFunzioneEnum["ANAGRAFICHEWL_MODIFICA"] = "ANAGRAFICHEWL_MODIFICA";
    GetAccettazioniFunzioneEnum["ANAGRAFICHE_MODIFICA"] = "ANAGRAFICHE_MODIFICA";
    GetAccettazioniFunzioneEnum["ANAGRAFICHE_ELIMINA"] = "ANAGRAFICHE_ELIMINA";
    GetAccettazioniFunzioneEnum["ANAGRAFICHE_DISPENSA"] = "ANAGRAFICHE_DISPENSA";
    GetAccettazioniFunzioneEnum["ANAGRAFICHE_DISPENSA_ANNULLO"] = "ANAGRAFICHE_DISPENSA_ANNULLO";
    GetAccettazioniFunzioneEnum["ANAGRAFICHE"] = "ANAGRAFICHE";
    GetAccettazioniFunzioneEnum["BONIFICORIPETITIVO_REVOCA"] = "BONIFICORIPETITIVO_REVOCA";
    GetAccettazioniFunzioneEnum["BONIFICORIPETITIVO_SOSPENDI"] = "BONIFICORIPETITIVO_SOSPENDI";
    GetAccettazioniFunzioneEnum["BONIFICORIPETITIVO_RIATTIVA"] = "BONIFICORIPETITIVO_RIATTIVA";
    GetAccettazioniFunzioneEnum["BONIFICORIPETITIVO_MODIFICA"] = "BONIFICORIPETITIVO_MODIFICA";
    GetAccettazioniFunzioneEnum["JIFFYACCOUNT"] = "JIFFYACCOUNT";
    GetAccettazioniFunzioneEnum["JIFFYP2BACCOUNT"] = "JIFFYP2BACCOUNT";
    GetAccettazioniFunzioneEnum["MYBANK"] = "MYBANK";
    GetAccettazioniFunzioneEnum["DATIPERSONALI"] = "DATIPERSONALI";
    GetAccettazioniFunzioneEnum["TRADING"] = "TRADING";
    GetAccettazioniFunzioneEnum["TRADINGNEW"] = "TRADINGNEW";
    GetAccettazioniFunzioneEnum["TRADING_ANNULLO"] = "TRADING_ANNULLO";
    GetAccettazioniFunzioneEnum["TITOLISCADENZA"] = "TITOLISCADENZA";
    GetAccettazioniFunzioneEnum["PROFILOTRADING"] = "PROFILOTRADING";
    GetAccettazioniFunzioneEnum["POSIZIONETITOLI"] = "POSIZIONETITOLI";
    GetAccettazioniFunzioneEnum["QUOTAZIONI"] = "QUOTAZIONI";
    GetAccettazioniFunzioneEnum["COMUNICAZIONICANALE"] = "COMUNICAZIONICANALE";
    GetAccettazioniFunzioneEnum["SMSACQUISTO"] = "SMSACQUISTO";
    GetAccettazioniFunzioneEnum["FILIALEVIRTUALE"] = "FILIALEVIRTUALE";
    GetAccettazioniFunzioneEnum["GESTIONECONSENSIPSD2"] = "GESTIONECONSENSIPSD2";
    GetAccettazioniFunzioneEnum["ANATOCISMO"] = "ANATOCISMO";
    GetAccettazioniFunzioneEnum["FONDIDISPO"] = "FONDIDISPO";
    GetAccettazioniFunzioneEnum["SSOCARTASI"] = "SSOCARTASI";
    GetAccettazioniFunzioneEnum["SSOZURICH"] = "SSOZURICH";
    GetAccettazioniFunzioneEnum["VENDITAPRESTITICREDI"] = "VENDITAPRESTITICREDI";
    GetAccettazioniFunzioneEnum["VENDITAPRESTITIDISPO"] = "VENDITAPRESTITIDISPO";
    GetAccettazioniFunzioneEnum["ESTRATTOCONTOITALIA"] = "ESTRATTOCONTOITALIA";
    GetAccettazioniFunzioneEnum["ELENCOPAGAMENTI"] = "ELENCOPAGAMENTI";
    GetAccettazioniFunzioneEnum["DATIAGGIUNTIVI"] = "DATIAGGIUNTIVI";
    GetAccettazioniFunzioneEnum["PSD2ATTIVA"] = "PSD2ATTIVA";
    GetAccettazioniFunzioneEnum["PSD2PISP"] = "PSD2PISP";
    GetAccettazioniFunzioneEnum["ASSISTENTEVIRTUALE"] = "ASSISTENTEVIRTUALE";
    GetAccettazioniFunzioneEnum["VARIAZIONECANALEFONDI"] = "VARIAZIONECANALEFONDI";
    GetAccettazioniFunzioneEnum["ZAINETTOFISCALE"] = "ZAINETTOFISCALE";
    GetAccettazioniFunzioneEnum["NEXIDEBIT"] = "NEXIDEBIT";
    GetAccettazioniFunzioneEnum["ACQUISTO_NEXIDEBIT"] = "ACQUISTO_NEXIDEBIT";
})(GetAccettazioniFunzioneEnum = exports.GetAccettazioniFunzioneEnum || (exports.GetAccettazioniFunzioneEnum = {}));
