"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.VoceCategoriaDtoToJSON = exports.VoceCategoriaDtoFromJSONTyped = exports.VoceCategoriaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function VoceCategoriaDtoFromJSON(json) {
    return VoceCategoriaDtoFromJSONTyped(json, false);
}
exports.VoceCategoriaDtoFromJSON = VoceCategoriaDtoFromJSON;
function VoceCategoriaDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codiceVoce': !runtime_1.exists(json, 'codiceVoce') ? undefined : json['codiceVoce'],
        'descrizioneVoce': !runtime_1.exists(json, 'descrizioneVoce') ? undefined : json['descrizioneVoce'],
        'importo': !runtime_1.exists(json, 'importo') ? undefined : json['importo'],
        'segno': !runtime_1.exists(json, 'segno') ? undefined : json['segno'],
    };
}
exports.VoceCategoriaDtoFromJSONTyped = VoceCategoriaDtoFromJSONTyped;
function VoceCategoriaDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codiceVoce': value.codiceVoce,
        'descrizioneVoce': value.descrizioneVoce,
        'importo': value.importo,
        'segno': value.segno,
    };
}
exports.VoceCategoriaDtoToJSON = VoceCategoriaDtoToJSON;
