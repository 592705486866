"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.postloginReducer = void 0;
const __1 = require("..");
const initialState = {
    parametri: undefined,
    listaComuni: [],
    tipoDocumento: []
};
const postloginReducer = (state = initialState, action) => {
    switch (action.type) {
        case __1.EReduxActionTypes.POSTLOGIN_GET_PARAMETRI_SUCCESS:
            return {
                ...state,
                parametri: action.payload
            };
        case __1.EReduxActionTypes.POSTLOGIN_GET_LISTA_COMUNI_SUCCESS:
            return {
                ...state,
                listaComuni: action.payload
            };
        case __1.EReduxActionTypes.POSTLOGIN_GET_TIPO_DOCUMENTO_SUCCESS:
            return {
                ...state,
                tipoDocumento: action.payload
            };
        default:
            return state;
    }
};
exports.postloginReducer = postloginReducer;
exports.default = exports.postloginReducer;
