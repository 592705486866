"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttributiRapportoPsd2ConsensoEnum = exports.AttributiRapportoPsd2ToJSON = exports.AttributiRapportoPsd2FromJSONTyped = exports.AttributiRapportoPsd2FromJSON = void 0;
const runtime_1 = require("../runtime");
function AttributiRapportoPsd2FromJSON(json) {
    return AttributiRapportoPsd2FromJSONTyped(json, false);
}
exports.AttributiRapportoPsd2FromJSON = AttributiRapportoPsd2FromJSON;
function AttributiRapportoPsd2FromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'accountId': !runtime_1.exists(json, 'accountId') ? undefined : json['accountId'],
        'resourceId': !runtime_1.exists(json, 'resourceId') ? undefined : json['resourceId'],
        'providerId': !runtime_1.exists(json, 'providerId') ? undefined : json['providerId'],
        'aspspId': !runtime_1.exists(json, 'aspspId') ? undefined : json['aspspId'],
        'aspspProductCode': !runtime_1.exists(json, 'aspspProductCode') ? undefined : json['aspspProductCode'],
        'aspspProductTypes': !runtime_1.exists(json, 'aspspProductTypes') ? undefined : json['aspspProductTypes'],
        'aspspProductSuggestedLabel': !runtime_1.exists(json, 'aspspProductSuggestedLabel') ? undefined : json['aspspProductSuggestedLabel'],
        'psuId': !runtime_1.exists(json, 'psuId') ? undefined : json['psuId'],
        'iconImage64': !runtime_1.exists(json, 'iconImage64') ? undefined : json['iconImage64'],
        'consenso': !runtime_1.exists(json, 'consenso') ? undefined : json['consenso'],
        'dataScadenza': !runtime_1.exists(json, 'dataScadenza') ? undefined : (new Date(json['dataScadenza'])),
        'numeroConto': !runtime_1.exists(json, 'numeroConto') ? undefined : json['numeroConto'],
        'pollingAuth': !runtime_1.exists(json, 'pollingAuth') ? undefined : json['pollingAuth'],
        'psuIdRequired': !runtime_1.exists(json, 'psuIdRequired') ? undefined : json['psuIdRequired'],
    };
}
exports.AttributiRapportoPsd2FromJSONTyped = AttributiRapportoPsd2FromJSONTyped;
function AttributiRapportoPsd2ToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'accountId': value.accountId,
        'resourceId': value.resourceId,
        'providerId': value.providerId,
        'aspspId': value.aspspId,
        'aspspProductCode': value.aspspProductCode,
        'aspspProductTypes': value.aspspProductTypes,
        'aspspProductSuggestedLabel': value.aspspProductSuggestedLabel,
        'psuId': value.psuId,
        'iconImage64': value.iconImage64,
        'consenso': value.consenso,
        'dataScadenza': value.dataScadenza === undefined ? undefined : (value.dataScadenza.toISOString()),
        'numeroConto': value.numeroConto,
        'pollingAuth': value.pollingAuth,
        'psuIdRequired': value.psuIdRequired,
    };
}
exports.AttributiRapportoPsd2ToJSON = AttributiRapportoPsd2ToJSON;
/**
* @export
* @enum {string}
*/
var AttributiRapportoPsd2ConsensoEnum;
(function (AttributiRapportoPsd2ConsensoEnum) {
    AttributiRapportoPsd2ConsensoEnum["ACTIVE"] = "ACTIVE";
    AttributiRapportoPsd2ConsensoEnum["VALID"] = "VALID";
    AttributiRapportoPsd2ConsensoEnum["INACTIVE"] = "INACTIVE";
    AttributiRapportoPsd2ConsensoEnum["EXPIRING"] = "EXPIRING";
})(AttributiRapportoPsd2ConsensoEnum = exports.AttributiRapportoPsd2ConsensoEnum || (exports.AttributiRapportoPsd2ConsensoEnum = {}));
