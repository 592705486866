"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EReduxTitoliQuotatiActionTypes = exports.TipoGraficoTitoliQuotatiEsteri = exports.TipoGraficoTitoliQuotati = void 0;
var TipoGraficoTitoliQuotati;
(function (TipoGraficoTitoliQuotati) {
    TipoGraficoTitoliQuotati[TipoGraficoTitoliQuotati["Ore1"] = 0] = "Ore1";
    TipoGraficoTitoliQuotati[TipoGraficoTitoliQuotati["Ore4"] = 1] = "Ore4";
    TipoGraficoTitoliQuotati[TipoGraficoTitoliQuotati["Giorni1"] = 2] = "Giorni1";
    TipoGraficoTitoliQuotati[TipoGraficoTitoliQuotati["Settimana1"] = 3] = "Settimana1";
    TipoGraficoTitoliQuotati[TipoGraficoTitoliQuotati["Mese1"] = 4] = "Mese1";
    TipoGraficoTitoliQuotati[TipoGraficoTitoliQuotati["Anno1"] = 5] = "Anno1";
})(TipoGraficoTitoliQuotati = exports.TipoGraficoTitoliQuotati || (exports.TipoGraficoTitoliQuotati = {}));
;
var TipoGraficoTitoliQuotatiEsteri;
(function (TipoGraficoTitoliQuotatiEsteri) {
    TipoGraficoTitoliQuotatiEsteri[TipoGraficoTitoliQuotatiEsteri["Settimana1"] = 0] = "Settimana1";
    TipoGraficoTitoliQuotatiEsteri[TipoGraficoTitoliQuotatiEsteri["Mese1"] = 1] = "Mese1";
    TipoGraficoTitoliQuotatiEsteri[TipoGraficoTitoliQuotatiEsteri["Anno1"] = 2] = "Anno1";
})(TipoGraficoTitoliQuotatiEsteri = exports.TipoGraficoTitoliQuotatiEsteri || (exports.TipoGraficoTitoliQuotatiEsteri = {}));
;
var EReduxTitoliQuotatiActionTypes;
(function (EReduxTitoliQuotatiActionTypes) {
    EReduxTitoliQuotatiActionTypes["TITOLI_QUOTATI_GET_MERCATI_START"] = "TITOLI_QUOTATI_GET_MERCATI_START";
    EReduxTitoliQuotatiActionTypes["TITOLI_QUOTATI_GET_MERCATI_SUCCESS"] = "TITOLI_QUOTATI_GET_MERCATI_SUCCESS";
    EReduxTitoliQuotatiActionTypes["TITOLI_QUOTATI_GET_TITOLI_START"] = "TITOLI_QUOTATI_GET_TITOLI_START";
    EReduxTitoliQuotatiActionTypes["TITOLI_QUOTATI_GET_TITOLI_SUCCESS"] = "TITOLI_QUOTATI_GET_TITOLI_SUCCESS";
    EReduxTitoliQuotatiActionTypes["SET_TITOLI_QUOTATI_MERCATO"] = "SET_TITOLI_QUOTATI_MERCATO";
    EReduxTitoliQuotatiActionTypes["SET_TITOLI_QUOTATI_DENOMINAZIONE"] = "SET_TITOLI_QUOTATI_DENOMINAZIONE";
    EReduxTitoliQuotatiActionTypes["TITOLI_QUOTATI_RESET_FORM"] = "TITOLI_QUOTATI_RESET_FORM";
    EReduxTitoliQuotatiActionTypes["TITOLI_QUOTATI_RESET"] = "TITOLI_QUOTATI_RESET";
    EReduxTitoliQuotatiActionTypes["TITOLI_QUOTATI_GET_DETTAGLIO_TITOLO_START"] = "TITOLI_QUOTATI_GET_DETTAGLIO_TITOLO_START";
    EReduxTitoliQuotatiActionTypes["TITOLI_QUOTATI_GET_DETTAGLIO_TITOLO_SUCCESS"] = "TITOLI_QUOTATI_GET_DETTAGLIO_TITOLO_SUCCESS";
    EReduxTitoliQuotatiActionTypes["TITOLI_QUOTATI_GET_SCHEDA_TITOLO_START"] = "TITOLI_QUOTATI_GET_SCHEDA_TITOLO_START";
    EReduxTitoliQuotatiActionTypes["TITOLI_QUOTATI_GET_SCHEDA_TITOLO_SUCCESS"] = "TITOLI_QUOTATI_GET_SCHEDA_TITOLO_SUCCESS";
    EReduxTitoliQuotatiActionTypes["TITOLI_QUOTATI_SET_TITOLO_SELEZIONATO"] = "TITOLI_QUOTATI_SET_TITOLO_SELEZIONATO";
    EReduxTitoliQuotatiActionTypes["TITOLI_QUOTATI_GET_DATI_INTRADAY_TITOLO_START"] = "TITOLI_QUOTATI_GET_DATI_INTRADAY_TITOLO_START";
    EReduxTitoliQuotatiActionTypes["TITOLI_QUOTATI_GET_DATI_INTRADAY_TITOLO_SUCCESS"] = "TITOLI_QUOTATI_GET_DATI_INTRADAY_TITOLO_SUCCESS";
    EReduxTitoliQuotatiActionTypes["TITOLI_QUOTATI_GET_DATI_STORICI_TITOLO_START"] = "TITOLI_QUOTATI_GET_DATI_STORICI_TITOLO_START";
    EReduxTitoliQuotatiActionTypes["TITOLI_QUOTATI_GET_DATI_STORICI_TITOLO_SUCCESS"] = "TITOLI_QUOTATI_GET_DATI_STORICI_TITOLO_SUCCESS";
    EReduxTitoliQuotatiActionTypes["TITOLI_QUOTATI_SET_OFFSET"] = "TITOLI_QUOTATI_SET_OFFSET";
    EReduxTitoliQuotatiActionTypes["TITOLI_QUOTATI_RESET_DETTAGLIO"] = "TITOLI_QUOTATI_RESET_DETTAGLIO";
    EReduxTitoliQuotatiActionTypes["TITOLI_QUOTATI_SET_TIPO_GRAFICO"] = "TITOLI_QUOTATI_SET_TIPO_GRAFICO";
    EReduxTitoliQuotatiActionTypes["TITOLI_QUOTATI_AGGIORNA_GRAFICO"] = "TITOLI_QUOTATI_AGGIORNA_GRAFICO";
    EReduxTitoliQuotatiActionTypes["TITOLI_QUOTATI_ESTERI_AGGIORNA_GRAFICO"] = "TITOLI_QUOTATI_ESTERI_AGGIORNA_GRAFICO";
})(EReduxTitoliQuotatiActionTypes = exports.EReduxTitoliQuotatiActionTypes || (exports.EReduxTitoliQuotatiActionTypes = {}));
