"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RigaSezioneInailDtoToJSON = exports.RigaSezioneInailDtoFromJSONTyped = exports.RigaSezioneInailDtoFromJSON = void 0;
function RigaSezioneInailDtoFromJSON(json) {
    return RigaSezioneInailDtoFromJSONTyped(json, false);
}
exports.RigaSezioneInailDtoFromJSON = RigaSezioneInailDtoFromJSON;
function RigaSezioneInailDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codiceSede': json['codiceSede'],
        'codiceDitta': json['codiceDitta'],
        'cc': json['cc'],
        'riferimento': json['riferimento'],
        'causale': json['causale'],
        'importoDebito': json['importoDebito'],
        'importoCredito': json['importoCredito'],
    };
}
exports.RigaSezioneInailDtoFromJSONTyped = RigaSezioneInailDtoFromJSONTyped;
function RigaSezioneInailDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codiceSede': value.codiceSede,
        'codiceDitta': value.codiceDitta,
        'cc': value.cc,
        'riferimento': value.riferimento,
        'causale': value.causale,
        'importoDebito': value.importoDebito,
        'importoCredito': value.importoCredito,
    };
}
exports.RigaSezioneInailDtoToJSON = RigaSezioneInailDtoToJSON;
