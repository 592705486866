"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BolloAciControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class BolloAciControllerApi extends runtime.BaseAPI {
    /**
     * Informazioni sull\'autenticazione di un bollo aci
     */
    authenticationInformationBolloAciRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling authenticationInformationBolloAci.');
            }
            const queryParameters = {};
            if (requestParameters.chiamataUscente !== undefined) {
                queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
            }
            if (requestParameters.eseguiInit !== undefined) {
                queryParameters['eseguiInit'] = requestParameters.eseguiInit;
            }
            if (requestParameters.offline !== undefined) {
                queryParameters['offline'] = requestParameters.offline;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/bolloAci/infoautenticazione/{idDispositiva}`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Informazioni sull\'autenticazione di un bollo aci
     */
    authenticationInformationBolloAci(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.authenticationInformationBolloAciRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Autorizzazione di una disposizione di un bollo aci
     */
    authorizeBolloAciRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling authorizeBolloAci.');
            }
            if (requestParameters.credenzialeDispositivaDto === null || requestParameters.credenzialeDispositivaDto === undefined) {
                throw new runtime.RequiredError('credenzialeDispositivaDto', 'Required parameter requestParameters.credenzialeDispositivaDto was null or undefined when calling authorizeBolloAci.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/bolloAci/autorizza/{idDispositiva}`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CredenzialeDispositivaDtoToJSON(requestParameters.credenzialeDispositivaDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.EsitoAutorizzazioneDisposizioneBolloAciDtoFromJSON(jsonValue));
        });
    }
    /**
     * Autorizzazione di una disposizione di un bollo aci
     */
    authorizeBolloAci(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.authorizeBolloAciRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Esegue il controllo di validità di un bollo aci in base ai valori specificati nell\'oggetto di richiesta
     */
    controlloBolloAciRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.bolloAciDto === null || requestParameters.bolloAciDto === undefined) {
                throw new runtime.RequiredError('bolloAciDto', 'Required parameter requestParameters.bolloAciDto was null or undefined when calling controlloBolloAci.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/bolloAci/controllo`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.BolloAciDtoToJSON(requestParameters.bolloAciDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.BolloAciDtoFromJSON(jsonValue));
        });
    }
    /**
     * Esegue il controllo di validità di un bollo aci in base ai valori specificati nell\'oggetto di richiesta
     */
    controlloBolloAci(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.controlloBolloAciRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Salva una bozza di bollo aci
     */
    createDraftBolloAciRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.bolloAciDto === null || requestParameters.bolloAciDto === undefined) {
                throw new runtime.RequiredError('bolloAciDto', 'Required parameter requestParameters.bolloAciDto was null or undefined when calling createDraftBolloAci.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/bolloAci/salvabozza`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.BolloAciDtoToJSON(requestParameters.bolloAciDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.BolloAciDtoFromJSON(jsonValue));
        });
    }
    /**
     * Salva una bozza di bollo aci
     */
    createDraftBolloAci(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.createDraftBolloAciRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Eliminazione di una bozza di bollo aci dato l\'id
     */
    deleteDraftBolloAciRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling deleteDraftBolloAci.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/bolloAci/bozze/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Eliminazione di una bozza di bollo aci dato l\'id
     */
    deleteDraftBolloAci(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.deleteDraftBolloAciRaw(requestParameters);
        });
    }
    /**
     * Restituisce una bozza dato l\'id per bollo aci
     */
    emptyRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/bolloAci/empty`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.BolloAciElencoPagamentiDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce una bozza dato l\'id per bollo aci
     */
    empty() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.emptyRaw();
            return yield response.value();
        });
    }
    /**
     * Restituisce una bozza dato l\'id per bollo aci
     */
    getDraftByIdBolloAciRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getDraftByIdBolloAci.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/bolloAci/bozze/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.BolloAciDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce una bozza dato l\'id per bollo aci
     */
    getDraftByIdBolloAci(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getDraftByIdBolloAciRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce le bozze per bollo aci
     */
    getDraftListBolloAciRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.bozzeInDto === null || requestParameters.bozzeInDto === undefined) {
                throw new runtime.RequiredError('bozzeInDto', 'Required parameter requestParameters.bozzeInDto was null or undefined when calling getDraftListBolloAci.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/bolloAci/bozze`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.BozzeInDtoToJSON(requestParameters.bozzeInDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.BolloAciDtoFromJSON));
        });
    }
    /**
     * Restituisce le bozze per bollo aci
     */
    getDraftListBolloAci(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getDraftListBolloAciRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Lettura parametri bollo aci
     */
    parametersBolloAciRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/bolloAci/parametri`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ParametriDtoFromJSON(jsonValue));
        });
    }
    /**
     * Lettura parametri bollo aci
     */
    parametersBolloAci() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.parametersBolloAciRaw();
            return yield response.value();
        });
    }
    /**
     * Modifica di una bozza di bollo aci
     */
    updateDraftBolloAciRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling updateDraftBolloAci.');
            }
            if (requestParameters.bolloAciDto === null || requestParameters.bolloAciDto === undefined) {
                throw new runtime.RequiredError('bolloAciDto', 'Required parameter requestParameters.bolloAciDto was null or undefined when calling updateDraftBolloAci.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/bolloAci/bozze/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.BolloAciDtoToJSON(requestParameters.bolloAciDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.BolloAciDtoFromJSON(jsonValue));
        });
    }
    /**
     * Modifica di una bozza di bollo aci
     */
    updateDraftBolloAci(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateDraftBolloAciRaw(requestParameters);
            return yield response.value();
        });
    }
}
exports.BolloAciControllerApi = BolloAciControllerApi;
