"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !exports.hasOwnProperty(p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
__exportStar(require("./ASPSPDto"), exports);
__exportStar(require("./AccountBODto"), exports);
__exportStar(require("./AccountDetailDto"), exports);
__exportStar(require("./AddToPreferredDto"), exports);
__exportStar(require("./AdeguataVerificaRapportiDto"), exports);
__exportStar(require("./AgenziaDto"), exports);
__exportStar(require("./AggiornaDatiAggiuntiviRichiestaDto"), exports);
__exportStar(require("./AlertPsd2Dto"), exports);
__exportStar(require("./AllegatoPrestito"), exports);
__exportStar(require("./AnagraficaCedacriDto"), exports);
__exportStar(require("./AnagraficaRapportoDto"), exports);
__exportStar(require("./AnatocismoDto"), exports);
__exportStar(require("./AnnulloMyBankDto"), exports);
__exportStar(require("./AnswerDto"), exports);
__exportStar(require("./AppuntamentoDto"), exports);
__exportStar(require("./ApriPraticaInDto"), exports);
__exportStar(require("./ApriPraticaOutDto"), exports);
__exportStar(require("./ArchivioBozzeInDto"), exports);
__exportStar(require("./AspspDetailDto"), exports);
__exportStar(require("./AspspFilterIn"), exports);
__exportStar(require("./AspspOutDto"), exports);
__exportStar(require("./AspspProductDto"), exports);
__exportStar(require("./AssegniItaliaDto"), exports);
__exportStar(require("./AssicurazioneDto"), exports);
__exportStar(require("./AssicurazioneElementoDto"), exports);
__exportStar(require("./AssicurazioniContoDto"), exports);
__exportStar(require("./AssicurazioniDto"), exports);
__exportStar(require("./AtmDto"), exports);
__exportStar(require("./AttivitaEconomicaDto"), exports);
__exportStar(require("./AttributiMovimentiPsd2"), exports);
__exportStar(require("./AttributiRapportoPsd2"), exports);
__exportStar(require("./AutenticationContextDto"), exports);
__exportStar(require("./AuthenticationResponseDto"), exports);
__exportStar(require("./AuthenticationResponseMandatoryActionDto"), exports);
__exportStar(require("./AuthenticationStartRequestDto"), exports);
__exportStar(require("./AuthenticationStartRequestOptionsDto"), exports);
__exportStar(require("./AutorizzaConsensoPsd2Dto"), exports);
__exportStar(require("./AvvisoDto"), exports);
__exportStar(require("./BannerIdsDto"), exports);
__exportStar(require("./BannerWcmDto"), exports);
__exportStar(require("./BannerWcmListDto"), exports);
__exportStar(require("./BeneficiarioDto"), exports);
__exportStar(require("./BicDto"), exports);
__exportStar(require("./BilancioDto"), exports);
__exportStar(require("./BilancioSinteticoDto"), exports);
__exportStar(require("./BillerDto"), exports);
__exportStar(require("./BloccoCarteDto"), exports);
__exportStar(require("./BollettinoPostaleDto"), exports);
__exportStar(require("./BollettinoPostaleRevocaIn"), exports);
__exportStar(require("./BolloAciDto"), exports);
__exportStar(require("./BolloAciElencoPagamentiDto"), exports);
__exportStar(require("./BonificiEsteroDto"), exports);
__exportStar(require("./BonificiSepaDto"), exports);
__exportStar(require("./BonificoEsteroDto"), exports);
__exportStar(require("./BonificoSepaDto"), exports);
__exportStar(require("./BookDto"), exports);
__exportStar(require("./BozzaDto"), exports);
__exportStar(require("./BozzaEsteroDto"), exports);
__exportStar(require("./BozzeInDto"), exports);
__exportStar(require("./BozzeWrapperDto"), exports);
__exportStar(require("./BreakdownDetailDto"), exports);
__exportStar(require("./BreakdownDto"), exports);
__exportStar(require("./BreakdownItemDto"), exports);
__exportStar(require("./CambioCredenzialeRequestDto"), exports);
__exportStar(require("./CambioDivisaDto"), exports);
__exportStar(require("./CampiF24DelegheRBDto"), exports);
__exportStar(require("./CampiF24SPDto"), exports);
__exportStar(require("./CampoDto"), exports);
__exportStar(require("./CanaleDto"), exports);
__exportStar(require("./CaricaDocumentoIdentitaInDto"), exports);
__exportStar(require("./CaricaDocumentoIdentitaOutDto"), exports);
__exportStar(require("./CarnetDto"), exports);
__exportStar(require("./CartaAbilitabileDto"), exports);
__exportStar(require("./CartaCondizioniDto"), exports);
__exportStar(require("./CartaDto"), exports);
__exportStar(require("./CartaNexiDto"), exports);
__exportStar(require("./CartaNexiIdDto"), exports);
__exportStar(require("./CartaSiSSoDto"), exports);
__exportStar(require("./CategoryDto"), exports);
__exportStar(require("./CercaComunicazioniInDto"), exports);
__exportStar(require("./CercaDocumentiInDto"), exports);
__exportStar(require("./CertificateDto"), exports);
__exportStar(require("./CertificateInfoOutDto"), exports);
__exportStar(require("./CertificatoInfoDto"), exports);
__exportStar(require("./ChangePasswordRequestDto"), exports);
__exportStar(require("./ChannelEnum"), exports);
__exportStar(require("./CheckUserResponseDto"), exports);
__exportStar(require("./ChiaveQuietanzaDto"), exports);
__exportStar(require("./ChiaveValoreCausaliDto"), exports);
__exportStar(require("./ChiaveValoreDto"), exports);
__exportStar(require("./CittadinanzaResidenzaDto"), exports);
__exportStar(require("./CodeGrantReqIn"), exports);
__exportStar(require("./CodiceAttivazioneGeneratoDto"), exports);
__exportStar(require("./CodiceIdentificativoDto"), exports);
__exportStar(require("./CodiceTitoliDto"), exports);
__exportStar(require("./CodiceTitoloDto"), exports);
__exportStar(require("./CofidisDto"), exports);
__exportStar(require("./CofidisInDto"), exports);
__exportStar(require("./CofidisUrlDto"), exports);
__exportStar(require("./CofidisUtenteDto"), exports);
__exportStar(require("./CoherenceResultDto"), exports);
__exportStar(require("./CollocamentoDto"), exports);
__exportStar(require("./CommissioneDto"), exports);
__exportStar(require("./ComunicazioneDto"), exports);
__exportStar(require("./CondivisioneDto"), exports);
__exportStar(require("./CondizioniAcquistoNexiDebit"), exports);
__exportStar(require("./ConsensoNexiDto"), exports);
__exportStar(require("./ConsensoPsd2Dto"), exports);
__exportStar(require("./ConsensoResponse"), exports);
__exportStar(require("./ConsentType"), exports);
__exportStar(require("./ConsulenteDto"), exports);
__exportStar(require("./ContattiDto"), exports);
__exportStar(require("./ContattoDto"), exports);
__exportStar(require("./ContattoEmailDto"), exports);
__exportStar(require("./ContattoIbanDto"), exports);
__exportStar(require("./ContattoIndirizzoDto"), exports);
__exportStar(require("./ContattoMezzoDto"), exports);
__exportStar(require("./ContattoSudtirolPasDto"), exports);
__exportStar(require("./ContattoTelefonoDto"), exports);
__exportStar(require("./ContribuenteDatiAnagraficiDto"), exports);
__exportStar(require("./ContribuenteDomicilioDto"), exports);
__exportStar(require("./ContribuenteDto"), exports);
__exportStar(require("./ControlloAcquistoRequestDto"), exports);
__exportStar(require("./ControlloAnatocismoDto"), exports);
__exportStar(require("./ControlloMyBankDto"), exports);
__exportStar(require("./ConvenzioneRapportoResponseDto"), exports);
__exportStar(require("./CorrispondenzaDto"), exports);
__exportStar(require("./CorrispondenzaFondiDto"), exports);
__exportStar(require("./CorrispondenzaRequestIn"), exports);
__exportStar(require("./CredenzialeDispositiva"), exports);
__exportStar(require("./CredenzialeDispositivaDto"), exports);
__exportStar(require("./CredenzialiDto"), exports);
__exportStar(require("./CriteriBilancioIn"), exports);
__exportStar(require("./CriteriMovimentiPsd2In"), exports);
__exportStar(require("./CriteriRicercaMovimentiNexiDebitDto"), exports);
__exportStar(require("./CriteriRicercaMovimentiRequestIn"), exports);
__exportStar(require("./CustomerAwarenessRestDto"), exports);
__exportStar(require("./DataValutaBOEDto"), exports);
__exportStar(require("./Dates"), exports);
__exportStar(require("./DatiAdeguataVerificaDto"), exports);
__exportStar(require("./DatiAggiuntiviDto"), exports);
__exportStar(require("./DatiAggiuntiviRispostaDto"), exports);
__exportStar(require("./DatiAnagraficiDto"), exports);
__exportStar(require("./DatiAnagraficiRispostaDto"), exports);
__exportStar(require("./DatiBollettinoDto"), exports);
__exportStar(require("./DatiCaricamentoDocumentoIdentitaOutDto"), exports);
__exportStar(require("./DatiCartaAcquistoDto"), exports);
__exportStar(require("./DatiCartaDto"), exports);
__exportStar(require("./DatiCarteDto"), exports);
__exportStar(require("./DatiDettOrdiPerContoDiDto"), exports);
__exportStar(require("./DatiDocumento"), exports);
__exportStar(require("./DatiDocumentoDto"), exports);
__exportStar(require("./DatiEsecuzioneBollettinoDto"), exports);
__exportStar(require("./DatiEsecuzioneBonificoDto"), exports);
__exportStar(require("./DatiEsecuzioneBonificoEsteroDto"), exports);
__exportStar(require("./DatiFilialeDto"), exports);
__exportStar(require("./DatiFirmatarioDto"), exports);
__exportStar(require("./DatiFiscaliDto"), exports);
__exportStar(require("./DatiGeneraliDto"), exports);
__exportStar(require("./DatiIntestatarioDto"), exports);
__exportStar(require("./DatiIntradayCambioInDto"), exports);
__exportStar(require("./DatiIntradayDto"), exports);
__exportStar(require("./DatiIntradayIndiceInDto"), exports);
__exportStar(require("./DatiIntradayQuotazioneDto"), exports);
__exportStar(require("./DatiIntradayTitoloInDto"), exports);
__exportStar(require("./DatiPagamentiDto"), exports);
__exportStar(require("./DatiPagamentoCommonDto"), exports);
__exportStar(require("./DatiPagamentoDto"), exports);
__exportStar(require("./DatiPagamentoTributoDto"), exports);
__exportStar(require("./DatiProfessioneDto"), exports);
__exportStar(require("./DatiRapportoDto"), exports);
__exportStar(require("./DatiRedditualiPatrimonialiDto"), exports);
__exportStar(require("./DatiRicaricaDto"), exports);
__exportStar(require("./DatiRilevati"), exports);
__exportStar(require("./DatiStoriciDto"), exports);
__exportStar(require("./DatiStoriciQuotazioneDto"), exports);
__exportStar(require("./DatiTestataDto"), exports);
__exportStar(require("./DatiTitoliConMercatoDto"), exports);
__exportStar(require("./DatiTitoloConMercatoDto"), exports);
__exportStar(require("./DatoAggiuntivoDto"), exports);
__exportStar(require("./DescrizioneDto"), exports);
__exportStar(require("./DestinatarioDto"), exports);
__exportStar(require("./DettagliAppuntamentoPeriodicoDto"), exports);
__exportStar(require("./DettaglioAppuntamentoDto"), exports);
__exportStar(require("./DettaglioAppuntamentoIn"), exports);
__exportStar(require("./DettaglioBloccoCarteDto"), exports);
__exportStar(require("./DettaglioInformativaCofidisDto"), exports);
__exportStar(require("./DettaglioLibrettoDiRisparmioDto"), exports);
__exportStar(require("./DettaglioMandatoDto"), exports);
__exportStar(require("./DettaglioPagamentoDto"), exports);
__exportStar(require("./DettaglioRequest"), exports);
__exportStar(require("./DettaglioRichiestaDto"), exports);
__exportStar(require("./DisposizioneDto"), exports);
__exportStar(require("./DisposizioneVirtualeChiaveDto"), exports);
__exportStar(require("./DisposizioneVirtualeDto"), exports);
__exportStar(require("./Divisa"), exports);
__exportStar(require("./DocumentoContrattoDto"), exports);
__exportStar(require("./DocumentoDatiAnagraficiDto"), exports);
__exportStar(require("./DomiciliazioneAmministrativaDto"), exports);
__exportStar(require("./DossierAccountLevelBODto"), exports);
__exportStar(require("./DossierBODto"), exports);
__exportStar(require("./DossierCustomerLevelBODto"), exports);
__exportStar(require("./DossierCustomersAggregationLevelBODto"), exports);
__exportStar(require("./DossierDataBODto"), exports);
__exportStar(require("./DossierPositionLevelBODto"), exports);
__exportStar(require("./DossierRequestInDto"), exports);
__exportStar(require("./ElementiDto"), exports);
__exportStar(require("./ElementoPrivacyDto"), exports);
__exportStar(require("./ElencoAppuntamentiIn"), exports);
__exportStar(require("./ElencoBilancioDto"), exports);
__exportStar(require("./ElencoMovimentiFilterDto"), exports);
__exportStar(require("./ElencoNewsDto"), exports);
__exportStar(require("./ElencoNotificheIn"), exports);
__exportStar(require("./ElencoRichiesteInDto"), exports);
__exportStar(require("./ElencoTemplateFilialeDto"), exports);
__exportStar(require("./EliminaAppuntamentoIn"), exports);
__exportStar(require("./EnrollmentActivityDto"), exports);
__exportStar(require("./EnrollmentStatusDto"), exports);
__exportStar(require("./EnrollmentStatusInDto"), exports);
__exportStar(require("./EseguitiResponseDto"), exports);
__exportStar(require("./EsitiOperazioneDto"), exports);
__exportStar(require("./EsitoAutorizzazioneDisposizioneAnatocismoDto"), exports);
__exportStar(require("./EsitoAutorizzazioneDisposizioneAttivazioneJiffyDto"), exports);
__exportStar(require("./EsitoAutorizzazioneDisposizioneBollettinoPostaleDto"), exports);
__exportStar(require("./EsitoAutorizzazioneDisposizioneBolloAciDto"), exports);
__exportStar(require("./EsitoAutorizzazioneDisposizioneBonificoDto"), exports);
__exportStar(require("./EsitoAutorizzazioneDisposizioneBonificoEsteroDto"), exports);
__exportStar(require("./EsitoAutorizzazioneDisposizioneBonificoRipetitivoDto"), exports);
__exportStar(require("./EsitoAutorizzazioneDisposizioneCBillDto"), exports);
__exportStar(require("./EsitoAutorizzazioneDisposizioneConsensoPsd2Dto"), exports);
__exportStar(require("./EsitoAutorizzazioneDisposizioneContattoDto"), exports);
__exportStar(require("./EsitoAutorizzazioneDisposizioneCredenzialiDto"), exports);
__exportStar(require("./EsitoAutorizzazioneDisposizioneDatiAggiuntiviDto"), exports);
__exportStar(require("./EsitoAutorizzazioneDisposizioneDatiPagamentoTributoDto"), exports);
__exportStar(require("./EsitoAutorizzazioneDisposizioneDto"), exports);
__exportStar(require("./EsitoAutorizzazioneDisposizioneFilialeVirtualeDto"), exports);
__exportStar(require("./EsitoAutorizzazioneDisposizioneFinanzaDto"), exports);
__exportStar(require("./EsitoAutorizzazioneDisposizioneFrecciaDto"), exports);
__exportStar(require("./EsitoAutorizzazioneDisposizioneMandatoSDDDto"), exports);
__exportStar(require("./EsitoAutorizzazioneDisposizioneMavDto"), exports);
__exportStar(require("./EsitoAutorizzazioneDisposizioneModelloF24Dto"), exports);
__exportStar(require("./EsitoAutorizzazioneDisposizioneOneClickDto"), exports);
__exportStar(require("./EsitoAutorizzazioneDisposizionePagamentoJiffyDto"), exports);
__exportStar(require("./EsitoAutorizzazioneDisposizionePctDto"), exports);
__exportStar(require("./EsitoAutorizzazioneDisposizionePrestitoDto"), exports);
__exportStar(require("./EsitoAutorizzazioneDisposizioneProfiloDto"), exports);
__exportStar(require("./EsitoAutorizzazioneDisposizioneRapportiDto"), exports);
__exportStar(require("./EsitoAutorizzazioneDisposizioneRavDto"), exports);
__exportStar(require("./EsitoAutorizzazioneDisposizioneRibaDto"), exports);
__exportStar(require("./EsitoAutorizzazioneDisposizioneRicaricaCarteDto"), exports);
__exportStar(require("./EsitoAutorizzazioneDisposizioneRicaricaCellulareDto"), exports);
__exportStar(require("./EsitoAutorizzazioneDisposizioneSmsAlertDto"), exports);
__exportStar(require("./EsitoAutorizzazioneDisposizioneSudtirolPassDto"), exports);
__exportStar(require("./EsitoAutorizzazioneDisposizioneTimeDepositDto"), exports);
__exportStar(require("./EsitoAutorizzazioneDisposizioneVirtualeDto"), exports);
__exportStar(require("./EsitoCompilaContrattoDto"), exports);
__exportStar(require("./EsitoOperazioniAnagrafeDto"), exports);
__exportStar(require("./EsitoVerificaCGS"), exports);
__exportStar(require("./EsitoVerificaContrattoDto"), exports);
__exportStar(require("./ExportMovimentiInDto"), exports);
__exportStar(require("./ExportMovimentoCartaRequestDto"), exports);
__exportStar(require("./ExportMovimentoCartaRicaricabileInDto"), exports);
__exportStar(require("./ExtendedDataDto"), exports);
__exportStar(require("./F24LookupDto"), exports);
__exportStar(require("./F24ParametriDto"), exports);
__exportStar(require("./FaqWcmCategoriaDto"), exports);
__exportStar(require("./FaqWcmDto"), exports);
__exportStar(require("./FasciaDto"), exports);
__exportStar(require("./FatturaDto"), exports);
__exportStar(require("./FatturazioneDto"), exports);
__exportStar(require("./FileDto"), exports);
__exportStar(require("./FilialeDto"), exports);
__exportStar(require("./FilterBillerDto"), exports);
__exportStar(require("./FilterMandatiDto"), exports);
__exportStar(require("./FiltriEstintiDto"), exports);
__exportStar(require("./FiltroAssegniItaliaDto"), exports);
__exportStar(require("./FiltroCorrispondenzaDto"), exports);
__exportStar(require("./FiltroDettaglioTimeDepositDto"), exports);
__exportStar(require("./FiltroElencoAvvisiDto"), exports);
__exportStar(require("./FiltroElencoDepositiRisparmioDto"), exports);
__exportStar(require("./FiltroFinanziamentiDto"), exports);
__exportStar(require("./FiltroListaBonificiPeriodiciDto"), exports);
__exportStar(require("./FiltroListaContattiDto"), exports);
__exportStar(require("./FiltroListaPagamentiDto"), exports);
__exportStar(require("./FiltroTagliRicaricaDto"), exports);
__exportStar(require("./FiltroTimeDepositDto"), exports);
__exportStar(require("./FinanziamentiContoDto"), exports);
__exportStar(require("./FinanziamentiDashboardDto"), exports);
__exportStar(require("./FinanziamentoDto"), exports);
__exportStar(require("./FirmaDigitaleSessionUrlDto"), exports);
__exportStar(require("./FlagGenericiDto"), exports);
__exportStar(require("./FondoDto"), exports);
__exportStar(require("./FrecciaDto"), exports);
__exportStar(require("./FrecciaRevocaRequestIn"), exports);
__exportStar(require("./FrecciasDto"), exports);
__exportStar(require("./FvDatiUtenteDto"), exports);
__exportStar(require("./GestioneConsensiPsd2AbilitazioniDto"), exports);
__exportStar(require("./GestioneNotificheRequestIn"), exports);
__exportStar(require("./GestionePatrimonialiDto"), exports);
__exportStar(require("./GestionePreferitiDto"), exports);
__exportStar(require("./GestoreTelefonicoDto"), exports);
__exportStar(require("./GetBreakdownDetailInDto"), exports);
__exportStar(require("./GetBreakdownsInDto"), exports);
__exportStar(require("./GetQuestionnaireAnswersInDto"), exports);
__exportStar(require("./HomebankingQuestionBODto"), exports);
__exportStar(require("./HomebankingQuestionOptionBODto"), exports);
__exportStar(require("./IdentityStoreIdEnum"), exports);
__exportStar(require("./ImportiDto"), exports);
__exportStar(require("./IndirizzoAnagrafeDto"), exports);
__exportStar(require("./IndirizzoDatiAnagraficiDto"), exports);
__exportStar(require("./IndirizzoDto"), exports);
__exportStar(require("./IndirizzoSportelloDto"), exports);
__exportStar(require("./InfoCCIAADto"), exports);
__exportStar(require("./InfoDto"), exports);
__exportStar(require("./InfoMyBankDto"), exports);
__exportStar(require("./InfoNascitaDto"), exports);
__exportStar(require("./InfoTitoloDto"), exports);
__exportStar(require("./InfoUtenteOutDto"), exports);
__exportStar(require("./InformazioniAutenticazioneDispositivaDto"), exports);
__exportStar(require("./InformazioniDto"), exports);
__exportStar(require("./InfotUtentereqInDto"), exports);
__exportStar(require("./InlineResponse2004Dto"), exports);
__exportStar(require("./IntestazioneDto"), exports);
__exportStar(require("./InvestimentiContoDto"), exports);
__exportStar(require("./InvestimentiContoSaldiDto"), exports);
__exportStar(require("./InviatoDto"), exports);
__exportStar(require("./JobActorAttributesValueDto"), exports);
__exportStar(require("./JobActorDto"), exports);
__exportStar(require("./JobAttributesDto"), exports);
__exportStar(require("./JobDocumentAttributesValueDto"), exports);
__exportStar(require("./JobDocumentDto"), exports);
__exportStar(require("./JobDocumentMetadataDto"), exports);
__exportStar(require("./JobDto"), exports);
__exportStar(require("./JobParametersDto"), exports);
__exportStar(require("./JwtReqOut"), exports);
__exportStar(require("./KeyValeDto"), exports);
__exportStar(require("./LetturaDatiAggiuntiviRichiestaDto"), exports);
__exportStar(require("./LibrettoDiRisparmioDto"), exports);
__exportStar(require("./LimitiOperativiDto"), exports);
__exportStar(require("./LineDataRowDto"), exports);
__exportStar(require("./LiquiditaContoDto"), exports);
__exportStar(require("./LiquiditaDto"), exports);
__exportStar(require("./LiquiditaElementoDto"), exports);
__exportStar(require("./ListaMovimentiDto"), exports);
__exportStar(require("./ListaMovimentiNexiDebit"), exports);
__exportStar(require("./ListaMovimentiNexiDto"), exports);
__exportStar(require("./ListaServiziOperativiOutputDto"), exports);
__exportStar(require("./ListiniTimeDepositDto"), exports);
__exportStar(require("./LoadIndiciDto"), exports);
__exportStar(require("./LogTimeDepositDto"), exports);
__exportStar(require("./LoggerDto"), exports);
__exportStar(require("./MandatiSddRevocaRequestIn"), exports);
__exportStar(require("./MandatoSDDDto"), exports);
__exportStar(require("./MandatoryActionTypeEnum"), exports);
__exportStar(require("./MavDto"), exports);
__exportStar(require("./MavRevocaRequestIn"), exports);
__exportStar(require("./MavsDto"), exports);
__exportStar(require("./MercatiDto"), exports);
__exportStar(require("./MercatoDto"), exports);
__exportStar(require("./MeseBilancioDto"), exports);
__exportStar(require("./MessaggiDto"), exports);
__exportStar(require("./MessaggioAllegatoDto"), exports);
__exportStar(require("./MessaggioBodyDto"), exports);
__exportStar(require("./MessaggioConvenzioneDto"), exports);
__exportStar(require("./MfUrlResponse"), exports);
__exportStar(require("./ModelError"), exports);
__exportStar(require("./ModelloF24Dto"), exports);
__exportStar(require("./ModelloF24RevocaRequestIn"), exports);
__exportStar(require("./ModificaAdeguataVerificaRequestDto"), exports);
__exportStar(require("./ModificaAliasReqDto"), exports);
__exportStar(require("./ModificaIbanInputDto"), exports);
__exportStar(require("./ModificaMassimaleSepaIstantInDto"), exports);
__exportStar(require("./ModificaMassimaleSepaIstantOutDto"), exports);
__exportStar(require("./ModificaMassimaliInputDto"), exports);
__exportStar(require("./ModificaPinTokenRispostaDto"), exports);
__exportStar(require("./ModificaPinTokenUtenteRichiestaDto"), exports);
__exportStar(require("./ModificaRequestDto"), exports);
__exportStar(require("./ModificaTokenPinDto"), exports);
__exportStar(require("./MotivazionePagamentoDto"), exports);
__exportStar(require("./MovimentiCartaRequestDto"), exports);
__exportStar(require("./MovimentoCartaDto"), exports);
__exportStar(require("./MovimentoCarteRicaricabileDto"), exports);
__exportStar(require("./MovimentoDto"), exports);
__exportStar(require("./MovimentoNexiDebitDto"), exports);
__exportStar(require("./NaturaGiuridicaDto"), exports);
__exportStar(require("./NegoziazioneContoProprioDto"), exports);
__exportStar(require("./NegoziazioneContoTerziDto"), exports);
__exportStar(require("./NeosurancePetBirthdayDto"), exports);
__exportStar(require("./NeosurancePoliciesOutDto"), exports);
__exportStar(require("./NewsDto"), exports);
__exportStar(require("./NexiServletDto"), exports);
__exportStar(require("./NotaStatusDto"), exports);
__exportStar(require("./NotificaDto"), exports);
__exportStar(require("./NotificaMenuDto"), exports);
__exportStar(require("./NotificaTributiDto"), exports);
__exportStar(require("./NumeroContattiDto"), exports);
__exportStar(require("./NumeroContattoDto"), exports);
__exportStar(require("./OauthAuthorizeIn"), exports);
__exportStar(require("./OnBoardOutDto"), exports);
__exportStar(require("./OnBoardSessionEntity"), exports);
__exportStar(require("./OperationLogDto"), exports);
__exportStar(require("./OperazioneDatoAggiuntivoDto"), exports);
__exportStar(require("./OperazioneOneClickDto"), exports);
__exportStar(require("./OperazioniOneClickDto"), exports);
__exportStar(require("./OperazioniOneClickInputDto"), exports);
__exportStar(require("./OperazioniPreferiteDto"), exports);
__exportStar(require("./OptionDto"), exports);
__exportStar(require("./OrdinanteDto"), exports);
__exportStar(require("./OrdineDto"), exports);
__exportStar(require("./OrdineParametriAvanzati"), exports);
__exportStar(require("./OrdineParametriAvanzatiIsinInDto"), exports);
__exportStar(require("./OrdineParametriDto"), exports);
__exportStar(require("./OrdineParametriMercatoDto"), exports);
__exportStar(require("./OrdineParametriQuotazioni"), exports);
__exportStar(require("./OrdineParametriSaldoConto"), exports);
__exportStar(require("./OrdineTradingDto"), exports);
__exportStar(require("./OrdiniInScadenzaFiltriInDto"), exports);
__exportStar(require("./OrdiniScadenzaDto"), exports);
__exportStar(require("./PFMCalendarPatternDto"), exports);
__exportStar(require("./PNSMessageBody"), exports);
__exportStar(require("./PaeseDto"), exports);
__exportStar(require("./PaeseSepaDto"), exports);
__exportStar(require("./PagamentoJiffyDto"), exports);
__exportStar(require("./PagamentoTributiDto"), exports);
__exportStar(require("./PagamentoTributiRichiestaDto"), exports);
__exportStar(require("./Paginazione"), exports);
__exportStar(require("./PanViewResponseDto"), exports);
__exportStar(require("./ParametriAcquistoNexiDebitResponseDto"), exports);
__exportStar(require("./ParametriAdeguataVerificaDto"), exports);
__exportStar(require("./ParametriAssegniDto"), exports);
__exportStar(require("./ParametriAssicurazioniDto"), exports);
__exportStar(require("./ParametriAuditingDto"), exports);
__exportStar(require("./ParametriBollettiniPostaliDto"), exports);
__exportStar(require("./ParametriBonificoDto"), exports);
__exportStar(require("./ParametriBonificoEsteroDto"), exports);
__exportStar(require("./ParametriBonificoRipetitivoDto"), exports);
__exportStar(require("./ParametriBozzeDto"), exports);
__exportStar(require("./ParametriCalendarioDto"), exports);
__exportStar(require("./ParametriCartaContoDto"), exports);
__exportStar(require("./ParametriContattiDto"), exports);
__exportStar(require("./ParametriCorrispondenzaDto"), exports);
__exportStar(require("./ParametriDocumentaleDto"), exports);
__exportStar(require("./ParametriDto"), exports);
__exportStar(require("./ParametriElencoPagamentiDto"), exports);
__exportStar(require("./ParametriFinanzaDto"), exports);
__exportStar(require("./ParametriFinanziamentiDto"), exports);
__exportStar(require("./ParametriFrecciaDto"), exports);
__exportStar(require("./ParametriLimitiOperativiDto"), exports);
__exportStar(require("./ParametriMandatiSDDDto"), exports);
__exportStar(require("./ParametriMavDto"), exports);
__exportStar(require("./ParametriMovimentiDto"), exports);
__exportStar(require("./ParametriMutuiDto"), exports);
__exportStar(require("./ParametriNexiDto"), exports);
__exportStar(require("./ParametriOneClickDto"), exports);
__exportStar(require("./ParametriPrestitiIsiDispoDto"), exports);
__exportStar(require("./ParametriProfiloDto"), exports);
__exportStar(require("./ParametriRavDto"), exports);
__exportStar(require("./ParametriRibaDto"), exports);
__exportStar(require("./ParametriRicaricaCarteDto"), exports);
__exportStar(require("./ParametriSudtirolPassDto"), exports);
__exportStar(require("./ParametriTimeDepositDto"), exports);
__exportStar(require("./PartitePrenotateDto"), exports);
__exportStar(require("./PatrimonioGestitoDto"), exports);
__exportStar(require("./PayloadDto"), exports);
__exportStar(require("./PctDisposizioneVirtualeDto"), exports);
__exportStar(require("./PctDto"), exports);
__exportStar(require("./PenaleDto"), exports);
__exportStar(require("./PerformanceRequestInDto"), exports);
__exportStar(require("./PfmTagsAndCategoriesDto"), exports);
__exportStar(require("./PhoneInfoQrcodeDto"), exports);
__exportStar(require("./PianoCedoleDto"), exports);
__exportStar(require("./PieBarDataDto"), exports);
__exportStar(require("./PieBarRow"), exports);
__exportStar(require("./PinErrore"), exports);
__exportStar(require("./PinViewResponseDto"), exports);
__exportStar(require("./PosizioneAssicurazioniDto"), exports);
__exportStar(require("./PosizioneDisponibilitaDto"), exports);
__exportStar(require("./PosizioneFinanziamentiDto"), exports);
__exportStar(require("./PosizioneGestionePatrimoniali"), exports);
__exportStar(require("./PosizioneGlobaleDto"), exports);
__exportStar(require("./PosizioneInvestimentiDto"), exports);
__exportStar(require("./PosizioneLiquiditaDto"), exports);
__exportStar(require("./PosizioneTitoliDto"), exports);
__exportStar(require("./PosizioneTitoliPerAnnoDto"), exports);
__exportStar(require("./PreAccountLevelBODto"), exports);
__exportStar(require("./PreCustomerLevelBODto"), exports);
__exportStar(require("./PreCustomersAggregationLevelBODto"), exports);
__exportStar(require("./PrePositionLevelBODto"), exports);
__exportStar(require("./PrePositionMovementBODto"), exports);
__exportStar(require("./PreferenzeAnatocismoDto"), exports);
__exportStar(require("./PreferenzeUtenteDto"), exports);
__exportStar(require("./PrestitoDto"), exports);
__exportStar(require("./ProdottiTopDto"), exports);
__exportStar(require("./ProdottoDto"), exports);
__exportStar(require("./ProductGovernanceDto"), exports);
__exportStar(require("./ProfiloAbilitazioneDto"), exports);
__exportStar(require("./ProfiloAnagrafeDto"), exports);
__exportStar(require("./ProfiloDatiPersonaliDto"), exports);
__exportStar(require("./ProfiloDatiUtenteDto"), exports);
__exportStar(require("./ProfiloDto"), exports);
__exportStar(require("./ProfiloImmaginePersonaleDto"), exports);
__exportStar(require("./ProfiloMenu"), exports);
__exportStar(require("./ProfiloMenuDto"), exports);
__exportStar(require("./ProfiloRapportoDto"), exports);
__exportStar(require("./ProvenienzaFondiDto"), exports);
__exportStar(require("./ProvenienzaFondiReqDto"), exports);
__exportStar(require("./ProvinceDto"), exports);
__exportStar(require("./Psd2PaymentDto"), exports);
__exportStar(require("./QuestionDto"), exports);
__exportStar(require("./QuestionOptionDto"), exports);
__exportStar(require("./QuestionnaireAnswersDto"), exports);
__exportStar(require("./QuestionnaireReportInDto"), exports);
__exportStar(require("./QuestionnaireSaveBodyDto"), exports);
__exportStar(require("./QuietanzaDto"), exports);
__exportStar(require("./QuotazioneCambioDto"), exports);
__exportStar(require("./QuotazioneDettaglioCambioDto"), exports);
__exportStar(require("./QuotazioneDettaglioIndiceDto"), exports);
__exportStar(require("./QuotazioneDettaglioTitoloDto"), exports);
__exportStar(require("./QuotazioneDto"), exports);
__exportStar(require("./QuotazioneIndiceDto"), exports);
__exportStar(require("./QuotazioneTitolo"), exports);
__exportStar(require("./QuotazioneTitoloDto"), exports);
__exportStar(require("./QuotazioniCambiDto"), exports);
__exportStar(require("./QuotazioniDto"), exports);
__exportStar(require("./QuotazioniFiltriInDto"), exports);
__exportStar(require("./QuotazioniIndiciDto"), exports);
__exportStar(require("./QuotazioniSortDto"), exports);
__exportStar(require("./QuotazioniTitoliDto"), exports);
__exportStar(require("./RaccomandazioneDto"), exports);
__exportStar(require("./RangeImporti"), exports);
__exportStar(require("./RangeTassiDto"), exports);
__exportStar(require("./RapportoADVDto"), exports);
__exportStar(require("./RapportoCartaNexiDto"), exports);
__exportStar(require("./RapportoCarteDto"), exports);
__exportStar(require("./RapportoCategoriaAttributiPsd2"), exports);
__exportStar(require("./RapportoCategoriaDto"), exports);
__exportStar(require("./RapportoCompletoDto"), exports);
__exportStar(require("./RapportoContoCorrenteDto"), exports);
__exportStar(require("./RapportoDto"), exports);
__exportStar(require("./RapportoGirofondoDto"), exports);
__exportStar(require("./RapportoLightDto"), exports);
__exportStar(require("./RapportoPerServizioDto"), exports);
__exportStar(require("./RataDto"), exports);
__exportStar(require("./RataFinanziamentoDto"), exports);
__exportStar(require("./RateFinanziamentoDto"), exports);
__exportStar(require("./RavDto"), exports);
__exportStar(require("./RavRevocaRequestInDto"), exports);
__exportStar(require("./RavsDto"), exports);
__exportStar(require("./RecuperaImpostazioniDto"), exports);
__exportStar(require("./RefreshTokenIn"), exports);
__exportStar(require("./RemoveContattoReqInDto"), exports);
__exportStar(require("./RequestDeleghe"), exports);
__exportStar(require("./RequestSP"), exports);
__exportStar(require("./RevocaOrdineTradingDto"), exports);
__exportStar(require("./RevokeConsentRequest"), exports);
__exportStar(require("./RibaDto"), exports);
__exportStar(require("./RibaRequestInDto"), exports);
__exportStar(require("./RicaricaCarteDto"), exports);
__exportStar(require("./RicaricaCellulareDto"), exports);
__exportStar(require("./RicaricaSudtirolPassDto"), exports);
__exportStar(require("./RicaricheCarteDto"), exports);
__exportStar(require("./RicercaAppuntamentoIn"), exports);
__exportStar(require("./RicercaAtmFiltriInDto"), exports);
__exportStar(require("./RicercaTitoliInDto"), exports);
__exportStar(require("./RichiestaDto"), exports);
__exportStar(require("./RiepilogoPatrimonioDisponibilitaDto"), exports);
__exportStar(require("./RiepilogoPatrimonioDto"), exports);
__exportStar(require("./RigaSezioneAcciseDto"), exports);
__exportStar(require("./RigaSezioneEntiPrevidenzialiDto"), exports);
__exportStar(require("./RigaSezioneErarioDto"), exports);
__exportStar(require("./RigaSezioneIdentificativiDto"), exports);
__exportStar(require("./RigaSezioneInailDto"), exports);
__exportStar(require("./RigaSezioneInpsDto"), exports);
__exportStar(require("./RigaSezioneRegioniDto"), exports);
__exportStar(require("./RigaSezioneSemplificatoDto"), exports);
__exportStar(require("./RigaSezioneTributiLocaliDto"), exports);
__exportStar(require("./RipetitiviDto"), exports);
__exportStar(require("./RipetitivoDto"), exports);
__exportStar(require("./RipetitivoRevocaRequestIn"), exports);
__exportStar(require("./RipetitivoRiattivaRequestIn"), exports);
__exportStar(require("./RipetitivoSospendiRequestIn"), exports);
__exportStar(require("./RiskProfileDto"), exports);
__exportStar(require("./RiskProfileResultDto"), exports);
__exportStar(require("./RispostaAutorizzazioneAnatocismoDto"), exports);
__exportStar(require("./SaldoCartaDto"), exports);
__exportStar(require("./SaldoCartaNexiDto"), exports);
__exportStar(require("./SaldoDto"), exports);
__exportStar(require("./SaldoRapportoDto"), exports);
__exportStar(require("./SaveQuestionnaireAnswersInDto"), exports);
__exportStar(require("./ScadenziereUnicoDto"), exports);
__exportStar(require("./SchedaCambioDto"), exports);
__exportStar(require("./SchedaStrumentoItemDto"), exports);
__exportStar(require("./SchedaTitoloDto"), exports);
__exportStar(require("./SchedaTitoloTrading2Dto"), exports);
__exportStar(require("./ScopeListDto"), exports);
__exportStar(require("./SearchBicResponse"), exports);
__exportStar(require("./SectionDto"), exports);
__exportStar(require("./SelfResetBranchRequestDto"), exports);
__exportStar(require("./SelfResetStatusRequestDto"), exports);
__exportStar(require("./SelfResetStatusResponseDto"), exports);
__exportStar(require("./SelfResetUpdateStatoRequestDto"), exports);
__exportStar(require("./SendNotificationInDto"), exports);
__exportStar(require("./SendNotificationOutDto"), exports);
__exportStar(require("./SendRequest"), exports);
__exportStar(require("./ServizioDto"), exports);
__exportStar(require("./ServizioOperativoOutputDto"), exports);
__exportStar(require("./SessionReqIn"), exports);
__exportStar(require("./SessioniDiFirmaDto"), exports);
__exportStar(require("./SezioneAcciseDto"), exports);
__exportStar(require("./SezioneEntiPrevidenzialiDto"), exports);
__exportStar(require("./SezioneErarioDto"), exports);
__exportStar(require("./SezioneIdentificativiDto"), exports);
__exportStar(require("./SezioneInailDto"), exports);
__exportStar(require("./SezioneInpsDto"), exports);
__exportStar(require("./SezioneRegioniDto"), exports);
__exportStar(require("./SezioneSemplificatoDto"), exports);
__exportStar(require("./SezioneTributiLocaliDto"), exports);
__exportStar(require("./SmsAlertDto"), exports);
__exportStar(require("./SogliaDaRecuperareDto"), exports);
__exportStar(require("./SogliaNotificheDto"), exports);
__exportStar(require("./SoglieInDto"), exports);
__exportStar(require("./SortItemDto"), exports);
__exportStar(require("./SpesaBOEDto"), exports);
__exportStar(require("./StartPanViewSCARequestDto"), exports);
__exportStar(require("./StartPanViewSCAResponseDto"), exports);
__exportStar(require("./StartPinViewSCARequestDto"), exports);
__exportStar(require("./StartPinViewSCAResponseDto"), exports);
__exportStar(require("./StatoModificaMassimaleOutDto"), exports);
__exportStar(require("./StatusElementoDto"), exports);
__exportStar(require("./StrongAuthInfoDto"), exports);
__exportStar(require("./StrongAuthInfoPhoneInfoDto"), exports);
__exportStar(require("./StrongAuthInfoTokenInfoDto"), exports);
__exportStar(require("./StrongAuthInitRequestDto"), exports);
__exportStar(require("./StrongAuthInitRequestOptionsDto"), exports);
__exportStar(require("./StrongAuthTypeEnum"), exports);
__exportStar(require("./StrongAuthVerifyRequestDto"), exports);
__exportStar(require("./SubcategoryDto"), exports);
__exportStar(require("./SubscriptionOk"), exports);
__exportStar(require("./SudtirolPassRicaricheFiltriInDto"), exports);
__exportStar(require("./TabellaCostiDto"), exports);
__exportStar(require("./TabellaCostiPctDto"), exports);
__exportStar(require("./TabellaDettaglioCostiDto"), exports);
__exportStar(require("./TagDto"), exports);
__exportStar(require("./TagliRicaricaCellulareDto"), exports);
__exportStar(require("./TaglioRicaricaCellulareDto"), exports);
__exportStar(require("./TaglioRicaricaDto"), exports);
__exportStar(require("./TassoInteressiDto"), exports);
__exportStar(require("./TelefonoDatiAnagraficiDto"), exports);
__exportStar(require("./TelefonoFissoDto"), exports);
__exportStar(require("./TemplateDto"), exports);
__exportStar(require("./TestataDto"), exports);
__exportStar(require("./TimeDepositDto"), exports);
__exportStar(require("./TimeDepositElencoDto"), exports);
__exportStar(require("./TitoliFondiDto"), exports);
__exportStar(require("./TitoliFondiElementoDto"), exports);
__exportStar(require("./TitoliInScadenzaFiltriInDto"), exports);
__exportStar(require("./TitoliScadenzaDto"), exports);
__exportStar(require("./TitoloScadenzaDto"), exports);
__exportStar(require("./TokenInfoQrcodeDto"), exports);
__exportStar(require("./TokenSoftwareActivationRequestDto"), exports);
__exportStar(require("./TokenSoftwareActivationResponseDto"), exports);
__exportStar(require("./TokenSoftwareActivationResponseQrCodeDto"), exports);
__exportStar(require("./TourDto"), exports);
__exportStar(require("./TransactionOutputDto"), exports);
__exportStar(require("./TransactionStartInputDto"), exports);
__exportStar(require("./TributiDto"), exports);
__exportStar(require("./UltimiAccessiDto"), exports);
__exportStar(require("./UltimoAccessoInDto"), exports);
__exportStar(require("./UltimoAccessoOutDto"), exports);
__exportStar(require("./UtenzaDto"), exports);
__exportStar(require("./VariazioneNexiRequestDto"), exports);
__exportStar(require("./VerificaRicaricaRequestDto"), exports);
__exportStar(require("./VerificaStatoUtenteDto"), exports);
__exportStar(require("./ViaDatiAnagraficiDto"), exports);
__exportStar(require("./VoceCategoriaDto"), exports);
__exportStar(require("./WatchlistDto"), exports);
__exportStar(require("./WatchlistItemInDto"), exports);
__exportStar(require("./WatchlistQuotazioneDto"), exports);
__exportStar(require("./WatchlistQuotazioniDto"), exports);
__exportStar(require("./WatchlistStaticaDto"), exports);
__exportStar(require("./WatchlistStaticaItemDto"), exports);
__exportStar(require("./WebhookApriPraticaInDto"), exports);
__exportStar(require("./WebhookApriPraticaOutDto"), exports);
__exportStar(require("./WidgetAssetDebtChartDto"), exports);
__exportStar(require("./WidgetAssetDebtDataDto"), exports);
__exportStar(require("./WidgetAssetDebtDto"), exports);
__exportStar(require("./WidgetAssetDebtTypeDto"), exports);
__exportStar(require("./WidgetBeniDebitiDto"), exports);
__exportStar(require("./WidgetDto"), exports);
__exportStar(require("./WidgetWrapDto"), exports);
__exportStar(require("./WidgetsDto"), exports);
