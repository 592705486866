"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateNecessarioOnboarding = exports.esitoUpdateOnboarding = exports.esitoCheckOnboarding = exports.esitoInviaNotificaPushOnboarding = void 0;
const reselect_1 = require("reselect");
const onboardingReducer = (state) => state;
exports.esitoInviaNotificaPushOnboarding = (0, reselect_1.createSelector)(onboardingReducer, (state) => {
    return state.esitoInviaNotificaPush;
});
exports.esitoCheckOnboarding = (0, reselect_1.createSelector)(onboardingReducer, (state) => {
    return state.esitoCheck;
});
exports.esitoUpdateOnboarding = (0, reselect_1.createSelector)(onboardingReducer, (state) => {
    return state.esitoUpdate;
});
exports.updateNecessarioOnboarding = (0, reselect_1.createSelector)(onboardingReducer, (state) => {
    return state.updateNecessario;
});
