"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BonificoControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class BonificoControllerApi extends runtime.BaseAPI {
    /**
     * Autorizzazione di una disposizione di bonifico
     */
    autorizzaBonificoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.funzioneDispositiva === null || requestParameters.funzioneDispositiva === undefined) {
                throw new runtime.RequiredError('funzioneDispositiva', 'Required parameter requestParameters.funzioneDispositiva was null or undefined when calling autorizzaBonifico.');
            }
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling autorizzaBonifico.');
            }
            if (requestParameters.credenzialeDispositivaDto === null || requestParameters.credenzialeDispositivaDto === undefined) {
                throw new runtime.RequiredError('credenzialeDispositivaDto', 'Required parameter requestParameters.credenzialeDispositivaDto was null or undefined when calling autorizzaBonifico.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/bonifico/{funzioneDispositiva}/autorizza/{idDispositiva}`.replace(`{${"funzioneDispositiva"}}`, encodeURIComponent(String(requestParameters.funzioneDispositiva))).replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CredenzialeDispositivaDtoToJSON(requestParameters.credenzialeDispositivaDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.EsitoAutorizzazioneDisposizioneBonificoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Autorizzazione di una disposizione di bonifico
     */
    autorizzaBonifico(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.autorizzaBonificoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce una bozza dato l\'id per bonifico Sepa
     */
    bozzaBonificoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.funzioneDispositiva === null || requestParameters.funzioneDispositiva === undefined) {
                throw new runtime.RequiredError('funzioneDispositiva', 'Required parameter requestParameters.funzioneDispositiva was null or undefined when calling bozzaBonifico.');
            }
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling bozzaBonifico.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/bonifico/{funzioneDispositiva}/bozze/{id}`.replace(`{${"funzioneDispositiva"}}`, encodeURIComponent(String(requestParameters.funzioneDispositiva))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.BonificoSepaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce una bozza dato l\'id per bonifico Sepa
     */
    bozzaBonifico(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.bozzaBonificoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce le bozze per bonifico Sepa
     */
    bozzeBonificoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.funzioneDispositiva === null || requestParameters.funzioneDispositiva === undefined) {
                throw new runtime.RequiredError('funzioneDispositiva', 'Required parameter requestParameters.funzioneDispositiva was null or undefined when calling bozzeBonifico.');
            }
            if (requestParameters.bozzeInDto === null || requestParameters.bozzeInDto === undefined) {
                throw new runtime.RequiredError('bozzeInDto', 'Required parameter requestParameters.bozzeInDto was null or undefined when calling bozzeBonifico.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/bonifico/{funzioneDispositiva}/bozze`.replace(`{${"funzioneDispositiva"}}`, encodeURIComponent(String(requestParameters.funzioneDispositiva))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.BozzeInDtoToJSON(requestParameters.bozzeInDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.BonificiSepaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce le bozze per bonifico Sepa
     */
    bozzeBonifico(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.bozzeBonificoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Esegue il controllo di validità di un nuovo bonifico sepa in base ai valori specificati nell\'oggetto di richiesta
     */
    controlloBonificoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.funzioneDispositiva === null || requestParameters.funzioneDispositiva === undefined) {
                throw new runtime.RequiredError('funzioneDispositiva', 'Required parameter requestParameters.funzioneDispositiva was null or undefined when calling controlloBonifico.');
            }
            if (requestParameters.bonificoSepaDto === null || requestParameters.bonificoSepaDto === undefined) {
                throw new runtime.RequiredError('bonificoSepaDto', 'Required parameter requestParameters.bonificoSepaDto was null or undefined when calling controlloBonifico.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/bonifico/{funzioneDispositiva}/controllo`.replace(`{${"funzioneDispositiva"}}`, encodeURIComponent(String(requestParameters.funzioneDispositiva))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.BonificoSepaDtoToJSON(requestParameters.bonificoSepaDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.BonificoSepaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Esegue il controllo di validità di un nuovo bonifico sepa in base ai valori specificati nell\'oggetto di richiesta
     */
    controlloBonifico(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.controlloBonificoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Eliminazione di una bozza dato l\'id
     */
    eliminaBozzaBonificoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling eliminaBozzaBonifico.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/bonifico/bozze/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Eliminazione di una bozza dato l\'id
     */
    eliminaBozzaBonifico(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.eliminaBozzaBonificoRaw(requestParameters);
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva
     */
    informazioniAutenticazioneBonificoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.funzioneDispositiva === null || requestParameters.funzioneDispositiva === undefined) {
                throw new runtime.RequiredError('funzioneDispositiva', 'Required parameter requestParameters.funzioneDispositiva was null or undefined when calling informazioniAutenticazioneBonifico.');
            }
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling informazioniAutenticazioneBonifico.');
            }
            const queryParameters = {};
            if (requestParameters.chiamataUscente !== undefined) {
                queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
            }
            if (requestParameters.eseguiInit !== undefined) {
                queryParameters['eseguiInit'] = requestParameters.eseguiInit;
            }
            if (requestParameters.offline !== undefined) {
                queryParameters['offline'] = requestParameters.offline;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/bonifico/{funzioneDispositiva}/infoautenticazione/{idDispositiva}`.replace(`{${"funzioneDispositiva"}}`, encodeURIComponent(String(requestParameters.funzioneDispositiva))).replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva
     */
    informazioniAutenticazioneBonifico(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.informazioniAutenticazioneBonificoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva per la revoca
     */
    informazioniAutenticazioneRevocaRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.funzioneDispositiva === null || requestParameters.funzioneDispositiva === undefined) {
                throw new runtime.RequiredError('funzioneDispositiva', 'Required parameter requestParameters.funzioneDispositiva was null or undefined when calling informazioniAutenticazioneRevoca.');
            }
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling informazioniAutenticazioneRevoca.');
            }
            const queryParameters = {};
            if (requestParameters.chiamataUscente !== undefined) {
                queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
            }
            if (requestParameters.eseguiInit !== undefined) {
                queryParameters['eseguiInit'] = requestParameters.eseguiInit;
            }
            if (requestParameters.offline !== undefined) {
                queryParameters['offline'] = requestParameters.offline;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/bonifico/{funzioneDispositiva}/infoautenticazione/{idDispositiva}/revoca`.replace(`{${"funzioneDispositiva"}}`, encodeURIComponent(String(requestParameters.funzioneDispositiva))).replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva per la revoca
     */
    informazioniAutenticazioneRevoca(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.informazioniAutenticazioneRevocaRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Autorizzazione di una disposizione di revoca bonifico
     */
    operazioneAutenticataBonificoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.funzioneDispositiva === null || requestParameters.funzioneDispositiva === undefined) {
                throw new runtime.RequiredError('funzioneDispositiva', 'Required parameter requestParameters.funzioneDispositiva was null or undefined when calling operazioneAutenticataBonifico.');
            }
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling operazioneAutenticataBonifico.');
            }
            if (requestParameters.credenzialeDispositivaDto === null || requestParameters.credenzialeDispositivaDto === undefined) {
                throw new runtime.RequiredError('credenzialeDispositivaDto', 'Required parameter requestParameters.credenzialeDispositivaDto was null or undefined when calling operazioneAutenticataBonifico.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/bonifico/{funzioneDispositiva}/autorizza/{idDispositiva}/revoca`.replace(`{${"funzioneDispositiva"}}`, encodeURIComponent(String(requestParameters.funzioneDispositiva))).replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CredenzialeDispositivaDtoToJSON(requestParameters.credenzialeDispositivaDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.EsitoAutorizzazioneDisposizioneBonificoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Autorizzazione di una disposizione di revoca bonifico
     */
    operazioneAutenticataBonifico(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.operazioneAutenticataBonificoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce le operazioni per bonifico Sepa
     */
    operazioniOneClickBonificoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.funzioneDispositiva === null || requestParameters.funzioneDispositiva === undefined) {
                throw new runtime.RequiredError('funzioneDispositiva', 'Required parameter requestParameters.funzioneDispositiva was null or undefined when calling operazioniOneClickBonifico.');
            }
            if (requestParameters.operazioniOneClickInputDto === null || requestParameters.operazioniOneClickInputDto === undefined) {
                throw new runtime.RequiredError('operazioniOneClickInputDto', 'Required parameter requestParameters.operazioniOneClickInputDto was null or undefined when calling operazioniOneClickBonifico.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/bonifico/{funzioneDispositiva}/oneclick`.replace(`{${"funzioneDispositiva"}}`, encodeURIComponent(String(requestParameters.funzioneDispositiva))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.OperazioniOneClickInputDtoToJSON(requestParameters.operazioniOneClickInputDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.OperazioniOneClickDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce le operazioni per bonifico Sepa
     */
    operazioniOneClickBonifico(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.operazioniOneClickBonificoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Lettura parametri bonifici SEPA
     */
    parametriBonificoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.psd2in !== undefined) {
                queryParameters['psd2in'] = requestParameters.psd2in;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/bonifico/parametri`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ParametriBonificoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Lettura parametri bonifici SEPA
     */
    parametriBonifico(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.parametriBonificoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Salva una bozza per bonifico Sepa
     */
    salvaBozzaBonificoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.funzioneDispositiva === null || requestParameters.funzioneDispositiva === undefined) {
                throw new runtime.RequiredError('funzioneDispositiva', 'Required parameter requestParameters.funzioneDispositiva was null or undefined when calling salvaBozzaBonifico.');
            }
            if (requestParameters.bonificoSepaDto === null || requestParameters.bonificoSepaDto === undefined) {
                throw new runtime.RequiredError('bonificoSepaDto', 'Required parameter requestParameters.bonificoSepaDto was null or undefined when calling salvaBozzaBonifico.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/bonifico/bozze/{funzioneDispositiva}/salvabozza`.replace(`{${"funzioneDispositiva"}}`, encodeURIComponent(String(requestParameters.funzioneDispositiva))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.BonificoSepaDtoToJSON(requestParameters.bonificoSepaDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.BonificoSepaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Salva una bozza per bonifico Sepa
     */
    salvaBozzaBonifico(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.salvaBozzaBonificoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Modifica di una bozza dato l\'id per bonifico Sepa
     */
    updateBozzaBonificoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.funzioneDispositiva === null || requestParameters.funzioneDispositiva === undefined) {
                throw new runtime.RequiredError('funzioneDispositiva', 'Required parameter requestParameters.funzioneDispositiva was null or undefined when calling updateBozzaBonifico.');
            }
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling updateBozzaBonifico.');
            }
            if (requestParameters.bonificoSepaDto === null || requestParameters.bonificoSepaDto === undefined) {
                throw new runtime.RequiredError('bonificoSepaDto', 'Required parameter requestParameters.bonificoSepaDto was null or undefined when calling updateBozzaBonifico.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/bonifico/{funzioneDispositiva}/bozze/{id}`.replace(`{${"funzioneDispositiva"}}`, encodeURIComponent(String(requestParameters.funzioneDispositiva))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.BonificoSepaDtoToJSON(requestParameters.bonificoSepaDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.BonificoSepaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Modifica di una bozza dato l\'id per bonifico Sepa
     */
    updateBozzaBonifico(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateBozzaBonificoRaw(requestParameters);
            return yield response.value();
        });
    }
}
exports.BonificoControllerApi = BonificoControllerApi;
