"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fondiPaperlessReducer = void 0;
const __1 = require("..");
const initialState = {
    form: {
        step: 1,
        corrispondenze: []
    },
    condizioni: undefined,
    corrispondenze: undefined,
    esito: undefined
};
const fondiPaperlessReducer = (state = initialState, action) => {
    switch (action.type) {
        case __1.EReduxActionTypes.FONDI_PAPERLESS_SET_FORM_CONDIZIONI:
            return {
                ...state,
                form: {
                    ...state.form,
                    corrispondenze: action.payload
                }
            };
        case __1.EReduxActionTypes.FONDI_PAPERLESS_GET_CONDIZIONI_FONDI_SUCCESS:
            return {
                ...state,
                condizioni: action.payload
            };
        case __1.EReduxActionTypes.FONDI_PAPERLESS_GET_CORRISPONDENZE_FONDI_SUCCESS:
            return {
                ...state,
                corrispondenze: action.payload,
                form: {
                    ...state.form,
                    step: 1,
                    corrispondenze: action.payload
                },
                esito: initialState.esito
            };
        case __1.EReduxActionTypes.FONDI_PAPERLESS_SALVA_CORRISPONDENZE_FONDI_SUCCESS:
            return {
                ...state,
                esito: action.payload,
                form: {
                    ...state.form,
                    step: 2,
                }
            };
        case __1.EReduxActionTypes.FONDI_PAPERLESS_RESET:
            return {
                ...initialState
            };
        case __1.EReduxActionTypes.RESET_ALL: // action invocata dalla logout
            return {
                ...initialState
            };
        default:
            return state;
    }
};
exports.fondiPaperlessReducer = fondiPaperlessReducer;
exports.default = exports.fondiPaperlessReducer;
