"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenSoftwareActivationRequestDtoToJSON = exports.TokenSoftwareActivationRequestDtoFromJSONTyped = exports.TokenSoftwareActivationRequestDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function TokenSoftwareActivationRequestDtoFromJSON(json) {
    return TokenSoftwareActivationRequestDtoFromJSONTyped(json, false);
}
exports.TokenSoftwareActivationRequestDtoFromJSON = TokenSoftwareActivationRequestDtoFromJSON;
function TokenSoftwareActivationRequestDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'activationCode': !runtime_1.exists(json, 'activationCode') ? undefined : json['activationCode'],
        'appCode': !runtime_1.exists(json, 'appCode') ? undefined : json['appCode'],
        'pin': !runtime_1.exists(json, 'pin') ? undefined : json['pin'],
    };
}
exports.TokenSoftwareActivationRequestDtoFromJSONTyped = TokenSoftwareActivationRequestDtoFromJSONTyped;
function TokenSoftwareActivationRequestDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'activationCode': value.activationCode,
        'appCode': value.appCode,
        'pin': value.pin,
    };
}
exports.TokenSoftwareActivationRequestDtoToJSON = TokenSoftwareActivationRequestDtoToJSON;
