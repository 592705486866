"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ElencoPagamentiPdfTypeEnum = exports.ElencoPagamentiControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class ElencoPagamentiControllerApi extends runtime.BaseAPI {
    /**
     * Export in pdf del dettaglio del pagamento in base ai criteri inviati per il rapporto indicato
     */
    dettaglioPagamentiPdfRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.datiPagamentoDto === null || requestParameters.datiPagamentoDto === undefined) {
                throw new runtime.RequiredError('datiPagamentoDto', 'Required parameter requestParameters.datiPagamentoDto was null or undefined when calling dettaglioPagamentiPdf.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/elencopagamenti/dettaglio/export/pdf`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.DatiPagamentoDtoToJSON(requestParameters.datiPagamentoDto),
            });
            return new runtime.BlobApiResponse(response);
        });
    }
    /**
     * Export in pdf del dettaglio del pagamento in base ai criteri inviati per il rapporto indicato
     */
    dettaglioPagamentiPdf(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.dettaglioPagamentiPdfRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Dettaglio di un pagamento
     */
    dettaglioPagamentoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.datiPagamentoDto === null || requestParameters.datiPagamentoDto === undefined) {
                throw new runtime.RequiredError('datiPagamentoDto', 'Required parameter requestParameters.datiPagamentoDto was null or undefined when calling dettaglioPagamento.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/elencopagamenti/dettaglio`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.DatiPagamentoDtoToJSON(requestParameters.datiPagamentoDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.DettaglioPagamentoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Dettaglio di un pagamento
     */
    dettaglioPagamento(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.dettaglioPagamentoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Elenco di pagamenti filtrati
     */
    elencoPagamentiRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.filtroListaPagamentiDto === null || requestParameters.filtroListaPagamentiDto === undefined) {
                throw new runtime.RequiredError('filtroListaPagamentiDto', 'Required parameter requestParameters.filtroListaPagamentiDto was null or undefined when calling elencoPagamenti.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/elencopagamenti`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.FiltroListaPagamentiDtoToJSON(requestParameters.filtroListaPagamentiDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.DatiPagamentiDtoFromJSON(jsonValue));
        });
    }
    /**
     * Elenco di pagamenti filtrati
     */
    elencoPagamenti(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.elencoPagamentiRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Export in pdf o excel dei movimenti in base ai criteri inviati per il rapporto indicato
     */
    elencoPagamentiPdfRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.type === null || requestParameters.type === undefined) {
                throw new runtime.RequiredError('type', 'Required parameter requestParameters.type was null or undefined when calling elencoPagamentiPdf.');
            }
            if (requestParameters.filtroListaPagamentiDto === null || requestParameters.filtroListaPagamentiDto === undefined) {
                throw new runtime.RequiredError('filtroListaPagamentiDto', 'Required parameter requestParameters.filtroListaPagamentiDto was null or undefined when calling elencoPagamentiPdf.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/elencopagamenti/export/{type}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.FiltroListaPagamentiDtoToJSON(requestParameters.filtroListaPagamentiDto),
            });
            return new runtime.BlobApiResponse(response);
        });
    }
    /**
     * Export in pdf o excel dei movimenti in base ai criteri inviati per il rapporto indicato
     */
    elencoPagamentiPdf(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.elencoPagamentiPdfRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Lettura parametri per i pagamenti
     */
    parametriPagamentiRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/elencopagamenti/parametri`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ParametriElencoPagamentiDtoFromJSON(jsonValue));
        });
    }
    /**
     * Lettura parametri per i pagamenti
     */
    parametriPagamenti() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.parametriPagamentiRaw();
            return yield response.value();
        });
    }
}
exports.ElencoPagamentiControllerApi = ElencoPagamentiControllerApi;
/**
    * @export
    * @enum {string}
    */
var ElencoPagamentiPdfTypeEnum;
(function (ElencoPagamentiPdfTypeEnum) {
    ElencoPagamentiPdfTypeEnum["pdf"] = "pdf";
    ElencoPagamentiPdfTypeEnum["excel"] = "excel";
})(ElencoPagamentiPdfTypeEnum = exports.ElencoPagamentiPdfTypeEnum || (exports.ElencoPagamentiPdfTypeEnum = {}));
