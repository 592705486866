"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DettaglioRequestToJSON = exports.DettaglioRequestFromJSONTyped = exports.DettaglioRequestFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function DettaglioRequestFromJSON(json) {
    return DettaglioRequestFromJSONTyped(json, false);
}
exports.DettaglioRequestFromJSON = DettaglioRequestFromJSON;
function DettaglioRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'campiF24SP': !runtime_1.exists(json, 'campiF24SP') ? undefined : _1.RequestSPFromJSON(json['campiF24SP']),
        'campiF24Deleghe': !runtime_1.exists(json, 'campiF24Deleghe') ? undefined : _1.RequestDelegheFromJSON(json['campiF24Deleghe']),
    };
}
exports.DettaglioRequestFromJSONTyped = DettaglioRequestFromJSONTyped;
function DettaglioRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'campiF24SP': _1.RequestSPToJSON(value.campiF24SP),
        'campiF24Deleghe': _1.RequestDelegheToJSON(value.campiF24Deleghe),
    };
}
exports.DettaglioRequestToJSON = DettaglioRequestToJSON;
