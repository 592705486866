"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParametriContattiDtoToJSON = exports.ParametriContattiDtoFromJSONTyped = exports.ParametriContattiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ParametriContattiDtoFromJSON(json) {
    return ParametriContattiDtoFromJSONTyped(json, false);
}
exports.ParametriContattiDtoFromJSON = ParametriContattiDtoFromJSON;
function ParametriContattiDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'province': !runtime_1.exists(json, 'province') ? undefined : (json['province'].map(_1.ProvinceDtoFromJSON)),
        'parametri': !runtime_1.exists(json, 'parametri') ? undefined : json['parametri'],
    };
}
exports.ParametriContattiDtoFromJSONTyped = ParametriContattiDtoFromJSONTyped;
function ParametriContattiDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'province': value.province === undefined ? undefined : (value.province.map(_1.ProvinceDtoToJSON)),
        'parametri': value.parametri,
    };
}
exports.ParametriContattiDtoToJSON = ParametriContattiDtoToJSON;
