"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.polizzeReducer = void 0;
const __1 = require("..");
const api_rest_1 = require("@sparkasse/api-rest");
const initialState = {
    parametri: (0, api_rest_1.ParametriAssicurazioniDtoFromJSON)({}),
    dettaglio: (0, api_rest_1.AssicurazioneDtoFromJSON)({}),
    form: {
        rapportoSelezionato: (0, api_rest_1.RapportoCompletoDtoFromJSON)({}),
        polizzaSelezionata: (0, api_rest_1.AssicurazioneDtoFromJSON)({})
    },
    listaPolizzeAssicurazione: [],
    listaPolizzeInvestimento: []
};
const polizzeReducer = (state = initialState, action) => {
    switch (action.type) {
        case __1.EReduxActionTypes.POLIZZE_SET_RAPPORTO_SELEZIONATO:
            return {
                ...state,
                form: {
                    ...state.form,
                    rapportoSelezionato: action.payload
                }
            };
        case __1.EReduxActionTypes.POLIZZE_GET_PARAMETRI_SUCCESS:
            return {
                ...state,
                parametri: action.payload
            };
        case __1.EReduxActionTypes.POLIZZE_GET_DETTAGLIO_SUCCESS:
            return {
                ...state,
                dettaglio: action.payload
            };
        case __1.EReduxActionTypes.POLIZZE_GET_POLIZZE_ASSICURAZIONE_SUCCESS:
            return {
                ...state,
                listaPolizzeAssicurazione: action.payload
            };
        case __1.EReduxActionTypes.POLIZZE_GET_POLIZZE_INVESTIMENTO_SUCCESS:
            return {
                ...state,
                listaPolizzeInvestimento: action.payload
            };
        case __1.EReduxActionTypes.POLIZZE_SET_POLIZZA_SELEZIONATA:
            return {
                ...state,
                form: {
                    ...state.form,
                    polizzaSelezionata: action.payload
                }
            };
        case __1.EReduxActionTypes.POLIZZE_RESET_POLIZZA_SELEZIONATA:
            return {
                ...state,
                form: {
                    ...state.form,
                    polizzaSelezionata: initialState.form.polizzaSelezionata
                }
            };
        case __1.EReduxActionTypes.RESET_ALL: // action invocata dalla logout
            return {
                ...initialState
            };
        default:
            return state;
    }
};
exports.polizzeReducer = polizzeReducer;
exports.default = exports.polizzeReducer;
