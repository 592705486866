"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterMandatiDtoToJSON = exports.FilterMandatiDtoFromJSONTyped = exports.FilterMandatiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function FilterMandatiDtoFromJSON(json) {
    return FilterMandatiDtoFromJSONTyped(json, false);
}
exports.FilterMandatiDtoFromJSON = FilterMandatiDtoFromJSON;
function FilterMandatiDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'contoAddebito': json['contoAddebito'],
        'schema': json['schema'],
        'stati': !runtime_1.exists(json, 'stati') ? undefined : json['stati'],
        'dataDa': !runtime_1.exists(json, 'dataDa') ? undefined : (new Date(json['dataDa'])),
        'dataA': !runtime_1.exists(json, 'dataA') ? undefined : (new Date(json['dataA'])),
        'ordinamento': !runtime_1.exists(json, 'ordinamento') ? undefined : json['ordinamento'],
        'numeroRisultati': !runtime_1.exists(json, 'numeroRisultati') ? undefined : json['numeroRisultati'],
    };
}
exports.FilterMandatiDtoFromJSONTyped = FilterMandatiDtoFromJSONTyped;
function FilterMandatiDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'contoAddebito': value.contoAddebito,
        'schema': value.schema,
        'stati': value.stati,
        'dataDa': value.dataDa === undefined ? undefined : (value.dataDa.toISOString()),
        'dataA': value.dataA === undefined ? undefined : (value.dataA.toISOString()),
        'ordinamento': value.ordinamento,
        'numeroRisultati': value.numeroRisultati,
    };
}
exports.FilterMandatiDtoToJSON = FilterMandatiDtoToJSON;
