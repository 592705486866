import { getRiepilogoPatrimonio, patrimonioRiepilogo, RiepilogoPatrimonioDto } from "@sparkasse/commons";
import ButtonText2 from "component/buttonText2/buttonText2.component";
import { navigateToPage, WEBPAGE } from "component/history/history.component";
import PatrimonioChartView from "pages/patrimonio/patrimonio.Chart.View";
import { ChartData, patrimonioFinanziaColors, patrimonioInvestimentiColors, patrimonioLiquiditaColors, patrimonioLordoColors } from "pages/patrimonio/patrimonio.view";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AppState } from "reducers";
import { AnyAction, bindActionCreators, Dispatch } from "redux";

const WidgetPatrimonio = (props: Props) => {

  const { t } = useTranslation();

  const [tipologiaSelezionata, setTipologiaSelezionata] = useState<string | undefined>(undefined);
  const [chartDataPatrimonioLordo, setChartDataPatrimonioLordo] = useState<Array<ChartData>>();
  const [chartDataFinanzamenti, setChartDataFinanzamenti] = useState<Array<ChartData>>();
  const [chartDataDettaglio, setChartDataDettaglio] = useState<Array<ChartData>>([]);

  const {
    getRiepilogoPatrimonio,
    riepilogo
  } = props;

  const getTotalTipologia = (tipologia: string | undefined): number => {
    if (tipologia === "bankingLiquidita") {
      return riepilogo?.totaleLiquidita || 0;
    } else if (tipologia === "investimenti") {
      return riepilogo?.totaleInvestimenti || 0;
    } else if (tipologia === "assicurazioni") {
      return riepilogo?.totaleAssicurazioni || 0;
    }
    return 0;
  };

  const buildPatrimonioLordoData = (
    riepilogo: RiepilogoPatrimonioDto
  ) => {
    if (riepilogo) {
      const data: Array<ChartData> = [];
      riepilogo.posizioneDisponibilita?.disponibilitaLordoList?.filter(e => (e && e.controvalore && e.controvalore > 0)).map((item) => {
        data.push({
          testo: item.tipologiaDesc || "",
          importo: getTotalTipologia(item.tipologia),
          color:
            (item.tipologia && patrimonioLordoColors[item.tipologia]) || "",
          tipologia: item.tipologia || "",
          showValues: true
        });
      });
      setChartDataPatrimonioLordo(data);
      setTipologiaSelezionata(undefined);
    }
  };

  const buildPatrimonioFinanziamentiData = (
    riepilogo: RiepilogoPatrimonioDto
  ) => {
    const data: Array<ChartData> = [];
    riepilogo.posizioneFinanziamentiList?.map((item: any) => {
      data.push({
        testo: t(`patrimonio.finanziamenti.tipologia.${item.tipologia}`) || "",
        importo: (item.tipologia === "1" ? Math.abs(item.saldoContabile) : item.totaleSaldoCapitaleResiduo) || 0,
        color:
          (item.tipologia && patrimonioFinanziaColors[item.tipologia]) || "",
        tipologia: item.tipologia || "",
        showValues: !!item.finanziamentiContoListVisibile
      });
    });
    setChartDataFinanzamenti(data);
  };

  const buildPatrimonioBankingLiquiditaData = (
    riepilogo: RiepilogoPatrimonioDto
  ) => {
    const data: Array<ChartData> = [];
    riepilogo.posizioneLiquiditaList?.map((item) => {
      data.push({
        testo: item.tipologiaDesc || "",
        importo: item.totaleSaldoContabile || 0,
        color:
          (item.tipologia && patrimonioLiquiditaColors[item.tipologia]) || "",
        tipologia: item.tipologia || "",
        showValues: !!item.liquiditaContiListVisibile
      });
    });
    setChartDataDettaglio(data);
  };

  const buildPatrimonioInvestimentiData = (
    riepilogo: RiepilogoPatrimonioDto
  ) => {
    const data: Array<ChartData> = [];
    riepilogo.posizioneInvestimentiList?.map((item) => {
      data.push({
        testo: item.tipologiaDesc || "",
        importo: item.totaleControvalore || 0,
        color:
          (item.tipologia && patrimonioInvestimentiColors[item.tipologia]) ||
          "",
        tipologia: item.tipologia || "",
        showValues: !!item.investimentiContiListVisibile
      });
    });
    setChartDataDettaglio(data);
  };

  const buildPatrimonioAssicurazioniData = (
    riepilogo: RiepilogoPatrimonioDto
  ) => {
    const data: Array<ChartData> = [];
    riepilogo.posizioneAssicurazioniList?.map((item) => {
      data.push({
        testo: "Assicurazioni",
        importo: item.totaleControvalore || 0,
        color: "#2662B3",
        showValues: true
      });
    });
    setChartDataDettaglio(data);
  };

  useEffect(() => {
    if (!riepilogo) {
      getRiepilogoPatrimonio();
    }
  }, []);

  useEffect(() => {
    if (riepilogo) {
      buildPatrimonioLordoData(riepilogo);
      buildPatrimonioFinanziamentiData(riepilogo);
    }
  }, [riepilogo]);

  const onSelectPatrimonio = (tipologia: string) => {

    if (tipologia === "bankingLiquidita" || tipologia === "investimenti" || tipologia === "assicurazioni") {
      setTipologiaSelezionata(tipologia);
    }

    if (tipologia === "bankingLiquidita") {
      riepilogo && buildPatrimonioBankingLiquiditaData(riepilogo);
    } else if (tipologia === "investimenti") {
      riepilogo && buildPatrimonioInvestimentiData(riepilogo);
    } else if (tipologia === "assicurazioni") {
      riepilogo && buildPatrimonioAssicurazioniData(riepilogo);
    }

  };

  const onBack = () => {
    setChartDataDettaglio([]);
  };

  return (
    <div className="widget widget-patrimonio widget-patrimonio-home">

      <p className="widget-title">
        {t("home.widgets.patrimonio.title")}
      </p>

      <div className="underline-short" />

      <div className="widgets-patrimonio-wrapper">
        {!!riepilogo && !!chartDataPatrimonioLordo &&
          <PatrimonioChartView
            chartDataPatrimonioLordo={chartDataPatrimonioLordo}
            chartDataFinanzamenti={chartDataFinanzamenti}
            chartDataDettaglio={chartDataDettaglio}
            onSelectPatrimonio={onSelectPatrimonio}
            onBack={onBack}
            tipologiaSelezionata={tipologiaSelezionata}
            patrimonioTotaleDisponibilita={riepilogo.posizioneDisponibilita}
            patrimonioTotaleFinanziamenti={riepilogo.totaleFinanziamenti}
            totaleLiquidita={riepilogo.totaleLiquidita}
            totaleAssicurazioni={riepilogo.totaleAssicurazioni}
            totaleInvestimenti={riepilogo.totaleInvestimenti}
            chartSize={"160"}
          />
        }
      </div>

      <ButtonText2
        className="widget-vai-a-dettaglio"
        onClick={() => { navigateToPage(WEBPAGE.Patrimonio.url) }}
        img="navigazione_avanti"
        descrizione={t("home.widgets.patrimonio.dettaglio")}
      />

    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  riepilogo: patrimonioRiepilogo(state.patrimonio),
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getRiepilogoPatrimonio,
    },
    dispatch
  );

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(WidgetPatrimonio);
