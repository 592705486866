"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RichiestaDtoToJSON = exports.RichiestaDtoFromJSONTyped = exports.RichiestaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function RichiestaDtoFromJSON(json) {
    return RichiestaDtoFromJSONTyped(json, false);
}
exports.RichiestaDtoFromJSON = RichiestaDtoFromJSON;
function RichiestaDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'richiesta': !runtime_1.exists(json, 'richiesta') ? undefined : json['richiesta'],
        'icona': !runtime_1.exists(json, 'icona') ? undefined : json['icona'],
        'dataInoltro': !runtime_1.exists(json, 'dataInoltro') ? undefined : (new Date(json['dataInoltro'])),
        'dataUltimaModifica': !runtime_1.exists(json, 'dataUltimaModifica') ? undefined : (new Date(json['dataUltimaModifica'])),
        'statoRichiesta': !runtime_1.exists(json, 'statoRichiesta') ? undefined : json['statoRichiesta'],
        'noteCliente': !runtime_1.exists(json, 'noteCliente') ? undefined : json['noteCliente'],
        'immagine': !runtime_1.exists(json, 'immagine') ? undefined : json['immagine'],
    };
}
exports.RichiestaDtoFromJSONTyped = RichiestaDtoFromJSONTyped;
function RichiestaDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'richiesta': value.richiesta,
        'icona': value.icona,
        'dataInoltro': value.dataInoltro === undefined ? undefined : (value.dataInoltro.toISOString()),
        'dataUltimaModifica': value.dataUltimaModifica === undefined ? undefined : (value.dataUltimaModifica.toISOString()),
        'statoRichiesta': value.statoRichiesta,
        'noteCliente': value.noteCliente,
        'immagine': value.immagine,
    };
}
exports.RichiestaDtoToJSON = RichiestaDtoToJSON;
