"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SessionReqInToJSON = exports.SessionReqInFromJSONTyped = exports.SessionReqInFromJSON = void 0;
const runtime_1 = require("../runtime");
function SessionReqInFromJSON(json) {
    return SessionReqInFromJSONTyped(json, false);
}
exports.SessionReqInFromJSON = SessionReqInFromJSON;
function SessionReqInFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'sessionId': json['sessionId'],
        'oauthState': !runtime_1.exists(json, 'oauthState') ? undefined : json['oauthState'],
        'info': json['info'],
    };
}
exports.SessionReqInFromJSONTyped = SessionReqInFromJSONTyped;
function SessionReqInToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'sessionId': value.sessionId,
        'oauthState': value.oauthState,
        'info': value.info,
    };
}
exports.SessionReqInToJSON = SessionReqInToJSON;
