"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnagraficaRapportoDtoToJSON = exports.AnagraficaRapportoDtoFromJSONTyped = exports.AnagraficaRapportoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function AnagraficaRapportoDtoFromJSON(json) {
    return AnagraficaRapportoDtoFromJSONTyped(json, false);
}
exports.AnagraficaRapportoDtoFromJSON = AnagraficaRapportoDtoFromJSON;
function AnagraficaRapportoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'iban': !runtime_1.exists(json, 'iban') ? undefined : json['iban'],
        'intestazione': !runtime_1.exists(json, 'intestazione') ? undefined : json['intestazione'],
        'codiceRapporto': !runtime_1.exists(json, 'codiceRapporto') ? undefined : json['codiceRapporto'],
        'pan': !runtime_1.exists(json, 'pan') ? undefined : json['pan'],
        'indirizzo': !runtime_1.exists(json, 'indirizzo') ? undefined : _1.IndirizzoAnagrafeDtoFromJSON(json['indirizzo']),
    };
}
exports.AnagraficaRapportoDtoFromJSONTyped = AnagraficaRapportoDtoFromJSONTyped;
function AnagraficaRapportoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'iban': value.iban,
        'intestazione': value.intestazione,
        'codiceRapporto': value.codiceRapporto,
        'pan': value.pan,
        'indirizzo': _1.IndirizzoAnagrafeDtoToJSON(value.indirizzo),
    };
}
exports.AnagraficaRapportoDtoToJSON = AnagraficaRapportoDtoToJSON;
