import React from "react";

interface iProps {
  sectionTitle: string;
  totalTitle: string;
  totalValue: string;
  children?: JSX.Element;
  accordionHeaderRight: string;
  accordionHeaderLeft: string;
  isNegative?: boolean;
  isAssicuracione?: boolean
}

const PatrimonioTableWraper = (props: iProps) => {
  const {
    sectionTitle,
    totalTitle,
    totalValue,
    children,
    accordionHeaderRight,
    accordionHeaderLeft,
    isNegative,
    isAssicuracione
  } = props;
  return (
    <div className="table-section">
      <div className="captionView-step2 ">
        <p className="txtCaption-step2 text-uppercase">{sectionTitle}</p>
      </div>

      <div className="patrimonio-accordion">
        <div className="accordion-header">
          <p className="accordion-header_text">{accordionHeaderLeft}</p>
          <p className={`accordion-header_text ${isAssicuracione && "accordion-header_text_mr"}`}>{accordionHeaderRight}</p>
        </div>
        {children}
        <div className="accordion-footer">
          <p className="accordion-footer_text">{totalTitle}</p>
          <p className={`accordion-footer_text-value ${isNegative && "accordion-footer_text-value_negative"}  `}>{totalValue} EUR</p>
        </div>
      </div>
    </div>
  );
};
export default PatrimonioTableWraper;
