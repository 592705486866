"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendNotificheNetinsurance = exports.setShowModalNotifiche = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
function notificePushSendNetInsuranceStart() {
    return {
        type: __1.EReduxActionTypes.NOTIFICE_PUSH_SEND_NET_INSURANCE_START
    };
}
function notificePushSendNetInsuranceSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.NOTIFICE_PUSH_SEND_NET_INSURANCE_SUCCESS,
        payload
    };
}
function generalError(value) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value, true));
    };
}
const setShowModalNotifiche = (payload) => {
    return {
        type: __1.EReduxActionTypes.NOTIFICHE_PUSH_SET_SHOW_MODAL,
        payload
    };
};
exports.setShowModalNotifiche = setShowModalNotifiche;
const sendNotificheNetinsurance = (operation, title, body, url) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(notificePushSendNetInsuranceStart());
    if (extraArguments.mock) {
    }
    else {
        /* Operation mock 'NETINSURANCE'
         * waiting for the operation ENUM from the BE
        */
        const sendNotificheNetInsuranceRequest = {
            sendRequest: {
                message: {
                    operation,
                    url,
                    title,
                    body
                }
            }
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.NotificationControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .sendNotificheNetInsurance(sendNotificheNetInsuranceRequest).then((response) => {
                dispatch(notificePushSendNetInsuranceSuccess(response));
                dispatch((0, general_actions_1.setLoader)(false));
                dispatch((0, exports.setShowModalNotifiche)(true));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
                dispatch((0, general_actions_1.setLoader)(false));
            }).finally(() => {
                if (extraArguments.platform == 'mobile') {
                    dispatch((0, general_actions_1.setLoader)(false));
                }
            });
        });
    }
};
exports.sendNotificheNetinsurance = sendNotificheNetinsurance;
