"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PosizioneGlobaleDtoToJSON = exports.PosizioneGlobaleDtoFromJSONTyped = exports.PosizioneGlobaleDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function PosizioneGlobaleDtoFromJSON(json) {
    return PosizioneGlobaleDtoFromJSONTyped(json, false);
}
exports.PosizioneGlobaleDtoFromJSON = PosizioneGlobaleDtoFromJSON;
function PosizioneGlobaleDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'liquidita': !runtime_1.exists(json, 'liquidita') ? undefined : _1.LiquiditaDtoFromJSON(json['liquidita']),
        'titoliFondi': !runtime_1.exists(json, 'titoliFondi') ? undefined : _1.TitoliFondiDtoFromJSON(json['titoliFondi']),
        'assicurazioni': !runtime_1.exists(json, 'assicurazioni') ? undefined : _1.AssicurazioniDtoFromJSON(json['assicurazioni']),
        'patrimonioGestito': !runtime_1.exists(json, 'patrimonioGestito') ? undefined : _1.PatrimonioGestitoDtoFromJSON(json['patrimonioGestito']),
    };
}
exports.PosizioneGlobaleDtoFromJSONTyped = PosizioneGlobaleDtoFromJSONTyped;
function PosizioneGlobaleDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'liquidita': _1.LiquiditaDtoToJSON(value.liquidita),
        'titoliFondi': _1.TitoliFondiDtoToJSON(value.titoliFondi),
        'assicurazioni': _1.AssicurazioniDtoToJSON(value.assicurazioni),
        'patrimonioGestito': _1.PatrimonioGestitoDtoToJSON(value.patrimonioGestito),
    };
}
exports.PosizioneGlobaleDtoToJSON = PosizioneGlobaleDtoToJSON;
