"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FrecciaControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class FrecciaControllerApi extends runtime.BaseAPI {
    /**
     * Informazioni sull\'autenticazione dispositiva per un bollettino freccia
     */
    authenticationInformationFrecciaRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling authenticationInformationFreccia.');
            }
            const queryParameters = {};
            if (requestParameters.chiamataUscente !== undefined) {
                queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
            }
            if (requestParameters.eseguiInit !== undefined) {
                queryParameters['eseguiInit'] = requestParameters.eseguiInit;
            }
            if (requestParameters.offline !== undefined) {
                queryParameters['offline'] = requestParameters.offline;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/freccia/infoautenticazione/{idDispositiva}`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva per un bollettino freccia
     */
    authenticationInformationFreccia(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.authenticationInformationFrecciaRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Autorizzazione di una disposizione di revoca per un bollettino freccia
     */
    authenticationRevocationFrecciaRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.frecciaRevocaRequestIn === null || requestParameters.frecciaRevocaRequestIn === undefined) {
                throw new runtime.RequiredError('frecciaRevocaRequestIn', 'Required parameter requestParameters.frecciaRevocaRequestIn was null or undefined when calling authenticationRevocationFreccia.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/freccia/autorizza/revoca`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.FrecciaRevocaRequestInToJSON(requestParameters.frecciaRevocaRequestIn),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.EsitoAutorizzazioneDisposizioneFrecciaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Autorizzazione di una disposizione di revoca per un bollettino freccia
     */
    authenticationRevocationFreccia(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.authenticationRevocationFrecciaRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva per la revoca di un bollettino freccia
     */
    authenticationRevocationInformationFrecciaRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling authenticationRevocationInformationFreccia.');
            }
            const queryParameters = {};
            if (requestParameters.chiamataUscente !== undefined) {
                queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
            }
            if (requestParameters.eseguiInit !== undefined) {
                queryParameters['eseguiInit'] = requestParameters.eseguiInit;
            }
            if (requestParameters.offline !== undefined) {
                queryParameters['offline'] = requestParameters.offline;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/freccia/infoautenticazione/{idDispositiva}/revoca`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva per la revoca di un bollettino freccia
     */
    authenticationRevocationInformationFreccia(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.authenticationRevocationInformationFrecciaRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Autorizzazione di una disposizione di un bollettino Freccia
     */
    authorizeFrecciaRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling authorizeFreccia.');
            }
            if (requestParameters.credenzialeDispositivaDto === null || requestParameters.credenzialeDispositivaDto === undefined) {
                throw new runtime.RequiredError('credenzialeDispositivaDto', 'Required parameter requestParameters.credenzialeDispositivaDto was null or undefined when calling authorizeFreccia.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/freccia/autorizza/{idDispositiva}`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CredenzialeDispositivaDtoToJSON(requestParameters.credenzialeDispositivaDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.EsitoAutorizzazioneDisposizioneFrecciaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Autorizzazione di una disposizione di un bollettino Freccia
     */
    authorizeFreccia(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.authorizeFrecciaRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Esegue il controllo di validità di un freccia in base ai valori specificati nell\'oggetto di richiesta
     */
    checkFrecciaRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.frecciaDto === null || requestParameters.frecciaDto === undefined) {
                throw new runtime.RequiredError('frecciaDto', 'Required parameter requestParameters.frecciaDto was null or undefined when calling checkFreccia.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/freccia/controllo`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.FrecciaDtoToJSON(requestParameters.frecciaDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.FrecciaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Esegue il controllo di validità di un freccia in base ai valori specificati nell\'oggetto di richiesta
     */
    checkFreccia(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.checkFrecciaRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Eliminazione di una bozza dato l\'id
     */
    deleteDraftFrecciaRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling deleteDraftFreccia.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/freccia/bozze/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Eliminazione di una bozza dato l\'id
     */
    deleteDraftFreccia(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.deleteDraftFrecciaRaw(requestParameters);
        });
    }
    /**
     * Restituisce una bozza dato l\'id per freccia
     */
    getDraftByIdFrecciaRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getDraftByIdFreccia.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/freccia/bozze/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.FrecciaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce una bozza dato l\'id per freccia
     */
    getDraftByIdFreccia(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getDraftByIdFrecciaRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce le bozze per freccia
     */
    getDraftListFrecciaRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.bozzeInDto === null || requestParameters.bozzeInDto === undefined) {
                throw new runtime.RequiredError('bozzeInDto', 'Required parameter requestParameters.bozzeInDto was null or undefined when calling getDraftListFreccia.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/freccia/bozze`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.BozzeInDtoToJSON(requestParameters.bozzeInDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.FrecciasDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce le bozze per freccia
     */
    getDraftListFreccia(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getDraftListFrecciaRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Lettura parametri per bollettino Freccia
     */
    parametersFrecciaRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/freccia/parametri`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ParametriFrecciaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Lettura parametri per bollettino Freccia
     */
    parametersFreccia() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.parametersFrecciaRaw();
            return yield response.value();
        });
    }
    /**
     * Salva una bozza per freccia
     */
    saveDraftFrecciaRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.frecciaDto === null || requestParameters.frecciaDto === undefined) {
                throw new runtime.RequiredError('frecciaDto', 'Required parameter requestParameters.frecciaDto was null or undefined when calling saveDraftFreccia.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/freccia/salvabozza`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.FrecciaDtoToJSON(requestParameters.frecciaDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.FrecciaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Salva una bozza per freccia
     */
    saveDraftFreccia(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.saveDraftFrecciaRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Modifica di una bozza dato l\'id per freccia
     */
    updateDraftFrecciaRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling updateDraftFreccia.');
            }
            if (requestParameters.frecciaDto === null || requestParameters.frecciaDto === undefined) {
                throw new runtime.RequiredError('frecciaDto', 'Required parameter requestParameters.frecciaDto was null or undefined when calling updateDraftFreccia.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/freccia/bozze/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.FrecciaDtoToJSON(requestParameters.frecciaDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.FrecciaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Modifica di una bozza dato l\'id per freccia
     */
    updateDraftFreccia(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateDraftFrecciaRaw(requestParameters);
            return yield response.value();
        });
    }
}
exports.FrecciaControllerApi = FrecciaControllerApi;
