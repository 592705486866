"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContribuenteDtoToJSON = exports.ContribuenteDtoFromJSONTyped = exports.ContribuenteDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ContribuenteDtoFromJSON(json) {
    return ContribuenteDtoFromJSONTyped(json, false);
}
exports.ContribuenteDtoFromJSON = ContribuenteDtoFromJSON;
function ContribuenteDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codiceFiscale': json['codiceFiscale'],
        'annoImpostaSolare': !runtime_1.exists(json, 'annoImpostaSolare') ? undefined : json['annoImpostaSolare'],
        'codiceFiscaleTutore': !runtime_1.exists(json, 'codiceFiscaleTutore') ? undefined : json['codiceFiscaleTutore'],
        'codiceIdentificativoCFTutore': !runtime_1.exists(json, 'codiceIdentificativoCFTutore') ? undefined : json['codiceIdentificativoCFTutore'],
        'datiAnagrafici': _1.ContribuenteDatiAnagraficiDtoFromJSON(json['datiAnagrafici']),
        'domicilio': _1.ContribuenteDomicilioDtoFromJSON(json['domicilio']),
    };
}
exports.ContribuenteDtoFromJSONTyped = ContribuenteDtoFromJSONTyped;
function ContribuenteDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codiceFiscale': value.codiceFiscale,
        'annoImpostaSolare': value.annoImpostaSolare,
        'codiceFiscaleTutore': value.codiceFiscaleTutore,
        'codiceIdentificativoCFTutore': value.codiceIdentificativoCFTutore,
        'datiAnagrafici': _1.ContribuenteDatiAnagraficiDtoToJSON(value.datiAnagrafici),
        'domicilio': _1.ContribuenteDomicilioDtoToJSON(value.domicilio),
    };
}
exports.ContribuenteDtoToJSON = ContribuenteDtoToJSON;
