"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreferenzeAnatocismoDtoToJSON = exports.PreferenzeAnatocismoDtoFromJSONTyped = exports.PreferenzeAnatocismoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function PreferenzeAnatocismoDtoFromJSON(json) {
    return PreferenzeAnatocismoDtoFromJSONTyped(json, false);
}
exports.PreferenzeAnatocismoDtoFromJSON = PreferenzeAnatocismoDtoFromJSON;
function PreferenzeAnatocismoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'rapporti': !runtime_1.exists(json, 'rapporti') ? undefined : (json['rapporti'].map(_1.AnatocismoDtoFromJSON)),
        'stampaPersonalizzata': !runtime_1.exists(json, 'stampaPersonalizzata') ? undefined : json['stampaPersonalizzata'],
        'descrizioneIstituto': !runtime_1.exists(json, 'descrizioneIstituto') ? undefined : json['descrizioneIstituto'],
        'descrizioneFiliale': !runtime_1.exists(json, 'descrizioneFiliale') ? undefined : json['descrizioneFiliale'],
        'idDisposizione': !runtime_1.exists(json, 'idDisposizione') ? undefined : json['idDisposizione'],
    };
}
exports.PreferenzeAnatocismoDtoFromJSONTyped = PreferenzeAnatocismoDtoFromJSONTyped;
function PreferenzeAnatocismoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'rapporti': value.rapporti === undefined ? undefined : (value.rapporti.map(_1.AnatocismoDtoToJSON)),
        'stampaPersonalizzata': value.stampaPersonalizzata,
        'descrizioneIstituto': value.descrizioneIstituto,
        'descrizioneFiliale': value.descrizioneFiliale,
        'idDisposizione': value.idDisposizione,
    };
}
exports.PreferenzeAnatocismoDtoToJSON = PreferenzeAnatocismoDtoToJSON;
