"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.myBankReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const helpers_1 = require("../helpers");
const initialState = {
    formMyBank: {
        step: 0
    },
    esito: (0, api_rest_1.EsitoAutorizzazioneDisposizioneBonificoDtoFromJSON)({}),
    infoDispositiva: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
    riepilogo: (0, api_rest_1.BonificoSepaDtoFromJSON)({}),
    annulla: (0, api_rest_1.AnnulloMyBankDtoFromJSON)({}),
    parametri: (0, api_rest_1.InfoMyBankDtoFromJSON)({}),
    rapportoSelezionato: (0, api_rest_1.RapportoCompletoDtoFromJSON)({}),
    myBankToken: ''
};
const myBankReducer = (state = initialState, action) => {
    switch (action.type) {
        case __1.EReduxActionTypes.MY_BANK_CONTROLLO_SUCCESS:
            return {
                ...state,
                riepilogo: action.payload
            };
        case __1.EReduxActionTypes.MY_BANK_AUTORIZZA_SUCCESS:
            if (action.payload.stato === 'OK') {
                return {
                    ...state,
                    //riepilogo: initialState.riepilogo,
                    esito: action.payload
                };
            }
            else {
                return {
                    ...state,
                    infoDispositiva: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoDispositiva, action.payload.informazioniAutenticazioneDispositiva),
                    esito: action.payload
                };
            }
        case __1.EReduxActionTypes.MY_BANK_GET_INFO_AUTENTICAZIONE_SUCCESS:
            return {
                ...state,
                infoDispositiva: action.payload,
                esito: initialState.esito
            };
        case __1.EReduxActionTypes.MY_BANK_SET_STEP:
            return {
                ...state,
                formMyBank: {
                    ...state.formMyBank,
                    step: action.payload
                }
            };
        case __1.EReduxActionTypes.MY_BANK_RESET:
            return {
                ...state,
                formMyBank: initialState.formMyBank
            };
        case __1.EReduxActionTypes.MY_BANK_ANNULLA_SUCCESS:
            return {
                ...state,
                annulla: action.payload
            };
        case __1.EReduxActionTypes.MY_BANK_GET_PARAMETRI_SUCCESS:
            return {
                ...state,
                parametri: action.payload
            };
        case __1.EReduxActionTypes.MY_BANK_RAPPORTO_SELEZIONATO:
            return {
                ...state,
                rapportoSelezionato: action.payload
            };
        case __1.EReduxActionTypes.MY_BANK_SET_My_BANK_TOKEN:
            return {
                ...state,
                myBankToken: action.payload
            };
        case __1.EReduxActionTypes.RESET_ALL: // action invocata dalla logout
            return {
                ...initialState
            };
        default:
            return state;
    }
};
exports.myBankReducer = myBankReducer;
