"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OperazioneDatoAggiuntivoDtoToJSON = exports.OperazioneDatoAggiuntivoDtoFromJSONTyped = exports.OperazioneDatoAggiuntivoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function OperazioneDatoAggiuntivoDtoFromJSON(json) {
    return OperazioneDatoAggiuntivoDtoFromJSONTyped(json, false);
}
exports.OperazioneDatoAggiuntivoDtoFromJSON = OperazioneDatoAggiuntivoDtoFromJSON;
function OperazioneDatoAggiuntivoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'tipoOperazione': !runtime_1.exists(json, 'tipoOperazione') ? undefined : json['tipoOperazione'],
        'codice': !runtime_1.exists(json, 'codice') ? undefined : json['codice'],
        'valore': !runtime_1.exists(json, 'valore') ? undefined : json['valore'],
    };
}
exports.OperazioneDatoAggiuntivoDtoFromJSONTyped = OperazioneDatoAggiuntivoDtoFromJSONTyped;
function OperazioneDatoAggiuntivoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'tipoOperazione': value.tipoOperazione,
        'codice': value.codice,
        'valore': value.valore,
    };
}
exports.OperazioneDatoAggiuntivoDtoToJSON = OperazioneDatoAggiuntivoDtoToJSON;
