"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.operazioniOneClickReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const helpers_1 = require("../helpers");
const oneclick_types_1 = require("./oneclick.types");
const initialState = {
    operazioniOneclickHomepage: (0, api_rest_1.OperazioniOneClickDtoFromJSON)({}),
    operazioniOneClick: (0, api_rest_1.OperazioniOneClickDtoFromJSON)({}),
    form: (0, oneclick_types_1.Form1ClickDtoFromJSONTyped)({}),
    operazioneSelezionata: undefined,
    parametri: (0, api_rest_1.ParametriOneClickDtoFromJSON)({}),
    infoDispositiva: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
    esito: (0, api_rest_1.EsitoAutorizzazioneDisposizioneOneClickDtoFromJSON)({}),
    riepilogo: (0, api_rest_1.OperazioneOneClickDtoFromJSON)({}),
    creaOneClick: false,
    modificaOneClick: false
};
const operazioniOneClickReducer = (state = initialState, action) => {
    switch (action.type) {
        case __1.EReduxActionTypes.OPERAZIONI_GET_OPERAZIONI_ONECLICK_HOMEPAGE_SUCCESS:
            return {
                ...state,
                operazioniOneclickHomepage: action.payload
            };
        case __1.EReduxActionTypes.GET_OPERAZIONI_ONECLICK_SUCCESS:
            return {
                ...state,
                operazioniOneClick: action.payload
            };
        case __1.EReduxActionTypes.ELIMINA_ONECLICK_SUCCESS:
            const newOneClick = state.operazioniOneClick.operazioniOneClick ?
                state.operazioniOneClick.operazioniOneClick.filter((val) => val.id !== action.payload) :
                [];
            return {
                ...state,
                operazioniOneclickHomepage: initialState.operazioniOneclickHomepage,
                operazioniOneClick: {
                    ...state.operazioniOneClick,
                    operazioniOneClick: newOneClick
                }
            };
        case __1.EReduxActionTypes.ONECLICK_TIPO_OPERAZIONE:
            return {
                ...state,
                form: {
                    ...state.form,
                    tipoOperazione: action.payload,
                    tipoOperazioneError: (0, helpers_1.validationField)(action.payload, '')
                }
            };
        case __1.EReduxActionTypes.ONECLICK_TIPO_OPERAZIONE_RICERCA:
            return {
                ...state,
                form: {
                    ...state.form,
                    tipoOperazioneRicerca: action.payload,
                }
            };
        case __1.EReduxActionTypes.ONECLICK_CONTENUTO:
            return {
                ...state,
                form: {
                    ...state.form,
                    contenuto: action.payload,
                    contenutoError: (0, helpers_1.validationField)(action.payload, '')
                }
            };
        case __1.EReduxActionTypes.ONECLICK_SET_ACCORDION_FILTRI_OPENED:
            return {
                ...state,
                form: {
                    ...state.form,
                    accordionFiltriOpened: action.payload
                }
            };
        case __1.EReduxActionTypes.ONECLICK_OPERAZIONE_SELEZIONATA:
            return {
                ...state,
                operazioneSelezionata: action.payload
            };
        case __1.EReduxActionTypes.ONECLICK_RESET_OPERAZIONE_SELEZIONATA:
            return {
                ...state,
                operazioneSelezionata: undefined
            };
        case __1.EReduxActionTypes.ONECLICK_GET_PARAMETRI_SUCCESS:
            return {
                ...state,
                parametri: action.payload
            };
        case __1.EReduxActionTypes.ONECLICK_SET_TIPO_OPERAZIONE:
            return {
                ...state,
                form: {
                    ...state.form,
                    tipoOperazione: action.payload,
                    tipoOperazioneDirty: true,
                    tipoOperazioneError: (0, helpers_1.validationField)(action.payload, '')
                }
            };
        case __1.EReduxActionTypes.ONECLICK_SET_ALIAS:
            return {
                ...state,
                form: {
                    ...state.form,
                    alias: action.payload,
                    aliasDirty: true,
                    aliasError: (0, helpers_1.validationField)(action.payload, '')
                }
            };
        case __1.EReduxActionTypes.ONECLICK_CONTROLLO_SUCCESS:
            return {
                ...state,
                riepilogo: action.payload
            };
        case __1.EReduxActionTypes.ONECLICK_AUTORIZZA_SUCCESS:
            if (action.payload.stato === 'OK') {
                return {
                    ...state,
                    operazioniOneclickHomepage: initialState.operazioniOneclickHomepage,
                    //riepilogo: initialState.riepilogo,
                    esito: action.payload
                };
            }
            else {
                return {
                    ...state,
                    infoDispositiva: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoDispositiva, action.payload.informazioniAutenticazioneDispositiva),
                    esito: action.payload
                };
            }
        case __1.EReduxActionTypes.ONECLICK_CONTROLLO_MODIFICA_SUCCESS:
            return {
                ...state,
                riepilogo: action.payload
            };
        case __1.EReduxActionTypes.ONECLICK_GET_INFO_AUTENTICAZIONE_SUCCESS:
            return {
                ...state,
                infoDispositiva: action.payload,
                esito: initialState.esito
            };
        case __1.EReduxActionTypes.ONECLICK_GET_INFO_AUTENTICAZIONE_MODIFICA_SUCCESS:
            return {
                ...state,
                infoDispositiva: action.payload,
                esito: initialState.esito
            };
        case __1.EReduxActionTypes.ONECLICK_AUTORIZZA_MODIFICA_SUCCESS:
            if (action.payload.stato === 'OK') {
                return {
                    ...state,
                    operazioniOneclickHomepage: initialState.operazioniOneclickHomepage,
                    //riepilogo: initialState.riepilogo,
                    esito: action.payload
                };
            }
            else {
                return {
                    ...state,
                    infoDispositiva: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoDispositiva, action.payload.informazioniAutenticazioneDispositiva),
                    esito: action.payload
                };
            }
        case __1.EReduxActionTypes.ONECLICK_FORM_SUBMITTED:
            return {
                ...state,
                form: {
                    ...state.form,
                    formSubmitted: action.payload
                }
            };
        case __1.EReduxActionTypes.ONECLICK_SET_STEP:
            return {
                ...state,
                form: {
                    ...state.form,
                    step: action.payload
                }
            };
        case __1.EReduxActionTypes.ONECLICK_RESET_FORM:
            const oldTipo = state.form.tipoOperazioneRicerca;
            return {
                ...state,
                form: {
                    ...initialState.form,
                    tipoOperazioneRicerca: oldTipo,
                },
                infoDispositiva: initialState.infoDispositiva,
                esito: initialState.esito,
                riepilogo: initialState.riepilogo,
                modificaOneClick: initialState.modificaOneClick
            };
        case __1.EReduxActionTypes.ONECLICK_RESET_FILTRO:
            return {
                ...state,
                form: initialState.form,
                operazioneSelezionata: initialState.operazioneSelezionata
            };
        case __1.EReduxActionTypes.ONECLICK_SET_RAPPORTO_SELEZIONATO:
            return {
                ...state,
                form: {
                    ...state.form,
                    rapportoSelezionato: action.payload
                }
            };
        case __1.EReduxActionTypes.ONECLICK_SET_BENEFICIARIO:
            return {
                ...state,
                form: {
                    ...state.form,
                    beneficiario: action.payload,
                    beneficiarioDirty: true,
                    beneficiarioError: (0, helpers_1.validationField)(action.payload, '', false, 3)
                }
            };
        case __1.EReduxActionTypes.ONECLICK_SET_IBAN:
            return {
                ...state,
                form: {
                    ...state.form,
                    iban: action.payload,
                    ibanDirty: true,
                    ibanError: (0, helpers_1.validationField)(action.payload, '', true)
                }
            };
        case __1.EReduxActionTypes.ONECLICK_SET_IMPORTO:
            return {
                ...state,
                form: {
                    ...state.form,
                    importo: action.payload
                }
            };
        case __1.EReduxActionTypes.ONECLICK_SET_CAUSALE:
            return {
                ...state,
                form: {
                    ...state.form,
                    causale: action.payload
                }
            };
        case __1.EReduxActionTypes.ONECLICK_SET_STEPSCA:
            return {
                ...state,
                form: {
                    ...state.form,
                    stepSCA: action.payload
                }
            };
        case __1.EReduxActionTypes.ONECLICK_SET_CREA:
            return {
                ...state,
                creaOneClick: action.payload
            };
        case __1.EReduxActionTypes.ONECLICK_SET_MODIFICA:
            return {
                ...state,
                modificaOneClick: action.payload
            };
        case __1.EReduxActionTypes.ONECLICK_RESET_RIEPILOGO:
            return {
                ...state,
                riepilogo: initialState.riepilogo
            };
        case __1.EReduxActionTypes.GENERAL_RESET_CACHE_POST_DISPO: // action invocata dopo una dispositiva di tipo resetWidgetsDispoFunctions
            return {
                ...state,
                operazioniOneclickHomepage: initialState.operazioniOneclickHomepage,
            };
        case __1.EReduxActionTypes.GENERAL_REFRESH_WIDGETS: // action invocata tramite pull to refresh
            return {
                ...state,
                operazioniOneclickHomepage: initialState.operazioniOneclickHomepage,
            };
        case __1.EReduxActionTypes.ONECLICK_RESET:
            return {
                ...initialState,
            };
        case __1.EReduxActionTypes.RESET_ALL: // action invocata dalla logout
            return {
                ...initialState
            };
        default:
            return state;
    }
};
exports.operazioniOneClickReducer = operazioniOneClickReducer;
exports.default = exports.operazioniOneClickReducer;
