"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RataFinanziamentoDtoStatoEnum = exports.RataFinanziamentoDtoToJSON = exports.RataFinanziamentoDtoFromJSONTyped = exports.RataFinanziamentoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function RataFinanziamentoDtoFromJSON(json) {
    return RataFinanziamentoDtoFromJSONTyped(json, false);
}
exports.RataFinanziamentoDtoFromJSON = RataFinanziamentoDtoFromJSON;
function RataFinanziamentoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'numeroRata': !runtime_1.exists(json, 'numeroRata') ? undefined : json['numeroRata'],
        'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
        'statoDesc': !runtime_1.exists(json, 'statoDesc') ? undefined : json['statoDesc'],
        'progressivo': !runtime_1.exists(json, 'progressivo') ? undefined : json['progressivo'],
        'data': !runtime_1.exists(json, 'data') ? undefined : (new Date(json['data'])),
        'divisa': !runtime_1.exists(json, 'divisa') ? undefined : json['divisa'],
        'interessi': !runtime_1.exists(json, 'interessi') ? undefined : json['interessi'],
        'capitale': !runtime_1.exists(json, 'capitale') ? undefined : json['capitale'],
        'oneriRata': !runtime_1.exists(json, 'oneriRata') ? undefined : json['oneriRata'],
        'importo': !runtime_1.exists(json, 'importo') ? undefined : json['importo'],
        'debitoResiduo': !runtime_1.exists(json, 'debitoResiduo') ? undefined : json['debitoResiduo'],
        'mora': !runtime_1.exists(json, 'mora') ? undefined : json['mora'],
    };
}
exports.RataFinanziamentoDtoFromJSONTyped = RataFinanziamentoDtoFromJSONTyped;
function RataFinanziamentoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'numeroRata': value.numeroRata,
        'stato': value.stato,
        'statoDesc': value.statoDesc,
        'progressivo': value.progressivo,
        'data': value.data === undefined ? undefined : (value.data.toISOString()),
        'divisa': value.divisa,
        'interessi': value.interessi,
        'capitale': value.capitale,
        'oneriRata': value.oneriRata,
        'importo': value.importo,
        'debitoResiduo': value.debitoResiduo,
        'mora': value.mora,
    };
}
exports.RataFinanziamentoDtoToJSON = RataFinanziamentoDtoToJSON;
/**
* @export
* @enum {string}
*/
var RataFinanziamentoDtoStatoEnum;
(function (RataFinanziamentoDtoStatoEnum) {
    RataFinanziamentoDtoStatoEnum["PAGATA"] = "PAGATA";
    RataFinanziamentoDtoStatoEnum["MORA"] = "MORA";
    RataFinanziamentoDtoStatoEnum["RESIDUA"] = "RESIDUA";
    RataFinanziamentoDtoStatoEnum["ANT"] = "ANT";
    RataFinanziamentoDtoStatoEnum["AVV"] = "AVV";
})(RataFinanziamentoDtoStatoEnum = exports.RataFinanziamentoDtoStatoEnum || (exports.RataFinanziamentoDtoStatoEnum = {}));
