"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessaggioConvenzioneDtoToJSON = exports.MessaggioConvenzioneDtoFromJSONTyped = exports.MessaggioConvenzioneDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function MessaggioConvenzioneDtoFromJSON(json) {
    return MessaggioConvenzioneDtoFromJSONTyped(json, false);
}
exports.MessaggioConvenzioneDtoFromJSON = MessaggioConvenzioneDtoFromJSON;
function MessaggioConvenzioneDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'lingua': !runtime_1.exists(json, 'lingua') ? undefined : json['lingua'],
        'messaggio': !runtime_1.exists(json, 'messaggio') ? undefined : json['messaggio'],
    };
}
exports.MessaggioConvenzioneDtoFromJSONTyped = MessaggioConvenzioneDtoFromJSONTyped;
function MessaggioConvenzioneDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'lingua': value.lingua,
        'messaggio': value.messaggio,
    };
}
exports.MessaggioConvenzioneDtoToJSON = MessaggioConvenzioneDtoToJSON;
