"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RicercaTitoliInDtoToJSON = exports.RicercaTitoliInDtoFromJSONTyped = exports.RicercaTitoliInDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function RicercaTitoliInDtoFromJSON(json) {
    return RicercaTitoliInDtoFromJSONTyped(json, false);
}
exports.RicercaTitoliInDtoFromJSON = RicercaTitoliInDtoFromJSON;
function RicercaTitoliInDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codiceRapporto': !runtime_1.exists(json, 'codiceRapporto') ? undefined : json['codiceRapporto'],
        'codiceIsin': !runtime_1.exists(json, 'codiceIsin') ? undefined : json['codiceIsin'],
        'raggruppamentoTitolo': !runtime_1.exists(json, 'raggruppamentoTitolo') ? undefined : json['raggruppamentoTitolo'],
        'descrizione': !runtime_1.exists(json, 'descrizione') ? undefined : json['descrizione'],
        'mercatoFiltro': !runtime_1.exists(json, 'mercatoFiltro') ? undefined : json['mercatoFiltro'],
        'flagMercatoChiuso': !runtime_1.exists(json, 'flagMercatoChiuso') ? undefined : json['flagMercatoChiuso'],
        'onlyPreferred': !runtime_1.exists(json, 'onlyPreferred') ? undefined : json['onlyPreferred'],
        'offset': !runtime_1.exists(json, 'offset') ? undefined : json['offset'],
    };
}
exports.RicercaTitoliInDtoFromJSONTyped = RicercaTitoliInDtoFromJSONTyped;
function RicercaTitoliInDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codiceRapporto': value.codiceRapporto,
        'codiceIsin': value.codiceIsin,
        'raggruppamentoTitolo': value.raggruppamentoTitolo,
        'descrizione': value.descrizione,
        'mercatoFiltro': value.mercatoFiltro,
        'flagMercatoChiuso': value.flagMercatoChiuso,
        'onlyPreferred': value.onlyPreferred,
        'offset': value.offset,
    };
}
exports.RicercaTitoliInDtoToJSON = RicercaTitoliInDtoToJSON;
