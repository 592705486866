"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TagliRicaricaCellulareDtoToJSON = exports.TagliRicaricaCellulareDtoFromJSONTyped = exports.TagliRicaricaCellulareDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function TagliRicaricaCellulareDtoFromJSON(json) {
    return TagliRicaricaCellulareDtoFromJSONTyped(json, false);
}
exports.TagliRicaricaCellulareDtoFromJSON = TagliRicaricaCellulareDtoFromJSON;
function TagliRicaricaCellulareDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'rrn': !runtime_1.exists(json, 'rrn') ? undefined : json['rrn'],
        'tagliRicarica': !runtime_1.exists(json, 'tagliRicarica') ? undefined : (json['tagliRicarica'].map(_1.TaglioRicaricaCellulareDtoFromJSON)),
        'annoRrn': !runtime_1.exists(json, 'annoRrn') ? undefined : json['annoRrn'],
        'dataOraRisposta': !runtime_1.exists(json, 'dataOraRisposta') ? undefined : (new Date(json['dataOraRisposta'])),
    };
}
exports.TagliRicaricaCellulareDtoFromJSONTyped = TagliRicaricaCellulareDtoFromJSONTyped;
function TagliRicaricaCellulareDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'rrn': value.rrn,
        'tagliRicarica': value.tagliRicarica === undefined ? undefined : (value.tagliRicarica.map(_1.TaglioRicaricaCellulareDtoToJSON)),
        'annoRrn': value.annoRrn,
        'dataOraRisposta': value.dataOraRisposta === undefined ? undefined : (value.dataOraRisposta.toISOString()),
    };
}
exports.TagliRicaricaCellulareDtoToJSON = TagliRicaricaCellulareDtoToJSON;
