"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NexiServletDtoToJSON = exports.NexiServletDtoFromJSONTyped = exports.NexiServletDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function NexiServletDtoFromJSON(json) {
    return NexiServletDtoFromJSONTyped(json, false);
}
exports.NexiServletDtoFromJSON = NexiServletDtoFromJSON;
function NexiServletDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'url': !runtime_1.exists(json, 'url') ? undefined : json['url'],
        'cookieName': !runtime_1.exists(json, 'cookieName') ? undefined : json['cookieName'],
        'cookieValue': !runtime_1.exists(json, 'cookieValue') ? undefined : json['cookieValue'],
    };
}
exports.NexiServletDtoFromJSONTyped = NexiServletDtoFromJSONTyped;
function NexiServletDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'url': value.url,
        'cookieName': value.cookieName,
        'cookieValue': value.cookieValue,
    };
}
exports.NexiServletDtoToJSON = NexiServletDtoToJSON;
