import { NotificaTributiDto, PagamentoTributiDto } from "@sparkasse/commons";
import ButtonText2 from "component/buttonText2/buttonText2.component";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { navigateToPage, WEBPAGE } from "../../component/history/history.component";
import ItemTributi from "../../component/itemTributiLocali/itemTributi.component";

interface Props {
  notificheTributi: NotificaTributiDto
  setDettaglioTributo: Function
}

const TributiLocaliHome: React.FC<Props> = (props: Props) => {

  const { t } = useTranslation();

  const {
    notificheTributi
  } = props;

  const [notificheDaMostrare, setNotificheDaMostrare] = useState<PagamentoTributiDto[]>([]);

  useEffect(() => {
    notificheTributi && notificheTributi.listaTributi && setNotificheDaMostrare(notificheTributi.listaTributi?.slice(0, 2));
  }, [notificheTributi]);

  const onClickHandler = (item: PagamentoTributiDto) => {
    props.setDettaglioTributo(item);
    navigateToPage(WEBPAGE.DettaglioTributiLocali.url);
  };

  const onNavigate = () => {
    navigateToPage(WEBPAGE.ElencoTributiLocali.url);
  };

  return (
    <>
      <div className="one-click">
        <div className={`${notificheTributi.tipoNotifica === 'ACCETTAZIONE' ? '' : 'd-flex align-items-center'}`}>

          <label className={`text-one-click ${notificheTributi.tipoNotifica === 'ACCETTAZIONE' ? '' : 'm-0'}`}>
            {t('home.tributiLocali.titolo')}
          </label>

          {notificheTributi.listaTributi && notificheTributi.listaTributi.length > 2 && notificheTributi.tipoNotifica !== 'ACCETTAZIONE' &&
            <div className="chip-tributi ml-2">
              <span
                className="chip-tributi-content"
                style={{
                  marginLeft: notificheTributi.listaTributi?.length > 9 ? 0 : -6,
                  marginRight: notificheTributi.listaTributi?.length > 9 ? 0 : -6
                }}
              >
                {notificheTributi.listaTributi?.length}
              </span>
            </div>
          }

          {notificheTributi && notificheTributi.tipoNotifica === 'ACCETTAZIONE' &&
            <label className="text-one-click-description">
              {t("home.tributiLocali.informativa")}
            </label>
          }

        </div>
        {
          notificheDaMostrare && notificheDaMostrare.map((pagamento: PagamentoTributiDto, i: number) => {
            return (
              <ItemTributi key={i} pagamento={pagamento} click={onClickHandler} />
            )
          })
        }
        <ButtonText2
          img="navigazione_avanti"
          descrizione={t(`home.tributiLocali.${notificheTributi.tipoNotifica === 'ACCETTAZIONE' ? 'visualizzaCondizioni' : 'vaiATributi'}`)}
          className="oneclick-aggiungi"
          onClick={onNavigate}
        />
      </div>
    </>
  );
};

export default TributiLocaliHome;
