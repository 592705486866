"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BreakdownItemDtoToJSON = exports.BreakdownItemDtoFromJSONTyped = exports.BreakdownItemDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function BreakdownItemDtoFromJSON(json) {
    return BreakdownItemDtoFromJSONTyped(json, false);
}
exports.BreakdownItemDtoFromJSON = BreakdownItemDtoFromJSON;
function BreakdownItemDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'aggregation': !runtime_1.exists(json, 'aggregation') ? undefined : json['aggregation'],
        'balance': !runtime_1.exists(json, 'balance') ? undefined : json['balance'],
        'balancePercentage': !runtime_1.exists(json, 'balancePercentage') ? undefined : json['balancePercentage'],
        'bankCode': !runtime_1.exists(json, 'bankCode') ? undefined : json['bankCode'],
        'color': !runtime_1.exists(json, 'color') ? undefined : json['color'],
        'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'instruments': !runtime_1.exists(json, 'instruments') ? undefined : (json['instruments'].map(_1.BreakdownDetailDtoFromJSON)),
        'key': !runtime_1.exists(json, 'key') ? undefined : json['key'],
        'message': !runtime_1.exists(json, 'message') ? undefined : json['message'],
        'userId': !runtime_1.exists(json, 'userId') ? undefined : json['userId'],
    };
}
exports.BreakdownItemDtoFromJSONTyped = BreakdownItemDtoFromJSONTyped;
function BreakdownItemDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'aggregation': value.aggregation,
        'balance': value.balance,
        'balancePercentage': value.balancePercentage,
        'bankCode': value.bankCode,
        'color': value.color,
        'description': value.description,
        'id': value.id,
        'instruments': value.instruments === undefined ? undefined : (value.instruments.map(_1.BreakdownDetailDtoToJSON)),
        'key': value.key,
        'message': value.message,
        'userId': value.userId,
    };
}
exports.BreakdownItemDtoToJSON = BreakdownItemDtoToJSON;
