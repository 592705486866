import { Environments, getCustomEnv, pushPopUp, selectedEnv } from "@sparkasse/commons";
import PopUpModal from "component/popUpModal/popUpModal.component";
import { getItem } from "index";
import React, { useEffect, useState } from "react";

interface Props {
  href: string | undefined
  successPath: string
  successParam: string
  onSuccess: (successHref: string) => void
  onError: () => void
  popUpWaiter: PopUpWaiter
  pushPopUp: typeof pushPopUp
  successWithPollingPath?: string
  onSuccessPolling?: (successHref: string) => void
  psd2PollingAuth?: boolean
}

interface WindowOpen {
  window: Window
  onSuccess: (successHref: string) => void
  onError: () => void
  onSuccessPolling?: (successHref: string) => void

}

interface PopUpWaiter {
  title: string
  body: string
  koButtonText?: string
}

const ThirdPartyWindow: React.FC<Props> = (props: Props) => {

  const {
    href,
    successPath,
    successParam,
    onError,
    onSuccess,
    popUpWaiter,
    pushPopUp,
    successWithPollingPath,
    onSuccessPolling,
    psd2PollingAuth
  } = props;

  const [windowOpen, setWindowOpen] = useState<WindowOpen | undefined>(undefined);

  const onWindowError = () => {
    console.info('ThirdPartyWindow | onWindowError');
    windowOpen?.window?.close();
    setWindowOpen(undefined);
    windowOpen?.onError();
  };

  const onWindowSuccess = (successHref: string) => {
    console.info('ThirdPartyWindow | onWindowSuccess');
    setWindowOpen(undefined);
    windowOpen?.window?.close();
    windowOpen?.onSuccess(successHref);
  };

  const onWindowSuccessPolling = (successHref: string) => {
    console.info('ThirdPartyWindow | onWindowSuccess');
    setWindowOpen(undefined);
    windowOpen?.window?.close();
    if (!!windowOpen?.onSuccessPolling) {
      windowOpen?.onSuccessPolling(successHref);
    }
  }

  const checkResult = (href: string) => {

    console.debug('\n\nThirdPartyWindow | Checking href:', href);
    if (!!onSuccessPolling &&
      successWithPollingPath &&
      href?.indexOf(successWithPollingPath) > -1 &&
      href?.indexOf(successParam) > -1 &&
      psd2PollingAuth) {
      onWindowSuccessPolling(href);
      return;
    } else if (href?.indexOf(successParam) > -1) {
      onWindowSuccess(href);
      return;
    }

    onWindowError();

  };

  const startCheckingWindow = (windowOpen: WindowOpen) => {

    var leftDomain = false;
    const interval = setInterval(function () {

      if (!windowOpen.window) {
        console.error('ThirdPartyWindow | Window has never been open!');

        clearInterval(interval);
        onWindowError();
        return;
      }

      try {

        if (windowOpen.window.document.domain === document.domain) {

          console.debug('\n\nThirdPartyWindow | Same domain href:', windowOpen);

          const isCallbackUrl = windowOpen.window.document?.location?.href?.indexOf(successPath) > 0;

          if ((leftDomain && isCallbackUrl && windowOpen.window.document.readyState === "complete") || (href?.startsWith(Environments[getCustomEnv() || selectedEnv].corebanking) && windowOpen.window.document?.location?.href?.indexOf(successParam) > -1)) {
            // we're here when the child window returned to our domain
            clearInterval(interval);
            checkResult(windowOpen.window.document.location.href);
          }

        } else {
          // this code should never be reached,
          // as the x-site security check throws
          // but just in case
          if (windowOpen.window.closed) {
            clearInterval(interval);
            onWindowError();
            return;
          }
          leftDomain = true;
        }

      } catch (e) {
        // we're here when the child window has been navigated away or closed
        if (windowOpen.window.closed) {
          console.error('ThirdPartyWindow | Window has been closed!');

          clearInterval(interval);
          onWindowError();
          return;
        }
        // navigated to another domain
        leftDomain = true;
      }

    }, 500);

  };

  const openWindowPsd2 = (href: string, onSuccess: (successHref: string) => void, onError: () => void) => {

    console.info('ThirdPartyWindow | Opening window...');

    if (!!windowOpen) {
      console.error('ThirdPartyWindow | Could not open another window in parallel.');
      return;
    }

    const redirectURI = href;
    const encodedComponentRedirectURI = encodeURIComponent(redirectURI);

    console.debug('redirect uri:', redirectURI);
    console.debug('encoded component redirect uri:', encodedComponentRedirectURI);

    getItem('jwt').then(jwt => {

      console.debug('jwt:', jwt);

      if (!jwt) {
        console.error('Redirect | Could not open window: jwt missing.');
        return;
      }

      const encodedComponentJwt = btoa(jwt);
      console.debug('encoded component jwt:', encodedComponentJwt);

      const domain = Environments[getCustomEnv() || selectedEnv].corebanking;
      let url = '';
      if (href.startsWith(domain)) {
        url = href;
      } else {
        url = domain + '/api/private/redirect?url=' + encodedComponentRedirectURI + '&jwt=' + encodedComponentJwt;
      }
      console.log('url to be opened:', url);

      const newWindow = window.open(url) as Window;

      const _windowOpen = {
        window: newWindow,
        onSuccess,
        onError,
        onSuccessPolling
      };

      if (!newWindow || newWindow.closed || typeof newWindow.closed == 'undefined') {
        // Pop-up (window) blocked by browser, showing an application pop-up that requests the user to click on the pop-up
        console.log('ThirdPartyWindow | Pop-up blocked by browser, user action needed - newWindow: ', newWindow);
        pushPopUp({
          type: 'okOnly',
          title: 'general.popUpBlockerPsd2Modal.title',
          message: 'general.popUpBlockerPsd2Modal.description',
          buttonOk: {
            text: 'general.popUpBlockerPsd2Modal.open',
            action: () => {
              const newWindow = window.open(url) as Window;
              const __windowOpen = {
                window: newWindow,
                onSuccess,
                onError,
                onSuccessPolling
              };
              setWindowOpen(__windowOpen);
            }
          }
        });
      } else {
        // Window open
        setWindowOpen(_windowOpen);
      }

    }).catch(() => {
      console.error('Redirect | Could not open window: Error while getting jwt.');
    });

  };

  useEffect(() => {

    console.debug('ThirdPartyWindow | windowOpen:', windowOpen);
    if (!!windowOpen) {
      startCheckingWindow(windowOpen);
    }

  }, [windowOpen]);

  useEffect(() => {
    if (!!href) {

      console.info('ThirdPartyWindow | New href: ', href);
      setWindowOpen(undefined);
      windowOpen?.window?.close();
      openWindowPsd2(href, onSuccess, onError);

    }
  }, [href]);

  return (
    <>

      <PopUpModal
        visible={!!windowOpen}
        onClose={onWindowError}
        popUp={{
          type: 'alert',
          title: popUpWaiter.title,
          body: popUpWaiter.body,
          buttonKo: {
            text: popUpWaiter.koButtonText,
            action: onWindowError
          }
        }}
      />

    </>
  );

};

export default ThirdPartyWindow;
