"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChiaveValoreDtoToJSON = exports.ChiaveValoreDtoFromJSONTyped = exports.ChiaveValoreDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function ChiaveValoreDtoFromJSON(json) {
    return ChiaveValoreDtoFromJSONTyped(json, false);
}
exports.ChiaveValoreDtoFromJSON = ChiaveValoreDtoFromJSON;
function ChiaveValoreDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'chiave': !runtime_1.exists(json, 'chiave') ? undefined : json['chiave'],
        'valore': !runtime_1.exists(json, 'valore') ? undefined : json['valore'],
    };
}
exports.ChiaveValoreDtoFromJSONTyped = ChiaveValoreDtoFromJSONTyped;
function ChiaveValoreDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'chiave': value.chiave,
        'valore': value.valore,
    };
}
exports.ChiaveValoreDtoToJSON = ChiaveValoreDtoToJSON;
