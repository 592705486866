"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RigaSezioneAcciseDtoToJSON = exports.RigaSezioneAcciseDtoFromJSONTyped = exports.RigaSezioneAcciseDtoFromJSON = void 0;
function RigaSezioneAcciseDtoFromJSON(json) {
    return RigaSezioneAcciseDtoFromJSONTyped(json, false);
}
exports.RigaSezioneAcciseDtoFromJSON = RigaSezioneAcciseDtoFromJSON;
function RigaSezioneAcciseDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codiceEnte': json['codiceEnte'],
        'provincia': json['provincia'],
        'codiceTributo': json['codiceTributo'],
        'codiceIdentificativo': json['codiceIdentificativo'],
        'rateazione': json['rateazione'],
        'mese': json['mese'],
        'annoRiferimento': json['annoRiferimento'],
        'importoDebito': json['importoDebito'],
    };
}
exports.RigaSezioneAcciseDtoFromJSONTyped = RigaSezioneAcciseDtoFromJSONTyped;
function RigaSezioneAcciseDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codiceEnte': value.codiceEnte,
        'provincia': value.provincia,
        'codiceTributo': value.codiceTributo,
        'codiceIdentificativo': value.codiceIdentificativo,
        'rateazione': value.rateazione,
        'mese': value.mese,
        'annoRiferimento': value.annoRiferimento,
        'importoDebito': value.importoDebito,
    };
}
exports.RigaSezioneAcciseDtoToJSON = RigaSezioneAcciseDtoToJSON;
