"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.messaggiReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const initialState = {
    accettazioni: undefined,
    accettazioniAssistenteVocale: undefined,
    bloccanti: undefined,
    comunicazioni: undefined,
    comunicazioneSelezionata: (0, api_rest_1.MessaggioBodyDtoFromJSON)({}),
    form: {
        accetta: false
    }
};
const messaggiReducer = (state = initialState, action) => {
    var _a, _b, _c, _d;
    switch (action.type) {
        case __1.EReduxActionTypes.MESSAGGI_ACCETTATO:
            return {
                ...state,
                form: {
                    ...state.form,
                    accetta: action.payload
                }
            };
        case __1.EReduxActionTypes.MESSAGGI_GET_ACCETTAZIONI_SUCCESS:
            return {
                ...state,
                accettazioni: action.payload,
                form: {
                    ...state.form,
                    funzionalitaAccettazione: action.funzione
                }
            };
        case __1.EReduxActionTypes.MESSAGGI_GET_ACCETTAZIONI_ASSISTENTE_VOCALE_SUCCESS:
            return {
                ...state,
                accettazioniAssistenteVocale: action.payload
            };
        case __1.EReduxActionTypes.MESSAGGI_RESET_ACCETTAZIONI:
            return {
                ...state,
                accettazioni: initialState.accettazioni
            };
        case __1.EReduxActionTypes.MESSAGGI_RESET_ACCETTAZIONI_ASSISTENTE_VOCALE:
            return {
                ...state,
                accettazioniAssistenteVocale: initialState.accettazioniAssistenteVocale
            };
        case __1.EReduxActionTypes.MESSAGGI_GET_BLOCCANTI_SUCCESS:
            return {
                ...state,
                bloccanti: action.payload
            };
        case __1.EReduxActionTypes.MESSAGGI_GET_COMUNICAZIONI_SUCCESS:
            return {
                ...state,
                comunicazioni: action.payload
            };
        case __1.EReduxActionTypes.MESSAGGI_SET_MESSAGGIO_COMUNICAZIONE_SELEZIONATO:
            return {
                ...state,
                comunicazioneSelezionata: action.payload
            };
        case __1.EReduxActionTypes.MESSAGGI_GET_NUMERO_MESSAGGI_NON_LETTI_SUCCESS:
            return {
                ...state,
                numeroMessaggiNonLetti: action.payload
            };
        case __1.EReduxActionTypes.MESSAGGI_SEGNA_COME_LETTO_SUCCESS:
            return {
                ...state,
                accettazioni: ((_a = state.accettazioni) === null || _a === void 0 ? void 0 : _a.length)
                    ? state.accettazioni.map(a => ({
                        ...a,
                        letto: a.idMessaggio === action.payload ? true : a.letto
                    }))
                    : state.accettazioni,
                bloccanti: ((_b = state.bloccanti) === null || _b === void 0 ? void 0 : _b.length)
                    ? state.bloccanti.filter(a => a.idMessaggio != action.payload)
                    : state.bloccanti,
                comunicazioni: ((_c = state.comunicazioni) === null || _c === void 0 ? void 0 : _c.length)
                    ? state.comunicazioni.map(a => ({
                        ...a,
                        letto: a.idMessaggio === action.payload ? true : a.letto
                    }))
                    : state.comunicazioni
            };
        case __1.EReduxActionTypes.MESSAGGI_UPDATE_BLOCCANTI:
            return {
                ...state,
                bloccanti: (_d = state.bloccanti) === null || _d === void 0 ? void 0 : _d.filter((e) => e.idMessaggio !== action.payload)
            };
        case __1.EReduxActionTypes.MESSAGGI_RESET_BLOCCANTI:
            return {
                ...state,
                bloccanti: initialState.bloccanti
            };
        case __1.EReduxActionTypes.RESET_ALL: // action invocata dalla logout
            return {
                ...initialState
            };
        default:
            return state;
    }
};
exports.messaggiReducer = messaggiReducer;
exports.default = exports.messaggiReducer;
