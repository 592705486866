"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EsitoAutorizzazioneDisposizioneMandatoSDDDtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneMandatoSDDDtoToJSON = exports.EsitoAutorizzazioneDisposizioneMandatoSDDDtoFromJSONTyped = exports.EsitoAutorizzazioneDisposizioneMandatoSDDDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function EsitoAutorizzazioneDisposizioneMandatoSDDDtoFromJSON(json) {
    return EsitoAutorizzazioneDisposizioneMandatoSDDDtoFromJSONTyped(json, false);
}
exports.EsitoAutorizzazioneDisposizioneMandatoSDDDtoFromJSON = EsitoAutorizzazioneDisposizioneMandatoSDDDtoFromJSON;
function EsitoAutorizzazioneDisposizioneMandatoSDDDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
        'codiceErrore': !runtime_1.exists(json, 'codiceErrore') ? undefined : json['codiceErrore'],
        'informazioniAutenticazioneDispositiva': !runtime_1.exists(json, 'informazioniAutenticazioneDispositiva') ? undefined : _1.InformazioniAutenticazioneDispositivaDtoFromJSON(json['informazioniAutenticazioneDispositiva']),
        'disposizione': !runtime_1.exists(json, 'disposizione') ? undefined : _1.MandatoSDDDtoFromJSON(json['disposizione']),
    };
}
exports.EsitoAutorizzazioneDisposizioneMandatoSDDDtoFromJSONTyped = EsitoAutorizzazioneDisposizioneMandatoSDDDtoFromJSONTyped;
function EsitoAutorizzazioneDisposizioneMandatoSDDDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'stato': value.stato,
        'codiceErrore': value.codiceErrore,
        'informazioniAutenticazioneDispositiva': _1.InformazioniAutenticazioneDispositivaDtoToJSON(value.informazioniAutenticazioneDispositiva),
        'disposizione': _1.MandatoSDDDtoToJSON(value.disposizione),
    };
}
exports.EsitoAutorizzazioneDisposizioneMandatoSDDDtoToJSON = EsitoAutorizzazioneDisposizioneMandatoSDDDtoToJSON;
/**
* @export
* @enum {string}
*/
var EsitoAutorizzazioneDisposizioneMandatoSDDDtoStatoEnum;
(function (EsitoAutorizzazioneDisposizioneMandatoSDDDtoStatoEnum) {
    EsitoAutorizzazioneDisposizioneMandatoSDDDtoStatoEnum["OK"] = "OK";
    EsitoAutorizzazioneDisposizioneMandatoSDDDtoStatoEnum["CREDENZIALE_ERRATA"] = "CREDENZIALE_ERRATA";
    EsitoAutorizzazioneDisposizioneMandatoSDDDtoStatoEnum["ATTENDERE"] = "ATTENDERE";
    EsitoAutorizzazioneDisposizioneMandatoSDDDtoStatoEnum["ACCOUNT_BLOCCATO"] = "ACCOUNT_BLOCCATO";
    EsitoAutorizzazioneDisposizioneMandatoSDDDtoStatoEnum["ACCP"] = "ACCP";
    EsitoAutorizzazioneDisposizioneMandatoSDDDtoStatoEnum["ACSC"] = "ACSC";
    EsitoAutorizzazioneDisposizioneMandatoSDDDtoStatoEnum["ACSP"] = "ACSP";
    EsitoAutorizzazioneDisposizioneMandatoSDDDtoStatoEnum["ACTC"] = "ACTC";
    EsitoAutorizzazioneDisposizioneMandatoSDDDtoStatoEnum["ACWC"] = "ACWC";
    EsitoAutorizzazioneDisposizioneMandatoSDDDtoStatoEnum["ACWP"] = "ACWP";
    EsitoAutorizzazioneDisposizioneMandatoSDDDtoStatoEnum["RCVD"] = "RCVD";
    EsitoAutorizzazioneDisposizioneMandatoSDDDtoStatoEnum["PDNG"] = "PDNG";
    EsitoAutorizzazioneDisposizioneMandatoSDDDtoStatoEnum["RJCT"] = "RJCT";
    EsitoAutorizzazioneDisposizioneMandatoSDDDtoStatoEnum["CANC"] = "CANC";
})(EsitoAutorizzazioneDisposizioneMandatoSDDDtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneMandatoSDDDtoStatoEnum || (exports.EsitoAutorizzazioneDisposizioneMandatoSDDDtoStatoEnum = {}));
