import { estendiSessione, incrementaIntervalli, infoSessione, resetIntervalliInattivi } from "@sparkasse/commons";
import { navigateToLogout } from "component/history/history.component";
import PopUpModal from "component/popUpModal/popUpModal.component";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AppState } from "reducers";
import { AnyAction, bindActionCreators, Dispatch } from "redux";

const events = ["click", "keydown", "mousewheel", "mouseDown"];

const SessionHandler = (props: Props) => {

  const { t } = useTranslation();

  const {
    infoSessione: {
      sessioneInScadenza,
      intervalloSessioneInScadenza,
      intervalloSessioneScaduta,
      logoutSessione,
      lunghezzaIntervalli,
      sessioneInattiva,
      intervalliInattivi,
      intervalloCorrente,
    },
    incrementaIntervalli,
    resetIntervalliInattivi,
    estendiSessione
  } = props;

  useEffect(() => {
    const _plan = creaTicker();
    return () => {
      clearInterval(_plan);
    };
  }, []);

  const creaTicker = () => {
    const logActivity = (e: any) => {
      resetIntervalliInattivi();
    };
    return setInterval(() => {
      if (!logoutSessione) {
        incrementaIntervalli();
        events.forEach(event => {
          document.removeEventListener(event, logActivity);
          document.addEventListener(event, logActivity);
        });
      }
    }, lunghezzaIntervalli * 1000);
  };

  useEffect(() => {
    if (logoutSessione) {
      onCloseAvvisoScadenzaSessione();
    } else if (sessioneInattiva) {
      console.log('Shoing pop-up');
    } else if (sessioneInScadenza) {
      estendiSessione();
    }
  }, [sessioneInScadenza, logoutSessione, sessioneInattiva]);

  const estendiSessioneInScadenza = () => {
    resetIntervalliInattivi(true);
    estendiSessione();
  };

  const onCloseAvvisoScadenzaSessione = () => {
    navigateToLogout()
  };

  const onLogout = () => {
    onCloseAvvisoScadenzaSessione();
  };

  return (
    <>

      <div className="session-handler">
        {`${intervalliInattivi || 0}/${intervalloSessioneInScadenza} - ${intervalloCorrente || 0}/${intervalloSessioneScaduta}`}
      </div>

      {sessioneInattiva && (
        <PopUpModal
          visible={true}
          popUp={{
            type: 'confirm',
            title: t("common.errors.attenzione"),
            message: t('common.errors.sessionExpiring'),
            buttonOk: {
              text: t('button.continua'),
              action: estendiSessioneInScadenza
            },
            buttonKo: {
              text: t('button.esci'),
              action: onLogout
            }
          }}
        />
      )}

    </>
  );
};

export type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const mapStateToProps = (state: AppState) => ({
  infoSessione: infoSessione(state.profilo)
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
  incrementaIntervalli,
  resetIntervalliInattivi,
  estendiSessione
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SessionHandler);
