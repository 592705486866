"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuestionOptionDtoToJSON = exports.QuestionOptionDtoFromJSONTyped = exports.QuestionOptionDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function QuestionOptionDtoFromJSON(json) {
    return QuestionOptionDtoFromJSONTyped(json, false);
}
exports.QuestionOptionDtoFromJSON = QuestionOptionDtoFromJSON;
function QuestionOptionDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'bankCode': !runtime_1.exists(json, 'bankCode') ? undefined : json['bankCode'],
        'inputNumberDropbox': !runtime_1.exists(json, 'inputNumberDropbox') ? undefined : json['inputNumberDropbox'],
        'inputTextDropbox': !runtime_1.exists(json, 'inputTextDropbox') ? undefined : json['inputTextDropbox'],
        'message': !runtime_1.exists(json, 'message') ? undefined : json['message'],
        'order': !runtime_1.exists(json, 'order') ? undefined : json['order'],
        'suggestValues': !runtime_1.exists(json, 'suggestValues') ? undefined : json['suggestValues'],
        'text': !runtime_1.exists(json, 'text') ? undefined : json['text'],
        'userId': !runtime_1.exists(json, 'userId') ? undefined : json['userId'],
        'value': !runtime_1.exists(json, 'value') ? undefined : json['value'],
    };
}
exports.QuestionOptionDtoFromJSONTyped = QuestionOptionDtoFromJSONTyped;
function QuestionOptionDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'bankCode': value.bankCode,
        'inputNumberDropbox': value.inputNumberDropbox,
        'inputTextDropbox': value.inputTextDropbox,
        'message': value.message,
        'order': value.order,
        'suggestValues': value.suggestValues,
        'text': value.text,
        'userId': value.userId,
        'value': value.value,
    };
}
exports.QuestionOptionDtoToJSON = QuestionOptionDtoToJSON;
