"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EsitoVerificaContrattoDtoStatoEnum = exports.EsitoVerificaContrattoDtoToJSON = exports.EsitoVerificaContrattoDtoFromJSONTyped = exports.EsitoVerificaContrattoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function EsitoVerificaContrattoDtoFromJSON(json) {
    return EsitoVerificaContrattoDtoFromJSONTyped(json, false);
}
exports.EsitoVerificaContrattoDtoFromJSON = EsitoVerificaContrattoDtoFromJSON;
function EsitoVerificaContrattoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
        'certificateInfo': !runtime_1.exists(json, 'certificateInfo') ? undefined : _1.CertificatoInfoDtoFromJSON(json['certificateInfo']),
        'jobUrl': !runtime_1.exists(json, 'jobUrl') ? undefined : json['jobUrl'],
        'shortJobUrl': !runtime_1.exists(json, 'shortJobUrl') ? undefined : json['shortJobUrl'],
    };
}
exports.EsitoVerificaContrattoDtoFromJSONTyped = EsitoVerificaContrattoDtoFromJSONTyped;
function EsitoVerificaContrattoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'stato': value.stato,
        'certificateInfo': _1.CertificatoInfoDtoToJSON(value.certificateInfo),
        'jobUrl': value.jobUrl,
        'shortJobUrl': value.shortJobUrl,
    };
}
exports.EsitoVerificaContrattoDtoToJSON = EsitoVerificaContrattoDtoToJSON;
/**
* @export
* @enum {string}
*/
var EsitoVerificaContrattoDtoStatoEnum;
(function (EsitoVerificaContrattoDtoStatoEnum) {
    EsitoVerificaContrattoDtoStatoEnum["CERTIFICATO_NON_PRESENTE"] = "CERTIFICATO_NON_PRESENTE";
    EsitoVerificaContrattoDtoStatoEnum["CERTIFICATO_INVIATO"] = "CERTIFICATO_INVIATO";
    EsitoVerificaContrattoDtoStatoEnum["CERTIFICATO_POLLING_SCADUTO"] = "CERTIFICATO_POLLING_SCADUTO";
    EsitoVerificaContrattoDtoStatoEnum["CONTRATTO_COMPILATO"] = "CONTRATTO_COMPILATO";
    EsitoVerificaContrattoDtoStatoEnum["CONTRATTO_POLLING_SCADUTO"] = "CONTRATTO_POLLING_SCADUTO";
    EsitoVerificaContrattoDtoStatoEnum["CONTRATTO_FIRMATO"] = "CONTRATTO_FIRMATO";
})(EsitoVerificaContrattoDtoStatoEnum = exports.EsitoVerificaContrattoDtoStatoEnum || (exports.EsitoVerificaContrattoDtoStatoEnum = {}));
