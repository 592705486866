"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArchivioBozzeInDtoTipoOrdinamentoEnum = exports.ArchivioBozzeInDtoToJSON = exports.ArchivioBozzeInDtoFromJSONTyped = exports.ArchivioBozzeInDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function ArchivioBozzeInDtoFromJSON(json) {
    return ArchivioBozzeInDtoFromJSONTyped(json, false);
}
exports.ArchivioBozzeInDtoFromJSON = ArchivioBozzeInDtoFromJSON;
function ArchivioBozzeInDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'numeroRisultati': !runtime_1.exists(json, 'numeroRisultati') ? undefined : json['numeroRisultati'],
        'naturaMovimento': !runtime_1.exists(json, 'naturaMovimento') ? undefined : json['naturaMovimento'],
        'motivazionePagamento': !runtime_1.exists(json, 'motivazionePagamento') ? undefined : json['motivazionePagamento'],
        'codiceRapporto': !runtime_1.exists(json, 'codiceRapporto') ? undefined : json['codiceRapporto'],
        'tipologia': !runtime_1.exists(json, 'tipologia') ? undefined : json['tipologia'],
        'tipoOrdinamento': !runtime_1.exists(json, 'tipoOrdinamento') ? undefined : json['tipoOrdinamento'],
        'billerEsclusi': !runtime_1.exists(json, 'billerEsclusi') ? undefined : json['billerEsclusi'],
        'paroleChiave': !runtime_1.exists(json, 'paroleChiave') ? undefined : json['paroleChiave'],
        'codiceFunzione': json['codiceFunzione'],
    };
}
exports.ArchivioBozzeInDtoFromJSONTyped = ArchivioBozzeInDtoFromJSONTyped;
function ArchivioBozzeInDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'numeroRisultati': value.numeroRisultati,
        'naturaMovimento': value.naturaMovimento,
        'motivazionePagamento': value.motivazionePagamento,
        'codiceRapporto': value.codiceRapporto,
        'tipologia': value.tipologia,
        'tipoOrdinamento': value.tipoOrdinamento,
        'billerEsclusi': value.billerEsclusi,
        'paroleChiave': value.paroleChiave,
        'codiceFunzione': value.codiceFunzione,
    };
}
exports.ArchivioBozzeInDtoToJSON = ArchivioBozzeInDtoToJSON;
/**
* @export
* @enum {string}
*/
var ArchivioBozzeInDtoTipoOrdinamentoEnum;
(function (ArchivioBozzeInDtoTipoOrdinamentoEnum) {
    ArchivioBozzeInDtoTipoOrdinamentoEnum["ASC"] = "ASC";
    ArchivioBozzeInDtoTipoOrdinamentoEnum["DESC"] = "DESC";
})(ArchivioBozzeInDtoTipoOrdinamentoEnum = exports.ArchivioBozzeInDtoTipoOrdinamentoEnum || (exports.ArchivioBozzeInDtoTipoOrdinamentoEnum = {}));
