"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.prestitiReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const initialState = {
    parametri: (0, api_rest_1.ParametriFinanziamentiDtoFromJSON)({}),
    form: {},
    rateResiduePrestiti: (0, api_rest_1.RateFinanziamentoDtoFromJSON)({}),
    ratePagatePrestiti: (0, api_rest_1.RateFinanziamentoDtoFromJSON)({}),
    rateMoraPrestiti: (0, api_rest_1.RateFinanziamentoDtoFromJSON)({}),
    pianoAmmortamentoPrestiti: (0, api_rest_1.RateFinanziamentoDtoFromJSON)({}),
    dashboardPrestiti: (0, api_rest_1.FinanziamentiDashboardDtoFromJSON)({})
};
const prestitiReducer = (state = initialState, action) => {
    switch (action.type) {
        case __1.EReduxActionTypes.PRESTITI_GET_PARAMETRI_SUCCESS:
            return {
                ...initialState,
                parametri: action.payload
            };
        case __1.EReduxActionTypes.PRESTITI_GET_RATE_RESIDUE_SUCCESS:
            return {
                ...state,
                rateResiduePrestiti: action.payload
            };
        case __1.EReduxActionTypes.PRESTITI_GET_RATE_PAGATE_SUCCESS:
            return {
                ...state,
                ratePagatePrestiti: action.payload
            };
        case __1.EReduxActionTypes.PRESTITI_GET_RATE_MORA_SUCCESS:
            return {
                ...state,
                rateMoraPrestiti: action.payload
            };
        case __1.EReduxActionTypes.PRESTITI_GET_PIANO_AMMORTAMENTO_SUCCESS:
            return {
                ...state,
                pianoAmmortamentoPrestiti: action.payload
            };
        case __1.EReduxActionTypes.PRESTITI_SET_RAPPORTO_SELEZIONATO:
            return {
                ...state,
                form: {
                    ...state.form,
                    rapportoSelezionato: action.payload
                }
            };
        case __1.EReduxActionTypes.PRESTITI_GET_DASHBOARD_SUCCESS:
            return {
                ...state,
                dashboardPrestiti: action.payload
            };
        case __1.EReduxActionTypes.PRESTITI_RESET_RATE:
            return {
                ...state,
                ratePagatePrestiti: initialState.ratePagatePrestiti,
                rateMoraPrestiti: initialState.rateMoraPrestiti,
                rateResiduePrestiti: initialState.rateResiduePrestiti
            };
        case __1.EReduxActionTypes.PRESTITI_RESET_DASHBOARD:
            return {
                ...state,
                dashboardPrestiti: initialState.dashboardPrestiti
            };
        case __1.EReduxActionTypes.PRESTITI_RESET:
            return {
                ...initialState
            };
        case __1.EReduxActionTypes.GENERAL_REFRESH_WIDGETS: // action invocata tramite pull to refresh
            return {
                ...state,
                parametri: initialState.parametri,
                dashboardPrestiti: initialState.dashboardPrestiti
            };
        case __1.EReduxActionTypes.RESET_ALL: // action invocata dalla logout
            return {
                ...initialState
            };
        default:
            return state;
    }
};
exports.prestitiReducer = prestitiReducer;
exports.default = exports.prestitiReducer;
