"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocumentiFonteDocumento = exports.DocumentiTipoIntervallo = void 0;
var DocumentiTipoIntervallo;
(function (DocumentiTipoIntervallo) {
    DocumentiTipoIntervallo["Ultimi5"] = "ultimi5";
    DocumentiTipoIntervallo["Personalizzato"] = "personalizzato";
})(DocumentiTipoIntervallo = exports.DocumentiTipoIntervallo || (exports.DocumentiTipoIntervallo = {}));
var DocumentiFonteDocumento;
(function (DocumentiFonteDocumento) {
    DocumentiFonteDocumento["Comunicazioni"] = "comunicazioni";
    DocumentiFonteDocumento["ContabiliCassa"] = "contabiliCassa";
})(DocumentiFonteDocumento = exports.DocumentiFonteDocumento || (exports.DocumentiFonteDocumento = {}));
