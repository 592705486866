"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RavControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class RavControllerApi extends runtime.BaseAPI {
    /**
     * Informazioni sull\'autenticazione dispositiva per un rav
     */
    authenticationInformationRavRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling authenticationInformationRav.');
            }
            const queryParameters = {};
            if (requestParameters.chiamataUscente !== undefined) {
                queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
            }
            if (requestParameters.eseguiInit !== undefined) {
                queryParameters['eseguiInit'] = requestParameters.eseguiInit;
            }
            if (requestParameters.offline !== undefined) {
                queryParameters['offline'] = requestParameters.offline;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/rav/infoautenticazione/{idDispositiva}`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva per un rav
     */
    authenticationInformationRav(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.authenticationInformationRavRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva per la revoca
     */
    authenticationRevocationInformationRavRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.chiamataUscente !== undefined) {
                queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
            }
            if (requestParameters.eseguiInit !== undefined) {
                queryParameters['eseguiInit'] = requestParameters.eseguiInit;
            }
            if (requestParameters.offline !== undefined) {
                queryParameters['offline'] = requestParameters.offline;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/rav/infoautenticazione/revoca`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva per la revoca
     */
    authenticationRevocationInformationRav(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.authenticationRevocationInformationRavRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Autorizzazione di una disposizione di revoca rav
     */
    authenticationRevocationRavRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.ravRevocaRequestInDto === null || requestParameters.ravRevocaRequestInDto === undefined) {
                throw new runtime.RequiredError('ravRevocaRequestInDto', 'Required parameter requestParameters.ravRevocaRequestInDto was null or undefined when calling authenticationRevocationRav.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/rav/autorizza/revoca`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.RavRevocaRequestInDtoToJSON(requestParameters.ravRevocaRequestInDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.EsitoAutorizzazioneDisposizioneRavDtoFromJSON(jsonValue));
        });
    }
    /**
     * Autorizzazione di una disposizione di revoca rav
     */
    authenticationRevocationRav(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.authenticationRevocationRavRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Autorizzazione di una disposizione di rav
     */
    authorizeRavRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling authorizeRav.');
            }
            if (requestParameters.credenzialeDispositivaDto === null || requestParameters.credenzialeDispositivaDto === undefined) {
                throw new runtime.RequiredError('credenzialeDispositivaDto', 'Required parameter requestParameters.credenzialeDispositivaDto was null or undefined when calling authorizeRav.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/rav/autorizza/{idDispositiva}`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CredenzialeDispositivaDtoToJSON(requestParameters.credenzialeDispositivaDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.EsitoAutorizzazioneDisposizioneRavDtoFromJSON(jsonValue));
        });
    }
    /**
     * Autorizzazione di una disposizione di rav
     */
    authorizeRav(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.authorizeRavRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Esegue il controllo di validità di un rav in base ai valori specificati nell\'oggetto di richiesta
     */
    checkRavRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.ravDto === null || requestParameters.ravDto === undefined) {
                throw new runtime.RequiredError('ravDto', 'Required parameter requestParameters.ravDto was null or undefined when calling checkRav.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/rav/controllo`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.RavDtoToJSON(requestParameters.ravDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.RavDtoFromJSON(jsonValue));
        });
    }
    /**
     * Esegue il controllo di validità di un rav in base ai valori specificati nell\'oggetto di richiesta
     */
    checkRav(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.checkRavRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Eliminazione di una bozza dato l\'id
     */
    deleteDraftRavRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling deleteDraftRav.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/rav/bozze/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Eliminazione di una bozza dato l\'id
     */
    deleteDraftRav(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.deleteDraftRavRaw(requestParameters);
        });
    }
    /**
     * Restituisce una bozza dato l\'id per rav
     */
    getDraftByIdRavRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getDraftByIdRav.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/rav/bozze/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.RavDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce una bozza dato l\'id per rav
     */
    getDraftByIdRav(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getDraftByIdRavRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce le bozze per rav
     */
    getDraftListRavRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.bozzeInDto === null || requestParameters.bozzeInDto === undefined) {
                throw new runtime.RequiredError('bozzeInDto', 'Required parameter requestParameters.bozzeInDto was null or undefined when calling getDraftListRav.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/rav/bozze`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.BozzeInDtoToJSON(requestParameters.bozzeInDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.RavsDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce le bozze per rav
     */
    getDraftListRav(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getDraftListRavRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Lettura parametri RAV
     */
    parametersRavRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/rav/parametri`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ParametriRavDtoFromJSON(jsonValue));
        });
    }
    /**
     * Lettura parametri RAV
     */
    parametersRav() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.parametersRavRaw();
            return yield response.value();
        });
    }
    /**
     * Salva una bozza per rav
     */
    saveDraftRavRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.ravDto === null || requestParameters.ravDto === undefined) {
                throw new runtime.RequiredError('ravDto', 'Required parameter requestParameters.ravDto was null or undefined when calling saveDraftRav.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/rav/salvabozza`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.RavDtoToJSON(requestParameters.ravDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.RavDtoFromJSON(jsonValue));
        });
    }
    /**
     * Salva una bozza per rav
     */
    saveDraftRav(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.saveDraftRavRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Modifica di una bozza dato l\'id per rav
     */
    updateDraftRavRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling updateDraftRav.');
            }
            if (requestParameters.ravDto === null || requestParameters.ravDto === undefined) {
                throw new runtime.RequiredError('ravDto', 'Required parameter requestParameters.ravDto was null or undefined when calling updateDraftRav.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/rav/bozze/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.RavDtoToJSON(requestParameters.ravDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.RavDtoFromJSON(jsonValue));
        });
    }
    /**
     * Modifica di una bozza dato l\'id per rav
     */
    updateDraftRav(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateDraftRavRaw(requestParameters);
            return yield response.value();
        });
    }
}
exports.RavControllerApi = RavControllerApi;
