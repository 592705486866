"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PanViewResponseDtoToJSON = exports.PanViewResponseDtoFromJSONTyped = exports.PanViewResponseDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function PanViewResponseDtoFromJSON(json) {
    return PanViewResponseDtoFromJSONTyped(json, false);
}
exports.PanViewResponseDtoFromJSON = PanViewResponseDtoFromJSON;
function PanViewResponseDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'content': !runtime_1.exists(json, 'content') ? undefined : json['content'],
        'contentType': !runtime_1.exists(json, 'contentType') ? undefined : json['contentType'],
    };
}
exports.PanViewResponseDtoFromJSONTyped = PanViewResponseDtoFromJSONTyped;
function PanViewResponseDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'content': value.content,
        'contentType': value.contentType,
    };
}
exports.PanViewResponseDtoToJSON = PanViewResponseDtoToJSON;
