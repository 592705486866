import React, { useMemo, useState } from "react";

interface Props {
  className?: string;
  descrizione: string;
  img?: any;
  value: number | string | undefined;
  onChange: (value: any) => void;
  onFocus?: () => void;
  maxDecimalChar?: number;
  hideBottomBar?: Boolean;
  noHorizontalPadding?: boolean;
  disable?: boolean;
  inError?: boolean;
  errore?: string;
}

const InputCurrency: React.FC<Props> = (props: Props) => {
  const {
    descrizione,
    onChange,
    value,
    errore,
    onFocus,
    img,
    inError,
    className,
    disable,
    hideBottomBar,
    maxDecimalChar = 2,
    noHorizontalPadding = false,
  } = props;

  const [focus, setFocus] = useState(false);

  const focusClass = "form-field__label_focus";

  let classNameInput = "form-field__input";
  if (img) {
    classNameInput += " form-field__input_btn";
  }
  if (disable) {
    classNameInput += " form-field__input__disable";
  }
  if (hideBottomBar) {
    classNameInput += " no-border-bottom";
  }

  const minDecimalChar = maxDecimalChar > 2 ? 2 : maxDecimalChar;

  const formattedDecimalValue: string = useMemo(() => {
    return value ? value.toString().replace(".", ",") : "";
  }, [value]);

  const formattedValue: string = useMemo(() => {
    return value
      ? new Intl.NumberFormat("it-IT", {
          minimumFractionDigits: minDecimalChar,
          maximumFractionDigits: maxDecimalChar,
        }).format(parseFloat(value.toString().replace(",", ".")))
      : "";
  }, [value, minDecimalChar, maxDecimalChar]);

  const showLabelFocus = !!value || focus;

  const onInputFocus = () => {
    setFocus(true);
    onFocus?.();
  };

  const onInputBlur = () => {
    setFocus(false);
    if (formattedValue) {
      const s: string = formattedValue.toString().replace(/[\.]/g, "");
      onChange?.(s);
    }
  };

  const onInputKeyPress = (e: any) => {
    let total = value
      ? Number((value.toString() + e.key).replace(/[\,]/g, "."))
      : 0;

    if (total > 999999999.99) {
      e.preventDefault();
    }
  };

  const onInputChange = (e: any) => {
    const re = /^-?\d+\,?\.?\d*$/;

    if (e.target.value == "" || re.test(e.target.value)) {
      onChange?.(e.target.value.toString().replace(".", ","));
    }
  };

  const onInputPaste = (e: any) => {
    const value = e.clipboardData.getData("text");
    const total = value ? Number(value.toString().replace(/[\,]/g, ".")) : 0;

    if (total > 999999999.99) {
      e.preventDefault();
    } else {
      e.preventDefault();
      const paste = total.toString();
      const re = /^-?\d+\,?\.?\d*$/;
      if (paste == "" || re.test(paste)) {
        onChange?.(paste.replace(".", ","));
      }
    }
  };

  return (
    <div className="input-control input-control-currency">
      <div className="form-field">
        <div className="form-field__control">
          <label
            htmlFor={`input-${descrizione}`}
            className={`form-field__label ${
              errore !== "" && inError ? "form-field__label_error" : ""
            }
            ${showLabelFocus ? focusClass : ""}`}
            style={{
              maxWidth: 250,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              display: "inline-block",
            }}
          >
            {descrizione}
          </label>
          <input
            maxLength={12}
            autoComplete={"off"}
            type="text"
            className={`${classNameInput} ${className}`}
            disabled={disable ? disable : false}
            value={focus ? formattedDecimalValue : formattedValue}
            onFocus={onInputFocus}
            onBlur={onInputBlur}
            onKeyPress={onInputKeyPress}
            onChange={onInputChange}
            onPaste={onInputPaste}
            style={
              noHorizontalPadding ? { paddingRight: 0, paddingLeft: 0 } : {}
            }
            id={`input-${descrizione}`}
            aria-describedby={`input-${descrizione}-error`}
          />

          {img && <img className="form-field__icon" src={img} />}
        </div>
      </div>

      {inError && (
        <p
          className="input-control-error"
          id={`input-${descrizione}-error`}
          role="alert"
        >
          {errore}
        </p>
      )}
    </div>
  );
};

export default InputCurrency;
