"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BannerWcmListDtoToJSON = exports.BannerWcmListDtoFromJSONTyped = exports.BannerWcmListDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function BannerWcmListDtoFromJSON(json) {
    return BannerWcmListDtoFromJSONTyped(json, false);
}
exports.BannerWcmListDtoFromJSON = BannerWcmListDtoFromJSON;
function BannerWcmListDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'banners': !runtime_1.exists(json, 'banners') ? undefined : (json['banners'].map(_1.BannerWcmDtoFromJSON)),
    };
}
exports.BannerWcmListDtoFromJSONTyped = BannerWcmListDtoFromJSONTyped;
function BannerWcmListDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'banners': value.banners === undefined ? undefined : (value.banners.map(_1.BannerWcmDtoToJSON)),
    };
}
exports.BannerWcmListDtoToJSON = BannerWcmListDtoToJSON;
