"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetEsitoBonificoVersamentoDeposito = exports.autorizzaRevocaBonificoVersamentoDeposito = exports.getInfoRevocaBonificoVersamentoDeposito = exports.setBonificoVersamentoDepositoRevocaSetStep = exports.setProvenienzaRevocaBonificoVersamentoDeposito = exports.setRiepilogoRevocaBonificoVersamentoDeposito = exports.resetRiepilogoBonificoVersamentoDeposito = exports.resetBonificoVersamentoDeposito = exports.setBonificoVersamentoDepositoSetFormSubmitted = exports.setBonificoVersamentoDepositoSetStep = exports.setBonificoVersamentoDepositoSetCausale = exports.setBonificoVersamentoDepositoSetDataEsecuzione = exports.setBonificoVersamentoDepositoSetImporto = exports.setBonificoVersamentoDepositoSetIntestatario = exports.setBonificoVersamentoDepositoSetContoAccredito = exports.setBonificoVersamentoDepositoSetRapportoSelezionato = exports.autorizzaBonificoVersamentoDeposito = exports.getInfoDispositivaBonificoVersamentoDeposito = exports.controlloBonificoVersamentoDeposito = exports.getParametriBonificoVersamentoDeposito = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const general_types_1 = require("../general/general.types");
const helpers_1 = require("../helpers");
const bonifico_versamento_deposito_selectors_1 = require("./bonifico-versamento-deposito.selectors");
const bonifico_versamento_deposito_types_1 = require("./bonifico-versamento-deposito.types");
/* get parametri - START */
function getParametriBonificoVersamentoDepositoStart() {
    return {
        type: bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_GET_PARAMETRI_START
    };
}
function getParametriBonificoVersamentoDepositoSuccess(payload) {
    return {
        type: bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_GET_PARAMETRI_SUCCESS,
        payload
    };
}
function getParametriPerRipetiBonificoVersamentoDepositoSuccess(payload) {
    return {
        type: bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_GET_PARAMETRI_PER_RIPETI_SUCCESS,
        payload
    };
}
function generalError(value, showGenericModalError) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value, showGenericModalError));
    };
}
const getParametriBonificoVersamentoDeposito = (ripeti) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getParametriBonificoVersamentoDepositoStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/parametri_bonifico_deposito.json')).then(parametri => {
            dispatch(ripeti ? getParametriPerRipetiBonificoVersamentoDepositoSuccess((0, api_rest_1.ParametriBonificoDtoFromJSON)(parametri)) : getParametriBonificoVersamentoDepositoSuccess((0, api_rest_1.ParametriBonificoDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.VersamentoContoDepositoControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametriVersamentoContoDepo().then((response) => {
                var _a;
                dispatch(ripeti ? getParametriPerRipetiBonificoVersamentoDepositoSuccess(response) : getParametriBonificoVersamentoDepositoSuccess(response));
                ((_a = response.rapporti) === null || _a === void 0 ? void 0 : _a.length) === 0 && dispatch((0, general_actions_1.setNoRapporti)({ type: general_types_1.TypeNoRapporti.TRASFERIMENTO_BONIFICO }));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getParametriBonificoVersamentoDeposito = getParametriBonificoVersamentoDeposito;
/* get parametri - FINE */
/* controllo bonifico - START */
function controlloBonificoVersamentoDepositoStart() {
    return {
        type: bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_CONTROLLO_START
    };
}
function controlloBonificoVersamentoDepositoSuccess(payload) {
    return {
        type: bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_CONTROLLO_SUCCESS,
        payload
    };
}
const controlloBonificoVersamentoDeposito = () => async (dispatch, getState, extraArguments) => {
    const bonificoVersamentoDeposito = getState().bonificoVersamentoDeposito;
    dispatch(setBonificoVersamentoDepositoSetFormSubmitted(true));
    if ((0, bonifico_versamento_deposito_selectors_1.formBonificoVersamentoDepositoValid)(bonificoVersamentoDeposito)) {
        dispatch((0, general_actions_1.setLoader)(true));
        dispatch(controlloBonificoVersamentoDepositoStart());
        if (extraArguments.mock) {
        }
        else {
            const req = {
                bonificoSepaDto: (0, bonifico_versamento_deposito_selectors_1.buildBonificoVersamentoDeposito)(bonificoVersamentoDeposito)
            };
            extraArguments.getItem('jwt').then((jwt) => {
                new api_rest_1.VersamentoContoDepositoControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).controllaVersamentoContoDepo(req).then((response) => {
                    dispatch(controlloBonificoVersamentoDepositoSuccess(response));
                }).catch(async (error) => {
                    dispatch(generalError(await (0, helpers_1.formatError)(error)));
                }).finally(() => {
                    dispatch((0, general_actions_1.setLoader)(false));
                });
            });
        }
    }
};
exports.controlloBonificoVersamentoDeposito = controlloBonificoVersamentoDeposito;
/* controllo bonifico - FINE */
/* get info dispositiva bonifico - START */
function getInfoDispositivaBonificoVersamentoDepositoStart() {
    return {
        type: bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_GET_INFO_DISPOSITIVA_START
    };
}
function getInfoDispositivaBonificoVersamentoDepositoSuccess(payload) {
    return {
        type: bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_GET_INFO_DISPOSITIVA_SUCCESS,
        payload
    };
}
const getInfoDispositivaBonificoVersamentoDeposito = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getInfoDispositivaBonificoVersamentoDepositoStart());
    if (extraArguments.mock) {
    }
    else {
        const req = {
            idDispositiva,
            chiamataUscente,
            eseguiInit,
            offline: !!offline
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.VersamentoContoDepositoControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).informazioniAutenticazioneVersamentoContoDepo(req).then((response) => {
                dispatch(getInfoDispositivaBonificoVersamentoDepositoSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getInfoDispositivaBonificoVersamentoDeposito = getInfoDispositivaBonificoVersamentoDeposito;
/* get info dispositiva bonifico - FINE */
/* autorizza bonifico - START */
function autorizzaBonificoVersamentoDepositoStart() {
    return {
        type: bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_AUTORIZZA_START
    };
}
function autorizzaBonificoVersamentoDepositoSuccess(payload) {
    return {
        type: bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_AUTORIZZA_SUCCESS,
        payload
    };
}
const autorizzaBonificoVersamentoDeposito = (idDispositiva, credenziale, bonifico) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(autorizzaBonificoVersamentoDepositoStart());
    if (extraArguments.mock) {
    }
    else {
        const req = {
            idDispositiva,
            credenzialeDispositivaDto: credenziale
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.VersamentoContoDepositoControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).autorizzaVersamentoContoDepo(req).then((response) => {
                extraArguments.sendEventToMatomo(`BONIFICO_VERSAMENTO_DEPOSITO`, `esito dispositiva BONIFICO_VERSAMENTO_DEPOSITO ${response.stato}`, 'ESITO_DISPOSITIVA');
                dispatch(autorizzaBonificoVersamentoDepositoSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error), false));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.autorizzaBonificoVersamentoDeposito = autorizzaBonificoVersamentoDeposito;
/* get info dispositiva bonifico - FINE */
function setBonificoVersamentoDepositoSetRapportoSelezionato(rapportoSelezionato) {
    return {
        type: bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_SET_RAPPORTO_SELEZIONATO,
        payload: rapportoSelezionato
    };
}
exports.setBonificoVersamentoDepositoSetRapportoSelezionato = setBonificoVersamentoDepositoSetRapportoSelezionato;
function setBonificoVersamentoDepositoSetContoAccredito(contoAccredito) {
    return {
        type: bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_CONTO_ACCREDITO,
        payload: contoAccredito
    };
}
exports.setBonificoVersamentoDepositoSetContoAccredito = setBonificoVersamentoDepositoSetContoAccredito;
function setBonificoVersamentoDepositoSetIntestatario(value) {
    return {
        type: bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_INTESTATARIO,
        payload: value
    };
}
exports.setBonificoVersamentoDepositoSetIntestatario = setBonificoVersamentoDepositoSetIntestatario;
function setBonificoVersamentoDepositoSetImporto(i) {
    return {
        type: bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_IMPORTO,
        payload: i
    };
}
exports.setBonificoVersamentoDepositoSetImporto = setBonificoVersamentoDepositoSetImporto;
function setBonificoVersamentoDepositoSetDataEsecuzione(txt) {
    return {
        type: bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_DATA_ESECUZIONE,
        payload: txt
    };
}
exports.setBonificoVersamentoDepositoSetDataEsecuzione = setBonificoVersamentoDepositoSetDataEsecuzione;
function setBonificoVersamentoDepositoSetCausale(txt) {
    return {
        type: bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_CAUSALE,
        payload: txt
    };
}
exports.setBonificoVersamentoDepositoSetCausale = setBonificoVersamentoDepositoSetCausale;
function setBonificoVersamentoDepositoSetStepAction(i) {
    return {
        type: bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_STEP,
        payload: i
    };
}
const setBonificoVersamentoDepositoSetStep = (i) => async (dispatch, getState, extraArguments) => {
    dispatch(setBonificoVersamentoDepositoSetStepAction(i));
    if (extraArguments.platform == 'mobile') {
        dispatch((0, general_actions_1.setBottomTabBarVisible)(i != 2));
    }
};
exports.setBonificoVersamentoDepositoSetStep = setBonificoVersamentoDepositoSetStep;
function setBonificoVersamentoDepositoSetFormSubmitted(bSelect) {
    return {
        type: bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_FORM_SUBMITTED,
        payload: bSelect
    };
}
exports.setBonificoVersamentoDepositoSetFormSubmitted = setBonificoVersamentoDepositoSetFormSubmitted;
function resetBonificoVersamentoDeposito() {
    return {
        type: bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_RESET
    };
}
exports.resetBonificoVersamentoDeposito = resetBonificoVersamentoDeposito;
function resetRiepilogoBonificoVersamentoDeposito() {
    return {
        type: bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_RESET_RIEPILOGO
    };
}
exports.resetRiepilogoBonificoVersamentoDeposito = resetRiepilogoBonificoVersamentoDeposito;
function setRiepilogoRevocaBonificoVersamentoDeposito(riepilogo) {
    return {
        type: bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_SET_RIEPILOGO_REVOCA,
        payload: riepilogo
    };
}
exports.setRiepilogoRevocaBonificoVersamentoDeposito = setRiepilogoRevocaBonificoVersamentoDeposito;
function setProvenienzaRevocaBonificoVersamentoDeposito(provenienza) {
    return {
        type: bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_SET_PROVENIENZA,
        payload: provenienza
    };
}
exports.setProvenienzaRevocaBonificoVersamentoDeposito = setProvenienzaRevocaBonificoVersamentoDeposito;
function setBonificoVersamentoDepositoRevocaSetStepAction(i) {
    return {
        type: bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_STEP_REVOCA,
        payload: i
    };
}
const setBonificoVersamentoDepositoRevocaSetStep = (i) => async (dispatch, getState, extraArguments) => {
    dispatch(setBonificoVersamentoDepositoRevocaSetStepAction(i));
    if (extraArguments.platform == 'mobile') {
        dispatch((0, general_actions_1.setBottomTabBarVisible)(i != 1));
    }
};
exports.setBonificoVersamentoDepositoRevocaSetStep = setBonificoVersamentoDepositoRevocaSetStep;
/* get info revoca - START */
function getInfoRevocaBonificoVersamentoDepositoStart() {
    return {
        type: bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_GET_INFO_REVOCA_START
    };
}
function getInfoRevocaBonificoVersamentoDepositoSuccess(payload) {
    return {
        type: bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_GET_INFO_REVOCA_SUCCESS,
        payload
    };
}
const getInfoRevocaBonificoVersamentoDeposito = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getInfoRevocaBonificoVersamentoDepositoStart());
    if (extraArguments.mock) {
    }
    else {
        const req = {
            idDispositiva,
            chiamataUscente,
            eseguiInit,
            offline: !!offline
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.VersamentoContoDepositoControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).informazioniAutenticazioneRevocaVersamentoContoDepo(req).then((response) => {
                dispatch(getInfoRevocaBonificoVersamentoDepositoSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getInfoRevocaBonificoVersamentoDeposito = getInfoRevocaBonificoVersamentoDeposito;
/* get info revoca - FINE */
/* autorizza revoca - START */
function autorizzaRevocaBonificoVersamentoDepositoStart() {
    return {
        type: bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_AUTORIZZA_REVOCA_START
    };
}
function autorizzaRevocaBonificoVersamentoDepositoSuccess(payload) {
    return {
        type: bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_AUTORIZZA_REVOCA_SUCCESS,
        payload
    };
}
const autorizzaRevocaBonificoVersamentoDeposito = (idDispositiva, credenziale, funzioneDispositiva) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(autorizzaRevocaBonificoVersamentoDepositoStart());
    if (extraArguments.mock) {
    }
    else {
        const req = {
            idDispositiva,
            credenzialeDispositivaDto: credenziale
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.VersamentoContoDepositoControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).operazioneAutenticataVersamentoContoDepo(req).then((response) => {
                extraArguments.sendEventToMatomo(`BONIFICO_VERSAMENTO_DEPOSITO_REVOCA`, `esito revoca dispositiva BONIFICO_VERSAMENTO_DEPOSITO_REVOCA ${response.stato}`, 'REVOCA_DISPOSITIVA');
                dispatch(autorizzaRevocaBonificoVersamentoDepositoSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error), false));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.autorizzaRevocaBonificoVersamentoDeposito = autorizzaRevocaBonificoVersamentoDeposito;
/* get autorizza revoca - FINE */
// Reset Esito
function resetEsitoBonificoVersamentoDeposito() {
    return {
        type: bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_RESET_ESITO
    };
}
exports.resetEsitoBonificoVersamentoDeposito = resetEsitoBonificoVersamentoDeposito;
