"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.catalogoProdottiReducer = void 0;
const __1 = require("..");
const helpers_1 = require("../helpers");
const catalogoProdotti_types_1 = require("./catalogoProdotti.types");
const initialState = {
    catalogo: undefined,
    // catalogoSenzaHTML: undefined,
    prodottoSelezionato: undefined,
    prodottiTop: undefined,
    isNdgPresent: false,
    prodottoCatalogoDaCodiceFunzione: undefined,
    prodottoCatalogoDaId: undefined
};
const catalogoProdottiReducer = (state = initialState, action) => {
    var _a;
    switch (action.type) {
        case catalogoProdotti_types_1.EReduxCatalogoProdottiActionTypes.CATALOGO_PRODOTTI_GET_CATALOGO_PRODOTTI_SUCCESS:
            const catalogo = [...action.payload];
            for (let i = 0; i < catalogo.length; i++) {
                catalogo[i].term.products.forEach((prodotto) => {
                    var _a, _b, _c, _d, _e, _f;
                    prodotto.previewSenzaHTML = {
                        ...prodotto.preview,
                        preview_title: (0, helpers_1.extractContentFromHTML)(((_a = prodotto.preview) === null || _a === void 0 ? void 0 : _a.preview_title) || ''),
                        preview_abstract: (0, helpers_1.extractContentFromHTML)(((_b = prodotto.preview) === null || _b === void 0 ? void 0 : _b.preview_abstract) || ''),
                        preview_image_small: ((_c = prodotto.preview) === null || _c === void 0 ? void 0 : _c.preview_image_small) || '',
                        preview_cta_1: {
                            ...(_d = prodotto.preview) === null || _d === void 0 ? void 0 : _d.preview_cta_1,
                            preview_cta_1_text: (0, helpers_1.extractContentFromHTML)(((_f = (_e = prodotto.preview) === null || _e === void 0 ? void 0 : _e.preview_cta_1) === null || _f === void 0 ? void 0 : _f.preview_cta_1_text) || ''),
                        },
                        preview_cta_2: {
                            preview_cta_2_text: '',
                            preview_cta_2_btn_label: '',
                            preview_cta_2_link_tool: ''
                        },
                    };
                    if (prodotto.multiplatform && prodotto.multiplatform.length > 0) {
                        for (let j = 0; j < prodotto.multiplatform.length; j++) {
                            const multiplatform = prodotto.multiplatform[j];
                            if (multiplatform.key === 'perte_titolo') {
                                prodotto.preview.preview_title = `<p><strong>${multiplatform.value}</strong></p>`;
                                prodotto.previewSenzaHTML.preview_title = multiplatform.value;
                            }
                            else if (multiplatform.key === 'perte_descrizione') {
                                prodotto.preview.preview_abstract = `<p>${multiplatform.value}</p>`;
                                // se è popolato il campo custom 'perte_descrizione', svuoto volontariamente il campo CTA1
                                prodotto.preview.preview_cta_1 = {
                                    preview_cta_1_text: '',
                                    preview_cta_1_btn_label: '',
                                    preview_cta_1_external_link: ''
                                };
                                prodotto.previewSenzaHTML.preview_abstract = multiplatform.value;
                                prodotto.previewSenzaHTML.preview_cta_1 = {
                                    preview_cta_1_text: '',
                                    preview_cta_1_btn_label: '',
                                    preview_cta_1_external_link: ''
                                };
                            }
                            else if (multiplatform.key === 'perte_immagine') {
                                prodotto.preview.preview_image_small = multiplatform.value;
                                prodotto.previewSenzaHTML.preview_image_small = multiplatform.value;
                            }
                            else if (multiplatform.key === 'perte_cta') {
                                const cta = (multiplatform.value || '').split(';');
                                // "CTA apertura prodotto;open_product;4602"
                                // "CTA apertura sito;open_url;https;//www.sparkasse.it"
                                // "CONTATTACI;open_template;templatecatalogo_depositi"
                                if (cta.length === 3) {
                                    prodotto.preview.preview_cta_2.preview_cta_2_btn_label = cta[0];
                                    prodotto.preview.preview_cta_2.preview_cta_2_text = cta[1]; // lo uso per discriminare la action a FE
                                    prodotto.preview.preview_cta_2.preview_cta_2_link_tool = cta[2];
                                    prodotto.previewSenzaHTML.preview_cta_2.preview_cta_2_btn_label = cta[0];
                                    prodotto.previewSenzaHTML.preview_cta_2.preview_cta_2_text = cta[1]; // lo uso per discriminare la action a FE
                                    prodotto.previewSenzaHTML.preview_cta_2.preview_cta_2_link_tool = cta[2];
                                }
                            }
                        }
                    }
                });
            }
            return {
                ...state,
                catalogo
            };
        case catalogoProdotti_types_1.EReduxCatalogoProdottiActionTypes.CATALOGO_PRODOTTI_SELECT_PRODOTTO:
            return {
                ...state,
                prodottoSelezionato: action.payload
            };
        case catalogoProdotti_types_1.EReduxCatalogoProdottiActionTypes.CATALOGO_PRODOTTI_GET_PRODOTTO_DA_CODICE_FUNZIONE_SUCCESS:
            return {
                ...state,
                prodottoCatalogoDaCodiceFunzione: action.payload
            };
        case catalogoProdotti_types_1.EReduxCatalogoProdottiActionTypes.CATALOGO_PRODOTTI_GET_PRODOTTO_DA_CODICE_FUNZIONE_ERROR:
            return {
                ...state,
                prodottoCatalogoDaCodiceFunzione: initialState.prodottoCatalogoDaCodiceFunzione
            };
        case catalogoProdotti_types_1.EReduxCatalogoProdottiActionTypes.CATALOGO_PRODOTTI_RESET_PRODOTTO_DA_CODICE_FUNZIONE:
            return {
                ...state,
                prodottoCatalogoDaCodiceFunzione: initialState.prodottoCatalogoDaCodiceFunzione
            };
        case catalogoProdotti_types_1.EReduxCatalogoProdottiActionTypes.CATALOGO_PRODOTTI_GET_PRODOTTO_DA_ID_SUCCESS:
            return {
                ...state,
                prodottoCatalogoDaId: action.payload
            };
        case catalogoProdotti_types_1.EReduxCatalogoProdottiActionTypes.CATALOGO_PRODOTTI_GET_PRODOTTO_DA_ID_ERROR:
            return {
                ...state,
                prodottoCatalogoDaId: initialState.prodottoCatalogoDaId
            };
        case catalogoProdotti_types_1.EReduxCatalogoProdottiActionTypes.CATALOGO_PRODOTTI_RESET_PRODOTTO_DA_ID:
            return {
                ...state,
                prodottoCatalogoDaId: initialState.prodottoCatalogoDaId
            };
        case catalogoProdotti_types_1.EReduxCatalogoProdottiActionTypes.CATALOGO_PRODOTTI_GET_PRODOTTI_TOP_SUCCESS:
            const prodottiTop = (_a = state.catalogo) === null || _a === void 0 ? void 0 : _a.flatMap((e) => e.term).flatMap((val) => val.products).filter((product) => {
                return action.payload.productIds ? !!action.payload.productIds.find((id) => parseInt(id) === product.ID) : false;
            });
            const sortedArray = !!action.payload.productIds && action.payload.productIds.reduce(function (r, a, i) {
                r[a] = i;
                return r;
            }, {});
            !!prodottiTop && prodottiTop.sort(function (x, y) {
                return sortedArray[x.ID] - sortedArray[y.ID];
            });
            return {
                ...state,
                prodottiTop,
                isNdgPresent: !!action.payload.isNdgPresent
            };
        case __1.EReduxActionTypes.GENERAL_RESET_POST_CAMBIO_LINGUA: // action invocata dal cambio lingua
            return {
                ...initialState
            };
        case __1.EReduxActionTypes.RESET_ALL: // action invocata dalla logout
            return {
                ...initialState
            };
        default:
            return state;
    }
};
exports.catalogoProdottiReducer = catalogoProdottiReducer;
