import { PopUp } from "@sparkasse/commons";
import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export enum PopUpStyleVariant {
  error
}

interface Props {
  visible?: boolean
  popUp: PopUp
  /**
   * Funzione eseguita alla chiusura della modale sia che si abbia cliccato sul bottone di OK, sia su quello di KO
   */
  onClose?: (id: number | undefined) => any
  styleVariant?: PopUpStyleVariant
}

const PopUpModal: React.FC<Props> = (props: Props) => {

  const { t } = useTranslation();

  const {
    visible = true,
    popUp,
    onClose,
    styleVariant
  } = props;

  const onPressOk = () => {
    if (popUp.type != 'confirm' && popUp.type != 'okOnly') {
      return;
    }

    if (popUp.buttonOk?.blocker) {
      popUp.buttonOk?.action?.();
      return;
    }

    popUp.buttonOk?.action?.();
    onClose?.(popUp.id);
  };

  const onPressKo = () => {
    if (popUp.type != 'confirm' && popUp.type != 'alert') {
      return;
    }

    if (popUp.buttonKo?.blocker) {
      popUp.buttonKo?.action?.();
      return;
    }

    popUp.buttonKo?.action?.();
    onClose?.(popUp.id);
  };

  const defaultKoText = 'button.chiudi';
  const defaultOkText = 'button.conferma';

  const backdrop = popUp?.type == 'okOnly' || popUp?.type == 'nobuttons' ? 'static' : true;

  // Styling
  const isTitlePrimary = popUp.type == 'okOnly' || popUp.type == 'confirm' || styleVariant == PopUpStyleVariant.error;
  const isSecondaryButtonFilled = styleVariant != PopUpStyleVariant.error;

  return (
    <Modal
      show={visible}
      className={
        `notification-modal pop-up${
        !isTitlePrimary
          ? ' pop-up-secondary'
          : ''
        }${
        isTitlePrimary
          ? ' pop-up-primary'
          : ''
        }`
      }
      onHide={onPressKo}
      backdrop={backdrop}
    >
      <Modal.Header>
        <Modal.Title>
          <div className="modal-title-text">
            {t(popUp.title || '')}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body-text">
          {popUp.message ? t(popUp.message) : popUp.body}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="contenitore-pulsanti">
          {popUp.type != 'nobuttons' &&
            <div className="pulsanti-destra">

              {popUp.type == 'alert' &&
                <button
                  type="button"
                  className={`${isSecondaryButtonFilled ? 'filled_secondary_button' : 'empty_secondary_button'}`}
                  onClick={onPressKo}
                >
                  {t(popUp.buttonKo?.text || defaultKoText)}
                </button>
              }

              {popUp.type == 'confirm' &&
                <button
                  type="button"
                  className="empty_secondary_button"
                  onClick={onPressKo}
                >
                  {t(popUp.buttonKo?.text || defaultKoText)}
                </button>
              }

              {(popUp.type == 'confirm' || popUp.type == 'okOnly') &&
                <button
                  type="button"
                  className="filled_primary_button"
                  onClick={onPressOk}
                >
                  {t(popUp.buttonOk?.text || defaultOkText)}
                </button>
              }

            </div>
          }
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PopUpModal;
