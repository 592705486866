import { loaderActive } from "@sparkasse/commons";
import React, { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import Item from "../../component/esitoSection/esito.item.component";
import { AppState } from "../../reducers";

const GuideSection: React.FC<Props> = (props) => {

  const { t } = useTranslation();

  const {
    titolo,
    descrizione,
    descrizione2,
    onExportExcel,
    onExportPdf,
    titoloExport,
    loader,
    feature,
    disabled,
    customGuide,
    hideExportGeneralDescription,
    pdfDescription,
    excelDescription,
    onCustomButtonAction,
    customButtonDescription,
    customButtonIcon
  } = props;

  const [elements, setElements] = useState({ pdf: false, xls: false })

  return (
    <div className="home-section-guide">
      {customGuide}
      {titolo !== '' &&
        <h3
          tabIndex={0}
          className="txtTitle-section-guide"
        >
          {titolo}
        </h3>
      }
      {descrizione !== '' &&
        <p
          tabIndex={0}
          className="txtText-section-guide"
        >
          {descrizione}
        </p>
      }
      {descrizione2 &&
        <p
          tabIndex={0}
          className="txtText-section-guide"
        >
          {descrizione2}
        </p>
      }
      {(onExportExcel || onExportPdf) && !hideExportGeneralDescription &&
        <h3
          tabIndex={0}
          className="content-main-section--right-bar-small-title"
        >
          {titoloExport || `${t('assegni.list.cta.title')} ${feature ? feature : ''}`}
        </h3>
      }
      {onExportPdf &&
        <Item
          onClick={() => { onExportPdf(); setElements({ pdf: true, xls: false }); }}
          descrizione={pdfDescription ? pdfDescription : t('assegni.list.cta.exportPdf')}
          icon={loader && elements.pdf ? "spinner" : "azioni.esporta_pdf"}
          disabled={disabled}
        />
      }
      {onExportExcel &&
        <Item
          onClick={() => { onExportExcel(); setElements({ pdf: false, xls: true }); }}
          descrizione={excelDescription ? excelDescription : t('assegni.list.cta.exportExcel')}
          icon={loader && elements.xls ? "spinner" : "azioni.esporta_excel"}
          disabled={disabled}
        />
      }
      {onCustomButtonAction &&
        <Item
          onClick={() => { onCustomButtonAction() }}
          descrizione={customButtonDescription ? customButtonDescription : ''}
          icon={customButtonIcon}
        />
      }
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  loader: loaderActive(state.general)
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
    },
    dispatch
  );

interface iProps {
  titolo: string
  descrizione: string
  descrizione2?: string
  titoloExport?: string
  onExportExcel?: Function
  onExportPdf?: Function
  feature?: string
  disabled?: boolean
  customGuide?: ReactNode
  hideExportGeneralDescription?: boolean
  excelDescription?: string
  pdfDescription?: string
  onCustomButtonAction?: () => void
  customButtonDescription?: string
  customButtonIcon?: string
}

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  iProps;

export default connect(mapStateToProps, mapDispatchToProps)(GuideSection);
