import Loader from "component/loader/loader.component";
import React from "react";

const Loading = () => {
  return (
    <div className="App">
      <Loader />
    </div>
  );
};

export default Loading;
