"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportElencoDepositoRisparmioTypeEnum = exports.ElencoDepositoRisparmiControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class ElencoDepositoRisparmiControllerApi extends runtime.BaseAPI {
    /**
     * Export in PDF del dettaglio dell\' deposito risparmio in base ai criteri inviati per il rapporto indicato
     */
    exportDettaglioDepositiRisparmioRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.codiceRapporto === null || requestParameters.codiceRapporto === undefined) {
                throw new runtime.RequiredError('codiceRapporto', 'Required parameter requestParameters.codiceRapporto was null or undefined when calling exportDettaglioDepositiRisparmio.');
            }
            if (requestParameters.dettaglioLibrettoDiRisparmioDto === null || requestParameters.dettaglioLibrettoDiRisparmioDto === undefined) {
                throw new runtime.RequiredError('dettaglioLibrettoDiRisparmioDto', 'Required parameter requestParameters.dettaglioLibrettoDiRisparmioDto was null or undefined when calling exportDettaglioDepositiRisparmio.');
            }
            const queryParameters = {};
            if (requestParameters.codiceRapporto !== undefined) {
                queryParameters['codiceRapporto'] = requestParameters.codiceRapporto;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/elencodepositorisparmi/export/dettaglio/pdf`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.DettaglioLibrettoDiRisparmioDtoToJSON(requestParameters.dettaglioLibrettoDiRisparmioDto),
            });
            return new runtime.BlobApiResponse(response);
        });
    }
    /**
     * Export in PDF del dettaglio dell\' deposito risparmio in base ai criteri inviati per il rapporto indicato
     */
    exportDettaglioDepositiRisparmio(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.exportDettaglioDepositiRisparmioRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Export in PDF o Excel degli depositi risparmio in base ai criteri inviati per il rapporto indicato
     */
    exportElencoDepositoRisparmioRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.type === null || requestParameters.type === undefined) {
                throw new runtime.RequiredError('type', 'Required parameter requestParameters.type was null or undefined when calling exportElencoDepositoRisparmio.');
            }
            if (requestParameters.filtroElencoDepositiRisparmioDto === null || requestParameters.filtroElencoDepositiRisparmioDto === undefined) {
                throw new runtime.RequiredError('filtroElencoDepositiRisparmioDto', 'Required parameter requestParameters.filtroElencoDepositiRisparmioDto was null or undefined when calling exportElencoDepositoRisparmio.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/elencodepositorisparmi/export/elenco/{type}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.FiltroElencoDepositiRisparmioDtoToJSON(requestParameters.filtroElencoDepositiRisparmioDto),
            });
            return new runtime.BlobApiResponse(response);
        });
    }
    /**
     * Export in PDF o Excel degli depositi risparmio in base ai criteri inviati per il rapporto indicato
     */
    exportElencoDepositoRisparmio(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.exportElencoDepositoRisparmioRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Lettura elenco depositi risparmio
     */
    getElencoDepositiRisparmioRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.filtroElencoDepositiRisparmioDto === null || requestParameters.filtroElencoDepositiRisparmioDto === undefined) {
                throw new runtime.RequiredError('filtroElencoDepositiRisparmioDto', 'Required parameter requestParameters.filtroElencoDepositiRisparmioDto was null or undefined when calling getElencoDepositiRisparmio.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/elencodepositorisparmi`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.FiltroElencoDepositiRisparmioDtoToJSON(requestParameters.filtroElencoDepositiRisparmioDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.LibrettoDiRisparmioDtoFromJSON(jsonValue));
        });
    }
    /**
     * Lettura elenco depositi risparmio
     */
    getElencoDepositiRisparmio(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getElencoDepositiRisparmioRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Richiesta parametri iniziali per il servizio libretti di risparmio
     */
    parametriLibrettiRisparmioRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.codiceRapporto !== undefined) {
                queryParameters['codiceRapporto'] = requestParameters.codiceRapporto;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/elencodepositorisparmi/parametri`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ParametriDtoFromJSON(jsonValue));
        });
    }
    /**
     * Richiesta parametri iniziali per il servizio libretti di risparmio
     */
    parametriLibrettiRisparmio(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.parametriLibrettiRisparmioRaw(requestParameters);
            return yield response.value();
        });
    }
}
exports.ElencoDepositoRisparmiControllerApi = ElencoDepositoRisparmiControllerApi;
/**
    * @export
    * @enum {string}
    */
var ExportElencoDepositoRisparmioTypeEnum;
(function (ExportElencoDepositoRisparmioTypeEnum) {
    ExportElencoDepositoRisparmioTypeEnum["pdf"] = "pdf";
    ExportElencoDepositoRisparmioTypeEnum["excel"] = "excel";
})(ExportElencoDepositoRisparmioTypeEnum = exports.ExportElencoDepositoRisparmioTypeEnum || (exports.ExportElencoDepositoRisparmioTypeEnum = {}));
