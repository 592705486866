"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PeriodoRendimenti = void 0;
exports.PeriodoRendimenti = {
    free: "free",
    currentTrimester: "currentTrimester",
    yearToDate: "yearToDate",
    all: "all",
    lastYear: "lastYear",
};
