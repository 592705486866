"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkUpdateBozzaRav = exports.currentBozzaRav = exports.infoAutenticazioneRav = exports.esitoRevocaRav = exports.infoRevocaRav = exports.formRevocaRav = exports.riepilogoRevocaRav = exports.bozzeRav = exports.disableSalvaBozzaRav = exports.esitoRav = exports.riepilogoRav = exports.formRavValid = exports.buildRav = exports.formRav = exports.parametriRav = void 0;
const reselect_1 = require("reselect");
const moment = require("moment");
const ravReducer = (state) => state;
exports.parametriRav = (0, reselect_1.createSelector)(ravReducer, (state) => {
    return state.parametri;
});
exports.formRav = (0, reselect_1.createSelector)(ravReducer, (state) => {
    return state.form;
});
exports.buildRav = (0, reselect_1.createSelector)(ravReducer, (state) => {
    var _a, _b;
    return {
        importo: state.form.importo
            ? Number(state.form.importo.toString().replace(',', '.'))
            : 0,
        numeroBollettino: state.form.codiceRAV,
        contoAddebito: ((_b = (_a = state.form.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.id) || 0,
        dataScadenza: moment(state.form.dataEsecuzione).utcOffset(0, true).toDate(),
        idBozzaCollegata: state.currentBozza.id
    };
});
exports.formRavValid = (0, reselect_1.createSelector)(ravReducer, (state) => {
    let isBozzaAccepted = 
    // !state.form.bozzaSalvata &&
    (!!state.form.codiceRAV && !state.form.codiceRAVError) &&
        (!!state.form.importo && !state.form.importoError) &&
        (!!state.form.dataEsecuzione && !state.form.dataEsecuzioneError);
    return isBozzaAccepted;
});
exports.riepilogoRav = (0, reselect_1.createSelector)(ravReducer, (state) => {
    return state.riepilogo;
});
exports.esitoRav = (0, reselect_1.createSelector)(ravReducer, (state) => {
    return state.esito;
});
exports.disableSalvaBozzaRav = (0, reselect_1.createSelector)(ravReducer, (state) => {
    let isBozzaAccepted = !state.form.bozzaSalvata &&
        (!!state.form.importo && !state.form.importoError) &&
        (!!state.form.codiceRAV && !state.form.codiceRAVError);
    return !isBozzaAccepted;
});
exports.bozzeRav = (0, reselect_1.createSelector)(ravReducer, (state) => {
    return state.bozzeRav && state.bozzeRav.ravs ? state.bozzeRav.ravs.sort((a, b) => b.id - a.id) : [];
});
exports.riepilogoRevocaRav = (0, reselect_1.createSelector)(ravReducer, (state) => {
    return state.riepilogoRevoca;
});
exports.formRevocaRav = (0, reselect_1.createSelector)(ravReducer, (state) => {
    return state.formRevoca;
});
exports.infoRevocaRav = (0, reselect_1.createSelector)(ravReducer, (state) => {
    return state.infoRevoca;
});
exports.esitoRevocaRav = (0, reselect_1.createSelector)(ravReducer, (state) => {
    return state.esitoRevoca;
});
exports.infoAutenticazioneRav = (0, reselect_1.createSelector)(ravReducer, (state) => {
    return state.infoAutenticazioneRav;
});
exports.currentBozzaRav = (0, reselect_1.createSelector)(ravReducer, (state) => {
    return state.currentBozza;
});
exports.checkUpdateBozzaRav = (0, reselect_1.createSelector)(ravReducer, (state) => {
    var _a;
    return !!((_a = state.currentBozza) === null || _a === void 0 ? void 0 : _a.id);
});
