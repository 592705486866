"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RibaDtoToJSON = exports.RibaDtoFromJSONTyped = exports.RibaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function RibaDtoFromJSON(json) {
    return RibaDtoFromJSONTyped(json, false);
}
exports.RibaDtoFromJSON = RibaDtoFromJSON;
function RibaDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codiceRapporto': json['codiceRapporto'],
        'numElementi': json['numElementi'],
        'importoCommissioni': json['importoCommissioni'],
        'importoPagamento': json['importoPagamento'],
        'totaleImportoPagamento': json['totaleImportoPagamento'],
        'avvisiList': !runtime_1.exists(json, 'avvisiList') ? undefined : (json['avvisiList'].map(_1.AvvisoDtoFromJSON)),
        'condivisione': !runtime_1.exists(json, 'condivisione') ? undefined : (json['condivisione'].map(_1.CondivisioneDtoFromJSON)),
        'contoAddebitoIban': !runtime_1.exists(json, 'contoAddebitoIban') ? undefined : json['contoAddebitoIban'],
        'ordinanteIntestazione': !runtime_1.exists(json, 'ordinanteIntestazione') ? undefined : json['ordinanteIntestazione'],
        'codiceFiscale': !runtime_1.exists(json, 'codiceFiscale') ? undefined : json['codiceFiscale'],
    };
}
exports.RibaDtoFromJSONTyped = RibaDtoFromJSONTyped;
function RibaDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codiceRapporto': value.codiceRapporto,
        'numElementi': value.numElementi,
        'importoCommissioni': value.importoCommissioni,
        'importoPagamento': value.importoPagamento,
        'totaleImportoPagamento': value.totaleImportoPagamento,
        'avvisiList': value.avvisiList === undefined ? undefined : (value.avvisiList.map(_1.AvvisoDtoToJSON)),
        'condivisione': value.condivisione === undefined ? undefined : (value.condivisione.map(_1.CondivisioneDtoToJSON)),
        'contoAddebitoIban': value.contoAddebitoIban,
        'ordinanteIntestazione': value.ordinanteIntestazione,
        'codiceFiscale': value.codiceFiscale,
    };
}
exports.RibaDtoToJSON = RibaDtoToJSON;
