"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DatiAggiuntiviRispostaDtoToJSON = exports.DatiAggiuntiviRispostaDtoFromJSONTyped = exports.DatiAggiuntiviRispostaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function DatiAggiuntiviRispostaDtoFromJSON(json) {
    return DatiAggiuntiviRispostaDtoFromJSONTyped(json, false);
}
exports.DatiAggiuntiviRispostaDtoFromJSON = DatiAggiuntiviRispostaDtoFromJSON;
function DatiAggiuntiviRispostaDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'datiAggiuntivi': !runtime_1.exists(json, 'datiAggiuntivi') ? undefined : (json['datiAggiuntivi'].map(_1.DatoAggiuntivoDtoFromJSON)),
    };
}
exports.DatiAggiuntiviRispostaDtoFromJSONTyped = DatiAggiuntiviRispostaDtoFromJSONTyped;
function DatiAggiuntiviRispostaDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'datiAggiuntivi': value.datiAggiuntivi === undefined ? undefined : (value.datiAggiuntivi.map(_1.DatoAggiuntivoDtoToJSON)),
    };
}
exports.DatiAggiuntiviRispostaDtoToJSON = DatiAggiuntiviRispostaDtoToJSON;
