"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * API BFF Customer Web Collaboration
 * API BFF Customer Web Collaboration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DayToJSON = exports.DayFromJSONTyped = exports.DayFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function DayFromJSON(json) {
    return DayFromJSONTyped(json, false);
}
exports.DayFromJSON = DayFromJSON;
function DayFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'availableSlots': !runtime_1.exists(json, 'availableSlots') ? undefined : (json['availableSlots'].map(_1.SlotFromJSON)),
        'day': !runtime_1.exists(json, 'day') ? undefined : json['day'],
        'errorManagementResponse': !runtime_1.exists(json, 'errorManagementResponse') ? undefined : _1.ErrorManagementResponseFromJSON(json['errorManagementResponse']),
    };
}
exports.DayFromJSONTyped = DayFromJSONTyped;
function DayToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'availableSlots': value.availableSlots === undefined ? undefined : (value.availableSlots.map(_1.SlotToJSON)),
        'day': value.day,
        'errorManagementResponse': _1.ErrorManagementResponseToJSON(value.errorManagementResponse),
    };
}
exports.DayToJSON = DayToJSON;
