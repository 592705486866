import BankIcon from "component/Icon/icon.component";
import React, { ReactNode } from "react";
import { Placement } from "react-bootstrap/esm/Overlay";
import { placeholderForNullOrUndefined } from '../../helpers/utilities';

interface Props {
  descrizione?: string
  value?: ReactNode | string
  icon?: string
  iconSize?: number
  iconPosition?: 'left' | 'right'
  iconTooltipText?: string
  iconTooltipPlacement?: Placement
  onIconClick?: () => void
}

const LabelComponent: React.FC<Props> = (props: Props) => {

  const {
    descrizione,
    value,
    icon,
    iconSize,
    iconPosition,
    iconTooltipText,
    iconTooltipPlacement,
    onIconClick
  } = props;

  return (
    <>
      {descrizione && <div className='riepilogo-label'>{descrizione}</div>}

      <div className='riepilogo-value'>
        {value || placeholderForNullOrUndefined}
        {icon &&
          <BankIcon
            title={icon}
            height={iconSize || 24}
            width={iconSize || 24}
            tooltip={iconTooltipText}
            placement={iconTooltipPlacement}
            onClick={onIconClick}
            style={{ float: iconPosition }}
          />
        }
      </div>
    </>
  );
};

export default LabelComponent;
