"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.StrongAuthTypeEnumToJSON = exports.StrongAuthTypeEnumFromJSONTyped = exports.StrongAuthTypeEnumFromJSON = exports.StrongAuthTypeEnum = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var StrongAuthTypeEnum;
(function (StrongAuthTypeEnum) {
    StrongAuthTypeEnum["NUMERO_VERDE_BASE"] = "NUMERO_VERDE_BASE";
    StrongAuthTypeEnum["NUMERO_VERDE_BASE_ESTERO"] = "NUMERO_VERDE_BASE_ESTERO";
    StrongAuthTypeEnum["NUMERO_VERDE_CODICE"] = "NUMERO_VERDE_CODICE";
    StrongAuthTypeEnum["NUMERO_VERDE_CODICE_ESTERO"] = "NUMERO_VERDE_CODICE_ESTERO";
    StrongAuthTypeEnum["NUMERO_VERDE_CODICE_ESTERO_PSD2"] = "NUMERO_VERDE_CODICE_ESTERO_PSD2";
    StrongAuthTypeEnum["NUMERO_VERDE_CODICE_PSD2"] = "NUMERO_VERDE_CODICE_PSD2";
    StrongAuthTypeEnum["OTP"] = "OTP";
    StrongAuthTypeEnum["OTP_PIN"] = "OTP_PIN";
    StrongAuthTypeEnum["OTP_SIGNATURE"] = "OTP_SIGNATURE";
    StrongAuthTypeEnum["OTP_SIGNATURE_PAYLOAD"] = "OTP_SIGNATURE_PAYLOAD";
    StrongAuthTypeEnum["OTP_SIGNATURE_PSD2"] = "OTP_SIGNATURE_PSD2";
    StrongAuthTypeEnum["PASSWORD"] = "PASSWORD";
})(StrongAuthTypeEnum = exports.StrongAuthTypeEnum || (exports.StrongAuthTypeEnum = {}));
function StrongAuthTypeEnumFromJSON(json) {
    return StrongAuthTypeEnumFromJSONTyped(json, false);
}
exports.StrongAuthTypeEnumFromJSON = StrongAuthTypeEnumFromJSON;
function StrongAuthTypeEnumFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.StrongAuthTypeEnumFromJSONTyped = StrongAuthTypeEnumFromJSONTyped;
function StrongAuthTypeEnumToJSON(value) {
    return value;
}
exports.StrongAuthTypeEnumToJSON = StrongAuthTypeEnumToJSON;
