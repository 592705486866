import { dashboardMutui, FiltroFinanziamentiDto, formMutui, getDashboardMutui, getParametriMutui, loaderActive, parametriMutui, salvaWidgets, setMutuiRapportoSelezionato, widgetSaldo, widgetsUtente } from "@sparkasse/commons";
import ButtonText2 from "component/buttonText2/buttonText2.component";
import History from "component/history/history.component";
import SelectRapportoMini, { IRapporto } from "component/SelectRapportoMini/SelectRapportoMini";
import { currencyFormat, dateFormat } from "helpers/utilities";
import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { AppState } from "../../reducers";

export const WIDGET_MUTUO = "menu.mutui";

const WidgetMutui: React.FC<Props> = (props: Props) => {

  const { t } = useTranslation();

  const {
    parametri,
    form,
    dashboardMutui,
    getParametriMutui,
    setMutuiRapportoSelezionato,
    getDashboardMutui,
  } = props;

  const widget = props.widgetsUtente?.widgets?.find(w => w.widgetId === WIDGET_MUTUO);
  const mutuoSelezionatoCompleto = parametri.mutui?.find(r => r.codiceRapporto?.toString() === widget?.option?.codicerapporto);

  useEffect(() => {
    getParametriMutui();
  }, []);

  useEffect(() => {
    const selezione = mutuoSelezionatoCompleto || parametri?.mutui?.[0];
    selezione && setMutuiRapportoSelezionato(selezione);
  }, [parametri?.mutui, mutuoSelezionatoCompleto]);

  useEffect(() => {
    if (form.rapportoSelezionato?.codiceRapporto) {
      const request: FiltroFinanziamentiDto = {
        codiceRapporto: form.rapportoSelezionato?.codiceRapporto,
        progressivoRapporto: form.rapportoSelezionato?.progressivoRapporto,
      }
      getDashboardMutui(request);
    }
  }, [form.rapportoSelezionato?.codiceRapporto]);

  const onSelectAccount = (item: IRapporto) => {
    if (item.id !== (mutuoSelezionatoCompleto?.codiceRapporto || 0).toString()) {
      const _widgetSaldo = props.widgetSaldo ? [props.widgetSaldo] : [];
      props.salvaWidgets((props.widgetsUtente?.widgets?.map(w => w.widgetId === WIDGET_MUTUO ? {
        ...w,
        option: {
          toggle: true,
          interval: 0,
          codicerapporto: item.id
        }
      } : w) || []).concat(_widgetSaldo));
    }
  };

  const legenda = [
    t('home.widgets.mutuo.interessiResidui'),
    t('home.widgets.mutuo.capitaleResiduo'),
  ];

  const importi = [
    dashboardMutui?.datiPieBar?.interessi?.residuoImporto || 0,
    dashboardMutui?.datiPieBar?.capitale?.residuoImporto || 0
  ];

  const totale = dashboardMutui?.datiPieBar?.finanziamento?.residuoImporto;

  const option: any = {
    states: {
      active: {
        filter: {
          type: 'none',
          value: 0
        }
      },
      hover: {
        filter: {
          type: 'none',
          value: 0
        }
      }
    },
    plotOptions: {
      pie: {
        size: 160,
        expandOnClick: false
      }
    },
    dataLabels: { enabled: false },
    labels: [
      legenda[0] + ' ' + currencyFormat(importi[0], 'EUR'),
      legenda[1] + ' ' + currencyFormat(importi[1], 'EUR')
    ],
    legend: { show: false },
    colors: ["#6aa0ec", "#fad063"],
    fill: {
      colors: ["#6aa0ec", "#fad063"],
    },
    tooltip: {
      enabled: true,
      fillSeriesColor: false,
      y: {
        formatter: function (percentage: number) {
          return percentage + "%";
        },
        title: {
          formatter: function (label: any) {
            return label;
          }
        }
      }
    }
  };

  const seriesPieChart: number[] = importi.map(i => totale ? Math.round(i * 10000 / totale) / 100 : 0);

  const items: Array<IRapporto> = parametri.mutui?.map(f => ({ id: f.codiceRapporto?.toString() || "", description: `${t('home.widgets.mutuo.numero')} ${f.numeroFinanziamento || "-"}` })) || [];

  return (
    <div className="widget widget-prestiti">

      <p className="widget-title">
        {t('home.widgets.mutuo.title')}
      </p>

      <div className="underline-short" />

      {(parametri?.mutui?.length || 0) > 0 &&
        <>

          {(parametri?.mutui?.length || 0) > 1 &&
            <div>
              <SelectRapportoMini
                items={items}
                selectedItem={mutuoSelezionatoCompleto?.codiceRapporto?.toString() || parametri?.mutui?.[0].codiceRapporto?.toString() || ""}
                onSelect={onSelectAccount}
                numeroMinimoItems={2}
              />
            </div>
          }

          <div className="chartView-widget-prestiti">

            <Chart
              options={option}
              series={seriesPieChart}
              type="donut"
              width="160"
              height="160"
            />

            <div className="titleChartView-widget-prestiti">
              <p className="txtTitleChart-widget-prestiti">{t('home.widgets.mutuo.debitoResiduo')}</p>
              <p className="txtAmountChart-widget-prestiti is-saldo">
                {currencyFormat(totale)}
                <span className="widget-currency"> EUR</span>
              </p>
              <div className="txtAmountChart-widget-prestiti">
                {legenda.map((label: string, index: number) => (
                  <div key={`m-${index}`} className="legendView1col1-widget-prestiti">
                    <div
                      style={{
                        backgroundColor: option.colors[index],
                        height: 20,
                        width: 20,
                        marginRight: 5,
                      }}
                    />
                    <p className="txtTitleLegend-widget-prestiti">{label}</p>
                    <p className="txtImportoLegend-widget-prestiti is-saldo">{currencyFormat(importi[index])} EUR</p>
                  </div>
                ))}
              </div>
            </div>

          </div>

          <div className="chartView-widget-prestiti">
            <div className="widget-info-col-prestiti">
              <div className="widget-info-col-prestiti-label">{t('home.widgets.mutuo.prossimaRata')}</div>
              <div className="widget-info-col-prestiti-value">
                {dateFormat(form.rapportoSelezionato?.dataProssimaScadenza)}
              </div>
            </div>
            <div className="widget-info-col-prestiti">
              <div className="widget-info-col-prestiti-label">{t('home.widgets.mutuo.importoProssimaRata')}</div>
              <div className="widget-info-col-prestiti-value is-saldo">
                {form.rapportoSelezionato?.importoProssimaScadenza ?
                  `${currencyFormat(form.rapportoSelezionato?.importoProssimaScadenza)}` : "-"}
                <span className="widget-currency"> EUR</span>
              </div>
            </div>
          </div>

        </>
      }

      {(parametri?.mutui?.length || 0) == 0 &&
        <p className="widget-placeholder">
          {t('home.widgets.mutuo.nessunProdotto')}
        </p>
      }

      <ButtonText2
        className="widget-vai-a-dettaglio"
        descrizione={t('home.widgets.mutuo.cta')}
        onClick={() => {
          History.push("/piano-mutui", {
            idFinanziamentoIniziale: widget?.option?.codicerapporto
          })
        }}
        img="navigazione_avanti"
      />

    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  parametri: parametriMutui(state.mutui),
  form: formMutui(state.mutui),
  loader: loaderActive(state.general),
  dashboardMutui: dashboardMutui(state.mutui),
  widgetsUtente: widgetsUtente(state.widgets),
  widgetSaldo: widgetSaldo(state.widgets)
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getParametriMutui,
      setMutuiRapportoSelezionato,
      getDashboardMutui,
      salvaWidgets
    },
    dispatch
  );

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(WidgetMutui);
