"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuotazioneDettaglioTitoloDtoToJSON = exports.QuotazioneDettaglioTitoloDtoFromJSONTyped = exports.QuotazioneDettaglioTitoloDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function QuotazioneDettaglioTitoloDtoFromJSON(json) {
    return QuotazioneDettaglioTitoloDtoFromJSONTyped(json, false);
}
exports.QuotazioneDettaglioTitoloDtoFromJSON = QuotazioneDettaglioTitoloDtoFromJSON;
function QuotazioneDettaglioTitoloDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'valoreMax52Sett': !runtime_1.exists(json, 'valoreMax52Sett') ? undefined : json['valoreMax52Sett'],
        'valoreMin52Sett': !runtime_1.exists(json, 'valoreMin52Sett') ? undefined : json['valoreMin52Sett'],
        'quotazione': !runtime_1.exists(json, 'quotazione') ? undefined : _1.QuotazioneTitoloFromJSON(json['quotazione']),
        'volumeMiglioreDenaro': !runtime_1.exists(json, 'volumeMiglioreDenaro') ? undefined : json['volumeMiglioreDenaro'],
        'volumeMiglioreLettera': !runtime_1.exists(json, 'volumeMiglioreLettera') ? undefined : json['volumeMiglioreLettera'],
        'numDenari': !runtime_1.exists(json, 'numDenari') ? undefined : json['numDenari'],
        'denari': !runtime_1.exists(json, 'denari') ? undefined : json['denari'],
        'volumeDenari': !runtime_1.exists(json, 'volumeDenari') ? undefined : json['volumeDenari'],
        'numLettere': !runtime_1.exists(json, 'numLettere') ? undefined : json['numLettere'],
        'lettere': !runtime_1.exists(json, 'lettere') ? undefined : json['lettere'],
        'volumeLettere': !runtime_1.exists(json, 'volumeLettere') ? undefined : json['volumeLettere'],
        'apertura': !runtime_1.exists(json, 'apertura') ? undefined : json['apertura'],
        'chiusura': !runtime_1.exists(json, 'chiusura') ? undefined : json['chiusura'],
        'varAssoluta': !runtime_1.exists(json, 'varAssoluta') ? undefined : json['varAssoluta'],
    };
}
exports.QuotazioneDettaglioTitoloDtoFromJSONTyped = QuotazioneDettaglioTitoloDtoFromJSONTyped;
function QuotazioneDettaglioTitoloDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'valoreMax52Sett': value.valoreMax52Sett,
        'valoreMin52Sett': value.valoreMin52Sett,
        'quotazione': _1.QuotazioneTitoloToJSON(value.quotazione),
        'volumeMiglioreDenaro': value.volumeMiglioreDenaro,
        'volumeMiglioreLettera': value.volumeMiglioreLettera,
        'numDenari': value.numDenari,
        'denari': value.denari,
        'volumeDenari': value.volumeDenari,
        'numLettere': value.numLettere,
        'lettere': value.lettere,
        'volumeLettere': value.volumeLettere,
        'apertura': value.apertura,
        'chiusura': value.chiusura,
        'varAssoluta': value.varAssoluta,
    };
}
exports.QuotazioneDettaglioTitoloDtoToJSON = QuotazioneDettaglioTitoloDtoToJSON;
