"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNumeriUtiliPrelogin = exports.getNumeriUtiliPreloginSuccess = exports.getRiferimentiBloccoCartePrelogin = exports.getRiferimentiBloccoCartePreloginSuccess = exports.getParametriPrelogin = exports.getPinningInfo = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
const prelogin_types_1 = require("./prelogin.types");
function getPinningInfoStart() {
    return {
        type: __1.EReduxActionTypes.PRELOGIN_GET_PINNING_INFO_START
    };
}
function getPinningInfoSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.PRELOGIN_GET_PINNING_INFO_SUCCESS,
        payload
    };
}
function getPinningInfoError(value) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value));
    };
}
const getPinningInfo = (loaderEnabled = true) => async (dispatch, _, extraArguments) => {
    loaderEnabled && dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getPinningInfoStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/pinning_info.json')).then(parametri => {
            dispatch(getPinningInfoSuccess((0, api_rest_1.CertificateInfoOutDtoFromJSON)(parametri)));
            loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        new api_rest_1.AuthenticationControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, undefined)).getPinningInfo().then((response) => {
            dispatch(getPinningInfoSuccess(response));
        }).catch(async (error) => {
            dispatch(getPinningInfoError(await (0, helpers_1.formatError)(error)));
        }).finally(() => {
            loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
        });
    }
};
exports.getPinningInfo = getPinningInfo;
function getParametriPreloginStart() {
    return {
        type: __1.EReduxActionTypes.PRELOGIN_GET_PARAMETRI_START
    };
}
function getParametriPreloginSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.PRELOGIN_GET_PARAMETRI_SUCCESS,
        payload
    };
}
function getParametriPreloginError(value) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value));
    };
}
const getParametriPrelogin = (loaderEnabled = true) => async (dispatch, _, extraArguments) => {
    loaderEnabled && dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getParametriPreloginStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/parametri_prelogin.json')).then(parametri => {
            dispatch(getParametriPreloginSuccess((0, prelogin_types_1.ParametriPreloginFromJSON)(parametri)));
            loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const request = {
            appPlatform: extraArguments.platform === 'mobile' ? extraArguments.platformMobile.OS : undefined,
            appVersion: extraArguments.platform === 'mobile' ? extraArguments.appVersion : undefined
        };
        new api_rest_1.PreloginControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, undefined)).prelogin(request).then((response) => {
            dispatch(getParametriPreloginSuccess(response));
        }).catch(async (error) => {
            dispatch(getParametriPreloginError(await (0, helpers_1.formatError)(error)));
        }).finally(() => {
            loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
        });
    }
};
exports.getParametriPrelogin = getParametriPrelogin;
function getRiferimentiBloccoCartePreloginStart() {
    return {
        type: __1.EReduxActionTypes.PRELOGIN_BLOCCO_CARTE_GET_RIFERIMENTI_START
    };
}
function getRiferimentiBloccoCartePreloginSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.PRELOGIN_BLOCCO_CARTE_GET_RIFERIMENTI_SUCCESS,
        payload
    };
}
exports.getRiferimentiBloccoCartePreloginSuccess = getRiferimentiBloccoCartePreloginSuccess;
function getRiferimentiBloccoCartePreloginError(value) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value));
    };
}
const getRiferimentiBloccoCartePrelogin = (loaderEnabled = true, showError = true) => async (dispatch, getState, extraArguments) => {
    loaderEnabled && dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getRiferimentiBloccoCartePreloginStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/parametri_prelogin.json')).then(parametri => {
            loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const lingua = getState().i18n.currentLanguage;
        const request = {
            lingua: lingua || 'it'
        };
        new api_rest_1.PreloginControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, undefined)).riferimentiBloccoCartePrelogin(request).then((response) => {
            dispatch(getRiferimentiBloccoCartePreloginSuccess(response));
        }).catch(async (error) => {
            showError && dispatch(getRiferimentiBloccoCartePreloginError(await (0, helpers_1.formatError)(error)));
        }).finally(() => {
            loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
        });
    }
};
exports.getRiferimentiBloccoCartePrelogin = getRiferimentiBloccoCartePrelogin;
function getNumeriUtiliPreloginStart() {
    return {
        type: __1.EReduxActionTypes.PRELOGIN_NUMERI_UTILI_START
    };
}
function getNumeriUtiliPreloginSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.PRELOGIN_NUMERI_UTILI_SUCCESS,
        payload
    };
}
exports.getNumeriUtiliPreloginSuccess = getNumeriUtiliPreloginSuccess;
function getNumeriUtiliPreloginError(value) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value));
    };
}
const getNumeriUtiliPrelogin = (loaderEnabled = true, showError = true) => async (dispatch, getState, extraArguments) => {
    loaderEnabled && dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getNumeriUtiliPreloginStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/parametri_prelogin.json')).then(parametri => {
            loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const lingua = getState().i18n.currentLanguage;
        const request = {
            lingua: lingua || 'it'
        };
        new api_rest_1.PreloginControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, undefined)).contattiPrelogin(request).then((response) => {
            dispatch(getNumeriUtiliPreloginSuccess(response));
        }).catch(async (error) => {
            showError && dispatch(getNumeriUtiliPreloginError(await (0, helpers_1.formatError)(error)));
        }).finally(() => {
            loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
        });
    }
};
exports.getNumeriUtiliPrelogin = getNumeriUtiliPrelogin;
