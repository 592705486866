"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EsitoAutorizzazioneDisposizioneConsensoPsd2DtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneConsensoPsd2DtoToJSON = exports.EsitoAutorizzazioneDisposizioneConsensoPsd2DtoFromJSONTyped = exports.EsitoAutorizzazioneDisposizioneConsensoPsd2DtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function EsitoAutorizzazioneDisposizioneConsensoPsd2DtoFromJSON(json) {
    return EsitoAutorizzazioneDisposizioneConsensoPsd2DtoFromJSONTyped(json, false);
}
exports.EsitoAutorizzazioneDisposizioneConsensoPsd2DtoFromJSON = EsitoAutorizzazioneDisposizioneConsensoPsd2DtoFromJSON;
function EsitoAutorizzazioneDisposizioneConsensoPsd2DtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
        'codiceErrore': !runtime_1.exists(json, 'codiceErrore') ? undefined : json['codiceErrore'],
        'informazioniAutenticazioneDispositiva': !runtime_1.exists(json, 'informazioniAutenticazioneDispositiva') ? undefined : _1.InformazioniAutenticazioneDispositivaDtoFromJSON(json['informazioniAutenticazioneDispositiva']),
        'disposizione': !runtime_1.exists(json, 'disposizione') ? undefined : _1.AutorizzaConsensoPsd2DtoFromJSON(json['disposizione']),
    };
}
exports.EsitoAutorizzazioneDisposizioneConsensoPsd2DtoFromJSONTyped = EsitoAutorizzazioneDisposizioneConsensoPsd2DtoFromJSONTyped;
function EsitoAutorizzazioneDisposizioneConsensoPsd2DtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'stato': value.stato,
        'codiceErrore': value.codiceErrore,
        'informazioniAutenticazioneDispositiva': _1.InformazioniAutenticazioneDispositivaDtoToJSON(value.informazioniAutenticazioneDispositiva),
        'disposizione': _1.AutorizzaConsensoPsd2DtoToJSON(value.disposizione),
    };
}
exports.EsitoAutorizzazioneDisposizioneConsensoPsd2DtoToJSON = EsitoAutorizzazioneDisposizioneConsensoPsd2DtoToJSON;
/**
* @export
* @enum {string}
*/
var EsitoAutorizzazioneDisposizioneConsensoPsd2DtoStatoEnum;
(function (EsitoAutorizzazioneDisposizioneConsensoPsd2DtoStatoEnum) {
    EsitoAutorizzazioneDisposizioneConsensoPsd2DtoStatoEnum["OK"] = "OK";
    EsitoAutorizzazioneDisposizioneConsensoPsd2DtoStatoEnum["CREDENZIALE_ERRATA"] = "CREDENZIALE_ERRATA";
    EsitoAutorizzazioneDisposizioneConsensoPsd2DtoStatoEnum["ATTENDERE"] = "ATTENDERE";
    EsitoAutorizzazioneDisposizioneConsensoPsd2DtoStatoEnum["ACCOUNT_BLOCCATO"] = "ACCOUNT_BLOCCATO";
    EsitoAutorizzazioneDisposizioneConsensoPsd2DtoStatoEnum["ACCP"] = "ACCP";
    EsitoAutorizzazioneDisposizioneConsensoPsd2DtoStatoEnum["ACSC"] = "ACSC";
    EsitoAutorizzazioneDisposizioneConsensoPsd2DtoStatoEnum["ACSP"] = "ACSP";
    EsitoAutorizzazioneDisposizioneConsensoPsd2DtoStatoEnum["ACTC"] = "ACTC";
    EsitoAutorizzazioneDisposizioneConsensoPsd2DtoStatoEnum["ACWC"] = "ACWC";
    EsitoAutorizzazioneDisposizioneConsensoPsd2DtoStatoEnum["ACWP"] = "ACWP";
    EsitoAutorizzazioneDisposizioneConsensoPsd2DtoStatoEnum["RCVD"] = "RCVD";
    EsitoAutorizzazioneDisposizioneConsensoPsd2DtoStatoEnum["PDNG"] = "PDNG";
    EsitoAutorizzazioneDisposizioneConsensoPsd2DtoStatoEnum["RJCT"] = "RJCT";
    EsitoAutorizzazioneDisposizioneConsensoPsd2DtoStatoEnum["CANC"] = "CANC";
})(EsitoAutorizzazioneDisposizioneConsensoPsd2DtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneConsensoPsd2DtoStatoEnum || (exports.EsitoAutorizzazioneDisposizioneConsensoPsd2DtoStatoEnum = {}));
