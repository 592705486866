"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuotazioniSortDtoToJSON = exports.QuotazioniSortDtoFromJSONTyped = exports.QuotazioniSortDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function QuotazioniSortDtoFromJSON(json) {
    return QuotazioniSortDtoFromJSONTyped(json, false);
}
exports.QuotazioniSortDtoFromJSON = QuotazioniSortDtoFromJSON;
function QuotazioniSortDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'currentOrder': !runtime_1.exists(json, 'currentOrder') ? undefined : json['currentOrder'],
        'currentItem': !runtime_1.exists(json, 'currentItem') ? undefined : _1.SortItemDtoFromJSON(json['currentItem']),
        'order': !runtime_1.exists(json, 'order') ? undefined : json['order'],
        'items': !runtime_1.exists(json, 'items') ? undefined : (json['items'].map(_1.SortItemDtoFromJSON)),
    };
}
exports.QuotazioniSortDtoFromJSONTyped = QuotazioniSortDtoFromJSONTyped;
function QuotazioniSortDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'currentOrder': value.currentOrder,
        'currentItem': _1.SortItemDtoToJSON(value.currentItem),
        'order': value.order,
        'items': value.items === undefined ? undefined : (value.items.map(_1.SortItemDtoToJSON)),
    };
}
exports.QuotazioniSortDtoToJSON = QuotazioniSortDtoToJSON;
