"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setDocumentiContrattiResetForm = exports.setDocumentiResetForm = exports.setDocumentiResetComunicazioni = exports.setDocumentiSetTuttiIRapporti = exports.setDocumentiTipologiaComunicazione = exports.setDocumentiFonteDocumento = exports.setDocumentiVisualizzaSoloNonLetti = exports.setDocumentiDataA = exports.setDocumentiDataDa = exports.setDocumentiIntervallo = exports.setDocumentiRapporto = exports.setDocumentiTipoRapporto = exports.segnaDocumentiComeLetti = exports.getNumeroNuoveComunicazioni = exports.getParametriDocumenti = exports.getComunicazione = exports.getDocumento = exports.getElencoContratti = exports.getElencoDocumenti = exports.getTipologieComunicazioni = exports.getElencoComunicazioni = exports.getRapportiUtenteDocumentale = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
const documenti_types_1 = require("./documenti.types");
const moment = require("moment");
function getRapportiUtenteDocumentaleStart() {
    return {
        type: __1.EReduxActionTypes.DOCUMENTI_GET_RAPPORTI_UTENTE_DOCUMENTALE_START
    };
}
function getRapportiUtenteDocumentaleSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.DOCUMENTI_GET_RAPPORTI_UTENTE_DOCUMENTALE_SUCCESS,
        payload
    };
}
function getElencoComunicazioniStart() {
    return {
        type: __1.EReduxActionTypes.DOCUMENTI_GET_ELENCO_COMUNICAZIONI_START
    };
}
function getElencoComunicazioniSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.DOCUMENTI_GET_ELENCO_COMUNICAZIONI_SUCCESS,
        payload
    };
}
function getTipologieComunicazioniStart() {
    return {
        type: __1.EReduxActionTypes.DOCUMENTI_GET_TIPOLOGIE_COMUNICAZIONI_START
    };
}
function getTipologieComunicazioniSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.DOCUMENTI_GET_TIPOLOGIE_COMUNICAZIONI_SUCCESS,
        payload
    };
}
function getComunicazioneStart() {
    return {
        type: __1.EReduxActionTypes.DOCUMENTI_GET_COMUNICAZIONE_START
    };
}
function getComunicazioneSuccess() {
    return {
        type: __1.EReduxActionTypes.DOCUMENTI_GET_COMUNICAZIONE_SUCCESS
    };
}
function getElencoDocumentiStart() {
    return {
        type: __1.EReduxActionTypes.DOCUMENTI_GET_ELENCO_DOCUMENTI_START
    };
}
function getElencoDocumentiSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.DOCUMENTI_GET_ELENCO_DOCUMENTI_SUCCESS,
        payload
    };
}
function getDocumentoStart() {
    return {
        type: __1.EReduxActionTypes.DOCUMENTI_GET_DOCUMENTO_START
    };
}
function getDocumentoSuccess(richiestoAggiornamento) {
    return {
        type: __1.EReduxActionTypes.DOCUMENTI_GET_DOCUMENTO_SUCCESS,
        payload: richiestoAggiornamento
    };
}
function getParametriDocumentiStart() {
    return {
        type: __1.EReduxActionTypes.DOCUMENTI_GET_PARAMETRI_START
    };
}
function getParametriDocumentiSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.DOCUMENTI_GET_PARAMETRI_SUCCESS,
        payload
    };
}
function getNumeroNuoveComunicazioniStart() {
    return {
        type: __1.EReduxActionTypes.DOCUMENTI_GET_NUMERO_NUOVE_COMUNICAZIONI_START
    };
}
function getNumeroNuoveComunicazioniSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.DOCUMENTI_GET_NUMERO_NUOVE_COMUNICAZIONI_SUCCESS,
        payload
    };
}
function segnaDocumentiComeLettiStart() {
    return {
        type: __1.EReduxActionTypes.DOCUMENTI_SEGNA_COME_LETTI_START
    };
}
function segnaDocumentiComeLettiSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.DOCUMENTI_SEGNA_COME_LETTI_SUCCESS,
        payload
    };
}
;
const getRapportiUtenteDocumentale = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getRapportiUtenteDocumentaleStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.RapportiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .rapportiUtenteDocumentale().then((response) => {
                dispatch(getRapportiUtenteDocumentaleSuccess(response));
            }).catch(async (error) => {
                dispatch((0, general_actions_1.setError)(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getRapportiUtenteDocumentale = getRapportiUtenteDocumentale;
const getElencoComunicazioni = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getElencoComunicazioniStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        const ultimi5 = ((0, __1.formDocumenti)(getState().documenti).intervallo === documenti_types_1.DocumentiTipoIntervallo.Ultimi5);
        const request = {
            cercaComunicazioniInDto: {
                codiceRapporto: +(0, __1.formDocumenti)(getState().documenti).numeroRapporto || undefined,
                ultimeCinqueComunicazioni: ultimi5,
                dataIniziale: ultimi5 ? undefined : moment((0, __1.formDocumenti)(getState().documenti).dataDa, 'YYYY-MM-DD').toDate(),
                dataFinale: ultimi5 ? undefined : moment((0, __1.formDocumenti)(getState().documenti).dataA, 'YYYY-MM-DD').toDate(),
                soloNonLette: (0, __1.formDocumenti)(getState().documenti).visualizzaSoloNonLetti,
                tipologiaComunicazioni: (0, __1.formDocumenti)(getState().documenti).tipologiaComunicazione
            }
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.DocumentaleControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .getElencoComunicazioni(request).then((response) => {
                dispatch(getElencoComunicazioniSuccess(response));
            }).catch(async (error) => {
                dispatch((0, general_actions_1.setError)(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getElencoComunicazioni = getElencoComunicazioni;
const getTipologieComunicazioni = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getTipologieComunicazioniStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.DocumentaleControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .getTipologieComunicazioni().then((response) => {
                dispatch(getTipologieComunicazioniSuccess(response));
            }).catch(async (error) => {
                dispatch((0, general_actions_1.setError)(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getTipologieComunicazioni = getTipologieComunicazioni;
const getElencoDocumenti = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getElencoDocumentiStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        const request = {
            cercaDocumentiInDto: {
                codiceRapporto: +(0, __1.formDocumenti)(getState().documenti).numeroRapporto || undefined,
                dataIniziale: moment((0, __1.formDocumenti)(getState().documenti).dataDa, 'YYYY-MM-DD').toDate(),
                dataFinale: moment((0, __1.formDocumenti)(getState().documenti).dataA, 'YYYY-MM-DD').toDate(),
                tipologieComunicazione: ['BDC']
            }
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.DocumentaleControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .getElencoDocumenti(request).then((response) => {
                dispatch(getElencoDocumentiSuccess(response));
            }).catch(async (error) => {
                dispatch((0, general_actions_1.setError)(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getElencoDocumenti = getElencoDocumenti;
const getElencoContratti = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getElencoDocumentiStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        const request = {
            cercaDocumentiInDto: {
                codiceRapporto: (+(0, __1.formDocumenti)(getState().documenti).numeroRapporto) || undefined,
                dataIniziale: moment((0, __1.formDocumenti)(getState().documenti).dataDa || (0, __1.parametriDocumenti)(getState().documenti).comunicazioniInfoDataIniziale, 'YYYY-MM-DD').toDate(),
                dataFinale: moment((0, __1.formDocumenti)(getState().documenti).dataA || (0, __1.parametriDocumenti)(getState().documenti).comunicazioniInfoDataFinale, 'YYYY-MM-DD').toDate(),
                tipologieComunicazione: ['FEA', 'ANA']
            }
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.DocumentaleControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .getElencoDocumenti(request).then((response) => {
                dispatch(getElencoDocumentiSuccess(response));
            }).catch(async (error) => {
                dispatch((0, general_actions_1.setError)(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getElencoContratti = getElencoContratti;
const getDocumento = (id, apri) => async (dispatch, getState, extraArguments) => {
    const documenti = getState().documenti;
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getDocumentoStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        const request = {
            id: id
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.DocumentaleControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf'))
                .getDocumento(request).then((response) => {
                var _a;
                const blob = new Blob([response], { type: 'application/pdf' });
                const documento = (_a = documenti.comunicazioni) === null || _a === void 0 ? void 0 : _a.find(doc => doc.id == request.id);
                const timestamp = moment().format('DD_MM_YYYY-hh_mm');
                let nomeDocumento = 'Documento (' + timestamp + ').pdf';
                if (documento) {
                    const dataNomeDocumento = moment(documento.dataRiferimento).format('DD_MM_YYYY') || 'NO_DATE';
                    nomeDocumento = documento.descrizione + ' ' + dataNomeDocumento + ' (' + timestamp + ').pdf';
                }
                if (extraArguments.platform === 'desktop') {
                    if (apri) {
                        (0, helpers_1.hadlePdfResponse)(response, (url) => dispatch((0, general_actions_1.popUpBlockedPdf)(url)));
                    }
                    else {
                        (0, helpers_1.hadlePdfResponseForDownload)(response, nomeDocumento);
                    }
                }
                if (extraArguments.platform === 'mobile') {
                    (0, helpers_1.download_sharePDF)(extraArguments, nomeDocumento, blob, false);
                }
                if (!documento || !documento.letto) {
                    dispatch((0, exports.segnaDocumentiComeLetti)([request.id]));
                    dispatch(getDocumentoSuccess(true));
                }
                else {
                    dispatch(getDocumentoSuccess(false));
                }
            }).catch(async (error) => {
                dispatch((0, general_actions_1.setError)(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getDocumento = getDocumento;
const getComunicazione = (id, tipologia, apri) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getComunicazioneStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        const request = {
            id: id,
            tipologia: tipologia
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.DocumentaleControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf'))
                .getDocumentoPerTipo(request).then((response) => {
                dispatch(getComunicazioneSuccess());
                const blob = new Blob([response], { type: 'application/pdf' });
                if (extraArguments.platform === 'desktop') {
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        // @ts-ignore
                        window.navigator.msSaveOrOpenBlob(blob, `${moment().format('YYYYMMDD_HHmmss')}.pdf`);
                    }
                    else {
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = `${moment().format('YYYYMMDD_HHmmss')}.pdf`;
                        if (apri) {
                            link.target = '_blank';
                        }
                        link.click();
                    }
                }
                if (extraArguments.platform === 'mobile') {
                    (0, helpers_1.download_sharePDF)(extraArguments, 'Documento.pdf', blob, false);
                }
            }).catch(async (error) => {
                dispatch((0, general_actions_1.setError)(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getComunicazione = getComunicazione;
const getParametriDocumenti = () => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getParametriDocumentiStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.DocumentaleControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .getParametri().then((response) => {
                dispatch(getParametriDocumentiSuccess(response));
            }).catch(async (error) => {
                dispatch((0, general_actions_1.setError)(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getParametriDocumenti = getParametriDocumenti;
const getNumeroNuoveComunicazioni = () => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getNumeroNuoveComunicazioniStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.DocumentaleControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .getNumeroNuoveComunicazioni().then((response) => {
                dispatch(getNumeroNuoveComunicazioniSuccess(response));
            }).catch(async (error) => {
                dispatch((0, general_actions_1.setError)(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getNumeroNuoveComunicazioni = getNumeroNuoveComunicazioni;
const segnaDocumentiComeLetti = (docIds) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(segnaDocumentiComeLettiStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        const request = {
            requestBody: docIds
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.DocumentaleControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .segnaComeLetti(request).then((response) => {
                dispatch(segnaDocumentiComeLettiSuccess(response));
            }).catch(async (error) => {
                dispatch((0, general_actions_1.setError)(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.segnaDocumentiComeLetti = segnaDocumentiComeLetti;
function setDocumentiTipoRapporto(payload) {
    return {
        type: __1.EReduxActionTypes.DOCUMENTI_SET_TIPO_RAPPORTO,
        payload
    };
}
exports.setDocumentiTipoRapporto = setDocumentiTipoRapporto;
function setDocumentiRapporto(payload) {
    return {
        type: __1.EReduxActionTypes.DOCUMENTI_SET_RAPPORTO,
        payload
    };
}
exports.setDocumentiRapporto = setDocumentiRapporto;
function setDocumentiIntervallo(payload) {
    return {
        type: __1.EReduxActionTypes.DOCUMENTI_SET_INTERVALLO,
        payload
    };
}
exports.setDocumentiIntervallo = setDocumentiIntervallo;
function setDocumentiDataDa(payload) {
    return {
        type: __1.EReduxActionTypes.DOCUMENTI_SET_DATA_DA,
        payload
    };
}
exports.setDocumentiDataDa = setDocumentiDataDa;
function setDocumentiDataA(payload) {
    return {
        type: __1.EReduxActionTypes.DOCUMENTI_SET_DATA_A,
        payload
    };
}
exports.setDocumentiDataA = setDocumentiDataA;
function setDocumentiVisualizzaSoloNonLetti(payload) {
    return {
        type: __1.EReduxActionTypes.DOCUMENTI_SET_SOLO_NON_LETTI,
        payload
    };
}
exports.setDocumentiVisualizzaSoloNonLetti = setDocumentiVisualizzaSoloNonLetti;
function setDocumentiFonteDocumento(payload) {
    return {
        type: __1.EReduxActionTypes.DOCUMENTI_SET_FONTE_DOCUMENTO,
        payload
    };
}
exports.setDocumentiFonteDocumento = setDocumentiFonteDocumento;
function setDocumentiTipologiaComunicazione(payload) {
    return {
        type: __1.EReduxActionTypes.DOCUMENTI_SET_TIPOLOGIA_DOCUMENTAZIONE,
        payload
    };
}
exports.setDocumentiTipologiaComunicazione = setDocumentiTipologiaComunicazione;
function setDocumentiSetTuttiIRapporti(payload) {
    return {
        type: __1.EReduxActionTypes.DOCUMENTI_SET_TUTTI_I_RAPPORTI,
        payload
    };
}
exports.setDocumentiSetTuttiIRapporti = setDocumentiSetTuttiIRapporti;
function setDocumentiResetComunicazioni() {
    return {
        type: __1.EReduxActionTypes.DOCUMENTI_COMUNICAZIONI_RESET
    };
}
exports.setDocumentiResetComunicazioni = setDocumentiResetComunicazioni;
function setDocumentiResetForm() {
    return {
        type: __1.EReduxActionTypes.DOCUMENTI_FORM_RESET
    };
}
exports.setDocumentiResetForm = setDocumentiResetForm;
function setDocumentiContrattiResetForm() {
    return {
        type: __1.EReduxActionTypes.DOCUMENTI_CONTRATTI_FORM_RESET
    };
}
exports.setDocumentiContrattiResetForm = setDocumentiContrattiResetForm;
