"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LimitiOperativiControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class LimitiOperativiControllerApi extends runtime.BaseAPI {
    /**
     * Limiti operativi
     */
    limitiOperativiRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/limitioperativi`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.LimitiOperativiDtoFromJSON(jsonValue));
        });
    }
    /**
     * Limiti operativi
     */
    limitiOperativi() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.limitiOperativiRaw();
            return yield response.value();
        });
    }
    /**
     * Modifica massimali per bonifico
     */
    modificaMassimaliBonificoIstantaneoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.modificaMassimaleSepaIstantInDto === null || requestParameters.modificaMassimaleSepaIstantInDto === undefined) {
                throw new runtime.RequiredError('modificaMassimaleSepaIstantInDto', 'Required parameter requestParameters.modificaMassimaleSepaIstantInDto was null or undefined when calling modificaMassimaliBonificoIstantaneo.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/limitioperativi/sepaistant`,
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ModificaMassimaleSepaIstantInDtoToJSON(requestParameters.modificaMassimaleSepaIstantInDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ModificaMassimaleSepaIstantOutDtoFromJSON(jsonValue));
        });
    }
    /**
     * Modifica massimali per bonifico
     */
    modificaMassimaliBonificoIstantaneo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.modificaMassimaliBonificoIstantaneoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Lettura parametri limitioperativi
     */
    parametriLimitiOperativiRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/limitioperativi/parametri`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ParametriLimitiOperativiDtoFromJSON(jsonValue));
        });
    }
    /**
     * Lettura parametri limitioperativi
     */
    parametriLimitiOperativi() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.parametriLimitiOperativiRaw();
            return yield response.value();
        });
    }
    /**
     * Modifica massimali per bonifico
     */
    verificaStatoModificaMassimaliSepaIstantRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/limitioperativi/sepaistant/status`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.StatoModificaMassimaleOutDtoFromJSON(jsonValue));
        });
    }
    /**
     * Modifica massimali per bonifico
     */
    verificaStatoModificaMassimaliSepaIstant() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.verificaStatoModificaMassimaliSepaIstantRaw();
            return yield response.value();
        });
    }
}
exports.LimitiOperativiControllerApi = LimitiOperativiControllerApi;
