"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.categoriaSel = exports.faqs = void 0;
const reselect_1 = require("reselect");
const faqReducer = (state) => state;
exports.faqs = (0, reselect_1.createSelector)(faqReducer, (state) => {
    return state.faq;
});
exports.categoriaSel = (0, reselect_1.createSelector)(faqReducer, (state) => {
    return state.categoriaSel;
});
