"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DatiIntestatarioDtoToJSON = exports.DatiIntestatarioDtoFromJSONTyped = exports.DatiIntestatarioDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function DatiIntestatarioDtoFromJSON(json) {
    return DatiIntestatarioDtoFromJSONTyped(json, false);
}
exports.DatiIntestatarioDtoFromJSON = DatiIntestatarioDtoFromJSON;
function DatiIntestatarioDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'ndg': !runtime_1.exists(json, 'ndg') ? undefined : json['ndg'],
        'nominativo': !runtime_1.exists(json, 'nominativo') ? undefined : json['nominativo'],
        'ruolo': !runtime_1.exists(json, 'ruolo') ? undefined : json['ruolo'],
        'rapportoCointestato': !runtime_1.exists(json, 'rapportoCointestato') ? undefined : json['rapportoCointestato'],
        'rapportoDelega': !runtime_1.exists(json, 'rapportoDelega') ? undefined : json['rapportoDelega'],
        'rapporti': !runtime_1.exists(json, 'rapporti') ? undefined : json['rapporti'],
        'rapportiInformativi': !runtime_1.exists(json, 'rapportiInformativi') ? undefined : json['rapportiInformativi'],
    };
}
exports.DatiIntestatarioDtoFromJSONTyped = DatiIntestatarioDtoFromJSONTyped;
function DatiIntestatarioDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'ndg': value.ndg,
        'nominativo': value.nominativo,
        'ruolo': value.ruolo,
        'rapportoCointestato': value.rapportoCointestato,
        'rapportoDelega': value.rapportoDelega,
        'rapporti': value.rapporti,
        'rapportiInformativi': value.rapportiInformativi,
    };
}
exports.DatiIntestatarioDtoToJSON = DatiIntestatarioDtoToJSON;
