"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RibaRequestInDtoToJSON = exports.RibaRequestInDtoFromJSONTyped = exports.RibaRequestInDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function RibaRequestInDtoFromJSON(json) {
    return RibaRequestInDtoFromJSONTyped(json, false);
}
exports.RibaRequestInDtoFromJSON = RibaRequestInDtoFromJSON;
function RibaRequestInDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codiceRapporto': json['codiceRapporto'],
        'listAvvisiRest': (json['listAvvisiRest'].map(_1.AvvisoDtoFromJSON)),
        'condivisione': !runtime_1.exists(json, 'condivisione') ? undefined : (json['condivisione'].map(_1.CondivisioneDtoFromJSON)),
    };
}
exports.RibaRequestInDtoFromJSONTyped = RibaRequestInDtoFromJSONTyped;
function RibaRequestInDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codiceRapporto': value.codiceRapporto,
        'listAvvisiRest': (value.listAvvisiRest.map(_1.AvvisoDtoToJSON)),
        'condivisione': value.condivisione === undefined ? undefined : (value.condivisione.map(_1.CondivisioneDtoToJSON)),
    };
}
exports.RibaRequestInDtoToJSON = RibaRequestInDtoToJSON;
