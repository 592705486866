"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.depositoRisparmiReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const helpers_1 = require("../helpers");
const initialState = {
    parametri: (0, api_rest_1.ParametriDtoFromJSON)({}),
    elenco: (0, api_rest_1.LibrettoDiRisparmioDtoFromJSON)({}),
    form: {
        entrate: true,
        uscite: true,
        entrateUscite: '1'
    },
    pdfElenco: undefined,
    excelElenco: undefined,
    pdfDettaglioDeposito: undefined
};
const depositoRisparmiReducer = (state = initialState, action) => {
    var _a, _b;
    switch (action.type) {
        case __1.EReduxActionTypes.DEPOSITO_RISPARMI_GET_PARAMETRI_SUCCESS:
            return {
                ...initialState,
                parametri: action.payload
            };
        case __1.EReduxActionTypes.DEPOSITO_RISPARMI_GET_ELENCO_SUCCESS:
            return {
                ...state,
                elenco: action.payload,
                parametri: {
                    ...state.parametri,
                    rapporti: (_b = (_a = state.parametri) === null || _a === void 0 ? void 0 : _a.rapporti) === null || _b === void 0 ? void 0 : _b.map((rapp) => {
                        var _a, _b, _c;
                        if (((_a = rapp.rapporto) === null || _a === void 0 ? void 0 : _a.id) === ((_c = (_b = state.form.rapportoSelezionato) === null || _b === void 0 ? void 0 : _b.rapporto) === null || _c === void 0 ? void 0 : _c.id)) {
                            return {
                                ...rapp,
                                saldo: {
                                    ...rapp.saldo,
                                    saldoContabile: action.payload.saldoContabile
                                }
                            };
                        }
                        else {
                            return { ...rapp };
                        }
                    })
                },
                form: {
                    ...state.form,
                    rapportoSelezionato: {
                        ...state.form.rapportoSelezionato,
                        saldo: {
                            ...state.form.rapportoSelezionato.saldo,
                            saldoContabile: action.payload.saldoContabile
                        }
                    }
                }
            };
        case __1.EReduxActionTypes.DEPOSITO_RISPARMI_SET_RAPPORTO_SELEZIONATO:
            return {
                ...state,
                form: {
                    ...state.form,
                    rapportoSelezionato: action.payload
                }
            };
        case __1.EReduxActionTypes.DEPOSITO_RISPARMI_RESET_FILTRO:
            return {
                ...state,
                form: {
                    ...initialState.form
                }
            };
        case __1.EReduxActionTypes.DEPOSITO_RISPARMI_SET_DESCRIZIONE:
            return {
                ...state,
                form: {
                    ...state.form,
                    descrizione: action.payload,
                    descrizioneError: (0, helpers_1.validationField)(action.payload, '')
                }
            };
        case __1.EReduxActionTypes.DEPOSITO_RISPARMI_SET_PERIODO:
            return {
                ...state,
                form: {
                    ...state.form,
                    periodo: action.payload
                }
            };
        case __1.EReduxActionTypes.DEPOSITO_RISPARMI_SET_DATA_DA:
            return {
                ...state,
                form: {
                    ...state.form,
                    dataDa: action.payload,
                    dataDaError: (0, helpers_1.validationFieldDate)(action.payload, '', false, '', state.form.dataA),
                    dataAError: (0, helpers_1.validationFieldDate)(state.form.dataA, '', false, action.payload),
                    periodo: initialState.form.periodo
                }
            };
        case __1.EReduxActionTypes.DEPOSITO_RISPARMI_SET_DATA_A:
            return {
                ...state,
                form: {
                    ...state.form,
                    dataA: action.payload,
                    dataAError: (0, helpers_1.validationFieldDate)(action.payload, '', false, state.form.dataDa),
                    dataDaError: (0, helpers_1.validationFieldDate)(state.form.dataDa, '', false, '', action.payload),
                    periodo: initialState.form.periodo
                }
            };
        case __1.EReduxActionTypes.DEPOSITO_RISPARMI_SET_IMPORTO:
            return {
                ...state,
                form: {
                    ...state.form,
                    importo: action.payload,
                    importoError: (0, helpers_1.validationField)(action.payload, '')
                }
            };
        case __1.EReduxActionTypes.DEPOSITO_RISPARMI_SET_ENTRATE:
            return {
                ...state,
                form: {
                    ...state.form,
                    entrate: action.payload
                }
            };
        case __1.EReduxActionTypes.DEPOSITO_RISPARMI_SET_USCITE:
            return {
                ...state,
                form: {
                    ...state.form,
                    uscite: action.payload
                }
            };
        case __1.EReduxActionTypes.DEPOSITO_RISPARMI_SET_ENTRATE_USCITE:
            return {
                ...state,
                form: {
                    ...state.form,
                    entrate: action.payload === '1' || action.payload === '2',
                    uscite: action.payload === '1' || action.payload === '3',
                    entrateUscite: action.payload
                }
            };
        case __1.EReduxActionTypes.DEPOSITO_RISPARMI_SET_ACCORDITION_FILTRI_OPENED:
            return {
                ...state,
                form: {
                    ...state.form,
                    accordionFiltriOpened: action.payload
                }
            };
        case __1.EReduxActionTypes.DEPOSITO_RISPARMI_ESPORTA_PDF_SUCCESS:
            return {
                ...state,
                pdfElenco: action.payload
            };
        case __1.EReduxActionTypes.DEPOSITO_RISPARMI_ESPORTA_EXCEL_SUCCESS:
            return {
                ...state,
                excelElenco: action.payload
            };
        case __1.EReduxActionTypes.DEPOSITO_RISPARMI_ESPORTA_DETTAGLIO_PDF_SUCCESS:
            return {
                ...state,
                pdfDettaglioDeposito: action.payload
            };
        case __1.EReduxActionTypes.DEPOSITO_RISPARMI_RESET:
            return {
                ...initialState
            };
        case __1.EReduxActionTypes.RESET_ALL: // action invocata dalla logout
            return {
                ...initialState
            };
        default:
            return state;
    }
};
exports.depositoRisparmiReducer = depositoRisparmiReducer;
exports.default = exports.depositoRisparmiReducer;
