"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setNeosuranceSetRapportoSelezionato = exports.getNeosurancePolizze = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
function getNeosurancePolizzeStart() {
    return {
        type: __1.EReduxActionTypes.NEOSURANCE_GET_POLIZZE_START
    };
}
function getNeosurancePolizzeSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.NEOSURANCE_GET_POLIZZE_SUCCESS,
        payload
    };
}
function generalError(value) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value));
    };
}
const getNeosurancePolizze = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getNeosurancePolizzeStart());
    if (extraArguments.mock) {
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.NeosuranceControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .policies().then((response) => {
                dispatch(getNeosurancePolizzeSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getNeosurancePolizze = getNeosurancePolizze;
function setNeosuranceSetRapportoSelezionato(payload) {
    return {
        type: __1.EReduxActionTypes.NEOSURANCE_SET_RAPPORTO_SELEZIONATO,
        payload: payload
    };
}
exports.setNeosuranceSetRapportoSelezionato = setNeosuranceSetRapportoSelezionato;
