"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormTributiLocaliFromJSONTyped = exports.FilterTributiLocaliFromJSONTyped = exports.TipoPagamento = exports.TipoNotifica = void 0;
const helpers_1 = require("../helpers");
var TipoNotifica;
(function (TipoNotifica) {
    TipoNotifica["accettazione"] = "ACCETTAZIONE";
    TipoNotifica["empty"] = "EMPTY";
    TipoNotifica["tributi"] = "TRIBUTI";
})(TipoNotifica = exports.TipoNotifica || (exports.TipoNotifica = {}));
var TipoPagamento;
(function (TipoPagamento) {
    TipoPagamento["bonifico_sepa_ordinario"] = "BOI";
    TipoPagamento["bonifico_bancario"] = "BOBA";
    TipoPagamento["f24_semplificato"] = "F24s";
})(TipoPagamento = exports.TipoPagamento || (exports.TipoPagamento = {}));
function FilterTributiLocaliFromJSONTyped(json) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codiceFiscaleContribuente': !(0, helpers_1.exists)(json, 'codiceFiscaleContribuente') ? '' : json.codiceFiscaleContribuente,
        'codiceFiscaleContribuenteError': !(0, helpers_1.exists)(json, 'codiceFiscaleContribuenteError') ? 'invalid.required' : json.codiceFiscaleContribuenteError,
        'codiceFiscaleContribuenteDirty': !(0, helpers_1.exists)(json, 'codiceFiscaleContribuenteDirty') ? false : json.codiceFiscaleContribuenteDirty,
        'codicePagamento': !(0, helpers_1.exists)(json, 'codicePagamento') ? '' : json.codicePagamento,
        'codicePagamentoError': !(0, helpers_1.exists)(json, 'codicePagamentoError') ? 'invalid.required' : json.codicePagamentoError,
        'codicePagamentoDirty': !(0, helpers_1.exists)(json, 'codicePagamentoDirty') ? false : json.codicePagamentoDirty,
        'filtriDirty': !(0, helpers_1.exists)(json, 'filtriDirty') ? false : json.filtriDirty,
        'filtriSubmitted': !(0, helpers_1.exists)(json, 'filtriSubmitted') ? false : json.filtriSubmitted
    };
}
exports.FilterTributiLocaliFromJSONTyped = FilterTributiLocaliFromJSONTyped;
function FormTributiLocaliFromJSONTyped(json) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        step: !(0, helpers_1.exists)(json, 'step') ? 0 : json.step,
        rapportoSelezionato: !(0, helpers_1.exists)(json, 'rapportoSelezionato') ? undefined : json.rapportoSelezionato,
        presaVisione: !(0, helpers_1.exists)(json, 'presaVisione') ? false : json.presaVisione
    };
}
exports.FormTributiLocaliFromJSONTyped = FormTributiLocaliFromJSONTyped;
