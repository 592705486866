"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EsitoAutorizzazioneDisposizioneModelloF24DtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneModelloF24DtoToJSON = exports.EsitoAutorizzazioneDisposizioneModelloF24DtoFromJSONTyped = exports.EsitoAutorizzazioneDisposizioneModelloF24DtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function EsitoAutorizzazioneDisposizioneModelloF24DtoFromJSON(json) {
    return EsitoAutorizzazioneDisposizioneModelloF24DtoFromJSONTyped(json, false);
}
exports.EsitoAutorizzazioneDisposizioneModelloF24DtoFromJSON = EsitoAutorizzazioneDisposizioneModelloF24DtoFromJSON;
function EsitoAutorizzazioneDisposizioneModelloF24DtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
        'codiceErrore': !runtime_1.exists(json, 'codiceErrore') ? undefined : json['codiceErrore'],
        'informazioniAutenticazioneDispositiva': !runtime_1.exists(json, 'informazioniAutenticazioneDispositiva') ? undefined : _1.InformazioniAutenticazioneDispositivaDtoFromJSON(json['informazioniAutenticazioneDispositiva']),
        'disposizione': !runtime_1.exists(json, 'disposizione') ? undefined : _1.ModelloF24DtoFromJSON(json['disposizione']),
    };
}
exports.EsitoAutorizzazioneDisposizioneModelloF24DtoFromJSONTyped = EsitoAutorizzazioneDisposizioneModelloF24DtoFromJSONTyped;
function EsitoAutorizzazioneDisposizioneModelloF24DtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'stato': value.stato,
        'codiceErrore': value.codiceErrore,
        'informazioniAutenticazioneDispositiva': _1.InformazioniAutenticazioneDispositivaDtoToJSON(value.informazioniAutenticazioneDispositiva),
        'disposizione': _1.ModelloF24DtoToJSON(value.disposizione),
    };
}
exports.EsitoAutorizzazioneDisposizioneModelloF24DtoToJSON = EsitoAutorizzazioneDisposizioneModelloF24DtoToJSON;
/**
* @export
* @enum {string}
*/
var EsitoAutorizzazioneDisposizioneModelloF24DtoStatoEnum;
(function (EsitoAutorizzazioneDisposizioneModelloF24DtoStatoEnum) {
    EsitoAutorizzazioneDisposizioneModelloF24DtoStatoEnum["OK"] = "OK";
    EsitoAutorizzazioneDisposizioneModelloF24DtoStatoEnum["CREDENZIALE_ERRATA"] = "CREDENZIALE_ERRATA";
    EsitoAutorizzazioneDisposizioneModelloF24DtoStatoEnum["ATTENDERE"] = "ATTENDERE";
    EsitoAutorizzazioneDisposizioneModelloF24DtoStatoEnum["ACCOUNT_BLOCCATO"] = "ACCOUNT_BLOCCATO";
    EsitoAutorizzazioneDisposizioneModelloF24DtoStatoEnum["ACCP"] = "ACCP";
    EsitoAutorizzazioneDisposizioneModelloF24DtoStatoEnum["ACSC"] = "ACSC";
    EsitoAutorizzazioneDisposizioneModelloF24DtoStatoEnum["ACSP"] = "ACSP";
    EsitoAutorizzazioneDisposizioneModelloF24DtoStatoEnum["ACTC"] = "ACTC";
    EsitoAutorizzazioneDisposizioneModelloF24DtoStatoEnum["ACWC"] = "ACWC";
    EsitoAutorizzazioneDisposizioneModelloF24DtoStatoEnum["ACWP"] = "ACWP";
    EsitoAutorizzazioneDisposizioneModelloF24DtoStatoEnum["RCVD"] = "RCVD";
    EsitoAutorizzazioneDisposizioneModelloF24DtoStatoEnum["PDNG"] = "PDNG";
    EsitoAutorizzazioneDisposizioneModelloF24DtoStatoEnum["RJCT"] = "RJCT";
    EsitoAutorizzazioneDisposizioneModelloF24DtoStatoEnum["CANC"] = "CANC";
})(EsitoAutorizzazioneDisposizioneModelloF24DtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneModelloF24DtoStatoEnum || (exports.EsitoAutorizzazioneDisposizioneModelloF24DtoStatoEnum = {}));
