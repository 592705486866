"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildFilterExportDettaglioF24 = exports.buildF24Elide = exports.buildF24Accise = exports.buildF24Semplificato = exports.buildF24Ordinario = exports.identificativiRighe = exports.acciseRighe = exports.semplificatoRighe = exports.entiPrevidenzialiRighe = exports.inailRighe = exports.tributiLocaliRighe = exports.regioniRighe = exports.inpsRighe = exports.erarioRighe = exports.disableSalvaBozzaElide = exports.checkUpdateBozzaElide = exports.disableSalvaBozzaAccise = exports.checkUpdateBozzaAccise = exports.checkUpdateBozzaSemplificato = exports.disableSalvaBozzaOrdinario = exports.checkUpdateBozzaOrdinario = exports.currentBozzaF24Ordinario = exports.formF24SemplificatoValid = exports.righiMotivazioneF24SemplificatoValid = exports.formF24ElideValid = exports.formF24AcciseValid = exports.bozzeF24Ordinario = exports.esitoRevocaF24Ordinario = exports.infoRevocaF24Ordinario = exports.formRevocaF24Ordinario = exports.riepilogoRevocaF24Ordinario = exports.esitoF24Ordinario = exports.infoDispositivaF24Ordinario = exports.disableSalvaBozzaF24Semplificato = exports.formF24OrdinarioValid = exports.formF24Ordinario = exports.totaliFinali = exports.totaliSezioneAltroErario = exports.totaliSezioneMotivoPagamento = exports.totaliSezioneAccise = exports.totaliSezioneAltriEntiMN = exports.totaliSezioneAltriEntiIL = exports.totaliSezioneRegioni = exports.totaliSezioneIMU = exports.totaliSezioneInps = exports.totaliSezioneErario = exports.riepilogoF24Ordinario = exports.codiciUfficioF24 = exports.codiciEnteF24Ordinario = exports.parametriF24 = void 0;
exports.documentSessionTokenDownloadF24Result = exports.documentSessionTokenInfoF24Result = exports.documentSessionInitF24Result = exports.buildFilterExportQuietanzaF24 = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const reselect_1 = require("reselect");
const helpers_1 = require("../helpers");
const f24Reducer = (state) => state;
const pagamentiReducer = (state) => state;
exports.parametriF24 = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    return state.parametri;
});
exports.codiciEnteF24Ordinario = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    return state.form.codiciEnte;
});
exports.codiciUfficioF24 = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    return state.form.codiciUfficio;
});
exports.riepilogoF24Ordinario = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    return state.riepilogo;
});
exports.totaliSezioneErario = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    const debito = state.form.sezioneErario.righe.map(r => (0, helpers_1.formattedNumberToNumber)(r.importoDebito)).reduce((s, r) => (s + r), 0);
    const credito = state.form.sezioneErario.righe.map(r => (0, helpers_1.formattedNumberToNumber)(r.importoCredito)).reduce((s, r) => (s + r), 0);
    return {
        totaleDebito: debito,
        totaleCredito: credito,
        totale: debito - credito
    };
});
exports.totaliSezioneInps = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    const debito = state.form.sezioneINPS.righe.map(r => (0, helpers_1.formattedNumberToNumber)(r.importoDebito)).reduce((s, r) => (s + r), 0);
    const credito = state.form.sezioneINPS.righe.map(r => (0, helpers_1.formattedNumberToNumber)(r.importoCredito)).reduce((s, r) => (s + r), 0);
    return {
        totaleDebito: debito,
        totaleCredito: credito,
        totale: debito - credito
    };
});
exports.totaliSezioneIMU = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    const debito = state.form.sezioneIMU.righe.map(r => (0, helpers_1.formattedNumberToNumber)(r.importoDebito)).reduce((s, r) => (s + r), 0);
    const credito = state.form.sezioneIMU.righe.map(r => (0, helpers_1.formattedNumberToNumber)(r.importoCredito)).reduce((s, r) => (s + r), 0);
    return {
        totaleDebito: debito,
        totaleCredito: credito,
        totale: debito - credito
    };
});
exports.totaliSezioneRegioni = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    const debito = state.form.sezioneRegioni.righe.map(r => (0, helpers_1.formattedNumberToNumber)(r.importoDebito)).reduce((s, r) => (s + r), 0);
    const credito = state.form.sezioneRegioni.righe.map(r => (0, helpers_1.formattedNumberToNumber)(r.importoCredito)).reduce((s, r) => (s + r), 0);
    return {
        totaleDebito: debito,
        totaleCredito: credito,
        totale: debito - credito
    };
});
exports.totaliSezioneAltriEntiIL = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    const debito = state.form.sezioneAltriEntiIL.righe.map(r => (0, helpers_1.formattedNumberToNumber)(r.importoDebito)).reduce((s, r) => (s + r), 0);
    const credito = state.form.sezioneAltriEntiIL.righe.map(r => (0, helpers_1.formattedNumberToNumber)(r.importoCredito)).reduce((s, r) => (s + r), 0);
    return {
        totaleDebito: debito,
        totaleCredito: credito,
        totale: debito - credito
    };
});
exports.totaliSezioneAltriEntiMN = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    const debito = state.form.sezioneAltriEntiMN.righe.map(r => (0, helpers_1.formattedNumberToNumber)(r.importoDebito)).reduce((s, r) => (s + r), 0);
    const credito = state.form.sezioneAltriEntiMN.righe.map(r => (0, helpers_1.formattedNumberToNumber)(r.importoCredito)).reduce((s, r) => (s + r), 0);
    return {
        totaleDebito: debito,
        totaleCredito: credito,
        totale: debito - credito
    };
});
exports.totaliSezioneAccise = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    const debito = state.form.sezioneAccise.righe.map(r => (0, helpers_1.formattedNumberToNumber)(r.importoDebito)).reduce((s, r) => (s + r), 0);
    return {
        totaleDebito: debito,
        totaleCredito: 0,
        totale: debito
    };
});
exports.totaliSezioneMotivoPagamento = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    const debito = state.form.sezioneMotivoPagamento.righe.map(r => (0, helpers_1.formattedNumberToNumber)(r.importoDebito)).reduce((s, r) => (s + r), 0);
    const credito = state.form.sezioneMotivoPagamento.righe.map(r => (0, helpers_1.formattedNumberToNumber)(r.importoCredito)).reduce((s, r) => (s + r), 0);
    return {
        totaleDebito: debito,
        totaleCredito: credito,
        totale: debito - credito
    };
});
exports.totaliSezioneAltroErario = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    const debito = state.form.sezioneAltroErario.righe.map(r => (0, helpers_1.formattedNumberToNumber)(r.importoDebito)).reduce((s, r) => (s + r), 0);
    return {
        totaleDebito: debito,
        totaleCredito: 0,
        totale: debito
    };
});
exports.totaliFinali = (0, reselect_1.createSelector)(f24Reducer, exports.totaliSezioneErario, exports.totaliSezioneInps, exports.totaliSezioneIMU, exports.totaliSezioneRegioni, exports.totaliSezioneAltriEntiIL, exports.totaliSezioneAltriEntiMN, exports.totaliSezioneAccise, exports.totaliSezioneMotivoPagamento, exports.totaliSezioneAltroErario, (_, erario, inps, imu, regioni, il, mn, accise, motivo, altroErario) => {
    return erario.totale + inps.totale + imu.totale + regioni.totale + il.totale +
        mn.totale + accise.totale + motivo.totale + altroErario.totale;
});
exports.formF24Ordinario = (0, reselect_1.createSelector)(f24Reducer, exports.totaliSezioneErario, exports.totaliSezioneInps, exports.totaliSezioneIMU, exports.totaliSezioneRegioni, exports.totaliSezioneAltriEntiIL, exports.totaliSezioneAltriEntiMN, exports.totaliSezioneAccise, exports.totaliSezioneMotivoPagamento, exports.totaliSezioneAltroErario, (state, erario, inps, imu, regioni, il, mn, accise, motivo, altroErario) => {
    return {
        ...state.form,
        sezioneDataESaldo: {
            ...state.form.sezioneDataESaldo,
            saldo: erario.totale + inps.totale + imu.totale + regioni.totale + il.totale +
                mn.totale + accise.totale + motivo.totale + altroErario.totale
        }
    };
});
exports.formF24OrdinarioValid = (0, reselect_1.createSelector)(f24Reducer, exports.totaliFinali, (state, totale) => {
    var _a, _b, _c;
    return (!state.form.sezioneDataESaldo.dataError &&
        !!state.form.sezioneDataESaldo.data &&
        !state.form.contribuente.codiceFiscaleError &&
        !state.form.contribuente.nomeError &&
        !state.form.contribuente.sessoError &&
        !state.form.contribuente.cognomeError &&
        !state.form.contribuente.comuneError &&
        !state.form.contribuente.provinciaError &&
        !state.form.contribuente.dataDiNascitaError &&
        !state.form.contribuente.codiceIdentificativoError &&
        !state.form.contribuente.codiceFiscaleCoobligatoError &&
        !((_a = state.form.contribuente.domicilioFiscale) === null || _a === void 0 ? void 0 : _a.comuneError) &&
        !((_b = state.form.contribuente.domicilioFiscale) === null || _b === void 0 ? void 0 : _b.provinciaError) &&
        !((_c = state.form.contribuente.domicilioFiscale) === null || _c === void 0 ? void 0 : _c.viaENumeroCivicoError) &&
        !state.form.sezioneErario.codiceAttoError &&
        !state.form.sezioneErario.codiceUfficioError &&
        !state.form.sezioneErario.righe.some(r => (!!r.rateazioneError ||
            !!r.importoDebitoError ||
            !!r.importoCreditoError ||
            !!r.annoDiRiferimentoError)) &&
        !state.form.sezioneIMU.righe.some(r => (!!r.rateazioneError ||
            !!r.importoDebitoError ||
            !!r.importoCreditoError ||
            !!r.annoRiferimentoError)) &&
        !state.form.sezioneINPS.righe.some(r => (!!r.periodoDaError ||
            !!r.importoDebitoError ||
            !!r.importoCreditoError ||
            !!r.periodoAError)) &&
        !state.form.sezioneRegioni.righe.some(r => (!!r.rateazioneError ||
            !!r.importoDebitoError ||
            !!r.importoCreditoError ||
            !!r.annoRiferimentoError)) &&
        !state.form.sezioneAltriEntiIL.righe.some(r => (!!r.importoDebitoError ||
            !!r.importoCreditoError)) &&
        !state.form.sezioneAltriEntiMN.righe.some(r => (!!r.codiceEnteError ||
            !!r.periodoDaError ||
            !!r.periodoAError ||
            !!r.importoDebitoError ||
            !!r.importoCreditoError)) &&
        (state.form.sezioneErario.righe.some(r => !!r.importoCredito || !!r.importoDebito) ||
            state.form.sezioneIMU.righe.some(r => !!r.importoCredito || !!r.importoDebito) ||
            state.form.sezioneINPS.righe.some(r => !!r.importoCredito || !!r.importoDebito) ||
            state.form.sezioneRegioni.righe.some(r => !!r.importoCredito || !!r.importoDebito) ||
            state.form.sezioneAltriEntiIL.righe.some(r => !!r.importoCredito || !!r.importoDebito) ||
            state.form.sezioneAltriEntiMN.righe.some(r => !!r.importoCredito || !!r.importoDebito)))
        && !!totale;
});
exports.disableSalvaBozzaF24Semplificato = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    let disableSalvaBozza = false;
    if ((!state.form.contribuente.cognome || state.form.contribuente.cognomeError) &&
        (!state.form.contribuente.codiceFiscale || state.form.contribuente.codiceFiscaleError) &&
        (!state.form.sezioneMotivoPagamento.data || !state.form.sezioneMotivoPagamento.dataDirty) ||
        state.form.bozzaSalvata) {
        disableSalvaBozza = true;
    }
    return disableSalvaBozza;
});
exports.infoDispositivaF24Ordinario = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    return state.infoDispositiva;
});
exports.esitoF24Ordinario = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    return state.esito;
});
exports.riepilogoRevocaF24Ordinario = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    return state.riepilogoRevoca;
});
exports.formRevocaF24Ordinario = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    return state.formRevoca;
});
exports.infoRevocaF24Ordinario = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    return state.infoRevoca;
});
exports.esitoRevocaF24Ordinario = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    return state.esitoRevoca;
});
exports.bozzeF24Ordinario = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    return state.bozzeF24Ordinario ? state.bozzeF24Ordinario.sort((a, b) => b.id - a.id) : [];
});
exports.formF24AcciseValid = (0, reselect_1.createSelector)(f24Reducer, exports.totaliFinali, (state, totale) => {
    var _a, _b, _c;
    return (!state.form.sezioneDataESaldo.dataError &&
        !!state.form.sezioneDataESaldo.data &&
        !state.form.contribuente.codiceFiscaleError &&
        !state.form.contribuente.nomeError &&
        !state.form.contribuente.sessoError &&
        !state.form.contribuente.cognomeError &&
        !state.form.contribuente.comuneError &&
        !state.form.contribuente.provinciaError &&
        !state.form.contribuente.dataDiNascitaError &&
        !state.form.contribuente.codiceIdentificativoError &&
        !state.form.contribuente.codiceFiscaleCoobligatoError &&
        !((_a = state.form.contribuente.domicilioFiscale) === null || _a === void 0 ? void 0 : _a.comuneError) &&
        !((_b = state.form.contribuente.domicilioFiscale) === null || _b === void 0 ? void 0 : _b.provinciaError) &&
        !((_c = state.form.contribuente.domicilioFiscale) === null || _c === void 0 ? void 0 : _c.viaENumeroCivicoError) &&
        !state.form.sezioneErario.codiceAttoError &&
        !state.form.sezioneErario.codiceUfficioError &&
        !state.form.sezioneErario.righe.some(r => (!!r.rateazioneError ||
            !!r.importoDebitoError ||
            !!r.importoCreditoError ||
            !!r.annoDiRiferimentoError)) &&
        !state.form.sezioneIMU.righe.some(r => (!!r.rateazioneError ||
            !!r.importoDebitoError ||
            !!r.importoCreditoError ||
            !!r.annoRiferimentoError)) &&
        !state.form.sezioneINPS.righe.some(r => (!!r.periodoDaError ||
            !!r.importoDebitoError ||
            !!r.importoCreditoError ||
            !!r.periodoAError)) &&
        !state.form.sezioneRegioni.righe.some(r => (!!r.rateazioneError ||
            !!r.importoDebitoError ||
            !!r.importoCreditoError ||
            !!r.annoRiferimentoError)) &&
        !state.form.sezioneAccise.righe.some(r => (!!r.codiceEnteError ||
            !!r.provinciaError ||
            !!r.codiceTributoError ||
            !!r.codiceIdentificativoError ||
            !!r.rateazioneError ||
            !!r.meseError ||
            !!r.annoRiferimentoError ||
            !!r.importoDebitoError)) &&
        (state.form.sezioneErario.righe.some(r => !!r.importoCredito || !!r.importoDebito) ||
            state.form.sezioneIMU.righe.some(r => !!r.importoCredito || !!r.importoDebito) ||
            state.form.sezioneINPS.righe.some(r => !!r.importoCredito || !!r.importoDebito) ||
            state.form.sezioneRegioni.righe.some(r => !!r.importoCredito || !!r.importoDebito) ||
            state.form.sezioneAltriEntiIL.righe.some(r => !!r.importoCredito || !!r.importoDebito) ||
            state.form.sezioneAltriEntiMN.righe.some(r => !!r.importoCredito || !!r.importoDebito) ||
            state.form.sezioneAccise.righe.some(r => !!r.importoDebito))) && !!totale;
});
exports.formF24ElideValid = (0, reselect_1.createSelector)(f24Reducer, exports.totaliFinali, (state, totale) => {
    var _a, _b, _c, _d, _e;
    return (!state.form.sezioneDataESaldo.dataError &&
        !!state.form.sezioneDataESaldo.data &&
        !state.form.contribuente.codiceFiscaleError &&
        !state.form.contribuente.nomeError &&
        !state.form.contribuente.sessoError &&
        !state.form.contribuente.cognomeError &&
        !state.form.contribuente.comuneError &&
        !state.form.contribuente.provinciaError &&
        !state.form.contribuente.dataDiNascitaError &&
        !state.form.contribuente.codiceIdentificativoError &&
        !state.form.contribuente.codiceFiscaleCoobligatoError &&
        !((_a = state.form.contribuente.domicilioFiscale) === null || _a === void 0 ? void 0 : _a.comuneError) &&
        !((_b = state.form.contribuente.domicilioFiscale) === null || _b === void 0 ? void 0 : _b.provinciaError) &&
        !((_c = state.form.contribuente.domicilioFiscale) === null || _c === void 0 ? void 0 : _c.viaENumeroCivicoError) &&
        !((_d = state.form.sezioneAltroErario) === null || _d === void 0 ? void 0 : _d.codiceAttoError) &&
        !((_e = state.form.sezioneAltroErario) === null || _e === void 0 ? void 0 : _e.codiceUfficioError) &&
        !state.form.sezioneAltroErario.righe.some(r => (!!r.tipoVeicoloError ||
            !!r.elementoIdError ||
            !!r.codiceTributoError ||
            !!r.annoRiferimentoError ||
            !!r.importoDebitoError))
        && !!totale);
});
exports.righiMotivazioneF24SemplificatoValid = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    return (state.form.sezioneMotivoPagamento.righe.every(r => (!!r.sezione
        && !!r.codiceTributo
        && !!r.codiceEnte
        && !!r.numeroImmobiliari
        && (!!r.anno || r.anno === 0)
        && (!!r.importoDebito || r.importoDebito === 0)
        && !r.sezioneError
        && !r.codiceTributoError
        && !r.codiceEnteError
        && !r.numeroImmobiliariError
        && !r.annoError
        && !r.importoDebitoError)));
});
exports.formF24SemplificatoValid = (0, reselect_1.createSelector)(f24Reducer, exports.totaliFinali, (state, totale) => {
    var _a, _b, _c, _d;
    return (!state.form.sezioneDataESaldo.dataError &&
        !!state.form.sezioneDataESaldo.data &&
        !state.form.contribuente.codiceFiscaleError &&
        !state.form.contribuente.nomeError &&
        !state.form.contribuente.sessoError &&
        !state.form.contribuente.cognomeError &&
        !state.form.contribuente.comuneError &&
        !state.form.contribuente.provinciaError &&
        !state.form.contribuente.dataDiNascitaError &&
        !state.form.contribuente.codiceIdentificativoError &&
        !state.form.contribuente.codiceFiscaleCoobligatoError &&
        !((_a = state.form.contribuente.domicilioFiscale) === null || _a === void 0 ? void 0 : _a.comuneError) &&
        !((_b = state.form.contribuente.domicilioFiscale) === null || _b === void 0 ? void 0 : _b.provinciaError) &&
        !((_c = state.form.contribuente.domicilioFiscale) === null || _c === void 0 ? void 0 : _c.viaENumeroCivicoError) &&
        !((_d = state.form.sezioneMotivoPagamento) === null || _d === void 0 ? void 0 : _d.dataError) &&
        !state.form.sezioneMotivoPagamento.righe.some(r => (!!r.sezioneError ||
            !!r.codiceTributoError ||
            !!r.codiceEnteError ||
            !!r.codiceTributoError ||
            !!r.ravvedimentoError ||
            !!r.accontoError ||
            !!r.saldoError ||
            !!r.numeroImmobiliariError ||
            !!r.rateError ||
            !!r.annoError ||
            !!r.detrazioneError ||
            !!r.importoDebitoError ||
            !!r.importoCreditoError)) && !!totale);
});
exports.currentBozzaF24Ordinario = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    return state.currentBozza;
});
exports.checkUpdateBozzaOrdinario = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5;
    let check = false;
    const dataPagamento = ((_b = (_a = state.form) === null || _a === void 0 ? void 0 : _a.sezioneDataESaldo) === null || _b === void 0 ? void 0 : _b.data) ? new Date(state.form.sezioneDataESaldo.data) : new Date();
    if (((_c = state.currentBozza) === null || _c === void 0 ? void 0 : _c.id) &&
        ((((_e = (_d = state.form) === null || _d === void 0 ? void 0 : _d.contribuente) === null || _e === void 0 ? void 0 : _e.codiceFiscale) !== ((_g = (_f = state.currentBozza) === null || _f === void 0 ? void 0 : _f.contribuente) === null || _g === void 0 ? void 0 : _g.codiceFiscale) ||
            ((_j = (_h = state.form) === null || _h === void 0 ? void 0 : _h.contribuente) === null || _j === void 0 ? void 0 : _j.cognome) !== ((_m = (_l = (_k = state.currentBozza) === null || _k === void 0 ? void 0 : _k.contribuente) === null || _l === void 0 ? void 0 : _l.datiAnagrafici) === null || _m === void 0 ? void 0 : _m.cognome) ||
            ((_p = (_o = state.form) === null || _o === void 0 ? void 0 : _o.contribuente) === null || _p === void 0 ? void 0 : _p.nome) !== ((_s = (_r = (_q = state.currentBozza) === null || _q === void 0 ? void 0 : _q.contribuente) === null || _r === void 0 ? void 0 : _r.datiAnagrafici) === null || _s === void 0 ? void 0 : _s.nome) ||
            dataPagamento !== ((_t = state.currentBozza) === null || _t === void 0 ? void 0 : _t.dataOperazione)) ||
            ((_v = (_u = state.form) === null || _u === void 0 ? void 0 : _u.sezioneErario) === null || _v === void 0 ? void 0 : _v.codiceAttoDirty) ||
            ((_x = (_w = state.form) === null || _w === void 0 ? void 0 : _w.sezioneErario) === null || _x === void 0 ? void 0 : _x.codiceUfficioDirty) ||
            ((_y = state.form) === null || _y === void 0 ? void 0 : _y.sezioneErario.righe.some(r => (r.codiceTributoDirty ||
                r.rateazioneDirty ||
                r.codiceTributoDirty ||
                r.annoDiRiferimentoDirty ||
                r.importoDebitoDirty ||
                r.importoCreditoDirty))) ||
            ((_0 = (_z = state.form) === null || _z === void 0 ? void 0 : _z.sezioneIMU) === null || _0 === void 0 ? void 0 : _0.detrazioneDirty) ||
            ((_1 = state === null || state === void 0 ? void 0 : state.form) === null || _1 === void 0 ? void 0 : _1.sezioneIMU.righe.some(r => (r.codiceRegioneDirty ||
                r.RDirty ||
                r.IVDirty ||
                r.ADirty ||
                r.SDirty ||
                r.NIDirty ||
                r.codiceTributoDirty ||
                r.rateazioneDirty ||
                r.annoRiferimentoDirty ||
                r.importoDebitoDirty ||
                r.importoDebitoDirty ||
                r.importoCreditoDirty))) ||
            ((_2 = state.form) === null || _2 === void 0 ? void 0 : _2.sezioneINPS.righe.some(r => (r.codiceSedeDirty ||
                r.causaleContributoDirty ||
                r.matricolaINPSDirty ||
                r.periodoDaDirty ||
                r.periodoADirty ||
                r.importoDebitoDirty ||
                r.importoDebitoDirty ||
                r.importoCreditoDirty))) ||
            ((_3 = state.form) === null || _3 === void 0 ? void 0 : _3.sezioneRegioni.righe.some(r => (r.codiceRegioneDirty ||
                r.codiceTributoDirty ||
                r.rateazioneDirty ||
                r.annoRiferimentoDirty ||
                r.importoDebitoDirty ||
                r.importoDebitoDirty ||
                r.importoCreditoDirty))) ||
            ((_4 = state.form) === null || _4 === void 0 ? void 0 : _4.sezioneAltriEntiMN.righe.some(r => (r.codiceEnteDirty ||
                r.codiceSedeDirty ||
                r.causaleContributoDirty ||
                r.codicePosizioneDirty ||
                r.periodoDaDirty ||
                r.periodoADirty ||
                r.importoDebitoDirty ||
                r.importoDebitoDirty ||
                r.importoCreditoDirty))) ||
            ((_5 = state.form) === null || _5 === void 0 ? void 0 : _5.sezioneAltriEntiIL.righe.some(r => (r.codiceSedeDirty ||
                r.codiceDittaDirty ||
                r.CCDirty ||
                r.numeroDiRiferimentoDirty ||
                r.causaleDirty ||
                r.importoDebitoDirty ||
                r.importoDebitoDirty ||
                r.importoCreditoDirty))))) {
        check = true;
    }
    return check;
});
exports.disableSalvaBozzaOrdinario = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    var _a, _b;
    let b = false;
    if (!((_a = state.form.contribuente) === null || _a === void 0 ? void 0 : _a.codiceFiscale) &&
        !((_b = state.form.contribuente) === null || _b === void 0 ? void 0 : _b.cognome) &&
        (!state.form.sezioneDataESaldo.data || !state.form.sezioneDataESaldo.dataDirty) ||
        state.form.bozzaSalvata) {
        b = true;
    }
    return b;
});
exports.checkUpdateBozzaSemplificato = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
    let check = false;
    const dataPagamento = ((_b = (_a = state.form) === null || _a === void 0 ? void 0 : _a.sezioneDataESaldo) === null || _b === void 0 ? void 0 : _b.data) ? new Date(state.form.sezioneDataESaldo.data) : new Date();
    if (((_c = state.currentBozza) === null || _c === void 0 ? void 0 : _c.id) &&
        ((((_e = (_d = state.form) === null || _d === void 0 ? void 0 : _d.contribuente) === null || _e === void 0 ? void 0 : _e.codiceFiscale) !== ((_g = (_f = state.currentBozza) === null || _f === void 0 ? void 0 : _f.contribuente) === null || _g === void 0 ? void 0 : _g.codiceFiscale) ||
            ((_j = (_h = state.form) === null || _h === void 0 ? void 0 : _h.contribuente) === null || _j === void 0 ? void 0 : _j.cognome) !== ((_m = (_l = (_k = state.currentBozza) === null || _k === void 0 ? void 0 : _k.contribuente) === null || _l === void 0 ? void 0 : _l.datiAnagrafici) === null || _m === void 0 ? void 0 : _m.cognome) ||
            ((_p = (_o = state.form) === null || _o === void 0 ? void 0 : _o.contribuente) === null || _p === void 0 ? void 0 : _p.nome) !== ((_s = (_r = (_q = state.currentBozza) === null || _q === void 0 ? void 0 : _q.contribuente) === null || _r === void 0 ? void 0 : _r.datiAnagrafici) === null || _s === void 0 ? void 0 : _s.nome)
            || dataPagamento !== ((_t = state.currentBozza) === null || _t === void 0 ? void 0 : _t.dataPagamento)) ||
            ((_u = state.form) === null || _u === void 0 ? void 0 : _u.sezioneMotivoPagamento.righe.some(r => (r.sezioneDirty ||
                r.codiceTributoDirty ||
                r.codiceEnteDirty ||
                r.ravvedimentoDirty ||
                r.accontoDirty ||
                r.saldoDirty ||
                r.numeroImmobiliariDirty ||
                r.rateDirty ||
                r.annoDirty ||
                r.detrazioneDirty ||
                r.importoDebitoDirty ||
                r.importoCreditoDirty))))) {
        check = true;
    }
    return check;
});
exports.checkUpdateBozzaAccise = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8;
    let check = false;
    const dataPagamento = ((_b = (_a = state.form) === null || _a === void 0 ? void 0 : _a.sezioneDataESaldo) === null || _b === void 0 ? void 0 : _b.data) ? new Date(state.form.sezioneDataESaldo.data) : new Date();
    if (((_c = state.currentBozza) === null || _c === void 0 ? void 0 : _c.id) &&
        ((((_e = (_d = state.form) === null || _d === void 0 ? void 0 : _d.contribuente) === null || _e === void 0 ? void 0 : _e.codiceFiscale) !== ((_g = (_f = state.currentBozza) === null || _f === void 0 ? void 0 : _f.contribuente) === null || _g === void 0 ? void 0 : _g.codiceFiscale) ||
            ((_j = (_h = state.form) === null || _h === void 0 ? void 0 : _h.contribuente) === null || _j === void 0 ? void 0 : _j.cognome) !== ((_m = (_l = (_k = state.currentBozza) === null || _k === void 0 ? void 0 : _k.contribuente) === null || _l === void 0 ? void 0 : _l.datiAnagrafici) === null || _m === void 0 ? void 0 : _m.cognome) ||
            ((_p = (_o = state.form) === null || _o === void 0 ? void 0 : _o.contribuente) === null || _p === void 0 ? void 0 : _p.nome) !== ((_s = (_r = (_q = state.currentBozza) === null || _q === void 0 ? void 0 : _q.contribuente) === null || _r === void 0 ? void 0 : _r.datiAnagrafici) === null || _s === void 0 ? void 0 : _s.nome)
            || dataPagamento !== ((_t = state.currentBozza) === null || _t === void 0 ? void 0 : _t.dataPagamento)) ||
            ((_v = (_u = state.form) === null || _u === void 0 ? void 0 : _u.sezioneErario) === null || _v === void 0 ? void 0 : _v.codiceAttoDirty) ||
            ((_x = (_w = state.form) === null || _w === void 0 ? void 0 : _w.sezioneErario) === null || _x === void 0 ? void 0 : _x.codiceUfficioDirty) ||
            ((_y = state.form) === null || _y === void 0 ? void 0 : _y.sezioneErario.righe.some(r => (r.codiceTributoDirty ||
                r.rateazioneDirty ||
                r.codiceTributoDirty ||
                r.annoDiRiferimentoDirty ||
                r.importoDebitoDirty ||
                r.importoCreditoDirty))) ||
            ((_0 = (_z = state.form) === null || _z === void 0 ? void 0 : _z.sezioneIMU) === null || _0 === void 0 ? void 0 : _0.detrazioneDirty) ||
            ((_1 = state === null || state === void 0 ? void 0 : state.form) === null || _1 === void 0 ? void 0 : _1.sezioneIMU.righe.some(r => (r.codiceRegioneDirty ||
                r.RDirty ||
                r.IVDirty ||
                r.ADirty ||
                r.SDirty ||
                r.NIDirty ||
                r.codiceTributoDirty ||
                r.rateazioneDirty ||
                r.annoRiferimentoDirty ||
                r.importoDebitoDirty ||
                r.importoDebitoDirty ||
                r.importoCreditoDirty))) ||
            ((_2 = state.form) === null || _2 === void 0 ? void 0 : _2.sezioneINPS.righe.some(r => (r.codiceSedeDirty ||
                r.causaleContributoDirty ||
                r.matricolaINPSDirty ||
                r.periodoDaDirty ||
                r.periodoADirty ||
                r.importoDebitoDirty ||
                r.importoDebitoDirty ||
                r.importoCreditoDirty))) ||
            ((_3 = state.form) === null || _3 === void 0 ? void 0 : _3.sezioneRegioni.righe.some(r => (r.codiceRegioneDirty ||
                r.codiceTributoDirty ||
                r.rateazioneDirty ||
                r.annoRiferimentoDirty ||
                r.importoDebitoDirty ||
                r.importoDebitoDirty ||
                r.importoCreditoDirty))) ||
            ((_5 = (_4 = state === null || state === void 0 ? void 0 : state.form) === null || _4 === void 0 ? void 0 : _4.sezioneAccise) === null || _5 === void 0 ? void 0 : _5.codiceUfficioDirty) ||
            ((_7 = (_6 = state === null || state === void 0 ? void 0 : state.form) === null || _6 === void 0 ? void 0 : _6.sezioneAccise) === null || _7 === void 0 ? void 0 : _7.codiceAttoDirty) ||
            ((_8 = state.form) === null || _8 === void 0 ? void 0 : _8.sezioneAccise.righe.some(r => (r.codiceEnteDirty ||
                r.provinciaDirty ||
                r.codiceTributoDirty ||
                r.codiceIdentificativoDirty ||
                r.rateazioneDirty ||
                r.meseDirty ||
                r.annoRiferimentoDirty ||
                r.importoDebitoDirty))))) {
        check = true;
    }
    return check;
});
exports.disableSalvaBozzaAccise = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    var _a, _b;
    let b = false;
    if (!((_a = state.form.contribuente) === null || _a === void 0 ? void 0 : _a.codiceFiscale) &&
        !((_b = state.form.contribuente) === null || _b === void 0 ? void 0 : _b.cognome) &&
        (!state.form.sezioneDataESaldo.data || !state.form.sezioneDataESaldo.dataDirty) ||
        state.form.bozzaSalvata) {
        b = true;
    }
    return b;
});
exports.checkUpdateBozzaElide = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y;
    let check = false;
    const dataPagamento = ((_b = (_a = state.form) === null || _a === void 0 ? void 0 : _a.sezioneDataESaldo) === null || _b === void 0 ? void 0 : _b.data) ? new Date(state.form.sezioneDataESaldo.data) : new Date();
    if (((_c = state.currentBozza) === null || _c === void 0 ? void 0 : _c.id) &&
        ((((_e = (_d = state.form) === null || _d === void 0 ? void 0 : _d.contribuente) === null || _e === void 0 ? void 0 : _e.codiceFiscale) !== ((_g = (_f = state.currentBozza) === null || _f === void 0 ? void 0 : _f.contribuente) === null || _g === void 0 ? void 0 : _g.codiceFiscale) ||
            ((_j = (_h = state.form) === null || _h === void 0 ? void 0 : _h.contribuente) === null || _j === void 0 ? void 0 : _j.cognome) !== ((_m = (_l = (_k = state.currentBozza) === null || _k === void 0 ? void 0 : _k.contribuente) === null || _l === void 0 ? void 0 : _l.datiAnagrafici) === null || _m === void 0 ? void 0 : _m.cognome) ||
            ((_p = (_o = state.form) === null || _o === void 0 ? void 0 : _o.contribuente) === null || _p === void 0 ? void 0 : _p.nome) !== ((_s = (_r = (_q = state.currentBozza) === null || _q === void 0 ? void 0 : _q.contribuente) === null || _r === void 0 ? void 0 : _r.datiAnagrafici) === null || _s === void 0 ? void 0 : _s.nome)
            || dataPagamento !== ((_t = state.currentBozza) === null || _t === void 0 ? void 0 : _t.dataPagamento)) ||
            ((_v = (_u = state.form) === null || _u === void 0 ? void 0 : _u.sezioneAltroErario) === null || _v === void 0 ? void 0 : _v.codiceAttoDirty) ||
            ((_x = (_w = state.form) === null || _w === void 0 ? void 0 : _w.sezioneAltroErario) === null || _x === void 0 ? void 0 : _x.codiceUfficioDirty) ||
            ((_y = state.form) === null || _y === void 0 ? void 0 : _y.sezioneAltroErario.righe.some(r => (r.tipoVeicoloDirty ||
                r.elementoIdDirty ||
                r.codiceTributoDirty ||
                r.annoRiferimentoDirty ||
                r.importoDebitoDirty))))) {
        check = true;
    }
    return check;
});
exports.disableSalvaBozzaElide = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    var _a, _b;
    let b = false;
    if (!((_a = state.form.contribuente) === null || _a === void 0 ? void 0 : _a.codiceFiscale) &&
        !((_b = state.form.contribuente) === null || _b === void 0 ? void 0 : _b.cognome) &&
        (!state.form.sezioneDataESaldo.data || !state.form.sezioneDataESaldo.dataDirty) ||
        state.form.bozzaSalvata) {
        b = true;
    }
    return b;
});
exports.erarioRighe = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    var _a, _b, _c;
    return (_c = (_b = (_a = state === null || state === void 0 ? void 0 : state.form) === null || _a === void 0 ? void 0 : _a.sezioneErario) === null || _b === void 0 ? void 0 : _b.righe) === null || _c === void 0 ? void 0 : _c.filter(riga => {
        return !!riga.codiceTributo
            || !!riga.rateazione
            || !!riga.annoDiRiferimento
            || !!riga.importoDebito
            || !!riga.importoCredito;
    }).map((item) => {
        return {
            codiceTributo: (item === null || item === void 0 ? void 0 : item.codiceTributo) || '',
            infoTributo: (item === null || item === void 0 ? void 0 : item.rateazione) || '',
            anno: (item === null || item === void 0 ? void 0 : item.annoDiRiferimento) ? Number(item.annoDiRiferimento) : 0,
            importoDebito: (0, helpers_1.formattedNumberToNumber)(item.importoDebito),
            importoCredito: (0, helpers_1.formattedNumberToNumber)(item.importoCredito)
        };
    });
});
exports.inpsRighe = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    var _a, _b, _c;
    return (_c = (_b = (_a = state === null || state === void 0 ? void 0 : state.form) === null || _a === void 0 ? void 0 : _a.sezioneINPS) === null || _b === void 0 ? void 0 : _b.righe) === null || _c === void 0 ? void 0 : _c.filter(riga => {
        return !!riga.codiceSede
            || !!riga.causaleContributo
            || !!riga.matricolaINPS
            || !!riga.periodoDa
            || !!riga.periodoA
            || !!riga.importoDebito
            || !!riga.importoCredito;
    }).map((item) => {
        return {
            codiceSede: (item === null || item === void 0 ? void 0 : item.codiceSede) || '',
            causaleContributo: (item === null || item === void 0 ? void 0 : item.causaleContributo) || '',
            matricolaInps: (item === null || item === void 0 ? void 0 : item.matricolaINPS) || '',
            periodoDaMese: (item === null || item === void 0 ? void 0 : item.periodoDa) ? Number(item.periodoDa.split('/')[0]) : 0,
            periodoDaAnno: (item === null || item === void 0 ? void 0 : item.periodoDa) ? Number(item.periodoDa.split('/')[1]) : 0,
            periodoAMese: (item === null || item === void 0 ? void 0 : item.periodoA) ? Number(item.periodoA.split('/')[0]) : 0,
            periodoAAnno: (item === null || item === void 0 ? void 0 : item.periodoA) ? Number(item.periodoA.split('/')[1]) : 0,
            importoDebito: (0, helpers_1.formattedNumberToNumber)(item.importoDebito),
            importoCredito: (0, helpers_1.formattedNumberToNumber)(item.importoCredito)
        };
    });
});
exports.regioniRighe = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    var _a, _b, _c;
    return (_c = (_b = (_a = state === null || state === void 0 ? void 0 : state.form) === null || _a === void 0 ? void 0 : _a.sezioneRegioni) === null || _b === void 0 ? void 0 : _b.righe) === null || _c === void 0 ? void 0 : _c.filter(riga => {
        return !!riga.codiceRegione
            || !!riga.codiceTributo
            || !!riga.rateazione
            || !!riga.annoRiferimento
            || !!riga.importoDebito
            || !!riga.importoCredito;
    }).map((item) => {
        return {
            codiceRegione: (item === null || item === void 0 ? void 0 : item.codiceRegione) || '',
            codiceTributo: (item === null || item === void 0 ? void 0 : item.codiceTributo) || '',
            infoTributo: (item === null || item === void 0 ? void 0 : item.rateazione) || '',
            annoRiferimento: (item === null || item === void 0 ? void 0 : item.annoRiferimento) ? Number(item.annoRiferimento) : 0,
            importoDebito: (0, helpers_1.formattedNumberToNumber)(item.importoDebito),
            importoCredito: (0, helpers_1.formattedNumberToNumber)(item.importoCredito)
        };
    });
});
exports.tributiLocaliRighe = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    var _a, _b, _c;
    return (_c = (_b = (_a = state === null || state === void 0 ? void 0 : state.form) === null || _a === void 0 ? void 0 : _a.sezioneIMU) === null || _b === void 0 ? void 0 : _b.righe) === null || _c === void 0 ? void 0 : _c.filter(riga => {
        return !!riga.codiceRegione
            || !!riga.R
            || !!riga.IV
            || !!riga.A
            || !!riga.S
            || !!riga.NI
            || !!riga.codiceTributo
            || !!riga.rateazione
            || !!riga.annoRiferimento
            || !!riga.importoDebito
            || !!riga.importoCredito;
    }).map((item) => {
        return {
            codiceEnte: (item === null || item === void 0 ? void 0 : item.codiceRegione) || '',
            ravvedimento: (item === null || item === void 0 ? void 0 : item.R) ? api_rest_1.RigaSezioneTributiLocaliDtoRavvedimentoEnum.S : api_rest_1.RigaSezioneTributiLocaliDtoRavvedimentoEnum.N,
            immVariati: (item === null || item === void 0 ? void 0 : item.IV) ? api_rest_1.RigaSezioneTributiLocaliDtoImmVariatiEnum.S : api_rest_1.RigaSezioneTributiLocaliDtoImmVariatiEnum.N,
            acconto: (item === null || item === void 0 ? void 0 : item.A) ? api_rest_1.RigaSezioneTributiLocaliDtoAccontoEnum.S : api_rest_1.RigaSezioneTributiLocaliDtoAccontoEnum.N,
            saldo: item.S ? api_rest_1.RigaSezioneTributiLocaliDtoSaldoEnum.S : api_rest_1.RigaSezioneTributiLocaliDtoSaldoEnum.N,
            numeroImmobili: (item === null || item === void 0 ? void 0 : item.NI) ? Number(item.NI) : 0,
            codiceTributo: (item === null || item === void 0 ? void 0 : item.codiceTributo) || '',
            infoTributo: (item === null || item === void 0 ? void 0 : item.rateazione) || '',
            annoRiferimento: (item === null || item === void 0 ? void 0 : item.annoRiferimento) ? Number(item.annoRiferimento) : 0,
            importoDebito: (0, helpers_1.formattedNumberToNumber)(item === null || item === void 0 ? void 0 : item.importoDebito),
            importoCredito: (0, helpers_1.formattedNumberToNumber)(item.importoCredito)
        };
    });
});
exports.inailRighe = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    var _a, _b, _c;
    return (_c = (_b = (_a = state === null || state === void 0 ? void 0 : state.form) === null || _a === void 0 ? void 0 : _a.sezioneAltriEntiIL) === null || _b === void 0 ? void 0 : _b.righe) === null || _c === void 0 ? void 0 : _c.filter(riga => {
        return !!riga.codiceSede
            || !!riga.codiceDitta
            || !!riga.CC
            || !!riga.numeroDiRiferimento
            || !!riga.causale
            || !!riga.importoDebito
            || !!riga.importoCredito;
    }).map((item) => {
        return {
            codiceSede: (item === null || item === void 0 ? void 0 : item.codiceSede) || '',
            codiceDitta: (item === null || item === void 0 ? void 0 : item.codiceDitta) || '',
            cc: (item === null || item === void 0 ? void 0 : item.CC) || '',
            riferimento: (item === null || item === void 0 ? void 0 : item.numeroDiRiferimento) || '',
            causale: (item === null || item === void 0 ? void 0 : item.causale) || '',
            importoDebito: (0, helpers_1.formattedNumberToNumber)(item === null || item === void 0 ? void 0 : item.importoDebito),
            importoCredito: (0, helpers_1.formattedNumberToNumber)(item.importoCredito)
        };
    });
});
exports.entiPrevidenzialiRighe = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    var _a, _b, _c;
    return (_c = (_b = (_a = state === null || state === void 0 ? void 0 : state.form) === null || _a === void 0 ? void 0 : _a.sezioneAltriEntiMN) === null || _b === void 0 ? void 0 : _b.righe) === null || _c === void 0 ? void 0 : _c.filter(riga => {
        return !!riga.codiceEnte
            || !!riga.codiceSede
            || !!riga.causaleContributo
            || !!riga.codicePosizione
            || !!riga.periodoDa
            || !!riga.periodoA
            || !!riga.importoDebito
            || !!riga.importoCredito;
    }).map((item) => {
        var _a, _b;
        const periodoDaAnno = (item === null || item === void 0 ? void 0 : item.periodoDa)
            ? item.periodoDa.length > 4
                ? Number(item.periodoDa.substring(3, 7))
                : Number(item.periodoDa)
            : 0;
        const periodoDaMese = (item === null || item === void 0 ? void 0 : item.periodoDa)
            ? item.periodoDa.length > 4
                ? Number(item.periodoDa.substring(0, 2))
                : 0
            : 0;
        const periodoAAnno = (item === null || item === void 0 ? void 0 : item.periodoA)
            ? item.periodoA.length > 4
                ? Number(item.periodoA.substring(3, 7))
                : Number(item.periodoA)
            : 0;
        const periodoAMese = (item === null || item === void 0 ? void 0 : item.periodoA)
            ? item.periodoA.length > 4
                ? Number(item.periodoA.substring(0, 2))
                : 0
            : 0;
        return {
            codiceSede: ((_a = item === null || item === void 0 ? void 0 : item.codiceSede) === null || _a === void 0 ? void 0 : _a.toUpperCase()) || '',
            causale: ((_b = item === null || item === void 0 ? void 0 : item.causaleContributo) === null || _b === void 0 ? void 0 : _b.toUpperCase()) || '',
            codicePosizione: (item === null || item === void 0 ? void 0 : item.codicePosizione) || '',
            periodoDaAnno: periodoDaAnno,
            periodoDaMese: periodoDaMese,
            periodoAAnno: periodoAAnno,
            periodoAMese: periodoAMese,
            importoDebito: (0, helpers_1.formattedNumberToNumber)(item.importoDebito),
            importoCredito: (0, helpers_1.formattedNumberToNumber)(item.importoCredito)
        };
    });
});
exports.semplificatoRighe = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    var _a, _b, _c;
    return (_c = (_b = (_a = state === null || state === void 0 ? void 0 : state.form) === null || _a === void 0 ? void 0 : _a.sezioneMotivoPagamento) === null || _b === void 0 ? void 0 : _b.righe) === null || _c === void 0 ? void 0 : _c.filter(riga => {
        return !!riga.sezione
            || !!riga.codiceTributo
            || !!riga.codiceEnte
            || riga.ravvedimento == 'S'
            || riga.immobiliareVariati == 'S'
            || riga.acconto == 'S'
            || riga.saldo == 'S'
            || !!riga.numeroImmobiliari
            || !!riga.rate
            || !!riga.anno
            || !!riga.detrazione
            || !!riga.importoDebito
            || !!riga.importoCredito;
    }).map((item) => {
        return {
            sezione: (item === null || item === void 0 ? void 0 : item.sezione) || '',
            codiceTributo: (item === null || item === void 0 ? void 0 : item.codiceTributo) || '',
            codiceEnte: (item === null || item === void 0 ? void 0 : item.codiceEnte) || '',
            ravvedimento: (item === null || item === void 0 ? void 0 : item.ravvedimento) === api_rest_1.RigaSezioneSemplificatoDtoRavvedimentoEnum.S ? api_rest_1.RigaSezioneSemplificatoDtoRavvedimentoEnum.S : api_rest_1.RigaSezioneSemplificatoDtoRavvedimentoEnum.N,
            immobiliVariati: (item === null || item === void 0 ? void 0 : item.immobiliareVariati) === api_rest_1.RigaSezioneSemplificatoDtoImmobiliVariatiEnum.S ? api_rest_1.RigaSezioneSemplificatoDtoImmobiliVariatiEnum.S : api_rest_1.RigaSezioneSemplificatoDtoImmobiliVariatiEnum.N,
            acconto: (item === null || item === void 0 ? void 0 : item.acconto) === api_rest_1.RigaSezioneSemplificatoDtoAccontoEnum.S ? api_rest_1.RigaSezioneSemplificatoDtoAccontoEnum.S : api_rest_1.RigaSezioneSemplificatoDtoAccontoEnum.N,
            saldo: (item === null || item === void 0 ? void 0 : item.saldo) === api_rest_1.RigaSezioneSemplificatoDtoSaldoEnum.S ? api_rest_1.RigaSezioneSemplificatoDtoSaldoEnum.S : api_rest_1.RigaSezioneSemplificatoDtoSaldoEnum.N,
            numeroImmobili: (item === null || item === void 0 ? void 0 : item.numeroImmobiliari) || 0,
            rateazione: (item === null || item === void 0 ? void 0 : item.rate) || '',
            annoRiferimento: (item === null || item === void 0 ? void 0 : item.anno) ? Number(item.anno) : 0,
            detrazione: (0, helpers_1.formattedNumberToNumber)(item.detrazione),
            importoDebito: (0, helpers_1.formattedNumberToNumber)(item.importoDebito),
            importoCredito: (0, helpers_1.formattedNumberToNumber)(item.importoCredito)
        };
    });
});
exports.acciseRighe = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    var _a, _b, _c;
    return (_c = (_b = (_a = state === null || state === void 0 ? void 0 : state.form) === null || _a === void 0 ? void 0 : _a.sezioneAccise) === null || _b === void 0 ? void 0 : _b.righe) === null || _c === void 0 ? void 0 : _c.filter(riga => {
        return !!riga.codiceEnte
            || !!riga.provincia
            || !!riga.codiceTributo
            || !!riga.codiceIdentificativo
            || !!riga.rateazione
            || !!riga.mese
            || !!riga.annoRiferimento
            || !!riga.importoDebito;
    }).map((item) => {
        var _a, _b, _c;
        return {
            codiceEnte: ((_a = item === null || item === void 0 ? void 0 : item.codiceEnte) === null || _a === void 0 ? void 0 : _a.toUpperCase()) || '',
            provincia: ((_b = item === null || item === void 0 ? void 0 : item.provincia) === null || _b === void 0 ? void 0 : _b.toUpperCase()) || '',
            codiceTributo: (item === null || item === void 0 ? void 0 : item.codiceTributo) || '',
            codiceIdentificativo: ((_c = item === null || item === void 0 ? void 0 : item.codiceIdentificativo) === null || _c === void 0 ? void 0 : _c.toUpperCase()) || '',
            rateazione: (item === null || item === void 0 ? void 0 : item.rateazione) || '',
            mese: (item === null || item === void 0 ? void 0 : item.mese) || '',
            annoRiferimento: (item === null || item === void 0 ? void 0 : item.annoRiferimento) ? Number(item.annoRiferimento) : 0,
            importoDebito: (0, helpers_1.formattedNumberToNumber)(item.importoDebito)
        };
    });
});
exports.identificativiRighe = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    var _a, _b, _c;
    return (_c = (_b = (_a = state === null || state === void 0 ? void 0 : state.form) === null || _a === void 0 ? void 0 : _a.sezioneAltroErario) === null || _b === void 0 ? void 0 : _b.righe) === null || _c === void 0 ? void 0 : _c.filter(riga => {
        return !!riga.tipoVeicolo
            || !!riga.elementoId
            || !!riga.annoRiferimento
            || !!riga.codiceTributo
            || !!riga.importoDebito;
    }).map((item) => {
        return {
            tipoVeicolo: (item === null || item === void 0 ? void 0 : item.tipoVeicolo) || '',
            elementoId: (item === null || item === void 0 ? void 0 : item.elementoId) || '',
            codiceTributo: (item === null || item === void 0 ? void 0 : item.codiceTributo) || '',
            annoRiferimento: (item === null || item === void 0 ? void 0 : item.annoRiferimento) ? Number(item.annoRiferimento) : 0,
            importoDebito: (0, helpers_1.formattedNumberToNumber)(item.importoDebito)
        };
    });
});
exports.buildF24Ordinario = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30;
    const saldoFinale = ((_a = (0, exports.formF24Ordinario)(state).sezioneDataESaldo) === null || _a === void 0 ? void 0 : _a.saldo) || 0;
    return {
        id: (_b = state.currentBozza) === null || _b === void 0 ? void 0 : _b.id,
        codiceRapporto: ((_e = (_d = (_c = state.form) === null || _c === void 0 ? void 0 : _c.rapportoSelezionato) === null || _d === void 0 ? void 0 : _d.rapporto) === null || _e === void 0 ? void 0 : _e.id) || 0,
        codiceAzienda: (_j = (_h = (_g = (_f = state.form) === null || _f === void 0 ? void 0 : _f.rapportoSelezionato) === null || _g === void 0 ? void 0 : _g.rapporto) === null || _h === void 0 ? void 0 : _h.iban) === null || _j === void 0 ? void 0 : _j.substr(5, 5),
        codiceCAB: (_o = (_m = (_l = (_k = state.form) === null || _k === void 0 ? void 0 : _k.rapportoSelezionato) === null || _l === void 0 ? void 0 : _l.rapporto) === null || _m === void 0 ? void 0 : _m.iban) === null || _o === void 0 ? void 0 : _o.substr(10, 5),
        dataOperazione: ((_q = (_p = state.form) === null || _p === void 0 ? void 0 : _p.sezioneDataESaldo) === null || _q === void 0 ? void 0 : _q.data) ? new Date(state.form.sezioneDataESaldo.data) : undefined,
        stato: 'OK',
        revocabile: true,
        tipologia: 'STANDARD',
        saldoFinale: saldoFinale,
        idContattoBeneficiario: state.form.contribuente.idContattoBeneficiario,
        aggiungiRubrica: state.form.contribuente.aggiungiBeneficiarioRubrica,
        aggiungiWhitelist: state.form.contribuente.aggiungiBeneficiarioWhitelist,
        contribuente: {
            annoImpostaSolare: state.form.contribuente.nonCoincidenteAnnoSolare,
            codiceFiscale: ((_s = (_r = state.form) === null || _r === void 0 ? void 0 : _r.contribuente) === null || _s === void 0 ? void 0 : _s.codiceFiscale) || '',
            datiAnagrafici: {
                cognome: ((_u = (_t = state.form) === null || _t === void 0 ? void 0 : _t.contribuente) === null || _u === void 0 ? void 0 : _u.cognome) || '',
                nome: ((_w = (_v = state.form) === null || _v === void 0 ? void 0 : _v.contribuente) === null || _w === void 0 ? void 0 : _w.nome) || '',
                dataNascita: ((_y = (_x = state.form) === null || _x === void 0 ? void 0 : _x.contribuente) === null || _y === void 0 ? void 0 : _y.dataDiNascita) ? new Date(state.form.contribuente.dataDiNascita) : undefined,
                sesso: ((_0 = (_z = state.form) === null || _z === void 0 ? void 0 : _z.contribuente) === null || _0 === void 0 ? void 0 : _0.sesso) || '',
                comune: ((_2 = (_1 = state.form) === null || _1 === void 0 ? void 0 : _1.contribuente) === null || _2 === void 0 ? void 0 : _2.comune) || '',
                provincia: ((_4 = (_3 = state.form) === null || _3 === void 0 ? void 0 : _3.contribuente) === null || _4 === void 0 ? void 0 : _4.provincia) || ''
            },
            domicilio: {
                comune: ((_7 = (_6 = (_5 = state.form) === null || _5 === void 0 ? void 0 : _5.contribuente) === null || _6 === void 0 ? void 0 : _6.domicilioFiscale) === null || _7 === void 0 ? void 0 : _7.comune) || '',
                provincia: ((_10 = (_9 = (_8 = state.form) === null || _8 === void 0 ? void 0 : _8.contribuente) === null || _9 === void 0 ? void 0 : _9.domicilioFiscale) === null || _10 === void 0 ? void 0 : _10.provincia) || '',
                indirizzo: ((_13 = (_12 = (_11 = state.form) === null || _11 === void 0 ? void 0 : _11.contribuente) === null || _12 === void 0 ? void 0 : _12.domicilioFiscale) === null || _13 === void 0 ? void 0 : _13.viaENumeroCivico) || ''
            },
            codiceFiscaleTutore: (_15 = (_14 = state.form) === null || _14 === void 0 ? void 0 : _14.contribuente) === null || _15 === void 0 ? void 0 : _15.codiceFiscaleCoobligato,
            codiceIdentificativoCFTutore: (_17 = (_16 = state.form) === null || _16 === void 0 ? void 0 : _16.contribuente) === null || _17 === void 0 ? void 0 : _17.codiceIdentificativo
        },
        erario: {
            codiceUfficio: ((_19 = (_18 = state.form) === null || _18 === void 0 ? void 0 : _18.sezioneErario) === null || _19 === void 0 ? void 0 : _19.codiceUfficio) || '',
            codiceAtto: ((_21 = (_20 = state.form) === null || _20 === void 0 ? void 0 : _20.sezioneErario) === null || _21 === void 0 ? void 0 : _21.codiceAtto) ? Number((_23 = (_22 = state.form) === null || _22 === void 0 ? void 0 : _22.sezioneErario) === null || _23 === void 0 ? void 0 : _23.codiceAtto) : 0,
            righe: (0, exports.erarioRighe)(state)
        },
        inps: {
            righe: (0, exports.inpsRighe)(state)
        },
        regioni: {
            righe: (0, exports.regioniRighe)(state)
        },
        tributiLocali: {
            detrazione: (0, helpers_1.formattedNumberToNumber)((_25 = (_24 = state.form) === null || _24 === void 0 ? void 0 : _24.sezioneIMU) === null || _25 === void 0 ? void 0 : _25.detrazione),
            identificativoOperazione: '',
            righe: (0, exports.tributiLocaliRighe)(state)
        },
        inail: {
            righe: (0, exports.inailRighe)(state)
        },
        entiPrevidenziali: {
            codice: (_30 = (_29 = (_28 = (_27 = (_26 = state.form) === null || _26 === void 0 ? void 0 : _26.sezioneAltriEntiMN) === null || _27 === void 0 ? void 0 : _27.righe) === null || _28 === void 0 ? void 0 : _28[0]) === null || _29 === void 0 ? void 0 : _29.codiceEnte) !== null && _30 !== void 0 ? _30 : '',
            righe: (0, exports.entiPrevidenzialiRighe)(state)
        },
        idBozzaCollegata: state.currentBozza.id
    };
});
exports.buildF24Semplificato = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17;
    const saldoFinale = (_a = (0, exports.formF24Ordinario)(state).sezioneDataESaldo) === null || _a === void 0 ? void 0 : _a.saldo;
    return {
        id: (_b = state.currentBozza) === null || _b === void 0 ? void 0 : _b.id,
        codiceRapporto: ((_e = (_d = (_c = state.form) === null || _c === void 0 ? void 0 : _c.rapportoSelezionato) === null || _d === void 0 ? void 0 : _d.rapporto) === null || _e === void 0 ? void 0 : _e.id) || 0,
        codiceAzienda: (_j = (_h = (_g = (_f = state.form) === null || _f === void 0 ? void 0 : _f.rapportoSelezionato) === null || _g === void 0 ? void 0 : _g.rapporto) === null || _h === void 0 ? void 0 : _h.iban) === null || _j === void 0 ? void 0 : _j.substr(5, 5),
        codiceCAB: (_o = (_m = (_l = (_k = state.form) === null || _k === void 0 ? void 0 : _k.rapportoSelezionato) === null || _l === void 0 ? void 0 : _l.rapporto) === null || _m === void 0 ? void 0 : _m.iban) === null || _o === void 0 ? void 0 : _o.substr(10, 5),
        dataOperazione: ((_q = (_p = state.form) === null || _p === void 0 ? void 0 : _p.sezioneDataESaldo) === null || _q === void 0 ? void 0 : _q.data) ? new Date(state.form.sezioneDataESaldo.data) : undefined,
        stato: 'OK',
        revocabile: true,
        tipologia: 'SEMPLIFICATO',
        saldoFinale: saldoFinale,
        idContattoBeneficiario: state.form.contribuente.idContattoBeneficiario,
        aggiungiRubrica: state.form.contribuente.aggiungiBeneficiarioRubrica,
        aggiungiWhitelist: state.form.contribuente.aggiungiBeneficiarioWhitelist,
        contribuente: {
            codiceFiscale: ((_s = (_r = state.form) === null || _r === void 0 ? void 0 : _r.contribuente) === null || _s === void 0 ? void 0 : _s.codiceFiscale) || '',
            datiAnagrafici: {
                cognome: ((_u = (_t = state.form) === null || _t === void 0 ? void 0 : _t.contribuente) === null || _u === void 0 ? void 0 : _u.cognome) || '',
                nome: ((_w = (_v = state.form) === null || _v === void 0 ? void 0 : _v.contribuente) === null || _w === void 0 ? void 0 : _w.nome) || '',
                dataNascita: ((_y = (_x = state.form) === null || _x === void 0 ? void 0 : _x.contribuente) === null || _y === void 0 ? void 0 : _y.dataDiNascita) ? new Date(state.form.contribuente.dataDiNascita) : undefined,
                sesso: ((_0 = (_z = state.form) === null || _z === void 0 ? void 0 : _z.contribuente) === null || _0 === void 0 ? void 0 : _0.sesso) || '',
                comune: ((_2 = (_1 = state.form) === null || _1 === void 0 ? void 0 : _1.contribuente) === null || _2 === void 0 ? void 0 : _2.comune) || '',
                provincia: ((_4 = (_3 = state.form) === null || _3 === void 0 ? void 0 : _3.contribuente) === null || _4 === void 0 ? void 0 : _4.provincia) || ''
            },
            domicilio: {
                comune: ((_7 = (_6 = (_5 = state.form) === null || _5 === void 0 ? void 0 : _5.contribuente) === null || _6 === void 0 ? void 0 : _6.domicilioFiscale) === null || _7 === void 0 ? void 0 : _7.comune) || '',
                provincia: ((_10 = (_9 = (_8 = state.form) === null || _8 === void 0 ? void 0 : _8.contribuente) === null || _9 === void 0 ? void 0 : _9.domicilioFiscale) === null || _10 === void 0 ? void 0 : _10.provincia) || '',
                indirizzo: ((_13 = (_12 = (_11 = state.form) === null || _11 === void 0 ? void 0 : _11.contribuente) === null || _12 === void 0 ? void 0 : _12.domicilioFiscale) === null || _13 === void 0 ? void 0 : _13.viaENumeroCivico) || ''
            },
            codiceFiscaleTutore: (_15 = (_14 = state.form) === null || _14 === void 0 ? void 0 : _14.contribuente) === null || _15 === void 0 ? void 0 : _15.codiceFiscaleCoobligato,
            codiceIdentificativoCFTutore: (_17 = (_16 = state.form) === null || _16 === void 0 ? void 0 : _16.contribuente) === null || _17 === void 0 ? void 0 : _17.codiceIdentificativo
        },
        semplificato: {
            righe: (0, exports.semplificatoRighe)(state),
            identificativoOperazione: state.form.sezioneMotivoPagamento.numeroIdentificativo
        },
        idBozzaCollegata: state.currentBozza.id
    };
});
exports.buildF24Accise = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29;
    const saldoFinale = ((_a = (0, exports.formF24Ordinario)(state).sezioneDataESaldo) === null || _a === void 0 ? void 0 : _a.saldo) || 0;
    return {
        id: (_b = state.currentBozza) === null || _b === void 0 ? void 0 : _b.id,
        codiceRapporto: ((_e = (_d = (_c = state.form) === null || _c === void 0 ? void 0 : _c.rapportoSelezionato) === null || _d === void 0 ? void 0 : _d.rapporto) === null || _e === void 0 ? void 0 : _e.id) || 0,
        codiceAzienda: (_j = (_h = (_g = (_f = state.form) === null || _f === void 0 ? void 0 : _f.rapportoSelezionato) === null || _g === void 0 ? void 0 : _g.rapporto) === null || _h === void 0 ? void 0 : _h.iban) === null || _j === void 0 ? void 0 : _j.substr(5, 5),
        codiceCAB: (_o = (_m = (_l = (_k = state.form) === null || _k === void 0 ? void 0 : _k.rapportoSelezionato) === null || _l === void 0 ? void 0 : _l.rapporto) === null || _m === void 0 ? void 0 : _m.iban) === null || _o === void 0 ? void 0 : _o.substr(10, 5),
        dataOperazione: ((_q = (_p = state.form) === null || _p === void 0 ? void 0 : _p.sezioneDataESaldo) === null || _q === void 0 ? void 0 : _q.data) ? new Date(state.form.sezioneDataESaldo.data) : undefined,
        stato: 'OK',
        revocabile: true,
        tipologia: 'ACCISE',
        saldoFinale: saldoFinale,
        idContattoBeneficiario: state.form.contribuente.idContattoBeneficiario,
        aggiungiRubrica: state.form.contribuente.aggiungiBeneficiarioRubrica,
        aggiungiWhitelist: state.form.contribuente.aggiungiBeneficiarioWhitelist,
        contribuente: {
            codiceFiscale: ((_s = (_r = state.form) === null || _r === void 0 ? void 0 : _r.contribuente) === null || _s === void 0 ? void 0 : _s.codiceFiscale) || '',
            datiAnagrafici: {
                cognome: ((_u = (_t = state.form) === null || _t === void 0 ? void 0 : _t.contribuente) === null || _u === void 0 ? void 0 : _u.cognome) || '',
                nome: ((_w = (_v = state.form) === null || _v === void 0 ? void 0 : _v.contribuente) === null || _w === void 0 ? void 0 : _w.nome) || '',
                dataNascita: ((_y = (_x = state.form) === null || _x === void 0 ? void 0 : _x.contribuente) === null || _y === void 0 ? void 0 : _y.dataDiNascita) ? new Date(state.form.contribuente.dataDiNascita) : undefined,
                sesso: ((_0 = (_z = state.form) === null || _z === void 0 ? void 0 : _z.contribuente) === null || _0 === void 0 ? void 0 : _0.sesso) || '',
                comune: ((_2 = (_1 = state.form) === null || _1 === void 0 ? void 0 : _1.contribuente) === null || _2 === void 0 ? void 0 : _2.comune) || '',
                provincia: ((_4 = (_3 = state.form) === null || _3 === void 0 ? void 0 : _3.contribuente) === null || _4 === void 0 ? void 0 : _4.provincia) || ''
            },
            domicilio: {
                comune: ((_7 = (_6 = (_5 = state.form) === null || _5 === void 0 ? void 0 : _5.contribuente) === null || _6 === void 0 ? void 0 : _6.domicilioFiscale) === null || _7 === void 0 ? void 0 : _7.comune) || '',
                provincia: ((_10 = (_9 = (_8 = state.form) === null || _8 === void 0 ? void 0 : _8.contribuente) === null || _9 === void 0 ? void 0 : _9.domicilioFiscale) === null || _10 === void 0 ? void 0 : _10.provincia) || '',
                indirizzo: ((_13 = (_12 = (_11 = state.form) === null || _11 === void 0 ? void 0 : _11.contribuente) === null || _12 === void 0 ? void 0 : _12.domicilioFiscale) === null || _13 === void 0 ? void 0 : _13.viaENumeroCivico) || ''
            },
            codiceFiscaleTutore: (_15 = (_14 = state.form) === null || _14 === void 0 ? void 0 : _14.contribuente) === null || _15 === void 0 ? void 0 : _15.codiceFiscaleCoobligato,
            codiceIdentificativoCFTutore: (_17 = (_16 = state.form) === null || _16 === void 0 ? void 0 : _16.contribuente) === null || _17 === void 0 ? void 0 : _17.codiceIdentificativo
        },
        erario: {
            codiceUfficio: ((_19 = (_18 = state.form) === null || _18 === void 0 ? void 0 : _18.sezioneErario) === null || _19 === void 0 ? void 0 : _19.codiceUfficio) || '',
            codiceAtto: ((_21 = (_20 = state.form) === null || _20 === void 0 ? void 0 : _20.sezioneErario) === null || _21 === void 0 ? void 0 : _21.codiceAtto) ? Number((_23 = (_22 = state.form) === null || _22 === void 0 ? void 0 : _22.sezioneErario) === null || _23 === void 0 ? void 0 : _23.codiceAtto) : 0,
            righe: (0, exports.erarioRighe)(state)
        },
        inps: {
            righe: (0, exports.inpsRighe)(state)
        },
        regioni: {
            righe: (0, exports.regioniRighe)(state)
        },
        tributiLocali: {
            detrazione: (0, helpers_1.formattedNumberToNumber)((_25 = (_24 = state.form) === null || _24 === void 0 ? void 0 : _24.sezioneIMU) === null || _25 === void 0 ? void 0 : _25.detrazione),
            identificativoOperazione: '',
            righe: (0, exports.tributiLocaliRighe)(state)
        },
        accise: {
            codiceUfficio: ((_27 = (_26 = state.form) === null || _26 === void 0 ? void 0 : _26.sezioneAccise) === null || _27 === void 0 ? void 0 : _27.codiceUfficio) || '',
            codiceAtto: ((_29 = (_28 = state.form) === null || _28 === void 0 ? void 0 : _28.sezioneAccise) === null || _29 === void 0 ? void 0 : _29.codiceAtto) || 0,
            righe: (0, exports.acciseRighe)(state)
        },
        idBozzaCollegata: state.currentBozza.id
    };
});
exports.buildF24Elide = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21;
    const saldoFinale = ((_a = (0, exports.formF24Ordinario)(state).sezioneDataESaldo) === null || _a === void 0 ? void 0 : _a.saldo) || 0;
    return {
        id: (_b = state.currentBozza) === null || _b === void 0 ? void 0 : _b.id,
        codiceRapporto: ((_e = (_d = (_c = state.form) === null || _c === void 0 ? void 0 : _c.rapportoSelezionato) === null || _d === void 0 ? void 0 : _d.rapporto) === null || _e === void 0 ? void 0 : _e.id) || 0,
        codiceAzienda: (_j = (_h = (_g = (_f = state.form) === null || _f === void 0 ? void 0 : _f.rapportoSelezionato) === null || _g === void 0 ? void 0 : _g.rapporto) === null || _h === void 0 ? void 0 : _h.iban) === null || _j === void 0 ? void 0 : _j.substr(5, 5),
        codiceCAB: (_o = (_m = (_l = (_k = state.form) === null || _k === void 0 ? void 0 : _k.rapportoSelezionato) === null || _l === void 0 ? void 0 : _l.rapporto) === null || _m === void 0 ? void 0 : _m.iban) === null || _o === void 0 ? void 0 : _o.substr(10, 5),
        dataOperazione: ((_q = (_p = state.form) === null || _p === void 0 ? void 0 : _p.sezioneDataESaldo) === null || _q === void 0 ? void 0 : _q.data) ? new Date(state.form.sezioneDataESaldo.data) : undefined,
        stato: 'OK',
        revocabile: true,
        tipologia: 'IDENTIFICATIVI',
        saldoFinale: saldoFinale,
        idContattoBeneficiario: state.form.contribuente.idContattoBeneficiario,
        aggiungiRubrica: state.form.contribuente.aggiungiBeneficiarioRubrica,
        aggiungiWhitelist: state.form.contribuente.aggiungiBeneficiarioWhitelist,
        contribuente: {
            codiceFiscale: ((_s = (_r = state.form) === null || _r === void 0 ? void 0 : _r.contribuente) === null || _s === void 0 ? void 0 : _s.codiceFiscale) || '',
            datiAnagrafici: {
                cognome: ((_u = (_t = state.form) === null || _t === void 0 ? void 0 : _t.contribuente) === null || _u === void 0 ? void 0 : _u.cognome) || '',
                nome: ((_w = (_v = state.form) === null || _v === void 0 ? void 0 : _v.contribuente) === null || _w === void 0 ? void 0 : _w.nome) || '',
                dataNascita: ((_y = (_x = state.form) === null || _x === void 0 ? void 0 : _x.contribuente) === null || _y === void 0 ? void 0 : _y.dataDiNascita) ? new Date(state.form.contribuente.dataDiNascita) : undefined,
                sesso: ((_0 = (_z = state.form) === null || _z === void 0 ? void 0 : _z.contribuente) === null || _0 === void 0 ? void 0 : _0.sesso) || '',
                comune: ((_2 = (_1 = state.form) === null || _1 === void 0 ? void 0 : _1.contribuente) === null || _2 === void 0 ? void 0 : _2.comune) || '',
                provincia: ((_4 = (_3 = state.form) === null || _3 === void 0 ? void 0 : _3.contribuente) === null || _4 === void 0 ? void 0 : _4.provincia) || ''
            },
            domicilio: {
                comune: ((_7 = (_6 = (_5 = state.form) === null || _5 === void 0 ? void 0 : _5.contribuente) === null || _6 === void 0 ? void 0 : _6.domicilioFiscale) === null || _7 === void 0 ? void 0 : _7.comune) || '',
                provincia: ((_10 = (_9 = (_8 = state.form) === null || _8 === void 0 ? void 0 : _8.contribuente) === null || _9 === void 0 ? void 0 : _9.domicilioFiscale) === null || _10 === void 0 ? void 0 : _10.provincia) || '',
                indirizzo: ((_13 = (_12 = (_11 = state.form) === null || _11 === void 0 ? void 0 : _11.contribuente) === null || _12 === void 0 ? void 0 : _12.domicilioFiscale) === null || _13 === void 0 ? void 0 : _13.viaENumeroCivico) || ''
            },
            codiceFiscaleTutore: (_15 = (_14 = state.form) === null || _14 === void 0 ? void 0 : _14.contribuente) === null || _15 === void 0 ? void 0 : _15.codiceFiscaleCoobligato,
            codiceIdentificativoCFTutore: (_17 = (_16 = state.form) === null || _16 === void 0 ? void 0 : _16.contribuente) === null || _17 === void 0 ? void 0 : _17.codiceIdentificativo
        },
        identificativi: {
            codiceUfficio: ((_19 = (_18 = state.form) === null || _18 === void 0 ? void 0 : _18.sezioneAltroErario) === null || _19 === void 0 ? void 0 : _19.codiceUfficio) || '',
            codiceAtto: ((_21 = (_20 = state.form) === null || _20 === void 0 ? void 0 : _20.sezioneAltroErario) === null || _21 === void 0 ? void 0 : _21.codiceAtto) ? Number(state.form.sezioneAltroErario.codiceAtto) : 0,
            righe: (0, exports.identificativiRighe)(state)
        },
        idBozzaCollegata: state.currentBozza.id
    };
});
exports.buildFilterExportDettaglioF24 = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
    const request = {
        campiF24Deleghe: {
            codiceFiscaleContribuente: (_a = state.esito.disposizione) === null || _a === void 0 ? void 0 : _a.contribuente.codiceFiscale,
            codiceRapporto: (_b = state.esito.disposizione) === null || _b === void 0 ? void 0 : _b.codiceRapporto,
            dataContabile: (_c = state.esito.disposizione) === null || _c === void 0 ? void 0 : _c.dataOperazione,
            dataInvio: (_d = state.esito.disposizione) === null || _d === void 0 ? void 0 : _d.dataInserimento,
            progressivoDelegaInvio: (_f = (_e = state.esito.disposizione) === null || _e === void 0 ? void 0 : _e.campiF24DelegheRB) === null || _f === void 0 ? void 0 : _f.progressivoDelegaInvio,
            progressivoInvio: (_h = (_g = state.esito.disposizione) === null || _g === void 0 ? void 0 : _g.campiF24DelegheRB) === null || _h === void 0 ? void 0 : _h.progressivoInvio,
            progressivoTecnico: (_k = (_j = state.esito.disposizione) === null || _j === void 0 ? void 0 : _j.campiF24DelegheRB) === null || _k === void 0 ? void 0 : _k.progressivoTecnico
        },
        campiF24SP: {
            annoInserimento: (_m = (_l = state.esito.disposizione) === null || _l === void 0 ? void 0 : _l.dataInserimento) === null || _m === void 0 ? void 0 : _m.getFullYear(),
            codiceRapporto: (_o = state.esito.disposizione) === null || _o === void 0 ? void 0 : _o.codiceRapporto,
            progressivoInserimento: (_q = (_p = state.esito.disposizione) === null || _p === void 0 ? void 0 : _p.campiF24SP) === null || _q === void 0 ? void 0 : _q.progressivoInserimento,
            tipologia: (_r = state.esito.disposizione) === null || _r === void 0 ? void 0 : _r.tipologia
        }
    };
    return request;
});
exports.buildFilterExportQuietanzaF24 = (0, reselect_1.createSelector)(pagamentiReducer, (state) => {
    var _a, _b, _c, _d, _e, _f;
    const f24 = (_a = state.dettaglioPagamento) === null || _a === void 0 ? void 0 : _a.disposizione;
    const request = {
        campiF24Deleghe: {
            codiceFiscaleContribuente: f24.contribuente.codiceFiscale,
            codiceRapporto: f24.codiceRapporto,
            dataContabile: f24.dataOperazione ? new Date(f24.dataOperazione) : undefined,
            dataInvio: f24.dataInserimento ? new Date(f24.dataInserimento) : undefined,
            progressivoDelegaInvio: (_b = f24.campiF24DelegheRB) === null || _b === void 0 ? void 0 : _b.progressivoDelegaInvio,
            progressivoInvio: (_c = f24.campiF24DelegheRB) === null || _c === void 0 ? void 0 : _c.progressivoInvio,
            progressivoTecnico: (_d = f24.campiF24DelegheRB) === null || _d === void 0 ? void 0 : _d.progressivoTecnico
        },
        campiF24SP: {
            annoInserimento: (_e = f24.campiF24SP) === null || _e === void 0 ? void 0 : _e.annoRiferimento,
            codiceRapporto: f24.codiceRapporto,
            progressivoInserimento: (_f = f24.campiF24SP) === null || _f === void 0 ? void 0 : _f.progressivoInserimento,
            tipologia: f24.tipologia
        }
    };
    return request;
});
exports.documentSessionInitF24Result = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    return undefined; //state.documentSessionInitF24Result;
});
exports.documentSessionTokenInfoF24Result = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    return undefined; //state.documentSessionTokenInfoF24Result;
});
exports.documentSessionTokenDownloadF24Result = (0, reselect_1.createSelector)(f24Reducer, (state) => {
    return undefined; //state.documentSessionTokenDownloadF24Result;
});
