"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PerformanceRequestInDtoToJSON = exports.PerformanceRequestInDtoFromJSONTyped = exports.PerformanceRequestInDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function PerformanceRequestInDtoFromJSON(json) {
    return PerformanceRequestInDtoFromJSONTyped(json, false);
}
exports.PerformanceRequestInDtoFromJSON = PerformanceRequestInDtoFromJSON;
function PerformanceRequestInDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'customerId': json['customerId'],
        'period': json['period'],
        'accounts': !runtime_1.exists(json, 'accounts') ? undefined : json['accounts'],
        'perimeterAccounts': !runtime_1.exists(json, 'perimeterAccounts') ? undefined : json['perimeterAccounts'],
        'perimeterPositionCodes': !runtime_1.exists(json, 'perimeterPositionCodes') ? undefined : json['perimeterPositionCodes'],
        'startingDateString': !runtime_1.exists(json, 'startingDateString') ? undefined : json['startingDateString'],
        'endingDateString': !runtime_1.exists(json, 'endingDateString') ? undefined : json['endingDateString'],
        'includeClosedPosition': !runtime_1.exists(json, 'includeClosedPosition') ? undefined : json['includeClosedPosition'],
        'includeCoi': !runtime_1.exists(json, 'includeCoi') ? undefined : json['includeCoi'],
    };
}
exports.PerformanceRequestInDtoFromJSONTyped = PerformanceRequestInDtoFromJSONTyped;
function PerformanceRequestInDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'customerId': value.customerId,
        'period': value.period,
        'accounts': value.accounts,
        'perimeterAccounts': value.perimeterAccounts,
        'perimeterPositionCodes': value.perimeterPositionCodes,
        'startingDateString': value.startingDateString,
        'endingDateString': value.endingDateString,
        'includeClosedPosition': value.includeClosedPosition,
        'includeCoi': value.includeCoi,
    };
}
exports.PerformanceRequestInDtoToJSON = PerformanceRequestInDtoToJSON;
