import { MessaggioBodyDto } from "@sparkasse/commons";
import ButtonText2 from "component/buttonText2/buttonText2.component";
import CarouselCustom from "component/carouselCustom/carouselCustom.component";
import ItemCarousel from "component/carouselCustom/item.carouselCustom.component";
import BankIcon from "component/Icon/icon.component";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  comunicazioniMessaggi: MessaggioBodyDto[] | undefined
  segnaComeLettoMessaggi: Function
}

export const ComponenteComunicazione: React.FC<Props> = (props: Props) => {

  const { t } = useTranslation();

  const [visible, setVisible] = useState(true);
  const [selectedMessageIndex, setSelectedMessageIndex] = useState<number>(0);

  const _comunicazioniMessaggi = useMemo(() => {
    return props.comunicazioniMessaggi?.filter(c => !c.letto);
  }, [props.comunicazioniMessaggi]);

  useEffect(() => {
    if (_comunicazioniMessaggi?.length && _comunicazioniMessaggi.length > 0) {
      setSelectedMessageIndex(0);
    }
  }, [_comunicazioniMessaggi]);

  const onClose = () => {
    setVisible(false);
    props.comunicazioniMessaggi?.filter(message => !message.letto).forEach(message => {
      message?.idMessaggio && props.segnaComeLettoMessaggi(message?.idMessaggio);
    });
  };

  const onScopriDiPiu = (urlLink: string | undefined) => {
    if (!urlLink) {
      return;
    }

    const link = document.createElement("a");
    link.referrerPolicy = "no-referrer";
    link.target = "_blank";
    link.href = urlLink || "";
    !!link.href && link.click();
  };

  const slides = useMemo(() => {
    return _comunicazioniMessaggi?.map((comunicazione, index) => (
      <ItemCarousel
        key={'slide_comunicazione_' + index}
        index={index}
        selectedIndex={selectedMessageIndex}
        custom={{
          allowHtmlMainText: true,
          info0: comunicazione?.titolo,
          info1: comunicazione?.body,
          cta: !!comunicazione?.urlLink
            ? (
              <div className="scopri-di-piu">
                <ButtonText2
                  descrizione={t('messaggi_comunicazione.scopri')}
                  className="scopri-di-piu-button"
                  showImageBack
                  onClick={() => onScopriDiPiu(comunicazione.urlLink)}
                />
              </div>
            )
            : undefined
        }}
      />
    )) || [];
  }, [_comunicazioniMessaggi, selectedMessageIndex]);

  return _comunicazioniMessaggi?.length && visible
    ? <div className={`top-page-notification`}>

      <BankIcon
        title="navigazione_chiudi"
        className="top-page-notification-close-icon"
        white
        onClick={onClose}
      />

      <CarouselCustom
        className="messaggi-comunicazione"
        arrows
        custom={{
          slides: slides,
          onSelectSlideIndex: setSelectedMessageIndex
        }}
      />

    </div>
    : null;
};
