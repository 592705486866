"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StampaTitoliScadenzaTypeEnum = exports.StampaOrdiniInScadenzaTypeEnum = exports.FinanzaControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class FinanzaControllerApi extends runtime.BaseAPI {
    /**
     * Aggiungi item watchlist statica
     */
    aggiungiItemWatchlistStaticaRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.watchlistItemInDto === null || requestParameters.watchlistItemInDto === undefined) {
                throw new runtime.RequiredError('watchlistItemInDto', 'Required parameter requestParameters.watchlistItemInDto was null or undefined when calling aggiungiItemWatchlistStatica.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/finanza/watchliststatiche/aggiungiitem`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.WatchlistItemInDtoToJSON(requestParameters.watchlistItemInDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.WatchlistQuotazioniDtoFromJSON(jsonValue));
        });
    }
    /**
     * Aggiungi item watchlist statica
     */
    aggiungiItemWatchlistStatica(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.aggiungiItemWatchlistStaticaRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce la lista dei titoli preferiti
     */
    aggiungiPreferitoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.codiceRapporto === null || requestParameters.codiceRapporto === undefined) {
                throw new runtime.RequiredError('codiceRapporto', 'Required parameter requestParameters.codiceRapporto was null or undefined when calling aggiungiPreferito.');
            }
            if (requestParameters.datiTitoloConMercatoDto === null || requestParameters.datiTitoloConMercatoDto === undefined) {
                throw new runtime.RequiredError('datiTitoloConMercatoDto', 'Required parameter requestParameters.datiTitoloConMercatoDto was null or undefined when calling aggiungiPreferito.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/finanza/titoli/preferiti/{codiceRapporto}`.replace(`{${"codiceRapporto"}}`, encodeURIComponent(String(requestParameters.codiceRapporto))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.DatiTitoloConMercatoDtoToJSON(requestParameters.datiTitoloConMercatoDto),
            });
            return new runtime.TextApiResponse(response);
        });
    }
    /**
     * Restituisce la lista dei titoli preferiti
     */
    aggiungiPreferito(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.aggiungiPreferitoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce la lista dei titoli preferiti
     */
    aggiungiTitoloPreferitoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.addToPreferredDto === null || requestParameters.addToPreferredDto === undefined) {
                throw new runtime.RequiredError('addToPreferredDto', 'Required parameter requestParameters.addToPreferredDto was null or undefined when calling aggiungiTitoloPreferito.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/finanza/titoli/preferiti`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.AddToPreferredDtoToJSON(requestParameters.addToPreferredDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.DatiTitoliConMercatoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce la lista dei titoli preferiti
     */
    aggiungiTitoloPreferito(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.aggiungiTitoloPreferitoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Autorizzazione di una disposizione di trading
     */
    autorizzaOrdineRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling autorizzaOrdine.');
            }
            if (requestParameters.credenzialeDispositivaDto === null || requestParameters.credenzialeDispositivaDto === undefined) {
                throw new runtime.RequiredError('credenzialeDispositivaDto', 'Required parameter requestParameters.credenzialeDispositivaDto was null or undefined when calling autorizzaOrdine.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/finanza/autorizza/{idDispositiva}`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CredenzialeDispositivaDtoToJSON(requestParameters.credenzialeDispositivaDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.EsitoAutorizzazioneDisposizioneFinanzaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Autorizzazione di una disposizione di trading
     */
    autorizzaOrdine(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.autorizzaOrdineRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Autorizzazione di una revoca di trading.
     */
    autorizzaRevocaRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.revocaOrdineTradingDto === null || requestParameters.revocaOrdineTradingDto === undefined) {
                throw new runtime.RequiredError('revocaOrdineTradingDto', 'Required parameter requestParameters.revocaOrdineTradingDto was null or undefined when calling autorizzaRevoca.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/finanza/ordine/revoca/autorizza`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.RevocaOrdineTradingDtoToJSON(requestParameters.revocaOrdineTradingDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.EsitoAutorizzazioneDisposizioneFinanzaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Autorizzazione di una revoca di trading.
     */
    autorizzaRevoca(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.autorizzaRevocaRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce il book di un titolo per un mercato
     */
    bookTitoloRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.mercato === null || requestParameters.mercato === undefined) {
                throw new runtime.RequiredError('mercato', 'Required parameter requestParameters.mercato was null or undefined when calling bookTitolo.');
            }
            if (requestParameters.codiceTitolo === null || requestParameters.codiceTitolo === undefined) {
                throw new runtime.RequiredError('codiceTitolo', 'Required parameter requestParameters.codiceTitolo was null or undefined when calling bookTitolo.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/finanza/book/{mercato}/{codiceTitolo}`.replace(`{${"mercato"}}`, encodeURIComponent(String(requestParameters.mercato))).replace(`{${"codiceTitolo"}}`, encodeURIComponent(String(requestParameters.codiceTitolo))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.BookDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce il book di un titolo per un mercato
     */
    bookTitolo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.bookTitoloRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Controllo ordine di acquisto o vendita di un titolo
     */
    controllaOrdineRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.ordineTradingDto === null || requestParameters.ordineTradingDto === undefined) {
                throw new runtime.RequiredError('ordineTradingDto', 'Required parameter requestParameters.ordineTradingDto was null or undefined when calling controllaOrdine.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/finanza/ordine/controllo`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.OrdineTradingDtoToJSON(requestParameters.ordineTradingDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.OrdineTradingDtoFromJSON(jsonValue));
        });
    }
    /**
     * Controllo ordine di acquisto o vendita di un titolo
     */
    controllaOrdine(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.controllaOrdineRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Controllo della revoca di un ordine di trading.
     */
    controlloRevocaOrdineRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.revocaOrdineTradingDto === null || requestParameters.revocaOrdineTradingDto === undefined) {
                throw new runtime.RequiredError('revocaOrdineTradingDto', 'Required parameter requestParameters.revocaOrdineTradingDto was null or undefined when calling controlloRevocaOrdine.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/finanza/ordine/revoca/controllo`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.RevocaOrdineTradingDtoToJSON(requestParameters.revocaOrdineTradingDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.OrdineTradingDtoFromJSON(jsonValue));
        });
    }
    /**
     * Controllo della revoca di un ordine di trading.
     */
    controlloRevocaOrdine(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.controlloRevocaOrdineRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Crea una watchlist statica
     */
    creaWatchlistStaticaRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.watchlistStaticaDto === null || requestParameters.watchlistStaticaDto === undefined) {
                throw new runtime.RequiredError('watchlistStaticaDto', 'Required parameter requestParameters.watchlistStaticaDto was null or undefined when calling creaWatchlistStatica.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/finanza/watchliststatiche/crea`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.WatchlistStaticaDtoToJSON(requestParameters.watchlistStaticaDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.WatchlistQuotazioniDtoFromJSON(jsonValue));
        });
    }
    /**
     * Crea una watchlist statica
     */
    creaWatchlistStatica(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.creaWatchlistStaticaRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce i dati intraday di un titolo passato in input.
     */
    datiIntradayTitoloRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.datiIntradayTitoloInDto === null || requestParameters.datiIntradayTitoloInDto === undefined) {
                throw new runtime.RequiredError('datiIntradayTitoloInDto', 'Required parameter requestParameters.datiIntradayTitoloInDto was null or undefined when calling datiIntradayTitolo.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/finanza/titoli/intraday`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.DatiIntradayTitoloInDtoToJSON(requestParameters.datiIntradayTitoloInDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.DatiIntradayDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce i dati intraday di un titolo passato in input.
     */
    datiIntradayTitolo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.datiIntradayTitoloRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce la lista dei dati storici, di un titolo, usati per popolare il grafico. Prende input il campo codice del titolo (o anche il codiceisin) restituito da getQuotazioniTitoli.
     */
    datiStoriciTitoloRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.datiIntradayTitoloInDto === null || requestParameters.datiIntradayTitoloInDto === undefined) {
                throw new runtime.RequiredError('datiIntradayTitoloInDto', 'Required parameter requestParameters.datiIntradayTitoloInDto was null or undefined when calling datiStoriciTitolo.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/finanza/titolo/datistorici`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.DatiIntradayTitoloInDtoToJSON(requestParameters.datiIntradayTitoloInDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.DatiStoriciDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce la lista dei dati storici, di un titolo, usati per popolare il grafico. Prende input il campo codice del titolo (o anche il codiceisin) restituito da getQuotazioniTitoli.
     */
    datiStoriciTitolo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.datiStoriciTitoloRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce il book di un titolo per un mercato
     */
    dettaglioOrdineRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.numeroOrdine === null || requestParameters.numeroOrdine === undefined) {
                throw new runtime.RequiredError('numeroOrdine', 'Required parameter requestParameters.numeroOrdine was null or undefined when calling dettaglioOrdine.');
            }
            if (requestParameters.codiceRapporto === null || requestParameters.codiceRapporto === undefined) {
                throw new runtime.RequiredError('codiceRapporto', 'Required parameter requestParameters.codiceRapporto was null or undefined when calling dettaglioOrdine.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/finanza/ordini/{codiceRapporto}/{numeroOrdine}`.replace(`{${"numeroOrdine"}}`, encodeURIComponent(String(requestParameters.numeroOrdine))).replace(`{${"codiceRapporto"}}`, encodeURIComponent(String(requestParameters.codiceRapporto))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.EseguitiResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce il book di un titolo per un mercato
     */
    dettaglioOrdine(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.dettaglioOrdineRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Export in PDF del dettaglio dell\'ordine in base ai criteri inviati per il rapporto indicato
     */
    dettaglioOrdinePdfRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.eseguitiResponseDto === null || requestParameters.eseguitiResponseDto === undefined) {
                throw new runtime.RequiredError('eseguitiResponseDto', 'Required parameter requestParameters.eseguitiResponseDto was null or undefined when calling dettaglioOrdinePdf.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/finanza/export/dettaglioordine/pdf`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.EseguitiResponseDtoToJSON(requestParameters.eseguitiResponseDto),
            });
            return new runtime.BlobApiResponse(response);
        });
    }
    /**
     * Export in PDF del dettaglio dell\'ordine in base ai criteri inviati per il rapporto indicato
     */
    dettaglioOrdinePdf(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.dettaglioOrdinePdfRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Recupera il dettaglio di un titolo
     */
    dettaglioTitoloRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.mercato === null || requestParameters.mercato === undefined) {
                throw new runtime.RequiredError('mercato', 'Required parameter requestParameters.mercato was null or undefined when calling dettaglioTitolo.');
            }
            if (requestParameters.codiceTitolo === null || requestParameters.codiceTitolo === undefined) {
                throw new runtime.RequiredError('codiceTitolo', 'Required parameter requestParameters.codiceTitolo was null or undefined when calling dettaglioTitolo.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/finanza/titolo/{mercato}/{codiceTitolo}`.replace(`{${"mercato"}}`, encodeURIComponent(String(requestParameters.mercato))).replace(`{${"codiceTitolo"}}`, encodeURIComponent(String(requestParameters.codiceTitolo))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.SchedaTitoloDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera il dettaglio di un titolo
     */
    dettaglioTitolo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.dettaglioTitoloRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce la lista delle watchlist aggiornata senza la watchlist appena eliminata
     */
    eliminaWatchlistRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.codiceWatchlist === null || requestParameters.codiceWatchlist === undefined) {
                throw new runtime.RequiredError('codiceWatchlist', 'Required parameter requestParameters.codiceWatchlist was null or undefined when calling eliminaWatchlist.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/finanza/watchlist/elimina/{codiceWatchlist}`.replace(`{${"codiceWatchlist"}}`, encodeURIComponent(String(requestParameters.codiceWatchlist))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.WatchlistDtoFromJSON));
        });
    }
    /**
     * Restituisce la lista delle watchlist aggiornata senza la watchlist appena eliminata
     */
    eliminaWatchlist(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.eliminaWatchlistRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce la lista dei dati Intraday di un cambio usati per popolare il grafico.
     */
    getDatiIntradayCambioRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.datiIntradayCambioInDto === null || requestParameters.datiIntradayCambioInDto === undefined) {
                throw new runtime.RequiredError('datiIntradayCambioInDto', 'Required parameter requestParameters.datiIntradayCambioInDto was null or undefined when calling getDatiIntradayCambio.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/finanza/cambi/intraday`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.DatiIntradayCambioInDtoToJSON(requestParameters.datiIntradayCambioInDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.DatiIntradayDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce la lista dei dati Intraday di un cambio usati per popolare il grafico.
     */
    getDatiIntradayCambio(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getDatiIntradayCambioRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce la lista dei dati Intraday di un indice usati per popolare il grafico.
     */
    getDatiIntradayIndiceRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.datiIntradayIndiceInDto === null || requestParameters.datiIntradayIndiceInDto === undefined) {
                throw new runtime.RequiredError('datiIntradayIndiceInDto', 'Required parameter requestParameters.datiIntradayIndiceInDto was null or undefined when calling getDatiIntradayIndice.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/finanza/indici/intraday`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.DatiIntradayIndiceInDtoToJSON(requestParameters.datiIntradayIndiceInDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.DatiIntradayDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce la lista dei dati Intraday di un indice usati per popolare il grafico.
     */
    getDatiIntradayIndice(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getDatiIntradayIndiceRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce la lista dei dati storici, di un cambio, usati per popolare il grafico.
     */
    getDatiStoriciCambiRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.datiIntradayCambioInDto === null || requestParameters.datiIntradayCambioInDto === undefined) {
                throw new runtime.RequiredError('datiIntradayCambioInDto', 'Required parameter requestParameters.datiIntradayCambioInDto was null or undefined when calling getDatiStoriciCambi.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/finanza/cambi/datistorici`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.DatiIntradayCambioInDtoToJSON(requestParameters.datiIntradayCambioInDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.DatiStoriciDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce la lista dei dati storici, di un cambio, usati per popolare il grafico.
     */
    getDatiStoriciCambi(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getDatiStoriciCambiRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce la lista dei dati storici, di un indice, usati per popolare il grafico.
     */
    getDatiStoriciIndiceRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.datiIntradayIndiceInDto === null || requestParameters.datiIntradayIndiceInDto === undefined) {
                throw new runtime.RequiredError('datiIntradayIndiceInDto', 'Required parameter requestParameters.datiIntradayIndiceInDto was null or undefined when calling getDatiStoriciIndice.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/finanza/indici/datistorici`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.DatiIntradayIndiceInDtoToJSON(requestParameters.datiIntradayIndiceInDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.DatiStoriciDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce la lista dei dati storici, di un indice, usati per popolare il grafico.
     */
    getDatiStoriciIndice(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getDatiStoriciIndiceRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce il dettaglio di una news finanziaria.
     */
    getDettaglioNewsRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idNews === null || requestParameters.idNews === undefined) {
                throw new runtime.RequiredError('idNews', 'Required parameter requestParameters.idNews was null or undefined when calling getDettaglioNews.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/finanza/news/{idNews}`.replace(`{${"idNews"}}`, encodeURIComponent(String(requestParameters.idNews))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ElencoNewsDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce il dettaglio di una news finanziaria.
     */
    getDettaglioNews(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getDettaglioNewsRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce le news finanziarie.
     */
    getElencoNewsRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.offset !== undefined) {
                queryParameters['offset'] = requestParameters.offset;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/finanza/news`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ElencoNewsDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce le news finanziarie.
     */
    getElencoNews(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getElencoNewsRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Download del PDF Glossario
     */
    getGlossarioPdfRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/finanza/glossario/pdf`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.BlobApiResponse(response);
        });
    }
    /**
     * Download del PDF Glossario
     */
    getGlossarioPdf() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getGlossarioPdfRaw();
            return yield response.value();
        });
    }
    /**
     * Restituisce i mercati per i Cambi.
     */
    getMercatiCambiRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/finanza/cambi/mercati`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.MercatiDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce i mercati per i Cambi.
     */
    getMercatiCambi() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getMercatiCambiRaw();
            return yield response.value();
        });
    }
    /**
     * Restituisce i mercati per gli indici.
     */
    getMercatiIndiciRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/finanza/indici/mercati`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.MercatiDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce i mercati per gli indici.
     */
    getMercatiIndici() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getMercatiIndiciRaw();
            return yield response.value();
        });
    }
    /**
     * Restituisce la lista dei mercati per i titoli abilitati per l\'utente.
     */
    getMercatiTitoliRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/finanza/titoli/mercati`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.MercatiDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce la lista dei mercati per i titoli abilitati per l\'utente.
     */
    getMercatiTitoli() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getMercatiTitoliRaw();
            return yield response.value();
        });
    }
    /**
     * Restituisce un\'oggetto contenente il dettaglio per la quotazione di un cambio.
     */
    getQuotazioneCambioDettaglioRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.mercato === null || requestParameters.mercato === undefined) {
                throw new runtime.RequiredError('mercato', 'Required parameter requestParameters.mercato was null or undefined when calling getQuotazioneCambioDettaglio.');
            }
            if (requestParameters.codiceCambio === null || requestParameters.codiceCambio === undefined) {
                throw new runtime.RequiredError('codiceCambio', 'Required parameter requestParameters.codiceCambio was null or undefined when calling getQuotazioneCambioDettaglio.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/finanza/cambio/quotazione/{mercato}/{codiceCambio}`.replace(`{${"mercato"}}`, encodeURIComponent(String(requestParameters.mercato))).replace(`{${"codiceCambio"}}`, encodeURIComponent(String(requestParameters.codiceCambio))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.QuotazioneDettaglioCambioDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce un\'oggetto contenente il dettaglio per la quotazione di un cambio.
     */
    getQuotazioneCambioDettaglio(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getQuotazioneCambioDettaglioRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce un\'oggetto contenente il dettaglio per la quotazione di un indice.
     */
    getQuotazioneIndiceDettaglioRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.mercato === null || requestParameters.mercato === undefined) {
                throw new runtime.RequiredError('mercato', 'Required parameter requestParameters.mercato was null or undefined when calling getQuotazioneIndiceDettaglio.');
            }
            if (requestParameters.codiceIndice === null || requestParameters.codiceIndice === undefined) {
                throw new runtime.RequiredError('codiceIndice', 'Required parameter requestParameters.codiceIndice was null or undefined when calling getQuotazioneIndiceDettaglio.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/finanza/indice/quotazione/{mercato}/{codiceIndice}`.replace(`{${"mercato"}}`, encodeURIComponent(String(requestParameters.mercato))).replace(`{${"codiceIndice"}}`, encodeURIComponent(String(requestParameters.codiceIndice))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.QuotazioneDettaglioIndiceDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce un\'oggetto contenente il dettaglio per la quotazione di un indice.
     */
    getQuotazioneIndiceDettaglio(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getQuotazioneIndiceDettaglioRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce la lista delle quotazioni per i cambi di un mercato.
     */
    getQuotazioniCambiRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.quotazioniFiltriInDto === null || requestParameters.quotazioniFiltriInDto === undefined) {
                throw new runtime.RequiredError('quotazioniFiltriInDto', 'Required parameter requestParameters.quotazioniFiltriInDto was null or undefined when calling getQuotazioniCambi.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/finanza/cambi/quotazioni`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.QuotazioniFiltriInDtoToJSON(requestParameters.quotazioniFiltriInDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.QuotazioniCambiDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce la lista delle quotazioni per i cambi di un mercato.
     */
    getQuotazioniCambi(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getQuotazioniCambiRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce la lista delle quotazioni per gli indici di un mercato.
     */
    getQuotazioniIndiciRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.quotazioniFiltriInDto === null || requestParameters.quotazioniFiltriInDto === undefined) {
                throw new runtime.RequiredError('quotazioniFiltriInDto', 'Required parameter requestParameters.quotazioniFiltriInDto was null or undefined when calling getQuotazioniIndici.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/finanza/indici/quotazioni`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.QuotazioniFiltriInDtoToJSON(requestParameters.quotazioniFiltriInDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.QuotazioniIndiciDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce la lista delle quotazioni per gli indici di un mercato.
     */
    getQuotazioniIndici(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getQuotazioniIndiciRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce un oggetto contenente le informazioni relative alla scheda di un cambio.
     */
    getSchedaCambioRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.mercato === null || requestParameters.mercato === undefined) {
                throw new runtime.RequiredError('mercato', 'Required parameter requestParameters.mercato was null or undefined when calling getSchedaCambio.');
            }
            if (requestParameters.codiceCambio === null || requestParameters.codiceCambio === undefined) {
                throw new runtime.RequiredError('codiceCambio', 'Required parameter requestParameters.codiceCambio was null or undefined when calling getSchedaCambio.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/finanza/cambio/scheda/{mercato}/{codiceCambio}`.replace(`{${"mercato"}}`, encodeURIComponent(String(requestParameters.mercato))).replace(`{${"codiceCambio"}}`, encodeURIComponent(String(requestParameters.codiceCambio))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.SchedaCambioDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce un oggetto contenente le informazioni relative alla scheda di un cambio.
     */
    getSchedaCambio(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getSchedaCambioRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce un oggetto contenente le informazioni relative alla scheda di un indice.
     */
    getSchedaIndiceRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.mercato === null || requestParameters.mercato === undefined) {
                throw new runtime.RequiredError('mercato', 'Required parameter requestParameters.mercato was null or undefined when calling getSchedaIndice.');
            }
            if (requestParameters.codiceIndice === null || requestParameters.codiceIndice === undefined) {
                throw new runtime.RequiredError('codiceIndice', 'Required parameter requestParameters.codiceIndice was null or undefined when calling getSchedaIndice.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/finanza/indice/scheda/{mercato}/{codiceIndice}`.replace(`{${"mercato"}}`, encodeURIComponent(String(requestParameters.mercato))).replace(`{${"codiceIndice"}}`, encodeURIComponent(String(requestParameters.codiceIndice))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.SchedaTitoloTrading2DtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce un oggetto contenente le informazioni relative alla scheda di un indice.
     */
    getSchedaIndice(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getSchedaIndiceRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce un oggetto contenente le informazioni relative alla scheda di un titolo. Prende input il campo codice del titolo da visualizzare, restituito da getQuotazioniTitoli.
     */
    getSchedaTitoloTradingRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.mercato === null || requestParameters.mercato === undefined) {
                throw new runtime.RequiredError('mercato', 'Required parameter requestParameters.mercato was null or undefined when calling getSchedaTitoloTrading.');
            }
            if (requestParameters.codiceTitolo === null || requestParameters.codiceTitolo === undefined) {
                throw new runtime.RequiredError('codiceTitolo', 'Required parameter requestParameters.codiceTitolo was null or undefined when calling getSchedaTitoloTrading.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/finanza/titolo/scheda/{mercato}/{codiceTitolo}`.replace(`{${"mercato"}}`, encodeURIComponent(String(requestParameters.mercato))).replace(`{${"codiceTitolo"}}`, encodeURIComponent(String(requestParameters.codiceTitolo))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.SchedaTitoloTrading2DtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce un oggetto contenente le informazioni relative alla scheda di un titolo. Prende input il campo codice del titolo da visualizzare, restituito da getQuotazioniTitoli.
     */
    getSchedaTitoloTrading(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getSchedaTitoloTradingRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce le quotazioni di una watchlist.
     */
    getWatchlistQuotazioniRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.codiceWatchlist === null || requestParameters.codiceWatchlist === undefined) {
                throw new runtime.RequiredError('codiceWatchlist', 'Required parameter requestParameters.codiceWatchlist was null or undefined when calling getWatchlistQuotazioni.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/finanza/watchlists/{codiceWatchlist}/quotazioni`.replace(`{${"codiceWatchlist"}}`, encodeURIComponent(String(requestParameters.codiceWatchlist))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.WatchlistQuotazioniDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce le quotazioni di una watchlist.
     */
    getWatchlistQuotazioni(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getWatchlistQuotazioniRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Recupera indici borsa
     */
    indiciBorsaRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/finanza/indiciborsa`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.LoadIndiciDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera indici borsa
     */
    indiciBorsa() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.indiciBorsaRaw();
            return yield response.value();
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva
     */
    infoAutenticazioneOrdineRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling infoAutenticazioneOrdine.');
            }
            const queryParameters = {};
            if (requestParameters.chiamataUscente !== undefined) {
                queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
            }
            if (requestParameters.eseguiInit !== undefined) {
                queryParameters['eseguiInit'] = requestParameters.eseguiInit;
            }
            if (requestParameters.offline !== undefined) {
                queryParameters['offline'] = requestParameters.offline;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/finanza/infoautenticazione/{idDispositiva}`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva
     */
    infoAutenticazioneOrdine(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.infoAutenticazioneOrdineRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva della revoca.
     */
    infoAutenticazioneRevocaRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.chiamataUscente !== undefined) {
                queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
            }
            if (requestParameters.eseguiInit !== undefined) {
                queryParameters['eseguiInit'] = requestParameters.eseguiInit;
            }
            if (requestParameters.offline !== undefined) {
                queryParameters['offline'] = requestParameters.offline;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/finanza/ordine/revoca/infoautenticazione`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva della revoca.
     */
    infoAutenticazioneRevoca(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.infoAutenticazioneRevocaRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * check if title is preferred
     */
    isPreferredTitleRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.codiceRapporto === null || requestParameters.codiceRapporto === undefined) {
                throw new runtime.RequiredError('codiceRapporto', 'Required parameter requestParameters.codiceRapporto was null or undefined when calling isPreferredTitle.');
            }
            if (requestParameters.codiceIsin === null || requestParameters.codiceIsin === undefined) {
                throw new runtime.RequiredError('codiceIsin', 'Required parameter requestParameters.codiceIsin was null or undefined when calling isPreferredTitle.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/finanza/titoli/preferito/{codiceRapporto}/{codiceIsin}`.replace(`{${"codiceRapporto"}}`, encodeURIComponent(String(requestParameters.codiceRapporto))).replace(`{${"codiceIsin"}}`, encodeURIComponent(String(requestParameters.codiceIsin))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.TextApiResponse(response);
        });
    }
    /**
     * check if title is preferred
     */
    isPreferredTitle(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.isPreferredTitleRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce la watchlist modificata (E\' possibile modificare solo il nome)
     */
    modificaNomeWatchlistRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.watchlistDto === null || requestParameters.watchlistDto === undefined) {
                throw new runtime.RequiredError('watchlistDto', 'Required parameter requestParameters.watchlistDto was null or undefined when calling modificaNomeWatchlist.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/finanza/watchlist/modifica`,
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.WatchlistDtoToJSON(requestParameters.watchlistDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.WatchlistQuotazioniDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce la watchlist modificata (E\' possibile modificare solo il nome)
     */
    modificaNomeWatchlist(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.modificaNomeWatchlistRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Lettura ordini di titoli in scadenzza
     */
    ordiniInScadenzaRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.ordiniInScadenzaFiltriInDto === null || requestParameters.ordiniInScadenzaFiltriInDto === undefined) {
                throw new runtime.RequiredError('ordiniInScadenzaFiltriInDto', 'Required parameter requestParameters.ordiniInScadenzaFiltriInDto was null or undefined when calling ordiniInScadenza.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/finanza/titoliscadenza/ordini`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.OrdiniInScadenzaFiltriInDtoToJSON(requestParameters.ordiniInScadenzaFiltriInDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.OrdiniScadenzaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Lettura ordini di titoli in scadenzza
     */
    ordiniInScadenza(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.ordiniInScadenzaRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Lettura parametri avanzati per l\'ordine di un titolo
     */
    parametriAvanzatiRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.ordineParametriAvanzatiIsinInDto === null || requestParameters.ordineParametriAvanzatiIsinInDto === undefined) {
                throw new runtime.RequiredError('ordineParametriAvanzatiIsinInDto', 'Required parameter requestParameters.ordineParametriAvanzatiIsinInDto was null or undefined when calling parametriAvanzati.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/finanza/ordine/parametri`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.OrdineParametriAvanzatiIsinInDtoToJSON(requestParameters.ordineParametriAvanzatiIsinInDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.OrdineParametriDtoFromJSON(jsonValue));
        });
    }
    /**
     * Lettura parametri avanzati per l\'ordine di un titolo
     */
    parametriAvanzati(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.parametriAvanzatiRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Lettura parametri per codice funzione QUOTAZIONI
     */
    parametriQuotazioniRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/finanza/parametriquotazioni`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ParametriFinanzaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Lettura parametri per codice funzione QUOTAZIONI
     */
    parametriQuotazioni() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.parametriQuotazioniRaw();
            return yield response.value();
        });
    }
    /**
     * Lettura parametri per codice funzione TITOLISCADENZA
     */
    parametriTitoliScadenzaRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/finanza/parametrititoliscadenza`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ParametriFinanzaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Lettura parametri per codice funzione TITOLISCADENZA
     */
    parametriTitoliScadenza() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.parametriTitoliScadenzaRaw();
            return yield response.value();
        });
    }
    /**
     * Lettura parametri per codice funzione TRADING
     */
    parametriTradingRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/finanza/parametritrading`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ParametriFinanzaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Lettura parametri per codice funzione TRADING
     */
    parametriTrading() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.parametriTradingRaw();
            return yield response.value();
        });
    }
    /**
     * Restituisce la quotazione dettagliata di un titolo per un mercato
     */
    quotazioneDettaglioTitoloRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.mercato === null || requestParameters.mercato === undefined) {
                throw new runtime.RequiredError('mercato', 'Required parameter requestParameters.mercato was null or undefined when calling quotazioneDettaglioTitolo.');
            }
            if (requestParameters.codiceTitolo === null || requestParameters.codiceTitolo === undefined) {
                throw new runtime.RequiredError('codiceTitolo', 'Required parameter requestParameters.codiceTitolo was null or undefined when calling quotazioneDettaglioTitolo.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/finanza/titolo/quotazione/{mercato}/{codiceTitolo}`.replace(`{${"mercato"}}`, encodeURIComponent(String(requestParameters.mercato))).replace(`{${"codiceTitolo"}}`, encodeURIComponent(String(requestParameters.codiceTitolo))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.QuotazioneDettaglioTitoloDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce la quotazione dettagliata di un titolo per un mercato
     */
    quotazioneDettaglioTitolo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.quotazioneDettaglioTitoloRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce la lista delle quotazioni di un mercato per i titoli
     */
    quotazioniTitoliRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.quotazioniFiltriInDto === null || requestParameters.quotazioniFiltriInDto === undefined) {
                throw new runtime.RequiredError('quotazioniFiltriInDto', 'Required parameter requestParameters.quotazioniFiltriInDto was null or undefined when calling quotazioniTitoli.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/finanza/titoli/quotazioni`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.QuotazioniFiltriInDtoToJSON(requestParameters.quotazioniFiltriInDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.QuotazioniTitoliDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce la lista delle quotazioni di un mercato per i titoli
     */
    quotazioniTitoli(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.quotazioniTitoliRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Ricerca di titoli per ISIN e descrizione
     */
    ricercaTitoliRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.ricercaTitoliInDto === null || requestParameters.ricercaTitoliInDto === undefined) {
                throw new runtime.RequiredError('ricercaTitoliInDto', 'Required parameter requestParameters.ricercaTitoliInDto was null or undefined when calling ricercaTitoli.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/finanza/ricercatitoli`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.RicercaTitoliInDtoToJSON(requestParameters.ricercaTitoliInDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.DatiTitoliConMercatoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Ricerca di titoli per ISIN e descrizione
     */
    ricercaTitoli(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.ricercaTitoliRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Aggiungi item watchlist statica
     */
    rimuoviItemWatchlistStaticaRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.watchlistItemInDto === null || requestParameters.watchlistItemInDto === undefined) {
                throw new runtime.RequiredError('watchlistItemInDto', 'Required parameter requestParameters.watchlistItemInDto was null or undefined when calling rimuoviItemWatchlistStatica.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/finanza/watchliststatiche/rimuoviitem`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.WatchlistItemInDtoToJSON(requestParameters.watchlistItemInDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.WatchlistQuotazioniDtoFromJSON(jsonValue));
        });
    }
    /**
     * Aggiungi item watchlist statica
     */
    rimuoviItemWatchlistStatica(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.rimuoviItemWatchlistStaticaRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce la lista dei titoli preferiti
     */
    rimuoviPreferitoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.codiceRapporto === null || requestParameters.codiceRapporto === undefined) {
                throw new runtime.RequiredError('codiceRapporto', 'Required parameter requestParameters.codiceRapporto was null or undefined when calling rimuoviPreferito.');
            }
            if (requestParameters.codiceIsin === null || requestParameters.codiceIsin === undefined) {
                throw new runtime.RequiredError('codiceIsin', 'Required parameter requestParameters.codiceIsin was null or undefined when calling rimuoviPreferito.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/finanza/titoli/preferiti/{codiceRapporto}/{codiceIsin}`.replace(`{${"codiceRapporto"}}`, encodeURIComponent(String(requestParameters.codiceRapporto))).replace(`{${"codiceIsin"}}`, encodeURIComponent(String(requestParameters.codiceIsin))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Restituisce la lista dei titoli preferiti
     */
    rimuoviPreferito(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.rimuoviPreferitoRaw(requestParameters);
        });
    }
    /**
     * Genera file PDF o XLS dei ordini in scadenza
     */
    stampaOrdiniInScadenzaRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.type === null || requestParameters.type === undefined) {
                throw new runtime.RequiredError('type', 'Required parameter requestParameters.type was null or undefined when calling stampaOrdiniInScadenza.');
            }
            if (requestParameters.ordiniInScadenzaFiltriInDto === null || requestParameters.ordiniInScadenzaFiltriInDto === undefined) {
                throw new runtime.RequiredError('ordiniInScadenzaFiltriInDto', 'Required parameter requestParameters.ordiniInScadenzaFiltriInDto was null or undefined when calling stampaOrdiniInScadenza.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/finanza/export/elencoordini/{type}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.OrdiniInScadenzaFiltriInDtoToJSON(requestParameters.ordiniInScadenzaFiltriInDto),
            });
            return new runtime.BlobApiResponse(response);
        });
    }
    /**
     * Genera file PDF o XLS dei ordini in scadenza
     */
    stampaOrdiniInScadenza(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.stampaOrdiniInScadenzaRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Stampa dettaglio Kiid
     */
    stampaPDFKiidRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.isin === null || requestParameters.isin === undefined) {
                throw new runtime.RequiredError('isin', 'Required parameter requestParameters.isin was null or undefined when calling stampaPDFKiid.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/finanza/kiid/{isin}`.replace(`{${"isin"}}`, encodeURIComponent(String(requestParameters.isin))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.BlobApiResponse(response);
        });
    }
    /**
     * Stampa dettaglio Kiid
     */
    stampaPDFKiid(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.stampaPDFKiidRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Genera file PDF o XLS dei titoli in scadenza
     */
    stampaTitoliScadenzaRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.type === null || requestParameters.type === undefined) {
                throw new runtime.RequiredError('type', 'Required parameter requestParameters.type was null or undefined when calling stampaTitoliScadenza.');
            }
            if (requestParameters.titoliInScadenzaFiltriInDto === null || requestParameters.titoliInScadenzaFiltriInDto === undefined) {
                throw new runtime.RequiredError('titoliInScadenzaFiltriInDto', 'Required parameter requestParameters.titoliInScadenzaFiltriInDto was null or undefined when calling stampaTitoliScadenza.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/finanza/export/elenco/{type}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.TitoliInScadenzaFiltriInDtoToJSON(requestParameters.titoliInScadenzaFiltriInDto),
            });
            return new runtime.BlobApiResponse(response);
        });
    }
    /**
     * Genera file PDF o XLS dei titoli in scadenza
     */
    stampaTitoliScadenza(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.stampaTitoliScadenzaRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Lettura titoli in scadenza
     */
    titoliScadenzaRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.titoliInScadenzaFiltriInDto === null || requestParameters.titoliInScadenzaFiltriInDto === undefined) {
                throw new runtime.RequiredError('titoliInScadenzaFiltriInDto', 'Required parameter requestParameters.titoliInScadenzaFiltriInDto was null or undefined when calling titoliScadenza.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/finanza/titoliscadenza/titoli`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.TitoliInScadenzaFiltriInDtoToJSON(requestParameters.titoliInScadenzaFiltriInDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.TitoliScadenzaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Lettura titoli in scadenza
     */
    titoliScadenza(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.titoliScadenzaRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * recupero url di Milano Finanza
     */
    urlMilanoFinanzaRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/finanza/urlmilanofinanza`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.MfUrlResponseFromJSON(jsonValue));
        });
    }
    /**
     * recupero url di Milano Finanza
     */
    urlMilanoFinanza() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.urlMilanoFinanzaRaw();
            return yield response.value();
        });
    }
    /**
     * Recupera le watchlist
     */
    watchlistRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/finanza/watchlist`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.WatchlistDtoFromJSON));
        });
    }
    /**
     * Recupera le watchlist
     */
    watchlist() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.watchlistRaw();
            return yield response.value();
        });
    }
    /**
     * Recupera le watchlist statiche
     */
    watchlistStaticheRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/finanza/watchliststatiche`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.WatchlistDtoFromJSON));
        });
    }
    /**
     * Recupera le watchlist statiche
     */
    watchlistStatiche() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.watchlistStaticheRaw();
            return yield response.value();
        });
    }
}
exports.FinanzaControllerApi = FinanzaControllerApi;
/**
    * @export
    * @enum {string}
    */
var StampaOrdiniInScadenzaTypeEnum;
(function (StampaOrdiniInScadenzaTypeEnum) {
    StampaOrdiniInScadenzaTypeEnum["pdf"] = "pdf";
    StampaOrdiniInScadenzaTypeEnum["excel"] = "excel";
})(StampaOrdiniInScadenzaTypeEnum = exports.StampaOrdiniInScadenzaTypeEnum || (exports.StampaOrdiniInScadenzaTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var StampaTitoliScadenzaTypeEnum;
(function (StampaTitoliScadenzaTypeEnum) {
    StampaTitoliScadenzaTypeEnum["pdf"] = "pdf";
    StampaTitoliScadenzaTypeEnum["excel"] = "excel";
})(StampaTitoliScadenzaTypeEnum = exports.StampaTitoliScadenzaTypeEnum || (exports.StampaTitoliScadenzaTypeEnum = {}));
