"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessaggioAllegatoDtoToJSON = exports.MessaggioAllegatoDtoFromJSONTyped = exports.MessaggioAllegatoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function MessaggioAllegatoDtoFromJSON(json) {
    return MessaggioAllegatoDtoFromJSONTyped(json, false);
}
exports.MessaggioAllegatoDtoFromJSON = MessaggioAllegatoDtoFromJSON;
function MessaggioAllegatoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'contentType': !runtime_1.exists(json, 'contentType') ? undefined : json['contentType'],
        'data': !runtime_1.exists(json, 'data') ? undefined : json['data'],
        'dimensione': !runtime_1.exists(json, 'dimensione') ? undefined : json['dimensione'],
        'height': !runtime_1.exists(json, 'height') ? undefined : json['height'],
        'name': !runtime_1.exists(json, 'name') ? undefined : json['name'],
        'width': !runtime_1.exists(json, 'width') ? undefined : json['width'],
    };
}
exports.MessaggioAllegatoDtoFromJSONTyped = MessaggioAllegatoDtoFromJSONTyped;
function MessaggioAllegatoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'contentType': value.contentType,
        'data': value.data,
        'dimensione': value.dimensione,
        'height': value.height,
        'name': value.name,
        'width': value.width,
    };
}
exports.MessaggioAllegatoDtoToJSON = MessaggioAllegatoDtoToJSON;
