"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportPianoMutuoPrestitoTypeEnum = exports.ExportDettaglioRateTypeEnum = exports.MutuiControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class MutuiControllerApi extends runtime.BaseAPI {
    /**
     * Restituisce una lista di rate di progressivi per rapporto selezionato
     */
    elencoProgressiviMutuiRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.codiceRapporto === null || requestParameters.codiceRapporto === undefined) {
                throw new runtime.RequiredError('codiceRapporto', 'Required parameter requestParameters.codiceRapporto was null or undefined when calling elencoProgressiviMutui.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/mutui/elencoprogressivi/{codiceRapporto}`.replace(`{${"codiceRapporto"}}`, encodeURIComponent(String(requestParameters.codiceRapporto))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     * Restituisce una lista di rate di progressivi per rapporto selezionato
     */
    elencoProgressiviMutui(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.elencoProgressiviMutuiRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Genera file PDF o XLS rate per un mutuo/prestito
     */
    exportDettaglioRateRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.type === null || requestParameters.type === undefined) {
                throw new runtime.RequiredError('type', 'Required parameter requestParameters.type was null or undefined when calling exportDettaglioRate.');
            }
            if (requestParameters.filtroFinanziamentiDto === null || requestParameters.filtroFinanziamentiDto === undefined) {
                throw new runtime.RequiredError('filtroFinanziamentiDto', 'Required parameter requestParameters.filtroFinanziamentiDto was null or undefined when calling exportDettaglioRate.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/mutui/export/rate/{type}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.FiltroFinanziamentiDtoToJSON(requestParameters.filtroFinanziamentiDto),
            });
            return new runtime.BlobApiResponse(response);
        });
    }
    /**
     * Genera file PDF o XLS rate per un mutuo/prestito
     */
    exportDettaglioRate(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.exportDettaglioRateRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Genera file PDF o XLS piano mutuo/piano prestito
     */
    exportPianoMutuoPrestitoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.type === null || requestParameters.type === undefined) {
                throw new runtime.RequiredError('type', 'Required parameter requestParameters.type was null or undefined when calling exportPianoMutuoPrestito.');
            }
            if (requestParameters.filtroFinanziamentiDto === null || requestParameters.filtroFinanziamentiDto === undefined) {
                throw new runtime.RequiredError('filtroFinanziamentiDto', 'Required parameter requestParameters.filtroFinanziamentiDto was null or undefined when calling exportPianoMutuoPrestito.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/mutui/export/piano/{type}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.FiltroFinanziamentiDtoToJSON(requestParameters.filtroFinanziamentiDto),
            });
            return new runtime.BlobApiResponse(response);
        });
    }
    /**
     * Genera file PDF o XLS piano mutuo/piano prestito
     */
    exportPianoMutuoPrestito(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.exportPianoMutuoPrestitoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce i dati per la dasboard
     */
    getDashboardMutuiRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.filtroFinanziamentiDto === null || requestParameters.filtroFinanziamentiDto === undefined) {
                throw new runtime.RequiredError('filtroFinanziamentiDto', 'Required parameter requestParameters.filtroFinanziamentiDto was null or undefined when calling getDashboardMutui.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/mutui/dashboard`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.FiltroFinanziamentiDtoToJSON(requestParameters.filtroFinanziamentiDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.FinanziamentiDashboardDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce i dati per la dasboard
     */
    getDashboardMutui(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getDashboardMutuiRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce piano ammortamento per un mutuo
     */
    getPianoAmmortamentoMutuiRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.filtroFinanziamentiDto === null || requestParameters.filtroFinanziamentiDto === undefined) {
                throw new runtime.RequiredError('filtroFinanziamentiDto', 'Required parameter requestParameters.filtroFinanziamentiDto was null or undefined when calling getPianoAmmortamentoMutui.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/mutui/ammortamento`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.FiltroFinanziamentiDtoToJSON(requestParameters.filtroFinanziamentiDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.RateFinanziamentoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce piano ammortamento per un mutuo
     */
    getPianoAmmortamentoMutui(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getPianoAmmortamentoMutuiRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce la lista delle rate in mora per un mutuo
     */
    getRateMoraMutuiRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.filtroFinanziamentiDto === null || requestParameters.filtroFinanziamentiDto === undefined) {
                throw new runtime.RequiredError('filtroFinanziamentiDto', 'Required parameter requestParameters.filtroFinanziamentiDto was null or undefined when calling getRateMoraMutui.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/mutui/rate/mora`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.FiltroFinanziamentiDtoToJSON(requestParameters.filtroFinanziamentiDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.RateFinanziamentoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce la lista delle rate in mora per un mutuo
     */
    getRateMoraMutui(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getRateMoraMutuiRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce la lista delle rate pagate per un mutuo
     */
    getRatePagateMutuiRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.filtroFinanziamentiDto === null || requestParameters.filtroFinanziamentiDto === undefined) {
                throw new runtime.RequiredError('filtroFinanziamentiDto', 'Required parameter requestParameters.filtroFinanziamentiDto was null or undefined when calling getRatePagateMutui.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/mutui/rate/pagate`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.FiltroFinanziamentiDtoToJSON(requestParameters.filtroFinanziamentiDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.RateFinanziamentoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce la lista delle rate pagate per un mutuo
     */
    getRatePagateMutui(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getRatePagateMutuiRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce la lista delle rate residue per un mutuo
     */
    getRateResidueMutuiRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.filtroFinanziamentiDto === null || requestParameters.filtroFinanziamentiDto === undefined) {
                throw new runtime.RequiredError('filtroFinanziamentiDto', 'Required parameter requestParameters.filtroFinanziamentiDto was null or undefined when calling getRateResidueMutui.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/mutui/rate/residue`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.FiltroFinanziamentiDtoToJSON(requestParameters.filtroFinanziamentiDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.RateFinanziamentoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce la lista delle rate residue per un mutuo
     */
    getRateResidueMutui(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getRateResidueMutuiRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Richiesta parametri iniziali per il servizio di mutui
     */
    parametriMutuiRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/mutui/parametri`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ParametriMutuiDtoFromJSON(jsonValue));
        });
    }
    /**
     * Richiesta parametri iniziali per il servizio di mutui
     */
    parametriMutui() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.parametriMutuiRaw();
            return yield response.value();
        });
    }
}
exports.MutuiControllerApi = MutuiControllerApi;
/**
    * @export
    * @enum {string}
    */
var ExportDettaglioRateTypeEnum;
(function (ExportDettaglioRateTypeEnum) {
    ExportDettaglioRateTypeEnum["pdf"] = "pdf";
    ExportDettaglioRateTypeEnum["excel"] = "excel";
})(ExportDettaglioRateTypeEnum = exports.ExportDettaglioRateTypeEnum || (exports.ExportDettaglioRateTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ExportPianoMutuoPrestitoTypeEnum;
(function (ExportPianoMutuoPrestitoTypeEnum) {
    ExportPianoMutuoPrestitoTypeEnum["pdf"] = "pdf";
    ExportPianoMutuoPrestitoTypeEnum["excel"] = "excel";
})(ExportPianoMutuoPrestitoTypeEnum = exports.ExportPianoMutuoPrestitoTypeEnum || (exports.ExportPianoMutuoPrestitoTypeEnum = {}));
