"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBreakdownsInDtoToJSON = exports.GetBreakdownsInDtoFromJSONTyped = exports.GetBreakdownsInDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function GetBreakdownsInDtoFromJSON(json) {
    return GetBreakdownsInDtoFromJSONTyped(json, false);
}
exports.GetBreakdownsInDtoFromJSON = GetBreakdownsInDtoFromJSON;
function GetBreakdownsInDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'customerId': json['customerId'],
        'breakdownCodes': !runtime_1.exists(json, 'breakdownCodes') ? undefined : json['breakdownCodes'],
        'accounts': !runtime_1.exists(json, 'accounts') ? undefined : json['accounts'],
        'includeCoi': !runtime_1.exists(json, 'includeCoi') ? undefined : json['includeCoi'],
    };
}
exports.GetBreakdownsInDtoFromJSONTyped = GetBreakdownsInDtoFromJSONTyped;
function GetBreakdownsInDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'customerId': value.customerId,
        'breakdownCodes': value.breakdownCodes,
        'accounts': value.accounts,
        'includeCoi': value.includeCoi,
    };
}
exports.GetBreakdownsInDtoToJSON = GetBreakdownsInDtoToJSON;
