"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModelloF24ControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class ModelloF24ControllerApi extends runtime.BaseAPI {
    /**
     * Salva una nuova bozza di modello F24
     */
    addBozzaModelloF24Raw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.modelloF24Dto === null || requestParameters.modelloF24Dto === undefined) {
                throw new runtime.RequiredError('modelloF24Dto', 'Required parameter requestParameters.modelloF24Dto was null or undefined when calling addBozzaModelloF24.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/f24/salvabozza`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ModelloF24DtoToJSON(requestParameters.modelloF24Dto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ModelloF24DtoFromJSON(jsonValue));
        });
    }
    /**
     * Salva una nuova bozza di modello F24
     */
    addBozzaModelloF24(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.addBozzaModelloF24Raw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva
     */
    authenticationInformationModelloF24Raw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.codiceFunzioneF24 === null || requestParameters.codiceFunzioneF24 === undefined) {
                throw new runtime.RequiredError('codiceFunzioneF24', 'Required parameter requestParameters.codiceFunzioneF24 was null or undefined when calling authenticationInformationModelloF24.');
            }
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling authenticationInformationModelloF24.');
            }
            const queryParameters = {};
            if (requestParameters.chiamataUscente !== undefined) {
                queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
            }
            if (requestParameters.eseguiInit !== undefined) {
                queryParameters['eseguiInit'] = requestParameters.eseguiInit;
            }
            if (requestParameters.offline !== undefined) {
                queryParameters['offline'] = requestParameters.offline;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/f24/{codiceFunzioneF24}/infoautenticazione/{idDispositiva}`.replace(`{${"codiceFunzioneF24"}}`, encodeURIComponent(String(requestParameters.codiceFunzioneF24))).replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva
     */
    authenticationInformationModelloF24(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.authenticationInformationModelloF24Raw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Autorizzazione di una disposizione di f24
     */
    authorizeModelloF24Raw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.codiceFunzioneF24 === null || requestParameters.codiceFunzioneF24 === undefined) {
                throw new runtime.RequiredError('codiceFunzioneF24', 'Required parameter requestParameters.codiceFunzioneF24 was null or undefined when calling authorizeModelloF24.');
            }
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling authorizeModelloF24.');
            }
            if (requestParameters.credenzialeDispositivaDto === null || requestParameters.credenzialeDispositivaDto === undefined) {
                throw new runtime.RequiredError('credenzialeDispositivaDto', 'Required parameter requestParameters.credenzialeDispositivaDto was null or undefined when calling authorizeModelloF24.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/f24/{codiceFunzioneF24}/autorizza/{idDispositiva}`.replace(`{${"codiceFunzioneF24"}}`, encodeURIComponent(String(requestParameters.codiceFunzioneF24))).replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CredenzialeDispositivaDtoToJSON(requestParameters.credenzialeDispositivaDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.EsitoAutorizzazioneDisposizioneModelloF24DtoFromJSON(jsonValue));
        });
    }
    /**
     * Autorizzazione di una disposizione di f24
     */
    authorizeModelloF24(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.authorizeModelloF24Raw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Esegue il controllo di validità di un nuovo F24 in base ai valori specificati nell\'oggetto di richiesta
     */
    checkModelloF24Raw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.modelloF24Dto === null || requestParameters.modelloF24Dto === undefined) {
                throw new runtime.RequiredError('modelloF24Dto', 'Required parameter requestParameters.modelloF24Dto was null or undefined when calling checkModelloF24.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/f24/controllo`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ModelloF24DtoToJSON(requestParameters.modelloF24Dto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ModelloF24DtoFromJSON(jsonValue));
        });
    }
    /**
     * Esegue il controllo di validità di un nuovo F24 in base ai valori specificati nell\'oggetto di richiesta
     */
    checkModelloF24(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.checkModelloF24Raw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce i codici comuni data la provincia
     */
    codiciComuniRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.provincia === null || requestParameters.provincia === undefined) {
                throw new runtime.RequiredError('provincia', 'Required parameter requestParameters.provincia was null or undefined when calling codiciComuni.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/f24/codicicomuni/{provincia}`.replace(`{${"provincia"}}`, encodeURIComponent(String(requestParameters.provincia))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.F24LookupDtoFromJSON));
        });
    }
    /**
     * Restituisce i codici comuni data la provincia
     */
    codiciComuni(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.codiciComuniRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce i codici uffici data la provincia
     */
    codiciUfficiRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.provincia === null || requestParameters.provincia === undefined) {
                throw new runtime.RequiredError('provincia', 'Required parameter requestParameters.provincia was null or undefined when calling codiciUffici.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/f24/codiciUffici/{provincia}`.replace(`{${"provincia"}}`, encodeURIComponent(String(requestParameters.provincia))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.F24LookupDtoFromJSON));
        });
    }
    /**
     * Restituisce i codici uffici data la provincia
     */
    codiciUffici(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.codiciUfficiRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Elimina una bozza F24
     */
    deleteBozzaModelloF24Raw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling deleteBozzaModelloF24.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/f24/bozze/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Elimina una bozza F24
     */
    deleteBozzaModelloF24(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.deleteBozzaModelloF24Raw(requestParameters);
        });
    }
    /**
     * Lettura di una singola bozza F24
     */
    getBozzaModelloF24Raw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getBozzaModelloF24.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/f24/bozze/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ModelloF24DtoFromJSON(jsonValue));
        });
    }
    /**
     * Lettura di una singola bozza F24
     */
    getBozzaModelloF24(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getBozzaModelloF24Raw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Lettura bozze F24
     */
    getBozzeModelloF24Raw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.bozzeInDto === null || requestParameters.bozzeInDto === undefined) {
                throw new runtime.RequiredError('bozzeInDto', 'Required parameter requestParameters.bozzeInDto was null or undefined when calling getBozzeModelloF24.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/f24/bozze`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.BozzeInDtoToJSON(requestParameters.bozzeInDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.ModelloF24DtoFromJSON));
        });
    }
    /**
     * Lettura bozze F24
     */
    getBozzeModelloF24(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getBozzeModelloF24Raw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva per la revoca
     */
    informazioniAutenticazioneRevocaModelloF24Raw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling informazioniAutenticazioneRevocaModelloF24.');
            }
            const queryParameters = {};
            if (requestParameters.chiamataUscente !== undefined) {
                queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
            }
            if (requestParameters.eseguiInit !== undefined) {
                queryParameters['eseguiInit'] = requestParameters.eseguiInit;
            }
            if (requestParameters.offline !== undefined) {
                queryParameters['offline'] = requestParameters.offline;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/f24/infoautenticazione/{idDispositiva}/revoca`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva per la revoca
     */
    informazioniAutenticazioneRevocaModelloF24(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.informazioniAutenticazioneRevocaModelloF24Raw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Autorizzazione di una disposizione di revoca f24
     */
    operazioneAutenticataModelloF24Raw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.modelloF24RevocaRequestIn === null || requestParameters.modelloF24RevocaRequestIn === undefined) {
                throw new runtime.RequiredError('modelloF24RevocaRequestIn', 'Required parameter requestParameters.modelloF24RevocaRequestIn was null or undefined when calling operazioneAutenticataModelloF24.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/f24/autorizza/revoca`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ModelloF24RevocaRequestInToJSON(requestParameters.modelloF24RevocaRequestIn),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.EsitoAutorizzazioneDisposizioneModelloF24DtoFromJSON(jsonValue));
        });
    }
    /**
     * Autorizzazione di una disposizione di revoca f24
     */
    operazioneAutenticataModelloF24(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.operazioneAutenticataModelloF24Raw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Lettura parametri per F24
     */
    parametersModelloF24Raw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/f24/parametri`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.F24ParametriDtoFromJSON(jsonValue));
        });
    }
    /**
     * Lettura parametri per F24
     */
    parametersModelloF24() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.parametersModelloF24Raw();
            return yield response.value();
        });
    }
    /**
     * Stampa dettaglio pdf per F24
     */
    stampaPDFRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.dettaglioRequest === null || requestParameters.dettaglioRequest === undefined) {
                throw new runtime.RequiredError('dettaglioRequest', 'Required parameter requestParameters.dettaglioRequest was null or undefined when calling stampaPDF.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/f24/dettaglio/export/pdf`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.DettaglioRequestToJSON(requestParameters.dettaglioRequest),
            });
            return new runtime.BlobApiResponse(response);
        });
    }
    /**
     * Stampa dettaglio pdf per F24
     */
    stampaPDF(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.stampaPDFRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Stampa quietanza pdf per F24
     */
    stampaQuietanzaRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.dettaglioRequest === null || requestParameters.dettaglioRequest === undefined) {
                throw new runtime.RequiredError('dettaglioRequest', 'Required parameter requestParameters.dettaglioRequest was null or undefined when calling stampaQuietanza.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/f24/quietanza/export/pdf`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.DettaglioRequestToJSON(requestParameters.dettaglioRequest),
            });
            return new runtime.BlobApiResponse(response);
        });
    }
    /**
     * Stampa quietanza pdf per F24
     */
    stampaQuietanza(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.stampaQuietanzaRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Modifica una bozza F24
     */
    updateBozzaModelloF24Raw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling updateBozzaModelloF24.');
            }
            if (requestParameters.modelloF24Dto === null || requestParameters.modelloF24Dto === undefined) {
                throw new runtime.RequiredError('modelloF24Dto', 'Required parameter requestParameters.modelloF24Dto was null or undefined when calling updateBozzaModelloF24.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/f24/bozze/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ModelloF24DtoToJSON(requestParameters.modelloF24Dto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ModelloF24DtoFromJSON(jsonValue));
        });
    }
    /**
     * Modifica una bozza F24
     */
    updateBozzaModelloF24(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateBozzaModelloF24Raw(requestParameters);
            return yield response.value();
        });
    }
}
exports.ModelloF24ControllerApi = ModelloF24ControllerApi;
