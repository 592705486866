"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiltroTagliRicaricaDtoToJSON = exports.FiltroTagliRicaricaDtoFromJSONTyped = exports.FiltroTagliRicaricaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function FiltroTagliRicaricaDtoFromJSON(json) {
    return FiltroTagliRicaricaDtoFromJSONTyped(json, false);
}
exports.FiltroTagliRicaricaDtoFromJSON = FiltroTagliRicaricaDtoFromJSON;
function FiltroTagliRicaricaDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codiceRapporto': !runtime_1.exists(json, 'codiceRapporto') ? undefined : json['codiceRapporto'],
        'codiceSia': !runtime_1.exists(json, 'codiceSia') ? undefined : json['codiceSia'],
        'numeroCellulare': !runtime_1.exists(json, 'numeroCellulare') ? undefined : json['numeroCellulare'],
    };
}
exports.FiltroTagliRicaricaDtoFromJSONTyped = FiltroTagliRicaricaDtoFromJSONTyped;
function FiltroTagliRicaricaDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codiceRapporto': value.codiceRapporto,
        'codiceSia': value.codiceSia,
        'numeroCellulare': value.numeroCellulare,
    };
}
exports.FiltroTagliRicaricaDtoToJSON = FiltroTagliRicaricaDtoToJSON;
