"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BonificoMyBankControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class BonificoMyBankControllerApi extends runtime.BaseAPI {
    /**
     * Recupera l\'indirizzo di redirect per tornare indietro da mybank
     */
    annullaMyBankRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.token === null || requestParameters.token === undefined) {
                throw new runtime.RequiredError('token', 'Required parameter requestParameters.token was null or undefined when calling annullaMyBank.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/bonificomybank/annulla/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.AnnulloMyBankDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera l\'indirizzo di redirect per tornare indietro da mybank
     */
    annullaMyBank(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.annullaMyBankRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Autorizzazione di una disposizione di bonifico mybank
     */
    autorizzaBonificoMyBankRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling autorizzaBonificoMyBank.');
            }
            if (requestParameters.credenzialeDispositivaDto === null || requestParameters.credenzialeDispositivaDto === undefined) {
                throw new runtime.RequiredError('credenzialeDispositivaDto', 'Required parameter requestParameters.credenzialeDispositivaDto was null or undefined when calling autorizzaBonificoMyBank.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/bonificomybank/autorizza/{idDispositiva}`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CredenzialeDispositivaDtoToJSON(requestParameters.credenzialeDispositivaDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.EsitoAutorizzazioneDisposizioneBonificoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Autorizzazione di una disposizione di bonifico mybank
     */
    autorizzaBonificoMyBank(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.autorizzaBonificoMyBankRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Esegue il controllo di un bonifico mybank
     */
    controllaBonificoMyBankRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.controlloMyBankDto === null || requestParameters.controlloMyBankDto === undefined) {
                throw new runtime.RequiredError('controlloMyBankDto', 'Required parameter requestParameters.controlloMyBankDto was null or undefined when calling controllaBonificoMyBank.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/bonificomybank/controllo`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ControlloMyBankDtoToJSON(requestParameters.controlloMyBankDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.BonificoSepaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Esegue il controllo di un bonifico mybank
     */
    controllaBonificoMyBank(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.controllaBonificoMyBankRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva
     */
    informazioniAutenticazioneBonificoMyBankRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling informazioniAutenticazioneBonificoMyBank.');
            }
            const queryParameters = {};
            if (requestParameters.chiamataUscente !== undefined) {
                queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
            }
            if (requestParameters.eseguiInit !== undefined) {
                queryParameters['eseguiInit'] = requestParameters.eseguiInit;
            }
            if (requestParameters.offline !== undefined) {
                queryParameters['offline'] = requestParameters.offline;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/bonificomybank/infoautenticazione/{idDispositiva}`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva
     */
    informazioniAutenticazioneBonificoMyBank(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.informazioniAutenticazioneBonificoMyBankRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Lettura parametri bonifico mybank
     */
    parametriBonificoMyBankRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.token === null || requestParameters.token === undefined) {
                throw new runtime.RequiredError('token', 'Required parameter requestParameters.token was null or undefined when calling parametriBonificoMyBank.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/bonificomybank/parametri/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.InfoMyBankDtoFromJSON(jsonValue));
        });
    }
    /**
     * Lettura parametri bonifico mybank
     */
    parametriBonificoMyBank(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.parametriBonificoMyBankRaw(requestParameters);
            return yield response.value();
        });
    }
}
exports.BonificoMyBankControllerApi = BonificoMyBankControllerApi;
