"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.esitoFondiPaperless = exports.corrispondenzeFondiPaperless = exports.condizioniFondiPaperless = exports.formFondiPaperless = void 0;
const reselect_1 = require("reselect");
const fondiPaperlessReducer = (state) => state;
exports.formFondiPaperless = (0, reselect_1.createSelector)(fondiPaperlessReducer, (state) => {
    return state.form;
});
exports.condizioniFondiPaperless = (0, reselect_1.createSelector)(fondiPaperlessReducer, (state) => {
    return state.condizioni;
});
exports.corrispondenzeFondiPaperless = (0, reselect_1.createSelector)(fondiPaperlessReducer, (state) => {
    return state.corrispondenze;
});
exports.esitoFondiPaperless = (0, reselect_1.createSelector)(fondiPaperlessReducer, (state) => {
    return state.esito;
});
