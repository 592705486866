import ButtonText2 from "component/buttonText2/buttonText2.component";
import { currencyFormat, defaultCurrency } from "helpers/utilities";
import React from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import dettaglioIcon from "../../../assets/icons/azioni/mini/dettaglio-active-mini.svg";
import { ChartData } from "../patrimonio.view";

interface Props {
  isDettaglio?: boolean
  data: ChartData[]
  total: number
  onSelectPatrimonio?: (tipologia: string) => void
  onBack?: () => void
  patrimonioTitle: string
  patrimonioTotale: string
  className?: string
  chartSize?: string
}

const PatrimonioWidget: React.FC<Props> = (props: Props) => {

  const { t } = useTranslation();

  const {
    isDettaglio,
    data,
    total,
    onSelectPatrimonio,
    onBack,
    patrimonioTitle,
    patrimonioTotale,
    className,
    chartSize
  } = props;

  const option: any = {
    chart: {
      events: {
        dataPointSelection: (event: any, chartContext: any, config: any) => {
          setTimeout(() => {
            onSelectPatrimonio && onSelectPatrimonio(config.w.config.data[config.dataPointIndex]?.tipologia);
          });
        }
      }
    },
    states: {
      active: {
        filter: {
          type: 'none',
          value: 0
        }
      },
      hover: {
        filter: {
          type: 'none',
          value: 0
        }
      }
    },
    plotOptions: {
      pie: {
        size: 160,
        expandOnClick: false
      }
    },
    data: data,
    dataLabels: { enabled: false },
    labels: data?.map((d: any) => d.testo),
    legend: { show: false },
    colors: data?.map((d: any) => d.color),
    fill: {
      colors: data?.map((d: any) => d.color),
    },
    tooltip: {
      enabled: true,
      fillSeriesColor: false,
      y: {
        formatter: function (val: any) {
          return val + "%";
        },
        title: {
          formatter: function (seriesName: any) {
            return seriesName;
          },
        },
      }
    }
  };

  const series = data?.map((d: any) => parseFloat(((100 * d.importo) / total || 0).toFixed(2)));

  return (
    <div className={`widget-patrimonio ${className || ""}`}>

      <p className="txtTitle-widget-patrimonio">
        {patrimonioTitle}
      </p>

      <div className="chartView-widget-patrimonio_container">
        <div className="chartView-widget-patrimonio">
          {data && data.length > 0 &&
            <Chart
              options={option}
              series={series}
              type="donut"
              width={chartSize || "160"}
              height={chartSize || "160"}
            />
          }
          <div className="titleChartView-widget-patrimonio">
            <p className="txtTitleChart-widget-patrimonio">{patrimonioTotale}</p>
            <p className="txtAmountChart-widget-patrimonio is-saldo">
              {currencyFormat(total)}
              <span className="txtAmountChart-widget-patrimonio_value">
                {` ${defaultCurrency}`}
              </span>
            </p>
          </div>
        </div>
        <div className="widget-patrimonio-button">
          {isDettaglio && (
            <ButtonText2
              descrizione={t("patrimonio.button.turnBack")}
              onClick={onBack}
              imageOnLeft={true}
              img="navigazione_indietro"
            />
          )}
        </div>
      </div>

      {data?.map((d: any, i: number) => {
        return d.showValues
          ? <div key={`item-${i}`} className="legendView1-widget-patrimonio">

            <div className="legendView1col1-widget-patrimonio">
              <div
                style={{
                  backgroundColor: d.color,
                  height: 20,
                  width: 20,
                  marginRight: 12
                }}
              ></div>
              <p className="txtTitleLegend-widget-patrimonio">
                {((100 * d.importo) / total || 0).toFixed(2)}% {d.testo}
              </p>
            </div>

            <div className="legendView1col2-widget-patrimonio">
              <p className="txtAmountLegend-widget-patrimonio is-saldo">
                {currencyFormat(d.importo)}<span className="patrimonio-totaleInfo_netto_currency">{` ${defaultCurrency}`}</span>
              </p>
            </div>

            {!isDettaglio && onSelectPatrimonio && (
              <div className="widget-patrimonio_details_icon">
                <img
                  src={dettaglioIcon}
                  alt="dettaglio"
                  onClick={() => onSelectPatrimonio(d.tipologia)}
                  className="pointer"
                />
              </div>
            )}
          </div>
          : null;
      })}

    </div>
  );
};

export default PatrimonioWidget;
