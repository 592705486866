"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LibrettoDiRisparmioDtoToJSON = exports.LibrettoDiRisparmioDtoFromJSONTyped = exports.LibrettoDiRisparmioDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function LibrettoDiRisparmioDtoFromJSON(json) {
    return LibrettoDiRisparmioDtoFromJSONTyped(json, false);
}
exports.LibrettoDiRisparmioDtoFromJSON = LibrettoDiRisparmioDtoFromJSON;
function LibrettoDiRisparmioDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'movimenti': !runtime_1.exists(json, 'movimenti') ? undefined : (json['movimenti'].map(_1.DettaglioLibrettoDiRisparmioDtoFromJSON)),
        'saldoContabile': !runtime_1.exists(json, 'saldoContabile') ? undefined : json['saldoContabile'],
        'saldoIniziale': !runtime_1.exists(json, 'saldoIniziale') ? undefined : json['saldoIniziale'],
        'saldoFinale': !runtime_1.exists(json, 'saldoFinale') ? undefined : json['saldoFinale'],
        'dataIniziale': !runtime_1.exists(json, 'dataIniziale') ? undefined : (new Date(json['dataIniziale'])),
        'dataFinale': !runtime_1.exists(json, 'dataFinale') ? undefined : (new Date(json['dataFinale'])),
        'totaleMovimenti': !runtime_1.exists(json, 'totaleMovimenti') ? undefined : json['totaleMovimenti'],
    };
}
exports.LibrettoDiRisparmioDtoFromJSONTyped = LibrettoDiRisparmioDtoFromJSONTyped;
function LibrettoDiRisparmioDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'movimenti': value.movimenti === undefined ? undefined : (value.movimenti.map(_1.DettaglioLibrettoDiRisparmioDtoToJSON)),
        'saldoContabile': value.saldoContabile,
        'saldoIniziale': value.saldoIniziale,
        'saldoFinale': value.saldoFinale,
        'dataIniziale': value.dataIniziale === undefined ? undefined : (value.dataIniziale.toISOString()),
        'dataFinale': value.dataFinale === undefined ? undefined : (value.dataFinale.toISOString()),
        'totaleMovimenti': value.totaleMovimenti,
    };
}
exports.LibrettoDiRisparmioDtoToJSON = LibrettoDiRisparmioDtoToJSON;
