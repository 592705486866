"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetDepositoRisparmi = exports.esportaElencoDepositoRisparmiEXCEL = exports.esportaElencoDepositoRisparmiPDF = exports.esportaDettaglioDepositoRisparmioPDF = exports.setDepositoRisparmiAccorditionOpened = exports.setDepositoRisparmiEntrateUscite = exports.setDepositoRisparmiUscite = exports.setDepositoRisparmiEntrate = exports.setDepositoRisparmiPeriodo = exports.setDepositoRisparmiImporto = exports.setDepositoRisparmiDataA = exports.setDepositoRisparmiDataDa = exports.setDepositoRisparmiDescrizione = exports.resetFiltroDepositoRisparmi = exports.setDepositoRisparmiRapportoSelezionato = exports.getElencoDepositoRisparmi = exports.getElencoDepositoRisparmientiFiltrati = exports.getParametriDepositoRisparmi = exports.getParametriDepositoRisparmiPerCodiceRapporto = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
const depositoRisparmi_selectors_1 = require("./depositoRisparmi.selectors");
/* get parametri deposito risparmi - START */
function getParametriDepositoRisparmiStart() {
    return {
        type: __1.EReduxActionTypes.DEPOSITO_RISPARMI_GET_PARAMETRI_START
    };
}
function getParametriDepositoRisparmiSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.DEPOSITO_RISPARMI_GET_PARAMETRI_SUCCESS,
        payload
    };
}
function generalError(value) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value));
    };
}
const getParametriDepositoRisparmiPerCodiceRapporto = () => async (dispatch, getState, extraArguments) => {
    var _a, _b;
    const form = (0, depositoRisparmi_selectors_1.formDepositoRisparmi)(getState().depositoRisparmi);
    form && (0, exports.getParametriDepositoRisparmi)((_b = (_a = form.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.id)(dispatch, getState, extraArguments);
};
exports.getParametriDepositoRisparmiPerCodiceRapporto = getParametriDepositoRisparmiPerCodiceRapporto;
const getParametriDepositoRisparmi = (codiceRapporto) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getParametriDepositoRisparmiStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/parametri_movimenti.json')).then(parametri => {
            dispatch(getParametriDepositoRisparmiSuccess((0, api_rest_1.ParametriDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const request = {
            codiceRapporto: codiceRapporto
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.ElencoDepositoRisparmiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametriLibrettiRisparmio(request).then((response) => {
                dispatch(getParametriDepositoRisparmiSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getParametriDepositoRisparmi = getParametriDepositoRisparmi;
/* get parametri deposito risparmi - FINE */
/* get elenco deposito risparmi - START */
function getElencoDepositoRisparmiStart() {
    return {
        type: __1.EReduxActionTypes.DEPOSITO_RISPARMI_GET_ELENCO_START
    };
}
function getElencoDepositoRisparmiSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.DEPOSITO_RISPARMI_GET_ELENCO_SUCCESS,
        payload
    };
}
const getElencoDepositoRisparmientiFiltrati = () => async (dispatch, getState, extraArguments) => {
    const datiForm = (0, depositoRisparmi_selectors_1.formElencoDepositoRisparmiAsFiltro)(getState().depositoRisparmi);
    datiForm && (0, exports.getElencoDepositoRisparmi)(datiForm)(dispatch, getState, extraArguments);
};
exports.getElencoDepositoRisparmientiFiltrati = getElencoDepositoRisparmientiFiltrati;
const getElencoDepositoRisparmi = (filtro) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getElencoDepositoRisparmiStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/lista_movimenti.json')).then(parametri => {
            dispatch(getElencoDepositoRisparmiSuccess((0, api_rest_1.LibrettoDiRisparmioDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const request = {
            filtroElencoDepositiRisparmioDto: filtro
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.ElencoDepositoRisparmiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getElencoDepositiRisparmio(request).then((response) => {
                dispatch(getElencoDepositoRisparmiSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getElencoDepositoRisparmi = getElencoDepositoRisparmi;
/* get elenco deposito risparmi - FINE */
/* set deposito risparmi form values - START */
function setDepositoRisparmiRapportoSelezionato(rapportoSelezionato) {
    return {
        type: __1.EReduxActionTypes.DEPOSITO_RISPARMI_SET_RAPPORTO_SELEZIONATO,
        payload: rapportoSelezionato
    };
}
exports.setDepositoRisparmiRapportoSelezionato = setDepositoRisparmiRapportoSelezionato;
function resetFiltroDepositoRisparmi() {
    return {
        type: __1.EReduxActionTypes.DEPOSITO_RISPARMI_RESET_FILTRO
    };
}
exports.resetFiltroDepositoRisparmi = resetFiltroDepositoRisparmi;
function setDepositoRisparmiDescrizione(value) {
    return {
        type: __1.EReduxActionTypes.DEPOSITO_RISPARMI_SET_DESCRIZIONE,
        payload: value
    };
}
exports.setDepositoRisparmiDescrizione = setDepositoRisparmiDescrizione;
function setDepositoRisparmiDataDa(value) {
    return {
        type: __1.EReduxActionTypes.DEPOSITO_RISPARMI_SET_DATA_DA,
        payload: value
    };
}
exports.setDepositoRisparmiDataDa = setDepositoRisparmiDataDa;
function setDepositoRisparmiDataA(value) {
    return {
        type: __1.EReduxActionTypes.DEPOSITO_RISPARMI_SET_DATA_A,
        payload: value
    };
}
exports.setDepositoRisparmiDataA = setDepositoRisparmiDataA;
function setDepositoRisparmiImporto(value) {
    return {
        type: __1.EReduxActionTypes.DEPOSITO_RISPARMI_SET_IMPORTO,
        payload: value
    };
}
exports.setDepositoRisparmiImporto = setDepositoRisparmiImporto;
function setDepositoRisparmiPeriodo(value) {
    return {
        type: __1.EReduxActionTypes.DEPOSITO_RISPARMI_SET_PERIODO,
        payload: value
    };
}
exports.setDepositoRisparmiPeriodo = setDepositoRisparmiPeriodo;
function setDepositoRisparmiEntrate(value) {
    return {
        type: __1.EReduxActionTypes.DEPOSITO_RISPARMI_SET_ENTRATE,
        payload: value
    };
}
exports.setDepositoRisparmiEntrate = setDepositoRisparmiEntrate;
function setDepositoRisparmiUscite(value) {
    return {
        type: __1.EReduxActionTypes.DEPOSITO_RISPARMI_SET_USCITE,
        payload: value
    };
}
exports.setDepositoRisparmiUscite = setDepositoRisparmiUscite;
function setDepositoRisparmiEntrateUscite(value) {
    return {
        type: __1.EReduxActionTypes.DEPOSITO_RISPARMI_SET_ENTRATE_USCITE,
        payload: value
    };
}
exports.setDepositoRisparmiEntrateUscite = setDepositoRisparmiEntrateUscite;
function setDepositoRisparmiAccorditionOpened(value) {
    return {
        type: __1.EReduxActionTypes.DEPOSITO_RISPARMI_SET_ACCORDITION_FILTRI_OPENED,
        payload: value
    };
}
exports.setDepositoRisparmiAccorditionOpened = setDepositoRisparmiAccorditionOpened;
/* set deposito risparmi form values - START */
/* esporta PDF dettaglio deposito risparmi - START */
function esportaDettaglioDepositoRisparmioPDFStart() {
    return {
        type: __1.EReduxActionTypes.DEPOSITO_RISPARMI_ESPORTA_DETTAGLIO_PDF_START
    };
}
function esportaDettaglioDepositoRisparmioPDFSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.DEPOSITO_RISPARMI_ESPORTA_DETTAGLIO_PDF_SUCCESS,
        payload
    };
}
const esportaDettaglioDepositoRisparmioPDF = (codiceRapporto, datiForm, share, title, message) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(esportaDettaglioDepositoRisparmioPDFStart());
    if (extraArguments.mock) {
        /* TODO: vedere nella parte mockata cosa restituire
        import('../../mock/lista_movimenti.json').then(parametri => {
          dispatch(esportaDettaglioDepositoRisparmioPDFSuccess(ListaMovimentiDtoFromJSON(parametri)));
        });
        */
    }
    else {
        const requestIn = datiForm || (0, depositoRisparmi_selectors_1.formElencoDepositoRisparmiAsFiltro)(getState().depositoRisparmi);
        if (requestIn) {
            const esportaDettaglioMovimentoPDFRequest = {
                codiceRapporto,
                dettaglioLibrettoDiRisparmioDto: requestIn
            };
            extraArguments.getItem('jwt').then((jwt) => {
                new api_rest_1.ElencoDepositoRisparmiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf')).exportDettaglioDepositiRisparmio(esportaDettaglioMovimentoPDFRequest).then(async (response) => {
                    if (extraArguments.platform === 'desktop') {
                        (0, helpers_1.hadlePdfResponse)(response, (url) => dispatch((0, general_actions_1.popUpBlockedPdf)(url)));
                    }
                    if (extraArguments.platform === 'mobile') {
                        (0, helpers_1.download_sharePDF)(extraArguments, 'dettaglio_movimento.pdf', response, share, title, message);
                    }
                    dispatch(esportaDettaglioDepositoRisparmioPDFSuccess(response));
                }).catch(async (error) => {
                    try {
                        dispatch(generalError(await (0, helpers_1.formatError)(error)));
                    }
                    catch (e) {
                        console.log(e);
                    }
                }).finally(() => {
                    dispatch((0, general_actions_1.setLoader)(false));
                });
            });
        }
    }
};
exports.esportaDettaglioDepositoRisparmioPDF = esportaDettaglioDepositoRisparmioPDF;
/* esporta PDF dettaglio deposito risparmi - START */
/* esporta PDF lista deposito risparmi - START */
function esportaElencoDepositoRisparmiPDFStart() {
    return {
        type: __1.EReduxActionTypes.DEPOSITO_RISPARMI_ESPORTA_PDF_START
    };
}
function esportaElencoDepositoRisparmiPDFSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.DEPOSITO_RISPARMI_ESPORTA_PDF_SUCCESS,
        payload
    };
}
const esportaElencoDepositoRisparmiPDF = (share, title, message) => async (dispatch, getState, extraArguments) => {
    if ((0, depositoRisparmi_selectors_1.formDepositoRisparmiValid)(getState().depositoRisparmi)) {
        dispatch(esportaElencoDepositoRisparmiPDFStart());
        dispatch((0, general_actions_1.setLoader)(true));
        if (extraArguments.mock) {
        }
        else {
            const esportaMovimentiPDFRequest = {
                type: api_rest_1.ExportElencoDepositoRisparmioTypeEnum.pdf,
                filtroElencoDepositiRisparmioDto: (0, depositoRisparmi_selectors_1.formElencoDepositoRisparmiAsFiltro)(getState().depositoRisparmi)
            };
            extraArguments.getItem('jwt').then((jwt) => {
                new api_rest_1.ElencoDepositoRisparmiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf')).exportElencoDepositoRisparmio(esportaMovimentiPDFRequest).then((response) => {
                    if (extraArguments.platform === 'desktop') {
                        (0, helpers_1.hadlePdfResponse)(response, (url) => dispatch((0, general_actions_1.popUpBlockedPdf)(url)));
                    }
                    if (extraArguments.platform === 'mobile') {
                        (0, helpers_1.download_sharePDF)(extraArguments, 'movimenti.pdf', response, share, title, message);
                    }
                    dispatch(esportaElencoDepositoRisparmiPDFSuccess(response));
                }).catch(async (error) => {
                    dispatch(generalError(await (0, helpers_1.formatError)(error)));
                }).finally(() => {
                    dispatch((0, general_actions_1.setLoader)(false));
                });
            });
        }
    }
};
exports.esportaElencoDepositoRisparmiPDF = esportaElencoDepositoRisparmiPDF;
/* esporta PDF lista deposito risparmi - FINE */
/* esporta EXCEL lista deposito risparmi - START */
function esportaElencoDepositoRisparmiEXCELStart() {
    return {
        type: __1.EReduxActionTypes.DEPOSITO_RISPARMI_ESPORTA_EXCEL_START
    };
}
function esportaElencoDepositoRisparmiEXCELSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.DEPOSITO_RISPARMI_ESPORTA_EXCEL_SUCCESS,
        payload
    };
}
const esportaElencoDepositoRisparmiEXCEL = (share) => async (dispatch, getState, extraArguments) => {
    if ((0, depositoRisparmi_selectors_1.formDepositoRisparmiValid)(getState().depositoRisparmi)) {
        dispatch((0, general_actions_1.setLoader)(true));
        dispatch(esportaElencoDepositoRisparmiEXCELStart());
        if (extraArguments.mock) {
        }
        else {
            const esportaMovimentiEXCELRequest = {
                type: api_rest_1.ExportElencoDepositoRisparmioTypeEnum.excel,
                filtroElencoDepositiRisparmioDto: (0, depositoRisparmi_selectors_1.formElencoDepositoRisparmiAsFiltro)(getState().depositoRisparmi)
            };
            extraArguments.getItem('jwt').then((jwt) => {
                new api_rest_1.ElencoDepositoRisparmiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/vnd.ms-excel')).exportElencoDepositoRisparmio(esportaMovimentiEXCELRequest).then((response) => {
                    if (extraArguments.platform === 'desktop') {
                        (0, helpers_1.hadleExcelResponse)(response, 'movimenti.xlsx');
                    }
                    if (extraArguments.platform === 'mobile') {
                        (0, helpers_1.download_shareXLS)(extraArguments, 'ElencoMovimenti.xls', response, share);
                    }
                    dispatch(esportaElencoDepositoRisparmiEXCELSuccess(response));
                }).catch(async (error) => {
                    dispatch(generalError(await (0, helpers_1.formatError)(error)));
                }).finally(() => {
                    dispatch((0, general_actions_1.setLoader)(false));
                });
            });
        }
    }
};
exports.esportaElencoDepositoRisparmiEXCEL = esportaElencoDepositoRisparmiEXCEL;
/* esporta EXCEL lista deposito risparmi - FINE */
/* Reset deposito risparmi */
function resetDepositoRisparmi() {
    return {
        type: __1.EReduxActionTypes.DEPOSITO_RISPARMI_RESET
    };
}
exports.resetDepositoRisparmi = resetDepositoRisparmi;
