"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TabellaDettaglioCostiDtoToJSON = exports.TabellaDettaglioCostiDtoFromJSONTyped = exports.TabellaDettaglioCostiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function TabellaDettaglioCostiDtoFromJSON(json) {
    return TabellaDettaglioCostiDtoFromJSONTyped(json, false);
}
exports.TabellaDettaglioCostiDtoFromJSON = TabellaDettaglioCostiDtoFromJSON;
function TabellaDettaglioCostiDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'dettaglioCosti': !runtime_1.exists(json, 'dettaglioCosti') ? undefined : json['dettaglioCosti'],
    };
}
exports.TabellaDettaglioCostiDtoFromJSONTyped = TabellaDettaglioCostiDtoFromJSONTyped;
function TabellaDettaglioCostiDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'dettaglioCosti': value.dettaglioCosti,
    };
}
exports.TabellaDettaglioCostiDtoToJSON = TabellaDettaglioCostiDtoToJSON;
