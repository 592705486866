"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RangeTassiDtoToJSON = exports.RangeTassiDtoFromJSONTyped = exports.RangeTassiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function RangeTassiDtoFromJSON(json) {
    return RangeTassiDtoFromJSONTyped(json, false);
}
exports.RangeTassiDtoFromJSON = RangeTassiDtoFromJSON;
function RangeTassiDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'tasso1': !runtime_1.exists(json, 'tasso1') ? undefined : json['tasso1'],
        'tasso2': !runtime_1.exists(json, 'tasso2') ? undefined : json['tasso2'],
        'tasso3': !runtime_1.exists(json, 'tasso3') ? undefined : json['tasso3'],
        'tasso4': !runtime_1.exists(json, 'tasso4') ? undefined : json['tasso4'],
        'tasso5': !runtime_1.exists(json, 'tasso5') ? undefined : json['tasso5'],
        'tasso6': !runtime_1.exists(json, 'tasso6') ? undefined : json['tasso6'],
    };
}
exports.RangeTassiDtoFromJSONTyped = RangeTassiDtoFromJSONTyped;
function RangeTassiDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'tasso1': value.tasso1,
        'tasso2': value.tasso2,
        'tasso3': value.tasso3,
        'tasso4': value.tasso4,
        'tasso5': value.tasso5,
        'tasso6': value.tasso6,
    };
}
exports.RangeTassiDtoToJSON = RangeTassiDtoToJSON;
