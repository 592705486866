"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotaStatusDtoToJSON = exports.NotaStatusDtoFromJSONTyped = exports.NotaStatusDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function NotaStatusDtoFromJSON(json) {
    return NotaStatusDtoFromJSONTyped(json, false);
}
exports.NotaStatusDtoFromJSON = NotaStatusDtoFromJSON;
function NotaStatusDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codiceLingua': !runtime_1.exists(json, 'codiceLingua') ? undefined : json['codiceLingua'],
        'codiceMoneta': !runtime_1.exists(json, 'codiceMoneta') ? undefined : json['codiceMoneta'],
        'indicativoFornitore': !runtime_1.exists(json, 'indicativoFornitore') ? undefined : json['indicativoFornitore'],
    };
}
exports.NotaStatusDtoFromJSONTyped = NotaStatusDtoFromJSONTyped;
function NotaStatusDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codiceLingua': value.codiceLingua,
        'codiceMoneta': value.codiceMoneta,
        'indicativoFornitore': value.indicativoFornitore,
    };
}
exports.NotaStatusDtoToJSON = NotaStatusDtoToJSON;
