"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuditingControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class AuditingControllerApi extends runtime.BaseAPI {
    /**
     * Salva un log applicativo per un azione di front end
     */
    logginOperationRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.operationLogDto === null || requestParameters.operationLogDto === undefined) {
                throw new runtime.RequiredError('operationLogDto', 'Required parameter requestParameters.operationLogDto was null or undefined when calling logginOperation.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/auditing/log`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.OperationLogDtoToJSON(requestParameters.operationLogDto),
            });
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Salva un log applicativo per un azione di front end
     */
    logginOperation(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.logginOperationRaw(requestParameters);
        });
    }
    /**
     * Restituisce lista valori intervalli con cui filtrare ultimi accessi
     */
    parametriAuditingRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/auditing/parametri`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ParametriAuditingDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce lista valori intervalli con cui filtrare ultimi accessi
     */
    parametriAuditing() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.parametriAuditingRaw();
            return yield response.value();
        });
    }
    /**
     * Restituisce lista ultimi accessi dell\'utente
     */
    recuperaUltimoAccessoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.ultimoAccessoInDto === null || requestParameters.ultimoAccessoInDto === undefined) {
                throw new runtime.RequiredError('ultimoAccessoInDto', 'Required parameter requestParameters.ultimoAccessoInDto was null or undefined when calling recuperaUltimoAccesso.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/auditing/ultimiaccessi`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.UltimoAccessoInDtoToJSON(requestParameters.ultimoAccessoInDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.UltimiAccessiDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce lista ultimi accessi dell\'utente
     */
    recuperaUltimoAccesso(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.recuperaUltimoAccessoRaw(requestParameters);
            return yield response.value();
        });
    }
}
exports.AuditingControllerApi = AuditingControllerApi;
