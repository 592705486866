"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataValutaBOEDtoToJSON = exports.DataValutaBOEDtoFromJSONTyped = exports.DataValutaBOEDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function DataValutaBOEDtoFromJSON(json) {
    return DataValutaBOEDtoFromJSONTyped(json, false);
}
exports.DataValutaBOEDtoFromJSON = DataValutaBOEDtoFromJSON;
function DataValutaBOEDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'descrizione': !runtime_1.exists(json, 'descrizione') ? undefined : json['descrizione'],
        'valuta': !runtime_1.exists(json, 'valuta') ? undefined : (new Date(json['valuta'])),
    };
}
exports.DataValutaBOEDtoFromJSONTyped = DataValutaBOEDtoFromJSONTyped;
function DataValutaBOEDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'descrizione': value.descrizione,
        'valuta': value.valuta === undefined ? undefined : (value.valuta.toISOString()),
    };
}
exports.DataValutaBOEDtoToJSON = DataValutaBOEDtoToJSON;
