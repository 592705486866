"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionOutputDtoToJSON = exports.TransactionOutputDtoFromJSONTyped = exports.TransactionOutputDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function TransactionOutputDtoFromJSON(json) {
    return TransactionOutputDtoFromJSONTyped(json, false);
}
exports.TransactionOutputDtoFromJSON = TransactionOutputDtoFromJSON;
function TransactionOutputDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'transactionId': !runtime_1.exists(json, 'transactionId') ? undefined : json['transactionId'],
        'callbackUrl': !runtime_1.exists(json, 'callbackUrl') ? undefined : json['callbackUrl'],
    };
}
exports.TransactionOutputDtoFromJSONTyped = TransactionOutputDtoFromJSONTyped;
function TransactionOutputDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'transactionId': value.transactionId,
        'callbackUrl': value.callbackUrl,
    };
}
exports.TransactionOutputDtoToJSON = TransactionOutputDtoToJSON;
