"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormRevocaBollettinoFrecciaFromJSON = exports.FormBollettinoFrecciaFromJSONTyped = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const helpers_1 = require("../helpers");
function FormBollettinoFrecciaFromJSONTyped(json) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codiceSia': !(0, helpers_1.exists)(json, 'codiceSia') ? undefined : json.codiceSia,
        'codiceSiaDirty': !(0, helpers_1.exists)(json, 'codiceSiaDirty') ? false : json.codiceSiaDirty,
        'codiceSiaError': !(0, helpers_1.exists)(json, 'codiceSiaError') ? 'invalid.required' : json.codiceSiaError,
        'riferimentoCreditore': !(0, helpers_1.exists)(json, 'riferimentoCreditore') ? undefined : json.riferimentoCreditore,
        'riferimentoCreditoreDirty': !(0, helpers_1.exists)(json, 'riferimentoCreditoreDirty') ? false : json.riferimentoCreditoreDirty,
        'riferimentoCreditoreError': !(0, helpers_1.exists)(json, 'riferimentoCreditoreError') ? 'invalid.required' : json.riferimentoCreditoreError,
        'ibanCreditore': !(0, helpers_1.exists)(json, 'ibanCreditore') ? undefined : json.ibanCreditore,
        'ibanCreditoreDirty': !(0, helpers_1.exists)(json, 'ibanCreditoreDirty') ? false : json.ibanCreditoreDirty,
        'ibanCreditoreError': !(0, helpers_1.exists)(json, 'ibanCreditoreError') ? 'invalid.required' : json.ibanCreditoreError,
        'importo': !(0, helpers_1.exists)(json, 'importo') ? undefined : json.importo,
        'importoDirty': !(0, helpers_1.exists)(json, 'importoDirty') ? false : json.importoDirty,
        'importoError': !(0, helpers_1.exists)(json, 'importoError') ? 'invalid.required' : json.importoError,
        'dataScadenza': !(0, helpers_1.exists)(json, 'dataScadenza') ? new Date() : json.dataScadenza,
        'dataScadenzaDirty': !(0, helpers_1.exists)(json, 'dataScadenzaDirty') ? false : json.dataScadenzaDirty,
        'dataScadenzaError': !(0, helpers_1.exists)(json, 'dataScadenzaError') ? '' : json.dataScadenzaError,
        'note': !(0, helpers_1.exists)(json, 'note') ? undefined : json.note,
        'noteDirty': !(0, helpers_1.exists)(json, 'noteDirty') ? false : json.noteDirty,
        'noteError': !(0, helpers_1.exists)(json, 'noteError') ? '' : json.noteError,
        'cinImporto': !(0, helpers_1.exists)(json, 'cinImporto') ? undefined : json.cinImporto,
        'cinImportoDirty': !(0, helpers_1.exists)(json, 'cinImportoDirty') ? false : json.cinImportoDirty,
        'cinImportoError': !(0, helpers_1.exists)(json, 'cinImportoError') ? 'invalid.required' : json.cinImportoError,
        'cinIntermedio': !(0, helpers_1.exists)(json, 'cinIntermedio') ? undefined : json.cinIntermedio,
        'cinIntermedioDirty': !(0, helpers_1.exists)(json, 'cinIntermedioDirty') ? false : json.cinIntermedioDirty,
        'cinIntermedioError': !(0, helpers_1.exists)(json, 'cinIntermedioError') ? 'invalid.required' : json.cinIntermedioError,
        'cinComplesso': !(0, helpers_1.exists)(json, 'cinComplesso') ? undefined : json.cinComplesso,
        'cinComplessoDirty': !(0, helpers_1.exists)(json, 'cinComplessoDirty') ? false : json.cinComplessoDirty,
        'cinComplessoError': !(0, helpers_1.exists)(json, 'cinComplessoError') ? 'invalid.required' : json.cinComplessoError,
        'carattereEsenzione': !(0, helpers_1.exists)(json, 'carattereEsenzione') ? undefined : json.carattereEsenzione,
        'carattereEsenzioneDirty': !(0, helpers_1.exists)(json, 'carattereEsenzioneDirty') ? false : json.carattereEsenzioneDirty,
        'carattereEsenzioneError': !(0, helpers_1.exists)(json, 'carattereEsenzioneError') ? 'invalid.required' : json.carattereEsenzioneError,
        'step': !(0, helpers_1.exists)(json, 'step') ? 0 : json.step,
        'rapportoSelezionato': !(0, helpers_1.exists)(json, 'rapportoSelezionato') ? (0, api_rest_1.RapportoCompletoDtoFromJSON)({}) : json.rapportoSelezionato,
        'formSubmitted': !(0, helpers_1.exists)(json, 'formSubmitted') ? false : json.formSubmitted,
        'ripeti': !(0, helpers_1.exists)(json, 'ripeti') ? false : json.ripeti,
        'bozzaSalvata': !(0, helpers_1.exists)(json, 'bozzaSalvata') ? false : json.bozzaSalvata,
        'idOneClickCollegata': !(0, helpers_1.exists)(json, 'idOneClickCollegata') ? undefined : json.idOneClickCollegata
    };
}
exports.FormBollettinoFrecciaFromJSONTyped = FormBollettinoFrecciaFromJSONTyped;
function FormRevocaBollettinoFrecciaFromJSON(json) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'step': !(0, helpers_1.exists)(json, 'step') ? 0 : json.step,
        'provenienza': !(0, helpers_1.exists)(json, 'step') ? undefined : json.step
    };
}
exports.FormRevocaBollettinoFrecciaFromJSON = FormRevocaBollettinoFrecciaFromJSON;
