"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogTimeDepositDtoToJSON = exports.LogTimeDepositDtoFromJSONTyped = exports.LogTimeDepositDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function LogTimeDepositDtoFromJSON(json) {
    return LogTimeDepositDtoFromJSONTyped(json, false);
}
exports.LogTimeDepositDtoFromJSON = LogTimeDepositDtoFromJSON;
function LogTimeDepositDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'numero': !runtime_1.exists(json, 'numero') ? undefined : json['numero'],
        'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
        'importo': !runtime_1.exists(json, 'importo') ? undefined : json['importo'],
        'dataDecorrenza': !runtime_1.exists(json, 'dataDecorrenza') ? undefined : (new Date(json['dataDecorrenza'])),
    };
}
exports.LogTimeDepositDtoFromJSONTyped = LogTimeDepositDtoFromJSONTyped;
function LogTimeDepositDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'numero': value.numero,
        'stato': value.stato,
        'importo': value.importo,
        'dataDecorrenza': value.dataDecorrenza === undefined ? undefined : (value.dataDecorrenza.toISOString()),
    };
}
exports.LogTimeDepositDtoToJSON = LogTimeDepositDtoToJSON;
