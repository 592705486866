"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContattoMezzoDtoToJSON = exports.ContattoMezzoDtoFromJSONTyped = exports.ContattoMezzoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function ContattoMezzoDtoFromJSON(json) {
    return ContattoMezzoDtoFromJSONTyped(json, false);
}
exports.ContattoMezzoDtoFromJSON = ContattoMezzoDtoFromJSON;
function ContattoMezzoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'seq': !runtime_1.exists(json, 'seq') ? undefined : json['seq'],
        'targa': !runtime_1.exists(json, 'targa') ? undefined : json['targa'],
        'tipoMezzo': !runtime_1.exists(json, 'tipoMezzo') ? undefined : json['tipoMezzo'],
        'provinciaMezzo': !runtime_1.exists(json, 'provinciaMezzo') ? undefined : json['provinciaMezzo'],
        'etichetta': !runtime_1.exists(json, 'etichetta') ? undefined : json['etichetta'],
        'isDefault': !runtime_1.exists(json, 'isDefault') ? undefined : json['isDefault'],
        'dispensaCredenziale': !runtime_1.exists(json, 'dispensaCredenziale') ? undefined : json['dispensaCredenziale'],
    };
}
exports.ContattoMezzoDtoFromJSONTyped = ContattoMezzoDtoFromJSONTyped;
function ContattoMezzoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'seq': value.seq,
        'targa': value.targa,
        'tipoMezzo': value.tipoMezzo,
        'provinciaMezzo': value.provinciaMezzo,
        'etichetta': value.etichetta,
        'isDefault': value.isDefault,
        'dispensaCredenziale': value.dispensaCredenziale,
    };
}
exports.ContattoMezzoDtoToJSON = ContattoMezzoDtoToJSON;
