"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SendRequestToJSON = exports.SendRequestFromJSONTyped = exports.SendRequestFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function SendRequestFromJSON(json) {
    return SendRequestFromJSONTyped(json, false);
}
exports.SendRequestFromJSON = SendRequestFromJSON;
function SendRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'message': _1.PNSMessageBodyFromJSON(json['message']),
        'template': !runtime_1.exists(json, 'template') ? undefined : json['template'],
        'storeMessage': !runtime_1.exists(json, 'storeMessage') ? undefined : json['storeMessage'],
    };
}
exports.SendRequestFromJSONTyped = SendRequestFromJSONTyped;
function SendRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'message': _1.PNSMessageBodyToJSON(value.message),
        'template': value.template,
        'storeMessage': value.storeMessage,
    };
}
exports.SendRequestToJSON = SendRequestToJSON;
