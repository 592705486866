"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EReduxCambiActionTypes = exports.TipoGraficoCambi = void 0;
var TipoGraficoCambi;
(function (TipoGraficoCambi) {
    TipoGraficoCambi[TipoGraficoCambi["Settimana1"] = 0] = "Settimana1";
    TipoGraficoCambi[TipoGraficoCambi["Mese1"] = 1] = "Mese1";
    TipoGraficoCambi[TipoGraficoCambi["Anno1"] = 2] = "Anno1";
})(TipoGraficoCambi = exports.TipoGraficoCambi || (exports.TipoGraficoCambi = {}));
;
var EReduxCambiActionTypes;
(function (EReduxCambiActionTypes) {
    EReduxCambiActionTypes["CAMBI_GET_MERCATI_START"] = "CAMBI_GET_MERCATI_START";
    EReduxCambiActionTypes["CAMBI_GET_MERCATI_SUCCESS"] = "CAMBI_GET_MERCATI_SUCCESS";
    EReduxCambiActionTypes["CAMBI_GET_TITOLI_START"] = "CAMBI_GET_TITOLI_START";
    EReduxCambiActionTypes["CAMBI_GET_TITOLI_SUCCESS"] = "CAMBI_GET_TITOLI_SUCCESS";
    EReduxCambiActionTypes["SET_CAMBI_MERCATO"] = "SET_CAMBI_MERCATO";
    EReduxCambiActionTypes["SET_CAMBI_DENOMINAZIONE"] = "SET_CAMBI_DENOMINAZIONE";
    EReduxCambiActionTypes["CAMBI_RESET"] = "CAMBI_RESET";
    EReduxCambiActionTypes["CAMBI_GET_DETTAGLIO_CAMBIO_START"] = "CAMBI_GET_DETTAGLIO_CAMBIO_START";
    EReduxCambiActionTypes["CAMBI_GET_DETTAGLIO_CAMBIO_SUCCESS"] = "CAMBI_GET_DETTAGLIO_CAMBIO_SUCCESS";
    EReduxCambiActionTypes["CAMBI_SET_CAMBIO_SELEZIONATO"] = "CAMBI_SET_CAMBIO_SELEZIONATO";
    EReduxCambiActionTypes["CAMBI_GET_DATI_INTRADAY_CAMBIO_START"] = "CAMBI_GET_DATI_INTRADAY_CAMBIO_START";
    EReduxCambiActionTypes["CAMBI_GET_DATI_INTRADAY_CAMBIO_SUCCESS"] = "CAMBI_GET_DATI_INTRADAY_CAMBIO_SUCCESS";
    EReduxCambiActionTypes["CAMBI_GET_DATI_STORICI_CAMBIO_START"] = "CAMBI_GET_DATI_STORICI_CAMBIO_START";
    EReduxCambiActionTypes["CAMBI_GET_DATI_STORICI_CAMBIO_SUCCESS"] = "CAMBI_GET_DATI_STORICI_CAMBIO_SUCCESS";
    EReduxCambiActionTypes["CAMBI_GET_SCHEDA_CAMBIO_START"] = "CAMBI_GET_SCHEDA_CAMBIO_START";
    EReduxCambiActionTypes["CAMBI_GET_SCHEDA_CAMBIO_SUCCESS"] = "CAMBI_GET_SCHEDA_CAMBIO_SUCCESS";
    EReduxCambiActionTypes["CAMBI_SET_OFFSET"] = "CAMBI_SET_OFFSET";
    EReduxCambiActionTypes["CAMBI_SET_TIPO_GRAFICO"] = "CAMBI_SET_TIPO_GRAFICO";
    EReduxCambiActionTypes["CAMBI_RESET_FORM"] = "CAMBI_RESET_FORM";
    EReduxCambiActionTypes["CAMBI_AGGIORNA_GRAFICO"] = "CAMBI_AGGIORNA_GRAFICO";
    EReduxCambiActionTypes["CAMBI_RESET_DETTAGLIO"] = "CAMBI_RESET_DETTAGLIO";
})(EReduxCambiActionTypes = exports.EReduxCambiActionTypes || (exports.EReduxCambiActionTypes = {}));
