"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PosizioneAssicurazioniDtoToJSON = exports.PosizioneAssicurazioniDtoFromJSONTyped = exports.PosizioneAssicurazioniDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function PosizioneAssicurazioniDtoFromJSON(json) {
    return PosizioneAssicurazioniDtoFromJSONTyped(json, false);
}
exports.PosizioneAssicurazioniDtoFromJSON = PosizioneAssicurazioniDtoFromJSON;
function PosizioneAssicurazioniDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'divisa': !runtime_1.exists(json, 'divisa') ? undefined : json['divisa'],
        'capitaleAssicurato': !runtime_1.exists(json, 'capitaleAssicurato') ? undefined : json['capitaleAssicurato'],
        'controvalore': !runtime_1.exists(json, 'controvalore') ? undefined : json['controvalore'],
        'assicurazioniContoList': !runtime_1.exists(json, 'assicurazioniContoList') ? undefined : (json['assicurazioniContoList'].map(_1.AssicurazioniContoDtoFromJSON)),
        'assicurazioniContoListVisibile': !runtime_1.exists(json, 'assicurazioniContoListVisibile') ? undefined : json['assicurazioniContoListVisibile'],
        'totaleControvalore': !runtime_1.exists(json, 'totaleControvalore') ? undefined : json['totaleControvalore'],
    };
}
exports.PosizioneAssicurazioniDtoFromJSONTyped = PosizioneAssicurazioniDtoFromJSONTyped;
function PosizioneAssicurazioniDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'divisa': value.divisa,
        'capitaleAssicurato': value.capitaleAssicurato,
        'controvalore': value.controvalore,
        'assicurazioniContoList': value.assicurazioniContoList === undefined ? undefined : (value.assicurazioniContoList.map(_1.AssicurazioniContoDtoToJSON)),
        'assicurazioniContoListVisibile': value.assicurazioniContoListVisibile,
        'totaleControvalore': value.totaleControvalore,
    };
}
exports.PosizioneAssicurazioniDtoToJSON = PosizioneAssicurazioniDtoToJSON;
