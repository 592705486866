"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pfmReducer = void 0;
const __1 = require("..");
const api_rest_1 = require("@sparkasse/api-rest");
const initialState = {
    widgetPatrimonio: (0, api_rest_1.WidgetBeniDebitiDtoFromJSON)({}),
    widgetBilancioSintetico: (0, api_rest_1.BilancioSinteticoDtoFromJSON)({}),
    categorie: [(0, api_rest_1.CategoryDtoFromJSON)({})]
};
const pfmReducer = (state = initialState, action) => {
    switch (action.type) {
        case __1.EReduxActionTypes.PFM_GET_WIDGET_PATRIMONIO_SUCCESS:
            return {
                ...state,
                widgetPatrimonio: action.payload
            };
        case __1.EReduxActionTypes.PFM_GET_WIDGET_BILANCIO_SINTETICO_SUCCESS:
            return {
                ...state,
                widgetBilancioSintetico: action.payload
            };
        case __1.EReduxActionTypes.PFM_GET_CATEGORIE_SUCCESS:
            return {
                ...state,
                categorie: action.payload
            };
        case __1.EReduxActionTypes.RESET_ALL: // action invocata dalla logout
            return {
                ...initialState
            };
        default:
            return state;
    }
};
exports.pfmReducer = pfmReducer;
exports.default = exports.pfmReducer;
