"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ElencoMovimentiPdfTypeEnum = exports.MovimentiControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class MovimentiControllerApi extends runtime.BaseAPI {
    /**
     * Export in pdf del dettaglio del movimento in base ai criteri inviati per il rapporto indicato
     */
    dettaglioMovimentiPdfRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.exportMovimentiInDto === null || requestParameters.exportMovimentiInDto === undefined) {
                throw new runtime.RequiredError('exportMovimentiInDto', 'Required parameter requestParameters.exportMovimentiInDto was null or undefined when calling dettaglioMovimentiPdf.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/movimenti/export/pdf`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ExportMovimentiInDtoToJSON(requestParameters.exportMovimentiInDto),
            });
            return new runtime.BlobApiResponse(response);
        });
    }
    /**
     * Export in pdf del dettaglio del movimento in base ai criteri inviati per il rapporto indicato
     */
    dettaglioMovimentiPdf(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.dettaglioMovimentiPdfRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Export in pdf o excel dei movimenti in base ai criteri inviati per il rapporto indicato
     */
    elencoMovimentiPdfRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.type === null || requestParameters.type === undefined) {
                throw new runtime.RequiredError('type', 'Required parameter requestParameters.type was null or undefined when calling elencoMovimentiPdf.');
            }
            if (requestParameters.criteriRicercaMovimentiRequestIn === null || requestParameters.criteriRicercaMovimentiRequestIn === undefined) {
                throw new runtime.RequiredError('criteriRicercaMovimentiRequestIn', 'Required parameter requestParameters.criteriRicercaMovimentiRequestIn was null or undefined when calling elencoMovimentiPdf.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/movimenti/elenco/export/{type}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CriteriRicercaMovimentiRequestInToJSON(requestParameters.criteriRicercaMovimentiRequestIn),
            });
            return new runtime.BlobApiResponse(response);
        });
    }
    /**
     * Export in pdf o excel dei movimenti in base ai criteri inviati per il rapporto indicato
     */
    elencoMovimentiPdf(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.elencoMovimentiPdfRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Recupera i movimenti in base ai criteri inviati per il rapporto indicato
     */
    getListaMovimentiRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.criteriRicercaMovimentiRequestIn === null || requestParameters.criteriRicercaMovimentiRequestIn === undefined) {
                throw new runtime.RequiredError('criteriRicercaMovimentiRequestIn', 'Required parameter requestParameters.criteriRicercaMovimentiRequestIn was null or undefined when calling getListaMovimenti.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/movimenti/elenco`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CriteriRicercaMovimentiRequestInToJSON(requestParameters.criteriRicercaMovimentiRequestIn),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ListaMovimentiDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera i movimenti in base ai criteri inviati per il rapporto indicato
     */
    getListaMovimenti(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getListaMovimentiRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Recupera i primi 5 movimenti tra tutti i rapporti, per la homepage
     */
    getListaMovimentiHomeRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.psd2in !== undefined) {
                queryParameters['psd2in'] = requestParameters.psd2in;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/movimenti/home`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ListaMovimentiDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera i primi 5 movimenti tra tutti i rapporti, per la homepage
     */
    getListaMovimentiHome(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getListaMovimentiHomeRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Lettura parametri per i movimenti
     */
    parametriMovimentiRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.psd2in !== undefined) {
                queryParameters['psd2in'] = requestParameters.psd2in;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/movimenti/parametri`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ParametriMovimentiDtoFromJSON(jsonValue));
        });
    }
    /**
     * Lettura parametri per i movimenti
     */
    parametriMovimenti(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.parametriMovimentiRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Lettura parametri per i movimenti di Carta&Conto
     */
    parametriMovimentiCartaContoRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/movimenti/parametri/cartaconto`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ParametriMovimentiDtoFromJSON(jsonValue));
        });
    }
    /**
     * Lettura parametri per i movimenti di Carta&Conto
     */
    parametriMovimentiCartaConto() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.parametriMovimentiCartaContoRaw();
            return yield response.value();
        });
    }
    /**
     * Lettura parametri per i movimenti conto deposito
     */
    parametriMovimentiContoDepositoRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/movimenti/parametri/contodeposito`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ParametriMovimentiDtoFromJSON(jsonValue));
        });
    }
    /**
     * Lettura parametri per i movimenti conto deposito
     */
    parametriMovimentiContoDeposito() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.parametriMovimentiContoDepositoRaw();
            return yield response.value();
        });
    }
}
exports.MovimentiControllerApi = MovimentiControllerApi;
/**
    * @export
    * @enum {string}
    */
var ElencoMovimentiPdfTypeEnum;
(function (ElencoMovimentiPdfTypeEnum) {
    ElencoMovimentiPdfTypeEnum["pdf"] = "pdf";
    ElencoMovimentiPdfTypeEnum["excel"] = "excel";
})(ElencoMovimentiPdfTypeEnum = exports.ElencoMovimentiPdfTypeEnum || (exports.ElencoMovimentiPdfTypeEnum = {}));
