"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.nexiDebitReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const nexiDebit_types_1 = require("./nexiDebit.types");
const moment = require("moment");
const initialState = {
    parametri: undefined,
    movimenti: undefined,
    convenzioneRapporto: undefined,
    form: (0, nexiDebit_types_1.FormNexiDebitDtoFromJSON)({}),
    startPinViewResponse: undefined,
    getPinViewResponse: undefined,
    startPanViewResponse: undefined,
    getPanViewResponse: undefined,
    copyPanViewResponse: undefined,
    parametriAcquisto: undefined,
    userAcquisto: undefined,
    formAcquisto: {
        taeList: [],
        tipoCarta: { value: '', dirty: false, error: 'invalid.required' },
        codiceRapporto: { value: undefined, dirty: false, error: 'invalid.required' },
        professione: { value: '', dirty: false, error: 'invalid.required' },
        tae: { value: '', dirty: false, error: 'invalid.required' },
        provenienzaFondi: { value: [], dirty: false, error: 'invalid.required' },
        personaPoliticamenteEsposta: { value: undefined, dirty: false, error: 'invalid.required' },
        scopoPrevalente: { value: '', dirty: false, error: 'invalid.required' },
        presaVisioneDocumenti: { value: false, dirty: false, error: 'invalid.required' },
        presaVisioneDocumentiFirma: { value: false, dirty: false, error: 'invalid.required' },
        consensoResponseList: [],
    },
    esitoAcquisto: undefined,
    esitoCGS: undefined,
    annullaAcquisto: false,
    praticaConclusa: false
};
const nexiDebitReducer = (state = initialState, action) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    switch (action.type) {
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_GET_PARAMETRI_SUCCESS:
            return {
                ...state,
                parametri: action.payload
            };
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_SET_RAPPORTO_SELEZIONATO:
            return {
                ...state,
                form: {
                    ...state.form,
                    rapportoSelezionato: action.payload,
                },
                startPinViewResponse: initialState.startPinViewResponse,
                getPanViewResponse: initialState.getPanViewResponse,
                startPanViewResponse: initialState.startPanViewResponse,
                getPinViewResponse: initialState.getPinViewResponse
            };
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_DATA_A:
            return {
                ...state,
                form: {
                    ...state.form,
                    dataA: action.payload,
                    dataAError: (0, __1.validationFieldDate)(action.payload, '', false, state.form.dataDa),
                    dataDaError: (0, __1.validationFieldDate)(state.form.dataDa, '', false, '', action.payload, true),
                    periodo: initialState.form.periodo,
                }
            };
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_DATA_DA:
            return {
                ...state,
                form: {
                    ...state.form,
                    dataDa: action.payload,
                    dataAError: (0, __1.validationFieldDate)(state.form.dataA, '', false, action.payload),
                    dataDaError: (0, __1.validationFieldDate)(action.payload, '', false, '', state.form.dataA, true),
                    periodo: initialState.form.periodo,
                }
            };
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_IMPORTO:
            return {
                ...state,
                form: {
                    ...state.form,
                    importo: action.payload,
                    importoError: ''
                }
            };
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_ESERCENTE:
            return {
                ...state,
                form: {
                    ...state.form,
                    esercente: action.payload
                }
            };
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_ENTRATE:
            return {
                ...state,
                form: {
                    ...state.form,
                    entrate: action.payload
                }
            };
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_USCITE:
            return {
                ...state,
                form: {
                    ...state.form,
                    uscite: action.payload
                }
            };
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_ENTRATE_USCITE:
            return {
                ...state,
                form: {
                    ...state.form,
                    segno: action.payload
                }
            };
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_ENTRATA_USCITA:
            return {
                ...state,
                form: {
                    ...state.form,
                    entrate: action.payload === '1' || action.payload === '2',
                    uscite: action.payload === '1' || action.payload === '3',
                }
            };
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_ORDINAMENTO:
            return {
                ...state,
                form: {
                    ...state.form,
                    ordinamento: action.payload
                }
            };
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_PERIODO:
            return {
                ...state,
                form: {
                    ...state.form,
                    periodo: action.payload
                }
            };
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_OFFSET:
            return {
                ...state,
                form: {
                    ...state.form,
                    offset: action.payload
                }
            };
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_GET_MOVIMENTI_SUCCESS:
            {
                return {
                    ...state,
                    movimenti: !action.offset
                        ? action.payload
                        : {
                            ...state.movimenti,
                            totaleMovimenti: action.payload.totaleMovimenti,
                            movimenti: (((_a = state.movimenti) === null || _a === void 0 ? void 0 : _a.movimenti) || []).concat(action.payload.movimenti || [])
                        }
                };
            }
            ;
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_START_PIN_VIEW__SCA_SUCCESS:
            {
                return {
                    ...state,
                    startPinViewResponse: action.payload
                };
            }
            ;
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_GET_PIN_VIEW_SUCCESS:
            {
                return {
                    ...state,
                    getPinViewResponse: action.payload
                };
            }
            ;
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_RESET_PIN_VIEW:
            {
                return {
                    ...state,
                    startPinViewResponse: initialState.startPinViewResponse,
                    getPinViewResponse: initialState.getPinViewResponse,
                };
            }
            ;
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_START_PAN_VIEW__SCA_SUCCESS:
            {
                return {
                    ...state,
                    startPanViewResponse: action.payload
                };
            }
            ;
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_GET_PAN_VIEW_SUCCESS:
            {
                return {
                    ...state,
                    getPanViewResponse: action.payload
                };
            }
            ;
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_COPY_PAN_VIEW: {
            return {
                ...state,
                copyPanViewResponse: action.payload
            };
        }
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_RESET_PAN_VIEW:
            {
                return {
                    ...state,
                    startPanViewResponse: initialState.startPanViewResponse,
                    getPanViewResponse: initialState.getPanViewResponse,
                };
            }
            ;
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_RESET_FILTRO:
            let periodo;
            if ((_b = state.parametri) === null || _b === void 0 ? void 0 : _b.parametri) {
                periodo = state.parametri.parametri['MOVIMENTI.PERIODI.RICERCA'].find((p) => !!p.defaultValue);
            }
            return {
                ...state,
                form: {
                    ...initialState.form,
                    rapportoSelezionato: state.form.rapportoSelezionato,
                    periodo: periodo === null || periodo === void 0 ? void 0 : periodo.idPeriodo,
                    dataDa: moment().subtract((periodo === null || periodo === void 0 ? void 0 : periodo.giorni) || 0, 'days').format('YYYY-MM-DD'),
                    dataA: moment().format('YYYY-MM-DD')
                }
            };
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_RESET_MOVIMENTI:
            return {
                ...state,
                movimenti: {
                    ...initialState.movimenti
                }
            };
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_RESET:
            return {
                ...state,
                parametri: initialState.parametri,
                movimenti: initialState.movimenti,
                parametriAcquisto: initialState.parametriAcquisto,
                form: initialState.form,
                startPinViewResponse: initialState.startPinViewResponse,
                getPinViewResponse: initialState.getPinViewResponse,
            };
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_ACQUISTO_GET_PARAMETRI_SUCCESS:
            const ultimoAcquistoStato = (_c = action.payload.ultimoAcquisto) === null || _c === void 0 ? void 0 : _c.stato;
            const shouldValueForm = ultimoAcquistoStato === api_rest_1.DatiCartaAcquistoDtoStatoEnum.PRATICA_INSERITA || ultimoAcquistoStato === api_rest_1.DatiCartaAcquistoDtoStatoEnum.IN_ATTESA_DI_FIRMA;
            const ultimoAcquisto = action.payload.ultimoAcquisto;
            const tipoCarta = (_d = ultimoAcquisto === null || ultimoAcquisto === void 0 ? void 0 : ultimoAcquisto.cartaCondizioniList) === null || _d === void 0 ? void 0 : _d.tipo;
            const codiceRapporto = (_f = (_e = ultimoAcquisto === null || ultimoAcquisto === void 0 ? void 0 : ultimoAcquisto.rapportoDiAppoggio) === null || _e === void 0 ? void 0 : _e.rapporto) === null || _f === void 0 ? void 0 : _f.id;
            const professione = ultimoAcquisto === null || ultimoAcquisto === void 0 ? void 0 : ultimoAcquisto.professione;
            const tae = ultimoAcquisto === null || ultimoAcquisto === void 0 ? void 0 : ultimoAcquisto.tae;
            const provenienzaFondi = ultimoAcquisto === null || ultimoAcquisto === void 0 ? void 0 : ultimoAcquisto.provenienzaFondi;
            const personaPoliticamenteEsposta = ultimoAcquisto === null || ultimoAcquisto === void 0 ? void 0 : ultimoAcquisto.personaPoliticamenteEsposta;
            const scopoPrevalente = ultimoAcquisto === null || ultimoAcquisto === void 0 ? void 0 : ultimoAcquisto.scopoPrevalente;
            const consensoResponseList = ultimoAcquisto === null || ultimoAcquisto === void 0 ? void 0 : ultimoAcquisto.consensoResponseList;
            return {
                ...state,
                parametriAcquisto: action.payload,
                formAcquisto: {
                    ...initialState.formAcquisto,
                    tipoCarta: shouldValueForm && tipoCarta != undefined
                        ? {
                            value: tipoCarta,
                            dirty: true,
                            error: (0, __1.validationField)(tipoCarta, '', undefined, undefined, undefined, true)
                        } : initialState.formAcquisto.tipoCarta,
                    codiceRapporto: shouldValueForm && codiceRapporto != undefined
                        ? {
                            value: codiceRapporto,
                            dirty: true,
                            error: (0, __1.validationField)(codiceRapporto, 0, undefined, undefined, undefined, true)
                        } : initialState.formAcquisto.codiceRapporto,
                    professione: shouldValueForm && professione != undefined
                        ? {
                            value: professione,
                            dirty: true,
                            error: (0, __1.validationField)(professione, '', undefined, undefined, undefined, true)
                        } : initialState.formAcquisto.professione,
                    tae: shouldValueForm && tae != undefined
                        ? {
                            value: tae,
                            dirty: true,
                            error: (0, __1.validationField)(tae, '', undefined, undefined, undefined, true)
                        } : initialState.formAcquisto.tae,
                    provenienzaFondi: shouldValueForm && provenienzaFondi != undefined
                        ? {
                            value: provenienzaFondi.map(x => {
                                if (!!x.text) {
                                    return {
                                        ...x,
                                        text: x.text.slice(0, 40)
                                    };
                                }
                                return x;
                            }),
                            dirty: true,
                            error: provenienzaFondi.length === 0 ? 'invalid.required' : ''
                        } : initialState.formAcquisto.provenienzaFondi,
                    personaPoliticamenteEsposta: shouldValueForm && personaPoliticamenteEsposta != undefined
                        ? {
                            value: personaPoliticamenteEsposta,
                            dirty: true,
                            error: action.payload != undefined ? '' : 'invalid.required'
                        } : initialState.formAcquisto.personaPoliticamenteEsposta,
                    scopoPrevalente: shouldValueForm && scopoPrevalente != undefined
                        ? {
                            value: scopoPrevalente,
                            dirty: true,
                            error: (0, __1.validationField)(action.payload, '', undefined, undefined, undefined, true)
                        } : initialState.formAcquisto.scopoPrevalente,
                    presaVisioneDocumenti: shouldValueForm
                        ? {
                            value: true,
                            dirty: true,
                            error: action.payload ? '' : 'invalid.required'
                        } : initialState.formAcquisto.presaVisioneDocumenti,
                    presaVisioneDocumentiFirma: ((_g = state.esitoCGS) === null || _g === void 0 ? void 0 : _g.found)
                        ? {
                            value: true,
                            dirty: true,
                            error: action.payload ? '' : 'invalid.required'
                        } : initialState.formAcquisto.presaVisioneDocumentiFirma,
                    consensoResponseList: shouldValueForm && consensoResponseList != undefined
                        ? consensoResponseList.map(({ consenso, response }) => {
                            return {
                                value: { consenso, response },
                                dirty: true,
                                error: response == undefined ? 'invalid.required' : ''
                            };
                        }) : ((_h = action.payload.consensoList) === null || _h === void 0 ? void 0 : _h.map(x => {
                        return {
                            value: { consenso: x, response: undefined },
                            dirty: false,
                            error: 'invalid.required'
                        };
                    })) || initialState.formAcquisto.consensoResponseList,
                }
            };
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_ACQUISTO_CHECK_USER_START:
            return {
                ...state,
                userAcquisto: initialState.userAcquisto,
            };
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_ACQUISTO_CHECK_USER_SUCCESS:
            return {
                ...state,
                userAcquisto: action.payload
            };
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_ACQUISTO_GET_CONVENZIONE_START:
            return {
                ...state,
                convenzioneRapporto: initialState.convenzioneRapporto,
            };
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_ACQUISTO_GET_CONVENZIONE_SUCCESS:
            return {
                ...state,
                convenzioneRapporto: action.payload
            };
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_ACQUISTO_GET_LISTE_AGGIUNTIVE_PROFESSIONE_SUCCESS:
            return {
                ...state,
                formAcquisto: {
                    ...state.formAcquisto,
                    taeList: action.payload.tae || [],
                }
            };
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_ACQUISTO_CONTROLLO_START:
            return {
                ...state,
                esitoAcquisto: initialState.esitoAcquisto,
            };
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_ACQUISTO_CONTROLLO_SUCCESS:
            return {
                ...state,
                esitoAcquisto: action.payload
            };
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_ACQUISTO_SET_TIPO_CARTA:
            return {
                ...state,
                formAcquisto: {
                    ...state.formAcquisto,
                    tipoCarta: {
                        value: action.payload,
                        dirty: true,
                        error: (0, __1.validationField)(action.payload, '', undefined, undefined, undefined, true)
                    }
                }
            };
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_ACQUISTO_SET_CODICE_RAPPORTO:
            return {
                ...state,
                formAcquisto: {
                    ...state.formAcquisto,
                    codiceRapporto: {
                        value: action.payload,
                        dirty: true,
                        error: (0, __1.validationField)(action.payload, 0, undefined, undefined, undefined, true)
                    }
                }
            };
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_ACQUISTO_SET_PROFESSIONE:
            return {
                ...state,
                formAcquisto: {
                    ...state.formAcquisto,
                    professione: {
                        value: action.payload,
                        dirty: true,
                        error: (0, __1.validationField)(action.payload, '', undefined, undefined, undefined, true)
                    }
                }
            };
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_ACQUISTO_SET_TAE:
            return {
                ...state,
                formAcquisto: {
                    ...state.formAcquisto,
                    tae: {
                        value: action.payload,
                        dirty: true,
                        error: (0, __1.validationField)(action.payload, '', undefined, undefined, undefined, true)
                    }
                }
            };
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_ACQUISTO_SET_PROVENIENZA_FONDI:
            return {
                ...state,
                formAcquisto: {
                    ...state.formAcquisto,
                    provenienzaFondi: {
                        value: action.payload.map(x => {
                            if (!!x.text) {
                                return {
                                    ...x,
                                    text: x.text.slice(0, 40)
                                };
                            }
                            return x;
                        }),
                        dirty: true,
                        error: action.payload.length === 0 ? 'invalid.required' : ''
                    }
                }
            };
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_ACQUISTO_SET_PERSONA_POLITICAMENTE_ESPOSTA:
            return {
                ...state,
                formAcquisto: {
                    ...state.formAcquisto,
                    personaPoliticamenteEsposta: {
                        value: action.payload,
                        dirty: true,
                        error: action.payload != undefined ? '' : 'invalid.required'
                    }
                }
            };
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_ACQUISTO_SET_SCOPO_PREVALENTE:
            return {
                ...state,
                formAcquisto: {
                    ...state.formAcquisto,
                    scopoPrevalente: {
                        value: action.payload,
                        dirty: true,
                        error: (0, __1.validationField)(action.payload, '', undefined, undefined, undefined, true)
                    }
                }
            };
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_ACQUISTO_SET_PRESA_VISIONE_DOCUMENTI:
            return {
                ...state,
                formAcquisto: {
                    ...state.formAcquisto,
                    presaVisioneDocumenti: {
                        value: action.payload,
                        dirty: true,
                        error: action.payload ? '' : 'invalid.required'
                    }
                }
            };
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_ACQUISTO_SET_PRESA_VISIONE_DOCUMENTI_FIRMA:
            return {
                ...state,
                formAcquisto: {
                    ...state.formAcquisto,
                    presaVisioneDocumentiFirma: {
                        value: action.payload,
                        dirty: true,
                        error: action.payload ? '' : 'invalid.required'
                    }
                }
            };
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_ACQUISTO_SET_CONSENSO_RESPONSE_LIST:
            return {
                ...state,
                formAcquisto: {
                    ...state.formAcquisto,
                    consensoResponseList: action.payload.map((x, i) => {
                        const currentValue = state.formAcquisto.consensoResponseList[i];
                        return {
                            value: x,
                            dirty: currentValue.value == x ? currentValue.dirty : true,
                            error: x.response == undefined ? 'invalid.required' : ''
                        };
                    })
                }
            };
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_ACQUISTO_RESET_CONTROLLO:
            return {
                ...state,
                esitoAcquisto: initialState.esitoAcquisto,
            };
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_ACQUISTO_RESET:
            return {
                ...state,
                parametriAcquisto: initialState.parametriAcquisto,
                userAcquisto: initialState.userAcquisto,
                formAcquisto: initialState.formAcquisto,
                esitoAcquisto: initialState.esitoAcquisto,
                esitoCGS: initialState.esitoCGS,
                annullaAcquisto: initialState.annullaAcquisto,
                praticaConclusa: initialState.praticaConclusa
            };
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_VERIFICA_CGS_SUCCESS:
            return {
                ...state,
                esitoCGS: action.payload
            };
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_ANNULLA_ACQUISTO_SUCCESS:
            return {
                ...state,
                annullaAcquisto: action.payload,
                parametriAcquisto: initialState.parametriAcquisto
            };
        case nexiDebit_types_1.ENexiDebitActionTypes.NEXI_DEBIT_ACQUISTO_CONCLUDI_NEGATIVAMENTE_PRATICA_ACQUISTO_SUCCESS:
            return {
                ...state,
                praticaConclusa: true,
                parametriAcquisto: initialState.parametriAcquisto
            };
        case __1.EReduxActionTypes.RESET_ALL: // action invocata dalla logout
            return {
                ...initialState
            };
        default:
            return state;
    }
};
exports.nexiDebitReducer = nexiDebitReducer;
exports.default = exports.nexiDebitReducer;
