"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DatiAnagraficiRispostaDtoStatoDocumentoRiconoscimentoEnum = exports.DatiAnagraficiRispostaDtoToJSON = exports.DatiAnagraficiRispostaDtoFromJSONTyped = exports.DatiAnagraficiRispostaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function DatiAnagraficiRispostaDtoFromJSON(json) {
    return DatiAnagraficiRispostaDtoFromJSONTyped(json, false);
}
exports.DatiAnagraficiRispostaDtoFromJSON = DatiAnagraficiRispostaDtoFromJSON;
function DatiAnagraficiRispostaDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'ndg': !runtime_1.exists(json, 'ndg') ? undefined : json['ndg'],
        'codiceFiscale': !runtime_1.exists(json, 'codiceFiscale') ? undefined : json['codiceFiscale'],
        'naturaGiuridica': !runtime_1.exists(json, 'naturaGiuridica') ? undefined : _1.NaturaGiuridicaDtoFromJSON(json['naturaGiuridica']),
        'intestazione': !runtime_1.exists(json, 'intestazione') ? undefined : _1.IntestazioneDtoFromJSON(json['intestazione']),
        'numeroCointestatari': !runtime_1.exists(json, 'numeroCointestatari') ? undefined : json['numeroCointestatari'],
        'codiceStatoCivile': !runtime_1.exists(json, 'codiceStatoCivile') ? undefined : json['codiceStatoCivile'],
        'descrizioneStatoCivile': !runtime_1.exists(json, 'descrizioneStatoCivile') ? undefined : json['descrizioneStatoCivile'],
        'infoNascita': !runtime_1.exists(json, 'infoNascita') ? undefined : _1.InfoNascitaDtoFromJSON(json['infoNascita']),
        'residenzaLegale': !runtime_1.exists(json, 'residenzaLegale') ? undefined : _1.IndirizzoDatiAnagraficiDtoFromJSON(json['residenzaLegale']),
        'domiciliazioneAmministrativa': !runtime_1.exists(json, 'domiciliazioneAmministrativa') ? undefined : _1.DomiciliazioneAmministrativaDtoFromJSON(json['domiciliazioneAmministrativa']),
        'documento': !runtime_1.exists(json, 'documento') ? undefined : _1.DocumentoDatiAnagraficiDtoFromJSON(json['documento']),
        'dataScadenzaDocumento': !runtime_1.exists(json, 'dataScadenzaDocumento') ? undefined : json['dataScadenzaDocumento'],
        'attivitaEconomica': !runtime_1.exists(json, 'attivitaEconomica') ? undefined : _1.AttivitaEconomicaDtoFromJSON(json['attivitaEconomica']),
        'telefono': !runtime_1.exists(json, 'telefono') ? undefined : _1.TelefonoDatiAnagraficiDtoFromJSON(json['telefono']),
        'infoCCIAA': !runtime_1.exists(json, 'infoCCIAA') ? undefined : _1.InfoCCIAADtoFromJSON(json['infoCCIAA']),
        'cittadinanzaResidenza': !runtime_1.exists(json, 'cittadinanzaResidenza') ? undefined : _1.CittadinanzaResidenzaDtoFromJSON(json['cittadinanzaResidenza']),
        'agenzia': !runtime_1.exists(json, 'agenzia') ? undefined : _1.AgenziaDtoFromJSON(json['agenzia']),
        'statusElemento': !runtime_1.exists(json, 'statusElemento') ? undefined : _1.StatusElementoDtoFromJSON(json['statusElemento']),
        'descrizioneSAEAlternativo': !runtime_1.exists(json, 'descrizioneSAEAlternativo') ? undefined : json['descrizioneSAEAlternativo'],
        'descrizioneRAEAlternativo': !runtime_1.exists(json, 'descrizioneRAEAlternativo') ? undefined : json['descrizioneRAEAlternativo'],
        'descrizioneResidenzaValutaria': !runtime_1.exists(json, 'descrizioneResidenzaValutaria') ? undefined : json['descrizioneResidenzaValutaria'],
        'descrizioneIndicativoSocio': !runtime_1.exists(json, 'descrizioneIndicativoSocio') ? undefined : json['descrizioneIndicativoSocio'],
        'descrizioneTipoGruppo': !runtime_1.exists(json, 'descrizioneTipoGruppo') ? undefined : json['descrizioneTipoGruppo'],
        'descrizioneIndicativoMarketing': !runtime_1.exists(json, 'descrizioneIndicativoMarketing') ? undefined : json['descrizioneIndicativoMarketing'],
        'codiceIndicativoMarketing': !runtime_1.exists(json, 'codiceIndicativoMarketing') ? undefined : json['codiceIndicativoMarketing'],
        'notaStatus': !runtime_1.exists(json, 'notaStatus') ? undefined : _1.NotaStatusDtoFromJSON(json['notaStatus']),
        'descrizioneLingua': !runtime_1.exists(json, 'descrizioneLingua') ? undefined : json['descrizioneLingua'],
        'codiceABI': !runtime_1.exists(json, 'codiceABI') ? undefined : json['codiceABI'],
        'fatturazione': !runtime_1.exists(json, 'fatturazione') ? undefined : _1.FatturazioneDtoFromJSON(json['fatturazione']),
        'matricolaFunzionario': !runtime_1.exists(json, 'matricolaFunzionario') ? undefined : json['matricolaFunzionario'],
        'dataCensimento': !runtime_1.exists(json, 'dataCensimento') ? undefined : (new Date(json['dataCensimento'])),
        'dataUltimaVariazioneAnagrafica': !runtime_1.exists(json, 'dataUltimaVariazioneAnagrafica') ? undefined : (new Date(json['dataUltimaVariazioneAnagrafica'])),
        'residenzaTitolare': !runtime_1.exists(json, 'residenzaTitolare') ? undefined : _1.IndirizzoDatiAnagraficiDtoFromJSON(json['residenzaTitolare']),
        'centraleRischi': !runtime_1.exists(json, 'centraleRischi') ? undefined : json['centraleRischi'],
        'partitaIVA': !runtime_1.exists(json, 'partitaIVA') ? undefined : json['partitaIVA'],
        'numeroCellulare': !runtime_1.exists(json, 'numeroCellulare') ? undefined : json['numeroCellulare'],
        'email': !runtime_1.exists(json, 'email') ? undefined : json['email'],
        'numeroFax': !runtime_1.exists(json, 'numeroFax') ? undefined : json['numeroFax'],
        'ateco': !runtime_1.exists(json, 'ateco') ? undefined : json['ateco'],
        'descrizioneAteco': !runtime_1.exists(json, 'descrizioneAteco') ? undefined : json['descrizioneAteco'],
        'tabellaPrivacy': !runtime_1.exists(json, 'tabellaPrivacy') ? undefined : (json['tabellaPrivacy'].map(_1.ElementoPrivacyDtoFromJSON)),
        'numeroCellulareAlternativo': !runtime_1.exists(json, 'numeroCellulareAlternativo') ? undefined : json['numeroCellulareAlternativo'],
        'emailAlternativo': !runtime_1.exists(json, 'emailAlternativo') ? undefined : json['emailAlternativo'],
        'numeroTelefonoAlternativo': !runtime_1.exists(json, 'numeroTelefonoAlternativo') ? undefined : json['numeroTelefonoAlternativo'],
        'sitoInternet': !runtime_1.exists(json, 'sitoInternet') ? undefined : json['sitoInternet'],
        'codiceProfessione': !runtime_1.exists(json, 'codiceProfessione') ? undefined : json['codiceProfessione'],
        'descrizioneProfessione': !runtime_1.exists(json, 'descrizioneProfessione') ? undefined : json['descrizioneProfessione'],
        'numeroAddetti': !runtime_1.exists(json, 'numeroAddetti') ? undefined : json['numeroAddetti'],
        'flagClientePotenziale': !runtime_1.exists(json, 'flagClientePotenziale') ? undefined : json['flagClientePotenziale'],
        'codiceAppellativo': !runtime_1.exists(json, 'codiceAppellativo') ? undefined : json['codiceAppellativo'],
        'descrizioneAppellativo': !runtime_1.exists(json, 'descrizioneAppellativo') ? undefined : json['descrizioneAppellativo'],
        'titoloNonAccademico': !runtime_1.exists(json, 'titoloNonAccademico') ? undefined : _1.InfoTitoloDtoFromJSON(json['titoloNonAccademico']),
        'titoloAccademico': !runtime_1.exists(json, 'titoloAccademico') ? undefined : _1.InfoTitoloDtoFromJSON(json['titoloAccademico']),
        'tipoDipendente': !runtime_1.exists(json, 'tipoDipendente') ? undefined : json['tipoDipendente'],
        'descrizioneTipoDipendente': !runtime_1.exists(json, 'descrizioneTipoDipendente') ? undefined : json['descrizioneTipoDipendente'],
        'matricolaDipendente': !runtime_1.exists(json, 'matricolaDipendente') ? undefined : json['matricolaDipendente'],
        'indicativoCapoConvenzione': !runtime_1.exists(json, 'indicativoCapoConvenzione') ? undefined : json['indicativoCapoConvenzione'],
        'codiceConvenzione': !runtime_1.exists(json, 'codiceConvenzione') ? undefined : json['codiceConvenzione'],
        'indicativoCapoGruppo': !runtime_1.exists(json, 'indicativoCapoGruppo') ? undefined : json['indicativoCapoGruppo'],
        'codiceGruppo': !runtime_1.exists(json, 'codiceGruppo') ? undefined : json['codiceGruppo'],
        'codiceSegmento': !runtime_1.exists(json, 'codiceSegmento') ? undefined : json['codiceSegmento'],
        'datiAggiuntiObbligatori': !runtime_1.exists(json, 'datiAggiuntiObbligatori') ? undefined : json['datiAggiuntiObbligatori'],
        'datiAggiuntivis': !runtime_1.exists(json, 'datiAggiuntivis') ? undefined : (json['datiAggiuntivis'].map(_1.DatiAggiuntiviDtoFromJSON)),
        'allineaIntestazione': !runtime_1.exists(json, 'allineaIntestazione') ? undefined : json['allineaIntestazione'],
        'indicativoNormalizzazioneResidenza': !runtime_1.exists(json, 'indicativoNormalizzazioneResidenza') ? undefined : json['indicativoNormalizzazioneResidenza'],
        'esitoNormalizzazioneResidenza': !runtime_1.exists(json, 'esitoNormalizzazioneResidenza') ? undefined : json['esitoNormalizzazioneResidenza'],
        'indicativoNormalizzazioneDomicilio': !runtime_1.exists(json, 'indicativoNormalizzazioneDomicilio') ? undefined : json['indicativoNormalizzazioneDomicilio'],
        'esitoNormalizzazioneDomicilio': !runtime_1.exists(json, 'esitoNormalizzazioneDomicilio') ? undefined : json['esitoNormalizzazioneDomicilio'],
        'indicativoSocietaConstituenda': !runtime_1.exists(json, 'indicativoSocietaConstituenda') ? undefined : json['indicativoSocietaConstituenda'],
        'codiceTAE': !runtime_1.exists(json, 'codiceTAE') ? undefined : json['codiceTAE'],
        'descrizioneTAE': !runtime_1.exists(json, 'descrizioneTAE') ? undefined : json['descrizioneTAE'],
        'flagValidazione': !runtime_1.exists(json, 'flagValidazione') ? undefined : json['flagValidazione'],
        'ndgOperatore': !runtime_1.exists(json, 'ndgOperatore') ? undefined : json['ndgOperatore'],
        'intestazioneOperatore': !runtime_1.exists(json, 'intestazioneOperatore') ? undefined : json['intestazioneOperatore'],
        'dataUltimaVariazione': !runtime_1.exists(json, 'dataUltimaVariazione') ? undefined : (new Date(json['dataUltimaVariazione'])),
        'flagGenerici': !runtime_1.exists(json, 'flagGenerici') ? undefined : _1.FlagGenericiDtoFromJSON(json['flagGenerici']),
        'ndgOperatorePws': !runtime_1.exists(json, 'ndgOperatorePws') ? undefined : json['ndgOperatorePws'],
        'moduloAdobe': !runtime_1.exists(json, 'moduloAdobe') ? undefined : json['moduloAdobe'],
        'intestazioneAnagrafica': !runtime_1.exists(json, 'intestazioneAnagrafica') ? undefined : json['intestazioneAnagrafica'],
        'statusAnagrafico1': !runtime_1.exists(json, 'statusAnagrafico1') ? undefined : json['statusAnagrafico1'],
        'statusAnagrafico2': !runtime_1.exists(json, 'statusAnagrafico2') ? undefined : json['statusAnagrafico2'],
        'reteAssociataNaturaGiuridica': !runtime_1.exists(json, 'reteAssociataNaturaGiuridica') ? undefined : json['reteAssociataNaturaGiuridica'],
        'filialeMadre': !runtime_1.exists(json, 'filialeMadre') ? undefined : json['filialeMadre'],
        'flagOperatore': !runtime_1.exists(json, 'flagOperatore') ? undefined : json['flagOperatore'],
        'statusNdg': !runtime_1.exists(json, 'statusNdg') ? undefined : json['statusNdg'],
        'statusInformativoNdg': !runtime_1.exists(json, 'statusInformativoNdg') ? undefined : json['statusInformativoNdg'],
        'statoDocumentoRiconoscimento': !runtime_1.exists(json, 'statoDocumentoRiconoscimento') ? undefined : json['statoDocumentoRiconoscimento'],
    };
}
exports.DatiAnagraficiRispostaDtoFromJSONTyped = DatiAnagraficiRispostaDtoFromJSONTyped;
function DatiAnagraficiRispostaDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'ndg': value.ndg,
        'codiceFiscale': value.codiceFiscale,
        'naturaGiuridica': _1.NaturaGiuridicaDtoToJSON(value.naturaGiuridica),
        'intestazione': _1.IntestazioneDtoToJSON(value.intestazione),
        'numeroCointestatari': value.numeroCointestatari,
        'codiceStatoCivile': value.codiceStatoCivile,
        'descrizioneStatoCivile': value.descrizioneStatoCivile,
        'infoNascita': _1.InfoNascitaDtoToJSON(value.infoNascita),
        'residenzaLegale': _1.IndirizzoDatiAnagraficiDtoToJSON(value.residenzaLegale),
        'domiciliazioneAmministrativa': _1.DomiciliazioneAmministrativaDtoToJSON(value.domiciliazioneAmministrativa),
        'documento': _1.DocumentoDatiAnagraficiDtoToJSON(value.documento),
        'dataScadenzaDocumento': value.dataScadenzaDocumento,
        'attivitaEconomica': _1.AttivitaEconomicaDtoToJSON(value.attivitaEconomica),
        'telefono': _1.TelefonoDatiAnagraficiDtoToJSON(value.telefono),
        'infoCCIAA': _1.InfoCCIAADtoToJSON(value.infoCCIAA),
        'cittadinanzaResidenza': _1.CittadinanzaResidenzaDtoToJSON(value.cittadinanzaResidenza),
        'agenzia': _1.AgenziaDtoToJSON(value.agenzia),
        'statusElemento': _1.StatusElementoDtoToJSON(value.statusElemento),
        'descrizioneSAEAlternativo': value.descrizioneSAEAlternativo,
        'descrizioneRAEAlternativo': value.descrizioneRAEAlternativo,
        'descrizioneResidenzaValutaria': value.descrizioneResidenzaValutaria,
        'descrizioneIndicativoSocio': value.descrizioneIndicativoSocio,
        'descrizioneTipoGruppo': value.descrizioneTipoGruppo,
        'descrizioneIndicativoMarketing': value.descrizioneIndicativoMarketing,
        'codiceIndicativoMarketing': value.codiceIndicativoMarketing,
        'notaStatus': _1.NotaStatusDtoToJSON(value.notaStatus),
        'descrizioneLingua': value.descrizioneLingua,
        'codiceABI': value.codiceABI,
        'fatturazione': _1.FatturazioneDtoToJSON(value.fatturazione),
        'matricolaFunzionario': value.matricolaFunzionario,
        'dataCensimento': value.dataCensimento === undefined ? undefined : (value.dataCensimento.toISOString()),
        'dataUltimaVariazioneAnagrafica': value.dataUltimaVariazioneAnagrafica === undefined ? undefined : (value.dataUltimaVariazioneAnagrafica.toISOString()),
        'residenzaTitolare': _1.IndirizzoDatiAnagraficiDtoToJSON(value.residenzaTitolare),
        'centraleRischi': value.centraleRischi,
        'partitaIVA': value.partitaIVA,
        'numeroCellulare': value.numeroCellulare,
        'email': value.email,
        'numeroFax': value.numeroFax,
        'ateco': value.ateco,
        'descrizioneAteco': value.descrizioneAteco,
        'tabellaPrivacy': value.tabellaPrivacy === undefined ? undefined : (value.tabellaPrivacy.map(_1.ElementoPrivacyDtoToJSON)),
        'numeroCellulareAlternativo': value.numeroCellulareAlternativo,
        'emailAlternativo': value.emailAlternativo,
        'numeroTelefonoAlternativo': value.numeroTelefonoAlternativo,
        'sitoInternet': value.sitoInternet,
        'codiceProfessione': value.codiceProfessione,
        'descrizioneProfessione': value.descrizioneProfessione,
        'numeroAddetti': value.numeroAddetti,
        'flagClientePotenziale': value.flagClientePotenziale,
        'codiceAppellativo': value.codiceAppellativo,
        'descrizioneAppellativo': value.descrizioneAppellativo,
        'titoloNonAccademico': _1.InfoTitoloDtoToJSON(value.titoloNonAccademico),
        'titoloAccademico': _1.InfoTitoloDtoToJSON(value.titoloAccademico),
        'tipoDipendente': value.tipoDipendente,
        'descrizioneTipoDipendente': value.descrizioneTipoDipendente,
        'matricolaDipendente': value.matricolaDipendente,
        'indicativoCapoConvenzione': value.indicativoCapoConvenzione,
        'codiceConvenzione': value.codiceConvenzione,
        'indicativoCapoGruppo': value.indicativoCapoGruppo,
        'codiceGruppo': value.codiceGruppo,
        'codiceSegmento': value.codiceSegmento,
        'datiAggiuntiObbligatori': value.datiAggiuntiObbligatori,
        'datiAggiuntivis': value.datiAggiuntivis === undefined ? undefined : (value.datiAggiuntivis.map(_1.DatiAggiuntiviDtoToJSON)),
        'allineaIntestazione': value.allineaIntestazione,
        'indicativoNormalizzazioneResidenza': value.indicativoNormalizzazioneResidenza,
        'esitoNormalizzazioneResidenza': value.esitoNormalizzazioneResidenza,
        'indicativoNormalizzazioneDomicilio': value.indicativoNormalizzazioneDomicilio,
        'esitoNormalizzazioneDomicilio': value.esitoNormalizzazioneDomicilio,
        'indicativoSocietaConstituenda': value.indicativoSocietaConstituenda,
        'codiceTAE': value.codiceTAE,
        'descrizioneTAE': value.descrizioneTAE,
        'flagValidazione': value.flagValidazione,
        'ndgOperatore': value.ndgOperatore,
        'intestazioneOperatore': value.intestazioneOperatore,
        'dataUltimaVariazione': value.dataUltimaVariazione === undefined ? undefined : (value.dataUltimaVariazione.toISOString()),
        'flagGenerici': _1.FlagGenericiDtoToJSON(value.flagGenerici),
        'ndgOperatorePws': value.ndgOperatorePws,
        'moduloAdobe': value.moduloAdobe,
        'intestazioneAnagrafica': value.intestazioneAnagrafica,
        'statusAnagrafico1': value.statusAnagrafico1,
        'statusAnagrafico2': value.statusAnagrafico2,
        'reteAssociataNaturaGiuridica': value.reteAssociataNaturaGiuridica,
        'filialeMadre': value.filialeMadre,
        'flagOperatore': value.flagOperatore,
        'statusNdg': value.statusNdg,
        'statusInformativoNdg': value.statusInformativoNdg,
        'statoDocumentoRiconoscimento': value.statoDocumentoRiconoscimento,
    };
}
exports.DatiAnagraficiRispostaDtoToJSON = DatiAnagraficiRispostaDtoToJSON;
/**
* @export
* @enum {string}
*/
var DatiAnagraficiRispostaDtoStatoDocumentoRiconoscimentoEnum;
(function (DatiAnagraficiRispostaDtoStatoDocumentoRiconoscimentoEnum) {
    DatiAnagraficiRispostaDtoStatoDocumentoRiconoscimentoEnum["VALIDO"] = "VALIDO";
    DatiAnagraficiRispostaDtoStatoDocumentoRiconoscimentoEnum["SCADUTO"] = "SCADUTO";
    DatiAnagraficiRispostaDtoStatoDocumentoRiconoscimentoEnum["IN_ATTESA_DI_APPROVAZIONE"] = "IN_ATTESA_DI_APPROVAZIONE";
})(DatiAnagraficiRispostaDtoStatoDocumentoRiconoscimentoEnum = exports.DatiAnagraficiRispostaDtoStatoDocumentoRiconoscimentoEnum || (exports.DatiAnagraficiRispostaDtoStatoDocumentoRiconoscimentoEnum = {}));
