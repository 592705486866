import { error, nextRoute, popUps, removePopUp, resetError, setRoute } from "@sparkasse/commons";
import history, { navigateToLogout } from "component/history/history.component";
import PopUpModal, { PopUpStyleVariant } from "component/popUpModal/popUpModal.component";
import { buildErrorMessage } from "helpers/utilities";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AppState } from "reducers";
import { AnyAction, bindActionCreators, Dispatch } from "redux";

interface FullPageNotificationProps {
  onReset?: () => void
}

const isTimeout = (error: any) => (error?.status === 401 || error?.status === 403 || error?.message === "Unauthorized");

const FullPageNotification: React.FC<Props> = (props: Props) => {

  const { t } = useTranslation();

  const {
    error,
    resetError,
    onReset,
    popUps,
    currentPopUp,
    nextRoute,
    removePopUp,
    setRoute
  } = props;

  const isError = (!!error?.details || !!error?.message || !!error?.error) && error?.showGenericModalError;
  const errorDescription = isError ? buildErrorMessage(error, t) : "";

  const onClosePopUp = (id: number | undefined) => {
    if (!id && id != 0) {
      return;
    }
    removePopUp(id);
  };

  const onCloseError = () => {
    if (isTimeout(error) && isError) {
      resetError();
      navigateToLogout();
    }
    if (onReset) {
      onReset();
    } else {
      resetError();
    }
    if (error?.message === "C_ADV_RAPPORTI_BLOCCATI") {
      if (nextRoute !== 'prestito-idea') {
        history.push("/home");
      } else {
        setRoute('')
      }
    }
  };

  return (
    <>

      {popUps.length > 0 && currentPopUp &&
        <PopUpModal
          onClose={onClosePopUp}
          popUp={currentPopUp}
        />
      }

      {isError &&
        <PopUpModal
          styleVariant={PopUpStyleVariant.error}
          onClose={onCloseError}
          popUp={{
            type: 'alert',
            title: t('common.errors.attenzione'),
            message: errorDescription
          }}
        />
      }

    </>
  );
};

export type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & FullPageNotificationProps;

const mapStateToProps = (state: AppState) => ({
  error: error(state.general),
  popUps: popUps(state.general),
  currentPopUp: state.general.popUps?.[0],
  nextRoute: nextRoute(state.general)
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      resetError,
      removePopUp,
      setRoute
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FullPageNotification);
