"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * API BFF Customer Web Collaboration
 * API BFF Customer Web Collaboration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfigResponseToJSON = exports.ConfigResponseFromJSONTyped = exports.ConfigResponseFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ConfigResponseFromJSON(json) {
    return ConfigResponseFromJSONTyped(json, false);
}
exports.ConfigResponseFromJSON = ConfigResponseFromJSON;
function ConfigResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'errorManagementResponse': !runtime_1.exists(json, 'errorManagementResponse') ? undefined : _1.ErrorManagementResponseFromJSON(json['errorManagementResponse']),
        'slotMaxTimeMm': !runtime_1.exists(json, 'slotMaxTimeMm') ? undefined : json['slotMaxTimeMm'],
        'urlAcsFe': !runtime_1.exists(json, 'urlAcsFe') ? undefined : json['urlAcsFe'],
        'workHourEnd': !runtime_1.exists(json, 'workHourEnd') ? undefined : json['workHourEnd'],
        'workHourStart': !runtime_1.exists(json, 'workHourStart') ? undefined : json['workHourStart'],
    };
}
exports.ConfigResponseFromJSONTyped = ConfigResponseFromJSONTyped;
function ConfigResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'errorManagementResponse': _1.ErrorManagementResponseToJSON(value.errorManagementResponse),
        'slotMaxTimeMm': value.slotMaxTimeMm,
        'urlAcsFe': value.urlAcsFe,
        'workHourEnd': value.workHourEnd,
        'workHourStart': value.workHourStart,
    };
}
exports.ConfigResponseToJSON = ConfigResponseToJSON;
