"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModificaRequestDtoToJSON = exports.ModificaRequestDtoFromJSONTyped = exports.ModificaRequestDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ModificaRequestDtoFromJSON(json) {
    return ModificaRequestDtoFromJSONTyped(json, false);
}
exports.ModificaRequestDtoFromJSON = ModificaRequestDtoFromJSON;
function ModificaRequestDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'codiceDisposizione': !runtime_1.exists(json, 'codiceDisposizione') ? undefined : json['codiceDisposizione'],
        'codiceRapporto': !runtime_1.exists(json, 'codiceRapporto') ? undefined : json['codiceRapporto'],
        'condivisione': !runtime_1.exists(json, 'condivisione') ? undefined : (json['condivisione'].map(_1.CondivisioneDtoFromJSON)),
        'importo': !runtime_1.exists(json, 'importo') ? undefined : json['importo'],
        'codiceIban': !runtime_1.exists(json, 'codiceIban') ? undefined : json['codiceIban'],
        'causale': !runtime_1.exists(json, 'causale') ? undefined : json['causale'],
        'dataFineDisposizione': !runtime_1.exists(json, 'dataFineDisposizione') ? undefined : (new Date(json['dataFineDisposizione'])),
        'tabellaRate': !runtime_1.exists(json, 'tabellaRate') ? undefined : (json['tabellaRate'].map(_1.RataDtoFromJSON)),
    };
}
exports.ModificaRequestDtoFromJSONTyped = ModificaRequestDtoFromJSONTyped;
function ModificaRequestDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'codiceDisposizione': value.codiceDisposizione,
        'codiceRapporto': value.codiceRapporto,
        'condivisione': value.condivisione === undefined ? undefined : (value.condivisione.map(_1.CondivisioneDtoToJSON)),
        'importo': value.importo,
        'codiceIban': value.codiceIban,
        'causale': value.causale,
        'dataFineDisposizione': value.dataFineDisposizione === undefined ? undefined : (value.dataFineDisposizione.toISOString()),
        'tabellaRate': value.tabellaRate === undefined ? undefined : (value.tabellaRate.map(_1.RataDtoToJSON)),
    };
}
exports.ModificaRequestDtoToJSON = ModificaRequestDtoToJSON;
