"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrePositionMovementBODtoToJSON = exports.PrePositionMovementBODtoFromJSONTyped = exports.PrePositionMovementBODtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function PrePositionMovementBODtoFromJSON(json) {
    return PrePositionMovementBODtoFromJSONTyped(json, false);
}
exports.PrePositionMovementBODtoFromJSON = PrePositionMovementBODtoFromJSON;
function PrePositionMovementBODtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'accountingDate': !runtime_1.exists(json, 'accountingDate') ? undefined : json['accountingDate'],
        'causalCode': !runtime_1.exists(json, 'causalCode') ? undefined : json['causalCode'],
        'causalDescription': !runtime_1.exists(json, 'causalDescription') ? undefined : json['causalDescription'],
        'ctv': !runtime_1.exists(json, 'ctv') ? undefined : json['ctv'],
        'ctvMarket': !runtime_1.exists(json, 'ctvMarket') ? undefined : json['ctvMarket'],
        'date': !runtime_1.exists(json, 'date') ? undefined : json['date'],
        'exchange': !runtime_1.exists(json, 'exchange') ? undefined : json['exchange'],
        'price': !runtime_1.exists(json, 'price') ? undefined : json['price'],
        'qta': !runtime_1.exists(json, 'qta') ? undefined : json['qta'],
    };
}
exports.PrePositionMovementBODtoFromJSONTyped = PrePositionMovementBODtoFromJSONTyped;
function PrePositionMovementBODtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'accountingDate': value.accountingDate,
        'causalCode': value.causalCode,
        'causalDescription': value.causalDescription,
        'ctv': value.ctv,
        'ctvMarket': value.ctvMarket,
        'date': value.date,
        'exchange': value.exchange,
        'price': value.price,
        'qta': value.qta,
    };
}
exports.PrePositionMovementBODtoToJSON = PrePositionMovementBODtoToJSON;
