"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.elencoUltimiAccessiReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const ultimiAccessi_types_1 = require("./ultimiAccessi.types");
const moment = require("moment");
const initialState = {
    elencoUltimiAccessi: {},
    form: (0, ultimiAccessi_types_1.FormFiltriUltimiAccessiDtoFromJSONTyped)({}),
    parametri: (0, api_rest_1.ParametriAuditingDtoFromJSON)({})
};
const elencoUltimiAccessiReducer = (state = initialState, action) => {
    switch (action.type) {
        case ultimiAccessi_types_1.EReduxElencoUltimiAccessiActionTypes.ULTIMI_ACCESSI_GET_ULTIMI_ACCESSI_SUCCESS:
            return {
                ...state,
                elencoUltimiAccessi: state.form.numeroPagina == 1 ? action.payload : {
                    ...state.elencoUltimiAccessi,
                    listaAccessi: (state.elencoUltimiAccessi.listaAccessi || []).concat(action.payload.listaAccessi || [])
                }
            };
        case ultimiAccessi_types_1.EReduxElencoUltimiAccessiActionTypes.PARAMETRI_GET_PARAMETRI_SUCCESS:
            return {
                ...state,
                parametri: action.payload
            };
        case ultimiAccessi_types_1.EReduxElencoUltimiAccessiActionTypes.ULTIMI_ACCESSI_INTERVALLO:
            return {
                ...state,
                form: {
                    ...state.form,
                    intervallo: action.payload
                }
            };
        case ultimiAccessi_types_1.EReduxElencoUltimiAccessiActionTypes.ULTIMI_ACCESSI_NUMERO_PAGINA:
            return {
                ...state,
                form: {
                    ...state.form,
                    numeroPagina: action.payload
                }
            };
        case ultimiAccessi_types_1.EReduxElencoUltimiAccessiActionTypes.ULTIMI_ACCESSI_DATA_DA:
            return {
                ...state,
                form: {
                    ...state.form,
                    dataDa: action.payload,
                    dataDaDirty: true,
                    dataDaError: (Math.abs(moment(action.payload).diff(moment(state.form.dataA), 'days')) > 365) ? 'ultimiAccessi.Filtri.data.error' : '',
                    intervallo: initialState.form.intervallo
                }
            };
        case ultimiAccessi_types_1.EReduxElencoUltimiAccessiActionTypes.ULTIMI_ACCESSI_DATA_A:
            return {
                ...state,
                form: {
                    ...state.form,
                    dataA: action.payload,
                    dataADirty: true,
                    dataAError: (Math.abs(moment(state.form.dataDa).diff(moment(action.payload), 'days')) > 365) ? 'ultimiAccessi.Filtri.data.error' : '',
                    intervallo: initialState.form.intervallo
                }
            };
        case ultimiAccessi_types_1.EReduxElencoUltimiAccessiActionTypes.ULTIMI_ACCESSI_FORM_RESET:
            return {
                ...state,
                form: {
                    ...initialState.form,
                    dataDa: initialState.form.dataDa,
                    dataA: initialState.form.dataA,
                    numeroPagina: initialState.form.numeroPagina,
                    listLoader: initialState.form.listLoader
                }
            };
        case ultimiAccessi_types_1.EReduxElencoUltimiAccessiActionTypes.ULTIMI_ACCESSI_LIST_LOADER:
            return {
                ...state,
                form: {
                    ...state.form,
                    listLoader: action.payload
                }
            };
        case ultimiAccessi_types_1.EReduxElencoUltimiAccessiActionTypes.ULTIMI_ACCESSI_RESET_ELENCO:
            return {
                ...state,
                elencoUltimiAccessi: initialState.elencoUltimiAccessi
            };
        default:
            return state;
    }
};
exports.elencoUltimiAccessiReducer = elencoUltimiAccessiReducer;
exports.default = exports.elencoUltimiAccessiReducer;
