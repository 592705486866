"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RapportoCarteDtoToJSON = exports.RapportoCarteDtoFromJSONTyped = exports.RapportoCarteDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function RapportoCarteDtoFromJSON(json) {
    return RapportoCarteDtoFromJSONTyped(json, false);
}
exports.RapportoCarteDtoFromJSON = RapportoCarteDtoFromJSON;
function RapportoCarteDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'servizio': !runtime_1.exists(json, 'servizio') ? undefined : json['servizio'],
        'filiale': !runtime_1.exists(json, 'filiale') ? undefined : json['filiale'],
        'conto': !runtime_1.exists(json, 'conto') ? undefined : json['conto'],
    };
}
exports.RapportoCarteDtoFromJSONTyped = RapportoCarteDtoFromJSONTyped;
function RapportoCarteDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'servizio': value.servizio,
        'filiale': value.filiale,
        'conto': value.conto,
    };
}
exports.RapportoCarteDtoToJSON = RapportoCarteDtoToJSON;
