"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetAllMandatiSDD = exports.setMandatiRevocaSelezionata = exports.esportaMandatiSDDEXCEL = exports.esportaDettaglioMandatoSDDPDF = exports.esportaMandatiSDDPDF = exports.getInfoRevocaMandatoSDD = exports.autorizzaRevocaMandatoSDD = exports.setMandatiSDDRevocaSetStep = exports.resetMandatoSelezionato = exports.setMandatiSDDMandatoSelezionato = exports.resetMandatiSDD = exports.resetMandatiSDDFiltri = exports.setMandatiSDDStati = exports.setMandatiSDDRapportoSelezionato = exports.getParametriMandatiSDD = exports.getMandatiSDD = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_1 = require("../general");
const general_configuration_1 = require("../general/general.configuration");
const general_types_1 = require("../general/general.types");
const helpers_1 = require("../helpers");
const mandatiSDD_selectors_1 = require("./mandatiSDD.selectors");
/* get MandatiSDD - INIZIO */
function getMandatiSDDStart() {
    return {
        type: __1.EReduxActionTypes.GET_MANDATI_SDD_START
    };
}
function getMandatiSDDSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.GET_MANDATI_SDD_SUCCESS,
        payload
    };
}
function generalError(value, showGenericModalError) {
    return async (dispatch) => {
        dispatch((0, general_1.setError)(value, showGenericModalError));
    };
}
/**
 *
 * @param filtriRequest
 */
const getMandatiSDD = () => async (dispatch, getState, extraArguments) => {
    var _a, _b, _c, _d;
    const mandatiSDD = getState().mandatiSDD;
    dispatch((0, general_1.setLoader)(true));
    dispatch(getMandatiSDDStart());
    if (extraArguments.mock) {
        // import('../../mock/MandatiSDD_list.json').then(parametri => {
        //   dispatch(getMandatiSDDSuccess(parametri));
        // });
    }
    else {
        const form = {
            contoAddebito: ((_c = (_b = (_a = mandatiSDD.filter) === null || _a === void 0 ? void 0 : _a.rapportoSelezionato) === null || _b === void 0 ? void 0 : _b.rapporto) === null || _c === void 0 ? void 0 : _c.id) || 0,
            schema: 'CORE',
            stati: (_d = mandatiSDD.filter) === null || _d === void 0 ? void 0 : _d.statoMandato
        };
        extraArguments.getItem('jwt').then((jwt) => {
            const request = {
                filterMandatiDto: form
            };
            new api_rest_1.MandatiSddControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).archivioMandatiSdd(request).then((response) => {
                dispatch(getMandatiSDDSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_1.setLoader)(false));
            });
        });
    }
};
exports.getMandatiSDD = getMandatiSDD;
/* get MandatiSDD - FINE */
/* get parametri Mandati SDD - START */
function getParametriMandatiSDDStart() {
    return {
        type: __1.EReduxActionTypes.MANDATI_SDD_GET_PARAMETRI_START
    };
}
function getParametriMandatiSDDSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.MANDATI_SDD_GET_PARAMETRI_SUCCESS,
        payload
    };
}
const getParametriMandatiSDD = () => async (dispatch, _, extraArguments) => {
    dispatch((0, general_1.setLoader)(true));
    dispatch(getParametriMandatiSDDStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/assegni_parametri.json')).then(parametri => {
            dispatch(getParametriMandatiSDDSuccess((0, api_rest_1.ParametriMandatiSDDDtoFromJSON)(parametri)));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.MandatiSddControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametersMandatiSdd().then((response) => {
                var _a;
                dispatch(getParametriMandatiSDDSuccess(response));
                ((_a = response.rapporti) === null || _a === void 0 ? void 0 : _a.length) === 0 && dispatch((0, general_1.setNoRapporti)({ type: general_types_1.TypeNoRapporti.ADDEBITI_DIRETTI }));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_1.setLoader)(false));
            });
        });
    }
};
exports.getParametriMandatiSDD = getParametriMandatiSDD;
/* get parametri Mandati SDD - FINE */
function setMandatiSDDRapportoSelezionato(rapportoSelezionato) {
    return {
        type: __1.EReduxActionTypes.MANDATI_SDD_RAPPORTO_SELEZIONATO,
        payload: rapportoSelezionato
    };
}
exports.setMandatiSDDRapportoSelezionato = setMandatiSDDRapportoSelezionato;
function setMandatiSDDStati(tipologie) {
    return {
        type: __1.EReduxActionTypes.MANDATI_SDD_STATI_MANDATI,
        payload: tipologie
    };
}
exports.setMandatiSDDStati = setMandatiSDDStati;
function resetMandatiSDDFiltri() {
    return {
        type: __1.EReduxActionTypes.MANDATI_SDD_RESET_FILTRI
    };
}
exports.resetMandatiSDDFiltri = resetMandatiSDDFiltri;
function resetMandatiSDD() {
    return {
        type: __1.EReduxActionTypes.MANDATI_SDD_RESET
    };
}
exports.resetMandatiSDD = resetMandatiSDD;
function setMandatiSDDMandatoSelezionato(mandato) {
    return {
        type: __1.EReduxActionTypes.MANDATI_SDD_MANDATO_SELEZIONATO,
        payload: mandato
    };
}
exports.setMandatiSDDMandatoSelezionato = setMandatiSDDMandatoSelezionato;
function resetMandatoSelezionato() {
    return {
        type: __1.EReduxActionTypes.MANDATI_SDD_RESET_MANDATO_SELEZIONATO
    };
}
exports.resetMandatoSelezionato = resetMandatoSelezionato;
function setMandatiSDDRevocaSetStepAction(i) {
    return {
        type: __1.EReduxActionTypes.MANDATI_SDD_STEP_REVOCA,
        payload: i
    };
}
const setMandatiSDDRevocaSetStep = (i) => async (dispatch, _, extraArguments) => {
    dispatch(setMandatiSDDRevocaSetStepAction(i));
    if (extraArguments.platform == 'mobile') {
        dispatch((0, general_1.setBottomTabBarVisible)(i != 1));
    }
};
exports.setMandatiSDDRevocaSetStep = setMandatiSDDRevocaSetStep;
/* autorizza revoca - START */
function autorizzaRevocaMandatoSDDStart() {
    return {
        type: __1.EReduxActionTypes.MANDATI_SDD_AUTORIZZA_REVOCA_START
    };
}
function autorizzaRevocaMandatoSDDSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.MANDATI_SDD_AUTORIZZA_REVOCA_SUCCESS,
        payload
    };
}
const autorizzaRevocaMandatoSDD = (idDispositiva, credenziale, mandato) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_1.setLoader)(true));
    dispatch(autorizzaRevocaMandatoSDDStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/autorizza_bonifico.json')).then(parametri => {
            dispatch(autorizzaRevocaMandatoSDDSuccess((0, api_rest_1.EsitoAutorizzazioneDisposizioneMandatoSDDDtoFromJSON)(parametri)));
            dispatch((0, general_1.setLoader)(false));
        });
    }
    else {
        const requestParameters = {
            mandatiSddRevocaRequestIn: {
                credenziale,
                mandato
            }
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.MandatiSddControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).operazioneAutenticataMandatiSdd(requestParameters).then((response) => {
                dispatch(autorizzaRevocaMandatoSDDSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error), false));
            }).finally(() => {
                dispatch((0, general_1.setLoader)(false));
            });
        });
    }
};
exports.autorizzaRevocaMandatoSDD = autorizzaRevocaMandatoSDD;
/* autorizza revoca - FINE */
/* get info revoca - START */
function getInfoRevocaMandatoSDDStart() {
    return {
        type: __1.EReduxActionTypes.MANDATI_SDD_GET_INFO_REVOCA_START
    };
}
function getInfoRevocaMandatoSDDSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.MANDATI_SDD_GET_INFO_REVOCA_SUCCESS,
        payload
    };
}
const getInfoRevocaMandatoSDD = (idDispositiva) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_1.setLoader)(true));
    dispatch(getInfoRevocaMandatoSDDStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/autenticazione-bonifico.json')).then(parametri => {
            dispatch(getInfoRevocaMandatoSDDSuccess((0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)(parametri)));
            dispatch((0, general_1.setLoader)(false));
        });
    }
    else {
        const requestParameters = {
            idDispositiva
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.MandatiSddControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).authenticationRevocationInformationMandatiSdd(requestParameters).then((response) => {
                dispatch(getInfoRevocaMandatoSDDSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_1.setLoader)(false));
            });
        });
    }
};
exports.getInfoRevocaMandatoSDD = getInfoRevocaMandatoSDD;
/* get info revoca - FINE */
/* esporta PDF mandati sdd - START */
function esportaMandatiSDDPDFStart() {
    return {
        type: __1.EReduxActionTypes.MANDATI_SDD_ESPORTA_PDF_START
    };
}
function esportaMandatiSDDPDFSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.MANDATI_SDD_ESPORTA_PDF_SUCCESS,
        payload
    };
}
const esportaMandatiSDDPDF = (share, title, message) => async (dispatch, getState, extraArguments) => {
    dispatch(esportaMandatiSDDPDFStart());
    const mandatiSDD = getState().mandatiSDD;
    dispatch((0, general_1.setLoader)(true));
    if (extraArguments.mock) {
        /* TODO: vedere nella parte mockata cosa restituire
          import('../../mock/MandatiSDD.json').then(parametri => {
            dispatch(esportaMandatiSDDPDFSuccess(ListaMandatiSDDDtoFromJSON(parametri)));
          });
          */
    }
    else {
        const esportaMandatiSDDPDFRequest = {
            type: api_rest_1.ElencoMandatiSDDPdfTypeEnum.pdf,
            filterMandatiDto: (0, mandatiSDD_selectors_1.buildFilterExportElencoMandatiSDD)(mandatiSDD)
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.MandatiSddControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf')).elencoMandatiSDDPdf(esportaMandatiSDDPDFRequest).then((response) => {
                if (extraArguments.platform === 'desktop') {
                    (0, helpers_1.hadlePdfResponse)(response, (url) => dispatch((0, general_1.popUpBlockedPdf)(url)));
                }
                if (extraArguments.platform === 'mobile') {
                    (0, helpers_1.download_sharePDF)(extraArguments, 'MandatiSDD.pdf', response, share, title, message);
                }
                dispatch(esportaMandatiSDDPDFSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_1.setLoader)(false));
            });
        });
    }
};
exports.esportaMandatiSDDPDF = esportaMandatiSDDPDF;
/* esporta PDF MandatiSDD - FINE */
/* esporta PDF dettaglio MandatoSDD - START */
function esportaDettaglioMandatoSDDPDFStart() {
    return {
        type: __1.EReduxActionTypes.MANDATI_SDD_ESPORTA_DETTAGLIO_PDF_START
    };
}
function esportaDettaglioMandatoSDDPDFSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.MANDATI_SDD_ESPORTA_DETTAGLIO_PDF_SUCCESS,
        payload
    };
}
const esportaDettaglioMandatoSDDPDF = (filtro, share, title, message) => async (dispatch, getState, extraArguments) => {
    const mandatiSDD = getState().mandatiSDD;
    dispatch((0, general_1.setLoader)(true));
    dispatch(esportaDettaglioMandatoSDDPDFStart());
    if (extraArguments.mock) {
        /* TODO: vedere nella parte mockata cosa restituire
        import('../../mock/dettaglio_assegno.json').then(parametri => {
          dispatch(esportaDettaglioMandatoSDDPDFSuccess(ListaMovimentiDtoFromJSON(parametri)));
        });
        */
    }
    else {
        const filterMandati = filtro || (0, mandatiSDD_selectors_1.buildFilterExportDettaglioMandatoSDD)(mandatiSDD);
        const esportaDettaglioMandatoSDDPDFRequest = {
            filterMandatiDto: filterMandati
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.MandatiSddControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf')).dettaglioMandatiSDDPdf(esportaDettaglioMandatoSDDPDFRequest).then(async (response) => {
                if (extraArguments.platform === 'desktop') {
                    (0, helpers_1.hadlePdfResponse)(response, (url) => dispatch((0, general_1.popUpBlockedPdf)(url)));
                }
                if (extraArguments.platform === 'mobile') {
                    (0, helpers_1.download_sharePDF)(extraArguments, 'dettaglio_mandato.pdf', response, share, title, message);
                }
                dispatch(esportaDettaglioMandatoSDDPDFSuccess(response));
            }).catch(async (error) => {
                try {
                    dispatch(generalError(await (0, helpers_1.formatError)(error)));
                }
                catch (e) {
                    console.log(e);
                }
            }).finally(() => {
                dispatch((0, general_1.setLoader)(false));
            });
        });
    }
};
exports.esportaDettaglioMandatoSDDPDF = esportaDettaglioMandatoSDDPDF;
/* esporta PDF dettaglio MandatoSDD - FINE */
/* esporta EXCEL MandatiSDD - START */
function esportaMandatiSDDEXCELStart() {
    return {
        type: __1.EReduxActionTypes.MANDATI_SDD_ESPORTA_EXCEL_START
    };
}
function esportaMandatiSDDEXCELSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.MANDATI_SDD_ESPORTA_EXCEL_SUCCESS,
        payload
    };
}
const esportaMandatiSDDEXCEL = (share) => async (dispatch, getState, extraArguments) => {
    const mandatiSDD = getState().mandatiSDD;
    dispatch((0, general_1.setLoader)(true));
    dispatch(esportaMandatiSDDEXCELStart());
    if (extraArguments.mock) {
        /* TODO: vedere nella parte mockata cosa restituire
          import('../../mock/lista_MandatiSDD.json').then(parametri => {
            dispatch(esportaMandatiSDDEXCELSuccess(ListaMovimentiDtoFromJSON(parametri)));
          });
          */
    }
    else {
        const esportaMandatiSDDEXCELRequest = {
            type: api_rest_1.ElencoMandatiSDDPdfTypeEnum.excel,
            filterMandatiDto: (0, mandatiSDD_selectors_1.buildFilterExportElencoMandatiSDD)(mandatiSDD)
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.MandatiSddControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/vnd.ms-excel'))
                .elencoMandatiSDDPdf(esportaMandatiSDDEXCELRequest)
                .then((response) => {
                if (extraArguments.platform === 'desktop') {
                    (0, helpers_1.hadleExcelResponse)(response, 'MandatiSDD.xlsx');
                }
                if (extraArguments.platform === 'mobile') {
                    (0, helpers_1.download_shareXLS)(extraArguments, 'MandatiSDD.xls', response, share);
                }
                dispatch(esportaMandatiSDDEXCELSuccess(response));
            })
                .catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            })
                .finally(() => {
                dispatch((0, general_1.setLoader)(false));
            });
        });
    }
};
exports.esportaMandatiSDDEXCEL = esportaMandatiSDDEXCEL;
/* esporta EXCEL MandatiSDD - FINE */
function setMandatiRevocaSelezionata(payload) {
    return {
        type: __1.EReduxActionTypes.MANDATI_SDD_REVOCA_SELEZIONATA,
        payload
    };
}
exports.setMandatiRevocaSelezionata = setMandatiRevocaSelezionata;
function resetAllMandatiSDD() {
    return {
        type: __1.EReduxActionTypes.MANDATI_SDD_RESET_TUTTO
    };
}
exports.resetAllMandatiSDD = resetAllMandatiSDD;
