"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RateFinanziamentoDtoToJSON = exports.RateFinanziamentoDtoFromJSONTyped = exports.RateFinanziamentoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function RateFinanziamentoDtoFromJSON(json) {
    return RateFinanziamentoDtoFromJSONTyped(json, false);
}
exports.RateFinanziamentoDtoFromJSON = RateFinanziamentoDtoFromJSON;
function RateFinanziamentoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'rate': !runtime_1.exists(json, 'rate') ? undefined : (json['rate'].map(_1.RataFinanziamentoDtoFromJSON)),
        'numeroRateMora': !runtime_1.exists(json, 'numeroRateMora') ? undefined : json['numeroRateMora'],
        'numeroRateResidue': !runtime_1.exists(json, 'numeroRateResidue') ? undefined : json['numeroRateResidue'],
        'totaleEuro': !runtime_1.exists(json, 'totaleEuro') ? undefined : json['totaleEuro'],
        'totaleInteressi': !runtime_1.exists(json, 'totaleInteressi') ? undefined : json['totaleInteressi'],
        'totaleOneri': !runtime_1.exists(json, 'totaleOneri') ? undefined : json['totaleOneri'],
        'totaleCapitale': !runtime_1.exists(json, 'totaleCapitale') ? undefined : json['totaleCapitale'],
        'capitaleResiduo': !runtime_1.exists(json, 'capitaleResiduo') ? undefined : json['capitaleResiduo'],
        'interessiResidui': !runtime_1.exists(json, 'interessiResidui') ? undefined : json['interessiResidui'],
        'oneriResidui': !runtime_1.exists(json, 'oneriResidui') ? undefined : json['oneriResidui'],
        'importoComplessivoResiduo': !runtime_1.exists(json, 'importoComplessivoResiduo') ? undefined : json['importoComplessivoResiduo'],
        'importoTotaleMora': !runtime_1.exists(json, 'importoTotaleMora') ? undefined : json['importoTotaleMora'],
        'importoMora': !runtime_1.exists(json, 'importoMora') ? undefined : json['importoMora'],
        'importoRestituito': !runtime_1.exists(json, 'importoRestituito') ? undefined : json['importoRestituito'],
    };
}
exports.RateFinanziamentoDtoFromJSONTyped = RateFinanziamentoDtoFromJSONTyped;
function RateFinanziamentoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'rate': value.rate === undefined ? undefined : (value.rate.map(_1.RataFinanziamentoDtoToJSON)),
        'numeroRateMora': value.numeroRateMora,
        'numeroRateResidue': value.numeroRateResidue,
        'totaleEuro': value.totaleEuro,
        'totaleInteressi': value.totaleInteressi,
        'totaleOneri': value.totaleOneri,
        'totaleCapitale': value.totaleCapitale,
        'capitaleResiduo': value.capitaleResiduo,
        'interessiResidui': value.interessiResidui,
        'oneriResidui': value.oneriResidui,
        'importoComplessivoResiduo': value.importoComplessivoResiduo,
        'importoTotaleMora': value.importoTotaleMora,
        'importoMora': value.importoMora,
        'importoRestituito': value.importoRestituito,
    };
}
exports.RateFinanziamentoDtoToJSON = RateFinanziamentoDtoToJSON;
