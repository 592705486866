"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuestionnaireAnswersDtoToJSON = exports.QuestionnaireAnswersDtoFromJSONTyped = exports.QuestionnaireAnswersDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function QuestionnaireAnswersDtoFromJSON(json) {
    return QuestionnaireAnswersDtoFromJSONTyped(json, false);
}
exports.QuestionnaireAnswersDtoFromJSON = QuestionnaireAnswersDtoFromJSON;
function QuestionnaireAnswersDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'answersList': !runtime_1.exists(json, 'answersList') ? undefined : (json['answersList'].map(_1.AnswerDtoFromJSON)),
        'bankCode': !runtime_1.exists(json, 'bankCode') ? undefined : json['bankCode'],
        'canSave': !runtime_1.exists(json, 'canSave') ? undefined : json['canSave'],
        'coherence': !runtime_1.exists(json, 'coherence') ? undefined : (json['coherence'].map(_1.CoherenceResultDtoFromJSON)),
        'customerId': !runtime_1.exists(json, 'customerId') ? undefined : json['customerId'],
        'engineOutput': !runtime_1.exists(json, 'engineOutput') ? undefined : _1.RiskProfileDtoFromJSON(json['engineOutput']),
        'filialeGestore': !runtime_1.exists(json, 'filialeGestore') ? undefined : json['filialeGestore'],
        'idChiamante': !runtime_1.exists(json, 'idChiamante') ? undefined : json['idChiamante'],
        'idChiamata': !runtime_1.exists(json, 'idChiamata') ? undefined : json['idChiamata'],
        'idControl': !runtime_1.exists(json, 'idControl') ? undefined : json['idControl'],
        'idQuestionnaire': !runtime_1.exists(json, 'idQuestionnaire') ? undefined : json['idQuestionnaire'],
        'lang': !runtime_1.exists(json, 'lang') ? undefined : json['lang'],
        'matricolaGestore': !runtime_1.exists(json, 'matricolaGestore') ? undefined : json['matricolaGestore'],
        'message': !runtime_1.exists(json, 'message') ? undefined : json['message'],
        'returnCode': !runtime_1.exists(json, 'returnCode') ? undefined : json['returnCode'],
        'returnMessage': !runtime_1.exists(json, 'returnMessage') ? undefined : json['returnMessage'],
        'save': !runtime_1.exists(json, 'save') ? undefined : json['save'],
        'sectionCode': !runtime_1.exists(json, 'sectionCode') ? undefined : json['sectionCode'],
        'sections': !runtime_1.exists(json, 'sections') ? undefined : (json['sections'].map(_1.SectionDtoFromJSON)),
        'source': !runtime_1.exists(json, 'source') ? undefined : json['source'],
        'updateReason': !runtime_1.exists(json, 'updateReason') ? undefined : json['updateReason'],
        'userId': !runtime_1.exists(json, 'userId') ? undefined : json['userId'],
        'verifySign': !runtime_1.exists(json, 'verifySign') ? undefined : json['verifySign'],
    };
}
exports.QuestionnaireAnswersDtoFromJSONTyped = QuestionnaireAnswersDtoFromJSONTyped;
function QuestionnaireAnswersDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'answersList': value.answersList === undefined ? undefined : (value.answersList.map(_1.AnswerDtoToJSON)),
        'bankCode': value.bankCode,
        'canSave': value.canSave,
        'coherence': value.coherence === undefined ? undefined : (value.coherence.map(_1.CoherenceResultDtoToJSON)),
        'customerId': value.customerId,
        'engineOutput': _1.RiskProfileDtoToJSON(value.engineOutput),
        'filialeGestore': value.filialeGestore,
        'idChiamante': value.idChiamante,
        'idChiamata': value.idChiamata,
        'idControl': value.idControl,
        'idQuestionnaire': value.idQuestionnaire,
        'lang': value.lang,
        'matricolaGestore': value.matricolaGestore,
        'message': value.message,
        'returnCode': value.returnCode,
        'returnMessage': value.returnMessage,
        'save': value.save,
        'sectionCode': value.sectionCode,
        'sections': value.sections === undefined ? undefined : (value.sections.map(_1.SectionDtoToJSON)),
        'source': value.source,
        'updateReason': value.updateReason,
        'userId': value.userId,
        'verifySign': value.verifySign,
    };
}
exports.QuestionnaireAnswersDtoToJSON = QuestionnaireAnswersDtoToJSON;
