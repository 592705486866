"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ElencoNotificheInToJSON = exports.ElencoNotificheInFromJSONTyped = exports.ElencoNotificheInFromJSON = void 0;
const runtime_1 = require("../runtime");
function ElencoNotificheInFromJSON(json) {
    return ElencoNotificheInFromJSONTyped(json, false);
}
exports.ElencoNotificheInFromJSON = ElencoNotificheInFromJSON;
function ElencoNotificheInFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'indicePrimoRisultato': json['indicePrimoRisultato'],
        'numeroRisultati': !runtime_1.exists(json, 'numeroRisultati') ? undefined : json['numeroRisultati'],
        'dataDa': !runtime_1.exists(json, 'dataDa') ? undefined : (new Date(json['dataDa'])),
        'dataA': !runtime_1.exists(json, 'dataA') ? undefined : (new Date(json['dataA'])),
    };
}
exports.ElencoNotificheInFromJSONTyped = ElencoNotificheInFromJSONTyped;
function ElencoNotificheInToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'indicePrimoRisultato': value.indicePrimoRisultato,
        'numeroRisultati': value.numeroRisultati,
        'dataDa': value.dataDa === undefined ? undefined : (value.dataDa.toISOString()),
        'dataA': value.dataA === undefined ? undefined : (value.dataA.toISOString()),
    };
}
exports.ElencoNotificheInToJSON = ElencoNotificheInToJSON;
