"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BilancioControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class BilancioControllerApi extends runtime.BaseAPI {
    /**
     * Bilancio trimestrale
     */
    bilancioTrimestraleRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.criteriBilancioIn === null || requestParameters.criteriBilancioIn === undefined) {
                throw new runtime.RequiredError('criteriBilancioIn', 'Required parameter requestParameters.criteriBilancioIn was null or undefined when calling bilancioTrimestrale.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/bilancio/trimestrale`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CriteriBilancioInToJSON(requestParameters.criteriBilancioIn),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.BilancioDtoFromJSON(jsonValue));
        });
    }
    /**
     * Bilancio trimestrale
     */
    bilancioTrimestrale(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.bilancioTrimestraleRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Export in pdf elenco bilancio in base ai criteri inviati per il rapporto indicato
     */
    stampaElencoBilancioPdfRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.elencoBilancioDto === null || requestParameters.elencoBilancioDto === undefined) {
                throw new runtime.RequiredError('elencoBilancioDto', 'Required parameter requestParameters.elencoBilancioDto was null or undefined when calling stampaElencoBilancioPdf.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/bilancio/export/elencobilancio/pdf`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ElencoBilancioDtoToJSON(requestParameters.elencoBilancioDto),
            });
            return new runtime.BlobApiResponse(response);
        });
    }
    /**
     * Export in pdf elenco bilancio in base ai criteri inviati per il rapporto indicato
     */
    stampaElencoBilancioPdf(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.stampaElencoBilancioPdfRaw(requestParameters);
            return yield response.value();
        });
    }
}
exports.BilancioControllerApi = BilancioControllerApi;
