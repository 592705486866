"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocumentoDatiAnagraficiDtoToJSON = exports.DocumentoDatiAnagraficiDtoFromJSONTyped = exports.DocumentoDatiAnagraficiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function DocumentoDatiAnagraficiDtoFromJSON(json) {
    return DocumentoDatiAnagraficiDtoFromJSONTyped(json, false);
}
exports.DocumentoDatiAnagraficiDtoFromJSON = DocumentoDatiAnagraficiDtoFromJSON;
function DocumentoDatiAnagraficiDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codiceTipo': !runtime_1.exists(json, 'codiceTipo') ? undefined : json['codiceTipo'],
        'descrizioneTipo': !runtime_1.exists(json, 'descrizioneTipo') ? undefined : json['descrizioneTipo'],
        'numero': !runtime_1.exists(json, 'numero') ? undefined : json['numero'],
        'data': !runtime_1.exists(json, 'data') ? undefined : (new Date(json['data'])),
        'ente': !runtime_1.exists(json, 'ente') ? undefined : json['ente'],
    };
}
exports.DocumentoDatiAnagraficiDtoFromJSONTyped = DocumentoDatiAnagraficiDtoFromJSONTyped;
function DocumentoDatiAnagraficiDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codiceTipo': value.codiceTipo,
        'descrizioneTipo': value.descrizioneTipo,
        'numero': value.numero,
        'data': value.data === undefined ? undefined : (value.data.toISOString()),
        'ente': value.ente,
    };
}
exports.DocumentoDatiAnagraficiDtoToJSON = DocumentoDatiAnagraficiDtoToJSON;
