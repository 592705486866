"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VersamentoContoDepositoControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class VersamentoContoDepositoControllerApi extends runtime.BaseAPI {
    /**
     * Autorizzazione di una disposizione di versamento conto deposito
     */
    autorizzaVersamentoContoDepoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling autorizzaVersamentoContoDepo.');
            }
            if (requestParameters.credenzialeDispositivaDto === null || requestParameters.credenzialeDispositivaDto === undefined) {
                throw new runtime.RequiredError('credenzialeDispositivaDto', 'Required parameter requestParameters.credenzialeDispositivaDto was null or undefined when calling autorizzaVersamentoContoDepo.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/versamentocontodeposito/autorizza/{idDispositiva}`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CredenzialeDispositivaDtoToJSON(requestParameters.credenzialeDispositivaDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.EsitoAutorizzazioneDisposizioneBonificoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Autorizzazione di una disposizione di versamento conto deposito
     */
    autorizzaVersamentoContoDepo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.autorizzaVersamentoContoDepoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Esegue il controllo di validità di un nuovo versamento conto deposito in base ai valori specificati nell\'oggetto di richiesta
     */
    controllaVersamentoContoDepoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.bonificoSepaDto === null || requestParameters.bonificoSepaDto === undefined) {
                throw new runtime.RequiredError('bonificoSepaDto', 'Required parameter requestParameters.bonificoSepaDto was null or undefined when calling controllaVersamentoContoDepo.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/versamentocontodeposito/controllo`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.BonificoSepaDtoToJSON(requestParameters.bonificoSepaDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.BonificoSepaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Esegue il controllo di validità di un nuovo versamento conto deposito in base ai valori specificati nell\'oggetto di richiesta
     */
    controllaVersamentoContoDepo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.controllaVersamentoContoDepoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva per la revoca
     */
    informazioniAutenticazioneRevocaVersamentoContoDepoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling informazioniAutenticazioneRevocaVersamentoContoDepo.');
            }
            const queryParameters = {};
            if (requestParameters.chiamataUscente !== undefined) {
                queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
            }
            if (requestParameters.eseguiInit !== undefined) {
                queryParameters['eseguiInit'] = requestParameters.eseguiInit;
            }
            if (requestParameters.offline !== undefined) {
                queryParameters['offline'] = requestParameters.offline;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/versamentocontodeposito/infoautenticazione/{idDispositiva}/revoca`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva per la revoca
     */
    informazioniAutenticazioneRevocaVersamentoContoDepo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.informazioniAutenticazioneRevocaVersamentoContoDepoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva
     */
    informazioniAutenticazioneVersamentoContoDepoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling informazioniAutenticazioneVersamentoContoDepo.');
            }
            const queryParameters = {};
            if (requestParameters.chiamataUscente !== undefined) {
                queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
            }
            if (requestParameters.eseguiInit !== undefined) {
                queryParameters['eseguiInit'] = requestParameters.eseguiInit;
            }
            if (requestParameters.offline !== undefined) {
                queryParameters['offline'] = requestParameters.offline;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/versamentocontodeposito/infoautenticazione/{idDispositiva}`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva
     */
    informazioniAutenticazioneVersamentoContoDepo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.informazioniAutenticazioneVersamentoContoDepoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Autorizzazione di una disposizione di revoca versamento conto deposito
     */
    operazioneAutenticataVersamentoContoDepoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling operazioneAutenticataVersamentoContoDepo.');
            }
            if (requestParameters.credenzialeDispositivaDto === null || requestParameters.credenzialeDispositivaDto === undefined) {
                throw new runtime.RequiredError('credenzialeDispositivaDto', 'Required parameter requestParameters.credenzialeDispositivaDto was null or undefined when calling operazioneAutenticataVersamentoContoDepo.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/versamentocontodeposito/autorizza/{idDispositiva}/revoca`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CredenzialeDispositivaDtoToJSON(requestParameters.credenzialeDispositivaDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.EsitoAutorizzazioneDisposizioneBonificoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Autorizzazione di una disposizione di revoca versamento conto deposito
     */
    operazioneAutenticataVersamentoContoDepo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.operazioneAutenticataVersamentoContoDepoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Lettura parametri versamento conto deposito
     */
    parametriVersamentoContoDepoRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/versamentocontodeposito/parametri`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ParametriBonificoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Lettura parametri versamento conto deposito
     */
    parametriVersamentoContoDepo() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.parametriVersamentoContoDepoRaw();
            return yield response.value();
        });
    }
}
exports.VersamentoContoDepositoControllerApi = VersamentoContoDepositoControllerApi;
