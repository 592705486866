import { dashboardPrestiti, dettaglioPrestitoIdea, FiltroFinanziamentiDto, FinanziamentoDto, formPrestiti, getDashboardPrestiti, getDettaglioInformativaPrestitoIdea, getParametriPrestiti, getParametriPrestitoIdea, loaderActive, menuList, parametriPrestiti, parametriPrestitoIdea, salvaWidgets, setPrestitiRapportoSelezionato, widgetSaldo, widgetsUtente } from "@sparkasse/commons";
import ButtonText2 from "component/buttonText2/buttonText2.component";
import History from "component/history/history.component";
import SelectRapportoMini, { IRapporto } from "component/SelectRapportoMini/SelectRapportoMini";
import { currencyFormat, dateFormat } from "helpers/utilities";
import React, { useEffect, useMemo, useState } from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { AppState } from "../../reducers";

export const WIDGET_PRESTITO = "menu.prestito_green";

const WidgetPrestiti: React.FC<Props> = (props: Props) => {

  const { t } = useTranslation();

  const {
    parametri,
    form,
    dashboardPrestiti,
    parametriIdea,
    dettaglioPrestito,
    getDettaglioInformativaPrestitoIdea,
    getParametriPrestitoIdea,
    getParametriPrestiti,
    setPrestitiRapportoSelezionato,
    getDashboardPrestiti,
    menu
  } = props;

  const widget = props.widgetsUtente?.widgets?.find(w => w.widgetId === WIDGET_PRESTITO);
  const [chartDataIdea, setChartDataIdea] = useState<Array<number>>([])
  const [importiIdeaArray, setImportiIdeaArray] = useState<Array<number> | undefined>([])
  const widgetFinanziamenti = props.menu?.profiloMenu?.items?.find(item => item.id === 'menu.finanziare');

  useEffect(() => {
    const widgetPrestito = widgetFinanziamenti?.submenu?.find(menu => menu.id == 'menu.prestiti');
    if (widgetPrestito && widgetPrestito?.submenu?.find(sub => sub.id === 'menu.prestito_idea')) {
      getParametriPrestitoIdea();
    }
    if (widgetPrestito && widgetPrestito?.submenu?.find(sub => sub.id === 'menu.prestito_green')) {
      getParametriPrestiti();
    }
  }, [widgetFinanziamenti]);


  const prestiti = useMemo(() => {
    if (parametriIdea.finanziamenti !== undefined && parametri.finanziamenti !== undefined && parametri.finanziamenti.length > 0 && parametriIdea.finanziamenti.length > 0) {
      return parametri.finanziamenti.concat(parametriIdea.finanziamenti)
    }
    else if ((parametriIdea.finanziamenti === undefined || parametriIdea.finanziamenti.length === 0) && parametri.finanziamenti !== undefined && parametri.finanziamenti.length > 0) {
      return parametri.finanziamenti
    }
    else if ((parametri.finanziamenti === undefined || parametri.finanziamenti.length === 0) && parametriIdea.finanziamenti !== undefined && parametriIdea.finanziamenti.length > 0) {
      return parametriIdea.finanziamenti
    }

  }, [parametriIdea, parametri.finanziamenti])


  const rapporti = useMemo(() => {
    return prestiti;
  }, [prestiti]);


  const selectedRapporto: FinanziamentoDto | undefined = useMemo(() => {
    return rapporti && rapporti?.length > 0
      ? rapporti?.find(r => r.codiceRapporto == Number(widget?.option?.codicerapporto)) || rapporti?.[0]
      : undefined;
  }, [widget?.option?.codicerapporto, rapporti]);


  useEffect(() => {
    const selezione = selectedRapporto || parametri?.finanziamenti?.[0];
    selezione && setPrestitiRapportoSelezionato(selezione);
  }, [parametri?.finanziamenti, selectedRapporto]);


  useEffect(() => {
    if (selectedRapporto && selectedRapporto.codiceRapporto) {
      const request: FiltroFinanziamentiDto = {
        codiceRapporto: selectedRapporto?.codiceRapporto,
        progressivoRapporto: selectedRapporto?.progressivoRapporto,
      }
      if (selectedRapporto && selectedRapporto.tipoPrestito !== 'COFIDIS') {

        getDashboardPrestiti(request);
      }
    }
  }, [selectedRapporto && selectedRapporto.codiceRapporto]);


  useEffect(() => {
    if (selectedRapporto && selectedRapporto.codiceRapporto && selectedRapporto.tipoPrestito === 'COFIDIS') {
      getDettaglioInformativaPrestitoIdea(selectedRapporto.codiceRapporto)
    }
  }, [selectedRapporto && selectedRapporto.tipoPrestito])

  const onSelectAccount = (item: IRapporto) => {
    if (item.id !== (selectedRapporto?.codiceRapporto || 0).toString()) {
      const _widgetSaldo = props.widgetSaldo ? [props.widgetSaldo] : [];
      props.salvaWidgets((props.widgetsUtente?.widgets?.map(w => w.widgetId === WIDGET_PRESTITO ? {
        ...w,
        option: {
          toggle: true,
          interval: 0,
          codicerapporto: item.id
        }
      } : w) || []).concat(_widgetSaldo));
    }
  }

  const legenda = [
    t('home.widgets.prestiti.interessiResidui'),
    t('home.widgets.prestiti.capitaleResiduo'),
  ];

  const legendaIdea = [
    t('prestitoIdea.importoPrestitoVersato'),
    t('prestitoIdea.importoPrestitoResiduo')
  ]

  const importi = [
    dashboardPrestiti?.datiPieBar?.interessi?.residuoImporto || 0,
    dashboardPrestiti?.datiPieBar?.capitale?.residuoImporto || 0
  ];


  useEffect(() => {
    if (selectedRapporto && selectedRapporto.tipoPrestito === 'COFIDIS') {
      const array = !!dettaglioPrestito && dettaglioPrestito?.importoErogato && dettaglioPrestito?.debitoResiduo &&
        [(dettaglioPrestito?.importoErogato) - (dettaglioPrestito?.debitoResiduo) || 0,
        dettaglioPrestito?.debitoResiduo || 0
        ]
      array && setImportiIdeaArray(array)
    }
  }, [dettaglioPrestito, selectedRapporto && selectedRapporto.tipoPrestito, rapporti])

  const totale = dashboardPrestiti?.datiPieBar?.finanziamento?.residuoImporto;
  const totaleIdea = dettaglioPrestito?.importoErogato

  const option: any = {
    states: {
      active: {
        filter: {
          type: 'none',
          value: 0
        }
      },
      hover: {
        filter: {
          type: 'none',
          value: 0
        }
      }
    },
    plotOptions: {
      pie: {
        size: 160,
        expandOnClick: false
      }
    },
    dataLabels: { enabled: false },
    labels: [
      legenda[0] + ' ' + currencyFormat(importi[0], 'EUR'),
      legenda[1] + ' ' + currencyFormat(importi[1], 'EUR')
    ],
    legend: { show: false },
    colors: ["#e1478a", "#47b7e1"],
    fill: {
      colors: ["#e1478a", "#47b7e1"],
    },
    tooltip: {
      enabled: true,
      fillSeriesColor: false,
      y: {
        formatter: function (val: any) {
          return val + "%";
        },
        title: {
          formatter: function (seriesName: any) {
            return seriesName;
          }
        }
      }
    }
  };


  const optionIdea: any = {
    states: {
      active: {
        filter: {
          type: 'none',
          value: 0
        }
      },
      hover: {
        filter: {
          type: 'none',
          value: 0
        }
      }
    },
    plotOptions: {
      pie: {
        size: 160,
        expandOnClick: false
      }
    },
    dataLabels: { enabled: false },
    labels: [
      legendaIdea[0] + ' ' + currencyFormat(importiIdeaArray && importiIdeaArray[0], 'EUR'),
      legendaIdea[1] + ' ' + currencyFormat(importiIdeaArray && importiIdeaArray[1], 'EUR')
    ],
    legend: { show: false },
    colors: ["#e1478a", "#47b7e1"],
    fill: {
      colors: ["#e1478a", "#47b7e1"],
    },
    tooltip: {
      enabled: true,
      fillSeriesColor: false,
      y: {
        formatter: function (val: any) {
          return val + "%";
        },
        title: {
          formatter: function (seriesName: any) {
            return seriesName;
          }
        }
      }
    }
  };

  const seriesPieChart: number[] = importi.map(i => totale ? Math.round(i * 10000 / totale) / 100 : 0);


  useEffect(() => {

    if (selectedRapporto && selectedRapporto.tipoPrestito === 'COFIDIS') {
      const data = importiIdeaArray ? importiIdeaArray.map(i => totaleIdea ? Math.round(i * 10000 / totaleIdea) / 100 : 0) : []
      setChartDataIdea(data)
    }
  }, [dettaglioPrestito, importiIdeaArray, selectedRapporto && selectedRapporto.tipoPrestito, rapporti])



  const items: Array<IRapporto> = !!rapporti && rapporti.map(f => ({ id: f.codiceRapporto?.toString() || "", description: `${t('home.widgets.prestiti.numero')} ${f.numeroFinanziamento || "-"}` })) || [];

  return (
    <div className="widget widget-prestiti">

      <p className="widget-title">
        {t('home.widgets.prestiti.title')}
      </p>

      <div className="underline-short" />

      {(rapporti && rapporti.length || 0) > 0 &&
        <>

          {(rapporti?.length || 0) > 1 &&
            <div>
              <SelectRapportoMini
                items={items}
                selectedItem={selectedRapporto?.codiceRapporto?.toString() || rapporti?.[0].codiceRapporto?.toString() || ""}
                onSelect={onSelectAccount}
                numeroMinimoItems={2}
              />
            </div>
          }

          <div className="chartView-widget-prestiti">

            {!!selectedRapporto && selectedRapporto.tipoPrestito !== 'COFIDIS' && <Chart
              options={option}
              series={seriesPieChart}
              type="donut"
              width="160"
              height="160"
            />}

            {!!selectedRapporto && selectedRapporto.tipoPrestito === 'COFIDIS' && <Chart
              options={optionIdea}
              series={chartDataIdea}
              type="donut"
              width="160"
              height="160"
            />}

            <div className="titleChartView-widget-prestiti">

              <p className="txtTitleChart-widget-prestiti">
                {!!selectedRapporto && selectedRapporto.tipoPrestito !== 'COFIDIS' ? t('home.widgets.prestiti.debitoResiduo') : t('prestitoIdea.importoPrestitoVersato')}
              </p>
              <p className="txtAmountChart-widget-prestiti is-saldo">
                {currencyFormat(selectedRapporto && selectedRapporto.tipoPrestito !== 'COFIDIS' ? totale : totaleIdea)}
                <span className="widget-currency"> EUR</span>
              </p>

              <div className="txtAmountChart-widget-prestiti">
                {selectedRapporto && selectedRapporto.tipoPrestito !== 'COFIDIS' && legenda.map((label: string, index: number) => (
                  <div key={`p-${index}`} className="legendView1col1-widget-prestiti">
                    <div
                      style={{
                        backgroundColor: option.colors[index],
                        height: 20,
                        width: 20,
                        marginRight: 5,
                      }}
                    />
                    <p className="txtTitleLegend-widget-prestiti">{label}</p>
                    <p className="txtImportoLegend-widget-prestiti is-saldo">{currencyFormat(importi[index])} EUR</p>
                  </div>
                ))}
                {selectedRapporto && selectedRapporto.tipoPrestito === 'COFIDIS' && !!importiIdeaArray && legendaIdea.map((label: string, index: number) => (
                  <div key={`p-${index}`} className="legendView1col1-widget-prestiti">
                    <div
                      style={{
                        backgroundColor: option.colors[index],
                        height: 20,
                        width: 20,
                        marginRight: 5,
                      }}
                    />
                    <p className="txtTitleLegend-widget-prestiti">{label}</p>
                    <p className="txtImportoLegend-widget-prestiti is-saldo">{currencyFormat(importiIdeaArray[index])} EUR</p>
                  </div>
                ))}
              </div>

            </div>

          </div>

          <div className="chartView-widget-prestiti">
            <div className="widget-info-col-prestiti">
              <div className="widget-info-col-prestiti-label">{!!selectedRapporto && selectedRapporto.tipoPrestito === 'COFIDIS' ? t('prestitoIdea.dataUltimaRata') : t('home.widgets.prestiti.prossimaRata')}</div>
              <div className="widget-info-col-prestiti-value">
                {!!selectedRapporto && selectedRapporto.tipoPrestito !== 'COFIDIS' ? dateFormat(form.rapportoSelezionato?.dataProssimaScadenza) : dateFormat(dettaglioPrestito.dataScadenza)}
              </div>
            </div>
            <div className="widget-info-col-prestiti">
              <div className="widget-info-col-prestiti-label">{t('home.widgets.prestiti.importoProssimaRata')}</div>
              <div className="widget-info-col-prestiti-value is-saldo">
                {form.rapportoSelezionato?.importoProssimaScadenza || dettaglioPrestito.importoRata ?
                  `${currencyFormat(!!selectedRapporto && selectedRapporto.tipoPrestito !== 'COFIDIS' ? form.rapportoSelezionato?.importoProssimaScadenza : dettaglioPrestito.importoRata)}` : "-"}
                <span className="widget-currency"> EUR</span>
              </div>
            </div>
          </div>

        </>
      }

      {(rapporti && rapporti.length || 0) == 0 &&
        <p className="widget-placeholder">
          {t('home.widgets.prestiti.nessunProdotto')}
        </p>
      }

      <ButtonText2
        className="widget-vai-a-dettaglio"
        descrizione={t('home.widgets.prestiti.cta')}
        onClick={() => selectedRapporto && selectedRapporto.tipoPrestito !== 'COFIDIS' ?

          History.push("/piano-prestiti", {
            idFinanziamentoIniziale: widget?.option?.codicerapporto
          }) : History.push("/prestito-cofidis", {
            idFinanziamentoIniziale: widget?.option?.codicerapporto
          })
        }
        img="navigazione_avanti"
      />

    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  parametri: parametriPrestiti(state.prestiti),
  form: formPrestiti(state.prestiti),
  loader: loaderActive(state.general),
  dashboardPrestiti: dashboardPrestiti(state.prestiti),
  widgetsUtente: widgetsUtente(state.widgets),
  widgetSaldo: widgetSaldo(state.widgets),
  parametriIdea: parametriPrestitoIdea(state.prestitoIdea),
  dettaglioPrestito: dettaglioPrestitoIdea(state.prestitoIdea),
  menu: menuList(state.menu)

});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getParametriPrestiti,
      setPrestitiRapportoSelezionato,
      getDashboardPrestiti,
      salvaWidgets,
      getDettaglioInformativaPrestitoIdea,
      getParametriPrestitoIdea,
    },
    dispatch
  );

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(WidgetPrestiti);
