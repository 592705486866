import {
  NotificaMenuDto,
  ProfiloMenu,
  ProfiloMenuDto,
  selectedAbi,
} from "@sparkasse/commons";
import BankIcon from "component/Icon/icon.component";
import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import IDToPath from "../../component/IDToPath";
import SidebarMenuNavigatorArea from "./SidebarMenu-navigator-area";
import SidebarMenuNavigatorLvl2 from "./SidebarMenu-navigator-lvl2";
import useHandleAreaMenu from "./_hooks/useHandleAreaMenu";

interface Props {
  menu: ProfiloMenuDto | null;
  items: ProfiloMenu[] | undefined;
  isMenuExpanded: boolean;
  setMenuExpanded: (v: boolean) => any;
  navigateTo: (path: string) => void;
  onMenu: (e?: any) => void;
  selectedLvl1: number | null;
  setSelectedLvl1: (v: number | null) => void;
  onChangeLanguage: (lingua: string) => void;
  currentLanguage: string;
  onLogout: () => void;
  notificheMenu: NotificaMenuDto[] | undefined;
  areaItemsLvl1: ProfiloMenu[];
  setAreaItemsLvl1: (v: ProfiloMenu[]) => void;
  areaItemsLvl2: ProfiloMenu[];
  setAreaItemsLvl2: (v: ProfiloMenu[]) => void;
  areaLabelLvl1: string;
  setAreaLabelLvl1: (v: string) => void;
  areaLabelLvl2: string;
  setAreaLabelLvl2: (v: string) => void;
  isMenuComunicazioniVisible: boolean;
  isMenuProfiloVisible: boolean;
  isMenuAssistenzaVisible: boolean;
  isNotificaMenuComunicazioniVisible: boolean;
  isNotificaMenuProfiloVisible: boolean;
  isNotificaMenuAssistenzaVisible: boolean;
}

const SideBarMenu = (props: Props) => {
  const { t } = useTranslation();

  const [selectedLvl2, setSelectedLvl2] = useState<number | null>(null);
  const [menuOffset, setMenuOffset] = useState<number>(0);

  const {
    menu,
    items,
    isMenuExpanded,
    setMenuExpanded,
    navigateTo,
    onMenu,
    selectedLvl1,
    setSelectedLvl1,
    onChangeLanguage,
    currentLanguage,
    onLogout,
    notificheMenu,
    areaItemsLvl1,
    setAreaItemsLvl1,
    areaItemsLvl2,
    setAreaItemsLvl2,
    areaLabelLvl1,
    setAreaLabelLvl1,
    areaLabelLvl2,
    setAreaLabelLvl2,
    isMenuComunicazioniVisible,
    isMenuProfiloVisible,
    isMenuAssistenzaVisible,
    isNotificaMenuComunicazioniVisible,
    isNotificaMenuProfiloVisible,
    isNotificaMenuAssistenzaVisible,
  } = props;

  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const selectionElement = (e: ProfiloMenu, i: number) => {
    setAreaItemsLvl1([]);
    setAreaLabelLvl1("");
    setAreaItemsLvl2([]);
    setAreaLabelLvl2("");
    if (e.submenu?.length === 0) {
      IDToPath(e.label, navigateTo, setMenuExpanded);
      setSelectedLvl1(null);
    } else {
      setSelectedLvl1(i);
      setSelectedLvl2(null);
      setIsMenuOpened(true);

      if (isMenuExpanded) {
        onMenu();
      }

      if (isMenuOpened) {
        if (i !== selectedLvl1) {
          setSelectedLvl1(i);
          setSelectedLvl2(null);
        } else {
          setSelectedLvl1(null);
          setSelectedLvl2(null);
          setAreaItemsLvl1([]);
          setAreaLabelLvl1("");
          setAreaItemsLvl2([]);
          setAreaLabelLvl2("");
          setIsMenuOpened(false);
        }
      }
    }
  };

  const clickOnWrapper = (e: any) => {
    if (
      e.target.className === "menu-wrapper menu-desktop-mode" ||
      e.target.className === "menu-wrapper menu-mobile-mode" ||
      e.target.className === "menu-wrapper-area" ||
      e.target.className === "menu-main-wrapper" ||
      e.target.className === "ham-menu"
    ) {
      resetAreaItemsAndLabels();

      setSelectedLvl1(null);
      setSelectedLvl2(null);
      setIsMenuOpened(false);
    }
  };

  const renderTooltip = (txt: string) => {
    return <Tooltip id="button-tooltip">{txt}</Tooltip>;
  };

  const openSubMenuArea = (items: ProfiloMenu[] | undefined, label: string) => {
    setAreaItemsLvl1(items || []);
    setAreaLabelLvl1(label);
  };

  const {
    areaItems,
    areaLabel,
    onClickBackArea,
    onClickElement,
    resetAreaItemsAndLabels,
  } = useHandleAreaMenu({
    areaItemsLvl1,
    areaItemsLvl2,
    areaLabelLvl1,
    areaLabelLvl2,
    isMenuExpanded,
    setAreaItemsLvl1,
    setAreaItemsLvl2,
    setAreaLabelLvl1,
    setAreaLabelLvl2,
    setMenuExpanded,
    navigateTo,
    onMenu,
  });

  const isMenuAreaOpen =
    areaItemsLvl1?.length > 0 ||
    areaItemsLvl2?.length > 0 ||
    selectedLvl1 != null;

  const checkAddressBarHeight = () => {
    let windowHeight = window.innerHeight;
    let appHeight = document.getElementById("root")?.clientHeight || 0;
    let topBarHeight =
      document.getElementsByClassName("main-header")[0]?.clientHeight || 0;
    let _menuOffset = Math.abs(windowHeight - appHeight - topBarHeight);

    setMenuOffset(_menuOffset);
  };

  const canShowSubMenu = (i: number) => {
    return selectedLvl1 != null && items?.[selectedLvl1] && selectedLvl1 === i;
  };

  window.addEventListener("resize", checkAddressBarHeight);

  return (
    <nav
      className={`menu-container ${isMenuAreaOpen ? "menu-area-open" : ""} ${
        isMenuExpanded ? "menu-container-expanded" : ""
      }`}
    >
      {areaItemsLvl1.length > 0 && areaLabelLvl1?.length > 0 && (
        <div
          className="menu-wrapper-area sidebar-area"
          onClick={(e) => clickOnWrapper(e)}
        >
          <SidebarMenuNavigatorArea
            isFirstLevel={
              areaItemsLvl2.length == 0 && areaLabelLvl2.length == 0
            }
            items={areaItems}
            label={areaLabel}
            notificheMenu={notificheMenu}
            location="sidebar-area"
            onClickBack={onClickBackArea}
            onClickElement={onClickElement}
          />
        </div>
      )}

      {items && (
        <div className="menu-main-wrapper" onClick={(e) => clickOnWrapper(e)}>
          <div
            className="menu-bar-expanded"
            onScroll={() => checkAddressBarHeight()}
            style={{ height: `calc(100vh - ${menuOffset}px)` }}
          >
            <div className="menu-row-expanded-header">
              {isMenuComunicazioniVisible && (
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip(t("home.navbar.comunicazioni"))}
                >
                  <button
                    className={
                      isNotificaMenuComunicazioniVisible
                        ? "has-notification"
                        : undefined
                    }
                    onClick={() =>
                      openSubMenuArea(
                        menu?.comunicazioniItems,
                        t("home.navbar.comunicazioni")
                      )
                    }
                    aria-label={t("home.navbar.comunicazioni")}
                    aria-expanded="false"
                  >
                    <BankIcon title="comunicazioni" etichetta="" white />
                  </button>
                </OverlayTrigger>
              )}

              {isMenuProfiloVisible && (
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip(t("home.navbar.areaPersonale"))}
                >
                  <button
                    className={
                      isNotificaMenuProfiloVisible
                        ? "has-notification"
                        : undefined
                    }
                    onClick={() =>
                      openSubMenuArea(
                        menu?.profiloItems,
                        t("home.navbar.areaPersonale")
                      )
                    }
                    aria-label={t("home.navbar.areaPersonale")}
                    aria-expanded="false"
                  >
                    <BankIcon title="profilo" etichetta="" white />
                  </button>
                </OverlayTrigger>
              )}

              {isMenuAssistenzaVisible && (
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip(t("home.navbar.assistenza"))}
                >
                  <button
                    className={
                      isNotificaMenuAssistenzaVisible
                        ? "has-notification"
                        : undefined
                    }
                    onClick={() =>
                      openSubMenuArea(
                        menu?.assistenzaItems,
                        t("home.navbar.assistenza")
                      )
                    }
                    aria-label={t("home.navbar.assistenza")}
                    aria-expanded="false"
                  >
                    <BankIcon title="assistenza" etichetta="" white />
                  </button>
                </OverlayTrigger>
              )}
            </div>

            <ul>
              {items?.map((el: ProfiloMenu, i: number) => (
                <React.Fragment key={`expanded_item_${i}`}>
                  {el.submenu?.length ? (
                    <li>
                      <button
                        key={"row_expanded_" + i}
                        className={"menu-row-expanded"}
                        onClick={() => selectionElement(el, i)}
                        aria-expanded={selectedLvl1 === i ? "true" : "false"}
                      >
                        <div id={el.id || ""} className={"menu-row-icon"}>
                          <BankIcon
                            title={el.id || "notFound"}
                            white={true}
                            etichetta=""
                          />
                        </div>
                        <span
                          className="menu-row-desc"
                          aria-label={`${t(
                            "accessibilita.button.apriChiudi"
                          )} ${t(el.label || "notFound")}`}
                        >
                          {t(el.label || "notFound")}
                        </span>
                      </button>
                    </li>
                  ) : (
                    // SPKON-5857 - Accessibilità
                    <li key={"li_expanded_" + i}>
                      <a
                        key={"row_expanded_" + i}
                        id={el.id || ""}
                        className={"menu-row-expanded"}
                        onClick={() => selectionElement(el, i)}
                      >
                        <div id={el.id || ""} className={"menu-row-icon"}>
                          <BankIcon
                            title={el.id || "notFound"}
                            white={true}
                            etichetta=""
                          />
                        </div>
                        <span className="menu-row-desc">
                          {t(el.label || "notFound")}
                        </span>
                      </a>
                    </li>
                  )}
                </React.Fragment>
              ))}
            </ul>
            <div id="menuex" className="menu-row-expanded-floating">
              {selectedAbi === "06045" && (
                <button
                  className="menu-row-expanded"
                  onClick={() =>
                    onChangeLanguage(currentLanguage === "it" ? "de" : "it")
                  }
                >
                  <div className="menu-row-icon">
                    <BankIcon
                      title={`lingua-${currentLanguage === "it" ? "de" : "it"}`}
                      etichetta=""
                    />
                  </div>
                  <span className="menu-row-desc">
                    {t(
                      `home.navbar.language.${
                        currentLanguage === "it" ? "de" : "it"
                      }`
                    )}
                  </span>
                </button>
              )}
              <button className="menu-row-expanded" onClick={onLogout}>
                <div className="menu-row-icon">
                  <BankIcon title="logout" etichetta="" />
                </div>
                <span className="menu-row-desc">{t("home.navbar.logout")}</span>
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="menu-bar">
        <ul>
          {items?.map((el: ProfiloMenu, i: number) => (
            <React.Fragment key={`collapsed_item_${i}`}>
              {el.submenu?.length ? (
                <li key={"li_" + i}>
                  <button
                    key={"row_" + i}
                    id={el.id || ""}
                    className={`menu-row ${
                      selectedLvl1 === i ? "menu-row-selected" : ""
                    }`}
                    onClick={() => selectionElement(el, i)}
                    aria-expanded={selectedLvl1 === i ? "true" : "false"}
                  >
                    <div>
                      <BankIcon
                        title={el.id || "notFound"}
                        white={true}
                        etichetta=""
                      />
                    </div>
                    <span
                      className="menu-row-label"
                      aria-label={`${t("accessibilita.button.apriChiudi")} ${t(
                        el.label || "notFound"
                      )}`}
                    >
                      {t(el.label || "notFound")}
                    </span>
                  </button>
                  {canShowSubMenu(i) && (
                    <div
                      className="menu-wrapper menu-desktop-mode"
                      onClick={(e) => clickOnWrapper(e)}
                    >
                      <SidebarMenuNavigatorLvl2
                        items={items}
                        navigateTo={navigateTo}
                        setMenuExpanded={setMenuExpanded}
                        selectedLvl1={selectedLvl1!}
                        setSelectedLvl1={setSelectedLvl1}
                        selectedLvl2={selectedLvl2}
                        setSelectedLvl2={setSelectedLvl2}
                      />
                    </div>
                  )}
                </li>
              ) : (
                // SPKON-5857 - Accessibilità
                <li key={"li_" + i}>
                  <a
                    href="#"
                    key={"row_" + i}
                    id={el.id || ""}
                    className={`menu-row ${
                      selectedLvl1 === i ? "menu-row-selected" : ""
                    }`}
                    onClick={() => selectionElement(el, i)}
                  >
                    <div>
                      <BankIcon
                        title={el.id || "notFound"}
                        white={true}
                        etichetta=""
                      />
                    </div>
                    <span className="menu-row-label">
                      {t(el.label || "notFound")}
                    </span>
                  </a>
                </li>
              )}
            </React.Fragment>
          ))}
        </ul>
      </div>

      {selectedLvl1 != null && items?.[selectedLvl1] && (
        <div
          className="menu-wrapper menu-mobile-mode"
          onClick={(e) => clickOnWrapper(e)}
        >
          <SidebarMenuNavigatorLvl2
            items={items}
            navigateTo={navigateTo}
            setMenuExpanded={setMenuExpanded}
            selectedLvl1={selectedLvl1}
            setSelectedLvl1={setSelectedLvl1}
            selectedLvl2={selectedLvl2}
            setSelectedLvl2={setSelectedLvl2}
          />
        </div>
      )}
    </nav>
  );
};

export default SideBarMenu;
