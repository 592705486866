"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shouldUpdateBozzaRav = exports.updateBozzaRav = exports.getRavFromBozza = exports.delBozzaRav = exports.addBozzaRav = exports.getBozzaRav = exports.getBozzeRav = exports.setRavBozzaSalvata = exports.setFormSubmittedRav = exports.setRavSetRapportoSelezionato = exports.setRavSetStep = exports.setRavSetOrdinanteEff = exports.setRavSetCodiceRAV = exports.setRavSetDataEsec = exports.setRavSetImport = exports.resetRav = exports.autorizzaRevocaRav = exports.getInfoRevocaRav = exports.setRavRevocaSetStep = exports.setProvenienzaRevocaRav = exports.setRiepilogoRevocaRav = exports.controlloRav = exports.getInfoAutenticazioneRav = exports.autorizzaRav = exports.getParametriRav = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const general_types_1 = require("../general/general.types");
const helpers_1 = require("../helpers");
const rav_selectors_1 = require("./rav.selectors");
const moment = require("moment");
/* get parametri - START */
function getParametriRavStart() {
    return {
        type: __1.EReduxActionTypes.RAV_GET_PARAMETRI_START
    };
}
function getParametriRavSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.RAV_GET_PARAMETRI_SUCCESS,
        payload
    };
}
function getParametriPerRipetiRavSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.RAV_GET_PARAMETRI_PER_RIPETI_SUCCESS,
        payload
    };
}
function generalError(value, showGenericModalError, sendErrorToMatomo, dispositiva, nomeEvento) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value, showGenericModalError));
        (!!dispositiva && !!sendErrorToMatomo) && sendErrorToMatomo('RAV_REVOCA', `code_error RAV ${value.status} / ${value === null || value === void 0 ? void 0 : value.message} / ${value === null || value === void 0 ? void 0 : value.timestamp}`, 'REVOCA_DISPOSITIVA');
    };
}
const getParametriRav = (ripeti) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getParametriRavStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/parametri_rav.json')).then(parametri => {
            dispatch(ripeti ? getParametriPerRipetiRavSuccess((0, api_rest_1.ParametriRavDtoFromJSON)(parametri)) : getParametriRavSuccess((0, api_rest_1.ParametriRavDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.RavControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametersRav().then((response) => {
                var _a;
                dispatch(ripeti ? getParametriPerRipetiRavSuccess(response) : getParametriRavSuccess(response));
                ((_a = response.rapporti) === null || _a === void 0 ? void 0 : _a.length) === 0 && dispatch((0, general_actions_1.setNoRapporti)({ type: general_types_1.TypeNoRapporti.RAV }));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getParametriRav = getParametriRav;
/* get parametri - FINE */
/* autorizza rav - START */
function autorizzaRavStart() {
    return {
        type: __1.EReduxActionTypes.RAV_AUTORIZZA_START
    };
}
function autorizzaRavSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.RAV_AUTORIZZA_SUCCESS,
        payload
    };
}
const autorizzaRav = (idDispositiva, credenziale, rav) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(autorizzaRavStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/autorizza_rav.json')).then(parametri => {
            dispatch(autorizzaRavSuccess((0, api_rest_1.EsitoAutorizzazioneDisposizioneRavDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const getInfoDispositivaRavRequest = {
            idDispositiva,
            credenzialeDispositivaDto: credenziale
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.RavControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).authorizeRav(getInfoDispositivaRavRequest).then((response) => {
                dispatch(autorizzaRavSuccess(response));
                extraArguments.sendEventToMatomo('RAV', `esito dispositiva RAV ${response.stato}`, 'ESITO_DISPOSITIVA');
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error), false, extraArguments.sendEventToMatomo, 'RAV', 'ESITO_DISPOSITIVA'));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.autorizzaRav = autorizzaRav;
/* autorizza rav - FINE */
/* get info autenticazione rav - START */
function getInfoAutenticazioneRavStart() {
    return {
        type: __1.EReduxActionTypes.RAV_GET_INFO_AUTENTICAZIONE_RAV_START
    };
}
function getInfoAutenticazioneRavSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.RAV_GET_INFO_AUTENTICAZIONE_RAV_SUCCESS,
        payload
    };
}
const getInfoAutenticazioneRav = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getInfoAutenticazioneRavStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/autenticazione-bonifico.json')).then(parametri => {
            dispatch(getInfoAutenticazioneRavSuccess((0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const requestParameters = {
            idDispositiva,
            chiamataUscente,
            eseguiInit,
            offline: !!offline
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.RavControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).authenticationInformationRav(requestParameters).then((response) => {
                dispatch(getInfoAutenticazioneRavSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getInfoAutenticazioneRav = getInfoAutenticazioneRav;
/* get info autenticazione rav - FINE */
/* controllo rav - START */
function controlloRavStart() {
    return {
        type: __1.EReduxActionTypes.RAV_CONTROLLO_START
    };
}
function controlloRavSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.RAV_CONTROLLO_SUCCESS,
        payload
    };
}
const controlloRav = () => async (dispatch, getState, extraArguments) => {
    const rav = getState().rav;
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(controlloRavStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/controllo_rav.json')).then(parametri => {
            dispatch(controlloRavSuccess((0, api_rest_1.RavDtoFromJSON)(parametri)));
        });
    }
    else {
        const controllRavRequest = {
            ravDto: {
                ...(0, rav_selectors_1.buildRav)(rav),
                bozza: {
                    dataUltimaModifica: moment().toDate()
                }
            }
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.RavControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).checkRav(controllRavRequest).then((response) => {
                dispatch(controlloRavSuccess(response));
                dispatch((0, general_actions_1.setLoader)(false));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.controlloRav = controlloRav;
/* controllo rav - FINE */
function setRiepilogoRevocaRav(riepilogo) {
    return {
        type: __1.EReduxActionTypes.RAV_SET_RIEPILOGO_REVOCA,
        payload: riepilogo
    };
}
exports.setRiepilogoRevocaRav = setRiepilogoRevocaRav;
function setProvenienzaRevocaRav(provenienza) {
    return {
        type: __1.EReduxActionTypes.RAV_SET_PROVENIENZA,
        payload: provenienza
    };
}
exports.setProvenienzaRevocaRav = setProvenienzaRevocaRav;
function setRavRevocaSetStepAction(i) {
    return {
        type: __1.EReduxActionTypes.RAV_STEP_REVOCA,
        payload: i
    };
}
const setRavRevocaSetStep = (i) => async (dispatch, _, extraArguments) => {
    dispatch(setRavRevocaSetStepAction(i));
    if (extraArguments.platform == 'mobile') {
        dispatch((0, general_actions_1.setBottomTabBarVisible)(i != 1));
    }
};
exports.setRavRevocaSetStep = setRavRevocaSetStep;
/* get info revoca - START */
function getInfoRevocaRavStart() {
    return {
        type: __1.EReduxActionTypes.RAV_GET_INFO_REVOCA_START
    };
}
function getInfoRevocaRavSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.RAV_GET_INFO_REVOCA_SUCCESS,
        payload
    };
}
const getInfoRevocaRav = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getInfoRevocaRavStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/autenticazione-bonifico.json')).then(parametri => {
            dispatch(getInfoRevocaRavSuccess((0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const requestParameters = {
            idDispositiva,
            funzioneDispositiva: '',
            chiamataUscente,
            eseguiInit,
            offline: !!offline
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.RavControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).authenticationRevocationInformationRav(requestParameters).then((response) => {
                dispatch(getInfoRevocaRavSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getInfoRevocaRav = getInfoRevocaRav;
/* get info revoca - FINE */
/* autorizza revoca - START */
function autorizzaRevocaRavStart() {
    return {
        type: __1.EReduxActionTypes.RAV_AUTORIZZA_REVOCA_START
    };
}
function autorizzaRevocaRavSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.RAV_AUTORIZZA_REVOCA_SUCCESS,
        payload
    };
}
const autorizzaRevocaRav = (idDispositiva, credenziale, rav) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(autorizzaRevocaRavStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/autorizza_revoca_rav.json')).then(parametri => {
            dispatch(autorizzaRevocaRavSuccess((0, api_rest_1.EsitoAutorizzazioneDisposizioneRavDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const requestParameters = {
            ravRevocaRequestInDto: {
                credenziale,
                annoInserimento: rav.annoInserimento,
                codiceRapporto: rav.contoAddebito,
                progressivoInserimento: Number(rav.progressivoInserimento)
            }
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.RavControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).authenticationRevocationRav(requestParameters).then((response) => {
                dispatch(autorizzaRevocaRavSuccess(response));
                extraArguments.sendEventToMatomo('RAV_REVOCA', `esito revoca dispositiva RAV ${response.stato}`, 'REVOCA_DISPOSITIVA');
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error), false, extraArguments.sendEventToMatomo, 'RAV_REVOCA', 'REVOCA_DISPOSITIVA'));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.autorizzaRevocaRav = autorizzaRevocaRav;
/* get autorizza revoca - FINE */
function resetRav() {
    return {
        type: __1.EReduxActionTypes.RAV_RESET
    };
}
exports.resetRav = resetRav;
function setRavSetImport(i) {
    return {
        type: __1.EReduxActionTypes.RAV_IMPORTO,
        payload: i
    };
}
exports.setRavSetImport = setRavSetImport;
function setRavSetDataEsec(txt) {
    return {
        type: __1.EReduxActionTypes.RAV_DATA_ESEC,
        payload: txt
    };
}
exports.setRavSetDataEsec = setRavSetDataEsec;
function setRavSetCodiceRAV(txt) {
    return {
        type: __1.EReduxActionTypes.RAV_CODICE_RAV,
        payload: txt
    };
}
exports.setRavSetCodiceRAV = setRavSetCodiceRAV;
function setRavSetOrdinanteEff(txt) {
    return {
        type: __1.EReduxActionTypes.RAV_ORDINANTE_EFF,
        payload: txt
    };
}
exports.setRavSetOrdinanteEff = setRavSetOrdinanteEff;
function setRavSetStepAction(i) {
    return {
        type: __1.EReduxActionTypes.RAV_STEP,
        payload: i
    };
}
const setRavSetStep = (i) => async (dispatch, _, extraArguments) => {
    dispatch(setRavSetStepAction(i));
    if (extraArguments.platform == 'mobile') {
        dispatch((0, general_actions_1.setBottomTabBarVisible)(i != 2));
    }
};
exports.setRavSetStep = setRavSetStep;
function setRavSetRapportoSelezionato(rapportoSelezionato) {
    return {
        type: __1.EReduxActionTypes.RAV_SET_RAPPORTO_SELEZIONATO,
        payload: rapportoSelezionato
    };
}
exports.setRavSetRapportoSelezionato = setRavSetRapportoSelezionato;
function setFormSubmittedRav(bSelect) {
    return {
        type: __1.EReduxActionTypes.RAV_FORM_SUBMITTED,
        payload: bSelect
    };
}
exports.setFormSubmittedRav = setFormSubmittedRav;
function setRavBozzaSalvata(b) {
    return {
        type: __1.EReduxActionTypes.RAV_SET_BOZZA_SALVATA,
        payload: b
    };
}
exports.setRavBozzaSalvata = setRavBozzaSalvata;
/* get bozze rav - START */
function getBozzeRavStart() {
    return {
        type: __1.EReduxActionTypes.RAV_GET_BOZZE_START
    };
}
function getBozzeRavSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.RAV_GET_BOZZE_SUCCESS,
        payload
    };
}
const getBozzeRav = (request) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getBozzeRavStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/bozze_rav.json')).then(bozze => {
            dispatch(getBozzeRavSuccess((0, api_rest_1.RavsDtoFromJSON)(bozze)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const getBozzeRavRequest = {
            bozzeInDto: request
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.RavControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getDraftListRav(getBozzeRavRequest).then((response) => {
                dispatch(getBozzeRavSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getBozzeRav = getBozzeRav;
/* get bozze rav - FINE */
/* get bozza rav - START */
function getBozzaRavStart() {
    return {
        type: __1.EReduxActionTypes.RAV_GET_BOZZA_START
    };
}
function getBozzaRavSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.RAV_GET_BOZZA_SUCCESS,
        payload
    };
}
const getBozzaRav = (id) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getBozzaRavStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/bozza_rav.json')).then(parametri => {
            dispatch(getBozzaRavSuccess((0, api_rest_1.RavDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const getBozzaRavRequest = {
            id
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.RavControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getDraftByIdRav(getBozzaRavRequest).then((response) => {
                dispatch(getBozzaRavSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getBozzaRav = getBozzaRav;
/* get bozza rav  - FINE */
/* add bozza rav  - START */
function addBozzaRavStart() {
    return {
        type: __1.EReduxActionTypes.RAV_ADD_BOZZA_START
    };
}
function addBozzaRavSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.RAV_ADD_BOZZA_SUCCESS,
        payload
    };
}
const addBozzaRav = () => async (dispatch, getState, extraArguments) => {
    const rav = getState().rav;
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(addBozzaRavStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/bozza_rav_response.json')).then(parametri => {
            dispatch(addBozzaRavSuccess((0, api_rest_1.RavDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const addBozzaRavRequest = {
            ravDto: {
                ...(0, rav_selectors_1.buildRav)(rav),
                bozza: {
                    descrizione: `${rav.form.codiceRAV}, ${(0, helpers_1.formattedNumberToNumber)(rav.form.importo)}`,
                    dataUltimaModifica: moment().toDate()
                }
            }
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.RavControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).saveDraftRav(addBozzaRavRequest).then((response) => {
                dispatch((0, general_actions_1.addSnackbar)('rav.nuovaBozzaRavSalvata'));
                dispatch(addBozzaRavSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.addBozzaRav = addBozzaRav;
/* add bozza rav - FINE */
/* del bozza rav - START */
function delBozzaRavStart() {
    return {
        type: __1.EReduxActionTypes.RAV_DEL_BOZZA_START
    };
}
function delBozzaRavSuccess(id) {
    return {
        type: __1.EReduxActionTypes.RAV_DEL_BOZZA_SUCCESS,
        payload: id
    };
}
const delBozzaRav = (id) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(delBozzaRavStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/oneclick.json')).then(parametri => {
            dispatch(delBozzaRavSuccess(id));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const delBozzaRavRequest = {
            id
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.RavControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).deleteDraftRav(delBozzaRavRequest).then(() => {
                dispatch(delBozzaRavSuccess(id));
                dispatch((0, general_actions_1.addSnackbar)('rav.bozzaEliminataConSuccesso'));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.delBozzaRav = delBozzaRav;
/* del bozza rav - FINE */
/* get rav from bozza - START */
function getRavFromBozza(payload, noBozza = false) {
    return {
        type: __1.EReduxActionTypes.RAV_GET_RAV_FROM_BOZZA,
        payload,
        noBozza
    };
}
exports.getRavFromBozza = getRavFromBozza;
//update bozza
function updateBozzeRavStart() {
    return {
        type: __1.EReduxActionTypes.RAV_UPDATE_BOZZA_START
    };
}
function updateBozzaRavSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.RAV_UPDATE_BOZZA_SUCCESS,
        payload
    };
}
const updateBozzaRav = () => async (dispatch, getState, extraArguments) => {
    const rav = getState().rav;
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(updateBozzeRavStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/bozza_rav_response.json')).then(bozza => {
            dispatch(updateBozzaRavSuccess((0, api_rest_1.RavDtoFromJSON)(bozza)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const idCurrentBozza = rav.currentBozza.id || 0;
        const updateBozzaRavRequest = {
            id: idCurrentBozza,
            ravDto: {
                id: idCurrentBozza,
                ...(0, rav_selectors_1.buildRav)(rav),
                bozza: {
                    dataUltimaModifica: moment().toDate()
                }
            }
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.RavControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).updateDraftRav(updateBozzaRavRequest).then((response) => {
                dispatch(updateBozzaRavSuccess(response));
                dispatch((0, general_actions_1.addSnackbar)('rav.bozzaRavAggiornata'));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.updateBozzaRav = updateBozzaRav;
const shouldUpdateBozzaRav = () => async (dispatch, getState, extraArguments) => {
    const state = getState().rav;
    if ((0, rav_selectors_1.checkUpdateBozzaRav)(state)) {
        dispatch((0, exports.updateBozzaRav)());
    }
    else {
        dispatch((0, exports.addBozzaRav)());
    }
};
exports.shouldUpdateBozzaRav = shouldUpdateBozzaRav;
