"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.titoliQuotatiReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const titoliQuotati_types_1 = require("./titoliQuotati.types");
const initialState = {
    titoli: (0, api_rest_1.QuotazioniTitoliDtoFromJSON)({}),
    mercati: (0, api_rest_1.MercatiDtoFromJSON)({}),
    titoliQuotatiDettaglio: {
        dettaglioTitolo: (0, api_rest_1.QuotazioneDettaglioTitoloDtoFromJSON)({}),
        datiIntradayTitolo: (0, api_rest_1.DatiIntradayDtoFromJSON)({}),
        schedaTitolo: (0, api_rest_1.SchedaTitoloTrading2DtoFromJSON)({}),
        datiStoriciTitolo: (0, api_rest_1.DatiStoriciDtoFromJSON)({}),
        tipoGrafico: titoliQuotati_types_1.TipoGraficoTitoliQuotatiEsteri.Settimana1,
        datiSettimana: (0, api_rest_1.DatiStoriciDtoFromJSON)({}),
        datiMese: (0, api_rest_1.DatiStoriciDtoFromJSON)({}),
        datiAnno: (0, api_rest_1.DatiStoriciDtoFromJSON)({}),
    },
    form: {
        mercato: '',
        denominazione: '',
        titoloSelezionato: (0, api_rest_1.QuotazioneTitoloDtoFromJSON)({}),
        offset: 0
    }
};
const titoliQuotatiReducer = (state = initialState, action) => {
    switch (action.type) {
        case titoliQuotati_types_1.EReduxTitoliQuotatiActionTypes.TITOLI_QUOTATI_GET_MERCATI_SUCCESS:
            return {
                ...initialState,
                mercati: action.payload
            };
        case titoliQuotati_types_1.EReduxTitoliQuotatiActionTypes.TITOLI_QUOTATI_GET_TITOLI_SUCCESS:
            return {
                ...state,
                titoli: !action.offset
                    ? action.payload
                    : {
                        ...state.titoli,
                        quotazioni: (state.titoli.quotazioni || []).concat(action.payload.quotazioni || [])
                    }
            };
        case titoliQuotati_types_1.EReduxTitoliQuotatiActionTypes.TITOLI_QUOTATI_GET_DETTAGLIO_TITOLO_SUCCESS:
            return {
                ...state,
                titoliQuotatiDettaglio: {
                    ...state.titoliQuotatiDettaglio,
                    dettaglioTitolo: action.payload
                }
            };
        case titoliQuotati_types_1.EReduxTitoliQuotatiActionTypes.TITOLI_QUOTATI_GET_SCHEDA_TITOLO_SUCCESS:
            return {
                ...state,
                titoliQuotatiDettaglio: {
                    ...state.titoliQuotatiDettaglio,
                    schedaTitolo: action.payload
                }
            };
        case titoliQuotati_types_1.EReduxTitoliQuotatiActionTypes.TITOLI_QUOTATI_GET_DATI_INTRADAY_TITOLO_SUCCESS:
            return {
                ...state,
                titoliQuotatiDettaglio: {
                    ...state.titoliQuotatiDettaglio,
                    datiIntradayTitolo: action.payload,
                    aggiornamento: action.aggiornamento
                }
            };
        case titoliQuotati_types_1.EReduxTitoliQuotatiActionTypes.TITOLI_QUOTATI_GET_DATI_STORICI_TITOLO_SUCCESS:
            return {
                ...state,
                titoliQuotatiDettaglio: {
                    ...state.titoliQuotatiDettaglio,
                    aggiornamento: action.aggiornamento,
                    datiStoriciTitolo: action.payload,
                    datiSettimana: action.tipo === action.tipologiaGrafico.Settimana1 ? action.payload : state.titoliQuotatiDettaglio.datiSettimana,
                    datiMese: action.tipo === action.tipologiaGrafico.Mese1 ? action.payload : state.titoliQuotatiDettaglio.datiMese,
                    datiAnno: action.tipo === action.tipologiaGrafico.Anno1 ? action.payload : state.titoliQuotatiDettaglio.datiAnno,
                }
            };
        case titoliQuotati_types_1.EReduxTitoliQuotatiActionTypes.SET_TITOLI_QUOTATI_MERCATO:
            return {
                ...state,
                form: {
                    ...state.form,
                    mercato: action.payload
                }
            };
        case titoliQuotati_types_1.EReduxTitoliQuotatiActionTypes.SET_TITOLI_QUOTATI_DENOMINAZIONE:
            return {
                ...state,
                form: {
                    ...state.form,
                    denominazione: action.payload
                }
            };
        case titoliQuotati_types_1.EReduxTitoliQuotatiActionTypes.TITOLI_QUOTATI_RESET_FORM:
            return {
                ...state,
                form: initialState.form
            };
        case titoliQuotati_types_1.EReduxTitoliQuotatiActionTypes.TITOLI_QUOTATI_RESET:
            return {
                ...initialState
            };
        case titoliQuotati_types_1.EReduxTitoliQuotatiActionTypes.TITOLI_QUOTATI_SET_TITOLO_SELEZIONATO:
            return {
                ...state,
                form: {
                    ...state.form,
                    titoloSelezionato: action.payload
                }
            };
        case titoliQuotati_types_1.EReduxTitoliQuotatiActionTypes.TITOLI_QUOTATI_SET_OFFSET:
            return {
                ...state,
                form: {
                    ...state.form,
                    offset: action.payload
                }
            };
        case titoliQuotati_types_1.EReduxTitoliQuotatiActionTypes.TITOLI_QUOTATI_RESET_DETTAGLIO:
            return {
                ...state,
                titoliQuotatiDettaglio: initialState.titoliQuotatiDettaglio
            };
        case titoliQuotati_types_1.EReduxTitoliQuotatiActionTypes.TITOLI_QUOTATI_SET_TIPO_GRAFICO:
            return {
                ...state,
                titoliQuotatiDettaglio: {
                    ...state.titoliQuotatiDettaglio,
                    tipoGrafico: action.payload
                }
            };
        case titoliQuotati_types_1.EReduxTitoliQuotatiActionTypes.TITOLI_QUOTATI_AGGIORNA_GRAFICO:
            return {
                ...state,
                titoliQuotatiDettaglio: {
                    ...state.titoliQuotatiDettaglio,
                    aggiornamento: action.aggiornamento,
                    datiIntradayTitolo: (action.tipo === titoliQuotati_types_1.TipoGraficoTitoliQuotati.Ore1 || action.tipo === titoliQuotati_types_1.TipoGraficoTitoliQuotati.Giorni1 || action.tipo === titoliQuotati_types_1.TipoGraficoTitoliQuotati.Ore4) ? action.payload : initialState.titoliQuotatiDettaglio.datiIntradayTitolo,
                    datiStoriciTitolo: (action.tipo === titoliQuotati_types_1.TipoGraficoTitoliQuotati.Ore1 || action.tipo === titoliQuotati_types_1.TipoGraficoTitoliQuotati.Giorni1 || action.tipo === titoliQuotati_types_1.TipoGraficoTitoliQuotati.Ore4) ? action.payload : initialState.titoliQuotatiDettaglio.datiStoriciTitolo,
                    datiSettimana: action.tipo === titoliQuotati_types_1.TipoGraficoTitoliQuotati.Settimana1 ? action.payload : initialState.titoliQuotatiDettaglio.datiSettimana,
                    datiMese: action.tipo === titoliQuotati_types_1.TipoGraficoTitoliQuotati.Mese1 ? action.payload : initialState.titoliQuotatiDettaglio.datiMese,
                    datiAnno: action.tipo === titoliQuotati_types_1.TipoGraficoTitoliQuotati.Anno1 ? action.payload : initialState.titoliQuotatiDettaglio.datiAnno,
                }
            };
        case titoliQuotati_types_1.EReduxTitoliQuotatiActionTypes.TITOLI_QUOTATI_ESTERI_AGGIORNA_GRAFICO:
            return {
                ...state,
                titoliQuotatiDettaglio: {
                    ...state.titoliQuotatiDettaglio,
                    aggiornamento: action.aggiornamento,
                    datiIntradayTitolo: initialState.titoliQuotatiDettaglio.datiIntradayTitolo,
                    datiStoriciTitolo: (action.tipo === titoliQuotati_types_1.TipoGraficoTitoliQuotatiEsteri.Settimana1 || action.tipo === titoliQuotati_types_1.TipoGraficoTitoliQuotatiEsteri.Mese1 || action.tipo === titoliQuotati_types_1.TipoGraficoTitoliQuotatiEsteri.Anno1) ? action.payload : initialState.titoliQuotatiDettaglio.datiStoriciTitolo,
                    datiSettimana: action.tipo === titoliQuotati_types_1.TipoGraficoTitoliQuotatiEsteri.Settimana1 ? action.payload : initialState.titoliQuotatiDettaglio.datiSettimana,
                    datiMese: action.tipo === titoliQuotati_types_1.TipoGraficoTitoliQuotatiEsteri.Mese1 ? action.payload : initialState.titoliQuotatiDettaglio.datiMese,
                    datiAnno: action.tipo === titoliQuotati_types_1.TipoGraficoTitoliQuotatiEsteri.Anno1 ? action.payload : initialState.titoliQuotatiDettaglio.datiAnno,
                }
            };
        default:
            return state;
    }
};
exports.titoliQuotatiReducer = titoliQuotatiReducer;
exports.default = exports.titoliQuotatiReducer;
