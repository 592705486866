"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocumentoIdentitaControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class DocumentoIdentitaControllerApi extends runtime.BaseAPI {
    /**
     * Apre una pratica di rinnovo documento di identità
     */
    apriPraticaRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.apriPraticaInDto === null || requestParameters.apriPraticaInDto === undefined) {
                throw new runtime.RequiredError('apriPraticaInDto', 'Required parameter requestParameters.apriPraticaInDto was null or undefined when calling apriPratica.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/docidentita/apripratica`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ApriPraticaInDtoToJSON(requestParameters.apriPraticaInDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ApriPraticaOutDtoFromJSON(jsonValue));
        });
    }
    /**
     * Apre una pratica di rinnovo documento di identità
     */
    apriPratica(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.apriPraticaRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Carica un nuovo documento di identità
     */
    caricaDocumentoIdentitaRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.caricaDocumentoIdentitaInDto === null || requestParameters.caricaDocumentoIdentitaInDto === undefined) {
                throw new runtime.RequiredError('caricaDocumentoIdentitaInDto', 'Required parameter requestParameters.caricaDocumentoIdentitaInDto was null or undefined when calling caricaDocumentoIdentita.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/docidentita/carica`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CaricaDocumentoIdentitaInDtoToJSON(requestParameters.caricaDocumentoIdentitaInDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.CaricaDocumentoIdentitaOutDtoFromJSON(jsonValue));
        });
    }
    /**
     * Carica un nuovo documento di identità
     */
    caricaDocumentoIdentita(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.caricaDocumentoIdentitaRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce i dati rilevati al documento di identità caricato
     */
    daticaricamentoDocumentoIdentitaRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.tokenIdentifier === null || requestParameters.tokenIdentifier === undefined) {
                throw new runtime.RequiredError('tokenIdentifier', 'Required parameter requestParameters.tokenIdentifier was null or undefined when calling daticaricamentoDocumentoIdentita.');
            }
            const queryParameters = {};
            if (requestParameters.tokenIdentifier !== undefined) {
                queryParameters['tokenIdentifier'] = requestParameters.tokenIdentifier;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/docidentita/daticaricamento`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.DatiCaricamentoDocumentoIdentitaOutDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce i dati rilevati al documento di identità caricato
     */
    daticaricamentoDocumentoIdentita(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.daticaricamentoDocumentoIdentitaRaw(requestParameters);
            return yield response.value();
        });
    }
}
exports.DocumentoIdentitaControllerApi = DocumentoIdentitaControllerApi;
