"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterBillerDtoRequestOptionEnum = exports.FilterBillerDtoToJSON = exports.FilterBillerDtoFromJSONTyped = exports.FilterBillerDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function FilterBillerDtoFromJSON(json) {
    return FilterBillerDtoFromJSONTyped(json, false);
}
exports.FilterBillerDtoFromJSON = FilterBillerDtoFromJSON;
function FilterBillerDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'requestOption': !runtime_1.exists(json, 'requestOption') ? undefined : json['requestOption'],
        'requestParam': !runtime_1.exists(json, 'requestParam') ? undefined : json['requestParam'],
        'soloPreferiti': !runtime_1.exists(json, 'soloPreferiti') ? undefined : json['soloPreferiti'],
    };
}
exports.FilterBillerDtoFromJSONTyped = FilterBillerDtoFromJSONTyped;
function FilterBillerDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'requestOption': value.requestOption,
        'requestParam': value.requestParam,
        'soloPreferiti': value.soloPreferiti,
    };
}
exports.FilterBillerDtoToJSON = FilterBillerDtoToJSON;
/**
* @export
* @enum {string}
*/
var FilterBillerDtoRequestOptionEnum;
(function (FilterBillerDtoRequestOptionEnum) {
    FilterBillerDtoRequestOptionEnum["DenomCodice"] = "DenomCodice";
    FilterBillerDtoRequestOptionEnum["IdFisc"] = "IdFisc";
    FilterBillerDtoRequestOptionEnum["Denom"] = "Denom";
    FilterBillerDtoRequestOptionEnum["Codice"] = "Codice";
})(FilterBillerDtoRequestOptionEnum = exports.FilterBillerDtoRequestOptionEnum || (exports.FilterBillerDtoRequestOptionEnum = {}));
