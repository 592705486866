"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuotazioneCambioDtoToJSON = exports.QuotazioneCambioDtoFromJSONTyped = exports.QuotazioneCambioDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function QuotazioneCambioDtoFromJSON(json) {
    return QuotazioneCambioDtoFromJSONTyped(json, false);
}
exports.QuotazioneCambioDtoFromJSON = QuotazioneCambioDtoFromJSON;
function QuotazioneCambioDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'mercato': !runtime_1.exists(json, 'mercato') ? undefined : json['mercato'],
        'codice': !runtime_1.exists(json, 'codice') ? undefined : json['codice'],
        'desc': !runtime_1.exists(json, 'desc') ? undefined : json['desc'],
        'data': !runtime_1.exists(json, 'data') ? undefined : (new Date(json['data'])),
        'valoreMax': !runtime_1.exists(json, 'valoreMax') ? undefined : json['valoreMax'],
        'valoreMin': !runtime_1.exists(json, 'valoreMin') ? undefined : json['valoreMin'],
        'apertura': !runtime_1.exists(json, 'apertura') ? undefined : json['apertura'],
        'chiusura': !runtime_1.exists(json, 'chiusura') ? undefined : json['chiusura'],
        'miglioreLettera': !runtime_1.exists(json, 'miglioreLettera') ? undefined : json['miglioreLettera'],
        'miglioreDenaro': !runtime_1.exists(json, 'miglioreDenaro') ? undefined : json['miglioreDenaro'],
        'fixing': !runtime_1.exists(json, 'fixing') ? undefined : json['fixing'],
        'fixingDate': !runtime_1.exists(json, 'fixingDate') ? undefined : (new Date(json['fixingDate'])),
    };
}
exports.QuotazioneCambioDtoFromJSONTyped = QuotazioneCambioDtoFromJSONTyped;
function QuotazioneCambioDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'mercato': value.mercato,
        'codice': value.codice,
        'desc': value.desc,
        'data': value.data === undefined ? undefined : (value.data.toISOString()),
        'valoreMax': value.valoreMax,
        'valoreMin': value.valoreMin,
        'apertura': value.apertura,
        'chiusura': value.chiusura,
        'miglioreLettera': value.miglioreLettera,
        'miglioreDenaro': value.miglioreDenaro,
        'fixing': value.fixing,
        'fixingDate': value.fixingDate === undefined ? undefined : (value.fixingDate.toISOString()),
    };
}
exports.QuotazioneCambioDtoToJSON = QuotazioneCambioDtoToJSON;
