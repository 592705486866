"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkUpdateBozzaBollettinoFreccia = exports.bozzeBollettinoFreccia = exports.esitoRevocaBollettinoFreccia = exports.infoRevocaBollettinoFreccia = exports.formRevocaBollettinoFreccia = exports.riepilogoRevocaBollettinoFreccia = exports.esitoBollettinoFreccia = exports.infoDispositivaBollettinoFreccia = exports.disableSalvaBozzaBollettinoFreccia = exports.formBollettinoFrecciaValid = exports.buildBollettinoFreccia = exports.formBollettinoFreccia = exports.riepilogoBollettinoFreccia = exports.parametriBollettinoFreccia = void 0;
const reselect_1 = require("reselect");
const helpers_1 = require("../helpers");
const moment = require("moment");
const bollettinoFrecciaReducer = (state) => state;
exports.parametriBollettinoFreccia = (0, reselect_1.createSelector)(bollettinoFrecciaReducer, (state) => {
    return state.parametri;
});
exports.riepilogoBollettinoFreccia = (0, reselect_1.createSelector)(bollettinoFrecciaReducer, (state) => {
    return state.riepilogo;
});
exports.formBollettinoFreccia = (0, reselect_1.createSelector)(bollettinoFrecciaReducer, (state) => {
    return state.form;
});
exports.buildBollettinoFreccia = (0, reselect_1.createSelector)(bollettinoFrecciaReducer, (state) => {
    var _a, _b;
    return {
        contoAddebito: ((_b = (_a = state.form.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.id) || 0,
        datiBollettino: {
            cinImporto: state.form.cinImporto,
            cinIntermedio: state.form.cinIntermedio,
            iban: state.form.ibanCreditore.toUpperCase(),
            cinComplessivo: state.form.cinComplesso,
            codiceIdentificativo: {
                codiceSia: state.form.codiceSia,
                riferimentoCreditore: state.form.riferimentoCreditore
            },
            esenzione: state.form.carattereEsenzione
        },
        importo: (0, helpers_1.formattedNumberToNumber)(state.form.importo),
        dataScadenza: moment(state.form.dataScadenza).toDate(),
        note: state.form.note,
        idBozzaCollegata: state.currentBozza.id,
        id: state.currentBozza.id,
    };
});
exports.formBollettinoFrecciaValid = (0, reselect_1.createSelector)(bollettinoFrecciaReducer, (state) => {
    let formValid = false;
    if (!state.form.codiceSiaError &&
        !state.form.riferimentoCreditoreError &&
        !state.form.ibanCreditoreError &&
        !state.form.importoError &&
        !state.form.dataScadenzaError &&
        !state.form.noteError &&
        !state.form.cinImportoError &&
        !state.form.cinIntermedioError &&
        !state.form.cinComplessoError &&
        !state.form.carattereEsenzioneError) {
        formValid = true;
    }
    return formValid;
});
exports.disableSalvaBozzaBollettinoFreccia = (0, reselect_1.createSelector)(bollettinoFrecciaReducer, (state) => {
    let isBozzaAccepted = !state.form.bozzaSalvata &&
        (!!state.form.codiceSia ||
            !!state.form.ibanCreditore ||
            !!state.form.importo ||
            !!state.form.note);
    isBozzaAccepted = isBozzaAccepted && !!state.form.codiceSia ? !state.form.codiceSiaError : isBozzaAccepted;
    isBozzaAccepted = isBozzaAccepted && !!state.form.ibanCreditore ? !state.form.ibanCreditoreError : isBozzaAccepted;
    isBozzaAccepted = isBozzaAccepted && !!state.form.importo ? !state.form.importoError : isBozzaAccepted;
    isBozzaAccepted = isBozzaAccepted && !!state.form.note ? !state.form.noteError : isBozzaAccepted;
    return !isBozzaAccepted;
});
exports.infoDispositivaBollettinoFreccia = (0, reselect_1.createSelector)(bollettinoFrecciaReducer, (state) => {
    return state.infoDispositiva;
});
exports.esitoBollettinoFreccia = (0, reselect_1.createSelector)(bollettinoFrecciaReducer, (state) => {
    return state.esito;
});
exports.riepilogoRevocaBollettinoFreccia = (0, reselect_1.createSelector)(bollettinoFrecciaReducer, (state) => {
    return state.riepilogoRevoca;
});
exports.formRevocaBollettinoFreccia = (0, reselect_1.createSelector)(bollettinoFrecciaReducer, (state) => {
    return state.formRevoca;
});
exports.infoRevocaBollettinoFreccia = (0, reselect_1.createSelector)(bollettinoFrecciaReducer, (state) => {
    return state.infoRevoca;
});
exports.esitoRevocaBollettinoFreccia = (0, reselect_1.createSelector)(bollettinoFrecciaReducer, (state) => {
    return state.esitoRevoca;
});
exports.bozzeBollettinoFreccia = (0, reselect_1.createSelector)(bollettinoFrecciaReducer, (state) => {
    return state.bozzeBollettinoFreccia && state.bozzeBollettinoFreccia.freccias ? state.bozzeBollettinoFreccia.freccias.sort((a, b) => b.id - a.id) : [];
});
exports.checkUpdateBozzaBollettinoFreccia = (0, reselect_1.createSelector)(bollettinoFrecciaReducer, (state) => {
    var _a;
    return !!((_a = state.currentBozza) === null || _a === void 0 ? void 0 : _a.id);
});
