"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CarnetDtoToJSON = exports.CarnetDtoFromJSONTyped = exports.CarnetDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function CarnetDtoFromJSON(json) {
    return CarnetDtoFromJSONTyped(json, false);
}
exports.CarnetDtoFromJSON = CarnetDtoFromJSON;
function CarnetDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'dataRilascio': !runtime_1.exists(json, 'dataRilascio') ? undefined : (new Date(json['dataRilascio'])),
        'dataUltimoAggiornamento': !runtime_1.exists(json, 'dataUltimoAggiornamento') ? undefined : (new Date(json['dataUltimoAggiornamento'])),
        'tipoCarnet': !runtime_1.exists(json, 'tipoCarnet') ? undefined : json['tipoCarnet'],
        'descrizioneTipoCarnet': !runtime_1.exists(json, 'descrizioneTipoCarnet') ? undefined : json['descrizioneTipoCarnet'],
        'divisa': !runtime_1.exists(json, 'divisa') ? undefined : json['divisa'],
        'primoAssegno': !runtime_1.exists(json, 'primoAssegno') ? undefined : json['primoAssegno'],
        'ultimoAssegno': !runtime_1.exists(json, 'ultimoAssegno') ? undefined : json['ultimoAssegno'],
        'ordinanteIntestazione': !runtime_1.exists(json, 'ordinanteIntestazione') ? undefined : json['ordinanteIntestazione'],
        'contoAddebitoIban': !runtime_1.exists(json, 'contoAddebitoIban') ? undefined : json['contoAddebitoIban'],
        'contoAddebitoAlias': !runtime_1.exists(json, 'contoAddebitoAlias') ? undefined : json['contoAddebitoAlias'],
        'assegniManoCliente': !runtime_1.exists(json, 'assegniManoCliente') ? undefined : json['assegniManoCliente'],
        'statoAssegni': !runtime_1.exists(json, 'statoAssegni') ? undefined : json['statoAssegni'],
    };
}
exports.CarnetDtoFromJSONTyped = CarnetDtoFromJSONTyped;
function CarnetDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'dataRilascio': value.dataRilascio === undefined ? undefined : (value.dataRilascio.toISOString()),
        'dataUltimoAggiornamento': value.dataUltimoAggiornamento === undefined ? undefined : (value.dataUltimoAggiornamento.toISOString()),
        'tipoCarnet': value.tipoCarnet,
        'descrizioneTipoCarnet': value.descrizioneTipoCarnet,
        'divisa': value.divisa,
        'primoAssegno': value.primoAssegno,
        'ultimoAssegno': value.ultimoAssegno,
        'ordinanteIntestazione': value.ordinanteIntestazione,
        'contoAddebitoIban': value.contoAddebitoIban,
        'contoAddebitoAlias': value.contoAddebitoAlias,
        'assegniManoCliente': value.assegniManoCliente,
        'statoAssegni': value.statoAssegni,
    };
}
exports.CarnetDtoToJSON = CarnetDtoToJSON;
