"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.esitoCaricamentoDocumento = exports.documentoModificatoSelector = exports.riepilogoModificaDocumento = exports.esitoModificaDocumento = exports.modificaDocumentoBooleanSelector = exports.formDatiDocumentoValid = exports.formDatiRedditualiValid = exports.formDatiResidenzaValid = exports.formDati = exports.riepilogoADV = exports.formDatiRedSubmitted = exports.buildQuestionario = exports.isUploadLoading = exports.filesDocumento = exports.elencoQualifiche = exports.elencoCAE = exports.elencoTAE = exports.disabeldFirma = exports.formDocumentoSumbitted = exports.formDatiReddituali = exports.formScopiRapporti = exports.formResidenzaSubmitted = exports.formDatiDocumentoIdentita = exports.formDatiResidenza = exports.datiADV = exports.parametriADV = void 0;
const reselect_1 = require("reselect");
const advReducer = (state) => state;
exports.parametriADV = (0, reselect_1.createSelector)(advReducer, (state) => {
    return state.parametri;
});
exports.datiADV = (0, reselect_1.createSelector)(advReducer, (state) => {
    return state.dati;
});
exports.formDatiResidenza = (0, reselect_1.createSelector)(advReducer, (state) => {
    return state.formDatiResidenza;
});
exports.formDatiDocumentoIdentita = (0, reselect_1.createSelector)(advReducer, (state) => {
    return state.formDocumentoIdentita;
});
exports.formResidenzaSubmitted = (0, reselect_1.createSelector)(advReducer, (state) => {
    return state.formDatiResidenza.formSubmitted;
});
exports.formScopiRapporti = (0, reselect_1.createSelector)(advReducer, (state) => {
    return state.formScopiRapporti;
});
exports.formDatiReddituali = (0, reselect_1.createSelector)(advReducer, (state) => {
    return state.formDatiReddituali;
});
exports.formDocumentoSumbitted = (0, reselect_1.createSelector)(advReducer, (state) => {
    return state.formDocumentoIdentita.formSubmitted;
});
exports.disabeldFirma = (0, reselect_1.createSelector)(advReducer, (state) => {
    return state.form.disabeldFirma;
});
exports.elencoTAE = (0, reselect_1.createSelector)(advReducer, (state) => {
    return state.elencoTAE;
});
exports.elencoCAE = (0, reselect_1.createSelector)(advReducer, (state) => {
    return state.elencoCAE;
});
exports.elencoQualifiche = (0, reselect_1.createSelector)(advReducer, (state) => {
    return state.elencoQualifiche;
});
exports.filesDocumento = (0, reselect_1.createSelector)(advReducer, (state) => {
    return state.formDocumentoIdentita.files;
});
exports.isUploadLoading = (0, reselect_1.createSelector)(advReducer, (state) => {
    return state.formDocumentoIdentita.isUploadLoading;
});
exports.buildQuestionario = (0, reselect_1.createSelector)(advReducer, (state) => {
    var _a, _b, _c, _d, _e, _f;
    const formResidenza = state.formDatiResidenza;
    const formDatiReddituali = state.formDatiReddituali;
    const formDocumentoIdentita = state.formDocumentoIdentita;
    return {
        fasciaRischioIniziale: state.dati.fasciaRischio,
        indirizzoResidenza: {
            via: {
                tipo: formResidenza.tipoViaResidenza,
                nome: formResidenza.indirizzoResidenza,
                numeroDomicilio: formResidenza.numeroCivicoResidenza
            },
            localita: formResidenza.localitaResidenza,
            cap: Number(formResidenza.capResidenza),
            comune: formResidenza.comuneResidenza,
            provincia: formResidenza.provinciaResidenza,
            residenza: (_a = state.dati.indirizzoResidenza) === null || _a === void 0 ? void 0 : _a.residenza,
            codiceResidenza: (_b = state.dati.indirizzoResidenza) === null || _b === void 0 ? void 0 : _b.codiceResidenza
        },
        indirizzoDomicilio: {
            indirizzo: {
                via: {
                    tipo: formResidenza.tipoViaDomicilio,
                    nome: formResidenza.indirizzoDomicilio,
                    numeroDomicilio: formResidenza.numeroCivicoDomicilio
                },
                localita: formResidenza.localitaDomicilio,
                cap: Number(formResidenza.capDomicilio),
                comune: formResidenza.comuneDomicilio,
                provincia: formResidenza.provinciaDomicilio,
                residenza: (_d = (_c = state.dati.indirizzoDomicilio) === null || _c === void 0 ? void 0 : _c.indirizzo) === null || _d === void 0 ? void 0 : _d.residenza,
                codiceResidenza: (_f = (_e = state.dati.indirizzoDomicilio) === null || _e === void 0 ? void 0 : _e.indirizzo) === null || _f === void 0 ? void 0 : _f.codiceResidenza
            }
        },
        rapportoADVDto: state.formScopiRapporti.rapporti,
        professione: formDatiReddituali.professione,
        tae: formDatiReddituali.tae,
        cae: formDatiReddituali.cae,
        redditoAnnuo: formDatiReddituali.redditoAnnuo,
        patrimonio: formDatiReddituali.patrimonioTotaleCliente,
        statoAttivitaPrevalente: formDatiReddituali.statoattecprev,
        provinciaAttivitaPrevalente: formDatiReddituali.provattecprev,
        noteAttivita: formDatiReddituali.notaTipologia,
        primoPaeseRelazioniCliente: formDatiReddituali.primoPaese,
        secondoPaeseRelazioniCliente: formDatiReddituali.secondoPaese,
        terzoPaeseRelazioniClente: formDatiReddituali.terzoPaese,
        esercizioDati: formDatiReddituali.esercizioDati,
        modificaDomicilio: formResidenza.modificaDatiDomicilio,
        modificaDocumento: formDocumentoIdentita.modificaDocumento,
        altreCarichePubbliche: state.dati.altreCarichePubbliche,
        ultimaProfessione: formDatiReddituali.ultimaProfessione,
        appaltiFinanziamentiPubblici: formDatiReddituali.appaltiFinanziamentiPubblici || false,
        origineReddito: formDatiReddituali.origineReddito,
        originePatrimonio: formDatiReddituali.originePatrimonio
    };
});
// TODO: RIMUOVERE DATI SCHIANTATI
// export const buildDocumento = createSelector(
//   advReducer,
//   (state): ModificaDocIdentitaInDto => {
//     const formDocumentoIdentita = state.formDocumentoIdentita
//     return {
//       tipoDocumento: formDocumentoIdentita.tipoDocumento,
//       numero: formDocumentoIdentita.numDocumento,
//       dataRilascio: new Date(formDocumentoIdentita.dataRilascio),
//       dataScadenza: new Date(formDocumentoIdentita.dataScadenza),
//       enteRilascio: formDocumentoIdentita.luogoRilascio,
//       fileDocumentoFronte: { fileName: '', data: ['nofile'] },
//       fileDocumentoRetro: { fileName: '', data: ['nofile'] }
//     };
//   }
// );
exports.formDatiRedSubmitted = (0, reselect_1.createSelector)(advReducer, (state) => {
    return state.formDatiReddituali.formDatiRedSubmitted;
});
exports.riepilogoADV = (0, reselect_1.createSelector)(advReducer, (state) => {
    return state.riepilogo;
});
exports.formDati = (0, reselect_1.createSelector)(advReducer, (state) => {
    let formValid = false;
    if (!state.formDatiResidenza.localitaResidenzaError &&
        !state.formDatiResidenza.indirizzoResidenzaError &&
        !state.formDatiResidenza.numeroCivicoResidenzaError &&
        !state.formDatiResidenza.provinciaResidenzaError &&
        !state.formDatiResidenza.comuneResidenzaError &&
        !state.formDatiResidenza.tipoViaResidenzaError &&
        !state.formDatiResidenza.capResidenzaError &&
        !state.formDatiResidenza.modificaDatiDomicilio) {
        formValid = true;
    }
    return formValid;
});
//TODO: NON CANCELLARE
exports.formDatiResidenzaValid = (0, reselect_1.createSelector)(advReducer, (state) => {
    // let formValid = false;
    // if (
    //   (!state.formDatiResidenza.indirizzoResidenzaError &&
    //     state.formDatiResidenza.indirizzoResidenzaDirty &&
    //     !state.formDatiResidenza.numeroCivicoResidenzaError &&
    //     state.formDatiResidenza.numeroCivicoResidenzaDirty &&
    //     !state.formDatiResidenza.provinciaResidenzaError &&
    //     state.formDatiResidenza.provinciaResidenzaDirty &&
    //     !state.formDatiResidenza.comuneResidenzaError &&
    //     state.formDatiResidenza.comuneResidenzaDirty &&
    //     !state.formDatiResidenza.tipoViaResidenzaError &&
    //     state.formDatiResidenza.tipoViaResidenzaDirty &&
    //     !state.formDatiResidenza.capResidenzaError &&
    //     state.formDatiResidenza.capResidenzaDirty &&
    //     !state.formDatiResidenza.modificaDatiDomicilio) ||
    //   (
    //     !state.formDatiResidenza.indirizzoResidenzaError &&
    //     !state.formDatiResidenza.numeroCivicoResidenzaError &&
    //     !state.formDatiResidenza.provinciaResidenzaError &&
    //     !state.formDatiResidenza.comuneResidenzaError &&
    //     !state.formDatiResidenza.tipoViaResidenzaError &&
    //     !state.formDatiResidenza.capResidenzaError &&
    //     state.formDatiResidenza.modificaDatiDomicilio &&
    //     !state.formDatiResidenza.indirizzoDomicilioError &&
    //     state.formDatiResidenza.indirizzoDomicilioDirty &&
    //     !state.formDatiResidenza.indirizzoDomicilioError &&
    //     state.formDatiResidenza.indirizzoDomicilioDirty &&
    //     !state.formDatiResidenza.numeroCivicoDomicilioError &&
    //     state.formDatiResidenza.numeroCivicoDomicilioDirty &&
    //     !state.formDatiResidenza.provinciaDomicilioError &&
    //     state.formDatiResidenza.provinciaDomicilioDirty &&
    //     !state.formDatiResidenza.comuneDomicilioError &&
    //     state.formDatiResidenza.comuneDomicilioDirty &&
    //     !state.formDatiResidenza.tipoViaDomicilioError &&
    //     state.formDatiResidenza.tipoViaDomicilioDirty &&
    //     !state.formDatiResidenza.capDomicilioError &&
    //     state.formDatiResidenza.capDomicilioDirty)
    // ) {
    //   formValid = true;
    // }
    //return formValid;
    return true;
});
exports.formDatiRedditualiValid = (0, reselect_1.createSelector)(advReducer, (state) => {
    let formValid = false;
    if ((!state.formDatiReddituali.professioneError &&
        !state.formDatiReddituali.qualificaLavorativaError &&
        !state.formDatiReddituali.statoattecprevError &&
        state.formDatiReddituali.provattecprevDirty &&
        !(state.formDatiReddituali.professione === '00002' || state.formDatiReddituali.professione === '00003' || state.formDatiReddituali.professione === '00110') &&
        !state.formDatiReddituali.taeError &&
        !state.formDatiReddituali.primoPaeseError &&
        !state.formDatiReddituali.provattecprevError &&
        state.formDatiReddituali.provattecprevDirty &&
        !state.formDatiReddituali.esercizioDatiError &&
        state.formDatiReddituali.primoPaeseDirty &&
        !state.formDatiReddituali.redditoAnnuoError &&
        state.formDatiReddituali.redditoAnnuoDirty &&
        state.formDatiReddituali.patrimonioTotaleClienteDirty &&
        !state.formDatiReddituali.patrimonioTotaleClienteError &&
        !state.formDatiReddituali.origineRedditoError &&
        !state.formDatiReddituali.originePatrimonioError &&
        state.formDatiReddituali.origineRedditoDirty &&
        state.formDatiReddituali.originePatrimonioDirty)) {
        formValid = true;
    }
    else if ((!state.formDatiReddituali.professioneError &&
        !state.formDatiReddituali.qualificaLavorativaError &&
        ((state.formDatiReddituali.professione === '00002' || state.formDatiReddituali.professione === '00003' || state.formDatiReddituali.professione === '00110') &&
            !state.formDatiReddituali.ultimaProfessioneError && !state.formDatiReddituali.notaTipologiaError && state.formDatiReddituali.notaTipologiaDirty) &&
        !state.formDatiReddituali.statoattecprevError &&
        state.formDatiReddituali.provattecprevDirty &&
        !state.formDatiReddituali.taeError &&
        !state.formDatiReddituali.primoPaeseError &&
        !state.formDatiReddituali.provattecprevError &&
        state.formDatiReddituali.provattecprevDirty &&
        !state.formDatiReddituali.esercizioDatiError &&
        state.formDatiReddituali.primoPaeseDirty &&
        !state.formDatiReddituali.redditoAnnuoError &&
        state.formDatiReddituali.redditoAnnuoDirty &&
        state.formDatiReddituali.patrimonioTotaleClienteDirty &&
        !state.formDatiReddituali.patrimonioTotaleClienteError &&
        !state.formDatiReddituali.origineRedditoError &&
        !state.formDatiReddituali.originePatrimonioError &&
        state.formDatiReddituali.origineRedditoDirty &&
        state.formDatiReddituali.originePatrimonioDirty)) {
        formValid = true;
    }
    return formValid;
});
exports.formDatiDocumentoValid = (0, reselect_1.createSelector)(advReducer, (state) => {
    let formValid = false;
    if (state.formDocumentoIdentita.luogoRilascioDirty && !state.formDocumentoIdentita.luogoRilascioError &&
        state.formDocumentoIdentita.numDocumentoDirty && !state.formDocumentoIdentita.numDocumentoError &&
        state.formDocumentoIdentita.dataScadenzaDirty && !state.formDocumentoIdentita.dataScadenzaError &&
        state.formDocumentoIdentita.dataRilascioDirty && !state.formDocumentoIdentita.dataRilascioError) {
        formValid = true;
    }
    return formValid;
});
exports.modificaDocumentoBooleanSelector = (0, reselect_1.createSelector)(advReducer, (state) => {
    return state.formDocumentoIdentita.modificaDocumento;
});
exports.esitoModificaDocumento = (0, reselect_1.createSelector)(advReducer, (state) => {
    return state.esitoModificaDocumento;
});
exports.riepilogoModificaDocumento = (0, reselect_1.createSelector)(advReducer, (state) => {
    return state.riepilogoDatiDocumento;
});
exports.documentoModificatoSelector = (0, reselect_1.createSelector)(advReducer, (state) => {
    return state.documentoModificato;
});
exports.esitoCaricamentoDocumento = (0, reselect_1.createSelector)(advReducer, (state) => {
    return state.esitoCaricamentoDocumento;
});
