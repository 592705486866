"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dashboardMutui = exports.formMutui = exports.ratePagateMutui = exports.formMutuiAsFiltro = exports.rateResidueMutui = exports.pianoAmmortamentoMutui = exports.rateMoraMutui = exports.elencoProgressiviMutui = exports.parametriMutui = void 0;
const reselect_1 = require("reselect");
const mutuiReducer = (state) => state;
exports.parametriMutui = (0, reselect_1.createSelector)(mutuiReducer, (state) => {
    return state.parametri;
});
exports.elencoProgressiviMutui = (0, reselect_1.createSelector)(mutuiReducer, (state) => {
    return state.elencoProgressiviMutui;
});
exports.rateMoraMutui = (0, reselect_1.createSelector)(mutuiReducer, (state) => {
    return state.rateMoraMutui;
});
exports.pianoAmmortamentoMutui = (0, reselect_1.createSelector)(mutuiReducer, (state) => {
    return state.pianoAmmortamentoMutui;
});
exports.rateResidueMutui = (0, reselect_1.createSelector)(mutuiReducer, (state) => {
    return state.rateResidueMutui;
});
exports.formMutuiAsFiltro = (0, reselect_1.createSelector)(mutuiReducer, (state) => {
    var _a, _b, _c, _d;
    return {
        codiceRapporto: (_b = (_a = state.form) === null || _a === void 0 ? void 0 : _a.rapportoSelezionato) === null || _b === void 0 ? void 0 : _b.codiceRapporto,
        progressivoRapporto: (_d = (_c = state.form) === null || _c === void 0 ? void 0 : _c.rapportoSelezionato) === null || _d === void 0 ? void 0 : _d.progressivoRapporto
    };
});
exports.ratePagateMutui = (0, reselect_1.createSelector)(mutuiReducer, (state) => {
    return state.ratePagateMutui;
});
exports.formMutui = (0, reselect_1.createSelector)(mutuiReducer, (state) => {
    return state.form;
});
exports.dashboardMutui = (0, reselect_1.createSelector)(mutuiReducer, (state) => {
    return state.dashboardMutui;
});
