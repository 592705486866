"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AspspProductDtoToJSON = exports.AspspProductDtoFromJSONTyped = exports.AspspProductDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function AspspProductDtoFromJSON(json) {
    return AspspProductDtoFromJSONTyped(json, false);
}
exports.AspspProductDtoFromJSON = AspspProductDtoFromJSON;
function AspspProductDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'providerId': !runtime_1.exists(json, 'providerId') ? undefined : json['providerId'],
        'globalConsents': !runtime_1.exists(json, 'globalConsents') ? undefined : json['globalConsents'],
        'instantPayments': !runtime_1.exists(json, 'instantPayments') ? undefined : json['instantPayments'],
        'aspspProductCode': !runtime_1.exists(json, 'aspspProductCode') ? undefined : json['aspspProductCode'],
        'aspspProductDescription': !runtime_1.exists(json, 'aspspProductDescription') ? undefined : json['aspspProductDescription'],
        'aspspProductTypes': !runtime_1.exists(json, 'aspspProductTypes') ? undefined : json['aspspProductTypes'],
        'aspspProductSuggestedLabel': !runtime_1.exists(json, 'aspspProductSuggestedLabel') ? undefined : json['aspspProductSuggestedLabel'],
    };
}
exports.AspspProductDtoFromJSONTyped = AspspProductDtoFromJSONTyped;
function AspspProductDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'providerId': value.providerId,
        'globalConsents': value.globalConsents,
        'instantPayments': value.instantPayments,
        'aspspProductCode': value.aspspProductCode,
        'aspspProductDescription': value.aspspProductDescription,
        'aspspProductTypes': value.aspspProductTypes,
        'aspspProductSuggestedLabel': value.aspspProductSuggestedLabel,
    };
}
exports.AspspProductDtoToJSON = AspspProductDtoToJSON;
