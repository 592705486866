import PopUpModal from "component/popUpModal/popUpModal.component";
import React from "react";
import { useTranslation } from "react-i18next";
import { navigateToPage, WEBPAGE } from "../../component/history/history.component";

interface Props {
  resetNoRapporti: () => void
  noRapporti: any
}

const NoRapportiModal: React.FC<Props> = (props: Props) => {

  const { t } = useTranslation();

  const onGoHome = () => {
    navigateToPage(WEBPAGE.Home.url);
    props.resetNoRapporti();
  };

  return (
    <PopUpModal
      visible={!!props.noRapporti}
      onClose={onGoHome}
      popUp={{
        type: 'alert',
        title: t('noRapportiPage.title'),
        message: t('noRapportiPage.description')
      }}
    // className="no-rapporti-modal"
    />
  );
};

export default NoRapportiModal;
