"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormRevocaBonificoDepositoDtoFromJSON = exports.FormBonificoDepositoDtoFromJSONTyped = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const helpers_1 = require("../helpers");
function FormBonificoDepositoDtoFromJSONTyped(json) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'importo': !(0, helpers_1.exists)(json, 'importo') ? undefined : json.importo,
        'importoDirty': !(0, helpers_1.exists)(json, 'importoDirty') ? false : json.importoDirty,
        'importoError': !(0, helpers_1.exists)(json, 'importoError') ? 'invalid.required' : json.importoError,
        'dataEsecuzione': !(0, helpers_1.exists)(json, 'dataEsecuzione') ? new Date() : json.dataEsecuzione,
        'dataEsecuzioneDirty': !(0, helpers_1.exists)(json, 'dataEsecuzioneDirty') ? false : json.dataEsecuzioneDirty,
        'dataEsecuzioneError': !(0, helpers_1.exists)(json, 'dataEsecuzioneError') ? '' : json.dataEsecuzioneError,
        'causale': !(0, helpers_1.exists)(json, 'causale') ? undefined : json.causale,
        'causaleDirty': !(0, helpers_1.exists)(json, 'causaleDirty') ? false : json.causaleDirty,
        'causaleError': !(0, helpers_1.exists)(json, 'causaleError') ? 'invalid.required' : json.causaleError,
        'step': !(0, helpers_1.exists)(json, 'step') ? 0 : json.step,
        'intestatario': !(0, helpers_1.exists)(json, 'intestatario') ? undefined : json.intestatario,
        'intestatarioDirty': !(0, helpers_1.exists)(json, 'intestatarioDirty') ? false : json.intestatarioDirty,
        'intestatarioError': !(0, helpers_1.exists)(json, 'intestatarioError') ? 'invalid.required' : json.intestatarioError,
        'rapportoSelezionato': !(0, helpers_1.exists)(json, 'rapportoSelezionato') ? (0, api_rest_1.RapportoCompletoDtoFromJSON)({}) : json.rapportoSelezionato,
        'contoAccredito': !(0, helpers_1.exists)(json, 'contoAccredito') ? (0, api_rest_1.RapportoGirofondoDtoFromJSON)({}) : json.contoAccredito,
        'contoAccreditoDirty': !(0, helpers_1.exists)(json, 'contoAccreditoDirty') ? false : json.contoAccreditoDirty,
        'contoAccreditoError': !(0, helpers_1.exists)(json, 'contoAccredito') ? 'invalid.required' : json.contoAccredito,
        'formSubmitted': !(0, helpers_1.exists)(json, 'formSubmitted') ? false : json.formSubmitted,
        'ripeti': !(0, helpers_1.exists)(json, 'ripeti') ? false : json.ripeti
    };
}
exports.FormBonificoDepositoDtoFromJSONTyped = FormBonificoDepositoDtoFromJSONTyped;
function FormRevocaBonificoDepositoDtoFromJSON(json) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'step': !(0, helpers_1.exists)(json, 'step') ? 0 : json.step,
        'provenienza': !(0, helpers_1.exists)(json, 'step') ? undefined : json.step
    };
}
exports.FormRevocaBonificoDepositoDtoFromJSON = FormRevocaBonificoDepositoDtoFromJSON;
