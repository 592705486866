import i18next from 'i18next';
import { getResources } from './helpers/utilities';

export const initI18n = (locale, labels) => {
  const resources = getResources(labels);

  return i18next.init({
    lng: locale,
    resources: resources,
    fallbackLng: `${locale}_offline`,
    ns: 'translation',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  })
}

export const DEFAULT_LANGUAGE = "it-IT"


export default i18next
