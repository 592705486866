"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ricaricaCartaReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const carte_ricaricabili_types_1 = require("./carte-ricaricabili.types");
const moment = require("moment");
const initialState = {
    parametri: (0, api_rest_1.ParametriRicaricaCarteDtoFromJSON)({}),
    formRicaricaCarta: (0, carte_ricaricabili_types_1.FormCartaRicaricabileDtoFromJSONTyped)({}),
    riepilogoRicaricaCarta: (0, api_rest_1.RicaricaCarteDtoFromJSON)({}),
    bozzeRicaricaCarta: (0, api_rest_1.RicaricheCarteDtoFromJSON)({}),
    currentBozza: (0, api_rest_1.RicaricaCarteDtoFromJSON)({}),
    oneclick: (0, api_rest_1.OperazioniOneClickDtoFromJSON)({}),
    infoDispositiva: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
    esito: (0, api_rest_1.EsitoAutorizzazioneDisposizioneRicaricaCarteDtoFromJSON)({}),
    parametriRicaricaMovimenti: (0, api_rest_1.ParametriRicaricaCarteDtoFromJSON)({}),
    movimentiRicaricaCarta: [],
    pdfRicaricaCartaDettaglioMovimento: undefined,
    pdfRicaricaCartaMovimenti: undefined,
    filtri: {
        rapportoSelezionatoMovimenti: {},
        pan: '',
        codiceRapporto: undefined,
        dataDa: '',
        dataDaDirty: false,
        dataDaError: '',
        dataA: '',
        dataADirty: false,
        dataAError: '',
        periodo: ''
    }
};
let rapportoSelezionato;
const ricaricaCartaReducer = (state = initialState, action) => {
    var _a, _b;
    switch (action.type) {
        case __1.EReduxActionTypes.CARTE_RICARICABILI_GET_PARAMETRI_SUCCESS:
            return {
                ...state,
                parametri: action.payload
            };
        case __1.EReduxActionTypes.CARTE_RICARICABILI_CONTROLLO_SUCCESS:
            return {
                ...state,
                riepilogoRicaricaCarta: action.payload
            };
        case __1.EReduxActionTypes.CARTE_RICARICABILI_GET_PARAMETRI_PER_RIPETI_SUCCESS:
            return {
                ...initialState,
                parametri: action.payload,
                formRicaricaCarta: {
                    ...state.formRicaricaCarta,
                    salva1Click: false,
                    alias1Click: '',
                    ripeti: true
                }
            };
        case __1.EReduxActionTypes.CARTE_RICARICABILI_SET_RAPPORTO_SELEZIONATO:
            return {
                ...state,
                formRicaricaCarta: {
                    ...state.formRicaricaCarta,
                    rapportoSelezionato: action.payload,
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.CARTE_RICARICABILI_SET_SAVE_ONE_CLICK:
            return {
                ...state,
                formRicaricaCarta: {
                    ...state.formRicaricaCarta,
                    salva1Click: action.payload,
                    //alias1Click: action.payload,
                    //aliasDirty: false,
                    // aliasError: action.payload
                    //   ? validationField(state.formRicaricaCarta.causale, '')
                    //   : ''
                }
            };
        case __1.EReduxActionTypes.CARTE_RICARICABILI_SET_ALIAS_ONE_CLICK:
            return {
                ...state,
                formRicaricaCarta: {
                    ...state.formRicaricaCarta,
                    alias1Click: action.payload,
                    aliasDirty: true,
                    aliasError: (0, __1.validationField)(action.payload, '')
                }
            };
        case __1.EReduxActionTypes.CARTE_RICARICABILI_SET_IMPORTO:
            return {
                ...state,
                formRicaricaCarta: {
                    ...state.formRicaricaCarta,
                    importo: action.payload,
                    importoDirty: true,
                    importoError: (0, __1.validationImportoRicaricaCarta)(action.payload, undefined, 25),
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.CARTE_RICARICABILI_SET_FORM_SUBMITTED:
            return {
                ...state,
                formRicaricaCarta: {
                    ...state.formRicaricaCarta,
                    formSubmitted: action.payload
                }
            };
        case __1.EReduxActionTypes.CARTE_RICARICABILI_SET_DATA_ESECUZIONE:
            return {
                ...state,
                formRicaricaCarta: {
                    ...state.formRicaricaCarta,
                    dataEsecuzione: action.payload,
                    dataEsecuzioneDirty: true,
                    dataEsecuzioneError: (0, __1.validationFieldDate)(action.payload, [], false)
                }
            };
        case __1.EReduxActionTypes.CARTE_RICARICABILI_SET_STEP:
            return {
                ...state,
                formRicaricaCarta: {
                    ...state.formRicaricaCarta,
                    step: action.payload
                }
            };
        case __1.EReduxActionTypes.CARTE_RICARICABILI_SET_PAN:
            return {
                ...state,
                formRicaricaCarta: {
                    ...state.formRicaricaCarta,
                    pan: action.payload,
                    panDirty: true,
                    panError: (0, __1.validationField)(action.payload, ''),
                }
            };
        case __1.EReduxActionTypes.CARTE_RICARICABILI_SET_TIPO_CARTA:
            return {
                ...state,
                formRicaricaCarta: {
                    ...state.formRicaricaCarta,
                    tipoCarta: action.payload
                }
            };
        case __1.EReduxActionTypes.CARTE_RICARICABILI_GET_BOZZE_SUCCESS:
            return {
                ...state,
                bozzeRicaricaCarta: action.payload
            };
        case __1.EReduxActionTypes.CARTE_RICARICABILI_ADD_BOZZA_SUCCESS:
            return {
                ...state,
                bozzeRicaricaCarta: {
                    ...state.bozzeRicaricaCarta,
                    ricariche: state.bozzeRicaricaCarta && state.bozzeRicaricaCarta.ricariche
                        ? [...state.bozzeRicaricaCarta.ricariche, action.payload]
                        : []
                },
                formRicaricaCarta: {
                    ...state.formRicaricaCarta,
                    bozzaSalvata: true
                },
                currentBozza: action.payload
            };
        case __1.EReduxActionTypes.CARTE_RICARICABILI_DEL_BOZZA_SUCCESS:
            return {
                ...state,
                bozzeRicaricaCarta: {
                    ...state.bozzeRicaricaCarta,
                    ricariche: state.bozzeRicaricaCarta && state.bozzeRicaricaCarta.ricariche
                        ? state.bozzeRicaricaCarta.ricariche.filter((ricarica) => ricarica.id !== action.payload)
                        : []
                },
                formRicaricaCarta: {
                    ...state.formRicaricaCarta,
                    bozzaSalvata: state.currentBozza.id === action.payload ? false : state.formRicaricaCarta.bozzaSalvata
                },
                currentBozza: state.currentBozza.id === action.payload ? initialState.currentBozza : state.currentBozza
            };
        case __1.EReduxActionTypes.CARTE_RICARICABILI_UPDATE_BOZZA_SUCCESS:
            return {
                ...state,
                bozzeRicaricaCarta: {
                    ...state.bozzeRicaricaCarta,
                    ricariche: state.bozzeRicaricaCarta && state.bozzeRicaricaCarta.ricariche
                        ? state.bozzeRicaricaCarta.ricariche.map(ricarica => ricarica.id === state.currentBozza.id ? action.payload : ricarica)
                        : []
                },
                formRicaricaCarta: {
                    ...state.formRicaricaCarta,
                    bozzaSalvata: true
                },
                currentBozza: action.payload
            };
        case __1.EReduxActionTypes.CARTE_RICARICABILI_GET_BOZZA_SUCCESS: {
            rapportoSelezionato = (0, __1.findInArrayRapporti)(state.parametri && state.parametri.rapporti, action.payload.contoAddebito);
            const data = moment().toDate();
            return {
                ...state,
                formRicaricaCarta: {
                    ...state.formRicaricaCarta,
                    rapportoSelezionato: rapportoSelezionato,
                    dataEsecuzione: data.toLocaleDateString(),
                    importo: (0, __1.formattedNumberToNumber)(action.payload.importo),
                    salva1Click: action.payload.aggiungi1click || false,
                    pan: action.payload.pan,
                    tipoCarta: action.payload.tipoCarta,
                    idOneClickCollegata: action.payload.idOneClickCollegata,
                },
                currentBozza: action.payload
            };
        }
        case __1.EReduxActionTypes.CARTE_RICARICABILI_GET_RICARICA_FROM_BOZZA: {
            rapportoSelezionato = (0, __1.findInArrayRapporti)(state.parametri && state.parametri.rapporti, action.payload.contoAddebito);
            const data = moment().toDate();
            return {
                ...state,
                formRicaricaCarta: {
                    ...state.formRicaricaCarta,
                    rapportoSelezionato: rapportoSelezionato,
                    dataEsecuzione: data.toLocaleDateString(),
                    importo: (0, __1.formattedNumberToNumber)(action.payload.importo),
                    salva1Click: action.payload.aggiungi1click || false,
                    pan: action.payload.pan,
                    tipoCarta: action.payload.tipoCarta,
                    idOneClickCollegata: action.payload.idOneClickCollegata,
                },
                currentBozza: action.noBozza ? initialState.currentBozza : action.payload
            };
        }
        case __1.EReduxActionTypes.CARTE_RICARICABILI_GET_ONECLICK_SUCCESS:
            return {
                ...state,
                oneclick: action.payload
            };
        case __1.EReduxActionTypes.CARTE_RICARICABILI_GET_INFO_DISPOSITIVA_SUCCESS:
            return {
                ...state,
                infoDispositiva: action.payload,
                esito: initialState.esito
            };
        case __1.EReduxActionTypes.CARTE_RICARICABILI_RESET:
            return {
                ...state,
                ...state.parametriRicaricaMovimenti,
                ...state.movimentiRicaricaCarta,
                ...state.filtri,
                parametri: initialState.parametri,
                bozzeRicaricaCarta: initialState.bozzeRicaricaCarta,
                currentBozza: initialState.currentBozza,
                esito: initialState.esito,
                infoDispositiva: initialState.infoDispositiva,
                formRicaricaCarta: initialState.formRicaricaCarta
            };
        case __1.EReduxActionTypes.CARTE_RICARICABILI_RESET_BOZZE:
            return {
                ...state,
                bozzeRicaricaCarta: initialState.bozzeRicaricaCarta
            };
        case __1.EReduxActionTypes.CARTE_RICARICABILI_RESET_RIEPILOGO:
            return {
                ...state,
                riepilogoRicaricaCarta: initialState.riepilogoRicaricaCarta
            };
        case __1.EReduxActionTypes.CARTE_RICARICABILI_RESET_ESITO:
            return {
                ...state,
                esito: initialState.esito
            };
        case __1.EReduxActionTypes.CARTE_RICARICABILI_RESET_INFO_DISPOSITIVA:
            return {
                ...state,
                infoDispositiva: initialState.infoDispositiva
            };
        case __1.EReduxActionTypes.CARTE_RICARICABILI_GET_MOVIMENTI_PARAMETRI_SUCCESS:
            return {
                ...state,
                parametriRicaricaMovimenti: action.payload
            };
        case __1.EReduxActionTypes.CARTE_RICARICABILI_GET_LISTA_MOVIMENTI_SUCCESS:
            return {
                ...state,
                movimentiRicaricaCarta: action.payload
            };
        case __1.EReduxActionTypes.CARTE_RICARICABILI_SET_DATA_DA:
            return {
                ...state,
                filtri: {
                    ...state.filtri,
                    dataDa: action.payload,
                    dataDaError: (0, __1.validationFieldDate)(action.payload, '', false, '', state.filtri.dataA, true),
                    dataAError: (0, __1.validationFieldDate)(state.filtri.dataA, '', false, action.payload),
                    periodo: initialState.filtri.periodo
                }
            };
        case __1.EReduxActionTypes.CARTE_RICARICABILI_SET_DATA_A:
            return {
                ...state,
                filtri: {
                    ...state.filtri,
                    dataA: action.payload,
                    dataAError: (0, __1.validationFieldDate)(action.payload, '', false, state.filtri.dataDa),
                    dataDaError: (0, __1.validationFieldDate)(state.filtri.dataDa, '', false, '', action.payload, true),
                    periodo: initialState.filtri.periodo
                }
            };
        case __1.EReduxActionTypes.CARTE_RICARICABILI_PERIODO:
            return {
                ...state,
                filtri: {
                    ...state.filtri,
                    periodo: action.payload
                }
            };
        case __1.EReduxActionTypes.CARTE_RICARICABILI_SET_PAN_FILTRI:
            return {
                ...state,
                filtri: {
                    ...state.filtri,
                    pan: action.payload
                }
            };
        case __1.EReduxActionTypes.CARTE_RICARICABILI_SET_RAPPORTO_FILTRI:
            return {
                ...state,
                filtri: {
                    ...state.filtri,
                    codiceRapporto: action.payload
                }
            };
        case __1.EReduxActionTypes.CARTE_RICARICABILI_SET_RAPPORTO_SELEZIONATO_FILTRI:
            return {
                ...state,
                filtri: {
                    ...state.filtri,
                    rapportoSelezionatoMovimenti: action.payload
                }
            };
        case __1.EReduxActionTypes.CARTE_RICARICABILI_RIMUOVI_FILTRI:
            const periodi_carta_conto = (_b = (_a = state.parametriRicaricaMovimenti) === null || _a === void 0 ? void 0 : _a.parametri) === null || _b === void 0 ? void 0 : _b['MOVIMENTI.PERIODI.RICERCA'];
            const _periodo_carta_conto = periodi_carta_conto === null || periodi_carta_conto === void 0 ? void 0 : periodi_carta_conto.find(p => !!p.defaultValue);
            return {
                ...state,
                filtri: {
                    ...state.filtri,
                    pan: initialState.filtri.pan,
                    codiceRapporto: initialState.filtri.codiceRapporto,
                    dataDa: moment().subtract((_periodo_carta_conto === null || _periodo_carta_conto === void 0 ? void 0 : _periodo_carta_conto.giorni) || 0, 'days').format('YYYY-MM-DD'),
                    dataDaDirty: true,
                    dataDaError: '',
                    dataA: moment().format('YYYY-MM-DD'),
                    dataADirty: true,
                    dataAError: '',
                    periodo: String(_periodo_carta_conto === null || _periodo_carta_conto === void 0 ? void 0 : _periodo_carta_conto.giorni) || ''
                }
            };
        case __1.EReduxActionTypes.CARTE_RICARICABILI_AUTORIZZA_SUCCESS:
            if (action.payload.stato === 'OK') {
                return {
                    ...state,
                    esito: action.payload
                };
            }
            else {
                return {
                    ...state,
                    infoDispositiva: (0, __1.getInfoAutenticazioneDispositivaCommon)(state.infoDispositiva, action.payload.informazioniAutenticazioneDispositiva),
                    esito: action.payload
                };
            }
        case __1.EReduxActionTypes.CARTE_RICARICABILI_ESPORTA_MOVIMENTI_SUCCESS:
            return {
                ...state,
                pdfRicaricaCartaMovimenti: action.payload
            };
        case __1.EReduxActionTypes.CARTE_RICARICABILI_ESPORTA_DETTAGLIO_MOVIMENTI_SUCCESS:
            return {
                ...state,
                pdfRicaricaCartaDettaglioMovimento: action.payload
            };
        default:
            return state;
    }
};
exports.ricaricaCartaReducer = ricaricaCartaReducer;
exports.default = exports.ricaricaCartaReducer;
