"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.F24LookupDtoToJSON = exports.F24LookupDtoFromJSONTyped = exports.F24LookupDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function F24LookupDtoFromJSON(json) {
    return F24LookupDtoFromJSONTyped(json, false);
}
exports.F24LookupDtoFromJSON = F24LookupDtoFromJSON;
function F24LookupDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codice': !runtime_1.exists(json, 'codice') ? undefined : json['codice'],
        'desc': !runtime_1.exists(json, 'desc') ? undefined : json['desc'],
        'campoRiferimento': !runtime_1.exists(json, 'campoRiferimento') ? undefined : json['campoRiferimento'],
    };
}
exports.F24LookupDtoFromJSONTyped = F24LookupDtoFromJSONTyped;
function F24LookupDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codice': value.codice,
        'desc': value.desc,
        'campoRiferimento': value.campoRiferimento,
    };
}
exports.F24LookupDtoToJSON = F24LookupDtoToJSON;
