"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TipoGraficoTitoli = void 0;
var TipoGraficoTitoli;
(function (TipoGraficoTitoli) {
    TipoGraficoTitoli[TipoGraficoTitoli["Ore1"] = 0] = "Ore1";
    TipoGraficoTitoli[TipoGraficoTitoli["Ore4"] = 1] = "Ore4";
    TipoGraficoTitoli[TipoGraficoTitoli["Giorni1"] = 2] = "Giorni1";
    TipoGraficoTitoli[TipoGraficoTitoli["Settimana1"] = 3] = "Settimana1";
    TipoGraficoTitoli[TipoGraficoTitoli["Mese1"] = 4] = "Mese1";
    TipoGraficoTitoli[TipoGraficoTitoli["Anno1"] = 5] = "Anno1";
})(TipoGraficoTitoli = exports.TipoGraficoTitoli || (exports.TipoGraficoTitoli = {}));
;
