"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DettaglioRichiestaDtoStatoDisposizioneEnum = exports.DettaglioRichiestaDtoToJSON = exports.DettaglioRichiestaDtoFromJSONTyped = exports.DettaglioRichiestaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function DettaglioRichiestaDtoFromJSON(json) {
    return DettaglioRichiestaDtoFromJSONTyped(json, false);
}
exports.DettaglioRichiestaDtoFromJSON = DettaglioRichiestaDtoFromJSON;
function DettaglioRichiestaDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'richiesta': !runtime_1.exists(json, 'richiesta') ? undefined : json['richiesta'],
        'codiceRichiesta': !runtime_1.exists(json, 'codiceRichiesta') ? undefined : json['codiceRichiesta'],
        'dataInoltro': !runtime_1.exists(json, 'dataInoltro') ? undefined : (new Date(json['dataInoltro'])),
        'dataUltimaModifica': !runtime_1.exists(json, 'dataUltimaModifica') ? undefined : (new Date(json['dataUltimaModifica'])),
        'statoRichiesta': !runtime_1.exists(json, 'statoRichiesta') ? undefined : json['statoRichiesta'],
        'prodotto': !runtime_1.exists(json, 'prodotto') ? undefined : json['prodotto'],
        'contoAddebito': !runtime_1.exists(json, 'contoAddebito') ? undefined : json['contoAddebito'],
        'noteCliente': !runtime_1.exists(json, 'noteCliente') ? undefined : json['noteCliente'],
        'datiUtente': !runtime_1.exists(json, 'datiUtente') ? undefined : _1.FvDatiUtenteDtoFromJSON(json['datiUtente']),
        'immagine': !runtime_1.exists(json, 'immagine') ? undefined : json['immagine'],
        'templateCompilato': !runtime_1.exists(json, 'templateCompilato') ? undefined : _1.TemplateDtoFromJSON(json['templateCompilato']),
        'statoDisposizione': !runtime_1.exists(json, 'statoDisposizione') ? undefined : json['statoDisposizione'],
    };
}
exports.DettaglioRichiestaDtoFromJSONTyped = DettaglioRichiestaDtoFromJSONTyped;
function DettaglioRichiestaDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'richiesta': value.richiesta,
        'codiceRichiesta': value.codiceRichiesta,
        'dataInoltro': value.dataInoltro === undefined ? undefined : (value.dataInoltro.toISOString()),
        'dataUltimaModifica': value.dataUltimaModifica === undefined ? undefined : (value.dataUltimaModifica.toISOString()),
        'statoRichiesta': value.statoRichiesta,
        'prodotto': value.prodotto,
        'contoAddebito': value.contoAddebito,
        'noteCliente': value.noteCliente,
        'datiUtente': _1.FvDatiUtenteDtoToJSON(value.datiUtente),
        'immagine': value.immagine,
        'templateCompilato': _1.TemplateDtoToJSON(value.templateCompilato),
        'statoDisposizione': value.statoDisposizione,
    };
}
exports.DettaglioRichiestaDtoToJSON = DettaglioRichiestaDtoToJSON;
/**
* @export
* @enum {string}
*/
var DettaglioRichiestaDtoStatoDisposizioneEnum;
(function (DettaglioRichiestaDtoStatoDisposizioneEnum) {
    DettaglioRichiestaDtoStatoDisposizioneEnum["AUTORIZZATO"] = "AUTORIZZATO";
    DettaglioRichiestaDtoStatoDisposizioneEnum["AUTORIZZATO_NON_REV"] = "AUTORIZZATO_NON_REV";
    DettaglioRichiestaDtoStatoDisposizioneEnum["NON_AUTORIZZATO"] = "NON_AUTORIZZATO";
    DettaglioRichiestaDtoStatoDisposizioneEnum["IN_CORSO"] = "IN_CORSO";
    DettaglioRichiestaDtoStatoDisposizioneEnum["INVIATO"] = "INVIATO";
    DettaglioRichiestaDtoStatoDisposizioneEnum["ESITO_INCERTO"] = "ESITO_INCERTO";
    DettaglioRichiestaDtoStatoDisposizioneEnum["REVOCATO"] = "REVOCATO";
    DettaglioRichiestaDtoStatoDisposizioneEnum["DA_AUTORIZZARE"] = "DA_AUTORIZZARE";
    DettaglioRichiestaDtoStatoDisposizioneEnum["BOZZA"] = "BOZZA";
    DettaglioRichiestaDtoStatoDisposizioneEnum["SOSPESO"] = "SOSPESO";
    DettaglioRichiestaDtoStatoDisposizioneEnum["STORNATO"] = "STORNATO";
    DettaglioRichiestaDtoStatoDisposizioneEnum["PAGATO"] = "PAGATO";
    DettaglioRichiestaDtoStatoDisposizioneEnum["ANNULLATO"] = "ANNULLATO";
})(DettaglioRichiestaDtoStatoDisposizioneEnum = exports.DettaglioRichiestaDtoStatoDisposizioneEnum || (exports.DettaglioRichiestaDtoStatoDisposizioneEnum = {}));
