"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PNS Client API
 * PNS - Push Notification Service: Api per l\'invio di notifiche
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PNSResponseSendToJSON = exports.PNSResponseSendFromJSONTyped = exports.PNSResponseSendFromJSON = void 0;
const runtime_1 = require("../runtime");
function PNSResponseSendFromJSON(json) {
    return PNSResponseSendFromJSONTyped(json, false);
}
exports.PNSResponseSendFromJSON = PNSResponseSendFromJSON;
function PNSResponseSendFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'inviati': !runtime_1.exists(json, 'inviati') ? undefined : json['inviati'],
        'ids': !runtime_1.exists(json, 'ids') ? undefined : json['ids'],
    };
}
exports.PNSResponseSendFromJSONTyped = PNSResponseSendFromJSONTyped;
function PNSResponseSendToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'inviati': value.inviati,
        'ids': value.ids,
    };
}
exports.PNSResponseSendToJSON = PNSResponseSendToJSON;
