"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConsensoResponseConsensoEnum = exports.ConsensoResponseToJSON = exports.ConsensoResponseFromJSONTyped = exports.ConsensoResponseFromJSON = void 0;
const runtime_1 = require("../runtime");
function ConsensoResponseFromJSON(json) {
    return ConsensoResponseFromJSONTyped(json, false);
}
exports.ConsensoResponseFromJSON = ConsensoResponseFromJSON;
function ConsensoResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'consenso': !runtime_1.exists(json, 'consenso') ? undefined : json['consenso'],
        'response': !runtime_1.exists(json, 'response') ? undefined : json['response'],
    };
}
exports.ConsensoResponseFromJSONTyped = ConsensoResponseFromJSONTyped;
function ConsensoResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'consenso': value.consenso,
        'response': value.response,
    };
}
exports.ConsensoResponseToJSON = ConsensoResponseToJSON;
/**
* @export
* @enum {string}
*/
var ConsensoResponseConsensoEnum;
(function (ConsensoResponseConsensoEnum) {
    ConsensoResponseConsensoEnum["COMUNICAZIONE"] = "COMUNICAZIONE";
    ConsensoResponseConsensoEnum["OFFERTE"] = "OFFERTE";
    ConsensoResponseConsensoEnum["MIGLIORIE"] = "MIGLIORIE";
    ConsensoResponseConsensoEnum["DATI"] = "DATI";
})(ConsensoResponseConsensoEnum = exports.ConsensoResponseConsensoEnum || (exports.ConsensoResponseConsensoEnum = {}));
