"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BolloAciElencoPagamentiDtoStatoEnum = exports.BolloAciElencoPagamentiDtoStatoDisposizioneEnum = exports.BolloAciElencoPagamentiDtoToJSON = exports.BolloAciElencoPagamentiDtoFromJSONTyped = exports.BolloAciElencoPagamentiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function BolloAciElencoPagamentiDtoFromJSON(json) {
    return BolloAciElencoPagamentiDtoFromJSONTyped(json, false);
}
exports.BolloAciElencoPagamentiDtoFromJSON = BolloAciElencoPagamentiDtoFromJSON;
function BolloAciElencoPagamentiDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'contoAddebito': !runtime_1.exists(json, 'contoAddebito') ? undefined : json['contoAddebito'],
        'identificativoMezzo': !runtime_1.exists(json, 'identificativoMezzo') ? undefined : json['identificativoMezzo'],
        'provinciaMezzo': !runtime_1.exists(json, 'provinciaMezzo') ? undefined : json['provinciaMezzo'],
        'tipoMezzoCode': !runtime_1.exists(json, 'tipoMezzoCode') ? undefined : json['tipoMezzoCode'],
        'dataInserimento': !runtime_1.exists(json, 'dataInserimento') ? undefined : (new Date(json['dataInserimento'])),
        'condivisione': !runtime_1.exists(json, 'condivisione') ? undefined : (json['condivisione'].map(_1.CondivisioneDtoFromJSON)),
        'bozza': !runtime_1.exists(json, 'bozza') ? undefined : _1.BozzaDtoFromJSON(json['bozza']),
        'inviato': !runtime_1.exists(json, 'inviato') ? undefined : _1.InviatoDtoFromJSON(json['inviato']),
        'statoDisposizione': !runtime_1.exists(json, 'statoDisposizione') ? undefined : json['statoDisposizione'],
        'dataOraRisposta': !runtime_1.exists(json, 'dataOraRisposta') ? undefined : (new Date(json['dataOraRisposta'])),
        'dataOraCompilazione': !runtime_1.exists(json, 'dataOraCompilazione') ? undefined : (new Date(json['dataOraCompilazione'])),
        'rrn': !runtime_1.exists(json, 'rrn') ? undefined : json['rrn'],
        'annoRrn': !runtime_1.exists(json, 'annoRrn') ? undefined : json['annoRrn'],
        'importo': !runtime_1.exists(json, 'importo') ? undefined : json['importo'],
        'commissioniOperazione': !runtime_1.exists(json, 'commissioniOperazione') ? undefined : json['commissioniOperazione'],
        'commissioneServizio': !runtime_1.exists(json, 'commissioneServizio') ? undefined : json['commissioneServizio'],
        'importoSanzioni': !runtime_1.exists(json, 'importoSanzioni') ? undefined : json['importoSanzioni'],
        'importoInteressi': !runtime_1.exists(json, 'importoInteressi') ? undefined : json['importoInteressi'],
        'totaleAddebito': !runtime_1.exists(json, 'totaleAddebito') ? undefined : json['totaleAddebito'],
        'codiceVersamento': !runtime_1.exists(json, 'codiceVersamento') ? undefined : json['codiceVersamento'],
        'dataOraAutorizzazione': !runtime_1.exists(json, 'dataOraAutorizzazione') ? undefined : (new Date(json['dataOraAutorizzazione'])),
        'idRichiesta': !runtime_1.exists(json, 'idRichiesta') ? undefined : json['idRichiesta'],
        'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
        'revocabile': !runtime_1.exists(json, 'revocabile') ? undefined : json['revocabile'],
        'utenza': !runtime_1.exists(json, 'utenza') ? undefined : _1.UtenzaDtoFromJSON(json['utenza']),
        'idBozzaCollegata': !runtime_1.exists(json, 'idBozzaCollegata') ? undefined : json['idBozzaCollegata'],
        'nativeId': !runtime_1.exists(json, 'nativeId') ? undefined : json['nativeId'],
        'contoAddebitoIban': !runtime_1.exists(json, 'contoAddebitoIban') ? undefined : json['contoAddebitoIban'],
        'contoAddebitoAlias': !runtime_1.exists(json, 'contoAddebitoAlias') ? undefined : json['contoAddebitoAlias'],
        'ordinanteIntestazione': !runtime_1.exists(json, 'ordinanteIntestazione') ? undefined : json['ordinanteIntestazione'],
        'statoReport': !runtime_1.exists(json, 'statoReport') ? undefined : json['statoReport'],
    };
}
exports.BolloAciElencoPagamentiDtoFromJSONTyped = BolloAciElencoPagamentiDtoFromJSONTyped;
function BolloAciElencoPagamentiDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'contoAddebito': value.contoAddebito,
        'identificativoMezzo': value.identificativoMezzo,
        'provinciaMezzo': value.provinciaMezzo,
        'tipoMezzoCode': value.tipoMezzoCode,
        'dataInserimento': value.dataInserimento === undefined ? undefined : (value.dataInserimento.toISOString()),
        'condivisione': value.condivisione === undefined ? undefined : (value.condivisione.map(_1.CondivisioneDtoToJSON)),
        'bozza': _1.BozzaDtoToJSON(value.bozza),
        'inviato': _1.InviatoDtoToJSON(value.inviato),
        'statoDisposizione': value.statoDisposizione,
        'dataOraRisposta': value.dataOraRisposta === undefined ? undefined : (value.dataOraRisposta.toISOString()),
        'dataOraCompilazione': value.dataOraCompilazione === undefined ? undefined : (value.dataOraCompilazione.toISOString()),
        'rrn': value.rrn,
        'annoRrn': value.annoRrn,
        'importo': value.importo,
        'commissioniOperazione': value.commissioniOperazione,
        'commissioneServizio': value.commissioneServizio,
        'importoSanzioni': value.importoSanzioni,
        'importoInteressi': value.importoInteressi,
        'totaleAddebito': value.totaleAddebito,
        'codiceVersamento': value.codiceVersamento,
        'dataOraAutorizzazione': value.dataOraAutorizzazione === undefined ? undefined : (value.dataOraAutorizzazione.toISOString()),
        'idRichiesta': value.idRichiesta,
        'stato': value.stato,
        'revocabile': value.revocabile,
        'utenza': _1.UtenzaDtoToJSON(value.utenza),
        'idBozzaCollegata': value.idBozzaCollegata,
        'nativeId': value.nativeId,
        'contoAddebitoIban': value.contoAddebitoIban,
        'contoAddebitoAlias': value.contoAddebitoAlias,
        'ordinanteIntestazione': value.ordinanteIntestazione,
        'statoReport': value.statoReport,
    };
}
exports.BolloAciElencoPagamentiDtoToJSON = BolloAciElencoPagamentiDtoToJSON;
/**
* @export
* @enum {string}
*/
var BolloAciElencoPagamentiDtoStatoDisposizioneEnum;
(function (BolloAciElencoPagamentiDtoStatoDisposizioneEnum) {
    BolloAciElencoPagamentiDtoStatoDisposizioneEnum["AUTORIZZATO"] = "AUTORIZZATO";
    BolloAciElencoPagamentiDtoStatoDisposizioneEnum["AUTORIZZATO_NON_REV"] = "AUTORIZZATO_NON_REV";
    BolloAciElencoPagamentiDtoStatoDisposizioneEnum["NON_AUTORIZZATO"] = "NON_AUTORIZZATO";
    BolloAciElencoPagamentiDtoStatoDisposizioneEnum["IN_CORSO"] = "IN_CORSO";
    BolloAciElencoPagamentiDtoStatoDisposizioneEnum["INVIATO"] = "INVIATO";
    BolloAciElencoPagamentiDtoStatoDisposizioneEnum["ESITO_INCERTO"] = "ESITO_INCERTO";
    BolloAciElencoPagamentiDtoStatoDisposizioneEnum["REVOCATO"] = "REVOCATO";
    BolloAciElencoPagamentiDtoStatoDisposizioneEnum["DA_AUTORIZZARE"] = "DA_AUTORIZZARE";
    BolloAciElencoPagamentiDtoStatoDisposizioneEnum["BOZZA"] = "BOZZA";
    BolloAciElencoPagamentiDtoStatoDisposizioneEnum["SOSPESO"] = "SOSPESO";
    BolloAciElencoPagamentiDtoStatoDisposizioneEnum["STORNATO"] = "STORNATO";
    BolloAciElencoPagamentiDtoStatoDisposizioneEnum["PAGATO"] = "PAGATO";
    BolloAciElencoPagamentiDtoStatoDisposizioneEnum["ANNULLATO"] = "ANNULLATO";
})(BolloAciElencoPagamentiDtoStatoDisposizioneEnum = exports.BolloAciElencoPagamentiDtoStatoDisposizioneEnum || (exports.BolloAciElencoPagamentiDtoStatoDisposizioneEnum = {}));
/**
* @export
* @enum {string}
*/
var BolloAciElencoPagamentiDtoStatoEnum;
(function (BolloAciElencoPagamentiDtoStatoEnum) {
    BolloAciElencoPagamentiDtoStatoEnum["DA_AUTORIZZARE"] = "DA_AUTORIZZARE";
    BolloAciElencoPagamentiDtoStatoEnum["BOZZA"] = "BOZZA";
    BolloAciElencoPagamentiDtoStatoEnum["NON_AUTORIZZATO"] = "NON_AUTORIZZATO";
    BolloAciElencoPagamentiDtoStatoEnum["INVIATO"] = "INVIATO";
    BolloAciElencoPagamentiDtoStatoEnum["REVOCATO"] = "REVOCATO";
})(BolloAciElencoPagamentiDtoStatoEnum = exports.BolloAciElencoPagamentiDtoStatoEnum || (exports.BolloAciElencoPagamentiDtoStatoEnum = {}));
