"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContattoDtoToJSON = exports.ContattoDtoFromJSONTyped = exports.ContattoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ContattoDtoFromJSON(json) {
    return ContattoDtoFromJSONTyped(json, false);
}
exports.ContattoDtoFromJSON = ContattoDtoFromJSON;
function ContattoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'nome': !runtime_1.exists(json, 'nome') ? undefined : json['nome'],
        'cognome': !runtime_1.exists(json, 'cognome') ? undefined : json['cognome'],
        'alias': !runtime_1.exists(json, 'alias') ? undefined : json['alias'],
        'azienda': !runtime_1.exists(json, 'azienda') ? undefined : json['azienda'],
        'cf': !runtime_1.exists(json, 'cf') ? undefined : json['cf'],
        'piva': !runtime_1.exists(json, 'piva') ? undefined : json['piva'],
        'indirizzo': !runtime_1.exists(json, 'indirizzo') ? undefined : _1.ContattoIndirizzoDtoFromJSON(json['indirizzo']),
        'dataNascita': !runtime_1.exists(json, 'dataNascita') ? undefined : (new Date(json['dataNascita'])),
        'luogoNascita': !runtime_1.exists(json, 'luogoNascita') ? undefined : json['luogoNascita'],
        'provinciaNascita': !runtime_1.exists(json, 'provinciaNascita') ? undefined : json['provinciaNascita'],
        'sesso': !runtime_1.exists(json, 'sesso') ? undefined : json['sesso'],
        'iban': !runtime_1.exists(json, 'iban') ? undefined : (json['iban'].map(_1.ContattoIbanDtoFromJSON)),
        'email': !runtime_1.exists(json, 'email') ? undefined : (json['email'].map(_1.ContattoEmailDtoFromJSON)),
        'telefono': !runtime_1.exists(json, 'telefono') ? undefined : (json['telefono'].map(_1.ContattoTelefonoDtoFromJSON)),
        'mezziTrasporto': !runtime_1.exists(json, 'mezziTrasporto') ? undefined : (json['mezziTrasporto'].map(_1.ContattoMezzoDtoFromJSON)),
        'sudtirolPass': !runtime_1.exists(json, 'sudtirolPass') ? undefined : (json['sudtirolPass'].map(_1.ContattoSudtirolPasDtoFromJSON)),
        'tipoContatto': !runtime_1.exists(json, 'tipoContatto') ? undefined : json['tipoContatto'],
        'dispensaCredenziale': !runtime_1.exists(json, 'dispensaCredenziale') ? undefined : json['dispensaCredenziale'],
        'dispensaF24': !runtime_1.exists(json, 'dispensaF24') ? undefined : json['dispensaF24'],
        'idVirtuale': !runtime_1.exists(json, 'idVirtuale') ? undefined : json['idVirtuale'],
        'codiceFunzione': !runtime_1.exists(json, 'codiceFunzione') ? undefined : json['codiceFunzione'],
    };
}
exports.ContattoDtoFromJSONTyped = ContattoDtoFromJSONTyped;
function ContattoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'nome': value.nome,
        'cognome': value.cognome,
        'alias': value.alias,
        'azienda': value.azienda,
        'cf': value.cf,
        'piva': value.piva,
        'indirizzo': _1.ContattoIndirizzoDtoToJSON(value.indirizzo),
        'dataNascita': value.dataNascita === undefined ? undefined : (value.dataNascita.toISOString()),
        'luogoNascita': value.luogoNascita,
        'provinciaNascita': value.provinciaNascita,
        'sesso': value.sesso,
        'iban': value.iban === undefined ? undefined : (value.iban.map(_1.ContattoIbanDtoToJSON)),
        'email': value.email === undefined ? undefined : (value.email.map(_1.ContattoEmailDtoToJSON)),
        'telefono': value.telefono === undefined ? undefined : (value.telefono.map(_1.ContattoTelefonoDtoToJSON)),
        'mezziTrasporto': value.mezziTrasporto === undefined ? undefined : (value.mezziTrasporto.map(_1.ContattoMezzoDtoToJSON)),
        'sudtirolPass': value.sudtirolPass === undefined ? undefined : (value.sudtirolPass.map(_1.ContattoSudtirolPasDtoToJSON)),
        'tipoContatto': value.tipoContatto,
        'dispensaCredenziale': value.dispensaCredenziale,
        'dispensaF24': value.dispensaF24,
        'idVirtuale': value.idVirtuale,
        'codiceFunzione': value.codiceFunzione,
    };
}
exports.ContattoDtoToJSON = ContattoDtoToJSON;
