"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnagraficaCedacriDtoToJSON = exports.AnagraficaCedacriDtoFromJSONTyped = exports.AnagraficaCedacriDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function AnagraficaCedacriDtoFromJSON(json) {
    return AnagraficaCedacriDtoFromJSONTyped(json, false);
}
exports.AnagraficaCedacriDtoFromJSON = AnagraficaCedacriDtoFromJSON;
function AnagraficaCedacriDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nome': !runtime_1.exists(json, 'nome') ? undefined : json['nome'],
        'cognome': !runtime_1.exists(json, 'cognome') ? undefined : json['cognome'],
        'preferenzeLinguistica': !runtime_1.exists(json, 'preferenzeLinguistica') ? undefined : json['preferenzeLinguistica'],
        'datanascita': !runtime_1.exists(json, 'datanascita') ? undefined : (new Date(json['datanascita'])),
        'luogonascita': !runtime_1.exists(json, 'luogonascita') ? undefined : json['luogonascita'],
        'codiceFiscale': !runtime_1.exists(json, 'codiceFiscale') ? undefined : json['codiceFiscale'],
        'ndg': !runtime_1.exists(json, 'ndg') ? undefined : json['ndg'],
        'cellulare': !runtime_1.exists(json, 'cellulare') ? undefined : json['cellulare'],
        'email': !runtime_1.exists(json, 'email') ? undefined : json['email'],
        'codiceUtenteIB': !runtime_1.exists(json, 'codiceUtenteIB') ? undefined : json['codiceUtenteIB'],
        'filiale': !runtime_1.exists(json, 'filiale') ? undefined : json['filiale'],
        'nominativoConsulente': !runtime_1.exists(json, 'nominativoConsulente') ? undefined : json['nominativoConsulente'],
        'filialeConsulente': !runtime_1.exists(json, 'filialeConsulente') ? undefined : json['filialeConsulente'],
        'mailConsulente': !runtime_1.exists(json, 'mailConsulente') ? undefined : json['mailConsulente'],
        'cellulareConsulente': !runtime_1.exists(json, 'cellulareConsulente') ? undefined : json['cellulareConsulente'],
    };
}
exports.AnagraficaCedacriDtoFromJSONTyped = AnagraficaCedacriDtoFromJSONTyped;
function AnagraficaCedacriDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'nome': value.nome,
        'cognome': value.cognome,
        'preferenzeLinguistica': value.preferenzeLinguistica,
        'datanascita': value.datanascita === undefined ? undefined : (value.datanascita.toISOString()),
        'luogonascita': value.luogonascita,
        'codiceFiscale': value.codiceFiscale,
        'ndg': value.ndg,
        'cellulare': value.cellulare,
        'email': value.email,
        'codiceUtenteIB': value.codiceUtenteIB,
        'filiale': value.filiale,
        'nominativoConsulente': value.nominativoConsulente,
        'filialeConsulente': value.filialeConsulente,
        'mailConsulente': value.mailConsulente,
        'cellulareConsulente': value.cellulareConsulente,
    };
}
exports.AnagraficaCedacriDtoToJSON = AnagraficaCedacriDtoToJSON;
