"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FasciaDtoToJSON = exports.FasciaDtoFromJSONTyped = exports.FasciaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function FasciaDtoFromJSON(json) {
    return FasciaDtoFromJSONTyped(json, false);
}
exports.FasciaDtoFromJSON = FasciaDtoFromJSON;
function FasciaDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'linguaFascia': !runtime_1.exists(json, 'linguaFascia') ? undefined : json['linguaFascia'],
        'fasciaGiornaliera': !runtime_1.exists(json, 'fasciaGiornaliera') ? undefined : json['fasciaGiornaliera'],
        'fasciaOraria': !runtime_1.exists(json, 'fasciaOraria') ? undefined : json['fasciaOraria'],
    };
}
exports.FasciaDtoFromJSONTyped = FasciaDtoFromJSONTyped;
function FasciaDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'linguaFascia': value.linguaFascia,
        'fasciaGiornaliera': value.fasciaGiornaliera,
        'fasciaOraria': value.fasciaOraria,
    };
}
exports.FasciaDtoToJSON = FasciaDtoToJSON;
