"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EReduxPSD2ActionTypes = exports.FormEnrollmentPSD2DtoFromJSON = exports.FormEnrollmentPSD2IbanDtoFromJSON = exports.PSD2StatusResponseDtoFromJSON = void 0;
const helpers_1 = require("../helpers");
function PSD2StatusResponseDtoFromJSON(json) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'psd2Response': !(0, helpers_1.exists)(json, 'psd2Response') ? {} : json.psd2Response,
    };
}
exports.PSD2StatusResponseDtoFromJSON = PSD2StatusResponseDtoFromJSON;
function FormEnrollmentPSD2IbanDtoFromJSON(json) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'iban': !(0, helpers_1.exists)(json, 'iban') ? "" : json.iban,
        'ibanError': !(0, helpers_1.exists)(json, 'ibanError') ? "invalid.required" : json.ibanError,
        'ibanDirty': !(0, helpers_1.exists)(json, 'ibanDirty') ? false : json.ibanDirty
    };
}
exports.FormEnrollmentPSD2IbanDtoFromJSON = FormEnrollmentPSD2IbanDtoFromJSON;
function FormEnrollmentPSD2DtoFromJSON(json) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'step': !(0, helpers_1.exists)(json, 'step') ? 0 : json.step,
        'banca': !(0, helpers_1.exists)(json, 'banca') ? undefined : json.banca,
        'username': !(0, helpers_1.exists)(json, 'username') ? "" : json.username,
        'usernameError': !(0, helpers_1.exists)(json, 'usernameError') ? "invalid.required" : json.usernameError,
        'usernameDirty': !(0, helpers_1.exists)(json, 'usernameDirty') ? false : json.usernameDirty,
        'prodotto': !(0, helpers_1.exists)(json, 'prodotto') ? undefined : json.prodotto,
        'presaVisione': !(0, helpers_1.exists)(json, 'presaVisione') ? false : json.presaVisione,
        'consenso': !(0, helpers_1.exists)(json, 'consenso') ? false : json.consenso,
        'ibanList': !(0, helpers_1.exists)(json, 'ibanList') ? [FormEnrollmentPSD2IbanDtoFromJSON({})] : json.ibanList.map(FormEnrollmentPSD2IbanDtoFromJSON),
        'formSubmitted': !(0, helpers_1.exists)(json, 'formSubmitted') ? false : json.formSubmitted,
    };
}
exports.FormEnrollmentPSD2DtoFromJSON = FormEnrollmentPSD2DtoFromJSON;
var EReduxPSD2ActionTypes;
(function (EReduxPSD2ActionTypes) {
    EReduxPSD2ActionTypes["PSD2_START_ENROLLMENT_START"] = "PSD2_START_ENROLLMENT_START";
    EReduxPSD2ActionTypes["PSD2_START_ENROLLMENT_SUCCESS"] = "PSD2_START_ENROLLMENT_SUCCESS";
    EReduxPSD2ActionTypes["PSD2_START_ENROLLMENT_ERROR"] = "PSD2_START_ENROLLMENT_ERROR";
    EReduxPSD2ActionTypes["PSD2_SET_BANCA_SELEZIONATA_ENROLLMENT"] = "PSD2_SET_BANCA_SELEZIONATA_ENROLLMENT";
    EReduxPSD2ActionTypes["PSD2_ENROLLMENT_ACTIVITIES_START"] = "PSD2_ENROLLMENT_ACTIVITIES_START";
    EReduxPSD2ActionTypes["PSD2_ENROLLMENT_ACTIVITIES_SUCCESS"] = "PSD2_ENROLLMENT_ACTIVITIES_SUCCESS";
    EReduxPSD2ActionTypes["PSD2_ENROLLMENT_ACTIVITIES_ERROR"] = "PSD2_ENROLLMENT_ACTIVITIES_ERROR";
    EReduxPSD2ActionTypes["PSD2_RESET_LINKS"] = "PSD2_RESET_LINKS";
    EReduxPSD2ActionTypes["PSD2_RESET_FORM_ENROLLMENT"] = "PSD2_RESET_FORM_ENROLLMENT";
    EReduxPSD2ActionTypes["PSD2_SET_STEP_ENROLLMENT"] = "PSD2_SET_STEP_ENROLLMENT";
    EReduxPSD2ActionTypes["PSD2_SET_USERNAME_ENROLLMENT"] = "PSD2_SET_USERNAME_ENROLLMENT";
    EReduxPSD2ActionTypes["PSD2_SET_CONSENSO_ENROLLMENT"] = "PSD2_SET_CONSENSO_ENROLLMENT";
    EReduxPSD2ActionTypes["PSD2_SET_PRODOTTO_ENROLLMENT"] = "PSD2_SET_PRODOTTO_ENROLLMENT";
    EReduxPSD2ActionTypes["PSD2_ADD_IBAN_ENROLLMENT"] = "PSD2_ADD_IBAN_ENROLLMENT";
    EReduxPSD2ActionTypes["PSD2_REMOVE_IBAN_ENROLLMENT"] = "PSD2_REMOVE_IBAN_ENROLLMENT";
    EReduxPSD2ActionTypes["PSD2_UPDATE_IBAN_ENROLLMENT"] = "PSD2_UPDATE_IBAN_ENROLLMENT";
    EReduxPSD2ActionTypes["PSD2_SET_FORM_ENROLLMENT_SUBMITTED"] = "PSD2_SET_FORM_ENROLLMENT_SUBMITTED";
    EReduxPSD2ActionTypes["PSD2_ENROLLMENT_STATUS_START"] = "PSD2_ENROLLMENT_STATUS_START";
    EReduxPSD2ActionTypes["PSD2_ENROLLMENT_STATUS_SUCCESS"] = "PSD2_ENROLLMENT_STATUS_SUCCESS";
    EReduxPSD2ActionTypes["PSD2_ENROLLMENT_STATUS_WEBVIEW_LINK"] = "PSD2_ENROLLMENT_STATUS_WEBVIEW_LINK";
    EReduxPSD2ActionTypes["PSD2_ENROLLMENT_STATUS_ERROR"] = "PSD2_ENROLLMENT_ACTIVITIES_ERROR";
    EReduxPSD2ActionTypes["PSD2_ENROLLMENT_POLLING_SET_ACTIVE"] = "PSD2_ENROLLMENT_POLLING_SET_ACTIVE";
    EReduxPSD2ActionTypes["PSD2_DOWNLOAD_PDF_ENROLLMENT_START"] = "PSD2_DOWNLOAD_PDF_ENROLLMENT_START";
    EReduxPSD2ActionTypes["PSD2_DOWNLOAD_PDF_ENROLLMENT_SUCCESS"] = "PSD2_DOWNLOAD_PDF_ENROLLMENT_SUCCESS";
    EReduxPSD2ActionTypes["PSD2_RESET_ENROLLMENT"] = "PSD2_RESET_ENROLLMENT";
    EReduxPSD2ActionTypes["PSD2_CONSENT_RENEW_START"] = "PSD2_CONSENT_RENEW_START";
    EReduxPSD2ActionTypes["PSD2_CONSENT_RENEW_WEBVIEW_LINK"] = "PSD2_CONSENT_RENEW_WEBVIEW_LINK";
    EReduxPSD2ActionTypes["PSD2_CONSENT_RENEW_ERROR"] = "PSD2_CONSENT_RENEW_ERROR";
    EReduxPSD2ActionTypes["VERIFICA_FIRMA_DIGITALE_START"] = "VERIFICA_FIRMA_DIGITALE_START";
    EReduxPSD2ActionTypes["VERIFICA_FIRMA_DIGITALE_SUCCESS"] = "VERIFICA_FIRMA_DIGITALE_SUCCESS";
    EReduxPSD2ActionTypes["VERIFICA_FIRMA_DIGITALE_ERROR"] = "VERIFICA_FIRMA_DIGITALE_ERROR";
    EReduxPSD2ActionTypes["CREA_CERTIFICATO_FIRMA_DIGITALE_START"] = "CREA_CERTIFICATO_FIRMA_DIGITALE_START";
    EReduxPSD2ActionTypes["CREA_CERTIFICATO_FIRMA_DIGITALE_SUCCESS"] = "CREA_CERTIFICATO_FIRMA_DIGITALE_SUCCESS";
    EReduxPSD2ActionTypes["CREA_CERTIFICATO_FIRMA_DIGITALE_ERROR"] = "CREA_CERTIFICATO_FIRMA_DIGITALE_ERROR";
    EReduxPSD2ActionTypes["RESET_PROCESSO_FIRMA_DIGITALE"] = "RESET_PROCESSO_FIRMA_DIGITALE";
    EReduxPSD2ActionTypes["PSD2_CONSENT_RENEW_SUCCESS"] = "PSD2_CONSENT_RENEW_SUCCESS";
    EReduxPSD2ActionTypes["PSD2_PAYMENT_STATUS_ERROR"] = "PSD2_PAYMENT_STATUS_ERROR";
    EReduxPSD2ActionTypes["PSD2_PAYMENT_POLLING_SET_ACTIVE"] = "PSD2_PAYMENT_POLLING_SET_ACTIVE";
    EReduxPSD2ActionTypes["PSD2_SET_RESPONSE_STATUS"] = "PSD2_SET_RESPONSE_STATUS";
})(EReduxPSD2ActionTypes = exports.EReduxPSD2ActionTypes || (exports.EReduxPSD2ActionTypes = {}));
