"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuotazioniDtoToJSON = exports.QuotazioniDtoFromJSONTyped = exports.QuotazioniDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function QuotazioniDtoFromJSON(json) {
    return QuotazioniDtoFromJSONTyped(json, false);
}
exports.QuotazioniDtoFromJSON = QuotazioniDtoFromJSON;
function QuotazioniDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'testata': !runtime_1.exists(json, 'testata') ? undefined : _1.TestataDtoFromJSON(json['testata']),
        'quotazione': !runtime_1.exists(json, 'quotazione') ? undefined : (json['quotazione'].map(_1.QuotazioneDtoFromJSON)),
    };
}
exports.QuotazioniDtoFromJSONTyped = QuotazioniDtoFromJSONTyped;
function QuotazioniDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'testata': _1.TestataDtoToJSON(value.testata),
        'quotazione': value.quotazione === undefined ? undefined : (value.quotazione.map(_1.QuotazioneDtoToJSON)),
    };
}
exports.QuotazioniDtoToJSON = QuotazioniDtoToJSON;
