"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Psd2PaymentDtoExecutionRuleEnum = exports.Psd2PaymentDtoToJSON = exports.Psd2PaymentDtoFromJSONTyped = exports.Psd2PaymentDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function Psd2PaymentDtoFromJSON(json) {
    return Psd2PaymentDtoFromJSONTyped(json, false);
}
exports.Psd2PaymentDtoFromJSON = Psd2PaymentDtoFromJSON;
function Psd2PaymentDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'resourceId': !runtime_1.exists(json, 'resourceId') ? undefined : json['resourceId'],
        'psuId': !runtime_1.exists(json, 'psuId') ? undefined : json['psuId'],
        'aspspId': !runtime_1.exists(json, 'aspspId') ? undefined : json['aspspId'],
        'providerId': !runtime_1.exists(json, 'providerId') ? undefined : json['providerId'],
        'aspspProductCode': !runtime_1.exists(json, 'aspspProductCode') ? undefined : json['aspspProductCode'],
        'aspspBusinessName': !runtime_1.exists(json, 'aspspBusinessName') ? undefined : json['aspspBusinessName'],
        'aspspProductTypes': !runtime_1.exists(json, 'aspspProductTypes') ? undefined : json['aspspProductTypes'],
        'aspspProductSuggestedLabel': !runtime_1.exists(json, 'aspspProductSuggestedLabel') ? undefined : json['aspspProductSuggestedLabel'],
        'transactionStatus': !runtime_1.exists(json, 'transactionStatus') ? undefined : json['transactionStatus'],
        'paymentId': !runtime_1.exists(json, 'paymentId') ? undefined : json['paymentId'],
        'paymentProduct': !runtime_1.exists(json, 'paymentProduct') ? undefined : json['paymentProduct'],
        'url': !runtime_1.exists(json, 'url') ? undefined : json['url'],
        'transactionType': !runtime_1.exists(json, 'transactionType') ? undefined : json['transactionType'],
        'nomeBanca': !runtime_1.exists(json, 'nomeBanca') ? undefined : json['nomeBanca'],
        'executionRule': !runtime_1.exists(json, 'executionRule') ? undefined : json['executionRule'],
        'webHook': !runtime_1.exists(json, 'webHook') ? undefined : json['webHook'],
        'creditorIban': !runtime_1.exists(json, 'creditorIban') ? undefined : json['creditorIban'],
        'intestatario': !runtime_1.exists(json, 'intestatario') ? undefined : json['intestatario'],
    };
}
exports.Psd2PaymentDtoFromJSONTyped = Psd2PaymentDtoFromJSONTyped;
function Psd2PaymentDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'resourceId': value.resourceId,
        'psuId': value.psuId,
        'aspspId': value.aspspId,
        'providerId': value.providerId,
        'aspspProductCode': value.aspspProductCode,
        'aspspBusinessName': value.aspspBusinessName,
        'aspspProductTypes': value.aspspProductTypes,
        'aspspProductSuggestedLabel': value.aspspProductSuggestedLabel,
        'transactionStatus': value.transactionStatus,
        'paymentId': value.paymentId,
        'paymentProduct': value.paymentProduct,
        'url': value.url,
        'transactionType': value.transactionType,
        'nomeBanca': value.nomeBanca,
        'executionRule': value.executionRule,
        'webHook': value.webHook,
        'creditorIban': value.creditorIban,
        'intestatario': value.intestatario,
    };
}
exports.Psd2PaymentDtoToJSON = Psd2PaymentDtoToJSON;
/**
* @export
* @enum {string}
*/
var Psd2PaymentDtoExecutionRuleEnum;
(function (Psd2PaymentDtoExecutionRuleEnum) {
    Psd2PaymentDtoExecutionRuleEnum["FOLLOWING"] = "FOLLOWING";
    Psd2PaymentDtoExecutionRuleEnum["PRECEDING"] = "PRECEDING";
})(Psd2PaymentDtoExecutionRuleEnum = exports.Psd2PaymentDtoExecutionRuleEnum || (exports.Psd2PaymentDtoExecutionRuleEnum = {}));
