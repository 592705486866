"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelfResetUpdateStatoRequestDtoStatoEnum = exports.SelfResetUpdateStatoRequestDtoToJSON = exports.SelfResetUpdateStatoRequestDtoFromJSONTyped = exports.SelfResetUpdateStatoRequestDtoFromJSON = void 0;
function SelfResetUpdateStatoRequestDtoFromJSON(json) {
    return SelfResetUpdateStatoRequestDtoFromJSONTyped(json, false);
}
exports.SelfResetUpdateStatoRequestDtoFromJSON = SelfResetUpdateStatoRequestDtoFromJSON;
function SelfResetUpdateStatoRequestDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codiceUtente': json['codiceUtente'],
        'vendorId': json['vendorId'],
        'stato': json['stato'],
    };
}
exports.SelfResetUpdateStatoRequestDtoFromJSONTyped = SelfResetUpdateStatoRequestDtoFromJSONTyped;
function SelfResetUpdateStatoRequestDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codiceUtente': value.codiceUtente,
        'vendorId': value.vendorId,
        'stato': value.stato,
    };
}
exports.SelfResetUpdateStatoRequestDtoToJSON = SelfResetUpdateStatoRequestDtoToJSON;
/**
* @export
* @enum {string}
*/
var SelfResetUpdateStatoRequestDtoStatoEnum;
(function (SelfResetUpdateStatoRequestDtoStatoEnum) {
    SelfResetUpdateStatoRequestDtoStatoEnum["PENDING"] = "PENDING";
    SelfResetUpdateStatoRequestDtoStatoEnum["SUCCESS"] = "SUCCESS";
    SelfResetUpdateStatoRequestDtoStatoEnum["FAIL"] = "FAIL";
    SelfResetUpdateStatoRequestDtoStatoEnum["CLOSE"] = "CLOSE";
    SelfResetUpdateStatoRequestDtoStatoEnum["INSERITA"] = "INSERITA";
})(SelfResetUpdateStatoRequestDtoStatoEnum = exports.SelfResetUpdateStatoRequestDtoStatoEnum || (exports.SelfResetUpdateStatoRequestDtoStatoEnum = {}));
