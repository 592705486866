"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetDossierDaAcquistare = exports.setDossierTitoliSetTitoloDaAcquistare = exports.esportaDossierTitoliEXCEL = exports.esportaDossierTitoliPDF = exports.resetDossierTitoliGraphics = exports.resetDossierTitoliDossier = exports.resetDossierTitoli = exports.setDossierTitoliDepositiSelezionati = exports.setDossierTitoliTuttiRapporti = exports.setDossierTitoliRapportoSelezionato = exports.getCustomerGraphicsWidget = exports.getCustomerGraphics = exports.getCustomerDossierWidget = exports.getCustomerDossier = exports.getDossierTitoliRapporti = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
function getDossierTitoliRapportiStart() {
    return {
        type: __1.EReduxActionTypes.DOSSIER_TITOLI_GET_RAPPORTI_START
    };
}
function getDossierTitoliRapportiSucces(payload) {
    return {
        type: __1.EReduxActionTypes.DOSSIER_TITOLI_GET_RAPPORTI_SUCCESS,
        payload
    };
}
const getDossierTitoliRapporti = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getDossierTitoliRapportiStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/portafoglio_titoli_rapporti.json')).then(response => {
            dispatch(getDossierTitoliRapportiSucces(response.map(r => (0, api_rest_1.DatiIntestatarioDtoFromJSON)(r))));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.PrometeiaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .soggettiOperantiPerFinanza().then((response) => {
                dispatch(getDossierTitoliRapportiSucces(response.filter(r => { var _a; return r.rapportiInformativi && ((_a = r.rapportiInformativi) === null || _a === void 0 ? void 0 : _a.length) > 0; })));
            }).catch(async (error) => {
                dispatch((0, general_actions_1.setError)(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getDossierTitoliRapporti = getDossierTitoliRapporti;
function getDossierTitoliCustomerDossierStart() {
    return {
        type: __1.EReduxActionTypes.DOSSIER_TITOLI_GET_CUSTOMER_DOSSIER_START
    };
}
function getDossierTitoliCustomerDossierSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.DOSSIER_TITOLI_GET_CUSTOMER_DOSSIER_SUCCESS,
        payload
    };
}
const getCustomerDossier = (loaderEnabled = true) => async (dispatch, getState, extraArguments) => {
    var _a, _b, _c;
    const dossierTitoli = getState().dossierTitoli;
    loaderEnabled && dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getDossierTitoliCustomerDossierStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/portafoglio_titoli_dossier.json')).then(response => {
            dispatch(getDossierTitoliCustomerDossierSuccess((0, api_rest_1.DossierBODtoFromJSON)(response)));
            loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const rapportoSelezionato = (_a = dossierTitoli.form) === null || _a === void 0 ? void 0 : _a.rapportoSelezionato;
        const customerCode = String(rapportoSelezionato === null || rapportoSelezionato === void 0 ? void 0 : rapportoSelezionato.ndg);
        const isRapportoSelezionatoIntestato = !rapportoSelezionato.rapportoCointestato && !rapportoSelezionato.rapportoDelega;
        const isRapportoDelegato = rapportoSelezionato.rapportoDelega;
        const accounts = isRapportoDelegato
            ? ((_b = rapportoSelezionato.rapportiInformativi) === null || _b === void 0 ? void 0 : _b.map(r => {
                return r.toString().substring(r.toString().length - 8);
            })) || []
            : [];
        const includeCoi = isRapportoDelegato ? true : isRapportoSelezionatoIntestato ? (_c = dossierTitoli.form) === null || _c === void 0 ? void 0 : _c.tuttiRapporti : false;
        const customerDossierRequest = {
            dossierRequestInDto: {
                customerCode,
                accounts,
                includeCoi
            }
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.PrometeiaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .getCustomerDossier(customerDossierRequest).then((response) => {
                dispatch(getDossierTitoliCustomerDossierSuccess(response));
            }).catch(async (error) => {
                dispatch((0, general_actions_1.setError)(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getCustomerDossier = getCustomerDossier;
const getCustomerDossierWidget = (filtri, loaderEnabled = true) => async (dispatch, getState, extraArguments) => {
    var _a;
    loaderEnabled && dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getDossierTitoliCustomerDossierStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/portafoglio_titoli_dossier.json')).then(response => {
            dispatch(getDossierTitoliCustomerDossierSuccess((0, api_rest_1.DossierBODtoFromJSON)(response)));
            loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        if (!filtri) {
            console.error('Filtri necessari!');
            return;
        }
        const rapportoSelezionato = filtri.rapportoSelezionato;
        const customerCode = String(rapportoSelezionato === null || rapportoSelezionato === void 0 ? void 0 : rapportoSelezionato.ndg);
        const isRapportoSelezionatoIntestato = !rapportoSelezionato.rapportoCointestato && !rapportoSelezionato.rapportoDelega;
        const isRapportoDelegato = rapportoSelezionato.rapportoDelega;
        const accounts = isRapportoDelegato
            ? ((_a = rapportoSelezionato.rapportiInformativi) === null || _a === void 0 ? void 0 : _a.map(r => {
                return r.toString().substring(r.toString().length - 8);
            })) || []
            : [];
        const includeCoi = isRapportoDelegato ? true : isRapportoSelezionatoIntestato ? filtri.tuttiRapporti : false;
        const customerDossierRequest = {
            dossierRequestInDto: {
                customerCode,
                accounts,
                includeCoi
            }
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.PrometeiaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .getCustomerDossier(customerDossierRequest).then((response) => {
                dispatch(getDossierTitoliCustomerDossierSuccess(response));
            }).catch(async (error) => {
                dispatch((0, general_actions_1.setError)(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getCustomerDossierWidget = getCustomerDossierWidget;
function getDossierTitoliCustomerGraphicsStart() {
    return {
        type: __1.EReduxActionTypes.DOSSIER_TITOLI_GET_GRAPHICS_START
    };
}
function getDossierTitoliCustomerGraphicsSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.DOSSIER_TITOLI_GET_GRAPHICS_SUCCESS,
        payload
    };
}
const getCustomerGraphics = (loaderEnabled = true, depositiSelezionati) => async (dispatch, getState, extraArguments) => {
    var _a, _b, _c;
    const dossierTitoli = getState().dossierTitoli;
    loaderEnabled && dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getDossierTitoliCustomerGraphicsStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/portafoglio_titoli_graphics.json')).then(response => {
            dispatch(getDossierTitoliCustomerGraphicsSuccess(response === null || response === void 0 ? void 0 : response.map(api_rest_1.BreakdownDtoFromJSON)));
            loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const rapportoSelezionato = (_a = dossierTitoli.form) === null || _a === void 0 ? void 0 : _a.rapportoSelezionato;
        const customerId = String(rapportoSelezionato === null || rapportoSelezionato === void 0 ? void 0 : rapportoSelezionato.ndg);
        const isRapportoSelezionatoIntestato = !rapportoSelezionato.rapportoCointestato && !rapportoSelezionato.rapportoDelega;
        const isRapportoDelegato = rapportoSelezionato.rapportoDelega;
        const accounts = isRapportoDelegato && depositiSelezionati.length == 0
            ? ((_b = rapportoSelezionato.rapportiInformativi) === null || _b === void 0 ? void 0 : _b.map(r => {
                return r.toString().substring(r.toString().length - 8).padStart(8, "0");
            })) || []
            : depositiSelezionati.map(r => {
                return r.toString().substring(r.toString().length - 8).padStart(8, "0");
            });
        const includeCoi = isRapportoDelegato ? true : isRapportoSelezionatoIntestato ? (_c = dossierTitoli.form) === null || _c === void 0 ? void 0 : _c.tuttiRapporti : false;
        const customerGraphicsRequest = {
            getBreakdownsInDto: {
                customerId,
                accounts,
                includeCoi
            }
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.PrometeiaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .getBreakdowns(customerGraphicsRequest).then((response) => {
                dispatch(getDossierTitoliCustomerGraphicsSuccess(response));
            }).catch(async (error) => {
                dispatch((0, general_actions_1.setError)(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getCustomerGraphics = getCustomerGraphics;
const getCustomerGraphicsWidget = (filtri, loaderEnabled = true) => async (dispatch, getState, extraArguments) => {
    var _a;
    loaderEnabled && dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getDossierTitoliCustomerGraphicsStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/portafoglio_titoli_graphics.json')).then(response => {
            dispatch(getDossierTitoliCustomerGraphicsSuccess(response === null || response === void 0 ? void 0 : response.map(api_rest_1.BreakdownDtoFromJSON)));
            loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        if (!filtri) {
            console.error('Filtri necessari!');
            return;
        }
        const rapportoSelezionato = filtri.rapportoSelezionato;
        const customerId = String(rapportoSelezionato === null || rapportoSelezionato === void 0 ? void 0 : rapportoSelezionato.ndg);
        const isRapportoSelezionatoIntestato = !rapportoSelezionato.rapportoCointestato && !rapportoSelezionato.rapportoDelega;
        const isRapportoDelegato = rapportoSelezionato.rapportoDelega;
        const depositiSelezionati = filtri.depositiSelezionati || [];
        const accounts = isRapportoDelegato && depositiSelezionati.length == 0
            ? ((_a = rapportoSelezionato.rapportiInformativi) === null || _a === void 0 ? void 0 : _a.map(r => {
                return r.toString().substring(r.toString().length - 8).padStart(8, "0");
            })) || []
            : depositiSelezionati.map(r => {
                return r.toString().substring(r.toString().length - 8).padStart(8, "0");
            });
        const includeCoi = isRapportoDelegato ? true : isRapportoSelezionatoIntestato ? filtri.tuttiRapporti : false;
        const customerGraphicsRequest = {
            getBreakdownsInDto: {
                customerId,
                accounts,
                includeCoi
            }
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.PrometeiaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .getBreakdowns(customerGraphicsRequest).then((response) => {
                dispatch(getDossierTitoliCustomerGraphicsSuccess(response));
            }).catch(async (error) => {
                dispatch((0, general_actions_1.setError)(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getCustomerGraphicsWidget = getCustomerGraphicsWidget;
function setDossierTitoliRapportoSelezionato(payload) {
    return {
        type: __1.EReduxActionTypes.DOSSIER_TITOLI_SET_RAPPORTO_SELEZIONATO,
        payload
    };
}
exports.setDossierTitoliRapportoSelezionato = setDossierTitoliRapportoSelezionato;
function setDossierTitoliTuttiRapporti(payload) {
    return {
        type: __1.EReduxActionTypes.DOSSIER_TITOLI_SET_TUTTI_RAPPORTI,
        payload
    };
}
exports.setDossierTitoliTuttiRapporti = setDossierTitoliTuttiRapporti;
function setDossierTitoliDepositiSelezionati(payload) {
    return {
        type: __1.EReduxActionTypes.DOSSIER_TITOLI_SET_DEPOSITI_SELEZIONATI,
        payload
    };
}
exports.setDossierTitoliDepositiSelezionati = setDossierTitoliDepositiSelezionati;
function resetDossierTitoli() {
    return {
        type: __1.EReduxActionTypes.DOSSIER_TITOLI_RESET
    };
}
exports.resetDossierTitoli = resetDossierTitoli;
function resetDossierTitoliDossier() {
    return {
        type: __1.EReduxActionTypes.DOSSIER_TITOLI_RESET_DOSSIER
    };
}
exports.resetDossierTitoliDossier = resetDossierTitoliDossier;
function resetDossierTitoliGraphics() {
    return {
        type: __1.EReduxActionTypes.DOSSIER_TITOLI_RESET_GRAPHICS
    };
}
exports.resetDossierTitoliGraphics = resetDossierTitoliGraphics;
/* esporta PDF dossier titoli - START */
function esportaDossierTitoliPDFStart() {
    return {
        type: __1.EReduxActionTypes.DOSSIER_TITOLI_ESPORTA_PDF_START
    };
}
function esportaDossierTitoliPDFSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.DOSSIER_TITOLI_ESPORTA_PDF_SUCCESS,
        payload
    };
}
const esportaDossierTitoliPDF = (datiRequest, share, title, message) => async (dispatch, _, extraArguments) => {
    dispatch(esportaDossierTitoliPDFStart());
    dispatch((0, general_actions_1.setLoader)(true));
    if (extraArguments.mock) {
        /* TODO: vedere nella parte mockata cosa restituire
          import('../../mock/assegni.json').then(parametri => {
            dispatch(esportaAssegniPDFSuccess(ListaAssegniDtoFromJSON(parametri)));
          });
          */
    }
    else {
        const esportaAssegniPDFRequest = {
            type: api_rest_1.StampaElencoTitoliTypeEnum.pdf,
            dossierBODto: datiRequest
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.PrometeiaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf')).stampaElencoTitoli(esportaAssegniPDFRequest).then((response) => {
                if (extraArguments.platform === 'desktop') {
                    (0, helpers_1.hadlePdfResponse)(response, (url) => dispatch((0, general_actions_1.popUpBlockedPdf)(url)));
                }
                if (extraArguments.platform === 'mobile') {
                    (0, helpers_1.download_sharePDF)(extraArguments, 'dossier_titoli.pdf', response, share, title, message);
                }
                dispatch(esportaDossierTitoliPDFSuccess(response));
            }).catch(async (error) => {
                dispatch((0, general_actions_1.setError)(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.esportaDossierTitoliPDF = esportaDossierTitoliPDF;
/* esporta PDF dossier titoli - FINE */
/* esporta PDF dossier titoli - START */
function esportaDossierTitoliEXCELStart() {
    return {
        type: __1.EReduxActionTypes.DOSSIER_TITOLI_ESPORTA_EXCEL_START
    };
}
function esportaDossierTitoliEXCELSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.DOSSIER_TITOLI_ESPORTA_EXCEL_SUCCESS,
        payload
    };
}
const esportaDossierTitoliEXCEL = (datiRequest, share, title, message) => async (dispatch, _, extraArguments) => {
    dispatch(esportaDossierTitoliEXCELStart());
    dispatch((0, general_actions_1.setLoader)(true));
    if (extraArguments.mock) {
        /* TODO: vedere nella parte mockata cosa restituire
          import('../../mock/assegni.json').then(parametri => {
            dispatch(esportaAssegniPDFSuccess(ListaAssegniDtoFromJSON(parametri)));
          });
          */
    }
    else {
        const esportaAssegniPDFRequest = {
            type: api_rest_1.StampaElencoTitoliTypeEnum.excel,
            dossierBODto: datiRequest
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.PrometeiaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/vnd.ms-excel')).stampaElencoTitoli(esportaAssegniPDFRequest).then((response) => {
                if (extraArguments.platform === 'desktop') {
                    (0, helpers_1.hadleExcelResponse)(response, 'dossier_titoli.xlsx');
                }
                if (extraArguments.platform === 'mobile') {
                    (0, helpers_1.download_sharePDF)(extraArguments, 'dossier_titoli.xls', response, share, title, message);
                }
                dispatch(esportaDossierTitoliEXCELSuccess(response));
            }).catch(async (error) => {
                dispatch((0, general_actions_1.setError)(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.esportaDossierTitoliEXCEL = esportaDossierTitoliEXCEL;
/* esporta EXCEL dossier titoli - FINE */
function setDossierTitoliSetTitoloDaAcquistare(payload) {
    return {
        type: __1.EReduxActionTypes.DOSSIER_TITOLI_SET_TITOLO_DA_ACQUISTARE,
        payload
    };
}
exports.setDossierTitoliSetTitoloDaAcquistare = setDossierTitoliSetTitoloDaAcquistare;
function resetDossierDaAcquistare() {
    return {
        type: __1.EReduxActionTypes.DOSSIER_TITOLI_RESET_TITOLO_DA_ACQUISTARE
    };
}
exports.resetDossierDaAcquistare = resetDossierDaAcquistare;
