"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ribaReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const helpers_1 = require("../helpers");
const initialState = {
    parametri: (0, api_rest_1.ParametriRibaDtoFromJSON)({}),
    form: {
        formSubmitted: false,
        step: 0,
        selectedAvvisi: [],
        selectedAll: false
    },
    infoAutenticazione: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
    esito: (0, api_rest_1.EsitoAutorizzazioneDisposizioneRibaDtoFromJSON)({}),
    riepilogo: (0, api_rest_1.RibaDtoFromJSON)({}),
    elenco: [],
    infoAutenticazioneRevoca: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
    esitoRevoca: (0, api_rest_1.EsitoAutorizzazioneDisposizioneRibaDtoFromJSON)({}),
    pdfElenco: undefined,
    excelElenco: undefined,
    pdfRicevuta: undefined
};
const ribaReducer = (state = initialState, action) => {
    var _a, _b;
    switch (action.type) {
        case __1.EReduxActionTypes.RIBA_GET_PARAMETRI_SUCCESS:
            return {
                ...initialState,
                parametri: action.payload
            };
        case __1.EReduxActionTypes.RIBA_GET_INFO_AUTENTICAZIONE_SUCCESS:
            return {
                ...state,
                infoAutenticazione: action.payload,
                esito: initialState.esito
            };
        case __1.EReduxActionTypes.RIBA_CONTROLLO_SUCCESS:
            return {
                ...state,
                riepilogo: action.payload
            };
        case __1.EReduxActionTypes.RIBA_AUTORIZZA_SUCCESS:
            if (action.payload.stato === 'OK') {
                return {
                    ...state,
                    //riepilogo: initialState.riepilogo,
                    esito: action.payload
                };
            }
            else {
                return {
                    ...state,
                    infoAutenticazione: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoAutenticazione, action.payload.informazioniAutenticazioneDispositiva),
                    esito: action.payload
                };
            }
        case __1.EReduxActionTypes.RIBA_GET_ELENCO_SUCCESS:
            return {
                ...state,
                elenco: action.payload
            };
        case __1.EReduxActionTypes.RIBA_GET_INFO_AUTENTICAZIONE_REVOCA_SUCCESS:
            return {
                ...state,
                infoAutenticazioneRevoca: action.payload,
                esitoRevoca: initialState.esitoRevoca
            };
        case __1.EReduxActionTypes.RIBA_AUTORIZZA_REVOCA_SUCCESS:
            if (action.payload.stato === 'OK') {
                return {
                    ...state,
                    //riepilogo: initialState.riepilogo,
                    esitoRevoca: action.payload
                };
            }
            else {
                return {
                    ...state,
                    infoAutenticazioneRevoca: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoAutenticazioneRevoca, action.payload.informazioniAutenticazioneDispositiva),
                    esitoRevoca: action.payload
                };
            }
        case __1.EReduxActionTypes.RIBA_ESPORTA_PDF_ELENCO_SUCCESS:
            return {
                ...state,
                pdfElenco: action.payload
            };
        case __1.EReduxActionTypes.RIBA_ESPORTA_EXCEL_ELENCO_SUCCESS:
            return {
                ...state,
                excelElenco: action.payload
            };
        case __1.EReduxActionTypes.RIBA_SET_RAPPORTO_SELEZIONATO:
            return {
                ...state,
                form: {
                    ...state.form,
                    rapportoSelezionato: action.payload
                }
            };
        case __1.EReduxActionTypes.RIBA_SET_STEP:
            return {
                ...state,
                form: {
                    ...state.form,
                    step: action.payload
                }
            };
        case __1.EReduxActionTypes.RIBA_SET_SELECTED_ALL_AVVISI:
            const selectedAvvisi = action.payload ? state.elenco : [];
            return {
                ...state,
                form: {
                    ...state.form,
                    selectedAvvisi: selectedAvvisi,
                    selectedAll: action.payload
                }
            };
        case __1.EReduxActionTypes.RIBA_SET_SELECTED_AVVISI:
            const selectedAvviso = (_a = state === null || state === void 0 ? void 0 : state.elenco) === null || _a === void 0 ? void 0 : _a.filter((item) => { var _a; return (item === null || item === void 0 ? void 0 : item.numeroAvviso) === ((_a = action.payload) === null || _a === void 0 ? void 0 : _a.numeroAvviso); })[0];
            const selected = state.form.selectedAvvisi.filter((item) => { var _a; return (item === null || item === void 0 ? void 0 : item.numeroAvviso) !== ((_a = action.payload) === null || _a === void 0 ? void 0 : _a.numeroAvviso); });
            return {
                ...state,
                form: {
                    ...state.form,
                    selectedAvvisi: ((_b = action.payload) === null || _b === void 0 ? void 0 : _b.selected) ? [
                        ...state.form.selectedAvvisi, selectedAvviso
                    ] : selected,
                    selectedAll: selected.length === state.elenco.length ? true : false
                }
            };
        case __1.EReduxActionTypes.RIBA_SET_FORM_SUBMITTED:
            return {
                ...state,
                form: {
                    ...state.form,
                    formSubmitted: action.payload
                }
            };
        case __1.EReduxActionTypes.RIBA_SET_COD_FISCALE_PARTITA_IVA:
            return {
                ...state,
                codFiscalePIvaSelezionato: action.payload
            };
        case __1.EReduxActionTypes.RIBA_RESET:
            return {
                ...initialState
            };
        case __1.EReduxActionTypes.RESET_ALL: // action invocata dalla logout
            return {
                ...initialState
            };
        default:
            return state;
    }
};
exports.ribaReducer = ribaReducer;
exports.default = exports.ribaReducer;
