"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rendimentiReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const helpers_1 = require("../helpers");
const rendimenti_types_1 = require("./rendimenti.types");
const initialState = {
    form: {
        tuttiRapporti: false,
        selectedDeposito: [],
        periodo: rendimenti_types_1.PeriodoRendimenti.yearToDate,
        dataDa: "",
        dataDaError: "",
        dataA: "",
        dataAError: "",
        accordionFiltriOpened: false
    },
    rapporti: [],
    rapportoSelezionato: (0, api_rest_1.DatiIntestatarioDtoFromJSON)({}),
    accountRendimenti: (0, api_rest_1.AccountBODtoFromJSON)({})
};
const rendimentiReducer = (state = initialState, action) => {
    switch (action.type) {
        case __1.EReduxActionTypes.RENDIMENTI_GET_RAPPORTI_SUCCESS:
            return {
                ...state,
                rapporti: action.payload
            };
        case __1.EReduxActionTypes.RENDIMENTI_SET_RAPPORTO_SELEZIONATO:
            return {
                ...state,
                rapportoSelezionato: action.payload
            };
        case __1.EReduxActionTypes.RENDIMENTI_RESET_FILTRI:
            return {
                ...state,
                form: {
                    ...initialState.form,
                    tuttiRapporti: state.form.tuttiRapporti,
                    selectedDeposito: state.form.selectedDeposito
                }
            };
        case __1.EReduxActionTypes.RENDIMENTI_DATA_DA:
            return {
                ...state,
                form: {
                    ...state.form,
                    dataDa: action.payload,
                    dataDaError: (0, helpers_1.validationField)(action.payload, ''),
                    periodo: rendimenti_types_1.PeriodoRendimenti.free,
                    formDirty: true,
                }
            };
        case __1.EReduxActionTypes.RENDIMENTI_DATA_A:
            return {
                ...state,
                form: {
                    ...state.form,
                    dataA: action.payload,
                    dataAError: (0, helpers_1.validationField)(action.payload, ''),
                    periodo: rendimenti_types_1.PeriodoRendimenti.free,
                    formDirty: true,
                }
            };
        case __1.EReduxActionTypes.RENDIMENTI_PERIODO:
            return {
                ...state,
                form: {
                    ...state.form,
                    periodo: action.payload,
                    dataDa: action.payload !== rendimenti_types_1.PeriodoRendimenti.free ? initialState.form.dataDa : state.form.dataDa,
                    dataA: action.payload !== rendimenti_types_1.PeriodoRendimenti.free ? initialState.form.dataA : state.form.dataA,
                    dataDaError: action.payload !== rendimenti_types_1.PeriodoRendimenti.free ? initialState.form.dataDaError : state.form.dataDaError,
                    dataAError: action.payload !== rendimenti_types_1.PeriodoRendimenti.free ? initialState.form.dataAError : state.form.dataAError,
                    formDirty: true,
                }
            };
        case __1.EReduxActionTypes.RENDIMENTI_INCLUDI_POSIZIONI_CHIUSE:
            return {
                ...state,
                form: {
                    ...state.form,
                    includeClosedPosition: action.payload,
                    formDirty: true,
                }
            };
        case __1.EReduxActionTypes.RENDIMENTI_GET_ACCOUNT_START:
            return {
                ...state,
                accountRendimenti: initialState.accountRendimenti
            };
        case __1.EReduxActionTypes.RENDIMENTI_GET_ACCOUNT_SUCCESS:
            return {
                ...state,
                accountRendimenti: action.payload,
                form: {
                    ...state.form,
                    formDirty: false,
                }
            };
        case __1.EReduxActionTypes.RENDIMENTI_SET_TUTTI_RAPPORTI:
            return {
                ...state,
                form: {
                    ...state.form,
                    tuttiRapporti: action.payload
                }
            };
        case __1.EReduxActionTypes.RENDIMENTI_SET_SELECTED_DEPOSITO:
            return {
                ...state,
                form: {
                    ...state.form,
                    selectedDeposito: action.payload
                }
            };
        case __1.EReduxActionTypes.RENDIMENTI_SET_ACCORDION_FILTRI_OPENED:
            return {
                ...state,
                form: {
                    ...state.form,
                    accordionFiltriOpened: action.payload
                }
            };
        case __1.EReduxActionTypes.RENDIMENTI_RESET:
            return {
                ...initialState
            };
        default:
            return state;
    }
};
exports.rendimentiReducer = rendimentiReducer;
