"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.watchlistReducer = void 0;
const watchlist_types_1 = require("./watchlist.types");
const api_rest_1 = require("@sparkasse/api-rest");
const helpers_1 = require("../helpers");
const initialState = {
    watchlist: [],
    elenco: (0, api_rest_1.WatchlistQuotazioniDtoFromJSON)({}),
    form: (0, watchlist_types_1.WatchlistFormFromJSONTyped)({})
};
const watchlistReducer = (state = initialState, action) => {
    switch (action.type) {
        case watchlist_types_1.EReduxWatchlistActionTypes.WATCHLIST_GET_ELENCO_WATCHLIST_SUCCESS:
            return {
                ...state,
                watchlist: action.payload
            };
        case watchlist_types_1.EReduxWatchlistActionTypes.WATCHLIST_RIMUOVI_WATCHLIST_SUCCESS:
            return {
                ...state,
                watchlist: action.payload
            };
        case watchlist_types_1.EReduxWatchlistActionTypes.WATCHLIST_CREA_WATCHLIST_SUCCESS:
            return {
                ...state,
                elenco: action.payload
            };
        case watchlist_types_1.EReduxWatchlistActionTypes.WATCHLIST_AGGIUNGI_ELEMENTO_WATCHLIST_SUCCESS:
            return {
                ...state,
                elenco: action.payload
            };
        case watchlist_types_1.EReduxWatchlistActionTypes.WATCHLIST_RIMUOVI_ELEMENTO_WATCHLIST_SUCCESS:
            return {
                ...state,
                elenco: action.payload
            };
        case watchlist_types_1.EReduxWatchlistActionTypes.WATCHLIST_SET_NOME_WATCHLIST:
            return {
                ...state,
                form: {
                    ...state.form,
                    nomeWatchlist: action.payload,
                    nomeWatchlistDirty: true,
                    nomeWatchlistError: (0, helpers_1.validationField)(action.payload, '')
                }
            };
        case watchlist_types_1.EReduxWatchlistActionTypes.WATCHLIST_SET_WATCHLIST_SELEZIONATA:
            return {
                ...state,
                form: {
                    ...state.form,
                    watchlistSelezionata: action.payload
                }
            };
        case watchlist_types_1.EReduxWatchlistActionTypes.WATCHLIST_GET_QUOTAZIONI_WATCHLIST_SUCCESS:
            return {
                ...state,
                elenco: action.payload
            };
        case watchlist_types_1.EReduxWatchlistActionTypes.WATCHLIST_RESET:
            return {
                ...state,
                elenco: initialState.elenco,
                watchlist: initialState.watchlist,
                form: {
                    ...state.form,
                    nomeWatchlist: initialState.form.nomeWatchlist,
                    nomeWatchlistDirty: initialState.form.nomeWatchlistDirty,
                    nomeWatchlistError: initialState.form.nomeWatchlistError,
                    watchlistSelezionata: initialState.form.watchlistSelezionata
                }
            };
        case watchlist_types_1.EReduxWatchlistActionTypes.WATCHLIST_SET_TIPO_PAGINA:
            return {
                ...state,
                form: {
                    ...state.form,
                    tipoPagina: action.payload
                }
            };
        default:
            return state;
    }
};
exports.watchlistReducer = watchlistReducer;
exports.default = exports.watchlistReducer;
