"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NexiControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class NexiControllerApi extends runtime.BaseAPI {
    /**
     * Ottenimento del token SSO nexi
     */
    callNexiServletRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/nexi/sso`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.NexiServletDtoFromJSON(jsonValue));
        });
    }
    /**
     * Ottenimento del token SSO nexi
     */
    callNexiServlet() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.callNexiServletRaw();
            return yield response.value();
        });
    }
    /**
     * Export in pdf del dettaglio del movimento Nexi
     */
    dettaglioMovimentoNexiPdfRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.exportMovimentoCartaRequestDto === null || requestParameters.exportMovimentoCartaRequestDto === undefined) {
                throw new runtime.RequiredError('exportMovimentoCartaRequestDto', 'Required parameter requestParameters.exportMovimentoCartaRequestDto was null or undefined when calling dettaglioMovimentoNexiPdf.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/nexi/export/pdf`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ExportMovimentoCartaRequestDtoToJSON(requestParameters.exportMovimentoCartaRequestDto),
            });
            return new runtime.BlobApiResponse(response);
        });
    }
    /**
     * Export in pdf del dettaglio del movimento Nexi
     */
    dettaglioMovimentoNexiPdf(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.dettaglioMovimentoNexiPdfRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Export excel dei movimenti in base ai criteri inviati per il rapporto indicato
     */
    elencoMovimentiNexiExcelRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.movimentiCartaRequestDto === null || requestParameters.movimentiCartaRequestDto === undefined) {
                throw new runtime.RequiredError('movimentiCartaRequestDto', 'Required parameter requestParameters.movimentiCartaRequestDto was null or undefined when calling elencoMovimentiNexiExcel.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/nexi/elenco/export`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.MovimentiCartaRequestDtoToJSON(requestParameters.movimentiCartaRequestDto),
            });
            return new runtime.BlobApiResponse(response);
        });
    }
    /**
     * Export excel dei movimenti in base ai criteri inviati per il rapporto indicato
     */
    elencoMovimentiNexiExcel(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.elencoMovimentiNexiExcelRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Recupera il PAN della carta
     */
    getEncryptedNexiPanViewRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.body === null || requestParameters.body === undefined) {
                throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling getEncryptedNexiPanView.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/nexi/pan`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters.body,
            });
            return new runtime.TextApiResponse(response);
        });
    }
    /**
     * Recupera il PAN della carta
     */
    getEncryptedNexiPanView(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getEncryptedNexiPanViewRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Recupera il PAN della carta
     */
    getPanViewNexiCreditRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.txId === null || requestParameters.txId === undefined) {
                throw new runtime.RequiredError('txId', 'Required parameter requestParameters.txId was null or undefined when calling getPanViewNexiCredit.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/nexi/getPanView/{txId}`.replace(`{${"txId"}}`, encodeURIComponent(String(requestParameters.txId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.PanViewResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera il PAN della carta
     */
    getPanViewNexiCredit(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getPanViewNexiCreditRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Recupera il PIN della carta
     */
    getPinViewNexiCreditRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.txId === null || requestParameters.txId === undefined) {
                throw new runtime.RequiredError('txId', 'Required parameter requestParameters.txId was null or undefined when calling getPinViewNexiCredit.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/nexi/getPinView/{txId}`.replace(`{${"txId"}}`, encodeURIComponent(String(requestParameters.txId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.PinViewResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera il PIN della carta
     */
    getPinViewNexiCredit(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getPinViewNexiCreditRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Lettura parametri Nexi
     */
    parametriNexiRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/nexi/parametri`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ParametriNexiDtoFromJSON(jsonValue));
        });
    }
    /**
     * Lettura parametri Nexi
     */
    parametriNexi() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.parametriNexiRaw();
            return yield response.value();
        });
    }
    /**
     * Recupero lista movimenti carte Nexi
     */
    recuperaMovimentiCartaRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.movimentiCartaRequestDto === null || requestParameters.movimentiCartaRequestDto === undefined) {
                throw new runtime.RequiredError('movimentiCartaRequestDto', 'Required parameter requestParameters.movimentiCartaRequestDto was null or undefined when calling recuperaMovimentiCarta.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/nexi/movimenti/elenco`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.MovimentiCartaRequestDtoToJSON(requestParameters.movimentiCartaRequestDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ListaMovimentiNexiDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupero lista movimenti carte Nexi
     */
    recuperaMovimentiCarta(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.recuperaMovimentiCartaRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Inizializza l\'operazione di SCA per la visualizzazione del PAN della carta
     */
    startPanViewSCANexiCreditRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.startPanViewSCARequestDto === null || requestParameters.startPanViewSCARequestDto === undefined) {
                throw new runtime.RequiredError('startPanViewSCARequestDto', 'Required parameter requestParameters.startPanViewSCARequestDto was null or undefined when calling startPanViewSCANexiCredit.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/nexi/startPanViewSCA`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.StartPanViewSCARequestDtoToJSON(requestParameters.startPanViewSCARequestDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.StartPanViewSCAResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Inizializza l\'operazione di SCA per la visualizzazione del PAN della carta
     */
    startPanViewSCANexiCredit(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.startPanViewSCANexiCreditRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Inizializza l\'operazione di SCA per la visualizzazione del PIN della carta
     */
    startPinViewSCANexiCreditRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.startPinViewSCARequestDto === null || requestParameters.startPinViewSCARequestDto === undefined) {
                throw new runtime.RequiredError('startPinViewSCARequestDto', 'Required parameter requestParameters.startPinViewSCARequestDto was null or undefined when calling startPinViewSCANexiCredit.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/nexi/startPinViewSCA`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.StartPinViewSCARequestDtoToJSON(requestParameters.startPinViewSCARequestDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.StartPinViewSCAResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Inizializza l\'operazione di SCA per la visualizzazione del PIN della carta
     */
    startPinViewSCANexiCredit(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.startPinViewSCANexiCreditRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Ottenimento del token SSO nexi
     */
    tokenSSONexiRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/nexi/token`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.CartaSiSSoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Ottenimento del token SSO nexi
     */
    tokenSSONexi() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.tokenSSONexiRaw();
            return yield response.value();
        });
    }
    /**
     * Varia il consenso a nexi
     */
    variaConsensoNexiRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.codiceConsenso === null || requestParameters.codiceConsenso === undefined) {
                throw new runtime.RequiredError('codiceConsenso', 'Required parameter requestParameters.codiceConsenso was null or undefined when calling variaConsensoNexi.');
            }
            if (requestParameters.variazioneNexiRequestDto === null || requestParameters.variazioneNexiRequestDto === undefined) {
                throw new runtime.RequiredError('variazioneNexiRequestDto', 'Required parameter requestParameters.variazioneNexiRequestDto was null or undefined when calling variaConsensoNexi.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/nexi/consenso/{codiceConsenso}`.replace(`{${"codiceConsenso"}}`, encodeURIComponent(String(requestParameters.codiceConsenso))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.VariazioneNexiRequestDtoToJSON(requestParameters.variazioneNexiRequestDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ConsensoNexiDtoFromJSON(jsonValue));
        });
    }
    /**
     * Varia il consenso a nexi
     */
    variaConsensoNexi(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.variaConsensoNexiRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Verifica il consenso a nexi
     */
    verificaConsensoNexiRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.codiceConsenso === null || requestParameters.codiceConsenso === undefined) {
                throw new runtime.RequiredError('codiceConsenso', 'Required parameter requestParameters.codiceConsenso was null or undefined when calling verificaConsensoNexi.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/nexi/consenso/{codiceConsenso}`.replace(`{${"codiceConsenso"}}`, encodeURIComponent(String(requestParameters.codiceConsenso))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ConsensoNexiDtoFromJSON(jsonValue));
        });
    }
    /**
     * Verifica il consenso a nexi
     */
    verificaConsensoNexi(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.verificaConsensoNexiRaw(requestParameters);
            return yield response.value();
        });
    }
}
exports.NexiControllerApi = NexiControllerApi;
