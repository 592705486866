"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetCambiDettaglio = exports.resetCambiForm = exports.resetCambi = exports.setCambiOffset = exports.setCambioSelezionato = exports.setCambiDenominazione = exports.setCambiMercato = exports.setCambiTipoGrafico = exports.getSchedaCambio = exports.getDatiStoriciCambio = exports.getDatiIntradayCambio = exports.getDettaglioCambio = exports.getElencoCambi = exports.getMercatiCambi = void 0;
const general_actions_1 = require("../general/general.actions");
const api_rest_1 = require("@sparkasse/api-rest");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
const cambi_types_1 = require("./cambi.types");
const moment = require("moment");
// Get mercati Cambi - Start
function getMercatiCambiStart() {
    return {
        type: cambi_types_1.EReduxCambiActionTypes.CAMBI_GET_MERCATI_START
    };
}
function getMercatiCambiSuccess(payload) {
    return {
        type: cambi_types_1.EReduxCambiActionTypes.CAMBI_GET_MERCATI_SUCCESS,
        payload
    };
}
function generalError(value) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value));
    };
}
const getMercatiCambi = () => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getMercatiCambiStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getMercatiCambi().then((response) => {
                dispatch(getMercatiCambiSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getMercatiCambi = getMercatiCambi;
// Get mercati Cambi - Fine
// Get elenco cambi - Start
function getElencoCambiStart() {
    return {
        type: cambi_types_1.EReduxCambiActionTypes.CAMBI_GET_TITOLI_START
    };
}
function getElencoCambiSuccess(payload, offset) {
    return {
        type: cambi_types_1.EReduxCambiActionTypes.CAMBI_GET_TITOLI_SUCCESS,
        payload,
        offset
    };
}
const getElencoCambi = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getElencoCambiStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        const cambiState = getState().cambi;
        const request = {
            quotazioniFiltriInDto: {
                mercato: cambiState.form.mercato,
                cerca: cambiState.form.denominazione,
            }
        };
        if (extraArguments.platform === 'mobile') {
            request.quotazioniFiltriInDto.offset = (cambiState.form.offset);
        }
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getQuotazioniCambi(request).then((response) => {
                dispatch(getElencoCambiSuccess(response, cambiState.form.offset));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getElencoCambi = getElencoCambi;
// Get elenco cambi - Fine
// Get dettaglio cambio - Start
function getDettaglioCambioStart() {
    return {
        type: cambi_types_1.EReduxCambiActionTypes.CAMBI_GET_DETTAGLIO_CAMBIO_START
    };
}
function getDettaglioCambioSuccess(payload) {
    return {
        type: cambi_types_1.EReduxCambiActionTypes.CAMBI_GET_DETTAGLIO_CAMBIO_SUCCESS,
        payload,
    };
}
const getDettaglioCambio = () => async (dispatch, getState, extraArguments) => {
    var _a;
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getDettaglioCambioStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        const cambiState = getState().cambi;
        const request = {
            mercato: cambiState.form.mercato,
            codiceCambio: (_a = cambiState.form.cambioSelezionato.codice) !== null && _a !== void 0 ? _a : ''
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getQuotazioneCambioDettaglio(request).then((response) => {
                dispatch(getDettaglioCambioSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getDettaglioCambio = getDettaglioCambio;
// Get dettaglio cambio - Fine
function aggiornaGraficoIndiceSuccess(payload, aggiornamento, tipo) {
    return {
        type: cambi_types_1.EReduxCambiActionTypes.CAMBI_AGGIORNA_GRAFICO,
        payload,
        aggiornamento,
        tipo
    };
}
// Get dati intraday cambio - Start
function getDatiIntradayCambioStart() {
    return {
        type: cambi_types_1.EReduxCambiActionTypes.CAMBI_GET_DATI_INTRADAY_CAMBIO_START
    };
}
function getDatiIntradayCambioSuccess(payload, aggiornamento) {
    return {
        type: cambi_types_1.EReduxCambiActionTypes.CAMBI_GET_DATI_INTRADAY_CAMBIO_SUCCESS,
        payload,
        aggiornamento
    };
}
const getDatiIntradayCambio = () => async (dispatch, getState, extraArguments) => {
    var _a;
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getDatiIntradayCambioStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        const cambiState = getState().cambi;
        const request = {
            datiIntradayCambioInDto: {
                mercato: cambiState.form.mercato,
                codiceCambio: (_a = cambiState.form.cambioSelezionato.codice) !== null && _a !== void 0 ? _a : ''
            }
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getDatiIntradayCambio(request).then((response) => {
                dispatch(getDatiIntradayCambioSuccess(response, moment().toDate()));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getDatiIntradayCambio = getDatiIntradayCambio;
// Get dati intraday cambio - Fine
// Get dati storici cambio - Start
function getDatiStoriciCambioStart() {
    return {
        type: cambi_types_1.EReduxCambiActionTypes.CAMBI_GET_DATI_STORICI_CAMBIO_START
    };
}
function getDatiStoriciCambioSuccess(payload, aggiornamento, tipo) {
    return {
        type: cambi_types_1.EReduxCambiActionTypes.CAMBI_GET_DATI_STORICI_CAMBIO_SUCCESS,
        payload,
        aggiornamento,
        tipo
    };
}
const getDatiStoriciCambio = (type, refresh) => async (dispatch, getState, extraArguments) => {
    var _a;
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getDatiStoriciCambioStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        const now = moment();
        const cambiState = getState().cambi;
        const request = {
            datiIntradayCambioInDto: {
                mercato: cambiState.form.mercato,
                codiceCambio: (_a = cambiState.form.cambioSelezionato.codice) !== null && _a !== void 0 ? _a : '',
                dataFinale: now.toDate(),
                dataIniziale: (type === cambi_types_1.TipoGraficoCambi.Settimana1 ? now.subtract(7, "days") :
                    type === cambi_types_1.TipoGraficoCambi.Mese1 ? now.subtract(1, "months") :
                        now.subtract(12, "months")).toDate()
            }
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getDatiStoriciCambi(request).then((response) => {
                if (refresh) {
                    dispatch(aggiornaGraficoIndiceSuccess(response, now.toDate(), type));
                }
                else {
                    dispatch(getDatiStoriciCambioSuccess(response, now.toDate(), type));
                }
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getDatiStoriciCambio = getDatiStoriciCambio;
// Get dati storici cambio - Fine
// Get scheda cambio - Start
function getSchedaCambioStart() {
    return {
        type: cambi_types_1.EReduxCambiActionTypes.CAMBI_GET_SCHEDA_CAMBIO_START
    };
}
function getSchedaCambioSuccess(payload) {
    return {
        type: cambi_types_1.EReduxCambiActionTypes.CAMBI_GET_SCHEDA_CAMBIO_SUCCESS,
        payload,
    };
}
const getSchedaCambio = () => async (dispatch, getState, extraArguments) => {
    var _a, _b;
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getSchedaCambioStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        const cambiState = getState().cambi;
        const request = {
            mercato: (_a = cambiState.form.cambioSelezionato.mercato) !== null && _a !== void 0 ? _a : '',
            codiceCambio: (_b = cambiState.form.cambioSelezionato.codice) !== null && _b !== void 0 ? _b : ''
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getSchedaCambio(request).then((response) => {
                dispatch(getSchedaCambioSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getSchedaCambio = getSchedaCambio;
// Get scheda cambio - Fine
function setCambiTipoGrafico(tipo) {
    return {
        type: cambi_types_1.EReduxCambiActionTypes.CAMBI_SET_TIPO_GRAFICO,
        payload: tipo
    };
}
exports.setCambiTipoGrafico = setCambiTipoGrafico;
/* set form values - START */
function setCambiMercato(txt) {
    return {
        type: cambi_types_1.EReduxCambiActionTypes.SET_CAMBI_MERCATO,
        payload: txt
    };
}
exports.setCambiMercato = setCambiMercato;
function setCambiDenominazione(txt) {
    return {
        type: cambi_types_1.EReduxCambiActionTypes.SET_CAMBI_DENOMINAZIONE,
        payload: txt
    };
}
exports.setCambiDenominazione = setCambiDenominazione;
function setCambioSelezionato(titolo) {
    return {
        type: cambi_types_1.EReduxCambiActionTypes.CAMBI_SET_CAMBIO_SELEZIONATO,
        payload: titolo
    };
}
exports.setCambioSelezionato = setCambioSelezionato;
function setCambiOffset(offset) {
    return {
        type: cambi_types_1.EReduxCambiActionTypes.CAMBI_SET_OFFSET,
        payload: offset
    };
}
exports.setCambiOffset = setCambiOffset;
/* set form values - Fine */
// Reset Form Titoli Quotati
function resetCambi() {
    return {
        type: cambi_types_1.EReduxCambiActionTypes.CAMBI_RESET
    };
}
exports.resetCambi = resetCambi;
function resetCambiForm() {
    return {
        type: cambi_types_1.EReduxCambiActionTypes.CAMBI_RESET_FORM
    };
}
exports.resetCambiForm = resetCambiForm;
function resetCambiDettaglio() {
    return {
        type: cambi_types_1.EReduxCambiActionTypes.CAMBI_RESET_DETTAGLIO
    };
}
exports.resetCambiDettaglio = resetCambiDettaglio;
// end Reset Form Titoli Quotati
