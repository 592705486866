"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.adeguataVerificaRapporti = exports.rapportoSelezionato = exports.setupAggiornaRequest = exports.formElencoRapporti = exports.rapportiUtente = exports.rapportiPredefiniti = void 0;
const reselect_1 = require("reselect");
const elencoRapportiReducer = (state) => state;
exports.rapportiPredefiniti = (0, reselect_1.createSelector)(elencoRapportiReducer, (state) => {
    return state.rapportiPredefiniti;
});
exports.rapportiUtente = (0, reselect_1.createSelector)(elencoRapportiReducer, (state) => {
    return state.rapportiUtente;
});
exports.formElencoRapporti = (0, reselect_1.createSelector)(elencoRapportiReducer, (state) => {
    return state.form;
});
exports.setupAggiornaRequest = (0, reselect_1.createSelector)(elencoRapportiReducer, (state) => {
    state.rapportiPredefiniti.forEach((rapporto, i) => {
        switch (rapporto.codiceCategoria) {
            case 'ESTRATTOCONTOITALIA':
                rapporto.codiceRapportoPredefinito = Number(state.form.rapportoContoCorrente);
                break;
            case 'ESTRATTOCONTOESTERO':
                rapporto.codiceRapportoPredefinito = Number(state.form.rapportoContoEstero);
                break;
            case 'ESTRATTOCONTODEPOSITO':
                rapporto.codiceRapportoPredefinito = Number(state.form.rapportoContoDeposito);
                break;
            case 'DEPOSITIRISPARMIO':
                rapporto.codiceRapportoPredefinito = Number(state.form.rapportoDepositiRisparmio);
                break;
            case 'BONIFICI':
                rapporto.codiceRapportoPredefinito = Number(state.form.rapportoBonifici);
                break;
            case 'PAGAMENTI':
                rapporto.codiceRapportoPredefinito = Number(state.form.rapportoPagamenti);
                break;
            case 'TRADINGINFO':
                rapporto.codiceRapportoPredefinito = Number(state.form.rapportoTradingInfo);
                break;
            case 'TRADING':
                rapporto.codiceRapportoPredefinito = Number(state.form.rapportoTrading);
                break;
            case 'FINANZIAMENTIINFO':
                rapporto.codiceRapportoPredefinito = Number(state.form.rapportoFinanziamentiInfo);
                break;
            case 'POLIZZEBANCAASS':
                rapporto.codiceRapportoPredefinito = Number(state.form.rapportoPolizzeBancaAss);
                break;
        }
    });
    return state;
});
exports.rapportoSelezionato = (0, reselect_1.createSelector)(elencoRapportiReducer, (state) => {
    return state.form.rapportoSelezionato;
});
exports.adeguataVerificaRapporti = (0, reselect_1.createSelector)(elencoRapportiReducer, (state) => {
    return state.adeguataVerificaRapporti;
});
