"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuotazioneDettaglioCambioDtoToJSON = exports.QuotazioneDettaglioCambioDtoFromJSONTyped = exports.QuotazioneDettaglioCambioDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function QuotazioneDettaglioCambioDtoFromJSON(json) {
    return QuotazioneDettaglioCambioDtoFromJSONTyped(json, false);
}
exports.QuotazioneDettaglioCambioDtoFromJSON = QuotazioneDettaglioCambioDtoFromJSON;
function QuotazioneDettaglioCambioDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'valoreMax52Sett': !runtime_1.exists(json, 'valoreMax52Sett') ? undefined : json['valoreMax52Sett'],
        'valoreMin52Sett': !runtime_1.exists(json, 'valoreMin52Sett') ? undefined : json['valoreMin52Sett'],
        'quotazione': !runtime_1.exists(json, 'quotazione') ? undefined : _1.QuotazioneCambioDtoFromJSON(json['quotazione']),
        'fixingVarPerc': !runtime_1.exists(json, 'fixingVarPerc') ? undefined : json['fixingVarPerc'],
        'fixingPrec': !runtime_1.exists(json, 'fixingPrec') ? undefined : json['fixingPrec'],
        'fixingDatePrec': !runtime_1.exists(json, 'fixingDatePrec') ? undefined : (new Date(json['fixingDatePrec'])),
    };
}
exports.QuotazioneDettaglioCambioDtoFromJSONTyped = QuotazioneDettaglioCambioDtoFromJSONTyped;
function QuotazioneDettaglioCambioDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'valoreMax52Sett': value.valoreMax52Sett,
        'valoreMin52Sett': value.valoreMin52Sett,
        'quotazione': _1.QuotazioneCambioDtoToJSON(value.quotazione),
        'fixingVarPerc': value.fixingVarPerc,
        'fixingPrec': value.fixingPrec,
        'fixingDatePrec': value.fixingDatePrec === undefined ? undefined : (value.fixingDatePrec.toISOString()),
    };
}
exports.QuotazioneDettaglioCambioDtoToJSON = QuotazioneDettaglioCambioDtoToJSON;
