"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.prestitoIdeaReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const initialState = {
    datiUtente: (0, api_rest_1.CofidisDtoFromJSON)({}),
    rapportoSelezionato: '',
    url: (0, api_rest_1.CofidisUrlDtoFromJSON)({}),
    parametri: (0, api_rest_1.ParametriFinanziamentiDtoFromJSON)({}),
    rapportoSelezionatoCarousel: (0, api_rest_1.FinanziamentoDtoFromJSON)({}),
    dettaglioPrestito: (0, api_rest_1.DettaglioInformativaCofidisDtoFromJSON)({})
};
const prestitoIdeaReducer = (state = initialState, action) => {
    switch (action.type) {
        case __1.EReduxActionTypes.PRESTITO_IDEA_GET_DATI_UTENTE_SUCCESS:
            return {
                ...state,
                datiUtente: action.payload
            };
        case __1.EReduxActionTypes.PRESTITO_IDEA_SET_RAPPORTO_DATI_UTENTE:
            return {
                ...state,
                rapportoSelezionato: action.payload
            };
        case __1.EReduxActionTypes.PRESTITO_IDEA_GET_URL_SUCCESS:
            return {
                ...state,
                url: action.payload
            };
        case __1.EReduxActionTypes.PRESTITO_IDEA_RESET:
            return {
                ...initialState
            };
        case __1.EReduxActionTypes.PRESTITO_IDEA_GET_PARAMETRI_SUCCESS:
            return {
                ...state,
                parametri: action.payload
            };
        case __1.EReduxActionTypes.PRESTITO_IDEA_SET_RAPPORTO_SELEZIONATO_CAROUSEL:
            return {
                ...state,
                rapportoSelezionatoCarousel: action.payload
            };
        case __1.EReduxActionTypes.PRESTITO_IDEA_GET_DETTAGLIO_INFORMATIVA_SUCCESS:
            return {
                ...state,
                dettaglioPrestito: action.payload
            };
        case __1.EReduxActionTypes.RESET_ALL: // action invocata dalla logout
            return {
                ...initialState
            };
        default:
            return state;
    }
};
exports.prestitoIdeaReducer = prestitoIdeaReducer;
exports.default = exports.prestitoIdeaReducer;
