"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiltriEstintiDtoToJSON = exports.FiltriEstintiDtoFromJSONTyped = exports.FiltriEstintiDtoFromJSON = void 0;
function FiltriEstintiDtoFromJSON(json) {
    return FiltriEstintiDtoFromJSONTyped(json, false);
}
exports.FiltriEstintiDtoFromJSON = FiltriEstintiDtoFromJSON;
function FiltriEstintiDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'tipologiaMovimento': json['tipologiaMovimento'],
        'dataDa': (new Date(json['dataDa'])),
        'dataA': (new Date(json['dataA'])),
    };
}
exports.FiltriEstintiDtoFromJSONTyped = FiltriEstintiDtoFromJSONTyped;
function FiltriEstintiDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'tipologiaMovimento': value.tipologiaMovimento,
        'dataDa': (value.dataDa.toISOString()),
        'dataA': (value.dataA.toISOString()),
    };
}
exports.FiltriEstintiDtoToJSON = FiltriEstintiDtoToJSON;
