"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSoglieBancomatPay = exports.setBancomatPaySetSelectedInvio = exports.setBancomatPaySetDefaultInvio = exports.setBancomatPaySetSelectedListaOperativipGestioneRapporti = exports.setBancomatPaySetStepGestioneRapporti = exports.autorizzaModificaIbanBancomatPay = exports.getInfoAutenticazioneModificaIbanBancomatPay = exports.controlloModificaIbanJiffyBancomatPay = exports.getInfoDispositivaMassimaliBancomatPay = exports.autorizzaBancomatPayMassimali = exports.setBancomatPaySetMassimaleMensileAction = exports.setBancomatPaySetMassimaleGiornalieroAction = exports.setBancomatPaySetMassimaleTransazioneAction = exports.modificaMassimaliBancomatPayMassimali = exports.setBancomatPaySetStepMassimali = exports.disattivaUtenteBancomatPay = exports.getServiziOperativiBancomatPay = exports.setBancomatPaySetStepDisattiva = exports.autorizzaDisattivaBancomatPay = exports.getInfoDispositivaDisattivaBancomatPay = exports.getInfoAutenticazioneDisattivaBancomatPay = exports.setBancomatPaySetStepAttivazione = exports.controlloGeneraCodiceAttivazioneBancomatPay = exports.autorizzaAttivazioneBancomatPay = exports.getInfoDispositivaAttivazioneBancomatPay = exports.getInfoAutenticazioneAttivazioneBancomatPay = exports.resetBancomatPay = exports.setBancomatPaySetStep = exports.setBancomatPaySetRapportoSelezionato = exports.setBancomatPaySetStepAction = exports.setBancomatPaySetAccettato = exports.autorizzaAdesioneBancomatPay = exports.getInfoDispositivaBancomatPay = exports.getInfoDispositivaAdesioneBancomatPay = exports.enrollmentBancomatPay = exports.getStatoUtenteBancomatPay = exports.resetBancomatPayErroreSpecifico = exports.getParametriBancomatPay = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
const bancomatPay_selectors_1 = require("./bancomatPay.selectors");
const bancomatPay_types_1 = require("./bancomatPay.types");
function getParametriBancomatPayStart() {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_GET_PARAMETRI_START
    };
}
function getParametriBancomatPaySuccess(payload) {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_GET_PARAMETRI_SUCCESS,
        payload
    };
}
function generalError(value, showGenericModalError) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value, showGenericModalError));
    };
}
const getParametriBancomatPay = () => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getParametriBancomatPayStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BancomatPayControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .parametriJiffy().then((response) => {
                dispatch(getParametriBancomatPaySuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getParametriBancomatPay = getParametriBancomatPay;
function getStatoUtenteBancomatPayStart() {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_GET_STATO_UTENTE_START
    };
}
function getStatoUtenteBancomatPaySuccess(payload) {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_GET_STATO_UTENTE_SUCCESS,
        payload
    };
}
function setBancomatPayErroreSpecifico(payload) {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_SET_ERRORE_SPECIFICO,
        payload
    };
}
function resetBancomatPayErroreSpecifico() {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_RESET_ERRORE_SPECIFICO
    };
}
exports.resetBancomatPayErroreSpecifico = resetBancomatPayErroreSpecifico;
function getStatoUtenteBancomatPayErroreSpecifico(value) {
    return async (dispatch) => {
        dispatch(setBancomatPayErroreSpecifico(value));
    };
}
const getStatoUtenteBancomatPay = () => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getStatoUtenteBancomatPayStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BancomatPayControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .verificaStatoUtente().then((response) => {
                dispatch(getStatoUtenteBancomatPaySuccess(response));
            }).catch(async (error) => {
                const formattedError = await (0, helpers_1.formatError)(error);
                if (formattedError.message === 'C_ERRORE_SPECIFICO_JIFFY_CE') {
                    dispatch(getStatoUtenteBancomatPayErroreSpecifico(formattedError));
                }
                else {
                    dispatch(generalError(formattedError));
                }
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getStatoUtenteBancomatPay = getStatoUtenteBancomatPay;
function enrollmentBancomatPayStart() {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_ENROLLMENT_START
    };
}
function enrollmentBancomatPaySuccess(payload) {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_ENROLLMENT_SUCCESS,
        payload
    };
}
const enrollmentBancomatPay = () => async (dispatch, getState, extraArguments) => {
    var _a, _b, _c, _d;
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(enrollmentBancomatPayStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        const request = {
            codiceRapporto: ((_c = (_b = (_a = (0, bancomatPay_selectors_1.formBancomatPay)(getState().bancomatPay)) === null || _a === void 0 ? void 0 : _a.rapportoSelezionato) === null || _b === void 0 ? void 0 : _b.rapporto) === null || _c === void 0 ? void 0 : _c.id) || 0,
            idSessioneChiamante: ((_d = (0, bancomatPay_selectors_1.statoUtenteBancomatPay)(getState().bancomatPay)) === null || _d === void 0 ? void 0 : _d.idSessioneChiamante) || ''
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BancomatPayControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .enrollmentJiffy(request).then((response) => {
                dispatch(enrollmentBancomatPaySuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.enrollmentBancomatPay = enrollmentBancomatPay;
function getInfoDispositivaBancomatPayStart() {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_GET_INFO_DISPOSIZIONE_START
    };
}
function getInfoDispositivaBancomatPaySuccess(payload) {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_GET_INFO_DISPOSIZIONE_SUCCESS,
        payload
    };
}
const getInfoDispositivaAdesioneBancomatPay = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, getState, extraArguments) => {
    const request = {
        codiceDisposizione: idDispositiva,
        chiamataUscente,
        eseguiInit,
        offline: !!offline
    };
    if (request.codiceDisposizione) {
        (0, exports.getInfoDispositivaBancomatPay)(request)(dispatch, getState, extraArguments);
    }
};
exports.getInfoDispositivaAdesioneBancomatPay = getInfoDispositivaAdesioneBancomatPay;
const getInfoDispositivaBancomatPay = (request) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getInfoDispositivaBancomatPayStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BancomatPayControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .authenticationInformationEnrollment(request).then((response) => {
                dispatch(getInfoDispositivaBancomatPaySuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getInfoDispositivaBancomatPay = getInfoDispositivaBancomatPay;
function autorizzaBancomatPayStart() {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_AUTORIZZA_START
    };
}
function autorizzaBancomatPaySuccess(payload) {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_AUTORIZZA_SUCCESS,
        payload
    };
}
const autorizzaAdesioneBancomatPay = (idDispositiva, credenziale) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(autorizzaBancomatPayStart());
    if (extraArguments.mock) {
    }
    else {
        const request = {
            codiceDisposizione: idDispositiva,
            credenzialeDispositivaDto: credenziale
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BancomatPayControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .authorizeEnrollment(request).then((response) => {
                dispatch(autorizzaBancomatPaySuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error), false));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.autorizzaAdesioneBancomatPay = autorizzaAdesioneBancomatPay;
/* set form values - START */
function setBancomatPaySetAccettato(value) {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_ACCETTATO,
        payload: value
    };
}
exports.setBancomatPaySetAccettato = setBancomatPaySetAccettato;
function setBancomatPaySetStepAction(step) {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_STEP,
        payload: step
    };
}
exports.setBancomatPaySetStepAction = setBancomatPaySetStepAction;
function setBancomatPaySetRapportoSelezionato(rapportoSelezionato) {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_SET_RAPPORTO_SELEZIONATO,
        payload: rapportoSelezionato
    };
}
exports.setBancomatPaySetRapportoSelezionato = setBancomatPaySetRapportoSelezionato;
const setBancomatPaySetStep = (i) => async (dispatch, _, extraArguments) => {
    dispatch(setBancomatPaySetStepAction(i));
};
exports.setBancomatPaySetStep = setBancomatPaySetStep;
function resetBancomatPay() {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_RESET
    };
}
exports.resetBancomatPay = resetBancomatPay;
/* Get info autenticazione attivazione - START */
function getInfoAutenticazioneAttivazioneBancomatPayStart() {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_ATTIVAZIONE_GET_INFO_AUTENTICAZIONE_START
    };
}
function getInfoAutenticazioneAttivazioneBancomatPaySuccess(payload) {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_ATTIVAZIONE_GET_INFO_AUTENTICAZIONE_SUCCESS,
        payload
    };
}
const getInfoAutenticazioneAttivazioneBancomatPay = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, getState, extraArguments) => {
    const request = {
        codiceDisposizione: idDispositiva,
        chiamataUscente,
        eseguiInit,
        offline: !!offline
    };
    if (request.codiceDisposizione) {
        (0, exports.getInfoDispositivaAttivazioneBancomatPay)(request)(dispatch, getState, extraArguments);
    }
};
exports.getInfoAutenticazioneAttivazioneBancomatPay = getInfoAutenticazioneAttivazioneBancomatPay;
const getInfoDispositivaAttivazioneBancomatPay = (request) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getInfoAutenticazioneAttivazioneBancomatPayStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BancomatPayControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .authenticationInformationAttivazione(request).then((response) => {
                dispatch(getInfoAutenticazioneAttivazioneBancomatPaySuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getInfoDispositivaAttivazioneBancomatPay = getInfoDispositivaAttivazioneBancomatPay;
/* Get info autenticazione attivazione - FINE */
/* Autorizza attivazione - START */
function autorizzaAttivazioneBancomatPayStart() {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_ATTIVAZIONE_AUTORIZZA_START
    };
}
function autorizzaAttivazioneBancomatPaySuccess(payload) {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_ATTIVAZIONE_AUTORIZZA_SUCCESS,
        payload
    };
}
const autorizzaAttivazioneBancomatPay = (idDispositiva, credenziale) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(autorizzaAttivazioneBancomatPayStart());
    if (extraArguments.mock) {
    }
    else {
        const request = {
            codiceDisposizione: idDispositiva,
            credenzialeDispositivaDto: credenziale
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BancomatPayControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .authorizeAttivazione(request).then((response) => {
                dispatch(autorizzaAttivazioneBancomatPaySuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error), false));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.autorizzaAttivazioneBancomatPay = autorizzaAttivazioneBancomatPay;
/* Autorizza attivazione - FINE */
/* Controllo genera codice attivazione - START */
function controlloGeneraCodiceAttivazioneBancomatPayStart() {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_ATTIVAZIONE_CONTROLLO_GENERA_CODICE_START
    };
}
function controlloGeneraCodiceAttivazioneBancomatPaySuccess(payload) {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_ATTIVAZIONE_CONTROLLO_GENERA_CODICE_SUCCESS,
        payload
    };
}
const controlloGeneraCodiceAttivazioneBancomatPay = (idSessioneChiamante) => async (dispatch, getState, extraArguments) => {
    var _a;
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(controlloGeneraCodiceAttivazioneBancomatPayStart());
    if (extraArguments.mock) {
    }
    else {
        const idSessione = idSessioneChiamante || ((_a = (0, bancomatPay_selectors_1.statoUtenteBancomatPay)(getState().bancomatPay)) === null || _a === void 0 ? void 0 : _a.idSessioneChiamante);
        const request = {
            idSessioneChiamante: idSessione || ''
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BancomatPayControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .controlloGeneraCodiceAttivazione(request).then((response) => {
                dispatch(controlloGeneraCodiceAttivazioneBancomatPaySuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.controlloGeneraCodiceAttivazioneBancomatPay = controlloGeneraCodiceAttivazioneBancomatPay;
/* Controllo genera codice attivazione - FINE */
/* Set attivazione form values - START*/
function setBancomatPaySetStepAttivazione(step) {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_ATTIVAZIONE_SET_STEP,
        payload: step
    };
}
exports.setBancomatPaySetStepAttivazione = setBancomatPaySetStepAttivazione;
/* Set attivazione form values - START*/
/* Get info autenticazione disattiva - START */
function getInfoAutenticazioneDisattivaBancomatPayStart() {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_DISATTIVA_GET_INFO_AUTENTICAZIONE_START
    };
}
function getInfoAutenticazioneDisattivaBancomatPaySuccess(payload) {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_DISATTIVA_GET_INFO_AUTENTICAZIONE_SUCCESS,
        payload
    };
}
const getInfoAutenticazioneDisattivaBancomatPay = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, getState, extraArguments) => {
    const request = {
        codiceDisposizione: idDispositiva,
        chiamataUscente,
        eseguiInit,
        offline: !!offline
    };
    if (request.codiceDisposizione) {
        (0, exports.getInfoDispositivaDisattivaBancomatPay)(request)(dispatch, getState, extraArguments);
    }
};
exports.getInfoAutenticazioneDisattivaBancomatPay = getInfoAutenticazioneDisattivaBancomatPay;
const getInfoDispositivaDisattivaBancomatPay = (request) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getInfoAutenticazioneDisattivaBancomatPayStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BancomatPayControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .authenticationInformationDisattiva(request).then((response) => {
                dispatch(getInfoAutenticazioneDisattivaBancomatPaySuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getInfoDispositivaDisattivaBancomatPay = getInfoDispositivaDisattivaBancomatPay;
/* Get info autenticazione disattiva - FINE */
/* Autorizza disattiva - START */
function autorizzaDisattivaBancomatPayStart() {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_DISATTIVA_AUTORIZZA_START
    };
}
function autorizzaDisattivaBancomatPaySuccess(payload) {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_DISATTIVA_AUTORIZZA_SUCCESS,
        payload
    };
}
const autorizzaDisattivaBancomatPay = (idDispositiva, credenziale) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(autorizzaDisattivaBancomatPayStart());
    if (extraArguments.mock) {
    }
    else {
        const request = {
            codiceDisposizione: idDispositiva,
            credenzialeDispositivaDto: credenziale
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BancomatPayControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .authorizeDisattiva(request).then((response) => {
                dispatch(autorizzaDisattivaBancomatPaySuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error), false));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.autorizzaDisattivaBancomatPay = autorizzaDisattivaBancomatPay;
/* Autorizza disattiva - FINE */
/* Set attivazione form values - START*/
function setBancomatPaySetStepDisattiva(step) {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_DISATTIVA_SET_STEP,
        payload: step
    };
}
exports.setBancomatPaySetStepDisattiva = setBancomatPaySetStepDisattiva;
/* Set attivazione form values - START*/
/* Get servizi operativi - START */
function getServiziOperativiBancomatPayStart() {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_GET_SERVIZI_OPERATIVI_START
    };
}
function getServiziOperativiBancomatPaySuccess(payload) {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_GET_SERVIZI_OPERATIVI_SUCCESS,
        payload
    };
}
const getServiziOperativiBancomatPay = (idSessioneChiamante) => async (dispatch, getState, extraArguments) => {
    var _a;
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getServiziOperativiBancomatPayStart());
    if (extraArguments.mock) {
    }
    else {
        const idSessione = idSessioneChiamante || ((_a = (0, bancomatPay_selectors_1.statoUtenteBancomatPay)(getState().bancomatPay)) === null || _a === void 0 ? void 0 : _a.idSessioneChiamante);
        const request = {
            idSessioneChiamante: idSessione || ''
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BancomatPayControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .listaServiziOperativi(request).then((response) => {
                dispatch(getServiziOperativiBancomatPaySuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getServiziOperativiBancomatPay = getServiziOperativiBancomatPay;
/* Get servizi operativi - FINE */
/* Disattiva utente - START */
function disattivaUtenteBancomatPayStart() {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_DISATTIVA_UTENTE_START
    };
}
function disattivaUtenteBancomatPaySuccess(payload) {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_DISATTIVA_UTENTE_SUCCESS,
        payload
    };
}
const disattivaUtenteBancomatPay = (idSessioneChiamante) => async (dispatch, getState, extraArguments) => {
    var _a;
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(disattivaUtenteBancomatPayStart());
    if (extraArguments.mock) {
    }
    else {
        const idSessione = idSessioneChiamante || ((_a = (0, bancomatPay_selectors_1.statoUtenteBancomatPay)(getState().bancomatPay)) === null || _a === void 0 ? void 0 : _a.idSessioneChiamante);
        const request = {
            idSessioneChiamante: idSessione || ''
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BancomatPayControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .disattivaUtente(request).then((response) => {
                dispatch(disattivaUtenteBancomatPaySuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.disattivaUtenteBancomatPay = disattivaUtenteBancomatPay;
/* Disattiva utente  - FINE */
/* set step massimali -START*/
function setBancomatPaySetStepMassimali(stepMassimali) {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_STEP_MASSIMALI,
        payload: stepMassimali
    };
}
exports.setBancomatPaySetStepMassimali = setBancomatPaySetStepMassimali;
/* set step massimali -FINE */
/* Modifica massimali START*/
function setBancomatPayMassimaliModificaStartAction() {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_MASSIMALI_MODIFICA_START
    };
}
function setBancomatPayMassimaliModificaSuccessAction(payload) {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_MASSIMALI_MODIFICA_SUCCESS,
        payload
    };
}
const modificaMassimaliBancomatPayMassimali = (modificaMassimali) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(setBancomatPayMassimaliModificaStartAction());
    if (extraArguments.mock) {
    }
    else {
        const requestIn = modificaMassimali || (0, bancomatPay_selectors_1.formMassimaliBancomatPayAsFilter)(getState().bancomatPay);
        const request = {
            modificaMassimaliInputDto: requestIn
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BancomatPayControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .modificaMassimali(request).then((response) => {
                dispatch(setBancomatPayMassimaliModificaSuccessAction(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.modificaMassimaliBancomatPayMassimali = modificaMassimaliBancomatPayMassimali;
function setBancomatPaySetMassimaleTransazioneAction(payload) {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_SET_MASSIMALE_TRANSAZIONE,
        payload
    };
}
exports.setBancomatPaySetMassimaleTransazioneAction = setBancomatPaySetMassimaleTransazioneAction;
function setBancomatPaySetMassimaleGiornalieroAction(payload) {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_SET_MASSIMALE_GIORNALIERO,
        payload
    };
}
exports.setBancomatPaySetMassimaleGiornalieroAction = setBancomatPaySetMassimaleGiornalieroAction;
function setBancomatPaySetMassimaleMensileAction(payload) {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_SET_MASSIMALE_MENSILE,
        payload
    };
}
exports.setBancomatPaySetMassimaleMensileAction = setBancomatPaySetMassimaleMensileAction;
/* Modifica massimali FINE*/
/* autoriza massimali -START */
function autorizzaBancomatPayMassimaliStart() {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_MASSIMALI_AUTORIZZA_START
    };
}
function autorizzaBancomatPayMassimaliSuccess(payload) {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_MASSIMALI_AUTORIZZA_SUCCESS,
        payload
    };
}
const autorizzaBancomatPayMassimali = (codice, credenzialeDispositiva) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(autorizzaBancomatPayMassimaliStart());
    if (extraArguments.mock) {
    }
    else {
        const getautorizzaBancomatPayMassimaliRequest = {
            codiceDisposizione: codice,
            credenzialeDispositivaDto: credenzialeDispositiva
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BancomatPayControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).authorizeMassimali(getautorizzaBancomatPayMassimaliRequest).then((response) => {
                dispatch(autorizzaBancomatPayMassimaliSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.autorizzaBancomatPayMassimali = autorizzaBancomatPayMassimali;
/*autoriza massimali -FINE */
/*get info dispositiva massimali -START */
function getInfoDispositivaMassimaliStart() {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_MASSIMALI_GET_INFO_DISPOSITIVA_START
    };
}
function getInfoDispositivaMassimaliSuccess(payload) {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_MASSIMALI_GET_INFO_DISPOSITIVA_SUCCESS,
        payload
    };
}
const getInfoDispositivaMassimaliBancomatPay = (codice, eseguiInit, chiamataUscente, offline) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getInfoDispositivaMassimaliStart());
    if (extraArguments.mock) {
    }
    else {
        const getInfoDispositivaBancomatPayMassimaliRequest = {
            codiceDisposizione: codice,
            chiamataUscente,
            eseguiInit,
            offline: !!offline
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BancomatPayControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).authenticationInformationMassimali(getInfoDispositivaBancomatPayMassimaliRequest).then((response) => {
                dispatch(getInfoDispositivaMassimaliSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getInfoDispositivaMassimaliBancomatPay = getInfoDispositivaMassimaliBancomatPay;
/*get info dispositiva massimali-FINE */
/* Controllo modifica Iban Jiffy - START */
function controlloModificaIbanJiffyBancomatPayStart() {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_MODIFICA_IBAN_CONTROLLO_START
    };
}
function controlloModificaIbanJiffyBancomatPaySuccess(payload) {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_MODIFICA_IBAN_CONTROLLO_SUCCESS,
        payload
    };
}
const controlloModificaIbanJiffyBancomatPay = (modificaIban) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(controlloModificaIbanJiffyBancomatPayStart());
    if (extraArguments.mock) {
    }
    else {
        const modificaIbanInputDto = modificaIban || (0, bancomatPay_selectors_1.formGestioneRapportiBancomatPayAsFilter)(getState().bancomatPay);
        const request = {
            modificaIbanInputDto: modificaIbanInputDto
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BancomatPayControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .controlloModificaIbanJiffy(request).then((response) => {
                dispatch(controlloModificaIbanJiffyBancomatPaySuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.controlloModificaIbanJiffyBancomatPay = controlloModificaIbanJiffyBancomatPay;
/* Controllo modifica Iban Jiffy - FINE */
/*get info dispositiva modifica Iban (rapporti) -START */
function getInfoAutenticazioneModificaIbanBancomatPayStart() {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_MODIFICA_IBAN_GET_INFO_AUTENTICAZIONE_START
    };
}
function getInfoAutenticazioneModificaIbanBancomatPaySuccess(payload) {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_MODIFICA_IBAN_GET_INFO_AUTENTICAZIONE_SUCCESS,
        payload
    };
}
const getInfoAutenticazioneModificaIbanBancomatPay = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getInfoAutenticazioneModificaIbanBancomatPayStart());
    if (extraArguments.mock) {
    }
    else {
        const request = {
            codiceDisposizione: idDispositiva,
            chiamataUscente,
            eseguiInit,
            offline: !!offline
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BancomatPayControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).authenticationInformationModificaIban(request).then((response) => {
                dispatch(getInfoAutenticazioneModificaIbanBancomatPaySuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getInfoAutenticazioneModificaIbanBancomatPay = getInfoAutenticazioneModificaIbanBancomatPay;
/*get info dispositiva modifica Iban (rapporti) -FINE */
/* Autorizza Modifica Iban (Rapporti) - START */
function autorizzaModificaIbanBancomatPayStart() {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_MODIFICA_IBAN_AUTORIZZA_START
    };
}
function autorizzaModificaIbanBancomatPaySuccess(payload) {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_MODIFICA_IBAN_AUTORIZZA_SUCCESS,
        payload
    };
}
const autorizzaModificaIbanBancomatPay = (idDispositiva, credenziale) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(autorizzaModificaIbanBancomatPayStart());
    if (extraArguments.mock) {
    }
    else {
        const request = {
            codiceDisposizione: idDispositiva,
            credenzialeDispositivaDto: credenziale
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BancomatPayControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .authorizeModificaIban(request).then((response) => {
                dispatch(autorizzaModificaIbanBancomatPaySuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.autorizzaModificaIbanBancomatPay = autorizzaModificaIbanBancomatPay;
/* Autorizza Modifica Iban (Rapporti) - FINE */
/* Set gestione rapporti form values - START*/
function setBancomatPaySetStepGestioneRapporti(step) {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_GESTIONE_RAPPORTI_SET_STEP,
        payload: step
    };
}
exports.setBancomatPaySetStepGestioneRapporti = setBancomatPaySetStepGestioneRapporti;
function setBancomatPaySetSelectedListaOperativipGestioneRapporti(servizio) {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_GESTIONE_RAPPORTI_SET_SELECTED_LISTA_SERVIZI_OPERATIVI,
        payload: servizio
    };
}
exports.setBancomatPaySetSelectedListaOperativipGestioneRapporti = setBancomatPaySetSelectedListaOperativipGestioneRapporti;
function setBancomatPaySetDefaultInvio(servizio) {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_GESTIONE_RAPPORTI_SET_DEFAULT_INVIO,
        payload: servizio
    };
}
exports.setBancomatPaySetDefaultInvio = setBancomatPaySetDefaultInvio;
function setBancomatPaySetSelectedInvio(servizio) {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_GESTIONE_RAPPORTI_SET_SELECTED_INVIO,
        payload: servizio
    };
}
exports.setBancomatPaySetSelectedInvio = setBancomatPaySetSelectedInvio;
/* Set gestione rapporti form values - FINE*/
/* Recupera soglie - START */
function getSoglieBancomatPayStart() {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_GET_SOGLIE_START
    };
}
function getSoglieBancomatPaySuccess(payload) {
    return {
        type: bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_GET_SOGLIE_SUCCESS,
        payload
    };
}
const getSoglieBancomatPay = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getSoglieBancomatPayStart());
    if (extraArguments.mock) {
    }
    else {
        const buildRequest = (0, bancomatPay_selectors_1.buildForSoglie)(getState().bancomatPay);
        const request = {
            soglieInDto: buildRequest
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BancomatPayControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .recuperaSoglie(request).then((response) => {
                dispatch(getSoglieBancomatPaySuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getSoglieBancomatPay = getSoglieBancomatPay;
/* Recupera soglie - FINE */
