"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiltroAssegniItaliaDtoToJSON = exports.FiltroAssegniItaliaDtoFromJSONTyped = exports.FiltroAssegniItaliaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function FiltroAssegniItaliaDtoFromJSON(json) {
    return FiltroAssegniItaliaDtoFromJSONTyped(json, false);
}
exports.FiltroAssegniItaliaDtoFromJSON = FiltroAssegniItaliaDtoFromJSON;
function FiltroAssegniItaliaDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codiceRapporto': json['codiceRapporto'],
        'soloAManoCliente': !runtime_1.exists(json, 'soloAManoCliente') ? undefined : json['soloAManoCliente'],
        'primoAssegno': !runtime_1.exists(json, 'primoAssegno') ? undefined : json['primoAssegno'],
        'ultimoAssegno': !runtime_1.exists(json, 'ultimoAssegno') ? undefined : json['ultimoAssegno'],
        'offset': !runtime_1.exists(json, 'offset') ? undefined : json['offset'],
    };
}
exports.FiltroAssegniItaliaDtoFromJSONTyped = FiltroAssegniItaliaDtoFromJSONTyped;
function FiltroAssegniItaliaDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codiceRapporto': value.codiceRapporto,
        'soloAManoCliente': value.soloAManoCliente,
        'primoAssegno': value.primoAssegno,
        'ultimoAssegno': value.ultimoAssegno,
        'offset': value.offset,
    };
}
exports.FiltroAssegniItaliaDtoToJSON = FiltroAssegniItaliaDtoToJSON;
