"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.WatchlistQuotazioneDtoTipoStrumentoEnum = exports.WatchlistQuotazioneDtoToJSON = exports.WatchlistQuotazioneDtoFromJSONTyped = exports.WatchlistQuotazioneDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function WatchlistQuotazioneDtoFromJSON(json) {
    return WatchlistQuotazioneDtoFromJSONTyped(json, false);
}
exports.WatchlistQuotazioneDtoFromJSON = WatchlistQuotazioneDtoFromJSON;
function WatchlistQuotazioneDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codice': !runtime_1.exists(json, 'codice') ? undefined : json['codice'],
        'codiceMercato': !runtime_1.exists(json, 'codiceMercato') ? undefined : json['codiceMercato'],
        'tipoStrumento': !runtime_1.exists(json, 'tipoStrumento') ? undefined : json['tipoStrumento'],
        'desc': !runtime_1.exists(json, 'desc') ? undefined : json['desc'],
        'trend': !runtime_1.exists(json, 'trend') ? undefined : json['trend'],
        'varPerc': !runtime_1.exists(json, 'varPerc') ? undefined : json['varPerc'],
        'valoreMax': !runtime_1.exists(json, 'valoreMax') ? undefined : json['valoreMax'],
        'valoreMin': !runtime_1.exists(json, 'valoreMin') ? undefined : json['valoreMin'],
        'apertura': !runtime_1.exists(json, 'apertura') ? undefined : json['apertura'],
        'chiusura': !runtime_1.exists(json, 'chiusura') ? undefined : json['chiusura'],
        'ultimoPrezzo': !runtime_1.exists(json, 'ultimoPrezzo') ? undefined : json['ultimoPrezzo'],
        'oraUltimoPrezzo': !runtime_1.exists(json, 'oraUltimoPrezzo') ? undefined : (new Date(json['oraUltimoPrezzo'])),
        'codiceIsin': !runtime_1.exists(json, 'codiceIsin') ? undefined : json['codiceIsin'],
        'miglioreDenaro': !runtime_1.exists(json, 'miglioreDenaro') ? undefined : json['miglioreDenaro'],
        'miglioreLettera': !runtime_1.exists(json, 'miglioreLettera') ? undefined : json['miglioreLettera'],
        'prezzoRiferim': !runtime_1.exists(json, 'prezzoRiferim') ? undefined : json['prezzoRiferim'],
        'fixing': !runtime_1.exists(json, 'fixing') ? undefined : json['fixing'],
        'fixingDate': !runtime_1.exists(json, 'fixingDate') ? undefined : (new Date(json['fixingDate'])),
    };
}
exports.WatchlistQuotazioneDtoFromJSONTyped = WatchlistQuotazioneDtoFromJSONTyped;
function WatchlistQuotazioneDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codice': value.codice,
        'codiceMercato': value.codiceMercato,
        'tipoStrumento': value.tipoStrumento,
        'desc': value.desc,
        'trend': value.trend,
        'varPerc': value.varPerc,
        'valoreMax': value.valoreMax,
        'valoreMin': value.valoreMin,
        'apertura': value.apertura,
        'chiusura': value.chiusura,
        'ultimoPrezzo': value.ultimoPrezzo,
        'oraUltimoPrezzo': value.oraUltimoPrezzo === undefined ? undefined : (value.oraUltimoPrezzo.toISOString()),
        'codiceIsin': value.codiceIsin,
        'miglioreDenaro': value.miglioreDenaro,
        'miglioreLettera': value.miglioreLettera,
        'prezzoRiferim': value.prezzoRiferim,
        'fixing': value.fixing,
        'fixingDate': value.fixingDate === undefined ? undefined : (value.fixingDate.toISOString()),
    };
}
exports.WatchlistQuotazioneDtoToJSON = WatchlistQuotazioneDtoToJSON;
/**
* @export
* @enum {string}
*/
var WatchlistQuotazioneDtoTipoStrumentoEnum;
(function (WatchlistQuotazioneDtoTipoStrumentoEnum) {
    WatchlistQuotazioneDtoTipoStrumentoEnum["CAMBIO"] = "CAMBIO";
    WatchlistQuotazioneDtoTipoStrumentoEnum["INDICE"] = "INDICE";
    WatchlistQuotazioneDtoTipoStrumentoEnum["TITOLO"] = "TITOLO";
})(WatchlistQuotazioneDtoTipoStrumentoEnum = exports.WatchlistQuotazioneDtoTipoStrumentoEnum || (exports.WatchlistQuotazioneDtoTipoStrumentoEnum = {}));
