"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getElencoDiviseBonificoEstero = exports.getCambioDivisa = exports.resetFiltriRicercaBIC = exports.resetBonificoEstero = exports.resetEsitoBonificoEstero = exports.resetBonificoEsteroInfoDispositiva = exports.resetRiepilogoBonificoEstero = exports.getBonificoEsteroFromBozza = exports.aggiornaBozzaBonificoEstero = exports.cercaBICBonificoEstero = exports.salvaBozzaBonificoEstero = exports.eliminaBozzaBonificoEstero = exports.getBozzeBonificoEstero = exports.getBozzaBonificoEstero = exports.getPaesiBIC = exports.autorizzaBonificoEstero = exports.getInfoDispositivaBonificoEstero = exports.controlloBonificoEstero = exports.getParametriBonificoEstero = exports.setBonificoEsteroOffset = exports.setBonificoEsteroIdContattoBeneficiario = exports.setBonificoEsteroSetNomePaese = exports.setBonificoEsteroSetRicercaPaeseBIC = exports.setBonificoEsteroRapportoSelezionato = exports.setFormSubmittedBonificoEstero = exports.setBonificoEsteroSetStep = exports.setBonificoEsteroSetAccordionDatiAnagraficiAggiuntiviOpened = exports.setBonificoEsteroSetAccordionDatiAggiuntiviOpened = exports.setBonificoEsteroSetOrdinanteEffettivo = exports.setBonificoEsteroSetCausale = exports.setBonificoEsteroSetModalitaAddebitoSpese = exports.setBonificoEsteroSetDataEsecuzione = exports.setBonificoEsteroSetValuta = exports.setBonificoEsteroSetImporto = exports.setBonificoEsteroSetProvincia = exports.setBonificoEsteroSetCAP = exports.setBonificoEsteroSetIndirizzo = exports.setBonificoEsteroSetAlias = exports.setBonificoEsteroSetBicSwift = exports.setBonificoEsteroSetCitta = exports.setBonificoEsteroSetPaeseBeneficiario = exports.setBonificoEsteroSetStato = exports.setBonificoEsteroSetIBAN = exports.setBonificoEsteroSetBeneficiario = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_configuration_1 = require("../general/general.configuration");
const bonificoEstero_selectors_1 = require("./bonificoEstero.selectors");
const bonificoEstero_types_1 = require("./bonificoEstero.types");
function generalError(value, showGenericModalError, sendErrorToMatomo) {
    return async (dispatch) => {
        dispatch((0, __1.setError)(value, showGenericModalError));
        !!sendErrorToMatomo && sendErrorToMatomo('BONIFICO_ESTERO', `code_error BONIFICO_ESTERO ${value.status} ${value === null || value === void 0 ? void 0 : value.message} / ${value.timestamp}`, 'ESITO_DISPOSITIVA');
    };
}
function setBonificoEsteroSetBeneficiario(txt) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_BENEFICIARIO,
        payload: txt
    };
}
exports.setBonificoEsteroSetBeneficiario = setBonificoEsteroSetBeneficiario;
function setBonificoEsteroSetIBAN(txt) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_IBAN,
        payload: txt
    };
}
exports.setBonificoEsteroSetIBAN = setBonificoEsteroSetIBAN;
function setBonificoEsteroSetStato(txt) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_STATO,
        payload: txt
    };
}
exports.setBonificoEsteroSetStato = setBonificoEsteroSetStato;
function setBonificoEsteroSetPaeseBeneficiario(txt) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_CITTA,
        payload: txt
    };
}
exports.setBonificoEsteroSetPaeseBeneficiario = setBonificoEsteroSetPaeseBeneficiario;
function setBonificoEsteroSetCitta(txt) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_CITTA,
        payload: txt
    };
}
exports.setBonificoEsteroSetCitta = setBonificoEsteroSetCitta;
function setBonificoEsteroSetBicSwift(txt) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_BIC_SWIFT,
        payload: txt
    };
}
exports.setBonificoEsteroSetBicSwift = setBonificoEsteroSetBicSwift;
function setBonificoEsteroSetAlias(txt) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_ALIAS,
        payload: txt
    };
}
exports.setBonificoEsteroSetAlias = setBonificoEsteroSetAlias;
function setBonificoEsteroSetIndirizzo(txt) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_INDIRIZZO,
        payload: txt
    };
}
exports.setBonificoEsteroSetIndirizzo = setBonificoEsteroSetIndirizzo;
function setBonificoEsteroSetCAP(txt) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_CAP,
        payload: txt
    };
}
exports.setBonificoEsteroSetCAP = setBonificoEsteroSetCAP;
function setBonificoEsteroSetProvincia(txt) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_PROVINCIA,
        payload: txt
    };
}
exports.setBonificoEsteroSetProvincia = setBonificoEsteroSetProvincia;
function setBonificoEsteroSetImporto(txt) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_IMPORTO,
        payload: txt
    };
}
exports.setBonificoEsteroSetImporto = setBonificoEsteroSetImporto;
function setBonificoEsteroSetValuta(txt) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_VALUTA,
        payload: txt
    };
}
exports.setBonificoEsteroSetValuta = setBonificoEsteroSetValuta;
function setBonificoEsteroSetDataEsecuzione(txt) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_DATA_ESECUZIONE,
        payload: txt
    };
}
exports.setBonificoEsteroSetDataEsecuzione = setBonificoEsteroSetDataEsecuzione;
function setBonificoEsteroSetModalitaAddebitoSpese(txt) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_MODALITA_ADDEBITO_SPESE,
        payload: txt
    };
}
exports.setBonificoEsteroSetModalitaAddebitoSpese = setBonificoEsteroSetModalitaAddebitoSpese;
function setBonificoEsteroSetCausale(txt) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_CAUSALE,
        payload: txt
    };
}
exports.setBonificoEsteroSetCausale = setBonificoEsteroSetCausale;
function setBonificoEsteroSetOrdinanteEffettivo(txt) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_ORDINANTE_EFFETTIVO,
        payload: txt
    };
}
exports.setBonificoEsteroSetOrdinanteEffettivo = setBonificoEsteroSetOrdinanteEffettivo;
function setBonificoEsteroSetAccordionDatiAggiuntiviOpened(bool) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_ACCORDION_DATI_AGGIUNTIVI_OPENED,
        payload: bool
    };
}
exports.setBonificoEsteroSetAccordionDatiAggiuntiviOpened = setBonificoEsteroSetAccordionDatiAggiuntiviOpened;
function setBonificoEsteroSetAccordionDatiAnagraficiAggiuntiviOpened(bool) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_ACCORDION_DATI_ANAGRAFICI_AGGIUNTIVI_OPENED,
        payload: bool
    };
}
exports.setBonificoEsteroSetAccordionDatiAnagraficiAggiuntiviOpened = setBonificoEsteroSetAccordionDatiAnagraficiAggiuntiviOpened;
function setBonificoEsteroSetStepAction(i) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_STEP,
        payload: i
    };
}
const setBonificoEsteroSetStep = (i) => async (dispatch, _, extraArguments) => {
    dispatch(setBonificoEsteroSetStepAction(i));
    if (extraArguments.platform == 'mobile') {
        dispatch((0, __1.setBottomTabBarVisible)(i != 2));
    }
};
exports.setBonificoEsteroSetStep = setBonificoEsteroSetStep;
function setFormSubmittedBonificoEstero(bSelect) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_FORM_SUBMITTED,
        payload: bSelect
    };
}
exports.setFormSubmittedBonificoEstero = setFormSubmittedBonificoEstero;
function setBonificoEsteroRapportoSelezionato(rapporto) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_RAPPORTO_SELEZIONATO,
        payload: rapporto
    };
}
exports.setBonificoEsteroRapportoSelezionato = setBonificoEsteroRapportoSelezionato;
function setBonificoEsteroSetRicercaPaeseBIC(txt) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_PAESE_BIC_RICERCA,
        payload: txt
    };
}
exports.setBonificoEsteroSetRicercaPaeseBIC = setBonificoEsteroSetRicercaPaeseBIC;
function setBonificoEsteroSetNomePaese(txt) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_NOME_BANCA,
        payload: txt
    };
}
exports.setBonificoEsteroSetNomePaese = setBonificoEsteroSetNomePaese;
function setBonificoEsteroIdContattoBeneficiario(id) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_ID_CONTATTO_BENEFICIARIO,
        payload: id
    };
}
exports.setBonificoEsteroIdContattoBeneficiario = setBonificoEsteroIdContattoBeneficiario;
function setBonificoEsteroListLoader(show) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_LIST_LOADER,
        payload: show
    };
}
function setBonificoEsteroOffset(payload) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SET_OFFSET,
        payload
    };
}
exports.setBonificoEsteroOffset = setBonificoEsteroOffset;
function getParametriBonificoEsteroStart() {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_GET_PARAMETRI_START
    };
}
function getParametriBonificoEsteroSuccess(payload) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_GET_PARAMETRI_SUCCESS,
        payload
    };
}
function getParametriPerRipetiBonificoEsteroSuccess(payload) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_GET_PARAMETRI_PER_RIPETI_SUCCESS,
        payload
    };
}
const getParametriBonificoEstero = (ripeti = false) => async (dispatch, _, extraArguments) => {
    dispatch((0, __1.setLoader)(true));
    dispatch(getParametriBonificoEsteroStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/bonifico_estero_parametri.json')).then(parametri => {
            dispatch(getParametriBonificoEsteroSuccess((0, api_rest_1.ParametriBonificoEsteroDtoFromJSON)(parametri)));
            dispatch((0, __1.setLoader)(false));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BonificoEsteroControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametriBonificoEstero().then((response) => {
                dispatch(ripeti ? getParametriPerRipetiBonificoEsteroSuccess(response) : getParametriBonificoEsteroSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, __1.formatError)(error)));
            }).finally(() => {
                dispatch((0, __1.setLoader)(false));
            });
        });
    }
    // }
};
exports.getParametriBonificoEstero = getParametriBonificoEstero;
/* controllo bonifico estero - START */
function controlloBonificoEsteroStart() {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_CONTROLLO_START
    };
}
function controlloBonificoEsteroSuccess(payload) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_CONTROLLO_SUCCESS,
        payload
    };
}
const controlloBonificoEstero = () => async (dispatch, getState, extraArguments) => {
    const bonificoEstero = getState().bonificoEstero;
    dispatch(setFormSubmittedBonificoEstero(true));
    if ((0, bonificoEstero_selectors_1.formValidBonificoEstero)(bonificoEstero)) {
        dispatch((0, __1.setLoader)(true));
        dispatch(controlloBonificoEsteroStart());
        if (extraArguments.mock) {
            Promise.resolve().then(() => require('../../mock/controllo_bonifico.json')).then(parametri => {
                // dispatch(controlloBonificoEsteroSuccess(BonificoSepaDtoFromJSON(parametri)));
                dispatch((0, __1.setLoader)(false));
            });
        }
        else {
            const bonificoEstero = getState().bonificoEstero;
            const controlloBonificoEsteroRequest = {
                bonificoEsteroDto: (0, __1.buildBonificoEstero)(bonificoEstero)
            };
            console.log('REQUEST: ', controlloBonificoEsteroRequest);
            extraArguments.getItem('jwt').then((jwt) => {
                new api_rest_1.BonificoEsteroControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).controlloBonificoEstero(controlloBonificoEsteroRequest).then((response) => {
                    dispatch(controlloBonificoEsteroSuccess(response));
                }).catch(async (error) => {
                    dispatch(generalError(await (0, __1.formatError)(error)));
                }).finally(() => {
                    dispatch((0, __1.setLoader)(false));
                });
            });
        }
    }
};
exports.controlloBonificoEstero = controlloBonificoEstero;
/* controllo bonifico estero - FINE */
/* get info dispositiva bonifico estero - START */
function getInfoDispositivaBonificoEsteroStart() {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_GET_INFO_DISPOSITIVA_START
    };
}
function getInfoDispositivaBonificoEsteroSuccess(payload) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_GET_INFO_DISPOSITIVA_SUCCESS,
        payload
    };
}
const getInfoDispositivaBonificoEstero = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, _, extraArguments) => {
    dispatch((0, __1.setLoader)(true));
    dispatch(getInfoDispositivaBonificoEsteroStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/autenticazione-bonifico.json')).then(parametri => {
            // dispatch(getInfoDispositivaBonificoEsteroSuccess(InformazioniAutenticazioneDispositivaDtoFromJSON(parametri)));
            dispatch((0, __1.setLoader)(false));
        });
    }
    else {
        const getInfoDispositivaBonificoEsteroRequest = {
            idDispositiva,
            chiamataUscente,
            eseguiInit,
            offline: !!offline
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BonificoEsteroControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).informazioniAutenticazioneBonificoEstero(getInfoDispositivaBonificoEsteroRequest).then((response) => {
                dispatch(getInfoDispositivaBonificoEsteroSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, __1.formatError)(error)));
            }).finally(() => {
                dispatch((0, __1.setLoader)(false));
            });
        });
    }
};
exports.getInfoDispositivaBonificoEstero = getInfoDispositivaBonificoEstero;
/* get info dispositiva bonifico estero - FINE */
/* autorizza bonifico estero - START */
function autorizzaBonificoEsteroStart() {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_AUTORIZZA_START
    };
}
function autorizzaBonificoEsteroSuccess(payload) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_AUTORIZZA_SUCCESS,
        payload
    };
}
const autorizzaBonificoEstero = (idDispositiva, credenziale, bonifico) => async (dispatch, _, extraArguments) => {
    dispatch((0, __1.setLoader)(true));
    dispatch(autorizzaBonificoEsteroStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/autorizza_bonifico.json')).then(parametri => {
            // dispatch(autorizzaBonificoEsteroSuccess(EsitoAutorizzazioneDisposizioneBonificoDtoFromJSON(parametri)));
            dispatch((0, __1.setLoader)(false));
        });
    }
    else {
        const getInfoDispositivaBonificoSepaRequest = {
            idDispositiva,
            credenzialeDispositivaDto: credenziale,
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BonificoEsteroControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).autorizzaBonificoEstero(getInfoDispositivaBonificoSepaRequest).then((response) => {
                dispatch(autorizzaBonificoEsteroSuccess(response));
                extraArguments.sendEventToMatomo('BONIFICO_ESTERO', `esito dispositiva BONIFICO_ESTERO ${response.stato}`, 'ESITO_DISPOSITIVA');
            }).catch(async (error) => {
                dispatch(generalError(await (0, __1.formatError)(error), false, extraArguments.sendEventToMatomo));
            }).finally(() => {
                dispatch((0, __1.setLoader)(false));
            });
        });
    }
};
exports.autorizzaBonificoEstero = autorizzaBonificoEstero;
/* autorizza dispositiva bonifico estero - FINE */
function getPaesiBICStart() {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_GET_PAESI_BIC_START
    };
}
function getPaesiBICSuccess(payload) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_GET_PAESI_BIC_SUCCESS,
        payload
    };
}
const getPaesiBIC = () => async (dispatch, _, extraArguments) => {
    dispatch((0, __1.setLoader)(true));
    dispatch(getPaesiBICStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/paesi_bic.json')).then(parametri => {
            // dispatch(autorizzaBonificoSepaSuccess(EsitoAutorizzazioneDisposizioneBonificoDtoFromJSON(parametri)));
            dispatch((0, __1.setLoader)(false));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BonificoEsteroControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).paesiBic().then((response) => {
                dispatch(getPaesiBICSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, __1.formatError)(error)));
            }).finally(() => {
                dispatch((0, __1.setLoader)(false));
            });
        });
    }
};
exports.getPaesiBIC = getPaesiBIC;
function getBozzaBonificoEsteroStart() {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_GET_BOZZA_BONIFICO_ESTERO_START
    };
}
function getBozzaBonificoEsteroSuccess(payload) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_GET_BOZZA_BONIFICO_ESTERO_SUCCESS,
        payload
    };
}
const getBozzaBonificoEstero = (id) => async (dispatch, _, extraArguments) => {
    dispatch((0, __1.setLoader)(true));
    dispatch(getBozzaBonificoEsteroStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/autorizza_bonifico.json')).then(parametri => {
            // dispatch(getBozzaBonificoEsteroSuccess(EsitoAutorizzazioneDisposizioneBonificoDtoFromJSON(parametri)));
            dispatch((0, __1.setLoader)(false));
        });
    }
    else {
        const request = {
            id: id
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BonificoEsteroControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).bozzaBonificoEstero(request).then((response) => {
                dispatch(getBozzaBonificoEsteroSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, __1.formatError)(error)));
            }).finally(() => {
                dispatch((0, __1.setLoader)(false));
            });
        });
    }
};
exports.getBozzaBonificoEstero = getBozzaBonificoEstero;
function getBozzeBonificoEsteroStart() {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_GET_BOZZE_BONIFICO_ESTERO_START
    };
}
function getBozzeBonificoEsteroSuccess(payload) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_GET_BOZZE_BONIFICO_ESTERO_SUCCESS,
        payload
    };
}
const getBozzeBonificoEstero = (bozze) => async (dispatch, _, extraArguments) => {
    dispatch((0, __1.setLoader)(true));
    dispatch(getBozzeBonificoEsteroStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/autorizza_bonifico.json')).then(parametri => {
            // dispatch(getBozzeBonificoEsteroSuccess(EsitoAutorizzazioneDisposizioneBonificoDtoFromJSON(parametri)));
            dispatch((0, __1.setLoader)(false));
        });
    }
    else {
        const request = {
            bozzeInDto: bozze
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BonificoEsteroControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).bozzeBonificoEstero(request).then((response) => {
                dispatch(getBozzeBonificoEsteroSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, __1.formatError)(error)));
            }).finally(() => {
                dispatch((0, __1.setLoader)(false));
            });
        });
    }
};
exports.getBozzeBonificoEstero = getBozzeBonificoEstero;
function eliminaBozzaBonificoEsteroStart() {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_ELIMINA_BOZZA_BONIFICO_ESTERO_START
    };
}
function eliminaBozzaBonificoEsteroSuccess(id) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_ELIMINA_BOZZA_BONIFICO_ESTERO_SUCCESS,
        payload: id
    };
}
const eliminaBozzaBonificoEstero = (id) => async (dispatch, _, extraArguments) => {
    dispatch((0, __1.setLoader)(true));
    dispatch(eliminaBozzaBonificoEsteroStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/autorizza_bonifico.json')).then(parametri => {
            // dispatch(getBozzeBonificoEsteroSuccess(EsitoAutorizzazioneDisposizioneBonificoDtoFromJSON(parametri)));
            dispatch((0, __1.setLoader)(false));
        });
    }
    else {
        const request = {
            id: id
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BonificoEsteroControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).eliminaBozzaBonificoEstero(request).then(() => {
                dispatch(eliminaBozzaBonificoEsteroSuccess(id));
            }).catch(async (error) => {
                dispatch(generalError(await (0, __1.formatError)(error)));
            }).finally(() => {
                dispatch((0, __1.setLoader)(false));
            });
        });
    }
};
exports.eliminaBozzaBonificoEstero = eliminaBozzaBonificoEstero;
function salvaBozzaBonificoEsteroStart() {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SALVA_BOZZA_BONIFICO_ESTERO_START
    };
}
function salvaBozzaBonificoEsteroSuccess(payload) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_SALVA_BOZZA_BONIFICO_ESTERO_SUCCESS,
        payload
    };
}
const salvaBozzaBonificoEstero = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, __1.setLoader)(true));
    dispatch(salvaBozzaBonificoEsteroStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/autorizza_bonifico.json')).then(parametri => {
            // dispatch(salvaBozzaBonificoEsteroSuccess(EsitoAutorizzazioneDisposizioneBonificoDtoFromJSON(parametri)));
            dispatch((0, __1.setLoader)(false));
        });
    }
    else {
        const bonificoEstero = getState().bonificoEstero;
        const request = {
            bonificoEsteroDto: (0, __1.buildBonificoEstero)(bonificoEstero)
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BonificoEsteroControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).salvaBozzaBonificoEstero(request).then((result) => {
                dispatch(salvaBozzaBonificoEsteroSuccess(result));
            }).catch(async (error) => {
                dispatch(generalError(await (0, __1.formatError)(error)));
            }).finally(() => {
                dispatch((0, __1.setLoader)(false));
            });
        });
    }
};
exports.salvaBozzaBonificoEstero = salvaBozzaBonificoEstero;
function cercaBICBonificoEsteroStart() {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_CERCA_BIC_BONIFICO_ESTERO_START
    };
}
function cercaBICBonificoEsteroSuccess(payload, offset) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_CERCA_BIC_BONIFICO_ESTERO_SUCCESS,
        payload,
        offset
    };
}
const cercaBICBonificoEstero = (pagina) => async (dispatch, getState, extraArguments) => {
    const form = getState().bonificoEstero.form;
    if ((!form.offset || form.offset === 0)) {
        dispatch((0, __1.setLoader)(true));
    }
    if (form.offset && form.offset > 0) {
        dispatch(setBonificoEsteroListLoader(true));
    }
    dispatch(cercaBICBonificoEsteroStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/autorizza_bonifico.json')).then(parametri => {
            // dispatch(salvaBozzaBonificoEsteroSuccess(EsitoAutorizzazioneDisposizioneBonificoDtoFromJSON(parametri)));
            dispatch((0, __1.setLoader)(false));
        });
    }
    else {
        const formRicercaBIC = getState().bonificoEstero.formRicercaBIC;
        const request = {
            countryCode: formRicercaBIC.paeseBic,
            bankName: formRicercaBIC.nomeBanca.toUpperCase(),
            offset: pagina || form.offset
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BonificoEsteroControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).searchBic(request).then((result) => {
                dispatch(cercaBICBonificoEsteroSuccess(result, form.offset));
            }).catch(async (error) => {
                dispatch(generalError(await (0, __1.formatError)(error)));
            }).finally(() => {
                if ((!form.offset || form.offset === 0)) {
                    dispatch((0, __1.setLoader)(false));
                }
                if (form.offset && form.offset > 0) {
                    dispatch(setBonificoEsteroListLoader(false));
                }
            });
        });
    }
};
exports.cercaBICBonificoEstero = cercaBICBonificoEstero;
function aggiornaBozzaBonificoEsteroStart() {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_AGGIORNA_BOZZA_BONIFICO_ESTERO_START
    };
}
function aggiornaBozzaBonificoEsteroSuccess(payload) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_AGGIORNA_BOZZA_BONIFICO_ESTERO_SUCCESS,
        payload
    };
}
const aggiornaBozzaBonificoEstero = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, __1.setLoader)(true));
    dispatch(aggiornaBozzaBonificoEsteroStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/autorizza_bonifico.json')).then(parametri => {
            // dispatch(aggiornaBozzaBonificoEsteroSuccess(EsitoAutorizzazioneDisposizioneBonificoDtoFromJSON(parametri)));
            dispatch((0, __1.setLoader)(false));
        });
    }
    else {
        const bonificoEstero = getState().bonificoEstero;
        const request = {
            id: 0,
            bonificoEsteroDto: (0, __1.buildBonificoEstero)(bonificoEstero)
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BonificoEsteroControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).updateBozzaBonificoEstero(request).then((result) => {
                dispatch(aggiornaBozzaBonificoEsteroSuccess(result));
            }).catch(async (error) => {
                dispatch(generalError(await (0, __1.formatError)(error)));
            }).finally(() => {
                dispatch((0, __1.setLoader)(false));
            });
        });
    }
};
exports.aggiornaBozzaBonificoEstero = aggiornaBozzaBonificoEstero;
function getBonificoEsteroFromBozza(payload, noBozza = false) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_GET_BONIFICO_ESTERO_FROM_BOZZA,
        payload,
        noBozza
    };
}
exports.getBonificoEsteroFromBozza = getBonificoEsteroFromBozza;
function resetRiepilogoBonificoEstero() {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_RESET_RIEPILOGO_BONIFICO_ESTERO
    };
}
exports.resetRiepilogoBonificoEstero = resetRiepilogoBonificoEstero;
function resetBonificoEsteroInfoDispositiva() {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_RESET_BONIFICO_ESTERO_INFO_DISPOSITIVA
    };
}
exports.resetBonificoEsteroInfoDispositiva = resetBonificoEsteroInfoDispositiva;
function resetEsitoBonificoEstero() {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_RESET_ESITO_BONIFICO_ESTERO
    };
}
exports.resetEsitoBonificoEstero = resetEsitoBonificoEstero;
function resetBonificoEstero() {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_RESET_BONIFICO_ESTERO
    };
}
exports.resetBonificoEstero = resetBonificoEstero;
function resetFiltriRicercaBIC() {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_RESET_FILTRI_RICERCA_BIC
    };
}
exports.resetFiltriRicercaBIC = resetFiltriRicercaBIC;
function getCambioDivisaEsteroStart() {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_GET_CAMBIO_DIVISA_START
    };
}
function getCambioDivisaEsteroSuccess(payload) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_GET_CAMBIO_DIVISA_SUCCESS,
        payload
    };
}
const getCambioDivisa = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, __1.setLoader)(true));
    dispatch(getCambioDivisaEsteroStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/controllo_bonifico.json')).then(parametri => {
            // dispatch(getCambioDivisaEsteroSuccess(BonificoSepaDtoFromJSON(parametri)));
            dispatch((0, __1.setLoader)(false));
        });
    }
    else {
        const request = {
            divisa: getState().bonificoEstero.form.valuta
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BonificoEsteroControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).cambioBonificoEstero(request).then((response) => {
                dispatch(getCambioDivisaEsteroSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, __1.formatError)(error)));
            }).finally(() => {
                dispatch((0, __1.setLoader)(false));
            });
        });
    }
};
exports.getCambioDivisa = getCambioDivisa;
function getElencoDiviseBonificoEsteroStart() {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_GET_ELENCO_DIVISE_START
    };
}
function getElencoDiviseBonificoEsteroSuccess(payload) {
    return {
        type: bonificoEstero_types_1.EReduxBonificoEsteroActionTypes.BONIFICO_ESTERO_GET_ELENCO_DIVISE_SUCCESS,
        payload
    };
}
const getElencoDiviseBonificoEstero = () => async (dispatch, _, extraArguments) => {
    dispatch((0, __1.setLoader)(true));
    dispatch(getElencoDiviseBonificoEsteroStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/controllo_bonifico.json')).then(parametri => {
            // dispatch(getCambioDivisaEsteroSuccess(BonificoSepaDtoFromJSON(parametri)));
            dispatch((0, __1.setLoader)(false));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BonificoEsteroControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).diviseBonificoEstero().then((response) => {
                dispatch(getElencoDiviseBonificoEsteroSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, __1.formatError)(error)));
            }).finally(() => {
                dispatch((0, __1.setLoader)(false));
            });
        });
    }
};
exports.getElencoDiviseBonificoEstero = getElencoDiviseBonificoEstero;
