"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.riepilogoConsensi = exports.esitoConsensi = exports.infoAutenticazioneConsensi = exports.formConsensi = exports.elencoConsensi = void 0;
const reselect_1 = require("reselect");
const consensiReducer = (state) => state;
exports.elencoConsensi = (0, reselect_1.createSelector)(consensiReducer, (state) => {
    return state.consensi.consensi;
});
exports.formConsensi = (0, reselect_1.createSelector)(consensiReducer, (state) => {
    return state.formConsensi;
});
exports.infoAutenticazioneConsensi = (0, reselect_1.createSelector)(consensiReducer, (state) => {
    return state.infoDispositiva;
});
exports.esitoConsensi = (0, reselect_1.createSelector)(consensiReducer, (state) => {
    return state.esito;
});
exports.riepilogoConsensi = (0, reselect_1.createSelector)(consensiReducer, (state) => {
    return state.riepilogo;
});
