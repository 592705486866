"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dettaglioPrestitoIdea = exports.rapportoSelezionatoCarousel = exports.parametriPrestitoIdea = exports.urlPrestitoIdea = exports.rapportoSelezionatoPrestitoIdea = exports.datiUtentePrestitoIdea = void 0;
const reselect_1 = require("reselect");
const prestitoIdeaReducer = (state) => state;
exports.datiUtentePrestitoIdea = (0, reselect_1.createSelector)(prestitoIdeaReducer, (state) => {
    return state.datiUtente;
});
exports.rapportoSelezionatoPrestitoIdea = (0, reselect_1.createSelector)(prestitoIdeaReducer, (state) => {
    return state.rapportoSelezionato;
});
exports.urlPrestitoIdea = (0, reselect_1.createSelector)(prestitoIdeaReducer, (state) => {
    return state.url;
});
exports.parametriPrestitoIdea = (0, reselect_1.createSelector)(prestitoIdeaReducer, (state) => {
    return state.parametri;
});
exports.rapportoSelezionatoCarousel = (0, reselect_1.createSelector)(prestitoIdeaReducer, (state) => {
    return state.rapportoSelezionatoCarousel;
});
exports.dettaglioPrestitoIdea = (0, reselect_1.createSelector)(prestitoIdeaReducer, (state) => {
    return state.dettaglioPrestito;
});
