"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DisposizioneVirtualeDtoStatoDisposizioneEnum = exports.DisposizioneVirtualeDtoToJSON = exports.DisposizioneVirtualeDtoFromJSONTyped = exports.DisposizioneVirtualeDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function DisposizioneVirtualeDtoFromJSON(json) {
    return DisposizioneVirtualeDtoFromJSONTyped(json, false);
}
exports.DisposizioneVirtualeDtoFromJSON = DisposizioneVirtualeDtoFromJSON;
function DisposizioneVirtualeDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : _1.DisposizioneVirtualeChiaveDtoFromJSON(json['id']),
        'statoDisposizione': !runtime_1.exists(json, 'statoDisposizione') ? undefined : json['statoDisposizione'],
        'customData': !runtime_1.exists(json, 'customData') ? undefined : json['customData'],
        'codiceVerifica': !runtime_1.exists(json, 'codiceVerifica') ? undefined : json['codiceVerifica'],
        'codiceConfermaRecapiti': !runtime_1.exists(json, 'codiceConfermaRecapiti') ? undefined : json['codiceConfermaRecapiti'],
        'dataInserimento': !runtime_1.exists(json, 'dataInserimento') ? undefined : (new Date(json['dataInserimento'])),
        'codiceIstituto': !runtime_1.exists(json, 'codiceIstituto') ? undefined : json['codiceIstituto'],
        'codiceUtente': !runtime_1.exists(json, 'codiceUtente') ? undefined : json['codiceUtente'],
    };
}
exports.DisposizioneVirtualeDtoFromJSONTyped = DisposizioneVirtualeDtoFromJSONTyped;
function DisposizioneVirtualeDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': _1.DisposizioneVirtualeChiaveDtoToJSON(value.id),
        'statoDisposizione': value.statoDisposizione,
        'customData': value.customData,
        'codiceVerifica': value.codiceVerifica,
        'codiceConfermaRecapiti': value.codiceConfermaRecapiti,
        'dataInserimento': value.dataInserimento === undefined ? undefined : (value.dataInserimento.toISOString()),
        'codiceIstituto': value.codiceIstituto,
        'codiceUtente': value.codiceUtente,
    };
}
exports.DisposizioneVirtualeDtoToJSON = DisposizioneVirtualeDtoToJSON;
/**
* @export
* @enum {string}
*/
var DisposizioneVirtualeDtoStatoDisposizioneEnum;
(function (DisposizioneVirtualeDtoStatoDisposizioneEnum) {
    DisposizioneVirtualeDtoStatoDisposizioneEnum["AUTORIZZATO"] = "AUTORIZZATO";
    DisposizioneVirtualeDtoStatoDisposizioneEnum["AUTORIZZATO_NON_REV"] = "AUTORIZZATO_NON_REV";
    DisposizioneVirtualeDtoStatoDisposizioneEnum["NON_AUTORIZZATO"] = "NON_AUTORIZZATO";
    DisposizioneVirtualeDtoStatoDisposizioneEnum["IN_CORSO"] = "IN_CORSO";
    DisposizioneVirtualeDtoStatoDisposizioneEnum["INVIATO"] = "INVIATO";
    DisposizioneVirtualeDtoStatoDisposizioneEnum["ESITO_INCERTO"] = "ESITO_INCERTO";
    DisposizioneVirtualeDtoStatoDisposizioneEnum["REVOCATO"] = "REVOCATO";
    DisposizioneVirtualeDtoStatoDisposizioneEnum["DA_AUTORIZZARE"] = "DA_AUTORIZZARE";
    DisposizioneVirtualeDtoStatoDisposizioneEnum["BOZZA"] = "BOZZA";
    DisposizioneVirtualeDtoStatoDisposizioneEnum["SOSPESO"] = "SOSPESO";
    DisposizioneVirtualeDtoStatoDisposizioneEnum["STORNATO"] = "STORNATO";
    DisposizioneVirtualeDtoStatoDisposizioneEnum["PAGATO"] = "PAGATO";
    DisposizioneVirtualeDtoStatoDisposizioneEnum["ANNULLATO"] = "ANNULLATO";
})(DisposizioneVirtualeDtoStatoDisposizioneEnum = exports.DisposizioneVirtualeDtoStatoDisposizioneEnum || (exports.DisposizioneVirtualeDtoStatoDisposizioneEnum = {}));
