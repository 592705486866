"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiltroCorrispondenzaDtoToJSON = exports.FiltroCorrispondenzaDtoFromJSONTyped = exports.FiltroCorrispondenzaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function FiltroCorrispondenzaDtoFromJSON(json) {
    return FiltroCorrispondenzaDtoFromJSONTyped(json, false);
}
exports.FiltroCorrispondenzaDtoFromJSON = FiltroCorrispondenzaDtoFromJSON;
function FiltroCorrispondenzaDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'tipoEstrazione': !runtime_1.exists(json, 'tipoEstrazione') ? undefined : json['tipoEstrazione'],
    };
}
exports.FiltroCorrispondenzaDtoFromJSONTyped = FiltroCorrispondenzaDtoFromJSONTyped;
function FiltroCorrispondenzaDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'tipoEstrazione': value.tipoEstrazione,
    };
}
exports.FiltroCorrispondenzaDtoToJSON = FiltroCorrispondenzaDtoToJSON;
