"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DatiBollettinoDtoToJSON = exports.DatiBollettinoDtoFromJSONTyped = exports.DatiBollettinoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function DatiBollettinoDtoFromJSON(json) {
    return DatiBollettinoDtoFromJSONTyped(json, false);
}
exports.DatiBollettinoDtoFromJSON = DatiBollettinoDtoFromJSON;
function DatiBollettinoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'cinImporto': !runtime_1.exists(json, 'cinImporto') ? undefined : json['cinImporto'],
        'cinIntermedio': !runtime_1.exists(json, 'cinIntermedio') ? undefined : json['cinIntermedio'],
        'iban': !runtime_1.exists(json, 'iban') ? undefined : json['iban'],
        'esenzione': !runtime_1.exists(json, 'esenzione') ? undefined : json['esenzione'],
        'divisa': !runtime_1.exists(json, 'divisa') ? undefined : json['divisa'],
        'cinComplessivo': !runtime_1.exists(json, 'cinComplessivo') ? undefined : json['cinComplessivo'],
        'codiceIdentificativo': !runtime_1.exists(json, 'codiceIdentificativo') ? undefined : _1.CodiceIdentificativoDtoFromJSON(json['codiceIdentificativo']),
    };
}
exports.DatiBollettinoDtoFromJSONTyped = DatiBollettinoDtoFromJSONTyped;
function DatiBollettinoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'cinImporto': value.cinImporto,
        'cinIntermedio': value.cinIntermedio,
        'iban': value.iban,
        'esenzione': value.esenzione,
        'divisa': value.divisa,
        'cinComplessivo': value.cinComplessivo,
        'codiceIdentificativo': _1.CodiceIdentificativoDtoToJSON(value.codiceIdentificativo),
    };
}
exports.DatiBollettinoDtoToJSON = DatiBollettinoDtoToJSON;
