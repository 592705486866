"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DossierBODtoToJSON = exports.DossierBODtoFromJSONTyped = exports.DossierBODtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function DossierBODtoFromJSON(json) {
    return DossierBODtoFromJSONTyped(json, false);
}
exports.DossierBODtoFromJSON = DossierBODtoFromJSON;
function DossierBODtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'bankCode': !runtime_1.exists(json, 'bankCode') ? undefined : json['bankCode'],
        'data': !runtime_1.exists(json, 'data') ? undefined : _1.DossierCustomersAggregationLevelBODtoFromJSON(json['data']),
        'message': !runtime_1.exists(json, 'message') ? undefined : json['message'],
        'userId': !runtime_1.exists(json, 'userId') ? undefined : json['userId'],
    };
}
exports.DossierBODtoFromJSONTyped = DossierBODtoFromJSONTyped;
function DossierBODtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'bankCode': value.bankCode,
        'data': _1.DossierCustomersAggregationLevelBODtoToJSON(value.data),
        'message': value.message,
        'userId': value.userId,
    };
}
exports.DossierBODtoToJSON = DossierBODtoToJSON;
