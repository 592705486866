"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImportiDtoToJSON = exports.ImportiDtoFromJSONTyped = exports.ImportiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function ImportiDtoFromJSON(json) {
    return ImportiDtoFromJSONTyped(json, false);
}
exports.ImportiDtoFromJSON = ImportiDtoFromJSON;
function ImportiDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'totaleVersato': !runtime_1.exists(json, 'totaleVersato') ? undefined : json['totaleVersato'],
        'totaleRiscatti': !runtime_1.exists(json, 'totaleRiscatti') ? undefined : json['totaleRiscatti'],
        'rata': !runtime_1.exists(json, 'rata') ? undefined : json['rata'],
        'rataValutato': !runtime_1.exists(json, 'rataValutato') ? undefined : json['rataValutato'],
        'rendimento': !runtime_1.exists(json, 'rendimento') ? undefined : json['rendimento'],
    };
}
exports.ImportiDtoFromJSONTyped = ImportiDtoFromJSONTyped;
function ImportiDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'totaleVersato': value.totaleVersato,
        'totaleRiscatti': value.totaleRiscatti,
        'rata': value.rata,
        'rataValutato': value.rataValutato,
        'rendimento': value.rendimento,
    };
}
exports.ImportiDtoToJSON = ImportiDtoToJSON;
