"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssicurazioneElementoDtoToJSON = exports.AssicurazioneElementoDtoFromJSONTyped = exports.AssicurazioneElementoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function AssicurazioneElementoDtoFromJSON(json) {
    return AssicurazioneElementoDtoFromJSONTyped(json, false);
}
exports.AssicurazioneElementoDtoFromJSON = AssicurazioneElementoDtoFromJSON;
function AssicurazioneElementoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codiceRapporto': !runtime_1.exists(json, 'codiceRapporto') ? undefined : json['codiceRapporto'],
        'codiceRapportoFormattatoPerTipo': !runtime_1.exists(json, 'codiceRapportoFormattatoPerTipo') ? undefined : json['codiceRapportoFormattatoPerTipo'],
        'numeroPolizza': !runtime_1.exists(json, 'numeroPolizza') ? undefined : json['numeroPolizza'],
        'controvalore': !runtime_1.exists(json, 'controvalore') ? undefined : json['controvalore'],
    };
}
exports.AssicurazioneElementoDtoFromJSONTyped = AssicurazioneElementoDtoFromJSONTyped;
function AssicurazioneElementoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codiceRapporto': value.codiceRapporto,
        'codiceRapportoFormattatoPerTipo': value.codiceRapportoFormattatoPerTipo,
        'numeroPolizza': value.numeroPolizza,
        'controvalore': value.controvalore,
    };
}
exports.AssicurazioneElementoDtoToJSON = AssicurazioneElementoDtoToJSON;
