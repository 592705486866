"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ravReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const helpers_1 = require("../helpers");
const rav_types_1 = require("./rav.types");
const moment = require("moment");
const initialState = {
    parametri: (0, api_rest_1.ParametriRavDtoFromJSON)({}),
    riepilogo: (0, api_rest_1.RavDtoFromJSON)({}),
    form: (0, rav_types_1.FormRavDtoFromJSONTyped)({}),
    esito: (0, api_rest_1.EsitoAutorizzazioneDisposizioneRavDtoFromJSON)({}),
    bozzeRav: (0, api_rest_1.RavsDtoFromJSON)({}),
    riepilogoRevoca: (0, api_rest_1.RavDtoFromJSON)({}),
    infoRevoca: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
    formRevoca: (0, rav_types_1.FormRevocaRavDtoFromJSON)({}),
    infoAutenticazioneRav: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
    esitoRevoca: (0, api_rest_1.EsitoAutorizzazioneDisposizioneRavDtoFromJSON)({}),
    currentBozza: (0, api_rest_1.RavDtoFromJSON)({})
};
let rapportoSelezionato;
const ravReducer = (state = initialState, action) => {
    switch (action.type) {
        case __1.EReduxActionTypes.RAV_GET_PARAMETRI_SUCCESS:
            return {
                ...initialState,
                parametri: action.payload
            };
        case __1.EReduxActionTypes.RAV_SET_RAPPORTO_SELEZIONATO:
            return {
                ...state,
                form: {
                    ...state.form,
                    rapportoSelezionato: action.payload,
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.RAV_IMPORTO:
            return {
                ...state,
                form: {
                    ...state.form,
                    importo: action.payload,
                    importoDirty: true,
                    importoError: (0, helpers_1.validationField)(action.payload, 0),
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.RAV_CODICE_RAV:
            return {
                ...state,
                form: {
                    ...state.form,
                    codiceRAV: action.payload,
                    codiceRAVDirty: true,
                    codiceRAVError: (0, helpers_1.validationCodiceRav)(action.payload, true),
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.RAV_DATA_ESEC:
            return {
                ...state,
                form: {
                    ...state.form,
                    dataEsecuzione: action.payload,
                    dataEsecuzioneDirty: true,
                    dataEsecuzioneError: (0, helpers_1.validationFieldDate)(action.payload, [], true, '')
                }
            };
        case __1.EReduxActionTypes.RAV_CONTROLLO_SUCCESS:
            return {
                ...state,
                riepilogo: action.payload
            };
        case __1.EReduxActionTypes.RAV_AUTORIZZA_SUCCESS:
            if (action.payload.stato === 'OK') {
                return {
                    ...state,
                    //riepilogo: initialState.riepilogo,
                    esito: action.payload
                };
            }
            else {
                return {
                    ...state,
                    infoAutenticazioneRav: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoAutenticazioneRav, action.payload.informazioniAutenticazioneDispositiva),
                    esito: action.payload
                };
            }
        case __1.EReduxActionTypes.RAV_STEP:
            return {
                ...state,
                form: {
                    ...state.form,
                    step: action.payload
                }
            };
        case __1.EReduxActionTypes.RAV_RESET:
            return {
                ...initialState
            };
        case __1.EReduxActionTypes.RAV_FORM_SUBMITTED:
            return {
                ...state,
                form: {
                    ...state.form,
                    formSubmitted: action.payload
                }
            };
        case __1.EReduxActionTypes.RAV_GET_BOZZE_SUCCESS:
            return {
                ...state,
                bozzeRav: action.payload
            };
        case __1.EReduxActionTypes.RAV_GET_BOZZA_SUCCESS: {
            rapportoSelezionato = (0, helpers_1.findInArrayRapporti)(state.parametri && state.parametri.rapporti, action.payload.contoAddebito) || state.form.rapportoSelezionato;
            const data = (action.payload.dataScadenza && moment(action.payload.dataScadenza) >= moment())
                ? moment(action.payload.dataScadenza).format('YYYY-MM-DD')
                : moment().format('YYYY-MM-DD');
            return {
                ...state,
                form: {
                    ...state.form,
                    importo: action.payload.importo,
                    importoDirty: false,
                    importoError: (0, helpers_1.validationField)(action.payload.importo, 0),
                    codiceRAV: action.payload.numeroBollettino,
                    codiceRAVDirty: false,
                    codiceRAVError: (0, helpers_1.validationCodiceRav)(action.payload.numeroBollettino || '', true),
                    rapportoSelezionato: rapportoSelezionato
                },
                currentBozza: action.payload
            };
        }
        case __1.EReduxActionTypes.RAV_GET_RAV_FROM_BOZZA: {
            rapportoSelezionato = (0, helpers_1.findInArrayRapporti)(state.parametri && state.parametri.rapporti, action.payload.contoAddebito) || state.form.rapportoSelezionato;
            const data = (action.payload.dataScadenza && moment(action.payload.dataScadenza) >= moment())
                ? moment(action.payload.dataScadenza).format('YYYY-MM-DD')
                : moment().format('YYYY-MM-DD');
            return {
                ...state,
                form: {
                    ...state.form,
                    importo: action.payload.importo,
                    importoDirty: false,
                    importoError: (0, helpers_1.validationField)(action.payload.importo, 0),
                    codiceRAV: action.payload.numeroBollettino,
                    codiceRAVDirty: false,
                    codiceRAVError: (0, helpers_1.validationCodiceRav)(action.payload.numeroBollettino || '', true),
                    rapportoSelezionato: rapportoSelezionato,
                    dataEsecuzione: data,
                    dataEsecuzioneDirty: true,
                    dataEsecuzioneError: (0, helpers_1.validationFieldDate)(data, [], true, '')
                },
                currentBozza: action.noBozza ? initialState.currentBozza : action.payload
            };
        }
        case __1.EReduxActionTypes.RAV_ADD_BOZZA_SUCCESS:
            return {
                ...state,
                bozzeRav: {
                    ...state.bozzeRav,
                    ravs: state.bozzeRav && state.bozzeRav.ravs ? [
                        ...state.bozzeRav.ravs, action.payload
                    ] : []
                },
                form: {
                    ...state.form,
                    bozzaSalvata: true
                },
                currentBozza: action.payload
            };
        case __1.EReduxActionTypes.RAV_DEL_BOZZA_SUCCESS:
            return {
                ...state,
                bozzeRav: {
                    ...state.bozzeRav,
                    ravs: state.bozzeRav && state.bozzeRav.ravs
                        ? state.bozzeRav.ravs.filter((rav) => rav.id !== action.payload)
                        : []
                },
                form: {
                    ...state.form,
                    bozzaSalvata: state.currentBozza.id === action.payload ? false : state.form.bozzaSalvata
                },
                currentBozza: state.currentBozza.id === action.payload ? initialState.currentBozza : state.currentBozza
            };
        case __1.EReduxActionTypes.RAV_UPDATE_BOZZA_SUCCESS:
            return {
                ...state,
                bozzeRav: {
                    ...state.bozzeRav,
                    ravs: state.bozzeRav && state.bozzeRav.ravs
                        ? state.bozzeRav.ravs.map((rav) => rav.id === action.payload.id ? action.payload : rav)
                        : []
                },
                form: {
                    ...state.form,
                    bozzaSalvata: true
                }
            };
        case __1.EReduxActionTypes.RAV_SET_BOZZA_SALVATA:
            return {
                ...state,
                form: {
                    ...state.form,
                    bozzaSalvata: action.payload
                }
            };
        case __1.EReduxActionTypes.RAV_SET_RIEPILOGO_REVOCA:
            return {
                ...state,
                formRevoca: initialState.formRevoca,
                riepilogoRevoca: action.payload
            };
        case __1.EReduxActionTypes.RAV_SET_PROVENIENZA:
            return {
                ...state,
                formRevoca: {
                    ...state.formRevoca,
                    provenienza: action.payload
                }
            };
        case __1.EReduxActionTypes.RAV_STEP_REVOCA:
            return {
                ...state,
                formRevoca: {
                    ...state.formRevoca,
                    step: action.payload
                }
            };
        case __1.EReduxActionTypes.RAV_GET_INFO_REVOCA_SUCCESS:
            return {
                ...state,
                infoRevoca: action.payload,
                esitoRevoca: initialState.esitoRevoca
            };
        case __1.EReduxActionTypes.RAV_AUTORIZZA_REVOCA_SUCCESS:
            if (action.payload.stato === 'OK') {
                return {
                    ...state,
                    esitoRevoca: action.payload,
                    esito: initialState.esito
                };
            }
            else {
                return {
                    ...state,
                    parametri: initialState.parametri,
                    riepilogo: initialState.riepilogo,
                    esito: initialState.esito,
                    form: initialState.form,
                    riepilogoRevoca: initialState.riepilogoRevoca,
                    infoRevoca: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoRevoca, action.payload.informazioniAutenticazioneDispositiva),
                    esitoRevoca: action.payload
                };
            }
        case __1.EReduxActionTypes.RAV_GET_INFO_AUTENTICAZIONE_RAV_SUCCESS:
            return {
                ...state,
                infoAutenticazioneRav: action.payload,
                esito: initialState.esito
            };
        case __1.EReduxActionTypes.RESET_ALL: // action invocata dalla logout
            return {
                ...initialState
            };
        default:
            return state;
    }
};
exports.ravReducer = ravReducer;
exports.default = exports.ravReducer;
