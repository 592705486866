"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DossierCustomerLevelBODtoToJSON = exports.DossierCustomerLevelBODtoFromJSONTyped = exports.DossierCustomerLevelBODtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function DossierCustomerLevelBODtoFromJSON(json) {
    return DossierCustomerLevelBODtoFromJSONTyped(json, false);
}
exports.DossierCustomerLevelBODtoFromJSON = DossierCustomerLevelBODtoFromJSON;
function DossierCustomerLevelBODtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'bankCode': !runtime_1.exists(json, 'bankCode') ? undefined : json['bankCode'],
        'children': !runtime_1.exists(json, 'children') ? undefined : (json['children'].map(_1.DossierAccountLevelBODtoFromJSON)),
        'ctvAcquisto': !runtime_1.exists(json, 'ctvAcquisto') ? undefined : json['ctvAcquisto'],
        'ctvMercato': !runtime_1.exists(json, 'ctvMercato') ? undefined : json['ctvMercato'],
        'customerCode': !runtime_1.exists(json, 'customerCode') ? undefined : json['customerCode'],
        'dataByInstrumentType': !runtime_1.exists(json, 'dataByInstrumentType') ? undefined : (runtime_1.mapValues(json['dataByInstrumentType'], _1.DossierDataBODtoFromJSON)),
        'fullName': !runtime_1.exists(json, 'fullName') ? undefined : json['fullName'],
        'message': !runtime_1.exists(json, 'message') ? undefined : json['message'],
        'peso': !runtime_1.exists(json, 'peso') ? undefined : json['peso'],
        'plusMinusEuro': !runtime_1.exists(json, 'plusMinusEuro') ? undefined : json['plusMinusEuro'],
        'plusMinusPerc': !runtime_1.exists(json, 'plusMinusPerc') ? undefined : json['plusMinusPerc'],
        'userId': !runtime_1.exists(json, 'userId') ? undefined : json['userId'],
    };
}
exports.DossierCustomerLevelBODtoFromJSONTyped = DossierCustomerLevelBODtoFromJSONTyped;
function DossierCustomerLevelBODtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'bankCode': value.bankCode,
        'children': value.children === undefined ? undefined : (value.children.map(_1.DossierAccountLevelBODtoToJSON)),
        'ctvAcquisto': value.ctvAcquisto,
        'ctvMercato': value.ctvMercato,
        'customerCode': value.customerCode,
        'dataByInstrumentType': value.dataByInstrumentType === undefined ? undefined : (runtime_1.mapValues(value.dataByInstrumentType, _1.DossierDataBODtoToJSON)),
        'fullName': value.fullName,
        'message': value.message,
        'peso': value.peso,
        'plusMinusEuro': value.plusMinusEuro,
        'plusMinusPerc': value.plusMinusPerc,
        'userId': value.userId,
    };
}
exports.DossierCustomerLevelBODtoToJSON = DossierCustomerLevelBODtoToJSON;
