import { PosizioneGestionePatrimoniali } from "@sparkasse/commons";
import { currencyFormat } from "helpers/utilities";
import React from "react";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";

const GestionePatrimonialiTable: React.FC<iProps> = (props: iProps) => {

  const { t } = useTranslation();

  const {
    data,
    noItemsText,
    compactHeader
  } = props;

  const columnsContoTecnico = [
    {
      name: "intestatario",
      descrizione: "patrimonio.tableLabels.intestatario",
      formatter: "",
      bold: true,
      isIntestatario: true,
    },
    {
      name: "saldoDisponibile",
      descrizione: "patrimonio.tableLabels.saldoDisponibile",
      bold: true,
      align: "right",
      isPriceQuote: true,
    },
    {
      name: "saldoContabile",
      descrizione: "patrimonio.tableLabels.saldoContabile",
      bold: true,
      isPriceQuote: true,
      align: "right",
    }
  ] as Column[];

  const columnsDossier = [
    {
      name: "intestatario",
      descrizione: "patrimonio.tableLabels.intestatario",
      formatter: "",
      bold: true,
      isIntestatario: true,
    },
    {
      name: "saldoContabile",
      descrizione: "patrimonio.tableLabels.controvalore",
      bold: true,
      isPriceQuote: true,
      align: "right",
    }
  ] as Column[];

  const columns = data?.tipologia == 'DT' ? columnsDossier : columnsContoTecnico;

  const buildNoItemMesage = (noItemsText: any) => {
    if (noItemsText && noItemsText.length) {
      noItemsText.map((item: any) => {
        return <p>{item}</p>;
      });
    }
  };

  return (
    <div className="custom-table">
      {data &&
        <div >
          {data && data?.gestionePatrimonialeList && data?.gestionePatrimonialeList.length > 0 ? (
            <Table hover borderless responsive>
              <thead>
                <tr>
                  {columns.map((column: Column, index: number) => (
                    <th
                      style={column.width ? { width: column.width } : {}}
                      key={index}
                      className={`${column.align ? `align-${column.align}` : ``
                        }  ${compactHeader ? `compact-header` : ``
                        } small-header bold-text`}
                    >
                      {column.descrizione ? t(column.descrizione) : ""}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.gestionePatrimonialeList.length > 0 &&
                  data.gestionePatrimonialeList.map((item: any, index: number) => {
                    const itemAsAny = item as any;
                    return (
                      <tr key={index}>
                        {columns.map((column: Column, index: number) => {
                          const key = column.name;
                          return (
                            <td
                              key={index + column.name}
                              className={`${column.compact ? "compact " : ""
                                }${column.align ? `align-${column.align} ` : ``
                                }`}
                            >
                              {column.isPrice
                                ? `${currencyFormat(itemAsAny[key])}  EUR`
                                : column.isPriceQuote ? currencyFormat(itemAsAny[key]) :
                                  itemAsAny[key]}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          ) : (
            <div>
              {noItemsText &&
                noItemsText.map((item: any, index: number) => (
                  <p key={`infoText-${index}`}>{item}</p>
                ))}
            </div>
          )}
        </div>
      }
    </div>
  );
};

export interface Column {
  name: string;
  descrizione?: string;
  align?: "left" | "center" | "right";
  compact?: boolean;
  bold?: boolean;
  isPrice?: boolean;
  isPriceQuote?: boolean;
  width?: number;
}

interface iProps {
  data?: PosizioneGestionePatrimoniali;
  noItemsText?: any;
  boldHeader?: boolean;
  compactHeader?: boolean;
  tableTitle?: string;
  aggiungiPreferito?: any;
  rimuoviPreferito?: any;
}

export default GestionePatrimonialiTable;
