"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CofidisInDtoToJSON = exports.CofidisInDtoFromJSONTyped = exports.CofidisInDtoFromJSON = void 0;
function CofidisInDtoFromJSON(json) {
    return CofidisInDtoFromJSONTyped(json, false);
}
exports.CofidisInDtoFromJSON = CofidisInDtoFromJSON;
function CofidisInDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codiceFiscale': json['codiceFiscale'],
        'nome': json['nome'],
        'cognome': json['cognome'],
        'iban': json['iban'],
    };
}
exports.CofidisInDtoFromJSONTyped = CofidisInDtoFromJSONTyped;
function CofidisInDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codiceFiscale': value.codiceFiscale,
        'nome': value.nome,
        'cognome': value.cognome,
        'iban': value.iban,
    };
}
exports.CofidisInDtoToJSON = CofidisInDtoToJSON;
