"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenSoftwareActivationResponseDtoToJSON = exports.TokenSoftwareActivationResponseDtoFromJSONTyped = exports.TokenSoftwareActivationResponseDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function TokenSoftwareActivationResponseDtoFromJSON(json) {
    return TokenSoftwareActivationResponseDtoFromJSONTyped(json, false);
}
exports.TokenSoftwareActivationResponseDtoFromJSON = TokenSoftwareActivationResponseDtoFromJSON;
function TokenSoftwareActivationResponseDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'binaryBlockData': !runtime_1.exists(json, 'binaryBlockData') ? undefined : json['binaryBlockData'],
        'nextStep': !runtime_1.exists(json, 'nextStep') ? undefined : json['nextStep'],
        'qrCode': !runtime_1.exists(json, 'qrCode') ? undefined : _1.TokenSoftwareActivationResponseQrCodeDtoFromJSON(json['qrCode']),
    };
}
exports.TokenSoftwareActivationResponseDtoFromJSONTyped = TokenSoftwareActivationResponseDtoFromJSONTyped;
function TokenSoftwareActivationResponseDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'binaryBlockData': value.binaryBlockData,
        'nextStep': value.nextStep,
        'qrCode': _1.TokenSoftwareActivationResponseQrCodeDtoToJSON(value.qrCode),
    };
}
exports.TokenSoftwareActivationResponseDtoToJSON = TokenSoftwareActivationResponseDtoToJSON;
