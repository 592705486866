"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OnBoardOutDtoToJSON = exports.OnBoardOutDtoFromJSONTyped = exports.OnBoardOutDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function OnBoardOutDtoFromJSON(json) {
    return OnBoardOutDtoFromJSONTyped(json, false);
}
exports.OnBoardOutDtoFromJSON = OnBoardOutDtoFromJSON;
function OnBoardOutDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'subscription': !runtime_1.exists(json, 'subscription') ? undefined : _1.SubscriptionOkFromJSON(json['subscription']),
        'token': !runtime_1.exists(json, 'token') ? undefined : json['token'],
    };
}
exports.OnBoardOutDtoFromJSONTyped = OnBoardOutDtoFromJSONTyped;
function OnBoardOutDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'subscription': _1.SubscriptionOkToJSON(value.subscription),
        'token': value.token,
    };
}
exports.OnBoardOutDtoToJSON = OnBoardOutDtoToJSON;
