"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaginazioneToJSON = exports.PaginazioneFromJSONTyped = exports.PaginazioneFromJSON = void 0;
const runtime_1 = require("../runtime");
function PaginazioneFromJSON(json) {
    return PaginazioneFromJSONTyped(json, false);
}
exports.PaginazioneFromJSON = PaginazioneFromJSON;
function PaginazioneFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'pagina': !runtime_1.exists(json, 'pagina') ? undefined : json['pagina'],
        'elementiPerPagina': !runtime_1.exists(json, 'elementiPerPagina') ? undefined : json['elementiPerPagina'],
        'first': !runtime_1.exists(json, 'first') ? undefined : json['first'],
        'last': !runtime_1.exists(json, 'last') ? undefined : json['last'],
        'totaleElementi': !runtime_1.exists(json, 'totaleElementi') ? undefined : json['totaleElementi'],
    };
}
exports.PaginazioneFromJSONTyped = PaginazioneFromJSONTyped;
function PaginazioneToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'pagina': value.pagina,
        'elementiPerPagina': value.elementiPerPagina,
        'first': value.first,
        'last': value.last,
        'totaleElementi': value.totaleElementi,
    };
}
exports.PaginazioneToJSON = PaginazioneToJSON;
