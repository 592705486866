"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UtenzaDtoToJSON = exports.UtenzaDtoFromJSONTyped = exports.UtenzaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function UtenzaDtoFromJSON(json) {
    return UtenzaDtoFromJSONTyped(json, false);
}
exports.UtenzaDtoFromJSON = UtenzaDtoFromJSON;
function UtenzaDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'identificativo': !runtime_1.exists(json, 'identificativo') ? undefined : json['identificativo'],
        'segnalazioni': !runtime_1.exists(json, 'segnalazioni') ? undefined : json['segnalazioni'],
        'codiceFiscale': !runtime_1.exists(json, 'codiceFiscale') ? undefined : json['codiceFiscale'],
        'provincia': !runtime_1.exists(json, 'provincia') ? undefined : json['provincia'],
        'regione': !runtime_1.exists(json, 'regione') ? undefined : json['regione'],
        'destinazioneUso': !runtime_1.exists(json, 'destinazioneUso') ? undefined : json['destinazioneUso'],
        'alimentazione': !runtime_1.exists(json, 'alimentazione') ? undefined : json['alimentazione'],
        'immatricolazione': !runtime_1.exists(json, 'immatricolazione') ? undefined : (new Date(json['immatricolazione'])),
        'classeEuro': !runtime_1.exists(json, 'classeEuro') ? undefined : json['classeEuro'],
        'potenza': !runtime_1.exists(json, 'potenza') ? undefined : json['potenza'],
        'categoria': !runtime_1.exists(json, 'categoria') ? undefined : json['categoria'],
        'note': !runtime_1.exists(json, 'note') ? undefined : json['note'],
        'mesiValidita': !runtime_1.exists(json, 'mesiValidita') ? undefined : json['mesiValidita'],
        'scadenza': !runtime_1.exists(json, 'scadenza') ? undefined : (new Date(json['scadenza'])),
    };
}
exports.UtenzaDtoFromJSONTyped = UtenzaDtoFromJSONTyped;
function UtenzaDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'identificativo': value.identificativo,
        'segnalazioni': value.segnalazioni,
        'codiceFiscale': value.codiceFiscale,
        'provincia': value.provincia,
        'regione': value.regione,
        'destinazioneUso': value.destinazioneUso,
        'alimentazione': value.alimentazione,
        'immatricolazione': value.immatricolazione === undefined ? undefined : (value.immatricolazione.toISOString()),
        'classeEuro': value.classeEuro,
        'potenza': value.potenza,
        'categoria': value.categoria,
        'note': value.note,
        'mesiValidita': value.mesiValidita,
        'scadenza': value.scadenza === undefined ? undefined : (value.scadenza.toISOString()),
    };
}
exports.UtenzaDtoToJSON = UtenzaDtoToJSON;
