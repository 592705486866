"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.esportaPdfKiidPCT = exports.esportaPdfAcquistoPCT = exports.autorizzaAcquistoPCT = exports.getInfoDispositivaAcquistoPCT = exports.controlloAcquistoPCT = exports.getListiniPCT = exports.setPCTresetForm = exports.resetPCT = exports.resetEsitoPCT = exports.resetRiepilogoPCT = exports.setPCTSetStepImporto = exports.setPCTSetFormSubmitted = exports.setPCTSetCheckboxEnabled = exports.setPCTSetPresaVisioneCosti = exports.setPCTSetPresaVisioneScheda = exports.setPCTSetRapportoSelezionato = exports.setPCTSetListinoSelezionato = exports.setPCTSetImporto = exports.setPCTSetStep = exports.getParametriPCT = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_1 = require("../general");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
const pct_selectors_1 = require("./pct.selectors");
const pct_types_1 = require("./pct.types");
/*
get parametri PCT - START
*/
function getParametriPCTStart() {
    return {
        type: pct_types_1.EReduxPCTActionTypes.PCT_GET_PARAMETRI_PCT_START
    };
}
function getParametriPCTSuccess(payload) {
    return {
        type: pct_types_1.EReduxPCTActionTypes.PCT_GET_PARAMETRI_PCT_SUCCESS,
        payload
    };
}
function generalError(value, showGenericModalError, sendErrorToMatomo) {
    return async (dispatch) => {
        dispatch((0, __1.setError)(value, showGenericModalError));
        !!sendErrorToMatomo && sendErrorToMatomo('ACQUISTO_PCT', `code_error ACQUISTO_PCT ${value.status} / ${value === null || value === void 0 ? void 0 : value.message} / ${value === null || value === void 0 ? void 0 : value.timestamp}`, 'ESITO_DISPOSITIVA');
    };
}
const getParametriPCT = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, __1.setLoader)(true));
    dispatch(getParametriPCTStart());
    if (extraArguments.mock) {
        dispatch((0, __1.setLoader)(false));
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.AcquistoPctControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametriPct().then((response) => {
                dispatch(getParametriPCTSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, __1.setLoader)(false));
            });
        });
    }
};
exports.getParametriPCT = getParametriPCT;
/* get parametri PCT - FINE */
/* set valori form - START */
function setPCTSetStep(i) {
    return {
        type: pct_types_1.EReduxPCTActionTypes.PCT_SET_STEP,
        payload: i
    };
}
exports.setPCTSetStep = setPCTSetStep;
function setPCTSetImporto(i) {
    return {
        type: pct_types_1.EReduxPCTActionTypes.PCT_SET_IMPORTO,
        payload: i
    };
}
exports.setPCTSetImporto = setPCTSetImporto;
function setPCTSetListinoSelezionato(payload) {
    return {
        type: pct_types_1.EReduxPCTActionTypes.PCT_SET_LISTINO_SELEZIONATO,
        payload
    };
}
exports.setPCTSetListinoSelezionato = setPCTSetListinoSelezionato;
function setPCTSetRapportoSelezionato(payload) {
    return {
        type: pct_types_1.EReduxPCTActionTypes.PCT_SET_RAPPORTO_SELEZIONATO,
        payload
    };
}
exports.setPCTSetRapportoSelezionato = setPCTSetRapportoSelezionato;
function setPCTSetPresaVisioneScheda(payload) {
    return {
        type: pct_types_1.EReduxPCTActionTypes.PCT_SET_PRESA_VISIONE_SCHEDA,
        payload
    };
}
exports.setPCTSetPresaVisioneScheda = setPCTSetPresaVisioneScheda;
function setPCTSetPresaVisioneCosti(payload) {
    return {
        type: pct_types_1.EReduxPCTActionTypes.PCT_SET_PRESA_VISIONE_COSTI,
        payload
    };
}
exports.setPCTSetPresaVisioneCosti = setPCTSetPresaVisioneCosti;
function setPCTSetCheckboxEnabled(payload) {
    return {
        type: pct_types_1.EReduxPCTActionTypes.PCT_SET_CHECKBOX_ENABLED,
        payload
    };
}
exports.setPCTSetCheckboxEnabled = setPCTSetCheckboxEnabled;
function setPCTSetFormSubmitted(payload) {
    return {
        type: pct_types_1.EReduxPCTActionTypes.PCT_SET_FORM_SUBMITTED,
        payload
    };
}
exports.setPCTSetFormSubmitted = setPCTSetFormSubmitted;
function setPCTSetStepImporto(i) {
    return {
        type: pct_types_1.EReduxPCTActionTypes.PCT_SET_STEP_IMPORTO,
        payload: i
    };
}
exports.setPCTSetStepImporto = setPCTSetStepImporto;
/* set valori form - FINE */
/* reset valori reducer - START */
function resetRiepilogoPCT() {
    return {
        type: pct_types_1.EReduxPCTActionTypes.PCT_RESET_RIEPILOGO
    };
}
exports.resetRiepilogoPCT = resetRiepilogoPCT;
function resetEsitoPCT() {
    return {
        type: pct_types_1.EReduxPCTActionTypes.PCT_RESET_ESITO
    };
}
exports.resetEsitoPCT = resetEsitoPCT;
function resetPCT() {
    return {
        type: pct_types_1.EReduxPCTActionTypes.PCT_RESET_PCT
    };
}
exports.resetPCT = resetPCT;
function setPCTresetForm() {
    return {
        type: pct_types_1.EReduxPCTActionTypes.PCT_RESET_FORM,
    };
}
exports.setPCTresetForm = setPCTresetForm;
/* reset valori reducer - FINE */
/* get listini PCT - START */
function getListiniPCTStart() {
    return {
        type: pct_types_1.EReduxPCTActionTypes.PCT_GET_LISTINI_PCT_START
    };
}
function getListiniPCTSuccess(payload) {
    return {
        type: pct_types_1.EReduxPCTActionTypes.PCT_GET_LISTINI_PCT_SUCCESS,
        payload
    };
}
const getListiniPCT = () => async (dispatch, getState, extraArguments) => {
    var _a, _b;
    dispatch((0, __1.setLoader)(true));
    dispatch(getListiniPCTStart());
    if (extraArguments.mock) {
        dispatch((0, __1.setLoader)(false));
    }
    else {
        const listaPctRequest = {
            codiceRapporto: (_b = (_a = getState().pct.form.rapportoSelezionato.rapporto) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : 0
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.AcquistoPctControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).listaPct(listaPctRequest).then((response) => {
                dispatch(getListiniPCTSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, __1.setLoader)(false));
            });
        });
    }
};
exports.getListiniPCT = getListiniPCT;
/* get listini PCT - FINE */
/* controllo acquisto PCT - START */
function controlloAcquistoPCTStart() {
    return {
        type: pct_types_1.EReduxPCTActionTypes.PCT_CONTROLLO_ACQUISTO_PCT_START
    };
}
function controlloAcquistoPCTSuccess(payload) {
    return {
        type: pct_types_1.EReduxPCTActionTypes.PCT_CONTROLLO_ACQUISTO_PCT_SUCCESS,
        payload
    };
}
const controlloAcquistoPCT = () => async (dispatch, getState, extraArguments) => {
    dispatch(setPCTSetFormSubmitted(true));
    const pct = getState().pct;
    if ((0, pct_selectors_1.formPCTValid)(pct)) {
        dispatch((0, __1.setLoader)(true));
        dispatch(controlloAcquistoPCTStart());
        if (extraArguments.mock) {
            dispatch((0, __1.setLoader)(false));
        }
        else {
            const pct = getState().pct;
            const controlloAcquistoPctRequest = {
                pctDto: (0, pct_selectors_1.buildPCT)(pct)
            };
            extraArguments.getItem('jwt').then((jwt) => {
                new api_rest_1.AcquistoPctControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).controlloPct(controlloAcquistoPctRequest).then((response) => {
                    dispatch(controlloAcquistoPCTSuccess(response));
                }).catch(async (error) => {
                    dispatch(generalError(await (0, helpers_1.formatError)(error)));
                }).finally(() => {
                    dispatch((0, __1.setLoader)(false));
                });
            });
        }
    }
};
exports.controlloAcquistoPCT = controlloAcquistoPCT;
/* controllo acquisto PCT - FINE */
/* get info acquisto PCT - START */
function getInfoDispositivaAcquistoPCTStart() {
    return {
        type: pct_types_1.EReduxPCTActionTypes.PCT_GET_INFO_DISPOSITIVA_ACQUISTO_PCT_START
    };
}
function getInfoDispositivaAcquistoPCTSuccess(payload) {
    return {
        type: pct_types_1.EReduxPCTActionTypes.PCT_GET_INFO_DISPOSITIVA_ACQUISTO_PCT_SUCCESS,
        payload
    };
}
const getInfoDispositivaAcquistoPCT = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, _, extraArguments) => {
    dispatch((0, __1.setLoader)(true));
    dispatch(getInfoDispositivaAcquistoPCTStart());
    if (extraArguments.mock) {
        dispatch((0, __1.setLoader)(false));
    }
    else {
        const infoDispositivaAcquistoPctRequest = {
            idDispositiva: idDispositiva,
            chiamataUscente,
            eseguiInit,
            offline: !!offline
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.AcquistoPctControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).informazioniAutenticazioneAcquistoPct(infoDispositivaAcquistoPctRequest).then((response) => {
                dispatch(getInfoDispositivaAcquistoPCTSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, __1.setLoader)(false));
            });
        });
    }
};
exports.getInfoDispositivaAcquistoPCT = getInfoDispositivaAcquistoPCT;
/* get info acquisto PCT - FINE */
/* autorizza acquisto PCT - START */
function autorizzaAcquistoPCTStart() {
    return {
        type: pct_types_1.EReduxPCTActionTypes.PCT_AUTORIZZA_ACQUISTO_PCT_START
    };
}
function autorizzaAcquistoPCTSuccess(payload) {
    return {
        type: pct_types_1.EReduxPCTActionTypes.PCT_AUTORIZZA_ACQUISTO_PCT_SUCCESS,
        payload
    };
}
const autorizzaAcquistoPCT = (idDispositiva, credenziale) => async (dispatch, _, extraArguments) => {
    dispatch((0, __1.setLoader)(true));
    dispatch(autorizzaAcquistoPCTStart());
    if (extraArguments.mock) {
        dispatch((0, __1.setLoader)(false));
    }
    else {
        const autorizzaAcquistoPctRequest = {
            idDispositiva: idDispositiva,
            credenzialeDispositivaDto: credenziale
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.AcquistoPctControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).autorizzaAcquistoPct(autorizzaAcquistoPctRequest).then((response) => {
                dispatch(autorizzaAcquistoPCTSuccess(response));
                extraArguments.sendEventToMatomo('ACQUISTO_PCT', `esito dispositiva ACQUISTO_PCT ${response.stato}`, 'ESITO_DISPOSITIVA');
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error), false, extraArguments.sendEventToMatomo));
            }).finally(() => {
                dispatch((0, __1.setLoader)(false));
            });
        });
    }
};
exports.autorizzaAcquistoPCT = autorizzaAcquistoPCT;
/* autorizza acquisto PCT - FINE */
/* esporta pdf acquisto PCT - START */
function esportaPdfAcquistoPCTStart() {
    return {
        type: pct_types_1.EReduxPCTActionTypes.PCT_ESPORTA_PDF_ACQUISTO_PCT_START
    };
}
function esportaPdfAcquistoPCTSuccess(payload) {
    return {
        type: pct_types_1.EReduxPCTActionTypes.PCT_ESPORTA_PDF_ACQUISTO_PCT_SUCCESS,
        payload
    };
}
const esportaPdfAcquistoPCT = (share, title, message) => async (dispatch, getState, extraArguments) => {
    dispatch((0, __1.setLoader)(true));
    dispatch(esportaPdfAcquistoPCTStart());
    if (extraArguments.mock) {
        dispatch((0, __1.setLoader)(false));
    }
    else {
        const pct = getState().pct;
        const esportaPctRequest = {
            pctDto: (0, pct_selectors_1.buildPCT)(pct)
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.AcquistoPctControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf')).stampaDettaglioPctPdf(esportaPctRequest).then((response) => {
                if (extraArguments.platform === 'desktop') {
                    (0, helpers_1.hadlePdfResponse)(response, (url) => dispatch((0, general_1.popUpBlockedPdf)(url)));
                }
                if (extraArguments.platform === 'mobile') {
                    (0, helpers_1.download_sharePDF)(extraArguments, 'Dettaglio Acquisto PCT.pdf', response, share, title, message);
                }
                dispatch(esportaPdfAcquistoPCTSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, __1.setLoader)(false));
            });
        });
    }
};
exports.esportaPdfAcquistoPCT = esportaPdfAcquistoPCT;
/* esporta pdf acquisto PCT - FINE */
/* esporta pdf acquisto PCT - START */
function esportaPdfKiidPCTStart() {
    return {
        type: pct_types_1.EReduxPCTActionTypes.PCT_ESPORTA_PDF_ACQUISTO_PCT_START
    };
}
function esportaPdfKiidPCTSuccess(payload) {
    return {
        type: pct_types_1.EReduxPCTActionTypes.PCT_ESPORTA_PDF_ACQUISTO_PCT_SUCCESS,
        payload
    };
}
const esportaPdfKiidPCT = () => async (dispatch, getState, extraArguments) => {
    var _a, _b, _c;
    dispatch((0, __1.setLoader)(true));
    dispatch(esportaPdfKiidPCTStart());
    if (extraArguments.mock) {
        dispatch((0, __1.setLoader)(false));
    }
    else {
        const pct = getState().pct;
        const esportaPctRequest = {
            codiceTitolo: (_c = (_b = (_a = pct.form.listinoSelezionato) === null || _a === void 0 ? void 0 : _a.codiceTitolo) === null || _b === void 0 ? void 0 : _b.toString()) !== null && _c !== void 0 ? _c : ''
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.AcquistoPctControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf')).stampaPDFKiidPct(esportaPctRequest).then((response) => {
                if (extraArguments.platform === 'desktop') {
                    (0, helpers_1.hadlePdfResponse)(response, (url) => dispatch((0, general_1.popUpBlockedPdf)(url)));
                }
                if (extraArguments.platform === 'mobile') {
                    (0, helpers_1.download_sharePDF)(extraArguments, 'Kiid PCT.pdf', response, false);
                }
                dispatch(esportaPdfKiidPCTSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, __1.setLoader)(false));
            });
        });
    }
};
exports.esportaPdfKiidPCT = esportaPdfKiidPCT;
/* esporta pdf acquisto PCT - FINE */
