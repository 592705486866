"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScadenziereUnicoDtoToJSON = exports.ScadenziereUnicoDtoFromJSONTyped = exports.ScadenziereUnicoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function ScadenziereUnicoDtoFromJSON(json) {
    return ScadenziereUnicoDtoFromJSONTyped(json, false);
}
exports.ScadenziereUnicoDtoFromJSON = ScadenziereUnicoDtoFromJSON;
function ScadenziereUnicoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'anno': !runtime_1.exists(json, 'anno') ? undefined : json['anno'],
        'progressivo': !runtime_1.exists(json, 'progressivo') ? undefined : json['progressivo'],
    };
}
exports.ScadenziereUnicoDtoFromJSONTyped = ScadenziereUnicoDtoFromJSONTyped;
function ScadenziereUnicoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'anno': value.anno,
        'progressivo': value.progressivo,
    };
}
exports.ScadenziereUnicoDtoToJSON = ScadenziereUnicoDtoToJSON;
