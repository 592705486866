"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.titoloDaAcquistare = exports.customerGraphicsTitoli = exports.customerDossierTitoli = exports.rapportoSelezionatoDossierTitoli = exports.rapportiDossierTitoli = exports.formDossierTitoli = void 0;
const reselect_1 = require("reselect");
const dossierTitoliReducer = (state) => state;
exports.formDossierTitoli = (0, reselect_1.createSelector)(dossierTitoliReducer, (state) => {
    return state.form;
});
exports.rapportiDossierTitoli = (0, reselect_1.createSelector)(dossierTitoliReducer, (state) => {
    return state.rapporti;
});
exports.rapportoSelezionatoDossierTitoli = (0, reselect_1.createSelector)(dossierTitoliReducer, (state) => {
    return state.form.rapportoSelezionato;
});
exports.customerDossierTitoli = (0, reselect_1.createSelector)(dossierTitoliReducer, (state) => {
    return state.customerDossier;
});
exports.customerGraphicsTitoli = (0, reselect_1.createSelector)(dossierTitoliReducer, (state) => {
    return state.customerGraphics;
});
exports.titoloDaAcquistare = (0, reselect_1.createSelector)(dossierTitoliReducer, (state) => {
    return state.titoloDaAcquistare;
});
