"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bancomatPayReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const helpers_1 = require("../helpers");
const bancomatPay_types_1 = require("./bancomatPay.types");
const initialState = {
    parametri: (0, api_rest_1.ParametriDtoFromJSON)({}),
    statoUtente: (0, api_rest_1.VerificaStatoUtenteDtoFromJSON)({}),
    disposizioneVirtuale: (0, api_rest_1.DisposizioneVirtualeDtoFromJSON)({}),
    infoDispositiva: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
    esito: (0, api_rest_1.EsitoAutorizzazioneDisposizioneVirtualeDtoFromJSON)({}),
    disposizioneVirtualeMassimali: (0, api_rest_1.DisposizioneVirtualeDtoFromJSON)({}),
    infoDispositivaMassimali: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
    esitoMassimali: (0, api_rest_1.EsitoAutorizzazioneDisposizioneVirtualeDtoFromJSON)({}),
    infoAutenticazioneAttivazione: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
    esitoAttivazione: (0, api_rest_1.EsitoAutorizzazioneDisposizioneAttivazioneJiffyDtoFromJSON)({}),
    disposizioneVirtualeAttivazione: (0, api_rest_1.DisposizioneVirtualeDtoFromJSON)({}),
    infoAutenticazioneDisattiva: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
    listaServiziOperativi: (0, api_rest_1.ListaServiziOperativiOutputDtoFromJSON)({}),
    disposizioneVirtualeDisattiva: (0, api_rest_1.DisposizioneVirtualeDtoFromJSON)({}),
    disposizioneVirtualeRapporti: (0, api_rest_1.DisposizioneVirtualeDtoFromJSON)({}),
    infoAutenticazioneRapporti: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
    esitoRapporti: (0, api_rest_1.EsitoAutorizzazioneDisposizioneVirtualeDtoFromJSON)({}),
    esitoDisattiva: (0, api_rest_1.EsitoAutorizzazioneDisposizioneVirtualeDtoFromJSON)({}),
    soglie: (0, api_rest_1.RecuperaImpostazioniDtoFromJSON)({}),
    erroreSpecifico: undefined,
    form: {
        rapportoSelezionato: (0, api_rest_1.RapportoCompletoDtoFromJSON)({}),
        step: 0,
        stepMassimali: 0,
        accetta: false,
        formSubmitted: false,
        infoRecuperata: false,
        massimaleTransazione: 0,
        massimaleGiornaliero: 0,
        massimaleMensile: 0,
        infoMassimaliRecuperata: false,
        stepAttivazione: 0,
        stepDisattiva: 0,
        stepGestioneRapporti: 0,
        infoRapportiRecuperata: false,
        selectedListaServiziOperativi: []
    }
};
const bancomatPayReducer = (state = initialState, action) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
    const maxMensile = (_c = (_b = (_a = state.soglie) === null || _a === void 0 ? void 0 : _a.rangeSoglie) === null || _b === void 0 ? void 0 : _b.find(soglia => soglia.tipoSoglia === 'M')) === null || _c === void 0 ? void 0 : _c.sogliaMassima;
    const maxGiornaliero = (_f = (_e = (_d = state.soglie) === null || _d === void 0 ? void 0 : _d.rangeSoglie) === null || _e === void 0 ? void 0 : _e.find(soglia => soglia.tipoSoglia === 'G')) === null || _f === void 0 ? void 0 : _f.sogliaMassima;
    const maxTransazione = (_j = (_h = (_g = state.soglie) === null || _g === void 0 ? void 0 : _g.rangeSoglie) === null || _h === void 0 ? void 0 : _h.find(soglia => soglia.tipoSoglia === 'SP')) === null || _j === void 0 ? void 0 : _j.sogliaMassima;
    switch (action.type) {
        case bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_ACCETTATO:
            return {
                ...state,
                form: {
                    ...state.form,
                    accetta: action.payload
                }
            };
        case bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_STEP:
            return {
                ...state,
                form: {
                    ...state.form,
                    step: action.payload
                }
            };
        case bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_STEP_MASSIMALI:
            return {
                ...state,
                form: {
                    ...state.form,
                    stepMassimali: action.payload
                }
            };
        case bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_RESET:
            return {
                ...initialState
            };
        case bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_GET_PARAMETRI_SUCCESS:
            return {
                ...initialState,
                statoUtente: state.statoUtente,
                erroreSpecifico: state.erroreSpecifico,
                parametri: action.payload
            };
        case bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_SET_RAPPORTO_SELEZIONATO:
            return {
                ...state,
                form: {
                    ...state.form,
                    rapportoSelezionato: action.payload
                }
            };
        case bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_GET_STATO_UTENTE_SUCCESS:
            return {
                ...state,
                statoUtente: action.payload
            };
        case bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_ENROLLMENT_SUCCESS:
            return {
                ...state,
                disposizioneVirtuale: action.payload,
                infoDispositiva: initialState.infoDispositiva
            };
        case bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_GET_INFO_DISPOSIZIONE_SUCCESS:
            return {
                ...state,
                infoDispositiva: action.payload,
                esito: initialState.esito,
                form: {
                    ...state.form,
                    infoRecuperata: true
                }
            };
        case bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_AUTORIZZA_SUCCESS:
            if (action.payload.stato === 'OK') {
                return {
                    ...state,
                    esito: action.payload,
                    form: {
                        ...state.form,
                        infoRecuperata: initialState.form.infoRecuperata
                    }
                };
            }
            else {
                return {
                    ...state,
                    infoDispositiva: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoDispositiva, action.payload.informazioniAutenticazioneDispositiva),
                    esito: action.payload
                };
            }
        case bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_ATTIVAZIONE_GET_INFO_AUTENTICAZIONE_SUCCESS:
            return {
                ...state,
                infoAutenticazioneAttivazione: action.payload,
                esitoAttivazione: initialState.esitoAttivazione
            };
        case bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_ATTIVAZIONE_AUTORIZZA_SUCCESS:
            if (action.payload.stato === 'OK') {
                return {
                    ...state,
                    esitoAttivazione: action.payload
                };
            }
            else {
                return {
                    ...state,
                    infoAutenticazioneAttivazione: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoAutenticazioneAttivazione, action.payload.informazioniAutenticazioneDispositiva),
                    esitoAttivazione: action.payload
                };
            }
        case bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_ATTIVAZIONE_SET_STEP:
            return {
                ...state,
                form: {
                    ...state.form,
                    stepAttivazione: action.payload
                }
            };
        case bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_ATTIVAZIONE_CONTROLLO_GENERA_CODICE_SUCCESS:
            return {
                ...state,
                disposizioneVirtualeAttivazione: action.payload
            };
        case bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_DISATTIVA_GET_INFO_AUTENTICAZIONE_SUCCESS:
            return {
                ...state,
                infoAutenticazioneDisattiva: action.payload,
                esitoDisattiva: initialState.esitoDisattiva,
            };
        case bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_DISATTIVA_AUTORIZZA_SUCCESS:
            if (action.payload.stato === 'OK') {
                return {
                    ...state,
                    esitoDisattiva: action.payload
                };
            }
            else {
                return {
                    ...state,
                    infoAutenticazioneDisattiva: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoAutenticazioneDisattiva, action.payload.informazioniAutenticazioneDispositiva),
                    esitoDisattiva: action.payload
                };
            }
        case bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_DISATTIVA_SET_STEP:
            return {
                ...state,
                form: {
                    ...state.form,
                    stepDisattiva: action.payload
                }
            };
        case bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_GET_SERVIZI_OPERATIVI_SUCCESS:
            return {
                ...state,
                listaServiziOperativi: action.payload,
                form: {
                    ...state.form,
                    selectedListaServiziOperativi: action.payload.serviziOperativi
                }
            };
        case bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_DISATTIVA_UTENTE_SUCCESS:
            return {
                ...state,
                disposizioneVirtualeDisattiva: action.payload
            };
        case bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_MASSIMALI_MODIFICA_SUCCESS:
            return {
                ...state,
                disposizioneVirtualeMassimali: action.payload
            };
        case bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_SET_MASSIMALE_TRANSAZIONE:
            return {
                ...state,
                form: {
                    ...state.form,
                    massimaleTransazione: Number(action.payload),
                    massimaleTransazioneDirty: true,
                    massimaleTransazioneError: (0, helpers_1.validationTransazione)(action.payload, maxTransazione || 500)
                }
            };
        case bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_SET_MASSIMALE_GIORNALIERO:
            return {
                ...state,
                form: {
                    ...state.form,
                    massimaleGiornaliero: Number(action.payload),
                    massimaleGiornalieroDirty: true,
                    massimaleGiornalieroError: (0, helpers_1.validationTransazione)(action.payload, maxGiornaliero || 500)
                }
            };
        case bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_SET_MASSIMALE_MENSILE:
            return {
                ...state,
                form: {
                    ...state.form,
                    massimaleMensile: Number(action.payload),
                    massimaleMensileDirty: true,
                    massimaleMensileError: (0, helpers_1.validationTransazione)(action.payload, maxMensile || 1500)
                }
            };
        case bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_MASSIMALI_GET_INFO_DISPOSITIVA_SUCCESS:
            return {
                ...state,
                infoDispositivaMassimali: action.payload,
                esitoMassimali: initialState.esitoMassimali,
                form: {
                    ...state.form,
                    infoMassimaliRecuperata: true
                }
            };
        case bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_MASSIMALI_AUTORIZZA_SUCCESS:
            if (action.payload.stato === 'OK') {
                return {
                    ...state,
                    esitoMassimali: action.payload,
                    form: {
                        ...state.form,
                        infoMassimaliRecuperata: initialState.form.infoMassimaliRecuperata
                    }
                };
            }
            else {
                return {
                    ...state,
                    infoDispositivaMassimali: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoDispositivaMassimali, action.payload.informazioniAutenticazioneDispositiva),
                    esitoMassimali: action.payload
                };
            }
        case bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_MODIFICA_IBAN_CONTROLLO_SUCCESS:
            return {
                ...state,
                disposizioneVirtualeRapporti: action.payload
            };
        case bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_MODIFICA_IBAN_GET_INFO_AUTENTICAZIONE_SUCCESS:
            return {
                ...state,
                infoAutenticazioneRapporti: action.payload,
                esitoRapporti: initialState.esitoRapporti,
                form: {
                    ...state.form,
                    infoRapportiRecuperata: true
                }
            };
        case bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_MODIFICA_IBAN_AUTORIZZA_SUCCESS:
            if (action.payload.stato === 'OK') {
                return {
                    ...state,
                    esitoRapporti: action.payload,
                    form: {
                        ...state.form,
                        infoRapportiRecuperata: initialState.form.infoRapportiRecuperata
                    }
                };
            }
            else {
                return {
                    ...state,
                    infoAutenticazioneRapporti: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoAutenticazioneRapporti, action.payload.informazioniAutenticazioneDispositiva),
                    esitoRapporti: action.payload
                };
            }
        case bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_GESTIONE_RAPPORTI_SET_STEP:
            return {
                ...state,
                form: {
                    ...state.form,
                    stepGestioneRapporti: action.payload
                }
            };
        case bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_GESTIONE_RAPPORTI_SET_DEFAULT_INVIO:
            let item = action.payload;
            item.defaultInvio = !item.defaultInvio;
            let listaSelectedRic = state.form.selectedListaServiziOperativi || [];
            const foundIndexRic = listaSelectedRic === null || listaSelectedRic === void 0 ? void 0 : listaSelectedRic.findIndex((item) => item.iban === action.payload.iban);
            const mappedListRic = listaSelectedRic.map((servizio) => {
                return {
                    ...servizio,
                    defaultInvio: false
                };
            });
            const newListRic = Object.assign([], mappedListRic, { [foundIndexRic]: item });
            return {
                ...state,
                form: {
                    ...state.form,
                    selectedListaServiziOperativi: newListRic
                }
            };
        case bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_GESTIONE_RAPPORTI_SET_SELECTED_INVIO:
            {
                let item = action.payload;
                item.selectedInvio = !item.selectedInvio;
                item.defaultInvio = !item.selectedInvio ? false : item.defaultInvio;
                let listaSelectedInv = state.form.selectedListaServiziOperativi || [];
                const foundIndexInv = listaSelectedInv === null || listaSelectedInv === void 0 ? void 0 : listaSelectedInv.findIndex((item) => item.iban === action.payload.iban);
                const newListInv = Object.assign([], listaSelectedInv, { [foundIndexInv]: item });
                return {
                    ...state,
                    form: {
                        ...state.form,
                        selectedListaServiziOperativi: newListInv
                    }
                };
            }
        case bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_GET_SOGLIE_SUCCESS:
            return {
                ...state,
                form: {
                    ...state.form,
                    massimaleGiornaliero: (_m = (_l = (_k = action.payload) === null || _k === void 0 ? void 0 : _k.rangeSoglie) === null || _l === void 0 ? void 0 : _l.find(soglia => soglia.tipoSoglia === 'G')) === null || _m === void 0 ? void 0 : _m.sogliaImpostata,
                    massimaleMensile: (_q = (_p = (_o = action.payload) === null || _o === void 0 ? void 0 : _o.rangeSoglie) === null || _p === void 0 ? void 0 : _p.find(soglia => soglia.tipoSoglia === 'M')) === null || _q === void 0 ? void 0 : _q.sogliaImpostata,
                    massimaleTransazione: (_t = (_s = (_r = action.payload) === null || _r === void 0 ? void 0 : _r.rangeSoglie) === null || _s === void 0 ? void 0 : _s.find(soglia => soglia.tipoSoglia === 'SP')) === null || _t === void 0 ? void 0 : _t.sogliaImpostata
                },
                soglie: action.payload
            };
        case bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_SET_ERRORE_SPECIFICO:
            return {
                ...state,
                erroreSpecifico: action.payload
            };
        case bancomatPay_types_1.EReduxBancomatPayActionTypes.BANCOMAT_PAY_RESET_ERRORE_SPECIFICO:
            return {
                ...state,
                erroreSpecifico: initialState.erroreSpecifico
            };
        case __1.EReduxActionTypes.RESET_ALL: // action invocata dalla logout
            return {
                ...initialState
            };
        default:
            return state;
    }
};
exports.bancomatPayReducer = bancomatPayReducer;
