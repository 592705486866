"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DatiIntradayTitoloInDtoToJSON = exports.DatiIntradayTitoloInDtoFromJSONTyped = exports.DatiIntradayTitoloInDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function DatiIntradayTitoloInDtoFromJSON(json) {
    return DatiIntradayTitoloInDtoFromJSONTyped(json, false);
}
exports.DatiIntradayTitoloInDtoFromJSON = DatiIntradayTitoloInDtoFromJSON;
function DatiIntradayTitoloInDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'mercato': json['mercato'],
        'codiceTitolo': json['codiceTitolo'],
        'dataIniziale': !runtime_1.exists(json, 'dataIniziale') ? undefined : (new Date(json['dataIniziale'])),
        'dataFinale': !runtime_1.exists(json, 'dataFinale') ? undefined : (new Date(json['dataFinale'])),
    };
}
exports.DatiIntradayTitoloInDtoFromJSONTyped = DatiIntradayTitoloInDtoFromJSONTyped;
function DatiIntradayTitoloInDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'mercato': value.mercato,
        'codiceTitolo': value.codiceTitolo,
        'dataIniziale': value.dataIniziale === undefined ? undefined : (value.dataIniziale.toISOString()),
        'dataFinale': value.dataFinale === undefined ? undefined : (value.dataFinale.toISOString()),
    };
}
exports.DatiIntradayTitoloInDtoToJSON = DatiIntradayTitoloInDtoToJSON;
