"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormBozzeDtoFromJSONTyped = void 0;
const helpers_1 = require("../helpers");
function FormBozzeDtoFromJSONTyped(json) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        rapportoSelezionato: !(0, helpers_1.exists)(json, 'rapportoSelezionato') ? undefined : json.rapportoSelezionato,
        descrizione: !(0, helpers_1.exists)(json, 'descrizione') ? undefined : json.descrizione,
        tipologia: !(0, helpers_1.exists)(json, 'tipologia') ? undefined : json.tipologia,
        tuttiRapporti: !(0, helpers_1.exists)(json, 'tuttiRapporti') ? true : json.tuttiRapporti,
        accordionFiltriOpened: !(0, helpers_1.exists)(json, 'accordionFiltriOpened') ? false : json.accordionFiltriOpened
    };
}
exports.FormBozzeDtoFromJSONTyped = FormBozzeDtoFromJSONTyped;
