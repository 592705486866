"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListiniTimeDepositDtoToJSON = exports.ListiniTimeDepositDtoFromJSONTyped = exports.ListiniTimeDepositDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ListiniTimeDepositDtoFromJSON(json) {
    return ListiniTimeDepositDtoFromJSONTyped(json, false);
}
exports.ListiniTimeDepositDtoFromJSON = ListiniTimeDepositDtoFromJSON;
function ListiniTimeDepositDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codiceListino': !runtime_1.exists(json, 'codiceListino') ? undefined : json['codiceListino'],
        'descrizione': !runtime_1.exists(json, 'descrizione') ? undefined : json['descrizione'],
        'dataDecorrenza': !runtime_1.exists(json, 'dataDecorrenza') ? undefined : (new Date(json['dataDecorrenza'])),
        'dataScadenza': !runtime_1.exists(json, 'dataScadenza') ? undefined : (new Date(json['dataScadenza'])),
        'rangeTassi': !runtime_1.exists(json, 'rangeTassi') ? undefined : _1.RangeTassiDtoFromJSON(json['rangeTassi']),
        'rangeImporti': !runtime_1.exists(json, 'rangeImporti') ? undefined : _1.RangeImportiFromJSON(json['rangeImporti']),
        'tipoTasso': !runtime_1.exists(json, 'tipoTasso') ? undefined : json['tipoTasso'],
        'tipoInteressi': !runtime_1.exists(json, 'tipoInteressi') ? undefined : json['tipoInteressi'],
        'importoMinimo': !runtime_1.exists(json, 'importoMinimo') ? undefined : json['importoMinimo'],
        'importoMassimo': !runtime_1.exists(json, 'importoMassimo') ? undefined : json['importoMassimo'],
        'importoMultiplo': !runtime_1.exists(json, 'importoMultiplo') ? undefined : json['importoMultiplo'],
        'importoMassimoCliente': !runtime_1.exists(json, 'importoMassimoCliente') ? undefined : json['importoMassimoCliente'],
        'tipoOperazione': !runtime_1.exists(json, 'tipoOperazione') ? undefined : json['tipoOperazione'],
        'durataOperazione': !runtime_1.exists(json, 'durataOperazione') ? undefined : json['durataOperazione'],
        'commissione': !runtime_1.exists(json, 'commissione') ? undefined : _1.CommissioneDtoFromJSON(json['commissione']),
        'penale': !runtime_1.exists(json, 'penale') ? undefined : _1.PenaleDtoFromJSON(json['penale']),
        'ritenutaFiscale': !runtime_1.exists(json, 'ritenutaFiscale') ? undefined : json['ritenutaFiscale'],
        'divisoreAnnuo': !runtime_1.exists(json, 'divisoreAnnuo') ? undefined : json['divisoreAnnuo'],
    };
}
exports.ListiniTimeDepositDtoFromJSONTyped = ListiniTimeDepositDtoFromJSONTyped;
function ListiniTimeDepositDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codiceListino': value.codiceListino,
        'descrizione': value.descrizione,
        'dataDecorrenza': value.dataDecorrenza === undefined ? undefined : (value.dataDecorrenza.toISOString()),
        'dataScadenza': value.dataScadenza === undefined ? undefined : (value.dataScadenza.toISOString()),
        'rangeTassi': _1.RangeTassiDtoToJSON(value.rangeTassi),
        'rangeImporti': _1.RangeImportiToJSON(value.rangeImporti),
        'tipoTasso': value.tipoTasso,
        'tipoInteressi': value.tipoInteressi,
        'importoMinimo': value.importoMinimo,
        'importoMassimo': value.importoMassimo,
        'importoMultiplo': value.importoMultiplo,
        'importoMassimoCliente': value.importoMassimoCliente,
        'tipoOperazione': value.tipoOperazione,
        'durataOperazione': value.durataOperazione,
        'commissione': _1.CommissioneDtoToJSON(value.commissione),
        'penale': _1.PenaleDtoToJSON(value.penale),
        'ritenutaFiscale': value.ritenutaFiscale,
        'divisoreAnnuo': value.divisoreAnnuo,
    };
}
exports.ListiniTimeDepositDtoToJSON = ListiniTimeDepositDtoToJSON;
