"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FondoDtoToJSON = exports.FondoDtoFromJSONTyped = exports.FondoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function FondoDtoFromJSON(json) {
    return FondoDtoFromJSONTyped(json, false);
}
exports.FondoDtoFromJSON = FondoDtoFromJSON;
function FondoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codice': !runtime_1.exists(json, 'codice') ? undefined : json['codice'],
        'controvalore': !runtime_1.exists(json, 'controvalore') ? undefined : json['controvalore'],
        'data': !runtime_1.exists(json, 'data') ? undefined : (new Date(json['data'])),
        'valoreQuota': !runtime_1.exists(json, 'valoreQuota') ? undefined : json['valoreQuota'],
        'numeroQuote': !runtime_1.exists(json, 'numeroQuote') ? undefined : json['numeroQuote'],
        'descrizione': !runtime_1.exists(json, 'descrizione') ? undefined : json['descrizione'],
        'numeroQuoteInvestimentoConfermato': !runtime_1.exists(json, 'numeroQuoteInvestimentoConfermato') ? undefined : json['numeroQuoteInvestimentoConfermato'],
        'numeroQuoteInvestimentoInCorso': !runtime_1.exists(json, 'numeroQuoteInvestimentoInCorso') ? undefined : json['numeroQuoteInvestimentoInCorso'],
        'numeroQuoteRiscattoInCorso': !runtime_1.exists(json, 'numeroQuoteRiscattoInCorso') ? undefined : json['numeroQuoteRiscattoInCorso'],
        'numeroQuoteSwitchOutInCorso': !runtime_1.exists(json, 'numeroQuoteSwitchOutInCorso') ? undefined : json['numeroQuoteSwitchOutInCorso'],
        'controvaloreInvestimentoConfermato': !runtime_1.exists(json, 'controvaloreInvestimentoConfermato') ? undefined : json['controvaloreInvestimentoConfermato'],
        'controvaloreInvestimentoInCorso': !runtime_1.exists(json, 'controvaloreInvestimentoInCorso') ? undefined : json['controvaloreInvestimentoInCorso'],
        'controvaloreRiscattoInCorso': !runtime_1.exists(json, 'controvaloreRiscattoInCorso') ? undefined : json['controvaloreRiscattoInCorso'],
        'controvaloreSwitchOutInCorso': !runtime_1.exists(json, 'controvaloreSwitchOutInCorso') ? undefined : json['controvaloreSwitchOutInCorso'],
    };
}
exports.FondoDtoFromJSONTyped = FondoDtoFromJSONTyped;
function FondoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codice': value.codice,
        'controvalore': value.controvalore,
        'data': value.data === undefined ? undefined : (value.data.toISOString()),
        'valoreQuota': value.valoreQuota,
        'numeroQuote': value.numeroQuote,
        'descrizione': value.descrizione,
        'numeroQuoteInvestimentoConfermato': value.numeroQuoteInvestimentoConfermato,
        'numeroQuoteInvestimentoInCorso': value.numeroQuoteInvestimentoInCorso,
        'numeroQuoteRiscattoInCorso': value.numeroQuoteRiscattoInCorso,
        'numeroQuoteSwitchOutInCorso': value.numeroQuoteSwitchOutInCorso,
        'controvaloreInvestimentoConfermato': value.controvaloreInvestimentoConfermato,
        'controvaloreInvestimentoInCorso': value.controvaloreInvestimentoInCorso,
        'controvaloreRiscattoInCorso': value.controvaloreRiscattoInCorso,
        'controvaloreSwitchOutInCorso': value.controvaloreSwitchOutInCorso,
    };
}
exports.FondoDtoToJSON = FondoDtoToJSON;
