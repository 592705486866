"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportElencoBonificiRipetitiviTypeEnum = exports.BonificoRipetitivoControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class BonificoRipetitivoControllerApi extends runtime.BaseAPI {
    /**
     * Autorizzazione di una disposizione di bonifico
     */
    autorizzaBonificoRipetitivoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling autorizzaBonificoRipetitivo.');
            }
            if (requestParameters.credenzialeDispositivaDto === null || requestParameters.credenzialeDispositivaDto === undefined) {
                throw new runtime.RequiredError('credenzialeDispositivaDto', 'Required parameter requestParameters.credenzialeDispositivaDto was null or undefined when calling autorizzaBonificoRipetitivo.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/bonificoripetitivo/autorizza/{idDispositiva}`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CredenzialeDispositivaDtoToJSON(requestParameters.credenzialeDispositivaDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Autorizzazione di una disposizione di bonifico
     */
    autorizzaBonificoRipetitivo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.autorizzaBonificoRipetitivoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Autorizzazione di una disposizione di bonifico
     */
    autorizzaModificaBonificoRipetitivoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling autorizzaModificaBonificoRipetitivo.');
            }
            if (requestParameters.credenzialeDispositivaDto === null || requestParameters.credenzialeDispositivaDto === undefined) {
                throw new runtime.RequiredError('credenzialeDispositivaDto', 'Required parameter requestParameters.credenzialeDispositivaDto was null or undefined when calling autorizzaModificaBonificoRipetitivo.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/bonificoripetitivo/modifica/autorizza/{idDispositiva}`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CredenzialeDispositivaDtoToJSON(requestParameters.credenzialeDispositivaDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Autorizzazione di una disposizione di bonifico
     */
    autorizzaModificaBonificoRipetitivo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.autorizzaModificaBonificoRipetitivoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce una bozza dato l\'id per bonifico Ripetitivo
     */
    bozzaBonificoRipetitivoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling bozzaBonificoRipetitivo.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/bonificoripetitivo/bozze/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.RipetitivoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce una bozza dato l\'id per bonifico Ripetitivo
     */
    bozzaBonificoRipetitivo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.bozzaBonificoRipetitivoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce le bozze per bonifico Ripetitivo
     */
    bozzeBonificoRipetitivoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.bozzeInDto === null || requestParameters.bozzeInDto === undefined) {
                throw new runtime.RequiredError('bozzeInDto', 'Required parameter requestParameters.bozzeInDto was null or undefined when calling bozzeBonificoRipetitivo.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/bonificoripetitivo/bozze`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.BozzeInDtoToJSON(requestParameters.bozzeInDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.RipetitiviDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce le bozze per bonifico Ripetitivo
     */
    bozzeBonificoRipetitivo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.bozzeBonificoRipetitivoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Esegue il controllo di validità di un nuovo bonifico ripetitivi in base ai valori specificati nell\'oggetto di richiesta
     */
    controlloBonificoRipetitivoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.ripetitivoDto === null || requestParameters.ripetitivoDto === undefined) {
                throw new runtime.RequiredError('ripetitivoDto', 'Required parameter requestParameters.ripetitivoDto was null or undefined when calling controlloBonificoRipetitivo.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/bonificoripetitivo/controllo`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.RipetitivoDtoToJSON(requestParameters.ripetitivoDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.RipetitivoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Esegue il controllo di validità di un nuovo bonifico ripetitivi in base ai valori specificati nell\'oggetto di richiesta
     */
    controlloBonificoRipetitivo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.controlloBonificoRipetitivoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Esegue il controllo della modifica di un ripetitivo.
     */
    controlloModificaBonificoRipetitivoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.modificaRequestDto === null || requestParameters.modificaRequestDto === undefined) {
                throw new runtime.RequiredError('modificaRequestDto', 'Required parameter requestParameters.modificaRequestDto was null or undefined when calling controlloModificaBonificoRipetitivo.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/bonificoripetitivo/modifica/controllo`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ModificaRequestDtoToJSON(requestParameters.modificaRequestDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.RipetitivoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Esegue il controllo della modifica di un ripetitivo.
     */
    controlloModificaBonificoRipetitivo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.controlloModificaBonificoRipetitivoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce il dettaglio di un bonifico in base all\'id indicato come parametro
     */
    dettaglioBonificoRipetitivoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling dettaglioBonificoRipetitivo.');
            }
            if (requestParameters.codiceRapporto === null || requestParameters.codiceRapporto === undefined) {
                throw new runtime.RequiredError('codiceRapporto', 'Required parameter requestParameters.codiceRapporto was null or undefined when calling dettaglioBonificoRipetitivo.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/bonificoripetitivo/{codiceRapporto}/lista/{idDispositiva}`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))).replace(`{${"codiceRapporto"}}`, encodeURIComponent(String(requestParameters.codiceRapporto))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.RipetitivoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce il dettaglio di un bonifico in base all\'id indicato come parametro
     */
    dettaglioBonificoRipetitivo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.dettaglioBonificoRipetitivoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Export in pdf del dettaglio del bonifico ripetitivo
     */
    dettaglioBonificoRipetitivoPdfRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.ripetitivoDto === null || requestParameters.ripetitivoDto === undefined) {
                throw new runtime.RequiredError('ripetitivoDto', 'Required parameter requestParameters.ripetitivoDto was null or undefined when calling dettaglioBonificoRipetitivoPdf.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/bonificoripetitivo/dettaglio/export/pdf`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.RipetitivoDtoToJSON(requestParameters.ripetitivoDto),
            });
            return new runtime.BlobApiResponse(response);
        });
    }
    /**
     * Export in pdf del dettaglio del bonifico ripetitivo
     */
    dettaglioBonificoRipetitivoPdf(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.dettaglioBonificoRipetitivoPdfRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Eliminazione di una bozza dato l\'id
     */
    eliminaBozzaBonificoRipetitivoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling eliminaBozzaBonificoRipetitivo.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/bonificoripetitivo/bozze/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Eliminazione di una bozza dato l\'id
     */
    eliminaBozzaBonificoRipetitivo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.eliminaBozzaBonificoRipetitivoRaw(requestParameters);
        });
    }
    /**
     * Export in pdf o excel Elenco Bonifici Ripetitivi in base ai criteri inviati per il rapporto indicato
     */
    exportElencoBonificiRipetitiviRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.type === null || requestParameters.type === undefined) {
                throw new runtime.RequiredError('type', 'Required parameter requestParameters.type was null or undefined when calling exportElencoBonificiRipetitivi.');
            }
            if (requestParameters.codiceRapporto === null || requestParameters.codiceRapporto === undefined) {
                throw new runtime.RequiredError('codiceRapporto', 'Required parameter requestParameters.codiceRapporto was null or undefined when calling exportElencoBonificiRipetitivi.');
            }
            if (requestParameters.filtroListaBonificiPeriodiciDto === null || requestParameters.filtroListaBonificiPeriodiciDto === undefined) {
                throw new runtime.RequiredError('filtroListaBonificiPeriodiciDto', 'Required parameter requestParameters.filtroListaBonificiPeriodiciDto was null or undefined when calling exportElencoBonificiRipetitivi.');
            }
            const queryParameters = {};
            if (requestParameters.codiceRapporto !== undefined) {
                queryParameters['codiceRapporto'] = requestParameters.codiceRapporto;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/bonificoripetitivo/export/{type}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.FiltroListaBonificiPeriodiciDtoToJSON(requestParameters.filtroListaBonificiPeriodiciDto),
            });
            return new runtime.BlobApiResponse(response);
        });
    }
    /**
     * Export in pdf o excel Elenco Bonifici Ripetitivi in base ai criteri inviati per il rapporto indicato
     */
    exportElencoBonificiRipetitivi(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.exportElencoBonificiRipetitiviRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva
     */
    informazioniAutenticazioneBonificoRipetitivoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling informazioniAutenticazioneBonificoRipetitivo.');
            }
            const queryParameters = {};
            if (requestParameters.chiamataUscente !== undefined) {
                queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
            }
            if (requestParameters.eseguiInit !== undefined) {
                queryParameters['eseguiInit'] = requestParameters.eseguiInit;
            }
            if (requestParameters.offline !== undefined) {
                queryParameters['offline'] = requestParameters.offline;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/bonificoripetitivo/infoautenticazione/{idDispositiva}`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva
     */
    informazioniAutenticazioneBonificoRipetitivo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.informazioniAutenticazioneBonificoRipetitivoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva
     */
    informazioniAutenticazioneModificaRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling informazioniAutenticazioneModifica.');
            }
            const queryParameters = {};
            if (requestParameters.chiamataUscente !== undefined) {
                queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
            }
            if (requestParameters.eseguiInit !== undefined) {
                queryParameters['eseguiInit'] = requestParameters.eseguiInit;
            }
            if (requestParameters.offline !== undefined) {
                queryParameters['offline'] = requestParameters.offline;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/bonificoripetitivo/modifica/infoautenticazione/{idDispositiva}`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva
     */
    informazioniAutenticazioneModifica(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.informazioniAutenticazioneModificaRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva per la revoca
     */
    informazioniAutenticazioneRevocaBonificoRipetitivoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling informazioniAutenticazioneRevocaBonificoRipetitivo.');
            }
            const queryParameters = {};
            if (requestParameters.chiamataUscente !== undefined) {
                queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
            }
            if (requestParameters.eseguiInit !== undefined) {
                queryParameters['eseguiInit'] = requestParameters.eseguiInit;
            }
            if (requestParameters.offline !== undefined) {
                queryParameters['offline'] = requestParameters.offline;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/bonificoripetitivo/infoautenticazione/{idDispositiva}/revoca`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva per la revoca
     */
    informazioniAutenticazioneRevocaBonificoRipetitivo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.informazioniAutenticazioneRevocaBonificoRipetitivoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva per la revoca
     */
    informazioniAutenticazioneRiattivaBonificoRipetitivoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling informazioniAutenticazioneRiattivaBonificoRipetitivo.');
            }
            const queryParameters = {};
            if (requestParameters.chiamataUscente !== undefined) {
                queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
            }
            if (requestParameters.eseguiInit !== undefined) {
                queryParameters['eseguiInit'] = requestParameters.eseguiInit;
            }
            if (requestParameters.offline !== undefined) {
                queryParameters['offline'] = requestParameters.offline;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/bonificoripetitivo/infoautenticazione/{idDispositiva}/riattiva`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva per la revoca
     */
    informazioniAutenticazioneRiattivaBonificoRipetitivo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.informazioniAutenticazioneRiattivaBonificoRipetitivoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva per la revoca
     */
    informazioniAutenticazioneSospendiBonificoRipetitivoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling informazioniAutenticazioneSospendiBonificoRipetitivo.');
            }
            const queryParameters = {};
            if (requestParameters.chiamataUscente !== undefined) {
                queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
            }
            if (requestParameters.eseguiInit !== undefined) {
                queryParameters['eseguiInit'] = requestParameters.eseguiInit;
            }
            if (requestParameters.offline !== undefined) {
                queryParameters['offline'] = requestParameters.offline;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/bonificoripetitivo/infoautenticazione/{idDispositiva}/sospendi`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva per la revoca
     */
    informazioniAutenticazioneSospendiBonificoRipetitivo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.informazioniAutenticazioneSospendiBonificoRipetitivoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce una lista di bonifici ripetitivi inviati
     */
    listaBonificiRipetitiviRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.codiceRapporto === null || requestParameters.codiceRapporto === undefined) {
                throw new runtime.RequiredError('codiceRapporto', 'Required parameter requestParameters.codiceRapporto was null or undefined when calling listaBonificiRipetitivi.');
            }
            if (requestParameters.filtroListaBonificiPeriodiciDto === null || requestParameters.filtroListaBonificiPeriodiciDto === undefined) {
                throw new runtime.RequiredError('filtroListaBonificiPeriodiciDto', 'Required parameter requestParameters.filtroListaBonificiPeriodiciDto was null or undefined when calling listaBonificiRipetitivi.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/bonificoripetitivo/{codiceRapporto}/lista`.replace(`{${"codiceRapporto"}}`, encodeURIComponent(String(requestParameters.codiceRapporto))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.FiltroListaBonificiPeriodiciDtoToJSON(requestParameters.filtroListaBonificiPeriodiciDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.RipetitiviDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce una lista di bonifici ripetitivi inviati
     */
    listaBonificiRipetitivi(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.listaBonificiRipetitiviRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce le operazioni per bonifico Ripetitivo
     */
    operazioniOneClickBonificoRipetitivoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.operazioniOneClickInputDto === null || requestParameters.operazioniOneClickInputDto === undefined) {
                throw new runtime.RequiredError('operazioniOneClickInputDto', 'Required parameter requestParameters.operazioniOneClickInputDto was null or undefined when calling operazioniOneClickBonificoRipetitivo.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/bonificoripetitivo/oneclick`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.OperazioniOneClickInputDtoToJSON(requestParameters.operazioniOneClickInputDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.OperazioniOneClickDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce le operazioni per bonifico Ripetitivo
     */
    operazioniOneClickBonificoRipetitivo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.operazioniOneClickBonificoRipetitivoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Lettura parametri bonifici Ripetitivi
     */
    parametriBonificoRipetitivoRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/bonificoripetitivo/parametri`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ParametriBonificoRipetitivoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Lettura parametri bonifici Ripetitivi
     */
    parametriBonificoRipetitivo() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.parametriBonificoRipetitivoRaw();
            return yield response.value();
        });
    }
    /**
     * Autorizzazione di una disposizione di revoca bonifico
     */
    revocaBonificoRipetitivoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.ripetitivoRevocaRequestIn === null || requestParameters.ripetitivoRevocaRequestIn === undefined) {
                throw new runtime.RequiredError('ripetitivoRevocaRequestIn', 'Required parameter requestParameters.ripetitivoRevocaRequestIn was null or undefined when calling revocaBonificoRipetitivo.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/bonificoripetitivo/autorizza/revoca`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.RipetitivoRevocaRequestInToJSON(requestParameters.ripetitivoRevocaRequestIn),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Autorizzazione di una disposizione di revoca bonifico
     */
    revocaBonificoRipetitivo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.revocaBonificoRipetitivoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Autorizzazione di una disposizione di riattiva bonifico ripetitivo
     */
    riattivaBonificoRipetitivoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.ripetitivoRiattivaRequestIn === null || requestParameters.ripetitivoRiattivaRequestIn === undefined) {
                throw new runtime.RequiredError('ripetitivoRiattivaRequestIn', 'Required parameter requestParameters.ripetitivoRiattivaRequestIn was null or undefined when calling riattivaBonificoRipetitivo.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/bonificoripetitivo/autorizza/riattiva`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.RipetitivoRiattivaRequestInToJSON(requestParameters.ripetitivoRiattivaRequestIn),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Autorizzazione di una disposizione di riattiva bonifico ripetitivo
     */
    riattivaBonificoRipetitivo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.riattivaBonificoRipetitivoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Salva una bozza per bonifico Ripetitivo
     */
    salvaBozzaBonificoRipetitivoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.ripetitivoDto === null || requestParameters.ripetitivoDto === undefined) {
                throw new runtime.RequiredError('ripetitivoDto', 'Required parameter requestParameters.ripetitivoDto was null or undefined when calling salvaBozzaBonificoRipetitivo.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/bonificoripetitivo/salvabozza`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.RipetitivoDtoToJSON(requestParameters.ripetitivoDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.RipetitivoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Salva una bozza per bonifico Ripetitivo
     */
    salvaBozzaBonificoRipetitivo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.salvaBozzaBonificoRipetitivoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Autorizzazione di una disposizione di sospendi bonifico ripetitivo
     */
    sospendiBonificoRipetitivoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.ripetitivoSospendiRequestIn === null || requestParameters.ripetitivoSospendiRequestIn === undefined) {
                throw new runtime.RequiredError('ripetitivoSospendiRequestIn', 'Required parameter requestParameters.ripetitivoSospendiRequestIn was null or undefined when calling sospendiBonificoRipetitivo.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/bonificoripetitivo/autorizza/sospendi`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.RipetitivoSospendiRequestInToJSON(requestParameters.ripetitivoSospendiRequestIn),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.EsitoAutorizzazioneDisposizioneBonificoRipetitivoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Autorizzazione di una disposizione di sospendi bonifico ripetitivo
     */
    sospendiBonificoRipetitivo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.sospendiBonificoRipetitivoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Modifica di una bozza dato l\'id per bonifico Ripetitivo
     */
    updateBozzaBonificoRipetitivoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling updateBozzaBonificoRipetitivo.');
            }
            if (requestParameters.ripetitivoDto === null || requestParameters.ripetitivoDto === undefined) {
                throw new runtime.RequiredError('ripetitivoDto', 'Required parameter requestParameters.ripetitivoDto was null or undefined when calling updateBozzaBonificoRipetitivo.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/bonificoripetitivo/bozze/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.RipetitivoDtoToJSON(requestParameters.ripetitivoDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.RipetitivoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Modifica di una bozza dato l\'id per bonifico Ripetitivo
     */
    updateBozzaBonificoRipetitivo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateBozzaBonificoRipetitivoRaw(requestParameters);
            return yield response.value();
        });
    }
}
exports.BonificoRipetitivoControllerApi = BonificoRipetitivoControllerApi;
/**
    * @export
    * @enum {string}
    */
var ExportElencoBonificiRipetitiviTypeEnum;
(function (ExportElencoBonificiRipetitiviTypeEnum) {
    ExportElencoBonificiRipetitiviTypeEnum["pdf"] = "pdf";
    ExportElencoBonificiRipetitiviTypeEnum["excel"] = "excel";
})(ExportElencoBonificiRipetitiviTypeEnum = exports.ExportElencoBonificiRipetitiviTypeEnum || (exports.ExportElencoBonificiRipetitiviTypeEnum = {}));
