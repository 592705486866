"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssicurazioneControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class AssicurazioneControllerApi extends runtime.BaseAPI {
    /**
     * Export in PDF del dettaglio polizza in base ai criteri inviati per il rapporto indicato
     */
    exportDettaglioPolizzaRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.assicurazioneDto === null || requestParameters.assicurazioneDto === undefined) {
                throw new runtime.RequiredError('assicurazioneDto', 'Required parameter requestParameters.assicurazioneDto was null or undefined when calling exportDettaglioPolizza.');
            }
            const queryParameters = {};
            if (requestParameters.visualizzaGrafico !== undefined) {
                queryParameters['visualizzaGrafico'] = requestParameters.visualizzaGrafico;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/assicurazione/export/dettaglio/pdf`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.AssicurazioneDtoToJSON(requestParameters.assicurazioneDto),
            });
            return new runtime.BlobApiResponse(response);
        });
    }
    /**
     * Export in PDF del dettaglio polizza in base ai criteri inviati per il rapporto indicato
     */
    exportDettaglioPolizza(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.exportDettaglioPolizzaRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Lista assicurazioni
     */
    getAndFilterAssicurazioniRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/assicurazione`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.AssicurazioneDtoFromJSON));
        });
    }
    /**
     * Lista assicurazioni
     */
    getAndFilterAssicurazioni() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getAndFilterAssicurazioniRaw();
            return yield response.value();
        });
    }
    /**
     * Lista Polizze Investimento
     */
    getAndFilterPolizzeInvestimentoRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/assicurazione/polizze`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.AssicurazioneDtoFromJSON));
        });
    }
    /**
     * Lista Polizze Investimento
     */
    getAndFilterPolizzeInvestimento() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getAndFilterPolizzeInvestimentoRaw();
            return yield response.value();
        });
    }
    /**
     * Esegue il controllo di validità di un nuovo assicurazione in base ai valori specificati nell\'oggetto di richiesta
     */
    getDettaglioAssicurazioniRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.codiceRapporto === null || requestParameters.codiceRapporto === undefined) {
                throw new runtime.RequiredError('codiceRapporto', 'Required parameter requestParameters.codiceRapporto was null or undefined when calling getDettaglioAssicurazioni.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/assicurazione/dettaglio/{codiceRapporto}`.replace(`{${"codiceRapporto"}}`, encodeURIComponent(String(requestParameters.codiceRapporto))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.AssicurazioneDtoFromJSON(jsonValue));
        });
    }
    /**
     * Esegue il controllo di validità di un nuovo assicurazione in base ai valori specificati nell\'oggetto di richiesta
     */
    getDettaglioAssicurazioni(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getDettaglioAssicurazioniRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Lettura parametri assicurazione
     */
    parametriAssicurazioniRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/assicurazione/parametri`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ParametriAssicurazioniDtoFromJSON(jsonValue));
        });
    }
    /**
     * Lettura parametri assicurazione
     */
    parametriAssicurazioni() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.parametriAssicurazioniRaw();
            return yield response.value();
        });
    }
}
exports.AssicurazioneControllerApi = AssicurazioneControllerApi;
