import { ASPSPDto, AspspProductDto, bancheDisponibiliPSD2, consensoRichiestoEnrollmentPSD2, dettaglioBancaEnrollmentPSD2, elencoProdottiEnrollmentPSD2, enrollmentActivitiesPSD2, EnrollmentActivityDto, enrollmentAddIbanEnrollmentPSD2, enrollmentPSD2Completed, enrollmentPSD2PollingActive, enrollmentPsd2PollingModalShown, enrollmentPSD2Reset, enrollmentPSD2ResetForm, enrollmentRemoveIbanEnrollmentPSD2, enrollmentSetBancaSelezionataEnrollmentPSD2, enrollmentSetConsensoEnrollmentPSD2, enrollmentSetFormSubmitted, enrollmentSetProdottoEnrollmentPSD2, enrollmentSetStepEnrollmentPSD2, enrollmentSetUsernameEnrollmentPSD2, enrollmentStatusPSD2, enrollmentStatusPSD2FromConsentRenew, enrollmentUpdateIbanEnrollmentPSD2, formEnrollmentPSD2, FormEnrollmentPSD2Dto, formPsd2EnrollmentValid, loaderActive, psd2AISPAbilitato, psd2ResponseStatus, pushPopUp, startEnrollmentPSD2, webViewLinkConsentRenewPSD2, webViewLinkEnrollmentPSD2 } from '@sparkasse/commons';
import closeIcon from "assets/icons/navigazione/chiudi.svg";
import ButtonText2 from "component/buttonText2/buttonText2.component";
import BankIcon from "component/Icon/icon.component";
import InputRadioGroup from "component/inputRadioGroup/inputRadioGroup.component";
import InputText from "component/inputText/inputText.component";
import PopUpModal from 'component/popUpModal/popUpModal.component';
import ThirdPartyWindow from 'component/thirdPartyWindow/thirdPartyWindow.component';
import { base64ToImageSrc, getQueryParams, placeholderForNullOrUndefined, sendEventToMatomo } from "helpers/utilities";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { EnrollmentStatusDto } from '../../../../commons/node_modules/@sparkasse/api-rest/dist';
import { AppState } from '../../reducers';

let pollingTimeout: NodeJS.Timeout;

interface ListaBancheProps {
  bancheDisponibili: ASPSPDto[] | undefined
  enrollmentActivitiesPSD2: Function
  enrollmentSetBancaSelezionataEnrollmentPSD2: Function
  loaderActive: boolean
}

const ListaBanche = (props: ListaBancheProps) => {

  const { t } = useTranslation();

  const {
    bancheDisponibili,
    enrollmentActivitiesPSD2,
    enrollmentSetBancaSelezionataEnrollmentPSD2,
    loaderActive
  } = props;

  const [filtroFunzionalita, setFiltroFunzionalita] = useState("");

  const onBancaSelected = (banca: ASPSPDto) => {
    if (loaderActive) {
      return;
    }

    enrollmentSetBancaSelezionataEnrollmentPSD2(banca);
    enrollmentActivitiesPSD2();
  };

  return <>

    <span className="modal-body-text">{t('psd2Attiva.descrizione')}</span>

    <InputText
      descrizione={t("psd2Attiva.cerca")}
      className="cerca-banca-testo"
      img={!!filtroFunzionalita ? closeIcon : undefined}
      icon={!filtroFunzionalita ? "navigazione-search" : undefined}
      value={filtroFunzionalita}
      onChange={setFiltroFunzionalita}
      imgOnClick={() => setFiltroFunzionalita("")}
    />

    <div className="lista-banche">

      {bancheDisponibili
        ?.filter(item => (
          !filtroFunzionalita || (item.name || "").toLowerCase().indexOf(filtroFunzionalita.toLowerCase()) >= 0
        ))
        .map((item, i) => (
          <div
            key={`lpi-${i}`}
            className={`lista-banche-item general-button ${loaderActive ? 'general-button-disabled' : ''}`}
            onClick={() => onBancaSelected(item)}
            title={item.name}
          >
            <div className="lista-banche-item-name">
              {item.iconImage64
                ? <img className="bank-img" src={base64ToImageSrc(item.iconImage64)} />
                : <BankIcon title="empty" className="bank-img" />
              }
              <span>{item.name || placeholderForNullOrUndefined}</span>
            </div>
            <div className="lista-banche-item-star">
              <BankIcon
                title={"freccetta-destra"}
                red
                height={24}
                width={24}
              />
            </div>
          </div>
        ))
      }

      {bancheDisponibili == null &&
        <Skeleton height={40} count={6} style={{ marginTop: 4, borderRadius: 0 }} />
      }

    </div>

  </>;
};

interface ConfermaBancaProps {
  form: FormEnrollmentPSD2Dto
  elencoProdotti: AspspProductDto[] | undefined
  consensoRichiesto: boolean
  dettaglioBanca: EnrollmentActivityDto | undefined
  enrollmentSetConsensoEnrollmentPSD2: Function
  enrollmentSetProdottoEnrollmentPSD2: Function
  enrollmentSetUsernameEnrollmentPSD2: Function
  enrollmentAddIbanEnrollmentPSD2: Function
  enrollmentRemoveIbanEnrollmentPSD2: Function
  enrollmentUpdateIbanEnrollmentPSD2: Function
}

const ConfermaBanca = (props: ConfermaBancaProps) => {

  const { t } = useTranslation();

  const {
    form,
    elencoProdotti,
    consensoRichiesto,
    dettaglioBanca,
    enrollmentSetConsensoEnrollmentPSD2,
    enrollmentSetProdottoEnrollmentPSD2,
    enrollmentSetUsernameEnrollmentPSD2,
    enrollmentAddIbanEnrollmentPSD2,
    enrollmentRemoveIbanEnrollmentPSD2,
    enrollmentUpdateIbanEnrollmentPSD2
  } = props;

  const onChangeUsername = (username: string) => {
    enrollmentSetUsernameEnrollmentPSD2(username);
  };

  return <>

    <div className={`lista-banche-item`}>
      <div className="lista-banche-item-name">
        {form.banca?.iconImage64
          ? <img className="bank-img" src={base64ToImageSrc(form.banca?.iconImage64)} />
          : <BankIcon title="empty" className="bank-img" />
        }
        <span>{form.banca?.name || placeholderForNullOrUndefined}</span>
      </div>
    </div>

    {(dettaglioBanca?.ibanRequired || dettaglioBanca?.psuIdRequired) &&
      <InputText
        descrizione={t("psd2Attiva.username")}
        className="cerca-banca-testo"
        icon={"user-generic"}
        value={form.username}
        onChange={onChangeUsername}
        errore={t(`${form.usernameError}.generic`)}
        inError={
          (form.formSubmitted || form.usernameDirty) &&
          form.usernameError !== ""
        }
      />
    }

    {dettaglioBanca?.ibanRequired &&
      <>

        <div className="modal-subtitle">
          {t('psd2Attiva.inserimentoIbanDaRegistrare')}
        </div>

        {form.ibanList?.map((iban, i) => (
          <React.Fragment key={'input-iban-' + i}>

            <InputText
              descrizione={t("psd2Attiva.iban")}
              className="cerca-banca-testo"
              value={iban.iban}
              onChange={(value: string) => enrollmentUpdateIbanEnrollmentPSD2(i, value)}
              errore={t(`${iban.ibanError}.generic`)}
              inError={
                (form.formSubmitted || iban.ibanDirty) &&
                iban.ibanError !== ""
              }
            />

            {form.ibanList?.length > 1 &&
              <ButtonText2
                className="cerca-banca-testo"
                descrizione={t("psd2Attiva.rimuoviIban")}
                onClick={() => enrollmentRemoveIbanEnrollmentPSD2(i)}
                img={"azioni_elimina_light"}
                imageMonochrome
              />
            }

          </React.Fragment>
        ))}

        <ButtonText2
          className="cerca-banca-testo"
          descrizione={t("psd2Attiva.aggiungiIban")}
          onClick={enrollmentAddIbanEnrollmentPSD2}
          img={"azioni_aggiungi_light"}
          imageMonochrome
        />

      </>
    }

    {elencoProdotti?.length &&
      <>
        <InputRadioGroup
          descrizione={t('psd2Attiva.scegliProdotto')}
          items={elencoProdotti.map(item => ({
            key: item.aspspProductCode || "",
            description: item.aspspProductDescription || ""
          }))}
          onChange={(key: any) => {
            const product = elencoProdotti?.find(p => p.aspspProductCode === key);
            product && enrollmentSetProdottoEnrollmentPSD2(product);
          }}
          value={form.prodotto?.aspspProductCode}
          className="elenco-prodotti-psd2"
        />
      </>
    }

    {/* {consensoRichiesto &&
      <>

        <span>{t('psd2Attiva.descrizioneConsenso')}</span>

        <div className="checkBox">
          <InputBox
            descrizione={t('psd2Attiva.accettoProvacy')}
            onChange={value => enrollmentSetConsensoEnrollmentPSD2(value)}
            value={form.consenso}
          />
        </div>

      </>
    } */}

    {!dettaglioBanca?.ibanRequired && !dettaglioBanca?.psuIdRequired && (elencoProdotti?.length === 0 || elencoProdotti === undefined) && (
      <div className="modal-subtitle">
        {t('psd2Attiva.noUsernameLabel', { nomeBanca: form.banca?.name ?? '' })}
      </div>
    )}

  </>;
};


interface EnrollmentAlertProps {
  form: FormEnrollmentPSD2Dto,
  psd2ResponseStatus?: EnrollmentStatusDto,
  globalConsent?: boolean

}

const EnrollmentAlert = (props: EnrollmentAlertProps) => {

  const { t } = useTranslation();

  const {
    form,
    psd2ResponseStatus,
    globalConsent
  } = props;


  return <>

    <div className={`lista-banche-item`}>
      <div className="lista-banche-item-name">
        {form.banca?.iconImage64
          ? <img className="bank-img" src={base64ToImageSrc(form.banca?.iconImage64)} />
          : <BankIcon title="empty" className="bank-img" />
        }
        <span>{form.banca?.name || placeholderForNullOrUndefined}</span>
      </div>

    </div>
    {globalConsent && <div>{t('psd2.unaSca.alert')}</div>}
    {!globalConsent && !psd2ResponseStatus?.consentType && <div>{t('psd2.doppiaSca.alert')}</div>}
    {psd2ResponseStatus && psd2ResponseStatus?.oneShot && !psd2ResponseStatus.accountList?.link && <div>{t('psd2.secondaSca.alert')}</div>}
  </>;
};

const RiepilogoEnrollmentAlert = (props: EnrollmentAlertProps) => {

  const { t } = useTranslation();

  const {
    form,
  } = props;


  return <>

    <p style={{ fontWeight: 'bold', fontSize: 16 }}>{t('psd2Attiva.bancaAggiuntaConSuccesso')}</p>

    <div className={`lista-banche-item`}>
      <div className="lista-banche-item-name">
        {form.banca?.iconImage64
          ? <img className="bank-img" src={base64ToImageSrc(form.banca?.iconImage64)} />
          : <BankIcon title="empty" className="bank-img" />
        }
        <span>{form.banca?.name || placeholderForNullOrUndefined}</span>
      </div>

    </div>
    <div style={{ marginBottom: 12 }}>{t('psd2.riepilogo.messaggio')}</div>
    <ul>
      <div className='psd2-list-container'><div className='psd2-dash' /> <div style={{ alignContent: 'center' }}>{t('psd2.riepilogoList.saldoHome')}</div></div>
      <div className='psd2-list-container'><div className='psd2-dash' /> <div style={{ alignContent: 'center' }}>{t('psd2.riepilogoList.saldoMovimenti')}</div></div>
      <div className='psd2-list-container'><div className='psd2-dash' /> <div style={{ alignContent: 'center' }}>{t('psd2.riepilogoList.bonifico')}</div></div>
    </ul>
  </>;
};



interface FooterPsd2EnrollmentProps {
  form: FormEnrollmentPSD2Dto
  formValid?: boolean
  onBack: () => void
  onConfirm: () => void
  dettaglioBanca: EnrollmentActivityDto | undefined
  loaderActive: boolean,
  enrollmentSetStepEnrollmentPSD2: Function
}


const FooterPsd2Enrollment = (props: FooterPsd2EnrollmentProps) => {

  const { t } = useTranslation();


  const {
    form,
    formValid,
    onBack,
    onConfirm,
    dettaglioBanca,
    loaderActive,
    enrollmentSetStepEnrollmentPSD2
  } = props;

  return (
    (form.step === 0 || form.step === 3)
      ? (
        <button
          type="button"
          className="modal-psd2__close-button"
          onClick={onBack}
        >
          {t("button.chiudi")}
        </button>
      ) : form.step === 1 ? (
        <>
          <button
            type="button"
            className="modal-psd2__close-button left"
            onClick={onBack}
          >
            {t("button.indietro")}
          </button>

          <button
            type="button"
            className="modal-psd2__close-button right"
            onClick={() => enrollmentSetStepEnrollmentPSD2(2)}
          // disabled={
          //   loaderActive ||
          //   (!form.consenso && !!dettaglioBanca?.activeConsent) ||
          //   (!form.prodotto && !!dettaglioBanca?.listDetail?.length) ||
          //   ((dettaglioBanca?.ibanRequired || dettaglioBanca?.psuIdRequired) && !formValid && form.formSubmitted)
          // }
          >
            {t("button.continua")}
          </button>
        </>
      ) : (
        <>
          <button
            type="button"
            className="modal-psd2__close-button left"
            onClick={onBack}
          >
            {t("button.indietro")}
          </button>

          <button
            type="button"
            className="modal-psd2__close-button right"
            onClick={onConfirm}
          // disabled={
          //   loaderActive ||
          //   (!form.consenso && !!dettaglioBanca?.activeConsent) ||
          //   (!form.prodotto && !!dettaglioBanca?.listDetail?.length) ||
          //   ((dettaglioBanca?.ibanRequired || dettaglioBanca?.psuIdRequired) && !formValid && form.formSubmitted)
          // }
          >
            {t("button.continua")}
          </button>
        </>
      )
  );
};

interface ModalPsd2EnrollmentProps {
  form: FormEnrollmentPSD2Dto
  formValid: boolean
  modalEnrollmentVisible: boolean
  onClose: () => void
  onBack: () => void
  onConfirm: () => void
  dettaglioBanca: EnrollmentActivityDto | undefined
  bancheDisponibili: ASPSPDto[] | undefined
  elencoProdotti: AspspProductDto[] | undefined
  consensoRichiesto: boolean
  enrollmentWebViewLink: string | undefined
  enrollmentActivitiesPSD2: Function
  enrollmentSetBancaSelezionataEnrollmentPSD2: Function
  enrollmentSetStepEnrollmentPSD2: Function
  enrollmentSetConsensoEnrollmentPSD2: Function
  enrollmentSetProdottoEnrollmentPSD2: Function
  enrollmentSetUsernameEnrollmentPSD2: Function
  enrollmentAddIbanEnrollmentPSD2: Function
  enrollmentRemoveIbanEnrollmentPSD2: Function
  enrollmentUpdateIbanEnrollmentPSD2: Function
  loaderActive: boolean,
  psd2ResponseStatus?: EnrollmentStatusDto | undefined,

}

const ModalPsd2Enrollment = (props: ModalPsd2EnrollmentProps) => {

  const { t } = useTranslation();

  const {
    form,
    formValid,
    modalEnrollmentVisible,
    onClose,
    onBack,
    onConfirm,
    dettaglioBanca,
    bancheDisponibili,
    consensoRichiesto,
    elencoProdotti,
    enrollmentWebViewLink,
    enrollmentActivitiesPSD2,
    enrollmentSetBancaSelezionataEnrollmentPSD2,
    enrollmentSetStepEnrollmentPSD2,
    enrollmentSetConsensoEnrollmentPSD2,
    enrollmentSetProdottoEnrollmentPSD2,
    enrollmentSetUsernameEnrollmentPSD2,
    enrollmentAddIbanEnrollmentPSD2,
    enrollmentRemoveIbanEnrollmentPSD2,
    enrollmentUpdateIbanEnrollmentPSD2,
    loaderActive,
    psd2ResponseStatus
  } = props;

  useEffect(() => {
    if (dettaglioBanca) {
      enrollmentSetStepEnrollmentPSD2(1);
    }
  }, [dettaglioBanca]);


  return (
    <Modal
      show={modalEnrollmentVisible}
      className="modal-psd2-modal"
      onHide={onClose}
    >
      <Modal.Header style={{ backgroundColor: '#3F3F3F', alignContent: 'center', justifyContent: 'center', marginBottom: 26 }}>
        <Modal.Title>
          <span style={{ color: 'white' }}>{form.step === 0 && t('psd2Attiva.titolo').toUpperCase() || form.step === 3 && t('psd2Attiva.esito').toUpperCase() || t('psd2Attiva.titoloStep2').toUpperCase()}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        {form.step === 0 &&
          <ListaBanche
            loaderActive={loaderActive}
            bancheDisponibili={bancheDisponibili}
            enrollmentActivitiesPSD2={enrollmentActivitiesPSD2}
            enrollmentSetBancaSelezionataEnrollmentPSD2={enrollmentSetBancaSelezionataEnrollmentPSD2}
          />
        }

        {form.step === 1 && !enrollmentWebViewLink &&
          <ConfermaBanca
            consensoRichiesto={consensoRichiesto}
            elencoProdotti={elencoProdotti}
            form={form}
            dettaglioBanca={dettaglioBanca}
            enrollmentSetConsensoEnrollmentPSD2={enrollmentSetConsensoEnrollmentPSD2}
            enrollmentSetProdottoEnrollmentPSD2={enrollmentSetProdottoEnrollmentPSD2}
            enrollmentSetUsernameEnrollmentPSD2={enrollmentSetUsernameEnrollmentPSD2}
            enrollmentAddIbanEnrollmentPSD2={enrollmentAddIbanEnrollmentPSD2}
            enrollmentRemoveIbanEnrollmentPSD2={enrollmentRemoveIbanEnrollmentPSD2}
            enrollmentUpdateIbanEnrollmentPSD2={enrollmentUpdateIbanEnrollmentPSD2}
          />
        }

        {form.step === 2 &&
          <EnrollmentAlert
            psd2ResponseStatus={psd2ResponseStatus}
            form={form}
            globalConsent={elencoProdotti?.[0].globalConsents}

          />
        }

        {form.step === 3 &&
          <RiepilogoEnrollmentAlert form={form} />
        }

      </Modal.Body>
      <Modal.Footer style={{ justifyContent: 'space-between' }}>
        <FooterPsd2Enrollment
          onBack={(form.step === 1 || form.step === 2) ? onBack : onClose}
          onConfirm={onConfirm}
          dettaglioBanca={dettaglioBanca}
          form={form}
          formValid={formValid}
          loaderActive={loaderActive}
          enrollmentSetStepEnrollmentPSD2={enrollmentSetStepEnrollmentPSD2}
        />
      </Modal.Footer>
    </Modal>
  );
};

interface Psd2EnrollmentProps {
  rinnovoConsenso?: boolean
  hideButton?: boolean
  modalOpen?: boolean
  okCallback?: () => void
  closeCallback?: () => void
  onStartEnrollmentCallback?: () => void,
}

const Psd2Enrollment = (props: Props) => {

  const { t } = useTranslation();

  const {
    rinnovoConsenso,
    hideButton = false,
    modalOpen = false,
    okCallback,
    closeCallback,
    psd2Abilitata,
    bancheDisponibili,
    form,
    formValid,
    consensoRichiesto,
    dettaglioBanca,
    elencoProdotti,
    enrollmentWebViewLink,
    enrollmentPollingModalShown,
    enrollmentPollingActive,
    enrollmentCompleted,
    webViewLinkConsentRenew,
    psd2ResponseStatus,
    enrollmentStatusPSD2FromConsentRenew,
    enrollmentActivitiesPSD2,
    enrollmentPSD2Reset,
    enrollmentPSD2ResetForm,
    enrollmentSetBancaSelezionataEnrollmentPSD2,
    enrollmentSetStepEnrollmentPSD2,
    enrollmentSetConsensoEnrollmentPSD2,
    enrollmentSetProdottoEnrollmentPSD2,
    enrollmentSetUsernameEnrollmentPSD2,
    enrollmentAddIbanEnrollmentPSD2,
    enrollmentRemoveIbanEnrollmentPSD2,
    enrollmentUpdateIbanEnrollmentPSD2,
    enrollmentSetFormSubmitted,
    enrollmentStatusPSD2,
    startEnrollmentPSD2,
    pushPopUp,
    onStartEnrollmentCallback,
    loaderActive
  } = props;

  const [modalEnrollmentVisible, setModalEnrollmentVisible] = useState<boolean>(false);
  const [consentStatus, setConsentStatus] = useState<string | undefined>(undefined);

  const onClose = () => {
    console.info('Psd2Enrollment | onClose');
    pollingTimeout && clearInterval(pollingTimeout);
    enrollmentPSD2Reset();
    setModalEnrollmentVisible(false);
    setConsentStatus(undefined);
    closeCallback?.();
  };

  const onBack = () => {
    console.info('Psd2Enrollment | onBack');
    enrollmentPSD2ResetForm();
  };


  //TODO: INSERIRE RIMANDO A SECONDO POPUP
  const onConfirm = () => {
    console.info('Psd2Enrollment | onConfirm');
    enrollmentSetFormSubmitted?.(true);
    //setModalEnrollmentVisible(false)


    if (!(dettaglioBanca?.ibanRequired || dettaglioBanca?.psuIdRequired) || formValid) {
      enrollmentStatusPSD2();
    }

  };

  const onErrorEnrollment = () => {
    console.info('Psd2Enrollment | onErrorAggiuntaBanca');
    onClose();
    pushPopUp({
      type: 'alert',
      title: t('psd2Attiva.PSD2'),
      message: t('psd2Attiva.erroreAggiuntaBanca')
    });
  };

  const onSuccessEnrollment = (successHref: string) => {

    console.info('Psd2Enrollment | onSuccessAggiuntaBanca', successHref);
    const newConsentStatus = getQueryParams('consentStatus', successHref);
    setConsentStatus(newConsentStatus);
    enrollmentStatusPSD2(newConsentStatus);
    //setModalEnrollmentVisible(false)
  };

  const onErrorConsentRenew = () => {
    console.info('Psd2Enrollment | onErrorConsentRenew');
    onClose();
    pushPopUp({
      type: 'alert',
      title: t('psd2Attiva.PSD2'),
      message: t('psd2Attiva.erroreRinnovoConsenso')
    });
  };

  const onSuccessConsentRenew = (successHref: string) => {
    console.info('Psd2Enrollment | onSuccessConsentRenew');
    const newConsentStatus = getQueryParams('consentStatus', successHref);
    setConsentStatus(newConsentStatus);
    //TODO: RIMUOVERE COMMENTO
    //consentRenewPSD2();
  };

  const onAggiungiBanca = () => {
    console.info('Psd2Enrollment | onAggiungiBanca');
    sendEventToMatomo('PSD2_AGGIUNGI_ALTRA_BANCA', 'click su pulsante aggiungi altra banca');
    onStartEnrollmentCallback?.()
    setModalEnrollmentVisible(true);
  };

  const onRinnovaConsenso = () => {
    console.info('Psd2Enrollment | onRinnovaConsenso');
    setModalEnrollmentVisible(true);
  };

  useEffect(() => {
    return () => {
      console.info('Psd2Enrollment | Resetting modal PSD2');
      onClose();
    };
  }, []);

  useEffect(() => {
    if (psd2ResponseStatus?.oneShot && !psd2ResponseStatus.accountList?.link) {
      enrollmentSetStepEnrollmentPSD2(2);
      setModalEnrollmentVisible(true);
      // } else if (!psd2ResponseStatus?.oneShot && !psd2ResponseStatus?.accountList?.accounts?.length && !psd2ResponseStatus?.activatedConsent) {
      //   console.log('CASA')
      //   setModalEnrollmentVisible(true);
      //} 
    }
  }, [psd2ResponseStatus])

  useEffect(() => {

    setModalEnrollmentVisible(modalOpen);

  }, [modalOpen]);


  useEffect(() => {

    if (modalEnrollmentVisible && !psd2ResponseStatus?.consentId) {
      enrollmentSetStepEnrollmentPSD2(0);

      if (rinnovoConsenso) {
        console.info('Psd2Enrollment | Starting consent renew...');
        //TODO: RIMUOVERE COMMENTO
        //consentRenewPSD2();
      } else {
        console.info('Psd2Enrollment | Starting enrollment...');
        startEnrollmentPSD2();
      }

    } else if (modalEnrollmentVisible && psd2ResponseStatus?.oneShot) {
      enrollmentSetStepEnrollmentPSD2(2);
    }

    // else if (modalEnrollmentVisible && aspspId) {
    //   enrollmentSetStepEnrollmentPSD2(1);
    // }
    else {
      console.info('Psd2Enrollment | Resetting modal PSD2');
      enrollmentPSD2Reset();
    }

  }, [modalEnrollmentVisible]);

  useEffect(() => {

    if (psd2ResponseStatus?.oneShot) {
      enrollmentSetStepEnrollmentPSD2(2);
      setModalEnrollmentVisible(true);
    }
  }, [psd2ResponseStatus])

  useEffect(() => {

    if (enrollmentPollingActive) {

      pollingTimeout && clearTimeout(pollingTimeout);
      pollingTimeout = setTimeout(() => {
        if (!rinnovoConsenso) {
          enrollmentStatusPSD2(consentStatus || 'received');
        }
      }, 3000);

    }

  }, [enrollmentPollingActive, consentStatus]);

  useEffect(() => {
    console.log('STEP: ', form.step)
  }, [form.step])

  useEffect(() => {

    if (enrollmentCompleted) {

      //onClose();

      if (rinnovoConsenso) {
        pushPopUp({
          type: 'alert',
          title: t('psd2Attiva.PSD2'),
          message: t('psd2Attiva.consensoRinnovatoConSuccesso')
        });
      } else {
        // pushPopUp({
        //   type: 'alert',
        //   title: t('psd2Attiva.PSD2'),
        //   message: t('psd2Attiva.bancaAggiuntaConSuccesso')
        // });
        //setModalEnrollmentVisible(true)
        enrollmentSetStepEnrollmentPSD2(3)
        setModalEnrollmentVisible(true)
      }

      okCallback?.();

    }

  }, [enrollmentCompleted, psd2ResponseStatus]);



  useEffect(() => {
    setModalEnrollmentVisible(false)
  }, [])
  return <>

    {!hideButton &&
      <>

        {!rinnovoConsenso &&
          <ButtonText2
            className={`home-aggiungi-banche${psd2Abilitata ? '' : ' make-invisible'
              }`
            }
            descrizione={t("psd2.aggiungiBanca")}
            onClick={onAggiungiBanca}
            img={"azioni_aggiungi"}
            imageOnLeft
          />
        }

        {rinnovoConsenso &&
          <ButtonText2
            className={`home-rinnovoConsenso-consenso${psd2Abilitata ? '' : ' make-invisible'
              }`
            }
            descrizione={t("elenco_rapporti.dettaglio.rinnovaConsenso")}
            onClick={onRinnovaConsenso}
            img={"navigazione_avanti"}
          />
        }

      </>
    }

    {!rinnovoConsenso &&
      <>

        {modalEnrollmentVisible &&
          <ModalPsd2Enrollment
            onClose={onClose}
            onBack={onBack}
            onConfirm={onConfirm}
            modalEnrollmentVisible={!enrollmentWebViewLink || (psd2ResponseStatus?.oneShot && !psd2ResponseStatus?.accountList?.link) || form.step === 3}
            bancheDisponibili={bancheDisponibili}
            consensoRichiesto={consensoRichiesto}
            dettaglioBanca={dettaglioBanca}
            elencoProdotti={elencoProdotti}
            form={form}
            formValid={formValid}
            enrollmentWebViewLink={enrollmentWebViewLink}
            enrollmentActivitiesPSD2={enrollmentActivitiesPSD2}
            enrollmentSetBancaSelezionataEnrollmentPSD2={enrollmentSetBancaSelezionataEnrollmentPSD2}
            enrollmentSetStepEnrollmentPSD2={enrollmentSetStepEnrollmentPSD2}
            enrollmentSetConsensoEnrollmentPSD2={enrollmentSetConsensoEnrollmentPSD2}
            enrollmentSetProdottoEnrollmentPSD2={enrollmentSetProdottoEnrollmentPSD2}
            enrollmentSetUsernameEnrollmentPSD2={enrollmentSetUsernameEnrollmentPSD2}
            enrollmentAddIbanEnrollmentPSD2={enrollmentAddIbanEnrollmentPSD2}
            enrollmentRemoveIbanEnrollmentPSD2={enrollmentRemoveIbanEnrollmentPSD2}
            enrollmentUpdateIbanEnrollmentPSD2={enrollmentUpdateIbanEnrollmentPSD2}
            loaderActive={loaderActive}
            psd2ResponseStatus={psd2ResponseStatus}
          />
        }

      </>
    }

    <PopUpModal
      visible={enrollmentPollingModalShown}
      popUp={{
        type: 'nobuttons',
        title: t('psd2Attiva.notificationModal.popUpEnrollmentPolling.title', { nomeBanca: form.banca?.name || placeholderForNullOrUndefined }),
        message: t('psd2Attiva.notificationModal.popUpEnrollmentPolling.content', { nomeBanca: form.banca?.name || placeholderForNullOrUndefined })
      }}
    />

    <ThirdPartyWindow
      href={enrollmentWebViewLink}
      successPath={'/psdredirect'}
      successParam={'enrollment=ok'}
      onSuccess={onSuccessEnrollment}
      onError={onErrorEnrollment}
      popUpWaiter={{
        title: t('psd2Attiva.titoloStep2'),
        body: t('psd2Attiva.schedaAperta')
      }}
      pushPopUp={pushPopUp}
    />

    {rinnovoConsenso &&
      <ThirdPartyWindow
        href={webViewLinkConsentRenew}
        successPath={'/psdredirect'}
        successParam={'enrollment=ok'}
        onSuccess={onSuccessConsentRenew}
        onError={onErrorConsentRenew}
        popUpWaiter={{
          title: t('psd2Attiva.titoloRinnovoConsenso'),
          body: t('psd2Attiva.schedaApertaRinnovo')
        }}
        pushPopUp={pushPopUp}
      />
    }

  </>;
};

const mapStateToProps = (state: AppState) => ({
  loaderActive: loaderActive(state.general),
  psd2Abilitata: psd2AISPAbilitato(state.postlogin),
  bancheDisponibili: bancheDisponibiliPSD2(state.psd2),
  form: formEnrollmentPSD2(state.psd2),
  formValid: formPsd2EnrollmentValid(state.psd2),
  consensoRichiesto: consensoRichiestoEnrollmentPSD2(state.psd2),
  dettaglioBanca: dettaglioBancaEnrollmentPSD2(state.psd2),
  elencoProdotti: elencoProdottiEnrollmentPSD2(state.psd2),
  enrollmentWebViewLink: webViewLinkEnrollmentPSD2(state.psd2),
  enrollmentPollingModalShown: enrollmentPsd2PollingModalShown(state.psd2),
  enrollmentPollingActive: enrollmentPSD2PollingActive(state.psd2),
  enrollmentCompleted: enrollmentPSD2Completed(state.psd2),
  webViewLinkConsentRenew: webViewLinkConsentRenewPSD2(state.psd2),
  psd2ResponseStatus: psd2ResponseStatus(state.psd2)
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
  startEnrollmentPSD2,
  enrollmentSetBancaSelezionataEnrollmentPSD2,
  enrollmentSetStepEnrollmentPSD2,
  enrollmentSetUsernameEnrollmentPSD2,
  enrollmentSetProdottoEnrollmentPSD2,
  enrollmentSetConsensoEnrollmentPSD2,
  enrollmentAddIbanEnrollmentPSD2,
  enrollmentRemoveIbanEnrollmentPSD2,
  enrollmentUpdateIbanEnrollmentPSD2,
  enrollmentSetFormSubmitted,
  enrollmentActivitiesPSD2,
  enrollmentStatusPSD2,
  enrollmentPSD2Reset,
  enrollmentPSD2ResetForm,
  pushPopUp,
  enrollmentStatusPSD2FromConsentRenew
}, dispatch);

export type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & Psd2EnrollmentProps;

export default connect(mapStateToProps, mapDispatchToProps)(Psd2Enrollment);
