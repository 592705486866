"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssegniItaliaDtoToJSON = exports.AssegniItaliaDtoFromJSONTyped = exports.AssegniItaliaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function AssegniItaliaDtoFromJSON(json) {
    return AssegniItaliaDtoFromJSONTyped(json, false);
}
exports.AssegniItaliaDtoFromJSON = AssegniItaliaDtoFromJSON;
function AssegniItaliaDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'datiGenerali': !runtime_1.exists(json, 'datiGenerali') ? undefined : _1.DatiGeneraliDtoFromJSON(json['datiGenerali']),
        'carnet': !runtime_1.exists(json, 'carnet') ? undefined : (json['carnet'].map(_1.CarnetDtoFromJSON)),
    };
}
exports.AssegniItaliaDtoFromJSONTyped = AssegniItaliaDtoFromJSONTyped;
function AssegniItaliaDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'datiGenerali': _1.DatiGeneraliDtoToJSON(value.datiGenerali),
        'carnet': value.carnet === undefined ? undefined : (value.carnet.map(_1.CarnetDtoToJSON)),
    };
}
exports.AssegniItaliaDtoToJSON = AssegniItaliaDtoToJSON;
