"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DettaglioMandatoDtoToJSON = exports.DettaglioMandatoDtoFromJSONTyped = exports.DettaglioMandatoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function DettaglioMandatoDtoFromJSON(json) {
    return DettaglioMandatoDtoFromJSONTyped(json, false);
}
exports.DettaglioMandatoDtoFromJSON = DettaglioMandatoDtoFromJSON;
function DettaglioMandatoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codiceMandato': !runtime_1.exists(json, 'codiceMandato') ? undefined : json['codiceMandato'],
        'controValore': !runtime_1.exists(json, 'controValore') ? undefined : json['controValore'],
        'rendimentoPeriodo': !runtime_1.exists(json, 'rendimentoPeriodo') ? undefined : json['rendimentoPeriodo'],
        'dataRendimentoPeriodo': !runtime_1.exists(json, 'dataRendimentoPeriodo') ? undefined : (new Date(json['dataRendimentoPeriodo'])),
        'intestazioneMandato': !runtime_1.exists(json, 'intestazioneMandato') ? undefined : json['intestazioneMandato'],
        'ndgMandato': !runtime_1.exists(json, 'ndgMandato') ? undefined : json['ndgMandato'],
        'categoriaGestione': !runtime_1.exists(json, 'categoriaGestione') ? undefined : json['categoriaGestione'],
        'valutazioneMandato': !runtime_1.exists(json, 'valutazioneMandato') ? undefined : json['valutazioneMandato'],
        'conferimentoLiquidi': !runtime_1.exists(json, 'conferimentoLiquidi') ? undefined : json['conferimentoLiquidi'],
        'conferimentoTitoli': !runtime_1.exists(json, 'conferimentoTitoli') ? undefined : json['conferimentoTitoli'],
        'prelievoLiquidi': !runtime_1.exists(json, 'prelievoLiquidi') ? undefined : json['prelievoLiquidi'],
        'prelievoTitoli': !runtime_1.exists(json, 'prelievoTitoli') ? undefined : json['prelievoTitoli'],
        'rendimentoAnnuo': !runtime_1.exists(json, 'rendimentoAnnuo') ? undefined : json['rendimentoAnnuo'],
        'valutazioneIniziale': !runtime_1.exists(json, 'valutazioneIniziale') ? undefined : json['valutazioneIniziale'],
        'divisa': !runtime_1.exists(json, 'divisa') ? undefined : json['divisa'],
        'dataInizioGestione': !runtime_1.exists(json, 'dataInizioGestione') ? undefined : (new Date(json['dataInizioGestione'])),
        'dataChiusura': !runtime_1.exists(json, 'dataChiusura') ? undefined : (new Date(json['dataChiusura'])),
    };
}
exports.DettaglioMandatoDtoFromJSONTyped = DettaglioMandatoDtoFromJSONTyped;
function DettaglioMandatoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codiceMandato': value.codiceMandato,
        'controValore': value.controValore,
        'rendimentoPeriodo': value.rendimentoPeriodo,
        'dataRendimentoPeriodo': value.dataRendimentoPeriodo === undefined ? undefined : (value.dataRendimentoPeriodo.toISOString()),
        'intestazioneMandato': value.intestazioneMandato,
        'ndgMandato': value.ndgMandato,
        'categoriaGestione': value.categoriaGestione,
        'valutazioneMandato': value.valutazioneMandato,
        'conferimentoLiquidi': value.conferimentoLiquidi,
        'conferimentoTitoli': value.conferimentoTitoli,
        'prelievoLiquidi': value.prelievoLiquidi,
        'prelievoTitoli': value.prelievoTitoli,
        'rendimentoAnnuo': value.rendimentoAnnuo,
        'valutazioneIniziale': value.valutazioneIniziale,
        'divisa': value.divisa,
        'dataInizioGestione': value.dataInizioGestione === undefined ? undefined : (value.dataInizioGestione.toISOString()),
        'dataChiusura': value.dataChiusura === undefined ? undefined : (value.dataChiusura.toISOString()),
    };
}
exports.DettaglioMandatoDtoToJSON = DettaglioMandatoDtoToJSON;
