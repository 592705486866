"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnatocismoDtoToJSON = exports.AnatocismoDtoFromJSONTyped = exports.AnatocismoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function AnatocismoDtoFromJSON(json) {
    return AnatocismoDtoFromJSONTyped(json, false);
}
exports.AnatocismoDtoFromJSON = AnatocismoDtoFromJSON;
function AnatocismoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'iban': !runtime_1.exists(json, 'iban') ? undefined : json['iban'],
        'ibanFormattato': !runtime_1.exists(json, 'ibanFormattato') ? undefined : json['ibanFormattato'],
        'accettato': !runtime_1.exists(json, 'accettato') ? undefined : json['accettato'],
        'intestatarioDelContoDiAddebito': !runtime_1.exists(json, 'intestatarioDelContoDiAddebito') ? undefined : json['intestatarioDelContoDiAddebito'],
        'anatocismoGuc': !runtime_1.exists(json, 'anatocismoGuc') ? undefined : json['anatocismoGuc'],
        'addendum': !runtime_1.exists(json, 'addendum') ? undefined : json['addendum'],
        'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
        'alias': !runtime_1.exists(json, 'alias') ? undefined : json['alias'],
    };
}
exports.AnatocismoDtoFromJSONTyped = AnatocismoDtoFromJSONTyped;
function AnatocismoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'iban': value.iban,
        'ibanFormattato': value.ibanFormattato,
        'accettato': value.accettato,
        'intestatarioDelContoDiAddebito': value.intestatarioDelContoDiAddebito,
        'anatocismoGuc': value.anatocismoGuc,
        'addendum': value.addendum,
        'stato': value.stato,
        'alias': value.alias,
    };
}
exports.AnatocismoDtoToJSON = AnatocismoDtoToJSON;
