"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CampiF24DelegheRBDtoToJSON = exports.CampiF24DelegheRBDtoFromJSONTyped = exports.CampiF24DelegheRBDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function CampiF24DelegheRBDtoFromJSON(json) {
    return CampiF24DelegheRBDtoFromJSONTyped(json, false);
}
exports.CampiF24DelegheRBDtoFromJSON = CampiF24DelegheRBDtoFromJSON;
function CampiF24DelegheRBDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'provenienzaMovimento': !runtime_1.exists(json, 'provenienzaMovimento') ? undefined : json['provenienzaMovimento'],
        'cabPresentazione': !runtime_1.exists(json, 'cabPresentazione') ? undefined : json['cabPresentazione'],
        'progressivoTecnico': !runtime_1.exists(json, 'progressivoTecnico') ? undefined : json['progressivoTecnico'],
        'progressivoInvio': !runtime_1.exists(json, 'progressivoInvio') ? undefined : json['progressivoInvio'],
        'progressivoDelegaInvio': !runtime_1.exists(json, 'progressivoDelegaInvio') ? undefined : json['progressivoDelegaInvio'],
        'statoDelega': !runtime_1.exists(json, 'statoDelega') ? undefined : json['statoDelega'],
        'dataDelega': !runtime_1.exists(json, 'dataDelega') ? undefined : (new Date(json['dataDelega'])),
        'dataInvio': !runtime_1.exists(json, 'dataInvio') ? undefined : (new Date(json['dataInvio'])),
        'codiceTransazione': !runtime_1.exists(json, 'codiceTransazione') ? undefined : json['codiceTransazione'],
        'codiceTerminale': !runtime_1.exists(json, 'codiceTerminale') ? undefined : json['codiceTerminale'],
        'flagSezione': !runtime_1.exists(json, 'flagSezione') ? undefined : json['flagSezione'],
    };
}
exports.CampiF24DelegheRBDtoFromJSONTyped = CampiF24DelegheRBDtoFromJSONTyped;
function CampiF24DelegheRBDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'provenienzaMovimento': value.provenienzaMovimento,
        'cabPresentazione': value.cabPresentazione,
        'progressivoTecnico': value.progressivoTecnico,
        'progressivoInvio': value.progressivoInvio,
        'progressivoDelegaInvio': value.progressivoDelegaInvio,
        'statoDelega': value.statoDelega,
        'dataDelega': value.dataDelega === undefined ? undefined : (value.dataDelega.toISOString()),
        'dataInvio': value.dataInvio === undefined ? undefined : (value.dataInvio.toISOString()),
        'codiceTransazione': value.codiceTransazione,
        'codiceTerminale': value.codiceTerminale,
        'flagSezione': value.flagSezione,
    };
}
exports.CampiF24DelegheRBDtoToJSON = CampiF24DelegheRBDtoToJSON;
