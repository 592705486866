"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CampoDtoToJSON = exports.CampoDtoFromJSONTyped = exports.CampoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function CampoDtoFromJSON(json) {
    return CampoDtoFromJSONTyped(json, false);
}
exports.CampoDtoFromJSON = CampoDtoFromJSON;
function CampoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'titolo': !runtime_1.exists(json, 'titolo') ? undefined : json['titolo'],
        'titoloFormattato': !runtime_1.exists(json, 'titoloFormattato') ? undefined : json['titoloFormattato'],
        'descrizione': !runtime_1.exists(json, 'descrizione') ? undefined : json['descrizione'],
        'obbligatorio': !runtime_1.exists(json, 'obbligatorio') ? undefined : json['obbligatorio'],
        'aVideo': !runtime_1.exists(json, 'aVideo') ? undefined : json['aVideo'],
        'tipoCampo': !runtime_1.exists(json, 'tipoCampo') ? undefined : json['tipoCampo'],
        'operatoreConfronto': !runtime_1.exists(json, 'operatoreConfronto') ? undefined : json['operatoreConfronto'],
        'importoConfronto': !runtime_1.exists(json, 'importoConfronto') ? undefined : json['importoConfronto'],
        'url': !runtime_1.exists(json, 'url') ? undefined : json['url'],
        'radioValues': !runtime_1.exists(json, 'radioValues') ? undefined : json['radioValues'],
        'radioValuesFormattato': !runtime_1.exists(json, 'radioValuesFormattato') ? undefined : json['radioValuesFormattato'],
        'radioLabels': !runtime_1.exists(json, 'radioLabels') ? undefined : json['radioLabels'],
        'radioLabesFormattato': !runtime_1.exists(json, 'radioLabesFormattato') ? undefined : json['radioLabesFormattato'],
        'posizione': !runtime_1.exists(json, 'posizione') ? undefined : json['posizione'],
        'lingua': !runtime_1.exists(json, 'lingua') ? undefined : json['lingua'],
        'value': !runtime_1.exists(json, 'value') ? undefined : json['value'],
        'funzione': !runtime_1.exists(json, 'funzione') ? undefined : json['funzione'],
        'filename': !runtime_1.exists(json, 'filename') ? undefined : json['filename'],
        'rapporti': !runtime_1.exists(json, 'rapporti') ? undefined : (json['rapporti'].map(_1.RapportoCompletoDtoFromJSON)),
        'fieldDirty': !runtime_1.exists(json, 'fieldDirty') ? undefined : json['fieldDirty'],
        'error': !runtime_1.exists(json, 'error') ? undefined : json['error'],
    };
}
exports.CampoDtoFromJSONTyped = CampoDtoFromJSONTyped;
function CampoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'titolo': value.titolo,
        'titoloFormattato': value.titoloFormattato,
        'descrizione': value.descrizione,
        'obbligatorio': value.obbligatorio,
        'aVideo': value.aVideo,
        'tipoCampo': value.tipoCampo,
        'operatoreConfronto': value.operatoreConfronto,
        'importoConfronto': value.importoConfronto,
        'url': value.url,
        'radioValues': value.radioValues,
        'radioValuesFormattato': value.radioValuesFormattato,
        'radioLabels': value.radioLabels,
        'radioLabesFormattato': value.radioLabesFormattato,
        'posizione': value.posizione,
        'lingua': value.lingua,
        'value': value.value,
        'funzione': value.funzione,
        'filename': value.filename,
        'rapporti': value.rapporti === undefined ? undefined : (value.rapporti.map(_1.RapportoCompletoDtoToJSON)),
        'fieldDirty': value.fieldDirty,
        'error': value.error,
    };
}
exports.CampoDtoToJSON = CampoDtoToJSON;
