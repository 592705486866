"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelfResetStatusResponseDtoStatoEnum = exports.SelfResetStatusResponseDtoToJSON = exports.SelfResetStatusResponseDtoFromJSONTyped = exports.SelfResetStatusResponseDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function SelfResetStatusResponseDtoFromJSON(json) {
    return SelfResetStatusResponseDtoFromJSONTyped(json, false);
}
exports.SelfResetStatusResponseDtoFromJSON = SelfResetStatusResponseDtoFromJSON;
function SelfResetStatusResponseDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codiceUtente': !runtime_1.exists(json, 'codiceUtente') ? undefined : json['codiceUtente'],
        'vendorId': !runtime_1.exists(json, 'vendorId') ? undefined : json['vendorId'],
        'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
    };
}
exports.SelfResetStatusResponseDtoFromJSONTyped = SelfResetStatusResponseDtoFromJSONTyped;
function SelfResetStatusResponseDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codiceUtente': value.codiceUtente,
        'vendorId': value.vendorId,
        'stato': value.stato,
    };
}
exports.SelfResetStatusResponseDtoToJSON = SelfResetStatusResponseDtoToJSON;
/**
* @export
* @enum {string}
*/
var SelfResetStatusResponseDtoStatoEnum;
(function (SelfResetStatusResponseDtoStatoEnum) {
    SelfResetStatusResponseDtoStatoEnum["PENDING"] = "PENDING";
    SelfResetStatusResponseDtoStatoEnum["SUCCESS"] = "SUCCESS";
    SelfResetStatusResponseDtoStatoEnum["FAIL"] = "FAIL";
    SelfResetStatusResponseDtoStatoEnum["CLOSE"] = "CLOSE";
    SelfResetStatusResponseDtoStatoEnum["INSERITA"] = "INSERITA";
})(SelfResetStatusResponseDtoStatoEnum = exports.SelfResetStatusResponseDtoStatoEnum || (exports.SelfResetStatusResponseDtoStatoEnum = {}));
