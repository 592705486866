"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * API BFF Customer Web Collaboration
 * API BFF Customer Web Collaboration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CounselorResponseToJSON = exports.CounselorResponseFromJSONTyped = exports.CounselorResponseFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function CounselorResponseFromJSON(json) {
    return CounselorResponseFromJSONTyped(json, false);
}
exports.CounselorResponseFromJSON = CounselorResponseFromJSON;
function CounselorResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'branch': !runtime_1.exists(json, 'branch') ? undefined : json['branch'],
        'branchKeyLabel': !runtime_1.exists(json, 'branchKeyLabel') ? undefined : json['branchKeyLabel'],
        'certifications': !runtime_1.exists(json, 'certifications') ? undefined : (json['certifications'].map(_1.CertificationResponseFromJSON)),
        'displayName': !runtime_1.exists(json, 'displayName') ? undefined : json['displayName'],
        'email': !runtime_1.exists(json, 'email') ? undefined : json['email'],
        'enabledServiceProducts': !runtime_1.exists(json, 'enabledServiceProducts') ? undefined : (json['enabledServiceProducts'].map(_1.ServiceProductResponseFromJSON)),
        'errorManagementResponse': !runtime_1.exists(json, 'errorManagementResponse') ? undefined : _1.ErrorManagementResponseFromJSON(json['errorManagementResponse']),
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'idGraphUser': !runtime_1.exists(json, 'idGraphUser') ? undefined : json['idGraphUser'],
        'phone': !runtime_1.exists(json, 'phone') ? undefined : json['phone'],
        'role': !runtime_1.exists(json, 'role') ? undefined : json['role'],
        'type': !runtime_1.exists(json, 'type') ? undefined : json['type'],
    };
}
exports.CounselorResponseFromJSONTyped = CounselorResponseFromJSONTyped;
function CounselorResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'branch': value.branch,
        'branchKeyLabel': value.branchKeyLabel,
        'certifications': value.certifications === undefined ? undefined : (value.certifications.map(_1.CertificationResponseToJSON)),
        'displayName': value.displayName,
        'email': value.email,
        'enabledServiceProducts': value.enabledServiceProducts === undefined ? undefined : (value.enabledServiceProducts.map(_1.ServiceProductResponseToJSON)),
        'errorManagementResponse': _1.ErrorManagementResponseToJSON(value.errorManagementResponse),
        'id': value.id,
        'idGraphUser': value.idGraphUser,
        'phone': value.phone,
        'role': value.role,
        'type': value.type,
    };
}
exports.CounselorResponseToJSON = CounselorResponseToJSON;
