"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tipoDocumento = exports.listaComuni = exports.giorniFestivi = exports.browserSupported = exports.psd2PISPAbilitato = exports.psd2AISPAbilitato = exports.assistenteVirtualeAbilitatə = exports.parametroUltimoAccesso = exports.parametriPostlogin = void 0;
const reselect_1 = require("reselect");
const postloginReducer = (state) => state;
exports.parametriPostlogin = (0, reselect_1.createSelector)(postloginReducer, (state) => {
    return state.parametri;
});
exports.parametroUltimoAccesso = (0, reselect_1.createSelector)(postloginReducer, (state) => {
    var _a;
    return (_a = state.parametri) === null || _a === void 0 ? void 0 : _a.ULTIMO_ACCESSO;
});
exports.assistenteVirtualeAbilitatə = (0, reselect_1.createSelector)(postloginReducer, (state) => {
    var _a;
    return ((_a = state.parametri) === null || _a === void 0 ? void 0 : _a.VIRTUAL_ASSISTANT_ENABLED) || false;
});
exports.psd2AISPAbilitato = (0, reselect_1.createSelector)(postloginReducer, (state) => {
    var _a;
    return ((_a = state.parametri) === null || _a === void 0 ? void 0 : _a.PSD2_USER_ENABLED) || false;
});
exports.psd2PISPAbilitato = (0, reselect_1.createSelector)(postloginReducer, (state) => {
    var _a;
    return ((_a = state.parametri) === null || _a === void 0 ? void 0 : _a.PSD2_PISP_USER_ENABLED) || false;
});
exports.browserSupported = (0, reselect_1.createSelector)(postloginReducer, (state) => {
    var _a;
    return ((_a = state.parametri) === null || _a === void 0 ? void 0 : _a.BROWSER_SUPPORTED) || false;
});
exports.giorniFestivi = (0, reselect_1.createSelector)(postloginReducer, (state) => {
    var _a;
    return ((_a = state.parametri) === null || _a === void 0 ? void 0 : _a.GIORNI_FESTIVI) || [''];
});
exports.listaComuni = (0, reselect_1.createSelector)(postloginReducer, (state) => {
    return state.listaComuni;
});
exports.tipoDocumento = (0, reselect_1.createSelector)(postloginReducer, (state) => {
    return state.tipoDocumento;
});
