"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LineDataRowDtoToJSON = exports.LineDataRowDtoFromJSONTyped = exports.LineDataRowDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function LineDataRowDtoFromJSON(json) {
    return LineDataRowDtoFromJSONTyped(json, false);
}
exports.LineDataRowDtoFromJSON = LineDataRowDtoFromJSON;
function LineDataRowDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'dataRata': !runtime_1.exists(json, 'dataRata') ? undefined : (new Date(json['dataRata'])),
        'totalePagato': !runtime_1.exists(json, 'totalePagato') ? undefined : json['totalePagato'],
        'totaleResiduo': !runtime_1.exists(json, 'totaleResiduo') ? undefined : json['totaleResiduo'],
    };
}
exports.LineDataRowDtoFromJSONTyped = LineDataRowDtoFromJSONTyped;
function LineDataRowDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'dataRata': value.dataRata === undefined ? undefined : (value.dataRata.toISOString()),
        'totalePagato': value.totalePagato,
        'totaleResiduo': value.totaleResiduo,
    };
}
exports.LineDataRowDtoToJSON = LineDataRowDtoToJSON;
