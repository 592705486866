"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.operazioniReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const initialState = {
    operazioniPreferite: (0, api_rest_1.GestionePreferitiDtoFromJSON)({})
};
const operazioniReducer = (state = initialState, action) => {
    switch (action.type) {
        case __1.EReduxActionTypes.OPERAZIONI_GET_PREFERITI_SUCCESS:
            return {
                ...state,
                operazioniPreferite: action.payload
            };
        case __1.EReduxActionTypes.OPERAZIONI_RESET_PREFERITI:
            return {
                ...initialState
            };
        case __1.EReduxActionTypes.GENERAL_REFRESH_WIDGETS: // action invocata tramite pull to refresh
            return {
                ...state,
                operazioniPreferite: initialState.operazioniPreferite
            };
        case __1.EReduxActionTypes.RESET_ALL: // action invocata dalla logout
            return {
                ...initialState
            };
        default:
            return state;
    }
};
exports.operazioniReducer = operazioniReducer;
exports.default = exports.operazioniReducer;
