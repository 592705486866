"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bonificoVersamentoDepositoReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const helpers_1 = require("../helpers");
const bonifico_versamento_deposito_types_1 = require("./bonifico-versamento-deposito.types");
const initialState = {
    parametri: (0, api_rest_1.ParametriBonificoDtoFromJSON)({}),
    riepilogo: (0, api_rest_1.BonificoSepaDtoFromJSON)({}),
    infoDispositiva: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
    esito: (0, api_rest_1.EsitoAutorizzazioneDisposizioneBonificoDtoFromJSON)({}),
    form: (0, bonifico_versamento_deposito_types_1.FormBonificoVersamentoDepositoDtoFromJSONTyped)({}),
    riepilogoRevoca: (0, api_rest_1.BonificoSepaDtoFromJSON)({}),
    formRevoca: (0, bonifico_versamento_deposito_types_1.FormRevocaBonificoVersamentoDepositoDtoFromJSON)({}),
    infoRevoca: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
    esitoRevoca: (0, api_rest_1.EsitoAutorizzazioneDisposizioneBonificoDtoFromJSON)({})
};
const bonificoVersamentoDepositoReducer = (state = initialState, action) => {
    switch (action.type) {
        case bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_GET_PARAMETRI_SUCCESS:
            return {
                ...initialState,
                parametri: action.payload
            };
        case bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_GET_PARAMETRI_PER_RIPETI_SUCCESS:
            return {
                ...initialState,
                parametri: action.payload,
                form: {
                    ...state.form,
                    ripeti: true
                }
            };
        case bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_CONTROLLO_SUCCESS:
            return {
                ...state,
                riepilogo: action.payload
            };
        case bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_GET_INFO_DISPOSITIVA_SUCCESS:
            return {
                ...state,
                infoDispositiva: action.payload,
                esito: initialState.esito
            };
        case bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_AUTORIZZA_SUCCESS:
            if (action.payload.stato === 'OK') {
                return {
                    ...state,
                    //riepilogo: initialState.riepilogo,
                    esito: action.payload
                };
            }
            else {
                return {
                    ...state,
                    infoDispositiva: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoDispositiva, action.payload.informazioniAutenticazioneDispositiva),
                    esito: action.payload
                };
            }
        case bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_CONTO_ACCREDITO:
            return {
                ...state,
                form: {
                    ...state.form,
                    contoAccredito: action.payload,
                    contoAccreditoDirty: true,
                    contoAccreditoError: (0, helpers_1.validationField)(action.payload, '')
                }
            };
        case bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_IMPORTO:
            return {
                ...state,
                form: {
                    ...state.form,
                    importo: action.payload,
                    importoDirty: true,
                    importoError: (0, helpers_1.validationField)(action.payload, 0)
                }
            };
        case bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_DATA_ESECUZIONE:
            return {
                ...state,
                form: {
                    ...state.form,
                    dataEsecuzione: action.payload,
                    dataEsecuzioneDirty: true,
                    dataEsecuzioneError: (0, helpers_1.validationFieldDate)(action.payload, [], true, '')
                    // dataEsecuzioneError: validationFieldDate(
                    //   action.payload,
                    //   state.parametri && state.parametri.parametri
                    //     ? state.parametri.parametri["BONIFICOSEPA.FESTIVI"]
                    //     : [],
                    //   true,
                    //   state.parametri && state.parametri.parametri
                    //     ? state.parametri.parametri[
                    //       "BONIFICOSEPA.PRIMADATAUTILE"
                    //     ].toString()
                    //     : ""
                    // ),
                }
            };
        case bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_CAUSALE:
            return {
                ...state,
                form: {
                    ...state.form,
                    causale: action.payload,
                    causaleDirty: true,
                    causaleError: (0, helpers_1.validationField)(action.payload, '')
                }
            };
        case bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_STEP:
            return {
                ...state,
                form: {
                    ...state.form,
                    step: action.payload
                }
            };
        case bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_INTESTATARIO:
            return {
                ...state,
                form: {
                    ...state.form,
                    intestatario: action.payload,
                    intestatarioDirty: true,
                    intestatarioError: (0, helpers_1.validationField)(action.payload, '')
                }
            };
        case bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_SET_RAPPORTO_SELEZIONATO:
            return {
                ...state,
                form: {
                    ...state.form,
                    rapportoSelezionato: action.payload
                }
            };
        case bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_FORM_SUBMITTED:
            return {
                ...state,
                form: {
                    ...state.form,
                    formSubmitted: action.payload
                }
            };
        case bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_RESET:
            return {
                ...initialState
            };
        case bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_SET_RIEPILOGO_REVOCA:
            return {
                ...state,
                formRevoca: initialState.formRevoca,
                riepilogoRevoca: action.payload
            };
        case bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_SET_PROVENIENZA:
            return {
                ...state,
                formRevoca: {
                    ...state.formRevoca,
                    provenienza: action.payload
                }
            };
        case bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_STEP_REVOCA:
            return {
                ...state,
                formRevoca: {
                    ...state.formRevoca,
                    step: action.payload
                }
            };
        case bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_GET_INFO_REVOCA_SUCCESS:
            return {
                ...state,
                infoRevoca: action.payload,
                esitoRevoca: initialState.esitoRevoca
            };
        case bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_AUTORIZZA_REVOCA_SUCCESS:
            if (action.payload.stato === 'OK') {
                return {
                    ...state,
                    esitoRevoca: action.payload
                };
            }
            else {
                return {
                    ...state,
                    parametri: initialState.parametri,
                    riepilogo: initialState.riepilogo,
                    esito: initialState.esito,
                    form: initialState.form,
                    riepilogoRevoca: initialState.riepilogoRevoca,
                    infoRevoca: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoDispositiva, action.payload.informazioniAutenticazioneDispositiva),
                    esitoRevoca: action.payload
                };
            }
        case bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_RESET_RIEPILOGO:
            return {
                ...state,
                riepilogo: initialState.riepilogo
            };
        case bonifico_versamento_deposito_types_1.EBonificoVersamentoDepositoTypes.BONIFICO_VERSAMENTO_DEPOSITO_RESET_ESITO:
            return {
                ...state,
                esito: initialState.esito
            };
        case __1.EReduxActionTypes.RESET_ALL: // action invocata dalla logout
            return {
                ...initialState
            };
        default:
            return state;
    }
};
exports.bonificoVersamentoDepositoReducer = bonificoVersamentoDepositoReducer;
exports.default = exports.bonificoVersamentoDepositoReducer;
