"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EsitoAutorizzazioneDisposizioneBonificoDtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneBonificoDtoToJSON = exports.EsitoAutorizzazioneDisposizioneBonificoDtoFromJSONTyped = exports.EsitoAutorizzazioneDisposizioneBonificoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function EsitoAutorizzazioneDisposizioneBonificoDtoFromJSON(json) {
    return EsitoAutorizzazioneDisposizioneBonificoDtoFromJSONTyped(json, false);
}
exports.EsitoAutorizzazioneDisposizioneBonificoDtoFromJSON = EsitoAutorizzazioneDisposizioneBonificoDtoFromJSON;
function EsitoAutorizzazioneDisposizioneBonificoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
        'codiceErrore': !runtime_1.exists(json, 'codiceErrore') ? undefined : json['codiceErrore'],
        'informazioniAutenticazioneDispositiva': !runtime_1.exists(json, 'informazioniAutenticazioneDispositiva') ? undefined : _1.InformazioniAutenticazioneDispositivaDtoFromJSON(json['informazioniAutenticazioneDispositiva']),
        'disposizione': !runtime_1.exists(json, 'disposizione') ? undefined : _1.BonificoSepaDtoFromJSON(json['disposizione']),
    };
}
exports.EsitoAutorizzazioneDisposizioneBonificoDtoFromJSONTyped = EsitoAutorizzazioneDisposizioneBonificoDtoFromJSONTyped;
function EsitoAutorizzazioneDisposizioneBonificoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'stato': value.stato,
        'codiceErrore': value.codiceErrore,
        'informazioniAutenticazioneDispositiva': _1.InformazioniAutenticazioneDispositivaDtoToJSON(value.informazioniAutenticazioneDispositiva),
        'disposizione': _1.BonificoSepaDtoToJSON(value.disposizione),
    };
}
exports.EsitoAutorizzazioneDisposizioneBonificoDtoToJSON = EsitoAutorizzazioneDisposizioneBonificoDtoToJSON;
/**
* @export
* @enum {string}
*/
var EsitoAutorizzazioneDisposizioneBonificoDtoStatoEnum;
(function (EsitoAutorizzazioneDisposizioneBonificoDtoStatoEnum) {
    EsitoAutorizzazioneDisposizioneBonificoDtoStatoEnum["OK"] = "OK";
    EsitoAutorizzazioneDisposizioneBonificoDtoStatoEnum["CREDENZIALE_ERRATA"] = "CREDENZIALE_ERRATA";
    EsitoAutorizzazioneDisposizioneBonificoDtoStatoEnum["ATTENDERE"] = "ATTENDERE";
    EsitoAutorizzazioneDisposizioneBonificoDtoStatoEnum["ACCOUNT_BLOCCATO"] = "ACCOUNT_BLOCCATO";
    EsitoAutorizzazioneDisposizioneBonificoDtoStatoEnum["ACCP"] = "ACCP";
    EsitoAutorizzazioneDisposizioneBonificoDtoStatoEnum["ACSC"] = "ACSC";
    EsitoAutorizzazioneDisposizioneBonificoDtoStatoEnum["ACSP"] = "ACSP";
    EsitoAutorizzazioneDisposizioneBonificoDtoStatoEnum["ACTC"] = "ACTC";
    EsitoAutorizzazioneDisposizioneBonificoDtoStatoEnum["ACWC"] = "ACWC";
    EsitoAutorizzazioneDisposizioneBonificoDtoStatoEnum["ACWP"] = "ACWP";
    EsitoAutorizzazioneDisposizioneBonificoDtoStatoEnum["RCVD"] = "RCVD";
    EsitoAutorizzazioneDisposizioneBonificoDtoStatoEnum["PDNG"] = "PDNG";
    EsitoAutorizzazioneDisposizioneBonificoDtoStatoEnum["RJCT"] = "RJCT";
    EsitoAutorizzazioneDisposizioneBonificoDtoStatoEnum["CANC"] = "CANC";
})(EsitoAutorizzazioneDisposizioneBonificoDtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneBonificoDtoStatoEnum || (exports.EsitoAutorizzazioneDisposizioneBonificoDtoStatoEnum = {}));
