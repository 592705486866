"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * API BFF Customer Web Collaboration
 * API BFF Customer Web Collaboration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerEventFilterRequestToJSON = exports.CustomerEventFilterRequestFromJSONTyped = exports.CustomerEventFilterRequestFromJSON = void 0;
const runtime_1 = require("../runtime");
function CustomerEventFilterRequestFromJSON(json) {
    return CustomerEventFilterRequestFromJSONTyped(json, false);
}
exports.CustomerEventFilterRequestFromJSON = CustomerEventFilterRequestFromJSON;
function CustomerEventFilterRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'from': !runtime_1.exists(json, 'from') ? undefined : json['from'],
        'to': !runtime_1.exists(json, 'to') ? undefined : json['to'],
    };
}
exports.CustomerEventFilterRequestFromJSONTyped = CustomerEventFilterRequestFromJSONTyped;
function CustomerEventFilterRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'from': value.from,
        'to': value.to,
    };
}
exports.CustomerEventFilterRequestToJSON = CustomerEventFilterRequestToJSON;
