"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.copyPanViewSuccessCartaConto = exports.getPanViewResponseCartaConto = exports.startPanViewResponseCartaConto = exports.getPinViewResponseCartaConto = exports.startPinViewResponseCartaConto = exports.saldoComplessivo = exports.parametriCartaConto = exports.filtriApplicati = exports.formElencoMovimentiContoDepositoAsFiltro = exports.formElencoMovimentiCartaContoAsFiltro = exports.formElencoMovimentiAsFiltro = exports.formSaldoEMovimentiValid = exports.formSaldoEMovimenti = exports.pdfDettaglioMovimento = exports.excelMovimenti = exports.pdfMovimenti = exports.movimenti = exports.movimentiHomepage = exports.parametriMovimentiContoDeposito = exports.rapportiNoPSD2 = exports.parametriMovimentiWidget = exports.parametriMovimenti = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const reselect_1 = require("reselect");
const helpers_1 = require("../helpers");
const moment = require("moment");
const IMPORTO_MOLTIPLICATORI_INTERVALLO = { MIN: 0.8, MAX: 1.2 };
const movimentiReducer = (state) => state;
exports.parametriMovimenti = (0, reselect_1.createSelector)(movimentiReducer, (state) => {
    return state.parametri;
});
exports.parametriMovimentiWidget = (0, reselect_1.createSelector)(movimentiReducer, (state) => {
    return state.parametriWidget;
});
exports.rapportiNoPSD2 = (0, reselect_1.createSelector)(movimentiReducer, (state) => {
    var _a;
    return (_a = state.parametri.rapporti) === null || _a === void 0 ? void 0 : _a.filter((rapporto) => { var _a; return !((_a = rapporto.rapporto) === null || _a === void 0 ? void 0 : _a.psd2); });
});
exports.parametriMovimentiContoDeposito = (0, reselect_1.createSelector)(movimentiReducer, (state) => {
    return state.parametriContoDeposito;
});
exports.movimentiHomepage = (0, reselect_1.createSelector)(movimentiReducer, (state) => {
    return state.movimentiHomepage;
});
exports.movimenti = (0, reselect_1.createSelector)(movimentiReducer, (state) => {
    return state.movimenti;
});
exports.pdfMovimenti = (0, reselect_1.createSelector)(movimentiReducer, (state) => {
    return state.pdfMovimenti;
});
exports.excelMovimenti = (0, reselect_1.createSelector)(movimentiReducer, (state) => {
    return state.excelMovimenti;
});
exports.pdfDettaglioMovimento = (0, reselect_1.createSelector)(movimentiReducer, (state) => {
    return state.pdfDettaglioMovimento;
});
exports.formSaldoEMovimenti = (0, reselect_1.createSelector)(movimentiReducer, (state) => {
    return state.form;
});
exports.formSaldoEMovimentiValid = (0, reselect_1.createSelector)(movimentiReducer, (state) => {
    var _a, _b;
    let formValid = false;
    if (!!((_b = (_a = state.form.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.id) &&
        !state.form.descrizioneError &&
        !state.form.dataDaError &&
        !state.form.dataAError &&
        !state.form.importoError) {
        formValid = true;
    }
    return formValid;
});
exports.formElencoMovimentiAsFiltro = (0, reselect_1.createSelector)(movimentiReducer, exports.formSaldoEMovimentiValid, (state, valid) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const datiForm = valid
        ? {
            offset: state.form.offset || 0,
            codiceRapporto: ((_b = (_a = state.form.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.id) || 0,
            categoriePfm: state.form.categorie,
            dataIniziale: new Date(state.form.dataDa),
            dataFinale: new Date(state.form.dataA),
            keyword: state.form.descrizione || undefined,
            importoDa: (0, helpers_1.formattedNumberToNumber)(state.form.importo) *
                IMPORTO_MOLTIPLICATORI_INTERVALLO.MIN || undefined,
            importoA: (0, helpers_1.formattedNumberToNumber)(state.form.importo) *
                IMPORTO_MOLTIPLICATORI_INTERVALLO.MAX || undefined,
            segno: state.form.entrate && state.form.uscite
                ? undefined
                : state.form.entrate
                    ? api_rest_1.CriteriRicercaMovimentiRequestInSegnoEnum.A
                    : api_rest_1.CriteriRicercaMovimentiRequestInSegnoEnum.D,
            ordinamento: state.form.sorting,
            divisa: state.form.divisaSelezionata ||
                ((_e = (_d = (_c = state.form) === null || _c === void 0 ? void 0 : _c.rapportoSelezionato) === null || _d === void 0 ? void 0 : _d.rapporto) === null || _e === void 0 ? void 0 : _e.divisa),
            movNonContabilizzati: state.form.movimentiNonContabilizzati
        }
        : undefined;
    if (datiForm && ((_g = (_f = state.form.rapportoSelezionato) === null || _f === void 0 ? void 0 : _f.rapporto) === null || _g === void 0 ? void 0 : _g.psd2)) {
        const divisaRapporto = state.form.rapportoSelezionato.rapporto.divisa ||
            ((_h = state.form.rapportoSelezionato.saldo) === null || _h === void 0 ? void 0 : _h.divisa);
        datiForm.criteriPsd2 = {
            aspspId: state.form.rapportoSelezionato.rapporto.psd2.aspspId,
            aspspProductCode: state.form.rapportoSelezionato.rapporto.psd2.aspspProductCode,
            resourceId: state.form.rapportoSelezionato.rapporto.psd2.resourceId,
            iban: state.form.rapportoSelezionato.rapporto.ibanFormattato,
            nomeBanca: state.form.rapportoSelezionato.rapporto.nomeBanca,
            divisa: divisaRapporto,
            providerId: state.form.rapportoSelezionato.rapporto.psd2.providerId,
            accountId: state.form.rapportoSelezionato.rapporto.psd2.accountId,
        };
        datiForm.divisa = state.form.divisaSelezionata || divisaRapporto;
    }
    return datiForm;
});
exports.formElencoMovimentiCartaContoAsFiltro = (0, reselect_1.createSelector)(movimentiReducer, exports.formSaldoEMovimentiValid, (state, valid) => {
    var _a, _b;
    const datiForm = valid
        ? {
            cartaconto: true,
            offset: state.form.offset || 0,
            codiceRapporto: ((_b = (_a = state.form.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.id) || 0,
            categoriePfm: state.form.categorie,
            dataIniziale: new Date(state.form.dataDa),
            dataFinale: new Date(state.form.dataA),
            keyword: state.form.descrizione || undefined,
            importoDa: (0, helpers_1.formattedNumberToNumber)(state.form.importo) *
                IMPORTO_MOLTIPLICATORI_INTERVALLO.MIN || undefined,
            importoA: (0, helpers_1.formattedNumberToNumber)(state.form.importo) *
                IMPORTO_MOLTIPLICATORI_INTERVALLO.MAX || undefined,
            segno: state.form.entrate && state.form.uscite
                ? undefined
                : state.form.entrate
                    ? api_rest_1.CriteriRicercaMovimentiRequestInSegnoEnum.A
                    : api_rest_1.CriteriRicercaMovimentiRequestInSegnoEnum.D,
            ordinamento: state.form.sorting,
        }
        : undefined;
    return datiForm;
});
exports.formElencoMovimentiContoDepositoAsFiltro = (0, reselect_1.createSelector)(movimentiReducer, exports.formSaldoEMovimentiValid, (state, valid) => {
    var _a, _b;
    let importoNumber = state.form.importo
        ? Number(String(state.form.importo).replace(",", "."))
        : undefined;
    const form = {
        codiceRapporto: state.form.rapportoSelezionatoContoDeposito
            ? ((_b = (_a = state.form.rapportoSelezionatoContoDeposito) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.id) || 0
            : 0,
        dataIniziale: moment(state.form.dataDa).toDate(),
        dataFinale: moment(state.form.dataA).toDate(),
        keyword: state.form.descrizione || undefined,
        importoDa: importoNumber
            ? importoNumber - 0.2 * importoNumber
            : undefined,
        importoA: importoNumber ? importoNumber + 0.2 * importoNumber : undefined,
        //categoriePfm: categorie,
        segno: state.form.entrate && state.form.uscite
            ? undefined
            : state.form.entrate
                ? api_rest_1.CriteriRicercaMovimentiRequestInSegnoEnum.A
                : api_rest_1.CriteriRicercaMovimentiRequestInSegnoEnum.D,
        offset: state.form.offset,
        contoDeposito: true,
        ordinamento: state.form.sorting
            ? state.form.sorting
            : api_rest_1.CriteriRicercaMovimentiRequestInOrdinamentoEnum.D,
    };
    return form;
});
exports.filtriApplicati = (0, reselect_1.createSelector)(movimentiReducer, (state) => {
    let r = false;
    if (!!state.form.descrizione ||
        state.form.periodo != "90" ||
        !!state.form.importo ||
        state.form.entrataUscita == "A" ||
        state.form.entrataUscita == "D" ||
        state.form.entrate == false ||
        state.form.uscite == false) {
        r = true;
    }
    return r;
});
exports.parametriCartaConto = (0, reselect_1.createSelector)(movimentiReducer, (state) => {
    return state.parametriCartaConto;
});
exports.saldoComplessivo = (0, reselect_1.createSelector)(movimentiReducer, (state) => {
    return state.saldoComplessivo;
});
exports.startPinViewResponseCartaConto = (0, reselect_1.createSelector)(movimentiReducer, (state) => {
    return state.startPinViewResponse;
});
exports.getPinViewResponseCartaConto = (0, reselect_1.createSelector)(movimentiReducer, (state) => {
    return state.getPinViewResponse;
});
exports.startPanViewResponseCartaConto = (0, reselect_1.createSelector)(movimentiReducer, (state) => {
    return state.startPanViewResponse;
});
exports.getPanViewResponseCartaConto = (0, reselect_1.createSelector)(movimentiReducer, (state) => {
    return state.getPanViewResponse;
});
exports.copyPanViewSuccessCartaConto = (0, reselect_1.createSelector)(movimentiReducer, (state) => {
    return state.copyPanViewResponse;
});
