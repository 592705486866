"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DatiDocumentoToJSON = exports.DatiDocumentoFromJSONTyped = exports.DatiDocumentoFromJSON = void 0;
const runtime_1 = require("../runtime");
function DatiDocumentoFromJSON(json) {
    return DatiDocumentoFromJSONTyped(json, false);
}
exports.DatiDocumentoFromJSON = DatiDocumentoFromJSON;
function DatiDocumentoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'numero': !runtime_1.exists(json, 'numero') ? undefined : json['numero'],
        'dataEmissione': !runtime_1.exists(json, 'dataEmissione') ? undefined : json['dataEmissione'],
        'dataScadenza': !runtime_1.exists(json, 'dataScadenza') ? undefined : json['dataScadenza'],
        'luogoRilascio': !runtime_1.exists(json, 'luogoRilascio') ? undefined : json['luogoRilascio'],
    };
}
exports.DatiDocumentoFromJSONTyped = DatiDocumentoFromJSONTyped;
function DatiDocumentoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'numero': value.numero,
        'dataEmissione': value.dataEmissione,
        'dataScadenza': value.dataScadenza,
        'luogoRilascio': value.luogoRilascio,
    };
}
exports.DatiDocumentoToJSON = DatiDocumentoToJSON;
