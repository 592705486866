import React from "react";

interface Props {
  descrizione: string
  onClick: any
  disable?: boolean,
  filled?: boolean,
  ariaControls?: string,
  ariaHasPopup?: boolean | "dialog" | "menu" | "false" | "true" | "listbox" | "tree" | "grid"
}

const ButtonPrimary: React.FC<Props> = (props: Props) => {

  const {
    onClick,
    descrizione,
    disable,
    filled,
    ariaHasPopup,
    ariaControls
  } = props;

  return (
    <button
      type="button"
      className={`${filled ? "filled" : "empty"}_primary_button`}
      onClick={disable ? undefined : onClick}
      disabled={disable}
      aria-controls={ariaControls}
      aria-haspopup={ariaHasPopup}
    >
      {descrizione}
    </button>
  );
};

export default ButtonPrimary;
