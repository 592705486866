"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pnsReducer = void 0;
const __1 = require("..");
const pns_types_1 = require("./pns.types");
const initialState = {
    form: (0, pns_types_1.FormPNSDtoFromJSONTyped)({})
};
const pnsReducer = (state = initialState, action) => {
    switch (action.type) {
        case __1.EReduxActionTypes.PNS_SUBSCRIPTION_START:
            return {
                ...state
            };
        case __1.EReduxActionTypes.PNS_SUBSCRIPTION_SUCCESS:
            return {
                ...state
            };
        case __1.EReduxActionTypes.PNS_SUBSCRIPTION_ERROR:
            return {
                ...state
            };
        case __1.EReduxActionTypes.RESET_ALL: // action invocata dalla logout
            return {
                ...initialState
            };
        default:
            return state;
    }
};
exports.pnsReducer = pnsReducer;
exports.default = exports.pnsReducer;
