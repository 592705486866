"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrdineParametriAvanzatiIsinInDtoToJSON = exports.OrdineParametriAvanzatiIsinInDtoFromJSONTyped = exports.OrdineParametriAvanzatiIsinInDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function OrdineParametriAvanzatiIsinInDtoFromJSON(json) {
    return OrdineParametriAvanzatiIsinInDtoFromJSONTyped(json, false);
}
exports.OrdineParametriAvanzatiIsinInDtoFromJSON = OrdineParametriAvanzatiIsinInDtoFromJSON;
function OrdineParametriAvanzatiIsinInDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codiceRapporto': !runtime_1.exists(json, 'codiceRapporto') ? undefined : json['codiceRapporto'],
        'mercatoEsterno': !runtime_1.exists(json, 'mercatoEsterno') ? undefined : json['mercatoEsterno'],
        'codiceIsin': !runtime_1.exists(json, 'codiceIsin') ? undefined : json['codiceIsin'],
        'segno': !runtime_1.exists(json, 'segno') ? undefined : json['segno'],
        'mercatoSelezionato': !runtime_1.exists(json, 'mercatoSelezionato') ? undefined : json['mercatoSelezionato'],
        'esecuzione': !runtime_1.exists(json, 'esecuzione') ? undefined : json['esecuzione'],
        'flagMercatoChiuso': !runtime_1.exists(json, 'flagMercatoChiuso') ? undefined : json['flagMercatoChiuso'],
        'accorpa': !runtime_1.exists(json, 'accorpa') ? undefined : json['accorpa'],
    };
}
exports.OrdineParametriAvanzatiIsinInDtoFromJSONTyped = OrdineParametriAvanzatiIsinInDtoFromJSONTyped;
function OrdineParametriAvanzatiIsinInDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codiceRapporto': value.codiceRapporto,
        'mercatoEsterno': value.mercatoEsterno,
        'codiceIsin': value.codiceIsin,
        'segno': value.segno,
        'mercatoSelezionato': value.mercatoSelezionato,
        'esecuzione': value.esecuzione,
        'flagMercatoChiuso': value.flagMercatoChiuso,
        'accorpa': value.accorpa,
    };
}
exports.OrdineParametriAvanzatiIsinInDtoToJSON = OrdineParametriAvanzatiIsinInDtoToJSON;
