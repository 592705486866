"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessaggioBodyDtoToJSON = exports.MessaggioBodyDtoFromJSONTyped = exports.MessaggioBodyDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function MessaggioBodyDtoFromJSON(json) {
    return MessaggioBodyDtoFromJSONTyped(json, false);
}
exports.MessaggioBodyDtoFromJSON = MessaggioBodyDtoFromJSON;
function MessaggioBodyDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'idMessaggio': !runtime_1.exists(json, 'idMessaggio') ? undefined : json['idMessaggio'],
        'body': !runtime_1.exists(json, 'body') ? undefined : json['body'],
        'bodyADV': !runtime_1.exists(json, 'bodyADV') ? undefined : json['bodyADV'],
        'lingua': !runtime_1.exists(json, 'lingua') ? undefined : json['lingua'],
        'titolo': !runtime_1.exists(json, 'titolo') ? undefined : json['titolo'],
        'showBtnMostraAllaProssimaVolta': !runtime_1.exists(json, 'showBtnMostraAllaProssimaVolta') ? undefined : json['showBtnMostraAllaProssimaVolta'],
        'dataInserimento': !runtime_1.exists(json, 'dataInserimento') ? undefined : (new Date(json['dataInserimento'])),
        'urlLink': !runtime_1.exists(json, 'urlLink') ? undefined : json['urlLink'],
        'visualizzaTitolo': !runtime_1.exists(json, 'visualizzaTitolo') ? undefined : json['visualizzaTitolo'],
        'letto': !runtime_1.exists(json, 'letto') ? undefined : json['letto'],
        'nomeAllegato': !runtime_1.exists(json, 'nomeAllegato') ? undefined : json['nomeAllegato'],
        'impedisciAccesso': !runtime_1.exists(json, 'impedisciAccesso') ? undefined : json['impedisciAccesso'],
        'funzione': !runtime_1.exists(json, 'funzione') ? undefined : json['funzione'],
        'allegato': !runtime_1.exists(json, 'allegato') ? undefined : _1.MessaggioAllegatoDtoFromJSON(json['allegato']),
        'immagine': !runtime_1.exists(json, 'immagine') ? undefined : _1.MessaggioAllegatoDtoFromJSON(json['immagine']),
        'nascondiPresaVisione': !runtime_1.exists(json, 'nascondiPresaVisione') ? undefined : json['nascondiPresaVisione'],
    };
}
exports.MessaggioBodyDtoFromJSONTyped = MessaggioBodyDtoFromJSONTyped;
function MessaggioBodyDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'idMessaggio': value.idMessaggio,
        'body': value.body,
        'bodyADV': value.bodyADV,
        'lingua': value.lingua,
        'titolo': value.titolo,
        'showBtnMostraAllaProssimaVolta': value.showBtnMostraAllaProssimaVolta,
        'dataInserimento': value.dataInserimento === undefined ? undefined : (value.dataInserimento.toISOString()),
        'urlLink': value.urlLink,
        'visualizzaTitolo': value.visualizzaTitolo,
        'letto': value.letto,
        'nomeAllegato': value.nomeAllegato,
        'impedisciAccesso': value.impedisciAccesso,
        'funzione': value.funzione,
        'allegato': _1.MessaggioAllegatoDtoToJSON(value.allegato),
        'immagine': _1.MessaggioAllegatoDtoToJSON(value.immagine),
        'nascondiPresaVisione': value.nascondiPresaVisione,
    };
}
exports.MessaggioBodyDtoToJSON = MessaggioBodyDtoToJSON;
