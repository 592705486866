"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SezioneAcciseDtoToJSON = exports.SezioneAcciseDtoFromJSONTyped = exports.SezioneAcciseDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function SezioneAcciseDtoFromJSON(json) {
    return SezioneAcciseDtoFromJSONTyped(json, false);
}
exports.SezioneAcciseDtoFromJSON = SezioneAcciseDtoFromJSON;
function SezioneAcciseDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codiceUfficio': json['codiceUfficio'],
        'codiceAtto': json['codiceAtto'],
        'righe': !runtime_1.exists(json, 'righe') ? undefined : (json['righe'].map(_1.RigaSezioneAcciseDtoFromJSON)),
        'totalAccise': !runtime_1.exists(json, 'totalAccise') ? undefined : json['totalAccise'],
    };
}
exports.SezioneAcciseDtoFromJSONTyped = SezioneAcciseDtoFromJSONTyped;
function SezioneAcciseDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codiceUfficio': value.codiceUfficio,
        'codiceAtto': value.codiceAtto,
        'righe': value.righe === undefined ? undefined : (value.righe.map(_1.RigaSezioneAcciseDtoToJSON)),
        'totalAccise': value.totalAccise,
    };
}
exports.SezioneAcciseDtoToJSON = SezioneAcciseDtoToJSON;
