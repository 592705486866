"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TributiDtoToJSON = exports.TributiDtoFromJSONTyped = exports.TributiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function TributiDtoFromJSON(json) {
    return TributiDtoFromJSONTyped(json, false);
}
exports.TributiDtoFromJSON = TributiDtoFromJSON;
function TributiDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'tributi': !runtime_1.exists(json, 'tributi') ? undefined : (json['tributi'].map(_1.PagamentoTributiDtoFromJSON)),
    };
}
exports.TributiDtoFromJSONTyped = TributiDtoFromJSONTyped;
function TributiDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'tributi': value.tributi === undefined ? undefined : (value.tributi.map(_1.PagamentoTributiDtoToJSON)),
    };
}
exports.TributiDtoToJSON = TributiDtoToJSON;
