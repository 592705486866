"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filtriContrattiApplicati = exports.filtriComunicazioniApplicati = exports.segnaComeLetti = exports.tipologieComunicazioni = exports.parametriDocumenti = exports.numeroNuoveComunicazioni = exports.formDocumenti = exports.contratti = exports.comunicazioni = exports.rapporti = void 0;
const reselect_1 = require("reselect");
const documenti_types_1 = require("./documenti.types");
const documentiReducer = (state) => state;
exports.rapporti = (0, reselect_1.createSelector)(documentiReducer, (state) => {
    return state.rapporti;
});
exports.comunicazioni = (0, reselect_1.createSelector)(documentiReducer, (state) => {
    return state.comunicazioni;
});
exports.contratti = (0, reselect_1.createSelector)(documentiReducer, (state) => {
    return state.comunicazioni;
});
exports.formDocumenti = (0, reselect_1.createSelector)(documentiReducer, (state) => {
    return state.form;
});
exports.numeroNuoveComunicazioni = (0, reselect_1.createSelector)(documentiReducer, (state) => {
    return state.numeroNuoveComunicazioni;
});
exports.parametriDocumenti = (0, reselect_1.createSelector)(documentiReducer, (state) => {
    return state.parametri;
});
exports.tipologieComunicazioni = (0, reselect_1.createSelector)(documentiReducer, (state) => {
    return state.tipologiaComunicazioni;
});
exports.segnaComeLetti = (0, reselect_1.createSelector)(documentiReducer, (state) => {
    return state.segnaComeLetti;
});
exports.filtriComunicazioniApplicati = (0, reselect_1.createSelector)(documentiReducer, (state) => {
    let result = true;
    if (state.form.tuttiIRapporti == true &&
        state.form.fonteDocumento == documenti_types_1.DocumentiFonteDocumento.Comunicazioni &&
        (state.form.tipologiaComunicazione == '' || !state.form.tipologiaComunicazione) &&
        state.form.intervallo == documenti_types_1.DocumentiTipoIntervallo.Ultimi5 &&
        state.form.visualizzaSoloNonLetti == false) {
        result = false;
    }
    return result;
});
exports.filtriContrattiApplicati = (0, reselect_1.createSelector)(documentiReducer, (state) => {
    let result = true;
    if (state.form.tuttiIRapporti == true &&
        state.form.dataDa == state.parametri.comunicazioniInfoDataIniziale &&
        state.form.dataA == state.parametri.comunicazioniInfoDataFinale) {
        result = false;
    }
    return result;
});
