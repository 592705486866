"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EsitoAutorizzazioneDisposizionePrestitoDtoStatoEnum = exports.EsitoAutorizzazioneDisposizionePrestitoDtoToJSON = exports.EsitoAutorizzazioneDisposizionePrestitoDtoFromJSONTyped = exports.EsitoAutorizzazioneDisposizionePrestitoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function EsitoAutorizzazioneDisposizionePrestitoDtoFromJSON(json) {
    return EsitoAutorizzazioneDisposizionePrestitoDtoFromJSONTyped(json, false);
}
exports.EsitoAutorizzazioneDisposizionePrestitoDtoFromJSON = EsitoAutorizzazioneDisposizionePrestitoDtoFromJSON;
function EsitoAutorizzazioneDisposizionePrestitoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
        'codiceErrore': !runtime_1.exists(json, 'codiceErrore') ? undefined : json['codiceErrore'],
        'informazioniAutenticazioneDispositiva': !runtime_1.exists(json, 'informazioniAutenticazioneDispositiva') ? undefined : _1.InformazioniAutenticazioneDispositivaDtoFromJSON(json['informazioniAutenticazioneDispositiva']),
        'disposizione': !runtime_1.exists(json, 'disposizione') ? undefined : _1.PrestitoDtoFromJSON(json['disposizione']),
    };
}
exports.EsitoAutorizzazioneDisposizionePrestitoDtoFromJSONTyped = EsitoAutorizzazioneDisposizionePrestitoDtoFromJSONTyped;
function EsitoAutorizzazioneDisposizionePrestitoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'stato': value.stato,
        'codiceErrore': value.codiceErrore,
        'informazioniAutenticazioneDispositiva': _1.InformazioniAutenticazioneDispositivaDtoToJSON(value.informazioniAutenticazioneDispositiva),
        'disposizione': _1.PrestitoDtoToJSON(value.disposizione),
    };
}
exports.EsitoAutorizzazioneDisposizionePrestitoDtoToJSON = EsitoAutorizzazioneDisposizionePrestitoDtoToJSON;
/**
* @export
* @enum {string}
*/
var EsitoAutorizzazioneDisposizionePrestitoDtoStatoEnum;
(function (EsitoAutorizzazioneDisposizionePrestitoDtoStatoEnum) {
    EsitoAutorizzazioneDisposizionePrestitoDtoStatoEnum["OK"] = "OK";
    EsitoAutorizzazioneDisposizionePrestitoDtoStatoEnum["CREDENZIALE_ERRATA"] = "CREDENZIALE_ERRATA";
    EsitoAutorizzazioneDisposizionePrestitoDtoStatoEnum["ATTENDERE"] = "ATTENDERE";
    EsitoAutorizzazioneDisposizionePrestitoDtoStatoEnum["ACCOUNT_BLOCCATO"] = "ACCOUNT_BLOCCATO";
    EsitoAutorizzazioneDisposizionePrestitoDtoStatoEnum["ACCP"] = "ACCP";
    EsitoAutorizzazioneDisposizionePrestitoDtoStatoEnum["ACSC"] = "ACSC";
    EsitoAutorizzazioneDisposizionePrestitoDtoStatoEnum["ACSP"] = "ACSP";
    EsitoAutorizzazioneDisposizionePrestitoDtoStatoEnum["ACTC"] = "ACTC";
    EsitoAutorizzazioneDisposizionePrestitoDtoStatoEnum["ACWC"] = "ACWC";
    EsitoAutorizzazioneDisposizionePrestitoDtoStatoEnum["ACWP"] = "ACWP";
    EsitoAutorizzazioneDisposizionePrestitoDtoStatoEnum["RCVD"] = "RCVD";
    EsitoAutorizzazioneDisposizionePrestitoDtoStatoEnum["PDNG"] = "PDNG";
    EsitoAutorizzazioneDisposizionePrestitoDtoStatoEnum["RJCT"] = "RJCT";
    EsitoAutorizzazioneDisposizionePrestitoDtoStatoEnum["CANC"] = "CANC";
})(EsitoAutorizzazioneDisposizionePrestitoDtoStatoEnum = exports.EsitoAutorizzazioneDisposizionePrestitoDtoStatoEnum || (exports.EsitoAutorizzazioneDisposizionePrestitoDtoStatoEnum = {}));
