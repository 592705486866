"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AtmDtoTypeAtmEnum = exports.AtmDtoToJSON = exports.AtmDtoFromJSONTyped = exports.AtmDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function AtmDtoFromJSON(json) {
    return AtmDtoFromJSONTyped(json, false);
}
exports.AtmDtoFromJSON = AtmDtoFromJSON;
function AtmDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'title': !runtime_1.exists(json, 'title') ? undefined : json['title'],
        'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
        'typeAtm': !runtime_1.exists(json, 'typeAtm') ? undefined : json['typeAtm'],
        'latitude': !runtime_1.exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !runtime_1.exists(json, 'longitude') ? undefined : json['longitude'],
        'nomeIstituto': !runtime_1.exists(json, 'nomeIstituto') ? undefined : json['nomeIstituto'],
    };
}
exports.AtmDtoFromJSONTyped = AtmDtoFromJSONTyped;
function AtmDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'title': value.title,
        'description': value.description,
        'typeAtm': value.typeAtm,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'nomeIstituto': value.nomeIstituto,
    };
}
exports.AtmDtoToJSON = AtmDtoToJSON;
/**
* @export
* @enum {string}
*/
var AtmDtoTypeAtmEnum;
(function (AtmDtoTypeAtmEnum) {
    AtmDtoTypeAtmEnum["FIL_ATM"] = "FIL_ATM";
    AtmDtoTypeAtmEnum["ATM"] = "ATM";
    AtmDtoTypeAtmEnum["FIL"] = "FIL";
})(AtmDtoTypeAtmEnum = exports.AtmDtoTypeAtmEnum || (exports.AtmDtoTypeAtmEnum = {}));
