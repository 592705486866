"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BonificoSepaDtoStatoDisposizioneEnum = exports.BonificoSepaDtoToJSON = exports.BonificoSepaDtoFromJSONTyped = exports.BonificoSepaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function BonificoSepaDtoFromJSON(json) {
    return BonificoSepaDtoFromJSONTyped(json, false);
}
exports.BonificoSepaDtoFromJSON = BonificoSepaDtoFromJSON;
function BonificoSepaDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'contoAddebito': json['contoAddebito'],
        'naturaMovimento': json['naturaMovimento'],
        'motivazionePagamento': json['motivazionePagamento'],
        'motivazionePagamentoDesc': !runtime_1.exists(json, 'motivazionePagamentoDesc') ? undefined : json['motivazionePagamentoDesc'],
        'importo': json['importo'],
        'nomeBeneficiario': json['nomeBeneficiario'],
        'ibanBeneficiario': json['ibanBeneficiario'],
        'ibanFormattato': !runtime_1.exists(json, 'ibanFormattato') ? undefined : json['ibanFormattato'],
        'causale': json['causale'],
        'condivisione': !runtime_1.exists(json, 'condivisione') ? undefined : (json['condivisione'].map(_1.CondivisioneDtoFromJSON)),
        'bozza': !runtime_1.exists(json, 'bozza') ? undefined : _1.BozzaDtoFromJSON(json['bozza']),
        'inviato': !runtime_1.exists(json, 'inviato') ? undefined : _1.InviatoDtoFromJSON(json['inviato']),
        'datiPagamento': !runtime_1.exists(json, 'datiPagamento') ? undefined : _1.DatiPagamentoCommonDtoFromJSON(json['datiPagamento']),
        'datiEsecuzioneBonifico': !runtime_1.exists(json, 'datiEsecuzioneBonifico') ? undefined : _1.DatiEsecuzioneBonificoDtoFromJSON(json['datiEsecuzioneBonifico']),
        'dataEsecuzione': (new Date(json['dataEsecuzione'])),
        'riferimentoOrdinante': !runtime_1.exists(json, 'riferimentoOrdinante') ? undefined : json['riferimentoOrdinante'],
        'numeroOperazione': !runtime_1.exists(json, 'numeroOperazione') ? undefined : json['numeroOperazione'],
        'contoAddebitoIban': json['contoAddebitoIban'],
        'contoAddebitoAlias': !runtime_1.exists(json, 'contoAddebitoAlias') ? undefined : json['contoAddebitoAlias'],
        'idContattoBeneficiario': !runtime_1.exists(json, 'idContattoBeneficiario') ? undefined : json['idContattoBeneficiario'],
        'indirizzo': !runtime_1.exists(json, 'indirizzo') ? undefined : json['indirizzo'],
        'comune': !runtime_1.exists(json, 'comune') ? undefined : json['comune'],
        'cap': !runtime_1.exists(json, 'cap') ? undefined : json['cap'],
        'provincia': !runtime_1.exists(json, 'provincia') ? undefined : json['provincia'],
        'paese': !runtime_1.exists(json, 'paese') ? undefined : json['paese'],
        'datiFiscali': !runtime_1.exists(json, 'datiFiscali') ? undefined : _1.DatiFiscaliDtoFromJSON(json['datiFiscali']),
        'statoDisposizione': !runtime_1.exists(json, 'statoDisposizione') ? undefined : json['statoDisposizione'],
        'idBozzaCollegata': !runtime_1.exists(json, 'idBozzaCollegata') ? undefined : json['idBozzaCollegata'],
        'raggruppamentoMovimenti': !runtime_1.exists(json, 'raggruppamentoMovimenti') ? undefined : json['raggruppamentoMovimenti'],
        'abi': !runtime_1.exists(json, 'abi') ? undefined : json['abi'],
        'cab': !runtime_1.exists(json, 'cab') ? undefined : json['cab'],
        'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
        'dettOrdi': !runtime_1.exists(json, 'dettOrdi') ? undefined : _1.DatiDettOrdiPerContoDiDtoFromJSON(json['dettOrdi']),
        'perContoDi': !runtime_1.exists(json, 'perContoDi') ? undefined : _1.DatiDettOrdiPerContoDiDtoFromJSON(json['perContoDi']),
        'tipologiaMovimento': !runtime_1.exists(json, 'tipologiaMovimento') ? undefined : json['tipologiaMovimento'],
        'idOneClickCollegata': !runtime_1.exists(json, 'idOneClickCollegata') ? undefined : json['idOneClickCollegata'],
        'ordinanteIntestazione': !runtime_1.exists(json, 'ordinanteIntestazione') ? undefined : json['ordinanteIntestazione'],
        'urlRedirect': !runtime_1.exists(json, 'urlRedirect') ? undefined : json['urlRedirect'],
        'aggiungiRubrica': !runtime_1.exists(json, 'aggiungiRubrica') ? undefined : json['aggiungiRubrica'],
        'aggiungi1click': !runtime_1.exists(json, 'aggiungi1click') ? undefined : json['aggiungi1click'],
        'aggiungiWhitelist': !runtime_1.exists(json, 'aggiungiWhitelist') ? undefined : json['aggiungiWhitelist'],
        'alias1Click': !runtime_1.exists(json, 'alias1Click') ? undefined : json['alias1Click'],
        'statoReport': !runtime_1.exists(json, 'statoReport') ? undefined : json['statoReport'],
        'psd2PaymentDto': !runtime_1.exists(json, 'psd2PaymentDto') ? undefined : _1.Psd2PaymentDtoFromJSON(json['psd2PaymentDto']),
    };
}
exports.BonificoSepaDtoFromJSONTyped = BonificoSepaDtoFromJSONTyped;
function BonificoSepaDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'contoAddebito': value.contoAddebito,
        'naturaMovimento': value.naturaMovimento,
        'motivazionePagamento': value.motivazionePagamento,
        'motivazionePagamentoDesc': value.motivazionePagamentoDesc,
        'importo': value.importo,
        'nomeBeneficiario': value.nomeBeneficiario,
        'ibanBeneficiario': value.ibanBeneficiario,
        'ibanFormattato': value.ibanFormattato,
        'causale': value.causale,
        'condivisione': value.condivisione === undefined ? undefined : (value.condivisione.map(_1.CondivisioneDtoToJSON)),
        'bozza': _1.BozzaDtoToJSON(value.bozza),
        'inviato': _1.InviatoDtoToJSON(value.inviato),
        'datiPagamento': _1.DatiPagamentoCommonDtoToJSON(value.datiPagamento),
        'datiEsecuzioneBonifico': _1.DatiEsecuzioneBonificoDtoToJSON(value.datiEsecuzioneBonifico),
        'dataEsecuzione': (value.dataEsecuzione.toISOString()),
        'riferimentoOrdinante': value.riferimentoOrdinante,
        'numeroOperazione': value.numeroOperazione,
        'contoAddebitoIban': value.contoAddebitoIban,
        'contoAddebitoAlias': value.contoAddebitoAlias,
        'idContattoBeneficiario': value.idContattoBeneficiario,
        'indirizzo': value.indirizzo,
        'comune': value.comune,
        'cap': value.cap,
        'provincia': value.provincia,
        'paese': value.paese,
        'datiFiscali': _1.DatiFiscaliDtoToJSON(value.datiFiscali),
        'statoDisposizione': value.statoDisposizione,
        'idBozzaCollegata': value.idBozzaCollegata,
        'raggruppamentoMovimenti': value.raggruppamentoMovimenti,
        'abi': value.abi,
        'cab': value.cab,
        'stato': value.stato,
        'dettOrdi': _1.DatiDettOrdiPerContoDiDtoToJSON(value.dettOrdi),
        'perContoDi': _1.DatiDettOrdiPerContoDiDtoToJSON(value.perContoDi),
        'tipologiaMovimento': value.tipologiaMovimento,
        'idOneClickCollegata': value.idOneClickCollegata,
        'ordinanteIntestazione': value.ordinanteIntestazione,
        'urlRedirect': value.urlRedirect,
        'aggiungiRubrica': value.aggiungiRubrica,
        'aggiungi1click': value.aggiungi1click,
        'aggiungiWhitelist': value.aggiungiWhitelist,
        'alias1Click': value.alias1Click,
        'statoReport': value.statoReport,
        'psd2PaymentDto': _1.Psd2PaymentDtoToJSON(value.psd2PaymentDto),
    };
}
exports.BonificoSepaDtoToJSON = BonificoSepaDtoToJSON;
/**
* @export
* @enum {string}
*/
var BonificoSepaDtoStatoDisposizioneEnum;
(function (BonificoSepaDtoStatoDisposizioneEnum) {
    BonificoSepaDtoStatoDisposizioneEnum["AUTORIZZATO"] = "AUTORIZZATO";
    BonificoSepaDtoStatoDisposizioneEnum["AUTORIZZATO_NON_REV"] = "AUTORIZZATO_NON_REV";
    BonificoSepaDtoStatoDisposizioneEnum["NON_AUTORIZZATO"] = "NON_AUTORIZZATO";
    BonificoSepaDtoStatoDisposizioneEnum["IN_CORSO"] = "IN_CORSO";
    BonificoSepaDtoStatoDisposizioneEnum["INVIATO"] = "INVIATO";
    BonificoSepaDtoStatoDisposizioneEnum["ESITO_INCERTO"] = "ESITO_INCERTO";
    BonificoSepaDtoStatoDisposizioneEnum["REVOCATO"] = "REVOCATO";
    BonificoSepaDtoStatoDisposizioneEnum["DA_AUTORIZZARE"] = "DA_AUTORIZZARE";
    BonificoSepaDtoStatoDisposizioneEnum["BOZZA"] = "BOZZA";
    BonificoSepaDtoStatoDisposizioneEnum["SOSPESO"] = "SOSPESO";
    BonificoSepaDtoStatoDisposizioneEnum["STORNATO"] = "STORNATO";
    BonificoSepaDtoStatoDisposizioneEnum["PAGATO"] = "PAGATO";
    BonificoSepaDtoStatoDisposizioneEnum["ANNULLATO"] = "ANNULLATO";
})(BonificoSepaDtoStatoDisposizioneEnum = exports.BonificoSepaDtoStatoDisposizioneEnum || (exports.BonificoSepaDtoStatoDisposizioneEnum = {}));
