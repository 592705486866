"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BozzaDtoToJSON = exports.BozzaDtoFromJSONTyped = exports.BozzaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function BozzaDtoFromJSON(json) {
    return BozzaDtoFromJSONTyped(json, false);
}
exports.BozzaDtoFromJSON = BozzaDtoFromJSON;
function BozzaDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'importo': !runtime_1.exists(json, 'importo') ? undefined : json['importo'],
        'intestazione': !runtime_1.exists(json, 'intestazione') ? undefined : json['intestazione'],
        'dataUltimaModifica': (new Date(json['dataUltimaModifica'])),
        'divisa': !runtime_1.exists(json, 'divisa') ? undefined : json['divisa'],
        'importoString': !runtime_1.exists(json, 'importoString') ? undefined : json['importoString'],
        'aliasRapporto': !runtime_1.exists(json, 'aliasRapporto') ? undefined : json['aliasRapporto'],
        'descrizione': !runtime_1.exists(json, 'descrizione') ? undefined : json['descrizione'],
    };
}
exports.BozzaDtoFromJSONTyped = BozzaDtoFromJSONTyped;
function BozzaDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'importo': value.importo,
        'intestazione': value.intestazione,
        'dataUltimaModifica': (value.dataUltimaModifica.toISOString()),
        'divisa': value.divisa,
        'importoString': value.importoString,
        'aliasRapporto': value.aliasRapporto,
        'descrizione': value.descrizione,
    };
}
exports.BozzaDtoToJSON = BozzaDtoToJSON;
