"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DossierPositionLevelBODtoToJSON = exports.DossierPositionLevelBODtoFromJSONTyped = exports.DossierPositionLevelBODtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function DossierPositionLevelBODtoFromJSON(json) {
    return DossierPositionLevelBODtoFromJSONTyped(json, false);
}
exports.DossierPositionLevelBODtoFromJSON = DossierPositionLevelBODtoFromJSON;
function DossierPositionLevelBODtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'bankCode': !runtime_1.exists(json, 'bankCode') ? undefined : json['bankCode'],
        'code': !runtime_1.exists(json, 'code') ? undefined : json['code'],
        'ctv': !runtime_1.exists(json, 'ctv') ? undefined : json['ctv'],
        'ctvAcquisto': !runtime_1.exists(json, 'ctvAcquisto') ? undefined : json['ctvAcquisto'],
        'ctvMercato': !runtime_1.exists(json, 'ctvMercato') ? undefined : json['ctvMercato'],
        'currency': !runtime_1.exists(json, 'currency') ? undefined : json['currency'],
        'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
        'isin': !runtime_1.exists(json, 'isin') ? undefined : json['isin'],
        'latestPrice': !runtime_1.exists(json, 'latestPrice') ? undefined : json['latestPrice'],
        'latestPriceDate': !runtime_1.exists(json, 'latestPriceDate') ? undefined : (new Date(json['latestPriceDate'])),
        'marketSquare': !runtime_1.exists(json, 'marketSquare') ? undefined : json['marketSquare'],
        'message': !runtime_1.exists(json, 'message') ? undefined : json['message'],
        'peso': !runtime_1.exists(json, 'peso') ? undefined : json['peso'],
        'plusMinus': !runtime_1.exists(json, 'plusMinus') ? undefined : json['plusMinus'],
        'plusMinusEuro': !runtime_1.exists(json, 'plusMinusEuro') ? undefined : json['plusMinusEuro'],
        'plusMinusPerc': !runtime_1.exists(json, 'plusMinusPerc') ? undefined : json['plusMinusPerc'],
        'pmc': !runtime_1.exists(json, 'pmc') ? undefined : json['pmc'],
        'productCode': !runtime_1.exists(json, 'productCode') ? undefined : json['productCode'],
        'quantity': !runtime_1.exists(json, 'quantity') ? undefined : json['quantity'],
        'type': !runtime_1.exists(json, 'type') ? undefined : json['type'],
        'userId': !runtime_1.exists(json, 'userId') ? undefined : json['userId'],
    };
}
exports.DossierPositionLevelBODtoFromJSONTyped = DossierPositionLevelBODtoFromJSONTyped;
function DossierPositionLevelBODtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'bankCode': value.bankCode,
        'code': value.code,
        'ctv': value.ctv,
        'ctvAcquisto': value.ctvAcquisto,
        'ctvMercato': value.ctvMercato,
        'currency': value.currency,
        'description': value.description,
        'isin': value.isin,
        'latestPrice': value.latestPrice,
        'latestPriceDate': value.latestPriceDate === undefined ? undefined : (value.latestPriceDate.toISOString()),
        'marketSquare': value.marketSquare,
        'message': value.message,
        'peso': value.peso,
        'plusMinus': value.plusMinus,
        'plusMinusEuro': value.plusMinusEuro,
        'plusMinusPerc': value.plusMinusPerc,
        'pmc': value.pmc,
        'productCode': value.productCode,
        'quantity': value.quantity,
        'type': value.type,
        'userId': value.userId,
    };
}
exports.DossierPositionLevelBODtoToJSON = DossierPositionLevelBODtoToJSON;
