"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssicurazioneDtoToJSON = exports.AssicurazioneDtoFromJSONTyped = exports.AssicurazioneDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function AssicurazioneDtoFromJSON(json) {
    return AssicurazioneDtoFromJSONTyped(json, false);
}
exports.AssicurazioneDtoFromJSON = AssicurazioneDtoFromJSON;
function AssicurazioneDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codiceRapporto': !runtime_1.exists(json, 'codiceRapporto') ? undefined : json['codiceRapporto'],
        'ndg': !runtime_1.exists(json, 'ndg') ? undefined : json['ndg'],
        'date': !runtime_1.exists(json, 'date') ? undefined : _1.DatesFromJSON(json['date']),
        'codiceSocieta': !runtime_1.exists(json, 'codiceSocieta') ? undefined : json['codiceSocieta'],
        'prodotto': !runtime_1.exists(json, 'prodotto') ? undefined : _1.ProdottoDtoFromJSON(json['prodotto']),
        'numeroPolizza': !runtime_1.exists(json, 'numeroPolizza') ? undefined : json['numeroPolizza'],
        'numeroProposta': !runtime_1.exists(json, 'numeroProposta') ? undefined : json['numeroProposta'],
        'ramo': !runtime_1.exists(json, 'ramo') ? undefined : json['ramo'],
        'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
        'frazionamento': !runtime_1.exists(json, 'frazionamento') ? undefined : json['frazionamento'],
        'importi': !runtime_1.exists(json, 'importi') ? undefined : _1.ImportiDtoFromJSON(json['importi']),
        'convenzione': !runtime_1.exists(json, 'convenzione') ? undefined : json['convenzione'],
        'tabellaFondi': !runtime_1.exists(json, 'tabellaFondi') ? undefined : (json['tabellaFondi'].map(_1.FondoDtoFromJSON)),
        'ordinanteIntestazione': !runtime_1.exists(json, 'ordinanteIntestazione') ? undefined : json['ordinanteIntestazione'],
        'contoAddebitoIban': !runtime_1.exists(json, 'contoAddebitoIban') ? undefined : json['contoAddebitoIban'],
    };
}
exports.AssicurazioneDtoFromJSONTyped = AssicurazioneDtoFromJSONTyped;
function AssicurazioneDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codiceRapporto': value.codiceRapporto,
        'ndg': value.ndg,
        'date': _1.DatesToJSON(value.date),
        'codiceSocieta': value.codiceSocieta,
        'prodotto': _1.ProdottoDtoToJSON(value.prodotto),
        'numeroPolizza': value.numeroPolizza,
        'numeroProposta': value.numeroProposta,
        'ramo': value.ramo,
        'stato': value.stato,
        'frazionamento': value.frazionamento,
        'importi': _1.ImportiDtoToJSON(value.importi),
        'convenzione': value.convenzione,
        'tabellaFondi': value.tabellaFondi === undefined ? undefined : (value.tabellaFondi.map(_1.FondoDtoToJSON)),
        'ordinanteIntestazione': value.ordinanteIntestazione,
        'contoAddebitoIban': value.contoAddebitoIban,
    };
}
exports.AssicurazioneDtoToJSON = AssicurazioneDtoToJSON;
