"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.esportaDetaglioRibaPDF = exports.setCodFiscalePIvaSelezionato = exports.resetRiba = exports.setRibaSelectedAllAvvisi = exports.setRibaSelectedAvvisi = exports.setRibaFormSubmitted = exports.setRibaStep = exports.setRibaRapportoSelezionato = exports.esportaElencoRibaEXCEL = exports.esportaElencoRibaPDF = exports.getElencoRiba = exports.autorizzaRiba = exports.controlloRiba = exports.getInfoAutenticazioneRiba = exports.getParametriRiba = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
const riba_selectors_1 = require("./riba.selectors");
/* get parametri riba - START */
function getParametriRibaStart() {
    return {
        type: __1.EReduxActionTypes.RIBA_GET_PARAMETRI_START
    };
}
function getParametriRibaSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.RIBA_GET_PARAMETRI_SUCCESS,
        payload
    };
}
function generalError(value, showGenericModalError) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value, showGenericModalError));
    };
}
const getParametriRiba = () => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getParametriRibaStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/parametri_riba.json')).then(parametri => {
            dispatch(getParametriRibaSuccess((0, api_rest_1.ParametriRibaDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.RiBaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametriRiba().then((response) => {
                dispatch(getParametriRibaSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getParametriRiba = getParametriRiba;
/* get parametri riba - FINE */
/* get info autenticazione riba - START */
function getInfoAutenticazioneRibaStart() {
    return {
        type: __1.EReduxActionTypes.RIBA_GET_INFO_AUTENTICAZIONE_START
    };
}
function getInfoAutenticazioneRibaSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.RIBA_GET_INFO_AUTENTICAZIONE_SUCCESS,
        payload
    };
}
const getInfoAutenticazioneRiba = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getInfoAutenticazioneRibaStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/autenticazione-bonifico.json')).then(parametri => {
            dispatch(getInfoAutenticazioneRibaSuccess((0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const request = {
            idDispositiva,
            chiamataUscente,
            eseguiInit,
            offline: !!offline
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.RiBaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).authenticationInformationRiba(request).then((response) => {
                dispatch(getInfoAutenticazioneRibaSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getInfoAutenticazioneRiba = getInfoAutenticazioneRiba;
/* get info autenticazione riba - FINE */
/* controllo riba - START */
function controlloRibaStart() {
    return {
        type: __1.EReduxActionTypes.RIBA_CONTROLLO_START
    };
}
function controlloRibaSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.RIBA_CONTROLLO_SUCCESS,
        payload
    };
}
const controlloRiba = (controlloRibaRequest) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(controlloRibaStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/controllo_bonifico.json')).then(parametri => {
            dispatch(controlloRibaSuccess((0, api_rest_1.RibaDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const requestIn = controlloRibaRequest || (0, riba_selectors_1.buildRiba)(getState().riba);
        const request = {
            ribaRequestInDto: requestIn
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.RiBaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).controlloRiba(request).then((response) => {
                dispatch(controlloRibaSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.controlloRiba = controlloRiba;
/* controllo riba - FINE */
/* autorizza riba - START */
function autorizzaRibaStart() {
    return {
        type: __1.EReduxActionTypes.RIBA_AUTORIZZA_START
    };
}
function autorizzaRibaSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.RIBA_AUTORIZZA_SUCCESS,
        payload
    };
}
const autorizzaRiba = (idDispositiva, controlloRibaRequest) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(autorizzaRibaStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/controllo_bonifico.json')).then(parametri => {
            dispatch(autorizzaRibaSuccess((0, api_rest_1.EsitoAutorizzazioneDisposizioneRibaDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const request = {
            idDispositiva,
            credenzialeDispositivaDto: controlloRibaRequest
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.RiBaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).authorizeRiba(request).then((response) => {
                dispatch(autorizzaRibaSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error), false));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.autorizzaRiba = autorizzaRiba;
/* autorizza riba - FINE */
/* get elenco riba - START */
function getElencoRibaStart() {
    return {
        type: __1.EReduxActionTypes.RIBA_GET_ELENCO_START
    };
}
function getElencoRibaSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.RIBA_GET_ELENCO_SUCCESS,
        payload
    };
}
const getElencoRiba = (filtroElencoAvvisi) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getElencoRibaStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/controllo_bonifico.json')).then(parametri => {
            dispatch(getElencoRibaSuccess([]));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const requestIn = filtroElencoAvvisi || (0, riba_selectors_1.formRibaAsFiltro)(getState().riba);
        const request = {
            filtroElencoAvvisiDto: requestIn
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.RiBaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getElenco(request).then((response) => {
                dispatch(getElencoRibaSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getElencoRiba = getElencoRiba;
/* get elenco riba - FINE */
/* esporta elenco PDF riba - START */
function esportaElencoRibaPDFStart() {
    return {
        type: __1.EReduxActionTypes.RIBA_ESPORTA_PDF_ELENCO_START
    };
}
function esportaElencoRibaPDFSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.RIBA_ESPORTA_PDF_ELENCO_SUCCESS,
        payload
    };
}
const esportaElencoRibaPDF = (share, title, message) => async (dispatch, getState, extraArguments) => {
    dispatch(esportaElencoRibaPDFStart());
    dispatch((0, general_actions_1.setLoader)(true));
    if (extraArguments.mock) {
    }
    else {
        const request = {
            type: api_rest_1.ExportElencoRibaTypeEnum.pdf,
            filtroElencoAvvisiDto: (0, riba_selectors_1.formRibaAsFiltro)(getState().riba)
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.RiBaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf')).exportElencoRiba(request).then((response) => {
                if (extraArguments.platform === 'desktop') {
                    (0, helpers_1.hadlePdfResponse)(response, (url) => dispatch((0, general_actions_1.popUpBlockedPdf)(url)));
                }
                if (extraArguments.platform === 'mobile') {
                    (0, helpers_1.download_sharePDF)(extraArguments, 'ricariche.pdf', response, share, title, message);
                }
                dispatch(esportaElencoRibaPDFSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.esportaElencoRibaPDF = esportaElencoRibaPDF;
/* esporta elenco PDF riba - FINE */
/* esporta elenco EXCEL riba - START */
function esportaElencoRibaEXCELStart() {
    return {
        type: __1.EReduxActionTypes.RIBA_ESPORTA_PDF_ELENCO_START
    };
}
function esportaElencoRibaEXCELSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.RIBA_ESPORTA_PDF_ELENCO_SUCCESS,
        payload
    };
}
const esportaElencoRibaEXCEL = (datiForm, share, title, message) => async (dispatch, getState, extraArguments) => {
    dispatch(esportaElencoRibaEXCELStart());
    dispatch((0, general_actions_1.setLoader)(true));
    if (extraArguments.mock) {
        /* TODO: vedere nella parte mockata cosa restituire
          import('../../mock/lista_movimenti.json').then(parametri => {
            dispatch(esportaElencoRibaEXCELSuccess(ListaMovimentiDtoFromJSON(parametri)));
          });
          */
    }
    else {
        const requestIn = datiForm || (0, riba_selectors_1.formRibaAsFiltro)(getState().riba);
        if (requestIn) {
            const request = {
                type: api_rest_1.ExportElencoRibaTypeEnum.excel,
                filtroElencoAvvisiDto: requestIn
            };
            extraArguments.getItem('jwt').then((jwt) => {
                new api_rest_1.RiBaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/vnd.ms-excel')).exportElencoRiba(request).then((response) => {
                    if (extraArguments.platform === 'desktop') {
                        (0, helpers_1.hadleExcelResponse)(response, 'RiBa.xlsx');
                    }
                    if (extraArguments.platform === 'mobile') {
                        (0, helpers_1.download_shareXLS)(extraArguments, 'RiBa.xlsx', response, share);
                    }
                    dispatch(esportaElencoRibaEXCELSuccess(response));
                }).catch(async (error) => {
                    dispatch(generalError(await (0, helpers_1.formatError)(error)));
                }).finally(() => {
                    dispatch((0, general_actions_1.setLoader)(false));
                });
            });
        }
    }
};
exports.esportaElencoRibaEXCEL = esportaElencoRibaEXCEL;
/* esporta elenco EXCEL riba- FINE */
/* set form values - START */
function setRibaRapportoSelezionato(rapportoSelezionato) {
    return {
        type: __1.EReduxActionTypes.RIBA_SET_RAPPORTO_SELEZIONATO,
        payload: rapportoSelezionato
    };
}
exports.setRibaRapportoSelezionato = setRibaRapportoSelezionato;
function setRibaStep(value) {
    return {
        type: __1.EReduxActionTypes.RIBA_SET_STEP,
        payload: value
    };
}
exports.setRibaStep = setRibaStep;
function setRibaFormSubmitted(value) {
    return {
        type: __1.EReduxActionTypes.RIBA_SET_FORM_SUBMITTED,
        payload: value
    };
}
exports.setRibaFormSubmitted = setRibaFormSubmitted;
function setRibaSelectedAvvisi(selected, numeroAvviso) {
    return {
        type: __1.EReduxActionTypes.RIBA_SET_SELECTED_AVVISI,
        payload: {
            selected,
            numeroAvviso
        }
    };
}
exports.setRibaSelectedAvvisi = setRibaSelectedAvvisi;
function setRibaSelectedAllAvvisi(selectedAll) {
    return {
        type: __1.EReduxActionTypes.RIBA_SET_SELECTED_ALL_AVVISI,
        payload: selectedAll
    };
}
exports.setRibaSelectedAllAvvisi = setRibaSelectedAllAvvisi;
function resetRiba() {
    return {
        type: __1.EReduxActionTypes.RIBA_RESET
    };
}
exports.resetRiba = resetRiba;
function setCodFiscalePIvaSelezionato(codFiscaleIvaSelezionato) {
    return {
        type: __1.EReduxActionTypes.RIBA_SET_COD_FISCALE_PARTITA_IVA,
        payload: codFiscaleIvaSelezionato
    };
}
exports.setCodFiscalePIvaSelezionato = setCodFiscalePIvaSelezionato;
/* set form values - FINE */
/* esporta detaglio PDF riba - START */
function esportaDetaglioRibaPDFStart() {
    return {
        type: __1.EReduxActionTypes.RIBA_ESPORTA_PDF_DETAGLIO_START
    };
}
function esportaDetaglioRibaPDFSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.RIBA_ESPORTA_PDF_DETAGLIO_SUCCESS,
        payload
    };
}
const esportaDetaglioRibaPDF = (datiForm, share, title, message) => async (dispatch, getState, extraArguments) => {
    dispatch(esportaDetaglioRibaPDFStart());
    dispatch((0, general_actions_1.setLoader)(true));
    if (extraArguments.mock) {
        /* TODO: vedere nella parte mockata cosa restituire
          import('../../mock/lista_movimenti.json').then(parametri => {
            dispatch(esportaDetaglioRibaPDFSuccess(ListaMovimentiDtoFromJSON(parametri)));
          });
          */
    }
    else {
        const requestIn = datiForm;
        if (requestIn) {
            const request = {
                ribaDto: requestIn
            };
            extraArguments.getItem('jwt').then((jwt) => {
                new api_rest_1.RiBaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf')).exportDettaglioRiba(request).then((response) => {
                    if (extraArguments.platform === 'desktop') {
                        (0, helpers_1.hadlePdfResponse)(response, (url) => dispatch((0, general_actions_1.popUpBlockedPdf)(url)));
                    }
                    if (extraArguments.platform === 'mobile') {
                        (0, helpers_1.download_sharePDF)(extraArguments, 'ricevuta.pdf', response, share, title, message);
                    }
                    dispatch(esportaDetaglioRibaPDFSuccess(response));
                }).catch(async (error) => {
                    dispatch(generalError(await (0, helpers_1.formatError)(error)));
                }).finally(() => {
                    dispatch((0, general_actions_1.setLoader)(false));
                });
            });
        }
    }
};
exports.esportaDetaglioRibaPDF = esportaDetaglioRibaPDF;
/* esporta detaglio PDF riba - FINE */
