"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CofidisUtenteDtoToJSON = exports.CofidisUtenteDtoFromJSONTyped = exports.CofidisUtenteDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function CofidisUtenteDtoFromJSON(json) {
    return CofidisUtenteDtoFromJSONTyped(json, false);
}
exports.CofidisUtenteDtoFromJSON = CofidisUtenteDtoFromJSON;
function CofidisUtenteDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codiceFiscale': !runtime_1.exists(json, 'codiceFiscale') ? undefined : json['codiceFiscale'],
        'nome': !runtime_1.exists(json, 'nome') ? undefined : json['nome'],
        'cognome': !runtime_1.exists(json, 'cognome') ? undefined : json['cognome'],
        'rapporti': !runtime_1.exists(json, 'rapporti') ? undefined : (json['rapporti'].map(_1.RapportoDtoFromJSON)),
        'linguaUtente': !runtime_1.exists(json, 'linguaUtente') ? undefined : json['linguaUtente'],
    };
}
exports.CofidisUtenteDtoFromJSONTyped = CofidisUtenteDtoFromJSONTyped;
function CofidisUtenteDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codiceFiscale': value.codiceFiscale,
        'nome': value.nome,
        'cognome': value.cognome,
        'rapporti': value.rapporti === undefined ? undefined : (value.rapporti.map(_1.RapportoDtoToJSON)),
        'linguaUtente': value.linguaUtente,
    };
}
exports.CofidisUtenteDtoToJSON = CofidisUtenteDtoToJSON;
