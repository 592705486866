"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMultilinguaPerChatGenesys = exports.getMultilingua = exports.initI18nAction = exports.setI18nSetResources = exports.setI18nSetCurrentLanguage = exports.resetI18n = exports.initI18n = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
const i18n_types_1 = require("./i18n.types");
function initI18n(platform) {
    return {
        type: __1.EReduxActionTypes.I18N_INIT,
        payload: platform
    };
}
exports.initI18n = initI18n;
function resetI18n() {
    return {
        type: __1.EReduxActionTypes.I18N_RESET
    };
}
exports.resetI18n = resetI18n;
function setI18nSetCurrentLanguage(value) {
    return {
        type: __1.EReduxActionTypes.I18N_SET_CURRENT_LANGUAGE,
        payload: value
    };
}
exports.setI18nSetCurrentLanguage = setI18nSetCurrentLanguage;
function setI18nSetResources(value) {
    return {
        type: __1.EReduxActionTypes.I18N_SET_RESOURCES,
        payload: value
    };
}
exports.setI18nSetResources = setI18nSetResources;
const initI18nAction = () => async (dispatch, _, extraArguments) => {
    dispatch(initI18n(extraArguments.platform));
};
exports.initI18nAction = initI18nAction;
/* get multilingua - START */
function getMultilinguaStart() {
    return {
        type: __1.EReduxActionTypes.I18N_GET_MULTILINGUA_START
    };
}
function getMultilinguaSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.I18N_GET_MULTILINGUA_SUCCESS,
        payload
    };
}
function getMultilinguaError(value) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value));
    };
}
const getMultilingua = (loaderEnabled = true) => async (dispatch, getState, extraArguments) => {
    var _a;
    const i18n = getState().i18n;
    if (i18n.resources[i18n.currentLanguage]) {
        dispatch(getMultilinguaSuccess((0, api_rest_1.MessaggiDtoFromJSON)({
            messaggi: i18n.resources[i18n.currentLanguage]
        })));
        return;
    }
    loaderEnabled && dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getMultilinguaStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/multilingua.json')).then(parametri => {
            dispatch(getMultilinguaSuccess((0, i18n_types_1.MultilinguaDtoFromJSON)(parametri)));
            loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const hashKey = i18n.currentLanguage == 'de' ? 'HASH_MESSAGGI_DE' : 'HASH_MESSAGGI_IT';
        const messageKey = i18n.currentLanguage == 'de' ? 'MESSAGGI_DE' : 'MESSAGGI_IT';
        const hashRemota = (_a = getState().prelogin.parametri) === null || _a === void 0 ? void 0 : _a[hashKey];
        extraArguments.getItem(hashKey, true).then((hashLocale) => {
            if (hashLocale == hashRemota) {
                extraArguments.getItem(messageKey, true).then((messaggiLocali) => {
                    if (messaggiLocali) {
                        dispatch(getMultilinguaSuccess((0, api_rest_1.MessaggiDtoFromJSON)({
                            filehash: hashLocale,
                            messaggi: JSON.parse(messaggiLocali)
                        })));
                        loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
                    }
                    else {
                        dispatch(getMultilinguaDaServizio(loaderEnabled, hashKey, messageKey));
                    }
                });
            }
            else {
                dispatch(getMultilinguaDaServizio(loaderEnabled, hashKey, messageKey));
            }
        });
    }
};
exports.getMultilingua = getMultilingua;
const getMultilinguaDaServizio = (loaderEnabled = true, hashKey, messageKey) => async (dispatch, getState, extraArguments) => {
    const i18n = getState().i18n;
    const requestParameters = {
        lingua: i18n.currentLanguage
    };
    new api_rest_1.I18nControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, undefined)).messaggiWithHash(requestParameters).then((response) => {
        console.log('### ho la response da backend e popolo il local storage con messaggi e hash', response.filehash);
        Promise.all([
            extraArguments.setItem(hashKey, response.filehash, true),
            extraArguments.setItem(messageKey, JSON.stringify(response.messaggi), true)
        ]).finally(() => {
            dispatch(getMultilinguaSuccess(response));
        });
    }).catch(async (error) => {
        dispatch(getMultilinguaError(await (0, helpers_1.formatError)(error)));
    }).finally(() => {
        loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
    });
};
/* get parametri - FINE */
function generalError(value, showGenericModalError) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value, showGenericModalError));
    };
}
/* get multilingua per chat genesys - START */
const getMultilinguaPerChatGenesys = () => async (dispatch, getState, extraArguments) => {
    dispatch(getMultilinguaGenesysStart());
    if (extraArguments.mock) {
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.I18nControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, undefined)).widgetGenesys().then((response) => {
                dispatch(getMultilinguaGenesysSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            });
        });
    }
};
exports.getMultilinguaPerChatGenesys = getMultilinguaPerChatGenesys;
function getMultilinguaGenesysStart() {
    return {
        type: __1.EReduxActionTypes.I18N_GET_MULTILINGUA_GENESYS_START
    };
}
function getMultilinguaGenesysSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.I18N_GET_MULTILINGUA_GENESYS_SUCCESS,
        payload
    };
}
/* get multilingua per chat genesys - FINE */
