"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EsitoAutorizzazioneDisposizioneFilialeVirtualeDtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneFilialeVirtualeDtoToJSON = exports.EsitoAutorizzazioneDisposizioneFilialeVirtualeDtoFromJSONTyped = exports.EsitoAutorizzazioneDisposizioneFilialeVirtualeDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function EsitoAutorizzazioneDisposizioneFilialeVirtualeDtoFromJSON(json) {
    return EsitoAutorizzazioneDisposizioneFilialeVirtualeDtoFromJSONTyped(json, false);
}
exports.EsitoAutorizzazioneDisposizioneFilialeVirtualeDtoFromJSON = EsitoAutorizzazioneDisposizioneFilialeVirtualeDtoFromJSON;
function EsitoAutorizzazioneDisposizioneFilialeVirtualeDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
        'codiceErrore': !runtime_1.exists(json, 'codiceErrore') ? undefined : json['codiceErrore'],
        'informazioniAutenticazioneDispositiva': !runtime_1.exists(json, 'informazioniAutenticazioneDispositiva') ? undefined : _1.InformazioniAutenticazioneDispositivaDtoFromJSON(json['informazioniAutenticazioneDispositiva']),
        'disposizione': !runtime_1.exists(json, 'disposizione') ? undefined : _1.DettaglioRichiestaDtoFromJSON(json['disposizione']),
    };
}
exports.EsitoAutorizzazioneDisposizioneFilialeVirtualeDtoFromJSONTyped = EsitoAutorizzazioneDisposizioneFilialeVirtualeDtoFromJSONTyped;
function EsitoAutorizzazioneDisposizioneFilialeVirtualeDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'stato': value.stato,
        'codiceErrore': value.codiceErrore,
        'informazioniAutenticazioneDispositiva': _1.InformazioniAutenticazioneDispositivaDtoToJSON(value.informazioniAutenticazioneDispositiva),
        'disposizione': _1.DettaglioRichiestaDtoToJSON(value.disposizione),
    };
}
exports.EsitoAutorizzazioneDisposizioneFilialeVirtualeDtoToJSON = EsitoAutorizzazioneDisposizioneFilialeVirtualeDtoToJSON;
/**
* @export
* @enum {string}
*/
var EsitoAutorizzazioneDisposizioneFilialeVirtualeDtoStatoEnum;
(function (EsitoAutorizzazioneDisposizioneFilialeVirtualeDtoStatoEnum) {
    EsitoAutorizzazioneDisposizioneFilialeVirtualeDtoStatoEnum["OK"] = "OK";
    EsitoAutorizzazioneDisposizioneFilialeVirtualeDtoStatoEnum["CREDENZIALE_ERRATA"] = "CREDENZIALE_ERRATA";
    EsitoAutorizzazioneDisposizioneFilialeVirtualeDtoStatoEnum["ATTENDERE"] = "ATTENDERE";
    EsitoAutorizzazioneDisposizioneFilialeVirtualeDtoStatoEnum["ACCOUNT_BLOCCATO"] = "ACCOUNT_BLOCCATO";
    EsitoAutorizzazioneDisposizioneFilialeVirtualeDtoStatoEnum["ACCP"] = "ACCP";
    EsitoAutorizzazioneDisposizioneFilialeVirtualeDtoStatoEnum["ACSC"] = "ACSC";
    EsitoAutorizzazioneDisposizioneFilialeVirtualeDtoStatoEnum["ACSP"] = "ACSP";
    EsitoAutorizzazioneDisposizioneFilialeVirtualeDtoStatoEnum["ACTC"] = "ACTC";
    EsitoAutorizzazioneDisposizioneFilialeVirtualeDtoStatoEnum["ACWC"] = "ACWC";
    EsitoAutorizzazioneDisposizioneFilialeVirtualeDtoStatoEnum["ACWP"] = "ACWP";
    EsitoAutorizzazioneDisposizioneFilialeVirtualeDtoStatoEnum["RCVD"] = "RCVD";
    EsitoAutorizzazioneDisposizioneFilialeVirtualeDtoStatoEnum["PDNG"] = "PDNG";
    EsitoAutorizzazioneDisposizioneFilialeVirtualeDtoStatoEnum["RJCT"] = "RJCT";
    EsitoAutorizzazioneDisposizioneFilialeVirtualeDtoStatoEnum["CANC"] = "CANC";
})(EsitoAutorizzazioneDisposizioneFilialeVirtualeDtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneFilialeVirtualeDtoStatoEnum || (exports.EsitoAutorizzazioneDisposizioneFilialeVirtualeDtoStatoEnum = {}));
