"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EReduxWatchlistActionTypes = exports.WatchlistFormFromJSONTyped = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
function WatchlistFormFromJSONTyped(json) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nomeWatchlist': !(0, api_rest_1.exists)(json, 'nomeWatchlist') ? '' : json.nomeWatchlist,
        'nomeWatchlistDirty': !(0, api_rest_1.exists)(json, 'nomeWatchlistDirty') ? '' : json.nomeWatchlistDirty,
        'nomeWatchlistError': !(0, api_rest_1.exists)(json, 'nomeWatchlistError') ? '' : json.nomeWatchlistError,
        'watchlistSelezionata': !(0, api_rest_1.exists)(json, 'watchlistSelezionata') ? (0, api_rest_1.WatchlistDtoFromJSON)({}) : json.watchlistSelezionata,
        'tipoPagina': !(0, api_rest_1.exists)(json, 'tipoPagina') ? 'elenco' : json.tipoPagina,
    };
}
exports.WatchlistFormFromJSONTyped = WatchlistFormFromJSONTyped;
var EReduxWatchlistActionTypes;
(function (EReduxWatchlistActionTypes) {
    EReduxWatchlistActionTypes["WATCHLIST_GET_ELENCO_WATCHLIST_START"] = "WATCHLIST_GET_ELENCO_WATCHLIST_START";
    EReduxWatchlistActionTypes["WATCHLIST_GET_ELENCO_WATCHLIST_SUCCESS"] = "WATCHLIST_GET_ELENCO_WATCHLIST_SUCCESS";
    EReduxWatchlistActionTypes["WATCHLIST_CREA_WATCHLIST_START"] = "WATCHLIST_CREA_WATCHLIST_START";
    EReduxWatchlistActionTypes["WATCHLIST_CREA_WATCHLIST_SUCCESS"] = "WATCHLIST_CREA_WATCHLIST_SUCCESS";
    EReduxWatchlistActionTypes["WATCHLIST_AGGIUNGI_ELEMENTO_WATCHLIST_START"] = "WATCHLIST_AGGIUNGI_ELEMENTO_WATCHLIST_START";
    EReduxWatchlistActionTypes["WATCHLIST_AGGIUNGI_ELEMENTO_WATCHLIST_SUCCESS"] = "WATCHLIST_AGGIUNGI_ELEMENTO_WATCHLIST_SUCCESS";
    EReduxWatchlistActionTypes["WATCHLIST_RIMUOVI_ELEMENTO_WATCHLIST_START"] = "WATCHLIST_RIMUOVI_ELEMENTO_WATCHLIST_START";
    EReduxWatchlistActionTypes["WATCHLIST_RIMUOVI_ELEMENTO_WATCHLIST_SUCCESS"] = "WATCHLIST_RIMUOVI_ELEMENTO_WATCHLIST_SUCCESS";
    EReduxWatchlistActionTypes["WATCHLIST_RIMUOVI_WATCHLIST_START"] = "WATCHLIST_RIMUOVI_WATCHLIST_START";
    EReduxWatchlistActionTypes["WATCHLIST_RIMUOVI_WATCHLIST_SUCCESS"] = "WATCHLIST_RIMUOVI_WATCHLIST_SUCCESS";
    EReduxWatchlistActionTypes["WATCHLIST_GET_QUOTAZIONI_WATCHLIST_START"] = "WATCHLIST_GET_QUOTAZIONI_WATCHLIST_START";
    EReduxWatchlistActionTypes["WATCHLIST_GET_QUOTAZIONI_WATCHLIST_SUCCESS"] = "WATCHLIST_GET_QUOTAZIONI_WATCHLIST_SUCCESS";
    EReduxWatchlistActionTypes["WATCHLIST_SET_NOME_WATCHLIST"] = "WATCHLIST_SET_NOME_WATCHLIST";
    EReduxWatchlistActionTypes["WATCHLIST_SET_WATCHLIST_SELEZIONATA"] = "WATCHLIST_SET_WATCHLIST_SELEZIONATA";
    EReduxWatchlistActionTypes["WATCHLIST_RESET"] = "WATCHLIST_RESET";
    EReduxWatchlistActionTypes["WATCHLIST_SET_TIPO_PAGINA"] = "WATCHLIST_SET_TIPO_PAGINA";
})(EReduxWatchlistActionTypes = exports.EReduxWatchlistActionTypes || (exports.EReduxWatchlistActionTypes = {}));
