"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formStoricoOrdiniAsFiltro = exports.eseguitiOrdine = exports.filtriStoricoOrdiniApplicati = exports.dettaglioOrdine = exports.ordiniInScadenza = exports.revocaOrdineForm = exports.storicoOrdiniForm = exports.parametriTrading = void 0;
const reselect_1 = require("reselect");
const moment = require("moment");
const storicoOrdiniReducer = (state) => state;
exports.parametriTrading = (0, reselect_1.createSelector)(storicoOrdiniReducer, (state) => {
    return state.parametriTrading;
});
exports.storicoOrdiniForm = (0, reselect_1.createSelector)(storicoOrdiniReducer, (state) => {
    return state.storicoOrdiniForm;
});
exports.revocaOrdineForm = (0, reselect_1.createSelector)(storicoOrdiniReducer, (state) => {
    return state.ordineRevocaForm;
});
exports.ordiniInScadenza = (0, reselect_1.createSelector)(storicoOrdiniReducer, (state) => {
    return state.ordiniInScadenza;
});
exports.dettaglioOrdine = (0, reselect_1.createSelector)(storicoOrdiniReducer, (state) => {
    return state.dettaglioOrdine;
});
exports.filtriStoricoOrdiniApplicati = (0, reselect_1.createSelector)(storicoOrdiniReducer, (state) => {
    let r = false;
    if (state.storicoOrdiniForm.stati && state.storicoOrdiniForm.stati.length > 0 ||
        state.storicoOrdiniForm.periodo != '30') {
        r = true;
    }
    return r;
});
exports.eseguitiOrdine = (0, reselect_1.createSelector)(storicoOrdiniReducer, (state) => {
    return state.eseguitiOrdine;
});
exports.formStoricoOrdiniAsFiltro = (0, reselect_1.createSelector)(storicoOrdiniReducer, (state) => {
    var _a, _b;
    const datiForm = {
        codiceRapporto: ((_b = (_a = state.storicoOrdiniForm.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.id) || 0,
        dataIniziale: moment(state.storicoOrdiniForm.dataDa).toDate(),
        dataFinale: moment(state.storicoOrdiniForm.dataA).toDate(),
        stato: state.storicoOrdiniForm.stati,
        offset: state.storicoOrdiniForm.offset
    };
    return datiForm;
});
