"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NewsDtoToJSON = exports.NewsDtoFromJSONTyped = exports.NewsDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function NewsDtoFromJSON(json) {
    return NewsDtoFromJSONTyped(json, false);
}
exports.NewsDtoFromJSON = NewsDtoFromJSON;
function NewsDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'idNews': !runtime_1.exists(json, 'idNews') ? undefined : json['idNews'],
        'agenzia': !runtime_1.exists(json, 'agenzia') ? undefined : json['agenzia'],
        'agDesc': !runtime_1.exists(json, 'agDesc') ? undefined : json['agDesc'],
        'oggetto': !runtime_1.exists(json, 'oggetto') ? undefined : json['oggetto'],
        'data': !runtime_1.exists(json, 'data') ? undefined : json['data'],
        'tipo': !runtime_1.exists(json, 'tipo') ? undefined : json['tipo'],
        'extendedData': !runtime_1.exists(json, 'extendedData') ? undefined : _1.ExtendedDataDtoFromJSON(json['extendedData']),
        'testo': !runtime_1.exists(json, 'testo') ? undefined : json['testo'],
        'titoli': !runtime_1.exists(json, 'titoli') ? undefined : json['titoli'],
        'nprog': !runtime_1.exists(json, 'nprog') ? undefined : json['nprog'],
    };
}
exports.NewsDtoFromJSONTyped = NewsDtoFromJSONTyped;
function NewsDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'idNews': value.idNews,
        'agenzia': value.agenzia,
        'agDesc': value.agDesc,
        'oggetto': value.oggetto,
        'data': value.data,
        'tipo': value.tipo,
        'extendedData': _1.ExtendedDataDtoToJSON(value.extendedData),
        'testo': value.testo,
        'titoli': value.titoli,
        'nprog': value.nprog,
    };
}
exports.NewsDtoToJSON = NewsDtoToJSON;
