"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.InlineResponse2004DtoToJSON = exports.InlineResponse2004DtoFromJSONTyped = exports.InlineResponse2004DtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function InlineResponse2004DtoFromJSON(json) {
    return InlineResponse2004DtoFromJSONTyped(json, false);
}
exports.InlineResponse2004DtoFromJSON = InlineResponse2004DtoFromJSON;
function InlineResponse2004DtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'accounts': !runtime_1.exists(json, 'accounts') ? undefined : (json['accounts'].map(_1.AccountDetailDtoFromJSON)),
        'link': !runtime_1.exists(json, 'link') ? undefined : json['link'],
        'psd2Warning': !runtime_1.exists(json, 'psd2Warning') ? undefined : json['psd2Warning'],
    };
}
exports.InlineResponse2004DtoFromJSONTyped = InlineResponse2004DtoFromJSONTyped;
function InlineResponse2004DtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'accounts': value.accounts === undefined ? undefined : (value.accounts.map(_1.AccountDetailDtoToJSON)),
        'link': value.link,
        'psd2Warning': value.psd2Warning,
    };
}
exports.InlineResponse2004DtoToJSON = InlineResponse2004DtoToJSON;
