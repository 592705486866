"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TitoliInScadenzaFiltriInDtoToJSON = exports.TitoliInScadenzaFiltriInDtoFromJSONTyped = exports.TitoliInScadenzaFiltriInDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function TitoliInScadenzaFiltriInDtoFromJSON(json) {
    return TitoliInScadenzaFiltriInDtoFromJSONTyped(json, false);
}
exports.TitoliInScadenzaFiltriInDtoFromJSON = TitoliInScadenzaFiltriInDtoFromJSON;
function TitoliInScadenzaFiltriInDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codiceRapporto': json['codiceRapporto'],
        'dataFinale': (new Date(json['dataFinale'])),
        'offset': !runtime_1.exists(json, 'offset') ? undefined : json['offset'],
        'causali': !runtime_1.exists(json, 'causali') ? undefined : json['causali'],
    };
}
exports.TitoliInScadenzaFiltriInDtoFromJSONTyped = TitoliInScadenzaFiltriInDtoFromJSONTyped;
function TitoliInScadenzaFiltriInDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codiceRapporto': value.codiceRapporto,
        'dataFinale': (value.dataFinale.toISOString()),
        'offset': value.offset,
        'causali': value.causali,
    };
}
exports.TitoliInScadenzaFiltriInDtoToJSON = TitoliInScadenzaFiltriInDtoToJSON;
