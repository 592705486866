"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.storicoOrdiniReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const helpers_1 = require("../helpers");
const initialState = {
    parametriTrading: (0, api_rest_1.ParametriFinanzaDtoFromJSON)({}),
    ordiniInScadenza: (0, api_rest_1.OrdiniScadenzaDtoFromJSON)([]),
    dettaglioOrdine: (0, api_rest_1.EseguitiResponseDtoFromJSON)({}),
    eseguitiOrdine: [],
    storicoOrdiniForm: {
        rapportoSelezionato: (0, api_rest_1.RapportoCompletoDtoFromJSON)({}),
        periodo: '',
        dataDa: '',
        stati: [],
        dataA: '',
        accordionFiltriOpened: false,
        offset: 0
    },
    ordineRevocaForm: {
        step: 0,
        controlloRevoca: (0, api_rest_1.OrdineTradingDtoFromJSON)({}),
        infoRevoca: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
        esitoRevoca: (0, api_rest_1.EsitoAutorizzazioneDisposizioneFinanzaDtoFromJSON)({})
    }
};
const storicoOrdiniReducer = (state = initialState, action) => {
    var _a;
    switch (action.type) {
        case __1.EReduxActionTypes.STORICO_ORDINI_GET_PARAMETRI_TRADING_SUCCESS:
            return {
                ...state,
                parametriTrading: action.payload
            };
        case __1.EReduxActionTypes.STORICO_ORDINI_GET_ORDINI_IN_SCADENZA_SUCCESS:
            return {
                ...state,
                ordiniInScadenza: !action.offset
                    ? action.payload
                    : {
                        ...state.ordiniInScadenza,
                        ordini: (((_a = state.ordiniInScadenza) === null || _a === void 0 ? void 0 : _a.ordini) || []).concat(action.payload.ordini || [])
                    }
            };
        case __1.EReduxActionTypes.STORICO_ORDINI_SET_RAPPORTO_SELEZIONATO:
            return {
                ...state,
                storicoOrdiniForm: {
                    ...state.storicoOrdiniForm,
                    rapportoSelezionato: action.payload
                }
            };
        case __1.EReduxActionTypes.STORICO_ORDINI_SET_ACCORDION_FILTRI_OPENED:
            return {
                ...state,
                storicoOrdiniForm: {
                    ...state.storicoOrdiniForm,
                    accordionFiltriOpened: action.payload
                }
            };
        case __1.EReduxActionTypes.STORICO_ORDINI_SET_DATA_DA:
            return {
                ...state,
                storicoOrdiniForm: {
                    ...state.storicoOrdiniForm,
                    dataDa: action.payload
                }
            };
        case __1.EReduxActionTypes.STORICO_ORDINI_SET_DATA_A:
            return {
                ...state,
                storicoOrdiniForm: {
                    ...state.storicoOrdiniForm,
                    dataA: action.payload
                }
            };
        case __1.EReduxActionTypes.STORICO_ORDINI_SET_STATI:
            return {
                ...state,
                storicoOrdiniForm: {
                    ...state.storicoOrdiniForm,
                    stati: action.payload
                }
            };
        case __1.EReduxActionTypes.STORICO_ORDINI_SET_PERIODO:
            return {
                ...state,
                storicoOrdiniForm: {
                    ...state.storicoOrdiniForm,
                    periodo: action.payload
                }
            };
        case __1.EReduxActionTypes.STORICO_ORDINI_RESET_FILTRO:
            return {
                ...state,
                storicoOrdiniForm: {
                    ...initialState.storicoOrdiniForm
                }
            };
        case __1.EReduxActionTypes.STORICO_ORDINI_GET_ESEGUITI_ORDINE_SUCCESS:
            return {
                ...state,
                eseguitiOrdine: action.payload.esitiOperaziones
            };
        case __1.EReduxActionTypes.STORICO_ORDINI_RESET_DETTAGLIO_ORDINE:
            return {
                ...state,
                dettaglioOrdine: initialState.dettaglioOrdine
            };
        case __1.EReduxActionTypes.STORICO_ORDINI_SET_DETTAGLIO_ORDINE:
            return {
                ...state,
                dettaglioOrdine: action.payload
            };
        case __1.EReduxActionTypes.REVOCA_ORDINE_SET_STEP_REVOCA:
            return {
                ...state,
                ordineRevocaForm: {
                    ...state.ordineRevocaForm,
                    step: action.payload
                }
            };
        case __1.EReduxActionTypes.REVOCA_ORDINE_CONTROLLO_REVOCA_SUCCESS:
            return {
                ...state,
                ordineRevocaForm: {
                    ...state.ordineRevocaForm,
                    controlloRevoca: action.payload
                }
            };
        case __1.EReduxActionTypes.REVOCA_ORDINE_GET_INFO_REVOCA_SUCCESS:
            return {
                ...state,
                ordineRevocaForm: {
                    ...state.ordineRevocaForm,
                    infoRevoca: action.payload,
                    esitoRevoca: initialState.ordineRevocaForm.esitoRevoca
                }
            };
        case __1.EReduxActionTypes.REVOCA_ORDINE_AUTORIZZA_REVOCA_SUCCESS:
            if (action.payload.stato === 'OK') {
                return {
                    ...state,
                    ordineRevocaForm: {
                        ...state.ordineRevocaForm,
                        esitoRevoca: action.payload
                    }
                };
            }
            else {
                return {
                    ...state,
                    ordineRevocaForm: {
                        ...state.ordineRevocaForm,
                        infoRevoca: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.ordineRevocaForm.infoRevoca, action.payload.informazioniAutenticazioneDispositiva),
                        esitoRevoca: action.payload
                    }
                };
            }
        case __1.EReduxActionTypes.REVOCA_ORDINE_RESET_FORM_REVOCA:
            return {
                ...state,
                ordineRevocaForm: initialState.ordineRevocaForm
            };
        case __1.EReduxActionTypes.STORICO_ORDINI_RESET_ESEGUITI_ORDINE:
            return {
                ...state,
                eseguitiOrdine: initialState.eseguitiOrdine
            };
        case __1.EReduxActionTypes.STORICO_ORDINI_SET_OFFSET:
            return {
                ...state,
                storicoOrdiniForm: {
                    ...state.storicoOrdiniForm,
                    offset: action.payload
                }
            };
        case __1.EReduxActionTypes.RESET_ALL: // action invocata dalla logout
            return {
                ...initialState
            };
        default:
            return state;
    }
};
exports.storicoOrdiniReducer = storicoOrdiniReducer;
