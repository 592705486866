import React from 'react';
import loader from "../../assets/image/loader.gif";

const Loader = () => {
  return (
    <div className="loader">
      <img className="loader_img" src={loader} />
    </div>
  );
};

export default Loader;
