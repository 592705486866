"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModelErrorToJSON = exports.ModelErrorFromJSONTyped = exports.ModelErrorFromJSON = void 0;
const runtime_1 = require("../runtime");
function ModelErrorFromJSON(json) {
    return ModelErrorFromJSONTyped(json, false);
}
exports.ModelErrorFromJSON = ModelErrorFromJSON;
function ModelErrorFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'error_code': !runtime_1.exists(json, 'error_code') ? undefined : json['error_code'],
        'error_description': !runtime_1.exists(json, 'error_description') ? undefined : json['error_description'],
        'error_subcode': !runtime_1.exists(json, 'error_subcode') ? undefined : json['error_subcode'],
    };
}
exports.ModelErrorFromJSONTyped = ModelErrorFromJSONTyped;
function ModelErrorToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'error_code': value.error_code,
        'error_description': value.error_description,
        'error_subcode': value.error_subcode,
    };
}
exports.ModelErrorToJSON = ModelErrorToJSON;
