"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PctDtoToJSON = exports.PctDtoFromJSONTyped = exports.PctDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function PctDtoFromJSON(json) {
    return PctDtoFromJSONTyped(json, false);
}
exports.PctDtoFromJSON = PctDtoFromJSON;
function PctDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codiceRapporto': !runtime_1.exists(json, 'codiceRapporto') ? undefined : json['codiceRapporto'],
        'codiceTitolo': !runtime_1.exists(json, 'codiceTitolo') ? undefined : json['codiceTitolo'],
        'descrizioneTitolo': !runtime_1.exists(json, 'descrizioneTitolo') ? undefined : json['descrizioneTitolo'],
        'dataValutaPronti': !runtime_1.exists(json, 'dataValutaPronti') ? undefined : (new Date(json['dataValutaPronti'])),
        'dataValutaTermine': !runtime_1.exists(json, 'dataValutaTermine') ? undefined : (new Date(json['dataValutaTermine'])),
        'giorniDurata': !runtime_1.exists(json, 'giorniDurata') ? undefined : json['giorniDurata'],
        'importoMin': !runtime_1.exists(json, 'importoMin') ? undefined : json['importoMin'],
        'importoMax': !runtime_1.exists(json, 'importoMax') ? undefined : json['importoMax'],
        'rendimento': !runtime_1.exists(json, 'rendimento') ? undefined : json['rendimento'],
        'divisa': !runtime_1.exists(json, 'divisa') ? undefined : json['divisa'],
        'chiaveRipartenza': !runtime_1.exists(json, 'chiaveRipartenza') ? undefined : json['chiaveRipartenza'],
        'importo': !runtime_1.exists(json, 'importo') ? undefined : json['importo'],
        'quantitaNominale': !runtime_1.exists(json, 'quantitaNominale') ? undefined : json['quantitaNominale'],
        'tassoLordo': !runtime_1.exists(json, 'tassoLordo') ? undefined : json['tassoLordo'],
        'tassoNetto': !runtime_1.exists(json, 'tassoNetto') ? undefined : json['tassoNetto'],
        'ritenutePT': !runtime_1.exists(json, 'ritenutePT') ? undefined : json['ritenutePT'],
        'utile': !runtime_1.exists(json, 'utile') ? undefined : json['utile'],
        'valutaPronti': !runtime_1.exists(json, 'valutaPronti') ? undefined : (new Date(json['valutaPronti'])),
        'valutaTermine': !runtime_1.exists(json, 'valutaTermine') ? undefined : (new Date(json['valutaTermine'])),
        'prezzoUnitarioPronti': !runtime_1.exists(json, 'prezzoUnitarioPronti') ? undefined : json['prezzoUnitarioPronti'],
        'prezzoUnitarioTermine': !runtime_1.exists(json, 'prezzoUnitarioTermine') ? undefined : json['prezzoUnitarioTermine'],
        'speseEffettivePronti': !runtime_1.exists(json, 'speseEffettivePronti') ? undefined : json['speseEffettivePronti'],
        'speseEffettiveTermine': !runtime_1.exists(json, 'speseEffettiveTermine') ? undefined : json['speseEffettiveTermine'],
        'totalePronti': !runtime_1.exists(json, 'totalePronti') ? undefined : json['totalePronti'],
        'totaleTermine': !runtime_1.exists(json, 'totaleTermine') ? undefined : json['totaleTermine'],
        'pgEsito': !runtime_1.exists(json, 'pgEsito') ? undefined : json['pgEsito'],
        'abilitaPulsanteCosti': !runtime_1.exists(json, 'abilitaPulsanteCosti') ? undefined : json['abilitaPulsanteCosti'],
        'abilitaPdfKiid': !runtime_1.exists(json, 'abilitaPdfKiid') ? undefined : json['abilitaPdfKiid'],
        'tabellaCosti': !runtime_1.exists(json, 'tabellaCosti') ? undefined : (json['tabellaCosti'].map(_1.TabellaCostiPctDtoFromJSON)),
        'numeroDossier': !runtime_1.exists(json, 'numeroDossier') ? undefined : json['numeroDossier'],
        'intestatarioDossier': !runtime_1.exists(json, 'intestatarioDossier') ? undefined : json['intestatarioDossier'],
        'dataOperazione': !runtime_1.exists(json, 'dataOperazione') ? undefined : (new Date(json['dataOperazione'])),
        'importoStimato': !runtime_1.exists(json, 'importoStimato') ? undefined : json['importoStimato'],
    };
}
exports.PctDtoFromJSONTyped = PctDtoFromJSONTyped;
function PctDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codiceRapporto': value.codiceRapporto,
        'codiceTitolo': value.codiceTitolo,
        'descrizioneTitolo': value.descrizioneTitolo,
        'dataValutaPronti': value.dataValutaPronti === undefined ? undefined : (value.dataValutaPronti.toISOString()),
        'dataValutaTermine': value.dataValutaTermine === undefined ? undefined : (value.dataValutaTermine.toISOString()),
        'giorniDurata': value.giorniDurata,
        'importoMin': value.importoMin,
        'importoMax': value.importoMax,
        'rendimento': value.rendimento,
        'divisa': value.divisa,
        'chiaveRipartenza': value.chiaveRipartenza,
        'importo': value.importo,
        'quantitaNominale': value.quantitaNominale,
        'tassoLordo': value.tassoLordo,
        'tassoNetto': value.tassoNetto,
        'ritenutePT': value.ritenutePT,
        'utile': value.utile,
        'valutaPronti': value.valutaPronti === undefined ? undefined : (value.valutaPronti.toISOString()),
        'valutaTermine': value.valutaTermine === undefined ? undefined : (value.valutaTermine.toISOString()),
        'prezzoUnitarioPronti': value.prezzoUnitarioPronti,
        'prezzoUnitarioTermine': value.prezzoUnitarioTermine,
        'speseEffettivePronti': value.speseEffettivePronti,
        'speseEffettiveTermine': value.speseEffettiveTermine,
        'totalePronti': value.totalePronti,
        'totaleTermine': value.totaleTermine,
        'pgEsito': value.pgEsito,
        'abilitaPulsanteCosti': value.abilitaPulsanteCosti,
        'abilitaPdfKiid': value.abilitaPdfKiid,
        'tabellaCosti': value.tabellaCosti === undefined ? undefined : (value.tabellaCosti.map(_1.TabellaCostiPctDtoToJSON)),
        'numeroDossier': value.numeroDossier,
        'intestatarioDossier': value.intestatarioDossier,
        'dataOperazione': value.dataOperazione === undefined ? undefined : (value.dataOperazione.toISOString()),
        'importoStimato': value.importoStimato,
    };
}
exports.PctDtoToJSON = PctDtoToJSON;
