"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.logginEnrollment = exports.setAndroidPermissionGranted = exports.setDownloadPDF = exports.setACSLoginAsked = exports.setInAppBrowserOpened = exports.logginOperation = exports.setChatGenesysOpened = exports.extLogginOperation = exports.resetErroreAdeguataVerifica = exports.setErroreAdeguataVerifica = exports.resetPostCambioLingua = exports.resetCachePostDispo = exports.refreshWidgets = exports.setAssistenteOpened = exports.enrollmentPSD2AttivaSuccess = exports.resetPostLoginError = exports.setPostLoginError = exports.resetNoRapporti = exports.setNoRapporti = exports.setAzioniSmartVisible = exports.setNeosuranceLoginAsked = exports.setDisableButtonOkCurrentPopUp = exports.removePopUp = exports.pushPopUp = exports.setIsConnected = exports.setStepHomeBollettinoPostale = exports.switchSaldoContabileSaldoDisponibile = exports.setStepHomeMav = exports.setStepHomeRav = exports.resetCallerObject = exports.setCallerObject = exports.popUpBlockedPdf = exports.delSnackbar = exports.addOrReplaceSnackbar = exports.addSnackbar = exports.setMobileTheme = exports.setBottomTabBarVisible = exports.showTooltipMobile = exports.setWebBreadCrumbs = exports.setStepHomeBonifico = exports.setBlurText = exports.setCurrentTab = exports.setWebMenu = exports.setRoute = exports.setSessionExpired = exports.setWaitingSection = exports.setLoader = exports.resetError = exports.setError = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const helpers_1 = require("../helpers");
const general_configuration_1 = require("./general.configuration");
function setError(payload, showGenericModalError) {
    if (showGenericModalError === undefined) {
        showGenericModalError = true;
    }
    return {
        type: __1.EReduxActionTypes.SET_GENERAL_ERROR,
        payload: {
            ...payload,
            showGenericModalError
        }
    };
}
exports.setError = setError;
function generalError(value, showGenericModalError) {
    return async (dispatch) => {
        dispatch(setError(value, showGenericModalError));
    };
}
function resetError() {
    return {
        type: __1.EReduxActionTypes.RESET_GENERAL_ERROR
    };
}
exports.resetError = resetError;
function setLoaderAction(active) {
    return {
        type: __1.EReduxActionTypes.SET_GENERAL_LOADER,
        active
    };
}
const setLoader = (active) => async (dispatch, _, extraArguments) => {
    setTimeout(() => {
        dispatch(setLoaderAction(active));
    }, active ? 0 : 50);
};
exports.setLoader = setLoader;
function setWaitingSection(active, section) {
    return {
        type: __1.EReduxActionTypes.SET_GENERAL_WAITING_SECTION,
        active,
        section
    };
}
exports.setWaitingSection = setWaitingSection;
function setSessionExpired(expired) {
    return {
        type: __1.EReduxActionTypes.SET_SESSION_EXPIRED,
        payload: expired
    };
}
exports.setSessionExpired = setSessionExpired;
function setRoute(nextRoute) {
    return {
        type: __1.EReduxActionTypes.SET_GENERAL_ROUTE,
        payload: { nextRoute: nextRoute, prevRoute: '' }
    };
}
exports.setRoute = setRoute;
function setWebMenu(bSelect) {
    return {
        type: __1.EReduxActionTypes.SET_GENERAL_WEBMENU,
        webMenuExpanded: bSelect
    };
}
exports.setWebMenu = setWebMenu;
function setCurrentTab(tab) {
    return {
        type: __1.EReduxActionTypes.SET_CURRENT_TAB,
        payload: tab
    };
}
exports.setCurrentTab = setCurrentTab;
function setBlurText(bBlur) {
    return {
        type: __1.EReduxActionTypes.SET_GENERAL_BLURTEXT,
        blurText: bBlur
    };
}
exports.setBlurText = setBlurText;
function setStepHomeBonifico(i) {
    return {
        type: __1.EReduxActionTypes.SET_GENERAL_HOME_BONIFICO,
        payload: i
    };
}
exports.setStepHomeBonifico = setStepHomeBonifico;
function setWebBreadCrumbs(arr) {
    return {
        type: __1.EReduxActionTypes.SET_GENERAL_BREAD_CRUMBS,
        payload: arr
    };
}
exports.setWebBreadCrumbs = setWebBreadCrumbs;
function showTooltipMobile(tooltip) {
    return {
        type: __1.EReduxActionTypes.SHOW_TOOLTIP_MOBILE,
        payload: tooltip
    };
}
exports.showTooltipMobile = showTooltipMobile;
function setBottomTabBarVisible(visible) {
    return {
        type: __1.EReduxActionTypes.SET_BOTTOM_TAB_BAR_VISIBLE,
        payload: visible
    };
}
exports.setBottomTabBarVisible = setBottomTabBarVisible;
function setMobileTheme(theme) {
    return {
        type: __1.EReduxActionTypes.SET_MOBILE_THEME,
        payload: theme
    };
}
exports.setMobileTheme = setMobileTheme;
function addSnackbar(item) {
    return {
        type: __1.EReduxActionTypes.GENERAL_ADD_SNACKBAR,
        payload: item
    };
}
exports.addSnackbar = addSnackbar;
function addOrReplaceSnackbar(item, id) {
    return {
        type: __1.EReduxActionTypes.GENERAL_ADD_OR_REPLACE_SNACKBAR,
        payload: {
            id,
            descrizione: item
        }
    };
}
exports.addOrReplaceSnackbar = addOrReplaceSnackbar;
function delSnackbar(id) {
    return {
        type: __1.EReduxActionTypes.GENERAL_DEL_SNACKBAR,
        payload: id
    };
}
exports.delSnackbar = delSnackbar;
const popUpBlockedPdf = (url, openingRequired) => async (dispatch, _, extraArguments) => {
    let popUpBlockerPdf = {
        type: 'confirm',
        title: 'general.popUpBlockerPdfModal.title',
        message: 'general.popUpBlockerPdfModal.description',
        buttonKo: {
            text: 'general.popUpBlockerPdfModal.cancel'
        },
        buttonOk: {
            text: 'general.popUpBlockerPdfModal.open',
            action: () => window.open(url, '_blank')
        }
    };
    if (openingRequired) {
        popUpBlockerPdf = {
            type: 'okOnly',
            title: 'general.popUpBlockerPdfModal.title',
            message: 'general.popUpBlockerPdfModal.description',
            buttonOk: {
                text: 'general.popUpBlockerPdfModal.open',
                action: () => window.open(url, '_blank')
            }
        };
    }
    dispatch(pushPopUp(popUpBlockerPdf));
};
exports.popUpBlockedPdf = popUpBlockedPdf;
function setCallerObject(payload) {
    return {
        type: __1.EReduxActionTypes.SET_GENERAL_CALLER_OBJECT,
        payload
    };
}
exports.setCallerObject = setCallerObject;
function resetCallerObject() {
    return {
        type: __1.EReduxActionTypes.RESET_GENERAL_CALLER_OBJECT
    };
}
exports.resetCallerObject = resetCallerObject;
function setStepHomeRav(i) {
    return {
        type: __1.EReduxActionTypes.SET_GENERAL_HOME_RAV,
        payload: i
    };
}
exports.setStepHomeRav = setStepHomeRav;
function setStepHomeMav(i) {
    return {
        type: __1.EReduxActionTypes.SET_GENERAL_HOME_MAV,
        payload: i
    };
}
exports.setStepHomeMav = setStepHomeMav;
function switchSaldoContabileSaldoDisponibile(b) {
    return {
        type: __1.EReduxActionTypes.SWITCH_SALDO_CONTABILE_SALDO_DISPONIBILE,
        payload: b
    };
}
exports.switchSaldoContabileSaldoDisponibile = switchSaldoContabileSaldoDisponibile;
function setStepHomeBollettinoPostale(i) {
    return {
        type: __1.EReduxActionTypes.SET_GENERAL_HOME_BOLLETTINO,
        payload: i
    };
}
exports.setStepHomeBollettinoPostale = setStepHomeBollettinoPostale;
function setIsConnected(isConnected) {
    return {
        type: __1.EReduxActionTypes.SET_GENERAL_ISCONNECTED,
        payload: isConnected
    };
}
exports.setIsConnected = setIsConnected;
function pushPopUp(popUp) {
    return {
        type: __1.EReduxActionTypes.PUSH_GENERAL_POPUP,
        payload: popUp
    };
}
exports.pushPopUp = pushPopUp;
function removePopUp(id) {
    return {
        type: __1.EReduxActionTypes.REMOVE_GENERAL_POPUP,
        payload: id
    };
}
exports.removePopUp = removePopUp;
function setDisableButtonOkCurrentPopUp(disabled) {
    return {
        type: __1.EReduxActionTypes.SET_DISABLE_BUTTON_OK_CURRENT_GENERAL_POPUP,
        payload: disabled
    };
}
exports.setDisableButtonOkCurrentPopUp = setDisableButtonOkCurrentPopUp;
function setNeosuranceLoginAsked(loginAsked) {
    return {
        type: __1.EReduxActionTypes.SET_NEOSURANCE_LOGIN_ASKED,
        payload: loginAsked
    };
}
exports.setNeosuranceLoginAsked = setNeosuranceLoginAsked;
function setAzioniSmartVisible(visible) {
    return {
        type: __1.EReduxActionTypes.SET_AZIONI_SMART_VISIBLE,
        payload: visible
    };
}
exports.setAzioniSmartVisible = setAzioniSmartVisible;
function setNoRapporti(payload) {
    return {
        type: __1.EReduxActionTypes.SET_GENERAL_NO_RAPPORTI,
        payload: payload
    };
}
exports.setNoRapporti = setNoRapporti;
function resetNoRapporti() {
    return {
        type: __1.EReduxActionTypes.RESET_GENERAL_NO_RAPPORTI
    };
}
exports.resetNoRapporti = resetNoRapporti;
function setPostLoginError(payload) {
    return {
        type: __1.EReduxActionTypes.SET_GENERAL_POST_LOGIN_ERROR,
        payload: payload
    };
}
exports.setPostLoginError = setPostLoginError;
function resetPostLoginError() {
    return {
        type: __1.EReduxActionTypes.RESET_GENERAL_POST_LOGIN_ERROR
    };
}
exports.resetPostLoginError = resetPostLoginError;
function enrollmentPSD2AttivaSuccess() {
    return {
        type: __1.EReduxActionTypes.ENROLLMENT_PSD2_ATTIVA_SUCCESS
    };
}
exports.enrollmentPSD2AttivaSuccess = enrollmentPSD2AttivaSuccess;
function setAssistenteOpened(opened) {
    return {
        type: __1.EReduxActionTypes.SET_ASSISTENTE_OPENED,
        payload: opened
    };
}
exports.setAssistenteOpened = setAssistenteOpened;
// action invocata tramite pull to refresh in home
function refreshWidgets() {
    return {
        type: __1.EReduxActionTypes.GENERAL_REFRESH_WIDGETS
    };
}
exports.refreshWidgets = refreshWidgets;
// action invocata dopo una dispositiva (facente parte di resetWidgetsDispoFunctions)
function resetCachePostDispo() {
    return {
        type: __1.EReduxActionTypes.GENERAL_RESET_CACHE_POST_DISPO
    };
}
exports.resetCachePostDispo = resetCachePostDispo;
// action invocata dopo il cambio lingua
function resetPostCambioLingua() {
    return {
        type: __1.EReduxActionTypes.GENERAL_RESET_POST_CAMBIO_LINGUA
    };
}
exports.resetPostCambioLingua = resetPostCambioLingua;
//action invocata su errore adeguata verifica
function setErroreAdeguataVerifica(error) {
    return {
        type: __1.EReduxActionTypes.GENERAL_SET_ERRORE_ADEGUATA_VERIFICA,
        payload: error
    };
}
exports.setErroreAdeguataVerifica = setErroreAdeguataVerifica;
function resetErroreAdeguataVerifica() {
    return {
        type: __1.EReduxActionTypes.GENERAL_RESET_ERRORE_ADEGUATA_VERIFICA
    };
}
exports.resetErroreAdeguataVerifica = resetErroreAdeguataVerifica;
function extLogginOperationStart() {
    return {
        type: __1.EReduxActionTypes.GENERAL_EXT_LOGGING_OPERATION_START
    };
}
function extLogginOperationSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.GENERAL_EXT_LOGGING_OPERATION_SUCCESS,
        payload
    };
}
const extLogginOperation = (operationLogDto) => async (dispatch, _, extraArguments) => {
    dispatch(extLogginOperationStart());
    dispatch((0, exports.setLoader)(true));
    if (extraArguments.mock) {
        dispatch((0, exports.setLoader)(false));
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            const request = {
                operationLogDto
            };
            new api_rest_1.PublicGenericControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, undefined, undefined, 'L.7e89-9f1b-0611-e83061677fb85f28190cbac1')).extLogginOperation(request).then((response) => {
                console.log('response: ', response);
                dispatch(extLogginOperationSuccess(response));
            }).catch(async (error) => {
                console.log('error: ', error);
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, exports.setLoader)(false));
            });
        });
    }
};
exports.extLogginOperation = extLogginOperation;
function setChatGenesysOpened(payload) {
    return {
        type: __1.EReduxActionTypes.GENERAL_SET_CHAT_GENESYS_OPENED,
        payload
    };
}
exports.setChatGenesysOpened = setChatGenesysOpened;
function logginOperationStart() {
    return {
        type: __1.EReduxActionTypes.GENERAL_LOGGING_OPERATION_START
    };
}
function logginOperationSuccess() {
    return {
        type: __1.EReduxActionTypes.GENERAL_LOGGING_OPERATION_SUCCESS
    };
}
const logginOperation = (operationLogDto) => async (dispatch, _, extraArguments) => {
    dispatch(logginOperationStart());
    if (extraArguments.mock) {
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            const request = {
                operationLogDto
            };
            new api_rest_1.AuditingControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).logginOperation(request).then(() => {
                dispatch(logginOperationSuccess());
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, exports.setLoader)(false));
            });
        });
    }
};
exports.logginOperation = logginOperation;
function setInAppBrowserOpened(payload) {
    return {
        type: __1.EReduxActionTypes.GENERAL_SET_IN_APP_BROWSER_OPENED,
        payload
    };
}
exports.setInAppBrowserOpened = setInAppBrowserOpened;
function setACSLoginAsked(loginAsked) {
    return {
        type: __1.EReduxActionTypes.SET_ACS_LOGIN_ASKED,
        payload: loginAsked
    };
}
exports.setACSLoginAsked = setACSLoginAsked;
function setDownloadPDF(downloadPDF) {
    return {
        type: __1.EReduxActionTypes.SET_DOWNLOAD_PDF,
        payload: downloadPDF
    };
}
exports.setDownloadPDF = setDownloadPDF;
function setAndroidPermissionGranted(androidPermissionGranted) {
    return {
        type: __1.EReduxActionTypes.SET_ANDROID_PERMISSION_GRANTED,
        payload: androidPermissionGranted
    };
}
exports.setAndroidPermissionGranted = setAndroidPermissionGranted;
function logginEnrollmentStart() {
    return {
        type: __1.EReduxActionTypes.GENERAL_LOGGING_ENROLLMENT_START
    };
}
function logginEnrollmentSuccess() {
    return {
        type: __1.EReduxActionTypes.GENERAL_LOGGING_ENROLLMENT_SUCCESS
    };
}
const logginEnrollment = (evento, noteAggiuntive) => async (dispatch, getState, extraArguments) => {
    dispatch(logginEnrollmentStart());
    if (extraArguments.mock) {
    }
    else {
        const state = getState().login;
        extraArguments.getItem('jwt').then((jwt) => {
            const request = {
                oauthSrToken: state.srInfo || '',
                operationLogDto: {
                    codiceUtente: state.formLogin.username,
                    codiceFunzione: 'ENROLLMENT',
                    evento: evento,
                    esito: 'OK',
                    noteAggiuntive: noteAggiuntive
                }
            };
            new api_rest_1.SelfResetControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).logginOperationSelfReset(request).then(() => {
                dispatch(logginEnrollmentSuccess());
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, exports.setLoader)(false));
            });
        });
    }
};
exports.logginEnrollment = logginEnrollment;
