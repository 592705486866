"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParametriDocumentaleDtoToJSON = exports.ParametriDocumentaleDtoFromJSONTyped = exports.ParametriDocumentaleDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ParametriDocumentaleDtoFromJSON(json) {
    return ParametriDocumentaleDtoFromJSONTyped(json, false);
}
exports.ParametriDocumentaleDtoFromJSON = ParametriDocumentaleDtoFromJSON;
function ParametriDocumentaleDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'flagAbilitazioneComunicazioni': !runtime_1.exists(json, 'flagAbilitazioneComunicazioni') ? undefined : json['flagAbilitazioneComunicazioni'],
        'flagAbilitazioneDocumenti': !runtime_1.exists(json, 'flagAbilitazioneDocumenti') ? undefined : json['flagAbilitazioneDocumenti'],
        'tabVisibili': !runtime_1.exists(json, 'tabVisibili') ? undefined : json['tabVisibili'],
        'tabAltro': !runtime_1.exists(json, 'tabAltro') ? undefined : json['tabAltro'],
        'comunicazioniInfoTipoEstrazioni': !runtime_1.exists(json, 'comunicazioniInfoTipoEstrazioni') ? undefined : json['comunicazioniInfoTipoEstrazioni'],
        'comunicazioniInfoLegamiCollRapporti': !runtime_1.exists(json, 'comunicazioniInfoLegamiCollRapporti') ? undefined : json['comunicazioniInfoLegamiCollRapporti'],
        'comunicazioniInfoNumeroultimecomunicazioni': !runtime_1.exists(json, 'comunicazioniInfoNumeroultimecomunicazioni') ? undefined : json['comunicazioniInfoNumeroultimecomunicazioni'],
        'comunicazioniInfoPeriodoEstrazione': !runtime_1.exists(json, 'comunicazioniInfoPeriodoEstrazione') ? undefined : json['comunicazioniInfoPeriodoEstrazione'],
        'comunicazioniInfoDataIniziale': !runtime_1.exists(json, 'comunicazioniInfoDataIniziale') ? undefined : json['comunicazioniInfoDataIniziale'],
        'comunicazioniInfoDataFinale': !runtime_1.exists(json, 'comunicazioniInfoDataFinale') ? undefined : json['comunicazioniInfoDataFinale'],
        'rapportiCartaContoDaEscludere': !runtime_1.exists(json, 'rapportiCartaContoDaEscludere') ? undefined : (json['rapportiCartaContoDaEscludere'].map(_1.RapportoCompletoDtoFromJSON)),
        'comunicazioniInfoNumeroMaxRecord': !runtime_1.exists(json, 'comunicazioniInfoNumeroMaxRecord') ? undefined : json['comunicazioniInfoNumeroMaxRecord'],
        'firmaBioDataIniziale': !runtime_1.exists(json, 'firmaBioDataIniziale') ? undefined : json['firmaBioDataIniziale'],
        'firmaBioDataFinale': !runtime_1.exists(json, 'firmaBioDataFinale') ? undefined : json['firmaBioDataFinale'],
        'firmaBioLegamiCollRapporti': !runtime_1.exists(json, 'firmaBioLegamiCollRapporti') ? undefined : json['firmaBioLegamiCollRapporti'],
        'firmaBioTipoEstrazioni': !runtime_1.exists(json, 'firmaBioTipoEstrazioni') ? undefined : json['firmaBioTipoEstrazioni'],
        'firmaBioNumeroMaxRecord': !runtime_1.exists(json, 'firmaBioNumeroMaxRecord') ? undefined : json['firmaBioNumeroMaxRecord'],
        'comunicazioniInfoShowCount': !runtime_1.exists(json, 'comunicazioniInfoShowCount') ? undefined : json['comunicazioniInfoShowCount'],
        'abilita_TIPOLOGIA_DOCUMENTO': !runtime_1.exists(json, 'abilita_TIPOLOGIA_DOCUMENTO') ? undefined : json['abilita_TIPOLOGIA_DOCUMENTO'],
        'abilitaSegnaTuttiComeLetti': !runtime_1.exists(json, 'abilitaSegnaTuttiComeLetti') ? undefined : json['abilitaSegnaTuttiComeLetti'],
    };
}
exports.ParametriDocumentaleDtoFromJSONTyped = ParametriDocumentaleDtoFromJSONTyped;
function ParametriDocumentaleDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'flagAbilitazioneComunicazioni': value.flagAbilitazioneComunicazioni,
        'flagAbilitazioneDocumenti': value.flagAbilitazioneDocumenti,
        'tabVisibili': value.tabVisibili,
        'tabAltro': value.tabAltro,
        'comunicazioniInfoTipoEstrazioni': value.comunicazioniInfoTipoEstrazioni,
        'comunicazioniInfoLegamiCollRapporti': value.comunicazioniInfoLegamiCollRapporti,
        'comunicazioniInfoNumeroultimecomunicazioni': value.comunicazioniInfoNumeroultimecomunicazioni,
        'comunicazioniInfoPeriodoEstrazione': value.comunicazioniInfoPeriodoEstrazione,
        'comunicazioniInfoDataIniziale': value.comunicazioniInfoDataIniziale,
        'comunicazioniInfoDataFinale': value.comunicazioniInfoDataFinale,
        'rapportiCartaContoDaEscludere': value.rapportiCartaContoDaEscludere === undefined ? undefined : (value.rapportiCartaContoDaEscludere.map(_1.RapportoCompletoDtoToJSON)),
        'comunicazioniInfoNumeroMaxRecord': value.comunicazioniInfoNumeroMaxRecord,
        'firmaBioDataIniziale': value.firmaBioDataIniziale,
        'firmaBioDataFinale': value.firmaBioDataFinale,
        'firmaBioLegamiCollRapporti': value.firmaBioLegamiCollRapporti,
        'firmaBioTipoEstrazioni': value.firmaBioTipoEstrazioni,
        'firmaBioNumeroMaxRecord': value.firmaBioNumeroMaxRecord,
        'comunicazioniInfoShowCount': value.comunicazioniInfoShowCount,
        'abilita_TIPOLOGIA_DOCUMENTO': value.abilita_TIPOLOGIA_DOCUMENTO,
        'abilitaSegnaTuttiComeLetti': value.abilitaSegnaTuttiComeLetti,
    };
}
exports.ParametriDocumentaleDtoToJSON = ParametriDocumentaleDtoToJSON;
