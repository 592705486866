"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TitoloScadenzaDtoToJSON = exports.TitoloScadenzaDtoFromJSONTyped = exports.TitoloScadenzaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function TitoloScadenzaDtoFromJSON(json) {
    return TitoloScadenzaDtoFromJSONTyped(json, false);
}
exports.TitoloScadenzaDtoFromJSON = TitoloScadenzaDtoFromJSON;
function TitoloScadenzaDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'ibanAddebito': !runtime_1.exists(json, 'ibanAddebito') ? undefined : json['ibanAddebito'],
        'rapportoAddebito': !runtime_1.exists(json, 'rapportoAddebito') ? undefined : json['rapportoAddebito'],
        'dataEvento': !runtime_1.exists(json, 'dataEvento') ? undefined : (new Date(json['dataEvento'])),
        'titolo': !runtime_1.exists(json, 'titolo') ? undefined : json['titolo'],
        'indicativoTitolo': !runtime_1.exists(json, 'indicativoTitolo') ? undefined : json['indicativoTitolo'],
        'codiceIsin': !runtime_1.exists(json, 'codiceIsin') ? undefined : json['codiceIsin'],
        'descrizioneTitolo': !runtime_1.exists(json, 'descrizioneTitolo') ? undefined : json['descrizioneTitolo'],
        'tassoLordo': !runtime_1.exists(json, 'tassoLordo') ? undefined : json['tassoLordo'],
        'quantita': !runtime_1.exists(json, 'quantita') ? undefined : json['quantita'],
        'causale': !runtime_1.exists(json, 'causale') ? undefined : json['causale'],
        'importoNetto': !runtime_1.exists(json, 'importoNetto') ? undefined : json['importoNetto'],
        'naturaFlusso': !runtime_1.exists(json, 'naturaFlusso') ? undefined : json['naturaFlusso'],
        'diviso': !runtime_1.exists(json, 'diviso') ? undefined : json['diviso'],
        'codiceRapporto': !runtime_1.exists(json, 'codiceRapporto') ? undefined : json['codiceRapporto'],
    };
}
exports.TitoloScadenzaDtoFromJSONTyped = TitoloScadenzaDtoFromJSONTyped;
function TitoloScadenzaDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'ibanAddebito': value.ibanAddebito,
        'rapportoAddebito': value.rapportoAddebito,
        'dataEvento': value.dataEvento === undefined ? undefined : (value.dataEvento.toISOString()),
        'titolo': value.titolo,
        'indicativoTitolo': value.indicativoTitolo,
        'codiceIsin': value.codiceIsin,
        'descrizioneTitolo': value.descrizioneTitolo,
        'tassoLordo': value.tassoLordo,
        'quantita': value.quantita,
        'causale': value.causale,
        'importoNetto': value.importoNetto,
        'naturaFlusso': value.naturaFlusso,
        'diviso': value.diviso,
        'codiceRapporto': value.codiceRapporto,
    };
}
exports.TitoloScadenzaDtoToJSON = TitoloScadenzaDtoToJSON;
