"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setSmartOtpEnrolledUsername = exports.resetTextSearchMenu = exports.setTextSearchMenu = exports.getNotificheMenu = exports.getTags = exports.getMenu = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_1 = require("../general");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
function getMenuStart() {
    return {
        type: __1.EReduxActionTypes.MENU_GET_MENU_START
    };
}
function getMenuSuccess(payload, platform) {
    return {
        type: platform == 'mobile' ? __1.EReduxActionTypes.MENU_GET_MENU_SUCCESS_MOBILE : __1.EReduxActionTypes.MENU_GET_MENU_SUCCESS,
        payload
    };
}
function generalError(value) {
    return async (dispatch) => {
        dispatch((0, general_1.setError)(value));
    };
}
const getMenu = () => async (dispatch, _, extraArguments) => {
    dispatch(getMenuStart());
    dispatch((0, general_1.setLoader)(true));
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/menu.json')).then(menu => {
            dispatch(getMenuSuccess((0, api_rest_1.ProfiloMenuDtoFromJSON)(menu), extraArguments.platform));
            dispatch((0, general_1.setLoader)(false));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.ProfiloControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).menu().then((response) => {
                dispatch(getMenuSuccess(response, extraArguments.platform));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
                dispatch((0, general_1.setPostLoginError)(true));
            }).finally(() => {
                dispatch((0, general_1.setLoader)(false));
            });
        });
    }
};
exports.getMenu = getMenu;
function getTagsStart() {
    return {
        type: __1.EReduxActionTypes.MENU_GET_TAGS_START
    };
}
function getTagsSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.MENU_GET_TAGS_SUCCESS,
        payload
    };
}
const getTags = () => async (dispatch, getState, extraArguments) => {
    dispatch(getTagsStart());
    dispatch((0, general_1.setLoader)(true));
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/menu.json')).then(menu => {
            // dispatch(getTagsSuccess(ProfiloMenuDtoFromJSON(menu)));
            dispatch((0, general_1.setLoader)(false));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            const request = {
                lingua: getState().i18n.currentLanguage
            };
            new api_rest_1.ProfiloControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).tags(request).then((response) => {
                dispatch(getTagsSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_1.setLoader)(false));
            });
        });
    }
};
exports.getTags = getTags;
function getNotificheMenuStart() {
    return {
        type: __1.EReduxActionTypes.MENU_GET_NOTIFICHE_MENU_START
    };
}
function getNotificheMenuSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.MENU_GET_NOTIFICHE_MENU_SUCCESS,
        payload
    };
}
const getNotificheMenu = () => async (dispatch, _, extraArguments) => {
    dispatch(getNotificheMenuStart());
    // dispatch(setLoader(true));
    if (extraArguments.mock) {
        // dispatch(setLoader(false));
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.ProfiloControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getNotifiche().then((response) => {
                dispatch(getNotificheMenuSuccess(response));
            }).catch(async (error) => {
                /*dispatch(generalError(*/ await (0, helpers_1.formatError)(error); /*));*/
            }).finally(() => {
                // dispatch(setLoader(false));
            });
        });
    }
};
exports.getNotificheMenu = getNotificheMenu;
function setTextSearchMenu(txt) {
    return {
        type: __1.EReduxActionTypes.MENU_SET_TEXT_SEARCH,
        payload: txt
    };
}
exports.setTextSearchMenu = setTextSearchMenu;
function resetTextSearchMenu() {
    return {
        type: __1.EReduxActionTypes.MENU_RESET_TEXT_SEARCH,
    };
}
exports.resetTextSearchMenu = resetTextSearchMenu;
function setSmartOtpEnrolledUsername(enrolledUsername) {
    return {
        type: __1.EReduxActionTypes.MENU_SET_SMART_OTP_ENROLLED_USERNAME,
        payload: enrolledUsername
    };
}
exports.setSmartOtpEnrolledUsername = setSmartOtpEnrolledUsername;
