"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MotivazionePagamentoDtoNaturaMovimentoEnum = exports.MotivazionePagamentoDtoToJSON = exports.MotivazionePagamentoDtoFromJSONTyped = exports.MotivazionePagamentoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function MotivazionePagamentoDtoFromJSON(json) {
    return MotivazionePagamentoDtoFromJSONTyped(json, false);
}
exports.MotivazionePagamentoDtoFromJSON = MotivazionePagamentoDtoFromJSON;
function MotivazionePagamentoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'naturaMovimento': !runtime_1.exists(json, 'naturaMovimento') ? undefined : json['naturaMovimento'],
        'codice': !runtime_1.exists(json, 'codice') ? undefined : json['codice'],
        'descrizione': !runtime_1.exists(json, 'descrizione') ? undefined : json['descrizione'],
        'principale': !runtime_1.exists(json, 'principale') ? undefined : json['principale'],
    };
}
exports.MotivazionePagamentoDtoFromJSONTyped = MotivazionePagamentoDtoFromJSONTyped;
function MotivazionePagamentoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'naturaMovimento': value.naturaMovimento,
        'codice': value.codice,
        'descrizione': value.descrizione,
        'principale': value.principale,
    };
}
exports.MotivazionePagamentoDtoToJSON = MotivazionePagamentoDtoToJSON;
/**
* @export
* @enum {string}
*/
var MotivazionePagamentoDtoNaturaMovimentoEnum;
(function (MotivazionePagamentoDtoNaturaMovimentoEnum) {
    MotivazionePagamentoDtoNaturaMovimentoEnum["BON"] = "BON";
    MotivazionePagamentoDtoNaturaMovimentoEnum["BIR"] = "BIR";
    MotivazionePagamentoDtoNaturaMovimentoEnum["SCT"] = "SCT";
    MotivazionePagamentoDtoNaturaMovimentoEnum["SCI"] = "SCI";
})(MotivazionePagamentoDtoNaturaMovimentoEnum = exports.MotivazionePagamentoDtoNaturaMovimentoEnum || (exports.MotivazionePagamentoDtoNaturaMovimentoEnum = {}));
