"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocumentaleControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class DocumentaleControllerApi extends runtime.BaseAPI {
    /**
     * Legge e restituisce un singolo documento.
     */
    getDocumentoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getDocumento.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/documentale/documenti/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.BlobApiResponse(response);
        });
    }
    /**
     * Legge e restituisce un singolo documento.
     */
    getDocumento(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getDocumentoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Legge e restituisce un singolo documento per tipo.
     */
    getDocumentoPerTipoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getDocumentoPerTipo.');
            }
            if (requestParameters.tipologia === null || requestParameters.tipologia === undefined) {
                throw new runtime.RequiredError('tipologia', 'Required parameter requestParameters.tipologia was null or undefined when calling getDocumentoPerTipo.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/documentale/comunicazioni/{id}/{tipologia}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"tipologia"}}`, encodeURIComponent(String(requestParameters.tipologia))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.BlobApiResponse(response);
        });
    }
    /**
     * Legge e restituisce un singolo documento per tipo.
     */
    getDocumentoPerTipo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getDocumentoPerTipoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce l\'elenco delle comunicazioni.
     */
    getElencoComunicazioniRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.cercaComunicazioniInDto === null || requestParameters.cercaComunicazioniInDto === undefined) {
                throw new runtime.RequiredError('cercaComunicazioniInDto', 'Required parameter requestParameters.cercaComunicazioniInDto was null or undefined when calling getElencoComunicazioni.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/documentale/comunicazioni`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CercaComunicazioniInDtoToJSON(requestParameters.cercaComunicazioniInDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.ComunicazioneDtoFromJSON));
        });
    }
    /**
     * Restituisce l\'elenco delle comunicazioni.
     */
    getElencoComunicazioni(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getElencoComunicazioniRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce l\'elenco dei documenti.
     */
    getElencoDocumentiRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.cercaDocumentiInDto === null || requestParameters.cercaDocumentiInDto === undefined) {
                throw new runtime.RequiredError('cercaDocumentiInDto', 'Required parameter requestParameters.cercaDocumentiInDto was null or undefined when calling getElencoDocumenti.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/documentale/documenti`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CercaDocumentiInDtoToJSON(requestParameters.cercaDocumentiInDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.ComunicazioneDtoFromJSON));
        });
    }
    /**
     * Restituisce l\'elenco dei documenti.
     */
    getElencoDocumenti(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getElencoDocumentiRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce il numero delle nuove comunicazioni.
     */
    getNumeroNuoveComunicazioniRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/documentale/nuovi`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.TextApiResponse(response);
        });
    }
    /**
     * Restituisce il numero delle nuove comunicazioni.
     */
    getNumeroNuoveComunicazioni() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getNumeroNuoveComunicazioniRaw();
            return yield response.value();
        });
    }
    /**
     * Recupera i parametri.
     */
    getParametriRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/documentale/parametri`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ParametriDocumentaleDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera i parametri.
     */
    getParametri() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getParametriRaw();
            return yield response.value();
        });
    }
    /**
     * Restituisce la lista di tipologie di comunicazioni.
     */
    getTipologieComunicazioniRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/documentale/tipologie`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     * Restituisce la lista di tipologie di comunicazioni.
     */
    getTipologieComunicazioni() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getTipologieComunicazioniRaw();
            return yield response.value();
        });
    }
    /**
     * Segna come letti i documenti passati in input.
     */
    segnaComeLettiRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
                throw new runtime.RequiredError('requestBody', 'Required parameter requestParameters.requestBody was null or undefined when calling segnaComeLetti.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/documentale/segnaComeLetti`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters.requestBody,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.DatiDocumentoDtoFromJSON));
        });
    }
    /**
     * Segna come letti i documenti passati in input.
     */
    segnaComeLetti(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.segnaComeLettiRaw(requestParameters);
            return yield response.value();
        });
    }
}
exports.DocumentaleControllerApi = DocumentaleControllerApi;
