"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.revocaSelezionataMandatiSDD = exports.buildFilterExportDettaglioMandatoSDD = exports.buildFilterExportElencoMandatiSDD = exports.pdfMandatiSDD = exports.formRevocaMandatoSDD = exports.infoDispositivaMandatoSDD = exports.esitoRevocaMandatoSDD = exports.mandatoSelezionato = exports.filtriMandatiSDDApplicati = exports.filtriMandatiSDD = exports.parametriMandatiSDD = exports.mandatiSDD = void 0;
const reselect_1 = require("reselect");
const mandatiSDDReducer = (state) => state;
exports.mandatiSDD = (0, reselect_1.createSelector)(mandatiSDDReducer, (state) => {
    return state.mandatiSDD;
});
exports.parametriMandatiSDD = (0, reselect_1.createSelector)(mandatiSDDReducer, (state) => {
    return state.parametri;
});
exports.filtriMandatiSDD = (0, reselect_1.createSelector)(mandatiSDDReducer, (state) => {
    return state.filter;
});
exports.filtriMandatiSDDApplicati = (0, reselect_1.createSelector)(mandatiSDDReducer, (state) => {
    return state.filter.statoMandato.length !== 0;
});
exports.mandatoSelezionato = (0, reselect_1.createSelector)(mandatiSDDReducer, (state) => {
    return state.sddSelezionato;
});
exports.esitoRevocaMandatoSDD = (0, reselect_1.createSelector)(mandatiSDDReducer, (state) => {
    return state.esitoRevocaMandatoSDD;
});
exports.infoDispositivaMandatoSDD = (0, reselect_1.createSelector)(mandatiSDDReducer, (state) => {
    return state.infoRevoca;
});
exports.formRevocaMandatoSDD = (0, reselect_1.createSelector)(mandatiSDDReducer, (state) => {
    return state.formRevoca;
});
exports.pdfMandatiSDD = (0, reselect_1.createSelector)(mandatiSDDReducer, (state) => {
    return state.pdfMandatiSDD;
});
exports.buildFilterExportElencoMandatiSDD = (0, reselect_1.createSelector)(mandatiSDDReducer, (state) => {
    var _a, _b;
    const request = {
        contoAddebito: ((_b = (_a = state.filter.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.id) || 0,
        schema: 'CORE'
    };
    return request;
});
exports.buildFilterExportDettaglioMandatoSDD = (0, reselect_1.createSelector)(mandatiSDDReducer, (state) => {
    var _a, _b, _c;
    const request = {
        contoAddebito: ((_b = (_a = state.filter.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.id) || 0,
        schema: 'CORE',
        id: ((_c = state.sddSelezionato) === null || _c === void 0 ? void 0 : _c.id) ? state.sddSelezionato.id : 0
    };
    return request;
});
exports.revocaSelezionataMandatiSDD = (0, reselect_1.createSelector)(mandatiSDDReducer, (state) => {
    return state.revocaSelezionata;
});
