"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoadIndiciDtoToJSON = exports.LoadIndiciDtoFromJSONTyped = exports.LoadIndiciDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function LoadIndiciDtoFromJSON(json) {
    return LoadIndiciDtoFromJSONTyped(json, false);
}
exports.LoadIndiciDtoFromJSON = LoadIndiciDtoFromJSON;
function LoadIndiciDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'quotazioni': !runtime_1.exists(json, 'quotazioni') ? undefined : _1.QuotazioniDtoFromJSON(json['quotazioni']),
    };
}
exports.LoadIndiciDtoFromJSONTyped = LoadIndiciDtoFromJSONTyped;
function LoadIndiciDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'quotazioni': _1.QuotazioniDtoToJSON(value.quotazioni),
    };
}
exports.LoadIndiciDtoToJSON = LoadIndiciDtoToJSON;
