"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EliminaAppuntamentoInToJSON = exports.EliminaAppuntamentoInFromJSONTyped = exports.EliminaAppuntamentoInFromJSON = void 0;
const runtime_1 = require("../runtime");
function EliminaAppuntamentoInFromJSON(json) {
    return EliminaAppuntamentoInFromJSONTyped(json, false);
}
exports.EliminaAppuntamentoInFromJSON = EliminaAppuntamentoInFromJSON;
function EliminaAppuntamentoInFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'data': !runtime_1.exists(json, 'data') ? undefined : (new Date(json['data'])),
        'deleteAll': !runtime_1.exists(json, 'deleteAll') ? undefined : json['deleteAll'],
    };
}
exports.EliminaAppuntamentoInFromJSONTyped = EliminaAppuntamentoInFromJSONTyped;
function EliminaAppuntamentoInToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'data': value.data === undefined ? undefined : (value.data.toISOString()),
        'deleteAll': value.deleteAll,
    };
}
exports.EliminaAppuntamentoInToJSON = EliminaAppuntamentoInToJSON;
