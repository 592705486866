"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnatocismoControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class AnatocismoControllerApi extends runtime.BaseAPI {
    /**
     */
    autorizzaAnatocismoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling autorizzaAnatocismo.');
            }
            if (requestParameters.credenzialeDispositivaDto === null || requestParameters.credenzialeDispositivaDto === undefined) {
                throw new runtime.RequiredError('credenzialeDispositivaDto', 'Required parameter requestParameters.credenzialeDispositivaDto was null or undefined when calling autorizzaAnatocismo.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/anatocismo/autorizza/{idDispositiva}`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CredenzialeDispositivaDtoToJSON(requestParameters.credenzialeDispositivaDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.EsitoAutorizzazioneDisposizioneAnatocismoDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    autorizzaAnatocismo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.autorizzaAnatocismoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     */
    controlloAnatocismoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.controlloAnatocismoDto === null || requestParameters.controlloAnatocismoDto === undefined) {
                throw new runtime.RequiredError('controlloAnatocismoDto', 'Required parameter requestParameters.controlloAnatocismoDto was null or undefined when calling controlloAnatocismo.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/anatocismo/controllo`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ControlloAnatocismoDtoToJSON(requestParameters.controlloAnatocismoDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ControlloAnatocismoDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    controlloAnatocismo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.controlloAnatocismoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Export in PDF del dettaglio dell’Anatocismo
     */
    dettaglioAnatocismoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.rispostaAutorizzazioneAnatocismoDto === null || requestParameters.rispostaAutorizzazioneAnatocismoDto === undefined) {
                throw new runtime.RequiredError('rispostaAutorizzazioneAnatocismoDto', 'Required parameter requestParameters.rispostaAutorizzazioneAnatocismoDto was null or undefined when calling dettaglioAnatocismo.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/anatocismo/export/dettaglio/pdf`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.RispostaAutorizzazioneAnatocismoDtoToJSON(requestParameters.rispostaAutorizzazioneAnatocismoDto),
            });
            return new runtime.BlobApiResponse(response);
        });
    }
    /**
     * Export in PDF del dettaglio dell’Anatocismo
     */
    dettaglioAnatocismo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.dettaglioAnatocismoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     */
    infoAutenticazioneAnatocismoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling infoAutenticazioneAnatocismo.');
            }
            const queryParameters = {};
            if (requestParameters.chiamataUscente !== undefined) {
                queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
            }
            if (requestParameters.eseguiInit !== undefined) {
                queryParameters['eseguiInit'] = requestParameters.eseguiInit;
            }
            if (requestParameters.offline !== undefined) {
                queryParameters['offline'] = requestParameters.offline;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/anatocismo/infoAutenticazione/{idDispositiva}`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    infoAutenticazioneAnatocismo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.infoAutenticazioneAnatocismoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     */
    recuperaPreferenzeRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/anatocismo/preferenze`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.PreferenzeAnatocismoDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    recuperaPreferenze() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.recuperaPreferenzeRaw();
            return yield response.value();
        });
    }
}
exports.AnatocismoControllerApi = AnatocismoControllerApi;
