import { ContattoDto, contattoSelezionato, eliminaContattoRubrica, getContattiRubrica, parametriContatti, rubricaContatti, setRubricaSetContattoSelezionato } from "@sparkasse/commons";
import ButtonText2 from "component/buttonText2/buttonText2.component";
import InputSearch from "component/inputSearchBar/inputSearchBar";
import RightbarShoulder from "component/Rightbar/RightbarShoulder";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AppState } from "reducers";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import Breadcrumbs, { BreadcrumbsProps } from "../../component/breadcrumbs/breadcrumbs.component";
import Guide from "../../component/guideSection/guide.section.component";
import { navigateToPage, WEBPAGE } from "../../component/history/history.component";
import Contacts from "./rubrica.contacts";

const Rubrica: React.FC<Props> = (props: Props) => {

  const { t } = useTranslation();

  const {
    getContattiRubrica,
    contattiRubrica,
    contattoSelezionato,
    eliminaContattoRubrica,
    setRubricaSetContattoSelezionato
  } = props;

  const [internalItems, setInternalItems] = useState<ContattoDto[]>([]);
  const [internalItemsFiltered, setInternalItemsFiltered] = useState<ContattoDto[] | undefined>(undefined);
  const [filter, setFilter] = useState<string>('');
  const [isVisible, setVisible] = useState<boolean>(false);

  const breadcrumbs: Array<BreadcrumbsProps> = [
    { menuId: "menu.pagare_e_trasferire" },
    {
      menuId: "menu.rubrica_beneficiari",
      inactive: false,
      action: undefined
    }
  ];

  useEffect(() => {
    caricaContatti();
  }, []);

  useEffect(() => {
    if (contattiRubrica && contattiRubrica.contatti) {
      setInternalItems(contattiRubrica.contatti);
      setInternalItemsFiltered(contattiRubrica.contatti);
    }
  }, [contattiRubrica]);

  const caricaContatti = () => {
    getContattiRubrica();
  };

  const onDetail = (item: ContattoDto) => {
    setRubricaSetContattoSelezionato(item);
  };

  useEffect(() => {
    contattoSelezionato && contattoSelezionato.id && navigateToPage(WEBPAGE.DettaglioContatto.url);
  }, [contattoSelezionato]);

  const goToNuovaRichiesta = () => {
    navigateToPage(WEBPAGE.NuovoContatto.url);
  };

  const onGoHome = () => {
    navigateToPage(WEBPAGE.Home.url);
  };

  const onModifica = (item: ContattoDto) => {
    item && navigateToPage(WEBPAGE.ModificaContatto.url, { item });
  };

  const onElimina = (item: ContattoDto) => {
    item && item.id && eliminaContattoRubrica(item.id);
  };

  const filtro = (text: string) => {
    const newItems = [];
    if (internalItems) {
      for (let i = 0; i < internalItems.length; i++) {
        if (internalItems[i].alias && internalItems[i].alias!.toLowerCase().indexOf(text.toLowerCase()) > -1) {
          newItems.push(internalItems[i]);
        }
      }
      setInternalItemsFiltered(newItems);
    };
  };

  const onFilterChange = (text: string) => {
    setFilter(text);
    if (text) {
      filtro(text);
    } else {
      setInternalItemsFiltered(internalItems);
    }
  };

  return (
    <div className="central">

      <div className="main">
        <div className="core">

          <Breadcrumbs
            breadcrumbs={breadcrumbs}
            onAction={onGoHome}
            onOpenSpallaDestra={() => setVisible(true)}
          />

          <div className="archivio-disposizioni">
            {internalItems.length > 0 && <InputSearch placeholder={t('button.applicaFiltri')[0].concat((t('button.applicaFiltri').substr(1).toLowerCase()))} onChange={onFilterChange} value={filter} />}
            {internalItems.length === 0 &&
              <div>
                <p style={{ fontSize: '20px', fontWeight: 'bold' }}>{t('rubrica.lista.noContactsTitle')}</p>
                <p>{t('rubrica.lista.noContactsDescription')}</p>
              </div>}
            <div className="new-button-list">
              <ButtonText2
                img="nuovo-beneficiario"
                descrizione={t("rubrica.lista.aggiungiBeneficiario")}
                onClick={goToNuovaRichiesta}
                height={25}
                width={25}
              />
            </div>
            {internalItems.length > 0 &&
              <Contacts
                onElimina={onElimina}
                onModifica={onModifica}
                items={internalItemsFiltered ? internalItemsFiltered : []}
                apriDettaglio={onDetail}
              />}
          </div>

        </div>
      </div>

      <RightbarShoulder
        isVisible={isVisible}
        setVisible={setVisible}
      >
        <Guide
          titolo={t('guide.titoloRubrica')}
          descrizione={t('guide.descrizioneRubrica')}
        />
      </RightbarShoulder>

    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  contattiRubrica: rubricaContatti(state.rubricaContatti),
  parametriContatti: parametriContatti(state.rubricaContatti),
  contattoSelezionato: contattoSelezionato(state.rubricaContatti)
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getContattiRubrica,
      eliminaContattoRubrica,
      setRubricaSetContattoSelezionato
    },
    dispatch
  );

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Rubrica);
