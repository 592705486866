"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecuperaImpostazioniDtoToJSON = exports.RecuperaImpostazioniDtoFromJSONTyped = exports.RecuperaImpostazioniDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function RecuperaImpostazioniDtoFromJSON(json) {
    return RecuperaImpostazioniDtoFromJSONTyped(json, false);
}
exports.RecuperaImpostazioniDtoFromJSON = RecuperaImpostazioniDtoFromJSON;
function RecuperaImpostazioniDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'numeroContratto': !runtime_1.exists(json, 'numeroContratto') ? undefined : json['numeroContratto'],
        'numeroTelefono': !runtime_1.exists(json, 'numeroTelefono') ? undefined : json['numeroTelefono'],
        'returnCode': !runtime_1.exists(json, 'returnCode') ? undefined : json['returnCode'],
        'returnMessage': !runtime_1.exists(json, 'returnMessage') ? undefined : json['returnMessage'],
        'rangeSoglie': !runtime_1.exists(json, 'rangeSoglie') ? undefined : (json['rangeSoglie'].map(_1.SogliaDaRecuperareDtoFromJSON)),
    };
}
exports.RecuperaImpostazioniDtoFromJSONTyped = RecuperaImpostazioniDtoFromJSONTyped;
function RecuperaImpostazioniDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'numeroContratto': value.numeroContratto,
        'numeroTelefono': value.numeroTelefono,
        'returnCode': value.returnCode,
        'returnMessage': value.returnMessage,
        'rangeSoglie': value.rangeSoglie === undefined ? undefined : (value.rangeSoglie.map(_1.SogliaDaRecuperareDtoToJSON)),
    };
}
exports.RecuperaImpostazioniDtoToJSON = RecuperaImpostazioniDtoToJSON;
