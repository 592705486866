"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AutenticationContextDtoToJSON = exports.AutenticationContextDtoFromJSONTyped = exports.AutenticationContextDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function AutenticationContextDtoFromJSON(json) {
    return AutenticationContextDtoFromJSONTyped(json, false);
}
exports.AutenticationContextDtoFromJSON = AutenticationContextDtoFromJSON;
function AutenticationContextDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'context': !runtime_1.exists(json, 'context') ? undefined : json['context'],
        'info': !runtime_1.exists(json, 'info') ? undefined : json['info'],
        'srInfo': !runtime_1.exists(json, 'srInfo') ? undefined : json['srInfo'],
    };
}
exports.AutenticationContextDtoFromJSONTyped = AutenticationContextDtoFromJSONTyped;
function AutenticationContextDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'context': value.context,
        'info': value.info,
        'srInfo': value.srInfo,
    };
}
exports.AutenticationContextDtoToJSON = AutenticationContextDtoToJSON;
