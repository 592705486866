"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pctReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const helpers_1 = require("../helpers");
const pct_types_1 = require("./pct.types");
const initialState = {
    form: (0, pct_types_1.FormPCTDtoFromJSONTyped)({}),
    parametri: (0, api_rest_1.ParametriFinanzaDtoFromJSON)({}),
    listini: [(0, api_rest_1.PctDtoFromJSON)({})],
    riepilogo: (0, api_rest_1.PctDisposizioneVirtualeDtoFromJSON)({}),
    infoDispositiva: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
    esito: (0, api_rest_1.EsitoAutorizzazioneDisposizionePctDtoFromJSON)({})
};
const pctReducer = (state = initialState, action) => {
    var _a, _b, _c;
    switch (action.type) {
        case pct_types_1.EReduxPCTActionTypes.PCT_GET_PARAMETRI_PCT_SUCCESS:
            return {
                ...initialState,
                parametri: action.payload
            };
        case pct_types_1.EReduxPCTActionTypes.PCT_GET_LISTINI_PCT_SUCCESS:
            return {
                ...state,
                listini: action.payload
            };
        case pct_types_1.EReduxPCTActionTypes.PCT_CONTROLLO_ACQUISTO_PCT_SUCCESS:
            return {
                ...state,
                riepilogo: action.payload,
                form: {
                    ...state.form,
                    checkboxEnabled: !((_a = action.payload.pct) === null || _a === void 0 ? void 0 : _a.abilitaPdfKiid)
                }
            };
        case pct_types_1.EReduxPCTActionTypes.PCT_GET_INFO_DISPOSITIVA_ACQUISTO_PCT_SUCCESS:
            return {
                ...state,
                infoDispositiva: action.payload,
                esito: initialState.esito
            };
        case pct_types_1.EReduxPCTActionTypes.PCT_AUTORIZZA_ACQUISTO_PCT_SUCCESS:
            if (action.payload.stato === 'OK') {
                return {
                    ...state,
                    //riepilogo: initialState.riepilogo,
                    esito: action.payload
                };
            }
            else {
                return {
                    ...state,
                    infoDispositiva: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoDispositiva, action.payload.informazioniAutenticazioneDispositiva),
                    esito: action.payload
                };
            }
        case pct_types_1.EReduxPCTActionTypes.PCT_SET_STEP:
            return {
                ...state,
                form: {
                    ...state.form,
                    step: action.payload
                }
            };
        case pct_types_1.EReduxPCTActionTypes.PCT_SET_RAPPORTO_SELEZIONATO:
            return {
                ...state,
                form: {
                    ...state.form,
                    rapportoSelezionato: action.payload
                }
            };
        case pct_types_1.EReduxPCTActionTypes.PCT_SET_IMPORTO:
            return {
                ...state,
                form: {
                    ...state.form,
                    importo: action.payload,
                    importoDirty: true,
                    importoError: (0, helpers_1.validationTransazione)(action.payload, (_b = state.form.listinoSelezionato.importoMax) !== null && _b !== void 0 ? _b : 0, (_c = state.form.listinoSelezionato.importoMin) !== null && _c !== void 0 ? _c : 0, state.form.stepImporto)
                }
            };
        case pct_types_1.EReduxPCTActionTypes.PCT_SET_LISTINO_SELEZIONATO:
            return {
                ...state,
                form: {
                    ...state.form,
                    listinoSelezionato: action.payload,
                    importo: state.form.importo ? state.form.importo : action.payload.importoMin || 0
                }
            };
        case pct_types_1.EReduxPCTActionTypes.PCT_SET_PRESA_VISIONE_SCHEDA:
            return {
                ...state,
                form: {
                    ...state.form,
                    presaVisioneScheda: action.payload
                }
            };
        case pct_types_1.EReduxPCTActionTypes.PCT_SET_PRESA_VISIONE_COSTI:
            return {
                ...state,
                form: {
                    ...state.form,
                    presaVisioneCosti: action.payload
                }
            };
        case pct_types_1.EReduxPCTActionTypes.PCT_SET_CHECKBOX_ENABLED:
            return {
                ...state,
                form: {
                    ...state.form,
                    checkboxEnabled: action.payload
                }
            };
        case pct_types_1.EReduxPCTActionTypes.PCT_SET_FORM_SUBMITTED:
            return {
                ...state,
                form: {
                    ...state.form,
                    formSubmitted: action.payload
                }
            };
        case pct_types_1.EReduxPCTActionTypes.PCT_RESET_PCT:
            return {
                ...initialState
            };
        case pct_types_1.EReduxPCTActionTypes.PCT_RESET_RIEPILOGO:
            return {
                ...state,
                riepilogo: initialState.riepilogo
            };
        case pct_types_1.EReduxPCTActionTypes.PCT_RESET_ESITO:
            return {
                ...state,
                esito: initialState.esito
            };
        case pct_types_1.EReduxPCTActionTypes.PCT_SET_STEP_IMPORTO:
            return {
                ...state,
                form: {
                    ...state.form,
                    stepImporto: action.payload
                }
            };
        case pct_types_1.EReduxPCTActionTypes.PCT_ESPORTA_PDF_ACQUISTO_PCT_SUCCESS:
            return {
                ...state,
                form: {
                    ...state.form,
                    checkboxEnabled: state.form.step === 1
                }
            };
        case pct_types_1.EReduxPCTActionTypes.PCT_RESET_FORM:
            return {
                ...state,
                form: {
                    ...initialState.form,
                    rapportoSelezionato: state.form.rapportoSelezionato
                },
                esito: initialState.esito,
                infoDispositiva: initialState.infoDispositiva,
                riepilogo: initialState.riepilogo
            };
        case __1.EReduxActionTypes.RESET_ALL: // action invocata dalla logout
            return {
                ...initialState
            };
        default:
            return state;
    }
};
exports.pctReducer = pctReducer;
exports.default = exports.pctReducer;
