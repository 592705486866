"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MovimentoCartaDtoToJSON = exports.MovimentoCartaDtoFromJSONTyped = exports.MovimentoCartaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function MovimentoCartaDtoFromJSON(json) {
    return MovimentoCartaDtoFromJSONTyped(json, false);
}
exports.MovimentoCartaDtoFromJSON = MovimentoCartaDtoFromJSON;
function MovimentoCartaDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'movimentoId': !runtime_1.exists(json, 'movimentoId') ? undefined : json['movimentoId'],
        'codicePosizione': !runtime_1.exists(json, 'codicePosizione') ? undefined : json['codicePosizione'],
        'codiceAnagrafica': !runtime_1.exists(json, 'codiceAnagrafica') ? undefined : json['codiceAnagrafica'],
        'codiceProgressivoCarta': !runtime_1.exists(json, 'codiceProgressivoCarta') ? undefined : json['codiceProgressivoCarta'],
        'codiceProgressivoCircuito': !runtime_1.exists(json, 'codiceProgressivoCircuito') ? undefined : json['codiceProgressivoCircuito'],
        'codiceAliasCarta': !runtime_1.exists(json, 'codiceAliasCarta') ? undefined : json['codiceAliasCarta'],
        'dataMovimento': !runtime_1.exists(json, 'dataMovimento') ? undefined : (new Date(json['dataMovimento'])),
        'dataContabilizzazione': !runtime_1.exists(json, 'dataContabilizzazione') ? undefined : (new Date(json['dataContabilizzazione'])),
        'sicCode': !runtime_1.exists(json, 'sicCode') ? undefined : json['sicCode'],
        'codiceCircuito': !runtime_1.exists(json, 'codiceCircuito') ? undefined : json['codiceCircuito'],
        'codiceTipoTecnologia': !runtime_1.exists(json, 'codiceTipoTecnologia') ? undefined : json['codiceTipoTecnologia'],
        'codiceTipoMovimento': !runtime_1.exists(json, 'codiceTipoMovimento') ? undefined : json['codiceTipoMovimento'],
        'codiceTipoOperazione': !runtime_1.exists(json, 'codiceTipoOperazione') ? undefined : json['codiceTipoOperazione'],
        'importo': !runtime_1.exists(json, 'importo') ? undefined : json['importo'],
        'divisa': !runtime_1.exists(json, 'divisa') ? undefined : json['divisa'],
        'importoDivisaOriginaria': !runtime_1.exists(json, 'importoDivisaOriginaria') ? undefined : json['importoDivisaOriginaria'],
        'codiceArn': !runtime_1.exists(json, 'codiceArn') ? undefined : json['codiceArn'],
        'codiceNazione': !runtime_1.exists(json, 'codiceNazione') ? undefined : json['codiceNazione'],
        'codiceEsercente': !runtime_1.exists(json, 'codiceEsercente') ? undefined : json['codiceEsercente'],
        'nomeEsercente': !runtime_1.exists(json, 'nomeEsercente') ? undefined : json['nomeEsercente'],
        'localitaEsercente': !runtime_1.exists(json, 'localitaEsercente') ? undefined : json['localitaEsercente'],
        'capLocalitaEsercente': !runtime_1.exists(json, 'capLocalitaEsercente') ? undefined : json['capLocalitaEsercente'],
        'codiceTipoFlusso': !runtime_1.exists(json, 'codiceTipoFlusso') ? undefined : json['codiceTipoFlusso'],
        'flagWallet': !runtime_1.exists(json, 'flagWallet') ? undefined : json['flagWallet'],
        'codiceWallet': !runtime_1.exists(json, 'codiceWallet') ? undefined : json['codiceWallet'],
    };
}
exports.MovimentoCartaDtoFromJSONTyped = MovimentoCartaDtoFromJSONTyped;
function MovimentoCartaDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'movimentoId': value.movimentoId,
        'codicePosizione': value.codicePosizione,
        'codiceAnagrafica': value.codiceAnagrafica,
        'codiceProgressivoCarta': value.codiceProgressivoCarta,
        'codiceProgressivoCircuito': value.codiceProgressivoCircuito,
        'codiceAliasCarta': value.codiceAliasCarta,
        'dataMovimento': value.dataMovimento === undefined ? undefined : (value.dataMovimento.toISOString()),
        'dataContabilizzazione': value.dataContabilizzazione === undefined ? undefined : (value.dataContabilizzazione.toISOString()),
        'sicCode': value.sicCode,
        'codiceCircuito': value.codiceCircuito,
        'codiceTipoTecnologia': value.codiceTipoTecnologia,
        'codiceTipoMovimento': value.codiceTipoMovimento,
        'codiceTipoOperazione': value.codiceTipoOperazione,
        'importo': value.importo,
        'divisa': value.divisa,
        'importoDivisaOriginaria': value.importoDivisaOriginaria,
        'codiceArn': value.codiceArn,
        'codiceNazione': value.codiceNazione,
        'codiceEsercente': value.codiceEsercente,
        'nomeEsercente': value.nomeEsercente,
        'localitaEsercente': value.localitaEsercente,
        'capLocalitaEsercente': value.capLocalitaEsercente,
        'codiceTipoFlusso': value.codiceTipoFlusso,
        'flagWallet': value.flagWallet,
        'codiceWallet': value.codiceWallet,
    };
}
exports.MovimentoCartaDtoToJSON = MovimentoCartaDtoToJSON;
