"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DettaglioAppuntamentoDtoCreatoDaEnum = exports.DettaglioAppuntamentoDtoTipoEnum = exports.DettaglioAppuntamentoDtoToJSON = exports.DettaglioAppuntamentoDtoFromJSONTyped = exports.DettaglioAppuntamentoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function DettaglioAppuntamentoDtoFromJSON(json) {
    return DettaglioAppuntamentoDtoFromJSONTyped(json, false);
}
exports.DettaglioAppuntamentoDtoFromJSON = DettaglioAppuntamentoDtoFromJSON;
function DettaglioAppuntamentoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'titolo': json['titolo'],
        'tipo': json['tipo'],
        'dettagliAppuntamentoPeriodico': _1.DettagliAppuntamentoPeriodicoDtoFromJSON(json['dettagliAppuntamentoPeriodico']),
        'operazioneAssociata': !runtime_1.exists(json, 'operazioneAssociata') ? undefined : json['operazioneAssociata'],
        'importo': !runtime_1.exists(json, 'importo') ? undefined : json['importo'],
        'note': !runtime_1.exists(json, 'note') ? undefined : json['note'],
        'modificabile': !runtime_1.exists(json, 'modificabile') ? undefined : json['modificabile'],
        'creatoDa': json['creatoDa'],
        'notifica': !runtime_1.exists(json, 'notifica') ? undefined : _1.NotificaDtoFromJSON(json['notifica']),
        'data': (new Date(json['data'])),
        'giorniFestiviPermessi': !runtime_1.exists(json, 'giorniFestiviPermessi') ? undefined : json['giorniFestiviPermessi'],
        'idOperazioneOneClick': json['idOperazioneOneClick'],
    };
}
exports.DettaglioAppuntamentoDtoFromJSONTyped = DettaglioAppuntamentoDtoFromJSONTyped;
function DettaglioAppuntamentoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'titolo': value.titolo,
        'tipo': value.tipo,
        'dettagliAppuntamentoPeriodico': _1.DettagliAppuntamentoPeriodicoDtoToJSON(value.dettagliAppuntamentoPeriodico),
        'operazioneAssociata': value.operazioneAssociata,
        'importo': value.importo,
        'note': value.note,
        'modificabile': value.modificabile,
        'creatoDa': value.creatoDa,
        'notifica': _1.NotificaDtoToJSON(value.notifica),
        'data': (value.data.toISOString()),
        'giorniFestiviPermessi': value.giorniFestiviPermessi,
        'idOperazioneOneClick': value.idOperazioneOneClick,
    };
}
exports.DettaglioAppuntamentoDtoToJSON = DettaglioAppuntamentoDtoToJSON;
/**
* @export
* @enum {string}
*/
var DettaglioAppuntamentoDtoTipoEnum;
(function (DettaglioAppuntamentoDtoTipoEnum) {
    DettaglioAppuntamentoDtoTipoEnum["U"] = "U";
    DettaglioAppuntamentoDtoTipoEnum["P"] = "P";
})(DettaglioAppuntamentoDtoTipoEnum = exports.DettaglioAppuntamentoDtoTipoEnum || (exports.DettaglioAppuntamentoDtoTipoEnum = {}));
/**
* @export
* @enum {string}
*/
var DettaglioAppuntamentoDtoCreatoDaEnum;
(function (DettaglioAppuntamentoDtoCreatoDaEnum) {
    DettaglioAppuntamentoDtoCreatoDaEnum["S"] = "S";
    DettaglioAppuntamentoDtoCreatoDaEnum["U"] = "U";
})(DettaglioAppuntamentoDtoCreatoDaEnum = exports.DettaglioAppuntamentoDtoCreatoDaEnum || (exports.DettaglioAppuntamentoDtoCreatoDaEnum = {}));
