"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormCartaRicaricabileDtoFromJSONTyped = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const helpers_1 = require("../helpers");
function FormCartaRicaricabileDtoFromJSONTyped(json) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'rapportoSelezionato': !(0, helpers_1.exists)(json, 'rapportoSelezionato') ? (0, api_rest_1.RapportoCompletoDtoFromJSON)({}) : json.rapportoSelezionato,
        'importo': !(0, helpers_1.exists)(json, 'importo') ? undefined : json.importo,
        'importoDirty': !(0, helpers_1.exists)(json, 'importoDirty') ? false : json.importoDirty,
        'importoError': !(0, helpers_1.exists)(json, 'importoError') ? 'invalid.required' : json.importoError,
        'dataEsecuzione': !(0, helpers_1.exists)(json, 'dataEsecuzione') ? new Date() : json.dataEsecuzione,
        'dataEsecuzioneDirty': !(0, helpers_1.exists)(json, 'dataEsecuzioneDirty') ? false : json.dataEsecuzioneDirty,
        'dataEsecuzioneError': !(0, helpers_1.exists)(json, 'dataEsecuzioneError') ? '' : json.dataEsecuzioneError,
        'salva1Click': !(0, helpers_1.exists)(json, 'salva1Click') ? undefined : json.salva1Click,
        'alias1Click': !(0, helpers_1.exists)(json, 'alias1Click') ? undefined : json.alias1Click,
        'aliasDirty': !(0, helpers_1.exists)(json, 'aliasDirty') ? false : json.aliasDirty,
        'aliasError': !(0, helpers_1.exists)(json, 'aliasError') ? '' : json.aliasError,
        'ripeti': !(0, helpers_1.exists)(json, 'ripeti') ? false : json.ripeti,
        'idOneClickCollegata': !(0, helpers_1.exists)(json, 'idOneClickCollegata') ? undefined : json.idOneClickCollegata,
        'formSubmitted': !(0, helpers_1.exists)(json, 'formSubmitted') ? false : json.formSubmitted,
        'bozzaSalvata': !(0, helpers_1.exists)(json, 'bozzaSalvata') ? false : json.bozzaSalvata,
        'step': !(0, helpers_1.exists)(json, 'step') ? undefined : json.step,
        'pan': !(0, helpers_1.exists)(json, 'pan') ? undefined : json.pan,
        'panDirty': !(0, helpers_1.exists)(json, 'panDirty') ? false : json.panDirty,
        'panError': !(0, helpers_1.exists)(json, 'panError') ? 'invalid.required' : json.panError,
        'tipoCarta': !(0, helpers_1.exists)(json, 'tipoCarta') ? undefined : json.tipoCarta,
    };
}
exports.FormCartaRicaricabileDtoFromJSONTyped = FormCartaRicaricabileDtoFromJSONTyped;
