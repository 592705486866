"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reset1Click = exports.resetRiepilogo1Click = exports.setModifica1Click = exports.setCrea1Click = exports.setStepSCA1Click = exports.setCausale1Click = exports.setImporto1Click = exports.setIban1Click = exports.setBeneficiario1Click = exports.setRapportoSelezionato1Click = exports.resetFiltro = exports.resetForm1Click = exports.setStep1Click = exports.setFormSubmitted1Click = exports.setAlias1Click = exports.setTipoOperazione1Click = exports.autorizzaModifica1Click = exports.getInfoAutenticazioneModifica1Click = exports.controlloModifica1Click = exports.autorizza1Click = exports.controllo1Click = exports.getInfoAutenticazione1Click = exports.getParametriOneClick = exports.resetOneclickOperazioneSelezionata = exports.setOneclickSetOperazione = exports.setOneClickSetContenuto = exports.setOneClickSetTipoOperazioneRicerca = exports.setOneClickSetTipoOperazione = exports.setOneclickAccordionFiltriOpened = exports.eliminaOneClick = exports.salvaOneClick = exports.getOperazioniOneClick = exports.getOperazioniOneclickHomepage = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const altoAdigePass_1 = require("../altoAdigePass");
const bollettinoPostale_1 = require("../bollettinoPostale");
const bonifico_selectors_1 = require("../bonificoSepa/bonifico.selectors");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
const ricaricaCellulare_1 = require("../ricaricaCellulare");
const oneclick_selectors_1 = require("./oneclick.selectors");
function getOperazioniOneclickHomepageStart() {
    return {
        type: __1.EReduxActionTypes.OPERAZIONI_GET_OPERAZIONI_ONECLICK_HOMEPAGE_START
    };
}
function getOperazioniOneclickHomepageSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.OPERAZIONI_GET_OPERAZIONI_ONECLICK_HOMEPAGE_SUCCESS,
        payload
    };
}
const getOperazioniOneclickHomepage = () => async (dispatch, _, extraArguments) => {
    dispatch(getOperazioniOneclickHomepageStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/oneclick.json')).then(parametri => {
            dispatch(getOperazioniOneclickHomepageSuccess((0, api_rest_1.OperazioniOneClickDtoFromJSON)(parametri)));
        });
    }
    else {
        const getOperazioniOneclickHomepageRequest = {
            operazioniOneClickInputDto: {}
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.OperazioniOneClickControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).operazioniOneClick(getOperazioniOneclickHomepageRequest).then((response) => {
                dispatch(getOperazioniOneclickHomepageSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            });
        });
    }
};
exports.getOperazioniOneclickHomepage = getOperazioniOneclickHomepage;
/* get oneclick - START */
function getOperazioniOneClickStart() {
    return {
        type: __1.EReduxActionTypes.GET_OPERAZIONI_ONECLICK_START
    };
}
function getOperazioniOneClickSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.GET_OPERAZIONI_ONECLICK_SUCCESS,
        payload
    };
}
function generalError(value, showGenericModalError) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value, showGenericModalError));
    };
}
const getOperazioniOneClick = (operazioniInputRequest) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getOperazioniOneClickStart());
    const oneClick = getState().oneclick;
    const filtro = (0, oneclick_selectors_1.buildCerca)(oneClick);
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/operazionidefault.json')).then(parametri => {
            dispatch(getOperazioniOneClickSuccess((0, api_rest_1.OperazioniOneClickDtoFromJSON)(parametri)));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            const request = {
                operazioniOneClickInputDto: operazioniInputRequest || filtro
            };
            new api_rest_1.OperazioniOneClickControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).operazioniOneClick(request).then((response) => {
                dispatch(getOperazioniOneClickSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getOperazioniOneClick = getOperazioniOneClick;
/* get oneclick - FINE */
/* salva oneclick - START */
function salvaOneClickStart() {
    return {
        type: __1.EReduxActionTypes.SALVA_ONECLICK_START
    };
}
function salvaOneClickSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.SALVA_ONECLICK_SUCCESS,
        payload
    };
}
const salvaOneClick = (op) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(salvaOneClickStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/operazionidefault.json')).then(parametri => {
            dispatch(salvaOneClickSuccess((0, api_rest_1.OperazioneOneClickDtoFromJSON)(parametri)));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            const request = {
                operazioneOneClickDto: op
            };
            new api_rest_1.OperazioniOneClickControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).salvaOneClick(request).then((response) => {
                dispatch(salvaOneClickSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.salvaOneClick = salvaOneClick;
/* salva oneclick - FINE */
/* elimina oneclick - START */
function eliminaOneClickStart() {
    return {
        type: __1.EReduxActionTypes.ELIMINA_ONECLICK_START
    };
}
function eliminaOneClickSuccess(id) {
    return {
        type: __1.EReduxActionTypes.ELIMINA_ONECLICK_SUCCESS,
        payload: id
    };
}
const eliminaOneClick = (id) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(eliminaOneClickStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/operazionidefault.json')).then(parametri => {
            dispatch(eliminaOneClickSuccess(id));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            const request = {
                id
            };
            new api_rest_1.OperazioniOneClickControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).eliminaOneClick(request).then(() => {
                dispatch(eliminaOneClickSuccess(id));
                dispatch((0, general_actions_1.addSnackbar)('oneClick.operazioneEliminataConSuccesso'));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.eliminaOneClick = eliminaOneClick;
/* elimina oneclick - FINE */
function setOneclickAccordionFiltriOpened(b) {
    return {
        type: __1.EReduxActionTypes.ONECLICK_SET_ACCORDION_FILTRI_OPENED,
        payload: b
    };
}
exports.setOneclickAccordionFiltriOpened = setOneclickAccordionFiltriOpened;
function setOneClickSetTipoOperazione(txt) {
    return {
        type: __1.EReduxActionTypes.ONECLICK_TIPO_OPERAZIONE,
        payload: txt
    };
}
exports.setOneClickSetTipoOperazione = setOneClickSetTipoOperazione;
function setOneClickSetTipoOperazioneRicerca(txt) {
    return {
        type: __1.EReduxActionTypes.ONECLICK_TIPO_OPERAZIONE_RICERCA,
        payload: txt
    };
}
exports.setOneClickSetTipoOperazioneRicerca = setOneClickSetTipoOperazioneRicerca;
function setOneClickSetContenuto(txt) {
    return {
        type: __1.EReduxActionTypes.ONECLICK_CONTENUTO,
        payload: txt
    };
}
exports.setOneClickSetContenuto = setOneClickSetContenuto;
function setOneclickSetOperazione(operazione) {
    return {
        type: __1.EReduxActionTypes.ONECLICK_OPERAZIONE_SELEZIONATA,
        payload: operazione
    };
}
exports.setOneclickSetOperazione = setOneclickSetOperazione;
function resetOneclickOperazioneSelezionata() {
    return {
        type: __1.EReduxActionTypes.ONECLICK_RESET_OPERAZIONE_SELEZIONATA
    };
}
exports.resetOneclickOperazioneSelezionata = resetOneclickOperazioneSelezionata;
/* get parametri One Click - START */
function getParametriOneClickStart() {
    return {
        type: __1.EReduxActionTypes.ONECLICK_GET_PARAMETRI_START
    };
}
function getParametriOneClickSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.ONECLICK_GET_PARAMETRI_SUCCESS,
        payload
    };
}
const getParametriOneClick = () => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getParametriOneClickStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/parametri_movimenti.json')).then(parametri => {
            dispatch(getParametriOneClickSuccess((0, api_rest_1.ParametriOneClickDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.OperazioniOneClickControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametriOneClick().then((response) => {
                dispatch(getParametriOneClickSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getParametriOneClick = getParametriOneClick;
/* get parametri OneClick - FINE */
/* get info autenticazione 1Click - START */
//
function getInfoAutenticazione1ClickStart() {
    return {
        type: __1.EReduxActionTypes.ONECLICK_GET_INFO_AUTENTICAZIONE_START
    };
}
//
function getInfoAutenticazione1ClickSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.ONECLICK_GET_INFO_AUTENTICAZIONE_SUCCESS,
        payload
    };
}
const getInfoAutenticazione1Click = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getInfoAutenticazione1ClickStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/autenticazione-bonifico.json')).then(parametri => {
            dispatch(getInfoAutenticazione1ClickSuccess((0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const requestParameters = {
            idDispositiva,
            chiamataUscente,
            eseguiInit,
            offline: !!offline
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.OperazioniOneClickControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).informazioniAutenticazioneOneClick(requestParameters).then((response) => {
                dispatch(getInfoAutenticazione1ClickSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getInfoAutenticazione1Click = getInfoAutenticazione1Click;
/* get info autenticazione 1Click - FINE */
/* controllo 1Click - START */
function controllo1ClickStart() {
    return {
        type: __1.EReduxActionTypes.ONECLICK_CONTROLLO_START
    };
}
function controllo1ClickSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.ONECLICK_CONTROLLO_SUCCESS,
        payload
    };
}
const controllo1Click = (opr1Click) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(controllo1ClickStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/controllo_mav.json')).then(parametri => {
            dispatch(controllo1ClickSuccess((0, api_rest_1.OperazioneOneClickDtoFromJSON)(parametri)));
        });
    }
    else {
        let disposizione = {};
        switch (opr1Click.codiceFunzione) {
            case 'BONIFICOSEPA':
                disposizione = (0, bonifico_selectors_1.buildBonificoSepa)(getState().bonificoSepa);
                break;
            case 'CARTACONTODISPOCATEGORIE':
                disposizione = (0, bonifico_selectors_1.buildSmartCard)(getState().bonificoSepa);
                break;
            case 'BOLLETTINOPOSTALE':
                disposizione = (0, bollettinoPostale_1.buildBollettinoPostaleBianco)(getState().bollettinoPostale);
                break;
            case 'RICARICACELLULARE':
                disposizione = (0, ricaricaCellulare_1.buildRicaricaCellulare)(getState().ricaricaCellulare);
                break;
            case 'SUDTIROLPASS':
                disposizione = (0, altoAdigePass_1.buildRicaricaAltoAdigePass)(getState().altoAdigePass);
                break;
        }
        const controllo1ClickRequest = {
            operazioneOneClickDto: {
                ...opr1Click,
                payload: {
                    ...opr1Click.payload,
                    disposizione: disposizione
                }
            }
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.OperazioniOneClickControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).salvaOneClick(controllo1ClickRequest).then((response) => {
                dispatch(controllo1ClickSuccess(response));
                dispatch((0, general_actions_1.setLoader)(false));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.controllo1Click = controllo1Click;
/* controllo 1Click - FINE */
/* autorizza 1Click - START */
function autorizza1ClickStart() {
    return {
        type: __1.EReduxActionTypes.ONECLICK_AUTORIZZA_START
    };
}
function autorizza1ClickSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.ONECLICK_AUTORIZZA_SUCCESS,
        payload
    };
}
const autorizza1Click = (idDispositiva, credenziale) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(autorizza1ClickStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/autorizza_mav.json')).then(parametri => {
            dispatch(autorizza1ClickSuccess((0, api_rest_1.EsitoAutorizzazioneDisposizioneOneClickDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const getInfoDispositiva1ClickRequest = {
            idDispositiva,
            credenzialeDispositivaDto: credenziale
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.OperazioniOneClickControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).autorizzaOneClick(getInfoDispositiva1ClickRequest).then((response) => {
                dispatch(autorizza1ClickSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error), false));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.autorizza1Click = autorizza1Click;
/* autorizza 1Click - FINE */
/* controllo modifica 1Click - START */
function controlloModifica1ClickStart() {
    return {
        type: __1.EReduxActionTypes.ONECLICK_CONTROLLO_MODIFICA_START
    };
}
function controlloModifica1ClickSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.ONECLICK_CONTROLLO_MODIFICA_SUCCESS,
        payload
    };
}
const controlloModifica1Click = (opr1Click) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(controlloModifica1ClickStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/controllo_mav.json')).then(parametri => {
            dispatch(controlloModifica1ClickSuccess((0, api_rest_1.OperazioneOneClickDtoFromJSON)(parametri)));
        });
    }
    else {
        let disposizione = {};
        switch (opr1Click.codiceFunzione) {
            case 'BONIFICOSEPA':
                disposizione = (0, bonifico_selectors_1.buildBonificoSepa)(getState().bonificoSepa);
                break;
            case 'CARTACONTODISPOCATEGORIE':
                disposizione = (0, bonifico_selectors_1.buildSmartCard)(getState().bonificoSepa);
                break;
            case 'BOLLETTINOPOSTALE':
                disposizione = (0, bollettinoPostale_1.buildBollettinoPostaleBianco)(getState().bollettinoPostale);
                break;
            case 'RICARICACELLULARE':
                disposizione = (0, ricaricaCellulare_1.buildRicaricaCellulare)(getState().ricaricaCellulare);
                break;
            case 'SUDTIROLPASS':
                disposizione = (0, altoAdigePass_1.buildRicaricaAltoAdigePass)(getState().altoAdigePass);
                break;
        }
        const controllo1ClickRequest = {
            id: opr1Click.id || 0,
            operazioneOneClickDto: {
                ...opr1Click,
                payload: {
                    ...opr1Click.payload,
                    disposizione: disposizione
                }
            }
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.OperazioniOneClickControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).modificaOperazioneOneClick(controllo1ClickRequest).then((response) => {
                dispatch(controlloModifica1ClickSuccess(response));
                dispatch((0, general_actions_1.setLoader)(false));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.controlloModifica1Click = controlloModifica1Click;
/* controllo modifica 1Click - FINE */
/* get info autenticazione modifica 1Click - START */
//
function getInfoAutenticazioneModifica1ClickStart() {
    return {
        type: __1.EReduxActionTypes.ONECLICK_GET_INFO_AUTENTICAZIONE_MODIFICA_START
    };
}
//
function getInfoAutenticazioneModifica1ClickSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.ONECLICK_GET_INFO_AUTENTICAZIONE_MODIFICA_SUCCESS,
        payload
    };
}
const getInfoAutenticazioneModifica1Click = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getInfoAutenticazioneModifica1ClickStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/autenticazione-bonifico.json')).then(parametri => {
            dispatch(getInfoAutenticazioneModifica1ClickSuccess((0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const requestParameters = {
            idDispositiva,
            chiamataUscente,
            eseguiInit,
            offline: !!offline
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.OperazioniOneClickControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).informazioniAutenticazioneModificaOneClick(requestParameters).then((response) => {
                dispatch(getInfoAutenticazioneModifica1ClickSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getInfoAutenticazioneModifica1Click = getInfoAutenticazioneModifica1Click;
/* get info autenticazione modifica 1Click - FINE */
/* autorizza modifica 1Click - START */
function autorizzaModifica1ClickStart() {
    return {
        type: __1.EReduxActionTypes.ONECLICK_AUTORIZZA_MODIFICA_START
    };
}
function autorizzaModifica1ClickSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.ONECLICK_AUTORIZZA_MODIFICA_SUCCESS,
        payload
    };
}
const autorizzaModifica1Click = (idDispositiva, credenziale) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(autorizzaModifica1ClickStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/autorizza_mav.json')).then(parametri => {
            dispatch(autorizzaModifica1ClickSuccess((0, api_rest_1.EsitoAutorizzazioneDisposizioneOneClickDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const getInfoDispositiva1ClickRequest = {
            idDispositiva,
            credenzialeDispositivaDto: credenziale
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.OperazioniOneClickControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).autorizzaModificaOneClick(getInfoDispositiva1ClickRequest).then((response) => {
                dispatch(autorizzaModifica1ClickSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error), false));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.autorizzaModifica1Click = autorizzaModifica1Click;
/* autorizza 1Click - FINE */
function setTipoOperazione1Click(value) {
    return {
        type: __1.EReduxActionTypes.ONECLICK_SET_TIPO_OPERAZIONE,
        payload: value
    };
}
exports.setTipoOperazione1Click = setTipoOperazione1Click;
function setAlias1Click(value) {
    return {
        type: __1.EReduxActionTypes.ONECLICK_SET_ALIAS,
        payload: value
    };
}
exports.setAlias1Click = setAlias1Click;
function setFormSubmitted1Click(bSelect) {
    return {
        type: __1.EReduxActionTypes.ONECLICK_FORM_SUBMITTED,
        payload: bSelect
    };
}
exports.setFormSubmitted1Click = setFormSubmitted1Click;
function setStep1Click(step) {
    return {
        type: __1.EReduxActionTypes.ONECLICK_SET_STEP,
        payload: step
    };
}
exports.setStep1Click = setStep1Click;
function resetForm1Click() {
    return {
        type: __1.EReduxActionTypes.ONECLICK_RESET_FORM
    };
}
exports.resetForm1Click = resetForm1Click;
function resetFiltro() {
    return {
        type: __1.EReduxActionTypes.ONECLICK_RESET_FILTRO
    };
}
exports.resetFiltro = resetFiltro;
function setRapportoSelezionato1Click(rapporto) {
    return {
        type: __1.EReduxActionTypes.ONECLICK_SET_RAPPORTO_SELEZIONATO,
        payload: rapporto
    };
}
exports.setRapportoSelezionato1Click = setRapportoSelezionato1Click;
function setBeneficiario1Click(value) {
    return {
        type: __1.EReduxActionTypes.ONECLICK_SET_BENEFICIARIO,
        payload: value
    };
}
exports.setBeneficiario1Click = setBeneficiario1Click;
function setIban1Click(value) {
    return {
        type: __1.EReduxActionTypes.ONECLICK_SET_IBAN,
        payload: value
    };
}
exports.setIban1Click = setIban1Click;
function setImporto1Click(value) {
    return {
        type: __1.EReduxActionTypes.ONECLICK_SET_IMPORTO,
        payload: value
    };
}
exports.setImporto1Click = setImporto1Click;
function setCausale1Click(value) {
    return {
        type: __1.EReduxActionTypes.ONECLICK_SET_CAUSALE,
        payload: value
    };
}
exports.setCausale1Click = setCausale1Click;
function setStepSCA1Click(value) {
    return {
        type: __1.EReduxActionTypes.ONECLICK_SET_STEPSCA,
        payload: value
    };
}
exports.setStepSCA1Click = setStepSCA1Click;
function setCrea1Click(value) {
    return {
        type: __1.EReduxActionTypes.ONECLICK_SET_CREA,
        payload: value
    };
}
exports.setCrea1Click = setCrea1Click;
function setModifica1Click(value) {
    return {
        type: __1.EReduxActionTypes.ONECLICK_SET_MODIFICA,
        payload: value
    };
}
exports.setModifica1Click = setModifica1Click;
function resetRiepilogo1Click() {
    return {
        type: __1.EReduxActionTypes.ONECLICK_RESET_RIEPILOGO
    };
}
exports.resetRiepilogo1Click = resetRiepilogo1Click;
function reset1Click() {
    return {
        type: __1.EReduxActionTypes.ONECLICK_RESET
    };
}
exports.reset1Click = reset1Click;
