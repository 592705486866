"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bollettinoPostaleReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const moment = require("moment");
const __1 = require("..");
const helpers_1 = require("../helpers");
const bollettinoPostale_types_1 = require("./bollettinoPostale.types");
const initialState = {
    parametri: (0, api_rest_1.ParametriBollettiniPostaliDtoFromJSON)({}),
    form: (0, bollettinoPostale_types_1.FormBollettinoPostaleDtoFromJSONTyped)({}),
    riepilogo: (0, api_rest_1.BollettinoPostaleDtoFromJSON)({}),
    esito: (0, api_rest_1.EsitoAutorizzazioneDisposizioneBollettinoPostaleDtoFromJSON)({}),
    infoAutenticazioneBollettinoPostale: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
    currentBozza: (0, api_rest_1.BollettinoPostaleDtoFromJSON)({}),
    oneclick: (0, api_rest_1.OperazioniOneClickDtoFromJSON)({}),
    formRevoca: (0, bollettinoPostale_types_1.FormRevocaBollettinoDtoFromJSON)({}),
    infoRevoca: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
    esitoRevoca: (0, api_rest_1.EsitoAutorizzazioneDisposizioneBollettinoPostaleDtoFromJSON)({}),
    bozzeBollettinoPostale: []
};
let rapportoSelezionato;
let newBozze;
let dataPag;
let dataNow;
const bollettinoPostaleReducer = (state = initialState, action) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1;
    switch (action.type) {
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_GET_PARAMETRI_SUCCESS:
            return {
                ...state,
                parametri: action.payload
            };
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_CONTROLLO_SUCCESS:
            return {
                ...state,
                riepilogo: action.payload
            };
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_AUTORIZZA_SUCCESS:
            if (action.payload.stato === 'OK') {
                return {
                    ...state,
                    //riepilogo: initialState.riepilogo,
                    esito: action.payload
                };
            }
            else {
                return {
                    ...state,
                    infoAutenticazioneBollettinoPostale: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoAutenticazioneBollettinoPostale, action.payload.informazioniAutenticazioneDispositiva),
                    esito: action.payload
                };
            }
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_GET_INFO_AUTENTICAZIONE_SUCCESS:
            return {
                ...state,
                infoAutenticazioneBollettinoPostale: action.payload,
                esito: initialState.esito
            };
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_GET_BOZZE_SUCCESS:
            return {
                ...state,
                bozzeBollettinoPostale: action.payload
            };
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_ADD_BOZZA_SUCCESS:
            return {
                ...state,
                bozzeBollettinoPostale: state.bozzeBollettinoPostale ? [...state.bozzeBollettinoPostale, action.payload] : [],
                form: {
                    ...state.form,
                    bozzaSalvata: true
                },
                currentBozza: action.payload
            };
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_DELETE_BOZZA_SUCCESS:
            return {
                ...state,
                bozzeBollettinoPostale: state.bozzeBollettinoPostale
                    ? state.bozzeBollettinoPostale.filter((bollettinoPostale) => bollettinoPostale.id !== action.payload)
                    : [],
                form: {
                    ...state.form,
                    bozzaSalvata: state.currentBozza.id === action.payload ? false : state.form.bozzaSalvata
                },
                currentBozza: state.currentBozza.id === action.payload ? initialState.currentBozza : state.currentBozza
            };
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_UPDATE_BOZZA_SUCCESS:
            return {
                ...state,
                bozzeBollettinoPostale: state.bozzeBollettinoPostale
                    ? state.bozzeBollettinoPostale.map(bollettinoPostale => bollettinoPostale.id === state.currentBozza.id ? action.payload : bollettinoPostale)
                    : [],
                form: {
                    ...state.form,
                    bozzaSalvata: true
                }
            };
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_GET_BOZZA_SUCCESS:
            rapportoSelezionato = (0, helpers_1.findInArrayRapporti)(state.parametri && state.parametri.rapporti, action.payload.contoAddebito);
            dataPag = new Date(((_a = action.payload) === null || _a === void 0 ? void 0 : _a.dataPagamento) || '') || new Date();
            dataNow = new Date();
            return {
                ...state,
                form: {
                    ...state.form,
                    idUtenza: action.payload.idUtenza,
                    idUtenzaDirty: false,
                    idUtenzaError: (0, helpers_1.validationAsNumber)(action.payload.idUtenza, true),
                    importo: ((_b = action === null || action === void 0 ? void 0 : action.payload) === null || _b === void 0 ? void 0 : _b.importo) || 0,
                    importoDirty: false,
                    importoError: (0, helpers_1.validationField)(action.payload.importo, 0),
                    esecutore: ((_c = action === null || action === void 0 ? void 0 : action.payload) === null || _c === void 0 ? void 0 : _c.esecutore) || '',
                    esecutoreDirty: false,
                    esecutoreError: (0, helpers_1.validationCompleteCharSet)(((_d = action.payload) === null || _d === void 0 ? void 0 : _d.esecutore) || '', true),
                    indirizzo: ((_e = action === null || action === void 0 ? void 0 : action.payload) === null || _e === void 0 ? void 0 : _e.indirizzo) || '',
                    indirizzoDirty: false,
                    indirizzoError: (0, helpers_1.validationIndirizzo)(action.payload.indirizzo || '', true),
                    provincia: ((_f = action === null || action === void 0 ? void 0 : action.payload) === null || _f === void 0 ? void 0 : _f.provincia) || '',
                    provinciaDirty: false,
                    provinciaError: (0, helpers_1.validationField)(action.payload.provincia, ''),
                    citta: ((_g = action === null || action === void 0 ? void 0 : action.payload) === null || _g === void 0 ? void 0 : _g.citta) || '',
                    cittaDirty: false,
                    cittaError: (0, helpers_1.validationCitta)(action.payload.citta || '', true),
                    cap: ((_h = action === null || action === void 0 ? void 0 : action.payload) === null || _h === void 0 ? void 0 : _h.cap) || '',
                    capDirty: false,
                    capError: (0, helpers_1.validationAsNumber)(action.payload.cap || '', true, 5),
                    numeroBollettino: ((_j = action === null || action === void 0 ? void 0 : action.payload) === null || _j === void 0 ? void 0 : _j.numeroBollettino) || '',
                    numeroBollettinoDirty: false,
                    numeroBollettinoError: (0, helpers_1.validationAsNumber)(action.payload.numeroBollettino || '', true, 18),
                    tipoDocumento: ((_k = action === null || action === void 0 ? void 0 : action.payload) === null || _k === void 0 ? void 0 : _k.tipoDocumento) || 0,
                    tipoDocumentoDirty: false,
                    tipoDocumentoError: (0, helpers_1.validationAsNumber)(((_l = action.payload.tipoDocumento) === null || _l === void 0 ? void 0 : _l.toString()) || '', true),
                    rapportoSelezionato: rapportoSelezionato,
                    causale: ((_m = action === null || action === void 0 ? void 0 : action.payload) === null || _m === void 0 ? void 0 : _m.causale) || '',
                    causaleDirty: false,
                    causaleError: (0, helpers_1.validationCompleteCharSet)(action.payload.causale || '', true),
                    intestatario: ((_o = action === null || action === void 0 ? void 0 : action.payload) === null || _o === void 0 ? void 0 : _o.intestatario) || '',
                    intestatarioDirty: false,
                    intestatarioError: (0, helpers_1.validationCompleteCharSet)(action.payload.intestatario || '', true),
                    dataPagamento: moment(dataPag > dataNow ? dataPag : dataNow).format('YYYY-MM-DD'),
                    dataPagamentoDirty: false,
                    dataPagamentoError: ''
                },
                currentBozza: action.payload
            };
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_GET_BOLLETTINO_FROM_BOZZA:
            rapportoSelezionato = (0, helpers_1.findInArrayRapporti)(state.parametri && state.parametri.rapporti, action.payload.contoAddebito);
            dataPag = new Date(((_p = action.payload) === null || _p === void 0 ? void 0 : _p.dataPagamento) || '') || new Date();
            dataNow = new Date();
            return {
                ...state,
                form: {
                    ...state.form,
                    idUtenza: action.payload.idUtenza,
                    idUtenzaDirty: false,
                    idUtenzaError: (0, helpers_1.validationAsNumber)(action.payload.idUtenza, true),
                    importo: ((_q = action === null || action === void 0 ? void 0 : action.payload) === null || _q === void 0 ? void 0 : _q.importo) || 0,
                    importoDirty: false,
                    importoError: (0, helpers_1.validationField)(action.payload.importo, 0),
                    esecutore: ((_r = action === null || action === void 0 ? void 0 : action.payload) === null || _r === void 0 ? void 0 : _r.esecutore) || '',
                    esecutoreDirty: false,
                    esecutoreError: (0, helpers_1.validationCompleteCharSet)(((_s = action.payload) === null || _s === void 0 ? void 0 : _s.esecutore) || '', true),
                    indirizzo: ((_t = action === null || action === void 0 ? void 0 : action.payload) === null || _t === void 0 ? void 0 : _t.indirizzo) || '',
                    indirizzoDirty: false,
                    indirizzoError: (0, helpers_1.validationIndirizzo)(action.payload.indirizzo || '', true),
                    provincia: ((_u = action === null || action === void 0 ? void 0 : action.payload) === null || _u === void 0 ? void 0 : _u.provincia) || '',
                    provinciaDirty: false,
                    provinciaError: (0, helpers_1.validationField)(action.payload.provincia, ''),
                    citta: ((_v = action === null || action === void 0 ? void 0 : action.payload) === null || _v === void 0 ? void 0 : _v.citta) || '',
                    cittaDirty: false,
                    cittaError: (0, helpers_1.validationCitta)(action.payload.citta || '', true),
                    cap: ((_w = action === null || action === void 0 ? void 0 : action.payload) === null || _w === void 0 ? void 0 : _w.cap) || '',
                    capDirty: false,
                    capError: (0, helpers_1.validationAsNumber)(action.payload.cap || '', true, 5),
                    numeroBollettino: ((_x = action === null || action === void 0 ? void 0 : action.payload) === null || _x === void 0 ? void 0 : _x.numeroBollettino) || '',
                    numeroBollettinoDirty: false,
                    numeroBollettinoError: (0, helpers_1.validationAsNumber)(action.payload.numeroBollettino || '', true, 18),
                    tipoDocumento: ((_y = action === null || action === void 0 ? void 0 : action.payload) === null || _y === void 0 ? void 0 : _y.tipoDocumento) || 0,
                    tipoDocumentoDirty: false,
                    tipoDocumentoError: (0, helpers_1.validationAsNumber)(((_z = action.payload.tipoDocumento) === null || _z === void 0 ? void 0 : _z.toString()) || '', true),
                    rapportoSelezionato: rapportoSelezionato,
                    causale: ((_0 = action === null || action === void 0 ? void 0 : action.payload) === null || _0 === void 0 ? void 0 : _0.causale) || '',
                    causaleDirty: false,
                    causaleError: (0, helpers_1.validationCompleteCharSet)(action.payload.causale || '', true),
                    intestatario: ((_1 = action === null || action === void 0 ? void 0 : action.payload) === null || _1 === void 0 ? void 0 : _1.intestatario) || '',
                    intestatarioDirty: false,
                    intestatarioError: (0, helpers_1.validationCompleteCharSet)(action.payload.intestatario || '', true),
                    idOneClickCollegata: action.payload.idOneClickCollegata,
                    dataPagamento: moment(dataPag > dataNow ? dataPag : dataNow).format('YYYY-MM-DD'),
                    dataPagamentoDirty: false,
                    dataPagamentoError: ''
                },
                currentBozza: action.noBozza ? initialState.currentBozza : action.payload
            };
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_SET_RAPPORTO_SELEZIONATO:
            return {
                ...state,
                form: {
                    ...state.form,
                    rapportoSelezionato: action.payload,
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_SET_ID_UTENZA:
            return {
                ...state,
                form: {
                    ...state.form,
                    idUtenza: action.payload,
                    idUtenzaDirty: true,
                    idUtenzaError: (0, helpers_1.validationAsNumber)(action.payload, true),
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_SET_IMPORTO:
            return {
                ...state,
                form: {
                    ...state.form,
                    importo: action.payload,
                    importoDirty: true,
                    importoError: (0, helpers_1.validationField)(action.payload, 0),
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_SET_ESECUTORE:
            return {
                ...state,
                form: {
                    ...state.form,
                    esecutore: action.payload,
                    esecutoreDirty: true,
                    esecutoreError: (0, helpers_1.validationCompleteCharSet)(action.payload, true),
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_SET_INDIRIZZO:
            return {
                ...state,
                form: {
                    ...state.form,
                    indirizzo: action.payload,
                    indirizzoDirty: true,
                    indirizzoError: (0, helpers_1.validationIndirizzo)(action.payload, true),
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_SET_PROVINCIA:
            return {
                ...state,
                form: {
                    ...state.form,
                    provincia: action.payload,
                    provinciaDirty: true,
                    provinciaError: (0, helpers_1.validationField)(action.payload, ''),
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_SET_CITTA:
            return {
                ...state,
                form: {
                    ...state.form,
                    citta: action.payload,
                    cittaDirty: true,
                    cittaError: (0, helpers_1.validationCitta)(action.payload, true),
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_SET_CAP:
            return {
                ...state,
                form: {
                    ...state.form,
                    cap: action.payload,
                    capDirty: true,
                    capError: (0, helpers_1.validationAsNumber)(action.payload, true, 5),
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_SET_NUMERO_BOLLETTINO:
            return {
                ...state,
                form: {
                    ...state.form,
                    numeroBollettino: action.payload,
                    numeroBollettinoDirty: true,
                    numeroBollettinoError: (0, helpers_1.validationAsNumber)(action.payload, true, 18),
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_SET_TIPO_DOCUMENTO:
            return {
                ...state,
                form: {
                    ...state.form,
                    tipoDocumento: action.payload,
                    tipoDocumentoDirty: true,
                    tipoDocumentoError: (0, helpers_1.validationAsNumber)(action.payload.toString(), true),
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_SET_DATA_PAGAMENTO:
            return {
                ...state,
                form: {
                    ...state.form,
                    dataPagamento: action.payload,
                    dataPagamentoDirty: true,
                    dataPagamentoError: (0, helpers_1.validationFieldDate)(action.payload, [], true, ''),
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_SET_STEP:
            return {
                ...state,
                form: {
                    ...state.form,
                    step: action.payload
                }
            };
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_SET_BOZZA_SALVATA:
            return {
                ...state,
                form: {
                    ...state.form,
                    bozzaSalvata: action.payload
                }
            };
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_SET_FORM_SUBMITTED:
            return {
                ...state,
                form: {
                    ...state.form,
                    formSubmitted: action.payload
                }
            };
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_SET_RIEPILOGO_REVOCA:
            return {
                ...state,
                formRevoca: initialState.formRevoca,
                riepilogoRevoca: action.payload
            };
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_SET_PROVENIENZA:
            return {
                ...state,
                formRevoca: {
                    ...state.formRevoca,
                    provenienza: action.payload
                }
            };
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_STEP_REVOCA:
            return {
                ...state,
                formRevoca: {
                    ...state.formRevoca,
                    step: action.payload
                }
            };
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_GET_INFO_REVOCA_SUCCESS:
            return {
                ...state,
                infoRevoca: action.payload,
                esitoRevoca: initialState.esitoRevoca
            };
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_AUTORIZZA_REVOCA_SUCCESS:
            if (action.payload.stato === 'OK') {
                return {
                    ...state,
                    esitoRevoca: action.payload
                };
            }
            else {
                return {
                    ...state,
                    parametri: initialState.parametri,
                    riepilogo: initialState.riepilogo,
                    infoAutenticazioneBollettinoPostale: initialState.infoAutenticazioneBollettinoPostale,
                    esito: initialState.esito,
                    form: initialState.form,
                    riepilogoRevoca: initialState.riepilogoRevoca,
                    infoRevoca: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoRevoca, action.payload.informazioniAutenticazioneDispositiva),
                    esitoRevoca: action.payload
                };
            }
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_SET_CAUSALE:
            return {
                ...state,
                form: {
                    ...state.form,
                    causale: action.payload,
                    causaleDirty: true,
                    causaleError: (0, helpers_1.validationCompleteCharSet)(action.payload, true),
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_SET_INTESTATARIO:
            return {
                ...state,
                form: {
                    ...state.form,
                    intestatario: action.payload,
                    intestatarioDirty: true,
                    intestatarioError: (0, helpers_1.validationCompleteCharSet)(action.payload, true),
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_SET_SALVA_BOZZA_VALIDATION:
            return {
                ...state,
                form: {
                    ...state.form,
                    indirizzoError: '',
                    provinciaError: '',
                    cittaError: '',
                    capError: '',
                    numeroBollettinoError: '',
                    tipoDocumentoError: '',
                    dataPagamentoError: '',
                    causaleError: '',
                    intestatarioError: '',
                    esecutoreError: '',
                    importoError: ''
                }
            };
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_SET_TIPO_BOLLETTINO_SELECTED:
            return {
                ...state,
                tipoBollettinoPostaleSelected: action.payload
            };
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_RESET_RIEPILOGO:
            return {
                ...state,
                riepilogo: initialState.riepilogo
            };
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_GET_PARAMETRI_PER_RIPETI_SUCCESS:
            return {
                ...initialState,
                parametri: action.payload,
                form: {
                    ...state.form,
                    ripeti: true
                }
            };
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_SET_SALVA_1CLICK:
            return {
                ...state,
                form: {
                    ...state.form,
                    salva1Click: action.payload,
                    alias1Click: action.payload ? state.form.causale : '',
                    aliasDirty: false,
                    aliasError: action.payload
                        ? (0, helpers_1.validationCompleteCharSet)(state.form.causale, true)
                        : ''
                }
            };
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_SET_ALIAS_1CLICK:
            return {
                ...state,
                form: {
                    ...state.form,
                    alias1Click: action.payload,
                    aliasDirty: true,
                    aliasError: (0, helpers_1.validationCompleteCharSet)(action.payload, true),
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_GET_ONECLICK_SUCCESS:
            return {
                ...state,
                oneclick: action.payload
            };
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_RESET_ESITO:
            return {
                ...state,
                esito: initialState.esito
            };
        case __1.EReduxActionTypes.BOLLETTINO_POSTALE_RESET:
            return {
                ...initialState
            };
        case __1.EReduxActionTypes.RESET_ALL: // action invocata dalla logout
            return {
                ...initialState
            };
        default:
            return state;
    }
};
exports.bollettinoPostaleReducer = bollettinoPostaleReducer;
exports.default = exports.bollettinoPostaleReducer;
