"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bolloAciReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const helpers_1 = require("../helpers");
const bolloAci_types_1 = require("./bolloAci.types");
const initialState = {
    form: (0, bolloAci_types_1.FormBolloAciDtoDtoFromJSONTyped)({}),
    infoAutenticazioneBolloAci: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
    riepilogo: (0, api_rest_1.BolloAciDtoFromJSON)({}),
    bozzeBolloAci: [],
    currentBozza: (0, api_rest_1.BolloAciDtoFromJSON)({}),
    parametri: (0, api_rest_1.ParametriDtoFromJSON)({}),
    esito: (0, api_rest_1.EsitoAutorizzazioneDisposizioneBolloAciDtoFromJSON)({})
};
let rapportoSelezionato;
const bolloAciReducer = (state = initialState, action) => {
    switch (action.type) {
        case __1.EReduxActionTypes.BOLLO_ACI_GET_PARAMETRI_SUCCESS:
            return {
                ...initialState,
                parametri: action.payload
            };
        case __1.EReduxActionTypes.BOLLO_ACI_GET_PARAMETRI_PER_RIPETI_SUCCESS:
            return {
                ...initialState,
                parametri: action.payload,
                form: {
                    ...state.form,
                    ripeti: true
                }
            };
        case __1.EReduxActionTypes.BOLLO_ACI_CONTROLLO_SUCCESS:
            return {
                ...state,
                riepilogo: action.payload
            };
        case __1.EReduxActionTypes.BOLLO_ACI_GET_INFO_AUTENTICAZIONE_SUCCESS:
            return {
                ...state,
                infoAutenticazioneBolloAci: action.payload,
                esito: initialState.esito
            };
        case __1.EReduxActionTypes.BOLLO_ACI_AUTORIZZA_SUCCESS:
            if (action.payload.stato === 'OK') {
                return {
                    ...state,
                    //riepilogo: initialState.riepilogo,
                    esito: action.payload
                };
            }
            else {
                return {
                    ...state,
                    infoAutenticazioneBolloAci: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoAutenticazioneBolloAci, action.payload.informazioniAutenticazioneDispositiva),
                    esito: action.payload
                };
            }
        case __1.EReduxActionTypes.BOLLO_ACI_SET_RAPPORTO_SELEZIONATO:
            return {
                ...state,
                form: {
                    ...state.form,
                    rapportoSelezionato: action.payload,
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.BOLLO_ACI_SET_ALIAS_1CLICK:
            return {
                ...state,
                form: {
                    ...state.form,
                    alias1Click: action.payload,
                    alias1ClickDirty: true,
                    alias1ClickError: (0, helpers_1.validationCompleteCharSet)(action.payload, true),
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.BOLLO_ACI_SET_SALVA_1CLICK:
            return {
                ...state,
                form: {
                    ...state.form,
                    salva1Click: action.payload,
                    alias1Click: action.payload ? `Bollo${state.form.targa ? ' ' + state.form.targa : ''}` : '',
                    alias1ClickDirty: false,
                    alias1ClickError: ''
                }
            };
        case __1.EReduxActionTypes.BOLLO_ACI_SET_FROM_SUBMITTED:
            return {
                ...state,
                form: {
                    ...state.form,
                    formSubmitted: action.payload
                }
            };
        case __1.EReduxActionTypes.BOLLO_ACI_SET_TIPO_VEICOLO:
            return {
                ...state,
                form: {
                    ...state.form,
                    tipoVeicolo: action.payload,
                    tipoVeicoloDirty: true,
                    tipoVeicoloError: (0, helpers_1.validationField)(action.payload, ''),
                    targaError: action.payload === '3' || action.payload === '7' ? (0, helpers_1.validationTelaio)(state.form.targa, true) : (0, helpers_1.validationTarga)(state.form.targa, true),
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.BOLLO_ACI_SET_TARGA:
            return {
                ...state,
                form: {
                    ...state.form,
                    targa: action.payload,
                    targaDirty: true,
                    targaError: state.form.tipoVeicolo === '3' || state.form.tipoVeicolo === '7' ? (0, helpers_1.validationTelaio)(action.payload, true) : (0, helpers_1.validationTarga)(action.payload, true),
                    bozzaSalvata: false,
                    idContattoBeneficiario: undefined
                }
            };
        case __1.EReduxActionTypes.BOLLO_ACI_SET_NOME:
            return {
                ...state,
                form: {
                    ...state.form,
                    nome: action.payload,
                    nomeDirty: true,
                    nomeError: (0, helpers_1.validationField)(action.payload, ''),
                    bozzaSalvata: false,
                    idContattoBeneficiario: undefined
                }
            };
        case __1.EReduxActionTypes.BOLLO_ACI_SET_AFFIDABILE:
            return {
                ...state,
                form: {
                    ...state.form,
                    affidabile: action.payload
                }
            };
        case __1.EReduxActionTypes.BOLLO_ACI_SET_ID_CONTATTO_BENEFICIARIO:
            return {
                ...state,
                form: {
                    ...state.form,
                    idContattoBeneficiario: action.payload
                }
            };
        case __1.EReduxActionTypes.BOLLO_ACI_SET_AGIUNGI_RUBRICA:
            return {
                ...state,
                form: {
                    ...state.form,
                    aggiungiRubrica: action.payload,
                    nome: '',
                    nomeDirty: false,
                    nomeError: 'invalid.required'
                }
            };
        case __1.EReduxActionTypes.BOLLO_ACI_SET_STEP:
            return {
                ...state,
                form: {
                    ...state.form,
                    step: action.payload
                }
            };
        case __1.EReduxActionTypes.BOLLO_ACI_RESET:
            return {
                ...initialState
            };
        case __1.EReduxActionTypes.BOLLO_ACI_SET_BOZZA_SALVATA:
            return {
                ...state,
                form: {
                    ...state.form,
                    bozzaSalvata: action.payload
                }
            };
        case __1.EReduxActionTypes.BOLLO_ACI_GET_BOZZE_SUCCESS:
            return {
                ...state,
                bozzeBolloAci: action.payload
            };
        case __1.EReduxActionTypes.BOLLO_ACI_GET_BOZZA_SUCCESS:
            rapportoSelezionato = (0, helpers_1.findInArrayRapporti)(state.parametri && state.parametri.rapporti, action.payload.contoAddebito);
            return {
                ...state,
                form: {
                    ...state.form,
                    alias1ClickError: '',
                    salva1Click: false,
                    tipoVeicolo: action.payload.tipoDiVeicolo,
                    tipoVeicoloError: (0, helpers_1.validationField)(action.payload.tipoDiVeicolo, ''),
                    targa: action.payload.targa,
                    targaError: action.payload.tipoDiVeicolo === '3' || action.payload.tipoDiVeicolo === '7' ? (0, helpers_1.validationTelaio)(action.payload.targa, true) : (0, helpers_1.validationTarga)(action.payload.targa, true),
                    nomeError: '',
                    rapportoSelezionato: rapportoSelezionato,
                    bozzaSalvata: true
                },
                currentBozza: action.payload
            };
        case __1.EReduxActionTypes.BOLLO_ACI_GET_BOLLO_ACI_FROM_BOZZA:
            rapportoSelezionato = (0, helpers_1.findInArrayRapporti)(state.parametri && state.parametri.rapporti, action.payload.contoAddebito);
            return {
                ...state,
                form: {
                    ...state.form,
                    alias1ClickError: '',
                    salva1Click: false,
                    tipoVeicolo: action.payload.tipoDiVeicolo,
                    tipoVeicoloError: (0, helpers_1.validationField)(action.payload.tipoDiVeicolo, ''),
                    targa: action.payload.targa,
                    targaError: action.payload.tipoDiVeicolo === '3' || action.payload.tipoDiVeicolo === '7' ? (0, helpers_1.validationTelaio)(action.payload.targa, true) : (0, helpers_1.validationTarga)(action.payload.targa, true),
                    nomeError: '',
                    rapportoSelezionato: rapportoSelezionato,
                    bozzaSalvata: true
                },
                currentBozza: action.noBozza ? initialState.currentBozza : action.payload
            };
        case __1.EReduxActionTypes.BOLLO_ACI_ADD_BOZZA_SUCCESS:
            return {
                ...state,
                bozzeBolloAci: state.bozzeBolloAci ? [...state.bozzeBolloAci, action.payload] : [],
                form: {
                    ...state.form,
                    bozzaSalvata: true
                },
                currentBozza: action.payload
            };
        case __1.EReduxActionTypes.BOLLO_ACI_DEL_BOZZA_SUCCESS:
            return {
                ...state,
                bozzeBolloAci: state.bozzeBolloAci
                    ? state.bozzeBolloAci.filter((bolloAci) => bolloAci.id !== action.payload)
                    : [],
                form: {
                    ...state.form,
                    bozzaSalvata: state.currentBozza.id === action.payload ? false : state.form.bozzaSalvata
                },
                currentBozza: state.currentBozza.id === action.payload ? initialState.currentBozza : state.currentBozza
            };
        case __1.EReduxActionTypes.BOLLO_ACI_UPDATE_BOZZA_SUCCESS:
            return {
                ...state,
                bozzeBolloAci: state.bozzeBolloAci
                    ? state.bozzeBolloAci.map(bolloAci => bolloAci.id === state.currentBozza.id ? action.payload : bolloAci)
                    : []
            };
        case __1.EReduxActionTypes.BOLLO_ACI_SET_SALVA_BOZZA_VALIDATION:
            return {
                ...state,
                form: {
                    ...state.form,
                    targaError: state.form.tipoVeicolo === '3' || state.form.tipoVeicolo === '7' ? (0, helpers_1.validationTelaio)(state.form.targa, true) : (0, helpers_1.validationTarga)(state.form.targa, true),
                    tipoVeicoloError: (0, helpers_1.validationField)(state.form.tipoVeicolo, '')
                }
            };
        case __1.EReduxActionTypes.BOLLO_ACI_SET_CONTATTO_SELEZIONATO:
            return {
                ...state,
                form: {
                    ...state.form,
                    contattoSelezionato: action.payload
                }
            };
        case __1.EReduxActionTypes.RESET_ALL: // action invocata dalla logout
            return {
                ...initialState
            };
        default:
            return state;
    }
};
exports.bolloAciReducer = bolloAciReducer;
exports.default = exports.bolloAciReducer;
