"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DatiIntradayDtoToJSON = exports.DatiIntradayDtoFromJSONTyped = exports.DatiIntradayDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function DatiIntradayDtoFromJSON(json) {
    return DatiIntradayDtoFromJSONTyped(json, false);
}
exports.DatiIntradayDtoFromJSON = DatiIntradayDtoFromJSON;
function DatiIntradayDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'valoreMin': !runtime_1.exists(json, 'valoreMin') ? undefined : json['valoreMin'],
        'valoreMax': !runtime_1.exists(json, 'valoreMax') ? undefined : json['valoreMax'],
        'dati': !runtime_1.exists(json, 'dati') ? undefined : (json['dati'].map(_1.DatiIntradayQuotazioneDtoFromJSON)),
    };
}
exports.DatiIntradayDtoFromJSONTyped = DatiIntradayDtoFromJSONTyped;
function DatiIntradayDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'valoreMin': value.valoreMin,
        'valoreMax': value.valoreMax,
        'dati': value.dati === undefined ? undefined : (value.dati.map(_1.DatiIntradayQuotazioneDtoToJSON)),
    };
}
exports.DatiIntradayDtoToJSON = DatiIntradayDtoToJSON;
