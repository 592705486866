"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EsitoOperazioniAnagrafeDtoToJSON = exports.EsitoOperazioniAnagrafeDtoFromJSONTyped = exports.EsitoOperazioniAnagrafeDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function EsitoOperazioniAnagrafeDtoFromJSON(json) {
    return EsitoOperazioniAnagrafeDtoFromJSONTyped(json, false);
}
exports.EsitoOperazioniAnagrafeDtoFromJSON = EsitoOperazioniAnagrafeDtoFromJSON;
function EsitoOperazioniAnagrafeDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'idDisposizione': !runtime_1.exists(json, 'idDisposizione') ? undefined : json['idDisposizione'],
        'statoDisposizione': !runtime_1.exists(json, 'statoDisposizione') ? undefined : json['statoDisposizione'],
        'datiAggiuntivi': !runtime_1.exists(json, 'datiAggiuntivi') ? undefined : _1.DatiAggiuntiviRispostaDtoFromJSON(json['datiAggiuntivi']),
    };
}
exports.EsitoOperazioniAnagrafeDtoFromJSONTyped = EsitoOperazioniAnagrafeDtoFromJSONTyped;
function EsitoOperazioniAnagrafeDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'idDisposizione': value.idDisposizione,
        'statoDisposizione': value.statoDisposizione,
        'datiAggiuntivi': _1.DatiAggiuntiviRispostaDtoToJSON(value.datiAggiuntivi),
    };
}
exports.EsitoOperazioniAnagrafeDtoToJSON = EsitoOperazioniAnagrafeDtoToJSON;
