"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrestitoDtoToJSON = exports.PrestitoDtoFromJSONTyped = exports.PrestitoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function PrestitoDtoFromJSON(json) {
    return PrestitoDtoFromJSONTyped(json, false);
}
exports.PrestitoDtoFromJSON = PrestitoDtoFromJSON;
function PrestitoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'codiceRapporto': !runtime_1.exists(json, 'codiceRapporto') ? undefined : json['codiceRapporto'],
        'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
        'importo': !runtime_1.exists(json, 'importo') ? undefined : json['importo'],
        'statoCivile': !runtime_1.exists(json, 'statoCivile') ? undefined : json['statoCivile'],
        'regimePatrimoniale': !runtime_1.exists(json, 'regimePatrimoniale') ? undefined : json['regimePatrimoniale'],
        'numeroFamAdulti': !runtime_1.exists(json, 'numeroFamAdulti') ? undefined : json['numeroFamAdulti'],
        'numeroFigli': !runtime_1.exists(json, 'numeroFigli') ? undefined : json['numeroFigli'],
        'situazioneAbitativa': !runtime_1.exists(json, 'situazioneAbitativa') ? undefined : json['situazioneAbitativa'],
        'categoriaProfessione': !runtime_1.exists(json, 'categoriaProfessione') ? undefined : json['categoriaProfessione'],
        'categoriaInattivi': !runtime_1.exists(json, 'categoriaInattivi') ? undefined : json['categoriaInattivi'],
        'categoriaDipendenti': !runtime_1.exists(json, 'categoriaDipendenti') ? undefined : json['categoriaDipendenti'],
        'categoriaMilitari': !runtime_1.exists(json, 'categoriaMilitari') ? undefined : json['categoriaMilitari'],
        'categoriaDocenti': !runtime_1.exists(json, 'categoriaDocenti') ? undefined : json['categoriaDocenti'],
        'categoriaLiberiProfessionisti': !runtime_1.exists(json, 'categoriaLiberiProfessionisti') ? undefined : json['categoriaLiberiProfessionisti'],
        'categoriaAutonomi': !runtime_1.exists(json, 'categoriaAutonomi') ? undefined : json['categoriaAutonomi'],
        'nomeDatoreLavoro': !runtime_1.exists(json, 'nomeDatoreLavoro') ? undefined : json['nomeDatoreLavoro'],
        'rapportoDiLavoro': !runtime_1.exists(json, 'rapportoDiLavoro') ? undefined : json['rapportoDiLavoro'],
        'annoInizioAttivita': !runtime_1.exists(json, 'annoInizioAttivita') ? undefined : json['annoInizioAttivita'],
        'domiciliazioneStipendio': !runtime_1.exists(json, 'domiciliazioneStipendio') ? undefined : json['domiciliazioneStipendio'],
        'redditoNettoMensile': !runtime_1.exists(json, 'redditoNettoMensile') ? undefined : json['redditoNettoMensile'],
        'altriRedditiMensili': !runtime_1.exists(json, 'altriRedditiMensili') ? undefined : json['altriRedditiMensili'],
        'oneriMensiliCrediti': !runtime_1.exists(json, 'oneriMensiliCrediti') ? undefined : json['oneriMensiliCrediti'],
        'oneriMensiliAffitto': !runtime_1.exists(json, 'oneriMensiliAffitto') ? undefined : json['oneriMensiliAffitto'],
        'altriImpegniMensili': !runtime_1.exists(json, 'altriImpegniMensili') ? undefined : json['altriImpegniMensili'],
        'tipoCartaDiCredito': !runtime_1.exists(json, 'tipoCartaDiCredito') ? undefined : json['tipoCartaDiCredito'],
        'durataPrestito': !runtime_1.exists(json, 'durataPrestito') ? undefined : json['durataPrestito'],
        'scopoPrestito': !runtime_1.exists(json, 'scopoPrestito') ? undefined : json['scopoPrestito'],
        'altroScopoPrestito': !runtime_1.exists(json, 'altroScopoPrestito') ? undefined : json['altroScopoPrestito'],
        'dettaglioProfessione': !runtime_1.exists(json, 'dettaglioProfessione') ? undefined : json['dettaglioProfessione'],
        'contoAddebitoIban': !runtime_1.exists(json, 'contoAddebitoIban') ? undefined : json['contoAddebitoIban'],
        'ordinanteIntestazione': !runtime_1.exists(json, 'ordinanteIntestazione') ? undefined : json['ordinanteIntestazione'],
        'contoAddebitoAlias': !runtime_1.exists(json, 'contoAddebitoAlias') ? undefined : json['contoAddebitoAlias'],
    };
}
exports.PrestitoDtoFromJSONTyped = PrestitoDtoFromJSONTyped;
function PrestitoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'codiceRapporto': value.codiceRapporto,
        'stato': value.stato,
        'importo': value.importo,
        'statoCivile': value.statoCivile,
        'regimePatrimoniale': value.regimePatrimoniale,
        'numeroFamAdulti': value.numeroFamAdulti,
        'numeroFigli': value.numeroFigli,
        'situazioneAbitativa': value.situazioneAbitativa,
        'categoriaProfessione': value.categoriaProfessione,
        'categoriaInattivi': value.categoriaInattivi,
        'categoriaDipendenti': value.categoriaDipendenti,
        'categoriaMilitari': value.categoriaMilitari,
        'categoriaDocenti': value.categoriaDocenti,
        'categoriaLiberiProfessionisti': value.categoriaLiberiProfessionisti,
        'categoriaAutonomi': value.categoriaAutonomi,
        'nomeDatoreLavoro': value.nomeDatoreLavoro,
        'rapportoDiLavoro': value.rapportoDiLavoro,
        'annoInizioAttivita': value.annoInizioAttivita,
        'domiciliazioneStipendio': value.domiciliazioneStipendio,
        'redditoNettoMensile': value.redditoNettoMensile,
        'altriRedditiMensili': value.altriRedditiMensili,
        'oneriMensiliCrediti': value.oneriMensiliCrediti,
        'oneriMensiliAffitto': value.oneriMensiliAffitto,
        'altriImpegniMensili': value.altriImpegniMensili,
        'tipoCartaDiCredito': value.tipoCartaDiCredito,
        'durataPrestito': value.durataPrestito,
        'scopoPrestito': value.scopoPrestito,
        'altroScopoPrestito': value.altroScopoPrestito,
        'dettaglioProfessione': value.dettaglioProfessione,
        'contoAddebitoIban': value.contoAddebitoIban,
        'ordinanteIntestazione': value.ordinanteIntestazione,
        'contoAddebitoAlias': value.contoAddebitoAlias,
    };
}
exports.PrestitoDtoToJSON = PrestitoDtoToJSON;
