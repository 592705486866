"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * API BFF Customer Web Collaboration
 * API BFF Customer Web Collaboration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventRequestToJSON = exports.EventRequestFromJSONTyped = exports.EventRequestFromJSON = void 0;
const runtime_1 = require("../runtime");
function EventRequestFromJSON(json) {
    return EventRequestFromJSONTyped(json, false);
}
exports.EventRequestFromJSON = EventRequestFromJSON;
function EventRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'customerNote': !runtime_1.exists(json, 'customerNote') ? undefined : json['customerNote'],
        'email': json['email'],
        'end': json['end'],
        'eventCreatorType': json['eventCreatorType'],
        'idServiceProduct': json['idServiceProduct'],
        'kind': json['kind'],
        'name': json['name'],
        'ndg': json['ndg'],
        'note': !runtime_1.exists(json, 'note') ? undefined : json['note'],
        'preparationMinutesTime': !runtime_1.exists(json, 'preparationMinutesTime') ? undefined : json['preparationMinutesTime'],
        'start': json['start'],
        'userType': !runtime_1.exists(json, 'userType') ? undefined : json['userType'],
    };
}
exports.EventRequestFromJSONTyped = EventRequestFromJSONTyped;
function EventRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'customerNote': value.customerNote,
        'email': value.email,
        'end': value.end,
        'eventCreatorType': value.eventCreatorType,
        'idServiceProduct': value.idServiceProduct,
        'kind': value.kind,
        'name': value.name,
        'ndg': value.ndg,
        'note': value.note,
        'preparationMinutesTime': value.preparationMinutesTime,
        'start': value.start,
        'userType': value.userType,
    };
}
exports.EventRequestToJSON = EventRequestToJSON;
