"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CofidisControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class CofidisControllerApi extends runtime.BaseAPI {
    /**
     * Restituisce l\'url di reindirizzamento per l\'utente
     */
    cofidisUrlRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.cofidisInDto === null || requestParameters.cofidisInDto === undefined) {
                throw new runtime.RequiredError('cofidisInDto', 'Required parameter requestParameters.cofidisInDto was null or undefined when calling cofidisUrl.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/cofidis/url`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CofidisInDtoToJSON(requestParameters.cofidisInDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.CofidisUrlDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce l\'url di reindirizzamento per l\'utente
     */
    cofidisUrl(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.cofidisUrlRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce le informazioni relative all\'utente per cofidis
     */
    datiUtenteRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/cofidis/datiutente`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.CofidisDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce le informazioni relative all\'utente per cofidis
     */
    datiUtente() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.datiUtenteRaw();
            return yield response.value();
        });
    }
    /**
     * Restituisce il dettaglio dell\'informativa cofidis
     */
    dettaglioInformativaCofidisRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.codiceRapporto === null || requestParameters.codiceRapporto === undefined) {
                throw new runtime.RequiredError('codiceRapporto', 'Required parameter requestParameters.codiceRapporto was null or undefined when calling dettaglioInformativaCofidis.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/cofidis/dettaglioInformativa/{codiceRapporto}`.replace(`{${"codiceRapporto"}}`, encodeURIComponent(String(requestParameters.codiceRapporto))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.DettaglioInformativaCofidisDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce il dettaglio dell\'informativa cofidis
     */
    dettaglioInformativaCofidis(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.dettaglioInformativaCofidisRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce i paramertri informativa cofidis
     */
    parametriInformativaCofidisRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/cofidis/parametri`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ParametriFinanziamentiDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce i paramertri informativa cofidis
     */
    parametriInformativaCofidis() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.parametriInformativaCofidisRaw();
            return yield response.value();
        });
    }
}
exports.CofidisControllerApi = CofidisControllerApi;
