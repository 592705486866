"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BollettinoPostaleDtoStatoDisposizioneEnum = exports.BollettinoPostaleDtoTipologiaEnum = exports.BollettinoPostaleDtoToJSON = exports.BollettinoPostaleDtoFromJSONTyped = exports.BollettinoPostaleDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function BollettinoPostaleDtoFromJSON(json) {
    return BollettinoPostaleDtoFromJSONTyped(json, false);
}
exports.BollettinoPostaleDtoFromJSON = BollettinoPostaleDtoFromJSON;
function BollettinoPostaleDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'tipologia': json['tipologia'],
        'contoAddebito': !runtime_1.exists(json, 'contoAddebito') ? undefined : json['contoAddebito'],
        'dataInserimento': !runtime_1.exists(json, 'dataInserimento') ? undefined : (new Date(json['dataInserimento'])),
        'dataPagamento': !runtime_1.exists(json, 'dataPagamento') ? undefined : (new Date(json['dataPagamento'])),
        'contoBeneficiario': !runtime_1.exists(json, 'contoBeneficiario') ? undefined : json['contoBeneficiario'],
        'esecutore': !runtime_1.exists(json, 'esecutore') ? undefined : json['esecutore'],
        'causale': !runtime_1.exists(json, 'causale') ? undefined : json['causale'],
        'importo': !runtime_1.exists(json, 'importo') ? undefined : json['importo'],
        'indirizzo': !runtime_1.exists(json, 'indirizzo') ? undefined : json['indirizzo'],
        'cap': !runtime_1.exists(json, 'cap') ? undefined : json['cap'],
        'citta': !runtime_1.exists(json, 'citta') ? undefined : json['citta'],
        'provincia': !runtime_1.exists(json, 'provincia') ? undefined : json['provincia'],
        'statoDisposizioneSP': !runtime_1.exists(json, 'statoDisposizioneSP') ? undefined : json['statoDisposizioneSP'],
        'revocabile': json['revocabile'],
        'numeroBollettino': !runtime_1.exists(json, 'numeroBollettino') ? undefined : json['numeroBollettino'],
        'tipoDocumento': !runtime_1.exists(json, 'tipoDocumento') ? undefined : json['tipoDocumento'],
        'canale': !runtime_1.exists(json, 'canale') ? undefined : json['canale'],
        'bozza': !runtime_1.exists(json, 'bozza') ? undefined : _1.BozzaDtoFromJSON(json['bozza']),
        'inviato': !runtime_1.exists(json, 'inviato') ? undefined : _1.InviatoDtoFromJSON(json['inviato']),
        'tipo': !runtime_1.exists(json, 'tipo') ? undefined : json['tipo'],
        'datiEsecuzioneBollettino': !runtime_1.exists(json, 'datiEsecuzioneBollettino') ? undefined : _1.DatiEsecuzioneBollettinoDtoFromJSON(json['datiEsecuzioneBollettino']),
        'intestatario': !runtime_1.exists(json, 'intestatario') ? undefined : json['intestatario'],
        'scadenziereUnico': !runtime_1.exists(json, 'scadenziereUnico') ? undefined : _1.ScadenziereUnicoDtoFromJSON(json['scadenziereUnico']),
        'idTransazione': !runtime_1.exists(json, 'idTransazione') ? undefined : json['idTransazione'],
        'quietanza': !runtime_1.exists(json, 'quietanza') ? undefined : _1.QuietanzaDtoFromJSON(json['quietanza']),
        'idUtenza': json['idUtenza'],
        'codiceSiaAzienda': !runtime_1.exists(json, 'codiceSiaAzienda') ? undefined : json['codiceSiaAzienda'],
        'tipoStrumento': !runtime_1.exists(json, 'tipoStrumento') ? undefined : json['tipoStrumento'],
        'idStrumento': !runtime_1.exists(json, 'idStrumento') ? undefined : json['idStrumento'],
        'abiCliente': !runtime_1.exists(json, 'abiCliente') ? undefined : json['abiCliente'],
        'condivisione': !runtime_1.exists(json, 'condivisione') ? undefined : (json['condivisione'].map(_1.CondivisioneDtoFromJSON)),
        'statoDisposizione': !runtime_1.exists(json, 'statoDisposizione') ? undefined : json['statoDisposizione'],
        'idBozzaCollegata': !runtime_1.exists(json, 'idBozzaCollegata') ? undefined : json['idBozzaCollegata'],
        'idOneClickCollegata': !runtime_1.exists(json, 'idOneClickCollegata') ? undefined : json['idOneClickCollegata'],
        'aggiungiRubrica': !runtime_1.exists(json, 'aggiungiRubrica') ? undefined : json['aggiungiRubrica'],
        'aggiungi1click': !runtime_1.exists(json, 'aggiungi1click') ? undefined : json['aggiungi1click'],
        'aggiungiWhitelist': !runtime_1.exists(json, 'aggiungiWhitelist') ? undefined : json['aggiungiWhitelist'],
        'alias1Click': !runtime_1.exists(json, 'alias1Click') ? undefined : json['alias1Click'],
        'nativeId': !runtime_1.exists(json, 'nativeId') ? undefined : json['nativeId'],
        'contoAddebitoIban': !runtime_1.exists(json, 'contoAddebitoIban') ? undefined : json['contoAddebitoIban'],
        'contoAddebitoAlias': !runtime_1.exists(json, 'contoAddebitoAlias') ? undefined : json['contoAddebitoAlias'],
        'ordinanteIntestazione': !runtime_1.exists(json, 'ordinanteIntestazione') ? undefined : json['ordinanteIntestazione'],
        'statoReport': !runtime_1.exists(json, 'statoReport') ? undefined : json['statoReport'],
    };
}
exports.BollettinoPostaleDtoFromJSONTyped = BollettinoPostaleDtoFromJSONTyped;
function BollettinoPostaleDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'tipologia': value.tipologia,
        'contoAddebito': value.contoAddebito,
        'dataInserimento': value.dataInserimento === undefined ? undefined : (value.dataInserimento.toISOString()),
        'dataPagamento': value.dataPagamento === undefined ? undefined : (value.dataPagamento.toISOString()),
        'contoBeneficiario': value.contoBeneficiario,
        'esecutore': value.esecutore,
        'causale': value.causale,
        'importo': value.importo,
        'indirizzo': value.indirizzo,
        'cap': value.cap,
        'citta': value.citta,
        'provincia': value.provincia,
        'statoDisposizioneSP': value.statoDisposizioneSP,
        'revocabile': value.revocabile,
        'numeroBollettino': value.numeroBollettino,
        'tipoDocumento': value.tipoDocumento,
        'canale': value.canale,
        'bozza': _1.BozzaDtoToJSON(value.bozza),
        'inviato': _1.InviatoDtoToJSON(value.inviato),
        'tipo': value.tipo,
        'datiEsecuzioneBollettino': _1.DatiEsecuzioneBollettinoDtoToJSON(value.datiEsecuzioneBollettino),
        'intestatario': value.intestatario,
        'scadenziereUnico': _1.ScadenziereUnicoDtoToJSON(value.scadenziereUnico),
        'idTransazione': value.idTransazione,
        'quietanza': _1.QuietanzaDtoToJSON(value.quietanza),
        'idUtenza': value.idUtenza,
        'codiceSiaAzienda': value.codiceSiaAzienda,
        'tipoStrumento': value.tipoStrumento,
        'idStrumento': value.idStrumento,
        'abiCliente': value.abiCliente,
        'condivisione': value.condivisione === undefined ? undefined : (value.condivisione.map(_1.CondivisioneDtoToJSON)),
        'statoDisposizione': value.statoDisposizione,
        'idBozzaCollegata': value.idBozzaCollegata,
        'idOneClickCollegata': value.idOneClickCollegata,
        'aggiungiRubrica': value.aggiungiRubrica,
        'aggiungi1click': value.aggiungi1click,
        'aggiungiWhitelist': value.aggiungiWhitelist,
        'alias1Click': value.alias1Click,
        'nativeId': value.nativeId,
        'contoAddebitoIban': value.contoAddebitoIban,
        'contoAddebitoAlias': value.contoAddebitoAlias,
        'ordinanteIntestazione': value.ordinanteIntestazione,
        'statoReport': value.statoReport,
    };
}
exports.BollettinoPostaleDtoToJSON = BollettinoPostaleDtoToJSON;
/**
* @export
* @enum {string}
*/
var BollettinoPostaleDtoTipologiaEnum;
(function (BollettinoPostaleDtoTipologiaEnum) {
    BollettinoPostaleDtoTipologiaEnum["PREMARCATO"] = "PREMARCATO";
    BollettinoPostaleDtoTipologiaEnum["BIANCO"] = "BIANCO";
})(BollettinoPostaleDtoTipologiaEnum = exports.BollettinoPostaleDtoTipologiaEnum || (exports.BollettinoPostaleDtoTipologiaEnum = {}));
/**
* @export
* @enum {string}
*/
var BollettinoPostaleDtoStatoDisposizioneEnum;
(function (BollettinoPostaleDtoStatoDisposizioneEnum) {
    BollettinoPostaleDtoStatoDisposizioneEnum["AUTORIZZATO"] = "AUTORIZZATO";
    BollettinoPostaleDtoStatoDisposizioneEnum["AUTORIZZATO_NON_REV"] = "AUTORIZZATO_NON_REV";
    BollettinoPostaleDtoStatoDisposizioneEnum["NON_AUTORIZZATO"] = "NON_AUTORIZZATO";
    BollettinoPostaleDtoStatoDisposizioneEnum["IN_CORSO"] = "IN_CORSO";
    BollettinoPostaleDtoStatoDisposizioneEnum["INVIATO"] = "INVIATO";
    BollettinoPostaleDtoStatoDisposizioneEnum["ESITO_INCERTO"] = "ESITO_INCERTO";
    BollettinoPostaleDtoStatoDisposizioneEnum["REVOCATO"] = "REVOCATO";
    BollettinoPostaleDtoStatoDisposizioneEnum["DA_AUTORIZZARE"] = "DA_AUTORIZZARE";
    BollettinoPostaleDtoStatoDisposizioneEnum["BOZZA"] = "BOZZA";
    BollettinoPostaleDtoStatoDisposizioneEnum["SOSPESO"] = "SOSPESO";
    BollettinoPostaleDtoStatoDisposizioneEnum["STORNATO"] = "STORNATO";
    BollettinoPostaleDtoStatoDisposizioneEnum["PAGATO"] = "PAGATO";
    BollettinoPostaleDtoStatoDisposizioneEnum["ANNULLATO"] = "ANNULLATO";
})(BollettinoPostaleDtoStatoDisposizioneEnum = exports.BollettinoPostaleDtoStatoDisposizioneEnum || (exports.BollettinoPostaleDtoStatoDisposizioneEnum = {}));
