"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TitoliFondiElementoDtoToJSON = exports.TitoliFondiElementoDtoFromJSONTyped = exports.TitoliFondiElementoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function TitoliFondiElementoDtoFromJSON(json) {
    return TitoliFondiElementoDtoFromJSONTyped(json, false);
}
exports.TitoliFondiElementoDtoFromJSON = TitoliFondiElementoDtoFromJSON;
function TitoliFondiElementoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'rapporto': !runtime_1.exists(json, 'rapporto') ? undefined : json['rapporto'],
        'codiceRapportoFormattatoPerTipo': !runtime_1.exists(json, 'codiceRapportoFormattatoPerTipo') ? undefined : json['codiceRapportoFormattatoPerTipo'],
        'controvalore': !runtime_1.exists(json, 'controvalore') ? undefined : json['controvalore'],
        'utilePerditaEuro': !runtime_1.exists(json, 'utilePerditaEuro') ? undefined : json['utilePerditaEuro'],
        'utilePerditaPercentuale': !runtime_1.exists(json, 'utilePerditaPercentuale') ? undefined : json['utilePerditaPercentuale'],
    };
}
exports.TitoliFondiElementoDtoFromJSONTyped = TitoliFondiElementoDtoFromJSONTyped;
function TitoliFondiElementoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'rapporto': value.rapporto,
        'codiceRapportoFormattatoPerTipo': value.codiceRapportoFormattatoPerTipo,
        'controvalore': value.controvalore,
        'utilePerditaEuro': value.utilePerditaEuro,
        'utilePerditaPercentuale': value.utilePerditaPercentuale,
    };
}
exports.TitoliFondiElementoDtoToJSON = TitoliFondiElementoDtoToJSON;
