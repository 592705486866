"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DatiDocumentoDtoToJSON = exports.DatiDocumentoDtoFromJSONTyped = exports.DatiDocumentoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function DatiDocumentoDtoFromJSON(json) {
    return DatiDocumentoDtoFromJSONTyped(json, false);
}
exports.DatiDocumentoDtoFromJSON = DatiDocumentoDtoFromJSON;
function DatiDocumentoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'classe': !runtime_1.exists(json, 'classe') ? undefined : json['classe'],
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'returnCode': !runtime_1.exists(json, 'returnCode') ? undefined : json['returnCode'],
        'error': !runtime_1.exists(json, 'error') ? undefined : json['error'],
    };
}
exports.DatiDocumentoDtoFromJSONTyped = DatiDocumentoDtoFromJSONTyped;
function DatiDocumentoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'classe': value.classe,
        'id': value.id,
        'returnCode': value.returnCode,
        'error': value.error,
    };
}
exports.DatiDocumentoDtoToJSON = DatiDocumentoDtoToJSON;
