"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssicurazioniDtoToJSON = exports.AssicurazioniDtoFromJSONTyped = exports.AssicurazioniDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function AssicurazioniDtoFromJSON(json) {
    return AssicurazioniDtoFromJSONTyped(json, false);
}
exports.AssicurazioniDtoFromJSON = AssicurazioniDtoFromJSON;
function AssicurazioniDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'dataElaborazione': !runtime_1.exists(json, 'dataElaborazione') ? undefined : (new Date(json['dataElaborazione'])),
        'saldoTotale': !runtime_1.exists(json, 'saldoTotale') ? undefined : json['saldoTotale'],
        'polizze': !runtime_1.exists(json, 'polizze') ? undefined : (json['polizze'].map(_1.AssicurazioneElementoDtoFromJSON)),
    };
}
exports.AssicurazioniDtoFromJSONTyped = AssicurazioniDtoFromJSONTyped;
function AssicurazioniDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'dataElaborazione': value.dataElaborazione === undefined ? undefined : (value.dataElaborazione.toISOString()),
        'saldoTotale': value.saldoTotale,
        'polizze': value.polizze === undefined ? undefined : (value.polizze.map(_1.AssicurazioneElementoDtoToJSON)),
    };
}
exports.AssicurazioniDtoToJSON = AssicurazioniDtoToJSON;
