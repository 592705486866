"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetMIFIDNoRapporti = exports.resetMIFID = exports.getMIFIDRapporti = exports.resetMIFIDForm = exports.resetMIFIDFormAnswers = exports.setMIFIDHistoryAnswers = exports.setMIFIDFormAnswerValue = exports.setMIFIDFormAnswers = exports.goBackRisposteMIFID = exports.modificaRispostaMIFID = exports.salvaIncoerenzaDaIgnorareMIFID = exports.salvaRisposteForzateMIFID = exports.salvaRisposteMIFID = exports.exportReportMIFID = exports.getRisposteMIFID = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
const mifid_selectors_1 = require("./mifid.selectors");
const mifid_types_1 = require("./mifid.types");
function generalError(value) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value));
    };
}
function getRisposteMIFIDStart() {
    return {
        type: mifid_types_1.EReduxMIFIDActionTypes.MIFID_GET_RISPOSTE_START
    };
}
function getRisposteMIFIDSuccess(payload) {
    return {
        type: mifid_types_1.EReduxMIFIDActionTypes.MIFID_GET_RISPOSTE_SUCCESS,
        payload
    };
}
const getRisposteMIFID = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getRisposteMIFIDStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/answer_mifid.json')).then(response => {
            dispatch(getRisposteMIFIDSuccess((0, api_rest_1.QuestionnaireAnswersDtoFromJSON)(response)));
            // dispatch(creaQuestionarioPerRisposte(QuestionnaireAnswersDtoFromJSON(response)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const mifid = getState().mifid;
        const request = {
            getQuestionnaireAnswersInDto: {
                customerId: (0, mifid_selectors_1.mifidCustomerId)(mifid),
                idQuestionnaire: "QUEST_PF",
                sectionCode: [
                    "SF",
                    "OI",
                    "ES"
                ]
            }
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.PrometeiaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .getQuestionnaireAnswers(request).then((response) => {
                dispatch(getRisposteMIFIDSuccess(response));
                // dispatch(creaQuestionarioPerRisposte(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getRisposteMIFID = getRisposteMIFID;
function exportReportMIFIDStart() {
    return {
        type: mifid_types_1.EReduxMIFIDActionTypes.MIFID_EXPORT_REPORT_START
    };
}
function exportReportSuccess() {
    return {
        type: mifid_types_1.EReduxMIFIDActionTypes.MIFID_EXPORT_REPORT_SUCCESS
    };
}
const exportReportMIFID = (share, title, message) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(exportReportMIFIDStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        const mifid = getState().mifid;
        const request = {
            questionnaireReportInDto: {
                customerId: (0, mifid_selectors_1.mifidCustomerId)(mifid),
                downloadReport: true,
                generateReport: false
            }
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.PrometeiaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf'))
                .printReport(request).then((response) => {
                if (extraArguments.platform === 'desktop') {
                    (0, helpers_1.hadlePdfResponse)(response, (url) => dispatch((0, general_actions_1.popUpBlockedPdf)(url)));
                }
                if (extraArguments.platform === 'mobile') {
                    (0, helpers_1.download_sharePDF)(extraArguments, 'mifid.pdf', response, share, title, message);
                }
                dispatch(exportReportSuccess());
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.exportReportMIFID = exportReportMIFID;
function salvaRisposteMIFIDStart() {
    return {
        type: mifid_types_1.EReduxMIFIDActionTypes.MIFID_SALVA_RISPOSTE_START
    };
}
function salvaRisposteMIFIDSuccess(payload) {
    return {
        type: mifid_types_1.EReduxMIFIDActionTypes.MIFID_SALVA_RISPOSTE_SUCCESS,
        payload
    };
}
const salvaRisposteMIFID = (idControl = [], backInHistoryTimes) => async (dispatch, getState, extraArguments) => {
    var _a, _b;
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(salvaRisposteMIFIDStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/save_mifid.json')).then(response => {
            dispatch(salvaRisposteMIFIDSuccess((0, api_rest_1.QuestionnaireAnswersDtoFromJSON)(response)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const mifid = getState().mifid;
        const historyAnswers = mifid.historyAnswers;
        const answersList = backInHistoryTimes
            ? historyAnswers.slice(-backInHistoryTimes - 1)[0] // .slice(-2) -> array: [[1, 2, 3], [4, 5, 6], [7, 8, 9], [10, 11, 12]] => result: [7, 8, 9]
            : mifid.formAnswers;
        const request = {
            saveQuestionnaireAnswersInDto: {
                customerId: (0, mifid_selectors_1.mifidCustomerId)(mifid),
                idQuestionnaire: ((_a = mifid === null || mifid === void 0 ? void 0 : mifid.risposte) === null || _a === void 0 ? void 0 : _a.idQuestionnaire) || '',
                body: {
                    answersList: answersList
                },
                idControl: [...(((_b = mifid.riepilogo) === null || _b === void 0 ? void 0 : _b.idControl) || []), ...idControl],
                updateReason: undefined,
                save: true
            }
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.PrometeiaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .saveQuestionnaireAnswers(request).then((response) => {
                var _a;
                const newQuestions = (0, mifid_selectors_1.mifidCurrentQuestions)({ ...mifid, riepilogo: response });
                const coherenceToBeTriggered = mifid.coherenceToBeIgnored.filter(ctbi => !!ctbi.questions.find(q => !!(newQuestions === null || newQuestions === void 0 ? void 0 : newQuestions.find(nq => q.id == nq.id))));
                if (coherenceToBeTriggered.length > 0) {
                    rimuoviIncoerenzaDaIgnorare(coherenceToBeTriggered);
                }
                const coherenceFiltered = (_a = response.coherence) === null || _a === void 0 ? void 0 : _a.filter(c => !mifid.coherenceToBeIgnored.find(ctbi => ctbi.coherence.id == c.id));
                if (response.answersList) {
                    if (coherenceToBeTriggered.length > 0) {
                        dispatch(salvaRisposteMIFIDSuccess(response));
                    }
                    else {
                        dispatch(salvaRisposteMIFIDSuccess({
                            ...response,
                            coherence: coherenceFiltered
                        }));
                    }
                    if (backInHistoryTimes) {
                        const newHistoryAnswers = historyAnswers.slice(0, -backInHistoryTimes); // .slice(0, -1) -> array: [[1, 2, 3], [4, 5, 6], [7, 8, 9], [10, 11, 12]] => result: [[1, 2, 3], [4, 5, 6], [7, 8, 9]]
                        dispatch(setMIFIDHistoryAnswers(newHistoryAnswers));
                        const lastAnswers = historyAnswers.slice(-backInHistoryTimes)[0]; // .slice(-1)[0] -> array: [[1, 2, 3], [4, 5, 6], [7, 8, 9], [10, 11, 12]] => result: [10, 11, 12]
                        dispatch(setMIFIDFormAnswers(response.answersList.map(a => {
                            const lastAnswer = lastAnswers.find(newHistoryAnswer => a.question == newHistoryAnswer.question);
                            const lastValue = lastAnswer === null || lastAnswer === void 0 ? void 0 : lastAnswer.value;
                            if (lastValue != undefined) {
                                return {
                                    ...a,
                                    value: lastValue
                                };
                            }
                            return { ...a };
                        })));
                    }
                    else {
                        const newHistoryAnswers = [...historyAnswers, answersList];
                        dispatch(setMIFIDHistoryAnswers(newHistoryAnswers));
                        dispatch(setMIFIDFormAnswers(response.answersList.map(a => {
                            var _a, _b, _c, _d, _e, _f, _g, _h;
                            const answer = mifid.formAnswers.find(fa => fa.question == a.question);
                            if (!!answer) {
                                return { ...answer };
                            }
                            if (a.questionTableId == 1) {
                                const section = (_b = (_a = mifid.risposte) === null || _a === void 0 ? void 0 : _a.sections) === null || _b === void 0 ? void 0 : _b.find(s => {
                                    var _a;
                                    return (_a = s.questions) === null || _a === void 0 ? void 0 : _a.find(q => {
                                        var _a, _b;
                                        return (_b = (_a = q.table) === null || _a === void 0 ? void 0 : _a['1']) === null || _b === void 0 ? void 0 : _b.find(o => o.id == a.question);
                                    });
                                });
                                const question = (_c = section === null || section === void 0 ? void 0 : section.questions) === null || _c === void 0 ? void 0 : _c.find(q => {
                                    var _a, _b;
                                    return (_b = (_a = q.table) === null || _a === void 0 ? void 0 : _a['1']) === null || _b === void 0 ? void 0 : _b.find(o => o.id == a.question);
                                });
                                const subQuestion = (_e = (_d = question === null || question === void 0 ? void 0 : question.table) === null || _d === void 0 ? void 0 : _d['1']) === null || _e === void 0 ? void 0 : _e.find(o => o.id == a.question);
                                const subValue = subQuestion === null || subQuestion === void 0 ? void 0 : subQuestion.value;
                                if (subValue != undefined) {
                                    return {
                                        ...a,
                                        value: subValue
                                    };
                                }
                                const newSections = response.sections;
                                const newSectionIndex = (newSections === null || newSections === void 0 ? void 0 : newSections.length)
                                    ? newSections.length - 1
                                    : undefined;
                                const newSection = newSections && newSectionIndex != undefined
                                    ? newSections[newSectionIndex]
                                    : undefined;
                                const newQuestions = newSection === null || newSection === void 0 ? void 0 : newSection.questions;
                                if ((newQuestions === null || newQuestions === void 0 ? void 0 : newQuestions.length) == 1) {
                                    const isCheckGroup = newQuestions[0].type == 'CHECKTABLE';
                                    if (isCheckGroup) {
                                        return {
                                            ...a,
                                            value: 'N'
                                        };
                                    }
                                }
                                return { ...a };
                            }
                            const section = (_g = (_f = mifid.risposte) === null || _f === void 0 ? void 0 : _f.sections) === null || _g === void 0 ? void 0 : _g.find(s => {
                                var _a;
                                return (_a = s.questions) === null || _a === void 0 ? void 0 : _a.find(q => q.id == a.question);
                            });
                            const question = (_h = section === null || section === void 0 ? void 0 : section.questions) === null || _h === void 0 ? void 0 : _h.find(q => q.id == a.question);
                            const value = question === null || question === void 0 ? void 0 : question.value;
                            if (value != undefined) {
                                return {
                                    ...a,
                                    value: value
                                };
                            }
                            return { ...a };
                        })));
                    }
                }
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.salvaRisposteMIFID = salvaRisposteMIFID;
const salvaRisposteForzateMIFID = (idControl) => async (dispatch, getState, extraArguments) => {
    dispatch((0, exports.salvaRisposteMIFID)(idControl, 1));
};
exports.salvaRisposteForzateMIFID = salvaRisposteForzateMIFID;
const salvaIncoerenzaDaIgnorareMIFID = (coherence) => async (dispatch, getState, extraArguments) => {
    const mifid = getState().mifid;
    const questions = (0, mifid_selectors_1.mifidCurrentQuestions)(mifid);
    if (!questions) {
        console.error('Something unexpected happend!');
        return;
    }
    dispatch(salvaIncoerenzaDaIgnorare({
        coherence,
        questions
    }));
};
exports.salvaIncoerenzaDaIgnorareMIFID = salvaIncoerenzaDaIgnorareMIFID;
function salvaIncoerenzaDaIgnorare(payload) {
    return {
        type: mifid_types_1.EReduxMIFIDActionTypes.MIFID_SALVA_INCOERENZA_DA_IGNORARE,
        payload: payload
    };
}
function rimuoviIncoerenzaDaIgnorare(payload) {
    return {
        type: mifid_types_1.EReduxMIFIDActionTypes.MIFID_RIMUOVI_INCOERENZE_DA_IGNORARE,
        payload
    };
}
const modificaRispostaMIFID = (questionId) => async (dispatch, getState, extraArguments) => {
    const mifid = getState().mifid;
    const index = questionId
        ? mifid.historyAnswers.findIndex(h => {
            return h.find(a => a.question == questionId);
        })
        : -1;
    const backInHistoryTimes = index >= 0
        ? mifid.historyAnswers.length - index
        : 1;
    dispatch((0, exports.salvaRisposteMIFID)(undefined, backInHistoryTimes));
};
exports.modificaRispostaMIFID = modificaRispostaMIFID;
const goBackRisposteMIFID = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, exports.salvaRisposteMIFID)(undefined, 1));
};
exports.goBackRisposteMIFID = goBackRisposteMIFID;
function getMIFIDRapportiStart() {
    return {
        type: mifid_types_1.EReduxMIFIDActionTypes.MIFID_GET_RAPPORTI_START
    };
}
function getMIFIDRapportiSuccess(payload) {
    return {
        type: mifid_types_1.EReduxMIFIDActionTypes.MIFID_GET_RAPPORTI_SUCCESS,
        payload
    };
}
function setMIFIDFormAnswers(answersList) {
    return {
        type: mifid_types_1.EReduxMIFIDActionTypes.MIFID_FORM_SET_ANSWERS,
        payload: answersList
    };
}
exports.setMIFIDFormAnswers = setMIFIDFormAnswers;
function setMIFIDFormAnswerValue(answerValue) {
    return {
        type: mifid_types_1.EReduxMIFIDActionTypes.MIFID_FORM_SET_ANSWER_VALUE,
        payload: answerValue
    };
}
exports.setMIFIDFormAnswerValue = setMIFIDFormAnswerValue;
function setMIFIDHistoryAnswers(answersList) {
    return {
        type: mifid_types_1.EReduxMIFIDActionTypes.MIFID_HISTORY_SET_ANSWERS,
        payload: answersList
    };
}
exports.setMIFIDHistoryAnswers = setMIFIDHistoryAnswers;
function resetMIFIDFormAnswers() {
    return {
        type: mifid_types_1.EReduxMIFIDActionTypes.MIFID_FORM_RESET_ANSWERS
    };
}
exports.resetMIFIDFormAnswers = resetMIFIDFormAnswers;
function resetMIFIDForm() {
    return {
        type: mifid_types_1.EReduxMIFIDActionTypes.MIFID_FORM_RESET
    };
}
exports.resetMIFIDForm = resetMIFIDForm;
const getMIFIDRapporti = () => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getMIFIDRapportiStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/portafoglio_titoli_rapporti.json')).then(response => {
            dispatch(getMIFIDRapportiSuccess(response.map(api_rest_1.DatiIntestatarioDtoFromJSON)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.PrometeiaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .soggettiOperantiPerFinanza().then((response) => {
                dispatch(getMIFIDRapportiSuccess(response));
            }).catch(async (error) => {
                dispatch((0, general_actions_1.setError)(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getMIFIDRapporti = getMIFIDRapporti;
function resetMIFID() {
    return {
        type: mifid_types_1.EReduxMIFIDActionTypes.MIFID_RESET
    };
}
exports.resetMIFID = resetMIFID;
function resetMIFIDNoRapporti() {
    return {
        type: mifid_types_1.EReduxMIFIDActionTypes.MIFID_RESET_NO_RAPPORTI
    };
}
exports.resetMIFIDNoRapporti = resetMIFIDNoRapporti;
