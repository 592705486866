"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OperazioniOneClickInputDtoToJSON = exports.OperazioniOneClickInputDtoFromJSONTyped = exports.OperazioniOneClickInputDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function OperazioniOneClickInputDtoFromJSON(json) {
    return OperazioniOneClickInputDtoFromJSONTyped(json, false);
}
exports.OperazioniOneClickInputDtoFromJSON = OperazioniOneClickInputDtoFromJSON;
function OperazioniOneClickInputDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'numeroOperazioni': !runtime_1.exists(json, 'numeroOperazioni') ? undefined : json['numeroOperazioni'],
        'idContatto': !runtime_1.exists(json, 'idContatto') ? undefined : json['idContatto'],
        'tipoOperazione': !runtime_1.exists(json, 'tipoOperazione') ? undefined : json['tipoOperazione'],
        'paroleChiave': !runtime_1.exists(json, 'paroleChiave') ? undefined : json['paroleChiave'],
    };
}
exports.OperazioniOneClickInputDtoFromJSONTyped = OperazioniOneClickInputDtoFromJSONTyped;
function OperazioniOneClickInputDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'numeroOperazioni': value.numeroOperazioni,
        'idContatto': value.idContatto,
        'tipoOperazione': value.tipoOperazione,
        'paroleChiave': value.paroleChiave,
    };
}
exports.OperazioniOneClickInputDtoToJSON = OperazioniOneClickInputDtoToJSON;
