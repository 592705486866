"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setBonificoMyBankToken = exports.setBonificoMyBankSetRapportoSelezionato = exports.parametriBonificoMyBank = exports.annullaMyBank = exports.myBankResetFormAction = exports.setStepMyBank = exports.getInfoAutenticazioneMyBank = exports.autorizzaMyBank = exports.controlloMyBank = void 0;
const general_actions_1 = require("../general/general.actions");
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
/**controllo -START */
function controlloMyBankStart() {
    return {
        type: __1.EReduxActionTypes.MY_BANK_CONTROLLO_START
    };
}
function controlloMyBankSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.MY_BANK_CONTROLLO_SUCCESS,
        payload
    };
}
function generalError(value, showGenericModalError) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value, showGenericModalError));
    };
}
const controlloMyBank = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(controlloMyBankStart());
    if (extraArguments.mock) {
    }
    else {
        const controlloMyBankRequest = {
            controlloMyBankDto: (0, __1.buildControlloMyBankFilter)(getState().myBank)
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BonificoMyBankControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .controllaBonificoMyBank(controlloMyBankRequest).then((response) => {
                dispatch(controlloMyBankSuccess(response));
                // dispatch(setStepMyBank(1))
                dispatch((0, general_actions_1.setLoader)(false));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
                dispatch((0, general_actions_1.setLoader)(false));
            }).finally(() => {
                if (extraArguments.platform == 'mobile') {
                    dispatch((0, general_actions_1.setLoader)(false));
                }
            });
        });
    }
};
exports.controlloMyBank = controlloMyBank;
/**controllo - FINE  */
/**autorizza -START */
function autorizzaMyBankStart() {
    return {
        type: __1.EReduxActionTypes.MY_BANK_AUTORIZZA_START
    };
}
function autorizzaMyBankSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.MY_BANK_AUTORIZZA_SUCCESS,
        payload
    };
}
const autorizzaMyBank = (idDispositiva, credenzialeDispositiva) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(autorizzaMyBankStart());
    if (extraArguments.mock) {
    }
    else {
        const autorizzaMyBankRequest = {
            idDispositiva,
            credenzialeDispositivaDto: credenzialeDispositiva
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BonificoMyBankControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .autorizzaBonificoMyBank(autorizzaMyBankRequest).then((response) => {
                dispatch(autorizzaMyBankSuccess(response));
                dispatch((0, general_actions_1.setLoader)(false));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error), false));
                dispatch((0, general_actions_1.setLoader)(false));
            }).finally(() => {
                if (extraArguments.platform == 'mobile') {
                    dispatch((0, general_actions_1.setLoader)(false));
                }
            });
        });
    }
};
exports.autorizzaMyBank = autorizzaMyBank;
/**autorizza - FINE  */
/* Get info autenticazione - START */
function getInfoAutenticazioneMyBankStart() {
    return {
        type: __1.EReduxActionTypes.MY_BANK_GET_INFO_AUTENTICAZIONE_START
    };
}
function getInfoAutenticazioneMyBankSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.MY_BANK_GET_INFO_AUTENTICAZIONE_SUCCESS,
        payload
    };
}
const getInfoAutenticazioneMyBank = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getInfoAutenticazioneMyBankStart());
    if (extraArguments.mock) {
    }
    else {
        const getInfoAutenticazioneMyBankRequest = {
            idDispositiva,
            chiamataUscente,
            eseguiInit,
            offline: !!offline
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BonificoMyBankControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .informazioniAutenticazioneBonificoMyBank(getInfoAutenticazioneMyBankRequest).then((response) => {
                dispatch(getInfoAutenticazioneMyBankSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getInfoAutenticazioneMyBank = getInfoAutenticazioneMyBank;
/* Get info autenticazione - FINE */
/* Set form values - START */
function setStepMyBank(step) {
    return {
        type: __1.EReduxActionTypes.MY_BANK_SET_STEP,
        payload: step
    };
}
exports.setStepMyBank = setStepMyBank;
function myBankResetFormAction() {
    return {
        type: __1.EReduxActionTypes.MY_BANK_RESET
    };
}
exports.myBankResetFormAction = myBankResetFormAction;
/* Set form values - FINE */
/* Anulla  - START */
function annullaMyBankStart() {
    return {
        type: __1.EReduxActionTypes.MY_BANK_ANNULLA_START
    };
}
function annullaMyBankSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.MY_BANK_ANNULLA_SUCCESS,
        payload
    };
}
const annullaMyBank = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(annullaMyBankStart());
    if (extraArguments.mock) {
    }
    else {
        const annullaMyBankRequest = {
            token: (0, __1.buildjwtTokenMyBankFilter)(getState().myBank)
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BonificoMyBankControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .annullaMyBank(annullaMyBankRequest).then((response) => {
                dispatch(annullaMyBankSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.annullaMyBank = annullaMyBank;
/* Anulla  - FINE */
/** Parametri - START */
function parametriBonificoMyBankStart() {
    return {
        type: __1.EReduxActionTypes.MY_BANK_GET_PARAMETRI_START
    };
}
function parametriBonificoMyBankSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.MY_BANK_GET_PARAMETRI_SUCCESS,
        payload
    };
}
const parametriBonificoMyBank = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(parametriBonificoMyBankStart());
    if (extraArguments.mock) {
    }
    else {
        const parametriMyBankRequest = {
            token: (0, __1.buildjwtTokenMyBankFilter)(getState().myBank)
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BonificoMyBankControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .parametriBonificoMyBank(parametriMyBankRequest).then((response) => {
                dispatch(parametriBonificoMyBankSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.parametriBonificoMyBank = parametriBonificoMyBank;
/** Parametri - FINE */
//    Set rapporto selezionato
function setBonificoMyBankSetRapportoSelezionato(rapportoSelezionato) {
    return {
        type: __1.EReduxActionTypes.MY_BANK_RAPPORTO_SELEZIONATO,
        payload: rapportoSelezionato
    };
}
exports.setBonificoMyBankSetRapportoSelezionato = setBonificoMyBankSetRapportoSelezionato;
function setBonificoMyBankToken(jwtToken) {
    return {
        type: __1.EReduxActionTypes.MY_BANK_SET_My_BANK_TOKEN,
        payload: jwtToken
    };
}
exports.setBonificoMyBankToken = setBonificoMyBankToken;
