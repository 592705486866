"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificaMenuDtoToJSON = exports.NotificaMenuDtoFromJSONTyped = exports.NotificaMenuDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function NotificaMenuDtoFromJSON(json) {
    return NotificaMenuDtoFromJSONTyped(json, false);
}
exports.NotificaMenuDtoFromJSON = NotificaMenuDtoFromJSON;
function NotificaMenuDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'notifica': !runtime_1.exists(json, 'notifica') ? undefined : json['notifica'],
    };
}
exports.NotificaMenuDtoFromJSONTyped = NotificaMenuDtoFromJSONTyped;
function NotificaMenuDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'notifica': value.notifica,
    };
}
exports.NotificaMenuDtoToJSON = NotificaMenuDtoToJSON;
