"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LimitiOperativiDtoToJSON = exports.LimitiOperativiDtoFromJSONTyped = exports.LimitiOperativiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function LimitiOperativiDtoFromJSON(json) {
    return LimitiOperativiDtoFromJSONTyped(json, false);
}
exports.LimitiOperativiDtoFromJSON = LimitiOperativiDtoFromJSON;
function LimitiOperativiDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'maxGiornaliero': !runtime_1.exists(json, 'maxGiornaliero') ? undefined : json['maxGiornaliero'],
        'utilizzatoGiornaliero': !runtime_1.exists(json, 'utilizzatoGiornaliero') ? undefined : json['utilizzatoGiornaliero'],
        'residuoMaxGiornaliero': !runtime_1.exists(json, 'residuoMaxGiornaliero') ? undefined : json['residuoMaxGiornaliero'],
        'maxMensile': !runtime_1.exists(json, 'maxMensile') ? undefined : json['maxMensile'],
        'utilizzatoMensile': !runtime_1.exists(json, 'utilizzatoMensile') ? undefined : json['utilizzatoMensile'],
        'residuoMaxMensile': !runtime_1.exists(json, 'residuoMaxMensile') ? undefined : json['residuoMaxMensile'],
        'serviziSoggetti': !runtime_1.exists(json, 'serviziSoggetti') ? undefined : json['serviziSoggetti'],
    };
}
exports.LimitiOperativiDtoFromJSONTyped = LimitiOperativiDtoFromJSONTyped;
function LimitiOperativiDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'maxGiornaliero': value.maxGiornaliero,
        'utilizzatoGiornaliero': value.utilizzatoGiornaliero,
        'residuoMaxGiornaliero': value.residuoMaxGiornaliero,
        'maxMensile': value.maxMensile,
        'utilizzatoMensile': value.utilizzatoMensile,
        'residuoMaxMensile': value.residuoMaxMensile,
        'serviziSoggetti': value.serviziSoggetti,
    };
}
exports.LimitiOperativiDtoToJSON = LimitiOperativiDtoToJSON;
