"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParametriPrestitiIsiDispoDtoToJSON = exports.ParametriPrestitiIsiDispoDtoFromJSONTyped = exports.ParametriPrestitiIsiDispoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ParametriPrestitiIsiDispoDtoFromJSON(json) {
    return ParametriPrestitiIsiDispoDtoFromJSONTyped(json, false);
}
exports.ParametriPrestitiIsiDispoDtoFromJSON = ParametriPrestitiIsiDispoDtoFromJSON;
function ParametriPrestitiIsiDispoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'rapporti': !runtime_1.exists(json, 'rapporti') ? undefined : (json['rapporti'].map(_1.RapportoCompletoDtoFromJSON)),
        'parametri': !runtime_1.exists(json, 'parametri') ? undefined : json['parametri'],
        'labelNomeProdotto': !runtime_1.exists(json, 'labelNomeProdotto') ? undefined : json['labelNomeProdotto'],
        'labelValuta': !runtime_1.exists(json, 'labelValuta') ? undefined : json['labelValuta'],
        'labelDurata': !runtime_1.exists(json, 'labelDurata') ? undefined : json['labelDurata'],
        'selectScopo': !runtime_1.exists(json, 'selectScopo') ? undefined : json['selectScopo'],
        'selectStatoCivile': !runtime_1.exists(json, 'selectStatoCivile') ? undefined : json['selectStatoCivile'],
        'selectRegimePatrimoniale': !runtime_1.exists(json, 'selectRegimePatrimoniale') ? undefined : json['selectRegimePatrimoniale'],
        'selectSituazioneAbitativa': !runtime_1.exists(json, 'selectSituazioneAbitativa') ? undefined : json['selectSituazioneAbitativa'],
        'selectCategoriaProfessione': !runtime_1.exists(json, 'selectCategoriaProfessione') ? undefined : json['selectCategoriaProfessione'],
        'selectCategoriaInattivi': !runtime_1.exists(json, 'selectCategoriaInattivi') ? undefined : json['selectCategoriaInattivi'],
        'selectCategoriaDipendenti': !runtime_1.exists(json, 'selectCategoriaDipendenti') ? undefined : json['selectCategoriaDipendenti'],
        'selectCategoriaMilitari': !runtime_1.exists(json, 'selectCategoriaMilitari') ? undefined : json['selectCategoriaMilitari'],
        'selectCategoriaDocenti': !runtime_1.exists(json, 'selectCategoriaDocenti') ? undefined : json['selectCategoriaDocenti'],
        'selectCategoriaLiberiProfessionisti': !runtime_1.exists(json, 'selectCategoriaLiberiProfessionisti') ? undefined : json['selectCategoriaLiberiProfessionisti'],
        'selectCategoriaAutonomi': !runtime_1.exists(json, 'selectCategoriaAutonomi') ? undefined : json['selectCategoriaAutonomi'],
        'selectRapportoDiLavoro': !runtime_1.exists(json, 'selectRapportoDiLavoro') ? undefined : json['selectRapportoDiLavoro'],
        'domiciliazioneStipendioPressoBanca': !runtime_1.exists(json, 'domiciliazioneStipendioPressoBanca') ? undefined : json['domiciliazioneStipendioPressoBanca'],
        'selectTipoCartaDiCredito': !runtime_1.exists(json, 'selectTipoCartaDiCredito') ? undefined : json['selectTipoCartaDiCredito'],
        'allegati': !runtime_1.exists(json, 'allegati') ? undefined : (json['allegati'].map(_1.AllegatoPrestitoFromJSON)),
    };
}
exports.ParametriPrestitiIsiDispoDtoFromJSONTyped = ParametriPrestitiIsiDispoDtoFromJSONTyped;
function ParametriPrestitiIsiDispoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'rapporti': value.rapporti === undefined ? undefined : (value.rapporti.map(_1.RapportoCompletoDtoToJSON)),
        'parametri': value.parametri,
        'labelNomeProdotto': value.labelNomeProdotto,
        'labelValuta': value.labelValuta,
        'labelDurata': value.labelDurata,
        'selectScopo': value.selectScopo,
        'selectStatoCivile': value.selectStatoCivile,
        'selectRegimePatrimoniale': value.selectRegimePatrimoniale,
        'selectSituazioneAbitativa': value.selectSituazioneAbitativa,
        'selectCategoriaProfessione': value.selectCategoriaProfessione,
        'selectCategoriaInattivi': value.selectCategoriaInattivi,
        'selectCategoriaDipendenti': value.selectCategoriaDipendenti,
        'selectCategoriaMilitari': value.selectCategoriaMilitari,
        'selectCategoriaDocenti': value.selectCategoriaDocenti,
        'selectCategoriaLiberiProfessionisti': value.selectCategoriaLiberiProfessionisti,
        'selectCategoriaAutonomi': value.selectCategoriaAutonomi,
        'selectRapportoDiLavoro': value.selectRapportoDiLavoro,
        'domiciliazioneStipendioPressoBanca': value.domiciliazioneStipendioPressoBanca,
        'selectTipoCartaDiCredito': value.selectTipoCartaDiCredito,
        'allegati': value.allegati === undefined ? undefined : (value.allegati.map(_1.AllegatoPrestitoToJSON)),
    };
}
exports.ParametriPrestitiIsiDispoDtoToJSON = ParametriPrestitiIsiDispoDtoToJSON;
