"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.StrongAuthInfoPhoneInfoDtoToJSON = exports.StrongAuthInfoPhoneInfoDtoFromJSONTyped = exports.StrongAuthInfoPhoneInfoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function StrongAuthInfoPhoneInfoDtoFromJSON(json) {
    return StrongAuthInfoPhoneInfoDtoFromJSONTyped(json, false);
}
exports.StrongAuthInfoPhoneInfoDtoFromJSON = StrongAuthInfoPhoneInfoDtoFromJSON;
function StrongAuthInfoPhoneInfoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'authorizationCode': !runtime_1.exists(json, 'authorizationCode') ? undefined : json['authorizationCode'],
        'callingFromServer': !runtime_1.exists(json, 'callingFromServer') ? undefined : json['callingFromServer'],
        'countryCode': !runtime_1.exists(json, 'countryCode') ? undefined : json['countryCode'],
        'number': !runtime_1.exists(json, 'number') ? undefined : json['number'],
        'otp': !runtime_1.exists(json, 'otp') ? undefined : json['otp'],
        'qrcode': !runtime_1.exists(json, 'qrcode') ? undefined : _1.PhoneInfoQrcodeDtoFromJSON(json['qrcode']),
        'timestampExp': !runtime_1.exists(json, 'timestampExp') ? undefined : (new Date(json['timestampExp'])),
        'timestampStart': !runtime_1.exists(json, 'timestampStart') ? undefined : (new Date(json['timestampStart'])),
    };
}
exports.StrongAuthInfoPhoneInfoDtoFromJSONTyped = StrongAuthInfoPhoneInfoDtoFromJSONTyped;
function StrongAuthInfoPhoneInfoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'authorizationCode': value.authorizationCode,
        'callingFromServer': value.callingFromServer,
        'countryCode': value.countryCode,
        'number': value.number,
        'otp': value.otp,
        'qrcode': _1.PhoneInfoQrcodeDtoToJSON(value.qrcode),
        'timestampExp': value.timestampExp === undefined ? undefined : (value.timestampExp.toISOString()),
        'timestampStart': value.timestampStart === undefined ? undefined : (value.timestampStart.toISOString()),
    };
}
exports.StrongAuthInfoPhoneInfoDtoToJSON = StrongAuthInfoPhoneInfoDtoToJSON;
