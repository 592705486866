"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PieBarRowToJSON = exports.PieBarRowFromJSONTyped = exports.PieBarRowFromJSON = void 0;
const runtime_1 = require("../runtime");
function PieBarRowFromJSON(json) {
    return PieBarRowFromJSONTyped(json, false);
}
exports.PieBarRowFromJSON = PieBarRowFromJSON;
function PieBarRowFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'versatoPercentuale': !runtime_1.exists(json, 'versatoPercentuale') ? undefined : json['versatoPercentuale'],
        'versatoImporto': !runtime_1.exists(json, 'versatoImporto') ? undefined : json['versatoImporto'],
        'residuoPercentuale': !runtime_1.exists(json, 'residuoPercentuale') ? undefined : json['residuoPercentuale'],
        'residuoImporto': !runtime_1.exists(json, 'residuoImporto') ? undefined : json['residuoImporto'],
    };
}
exports.PieBarRowFromJSONTyped = PieBarRowFromJSONTyped;
function PieBarRowToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'versatoPercentuale': value.versatoPercentuale,
        'versatoImporto': value.versatoImporto,
        'residuoPercentuale': value.residuoPercentuale,
        'residuoImporto': value.residuoImporto,
    };
}
exports.PieBarRowToJSON = PieBarRowToJSON;
