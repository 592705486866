"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExtendedDataDtoToJSON = exports.ExtendedDataDtoFromJSONTyped = exports.ExtendedDataDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ExtendedDataDtoFromJSON(json) {
    return ExtendedDataDtoFromJSONTyped(json, false);
}
exports.ExtendedDataDtoFromJSON = ExtendedDataDtoFromJSON;
function ExtendedDataDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codiceTitolos': !runtime_1.exists(json, 'codiceTitolos') ? undefined : (json['codiceTitolos'].map(_1.CodiceTitoloDtoFromJSON)),
        'codiceMercatoes': !runtime_1.exists(json, 'codiceMercatoes') ? undefined : json['codiceMercatoes'],
        'codiceSettores': !runtime_1.exists(json, 'codiceSettores') ? undefined : json['codiceSettores'],
        'codiceTipos': !runtime_1.exists(json, 'codiceTipos') ? undefined : json['codiceTipos'],
    };
}
exports.ExtendedDataDtoFromJSONTyped = ExtendedDataDtoFromJSONTyped;
function ExtendedDataDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codiceTitolos': value.codiceTitolos === undefined ? undefined : (value.codiceTitolos.map(_1.CodiceTitoloDtoToJSON)),
        'codiceMercatoes': value.codiceMercatoes,
        'codiceSettores': value.codiceSettores,
        'codiceTipos': value.codiceTipos,
    };
}
exports.ExtendedDataDtoToJSON = ExtendedDataDtoToJSON;
