"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntestazioneDtoToJSON = exports.IntestazioneDtoFromJSONTyped = exports.IntestazioneDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function IntestazioneDtoFromJSON(json) {
    return IntestazioneDtoFromJSONTyped(json, false);
}
exports.IntestazioneDtoFromJSON = IntestazioneDtoFromJSON;
function IntestazioneDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'cognome': !runtime_1.exists(json, 'cognome') ? undefined : json['cognome'],
        'nome': !runtime_1.exists(json, 'nome') ? undefined : json['nome'],
        'attivita': !runtime_1.exists(json, 'attivita') ? undefined : json['attivita'],
    };
}
exports.IntestazioneDtoFromJSONTyped = IntestazioneDtoFromJSONTyped;
function IntestazioneDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'cognome': value.cognome,
        'nome': value.nome,
        'attivita': value.attivita,
    };
}
exports.IntestazioneDtoToJSON = IntestazioneDtoToJSON;
