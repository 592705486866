"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NegoziazioneContoTerziDtoToJSON = exports.NegoziazioneContoTerziDtoFromJSONTyped = exports.NegoziazioneContoTerziDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function NegoziazioneContoTerziDtoFromJSON(json) {
    return NegoziazioneContoTerziDtoFromJSONTyped(json, false);
}
exports.NegoziazioneContoTerziDtoFromJSON = NegoziazioneContoTerziDtoFromJSON;
function NegoziazioneContoTerziDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'itemDettaglioCosti': !runtime_1.exists(json, 'itemDettaglioCosti') ? undefined : json['itemDettaglioCosti'],
        'tabellaCosti': !runtime_1.exists(json, 'tabellaCosti') ? undefined : _1.TabellaCostiDtoFromJSON(json['tabellaCosti']),
        'tabellaDettaglioCosti': !runtime_1.exists(json, 'tabellaDettaglioCosti') ? undefined : _1.TabellaDettaglioCostiDtoFromJSON(json['tabellaDettaglioCosti']),
    };
}
exports.NegoziazioneContoTerziDtoFromJSONTyped = NegoziazioneContoTerziDtoFromJSONTyped;
function NegoziazioneContoTerziDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'itemDettaglioCosti': value.itemDettaglioCosti,
        'tabellaCosti': _1.TabellaCostiDtoToJSON(value.tabellaCosti),
        'tabellaDettaglioCosti': _1.TabellaDettaglioCostiDtoToJSON(value.tabellaDettaglioCosti),
    };
}
exports.NegoziazioneContoTerziDtoToJSON = NegoziazioneContoTerziDtoToJSON;
