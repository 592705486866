"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.timeDepositSingleList = exports.pianoCedoleTimeDeposit = exports.formRevocaTimeDeposit = exports.esitoRevocaTimeDeposit = exports.formValidTimeDeposit = exports.riepilogoRevocaTimeDeposit = exports.infoAutenticazioneRevocaTimeDeposit = exports.buildTimeDeposit = exports.listinoSelezionato = exports.infoAutenticazioneTimeDeposit = exports.riepilogoTimeDeposit = exports.esitoTimeDeposit = exports.formTimeDeposit = exports.listiniTimeDeposit = exports.filtriTimeDepositDirty = exports.timeDepositBoxFiltriApplicati = exports.timeDepositFiltriApplicati = exports.buildFilterTimeDeposit = exports.pdfDettaglioTimeDeposit = exports.excelElencoTimeDeposit = exports.pdfElencoTimeDeposit = exports.filtriTimeDeposit = exports.timeDepositSelezionato = exports.parametriTimeDepositBox = exports.parametriTimeDeposit = exports.timeDepositListInCorso = exports.timeDepositListInErrore = exports.timeDepositList = void 0;
const reselect_1 = require("reselect");
const moment = require("moment");
const timeDepositReducer = (state) => state;
exports.timeDepositList = (0, reselect_1.createSelector)(timeDepositReducer, (state) => {
    return state.timeDepositList.attivi;
});
exports.timeDepositListInErrore = (0, reselect_1.createSelector)(timeDepositReducer, (state) => {
    var _a;
    return ((_a = state.timeDepositList) === null || _a === void 0 ? void 0 : _a.inErrore) || [];
});
exports.timeDepositListInCorso = (0, reselect_1.createSelector)(timeDepositReducer, (state) => {
    var _a;
    return ((_a = state.timeDepositList) === null || _a === void 0 ? void 0 : _a.inCorso) || [];
});
exports.parametriTimeDeposit = (0, reselect_1.createSelector)(timeDepositReducer, (state) => {
    return state.parametri;
});
exports.parametriTimeDepositBox = (0, reselect_1.createSelector)(timeDepositReducer, (state) => {
    return state.parametriTimeDepositBox;
});
exports.timeDepositSelezionato = (0, reselect_1.createSelector)(timeDepositReducer, (state) => {
    return state.timeDepositSelezionato;
});
exports.filtriTimeDeposit = (0, reselect_1.createSelector)(timeDepositReducer, (state) => {
    return state.filter;
});
exports.pdfElencoTimeDeposit = (0, reselect_1.createSelector)(timeDepositReducer, (state) => {
    return state.pdfElencoTimeDeposit;
});
exports.excelElencoTimeDeposit = (0, reselect_1.createSelector)(timeDepositReducer, (state) => {
    return state.excelElencoTimeDeposit;
});
exports.pdfDettaglioTimeDeposit = (0, reselect_1.createSelector)(timeDepositReducer, (state) => {
    return state.pdfDettaglioTimeDeposit;
});
exports.buildFilterTimeDeposit = (0, reselect_1.createSelector)(timeDepositReducer, (state) => {
    var _a, _b, _c, _d, _e, _f, _g;
    let filter = {
        codiceRapporto: (_c = (_b = (_a = state.filter.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.id) !== null && _c !== void 0 ? _c : 0,
        dataDecorrenzaDa: state.filter.dataDecorrenzaDa ? state.filter.dataDecorrenzaDa :
            ((_d = state === null || state === void 0 ? void 0 : state.parametri) === null || _d === void 0 ? void 0 : _d.parametri) ? new Date(state.parametri.parametri['DEFAULT.TIMEDEPOSITINFO.DATA_INIZIALE_DECORRENZA'].toString()) : new Date(),
        dataDecorrenzaA: state.filter.dataDecorrenzaA ? state.filter.dataDecorrenzaA :
            ((_e = state === null || state === void 0 ? void 0 : state.parametri) === null || _e === void 0 ? void 0 : _e.parametri) ? new Date(state.parametri.parametri['DEFAULT.TIMEDEPOSITINFO.DATA_FINALE_DECORRENZA'].toString()) : new Date(),
        dataScadenzaDa: state.filter.dataScadenzaDa ? state.filter.dataScadenzaDa :
            ((_f = state === null || state === void 0 ? void 0 : state.parametri) === null || _f === void 0 ? void 0 : _f.parametri) ? new Date(state.parametri.parametri['DEFAULT.TIMEDEPOSITINFO.DATA_INIZIALE_SCADENZA'].toString()) : new Date(),
        dataScadenzaA: state.filter.dataScadenzaA ? state.filter.dataScadenzaA :
            ((_g = state === null || state === void 0 ? void 0 : state.parametri) === null || _g === void 0 ? void 0 : _g.parametri) ? new Date(state.parametri.parametri['DEFAULT.TIMEDEPOSITINFO.DATA_FINALE_SCADENZA'].toString()) : new Date(),
        stato: state.filter.stato
    };
    return filter;
});
exports.timeDepositFiltriApplicati = (0, reselect_1.createSelector)(timeDepositReducer, (state) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    let r = true;
    if ((moment(state.filter.dataScadenzaDa).isSame(moment((_b = (_a = state.parametri) === null || _a === void 0 ? void 0 : _a.parametri) === null || _b === void 0 ? void 0 : _b["DEFAULT.TIMEDEPOSITINFO.DATA_INIZIALE_SCADENZA"], 'YYYY-MM-DD'), 'day')) &&
        (moment(state.filter.dataScadenzaA).isSame(moment((_d = (_c = state.parametri) === null || _c === void 0 ? void 0 : _c.parametri) === null || _d === void 0 ? void 0 : _d["DEFAULT.TIMEDEPOSITINFO.DATA_FINALE_SCADENZA"], 'YYYY-MM-DD'), 'day')) &&
        (moment(state.filter.dataDecorrenzaDa).isSame(moment((_f = (_e = state.parametri) === null || _e === void 0 ? void 0 : _e.parametri) === null || _f === void 0 ? void 0 : _f["DEFAULT.TIMEDEPOSITINFO.DATA_INIZIALE_DECORRENZA"], 'YYYY-MM-DD'), 'day')) &&
        (moment(state.filter.dataDecorrenzaA).isSame(moment((_h = (_g = state.parametri) === null || _g === void 0 ? void 0 : _g.parametri) === null || _h === void 0 ? void 0 : _h["DEFAULT.TIMEDEPOSITINFO.DATA_FINALE_DECORRENZA"], 'YYYY-MM-DD'), 'day'))) {
        r = false;
    }
    return r;
});
exports.timeDepositBoxFiltriApplicati = (0, reselect_1.createSelector)(timeDepositReducer, (state) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    let r = true;
    if ((moment(state.filter.dataScadenzaDa).isSame(moment((_b = (_a = state.parametriTimeDepositBox) === null || _a === void 0 ? void 0 : _a.parametri) === null || _b === void 0 ? void 0 : _b["DEFAULT.TIMEDEPOSITINFO.DATA_INIZIALE_SCADENZA"], 'YYYY-MM-DD'), 'day')) &&
        (moment(state.filter.dataScadenzaA).isSame(moment((_d = (_c = state.parametriTimeDepositBox) === null || _c === void 0 ? void 0 : _c.parametri) === null || _d === void 0 ? void 0 : _d["DEFAULT.TIMEDEPOSITINFO.DATA_FINALE_SCADENZA"], 'YYYY-MM-DD'), 'day')) &&
        (moment(state.filter.dataDecorrenzaDa).isSame(moment((_f = (_e = state.parametriTimeDepositBox) === null || _e === void 0 ? void 0 : _e.parametri) === null || _f === void 0 ? void 0 : _f["DEFAULT.TIMEDEPOSITINFO.DATA_INIZIALE_DECORRENZA"], 'YYYY-MM-DD'), 'day')) &&
        (moment(state.filter.dataDecorrenzaA).isSame(moment((_h = (_g = state.parametriTimeDepositBox) === null || _g === void 0 ? void 0 : _g.parametri) === null || _h === void 0 ? void 0 : _h["DEFAULT.TIMEDEPOSITINFO.DATA_FINALE_DECORRENZA"], 'YYYY-MM-DD'), 'day'))) {
        r = false;
    }
    return r;
});
exports.filtriTimeDepositDirty = (0, reselect_1.createSelector)(timeDepositReducer, (state) => {
    return !!state.filter.formDirty;
});
exports.listiniTimeDeposit = (0, reselect_1.createSelector)(timeDepositReducer, (state) => {
    return state.listiniTimeDeposit;
});
exports.formTimeDeposit = (0, reselect_1.createSelector)(timeDepositReducer, (state) => {
    return state.form;
});
exports.esitoTimeDeposit = (0, reselect_1.createSelector)(timeDepositReducer, (state) => {
    return state.esito;
});
exports.riepilogoTimeDeposit = (0, reselect_1.createSelector)(timeDepositReducer, (state) => {
    return state.riepilogo;
});
exports.infoAutenticazioneTimeDeposit = (0, reselect_1.createSelector)(timeDepositReducer, (state) => {
    return state.infoAutenticazione;
});
exports.listinoSelezionato = (0, reselect_1.createSelector)(timeDepositReducer, (state) => {
    return state.form.listinoSelezionato;
});
exports.buildTimeDeposit = (0, reselect_1.createSelector)(timeDepositReducer, (state) => {
    var _a, _b, _c, _d;
    const timeDeposit = {
        contoAddebito: (_c = (_b = (_a = state.form.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.id) !== null && _c !== void 0 ? _c : 0,
        importo: state.form.importoDeposito,
        listino: (_d = state.form.listinoSelezionato) === null || _d === void 0 ? void 0 : _d.codiceListino
    };
    return timeDeposit;
});
exports.infoAutenticazioneRevocaTimeDeposit = (0, reselect_1.createSelector)(timeDepositReducer, (state) => {
    return state.infoAutenticazioneRevoca;
});
exports.riepilogoRevocaTimeDeposit = (0, reselect_1.createSelector)(timeDepositReducer, (state) => {
    return state.riepilogoRevoca;
});
exports.formValidTimeDeposit = (0, reselect_1.createSelector)(timeDepositReducer, (state) => {
    return (!!state.form.listinoSelezionato && !!state.form.importoDeposito && state.form.importoDepositoError === '');
});
exports.esitoRevocaTimeDeposit = (0, reselect_1.createSelector)(timeDepositReducer, (state) => {
    return state.esitoRevoca;
});
exports.formRevocaTimeDeposit = (0, reselect_1.createSelector)(timeDepositReducer, (state) => {
    return state.formRevoca;
});
exports.pianoCedoleTimeDeposit = (0, reselect_1.createSelector)(timeDepositReducer, (state) => {
    return state.pianoCedole;
});
exports.timeDepositSingleList = (0, reselect_1.createSelector)(timeDepositReducer, (state) => {
    return state.timeDepositSingleList;
});
