"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnagrafeControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class AnagrafeControllerApi extends runtime.BaseAPI {
    /**
     * Autorizzazione di una disposizione di una modifica dati aggiuntivi
     */
    autorizzaDatiAggiuntiviRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling autorizzaDatiAggiuntivi.');
            }
            if (requestParameters.credenzialeDispositivaDto === null || requestParameters.credenzialeDispositivaDto === undefined) {
                throw new runtime.RequiredError('credenzialeDispositivaDto', 'Required parameter requestParameters.credenzialeDispositivaDto was null or undefined when calling autorizzaDatiAggiuntivi.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/anagrafe/autorizza/{idDispositiva}`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CredenzialeDispositivaDtoToJSON(requestParameters.credenzialeDispositivaDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.EsitoAutorizzazioneDisposizioneDatiAggiuntiviDtoFromJSON(jsonValue));
        });
    }
    /**
     * Autorizzazione di una disposizione di una modifica dati aggiuntivi
     */
    autorizzaDatiAggiuntivi(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.autorizzaDatiAggiuntiviRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce la lista dei dati aggiuntivi identificati dai codici nell\'oggetto di richiesta.
     */
    datiAggiuntiviRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.letturaDatiAggiuntiviRichiestaDto === null || requestParameters.letturaDatiAggiuntiviRichiestaDto === undefined) {
                throw new runtime.RequiredError('letturaDatiAggiuntiviRichiestaDto', 'Required parameter requestParameters.letturaDatiAggiuntiviRichiestaDto was null or undefined when calling datiAggiuntivi.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/anagrafe/datiaggiuntivi`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.LetturaDatiAggiuntiviRichiestaDtoToJSON(requestParameters.letturaDatiAggiuntiviRichiestaDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.DatiAggiuntiviRispostaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce la lista dei dati aggiuntivi identificati dai codici nell\'oggetto di richiesta.
     */
    datiAggiuntivi(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.datiAggiuntiviRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce tutti i dati anagrafici relativi all\'utente
     */
    datiAnagraficiRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.mask !== undefined) {
                queryParameters['mask'] = requestParameters.mask;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/anagrafe/dati`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.DatiAnagraficiRispostaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce tutti i dati anagrafici relativi all\'utente
     */
    datiAnagrafici(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.datiAnagraficiRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva
     */
    informazioniAutenticazioneDatiAggiuntiviRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling informazioniAutenticazioneDatiAggiuntivi.');
            }
            const queryParameters = {};
            if (requestParameters.chiamataUscente !== undefined) {
                queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
            }
            if (requestParameters.eseguiInit !== undefined) {
                queryParameters['eseguiInit'] = requestParameters.eseguiInit;
            }
            if (requestParameters.offline !== undefined) {
                queryParameters['offline'] = requestParameters.offline;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/anagrafe/infoautenticazione/{idDispositiva}`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva
     */
    informazioniAutenticazioneDatiAggiuntivi(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.informazioniAutenticazioneDatiAggiuntiviRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce la lista dei dati aggiuntivi identificati dai codici nell\'oggetto di richiesta.
     */
    modificaDatiAggiuntiviRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.aggiornaDatiAggiuntiviRichiestaDto === null || requestParameters.aggiornaDatiAggiuntiviRichiestaDto === undefined) {
                throw new runtime.RequiredError('aggiornaDatiAggiuntiviRichiestaDto', 'Required parameter requestParameters.aggiornaDatiAggiuntiviRichiestaDto was null or undefined when calling modificaDatiAggiuntivi.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/anagrafe/modificadatiaggiuntivi`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.AggiornaDatiAggiuntiviRichiestaDtoToJSON(requestParameters.aggiornaDatiAggiuntiviRichiestaDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.EsitoOperazioniAnagrafeDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce la lista dei dati aggiuntivi identificati dai codici nell\'oggetto di richiesta.
     */
    modificaDatiAggiuntivi(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.modificaDatiAggiuntiviRaw(requestParameters);
            return yield response.value();
        });
    }
}
exports.AnagrafeControllerApi = AnagrafeControllerApi;
