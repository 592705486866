"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.widgetSaldo = exports.widgetsUtente = exports.parametriWidget = void 0;
const reselect_1 = require("reselect");
const widgetsReducer = (state) => state;
exports.parametriWidget = (0, reselect_1.createSelector)(widgetsReducer, (state) => {
    return state.parametriWidget;
});
exports.widgetsUtente = (0, reselect_1.createSelector)(widgetsReducer, (state) => {
    return state.widgetsUtente;
});
exports.widgetSaldo = (0, reselect_1.createSelector)(widgetsReducer, (state) => {
    return state.widgetSaldo;
});
