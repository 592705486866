"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shouldUpdateBozzaRicaricaCellulare = exports.getOneClickRicaricaCellulare = exports.updateBozzaRicaricaCellulare = exports.getRicaricaCellulareFromBozza = exports.delBozzaRicaricaCellulare = exports.addBozzaRicaricaCellulare = exports.getBozzaRicaricaCellulare = exports.getBozzeRicaricaCellulare = exports.getBozzeRicaricaCellulareStart = exports.setRicaricaCellulareBozzaSalvata = exports.getTagliGestoreRicaricaCellulare = exports.getGestoriRicaricaCellulare = exports.autorizzaRicaricaCellulare = exports.controlloRicaricaCellulare = exports.getInfoDispositivaRicaricaCellulare = exports.getParametriRicaricaCellulare = exports.resetFormRicaricaCellulare = exports.resetCurrentBozzaAndFormRIcaricaCellulare = exports.resetRicaricaCellulareTaglioRicarica = exports.setRicaricaAvantiValidation = exports.setRicaricaSalvaBozzaValidation = exports.resetRiepilogoRicaricaCellulare = exports.setRicaricaCellulareSetCodiceRapporto = exports.setRicaricaCellulareSetSelectedContatto = exports.setRicaricaCellulareSetRapportoSelezionato = exports.resetRicaricaCellulare = exports.setRicaricaCellulareSetFormSubmitted = exports.setRicaricaCellulareSetAliasOneClick = exports.setRicaricaCellulareSetSalvaOneCLick = exports.setRicaricaCellulareSetStep = exports.setRicaricaCellulareSetTaglioRicarica = exports.setRicaricaCellulareSetCodiceSia = exports.setRicaricaCellulareSetNomeGestore = exports.setRicaricaCellulareSetAggiungiBeneficiarioARubrica = exports.setRicaricaCellulareSetBeneficiarioAffidabile = exports.setRicaricaCellulareIdContattoBeneficiario = exports.setRicaricaCellulareSetNomeBeneficiario = exports.setRicaricaCellulareSetNumeroCellulare = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const general_types_1 = require("../general/general.types");
const helpers_1 = require("../helpers");
const ricaricaCellulare_selectors_1 = require("./ricaricaCellulare.selectors");
const moment = require("moment");
/* set form values - START */
function setRicaricaCellulareSetNumeroCellulare(value) {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_NUMERO_CELLULARE,
        payload: value
    };
}
exports.setRicaricaCellulareSetNumeroCellulare = setRicaricaCellulareSetNumeroCellulare;
function setRicaricaCellulareSetNomeBeneficiario(value) {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_NOME_BENEFICIARIO,
        payload: value
    };
}
exports.setRicaricaCellulareSetNomeBeneficiario = setRicaricaCellulareSetNomeBeneficiario;
function setRicaricaCellulareIdContattoBeneficiario(value) {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_SET_ID_CONTATTO_BENEFICIARIO,
        payload: value
    };
}
exports.setRicaricaCellulareIdContattoBeneficiario = setRicaricaCellulareIdContattoBeneficiario;
function setRicaricaCellulareSetBeneficiarioAffidabile(value) {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_BENEFICIARIO_AFFIDABILE,
        payload: value
    };
}
exports.setRicaricaCellulareSetBeneficiarioAffidabile = setRicaricaCellulareSetBeneficiarioAffidabile;
function setRicaricaCellulareSetAggiungiBeneficiarioARubrica(value) {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_AGGIUNGI_BENEFICIARIO_RUBRICA,
        payload: value
    };
}
exports.setRicaricaCellulareSetAggiungiBeneficiarioARubrica = setRicaricaCellulareSetAggiungiBeneficiarioARubrica;
function setRicaricaCellulareSetNomeGestore(value) {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_NOME_GESTORE,
        payload: value
    };
}
exports.setRicaricaCellulareSetNomeGestore = setRicaricaCellulareSetNomeGestore;
function setRicaricaCellulareSetCodiceSia(value) {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_CODICE_SIA,
        payload: value
    };
}
exports.setRicaricaCellulareSetCodiceSia = setRicaricaCellulareSetCodiceSia;
function setRicaricaCellulareSetTaglioRicarica(value) {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_TAGLIO_RICARICA,
        payload: value
    };
}
exports.setRicaricaCellulareSetTaglioRicarica = setRicaricaCellulareSetTaglioRicarica;
function setRicaricaCellulareSetStep(value) {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_STEP,
        payload: value
    };
}
exports.setRicaricaCellulareSetStep = setRicaricaCellulareSetStep;
function setRicaricaCellulareSetSalvaOneCLick(value) {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_SALVA_1CLICK,
        payload: value
    };
}
exports.setRicaricaCellulareSetSalvaOneCLick = setRicaricaCellulareSetSalvaOneCLick;
function setRicaricaCellulareSetAliasOneClick(value) {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_ALIAS_1CLICK,
        payload: value
    };
}
exports.setRicaricaCellulareSetAliasOneClick = setRicaricaCellulareSetAliasOneClick;
function setRicaricaCellulareSetFormSubmitted(value) {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_FORM_SUBMITTED,
        payload: value
    };
}
exports.setRicaricaCellulareSetFormSubmitted = setRicaricaCellulareSetFormSubmitted;
function resetRicaricaCellulare() {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_RESET
    };
}
exports.resetRicaricaCellulare = resetRicaricaCellulare;
function setRicaricaCellulareSetRapportoSelezionato(rapportoSelezionato) {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_SET_RAPPORTO_SELEZIONATO,
        payload: rapportoSelezionato
    };
}
exports.setRicaricaCellulareSetRapportoSelezionato = setRicaricaCellulareSetRapportoSelezionato;
function setRicaricaCellulareSetSelectedContatto(contato) {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_SET_CONTATTO_SELEZIONATO,
        payload: contato
    };
}
exports.setRicaricaCellulareSetSelectedContatto = setRicaricaCellulareSetSelectedContatto;
function setRicaricaCellulareSetCodiceRapporto(value) {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_CODICE_RAPPORTO,
        payload: value
    };
}
exports.setRicaricaCellulareSetCodiceRapporto = setRicaricaCellulareSetCodiceRapporto;
function resetRiepilogoRicaricaCellulare() {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_RESET_RIEPILOGO
    };
}
exports.resetRiepilogoRicaricaCellulare = resetRiepilogoRicaricaCellulare;
function setRicaricaSalvaBozzaValidation() {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_SET_SALVA_BOZZA_VALIDATION
    };
}
exports.setRicaricaSalvaBozzaValidation = setRicaricaSalvaBozzaValidation;
function setRicaricaAvantiValidation(payload) {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_SET_AVANTI_VALIDATION,
        payload: payload
    };
}
exports.setRicaricaAvantiValidation = setRicaricaAvantiValidation;
function resetRicaricaCellulareTaglioRicarica() {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_RESET_TAGLIO_RICARICA
    };
}
exports.resetRicaricaCellulareTaglioRicarica = resetRicaricaCellulareTaglioRicarica;
function resetCurrentBozzaAndFormRIcaricaCellulare() {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_RESET_CURRENT_BOZZA_AND_FORM
    };
}
exports.resetCurrentBozzaAndFormRIcaricaCellulare = resetCurrentBozzaAndFormRIcaricaCellulare;
function resetFormRicaricaCellulare() {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_RESET_FORM
    };
}
exports.resetFormRicaricaCellulare = resetFormRicaricaCellulare;
/* set form values - FINE */
/* get parametri - START */
function getParametriRicaricaCellulareStart() {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_GET_PARAMETRI_START
    };
}
function getParametriRicaricaCellulareSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_GET_PARAMETRI_SUCCESS,
        payload
    };
}
function getParametriPerRipetiRicaricaCellulareSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_GET_PARAMETRI_PER_RIPETI_SUCCESS,
        payload
    };
}
function generalError(value, showGenericModalError, sendErrorToMatomo) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value, showGenericModalError));
        !!sendErrorToMatomo && sendErrorToMatomo('RICARICA_CELLULARE', `code_error RICARICA_CELLULARE ${value.status} / ${value === null || value === void 0 ? void 0 : value.message} / ${value === null || value === void 0 ? void 0 : value.timestamp}`, 'ESITO_DISPOSITIVA');
    };
}
const getParametriRicaricaCellulare = (ripeti) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getParametriRicaricaCellulareStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/parametri_bonifico.json')).then(parametri => {
            dispatch(ripeti ? getParametriPerRipetiRicaricaCellulareSuccess((0, api_rest_1.ParametriDtoFromJSON)(parametri)) : getParametriRicaricaCellulareSuccess((0, api_rest_1.ParametriDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.RicaricaCellulareControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametersRicaricaCellulare().then((response) => {
                var _a;
                dispatch(ripeti ? getParametriPerRipetiRicaricaCellulareSuccess(response) : getParametriRicaricaCellulareSuccess(response));
                ((_a = response.rapporti) === null || _a === void 0 ? void 0 : _a.length) === 0 && dispatch((0, general_actions_1.setNoRapporti)({ type: general_types_1.TypeNoRapporti.RICARICA_TELEFONICA }));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getParametriRicaricaCellulare = getParametriRicaricaCellulare;
/* get parametri - FINE */
/* get info dispositiva - START */
function getInfoDispositivaRicaricaCellulareStart() {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_GET_INFO_DISPOSITIVA_START
    };
}
function getInfoDispositivaRicaricaCellulareSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_GET_INFO_DISPOSITIVA_SUCCESS,
        payload
    };
}
const getInfoDispositivaRicaricaCellulare = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getInfoDispositivaRicaricaCellulareStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/autenticazione-ricarica-cellulare.json')).then(parametri => {
            dispatch(getInfoDispositivaRicaricaCellulareSuccess((0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const getInfoDispositivaRicaricaCellulareRequest = {
            idDispositiva,
            chiamataUscente,
            eseguiInit,
            offline: !!offline
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.RicaricaCellulareControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).authenticationInformationRicaricaCellulare(getInfoDispositivaRicaricaCellulareRequest).then((response) => {
                dispatch(getInfoDispositivaRicaricaCellulareSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getInfoDispositivaRicaricaCellulare = getInfoDispositivaRicaricaCellulare;
/* get info dispositiva - FINE */
/* controllo - START */
function controlloRicaricaCellulareStart() {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_CONTROLLO_START
    };
}
function controlloRicaricaCellulareSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_CONTROLLO_SUCCESS,
        payload
    };
}
const controlloRicaricaCellulare = () => async (dispatch, getState, extraArguments) => {
    const ricaricaCellulare = getState().ricaricaCellulare;
    if ((0, ricaricaCellulare_selectors_1.formValidRicaricaCellulare)(ricaricaCellulare)) {
        dispatch((0, general_actions_1.setLoader)(true));
        dispatch(controlloRicaricaCellulareStart());
        if (extraArguments.mock) {
            Promise.resolve().then(() => require('../../mock/controllo_ricarica_cellulare.json')).then(parametri => {
                dispatch(controlloRicaricaCellulareSuccess((0, api_rest_1.RicaricaCellulareDtoFromJSON)(parametri)));
                dispatch((0, general_actions_1.setLoader)(false));
            });
        }
        else {
            const controlloRicaricaCellulareRequest = {
                ricaricaCellulareDto: {
                    ...(0, ricaricaCellulare_selectors_1.buildRicaricaCellulare)(ricaricaCellulare),
                    bozza: {
                        dataUltimaModifica: moment().toDate()
                    }
                }
            };
            extraArguments.getItem('jwt').then((jwt) => {
                new api_rest_1.RicaricaCellulareControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).checkRicaricaCellulare(controlloRicaricaCellulareRequest).then((response) => {
                    dispatch(controlloRicaricaCellulareSuccess(response));
                    dispatch((0, general_actions_1.setLoader)(false));
                }).catch(async (error) => {
                    dispatch(generalError(await (0, helpers_1.formatError)(error)));
                }).finally(() => {
                    dispatch((0, general_actions_1.setLoader)(false));
                });
            });
        }
    }
};
exports.controlloRicaricaCellulare = controlloRicaricaCellulare;
/* controllo - FINE */
/* autorizza - START */
function autorizzaRicaricaCellulareStart() {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_AUTORIZZA_START
    };
}
function autorizzaRicaricaCellulareSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_AUTORIZZA_SUCCESS,
        payload
    };
}
const autorizzaRicaricaCellulare = (idDispositiva, credenziale, ricaricaCellulare) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(autorizzaRicaricaCellulareStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/autorizza_ricarica_cellulare.json')).then(parametri => {
            dispatch(autorizzaRicaricaCellulareSuccess((0, api_rest_1.EsitoAutorizzazioneDisposizioneRicaricaCellulareDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const getInfoDispositivaRicaricaCellulareRequest = {
            idDispositiva,
            credenzialeDispositivaDto: credenziale
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.RicaricaCellulareControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).authorizeRicaricaCellulare(getInfoDispositivaRicaricaCellulareRequest).then((response) => {
                dispatch(autorizzaRicaricaCellulareSuccess(response));
                extraArguments.sendEventToMatomo('RICARICA_CELLULARE', `esito dispositiva RICARICA_CELLULARE ${response.stato}`, 'ESITO_DISPOSITIVA');
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error), false, extraArguments.sendEventToMatomo));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.autorizzaRicaricaCellulare = autorizzaRicaricaCellulare;
/* autorizza - FINE */
/* get gestori - START */
function getGestoriRicaricaCellulareStart() {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_GET_GESTORI_START
    };
}
function getGestoriRicaricaCellulareSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_GET_GESTORI_SUCCESS,
        payload
    };
}
const getGestoriRicaricaCellulare = () => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getGestoriRicaricaCellulareStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/gestori_ricarica_cellulare.json')).then(parametri => {
            dispatch(getGestoriRicaricaCellulareSuccess(parametri));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.RicaricaCellulareControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).retrieveElencoGestoriRicaricaCellulare().then((response) => {
                dispatch(getGestoriRicaricaCellulareSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getGestoriRicaricaCellulare = getGestoriRicaricaCellulare;
/* get gestori - FINE */
/* get tagli del gestore - START */
function getTagliGestoreRicaricaCellulareStart() {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_GET_TAGLI_GESTORE_START
    };
}
function getTagliGestoreRicaricaCellulareSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_GET_TAGLI_GESTORE_SUCCESS,
        payload
    };
}
const getTagliGestoreRicaricaCellulare = (filtroTagliRicarica) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getTagliGestoreRicaricaCellulareStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/tagli_ricarica_cellulare.json')).then(parametri => {
            dispatch(getTagliGestoreRicaricaCellulareSuccess((0, api_rest_1.TagliRicaricaCellulareDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const getTagliRicaricaByGestoreTelefonicoRicaricaCellulareRequest = {
            filtroTagliRicaricaDto: filtroTagliRicarica
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.RicaricaCellulareControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).retrieveTagliRicaricaByGestoreTelefonicoRicaricaCellulare(getTagliRicaricaByGestoreTelefonicoRicaricaCellulareRequest).then((response) => {
                dispatch(getTagliGestoreRicaricaCellulareSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getTagliGestoreRicaricaCellulare = getTagliGestoreRicaricaCellulare;
/* get tagli del gestore - FINE */
/** Bozze section */
function setRicaricaCellulareBozzaSalvata(b) {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_SET_BOZZASALVATA,
        payload: b
    };
}
exports.setRicaricaCellulareBozzaSalvata = setRicaricaCellulareBozzaSalvata;
/*  get bozze Ricarica Cellulare - START */
function getBozzeRicaricaCellulareStart() {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_GET_BOZZE_START
    };
}
exports.getBozzeRicaricaCellulareStart = getBozzeRicaricaCellulareStart;
function getBozzeRicaricaCellulareSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_GET_BOZZE_SUCCESS,
        payload
    };
}
const getBozzeRicaricaCellulare = (request) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getBozzeRicaricaCellulareStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/bozze_mav.json')).then(bozze => {
            dispatch(getBozzeRicaricaCellulareSuccess([]));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const getBozzeRicaricaCellulareRequest = {
            bozzeInDto: request
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.RicaricaCellulareControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getDraftListRicaricaCellulare(getBozzeRicaricaCellulareRequest).then((response) => {
                dispatch(getBozzeRicaricaCellulareSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getBozzeRicaricaCellulare = getBozzeRicaricaCellulare;
/** get bozze Ricarica Cellulare -FINE- */
/* get bozza Ricarica Cellulare - START */
function getBozzaRicaricaCellulareStart() {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_GET_BOZZA_START
    };
}
function getBozzaRicaricaCellulareSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_GET_BOZZA_SUCCESS,
        payload
    };
}
const getBozzaRicaricaCellulare = (id) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getBozzaRicaricaCellulareStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/bozza_mav.json')).then(bozza => {
            dispatch(getBozzaRicaricaCellulareSuccess((0, api_rest_1.RicaricaCellulareDtoFromJSON)(bozza)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const getBozzaRicaricaCellulareRequest = {
            id
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.RicaricaCellulareControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getDraftByIdRicaricaCellulare(getBozzaRicaricaCellulareRequest).then((response) => {
                dispatch(getBozzaRicaricaCellulareSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getBozzaRicaricaCellulare = getBozzaRicaricaCellulare;
/* get bozza Ricarica Cellulare -  FINE */
/* add bozza Ricarica Cellulare - START */
function addBozzaRicaricaCellulareStart() {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_ADD_BOZZA_START
    };
}
function addBozzaRicaricaCellulareSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_ADD_BOZZA_SUCCESS,
        payload
    };
}
const addBozzaRicaricaCellulare = () => async (dispatch, getState, extraArguments) => {
    const ricaricaCellulare = getState().ricaricaCellulare;
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(addBozzaRicaricaCellulareStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/bozza_mav_response.json')).then(bozza => {
            dispatch(addBozzaRicaricaCellulareSuccess((0, api_rest_1.RicaricaCellulareDtoFromJSON)(bozza)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const addBozzaRicaricaCellulareRequest = {
            ricaricaCellulareDto: {
                ...(0, ricaricaCellulare_selectors_1.buildRicaricaCellulare)(ricaricaCellulare),
                bozza: {
                    dataUltimaModifica: moment().toDate()
                }
            }
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.RicaricaCellulareControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).saveDraftRicaricaCellulare(addBozzaRicaricaCellulareRequest).then((response) => {
                dispatch(addBozzaRicaricaCellulareSuccess(response));
                dispatch((0, general_actions_1.addSnackbar)('ricaricaCellulare.nuovaBozzaRicaricaCellulareSalvata'));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.addBozzaRicaricaCellulare = addBozzaRicaricaCellulare;
/* add bozza Ricarica Cellulare - FINE */
/* del bozza Ricarica Cellulare - START */
function delBozzaRicaricaCellulareStart() {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_DEL_BOZZA_START
    };
}
function delBozzaRicaricaCellulareSuccess(id) {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_DEL_BOZZA_SUCCESS,
        payload: id
    };
}
const delBozzaRicaricaCellulare = (id) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(delBozzaRicaricaCellulareStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/bozza_mav.json')).then(bozza => {
            dispatch(delBozzaRicaricaCellulareSuccess(id));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const delBozzaRicaricaCellulareRequest = {
            id
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.RicaricaCellulareControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).deleteDraftRicaricaCellulare(delBozzaRicaricaCellulareRequest).then(() => {
                dispatch(delBozzaRicaricaCellulareSuccess(id));
                dispatch((0, general_actions_1.addSnackbar)('ricaricaCellulare.bozzaEliminataConSuccesso'));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.delBozzaRicaricaCellulare = delBozzaRicaricaCellulare;
/* del bozza Ricarica Cellulare - FINE */
/** get Ricarica Cellulare from  bozza -START */
function getRicaricaCellulareFromBozza(payload, noBozza = false) {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_GET_RICARICA_CELLULARE_FROM_BOZZA,
        payload,
        noBozza
    };
}
exports.getRicaricaCellulareFromBozza = getRicaricaCellulareFromBozza;
/* get  Ricarica Cellulare from bozza - FINE */
/* update bozza Ricarica Cellulare - START */
function updateBozzaRicaricaCellulareStart() {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_UPDATE_BOZZA_START
    };
}
function updateBozzaRicaricaCellulareSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_UPDATE_BOZZA_SUCCESS,
        payload
    };
}
const updateBozzaRicaricaCellulare = () => async (dispatch, getState, extraArguments) => {
    const ricaricaCellulare = getState().ricaricaCellulare;
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(updateBozzaRicaricaCellulareStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/bozza_mav_response.json')).then(bozza => {
            dispatch(updateBozzaRicaricaCellulareSuccess((0, api_rest_1.RicaricaCellulareDtoFromJSON)(bozza)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const idCurrentBozza = ricaricaCellulare.currentBozza.id || 0;
        const updateBozzaRicaricaCellulareRequest = {
            id: idCurrentBozza,
            ricaricaCellulareDto: {
                id: idCurrentBozza,
                ...(0, ricaricaCellulare_selectors_1.buildRicaricaCellulare)(ricaricaCellulare),
                bozza: {
                    dataUltimaModifica: moment().toDate()
                }
            }
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.RicaricaCellulareControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).updateDraftRicaricaCellulare(updateBozzaRicaricaCellulareRequest).then((response) => {
                dispatch(updateBozzaRicaricaCellulareSuccess(response));
                dispatch((0, general_actions_1.addSnackbar)('ricaricaCellulare.bozzaRicaricaCellulareAggiornata'));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.updateBozzaRicaricaCellulare = updateBozzaRicaricaCellulare;
/* update bozza Ricarica Cellulare - FINE */
/* get oneclick Ricarica Cellulare - START */
function getOneClickRicaricaCellulareStart() {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_GET_ONECLICK_START
    };
}
function getOneclickRicaricaCellulareSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.RICARICA_CELLULARE_GET_ONECLICK_SUCCESS,
        payload
    };
}
const getOneClickRicaricaCellulare = () => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getOneClickRicaricaCellulareStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/ricarica_celulare_oneclick.json')).then(parametri => {
            dispatch(getOneclickRicaricaCellulareSuccess((0, api_rest_1.OperazioniOneClickDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const getOneclickRicaricaCellulareRequest = {
            operazioniOneClickInputDto: {
                tipoOperazione: "RICARICACELLULARE"
            },
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.RicaricaCellulareControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).operazioniOneClickRicaricaCellulare(getOneclickRicaricaCellulareRequest).then((response) => {
                dispatch(getOneclickRicaricaCellulareSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getOneClickRicaricaCellulare = getOneClickRicaricaCellulare;
/* get oneclick Ricarica Cellulare - FINE */
const shouldUpdateBozzaRicaricaCellulare = () => async (dispatch, getState, extraArguments) => {
    const state = getState().ricaricaCellulare;
    if ((0, ricaricaCellulare_selectors_1.checkUpdateBozzaRicaricaCellulare)(state)) {
        dispatch((0, exports.updateBozzaRicaricaCellulare)());
    }
    else {
        dispatch((0, exports.addBozzaRicaricaCellulare)());
    }
};
exports.shouldUpdateBozzaRicaricaCellulare = shouldUpdateBozzaRicaricaCellulare;
