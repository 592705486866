"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.elencoDivise = exports.elencoBICSelector = exports.formRicercaBIC = exports.disableSalvaBozzaBonificoEstero = exports.paesiBICSelector = exports.bozzeBonificoEstero = exports.currentBozzaBonificoEstero = exports.formValidBonificoEstero = exports.buildBonificoEstero = exports.esitoBonificoEstero = exports.infoDispositivaBonificoEstero = exports.riepilogoBonificoEstero = exports.formBonificoEstero = exports.parametriBonificoEstero = void 0;
const reselect_1 = require("reselect");
const __1 = require("..");
const bonificoEsteroReducer = (state) => state;
exports.parametriBonificoEstero = (0, reselect_1.createSelector)(bonificoEsteroReducer, (state) => {
    return state === null || state === void 0 ? void 0 : state.parametri;
});
exports.formBonificoEstero = (0, reselect_1.createSelector)(bonificoEsteroReducer, (state) => {
    return state.form;
});
exports.riepilogoBonificoEstero = (0, reselect_1.createSelector)(bonificoEsteroReducer, (state) => {
    return state.riepilogo;
});
exports.infoDispositivaBonificoEstero = (0, reselect_1.createSelector)(bonificoEsteroReducer, (state) => {
    return state.infoDispositiva;
});
exports.esitoBonificoEstero = (0, reselect_1.createSelector)(bonificoEsteroReducer, (state) => {
    return state.esito;
});
exports.buildBonificoEstero = (0, reselect_1.createSelector)(bonificoEsteroReducer, (state) => {
    var _a, _b, _c, _d;
    return {
        contoAddebito: ((_b = (_a = state.form.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.id) || 0,
        importo: (0, __1.formattedNumberToNumber)(state.form.importo),
        nomeBeneficiario: state.form.beneficiario,
        paeseDestinazione: state.form.paeseDestinazione,
        codicePaese: state.form.stato,
        ibanBeneficiario: state.form.iban.toUpperCase(),
        localita: state.form.citta,
        datiEsecuzioneBonifico: {
            bic: state.form.bicSwift,
            dataEsecuzioneAddebito: new Date(state.form.dataEsecuzione)
        },
        divisa: state.form.valuta,
        tipoAddebito: state.form.modalitaAddebitoSpese,
        causale: state.form.causale,
        indirizzo: state.form.indirizzo,
        cap: state.form.cap,
        provincia: state.form.provincia,
        cambioValuta: Number((_c = state.form.cambioDivisa) === null || _c === void 0 ? void 0 : _c.cambio),
        id: (_d = state.currentBozza) === null || _d === void 0 ? void 0 : _d.id,
        idBozzaCollegata: state.currentBozza.id,
        idContattoBeneficiario: state.form.idContattoBeneficiario,
    };
});
exports.formValidBonificoEstero = (0, reselect_1.createSelector)(bonificoEsteroReducer, (state) => {
    let formValid = false;
    if (!state.form.beneficiarioError &&
        !state.form.ibanError &&
        !state.form.bicSwiftError &&
        !state.form.statoError &&
        !state.form.cittaError &&
        !state.form.importoError &&
        !state.form.dataEsecuzioneError &&
        !state.form.valutaError &&
        !state.form.modalitaAddebitoSpeseError &&
        !state.form.causaleError) {
        formValid = true;
    }
    return formValid;
});
exports.currentBozzaBonificoEstero = (0, reselect_1.createSelector)(bonificoEsteroReducer, (state) => {
    return state.currentBozza;
});
exports.bozzeBonificoEstero = (0, reselect_1.createSelector)(bonificoEsteroReducer, (state) => {
    return state.bozzeBonifico && state.bozzeBonifico.bonifici ? state.bozzeBonifico.bonifici.sort((a, b) => b.id - a.id) : [];
});
exports.paesiBICSelector = (0, reselect_1.createSelector)(bonificoEsteroReducer, (state) => {
    return state.paesiBic;
});
exports.disableSalvaBozzaBonificoEstero = (0, reselect_1.createSelector)(bonificoEsteroReducer, (state) => {
    let isBozzaAccepted = !state.form.bozzaSalvata &&
        (!!state.form.iban ||
            !!state.form.causale ||
            !!state.form.importo ||
            !!state.form.beneficiario);
    isBozzaAccepted = isBozzaAccepted && !!state.form.iban ? !state.form.ibanError : isBozzaAccepted;
    isBozzaAccepted = isBozzaAccepted && !!state.form.causale ? !state.form.causaleError : isBozzaAccepted;
    isBozzaAccepted = isBozzaAccepted && !!state.form.importo ? !state.form.importoError : isBozzaAccepted;
    isBozzaAccepted = isBozzaAccepted && !!state.form.beneficiario ? !state.form.beneficiarioError : isBozzaAccepted;
    return !isBozzaAccepted;
});
exports.formRicercaBIC = (0, reselect_1.createSelector)(bonificoEsteroReducer, (state) => {
    return state.formRicercaBIC;
});
exports.elencoBICSelector = (0, reselect_1.createSelector)(bonificoEsteroReducer, (state) => {
    return state.elencoBic;
});
exports.elencoDivise = (0, reselect_1.createSelector)(bonificoEsteroReducer, (state) => {
    return state.elencoDivise;
});
