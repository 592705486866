"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildAutorizaMyBankFilter = exports.buildControlloMyBankFilter = exports.myBankTokenSelector = exports.buildjwtTokenMyBankFilter = exports.annullaMyBankSelector = exports.rapportoMyBank = exports.parametriMyBank = exports.esitoMyBank = exports.riepilogoMyBank = exports.infoAutenticazioneMyBank = exports.formMyBank = void 0;
const reselect_1 = require("reselect");
const myBankReducer = (state) => state;
exports.formMyBank = (0, reselect_1.createSelector)(myBankReducer, (state) => {
    return state.formMyBank;
});
exports.infoAutenticazioneMyBank = (0, reselect_1.createSelector)(myBankReducer, (state) => {
    return state.infoDispositiva;
});
exports.riepilogoMyBank = (0, reselect_1.createSelector)(myBankReducer, (state) => {
    return state.riepilogo;
});
exports.esitoMyBank = (0, reselect_1.createSelector)(myBankReducer, (state) => {
    return state.esito;
});
exports.parametriMyBank = (0, reselect_1.createSelector)(myBankReducer, (state) => {
    return state.parametri;
});
exports.rapportoMyBank = (0, reselect_1.createSelector)(myBankReducer, (state) => {
    return state.rapportoSelezionato;
});
exports.annullaMyBankSelector = (0, reselect_1.createSelector)(myBankReducer, (state) => {
    return state.annulla;
});
exports.buildjwtTokenMyBankFilter = (0, reselect_1.createSelector)(myBankReducer, (state) => {
    const token = state.myBankToken || '';
    return token;
});
exports.myBankTokenSelector = (0, reselect_1.createSelector)(myBankReducer, (state) => {
    return state.myBankToken;
});
exports.buildControlloMyBankFilter = (0, reselect_1.createSelector)(myBankReducer, (state) => {
    var _a, _b;
    const filter = {
        contoAddebito: ((_b = (_a = state.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.id) || 0,
        token: state.myBankToken
    };
    return filter;
});
exports.buildAutorizaMyBankFilter = (0, reselect_1.createSelector)(myBankReducer, (state) => {
    var _a;
    const filter = {
        contoAddebito: ((_a = state.parametri.bonifico) === null || _a === void 0 ? void 0 : _a.contoAddebito) || 0
    };
    return filter;
});
