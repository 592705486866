"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfiloRapportoDtoToJSON = exports.ProfiloRapportoDtoFromJSONTyped = exports.ProfiloRapportoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ProfiloRapportoDtoFromJSON(json) {
    return ProfiloRapportoDtoFromJSONTyped(json, false);
}
exports.ProfiloRapportoDtoFromJSON = ProfiloRapportoDtoFromJSON;
function ProfiloRapportoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'predefinito': !runtime_1.exists(json, 'predefinito') ? undefined : json['predefinito'],
        'alias': !runtime_1.exists(json, 'alias') ? undefined : json['alias'],
        'intestazione': !runtime_1.exists(json, 'intestazione') ? undefined : json['intestazione'],
        'coloreCardLight': !runtime_1.exists(json, 'coloreCardLight') ? undefined : json['coloreCardLight'],
        'coloreCardDark': !runtime_1.exists(json, 'coloreCardDark') ? undefined : json['coloreCardDark'],
        'servizio': !runtime_1.exists(json, 'servizio') ? undefined : json['servizio'],
        'filiale': !runtime_1.exists(json, 'filiale') ? undefined : json['filiale'],
        'numeroConto': !runtime_1.exists(json, 'numeroConto') ? undefined : json['numeroConto'],
        'iban': !runtime_1.exists(json, 'iban') ? undefined : json['iban'],
        'ibanFormattato': !runtime_1.exists(json, 'ibanFormattato') ? undefined : json['ibanFormattato'],
        'cab': !runtime_1.exists(json, 'cab') ? undefined : json['cab'],
        'codiceFiscale': !runtime_1.exists(json, 'codiceFiscale') ? undefined : json['codiceFiscale'],
        'bic': !runtime_1.exists(json, 'bic') ? undefined : json['bic'],
        'isEstero': !runtime_1.exists(json, 'isEstero') ? undefined : json['isEstero'],
        'divisa': !runtime_1.exists(json, 'divisa') ? undefined : json['divisa'],
        'divise': !runtime_1.exists(json, 'divise') ? undefined : json['divise'],
        'categoria': !runtime_1.exists(json, 'categoria') ? undefined : json['categoria'],
        'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
        'cointestatari': !runtime_1.exists(json, 'cointestatari') ? undefined : json['cointestatari'],
        'codiceTitoli': !runtime_1.exists(json, 'codiceTitoli') ? undefined : _1.CodiceTitoliDtoFromJSON(json['codiceTitoli']),
        'pan': !runtime_1.exists(json, 'pan') ? undefined : json['pan'],
        'codiceRapportoFormattatoPerTipo': !runtime_1.exists(json, 'codiceRapportoFormattatoPerTipo') ? undefined : json['codiceRapportoFormattatoPerTipo'],
        'tipoLegame': !runtime_1.exists(json, 'tipoLegame') ? undefined : json['tipoLegame'],
        'tipoCollegamento': !runtime_1.exists(json, 'tipoCollegamento') ? undefined : json['tipoCollegamento'],
        'isEstinto': !runtime_1.exists(json, 'isEstinto') ? undefined : json['isEstinto'],
        'codiceRapportoFormatoBanca': !runtime_1.exists(json, 'codiceRapportoFormatoBanca') ? undefined : json['codiceRapportoFormatoBanca'],
        'rapportoDelega': !runtime_1.exists(json, 'rapportoDelega') ? undefined : json['rapportoDelega'],
        'rapportoCointestato': !runtime_1.exists(json, 'rapportoCointestato') ? undefined : json['rapportoCointestato'],
        'codiceRapportoDossierPadre': !runtime_1.exists(json, 'codiceRapportoDossierPadre') ? undefined : json['codiceRapportoDossierPadre'],
        'codiceDossierPadre': !runtime_1.exists(json, 'codiceDossierPadre') ? undefined : _1.CodiceTitoliDtoFromJSON(json['codiceDossierPadre']),
        'tipologia': !runtime_1.exists(json, 'tipologia') ? undefined : json['tipologia'],
        'estero': !runtime_1.exists(json, 'estero') ? undefined : json['estero'],
    };
}
exports.ProfiloRapportoDtoFromJSONTyped = ProfiloRapportoDtoFromJSONTyped;
function ProfiloRapportoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'predefinito': value.predefinito,
        'alias': value.alias,
        'intestazione': value.intestazione,
        'coloreCardLight': value.coloreCardLight,
        'coloreCardDark': value.coloreCardDark,
        'servizio': value.servizio,
        'filiale': value.filiale,
        'numeroConto': value.numeroConto,
        'iban': value.iban,
        'ibanFormattato': value.ibanFormattato,
        'cab': value.cab,
        'codiceFiscale': value.codiceFiscale,
        'bic': value.bic,
        'isEstero': value.isEstero,
        'divisa': value.divisa,
        'divise': value.divise,
        'categoria': value.categoria,
        'stato': value.stato,
        'cointestatari': value.cointestatari,
        'codiceTitoli': _1.CodiceTitoliDtoToJSON(value.codiceTitoli),
        'pan': value.pan,
        'codiceRapportoFormattatoPerTipo': value.codiceRapportoFormattatoPerTipo,
        'tipoLegame': value.tipoLegame,
        'tipoCollegamento': value.tipoCollegamento,
        'isEstinto': value.isEstinto,
        'codiceRapportoFormatoBanca': value.codiceRapportoFormatoBanca,
        'rapportoDelega': value.rapportoDelega,
        'rapportoCointestato': value.rapportoCointestato,
        'codiceRapportoDossierPadre': value.codiceRapportoDossierPadre,
        'codiceDossierPadre': _1.CodiceTitoliDtoToJSON(value.codiceDossierPadre),
        'tipologia': value.tipologia,
        'estero': value.estero,
    };
}
exports.ProfiloRapportoDtoToJSON = ProfiloRapportoDtoToJSON;
