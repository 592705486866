import { currencyFormat } from "helpers/utilities";
import React from "react";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";

const LiquiditaTable: React.FC<iProps> = (props: iProps) => {
  const { t } = useTranslation();
  const { data, type, noItemsText, boldHeader, compactHeader, tableTitle, aggiungiPreferito, rimuoviPreferito } = props;
  const columns: Column[] = [
    {
      name: "intestatario",
      descrizione: "patrimonio.tableLabels.ibanIntestatario",
      bold: true,
      isIntestatario: true
    }
  ];
  if (type === 'TD') {
    columns.push({
      name: "saldo",
      descrizione: "patrimonio.tableLabels.saldo",
      bold: true,
      align: "right",
      isPrice: true
    })
  } else {
    columns.push({
      name: "saldoDisponibile",
      descrizione: "patrimonio.tableLabels.saldoDisponibile",
      bold: true,
      align: "right",
      isPrice: true
    });
    columns.push({
      name: "saldoContabile",
      descrizione: "patrimonio.tableLabels.saldoContabile",
      bold: true,
      align: "right",
      isPrice: true
    });
  }

  const buildNoItemMesage = (noItemsText: any) => {
    if (noItemsText && noItemsText.length) {
      noItemsText.map((item: any) => {
        return <p>{item}</p>;
      });
    }
  };

  return (
    <div className="custom-table">
      <p className="form-title">{tableTitle}</p>
      {data && data.length > 0 ? (
        <Table hover borderless responsive>
          <thead>
            <tr>
              {columns.map((column: Column, index: number) => (
                <th
                  style={column.width ? { width: column.width } : {}}
                  key={index}
                  className={`${column.align ? `align-${column.align}` : ``}  ${compactHeader ? `compact-header` : ``
                    } small-header bold-text`}
                >
                  {column.descrizione ? t(column.descrizione) : ""}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.length > 0 &&
              data.map((item: any, index: number) => {
                const itemAsAny = item as any;
                return (
                  <tr key={index}>
                    {columns.map((column: Column, index: number) => {
                      const key = column.name;
                      return (
                        <td
                          key={index + column.name}
                          className={`${column.compact ? "compact " : ""}${column.align ? `align-${column.align} ` : ``
                            }`}
                        >

                          {column.isPrice ? `${currencyFormat(itemAsAny[key])}  EUR` : column.isIntestatario
                            ? `${itemAsAny[key]} \n ${itemAsAny.iban}`
                            : itemAsAny[key]}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </Table>
      ) : (
          <div>
            {noItemsText &&
              noItemsText.map((item: any, index: number) => (
                <p key={`infoText-${index}`}>{item}</p>
              ))}
          </div>
        )}
    </div>
  );
};

export interface Column {
  name: string;
  descrizione?: string;
  align?: "left" | "center" | "right";
  compact?: boolean;
  bold?: boolean;
  isPrice?: boolean;
  isIntestatario?: boolean;
  width?: number;
}

interface iProps {
  data?: any;
  type?: string;
  noItemsText?: any;
  boldHeader?: boolean;
  compactHeader?: boolean;
  tableTitle?: string,
  aggiungiPreferito?: any,
  rimuoviPreferito?: any,
}

export default LiquiditaTable;
