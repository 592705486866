"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.IdentityStoreIdEnumToJSON = exports.IdentityStoreIdEnumFromJSONTyped = exports.IdentityStoreIdEnumFromJSON = exports.IdentityStoreIdEnum = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var IdentityStoreIdEnum;
(function (IdentityStoreIdEnum) {
    IdentityStoreIdEnum["CB"] = "CB";
    IdentityStoreIdEnum["LDAP"] = "LDAP";
})(IdentityStoreIdEnum = exports.IdentityStoreIdEnum || (exports.IdentityStoreIdEnum = {}));
function IdentityStoreIdEnumFromJSON(json) {
    return IdentityStoreIdEnumFromJSONTyped(json, false);
}
exports.IdentityStoreIdEnumFromJSON = IdentityStoreIdEnumFromJSON;
function IdentityStoreIdEnumFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.IdentityStoreIdEnumFromJSONTyped = IdentityStoreIdEnumFromJSONTyped;
function IdentityStoreIdEnumToJSON(value) {
    return value;
}
exports.IdentityStoreIdEnumToJSON = IdentityStoreIdEnumToJSON;
