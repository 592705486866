"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PartitePrenotateDtoToJSON = exports.PartitePrenotateDtoFromJSONTyped = exports.PartitePrenotateDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function PartitePrenotateDtoFromJSON(json) {
    return PartitePrenotateDtoFromJSONTyped(json, false);
}
exports.PartitePrenotateDtoFromJSON = PartitePrenotateDtoFromJSON;
function PartitePrenotateDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'debordoCassa': !runtime_1.exists(json, 'debordoCassa') ? undefined : json['debordoCassa'],
        'debordoLDA': !runtime_1.exists(json, 'debordoLDA') ? undefined : json['debordoLDA'],
        'debordoSBF': !runtime_1.exists(json, 'debordoSBF') ? undefined : json['debordoSBF'],
        'deltaFido': !runtime_1.exists(json, 'deltaFido') ? undefined : json['deltaFido'],
        'debordoAnticipoFatture': !runtime_1.exists(json, 'debordoAnticipoFatture') ? undefined : json['debordoAnticipoFatture'],
        'totaleMovimentiGra': !runtime_1.exists(json, 'totaleMovimentiGra') ? undefined : json['totaleMovimentiGra'],
        'totaleMovimentiSospesi': !runtime_1.exists(json, 'totaleMovimentiSospesi') ? undefined : json['totaleMovimentiSospesi'],
        'partitePrenotateAvere': !runtime_1.exists(json, 'partitePrenotateAvere') ? undefined : json['partitePrenotateAvere'],
        'importoAssegniVersati': !runtime_1.exists(json, 'importoAssegniVersati') ? undefined : json['importoAssegniVersati'],
        'partiteAvvisateEffetti': !runtime_1.exists(json, 'partiteAvvisateEffetti') ? undefined : json['partiteAvvisateEffetti'],
        'partiteIlliquide': !runtime_1.exists(json, 'partiteIlliquide') ? undefined : json['partiteIlliquide'],
        'partiteIndisponibili': !runtime_1.exists(json, 'partiteIndisponibili') ? undefined : json['partiteIndisponibili'],
        'fidoAssegniLDA': !runtime_1.exists(json, 'fidoAssegniLDA') ? undefined : json['fidoAssegniLDA'],
        'limiteFidoSBF': !runtime_1.exists(json, 'limiteFidoSBF') ? undefined : json['limiteFidoSBF'],
        'fidoCassa': !runtime_1.exists(json, 'fidoCassa') ? undefined : json['fidoCassa'],
        'fidoTemporaneoCassa': !runtime_1.exists(json, 'fidoTemporaneoCassa') ? undefined : json['fidoTemporaneoCassa'],
        'fidoSconfinoCassa': !runtime_1.exists(json, 'fidoSconfinoCassa') ? undefined : json['fidoSconfinoCassa'],
        'fidoCastellettoSBF': !runtime_1.exists(json, 'fidoCastellettoSBF') ? undefined : json['fidoCastellettoSBF'],
        'fidoSconfinoSBF': !runtime_1.exists(json, 'fidoSconfinoSBF') ? undefined : json['fidoSconfinoSBF'],
        'fidoSconfinoLDA': !runtime_1.exists(json, 'fidoSconfinoLDA') ? undefined : json['fidoSconfinoLDA'],
        'partitePrenotateDare': !runtime_1.exists(json, 'partitePrenotateDare') ? undefined : json['partitePrenotateDare'],
        'partiteInformativeDare': !runtime_1.exists(json, 'partiteInformativeDare') ? undefined : json['partiteInformativeDare'],
        'fidoAnticipoFatture': !runtime_1.exists(json, 'fidoAnticipoFatture') ? undefined : json['fidoAnticipoFatture'],
        'fidoSconfinoAnticipoFatture': !runtime_1.exists(json, 'fidoSconfinoAnticipoFatture') ? undefined : json['fidoSconfinoAnticipoFatture'],
        'fidoUtilizzato': !runtime_1.exists(json, 'fidoUtilizzato') ? undefined : json['fidoUtilizzato'],
    };
}
exports.PartitePrenotateDtoFromJSONTyped = PartitePrenotateDtoFromJSONTyped;
function PartitePrenotateDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'debordoCassa': value.debordoCassa,
        'debordoLDA': value.debordoLDA,
        'debordoSBF': value.debordoSBF,
        'deltaFido': value.deltaFido,
        'debordoAnticipoFatture': value.debordoAnticipoFatture,
        'totaleMovimentiGra': value.totaleMovimentiGra,
        'totaleMovimentiSospesi': value.totaleMovimentiSospesi,
        'partitePrenotateAvere': value.partitePrenotateAvere,
        'importoAssegniVersati': value.importoAssegniVersati,
        'partiteAvvisateEffetti': value.partiteAvvisateEffetti,
        'partiteIlliquide': value.partiteIlliquide,
        'partiteIndisponibili': value.partiteIndisponibili,
        'fidoAssegniLDA': value.fidoAssegniLDA,
        'limiteFidoSBF': value.limiteFidoSBF,
        'fidoCassa': value.fidoCassa,
        'fidoTemporaneoCassa': value.fidoTemporaneoCassa,
        'fidoSconfinoCassa': value.fidoSconfinoCassa,
        'fidoCastellettoSBF': value.fidoCastellettoSBF,
        'fidoSconfinoSBF': value.fidoSconfinoSBF,
        'fidoSconfinoLDA': value.fidoSconfinoLDA,
        'partitePrenotateDare': value.partitePrenotateDare,
        'partiteInformativeDare': value.partiteInformativeDare,
        'fidoAnticipoFatture': value.fidoAnticipoFatture,
        'fidoSconfinoAnticipoFatture': value.fidoSconfinoAnticipoFatture,
        'fidoUtilizzato': value.fidoUtilizzato,
    };
}
exports.PartitePrenotateDtoToJSON = PartitePrenotateDtoToJSON;
