"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddToPreferredDtoToJSON = exports.AddToPreferredDtoFromJSONTyped = exports.AddToPreferredDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function AddToPreferredDtoFromJSON(json) {
    return AddToPreferredDtoFromJSONTyped(json, false);
}
exports.AddToPreferredDtoFromJSON = AddToPreferredDtoFromJSON;
function AddToPreferredDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'ricercaIn': !runtime_1.exists(json, 'ricercaIn') ? undefined : _1.RicercaTitoliInDtoFromJSON(json['ricercaIn']),
        'titolo': !runtime_1.exists(json, 'titolo') ? undefined : _1.DatiTitoloConMercatoDtoFromJSON(json['titolo']),
    };
}
exports.AddToPreferredDtoFromJSONTyped = AddToPreferredDtoFromJSONTyped;
function AddToPreferredDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'ricercaIn': _1.RicercaTitoliInDtoToJSON(value.ricercaIn),
        'titolo': _1.DatiTitoloConMercatoDtoToJSON(value.titolo),
    };
}
exports.AddToPreferredDtoToJSON = AddToPreferredDtoToJSON;
