"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MovimentiCartaRequestDtoOrdinamentoEnum = exports.MovimentiCartaRequestDtoToJSON = exports.MovimentiCartaRequestDtoFromJSONTyped = exports.MovimentiCartaRequestDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function MovimentiCartaRequestDtoFromJSON(json) {
    return MovimentiCartaRequestDtoFromJSONTyped(json, false);
}
exports.MovimentiCartaRequestDtoFromJSON = MovimentiCartaRequestDtoFromJSON;
function MovimentiCartaRequestDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'panAlias': json['panAlias'],
        'dataIniziale': (new Date(json['dataIniziale'])),
        'dataFinale': (new Date(json['dataFinale'])),
        'divisa': !runtime_1.exists(json, 'divisa') ? undefined : json['divisa'],
        'keyword': !runtime_1.exists(json, 'keyword') ? undefined : json['keyword'],
        'importoDa': !runtime_1.exists(json, 'importoDa') ? undefined : json['importoDa'],
        'importoA': !runtime_1.exists(json, 'importoA') ? undefined : json['importoA'],
        'ordinamento': !runtime_1.exists(json, 'ordinamento') ? undefined : json['ordinamento'],
        'offset': !runtime_1.exists(json, 'offset') ? undefined : json['offset'],
        'intestatario': !runtime_1.exists(json, 'intestatario') ? undefined : json['intestatario'],
        'idPeriodo': !runtime_1.exists(json, 'idPeriodo') ? undefined : json['idPeriodo'],
        'pan': !runtime_1.exists(json, 'pan') ? undefined : json['pan'],
    };
}
exports.MovimentiCartaRequestDtoFromJSONTyped = MovimentiCartaRequestDtoFromJSONTyped;
function MovimentiCartaRequestDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'panAlias': value.panAlias,
        'dataIniziale': (value.dataIniziale.toISOString()),
        'dataFinale': (value.dataFinale.toISOString()),
        'divisa': value.divisa,
        'keyword': value.keyword,
        'importoDa': value.importoDa,
        'importoA': value.importoA,
        'ordinamento': value.ordinamento,
        'offset': value.offset,
        'intestatario': value.intestatario,
        'idPeriodo': value.idPeriodo,
        'pan': value.pan,
    };
}
exports.MovimentiCartaRequestDtoToJSON = MovimentiCartaRequestDtoToJSON;
/**
* @export
* @enum {string}
*/
var MovimentiCartaRequestDtoOrdinamentoEnum;
(function (MovimentiCartaRequestDtoOrdinamentoEnum) {
    MovimentiCartaRequestDtoOrdinamentoEnum["A"] = "A";
    MovimentiCartaRequestDtoOrdinamentoEnum["D"] = "D";
})(MovimentiCartaRequestDtoOrdinamentoEnum = exports.MovimentiCartaRequestDtoOrdinamentoEnum || (exports.MovimentiCartaRequestDtoOrdinamentoEnum = {}));
