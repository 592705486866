"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shouldUpdateBozzaMav = exports.resetCurrentBozza = exports.updateBozzaMav = exports.getMavFromBozza = exports.delBozzaMav = exports.addBozzaMav = exports.getBozzaMav = exports.getBozzeMav = exports.setMavBozzaSalvata = exports.setMavSetDataEsec = exports.resetMav = exports.setMavSetStep = exports.setMavSetStepAction = exports.setFormSubmittedMav = exports.setMavSetRapportoSelezionato = exports.setMavSetCodiceMAV = exports.setMavSetImporto = exports.autorizzaRevocaMav = exports.getInfoRevocaMav = exports.setMavRevocaSetStep = exports.setProvenienzaRevocaMav = exports.setRiepilogoRevocaMav = exports.autorizzaMav = exports.controlloMav = exports.getInfoAutenticazioneMav = exports.getParametriMav = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const general_types_1 = require("../general/general.types");
const helpers_1 = require("../helpers");
const mav_selectors_1 = require("./mav.selectors");
const moment = require("moment");
/* get parametri - START */
function getParametriMavStart() {
    return {
        type: __1.EReduxActionTypes.MAV_GET_PARAMETRI_START
    };
}
function getParametriMavSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.MAV_GET_PARAMETRI_SUCCESS,
        payload
    };
}
function generalError(value, showGenericModalError, sendErrorToMatomo, dispositiva, nomeEvento) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value, showGenericModalError));
        (!!dispositiva && !!sendErrorToMatomo) && sendErrorToMatomo(dispositiva, `code_error ${dispositiva} ${value.status} / ${value === null || value === void 0 ? void 0 : value.message} / ${value === null || value === void 0 ? void 0 : value.timestamp}`, nomeEvento);
    };
}
function getParametriPerRipetiMavSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.MAV_GET_PARAMETRI_PER_RIPETI_SUCCESS,
        payload
    };
}
const getParametriMav = (ripeti) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getParametriMavStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/parametri_mav.json')).then(parametri => {
            dispatch(ripeti ? getParametriPerRipetiMavSuccess((0, api_rest_1.ParametriMavDtoFromJSON)(parametri)) : getParametriMavSuccess((0, api_rest_1.ParametriMavDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.MavControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametersMav().then((response) => {
                var _a;
                dispatch(ripeti ? getParametriPerRipetiMavSuccess(response) : getParametriMavSuccess(response));
                ((_a = response.rapporti) === null || _a === void 0 ? void 0 : _a.length) === 0 && dispatch((0, general_actions_1.setNoRapporti)({ type: general_types_1.TypeNoRapporti.MAV }));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getParametriMav = getParametriMav;
/* get parametri - FINE */
/* get info autenticazione MAV - START */
function getInfoAutenticazioneMavStart() {
    return {
        type: __1.EReduxActionTypes.MAV_GET_INFO_AUTENTICAZIONE_MAV_START
    };
}
function getInfoAutenticazioneMavSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.MAV_GET_INFO_AUTENTICAZIONE_MAV_SUCCESS,
        payload
    };
}
// TODO rimuovere la facoltatività di eseguiInit!
const getInfoAutenticazioneMav = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getInfoAutenticazioneMavStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/autenticazione-bonifico.json')).then(parametri => {
            dispatch(getInfoAutenticazioneMavSuccess((0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const requestParameters = {
            idDispositiva,
            chiamataUscente,
            eseguiInit,
            offline: !!offline
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.MavControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).authenticationInformationMav(requestParameters).then((response) => {
                dispatch(getInfoAutenticazioneMavSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getInfoAutenticazioneMav = getInfoAutenticazioneMav;
/* get info autenticazione MAV - FINE */
/* controllo mav - START */
function controlloMavStart() {
    return {
        type: __1.EReduxActionTypes.MAV_CONTROLLO_START
    };
}
function controlloMavSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.MAV_CONTROLLO_SUCCESS,
        payload
    };
}
const controlloMav = () => async (dispatch, getState, extraArguments) => {
    const mav = getState().mav;
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(controlloMavStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/controllo_mav.json')).then(parametri => {
            dispatch(controlloMavSuccess((0, api_rest_1.MavDtoFromJSON)(parametri)));
        });
    }
    else {
        const controlloMavRequest = {
            mavDto: {
                ...(0, mav_selectors_1.buildMav)(mav),
                bozza: {
                    dataUltimaModifica: moment().toDate()
                }
            }
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.MavControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).checkMav(controlloMavRequest).then((response) => {
                dispatch(controlloMavSuccess(response));
                dispatch((0, general_actions_1.setLoader)(false));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.controlloMav = controlloMav;
/* controllo mav - FINE */
/* autorizza mav - START */
function autorizzaMavStart() {
    return {
        type: __1.EReduxActionTypes.MAV_AUTORIZZA_START
    };
}
function autorizzaMavSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.MAV_AUTORIZZA_SUCCESS,
        payload
    };
}
const autorizzaMav = (idDispositiva, credenzialeDispositivaDto, mav) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(autorizzaMavStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/autorizza_mav.json')).then(parametri => {
            dispatch(autorizzaMavSuccess((0, api_rest_1.EsitoAutorizzazioneDisposizioneMavDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const getInfoDispositivaMavRequest = {
            idDispositiva,
            credenzialeDispositivaDto
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.MavControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).authorizeMav(getInfoDispositivaMavRequest).then((response) => {
                dispatch(autorizzaMavSuccess(response));
                extraArguments.sendEventToMatomo('MAV', `esito dipositiva MAV ${response.stato}`, 'ESITO_DISPOSITIVA');
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error), false, extraArguments.sendEventToMatomo, 'MAV', 'ESITO_DISPOSITIVA'));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.autorizzaMav = autorizzaMav;
/* autorizza mav - FINE */
function setRiepilogoRevocaMav(riepilogo) {
    return {
        type: __1.EReduxActionTypes.MAV_SET_RIEPILOGO_REVOCA,
        payload: riepilogo
    };
}
exports.setRiepilogoRevocaMav = setRiepilogoRevocaMav;
function setProvenienzaRevocaMav(provenienza) {
    return {
        type: __1.EReduxActionTypes.MAV_SET_PROVENIENZA,
        payload: provenienza
    };
}
exports.setProvenienzaRevocaMav = setProvenienzaRevocaMav;
function setMavRevocaSetStepAction(i) {
    return {
        type: __1.EReduxActionTypes.MAV_STEP_REVOCA,
        payload: i
    };
}
const setMavRevocaSetStep = (i) => async (dispatch, _, extraArguments) => {
    dispatch(setMavRevocaSetStepAction(i));
    if (extraArguments.platform == 'mobile') {
        dispatch((0, general_actions_1.setBottomTabBarVisible)(i != 1));
    }
};
exports.setMavRevocaSetStep = setMavRevocaSetStep;
/* get info revoca - START */
function getInfoRevocaMavStart() {
    return {
        type: __1.EReduxActionTypes.MAV_GET_INFO_REVOCA_START
    };
}
function getInfoRevocaMavSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.MAV_GET_INFO_REVOCA_SUCCESS,
        payload
    };
}
// TODO rimuovere la facoltatività di eseguiInit!
const getInfoRevocaMav = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getInfoRevocaMavStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/autenticazione-bonifico.json')).then(parametri => {
            dispatch(getInfoRevocaMavSuccess((0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const requestParameters = {
            idDispositiva,
            funzioneDispositiva: '',
            chiamataUscente,
            eseguiInit,
            offline: !!offline
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.MavControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).authenticationRevocationInformationMav(requestParameters).then((response) => {
                dispatch(getInfoRevocaMavSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getInfoRevocaMav = getInfoRevocaMav;
/* get info revoca - FINE */
/* autorizza revoca - START */
function autorizzaRevocaMavStart() {
    return {
        type: __1.EReduxActionTypes.MAV_AUTORIZZA_REVOCA_START
    };
}
function autorizzaRevocaMavSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.MAV_AUTORIZZA_REVOCA_SUCCESS,
        payload
    };
}
const autorizzaRevocaMav = (idDispositiva, credenziale, mav) => async (dispatch, _, extraArguments) => {
    var _a, _b, _c, _d;
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(autorizzaRevocaMavStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/autorizza_revoca_mav.json')).then(parametri => {
            dispatch(autorizzaRevocaMavSuccess((0, api_rest_1.EsitoAutorizzazioneDisposizioneMavDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const requestParameters = {
            mavRevocaRequestIn: {
                progressivoInserimento: (_b = (_a = mav.datiPagamento) === null || _a === void 0 ? void 0 : _a.chiaveQuietanza) === null || _b === void 0 ? void 0 : _b.progressivoInserimento,
                annoInserimento: (_d = (_c = mav.datiPagamento) === null || _c === void 0 ? void 0 : _c.chiaveQuietanza) === null || _d === void 0 ? void 0 : _d.annoInserimento,
                codiceRapporto: mav.contoAddebito,
                credenziale
            }
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.MavControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).authenticationRevocationMav(requestParameters).then((response) => {
                dispatch(autorizzaRevocaMavSuccess(response));
                extraArguments.sendEventToMatomo('MAV_REVOCA', `esito revoca dispositiva MAV_REVOCA ${response.stato}`, 'REVOCA_DISPOSITIVA');
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error), false, extraArguments.sendEventToMatomo, 'MAV_REVOCA', 'REVOCA_DISPOSITIVA'));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.autorizzaRevocaMav = autorizzaRevocaMav;
/* get autorizza revoca - FINE */
/* set form values - START */
function setMavSetImporto(value) {
    return {
        type: __1.EReduxActionTypes.MAV_IMPORTO,
        payload: value
    };
}
exports.setMavSetImporto = setMavSetImporto;
function setMavSetCodiceMAV(value) {
    return {
        type: __1.EReduxActionTypes.MAV_CODICE_MAV,
        payload: value
    };
}
exports.setMavSetCodiceMAV = setMavSetCodiceMAV;
function setMavSetRapportoSelezionato(rapportoSelezionato) {
    return {
        type: __1.EReduxActionTypes.MAV_SET_RAPPORTO_SELEZIONATO,
        payload: rapportoSelezionato
    };
}
exports.setMavSetRapportoSelezionato = setMavSetRapportoSelezionato;
function setFormSubmittedMav(bSelect) {
    return {
        type: __1.EReduxActionTypes.MAV_FORM_SUBMITTED,
        payload: bSelect
    };
}
exports.setFormSubmittedMav = setFormSubmittedMav;
function setMavSetStepAction(step) {
    return {
        type: __1.EReduxActionTypes.MAV_STEP,
        payload: step
    };
}
exports.setMavSetStepAction = setMavSetStepAction;
const setMavSetStep = (i) => async (dispatch, _, extraArguments) => {
    dispatch(setMavSetStepAction(i));
    if (extraArguments.platform == 'mobile') {
        dispatch((0, general_actions_1.setBottomTabBarVisible)(i != 2));
    }
};
exports.setMavSetStep = setMavSetStep;
function resetMav() {
    return {
        type: __1.EReduxActionTypes.MAV_RESET
    };
}
exports.resetMav = resetMav;
function setMavSetDataEsec(txt) {
    return {
        type: __1.EReduxActionTypes.MAV_DATA_ESEC,
        payload: txt
    };
}
exports.setMavSetDataEsec = setMavSetDataEsec;
function setMavBozzaSalvata(b) {
    return {
        type: __1.EReduxActionTypes.MAV_SET_BOZZASALVATA,
        payload: b
    };
}
exports.setMavBozzaSalvata = setMavBozzaSalvata;
/* set form values - FINE */
/* get bozze mav - START */
function getBozzeMavStart() {
    return {
        type: __1.EReduxActionTypes.MAV_GET_BOZZE_START
    };
}
function getBozzeMavSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.MAV_GET_BOZZE_SUCCESS,
        payload
    };
}
const getBozzeMav = (request) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getBozzeMavStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/bozze_mav.json')).then(bozze => {
            dispatch(getBozzeMavSuccess((0, api_rest_1.MavsDtoFromJSON)(bozze)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const getBozzeMavRequest = {
            bozzeInDto: request
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.MavControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getDraftListMav(getBozzeMavRequest).then((response) => {
                dispatch(getBozzeMavSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getBozzeMav = getBozzeMav;
/* get bozze mav - FINE */
/* get bozza mav - START */
function getBozzaMavStart() {
    return {
        type: __1.EReduxActionTypes.MAV_GET_BOZZA_START
    };
}
function getBozzaMavSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.MAV_GET_BOZZA_SUCCESS,
        payload
    };
}
const getBozzaMav = (id) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getBozzaMavStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/bozza_mav.json')).then(bozza => {
            dispatch(getBozzaMavSuccess((0, api_rest_1.MavDtoFromJSON)(bozza)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const getBozzaMavRequest = {
            id
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.MavControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getDraftByIdMav(getBozzaMavRequest).then((response) => {
                dispatch(getBozzaMavSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getBozzaMav = getBozzaMav;
/* get bozza mav - FINE */
/* add bozza mav - START */
function addBozzaMavStart() {
    return {
        type: __1.EReduxActionTypes.MAV_ADD_BOZZA_START
    };
}
function addBozzaMavSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.MAV_ADD_BOZZA_SUCCESS,
        payload
    };
}
const addBozzaMav = () => async (dispatch, getState, extraArguments) => {
    const mav = getState().mav;
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(addBozzaMavStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/bozza_mav_response.json')).then(bozza => {
            dispatch(addBozzaMavSuccess((0, api_rest_1.MavDtoFromJSON)(bozza)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const addBozzaMavRequest = {
            mavDto: {
                ...(0, mav_selectors_1.buildMav)(mav),
                bozza: {
                    descrizione: `${mav.form.codiceMAV}, ${(0, helpers_1.formattedNumberToNumber)(mav.form.importo)}`,
                    dataUltimaModifica: moment().toDate()
                }
            }
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.MavControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).saveDraftMav(addBozzaMavRequest).then((response) => {
                dispatch((0, general_actions_1.addSnackbar)('mav.nuovaBozzaMavSalvata'));
                dispatch(addBozzaMavSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.addBozzaMav = addBozzaMav;
/* add bozza mav - FINE */
/* del bozza mav - START */
function delBozzaMavStart() {
    return {
        type: __1.EReduxActionTypes.MAV_DEL_BOZZA_START
    };
}
function delBozzaMavSuccess(id) {
    return {
        type: __1.EReduxActionTypes.MAV_DEL_BOZZA_SUCCESS,
        payload: id
    };
}
const delBozzaMav = (id) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(delBozzaMavStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/bozza_mav.json')).then(bozza => {
            dispatch(delBozzaMavSuccess(id));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const delBozzaMavRequest = {
            id
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.MavControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).deleteDraftMav(delBozzaMavRequest).then(() => {
                dispatch(delBozzaMavSuccess(id));
                dispatch((0, general_actions_1.addSnackbar)('mav.bozzaEliminataConSuccesso'));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.delBozzaMav = delBozzaMav;
/* del bozza mav - FINE */
/* get oneclick mav - START */
function getOneclickMavStart() {
    return {
        type: __1.EReduxActionTypes.MAV_GET_ONECLICK_START
    };
}
function getOneclickMavSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.MAV_GET_ONECLICK_SUCCESS,
        payload
    };
}
/* get oneclick mav - FINE */
/* get mav from bozza - START */
function getMavFromBozza(payload, noBozza = false) {
    return {
        type: __1.EReduxActionTypes.MAV_GET_MAV_FROM_BOZZA,
        payload,
        noBozza
    };
}
exports.getMavFromBozza = getMavFromBozza;
/* get mav from bozza - FINE */
/* update bozza mav - START */
function updateBozzaMavStart() {
    return {
        type: __1.EReduxActionTypes.MAV_UPDATE_BOZZA_START
    };
}
function updateBozzaMavSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.MAV_UPDATE_BOZZA_SUCCESS,
        payload
    };
}
const updateBozzaMav = () => async (dispatch, getState, extraArguments) => {
    const mav = getState().mav;
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(updateBozzaMavStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/bozza_mav_response.json')).then(bozza => {
            dispatch(updateBozzaMavSuccess((0, api_rest_1.MavDtoFromJSON)(bozza)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const idCurrentBozza = mav.currentBozza.id || 0;
        const updateBozzaMavRequest = {
            id: idCurrentBozza,
            mavDto: {
                id: idCurrentBozza,
                ...(0, mav_selectors_1.buildMav)(mav),
                datiPagamento: undefined,
                bozza: {
                    dataUltimaModifica: moment().toDate()
                }
            }
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.MavControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).updateDraftMav(updateBozzaMavRequest).then((response) => {
                dispatch(updateBozzaMavSuccess(response));
                dispatch((0, general_actions_1.addSnackbar)('mav.bozzaMavAggiornata'));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.updateBozzaMav = updateBozzaMav;
/* update bozza mav - FINE */
/* reset current bozza - INIZIO */
function resetCurrentBozza() {
    return {
        type: __1.EReduxActionTypes.MAV_RESET_CURRENT_BOZZA
    };
}
exports.resetCurrentBozza = resetCurrentBozza;
/* reset current bozza - FINE */
const shouldUpdateBozzaMav = () => async (dispatch, getState, extraArguments) => {
    const state = getState().mav;
    if ((0, mav_selectors_1.checkUpdateBozzaMav)(state)) {
        dispatch((0, exports.updateBozzaMav)());
    }
    else {
        dispatch((0, exports.addBozzaMav)());
    }
};
exports.shouldUpdateBozzaMav = shouldUpdateBozzaMav;
