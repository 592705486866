"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * API BFF Customer Web Collaboration
 * API BFF Customer Web Collaboration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AbstractResponseToJSON = exports.AbstractResponseFromJSONTyped = exports.AbstractResponseFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function AbstractResponseFromJSON(json) {
    return AbstractResponseFromJSONTyped(json, false);
}
exports.AbstractResponseFromJSON = AbstractResponseFromJSON;
function AbstractResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'errorManagementResponse': !runtime_1.exists(json, 'errorManagementResponse') ? undefined : _1.ErrorManagementResponseFromJSON(json['errorManagementResponse']),
    };
}
exports.AbstractResponseFromJSONTyped = AbstractResponseFromJSONTyped;
function AbstractResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'errorManagementResponse': _1.ErrorManagementResponseToJSON(value.errorManagementResponse),
    };
}
exports.AbstractResponseToJSON = AbstractResponseToJSON;
