"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LiquiditaDtoToJSON = exports.LiquiditaDtoFromJSONTyped = exports.LiquiditaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function LiquiditaDtoFromJSON(json) {
    return LiquiditaDtoFromJSONTyped(json, false);
}
exports.LiquiditaDtoFromJSON = LiquiditaDtoFromJSON;
function LiquiditaDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'dataElaborazione': !runtime_1.exists(json, 'dataElaborazione') ? undefined : (new Date(json['dataElaborazione'])),
        'saldoTotale': !runtime_1.exists(json, 'saldoTotale') ? undefined : json['saldoTotale'],
        'rapporti': !runtime_1.exists(json, 'rapporti') ? undefined : (json['rapporti'].map(_1.LiquiditaElementoDtoFromJSON)),
    };
}
exports.LiquiditaDtoFromJSONTyped = LiquiditaDtoFromJSONTyped;
function LiquiditaDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'dataElaborazione': value.dataElaborazione === undefined ? undefined : (value.dataElaborazione.toISOString()),
        'saldoTotale': value.saldoTotale,
        'rapporti': value.rapporti === undefined ? undefined : (value.rapporti.map(_1.LiquiditaElementoDtoToJSON)),
    };
}
exports.LiquiditaDtoToJSON = LiquiditaDtoToJSON;
