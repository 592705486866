"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimeDepositDtoStatoTimeDepositEnum = exports.TimeDepositDtoStatoAperturaEnum = exports.TimeDepositDtoStatoDisposizioneEnum = exports.TimeDepositDtoToJSON = exports.TimeDepositDtoFromJSONTyped = exports.TimeDepositDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function TimeDepositDtoFromJSON(json) {
    return TimeDepositDtoFromJSONTyped(json, false);
}
exports.TimeDepositDtoFromJSON = TimeDepositDtoFromJSON;
function TimeDepositDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'idVirtuale': !runtime_1.exists(json, 'idVirtuale') ? undefined : json['idVirtuale'],
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'contoAddebito': !runtime_1.exists(json, 'contoAddebito') ? undefined : json['contoAddebito'],
        'statoDisposizione': !runtime_1.exists(json, 'statoDisposizione') ? undefined : json['statoDisposizione'],
        'listino': !runtime_1.exists(json, 'listino') ? undefined : json['listino'],
        'importo': !runtime_1.exists(json, 'importo') ? undefined : json['importo'],
        'dataDecorrenza': !runtime_1.exists(json, 'dataDecorrenza') ? undefined : (new Date(json['dataDecorrenza'])),
        'durata': !runtime_1.exists(json, 'durata') ? undefined : json['durata'],
        'dataScadenza': !runtime_1.exists(json, 'dataScadenza') ? undefined : (new Date(json['dataScadenza'])),
        'tassoOperazione': !runtime_1.exists(json, 'tassoOperazione') ? undefined : json['tassoOperazione'],
        'interessi': !runtime_1.exists(json, 'interessi') ? undefined : json['interessi'],
        'tipoInteressi': !runtime_1.exists(json, 'tipoInteressi') ? undefined : json['tipoInteressi'],
        'penale': !runtime_1.exists(json, 'penale') ? undefined : json['penale'],
        'tipoPenale': !runtime_1.exists(json, 'tipoPenale') ? undefined : json['tipoPenale'],
        'commissione': !runtime_1.exists(json, 'commissione') ? undefined : json['commissione'],
        'tipoCommissioni': !runtime_1.exists(json, 'tipoCommissioni') ? undefined : json['tipoCommissioni'],
        'ritenutaFiscale': !runtime_1.exists(json, 'ritenutaFiscale') ? undefined : json['ritenutaFiscale'],
        'tassoPenale': !runtime_1.exists(json, 'tassoPenale') ? undefined : json['tassoPenale'],
        'esecuzione': !runtime_1.exists(json, 'esecuzione') ? undefined : (new Date(json['esecuzione'])),
        'descrizioneTasso': !runtime_1.exists(json, 'descrizioneTasso') ? undefined : json['descrizioneTasso'],
        'descrizioneProdotto': !runtime_1.exists(json, 'descrizioneProdotto') ? undefined : json['descrizioneProdotto'],
        'descrizioneInteressi': !runtime_1.exists(json, 'descrizioneInteressi') ? undefined : json['descrizioneInteressi'],
        'importoMinimo': !runtime_1.exists(json, 'importoMinimo') ? undefined : json['importoMinimo'],
        'importoMassimo': !runtime_1.exists(json, 'importoMassimo') ? undefined : json['importoMassimo'],
        'importoMultiplo': !runtime_1.exists(json, 'importoMultiplo') ? undefined : json['importoMultiplo'],
        'importoScadenza': !runtime_1.exists(json, 'importoScadenza') ? undefined : json['importoScadenza'],
        'statoOperazione': !runtime_1.exists(json, 'statoOperazione') ? undefined : json['statoOperazione'],
        'isRevocabile': !runtime_1.exists(json, 'isRevocabile') ? undefined : json['isRevocabile'],
        'dataEstenzione': !runtime_1.exists(json, 'dataEstenzione') ? undefined : (new Date(json['dataEstenzione'])),
        'condivisione': !runtime_1.exists(json, 'condivisione') ? undefined : (json['condivisione'].map(_1.CondivisioneDtoFromJSON)),
        'bozza': !runtime_1.exists(json, 'bozza') ? undefined : _1.BozzaDtoFromJSON(json['bozza']),
        'inviato': !runtime_1.exists(json, 'inviato') ? undefined : _1.InviatoDtoFromJSON(json['inviato']),
        'idBozzaCollegata': !runtime_1.exists(json, 'idBozzaCollegata') ? undefined : json['idBozzaCollegata'],
        'numeroOperazione': !runtime_1.exists(json, 'numeroOperazione') ? undefined : json['numeroOperazione'],
        'statoApertura': !runtime_1.exists(json, 'statoApertura') ? undefined : json['statoApertura'],
        'ordinanteIntestazione': !runtime_1.exists(json, 'ordinanteIntestazione') ? undefined : json['ordinanteIntestazione'],
        'contoAddebitoIban': !runtime_1.exists(json, 'contoAddebitoIban') ? undefined : json['contoAddebitoIban'],
        'contoAddebitoAlias': !runtime_1.exists(json, 'contoAddebitoAlias') ? undefined : json['contoAddebitoAlias'],
        'statoTimeDeposit': !runtime_1.exists(json, 'statoTimeDeposit') ? undefined : json['statoTimeDeposit'],
        'revocabile': !runtime_1.exists(json, 'revocabile') ? undefined : json['revocabile'],
    };
}
exports.TimeDepositDtoFromJSONTyped = TimeDepositDtoFromJSONTyped;
function TimeDepositDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'idVirtuale': value.idVirtuale,
        'id': value.id,
        'contoAddebito': value.contoAddebito,
        'statoDisposizione': value.statoDisposizione,
        'listino': value.listino,
        'importo': value.importo,
        'dataDecorrenza': value.dataDecorrenza === undefined ? undefined : (value.dataDecorrenza.toISOString()),
        'durata': value.durata,
        'dataScadenza': value.dataScadenza === undefined ? undefined : (value.dataScadenza.toISOString()),
        'tassoOperazione': value.tassoOperazione,
        'interessi': value.interessi,
        'tipoInteressi': value.tipoInteressi,
        'penale': value.penale,
        'tipoPenale': value.tipoPenale,
        'commissione': value.commissione,
        'tipoCommissioni': value.tipoCommissioni,
        'ritenutaFiscale': value.ritenutaFiscale,
        'tassoPenale': value.tassoPenale,
        'esecuzione': value.esecuzione === undefined ? undefined : (value.esecuzione.toISOString()),
        'descrizioneTasso': value.descrizioneTasso,
        'descrizioneProdotto': value.descrizioneProdotto,
        'descrizioneInteressi': value.descrizioneInteressi,
        'importoMinimo': value.importoMinimo,
        'importoMassimo': value.importoMassimo,
        'importoMultiplo': value.importoMultiplo,
        'importoScadenza': value.importoScadenza,
        'statoOperazione': value.statoOperazione,
        'isRevocabile': value.isRevocabile,
        'dataEstenzione': value.dataEstenzione === undefined ? undefined : (value.dataEstenzione.toISOString()),
        'condivisione': value.condivisione === undefined ? undefined : (value.condivisione.map(_1.CondivisioneDtoToJSON)),
        'bozza': _1.BozzaDtoToJSON(value.bozza),
        'inviato': _1.InviatoDtoToJSON(value.inviato),
        'idBozzaCollegata': value.idBozzaCollegata,
        'numeroOperazione': value.numeroOperazione,
        'statoApertura': value.statoApertura,
        'ordinanteIntestazione': value.ordinanteIntestazione,
        'contoAddebitoIban': value.contoAddebitoIban,
        'contoAddebitoAlias': value.contoAddebitoAlias,
        'statoTimeDeposit': value.statoTimeDeposit,
        'revocabile': value.revocabile,
    };
}
exports.TimeDepositDtoToJSON = TimeDepositDtoToJSON;
/**
* @export
* @enum {string}
*/
var TimeDepositDtoStatoDisposizioneEnum;
(function (TimeDepositDtoStatoDisposizioneEnum) {
    TimeDepositDtoStatoDisposizioneEnum["NON_AUTORIZZATO"] = "NON_AUTORIZZATO";
    TimeDepositDtoStatoDisposizioneEnum["IN_CORSO"] = "IN_CORSO";
    TimeDepositDtoStatoDisposizioneEnum["INVIATO"] = "INVIATO";
    TimeDepositDtoStatoDisposizioneEnum["ESITO_INCERTO"] = "ESITO_INCERTO";
    TimeDepositDtoStatoDisposizioneEnum["REVOCATO"] = "REVOCATO";
})(TimeDepositDtoStatoDisposizioneEnum = exports.TimeDepositDtoStatoDisposizioneEnum || (exports.TimeDepositDtoStatoDisposizioneEnum = {}));
/**
* @export
* @enum {string}
*/
var TimeDepositDtoStatoAperturaEnum;
(function (TimeDepositDtoStatoAperturaEnum) {
    TimeDepositDtoStatoAperturaEnum["BLOCCATO"] = "BLOCCATO";
    TimeDepositDtoStatoAperturaEnum["ELABORATO"] = "ELABORATO";
    TimeDepositDtoStatoAperturaEnum["IN_CORSO"] = "IN_CORSO";
})(TimeDepositDtoStatoAperturaEnum = exports.TimeDepositDtoStatoAperturaEnum || (exports.TimeDepositDtoStatoAperturaEnum = {}));
/**
* @export
* @enum {string}
*/
var TimeDepositDtoStatoTimeDepositEnum;
(function (TimeDepositDtoStatoTimeDepositEnum) {
    TimeDepositDtoStatoTimeDepositEnum["ATTIVO"] = "ATTIVO";
    TimeDepositDtoStatoTimeDepositEnum["IN_CORSO"] = "IN_CORSO";
    TimeDepositDtoStatoTimeDepositEnum["ERRORE"] = "ERRORE";
})(TimeDepositDtoStatoTimeDepositEnum = exports.TimeDepositDtoStatoTimeDepositEnum || (exports.TimeDepositDtoStatoTimeDepositEnum = {}));
