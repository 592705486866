import { getRiepilogoPatrimonio, patrimonioRiepilogo, RiepilogoPatrimonioDto, riepilogoPatrimonioPdf } from "@sparkasse/commons";
import ButtonText2 from "component/buttonText2/buttonText2.component";
import BankIcon from "component/Icon/icon.component";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import Breadcrumbs, { BreadcrumbsProps } from "../../component/breadcrumbs/breadcrumbs.component";
import RightbarShoulder from "../../component/Rightbar/RightbarShoulder";
import { AppState } from "../../reducers";
import PatrimonioChartView from "./patrimonio.Chart.View";
import PatrimonioTableView from "./patrimonio.Table.View";

interface PatrimonioLordoColors {
  bankingLiquidita: string;
  investimenti: string;
  assicurazioni: string;
  [key: string]: string;
}
interface PatrimonioFinanziaColors {
  1: string;
  2: string;
  [key: string]: string;
}

interface PatrimonioLiquiditaColors {
  CC: string;
  CD: string;
  DR: string;
  DS: string;
  TD: string;
  [key: string]: string;
}

interface PatrimonioInvestimentiColors {
  AZIONI: string;
  FONDI: string;
  OBBLIGAZIONI_PCT: string;
  ETF_ETC: string;
  DERIVATI: string;
  GPMGPF: string;
  [key: string]: string;
}

interface GestionePatrimonaliColors {
  CT: string;
  DT: string;
  [key: string]: string;
}

export interface ChartData {
  testo?: string;
  importo: number;
  color: string;
  tipologia?: string;
  showValues: boolean
}

export const patrimonioLordoColors: PatrimonioLordoColors = {
  bankingLiquidita: "#319C27",
  investimenti: "#F9CF6B",
  assicurazioni: "#2662B3",
  GP: "#DF0025"
};

export const patrimonioLiquiditaColors: PatrimonioLiquiditaColors = {
  CC: "#319C27",
  CD: "#F9CF6B",
  DR: "#BAB700",
  DS: "#BBDFC5",
  TD: "#9BB291",
};

export const patrimonioInvestimentiColors: PatrimonioInvestimentiColors = {
  AZIONI: "#60935d",
  FONDI: "#b2001c",
  OBBLIGAZIONI_PCT: "#22c065",
  ETF_ETC: "#d85400",
  DERIVATI: "#006633",
  GPMGPF: "#93bf1f",
};

export const gestionePatrimonialiColors: GestionePatrimonaliColors = {
  CT: "#60935d",
  DT: "#b2001c",
};

export const patrimonioFinanziaColors: PatrimonioFinanziaColors = {
  1: "#C6962C", // fido
  2: "#B00722", // mutui
};

const PatrimonioView: React.FC<Props> = (props: Props) => {

  const { t } = useTranslation();

  const [isVisible, setVisible] = useState<boolean>(false);
  const [viewDashboard, setViewDashboard] = useState<boolean>(true);

  const [tipologiaSelezionata, setTipologiaSelezionata] = useState<string | undefined>(undefined);
  const [chartDataPatrimonioLordo, setChartDataPatrimonioLordo] = useState<Array<ChartData>>();
  const [chartDataFinanzamenti, setChartDataFinanzamenti] = useState<Array<ChartData>>();
  const [chartDataDettaglio, setChartDataDettaglio] = useState<Array<ChartData>>([]);

  const breadcrumbs: Array<BreadcrumbsProps> = [
    { menuId: "profilo", description: t('home.navbar.areaPersonale') },
    {
      description: t("patrimonio.title"),
      inactive: true
    }
  ];

  const {
    history,
    riepilogo,
    getRiepilogoPatrimonio,
    riepilogoPatrimonioPdf
  } = props;

  const onGoHome = () => {
    history.push("/home");
  };

  useEffect(() => {
    if (!riepilogo) {
      getRiepilogoPatrimonio();
    }
  }, []);

  useEffect(() => {
    if (riepilogo) {
      buildPatrimonioLordoData(riepilogo);
      buildPatrimonioFinazamentioData(riepilogo);
    }
  }, [riepilogo]);

  const getTotalTipologia = (tipologia: string | undefined): number => {
    if (tipologia === "bankingLiquidita") {
      return riepilogo?.totaleLiquidita || 0;
    } else if (tipologia === "investimenti") {
      return riepilogo?.totaleInvestimenti || 0;
    } else if (tipologia === "assicurazioni") {
      return riepilogo?.totaleAssicurazioni || 0;
    } else if (tipologia === "GP") {
      return riepilogo?.totaleGestionePatrimoniale || 0;
    }
    return 0;
  };

  const buildPatrimonioLordoData = (
    riepilogo: RiepilogoPatrimonioDto
  ) => {
    if (riepilogo) {
      const data: Array<ChartData> = [];
      riepilogo.posizioneDisponibilita?.disponibilitaLordoList?.map((item) => {
        data.push({
          testo: item.tipologiaDesc || "",
          importo: getTotalTipologia(item.tipologia),
          color: (item.tipologia && patrimonioLordoColors[item.tipologia]) || "",
          tipologia: item.tipologia || "",
          showValues: !!item.controvalore
        });
      });
      setChartDataPatrimonioLordo(data);
      setTipologiaSelezionata(undefined);
    }
  };

  const buildPatrimonioFinazamentioData = (
    riepilogo: RiepilogoPatrimonioDto
  ) => {
    const data: Array<ChartData> = [];
    riepilogo.posizioneFinanziamentiList?.forEach((item) => {
      let importo = 0;
      if (item.tipologia === "1") {
        importo = Math.abs(item.saldoContabile ?? 0)
      } else if (item.tipologia === "2") {
        importo = item.totaleSaldoCapitaleResiduo ?? 0
      }
      data.push({
        testo: t(`patrimonio.finanziamenti.tipologia.${item.tipologia}`) || "",
        importo: importo,
        color: (item.tipologia && patrimonioFinanziaColors[item.tipologia]) || "",
        tipologia: item.tipologia || "",
        showValues: !!item.finanziamentiContoListVisibile
      });
    });
    setChartDataFinanzamenti(data);
  };

  const buildPatrimonioBankingLiquiditaData = (
    riepilogo: RiepilogoPatrimonioDto
  ) => {
    const data: Array<ChartData> = [];
    riepilogo.posizioneLiquiditaList?.map((item) => {
      data.push({
        testo: item.tipologiaDesc || "",
        importo: item.totaleSaldoContabile || 0,
        color: (item.tipologia && patrimonioLiquiditaColors[item.tipologia]) || "",
        tipologia: item.tipologia || "",
        showValues: !!item.liquiditaContiListVisibile
      });
    });
    setChartDataDettaglio(data);
  };

  const buildPatrimonioInvestimentiData = (
    riepilogo: RiepilogoPatrimonioDto
  ) => {
    const data: Array<ChartData> = [];
    riepilogo.posizioneInvestimentiList?.map((item) => {
      data.push({
        testo: item.tipologiaDesc || "",
        importo: item.totaleControvalore || 0,
        color: (item.tipologia && patrimonioInvestimentiColors[item.tipologia]) || "",
        tipologia: item.tipologia || "",
        showValues: !!item.investimentiContiListVisibile
      });
    });
    setChartDataDettaglio(data);
  };

  const buildPatrimonioAssicurazioniData = (
    riepilogo: RiepilogoPatrimonioDto
  ) => {
    const data: Array<ChartData> = [];
    riepilogo.posizioneAssicurazioniList?.map((item) => {
      data.push({
        testo: t('patrimonio.assicurazioni'),
        importo: item.totaleControvalore || 0,
        color: "#2662B3",
        showValues: !!item.assicurazioniContoListVisibile,
      });
    });
    setChartDataDettaglio(data);
  };

  const buildGestionePatrimonialiData = (
    riepilogo: RiepilogoPatrimonioDto
  ) => {
    if (riepilogo) {
      const data: Array<ChartData> = [];
      riepilogo.posizioneGestionePatrimonialiList?.map((item) => {
        data.push({
          testo: item.tipologiaDesc || "",
          importo: item.totaleSaldoContabile || 0,
          color: (item.tipologia && gestionePatrimonialiColors[item.tipologia]) || "",
          tipologia: item.tipologia || "",
          showValues: !!item.gestionePatrimonialeListVisibile
        });
      });
      setChartDataDettaglio(data);
    }
  };

  const onSelectPatrimonio = (tipologia: string) => {

    if (tipologia === "bankingLiquidita" || tipologia === "investimenti" || tipologia === "assicurazioni" || tipologia === "GP") {
      setTipologiaSelezionata(tipologia);
    }

    if (tipologia === "bankingLiquidita") {
      riepilogo && buildPatrimonioBankingLiquiditaData(riepilogo);
    } else if (tipologia === "investimenti") {
      riepilogo && buildPatrimonioInvestimentiData(riepilogo);
    } else if (tipologia === "assicurazioni") {
      riepilogo && buildPatrimonioAssicurazioniData(riepilogo);
    } else if (tipologia === 'GP') {
      riepilogo && buildGestionePatrimonialiData(riepilogo);
    }

  };

  const onBack = () => {
    setChartDataDettaglio([]);
  };

  const exportPdfPatrimonio = (patrimonio: RiepilogoPatrimonioDto) => {
    riepilogoPatrimonioPdf(patrimonio);
  };

  return (
    <div className="central patrimonio-view">

      <div className="main">
        <div className="core">

          <Breadcrumbs
            breadcrumbs={breadcrumbs}
            onAction={onGoHome}
            onOpenSpallaDestra={() => setVisible(true)}
          />

          <div className="align-dashboard-buttons">
            <BankIcon
              title={"visualizza_grafico"}
              onClick={() => setViewDashboard(true)}
              active={viewDashboard}
              monochrome={!viewDashboard}
            />
            <BankIcon
              title={"lista"}
              onClick={() => setViewDashboard(false)}
              active={!viewDashboard}
              monochrome={viewDashboard}
            />
          </div>

          {viewDashboard && riepilogo && chartDataPatrimonioLordo
            ? (
              <PatrimonioChartView
                chartDataPatrimonioLordo={chartDataPatrimonioLordo}
                chartDataFinanzamenti={chartDataFinanzamenti}
                chartDataDettaglio={chartDataDettaglio}
                onSelectPatrimonio={onSelectPatrimonio}
                onBack={onBack}
                tipologiaSelezionata={tipologiaSelezionata}
                patrimonioTotaleDisponibilita={riepilogo.posizioneDisponibilita}
                patrimonioTotaleFinanziamenti={riepilogo.totaleFinanziamenti}
                totaleLiquidita={riepilogo.totaleLiquidita}
                totaleAssicurazioni={riepilogo.totaleAssicurazioni}
                totaleInvestimenti={riepilogo.totaleInvestimenti}
                totaleGestionePatrimoniali={riepilogo.totaleGestionePatrimoniale}
              />
            ) : (
              <> {riepilogo
                && <PatrimonioTableView
                  data={riepilogo}
                  patrimonioTotaleDisponibilita={riepilogo.posizioneDisponibilita}
                  patrimonioTotaleFinanziamenti={riepilogo.totaleFinanziamenti}
                  patrimonioTotaleGestionePatrimoniale={riepilogo.totaleGestionePatrimoniale}
                />}</>
            )
          }

          {riepilogo && (
            <ButtonText2
              descrizione={t("patrimonio.button.scaricaPatrimonio")}
              onClick={() => exportPdfPatrimonio(riepilogo)}
              imageOnLeft={true}
              img="azioni_esporta_pdf"
            />
          )}

        </div>
      </div>

      <RightbarShoulder
        isVisible={isVisible}
        setVisible={setVisible}
      ></RightbarShoulder>

    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  riepilogo: patrimonioRiepilogo(state.patrimonio)
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getRiepilogoPatrimonio,
      riepilogoPatrimonioPdf,
    },
    dispatch
  );

interface RouteParams {
  id: string
}

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<RouteParams>;

export default connect(mapStateToProps, mapDispatchToProps)(PatrimonioView);
