"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CartaCondizioniDtoToJSON = exports.CartaCondizioniDtoFromJSONTyped = exports.CartaCondizioniDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function CartaCondizioniDtoFromJSON(json) {
    return CartaCondizioniDtoFromJSONTyped(json, false);
}
exports.CartaCondizioniDtoFromJSON = CartaCondizioniDtoFromJSON;
function CartaCondizioniDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'tipo': !runtime_1.exists(json, 'tipo') ? undefined : json['tipo'],
        'canoneMensile': !runtime_1.exists(json, 'canoneMensile') ? undefined : json['canoneMensile'],
        'massimaleGiornaliero': !runtime_1.exists(json, 'massimaleGiornaliero') ? undefined : json['massimaleGiornaliero'],
        'massimaleMensile': !runtime_1.exists(json, 'massimaleMensile') ? undefined : json['massimaleMensile'],
        'msgKey': !runtime_1.exists(json, 'msgKey') ? undefined : json['msgKey'],
        'productType': !runtime_1.exists(json, 'productType') ? undefined : json['productType'],
    };
}
exports.CartaCondizioniDtoFromJSONTyped = CartaCondizioniDtoFromJSONTyped;
function CartaCondizioniDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'tipo': value.tipo,
        'canoneMensile': value.canoneMensile,
        'massimaleGiornaliero': value.massimaleGiornaliero,
        'massimaleMensile': value.massimaleMensile,
        'msgKey': value.msgKey,
        'productType': value.productType,
    };
}
exports.CartaCondizioniDtoToJSON = CartaCondizioniDtoToJSON;
