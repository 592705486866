"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loginReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const helpers_1 = require("../helpers");
const login_types_1 = require("./login.types");
const initialState = {
    formLogin: (0, login_types_1.FormLoginDtoFromJSON)({}),
    authentication_context: undefined,
    info: undefined,
    srInfo: undefined,
    authenticationResponse: (0, api_rest_1.AuthenticationResponseDtoFromJSON)({}),
    authenticationError: (0, api_rest_1.ModelErrorFromJSON)({}),
    loginError: (0, api_rest_1.ModelErrorFromJSON)({}),
    strongAuthInfo: (0, api_rest_1.StrongAuthInfoDtoFromJSON)({}),
    scopeList: (0, api_rest_1.ScopeListDtoFromJSON)({}),
    jwtToken: (0, api_rest_1.JwtReqOutFromJSON)({}),
    formEnrollment: (0, login_types_1.FormEnrollmentDtoFromJSON)({}),
    formAzioniSmart: (0, login_types_1.FormAzioniSmartDtoFromJSON)({}),
    formCambioPasswordObbligatorio: (0, login_types_1.FormCambioPasswordObbligatorioDtoFromJSON)({}),
    tokenResettato: false,
    urlWebViewMigrazione: '',
    selfResetStatus: undefined,
    selfResetStarted: false,
    nascondiModaleEnrollmentPerSessioneScaduta: false,
    loginMyBankType: undefined,
    urlRedirect: undefined
};
const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case login_types_1.EReduxLoginActionTypes.LOGIN_SET_USERNAME:
            return {
                ...state,
                formLogin: {
                    ...state.formLogin,
                    username: action.payload,
                    usernameDirty: true,
                    usernameError: ''
                }
            };
        case login_types_1.EReduxLoginActionTypes.LOGIN_SET_PASSWORD:
            return {
                ...state,
                formLogin: {
                    ...state.formLogin,
                    password: action.payload,
                    passwordDirty: true,
                    passwordError: ''
                }
            };
        case login_types_1.EReduxLoginActionTypes.LOGIN_SET_NASCONDI_DATI_SENSIBILI:
            return {
                ...state,
                formLogin: {
                    ...state.formLogin,
                    nascondiDatiSensibili: action.payload
                }
            };
        case login_types_1.EReduxLoginActionTypes.LOGIN_SET_STEP:
            return {
                ...state,
                formLogin: {
                    ...state.formLogin,
                    step: action.payload
                }
            };
        case login_types_1.EReduxLoginActionTypes.LOGIN_SET_WEBSOCKET_CLOSED:
            return {
                ...state,
                formLogin: {
                    ...state.formLogin,
                    websocketClosed: action.payload,
                    websocket: undefined
                }
            };
        case login_types_1.EReduxLoginActionTypes.LOGIN_WEBSOCKET_OPENED:
            return {
                ...state,
                formLogin: {
                    ...state.formLogin,
                    websocket: action.payload
                }
            };
        case login_types_1.EReduxLoginActionTypes.LOGIN_GET_AUTHENTICATION_CONTEXT_START:
            return {
                ...state,
                authenticationError: initialState.authenticationError,
                strongAuthInfo: initialState.strongAuthInfo,
                authenticationResponse: initialState.authenticationResponse,
                formLogin: {
                    ...state.formLogin,
                    websocketClosed: initialState.formLogin.websocketClosed,
                    pin: initialState.formLogin.pin,
                    pinDirty: initialState.formLogin.pinDirty,
                    pinError: initialState.formLogin.pinError,
                    otpHardware: initialState.formLogin.otpHardware,
                    otpHardwareDirty: initialState.formLogin.otpHardwareDirty,
                    otpHardwareError: initialState.formLogin.otpHardwareError,
                    error: false
                },
                selfResetStatus: undefined,
                selfResetStarted: false
            };
        case login_types_1.EReduxLoginActionTypes.LOGIN_REFRESH_TOKEN_START:
            return {
                ...state,
                authenticationError: initialState.authenticationError,
                strongAuthInfo: initialState.strongAuthInfo,
                authenticationResponse: initialState.authenticationResponse,
                formLogin: {
                    ...state.formLogin,
                    websocketClosed: initialState.formLogin.websocketClosed,
                    error: false
                }
            };
        case login_types_1.EReduxLoginActionTypes.RESET_FORM_ENROLLMENT:
            return {
                ...state,
                formEnrollment: initialState.formEnrollment
            };
        case login_types_1.EReduxLoginActionTypes.LOGIN_GET_AUTHENTICATION_CONTEXT_SUCCESS:
            return {
                ...state,
                authentication_context: action.payload.context,
                info: action.payload.info,
                srInfo: action.payload.srInfo
            };
        case login_types_1.EReduxLoginActionTypes.LOGIN_AUTHENTICATION_START_SUCCESS:
            return {
                ...state,
                authenticationResponse: action.payload
            };
        case login_types_1.EReduxLoginActionTypes.LOGIN_AUTHENTICATION_START_ERROR:
            return {
                ...state,
                authentication_context: initialState.authentication_context,
                authenticationResponse: initialState.authenticationResponse,
                authenticationError: action.payload
            };
        case login_types_1.EReduxLoginActionTypes.LOGIN_STRONG_AUTH_INIT_ERROR:
            return {
                ...state,
                authentication_context: initialState.authentication_context,
                authenticationResponse: initialState.authenticationResponse,
                strongAuthInfo: initialState.strongAuthInfo,
                loginError: action.payload
            };
        case login_types_1.EReduxLoginActionTypes.LOGIN_STRONG_AUTH_INIT_SUCCESS:
            return {
                ...state,
                strongAuthInfo: action.payload
            };
        case login_types_1.EReduxLoginActionTypes.LOGIN_STRONG_AUTH_VERIFY_SUCCESS:
            return {
                ...state,
                authenticationResponse: action.payload
            };
        case login_types_1.EReduxLoginActionTypes.LOGIN_STRONG_AUTH_VERIFY_ERROR:
            return {
                ...state,
                authentication_context: initialState.authentication_context,
                authenticationResponse: initialState.authenticationResponse,
                strongAuthInfo: initialState.strongAuthInfo,
                formLogin: {
                    ...state.formLogin,
                    visualizzaQR: initialState.formLogin.visualizzaQR
                }
            };
        case login_types_1.EReduxLoginActionTypes.LOGIN_CONSENT_REQUESTED_GET_SUCCESS:
            return {
                ...state,
                scopeList: action.payload
            };
        case login_types_1.EReduxLoginActionTypes.LOGIN_CONSENT_APPROVED_POST_SUCCESS:
            return {
                ...state
                // scopeList: action.payload
            };
        case login_types_1.EReduxLoginActionTypes.LOGIN_GET_TOKEN_SUCCESS:
        case login_types_1.EReduxLoginActionTypes.LOGIN_REFRESH_TOKEN_SUCCESS:
            return {
                ...state,
                jwtToken: action.payload
            };
        case login_types_1.EReduxLoginActionTypes.LOGIN_REFRESH_TOKEN_ERROR:
            return {
                ...state,
                formLogin: {
                    ...state.formLogin,
                    error: true
                }
            };
        case login_types_1.EReduxLoginActionTypes.LOGIN_SET_PIN:
            return {
                ...state,
                formLogin: {
                    ...state.formLogin,
                    pin: action.payload,
                    pinDirty: true,
                    pinError: (0, helpers_1.validationAsNumber)(action.payload, true, 5, 5)
                }
            };
        case login_types_1.EReduxLoginActionTypes.LOGIN_SET_VISUALIZZA_QR:
            return {
                ...state,
                formLogin: {
                    ...state.formLogin,
                    visualizzaQR: action.payload
                }
            };
        case login_types_1.EReduxLoginActionTypes.LOGIN_SET_OTP:
            return {
                ...state,
                formLogin: {
                    ...state.formLogin,
                    otp: action.payload,
                    otpDirty: true,
                    otpError: (0, helpers_1.validationAsNumber)(action.payload, true, 6, 8)
                }
            };
        case login_types_1.EReduxLoginActionTypes.LOGIN_SET_OTP_HARDWARE:
            return {
                ...state,
                formLogin: {
                    ...state.formLogin,
                    otpHardware: action.payload,
                    otpHardwareDirty: true,
                    otpHardwareError: (0, helpers_1.validationAsNumber)(action.payload, true, 6, 6)
                }
            };
        case login_types_1.EReduxLoginActionTypes.POST_ENROLLMENT_START:
            return {
                ...state,
                formEnrollment: {
                    ...state.formEnrollment,
                    sms: initialState.formEnrollment.sms,
                    smsDirty: initialState.formEnrollment.smsDirty,
                    smsError: initialState.formEnrollment.smsError
                }
            };
        case login_types_1.EReduxLoginActionTypes.POST_ENROLLMENT_SUCCESS:
            return {
                ...state,
                formEnrollment: {
                    ...state.formEnrollment,
                    postEnrollmentSuccess: true
                }
            };
        case login_types_1.EReduxLoginActionTypes.ENROLLMENT_SET_PIN:
            return {
                ...state,
                formEnrollment: {
                    ...state.formEnrollment,
                    pin: action.payload,
                    pinDirty: true,
                    pinError: (0, helpers_1.validationAsNumber)(action.payload, true, 5, 5)
                }
            };
        case login_types_1.EReduxLoginActionTypes.ENROLLMENT_SET_RIPETI_PIN:
            return {
                ...state,
                formEnrollment: {
                    ...state.formEnrollment,
                    ripetiPin: action.payload,
                    ripetiPinDirty: true,
                    ripetiPinError: (0, helpers_1.validationEqualityCheck)(action.payload, state.formEnrollment.pin)
                }
            };
        case login_types_1.EReduxLoginActionTypes.ENROLLMENT_SET_SMS:
            return {
                ...state,
                formEnrollment: {
                    ...state.formEnrollment,
                    sms: action.payload,
                    smsDirty: true,
                    smsError: (0, helpers_1.validationAsNumber)(action.payload, true, 8, 8)
                }
            };
        case login_types_1.EReduxLoginActionTypes.AZIONI_SMART_SET_PIN:
            return {
                ...state,
                formAzioniSmart: {
                    ...state.formAzioniSmart,
                    pin: action.payload,
                    pinDirty: true,
                    pinError: (0, helpers_1.validationAsNumber)(action.payload, true, 5, 5)
                }
            };
        case login_types_1.EReduxLoginActionTypes.AZIONI_SMART_SET_STEP:
            return {
                ...state,
                formAzioniSmart: {
                    ...state.formAzioniSmart,
                    step: action.payload
                }
            };
        case login_types_1.EReduxLoginActionTypes.CAMBIO_PASSWORD_OBBLIGATORIO_SUCCESS:
            return {
                ...state,
                authenticationResponse: action.payload
            };
        case login_types_1.EReduxLoginActionTypes.CAMBIO_PASSWORD_OBBLIGATORIO_SET_VECCHIA_PASSWORD:
            return {
                ...state,
                formCambioPasswordObbligatorio: {
                    ...state.formCambioPasswordObbligatorio,
                    vecchiaPassword: action.payload,
                    vecchiaPasswordDirty: true,
                    vecchiaPasswordError: (0, helpers_1.validationPassword)(action.payload, 4, 20)
                }
            };
        case login_types_1.EReduxLoginActionTypes.CAMBIO_PASSWORD_OBBLIGATORIO_SET_NUOVA_PASSWORD:
            return {
                ...state,
                formCambioPasswordObbligatorio: {
                    ...state.formCambioPasswordObbligatorio,
                    nuovaPassword: action.payload,
                    nuovaPasswordDirty: true,
                    nuovaPasswordError: (0, helpers_1.validationPassword)(action.payload, 8, 20, state.formCambioPasswordObbligatorio.vecchiaPassword)
                }
            };
        case login_types_1.EReduxLoginActionTypes.CAMBIO_PASSWORD_OBBLIGATORIO_SET_RIPETI_NUOVA_PASSWORD:
            return {
                ...state,
                formCambioPasswordObbligatorio: {
                    ...state.formCambioPasswordObbligatorio,
                    ripetiNuovaPassword: action.payload,
                    ripetiNuovaPasswordDirty: true,
                    ripetiNuovaPasswordError: (0, helpers_1.validationPassword)(action.payload, 8, 20, '', state.formCambioPasswordObbligatorio.nuovaPassword)
                }
            };
        case login_types_1.EReduxLoginActionTypes.LOGOUT_OAUTH2_SUCCESS:
            return {
                ...state,
                formLogin: initialState.formLogin,
                formEnrollment: initialState.formEnrollment,
                formAzioniSmart: initialState.formAzioniSmart,
                formCambioPasswordObbligatorio: initialState.formCambioPasswordObbligatorio,
                authentication_context: initialState.authentication_context,
                authenticationResponse: initialState.authenticationResponse,
                strongAuthInfo: initialState.strongAuthInfo,
                scopeList: initialState.scopeList,
                jwtToken: initialState.jwtToken,
                tokenResettato: initialState.tokenResettato,
                urlWebViewMigrazione: initialState.urlWebViewMigrazione
            };
        case login_types_1.EReduxLoginActionTypes.TOKEN_RESET_START:
            return {
                ...state,
                tokenResettato: initialState.tokenResettato
            };
        case login_types_1.EReduxLoginActionTypes.TOKEN_RESET_SUCCESS:
            return {
                ...state,
                tokenResettato: true
            };
        case login_types_1.EReduxLoginActionTypes.LOGIN_TRANSACTION_START_START:
            return {
                ...state,
                urlWebViewMigrazione: initialState.urlWebViewMigrazione
            };
        case login_types_1.EReduxLoginActionTypes.LOGIN_TRANSACTION_START_SUCCESS:
            return {
                ...state,
                urlWebViewMigrazione: action.payload
            };
        case login_types_1.EReduxLoginActionTypes.SELF_RESET_GET_STATUS_START:
            return {
                ...state,
                selfResetStatus: undefined
            };
        case login_types_1.EReduxLoginActionTypes.SELF_RESET_GET_STATUS_SUCCESS:
            return {
                ...state,
                selfResetStatus: action.payload
            };
        case login_types_1.EReduxLoginActionTypes.SELF_RESET_START_START:
            return {
                ...state,
                selfResetStarted: false
            };
        case login_types_1.EReduxLoginActionTypes.SELF_RESET_START_SUCCESS:
            return {
                ...state,
                selfResetStarted: true
            };
        case login_types_1.EReduxLoginActionTypes.LOGIN_TOTAL_RESET:
            return {
                ...initialState
            };
        case login_types_1.EReduxLoginActionTypes.SELF_RESET_NASCONDI_MODALE_ENROLLMENT_PER_SESSIONE_SCADUTA:
            return {
                ...state,
                nascondiModaleEnrollmentPerSessioneScaduta: action.payload
            };
        case login_types_1.EReduxLoginActionTypes.LOGIN_SET_MYBANK_LOGIN_TYPE: {
            return {
                ...state,
                loginMyBankType: action.payload
            };
        }
        case login_types_1.EReduxLoginActionTypes.LOGIN_ANNULLA_LOGIN_MYBANK_SUCCESS: {
            return {
                ...state,
                urlRedirect: action.payload
            };
        }
        case __1.EReduxActionTypes.RESET_ALL: // action invocata dalla logout
            return {
                ...initialState
            };
        default:
            return state;
    }
};
exports.loginReducer = loginReducer;
exports.default = exports.loginReducer;
