"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GestioneConsensiPsd2AbilitazioniDtoToJSON = exports.GestioneConsensiPsd2AbilitazioniDtoFromJSONTyped = exports.GestioneConsensiPsd2AbilitazioniDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function GestioneConsensiPsd2AbilitazioniDtoFromJSON(json) {
    return GestioneConsensiPsd2AbilitazioniDtoFromJSONTyped(json, false);
}
exports.GestioneConsensiPsd2AbilitazioniDtoFromJSON = GestioneConsensiPsd2AbilitazioniDtoFromJSON;
function GestioneConsensiPsd2AbilitazioniDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'consensi': !runtime_1.exists(json, 'consensi') ? undefined : (json['consensi'].map(_1.ConsensoPsd2DtoFromJSON)),
    };
}
exports.GestioneConsensiPsd2AbilitazioniDtoFromJSONTyped = GestioneConsensiPsd2AbilitazioniDtoFromJSONTyped;
function GestioneConsensiPsd2AbilitazioniDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'consensi': value.consensi === undefined ? undefined : (value.consensi.map(_1.ConsensoPsd2DtoToJSON)),
    };
}
exports.GestioneConsensiPsd2AbilitazioniDtoToJSON = GestioneConsensiPsd2AbilitazioniDtoToJSON;
