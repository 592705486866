"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterBilancioFromJSON = void 0;
const helpers_1 = require("../helpers");
function FilterBilancioFromJSON(json) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'tuttiRapportiSelezionati': !(0, helpers_1.exists)(json, 'tuttiRapportiSelezionati') ? false : json.tuttiRapportiSelezionati,
        'rapportoSelezionato': !(0, helpers_1.exists)(json, 'rapportoSelezionato') ? undefined : json.rapportoSelezionato,
        'dataIniziale': !(0, helpers_1.exists)(json, 'dataIniziale') ? new Date(new Date().getFullYear(), new Date().getMonth() - 2) : json.dataIniziale,
        'dataFinale': !(0, helpers_1.exists)(json, 'dataFinale') ? new Date(new Date().getFullYear(), new Date().getMonth()) : json.dataFinale,
        'filtriDirty': !(0, helpers_1.exists)(json, 'filtriDirty') ? false : json.filtriDirty
    };
}
exports.FilterBilancioFromJSON = FilterBilancioFromJSON;
