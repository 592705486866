"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DatiRicaricaDtoToJSON = exports.DatiRicaricaDtoFromJSONTyped = exports.DatiRicaricaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function DatiRicaricaDtoFromJSON(json) {
    return DatiRicaricaDtoFromJSONTyped(json, false);
}
exports.DatiRicaricaDtoFromJSON = DatiRicaricaDtoFromJSON;
function DatiRicaricaDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'dataContabile': !runtime_1.exists(json, 'dataContabile') ? undefined : (new Date(json['dataContabile'])),
        'dataPrenotazione': !runtime_1.exists(json, 'dataPrenotazione') ? undefined : (new Date(json['dataPrenotazione'])),
        'dataScadenza': !runtime_1.exists(json, 'dataScadenza') ? undefined : (new Date(json['dataScadenza'])),
        'descrizioneCarta': !runtime_1.exists(json, 'descrizioneCarta') ? undefined : json['descrizioneCarta'],
        'idUnivoco': !runtime_1.exists(json, 'idUnivoco') ? undefined : json['idUnivoco'],
        'importoCommissioni': !runtime_1.exists(json, 'importoCommissioni') ? undefined : json['importoCommissioni'],
        'importoOperazione': !runtime_1.exists(json, 'importoOperazione') ? undefined : json['importoOperazione'],
        'importoTotale': !runtime_1.exists(json, 'importoTotale') ? undefined : json['importoTotale'],
        'numeroCarta': !runtime_1.exists(json, 'numeroCarta') ? undefined : json['numeroCarta'],
        'rapportoAddebito': !runtime_1.exists(json, 'rapportoAddebito') ? undefined : json['rapportoAddebito'],
        'statoOperazione': !runtime_1.exists(json, 'statoOperazione') ? undefined : json['statoOperazione'],
        'tipoRicarica': !runtime_1.exists(json, 'tipoRicarica') ? undefined : json['tipoRicarica'],
    };
}
exports.DatiRicaricaDtoFromJSONTyped = DatiRicaricaDtoFromJSONTyped;
function DatiRicaricaDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'dataContabile': value.dataContabile === undefined ? undefined : (value.dataContabile.toISOString()),
        'dataPrenotazione': value.dataPrenotazione === undefined ? undefined : (value.dataPrenotazione.toISOString()),
        'dataScadenza': value.dataScadenza === undefined ? undefined : (value.dataScadenza.toISOString()),
        'descrizioneCarta': value.descrizioneCarta,
        'idUnivoco': value.idUnivoco,
        'importoCommissioni': value.importoCommissioni,
        'importoOperazione': value.importoOperazione,
        'importoTotale': value.importoTotale,
        'numeroCarta': value.numeroCarta,
        'rapportoAddebito': value.rapportoAddebito,
        'statoOperazione': value.statoOperazione,
        'tipoRicarica': value.tipoRicarica,
    };
}
exports.DatiRicaricaDtoToJSON = DatiRicaricaDtoToJSON;
