"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvestimentiContoDtoToJSON = exports.InvestimentiContoDtoFromJSONTyped = exports.InvestimentiContoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function InvestimentiContoDtoFromJSON(json) {
    return InvestimentiContoDtoFromJSONTyped(json, false);
}
exports.InvestimentiContoDtoFromJSON = InvestimentiContoDtoFromJSON;
function InvestimentiContoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'intestatario': !runtime_1.exists(json, 'intestatario') ? undefined : json['intestatario'],
        'iban': !runtime_1.exists(json, 'iban') ? undefined : json['iban'],
        'divisa': !runtime_1.exists(json, 'divisa') ? undefined : json['divisa'],
        'controvalore': !runtime_1.exists(json, 'controvalore') ? undefined : json['controvalore'],
        'minusPlus': !runtime_1.exists(json, 'minusPlus') ? undefined : json['minusPlus'],
        'saldiList': !runtime_1.exists(json, 'saldiList') ? undefined : (json['saldiList'].map(_1.InvestimentiContoSaldiDtoFromJSON)),
        'totaleSaldi': !runtime_1.exists(json, 'totaleSaldi') ? undefined : json['totaleSaldi'],
    };
}
exports.InvestimentiContoDtoFromJSONTyped = InvestimentiContoDtoFromJSONTyped;
function InvestimentiContoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'intestatario': value.intestatario,
        'iban': value.iban,
        'divisa': value.divisa,
        'controvalore': value.controvalore,
        'minusPlus': value.minusPlus,
        'saldiList': value.saldiList === undefined ? undefined : (value.saldiList.map(_1.InvestimentiContoSaldiDtoToJSON)),
        'totaleSaldi': value.totaleSaldi,
    };
}
exports.InvestimentiContoDtoToJSON = InvestimentiContoDtoToJSON;
