"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ControlloMyBankDtoToJSON = exports.ControlloMyBankDtoFromJSONTyped = exports.ControlloMyBankDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ControlloMyBankDtoFromJSON(json) {
    return ControlloMyBankDtoFromJSONTyped(json, false);
}
exports.ControlloMyBankDtoFromJSON = ControlloMyBankDtoFromJSON;
function ControlloMyBankDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'contoAddebito': json['contoAddebito'],
        'token': !runtime_1.exists(json, 'token') ? undefined : json['token'],
        'condivisione': !runtime_1.exists(json, 'condivisione') ? undefined : (json['condivisione'].map(_1.CondivisioneDtoFromJSON)),
    };
}
exports.ControlloMyBankDtoFromJSONTyped = ControlloMyBankDtoFromJSONTyped;
function ControlloMyBankDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'contoAddebito': value.contoAddebito,
        'token': value.token,
        'condivisione': value.condivisione === undefined ? undefined : (value.condivisione.map(_1.CondivisioneDtoToJSON)),
    };
}
exports.ControlloMyBankDtoToJSON = ControlloMyBankDtoToJSON;
