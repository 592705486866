"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NumeroContattiDtoToJSON = exports.NumeroContattiDtoFromJSONTyped = exports.NumeroContattiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function NumeroContattiDtoFromJSON(json) {
    return NumeroContattiDtoFromJSONTyped(json, false);
}
exports.NumeroContattiDtoFromJSON = NumeroContattiDtoFromJSON;
function NumeroContattiDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'contattiList': !runtime_1.exists(json, 'contattiList') ? undefined : (json['contattiList'].map(_1.NumeroContattoDtoFromJSON)),
    };
}
exports.NumeroContattiDtoFromJSONTyped = NumeroContattiDtoFromJSONTyped;
function NumeroContattiDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'contattiList': value.contattiList === undefined ? undefined : (value.contattiList.map(_1.NumeroContattoDtoToJSON)),
    };
}
exports.NumeroContattiDtoToJSON = NumeroContattiDtoToJSON;
