"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CriteriMovimentiPsd2InToJSON = exports.CriteriMovimentiPsd2InFromJSONTyped = exports.CriteriMovimentiPsd2InFromJSON = void 0;
const runtime_1 = require("../runtime");
function CriteriMovimentiPsd2InFromJSON(json) {
    return CriteriMovimentiPsd2InFromJSONTyped(json, false);
}
exports.CriteriMovimentiPsd2InFromJSON = CriteriMovimentiPsd2InFromJSON;
function CriteriMovimentiPsd2InFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'accountId': !runtime_1.exists(json, 'accountId') ? undefined : json['accountId'],
        'providerId': !runtime_1.exists(json, 'providerId') ? undefined : json['providerId'],
        'resourceId': !runtime_1.exists(json, 'resourceId') ? undefined : json['resourceId'],
        'aspspId': !runtime_1.exists(json, 'aspspId') ? undefined : json['aspspId'],
        'aspspProductCode': !runtime_1.exists(json, 'aspspProductCode') ? undefined : json['aspspProductCode'],
        'iban': !runtime_1.exists(json, 'iban') ? undefined : json['iban'],
        'nomeBanca': !runtime_1.exists(json, 'nomeBanca') ? undefined : json['nomeBanca'],
        'divisa': !runtime_1.exists(json, 'divisa') ? undefined : json['divisa'],
        'intestazione': !runtime_1.exists(json, 'intestazione') ? undefined : json['intestazione'],
    };
}
exports.CriteriMovimentiPsd2InFromJSONTyped = CriteriMovimentiPsd2InFromJSONTyped;
function CriteriMovimentiPsd2InToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'accountId': value.accountId,
        'providerId': value.providerId,
        'resourceId': value.resourceId,
        'aspspId': value.aspspId,
        'aspspProductCode': value.aspspProductCode,
        'iban': value.iban,
        'nomeBanca': value.nomeBanca,
        'divisa': value.divisa,
        'intestazione': value.intestazione,
    };
}
exports.CriteriMovimentiPsd2InToJSON = CriteriMovimentiPsd2InToJSON;
