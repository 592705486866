"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SezioneInailDtoToJSON = exports.SezioneInailDtoFromJSONTyped = exports.SezioneInailDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function SezioneInailDtoFromJSON(json) {
    return SezioneInailDtoFromJSONTyped(json, false);
}
exports.SezioneInailDtoFromJSON = SezioneInailDtoFromJSON;
function SezioneInailDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'righe': !runtime_1.exists(json, 'righe') ? undefined : (json['righe'].map(_1.RigaSezioneInailDtoFromJSON)),
        'totalInail': !runtime_1.exists(json, 'totalInail') ? undefined : json['totalInail'],
    };
}
exports.SezioneInailDtoFromJSONTyped = SezioneInailDtoFromJSONTyped;
function SezioneInailDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'righe': value.righe === undefined ? undefined : (value.righe.map(_1.RigaSezioneInailDtoToJSON)),
        'totalInail': value.totalInail,
    };
}
exports.SezioneInailDtoToJSON = SezioneInailDtoToJSON;
