import BankIcon from "component/Icon/icon.component";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import separator from "../../assets/icons/breadcrumb_slash.svg";

interface Props {
  index: number;
  selectedIndex: number;
  txt?: string;
  menuId?: string;
  onClick?: any;
  isActive?: boolean;
  keepVisible?: boolean;
}

const ItemBreadcrumbs: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();

  const { index, selectedIndex, txt, menuId, onClick, isActive, keepVisible } =
    props;

  useEffect(() => {
    document.title = `${t("accessibilita.breadcrumbs.nomeBanca")} - ${txt || (menuId ? t(menuId) : "")}`
  }, [txt, menuId])

  return (
    <div className="breadcrumb-block">
      <img className="separator" src={separator} aria-hidden="true" alt="" />

      {menuId && index === 0 ? (
        <BankIcon
          title={menuId}
          tooltip={txt || (menuId ? t(menuId) : "")}
          onClick={isActive ? onClick : undefined}
          etichetta={txt || (menuId ? t(menuId) : "")}
        />
      ) : (
        index === selectedIndex ?
          <h1
            className={`
            text-item-breadcrumbs
            text-item-breadcrumbs-${selectedIndex === index ? "selected" : "unselected"
              }
            ${isActive ? "text-item-breadcrumbs-active" : ""}
            ${keepVisible ? "text-item-breadcrumbs-keep-visible" : ""}
          `}
            onClick={isActive ? onClick : undefined}
            aria-label={txt || (menuId ? t(menuId) : "")}
            tabIndex={0}
          >
            {txt || (menuId ? t(menuId) : "")}
          </h1>
          :
          <a
            className={`
          text-item-breadcrumbs
          text-item-breadcrumbs-${selectedIndex === index ? "selected" : "unselected"
              }
          ${isActive ? "text-item-breadcrumbs-active" : ""}
          ${keepVisible ? "text-item-breadcrumbs-keep-visible" : ""}
        `}
            onClick={isActive ? onClick : undefined}
            aria-label={txt || (menuId ? t(menuId) : "")}
            tabIndex={0}
          >
            {txt || (menuId ? t(menuId) : "")}
          </a>
      )}
    </div>
  );
};

export default ItemBreadcrumbs;
