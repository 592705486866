"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.WidgetAssetDebtDataDtoToJSON = exports.WidgetAssetDebtDataDtoFromJSONTyped = exports.WidgetAssetDebtDataDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function WidgetAssetDebtDataDtoFromJSON(json) {
    return WidgetAssetDebtDataDtoFromJSONTyped(json, false);
}
exports.WidgetAssetDebtDataDtoFromJSON = WidgetAssetDebtDataDtoFromJSON;
function WidgetAssetDebtDataDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'label': !runtime_1.exists(json, 'label') ? undefined : json['label'],
        'count': !runtime_1.exists(json, 'count') ? undefined : json['count'],
        'gettitle': !runtime_1.exists(json, 'gettitle') ? undefined : json['gettitle'],
        'type': !runtime_1.exists(json, 'type') ? undefined : json['type'],
        'get_count': !runtime_1.exists(json, 'get_count') ? undefined : json['get_count'],
        'get_title': !runtime_1.exists(json, 'get_title') ? undefined : json['get_title'],
    };
}
exports.WidgetAssetDebtDataDtoFromJSONTyped = WidgetAssetDebtDataDtoFromJSONTyped;
function WidgetAssetDebtDataDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'label': value.label,
        'count': value.count,
        'gettitle': value.gettitle,
        'type': value.type,
        'get_count': value.get_count,
        'get_title': value.get_title,
    };
}
exports.WidgetAssetDebtDataDtoToJSON = WidgetAssetDebtDataDtoToJSON;
