"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SectionDtoToJSON = exports.SectionDtoFromJSONTyped = exports.SectionDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function SectionDtoFromJSON(json) {
    return SectionDtoFromJSONTyped(json, false);
}
exports.SectionDtoFromJSON = SectionDtoFromJSON;
function SectionDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'bankCode': !runtime_1.exists(json, 'bankCode') ? undefined : json['bankCode'],
        'block': !runtime_1.exists(json, 'block') ? undefined : json['block'],
        'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'informationCompletionPerc': !runtime_1.exists(json, 'informationCompletionPerc') ? undefined : json['informationCompletionPerc'],
        'message': !runtime_1.exists(json, 'message') ? undefined : json['message'],
        'name': !runtime_1.exists(json, 'name') ? undefined : json['name'],
        'order': !runtime_1.exists(json, 'order') ? undefined : json['order'],
        'questions': !runtime_1.exists(json, 'questions') ? undefined : (json['questions'].map(_1.QuestionDtoFromJSON)),
        'userId': !runtime_1.exists(json, 'userId') ? undefined : json['userId'],
    };
}
exports.SectionDtoFromJSONTyped = SectionDtoFromJSONTyped;
function SectionDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'bankCode': value.bankCode,
        'block': value.block,
        'description': value.description,
        'id': value.id,
        'informationCompletionPerc': value.informationCompletionPerc,
        'message': value.message,
        'name': value.name,
        'order': value.order,
        'questions': value.questions === undefined ? undefined : (value.questions.map(_1.QuestionDtoToJSON)),
        'userId': value.userId,
    };
}
exports.SectionDtoToJSON = SectionDtoToJSON;
