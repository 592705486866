"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkUpdateBozzaMav = exports.currentBozzaMav = exports.infoAutenticazioneMav = exports.oneclickMav = exports.bozzeMav = exports.esitoRevocaMav = exports.infoRevocaMav = exports.formRevocaMav = exports.riepilogoRevocaMav = exports.esitoMav = exports.riepilogoMav = exports.disableSalvaBozzaMav = exports.formValidMav = exports.buildMav = exports.formMav = exports.parametriMav = void 0;
const moment = require("moment");
const reselect_1 = require("reselect");
const mavReducer = (state) => state;
exports.parametriMav = (0, reselect_1.createSelector)(mavReducer, (state) => {
    return state.parametri;
});
exports.formMav = (0, reselect_1.createSelector)(mavReducer, (state) => {
    return state.form;
});
exports.buildMav = (0, reselect_1.createSelector)(mavReducer, (state) => {
    var _a, _b;
    return {
        importo: state.form.importo ? Number(state.form.importo.toString().replace(',', '.')) : 0,
        numeroBollettino: state.form.codiceMAV,
        contoAddebito: ((_b = (_a = state.form.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.id) || 0,
        datiPagamento: {
            dataScadenza: moment(state.form.dataEsecuzione).toDate()
        },
        idBozzaCollegata: state.currentBozza.id
    };
});
exports.formValidMav = (0, reselect_1.createSelector)(mavReducer, (state) => {
    let formValid = false;
    if (!state.form.importoError &&
        !state.form.codiceMAVError &&
        !state.form.dataEsecuzioneError) {
        formValid = true;
    }
    return formValid;
});
exports.disableSalvaBozzaMav = (0, reselect_1.createSelector)(mavReducer, (state) => {
    let isBozzaAccepted = !state.form.bozzaSalvata &&
        (!!state.form.importo && !state.form.importoError) &&
        (!!state.form.codiceMAV && !state.form.codiceMAVError);
    return !isBozzaAccepted;
});
exports.riepilogoMav = (0, reselect_1.createSelector)(mavReducer, (state) => {
    return state.riepilogo;
});
exports.esitoMav = (0, reselect_1.createSelector)(mavReducer, (state) => {
    return state.esito;
});
exports.riepilogoRevocaMav = (0, reselect_1.createSelector)(mavReducer, (state) => {
    return state.riepilogoRevoca;
});
exports.formRevocaMav = (0, reselect_1.createSelector)(mavReducer, (state) => {
    return state.formRevoca;
});
exports.infoRevocaMav = (0, reselect_1.createSelector)(mavReducer, (state) => {
    return state.infoRevoca;
});
exports.esitoRevocaMav = (0, reselect_1.createSelector)(mavReducer, (state) => {
    return state.esitoRevoca;
});
exports.bozzeMav = (0, reselect_1.createSelector)(mavReducer, (state) => {
    return state.bozzeMav && state.bozzeMav.mavs ? state.bozzeMav.mavs.sort((a, b) => b.id - a.id) : [];
});
exports.oneclickMav = (0, reselect_1.createSelector)(mavReducer, (state) => {
    return state.oneclick;
});
exports.infoAutenticazioneMav = (0, reselect_1.createSelector)(mavReducer, (state) => {
    return state.infoAutenticazioneMav;
});
exports.currentBozzaMav = (0, reselect_1.createSelector)(mavReducer, (state) => {
    return state.currentBozza;
});
exports.checkUpdateBozzaMav = (0, reselect_1.createSelector)(mavReducer, (state) => {
    var _a;
    return !!((_a = state.currentBozza) === null || _a === void 0 ? void 0 : _a.id);
});
