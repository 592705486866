"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaeseSepaDtoToJSON = exports.PaeseSepaDtoFromJSONTyped = exports.PaeseSepaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function PaeseSepaDtoFromJSON(json) {
    return PaeseSepaDtoFromJSONTyped(json, false);
}
exports.PaeseSepaDtoFromJSON = PaeseSepaDtoFromJSON;
function PaeseSepaDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'uic': !runtime_1.exists(json, 'uic') ? undefined : json['uic'],
        'iso': !runtime_1.exists(json, 'iso') ? undefined : json['iso'],
        'descrizione': !runtime_1.exists(json, 'descrizione') ? undefined : json['descrizione'],
    };
}
exports.PaeseSepaDtoFromJSONTyped = PaeseSepaDtoFromJSONTyped;
function PaeseSepaDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'uic': value.uic,
        'iso': value.iso,
        'descrizione': value.descrizione,
    };
}
exports.PaeseSepaDtoToJSON = PaeseSepaDtoToJSON;
