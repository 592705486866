"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EsitoAutorizzazioneDisposizioneDatiAggiuntiviDtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneDatiAggiuntiviDtoToJSON = exports.EsitoAutorizzazioneDisposizioneDatiAggiuntiviDtoFromJSONTyped = exports.EsitoAutorizzazioneDisposizioneDatiAggiuntiviDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function EsitoAutorizzazioneDisposizioneDatiAggiuntiviDtoFromJSON(json) {
    return EsitoAutorizzazioneDisposizioneDatiAggiuntiviDtoFromJSONTyped(json, false);
}
exports.EsitoAutorizzazioneDisposizioneDatiAggiuntiviDtoFromJSON = EsitoAutorizzazioneDisposizioneDatiAggiuntiviDtoFromJSON;
function EsitoAutorizzazioneDisposizioneDatiAggiuntiviDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
        'codiceErrore': !runtime_1.exists(json, 'codiceErrore') ? undefined : json['codiceErrore'],
        'informazioniAutenticazioneDispositiva': !runtime_1.exists(json, 'informazioniAutenticazioneDispositiva') ? undefined : _1.InformazioniAutenticazioneDispositivaDtoFromJSON(json['informazioniAutenticazioneDispositiva']),
        'disposizione': !runtime_1.exists(json, 'disposizione') ? undefined : _1.DatiAggiuntiviRispostaDtoFromJSON(json['disposizione']),
    };
}
exports.EsitoAutorizzazioneDisposizioneDatiAggiuntiviDtoFromJSONTyped = EsitoAutorizzazioneDisposizioneDatiAggiuntiviDtoFromJSONTyped;
function EsitoAutorizzazioneDisposizioneDatiAggiuntiviDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'stato': value.stato,
        'codiceErrore': value.codiceErrore,
        'informazioniAutenticazioneDispositiva': _1.InformazioniAutenticazioneDispositivaDtoToJSON(value.informazioniAutenticazioneDispositiva),
        'disposizione': _1.DatiAggiuntiviRispostaDtoToJSON(value.disposizione),
    };
}
exports.EsitoAutorizzazioneDisposizioneDatiAggiuntiviDtoToJSON = EsitoAutorizzazioneDisposizioneDatiAggiuntiviDtoToJSON;
/**
* @export
* @enum {string}
*/
var EsitoAutorizzazioneDisposizioneDatiAggiuntiviDtoStatoEnum;
(function (EsitoAutorizzazioneDisposizioneDatiAggiuntiviDtoStatoEnum) {
    EsitoAutorizzazioneDisposizioneDatiAggiuntiviDtoStatoEnum["OK"] = "OK";
    EsitoAutorizzazioneDisposizioneDatiAggiuntiviDtoStatoEnum["CREDENZIALE_ERRATA"] = "CREDENZIALE_ERRATA";
    EsitoAutorizzazioneDisposizioneDatiAggiuntiviDtoStatoEnum["ATTENDERE"] = "ATTENDERE";
    EsitoAutorizzazioneDisposizioneDatiAggiuntiviDtoStatoEnum["ACCOUNT_BLOCCATO"] = "ACCOUNT_BLOCCATO";
    EsitoAutorizzazioneDisposizioneDatiAggiuntiviDtoStatoEnum["ACCP"] = "ACCP";
    EsitoAutorizzazioneDisposizioneDatiAggiuntiviDtoStatoEnum["ACSC"] = "ACSC";
    EsitoAutorizzazioneDisposizioneDatiAggiuntiviDtoStatoEnum["ACSP"] = "ACSP";
    EsitoAutorizzazioneDisposizioneDatiAggiuntiviDtoStatoEnum["ACTC"] = "ACTC";
    EsitoAutorizzazioneDisposizioneDatiAggiuntiviDtoStatoEnum["ACWC"] = "ACWC";
    EsitoAutorizzazioneDisposizioneDatiAggiuntiviDtoStatoEnum["ACWP"] = "ACWP";
    EsitoAutorizzazioneDisposizioneDatiAggiuntiviDtoStatoEnum["RCVD"] = "RCVD";
    EsitoAutorizzazioneDisposizioneDatiAggiuntiviDtoStatoEnum["PDNG"] = "PDNG";
    EsitoAutorizzazioneDisposizioneDatiAggiuntiviDtoStatoEnum["RJCT"] = "RJCT";
    EsitoAutorizzazioneDisposizioneDatiAggiuntiviDtoStatoEnum["CANC"] = "CANC";
})(EsitoAutorizzazioneDisposizioneDatiAggiuntiviDtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneDatiAggiuntiviDtoStatoEnum || (exports.EsitoAutorizzazioneDisposizioneDatiAggiuntiviDtoStatoEnum = {}));
