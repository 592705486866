"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrdineParametriMercatoDtoToJSON = exports.OrdineParametriMercatoDtoFromJSONTyped = exports.OrdineParametriMercatoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function OrdineParametriMercatoDtoFromJSON(json) {
    return OrdineParametriMercatoDtoFromJSONTyped(json, false);
}
exports.OrdineParametriMercatoDtoFromJSON = OrdineParametriMercatoDtoFromJSON;
function OrdineParametriMercatoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'lottoMinimo': !runtime_1.exists(json, 'lottoMinimo') ? undefined : json['lottoMinimo'],
        'lottoSuccessivo': !runtime_1.exists(json, 'lottoSuccessivo') ? undefined : json['lottoSuccessivo'],
        'codiceMercato': !runtime_1.exists(json, 'codiceMercato') ? undefined : json['codiceMercato'],
    };
}
exports.OrdineParametriMercatoDtoFromJSONTyped = OrdineParametriMercatoDtoFromJSONTyped;
function OrdineParametriMercatoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'lottoMinimo': value.lottoMinimo,
        'lottoSuccessivo': value.lottoSuccessivo,
        'codiceMercato': value.codiceMercato,
    };
}
exports.OrdineParametriMercatoDtoToJSON = OrdineParametriMercatoDtoToJSON;
