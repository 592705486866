"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.menuReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
// const initialState: ProfiloMenuDto = ProfiloMenuDtoFromJSON({});
const initialState = {
    profiloMenu: (0, api_rest_1.ProfiloMenuDtoFromJSON)({}),
    textSearch: '',
    smartOtpEnrolledUsername: '',
    notificheMenu: undefined
};
const menuReducer = (state = initialState, action) => {
    var _a, _b, _c;
    switch (action.type) {
        case __1.EReduxActionTypes.MENU_GET_MENU_SUCCESS:
            return {
                ...state,
                profiloMenu: action.payload
            };
        case __1.EReduxActionTypes.MENU_GET_MENU_SUCCESS_MOBILE:
            return {
                ...state,
                profiloMenu: action.payload
            };
        case __1.EReduxActionTypes.MENU_GET_TAGS_SUCCESS:
            return {
                ...state,
                profiloMenu: {
                    ...state.profiloMenu,
                    tags: action.payload
                }
            };
        case __1.EReduxActionTypes.MENU_GET_NOTIFICHE_MENU_SUCCESS:
            return {
                ...state,
                notificheMenu: action.payload
            };
        case __1.EReduxActionTypes.LOGOUT_SUCCESS:
            return {
                ...initialState
            };
        case __1.EReduxActionTypes.MENU_SET_TEXT_SEARCH:
            return {
                ...state,
                textSearch: action.payload
            };
        case __1.EReduxActionTypes.MENU_RESET_TEXT_SEARCH:
            return {
                ...state,
                textSearch: ''
            };
        case __1.EReduxActionTypes.MENU_SET_SMART_OTP_ENROLLED_USERNAME:
            const profiloMenu = { ...state.profiloMenu };
            if (action.payload) {
                (_c = (_b = (_a = profiloMenu.profiloItems) === null || _a === void 0 ? void 0 : _a.find((item) => item.id === 'menu.gestione_utente')) === null || _b === void 0 ? void 0 : _b.submenu) === null || _c === void 0 ? void 0 : _c.push({
                    id: 'menu.notifiche_push',
                    label: 'menu.notifiche_push'
                });
            }
            return {
                ...state,
                profiloMenu: profiloMenu,
                smartOtpEnrolledUsername: action.payload
            };
        case __1.EReduxActionTypes.RESET_ALL: // action invocata dalla logout
            return {
                ...initialState
            };
        default:
            return state;
    }
};
exports.menuReducer = menuReducer;
exports.default = exports.menuReducer;
