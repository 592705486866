"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OptionDtoToJSON = exports.OptionDtoFromJSONTyped = exports.OptionDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function OptionDtoFromJSON(json) {
    return OptionDtoFromJSONTyped(json, false);
}
exports.OptionDtoFromJSON = OptionDtoFromJSON;
function OptionDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'toggle': !runtime_1.exists(json, 'toggle') ? undefined : json['toggle'],
        'interval': !runtime_1.exists(json, 'interval') ? undefined : json['interval'],
        'codicerapporto': !runtime_1.exists(json, 'codicerapporto') ? undefined : json['codicerapporto'],
    };
}
exports.OptionDtoFromJSONTyped = OptionDtoFromJSONTyped;
function OptionDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'toggle': value.toggle,
        'interval': value.interval,
        'codicerapporto': value.codicerapporto,
    };
}
exports.OptionDtoToJSON = OptionDtoToJSON;
