import { BreakdownDto, BreakdownItemDto, DossierFiltriType, customerDossierTitoli, customerGraphicsTitoli, DatiIntestatarioDto, DossierCustomerLevelBODto, getCustomerDossierWidget, getCustomerGraphicsWidget, getDossierTitoliRapporti, rapportiDossierTitoli, resetDossierTitoliDossier, resetDossierTitoliGraphics, salvaWidgets, widgetsUtente } from "@sparkasse/commons";
import ButtonText2 from "component/buttonText2/buttonText2.component";
import { navigateToPage, WEBPAGE } from "component/history/history.component";
import LabelComponent from "component/label/label.component";
import SelectRapportoMini, { IRapporto } from "component/SelectRapportoMini/SelectRapportoMini";
import { currencyFormat, placeholderForNullOrUndefined } from "helpers/utilities";
import React, { useEffect, useMemo } from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AppState } from "reducers";
import { AnyAction, bindActionCreators, Dispatch } from "redux";

export const WIDGET_PORTAFOGLIO = "menu.portafoglio_titoli";

const WidgetDossierTitoli = (props: Props) => {

  const { t } = useTranslation();

  const {
    getDossierTitoliRapporti,
    getCustomerDossierWidget,
    getCustomerGraphicsWidget,
    resetDossierTitoliDossier,
    resetDossierTitoliGraphics,
    salvaWidgets,
    rapporti,
    customerDossier,
    customerGraphics,
    widgetsUtente
  } = props;

  const chartType = "TIPO_PRODOTTO";

  const buildDataGraphics = (item: string) => {
    const graphicItemData = customerGraphics?.filter(
      (graphicItem) => graphicItem.id == item
    );
    return graphicItemData?.[0];
  };

  const mapRapportiToItems = (rapporti: DatiIntestatarioDto[]): IRapporto[] | undefined => {
    if (!rapporti) {
      return undefined;
    }
    return rapporti?.map(rapporto => {
      return {
        id: rapporto?.ndg?.toString() || placeholderForNullOrUndefined,
        description: `${rapporto.nominativo} - ${rapporto.ndg!.toString()}`
      };
    });
  };

  const mapDossierToItems = (rapporto?: number, rapportiDossier?: DossierCustomerLevelBODto[]): IRapporto[] | undefined => {
    const _rapporto: DossierCustomerLevelBODto | undefined =
      rapportiDossier?.find(c => rapporto == Number(c.customerCode));
    if (!_rapporto) {
      return undefined;
    }
    return _rapporto?.children?.map(deposito => {
      const nDeposito = String(Number(deposito.accountCode));
      const labelDeposito = `12-${deposito.branchCode}-${deposito.accountCode}`;
      return {
        id: nDeposito,
        description: labelDeposito,
        descriptionClosed: t('dossierTitoli.depositoSelezionato', { deposito: labelDeposito })
      };
    });
  };

  const ndgs = useMemo((): IRapporto[] | undefined => {
    let _ndgs: IRapporto[] | undefined = [];
    if (!rapporti) {
      return undefined;
    }
    _ndgs = mapRapportiToItems(rapporti.filter(r => !r.rapportoDelega));
    if (!_ndgs) {
      return undefined;
    }
    const isTuttiIntestatariVisible =
      rapporti.some(r => r.rapportoCointestato) &&
      rapporti.some(r => !r.rapportoCointestato && !r.rapportoDelega);
    return (_ndgs.length > 1 && isTuttiIntestatariVisible)
      ? [
        {
          id: '-1',
          description: t('dossierTitoli.tutiRapporti')
        },
        ..._ndgs
      ]
      : _ndgs;
  }, [rapporti, t]);

  const selectedNdg = useMemo((): number | undefined => {
    return ndgs && ndgs?.length > 0
      ? Number(
        ndgs?.find(rapporto =>
          Number(rapporto?.id) == widgetsUtente?.widgets?.find(w => w.widgetId === WIDGET_PORTAFOGLIO)?.option?.interval
        )?.id || ndgs?.[0]?.id)
      : undefined;
  }, [widgetsUtente, ndgs]);

  const depositi = useMemo((): IRapporto[] | undefined => {
    let _depositi: IRapporto[] | undefined = [];
    if (!(customerDossier?.data?.children && customerDossier?.data?.children?.length > 0)) {
      return undefined;
    }
    if (!selectedNdg) {
      return undefined;
    }
    if (!(rapporti && rapporti?.length > 0)) {
      return undefined;
    }
    if (selectedNdg == -1) {
      rapporti?.forEach(r => {
        if (r.rapportoDelega) {
          return;
        }
        const __rapporti = mapDossierToItems(r.ndg, customerDossier?.data?.children);
        if (!__rapporti) {
          return;
        }
        if (!_depositi) {
          _depositi = [
            ...__rapporti
          ];
          return;
        }
        _depositi = [
          ..._depositi,
          ...__rapporti
        ];
      });
    } else {
      _depositi = mapDossierToItems(selectedNdg, customerDossier.data.children);
    }
    if (!_depositi) {
      return undefined;
    }
    return _depositi.length > 1
      ? [
        {
          id: '-1',
          description: t('dossierTitoli.tuttiDepositi')
        },
        ..._depositi
      ]
      : _depositi;
  }, [rapporti, selectedNdg, customerDossier, t]);

  const selectedDeposito = useMemo((): number | undefined => {
    return depositi && depositi?.length > 0
      ? Number(
        depositi?.find(deposito =>
          Number(deposito.id) == Number(widgetsUtente?.widgets?.find(w => w.widgetId === WIDGET_PORTAFOGLIO)?.option?.codicerapporto)
        )?.id || depositi?.[0]?.id)
      : undefined;
  }, [widgetsUtente, depositi]);

  type Data = {
    ctvMercato: number,
    ctvAcquisto: number,
    plusMinusEuro: number,
    plusMinusPerc: number
  };

  const selectedDepositoData = useMemo((): Data | undefined => {
    if (!customerDossier?.data) {
      return undefined;
    }

    if (selectedDeposito != -1) {
      const deposito =
        customerDossier.data.children?.
          find(r => r?.children?.some(d => Number(d.accountCode) == selectedDeposito))?.children?.
          find(d => Number(d.accountCode) == selectedDeposito);

      if (!deposito) {
        return undefined;
      }

      if (!(deposito.ctvMercato && deposito.ctvAcquisto && deposito.plusMinusEuro && deposito.plusMinusPerc)) {
        return undefined;
      }

      return {
        ctvMercato: deposito.ctvMercato,
        ctvAcquisto: deposito.ctvAcquisto,
        plusMinusEuro: deposito.plusMinusEuro,
        plusMinusPerc: deposito.plusMinusPerc
      };
    }

    if (selectedNdg == -1) {

      if (!(customerDossier.data.ctvMercato && customerDossier.data.ctvAcquisto && customerDossier.data.plusMinusEuro && customerDossier.data.plusMinusPerc)) {
        return undefined;
      }

      return {
        ctvMercato: customerDossier.data.ctvMercato,
        ctvAcquisto: customerDossier.data.ctvAcquisto,
        plusMinusEuro: customerDossier.data.plusMinusEuro,
        plusMinusPerc: customerDossier.data.plusMinusPerc
      };
    }

    const ndg: DossierCustomerLevelBODto | undefined = customerDossier?.data?.children?.find(r => Number(r.customerCode) == selectedNdg);

    if (!ndg || !(ndg.ctvMercato && ndg.ctvAcquisto && ndg.plusMinusEuro && ndg.plusMinusPerc)) {
      return undefined;
    }

    return {
      ctvMercato: ndg.ctvMercato,
      ctvAcquisto: ndg.ctvAcquisto,
      plusMinusEuro: ndg.plusMinusEuro,
      plusMinusPerc: ndg.plusMinusPerc
    };
  }, [selectedDeposito, customerDossier]);

  const onSelectAccount = (ndg?: number, deposito?: string) => {
    salvaWidgets((widgetsUtente?.widgets?.map(w => w.widgetId === WIDGET_PORTAFOGLIO ? {
      ...w,
      option: {
        toggle: true,
        interval: Number(ndg),
        codicerapporto: deposito
      }
    } : w) || []));
  };

  const handleNdgChange = (item: IRapporto) => {
    resetDossierTitoliDossier();
    resetDossierTitoliGraphics();
    onSelectAccount(Number(item.id), String(selectedDeposito));
  };

  const handleDepositoChange = (item: IRapporto) => {
    resetDossierTitoliGraphics();
    onSelectAccount(selectedNdg, item.id);
  };

  useEffect(() => {
    if (!rapporti) {
      getDossierTitoliRapporti();
    }
  }, [rapporti]);

  useEffect(() => {
    if (selectedNdg) {
      getCustomerDossierWidget(getFiltri());
    }
  }, [selectedNdg]);

  useEffect(() => {
    if (selectedDeposito) {
      getCustomerGraphicsWidget(getFiltri());
    }
  }, [selectedDeposito]);

  const getFiltri = (): DossierFiltriType => {
    let depositiSelezionati: string[] | undefined = [];

    if (selectedDeposito && selectedDeposito != -1) {
      depositiSelezionati = [String(selectedDeposito)];
    }

    return {
      tuttiRapporti: selectedNdg == -1,
      rapportoSelezionato:
        rapporti?.find(r => r.ndg == selectedNdg) ||
        rapporti?.find(r => !r.rapportoCointestato && !r.rapportoDelega) ||
        rapporti?.find(r => r.rapportoCointestato) || {},
      depositiSelezionati: depositiSelezionati || []
    }
  };

  return (
    <div className="widget widget-dossier">

      <p className="widget-title">
        {t("home.widgets.dossierTitoli.titolo")}
      </p>

      <div className="underline-short" />

      {ndgs && ndgs?.length > 1
        ? (
          <SelectRapportoMini
            onSelect={handleNdgChange}
            items={ndgs}
            selectedItem={String(selectedNdg)}
          />
        ) : (
          <LabelComponent
            value={ndgs?.[0] ? ndgs[0].description : ''}
          />
        )
      }

      {depositi && depositi?.length > 1
        ? (
          <SelectRapportoMini
            onSelect={handleDepositoChange}
            items={depositi}
            selectedItem={String(selectedDeposito)}
          />
        ) : (
          <LabelComponent
            value={depositi?.[0] ? depositi[0].descriptionClosed : ''}
          />
        )
      }

      {customerGraphics && customerGraphics.length > 0 &&
        <DossierTitoliWidgetChart
          dossierTitle={t(`dossierTitoli.graphics.titles.${chartType}`)}
          data={
            buildDataGraphics(chartType)
          }
          ctvMercato={selectedDepositoData?.ctvMercato}
          ctvAcquisto={selectedDepositoData?.ctvAcquisto}
          plusMinEur={selectedDepositoData?.plusMinusEuro}
          plusMinPerc={selectedDepositoData?.plusMinusPerc}
        />
      }

      {customerGraphics && customerGraphics.length == 0 &&
        <p className="widget-placeholder">
          {t('home.widgets.dossierTitoli.nessunDato')}
        </p>
      }

      <ButtonText2
        className="widget-vai-a-dettaglio"
        onClick={() => {
          navigateToPage(WEBPAGE.DossierTitoli.url, { ndg: selectedNdg, deposito: selectedDeposito });
        }}
        img="navigazione_avanti"
        descrizione={t("home.widgets.dossierTitoli.dossierdettaglio")}
      />
    </div>
  );
};

interface iProps {
  dossierTitle: string
  data?: BreakdownDto
  ctvMercato?: number
  ctvAcquisto?: number
  plusMinEur?: number
  plusMinPerc?: number
  className?: string
  chartSize?: string
}

const DossierTitoliWidgetChart: React.FC<iProps> = (props: iProps) => {

  const { t } = useTranslation();

  const {
    dossierTitle,
    data,
    ctvMercato,
    ctvAcquisto,
    plusMinEur,
    plusMinPerc,
    className,
    chartSize
  } = props;

  const option: any = {
    states: {
      active: {
        filter: {
          type: 'none',
          value: 0
        }
      },
      hover: {
        filter: {
          type: 'none',
          value: 0
        }
      }
    },
    plotOptions: {
      pie: {
        size: 160,
        expandOnClick: false
      }
    },
    dataLabels: { enabled: false },
    labels: data?.breakdownItems?.map((d: BreakdownItemDto) => t(`dossierTitoli.graphics.types.${data.id}.${d.id}`)),
    legend: { show: false },
    colors: data?.breakdownItems?.map((d: BreakdownItemDto) => d.color),
    fill: {
      colors: data?.breakdownItems?.map((d: BreakdownItemDto) => d.color)
    },
    tooltip: {
      enabled: true,
      fillSeriesColor: false,
      y: {
        formatter: function (val: any) {
          return val + "%";
        },
        title: {
          formatter: function (seriesName: any) {
            return seriesName;
          }
        }
      }
    }
  };

  return (
    <div className={`widget-dossier ${className || ""}`}>

      <p className="widget-title">
        {dossierTitle}
      </p>

      <div className="chartView-widget-patrimonio">
        <Chart
          options={option}
          series={data?.breakdownItems?.map((d: BreakdownItemDto) => Number(((d.balancePercentage || 0) * 100).toFixed(2)))}
          type="donut"
          width={chartSize || "160"}
          height={chartSize || "160"}
        />
        <div className="titleChartView-widget-patrimonio">
          <p className="txtTitleChart-widget-patrimonio">
            {t('widgetDossierTitoli.controvaloreMercato')}
          </p>
          <p className="txtAmountChart-widget-patrimonio is-saldo">
            {currencyFormat(ctvMercato)}
            <span className="txtAmountChart-widget-patrimonio_value ml-1">
              &nbsp;EUR
              </span>
          </p>
          {data?.breakdownItems?.map((d: BreakdownItemDto, i: number) => {
            return (d.balance != null && d.balance > 0 &&
              <div key={`item-${i}`} className="legendView1-widget-patrimonio">
                <div className="legendView1col1-widget-patrimonio">
                  <div
                    style={{
                      backgroundColor: d.color,
                      height: 20,
                      width: 20,
                      marginRight: 12
                    }}
                  ></div>
                  <p className="txtTitleLegend-widget-patrimonio ml-3">
                    {t(`dossierTitoli.graphics.types.${data.id}.${d.id}`)}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="chartView-widget-prestiti">
        <div className="widget-info-col-prestiti">
          <div className="widget-info-col-prestiti-label">{t('widgetDossierTitoli.controvaloreAcquisto')}</div>
          <div className="widget-info-col-prestiti-value is-saldo">
            {currencyFormat(ctvAcquisto)}
            <span className="txtAmountChart-widget-patrimonio_value" style={{ fontSize: '12px' }}>
              &nbsp;EUR
            </span>
          </div>
        </div>
        <div className="widget-info-col-prestiti">
          <div className="widget-info-col-prestiti-label">{t('widgetDossierTitoli.totaleVariazione')}</div>
          <div className="widget-info-col-prestiti-value is-saldo">
            <span style={{ color: Math.sign(plusMinEur ?? 0) === 1 ? '#006633' : '#b2001c' }}>
              {currencyFormat(plusMinEur)}
            </span>
            <span className="txtAmountChart-widget-patrimonio_value ml-1" style={{ fontSize: '12px' }}>
              &nbsp;EUR
              <span>{` (${(plusMinPerc ?? 0).toFixed(2).toString().replace(".", ",")} %)`}</span>
            </span>
          </div>
        </div>
      </div>

    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  rapporti: rapportiDossierTitoli(state.dossierTitoli),
  customerDossier: customerDossierTitoli(state.dossierTitoli),
  customerGraphics: customerGraphicsTitoli(state.dossierTitoli),
  widgetsUtente: widgetsUtente(state.widgets)
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getDossierTitoliRapporti,
      getCustomerDossierWidget,
      getCustomerGraphicsWidget,
      resetDossierTitoliDossier,
      resetDossierTitoliGraphics,
      salvaWidgets
    },
    dispatch
  );

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WidgetDossierTitoli);
