"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filialeVirtualeReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const helpers_1 = require("../helpers");
const filialeVirtuale_types_1 = require("./filialeVirtuale.types");
const initialState = {
    filter: (0, filialeVirtuale_types_1.FilterFilialeVirtualeFromJSONTyped)({}),
    parametri: (0, api_rest_1.ParametriDtoFromJSON)({}),
    templateRichieste: undefined,
    motiviRichieste: undefined,
    templateSelezionato: undefined,
    form: (0, filialeVirtuale_types_1.FormTypeFilialeVirtualeFromJSONTyped)({}),
    esito: (0, api_rest_1.EsitoAutorizzazioneDisposizioneFilialeVirtualeDtoFromJSON)({}),
    infoAutenticazione: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
    riepilogo: (0, api_rest_1.DettaglioRichiestaDtoFromJSON)({}),
    elencoRichieste: undefined,
    contatti: (0, api_rest_1.NumeroContattiDtoFromJSON)({}),
    rapporti: (0, api_rest_1.TemplateDtoFromJSON)({}),
    dettaglioRichiesta: (0, api_rest_1.DettaglioRichiestaDtoFromJSON)({})
};
const filialeVirtualeReducer = (state = initialState, action) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    switch (action.type) {
        case __1.EReduxActionTypes.FILIALE_VIRTUALE_GET_PARAMETRI_SUCCESS:
            return {
                ...initialState,
                parametri: action.payload,
                filter: {
                    ...initialState.filter,
                    dataRichiestaDa: action.payload.parametri && action.payload.parametri['DEFAULT.FILIALEVIRTUALENEW.DATA_INIZIALE'] ? new Date(action.payload.parametri['DEFAULT.FILIALEVIRTUALENEW.DATA_INIZIALE'].toString()) : undefined,
                    dataRichiestaA: action.payload.parametri && action.payload.parametri['DEFAULT.FILIALEVIRTUALENEW.DATA_FINALE'] ? new Date(action.payload.parametri['DEFAULT.FILIALEVIRTUALENEW.DATA_FINALE'].toString()) : undefined
                }
            };
        case __1.EReduxActionTypes.FILIALE_VIRTUALE_GET_ELENCO_TEMPLATE_SUCCESS:
            return {
                ...state,
                templateRichieste: action.payload.templates,
                motiviRichieste: action.payload.motiviRichiesta
            };
        case __1.EReduxActionTypes.FILIALE_VIRTUALE_RESET_FILTRI:
            return {
                ...state,
                filter: {
                    ...initialState.filter,
                    dataRichiestaDa: ((_a = state.parametri) === null || _a === void 0 ? void 0 : _a.parametri) && state.parametri.parametri['DEFAULT.FILIALEVIRTUALENEW.DATA_INIZIALE'] ? new Date(state.parametri.parametri['DEFAULT.FILIALEVIRTUALENEW.DATA_INIZIALE'].toString()) : undefined,
                    dataRichiestaA: ((_b = state.parametri) === null || _b === void 0 ? void 0 : _b.parametri) && state.parametri.parametri['DEFAULT.FILIALEVIRTUALENEW.DATA_FINALE'] ? new Date(state.parametri.parametri['DEFAULT.FILIALEVIRTUALENEW.DATA_FINALE'].toString()) : undefined
                }
            };
        case __1.EReduxActionTypes.FILIALE_VIRTUALE_DESCRIZIONE:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    filterDirty: true,
                    descrizione: action.payload
                }
            };
        case __1.EReduxActionTypes.FILIALE_VIRTUALE_DATA_RICHIESTA_DA:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    filterDirty: true,
                    dataRichiestaDa: new Date(action.payload)
                }
            };
        case __1.EReduxActionTypes.FILIALE_VIRTUALE_DATA_RICHIESTA_A:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    filterDirty: true,
                    dataRichiestaA: new Date(action.payload)
                }
            };
        case __1.EReduxActionTypes.FILIALE_VIRTUALE_GET_INFO_AUTENTICAZIONE_SUCCESS:
            return {
                ...state,
                infoAutenticazione: action.payload,
                esito: initialState.esito
            };
        case __1.EReduxActionTypes.FILIALE_VIRTUALE_RESET_INFO_AUTENTICAZIONE:
            return {
                ...state,
                infoAutenticazione: initialState.infoAutenticazione,
                esito: initialState.esito
            };
        case __1.EReduxActionTypes.FILIALE_VIRTUALE_AUTORIZZA_SUCCESS:
            if (action.payload.stato === 'OK') {
                return {
                    ...state,
                    //riepilogo: initialState.riepilogo,
                    esito: action.payload
                };
            }
            else {
                return {
                    ...state,
                    infoAutenticazione: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoAutenticazione, action.payload.informazioniAutenticazioneDispositiva),
                    esito: action.payload
                };
            }
        case __1.EReduxActionTypes.FILIALE_VIRTUALE_RESET_RIEPILOGO: {
            return {
                ...state,
                riepilogo: initialState.riepilogo
            };
        }
        case __1.EReduxActionTypes.FILIALE_VIRTUALE_STEP: {
            return {
                ...state,
                form: {
                    ...state.form,
                    step: action.payload
                }
            };
        }
        case __1.EReduxActionTypes.FILIALE_VIRTUALE_SET_TIPO: {
            return {
                ...state,
                form: {
                    ...state.form,
                    tipo: action.payload
                }
            };
        }
        case __1.EReduxActionTypes.FILIALE_VIRTUALE_TEMPLATE_SELEZIONATO: {
            const campiMapped = (_c = action.payload.campi) === null || _c === void 0 ? void 0 : _c.map((e) => {
                e.error = e.obbligatorio ? 'invalid.required' : '';
                return e;
            });
            return {
                ...state,
                templateSelezionato: { ...action.payload, campi: campiMapped },
                form: {
                    ...state.form,
                    formSubmitted: false
                }
            };
        }
        case __1.EReduxActionTypes.FILIALE_VIRTUALE_RESET_STEP: {
            return {
                ...state,
                form: {
                    ...state.form,
                    step: 0
                }
            };
        }
        case __1.EReduxActionTypes.FILIALE_VIRTUALE_SET_FORMSUBMITTED: {
            return {
                ...state,
                form: {
                    ...state.form,
                    formSubmitted: action.payload
                }
            };
        }
        case __1.EReduxActionTypes.FILIALE_VIRTUALE_RESET_FORM: {
            return {
                ...state,
                form: initialState.form,
                templateSelezionato: initialState.templateSelezionato,
                riepilogo: initialState.riepilogo,
                esito: initialState.esito
            };
        }
        case __1.EReduxActionTypes.FILIALE_VIRTUALE_GET_CONTATTI_SUCCESS: {
            return {
                ...state,
                contatti: action.payload
            };
        }
        case __1.EReduxActionTypes.FILIALE_VIRTUALE_GET_RAPPORTI_SUCCESS: {
            const campiMapped = (_d = action.payload.campi) === null || _d === void 0 ? void 0 : _d.map((e) => {
                e.error = e.obbligatorio ? 'error' : '';
                return e;
            });
            return {
                ...state,
                templateSelezionato: { ...action.payload, campi: campiMapped }
            };
        }
        case __1.EReduxActionTypes.FILIALE_VIRTUALE_GET_DETTAGLIO_SUCCESS: {
            return {
                ...state,
                dettaglioRichiesta: action.payload
            };
        }
        case __1.EReduxActionTypes.FILIALE_VIRTUALE_RESET_DETTAGLIO: {
            return {
                ...state,
                dettaglioRichiesta: initialState.dettaglioRichiesta
            };
        }
        case __1.EReduxActionTypes.FILIALE_VIRTUALE_GET_ELENCO_RICHIESTE_SUCCESS: {
            return {
                ...state,
                elencoRichieste: action.payload
            };
        }
        case __1.EReduxActionTypes.FILIALE_VIRTUALE_SALVA_RICHIESTA_SUCCESS:
            return {
                ...state,
                riepilogo: action.payload
            };
        case __1.EReduxActionTypes.FILIALE_VIRTUALE_ELIMINA_RICHIESTA_SUCCESS:
            return {
                ...state,
                elencoRichieste: (_e = state.elencoRichieste) === null || _e === void 0 ? void 0 : _e.filter((list) => (list === null || list === void 0 ? void 0 : list.id) !== action.payload)
            };
        case __1.EReduxActionTypes.FILIALE_VIRTUALE_SET_VALUE_TEMPLATE:
            {
                let itemSel = action.payload;
                let templateSel = state.templateSelezionato;
                if (!!itemSel.obbligatorio && itemSel.value === '') {
                    itemSel.error = 'error';
                }
                else {
                    itemSel.error = '';
                }
                if (itemSel.tipoCampo === 'Importo' || itemSel.tipoCampo === 'Numerico') {
                    itemSel.error = (0, helpers_1.validateNumericField)(itemSel.value, itemSel.operatoreConfronto, itemSel.importoConfronto, itemSel.obbligatorio) ? '' : 'error';
                }
                if (itemSel.tipoCampo === 'e-Mail') {
                    itemSel.error = (0, helpers_1.validationEmail)((_f = itemSel.value) !== null && _f !== void 0 ? _f : '', !!itemSel.obbligatorio);
                }
                const foundIndexCampo = (templateSel === null || templateSel === void 0 ? void 0 : templateSel.campi) ? (_g = templateSel === null || templateSel === void 0 ? void 0 : templateSel.campi) === null || _g === void 0 ? void 0 : _g.findIndex((item) => item.id === ((itemSel === null || itemSel === void 0 ? void 0 : itemSel.id) || 0)) : 0;
                const newListInv = Object.assign([], templateSel === null || templateSel === void 0 ? void 0 : templateSel.campi, { [foundIndexCampo]: itemSel });
                return {
                    ...state,
                    templateSelezionato: {
                        ...state.templateSelezionato,
                        campi: newListInv
                    }
                };
            }
        case __1.EReduxActionTypes.FILIALE_VIRTUALE_GET_CATALOGO_TEMPLATE_SUCCESS: {
            const campiMapped = (_h = action.payload.template.campi) === null || _h === void 0 ? void 0 : _h.map((e, i) => {
                if (i === 0) {
                    e = {
                        ...e,
                        value: action.payload.title
                    };
                }
                else {
                    e.error = e.obbligatorio ? 'error' : '';
                }
                return e;
            });
            return {
                ...state,
                templateSelezionato: { ...action.payload.template, campi: campiMapped },
                form: {
                    ...state.form,
                    formSubmitted: false
                }
            };
        }
        case __1.EReduxActionTypes.GENERAL_RESET_POST_CAMBIO_LINGUA: // action invocata dal cambio lingua
            return {
                ...state,
                contatti: initialState.contatti
            };
        case __1.EReduxActionTypes.RESET_ALL: // action invocata dalla logout
            return {
                ...initialState
            };
        default:
            return state;
    }
};
exports.filialeVirtualeReducer = filialeVirtualeReducer;
