import { currencyFormat } from "helpers/utilities";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface iProps {
  //   title: string;/
  expandable: boolean;
  expand?: boolean;
  isAssicurazione?: boolean;
  children?: JSX.Element;
  //   value: number;
  tipoTitle?: string;
  tipoValue?: string;
  fielName: string;
  item?: any;
}

const PatrimonioAccordion = (props: iProps) => {
  const {
    // title,
    expandable,
    expand,
    children,
    // value,
    tipoTitle,
    tipoValue,
    item,
    fielName,
    isAssicurazione
  } = props;
  const [arrow, setArrow] = useState(expand);
  const { t } = useTranslation();

  useEffect(() => {
    setArrow(!!expand);
  }, [expand]);


  return (
    <div className={`accordion-body accordion-container`}>
      <div className="accordion-title-tab">
        {!isAssicurazione && <p>{item.tipologiaDesc || t(`patrimonio.finanziamenti.tipologia.${item.tipologia}`)} </p>}
        <div className="accordion-value-tab">
          <p className="accordion-summary_value">
            {currencyFormat(Math.abs(item[fielName]))} EUR
                </p>
          {expandable && (
            <span
              className={`${!arrow ? "" : "reverse"}`}
              onClick={() => {
                setArrow(!arrow);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="44"
                height="44"
                viewBox="0 0 44 44"
              >
                <path
                  fill={arrow ? "#e20714" : "#9D9D9D"}
                  fillRule="evenodd"
                  d="M18 11.682L25.145 18.809 18.019 25.955 19.265 27.197 27.634 18.805 19.243 10.436z"
                  transform={`rotate(${arrow ? "90" : "270"
                    } 22.817 18.817)`}
                />
              </svg>
            </span>
          )}
        </div>
      </div>
      <div
        className={`accordion-row ${!arrow ? "" : "accordion-row-resize"}`}
      >
        {children}
      </div>
    </div>
  );
};
export default PatrimonioAccordion
