"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.compravenditaReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const moment = require("moment");
const __1 = require("..");
const helpers_1 = require("../helpers");
const helpers_2 = require("./../helpers");
const compravendita_types_1 = require("./compravendita.types");
const initialState = {
    parametri: (0, api_rest_1.ParametriFinanzaDtoFromJSON)({}),
    parametriAvanzati: (0, api_rest_1.OrdineParametriDtoFromJSON)({}),
    quotazioneTitolo: (0, api_rest_1.QuotazioneDettaglioTitoloDtoFromJSON)({}),
    bookTitolo: (0, api_rest_1.BookDtoFromJSON)({}),
    form: (0, compravendita_types_1.FormCompravenditaDtoFromJSONTyped)({}),
    riepilogo: (0, api_rest_1.OrdineTradingDtoFromJSON)({}),
    infoDispositiva: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
    esito: (0, api_rest_1.EsitoAutorizzazioneDisposizioneFinanzaDtoFromJSON)({}),
    dossierRapporti: [],
    customerDossier: (0, api_rest_1.DossierBODtoFromJSON)({})
    // riepilogoRevoca: BonificoSepaDtoFromJSON({}),
    // formRevoca: FormRevocaBonificoDepositoDtoFromJSON({}),
    // infoRevoca: InformazioniAutenticazioneDispositivaDtoFromJSON({}),
    // esitoRevoca: EsitoAutorizzazioneDisposizioneBonificoDtoFromJSON({}),
};
const compravenditaReducer = (state = initialState, action) => {
    var _a, _b;
    switch (action.type) {
        case compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_GET_PARAMETRI_COMPRAVENDITA_SUCCESS:
            let tipiPrezzo = (action.payload.parametri ? action.payload.parametri['TRADING.TIPO_PREZZO'] : []) || [];
            if (state.form.segnoOrdine === 'A') {
                tipiPrezzo = tipiPrezzo.filter(e => e !== 'SL' && e !== 'SLL');
            }
            else if (state.form.segnoOrdine === 'V') {
                tipiPrezzo = tipiPrezzo.filter(e => e !== 'SB' && e !== 'SBL');
            }
            const parametri = action.payload.parametri;
            parametri && (parametri['TRADING.TIPO_PREZZO'] = tipiPrezzo);
            return {
                ...initialState,
                parametri: {
                    ...action.payload,
                    parametri: {
                        ...parametri
                    }
                },
                dossierRapporti: state.dossierRapporti,
                form: {
                    ...initialState.form,
                    codiceIsin: state.form.codiceIsin,
                    mercatoEsterno: state.form.mercatoEsterno,
                    mercatoOrdine: state.form.mercatoOrdine,
                    segnoOrdine: state.form.segnoOrdine,
                    dossierRapportoSelezionato: state.form.dossierRapportoSelezionato,
                    dossierDepositoSelezionato: state.form.dossierDepositoSelezionato,
                }
            };
        case compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_DOSSIER_TITOLI_GET_RAPPORTI_SUCCESS:
            return {
                ...state,
                dossierRapporti: action.payload
            };
        case compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_GET_PARAMETRI_AVANZATI_COMPRAVENDITA_SUCCESS:
            return {
                ...state,
                parametriAvanzati: action.payload
            };
        case compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_GET_QUOTAZIONE_DETTAGLIO_TITOLO_SUCCESS:
            return {
                ...state,
                quotazioneTitolo: action.payload
            };
        case compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_GET_BOOK_TITOLO_SUCCESS:
            return {
                ...state,
                bookTitolo: action.payload
            };
        case compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_CONTROLLO_COMPRAVENDITA_TITOLO_SUCCESS:
            return {
                ...state,
                riepilogo: action.payload
            };
        case compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_GET_INFO_DISPOSITIVA_COMPRAVENDITA_TITOLO_SUCCESS:
            return {
                ...state,
                infoDispositiva: action.payload,
                esito: initialState.esito
            };
        case compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_AUTORIZZA_COMPRAVENDITA_TITOLO_SUCCESS:
            if (action.payload.stato === 'OK') {
                return {
                    ...state,
                    //riepilogo: initialState.riepilogo,
                    esito: action.payload
                };
            }
            else {
                return {
                    ...state,
                    infoDispositiva: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoDispositiva, action.payload.informazioniAutenticazioneDispositiva),
                    esito: action.payload
                };
            }
        case compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_STEP:
            return {
                ...state,
                form: {
                    ...state.form,
                    step: action.payload
                }
            };
        case compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_DOSSIER_RAPPORTO_SELEZIONATO:
            return {
                ...state,
                form: {
                    ...state.form,
                    dossierRapportoSelezionato: action.payload
                }
            };
        case compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_COSTUMER_DOSSIER_SUCCESS:
            return {
                ...state,
                customerDossier: action.payload
            };
        case compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_DOSSIER_DEPOSITO_SELEZIONATO:
            return {
                ...state,
                form: {
                    ...state.form,
                    dossierDepositoSelezionato: action.payload
                }
            };
        case compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_CODICE_ISIN:
            return {
                ...state,
                form: {
                    ...state.form,
                    codiceIsin: action.payload
                }
            };
        case compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_MERCATO_ESTERNO:
            return {
                ...state,
                form: {
                    ...state.form,
                    mercatoEsterno: action.payload
                }
            };
        case compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_MERCATO_ORDINE:
            return {
                ...state,
                form: {
                    ...state.form,
                    mercatoOrdine: action.payload
                }
            };
        case compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_SEGNO_ORDINE:
            return {
                ...state,
                form: {
                    ...state.form,
                    segnoOrdine: action.payload
                }
            };
        case compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_QUANTITA:
            return {
                ...state,
                form: {
                    ...state.form,
                    quantita: action.payload,
                    quantitaDirty: true,
                    quantitaError: (0, helpers_1.validationField)(action.payload, 0)
                }
            };
        case compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_TIPO_PREZZO:
            let prezzoStartStopEnabled = (action.payload == 'SBL' || action.payload == 'SB' || action.payload == 'SLL' || action.payload == 'SL');
            let prezzoLimiteEnabled = (action.payload == 'SBL' || action.payload == 'SLL' || action.payload == 'S');
            return {
                ...state,
                form: {
                    ...state.form,
                    tipoPrezzo: action.payload,
                    prezzoStartStop: prezzoStartStopEnabled ? state.form.prezzoStartStop : initialState.form.prezzoStartStop,
                    prezzoStartStopEnabled: prezzoStartStopEnabled,
                    prezzoStartStopDirty: prezzoStartStopEnabled ? state.form.prezzoStartStopDirty : initialState.form.prezzoStartStopDirty,
                    prezzoStartStopError: prezzoStartStopEnabled ? state.form.prezzoStartStopError : initialState.form.prezzoStartStopError,
                    prezzoLimite: prezzoLimiteEnabled ? state.form.prezzoLimite : initialState.form.prezzoLimite,
                    prezzoLimiteEnabled: prezzoLimiteEnabled,
                    prezzoLimiteDirty: prezzoLimiteEnabled ? state.form.prezzoLimiteDirty : initialState.form.prezzoLimiteDirty,
                    prezzoLimiteError: prezzoLimiteEnabled ? state.form.prezzoLimiteError : initialState.form.prezzoLimiteError
                }
            };
        case compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_PREZZO_START_STOP:
            return {
                ...state,
                form: {
                    ...state.form,
                    prezzoStartStop: action.payload,
                    prezzoStartStopDirty: true,
                    prezzoStartStopError: (0, helpers_1.validationField)(action.payload, 0),
                    prezzoLimiteError: (0, helpers_2.validationPrezzo)(state.form.prezzoLimite || 0, action.payload, state.form.segnoOrdine, state.form.prezzoLimite || 0)
                }
            };
        case compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_PREZZO_LIMITE:
            return {
                ...state,
                form: {
                    ...state.form,
                    prezzoLimite: action.payload,
                    prezzoLimiteDirty: true,
                    prezzoLimiteError: (0, helpers_2.validationPrezzo)(action.payload, state.form.prezzoStartStop, state.form.segnoOrdine, action.payload)
                }
            };
        case compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_ESECUZIONE:
            return {
                ...state,
                form: {
                    ...state.form,
                    esecuzione: action.payload
                }
            };
        case compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_VALIDITA:
            let dataOdierna = action.payload == '';
            const festivi = (_b = (_a = state.parametri) === null || _a === void 0 ? void 0 : _a.parametri) === null || _b === void 0 ? void 0 : _b['FINANZA.FESTIVI'];
            const primoGiornoUtile = (0, helpers_1.getValidDayByMonth)(moment().add(1, 'day').date(), moment().month() + 1, moment().year(), festivi);
            let newData = dataOdierna ?
                moment().format('YYYY-MM-DD')
                :
                    moment().date(primoGiornoUtile).format('YYYY-MM-DD');
            return {
                ...state,
                form: {
                    ...state.form,
                    validita: action.payload,
                    validitaDirty: true,
                    validitaError: action.payload ? '' : 'invalid.required',
                    data: newData,
                    dataEnabled: !dataOdierna,
                    dataDirty: true,
                    dataError: (0, helpers_1.validationFieldDate)(newData, state.parametri && state.parametri.parametri ? state.parametri.parametri['FINANZA.FESTIVI'] : [], // festivi
                    true, newData, moment().add(30, 'days').format('YYYY-MM-DD'))
                }
            };
        case compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_DATA:
            return {
                ...state,
                form: {
                    ...state.form,
                    data: action.payload,
                    dataDirty: true,
                    dataError: (0, helpers_1.validationFieldDate)(action.payload, state.parametri && state.parametri.parametri ? state.parametri.parametri['FINANZA.FESTIVI'] : [], // festivi
                    true, state.form.validita == "" ? moment().format('YYYY-MM-DD') : moment().add(1, "day").format('YYYY-MM-DD'), moment().add(30, 'days').format('YYYY-MM-DD'))
                }
            };
        case compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_TIPO_QUANTITA:
            return {
                ...state,
                form: {
                    ...state.form,
                    tipoQuantita: action.payload
                }
            };
        case compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_ACCORDION_PARAMETRI_AGGIUNTIVI_OPENED:
            return {
                ...state,
                form: {
                    ...state.form,
                    accordionParametriAggiuntiviOpened: action.payload
                }
            };
        case compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_FORM_SUBMITTED:
            return {
                ...state,
                form: {
                    ...state.form,
                    formSubmitted: action.payload
                }
            };
        case compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_PRESA_VISIONE_SCHEDA_TITOLO_ENABLED:
            return {
                ...state,
                form: {
                    ...state.form,
                    presaVisioneSchedaTitoloEnabled: action.payload
                }
            };
        case compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_PRESA_VISIONE_SCHEDA_TITOLO:
            return {
                ...state,
                form: {
                    ...state.form,
                    presaVisioneSchedaTitolo: action.payload
                }
            };
        case compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_PRESA_VISIONE_KIID_ENABLED:
            return {
                ...state,
                form: {
                    ...state.form,
                    presaVisioneKiidEnabled: action.payload
                }
            };
        case compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_PRESA_VISIONE_KIID:
            return {
                ...state,
                form: {
                    ...state.form,
                    presaVisioneKiid: action.payload
                }
            };
        case compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_PRESA_VISIONE_COSTI:
            return {
                ...state,
                form: {
                    ...state.form,
                    presaVisioneCosti: action.payload
                }
            };
        case compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_RESET_RIEPILOGO:
            return {
                ...state,
                riepilogo: initialState.riepilogo,
                form: {
                    ...state.form,
                    presaVisioneSchedaTitoloEnabled: initialState.form.presaVisioneSchedaTitoloEnabled,
                    presaVisioneSchedaTitolo: initialState.form.presaVisioneSchedaTitolo,
                    presaVisioneKiidEnabled: initialState.form.presaVisioneKiidEnabled,
                    presaVisioneKiid: initialState.form.presaVisioneKiid
                }
            };
        case compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_RESET_DOSSIER:
            return {
                ...state,
                customerDossier: initialState.customerDossier
            };
        case compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_RESET:
            return {
                ...initialState
            };
        case compravendita_types_1.EReduxCompravenditaActionTypes.COMPRAVENDITA_SET_ACCORDION_BOOK_OPENED:
            return {
                ...state,
                form: {
                    ...state.form,
                    accordionBookOpened: action.payload
                }
            };
        case __1.EReduxActionTypes.RESET_ALL: // action invocata dalla logout
            return {
                ...initialState
            };
        default:
            return state;
    }
};
exports.compravenditaReducer = compravenditaReducer;
exports.default = exports.compravenditaReducer;
