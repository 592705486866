"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.categoriePFM = exports.widgetBilancioSintetico = exports.widgetPatrimonio = void 0;
const reselect_1 = require("reselect");
const pfmReducer = (state) => state;
exports.widgetPatrimonio = (0, reselect_1.createSelector)(pfmReducer, (state) => {
    return state.widgetPatrimonio;
});
exports.widgetBilancioSintetico = (0, reselect_1.createSelector)(pfmReducer, (state) => {
    return state.widgetBilancioSintetico;
});
exports.categoriePFM = (0, reselect_1.createSelector)(pfmReducer, (state) => {
    return state.categorie;
});
