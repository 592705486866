"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.JobDtoToJSON = exports.JobDtoFromJSONTyped = exports.JobDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function JobDtoFromJSON(json) {
    return JobDtoFromJSONTyped(json, false);
}
exports.JobDtoFromJSON = JobDtoFromJSON;
function JobDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'jobId': !runtime_1.exists(json, 'jobId') ? undefined : json['jobId'],
        'type': !runtime_1.exists(json, 'type') ? undefined : json['type'],
        'creationTime': !runtime_1.exists(json, 'creationTime') ? undefined : (new Date(json['creationTime'])),
        'status': !runtime_1.exists(json, 'status') ? undefined : json['status'],
        'occField': !runtime_1.exists(json, 'occField') ? undefined : json['occField'],
        'actors': !runtime_1.exists(json, 'actors') ? undefined : (json['actors'].map(_1.JobActorDtoFromJSON)),
        'documents': !runtime_1.exists(json, 'documents') ? undefined : (json['documents'].map(_1.JobDocumentDtoFromJSON)),
        'typeId': !runtime_1.exists(json, 'typeId') ? undefined : json['typeId'],
        'parametersValue': !runtime_1.exists(json, 'parametersValue') ? undefined : _1.JobParametersDtoFromJSON(json['parametersValue']),
        'attributesValue': !runtime_1.exists(json, 'attributesValue') ? undefined : _1.JobAttributesDtoFromJSON(json['attributesValue']),
    };
}
exports.JobDtoFromJSONTyped = JobDtoFromJSONTyped;
function JobDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'jobId': value.jobId,
        'type': value.type,
        'creationTime': value.creationTime === undefined ? undefined : (value.creationTime.toISOString()),
        'status': value.status,
        'occField': value.occField,
        'actors': value.actors === undefined ? undefined : (value.actors.map(_1.JobActorDtoToJSON)),
        'documents': value.documents === undefined ? undefined : (value.documents.map(_1.JobDocumentDtoToJSON)),
        'typeId': value.typeId,
        'parametersValue': _1.JobParametersDtoToJSON(value.parametersValue),
        'attributesValue': _1.JobAttributesDtoToJSON(value.attributesValue),
    };
}
exports.JobDtoToJSON = JobDtoToJSON;
