"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttributiMovimentiPsd2ToJSON = exports.AttributiMovimentiPsd2FromJSONTyped = exports.AttributiMovimentiPsd2FromJSON = void 0;
const runtime_1 = require("../runtime");
function AttributiMovimentiPsd2FromJSON(json) {
    return AttributiMovimentiPsd2FromJSONTyped(json, false);
}
exports.AttributiMovimentiPsd2FromJSON = AttributiMovimentiPsd2FromJSON;
function AttributiMovimentiPsd2FromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'transactionId': !runtime_1.exists(json, 'transactionId') ? undefined : json['transactionId'],
    };
}
exports.AttributiMovimentiPsd2FromJSONTyped = AttributiMovimentiPsd2FromJSONTyped;
function AttributiMovimentiPsd2ToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'transactionId': value.transactionId,
    };
}
exports.AttributiMovimentiPsd2ToJSON = AttributiMovimentiPsd2ToJSON;
