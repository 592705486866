"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EsitoAutorizzazioneDisposizioneVirtualeDtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneVirtualeDtoToJSON = exports.EsitoAutorizzazioneDisposizioneVirtualeDtoFromJSONTyped = exports.EsitoAutorizzazioneDisposizioneVirtualeDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function EsitoAutorizzazioneDisposizioneVirtualeDtoFromJSON(json) {
    return EsitoAutorizzazioneDisposizioneVirtualeDtoFromJSONTyped(json, false);
}
exports.EsitoAutorizzazioneDisposizioneVirtualeDtoFromJSON = EsitoAutorizzazioneDisposizioneVirtualeDtoFromJSON;
function EsitoAutorizzazioneDisposizioneVirtualeDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
        'codiceErrore': !runtime_1.exists(json, 'codiceErrore') ? undefined : json['codiceErrore'],
        'informazioniAutenticazioneDispositiva': !runtime_1.exists(json, 'informazioniAutenticazioneDispositiva') ? undefined : _1.InformazioniAutenticazioneDispositivaDtoFromJSON(json['informazioniAutenticazioneDispositiva']),
        'disposizione': !runtime_1.exists(json, 'disposizione') ? undefined : _1.DisposizioneVirtualeDtoFromJSON(json['disposizione']),
    };
}
exports.EsitoAutorizzazioneDisposizioneVirtualeDtoFromJSONTyped = EsitoAutorizzazioneDisposizioneVirtualeDtoFromJSONTyped;
function EsitoAutorizzazioneDisposizioneVirtualeDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'stato': value.stato,
        'codiceErrore': value.codiceErrore,
        'informazioniAutenticazioneDispositiva': _1.InformazioniAutenticazioneDispositivaDtoToJSON(value.informazioniAutenticazioneDispositiva),
        'disposizione': _1.DisposizioneVirtualeDtoToJSON(value.disposizione),
    };
}
exports.EsitoAutorizzazioneDisposizioneVirtualeDtoToJSON = EsitoAutorizzazioneDisposizioneVirtualeDtoToJSON;
/**
* @export
* @enum {string}
*/
var EsitoAutorizzazioneDisposizioneVirtualeDtoStatoEnum;
(function (EsitoAutorizzazioneDisposizioneVirtualeDtoStatoEnum) {
    EsitoAutorizzazioneDisposizioneVirtualeDtoStatoEnum["OK"] = "OK";
    EsitoAutorizzazioneDisposizioneVirtualeDtoStatoEnum["CREDENZIALE_ERRATA"] = "CREDENZIALE_ERRATA";
    EsitoAutorizzazioneDisposizioneVirtualeDtoStatoEnum["ATTENDERE"] = "ATTENDERE";
    EsitoAutorizzazioneDisposizioneVirtualeDtoStatoEnum["ACCOUNT_BLOCCATO"] = "ACCOUNT_BLOCCATO";
    EsitoAutorizzazioneDisposizioneVirtualeDtoStatoEnum["ACCP"] = "ACCP";
    EsitoAutorizzazioneDisposizioneVirtualeDtoStatoEnum["ACSC"] = "ACSC";
    EsitoAutorizzazioneDisposizioneVirtualeDtoStatoEnum["ACSP"] = "ACSP";
    EsitoAutorizzazioneDisposizioneVirtualeDtoStatoEnum["ACTC"] = "ACTC";
    EsitoAutorizzazioneDisposizioneVirtualeDtoStatoEnum["ACWC"] = "ACWC";
    EsitoAutorizzazioneDisposizioneVirtualeDtoStatoEnum["ACWP"] = "ACWP";
    EsitoAutorizzazioneDisposizioneVirtualeDtoStatoEnum["RCVD"] = "RCVD";
    EsitoAutorizzazioneDisposizioneVirtualeDtoStatoEnum["PDNG"] = "PDNG";
    EsitoAutorizzazioneDisposizioneVirtualeDtoStatoEnum["RJCT"] = "RJCT";
    EsitoAutorizzazioneDisposizioneVirtualeDtoStatoEnum["CANC"] = "CANC";
})(EsitoAutorizzazioneDisposizioneVirtualeDtoStatoEnum = exports.EsitoAutorizzazioneDisposizioneVirtualeDtoStatoEnum || (exports.EsitoAutorizzazioneDisposizioneVirtualeDtoStatoEnum = {}));
