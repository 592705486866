"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BozzeWrapperDtoToJSON = exports.BozzeWrapperDtoFromJSONTyped = exports.BozzeWrapperDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function BozzeWrapperDtoFromJSON(json) {
    return BozzeWrapperDtoFromJSONTyped(json, false);
}
exports.BozzeWrapperDtoFromJSON = BozzeWrapperDtoFromJSON;
function BozzeWrapperDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'bozze': !runtime_1.exists(json, 'bozze') ? undefined : json['bozze'],
        'tipoBozza': !runtime_1.exists(json, 'tipoBozza') ? undefined : json['tipoBozza'],
    };
}
exports.BozzeWrapperDtoFromJSONTyped = BozzeWrapperDtoFromJSONTyped;
function BozzeWrapperDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'bozze': value.bozze,
        'tipoBozza': value.tipoBozza,
    };
}
exports.BozzeWrapperDtoToJSON = BozzeWrapperDtoToJSON;
