"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetAnatocismo = exports.setAnatocismoSetStep = exports.setAnatocismoSetStepAction = exports.setAnatocismoSetAccettato = exports.esportaAnatocismoPDF = exports.autorizzaAnatocismo = exports.getInfoDispositivaAnatocismo = exports.controlloAnatocismo = exports.getConsensi = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
const anatocismo_selectors_1 = require("./anatocismo.selectors");
/* autorizza anatocismo - START */
function getConsensiStart() {
    return {
        type: __1.EReduxActionTypes.ANATOCISMO_GET_CONSENSI_START
    };
}
function getConsensiSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.ANATOCISMO_GET_CONSENSI_SUCCESS,
        payload
    };
}
function generalError(value, showGenericModalError) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value, showGenericModalError));
    };
}
const getConsensi = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getConsensiStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/anatocismo_consensi.json')).then(response => {
            dispatch(getConsensiSuccess(response));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.AnatocismoControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).recuperaPreferenze().then((response) => {
                dispatch(getConsensiSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getConsensi = getConsensi;
function controlloAnatocismoStart() {
    return {
        type: __1.EReduxActionTypes.ANATOCISMO_CONTROLLO_START
    };
}
function controlloAnatocismoSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.ANATOCISMO_CONTROLLO_SUCCESS,
        payload
    };
}
const controlloAnatocismo = (value, index) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(controlloAnatocismoStart());
    const _anatocismo = (0, anatocismo_selectors_1.anatocismo)(getState().anatocismo);
    if (extraArguments.mock) {
    }
    else {
        const _controlloRequest = {
            controlloAnatocismoDto: {
                idDisposizione: _anatocismo.idDisposizione,
                preferenze: (_anatocismo.rapporti || [])
                    .filter((r, i) => (i === index || index < 0) && r.accettato !== value)
                    .map(r => ({
                    ...r,
                    accettato: value
                }))
            }
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.AnatocismoControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).controlloAnatocismo(_controlloRequest).then((response) => {
                dispatch(controlloAnatocismoSuccess(response));
                dispatch((0, general_actions_1.setLoader)(false));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.controlloAnatocismo = controlloAnatocismo;
function getInfoDispositivaAnatocismoStart() {
    return {
        type: __1.EReduxActionTypes.ANATOCISMO_GET_INFO_DISPOSITIVA_START
    };
}
function getInfoDispositivaAnatocismoSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.ANATOCISMO_GET_INFO_DISPOSITIVA_SUCCESS,
        payload
    };
}
const getInfoDispositivaAnatocismo = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getInfoDispositivaAnatocismoStart());
    if (extraArguments.mock) {
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            const requestParameters = {
                idDispositiva,
                chiamataUscente,
                eseguiInit,
                offline: !!offline
            };
            new api_rest_1.AnatocismoControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).infoAutenticazioneAnatocismo(requestParameters).then((response) => {
                dispatch(getInfoDispositivaAnatocismoSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getInfoDispositivaAnatocismo = getInfoDispositivaAnatocismo;
function autorizzaAnatocismoStart() {
    return {
        type: __1.EReduxActionTypes.ANATOCISMO_AUTORIZZA_START
    };
}
function autorizzaAnatocismoSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.ANATOCISMO_AUTORIZZA_SUCCESS,
        payload
    };
}
const autorizzaAnatocismo = (idDispositiva, credenziale) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(autorizzaAnatocismoStart());
    if (extraArguments.mock) {
    }
    else {
        const requestParameters = {
            idDispositiva,
            credenzialeDispositivaDto: credenziale
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.AnatocismoControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).autorizzaAnatocismo(requestParameters).then((response) => {
                dispatch(autorizzaAnatocismoSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error), false));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.autorizzaAnatocismo = autorizzaAnatocismo;
/* stampa PDF */
function esportaAnatocismoPDFStart() {
    return {
        type: __1.EReduxActionTypes.ANATOCISMO_ESPORTA_PDF_START
    };
}
function esportaAnatocismoPDFSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.ANATOCISMO_ESPORTA_PDF_SUCCESS,
        payload
    };
}
const esportaAnatocismoPDF = (rapporti, share, title, message) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(esportaAnatocismoPDFStart());
    if (extraArguments.mock) {
    }
    else {
        const nomeFile = (0, helpers_1.generateDocFileName)('pdf');
        const requestParameters = {
            rispostaAutorizzazioneAnatocismoDto: {
                rapporti: rapporti,
                accettato: rapporti === null || rapporti === void 0 ? void 0 : rapporti[0].accettato
            }
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.AnatocismoControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf'))
                .dettaglioAnatocismo(requestParameters).then((response) => {
                if (extraArguments.platform === 'desktop') {
                    (0, helpers_1.hadlePdfResponse)(response, (url) => dispatch((0, general_actions_1.popUpBlockedPdf)(url)));
                }
                if (extraArguments.platform === 'mobile') {
                    (0, helpers_1.download_sharePDF)(extraArguments, nomeFile, response, share, title, message);
                }
                dispatch(esportaAnatocismoPDFSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.esportaAnatocismoPDF = esportaAnatocismoPDF;
/* set form values - START */
function setAnatocismoSetAccettato(value, index) {
    return {
        type: __1.EReduxActionTypes.ANATOCISMO_ACCETTATO,
        payload: value,
        index: index
    };
}
exports.setAnatocismoSetAccettato = setAnatocismoSetAccettato;
function setAnatocismoSetStepAction(step) {
    return {
        type: __1.EReduxActionTypes.ANATOCISMO_STEP,
        payload: step
    };
}
exports.setAnatocismoSetStepAction = setAnatocismoSetStepAction;
const setAnatocismoSetStep = (i) => async (dispatch, _, extraArguments) => {
    dispatch(setAnatocismoSetStepAction(i));
    if (extraArguments.platform == 'mobile') {
        dispatch((0, general_actions_1.setBottomTabBarVisible)(i != 2));
    }
};
exports.setAnatocismoSetStep = setAnatocismoSetStep;
function resetAnatocismo() {
    return {
        type: __1.EReduxActionTypes.ANATOCISMO_RESET
    };
}
exports.resetAnatocismo = resetAnatocismo;
