"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrdinanteDtoToJSON = exports.OrdinanteDtoFromJSONTyped = exports.OrdinanteDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function OrdinanteDtoFromJSON(json) {
    return OrdinanteDtoFromJSONTyped(json, false);
}
exports.OrdinanteDtoFromJSON = OrdinanteDtoFromJSON;
function OrdinanteDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'contoAddebito': !runtime_1.exists(json, 'contoAddebito') ? undefined : json['contoAddebito'],
        'codiceIban': !runtime_1.exists(json, 'codiceIban') ? undefined : json['codiceIban'],
        'ndg': !runtime_1.exists(json, 'ndg') ? undefined : json['ndg'],
        'anagrafica': !runtime_1.exists(json, 'anagrafica') ? undefined : json['anagrafica'],
        'indirizzo': !runtime_1.exists(json, 'indirizzo') ? undefined : _1.IndirizzoDtoFromJSON(json['indirizzo']),
        'codiceBic': !runtime_1.exists(json, 'codiceBic') ? undefined : json['codiceBic'],
        'tipoCarta': !runtime_1.exists(json, 'tipoCarta') ? undefined : json['tipoCarta'],
    };
}
exports.OrdinanteDtoFromJSONTyped = OrdinanteDtoFromJSONTyped;
function OrdinanteDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'contoAddebito': value.contoAddebito,
        'codiceIban': value.codiceIban,
        'ndg': value.ndg,
        'anagrafica': value.anagrafica,
        'indirizzo': _1.IndirizzoDtoToJSON(value.indirizzo),
        'codiceBic': value.codiceBic,
        'tipoCarta': value.tipoCarta,
    };
}
exports.OrdinanteDtoToJSON = OrdinanteDtoToJSON;
