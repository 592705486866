"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormF24OrdinarioInitial = exports.FormRevocaF24ORdinarioInitial = exports.defaultRigaAltroErario = exports.numRigheAltroErario = exports.defaultRigaAccise = exports.numRigheAccise = exports.defaultRigaMotivoPagamento = exports.numRigheMotivoPagamento = exports.defaultRigaAltriEntiMN = exports.numRigheAltriEntiMN = exports.defaultRigaAltriEntiIL = exports.numRigheAltriEntiIL = exports.defaultRigaIMU = exports.numRigheAltriIMU = exports.defaultRigaRegioni = exports.numRigheRegioni = exports.defaultRigaINPS = exports.numRigheINPS = exports.defaultRigaErario = exports.numRigheErario = exports.RigaSezioneMotiviPagamentoFromJSON = void 0;
// import { DocumentSessionInfoResponseDto, F24BoundingBox, F24Model, InitDocumentSessionResponseDto } from '@sparkasse/api-f24';
const api_rest_1 = require("@sparkasse/api-rest");
const helpers_1 = require("../helpers");
function RigaSezioneMotiviPagamentoFromJSON(first) {
    return {
        enabled: false,
        sezione: undefined,
        sezioneDirty: false,
        sezioneError: first ? 'invalid.required' : '',
        codiceTributo: undefined,
        codiceTributoDirty: false,
        codiceTributoError: first ? 'invalid.required' : '',
        codiceEnte: undefined,
        codiceEnteDirty: false,
        codiceEnteError: first ? 'invalid.required' : '',
        ravvedimento: undefined,
        immobiliareVariati: undefined,
        acconto: undefined,
        saldo: undefined,
        numeroImmobiliari: undefined,
        numeroImmobiliariDirty: false,
        numeroImmobiliariError: first ? 'invalid.required' : '',
        rate: undefined,
        rateDirty: false,
        rateError: '',
        anno: undefined,
        annoDirty: false,
        annoError: first ? 'invalid.required' : '',
        detrazione: undefined,
        detrazioneDirty: false,
        detrazioneError: '',
        importoDebito: undefined,
        importoDebitoError: first ? 'invalid.required' : '',
        importoDebitoDirty: false,
        importoCredito: undefined,
        importoCreditoError: '',
        importoCreditoDirty: false
    };
}
exports.RigaSezioneMotiviPagamentoFromJSON = RigaSezioneMotiviPagamentoFromJSON;
exports.numRigheErario = 6;
exports.defaultRigaErario = {
    enabled: false,
    codiceTributo: undefined,
    codiceTributoDirty: false,
    // codiceTributoBoundingBox: undefined,
    rateazione: undefined,
    rateazioneError: '',
    rateazioneDirty: false,
    // rateazioneBoundingBox: undefined,
    annoDiRiferimento: undefined,
    annoDiRiferimentoError: '',
    annoDiRiferimentoDirty: false,
    // annoDiRiferimentoBoundingBox: undefined,
    importoDebito: undefined,
    importoDebitoError: '',
    importoDebitoDirty: false,
    // importoCreditoBoundingBox: undefined,
    importoCredito: undefined,
    importoCreditoError: '',
    importoCreditoDirty: false,
    // importoDebitoBoundingBox: undefined
};
exports.numRigheINPS = 4;
exports.defaultRigaINPS = {
    enabled: false,
    codiceSede: undefined,
    codiceSedeDirty: false,
    // codiceSedeBoundingBox: undefined,
    causaleContributo: undefined,
    causaleContributoDirty: false,
    // causaleContributoBoundingBox: undefined,
    matricolaINPS: undefined,
    matricolaINPSDirty: false,
    // matricolaINPSBoundingBox: undefined,
    periodoDa: undefined,
    periodoDaError: '',
    periodoDaDirty: false,
    // periodoDaBoundingBox: undefined,
    periodoA: undefined,
    periodoAError: '',
    periodoADirty: false,
    // periodoABoundingBox: undefined,
    importoDebito: undefined,
    importoDebitoError: '',
    importoDebitoDirty: false,
    // importoDebitoBoundingBox: undefined,
    importoCredito: undefined,
    importoCreditoError: '',
    importoCreditoDirty: false,
    // importoCreditoBoundingBox: undefined
};
exports.numRigheRegioni = 4;
exports.defaultRigaRegioni = {
    enabled: false,
    codiceRegione: undefined,
    codiceRegioneDirty: false,
    // codiceRegioneBoundingBox: undefined,
    codiceTributo: undefined,
    codiceTributoDirty: false,
    // codiceTributoBoundingBox: undefined,
    rateazione: undefined,
    rateazioneError: '',
    rateazioneDirty: false,
    // rateazioneBoundingBox: undefined,
    annoRiferimento: undefined,
    annoRiferimentoError: '',
    annoRiferimentoDirty: false,
    // annoRiferimentoBoundingBox: undefined,
    importoDebito: undefined,
    importoDebitoError: '',
    importoDebitoDirty: false,
    // importoDebitoBoundingBox: undefined,
    importoCredito: undefined,
    importoCreditoError: '',
    importoCreditoDirty: false,
    // importoCreditoBoundingBox: undefined
};
exports.numRigheAltriIMU = 4;
exports.defaultRigaIMU = {
    enabled: false,
    codiceRegione: undefined,
    codiceRegioneDirty: false,
    // codiceRegioneBoundingBox: undefined,
    R: undefined,
    RDirty: false,
    // RBoundingBox: undefined,
    IV: undefined,
    IVDirty: false,
    // IVBoundingBox: undefined,
    A: undefined,
    ADirty: false,
    // ABoundingBox: undefined,
    S: undefined,
    SDirty: false,
    // SBoundingBox: undefined,
    NI: undefined,
    NIDirty: false,
    // NIBoundingBox: undefined,
    codiceTributo: undefined,
    codiceTributoDirty: false,
    // codiceTributoBoundingBox: undefined,
    rateazione: undefined,
    rateazioneError: '',
    rateazioneDirty: false,
    // rateazioneBoundingBox: undefined,
    annoRiferimento: undefined,
    annoRiferimentoError: '',
    annoRiferimentoDirty: false,
    // annoRiferimentoBoundingBox: undefined,
    importoDebito: undefined,
    importoDebitoError: '',
    importoDebitoDirty: false,
    // importoDebitoBoundingBox: undefined,
    importoCredito: undefined,
    importoCreditoError: '',
    importoCreditoDirty: false,
    // importoCreditoBoundingBox: undefined
};
exports.numRigheAltriEntiIL = 3;
exports.defaultRigaAltriEntiIL = {
    enabled: false,
    codiceSede: undefined,
    codiceSedeDirty: false,
    // codiceSedeBoundingBox: undefined,
    codiceDitta: undefined,
    codiceDittaDirty: false,
    // codiceDittaBoundingBox: undefined,
    CC: undefined,
    CCDirty: false,
    // CCBoundingBox: undefined,
    numeroDiRiferimento: undefined,
    numeroDiRiferimentoDirty: false,
    // numeroDiRiferimentoBoundingBox: undefined,
    causale: undefined,
    causaleDirty: false,
    // causaleBoundingBox: undefined,
    importoDebito: undefined,
    importoDebitoError: '',
    importoDebitoDirty: false,
    // importoDebitoBoundingBox: undefined,
    importoCredito: undefined,
    importoCreditoError: '',
    importoCreditoDirty: false,
    // importoCreditoBoundingBox: undefined
};
exports.numRigheAltriEntiMN = 2;
exports.defaultRigaAltriEntiMN = {
    enabled: false,
    codiceEnte: undefined,
    codiceEnteError: '',
    codiceEnteDirty: false,
    // codiceEnteBoundingBox: undefined,
    codiceSede: undefined,
    codiceSedeDirty: false,
    // codiceSedeBoundingBox: undefined,
    causaleContributo: undefined,
    causaleContributoDirty: false,
    // causaleContributoBoundingBox: undefined,
    codicePosizione: undefined,
    codicePosizioneDirty: false,
    // codicePosizioneBoundingBox: undefined,
    periodoDa: undefined,
    periodoDaError: '',
    periodoDaDirty: false,
    // periodoDaBoundingBox: undefined,
    periodoA: undefined,
    periodoAError: '',
    periodoADirty: false,
    // periodoABoundingBox: undefined,
    importoDebito: undefined,
    importoDebitoDirty: false,
    importoDebitoError: '',
    // importoDebitoBoundingBox: undefined,
    importoCredito: undefined,
    importoCreditoError: '',
    importoCreditoDirty: false,
    // importoCreditoBoundingBox: undefined
};
exports.numRigheMotivoPagamento = 10;
exports.defaultRigaMotivoPagamento = {
    enabled: false,
    sezione: undefined,
    sezioneError: '',
    sezioneDirty: false,
    codiceTributo: undefined,
    codiceTributoError: '',
    codiceTributoDirty: false,
    codiceEnte: undefined,
    codiceEnteError: '',
    codiceEnteDirty: false,
    ravvedimento: undefined,
    ravvedimentoError: '',
    ravvedimentoDirty: false,
    immobiliareVariati: undefined,
    immobiliareVariatiError: '',
    immobiliareVariatiDirty: false,
    acconto: undefined,
    accontoError: '',
    accontoDirty: false,
    saldo: undefined,
    saldoError: '',
    saldoDirty: false,
    numeroImmobiliari: undefined,
    numeroImmobiliariError: '',
    numeroImmobiliariDirty: false,
    rate: undefined,
    rateError: '',
    rateDirty: false,
    anno: undefined,
    annoError: '',
    annoDirty: false,
    detrazione: undefined,
    detrazioneError: '',
    detrazioneDirty: false,
    importoDebito: undefined,
    importoDebitoError: '',
    importoDebitoDirty: false,
    importoCredito: undefined,
    importoCreditoError: '',
    importoCreditoDirty: false
};
exports.numRigheAccise = 7;
exports.defaultRigaAccise = {
    enabled: false,
    codiceEnte: undefined,
    codiceEnteError: '',
    codiceEnteDirty: false,
    provincia: undefined,
    provinciaError: '',
    provinciaDirty: false,
    codiceTributo: undefined,
    codiceTributoError: '',
    codiceTributoDirty: false,
    codiceIdentificativo: undefined,
    codiceIdentificativoError: '',
    codiceIdentificativoDirty: false,
    rateazione: undefined,
    rateazioneError: '',
    rateazioneDirty: false,
    mese: undefined,
    meseError: '',
    meseDirty: false,
    annoRiferimento: undefined,
    annoRiferimentoError: '',
    annoRiferimentoDirty: false,
    importoDebito: undefined,
    importoDebitoError: '',
    importoDebitoDirty: false
};
exports.numRigheAltroErario = 10;
exports.defaultRigaAltroErario = {
    enabled: false,
    tipoVeicolo: undefined,
    tipoVeicoloError: '',
    tipoVeicoloDirty: false,
    elementoId: undefined,
    elementoIdError: '',
    elementoIdDirty: false,
    codiceTributo: undefined,
    codiceTributoError: '',
    codiceTributoDirty: false,
    annoRiferimento: undefined,
    annoRiferimentoError: '',
    annoRiferimentoDirty: false,
    importoDebito: undefined,
    importoDebitoError: '',
    importoDebitoDirty: false
};
function FormRevocaF24ORdinarioInitial() {
    return {
        step: 1,
        provenienza: ''
    };
}
exports.FormRevocaF24ORdinarioInitial = FormRevocaF24ORdinarioInitial;
function FormF24OrdinarioInitial() {
    return {
        step: 1,
        rapportoSelezionato: (0, api_rest_1.RapportoCompletoDtoFromJSON)({}),
        codiciEnte: [],
        codiciUfficio: [],
        formSubmitted: false,
        bozzaSalvata: false,
        ripeti: false,
        contribuente: {
            codiceFiscale: '',
            codiceFiscaleDirty: false,
            // codiceFiscaleBoundingBox: undefined,
            codiceFiscaleError: 'invalid.required',
            nonCoincidenteAnnoSolare: undefined,
            nonCoincidenteAnnoSolareDirty: false,
            // nonCoincidenteAnnoSolareBoundingBox: undefined,
            cognome: undefined,
            cognomeDirty: false,
            // cognomeBoundingBox: undefined,
            cognomeError: 'invalid.required',
            nome: undefined,
            nomeDirty: false,
            // nomeBoundingBox: undefined,
            nomeError: '',
            dataDiNascita: undefined,
            dataDiNascitaDirty: false,
            // dataDiNascitaBoundingBox: undefined,
            dataDiNascitaError: '',
            sesso: undefined,
            sessoDirty: false,
            // sessoBoundingBox: undefined,
            sessoError: '',
            comune: undefined,
            comuneDirty: false,
            // comuneBoundingBox: undefined,
            comuneError: '',
            provincia: undefined,
            provinciaDirty: false,
            // provinciaBoundingBox: undefined,
            provinciaError: '',
            domicilioFiscale: {
                comune: undefined,
                comuneDirty: false,
                // comuneBoundingBox: undefined,
                comuneError: '',
                provincia: undefined,
                provinciaDirty: false,
                // provinciaBoundingBox: undefined,
                provinciaError: '',
                viaENumeroCivico: undefined,
                viaENumeroCivicoDirty: false,
                // viaENumeroCivicoBoundingBox: undefined,
                viaENumeroCivicoError: ''
            },
            codiceFiscaleCoobligato: undefined,
            codiceFiscaleCoobligatoError: '',
            codiceFiscaleCoobligatoDirty: false,
            // codiceFiscaleCoobligatoBoundingBox: undefined,
            codiceIdentificativo: undefined,
            codiceIdentificativoError: '',
            codiceIdentificativoDirty: false,
            // codiceIdentificativoBoundingBox: undefined,
            aggiungiBeneficiarioRubrica: undefined,
            aggiungiBeneficiarioWhitelist: undefined,
            idContattoBeneficiario: undefined
        },
        sezioneErario: {
            codiceAtto: undefined,
            codiceAttoError: '',
            codiceAttoDirty: false,
            // codiceAttoBoundingBox: undefined,
            codiceUfficio: undefined,
            codiceUfficioError: '',
            codiceUfficioDirty: false,
            // codiceUfficioBoundingBox: undefined,
            righe: (0, helpers_1.mapRigheSezioneErario)([], 'desktop'),
            totaleDebito: undefined,
            totaleCredito: undefined,
            totale: undefined
        },
        sezioneINPS: {
            righe: (0, helpers_1.mapRigheSezioneINPS)([], 'desktop'),
            totaleDebito: undefined,
            totaleCredito: undefined,
            totale: undefined
        },
        sezioneRegioni: {
            righe: (0, helpers_1.mapRigheSezioneRegioni)([], 'desktop'),
            totaleDebito: undefined,
            totaleCredito: undefined,
            totale: undefined
        },
        sezioneIMU: {
            detrazione: undefined,
            detrazioneError: '',
            detrazioneDirty: false,
            // detrazioneBoundingBox: undefined,
            righe: (0, helpers_1.mapRigheSezioneIMU)([], 'desktop'),
            totaleDebito: undefined,
            totaleCredito: undefined,
            totale: undefined
        },
        sezioneAltriEntiIL: {
            righe: (0, helpers_1.mapRigheSezioneAltriEntiIL)([], 'desktop'),
            totaleDebito: undefined,
            totaleCredito: undefined,
            totale: undefined
        },
        sezioneAltriEntiMN: {
            righe: (0, helpers_1.mapRigheSezioneAltriEntiMN)([], 'desktop'),
            totaleDebito: undefined,
            totaleCredito: undefined,
            totale: undefined
        },
        sezioneDataESaldo: {
            data: '',
            dataError: 'invalid.required',
            dataDirty: false,
            // dataBoundingBox: undefined,
            saldo: undefined
        },
        sezioneMotivoPagamento: {
            righe: (0, helpers_1.mapRigheSezioneMotivoPagamento)([], 'desktop'),
            data: new Date().toISOString(),
            dataError: '',
            dataDirty: false,
            saldo: undefined,
            numeroIdentificativo: '',
            numeroIdentificativoError: '',
            numeroIdentificativoDirty: false
        },
        sezioneAccise: {
            righe: (0, helpers_1.mapRigheSezioneAccise)([], 'desktop'),
            codiceUfficio: undefined,
            codiceUfficioError: '',
            codiceUfficioDirty: false,
            codiceAtto: undefined,
            codiceAttoError: '',
            codiceAttoDirty: false,
            totalAccise: undefined
        },
        sezioneAltroErario: {
            codiceUfficio: undefined,
            codiceUfficioError: '',
            codiceUfficioDirty: false,
            codiceAtto: undefined,
            codiceAttoError: '',
            codiceAttoDirty: false,
            righe: (0, helpers_1.mapRigheSezioneAltroErario)([], 'desktop'),
            totalIdentificativi: undefined
        }
    };
}
exports.FormF24OrdinarioInitial = FormF24OrdinarioInitial;
