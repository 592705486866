"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfiloMenuSsoEnum = exports.ProfiloMenuToJSON = exports.ProfiloMenuFromJSONTyped = exports.ProfiloMenuFromJSON = void 0;
const runtime_1 = require("../runtime");
function ProfiloMenuFromJSON(json) {
    return ProfiloMenuFromJSONTyped(json, false);
}
exports.ProfiloMenuFromJSON = ProfiloMenuFromJSON;
function ProfiloMenuFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'iconColorLight': !runtime_1.exists(json, 'iconColorLight') ? undefined : json['iconColorLight'],
        'iconColorDark': !runtime_1.exists(json, 'iconColorDark') ? undefined : json['iconColorDark'],
        'label': !runtime_1.exists(json, 'label') ? undefined : json['label'],
        'labelTradotta': !runtime_1.exists(json, 'labelTradotta') ? undefined : json['labelTradotta'],
        'posizione': !runtime_1.exists(json, 'posizione') ? undefined : json['posizione'],
        'labelDefaultValue': !runtime_1.exists(json, 'labelDefaultValue') ? undefined : json['labelDefaultValue'],
        'abilitato': !runtime_1.exists(json, 'abilitato') ? undefined : json['abilitato'],
        'def': !runtime_1.exists(json, 'def') ? undefined : json['def'],
        'sso': !runtime_1.exists(json, 'sso') ? undefined : json['sso'],
        'ssoTargetUrl': !runtime_1.exists(json, 'ssoTargetUrl') ? undefined : json['ssoTargetUrl'],
        'funzioneIniziale': !runtime_1.exists(json, 'funzioneIniziale') ? undefined : json['funzioneIniziale'],
        'funzioni': !runtime_1.exists(json, 'funzioni') ? undefined : json['funzioni'],
        'tipoSubmenu': !runtime_1.exists(json, 'tipoSubmenu') ? undefined : json['tipoSubmenu'],
        'loginNecessario': !runtime_1.exists(json, 'loginNecessario') ? undefined : json['loginNecessario'],
        'abilitaRaggrupamentoVociMenu': !runtime_1.exists(json, 'abilitaRaggrupamentoVociMenu') ? undefined : json['abilitaRaggrupamentoVociMenu'],
        'submenu': !runtime_1.exists(json, 'submenu') ? undefined : (json['submenu'].map(ProfiloMenuFromJSON)),
        'filileVirtuale_categoria_id': !runtime_1.exists(json, 'filileVirtuale_categoria_id') ? undefined : json['filileVirtuale_categoria_id'],
        'filileVirtuale_template_id': !runtime_1.exists(json, 'filileVirtuale_template_id') ? undefined : json['filileVirtuale_template_id'],
    };
}
exports.ProfiloMenuFromJSONTyped = ProfiloMenuFromJSONTyped;
function ProfiloMenuToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'iconColorLight': value.iconColorLight,
        'iconColorDark': value.iconColorDark,
        'label': value.label,
        'labelTradotta': value.labelTradotta,
        'posizione': value.posizione,
        'labelDefaultValue': value.labelDefaultValue,
        'abilitato': value.abilitato,
        'def': value.def,
        'sso': value.sso,
        'ssoTargetUrl': value.ssoTargetUrl,
        'funzioneIniziale': value.funzioneIniziale,
        'funzioni': value.funzioni,
        'tipoSubmenu': value.tipoSubmenu,
        'loginNecessario': value.loginNecessario,
        'abilitaRaggrupamentoVociMenu': value.abilitaRaggrupamentoVociMenu,
        'submenu': value.submenu === undefined ? undefined : (value.submenu.map(ProfiloMenuToJSON)),
        'filileVirtuale_categoria_id': value.filileVirtuale_categoria_id,
        'filileVirtuale_template_id': value.filileVirtuale_template_id,
    };
}
exports.ProfiloMenuToJSON = ProfiloMenuToJSON;
/**
* @export
* @enum {string}
*/
var ProfiloMenuSsoEnum;
(function (ProfiloMenuSsoEnum) {
    ProfiloMenuSsoEnum["HB1"] = "HB1";
})(ProfiloMenuSsoEnum = exports.ProfiloMenuSsoEnum || (exports.ProfiloMenuSsoEnum = {}));
