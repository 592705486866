"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContattoTelefonoDtoToJSON = exports.ContattoTelefonoDtoFromJSONTyped = exports.ContattoTelefonoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function ContattoTelefonoDtoFromJSON(json) {
    return ContattoTelefonoDtoFromJSONTyped(json, false);
}
exports.ContattoTelefonoDtoFromJSON = ContattoTelefonoDtoFromJSON;
function ContattoTelefonoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'seq': !runtime_1.exists(json, 'seq') ? undefined : json['seq'],
        'numero': !runtime_1.exists(json, 'numero') ? undefined : json['numero'],
        'etichetta': !runtime_1.exists(json, 'etichetta') ? undefined : json['etichetta'],
        'dispensaCredenziale': !runtime_1.exists(json, 'dispensaCredenziale') ? undefined : json['dispensaCredenziale'],
        'isDefault': !runtime_1.exists(json, 'isDefault') ? undefined : json['isDefault'],
    };
}
exports.ContattoTelefonoDtoFromJSONTyped = ContattoTelefonoDtoFromJSONTyped;
function ContattoTelefonoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'seq': value.seq,
        'numero': value.numero,
        'etichetta': value.etichetta,
        'dispensaCredenziale': value.dispensaCredenziale,
        'isDefault': value.isDefault,
    };
}
exports.ContattoTelefonoDtoToJSON = ContattoTelefonoDtoToJSON;
