"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.faqReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const initialState = {
    faq: undefined,
    categoriaSel: (0, api_rest_1.FaqWcmCategoriaDtoFromJSON)({})
};
const faqReducer = (state = initialState, action) => {
    switch (action.type) {
        case __1.EReduxActionTypes.FAQ_GET_SUCCESS:
            return {
                ...state,
                faq: action.payload
            };
        case __1.EReduxActionTypes.FAQ_SET_CATEGORIA_SEL:
            return {
                ...state,
                categoriaSel: action.payload
            };
        case __1.EReduxActionTypes.GENERAL_RESET_POST_CAMBIO_LINGUA: // action invocata dal cambio lingua
            return {
                ...initialState
            };
        case __1.EReduxActionTypes.RESET_ALL: // action invocata dalla logout
            return {
                ...initialState
            };
        default:
            return state;
    }
};
exports.faqReducer = faqReducer;
exports.default = exports.faqReducer;
