"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AcquistoPctControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class AcquistoPctControllerApi extends runtime.BaseAPI {
    /**
     * Autorizzazione di una disposizione di acquisto pct
     */
    autorizzaAcquistoPctRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling autorizzaAcquistoPct.');
            }
            if (requestParameters.credenzialeDispositivaDto === null || requestParameters.credenzialeDispositivaDto === undefined) {
                throw new runtime.RequiredError('credenzialeDispositivaDto', 'Required parameter requestParameters.credenzialeDispositivaDto was null or undefined when calling autorizzaAcquistoPct.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/acquistopct/autorizza/{idDispositiva}`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CredenzialeDispositivaDtoToJSON(requestParameters.credenzialeDispositivaDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.EsitoAutorizzazioneDisposizionePctDtoFromJSON(jsonValue));
        });
    }
    /**
     * Autorizzazione di una disposizione di acquisto pct
     */
    autorizzaAcquistoPct(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.autorizzaAcquistoPctRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce i dati relativi all\'esecuzione dell\'acquisto PCT.
     */
    controlloPctRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.pctDto === null || requestParameters.pctDto === undefined) {
                throw new runtime.RequiredError('pctDto', 'Required parameter requestParameters.pctDto was null or undefined when calling controlloPct.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/acquistopct/controllo`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.PctDtoToJSON(requestParameters.pctDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.PctDisposizioneVirtualeDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce i dati relativi all\'esecuzione dell\'acquisto PCT.
     */
    controlloPct(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.controlloPctRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva per acquisto PCT
     */
    informazioniAutenticazioneAcquistoPctRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling informazioniAutenticazioneAcquistoPct.');
            }
            const queryParameters = {};
            if (requestParameters.chiamataUscente !== undefined) {
                queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
            }
            if (requestParameters.eseguiInit !== undefined) {
                queryParameters['eseguiInit'] = requestParameters.eseguiInit;
            }
            if (requestParameters.offline !== undefined) {
                queryParameters['offline'] = requestParameters.offline;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/acquistopct/infoautenticazione/{idDispositiva}`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva per acquisto PCT
     */
    informazioniAutenticazioneAcquistoPct(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.informazioniAutenticazioneAcquistoPctRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce il paniere dei prodotti PCT acquistabili dall\'utente
     */
    listaPctRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.codiceRapporto === null || requestParameters.codiceRapporto === undefined) {
                throw new runtime.RequiredError('codiceRapporto', 'Required parameter requestParameters.codiceRapporto was null or undefined when calling listaPct.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/acquistopct/{codiceRapporto}`.replace(`{${"codiceRapporto"}}`, encodeURIComponent(String(requestParameters.codiceRapporto))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.PctDtoFromJSON));
        });
    }
    /**
     * Restituisce il paniere dei prodotti PCT acquistabili dall\'utente
     */
    listaPct(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.listaPctRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce i rapporti (con servizio 12) abilitati all\'acquisto PCT e i parametri applicabili a tale funzione, codice funzione: PCT.
     */
    parametriPctRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/acquistopct/parametri`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ParametriDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce i rapporti (con servizio 12) abilitati all\'acquisto PCT e i parametri applicabili a tale funzione, codice funzione: PCT.
     */
    parametriPct() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.parametriPctRaw();
            return yield response.value();
        });
    }
    /**
     * Export in pdf dettaglio pct in base ai criteri inviati per il rapporto indicato
     */
    stampaDettaglioPctPdfRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.pctDto === null || requestParameters.pctDto === undefined) {
                throw new runtime.RequiredError('pctDto', 'Required parameter requestParameters.pctDto was null or undefined when calling stampaDettaglioPctPdf.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/acquistopct/export/dettagliopct/pdf`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.PctDtoToJSON(requestParameters.pctDto),
            });
            return new runtime.BlobApiResponse(response);
        });
    }
    /**
     * Export in pdf dettaglio pct in base ai criteri inviati per il rapporto indicato
     */
    stampaDettaglioPctPdf(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.stampaDettaglioPctPdfRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Stampa dettaglio Kiid
     */
    stampaPDFKiidPctRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.codiceTitolo === null || requestParameters.codiceTitolo === undefined) {
                throw new runtime.RequiredError('codiceTitolo', 'Required parameter requestParameters.codiceTitolo was null or undefined when calling stampaPDFKiidPct.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/acquistopct/kiid/{codiceTitolo}`.replace(`{${"codiceTitolo"}}`, encodeURIComponent(String(requestParameters.codiceTitolo))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.BlobApiResponse(response);
        });
    }
    /**
     * Stampa dettaglio Kiid
     */
    stampaPDFKiidPct(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.stampaPDFKiidPctRaw(requestParameters);
            return yield response.value();
        });
    }
}
exports.AcquistoPctControllerApi = AcquistoPctControllerApi;
