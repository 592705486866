"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TourDtoToJSON = exports.TourDtoFromJSONTyped = exports.TourDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function TourDtoFromJSON(json) {
    return TourDtoFromJSONTyped(json, false);
}
exports.TourDtoFromJSON = TourDtoFromJSON;
function TourDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codiceTour': !runtime_1.exists(json, 'codiceTour') ? undefined : json['codiceTour'],
        'elementi': !runtime_1.exists(json, 'elementi') ? undefined : (json['elementi'].map(_1.ElementiDtoFromJSON)),
    };
}
exports.TourDtoFromJSONTyped = TourDtoFromJSONTyped;
function TourDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codiceTour': value.codiceTour,
        'elementi': value.elementi === undefined ? undefined : (value.elementi.map(_1.ElementiDtoToJSON)),
    };
}
exports.TourDtoToJSON = TourDtoToJSON;
