"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RicaricaCellulareControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class RicaricaCellulareControllerApi extends runtime.BaseAPI {
    /**
     * Informazioni sull\'autenticazione dispositiva per un RicaricaCellulare
     */
    authenticationInformationRicaricaCellulareRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling authenticationInformationRicaricaCellulare.');
            }
            const queryParameters = {};
            if (requestParameters.chiamataUscente !== undefined) {
                queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
            }
            if (requestParameters.eseguiInit !== undefined) {
                queryParameters['eseguiInit'] = requestParameters.eseguiInit;
            }
            if (requestParameters.offline !== undefined) {
                queryParameters['offline'] = requestParameters.offline;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/ricaricacellulare/infoautenticazione/{idDispositiva}`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva per un RicaricaCellulare
     */
    authenticationInformationRicaricaCellulare(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.authenticationInformationRicaricaCellulareRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Autorizzazione di una disposizione di RicaricaCellulare
     */
    authorizeRicaricaCellulareRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling authorizeRicaricaCellulare.');
            }
            if (requestParameters.credenzialeDispositivaDto === null || requestParameters.credenzialeDispositivaDto === undefined) {
                throw new runtime.RequiredError('credenzialeDispositivaDto', 'Required parameter requestParameters.credenzialeDispositivaDto was null or undefined when calling authorizeRicaricaCellulare.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/ricaricacellulare/autorizza/{idDispositiva}`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CredenzialeDispositivaDtoToJSON(requestParameters.credenzialeDispositivaDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.EsitoAutorizzazioneDisposizioneRicaricaCellulareDtoFromJSON(jsonValue));
        });
    }
    /**
     * Autorizzazione di una disposizione di RicaricaCellulare
     */
    authorizeRicaricaCellulare(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.authorizeRicaricaCellulareRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Esegue il controllo di validità di una ricarica cellulare in base ai valori specificati nell\'oggetto di richiesta
     */
    checkRicaricaCellulareRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.ricaricaCellulareDto === null || requestParameters.ricaricaCellulareDto === undefined) {
                throw new runtime.RequiredError('ricaricaCellulareDto', 'Required parameter requestParameters.ricaricaCellulareDto was null or undefined when calling checkRicaricaCellulare.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/ricaricacellulare/controllo`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.RicaricaCellulareDtoToJSON(requestParameters.ricaricaCellulareDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.RicaricaCellulareDtoFromJSON(jsonValue));
        });
    }
    /**
     * Esegue il controllo di validità di una ricarica cellulare in base ai valori specificati nell\'oggetto di richiesta
     */
    checkRicaricaCellulare(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.checkRicaricaCellulareRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Eliminazione di una bozza di ricarica cellulare dato l\'id
     */
    deleteDraftRicaricaCellulareRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling deleteDraftRicaricaCellulare.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/ricaricacellulare/bozze/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Eliminazione di una bozza di ricarica cellulare dato l\'id
     */
    deleteDraftRicaricaCellulare(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.deleteDraftRicaricaCellulareRaw(requestParameters);
        });
    }
    /**
     * Restituisce una bozza di ricarica cellulare dato l\'id
     */
    getDraftByIdRicaricaCellulareRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getDraftByIdRicaricaCellulare.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/ricaricacellulare/bozze/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.RicaricaCellulareDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce una bozza di ricarica cellulare dato l\'id
     */
    getDraftByIdRicaricaCellulare(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getDraftByIdRicaricaCellulareRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce le bozze per ricarica cellulare
     */
    getDraftListRicaricaCellulareRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.bozzeInDto === null || requestParameters.bozzeInDto === undefined) {
                throw new runtime.RequiredError('bozzeInDto', 'Required parameter requestParameters.bozzeInDto was null or undefined when calling getDraftListRicaricaCellulare.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/ricaricacellulare/bozze`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.BozzeInDtoToJSON(requestParameters.bozzeInDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.RicaricaCellulareDtoFromJSON));
        });
    }
    /**
     * Restituisce le bozze per ricarica cellulare
     */
    getDraftListRicaricaCellulare(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getDraftListRicaricaCellulareRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce le operazioni per Ricarica Cellulare
     */
    operazioniOneClickRicaricaCellulareRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.operazioniOneClickInputDto === null || requestParameters.operazioniOneClickInputDto === undefined) {
                throw new runtime.RequiredError('operazioniOneClickInputDto', 'Required parameter requestParameters.operazioniOneClickInputDto was null or undefined when calling operazioniOneClickRicaricaCellulare.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/ricaricacellulare/oneclick`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.OperazioniOneClickInputDtoToJSON(requestParameters.operazioniOneClickInputDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.OperazioniOneClickDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce le operazioni per Ricarica Cellulare
     */
    operazioniOneClickRicaricaCellulare(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.operazioniOneClickRicaricaCellulareRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Lettura parametri ricarica cellulare
     */
    parametersRicaricaCellulareRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/ricaricacellulare/parametri`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ParametriDtoFromJSON(jsonValue));
        });
    }
    /**
     * Lettura parametri ricarica cellulare
     */
    parametersRicaricaCellulare() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.parametersRicaricaCellulareRaw();
            return yield response.value();
        });
    }
    /**
     */
    retrieveElencoGestoriRicaricaCellulareRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/ricaricacellulare/elencogestori`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.GestoreTelefonicoDtoFromJSON));
        });
    }
    /**
     */
    retrieveElencoGestoriRicaricaCellulare() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.retrieveElencoGestoriRicaricaCellulareRaw();
            return yield response.value();
        });
    }
    /**
     * Ottieni i tagli di ricarica in funzione del tipo di operatore in ingresso
     */
    retrieveTagliRicaricaByGestoreTelefonicoRicaricaCellulareRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.filtroTagliRicaricaDto === null || requestParameters.filtroTagliRicaricaDto === undefined) {
                throw new runtime.RequiredError('filtroTagliRicaricaDto', 'Required parameter requestParameters.filtroTagliRicaricaDto was null or undefined when calling retrieveTagliRicaricaByGestoreTelefonicoRicaricaCellulare.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/ricaricacellulare/tagliricarica`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.FiltroTagliRicaricaDtoToJSON(requestParameters.filtroTagliRicaricaDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.TagliRicaricaCellulareDtoFromJSON(jsonValue));
        });
    }
    /**
     * Ottieni i tagli di ricarica in funzione del tipo di operatore in ingresso
     */
    retrieveTagliRicaricaByGestoreTelefonicoRicaricaCellulare(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.retrieveTagliRicaricaByGestoreTelefonicoRicaricaCellulareRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Salva una bozza di ricarica cellulare
     */
    saveDraftRicaricaCellulareRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.ricaricaCellulareDto === null || requestParameters.ricaricaCellulareDto === undefined) {
                throw new runtime.RequiredError('ricaricaCellulareDto', 'Required parameter requestParameters.ricaricaCellulareDto was null or undefined when calling saveDraftRicaricaCellulare.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/ricaricacellulare/salvabozza`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.RicaricaCellulareDtoToJSON(requestParameters.ricaricaCellulareDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.RicaricaCellulareDtoFromJSON(jsonValue));
        });
    }
    /**
     * Salva una bozza di ricarica cellulare
     */
    saveDraftRicaricaCellulare(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.saveDraftRicaricaCellulareRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Modifica di una bozza di ricarica cellulare dato l\'id
     */
    updateDraftRicaricaCellulareRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling updateDraftRicaricaCellulare.');
            }
            if (requestParameters.ricaricaCellulareDto === null || requestParameters.ricaricaCellulareDto === undefined) {
                throw new runtime.RequiredError('ricaricaCellulareDto', 'Required parameter requestParameters.ricaricaCellulareDto was null or undefined when calling updateDraftRicaricaCellulare.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/ricaricacellulare/bozze/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.RicaricaCellulareDtoToJSON(requestParameters.ricaricaCellulareDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.RicaricaCellulareDtoFromJSON(jsonValue));
        });
    }
    /**
     * Modifica di una bozza di ricarica cellulare dato l\'id
     */
    updateDraftRicaricaCellulare(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateDraftRicaricaCellulareRaw(requestParameters);
            return yield response.value();
        });
    }
}
exports.RicaricaCellulareControllerApi = RicaricaCellulareControllerApi;
