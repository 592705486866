"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RangeImportiToJSON = exports.RangeImportiFromJSONTyped = exports.RangeImportiFromJSON = void 0;
const runtime_1 = require("../runtime");
function RangeImportiFromJSON(json) {
    return RangeImportiFromJSONTyped(json, false);
}
exports.RangeImportiFromJSON = RangeImportiFromJSON;
function RangeImportiFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'importo1': !runtime_1.exists(json, 'importo1') ? undefined : json['importo1'],
        'importo2': !runtime_1.exists(json, 'importo2') ? undefined : json['importo2'],
        'importo3': !runtime_1.exists(json, 'importo3') ? undefined : json['importo3'],
        'importo4': !runtime_1.exists(json, 'importo4') ? undefined : json['importo4'],
        'importo5': !runtime_1.exists(json, 'importo5') ? undefined : json['importo5'],
    };
}
exports.RangeImportiFromJSONTyped = RangeImportiFromJSONTyped;
function RangeImportiToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'importo1': value.importo1,
        'importo2': value.importo2,
        'importo3': value.importo3,
        'importo4': value.importo4,
        'importo5': value.importo5,
    };
}
exports.RangeImportiToJSON = RangeImportiToJSON;
