"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RavDtoStatoDisposizioneEnum = exports.RavDtoStatoEnum = exports.RavDtoToJSON = exports.RavDtoFromJSONTyped = exports.RavDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function RavDtoFromJSON(json) {
    return RavDtoFromJSONTyped(json, false);
}
exports.RavDtoFromJSON = RavDtoFromJSON;
function RavDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'contoAddebito': json['contoAddebito'],
        'userId': !runtime_1.exists(json, 'userId') ? undefined : json['userId'],
        'numeroBollettino': json['numeroBollettino'],
        'numeroOperazione': !runtime_1.exists(json, 'numeroOperazione') ? undefined : json['numeroOperazione'],
        'importo': json['importo'],
        'dataScadenza': !runtime_1.exists(json, 'dataScadenza') ? undefined : (new Date(json['dataScadenza'])),
        'note': !runtime_1.exists(json, 'note') ? undefined : json['note'],
        'condivisione': !runtime_1.exists(json, 'condivisione') ? undefined : (json['condivisione'].map(_1.CondivisioneDtoFromJSON)),
        'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
        'revocabile': !runtime_1.exists(json, 'revocabile') ? undefined : json['revocabile'],
        'bozza': !runtime_1.exists(json, 'bozza') ? undefined : _1.BozzaDtoFromJSON(json['bozza']),
        'inviato': !runtime_1.exists(json, 'inviato') ? undefined : _1.InviatoDtoFromJSON(json['inviato']),
        'datiPagamento': !runtime_1.exists(json, 'datiPagamento') ? undefined : _1.DatiPagamentoCommonDtoFromJSON(json['datiPagamento']),
        'statoDisposizione': !runtime_1.exists(json, 'statoDisposizione') ? undefined : json['statoDisposizione'],
        'idBozzaCollegata': !runtime_1.exists(json, 'idBozzaCollegata') ? undefined : json['idBozzaCollegata'],
        'nativeId': !runtime_1.exists(json, 'nativeId') ? undefined : json['nativeId'],
        'progressivoInserimento': !runtime_1.exists(json, 'progressivoInserimento') ? undefined : json['progressivoInserimento'],
        'annoInserimento': !runtime_1.exists(json, 'annoInserimento') ? undefined : json['annoInserimento'],
        'dataPrenotazione': !runtime_1.exists(json, 'dataPrenotazione') ? undefined : (new Date(json['dataPrenotazione'])),
        'statoEP': !runtime_1.exists(json, 'statoEP') ? undefined : json['statoEP'],
        'canale': !runtime_1.exists(json, 'canale') ? undefined : json['canale'],
        'alias': !runtime_1.exists(json, 'alias') ? undefined : json['alias'],
        'contoAddebitoIban': !runtime_1.exists(json, 'contoAddebitoIban') ? undefined : json['contoAddebitoIban'],
        'contoAddebitoAlias': !runtime_1.exists(json, 'contoAddebitoAlias') ? undefined : json['contoAddebitoAlias'],
        'ordinanteIntestazione': !runtime_1.exists(json, 'ordinanteIntestazione') ? undefined : json['ordinanteIntestazione'],
        'statoReport': !runtime_1.exists(json, 'statoReport') ? undefined : json['statoReport'],
    };
}
exports.RavDtoFromJSONTyped = RavDtoFromJSONTyped;
function RavDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'contoAddebito': value.contoAddebito,
        'userId': value.userId,
        'numeroBollettino': value.numeroBollettino,
        'numeroOperazione': value.numeroOperazione,
        'importo': value.importo,
        'dataScadenza': value.dataScadenza === undefined ? undefined : (value.dataScadenza.toISOString()),
        'note': value.note,
        'condivisione': value.condivisione === undefined ? undefined : (value.condivisione.map(_1.CondivisioneDtoToJSON)),
        'stato': value.stato,
        'revocabile': value.revocabile,
        'bozza': _1.BozzaDtoToJSON(value.bozza),
        'inviato': _1.InviatoDtoToJSON(value.inviato),
        'datiPagamento': _1.DatiPagamentoCommonDtoToJSON(value.datiPagamento),
        'statoDisposizione': value.statoDisposizione,
        'idBozzaCollegata': value.idBozzaCollegata,
        'nativeId': value.nativeId,
        'progressivoInserimento': value.progressivoInserimento,
        'annoInserimento': value.annoInserimento,
        'dataPrenotazione': value.dataPrenotazione === undefined ? undefined : (value.dataPrenotazione.toISOString()),
        'statoEP': value.statoEP,
        'canale': value.canale,
        'alias': value.alias,
        'contoAddebitoIban': value.contoAddebitoIban,
        'contoAddebitoAlias': value.contoAddebitoAlias,
        'ordinanteIntestazione': value.ordinanteIntestazione,
        'statoReport': value.statoReport,
    };
}
exports.RavDtoToJSON = RavDtoToJSON;
/**
* @export
* @enum {string}
*/
var RavDtoStatoEnum;
(function (RavDtoStatoEnum) {
    RavDtoStatoEnum["AUTORIZZATO"] = "AUTORIZZATO";
    RavDtoStatoEnum["AUTORIZZATO_NON_REV"] = "AUTORIZZATO_NON_REV";
    RavDtoStatoEnum["NON_AUTORIZZATO"] = "NON_AUTORIZZATO";
    RavDtoStatoEnum["IN_CORSO"] = "IN_CORSO";
    RavDtoStatoEnum["INVIATO"] = "INVIATO";
    RavDtoStatoEnum["ESITO_INCERTO"] = "ESITO_INCERTO";
    RavDtoStatoEnum["REVOCATO"] = "REVOCATO";
    RavDtoStatoEnum["DA_AUTORIZZARE"] = "DA_AUTORIZZARE";
    RavDtoStatoEnum["BOZZA"] = "BOZZA";
    RavDtoStatoEnum["SOSPESO"] = "SOSPESO";
    RavDtoStatoEnum["STORNATO"] = "STORNATO";
    RavDtoStatoEnum["PAGATO"] = "PAGATO";
    RavDtoStatoEnum["ANNULLATO"] = "ANNULLATO";
})(RavDtoStatoEnum = exports.RavDtoStatoEnum || (exports.RavDtoStatoEnum = {}));
/**
* @export
* @enum {string}
*/
var RavDtoStatoDisposizioneEnum;
(function (RavDtoStatoDisposizioneEnum) {
    RavDtoStatoDisposizioneEnum["AUTORIZZATO"] = "AUTORIZZATO";
    RavDtoStatoDisposizioneEnum["AUTORIZZATO_NON_REV"] = "AUTORIZZATO_NON_REV";
    RavDtoStatoDisposizioneEnum["NON_AUTORIZZATO"] = "NON_AUTORIZZATO";
    RavDtoStatoDisposizioneEnum["IN_CORSO"] = "IN_CORSO";
    RavDtoStatoDisposizioneEnum["INVIATO"] = "INVIATO";
    RavDtoStatoDisposizioneEnum["ESITO_INCERTO"] = "ESITO_INCERTO";
    RavDtoStatoDisposizioneEnum["REVOCATO"] = "REVOCATO";
    RavDtoStatoDisposizioneEnum["DA_AUTORIZZARE"] = "DA_AUTORIZZARE";
    RavDtoStatoDisposizioneEnum["BOZZA"] = "BOZZA";
    RavDtoStatoDisposizioneEnum["SOSPESO"] = "SOSPESO";
    RavDtoStatoDisposizioneEnum["STORNATO"] = "STORNATO";
    RavDtoStatoDisposizioneEnum["PAGATO"] = "PAGATO";
    RavDtoStatoDisposizioneEnum["ANNULLATO"] = "ANNULLATO";
})(RavDtoStatoDisposizioneEnum = exports.RavDtoStatoDisposizioneEnum || (exports.RavDtoStatoDisposizioneEnum = {}));
