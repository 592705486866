"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContattoEmailDtoToJSON = exports.ContattoEmailDtoFromJSONTyped = exports.ContattoEmailDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function ContattoEmailDtoFromJSON(json) {
    return ContattoEmailDtoFromJSONTyped(json, false);
}
exports.ContattoEmailDtoFromJSON = ContattoEmailDtoFromJSON;
function ContattoEmailDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'seq': !runtime_1.exists(json, 'seq') ? undefined : json['seq'],
        'indirizzo': !runtime_1.exists(json, 'indirizzo') ? undefined : json['indirizzo'],
        'etichetta': !runtime_1.exists(json, 'etichetta') ? undefined : json['etichetta'],
        'isDefault': !runtime_1.exists(json, 'isDefault') ? undefined : json['isDefault'],
    };
}
exports.ContattoEmailDtoFromJSONTyped = ContattoEmailDtoFromJSONTyped;
function ContattoEmailDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'seq': value.seq,
        'indirizzo': value.indirizzo,
        'etichetta': value.etichetta,
        'isDefault': value.isDefault,
    };
}
exports.ContattoEmailDtoToJSON = ContattoEmailDtoToJSON;
