"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DatiDettOrdiPerContoDiDtoTipoEnum = exports.DatiDettOrdiPerContoDiDtoToJSON = exports.DatiDettOrdiPerContoDiDtoFromJSONTyped = exports.DatiDettOrdiPerContoDiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function DatiDettOrdiPerContoDiDtoFromJSON(json) {
    return DatiDettOrdiPerContoDiDtoFromJSONTyped(json, false);
}
exports.DatiDettOrdiPerContoDiDtoFromJSON = DatiDettOrdiPerContoDiDtoFromJSON;
function DatiDettOrdiPerContoDiDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'tipo': !runtime_1.exists(json, 'tipo') ? undefined : json['tipo'],
        'intestazione': !runtime_1.exists(json, 'intestazione') ? undefined : json['intestazione'],
        'indirizzo': !runtime_1.exists(json, 'indirizzo') ? undefined : json['indirizzo'],
        'comune': !runtime_1.exists(json, 'comune') ? undefined : json['comune'],
        'cap': !runtime_1.exists(json, 'cap') ? undefined : json['cap'],
        'provincia': !runtime_1.exists(json, 'provincia') ? undefined : json['provincia'],
        'paese': !runtime_1.exists(json, 'paese') ? undefined : json['paese'],
    };
}
exports.DatiDettOrdiPerContoDiDtoFromJSONTyped = DatiDettOrdiPerContoDiDtoFromJSONTyped;
function DatiDettOrdiPerContoDiDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'tipo': value.tipo,
        'intestazione': value.intestazione,
        'indirizzo': value.indirizzo,
        'comune': value.comune,
        'cap': value.cap,
        'provincia': value.provincia,
        'paese': value.paese,
    };
}
exports.DatiDettOrdiPerContoDiDtoToJSON = DatiDettOrdiPerContoDiDtoToJSON;
/**
* @export
* @enum {string}
*/
var DatiDettOrdiPerContoDiDtoTipoEnum;
(function (DatiDettOrdiPerContoDiDtoTipoEnum) {
    DatiDettOrdiPerContoDiDtoTipoEnum["D"] = "D";
    DatiDettOrdiPerContoDiDtoTipoEnum["P"] = "P";
})(DatiDettOrdiPerContoDiDtoTipoEnum = exports.DatiDettOrdiPerContoDiDtoTipoEnum || (exports.DatiDettOrdiPerContoDiDtoTipoEnum = {}));
