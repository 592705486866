"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MercatiDtoToJSON = exports.MercatiDtoFromJSONTyped = exports.MercatiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function MercatiDtoFromJSON(json) {
    return MercatiDtoFromJSONTyped(json, false);
}
exports.MercatiDtoFromJSON = MercatiDtoFromJSON;
function MercatiDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'mercati': !runtime_1.exists(json, 'mercati') ? undefined : (json['mercati'].map(_1.MercatoDtoFromJSON)),
    };
}
exports.MercatiDtoFromJSONTyped = MercatiDtoFromJSONTyped;
function MercatiDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'mercati': value.mercati === undefined ? undefined : (value.mercati.map(_1.MercatoDtoToJSON)),
    };
}
exports.MercatiDtoToJSON = MercatiDtoToJSON;
