"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiltroListaContattiDtoTipoEnum = exports.FiltroListaContattiDtoToJSON = exports.FiltroListaContattiDtoFromJSONTyped = exports.FiltroListaContattiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function FiltroListaContattiDtoFromJSON(json) {
    return FiltroListaContattiDtoFromJSONTyped(json, false);
}
exports.FiltroListaContattiDtoFromJSON = FiltroListaContattiDtoFromJSON;
function FiltroListaContattiDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'alias': !runtime_1.exists(json, 'alias') ? undefined : json['alias'],
        'azienda': !runtime_1.exists(json, 'azienda') ? undefined : json['azienda'],
        'nome': !runtime_1.exists(json, 'nome') ? undefined : json['nome'],
        'cognome': !runtime_1.exists(json, 'cognome') ? undefined : json['cognome'],
        'ibanAlias': !runtime_1.exists(json, 'ibanAlias') ? undefined : json['ibanAlias'],
        'beneficiarioAffidabile': !runtime_1.exists(json, 'beneficiarioAffidabile') ? undefined : json['beneficiarioAffidabile'],
        'parolaChiave': !runtime_1.exists(json, 'parolaChiave') ? undefined : json['parolaChiave'],
        'tipo': !runtime_1.exists(json, 'tipo') ? undefined : json['tipo'],
    };
}
exports.FiltroListaContattiDtoFromJSONTyped = FiltroListaContattiDtoFromJSONTyped;
function FiltroListaContattiDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'alias': value.alias,
        'azienda': value.azienda,
        'nome': value.nome,
        'cognome': value.cognome,
        'ibanAlias': value.ibanAlias,
        'beneficiarioAffidabile': value.beneficiarioAffidabile,
        'parolaChiave': value.parolaChiave,
        'tipo': value.tipo,
    };
}
exports.FiltroListaContattiDtoToJSON = FiltroListaContattiDtoToJSON;
/**
* @export
* @enum {string}
*/
var FiltroListaContattiDtoTipoEnum;
(function (FiltroListaContattiDtoTipoEnum) {
    FiltroListaContattiDtoTipoEnum["CF"] = "CF";
    FiltroListaContattiDtoTipoEnum["F24"] = "F24";
    FiltroListaContattiDtoTipoEnum["TARGA"] = "TARGA";
    FiltroListaContattiDtoTipoEnum["PAN"] = "PAN";
    FiltroListaContattiDtoTipoEnum["CELLULARE"] = "CELLULARE";
    FiltroListaContattiDtoTipoEnum["IBAN"] = "IBAN";
})(FiltroListaContattiDtoTipoEnum = exports.FiltroListaContattiDtoTipoEnum || (exports.FiltroListaContattiDtoTipoEnum = {}));
