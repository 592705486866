"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DatiProfessioneDtoToJSON = exports.DatiProfessioneDtoFromJSONTyped = exports.DatiProfessioneDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function DatiProfessioneDtoFromJSON(json) {
    return DatiProfessioneDtoFromJSONTyped(json, false);
}
exports.DatiProfessioneDtoFromJSON = DatiProfessioneDtoFromJSON;
function DatiProfessioneDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'qualificaLavorativa': !runtime_1.exists(json, 'qualificaLavorativa') ? undefined : (json['qualificaLavorativa'].map(_1.ChiaveValoreDtoFromJSON)),
        'cae': !runtime_1.exists(json, 'cae') ? undefined : (json['cae'].map(_1.ChiaveValoreDtoFromJSON)),
        'tae': !runtime_1.exists(json, 'tae') ? undefined : (json['tae'].map(_1.ChiaveValoreDtoFromJSON)),
    };
}
exports.DatiProfessioneDtoFromJSONTyped = DatiProfessioneDtoFromJSONTyped;
function DatiProfessioneDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'qualificaLavorativa': value.qualificaLavorativa === undefined ? undefined : (value.qualificaLavorativa.map(_1.ChiaveValoreDtoToJSON)),
        'cae': value.cae === undefined ? undefined : (value.cae.map(_1.ChiaveValoreDtoToJSON)),
        'tae': value.tae === undefined ? undefined : (value.tae.map(_1.ChiaveValoreDtoToJSON)),
    };
}
exports.DatiProfessioneDtoToJSON = DatiProfessioneDtoToJSON;
