import { delSnackbar, ISnackbar, snackbar } from "@sparkasse/commons";
import React from "react";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { AppState } from "../../reducers";
import ItemSnackbar from "./snackBar.item";

const SnackBar: React.FC<Props> = (props: Props) => {

  const { itemSnackbar, delSnackbar } = props;

  const onClose = (id: number) => {
    delSnackbar(id)
  };

  return (
    <div className="generalView-snackbar">
      <div className="modalView-snackbar" />
      <div className="containerView-snackbar">
        <div className="bodyView-snackbar">
          {itemSnackbar &&
            itemSnackbar.map((item: ISnackbar, index: number) => (
              <ItemSnackbar descrizione={item.descrizione} id={item.id} onCloseSnackbar={onClose} key={index} />
            ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  itemSnackbar: snackbar(state.general),
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      delSnackbar
    },
    dispatch
  );

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export default connect(mapStateToProps, mapDispatchToProps)(SnackBar);
