"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DatiRedditualiPatrimonialiDtoToJSON = exports.DatiRedditualiPatrimonialiDtoFromJSONTyped = exports.DatiRedditualiPatrimonialiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function DatiRedditualiPatrimonialiDtoFromJSON(json) {
    return DatiRedditualiPatrimonialiDtoFromJSONTyped(json, false);
}
exports.DatiRedditualiPatrimonialiDtoFromJSON = DatiRedditualiPatrimonialiDtoFromJSON;
function DatiRedditualiPatrimonialiDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'redditoAnnuo': !runtime_1.exists(json, 'redditoAnnuo') ? undefined : json['redditoAnnuo'],
        'patrimonioTotale': !runtime_1.exists(json, 'patrimonioTotale') ? undefined : json['patrimonioTotale'],
        'esercizioDati': !runtime_1.exists(json, 'esercizioDati') ? undefined : json['esercizioDati'],
        'origineReddito': !runtime_1.exists(json, 'origineReddito') ? undefined : json['origineReddito'],
        'originePatrimonio': !runtime_1.exists(json, 'originePatrimonio') ? undefined : json['originePatrimonio'],
    };
}
exports.DatiRedditualiPatrimonialiDtoFromJSONTyped = DatiRedditualiPatrimonialiDtoFromJSONTyped;
function DatiRedditualiPatrimonialiDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'redditoAnnuo': value.redditoAnnuo,
        'patrimonioTotale': value.patrimonioTotale,
        'esercizioDati': value.esercizioDati,
        'origineReddito': value.origineReddito,
        'originePatrimonio': value.originePatrimonio,
    };
}
exports.DatiRedditualiPatrimonialiDtoToJSON = DatiRedditualiPatrimonialiDtoToJSON;
