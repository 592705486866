import { sendEventToMatomo } from "helpers/utilities";

const IDToPath = (
  id: any,
  navigate: any,
  setWebMenu: any,
  el?: any
) => {
  let path = '';
  // Checking on default value
  if (!!el?.def) {
    // Checking the default value was not deleted by blocking messages
    if (el?.submenu && el.submenu.length > 0) {
      path = el.submenu.map((e: any) => e.id!).indexOf(el.def) > -1 ? el.def : el.submenu[0].id
    }
  } else {
    // Check submenu length
    if (el?.submenu.length > 0) {
      path = el.submenu[0].id
    } else {
      // Get the id of the menu (plain item)
      path = id
    }
  }
  sendEventToMatomo('NAVIGAZIONE_DA_MENU', `navigazione da menu verso ${path}`, 'NAVIGAZIONE_DA_MENU')
  navigate(path);
  setWebMenu(false);
  /* switch (id) {
    case "menu.bonifici_e_giroconti":
      navigate(WEBPAGE.BonificoSepa.url);
      setWebMenu(false);
      break;
    case "menu.saldo_e_movimenti":
      navigate(WEBPAGE.SaldoEMovimenti.url);
      setWebMenu(false);
      break;
    case "menu.movimenti_conto_deposito":
      navigate(WEBPAGE.SaldoEMovimentiDeposito.url);
      setWebMenu(false);
      break;
    case "menu.archivio_disposizioni":
      navigate(WEBPAGE.PagamentiEffettuati.url);
      setWebMenu(false);
      break;
    case "menu.mercato":
      navigate(WEBPAGE.Mercato.url);
      setWebMenu(false);
      break;
    case "menu.mav":
      navigate(WEBPAGE.Mav.url);
      setWebMenu(false);
      break;
    case "menu.rav":
      navigate(WEBPAGE.Rav.url);
      setWebMenu(false);
      break;
    case "menu.ricarica_telefonica":
      navigate(WEBPAGE.RicaricaTelefonica.url);
      setWebMenu(false);
      break;
    case "menu.bancomat_pay":
      navigate(WEBPAGE.BancomatPay.url);
      setWebMenu(false);
      break;
    case "menu.oneclick":
      navigate(WEBPAGE.OperazioniOneClick.url);
      setWebMenu(false);
      break;
    case "menu.rubrica_beneficiari":
      navigate(WEBPAGE.RubricaBeneficiari.url);
      setWebMenu(false);
      break;
    case "menu.bollettino_bancario_freccia":
      navigate(WEBPAGE.BollettinoBancarioFreccia.url);
      setWebMenu(false);
      break;
    case "menu.bollettino_postale":
      navigate(WEBPAGE.BollettinoBianco.url);
      setWebMenu(false);
      break;
    case "menu.bollo_aci":
      navigate(WEBPAGE.BolloAci.url);
      setWebMenu(false);
      break;
    case "menu.assegni":
      navigate(WEBPAGE.Assegni.url);
      setWebMenu(false);
      break;
    case "menu.f24":
      navigate(WEBPAGE.F24Semplificato.url);
      setWebMenu(false);
      break;
    case "menu.cbill":
      navigate(WEBPAGE.Cbill.url);
      setWebMenu(false);
      break;
    case "menu.addebiti_diretti_sdd":
      navigate(WEBPAGE.MandatiSdd.url);
      setWebMenu(false);
      break;
    case "menu.polizze":
      navigate(WEBPAGE.Polizze.url);
      setWebMenu(false);
      break;
    case "menu.ricarica_carta_conto":
      navigate(WEBPAGE.RicaricaSmartcard.url);
      setWebMenu(false);
      break;
    case "menu.carta_conto":
      navigate(WEBPAGE.ElencoSmartcard.url);
      setWebMenu(false);
      break;
    case "menu.addebito_interessi":
      navigate(WEBPAGE.Anatocismo.url);
      setWebMenu(false);
      break;
    case "menu.libretti_risparmio":
      navigate(WEBPAGE.LibrettiRisparmio.url);
      setWebMenu(false);
      break;
    case "menu.riba":
      navigate(WEBPAGE.RicevuteBancarie.url);
      setWebMenu(false);
      break;
    case "menu.mutui":
      navigate(WEBPAGE.PianoMutui.url);
      setWebMenu(false);
      break;
    case "menu.prestiti":
      navigate(WEBPAGE.PrestitoCofidis.url);
      setWebMenu(false);
      break;
    case "menu.carta_di_credito":
      navigate(WEBPAGE.CartaCredito.url);
      setWebMenu(false);
      break;
    case "menu.bonifico_conto_deposito":
      navigate(WEBPAGE.BonificoDeposito.url);
      setWebMenu(false);
      break;
    case "menu.elenco_time_deposit":
      navigate(WEBPAGE.TimeDeposit.url);
      setWebMenu(false);
      break;
    case "menu.flussi_attesi":
      navigate(WEBPAGE.ScadenzaTitoli.url);
      setWebMenu(false);
      break;
    case "menu.altoadige_pass":
      navigate(WEBPAGE.RicaricaAltoAdigePass.url);
      setWebMenu(false);
      break;
    case "menu.compravendita":
      navigate(WEBPAGE.CercaTitolo.url);
      setWebMenu(false);
      break;
    case "menu.bilancio":
      navigate(WEBPAGE.Bilancio.url);
      setWebMenu(false);
      break;
    case "menu.poc_notifica_push":
      navigate(WEBPAGE.NotificePush.url);
      setWebMenu(false);
      break;
    case "menu.time_deposit_box":
      navigate(WEBPAGE.TimeDepositBox.url);
      setWebMenu(false);
      break;
    case "menu.riepilogo_patrimonio":
      navigate(WEBPAGE.Patrimonio.url);
      setWebMenu(false);
      break;
    case "menu.storico_ordini":
      navigate(WEBPAGE.StoricoOrdine.url);
      setWebMenu(false);
      break;
    case "menu.neosurance_polizze_digital":
      navigate("/gestione-polizze");
      setWebMenu(false);
      break;
    case "menu.fido_isi_dispo":
      navigate(WEBPAGE.RichiediFidoIsiDispo.url);
      setWebMenu(false);
      break;
    case "menu.tributi_locali":
      navigate(WEBPAGE.ElencoTributiLocali.url);
      setWebMenu(false);
      break;
    case "menu.neosurance_acquisto_polizza":
      navigate("/gestione-polizze-aquisto");
      setWebMenu(false);
      break;
    case "menu.pronti_contro_termine":
      navigate(WEBPAGE.PctSottoscrizione.url);
      setWebMenu(false);
      break;
    case "menu.polizze_investimento":
      navigate(WEBPAGE.PolizzeInvestimento.url);
      setWebMenu(false);
      break;
    case "menu.rendimenti":
      navigate(WEBPAGE.Rendimenti.url);
      setWebMenu(false);
      break;
    case "menu.per_te":
      navigate(WEBPAGE.CatalogoProdotti.url);
      setWebMenu(false);
      break;
    case "menu.portafoglio_titoli":
      navigate(WEBPAGE.DossierTitoli.url);
      setWebMenu(false);
      break; 
  } */
};

export default IDToPath;
