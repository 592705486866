"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setElencoUltimiAccessiResetForm = exports.setElencoUltimoAccessoSetDataA = exports.setElencoUltimoAccessoSetDataDa = exports.setElencoUltimiAccessiNumeroPagina = exports.setElencoUltimoAccessoSetIntervallo = exports.getElencoUltimiAccessi = exports.getParametriUltimiAccessi = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_1 = require("../general");
const general_configuration_1 = require("../general/general.configuration");
const ultimiAccessi_types_1 = require("./ultimiAccessi.types");
//request parametri start
function getParametriUltimiAccessiStart() {
    return {
        type: ultimiAccessi_types_1.EReduxElencoUltimiAccessiActionTypes.PARAMETRI_GET_PARAMETRI_START
    };
}
;
function getParametriUltimiAccessiSuccess(payload) {
    return {
        type: ultimiAccessi_types_1.EReduxElencoUltimiAccessiActionTypes.PARAMETRI_GET_PARAMETRI_SUCCESS,
        payload
    };
}
;
const getParametriUltimiAccessi = () => async (dispatch, _, extraArgument) => {
    dispatch((0, general_1.setLoader)(true));
    dispatch(getParametriUltimiAccessiStart());
    if (extraArgument.mock) {
        dispatch((0, general_1.setLoader)(false));
    }
    else {
        extraArgument.getItem('jwt').then((jwt) => {
            new api_rest_1.AuditingControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArgument, undefined, jwt)).parametriAuditing().then((response) => {
                dispatch(getParametriUltimiAccessiSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, __1.formatError)(error)));
            }).finally(() => dispatch((0, general_1.setLoader)(false)));
        });
    }
};
exports.getParametriUltimiAccessi = getParametriUltimiAccessi;
// request parametri fine
//request elenco ultimi accessi start
function getElencoUltimiAccessiStart() {
    return {
        type: ultimiAccessi_types_1.EReduxElencoUltimiAccessiActionTypes.ULTIMI_ACCESSI_GET_ULTIMI_ACCESSI_START
    };
}
function getElencoUltimiAccessiSuccess(payload) {
    return {
        type: ultimiAccessi_types_1.EReduxElencoUltimiAccessiActionTypes.ULTIMI_ACCESSI_GET_ULTIMI_ACCESSI_SUCCESS,
        payload
    };
}
const getElencoUltimiAccessi = () => async (dispatch, getState, extraArguments) => {
    const state = getState().elencoUltimiAccessi;
    if (!state.form.numeroPagina || state.form.numeroPagina == 1) {
        dispatch((0, general_1.setLoader)(true));
    }
    if (state.form.numeroPagina && state.form.numeroPagina > 1) {
        dispatch(setElencoUltimiAccessiListLoader(true));
    }
    dispatch(getElencoUltimiAccessiStart());
    if (!state.form.numeroPagina || state.form.numeroPagina == 1) {
        dispatch(resetElencoUltimiAccessi());
    }
    if (extraArguments.mock) {
        dispatch((0, general_1.setLoader)(false));
    }
    else {
        var dataAl = new Date(state.form.dataA);
        dataAl.setHours(23, 59, 59);
        const request = {
            ultimoAccessoInDto: {
                intervallo: state.form.intervallo,
                dataDal: !state.form.intervallo ? new Date(state.form.dataDa) : undefined,
                dataAl: !state.form.intervallo ? dataAl : undefined,
                numeroPagina: state.form.numeroPagina
            }
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.AuditingControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).recuperaUltimoAccesso(request).then((response) => {
                dispatch(getElencoUltimiAccessiSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, __1.formatError)(error)));
            }).finally(() => {
                if (!state.form.numeroPagina || state.form.numeroPagina == 1) {
                    dispatch((0, general_1.setLoader)(false));
                }
                if (state.form.numeroPagina && state.form.numeroPagina > 1) {
                    dispatch(setElencoUltimiAccessiListLoader(false));
                }
            });
        });
    }
};
exports.getElencoUltimiAccessi = getElencoUltimiAccessi;
//request elenco ultimi accessi fine
function generalError(value, showGenericModalError) {
    return async (dispatch) => {
        dispatch((0, general_1.setError)(value, showGenericModalError));
    };
}
;
//filtri
function setElencoUltimoAccessoSetIntervallo(txt) {
    return {
        type: ultimiAccessi_types_1.EReduxElencoUltimiAccessiActionTypes.ULTIMI_ACCESSI_INTERVALLO,
        payload: txt
    };
}
exports.setElencoUltimoAccessoSetIntervallo = setElencoUltimoAccessoSetIntervallo;
;
function setElencoUltimiAccessiNumeroPagina(offset) {
    return {
        type: ultimiAccessi_types_1.EReduxElencoUltimiAccessiActionTypes.ULTIMI_ACCESSI_NUMERO_PAGINA,
        payload: offset
    };
}
exports.setElencoUltimiAccessiNumeroPagina = setElencoUltimiAccessiNumeroPagina;
;
function setElencoUltimoAccessoSetDataDa(txt) {
    return {
        type: ultimiAccessi_types_1.EReduxElencoUltimiAccessiActionTypes.ULTIMI_ACCESSI_DATA_DA,
        payload: txt
    };
}
exports.setElencoUltimoAccessoSetDataDa = setElencoUltimoAccessoSetDataDa;
;
function setElencoUltimoAccessoSetDataA(txt) {
    return {
        type: ultimiAccessi_types_1.EReduxElencoUltimiAccessiActionTypes.ULTIMI_ACCESSI_DATA_A,
        payload: txt
    };
}
exports.setElencoUltimoAccessoSetDataA = setElencoUltimoAccessoSetDataA;
;
function setElencoUltimiAccessiResetForm() {
    return {
        type: ultimiAccessi_types_1.EReduxElencoUltimiAccessiActionTypes.ULTIMI_ACCESSI_FORM_RESET
    };
}
exports.setElencoUltimiAccessiResetForm = setElencoUltimiAccessiResetForm;
;
function setElencoUltimiAccessiListLoader(show) {
    return {
        type: ultimiAccessi_types_1.EReduxElencoUltimiAccessiActionTypes.ULTIMI_ACCESSI_LIST_LOADER,
        payload: show
    };
}
;
function resetElencoUltimiAccessi() {
    return {
        type: ultimiAccessi_types_1.EReduxElencoUltimiAccessiActionTypes.ULTIMI_ACCESSI_RESET_ELENCO
    };
}
;
