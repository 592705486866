"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.StrongAuthInfoDtoToJSON = exports.StrongAuthInfoDtoFromJSONTyped = exports.StrongAuthInfoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function StrongAuthInfoDtoFromJSON(json) {
    return StrongAuthInfoDtoFromJSONTyped(json, false);
}
exports.StrongAuthInfoDtoFromJSON = StrongAuthInfoDtoFromJSON;
function StrongAuthInfoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'async': !runtime_1.exists(json, 'async') ? undefined : json['async'],
        'logger': !runtime_1.exists(json, 'logger') ? undefined : _1.LoggerDtoFromJSON(json['logger']),
        'notificationWebsocketUri': !runtime_1.exists(json, 'notificationWebsocketUri') ? undefined : json['notificationWebsocketUri'],
        'phoneInfo': !runtime_1.exists(json, 'phoneInfo') ? undefined : _1.StrongAuthInfoPhoneInfoDtoFromJSON(json['phoneInfo']),
        'strongAuthTimeout': !runtime_1.exists(json, 'strongAuthTimeout') ? undefined : (new Date(json['strongAuthTimeout'])),
        'strongAuthType': !runtime_1.exists(json, 'strongAuthType') ? undefined : _1.StrongAuthTypeEnumFromJSON(json['strongAuthType']),
        'tokenInfo': !runtime_1.exists(json, 'tokenInfo') ? undefined : _1.StrongAuthInfoTokenInfoDtoFromJSON(json['tokenInfo']),
    };
}
exports.StrongAuthInfoDtoFromJSONTyped = StrongAuthInfoDtoFromJSONTyped;
function StrongAuthInfoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'async': value.async,
        'logger': _1.LoggerDtoToJSON(value.logger),
        'notificationWebsocketUri': value.notificationWebsocketUri,
        'phoneInfo': _1.StrongAuthInfoPhoneInfoDtoToJSON(value.phoneInfo),
        'strongAuthTimeout': value.strongAuthTimeout === undefined ? undefined : (value.strongAuthTimeout.toISOString()),
        'strongAuthType': _1.StrongAuthTypeEnumToJSON(value.strongAuthType),
        'tokenInfo': _1.StrongAuthInfoTokenInfoDtoToJSON(value.tokenInfo),
    };
}
exports.StrongAuthInfoDtoToJSON = StrongAuthInfoDtoToJSON;
