"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UltimoAccessoInDtoToJSON = exports.UltimoAccessoInDtoFromJSONTyped = exports.UltimoAccessoInDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function UltimoAccessoInDtoFromJSON(json) {
    return UltimoAccessoInDtoFromJSONTyped(json, false);
}
exports.UltimoAccessoInDtoFromJSON = UltimoAccessoInDtoFromJSON;
function UltimoAccessoInDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'intervallo': !runtime_1.exists(json, 'intervallo') ? undefined : json['intervallo'],
        'dataDal': !runtime_1.exists(json, 'dataDal') ? undefined : (new Date(json['dataDal'])),
        'dataAl': !runtime_1.exists(json, 'dataAl') ? undefined : (new Date(json['dataAl'])),
        'numeroPagina': json['numeroPagina'],
    };
}
exports.UltimoAccessoInDtoFromJSONTyped = UltimoAccessoInDtoFromJSONTyped;
function UltimoAccessoInDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'intervallo': value.intervallo,
        'dataDal': value.dataDal === undefined ? undefined : (value.dataDal.toISOString()),
        'dataAl': value.dataAl === undefined ? undefined : (value.dataAl.toISOString()),
        'numeroPagina': value.numeroPagina,
    };
}
exports.UltimoAccessoInDtoToJSON = UltimoAccessoInDtoToJSON;
