"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DatiTitoliConMercatoDtoToJSON = exports.DatiTitoliConMercatoDtoFromJSONTyped = exports.DatiTitoliConMercatoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function DatiTitoliConMercatoDtoFromJSON(json) {
    return DatiTitoliConMercatoDtoFromJSONTyped(json, false);
}
exports.DatiTitoliConMercatoDtoFromJSON = DatiTitoliConMercatoDtoFromJSON;
function DatiTitoliConMercatoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'datiTitoli': !runtime_1.exists(json, 'datiTitoli') ? undefined : (json['datiTitoli'].map(_1.DatiTitoloConMercatoDtoFromJSON)),
        'totaleTitoli': !runtime_1.exists(json, 'totaleTitoli') ? undefined : json['totaleTitoli'],
    };
}
exports.DatiTitoliConMercatoDtoFromJSONTyped = DatiTitoliConMercatoDtoFromJSONTyped;
function DatiTitoliConMercatoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'datiTitoli': value.datiTitoli === undefined ? undefined : (value.datiTitoli.map(_1.DatiTitoloConMercatoDtoToJSON)),
        'totaleTitoli': value.totaleTitoli,
    };
}
exports.DatiTitoliConMercatoDtoToJSON = DatiTitoliConMercatoDtoToJSON;
