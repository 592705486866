"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PagamentoJiffyDtoStatoDisposizioneEnum = exports.PagamentoJiffyDtoToJSON = exports.PagamentoJiffyDtoFromJSONTyped = exports.PagamentoJiffyDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function PagamentoJiffyDtoFromJSON(json) {
    return PagamentoJiffyDtoFromJSONTyped(json, false);
}
exports.PagamentoJiffyDtoFromJSON = PagamentoJiffyDtoFromJSON;
function PagamentoJiffyDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'contoAddebito': !runtime_1.exists(json, 'contoAddebito') ? undefined : json['contoAddebito'],
        'importo': !runtime_1.exists(json, 'importo') ? undefined : json['importo'],
        'commissione': !runtime_1.exists(json, 'commissione') ? undefined : json['commissione'],
        'causale': !runtime_1.exists(json, 'causale') ? undefined : json['causale'],
        'nominativoDestinatario': !runtime_1.exists(json, 'nominativoDestinatario') ? undefined : json['nominativoDestinatario'],
        'numeroTelefonoDestinatario': !runtime_1.exists(json, 'numeroTelefonoDestinatario') ? undefined : json['numeroTelefonoDestinatario'],
        'idStrumento': !runtime_1.exists(json, 'idStrumento') ? undefined : json['idStrumento'],
        'idServizio': !runtime_1.exists(json, 'idServizio') ? undefined : json['idServizio'],
        'idPagamento': !runtime_1.exists(json, 'idPagamento') ? undefined : json['idPagamento'],
        'iban': !runtime_1.exists(json, 'iban') ? undefined : json['iban'],
        'flgInvitation': !runtime_1.exists(json, 'flgInvitation') ? undefined : json['flgInvitation'],
        'checkOtpRichiesto': !runtime_1.exists(json, 'checkOtpRichiesto') ? undefined : json['checkOtpRichiesto'],
        'dataEsecuzione': !runtime_1.exists(json, 'dataEsecuzione') ? undefined : (new Date(json['dataEsecuzione'])),
        'statoDisposizione': !runtime_1.exists(json, 'statoDisposizione') ? undefined : json['statoDisposizione'],
        'bozza': !runtime_1.exists(json, 'bozza') ? undefined : _1.BozzaDtoFromJSON(json['bozza']),
        'idSessioneChiamante': !runtime_1.exists(json, 'idSessioneChiamante') ? undefined : json['idSessioneChiamante'],
        'condivisione': !runtime_1.exists(json, 'condivisione') ? undefined : (json['condivisione'].map(_1.CondivisioneDtoFromJSON)),
        'idOneClickCollegata': !runtime_1.exists(json, 'idOneClickCollegata') ? undefined : json['idOneClickCollegata'],
    };
}
exports.PagamentoJiffyDtoFromJSONTyped = PagamentoJiffyDtoFromJSONTyped;
function PagamentoJiffyDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'contoAddebito': value.contoAddebito,
        'importo': value.importo,
        'commissione': value.commissione,
        'causale': value.causale,
        'nominativoDestinatario': value.nominativoDestinatario,
        'numeroTelefonoDestinatario': value.numeroTelefonoDestinatario,
        'idStrumento': value.idStrumento,
        'idServizio': value.idServizio,
        'idPagamento': value.idPagamento,
        'iban': value.iban,
        'flgInvitation': value.flgInvitation,
        'checkOtpRichiesto': value.checkOtpRichiesto,
        'dataEsecuzione': value.dataEsecuzione === undefined ? undefined : (value.dataEsecuzione.toISOString()),
        'statoDisposizione': value.statoDisposizione,
        'bozza': _1.BozzaDtoToJSON(value.bozza),
        'idSessioneChiamante': value.idSessioneChiamante,
        'condivisione': value.condivisione === undefined ? undefined : (value.condivisione.map(_1.CondivisioneDtoToJSON)),
        'idOneClickCollegata': value.idOneClickCollegata,
    };
}
exports.PagamentoJiffyDtoToJSON = PagamentoJiffyDtoToJSON;
/**
* @export
* @enum {string}
*/
var PagamentoJiffyDtoStatoDisposizioneEnum;
(function (PagamentoJiffyDtoStatoDisposizioneEnum) {
    PagamentoJiffyDtoStatoDisposizioneEnum["AUTORIZZATO"] = "AUTORIZZATO";
    PagamentoJiffyDtoStatoDisposizioneEnum["AUTORIZZATO_NON_REV"] = "AUTORIZZATO_NON_REV";
    PagamentoJiffyDtoStatoDisposizioneEnum["NON_AUTORIZZATO"] = "NON_AUTORIZZATO";
    PagamentoJiffyDtoStatoDisposizioneEnum["IN_CORSO"] = "IN_CORSO";
    PagamentoJiffyDtoStatoDisposizioneEnum["INVIATO"] = "INVIATO";
    PagamentoJiffyDtoStatoDisposizioneEnum["ESITO_INCERTO"] = "ESITO_INCERTO";
    PagamentoJiffyDtoStatoDisposizioneEnum["REVOCATO"] = "REVOCATO";
    PagamentoJiffyDtoStatoDisposizioneEnum["DA_AUTORIZZARE"] = "DA_AUTORIZZARE";
    PagamentoJiffyDtoStatoDisposizioneEnum["BOZZA"] = "BOZZA";
    PagamentoJiffyDtoStatoDisposizioneEnum["SOSPESO"] = "SOSPESO";
    PagamentoJiffyDtoStatoDisposizioneEnum["STORNATO"] = "STORNATO";
    PagamentoJiffyDtoStatoDisposizioneEnum["PAGATO"] = "PAGATO";
    PagamentoJiffyDtoStatoDisposizioneEnum["ANNULLATO"] = "ANNULLATO";
})(PagamentoJiffyDtoStatoDisposizioneEnum = exports.PagamentoJiffyDtoStatoDisposizioneEnum || (exports.PagamentoJiffyDtoStatoDisposizioneEnum = {}));
