"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiltroDettaglioTimeDepositDtoToJSON = exports.FiltroDettaglioTimeDepositDtoFromJSONTyped = exports.FiltroDettaglioTimeDepositDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function FiltroDettaglioTimeDepositDtoFromJSON(json) {
    return FiltroDettaglioTimeDepositDtoFromJSONTyped(json, false);
}
exports.FiltroDettaglioTimeDepositDtoFromJSON = FiltroDettaglioTimeDepositDtoFromJSON;
function FiltroDettaglioTimeDepositDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'timeDeposit': !runtime_1.exists(json, 'timeDeposit') ? undefined : _1.TimeDepositDtoFromJSON(json['timeDeposit']),
        'isDepoBox': !runtime_1.exists(json, 'isDepoBox') ? undefined : json['isDepoBox'],
    };
}
exports.FiltroDettaglioTimeDepositDtoFromJSONTyped = FiltroDettaglioTimeDepositDtoFromJSONTyped;
function FiltroDettaglioTimeDepositDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'timeDeposit': _1.TimeDepositDtoToJSON(value.timeDeposit),
        'isDepoBox': value.isDepoBox,
    };
}
exports.FiltroDettaglioTimeDepositDtoToJSON = FiltroDettaglioTimeDepositDtoToJSON;
