"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConvenzioneRapportoResponseDtoToJSON = exports.ConvenzioneRapportoResponseDtoFromJSONTyped = exports.ConvenzioneRapportoResponseDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ConvenzioneRapportoResponseDtoFromJSON(json) {
    return ConvenzioneRapportoResponseDtoFromJSONTyped(json, false);
}
exports.ConvenzioneRapportoResponseDtoFromJSON = ConvenzioneRapportoResponseDtoFromJSON;
function ConvenzioneRapportoResponseDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'convenzione': !runtime_1.exists(json, 'convenzione') ? undefined : json['convenzione'],
        'cartaList': !runtime_1.exists(json, 'cartaList') ? undefined : (json['cartaList'].map(_1.CartaAbilitabileDtoFromJSON)),
    };
}
exports.ConvenzioneRapportoResponseDtoFromJSONTyped = ConvenzioneRapportoResponseDtoFromJSONTyped;
function ConvenzioneRapportoResponseDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'convenzione': value.convenzione,
        'cartaList': value.cartaList === undefined ? undefined : (value.cartaList.map(_1.CartaAbilitabileDtoToJSON)),
    };
}
exports.ConvenzioneRapportoResponseDtoToJSON = ConvenzioneRapportoResponseDtoToJSON;
