"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shouldUpdateBozzaCBill = exports.setCBillSelectedBiller = exports.setCBillSelectedContatto = exports.setCBillSetNumeroFattura = exports.setCBillSetDescrizioneBiller = exports.setCBillSalvaBozzaValidation = exports.setCBillTarga = exports.setCBillTipoVeicolo = exports.resetCBill = exports.setCBillStep = exports.setCBillStepAction = exports.setCBiltAffidabile = exports.setCBillAgiungiRubrica = exports.setCBillNome = exports.setCBillFormSubmitted = exports.resetCurrentBozzaAndFormCBill = exports.setCBillSalva1Click = exports.setCBillAlias1Click = exports.setCBillNominativoCliente = exports.setCBillIntestazioneBiller = exports.setCBillFacilitatore = exports.setCBillImporto = exports.setCBillCodiceBiller = exports.setCBillRapportoSelezionato = exports.updateBozzaCBill = exports.getCBillFromBozza = exports.delBozzaCBill = exports.addBozzaCBill = exports.getBozzaCBill = exports.getBozzeCBill = exports.getBozzeCBillStart = exports.setCBillBozzaSalvata = exports.getInfoAutenticazioneCBill = exports.getBillersCBill = exports.resetBillers = exports.getCodiciFiscaliCBill = exports.controlloCBill = exports.autorizzaCBill = exports.getParametriCBill = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const general_types_1 = require("../general/general.types");
const helpers_1 = require("../helpers");
const cBill_selectors_1 = require("./cBill.selectors");
const moment = require("moment");
/* get parametri - START */
function getParametriCBillStart() {
    return {
        type: __1.EReduxActionTypes.CBILL_GET_PARAMETRI_START
    };
}
function getParametriCBillSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.CBILL_GET_PARAMETRI_SUCCESS,
        payload
    };
}
function generalError(value, showGenericModalError, sendErrorToMatomo) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value, showGenericModalError));
        !!sendErrorToMatomo && sendErrorToMatomo('CBILL', `code_error CBILL ${value.status} / ${value === null || value === void 0 ? void 0 : value.message} / ${value === null || value === void 0 ? void 0 : value.timestamp}`, 'ESITO_DISPOSITIVA');
    };
}
function getParametriPerRipetiCBillSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.CBILL_GET_PARAMETRI_PER_RIPETI_SUCCESS,
        payload
    };
}
const getParametriCBill = (ripeti) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getParametriCBillStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/parametri_mav.json')).then(parametri => {
            dispatch(ripeti ? getParametriPerRipetiCBillSuccess((0, api_rest_1.ParametriDtoFromJSON)(parametri)) : getParametriCBillSuccess((0, api_rest_1.ParametriDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.CBillControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametersCbill().then((response) => {
                var _a;
                dispatch(ripeti ? getParametriPerRipetiCBillSuccess(response) : getParametriCBillSuccess(response));
                ((_a = response.rapporti) === null || _a === void 0 ? void 0 : _a.length) === 0 && dispatch((0, general_actions_1.setNoRapporti)({ type: general_types_1.TypeNoRapporti.CBILL }));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getParametriCBill = getParametriCBill;
/* get parametri - FINE */
/* autorizza - START */
function autorizzaCBillStart() {
    return {
        type: __1.EReduxActionTypes.CBILL_AUTORIZZA_START
    };
}
function autorizzaCBillSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.CBILL_AUTORIZZA_SUCCESS,
        payload
    };
}
const autorizzaCBill = (idDispositiva, credenziale) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(autorizzaCBillStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/autorizza_mav.json')).then(parametri => {
            dispatch(autorizzaCBillSuccess((0, api_rest_1.EsitoAutorizzazioneDisposizioneCBillDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const request = {
            idDispositiva: idDispositiva,
            credenzialeDispositivaDto: credenziale
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.CBillControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).authorizeCBill(request).then((response) => {
                dispatch(autorizzaCBillSuccess(response));
                extraArguments.sendEventToMatomo('CBILL', `esito dispositiva CBILL ${response.stato}`, 'ESITO_DISPOSITIVA');
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error), false, extraArguments.sendEventToMatomo));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.autorizzaCBill = autorizzaCBill;
/* autorizza - FINE */
/* controllo - START */
function controlloCBillStart() {
    return {
        type: __1.EReduxActionTypes.CBILL_CONTROLLO_START
    };
}
function controlloCBillSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.CBILL_CONTROLLO_SUCCESS,
        payload
    };
}
const controlloCBill = () => async (dispatch, getState, extraArguments) => {
    const cbill = getState().cBill;
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(controlloCBillStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/controllo_mav.json')).then(parametri => {
            dispatch(controlloCBillSuccess((0, api_rest_1.FatturaDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const controlloCBillRequest = {
            fatturaDto: {
                ...(0, cBill_selectors_1.buildCBill)(cbill),
                bozza: {
                    dataUltimaModifica: moment().toDate()
                }
            }
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.CBillControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).controlloCBill(controlloCBillRequest).then((response) => {
                dispatch(controlloCBillSuccess(response));
                dispatch((0, general_actions_1.setLoader)(false));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.controlloCBill = controlloCBill;
/* controllo - FINE */
/* get codici fiscali - START */
function getCodiciFiscaliCBillStart() {
    return {
        type: __1.EReduxActionTypes.CBILL_GET_CODICI_FISCALI_START
    };
}
function getCodiciFiscaliCBillSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.CBILL_GET_CODICI_FISCALI_SUCCESS,
        payload
    };
}
const getCodiciFiscaliCBill = (codiceRapporto) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getCodiciFiscaliCBillStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/controllo_mav.json')).then(parametri => {
            dispatch(getCodiciFiscaliCBillSuccess([]));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const request = {
            codiceRapporto: codiceRapporto
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.CBillControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getCodiciFiscaliByContoAddebito(request).then((response) => {
                dispatch(getCodiciFiscaliCBillSuccess(response));
                dispatch((0, general_actions_1.setLoader)(false));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getCodiciFiscaliCBill = getCodiciFiscaliCBill;
/* get codici fiscali - FINE */
function resetBillers() {
    return {
        type: __1.EReduxActionTypes.CBILL_RESET_BILLERS
    };
}
exports.resetBillers = resetBillers;
/* get billers - START */
function getBillersCBillStart() {
    return {
        type: __1.EReduxActionTypes.CBILL_GET_BILLERS_START
    };
}
function getBillersCBillSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.CBILL_GET_BILLERS_SUCCESS,
        payload
    };
}
const getBillersCBill = (filter) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getBillersCBillStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/controllo_mav.json')).then(parametri => {
            dispatch(getBillersCBillSuccess([]));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const request = {
            filterBillerDto: filter
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.CBillControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).searchBillers(request).then((response) => {
                dispatch(getBillersCBillSuccess(response));
                dispatch((0, general_actions_1.setLoader)(false));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getBillersCBill = getBillersCBill;
/* get billers - FINE */
/* get info autenticazione - START */
function getInfoAutenticazioneCBillStart() {
    return {
        type: __1.EReduxActionTypes.CBILL_GET_INFO_AUTENTICAZIONE_START
    };
}
function getInfoAutenticazioneCBillSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.CBILL_GET_INFO_AUTENTICAZIONE_SUCCESS,
        payload
    };
}
const getInfoAutenticazioneCBill = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getInfoAutenticazioneCBillStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/autenticazione-bonifico.json')).then(parametri => {
            dispatch(getInfoAutenticazioneCBillSuccess((0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const requestParameters = {
            idDispositiva: idDispositiva,
            chiamataUscente,
            eseguiInit,
            offline: !!offline
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.CBillControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).authenticationInformationCBill(requestParameters).then((response) => {
                dispatch(getInfoAutenticazioneCBillSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getInfoAutenticazioneCBill = getInfoAutenticazioneCBill;
/* get info autenticazione - FINE */
function setCBillBozzaSalvata(b) {
    return {
        type: __1.EReduxActionTypes.CBILL_SET_BOZZA_SALVATA,
        payload: b
    };
}
exports.setCBillBozzaSalvata = setCBillBozzaSalvata;
/*  get bozze CBill - START */
function getBozzeCBillStart() {
    return {
        type: __1.EReduxActionTypes.CBILL_GET_BOZZE_START
    };
}
exports.getBozzeCBillStart = getBozzeCBillStart;
function getBozzeCBillSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.CBILL_GET_BOZZE_SUCCESS,
        payload
    };
}
const getBozzeCBill = (request) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getBozzeCBillStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/bozze_mav.json')).then(bozze => {
            dispatch(getBozzeCBillSuccess([]));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const getBozzeCBillRequest = {
            bozzeInDto: request
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.CBillControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getDraftList(getBozzeCBillRequest).then((response) => {
                dispatch(getBozzeCBillSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getBozzeCBill = getBozzeCBill;
/** get bozze CBill-FINE- */
/* get bozza CBill - START */
function getBozzaCBillStart() {
    return {
        type: __1.EReduxActionTypes.CBILL_GET_BOZZA_START
    };
}
function getBozzaCBillSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.CBILL_GET_BOZZA_SUCCESS,
        payload
    };
}
const getBozzaCBill = (id) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getBozzaCBillStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/bozza_mav.json')).then(bozza => {
            dispatch(getBozzaCBillSuccess((0, api_rest_1.FatturaDtoFromJSON)(bozza)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const getBozzaCBillRequest = {
            id
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.CBillControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getDraftById(getBozzaCBillRequest).then((response) => {
                dispatch(getBozzaCBillSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getBozzaCBill = getBozzaCBill;
/* get bozza CBill -  FINE */
/* add bozza CBill - START */
function addBozzaCBillStart() {
    return {
        type: __1.EReduxActionTypes.CBILL_ADD_BOZZA_START
    };
}
function addBozzaCBillSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.CBILL_ADD_BOZZA_SUCCESS,
        payload
    };
}
const addBozzaCBill = () => async (dispatch, getState, extraArguments) => {
    const cbill = getState().cBill;
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(addBozzaCBillStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/bozza_mav_response.json')).then(bozza => {
            dispatch(addBozzaCBillSuccess((0, api_rest_1.FatturaDtoFromJSON)(bozza)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const addBozzaCBilleRequest = {
            fatturaDto: {
                ...(0, cBill_selectors_1.buildCBill)(cbill),
                bozza: {
                    dataUltimaModifica: moment().toDate()
                }
            }
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.CBillControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).createDraft(addBozzaCBilleRequest).then((response) => {
                dispatch(addBozzaCBillSuccess(response));
                dispatch((0, general_actions_1.addSnackbar)('bolloACI.nuovaBozzaBolloACISalvata'));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.addBozzaCBill = addBozzaCBill;
/* add bozza CBill- FINE */
/* del bozza CBill - START */
function delBozzaCBillStart() {
    return {
        type: __1.EReduxActionTypes.CBILL_DEL_BOZZA_START
    };
}
function delBozzaCBillSuccess(id) {
    return {
        type: __1.EReduxActionTypes.CBILL_DEL_BOZZA_SUCCESS,
        payload: id
    };
}
const delBozzaCBill = (id) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(delBozzaCBillStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/bozza_mav.json')).then(bozza => {
            dispatch(delBozzaCBillSuccess(id));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const delBozzaCBillRequest = {
            id
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.CBillControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).deleteDraft(delBozzaCBillRequest).then(() => {
                dispatch(delBozzaCBillSuccess(id));
                dispatch((0, general_actions_1.addSnackbar)('bolloACI.bozzaEliminataConSuccesso'));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.delBozzaCBill = delBozzaCBill;
/* del bozza CBill - FINE */
/** get CBill from  bozza -START */
function getCBillFromBozza(payload, noBozza = false) {
    return {
        type: __1.EReduxActionTypes.CBILL_GET_CBILL_FROM_BOZZA,
        payload,
        noBozza
    };
}
exports.getCBillFromBozza = getCBillFromBozza;
/* get CBill from bozza - FINE */
/* update bozza CBill - START */
function updateBozzaCBillStart() {
    return {
        type: __1.EReduxActionTypes.CBILL_UPDATE_BOZZA_START
    };
}
function updateBozzaCBillSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.CBILL_UPDATE_BOZZA_SUCCESS,
        payload
    };
}
const updateBozzaCBill = () => async (dispatch, getState, extraArguments) => {
    const cbill = getState().cBill;
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(updateBozzaCBillStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/bozza_mav_response.json')).then(bozza => {
            dispatch(updateBozzaCBillSuccess((0, api_rest_1.FatturaDtoFromJSON)(bozza)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const idCurrentBozza = cbill.currentBozza.id || 0;
        const updateBozzaCBillRequest = {
            id: idCurrentBozza,
            fatturaDto: {
                id: idCurrentBozza,
                ...(0, cBill_selectors_1.buildCBill)(cbill),
                bozza: {
                    dataUltimaModifica: moment().toDate()
                }
            }
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.CBillControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).updateDraft(updateBozzaCBillRequest).then((response) => {
                dispatch(updateBozzaCBillSuccess(response));
                dispatch((0, general_actions_1.addSnackbar)('bolloACI.bozzaBolloACIAggiornata'));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.updateBozzaCBill = updateBozzaCBill;
/* update bozza CBill - FINE */
/* set form values - START */
function setCBillRapportoSelezionato(rapportoSelezionato) {
    return {
        type: __1.EReduxActionTypes.CBILL_SET_RAPPORTO_SELEZIONATO,
        payload: rapportoSelezionato
    };
}
exports.setCBillRapportoSelezionato = setCBillRapportoSelezionato;
function setCBillCodiceBiller(value) {
    return {
        type: __1.EReduxActionTypes.CBILL_SET_CODICE_BILLER,
        payload: value
    };
}
exports.setCBillCodiceBiller = setCBillCodiceBiller;
function setCBillImporto(value) {
    return {
        type: __1.EReduxActionTypes.CBILL_SET_IMPORTO,
        payload: value
    };
}
exports.setCBillImporto = setCBillImporto;
function setCBillFacilitatore(value) {
    return {
        type: __1.EReduxActionTypes.CBILL_SET_FACILITATORE,
        payload: value
    };
}
exports.setCBillFacilitatore = setCBillFacilitatore;
function setCBillIntestazioneBiller(value) {
    return {
        type: __1.EReduxActionTypes.CBILL_SET_INTESTAZIONE_BILLER,
        payload: value
    };
}
exports.setCBillIntestazioneBiller = setCBillIntestazioneBiller;
function setCBillNominativoCliente(value) {
    return {
        type: __1.EReduxActionTypes.CBILL_SET_NOMINATIVO_CLIENTE,
        payload: value
    };
}
exports.setCBillNominativoCliente = setCBillNominativoCliente;
function setCBillAlias1Click(value) {
    return {
        type: __1.EReduxActionTypes.CBILL_SET_ALIAS_1CLICK,
        payload: value
    };
}
exports.setCBillAlias1Click = setCBillAlias1Click;
function setCBillSalva1Click(value) {
    return {
        type: __1.EReduxActionTypes.CBILL_SET_SALVA_1CLICK,
        payload: value
    };
}
exports.setCBillSalva1Click = setCBillSalva1Click;
function resetCurrentBozzaAndFormCBill() {
    return {
        type: __1.EReduxActionTypes.CBILL_RESET_CURRENT_BOZZA_AND_FORM
    };
}
exports.resetCurrentBozzaAndFormCBill = resetCurrentBozzaAndFormCBill;
function setCBillFormSubmitted(value) {
    return {
        type: __1.EReduxActionTypes.CBILL_SET_FROM_SUBMITTED,
        payload: value
    };
}
exports.setCBillFormSubmitted = setCBillFormSubmitted;
function setCBillNome(value) {
    return {
        type: __1.EReduxActionTypes.CBILL_SET_NOME,
        payload: value
    };
}
exports.setCBillNome = setCBillNome;
function setCBillAgiungiRubrica(value) {
    return {
        type: __1.EReduxActionTypes.CBILL_SET_AGIUNGI_RUBRICA,
        payload: value
    };
}
exports.setCBillAgiungiRubrica = setCBillAgiungiRubrica;
function setCBiltAffidabile(value) {
    return {
        type: __1.EReduxActionTypes.CBILL_SET_AFFIDABILE,
        payload: value
    };
}
exports.setCBiltAffidabile = setCBiltAffidabile;
function setCBillStepAction(value) {
    return {
        type: __1.EReduxActionTypes.CBILL_SET_STEP,
        payload: value
    };
}
exports.setCBillStepAction = setCBillStepAction;
const setCBillStep = (i) => async (dispatch, _, extraArguments) => {
    dispatch(setCBillStepAction(i));
    if (extraArguments.platform == 'mobile') {
        dispatch((0, general_actions_1.setBottomTabBarVisible)(i != 2));
    }
};
exports.setCBillStep = setCBillStep;
function resetCBill() {
    return {
        type: __1.EReduxActionTypes.CBILL_RESET
    };
}
exports.resetCBill = resetCBill;
function setCBillTipoVeicolo(value) {
    return {
        type: __1.EReduxActionTypes.CBILL_SET_TIPO_VEICOLO,
        payload: value
    };
}
exports.setCBillTipoVeicolo = setCBillTipoVeicolo;
function setCBillTarga(value) {
    return {
        type: __1.EReduxActionTypes.CBILL_SET_TARGA,
        payload: value
    };
}
exports.setCBillTarga = setCBillTarga;
function setCBillSalvaBozzaValidation() {
    return {
        type: __1.EReduxActionTypes.CBILL_SET_SALVA_BOZZA_VALIDATION
    };
}
exports.setCBillSalvaBozzaValidation = setCBillSalvaBozzaValidation;
function setCBillSetDescrizioneBiller(payload) {
    return {
        type: __1.EReduxActionTypes.CBILL_DESCRIZIONE_BILLER,
        payload
    };
}
exports.setCBillSetDescrizioneBiller = setCBillSetDescrizioneBiller;
function setCBillSetNumeroFattura(payload) {
    return {
        type: __1.EReduxActionTypes.CBILL_NUMERO_FATTURA,
        payload
    };
}
exports.setCBillSetNumeroFattura = setCBillSetNumeroFattura;
function setCBillSelectedContatto(payload) {
    return {
        type: __1.EReduxActionTypes.CBILL_SET_CONTATTO_SELEZIONATO,
        payload
    };
}
exports.setCBillSelectedContatto = setCBillSelectedContatto;
function setCBillSelectedBiller(payload) {
    return {
        type: __1.EReduxActionTypes.CBILL_SET_SELECTED_BILLER,
        payload
    };
}
exports.setCBillSelectedBiller = setCBillSelectedBiller;
/* set form values - FINE */
const shouldUpdateBozzaCBill = () => async (dispatch, getState, extraArguments) => {
    const state = getState().cBill;
    if ((0, cBill_selectors_1.checkUpdateBozzaCBill)(state)) {
        dispatch((0, exports.updateBozzaCBill)());
    }
    else {
        dispatch((0, exports.addBozzaCBill)());
    }
};
exports.shouldUpdateBozzaCBill = shouldUpdateBozzaCBill;
