import { getCustomEnv, selectedEnv } from '@sparkasse/commons';
import { sendEventToMatomo } from 'helpers/utilities';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import App from './App';
import i18next, { initI18n } from './i18n';
import { reducerStore } from './reducers';
import * as serviceWorker from './serviceWorker';

export const DEFAULT_LANGUAGE = 'it';

export const setItem = async (key: any, value: any, forceCaching?: boolean) => {
  try {
    if (forceCaching) {
      localStorage.setItem(key, value);
    } else {
      sessionStorage.setItem(key, value);
    }
  } catch (e) { }
};

export const getItem = async (key: any, forceCaching?: boolean) => {
  let value = null;
  try {
    if (forceCaching) {
      value = localStorage.getItem(key);
    } else {
      value = sessionStorage.getItem(key);
    }
  } catch (e) { }
  return value;
};

const asyncStorageGet = (key: string) => {
  const value = sessionStorage.getItem(key);
  return Promise.resolve(value);
};

const asyncStorageSet = (key: string, value: string) => {
  sessionStorage.setItem(key, value);
  return Promise.resolve();
};

console.log(process.env);

export const MOCK = false;

const middlewares = [
  thunk.withExtraArgument({
    platform: "desktop",
    setItem,
    getItem,
    mock: MOCK,
    mockBE: (getCustomEnv() || selectedEnv) === 'MOCK',
    sendEventToMatomo: sendEventToMatomo,
  })
];

declare var GLOBAL: any;

if (typeof GLOBAL !== 'undefined') {
  // Route network requests through Chrome's native XMLHttpRequest
  GLOBAL.XMLHttpRequest = GLOBAL.originalXMLHttpRequest || GLOBAL.XMLHttpRequest;

  // Use native Blob for native XMLHttpRequest set above
  GLOBAL.Blob = GLOBAL.originalBlob || GLOBAL.Blob;

  // Use native FileReader to read native Blob set above
  GLOBAL.FileReader = GLOBAL.originalFileReader || GLOBAL.FileReader;
}

const composeEnhancers =
  typeof window === "object" && ((getCustomEnv() || selectedEnv) === "DEV") && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(...middlewares)
  // other store enhancers if any
);

const store = createStore(reducerStore, enhancer);

initI18n(DEFAULT_LANGUAGE, store.getState().i18n.resources);

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// serviceWorker.register();
