"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.WidgetWrapDtoToJSON = exports.WidgetWrapDtoFromJSONTyped = exports.WidgetWrapDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function WidgetWrapDtoFromJSON(json) {
    return WidgetWrapDtoFromJSONTyped(json, false);
}
exports.WidgetWrapDtoFromJSON = WidgetWrapDtoFromJSON;
function WidgetWrapDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'appWidget': !runtime_1.exists(json, 'appWidget') ? undefined : (json['appWidget'].map(_1.WidgetDtoFromJSON)),
        'userWidget': !runtime_1.exists(json, 'userWidget') ? undefined : (json['userWidget'].map(_1.WidgetDtoFromJSON)),
        'futureAppWidget': !runtime_1.exists(json, 'futureAppWidget') ? undefined : (json['futureAppWidget'].map(_1.WidgetDtoFromJSON)),
        'rapporti': !runtime_1.exists(json, 'rapporti') ? undefined : (json['rapporti'].map(_1.RapportoCompletoDtoFromJSON)),
        'parametri': !runtime_1.exists(json, 'parametri') ? undefined : json['parametri'],
    };
}
exports.WidgetWrapDtoFromJSONTyped = WidgetWrapDtoFromJSONTyped;
function WidgetWrapDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'appWidget': value.appWidget === undefined ? undefined : (value.appWidget.map(_1.WidgetDtoToJSON)),
        'userWidget': value.userWidget === undefined ? undefined : (value.userWidget.map(_1.WidgetDtoToJSON)),
        'futureAppWidget': value.futureAppWidget === undefined ? undefined : (value.futureAppWidget.map(_1.WidgetDtoToJSON)),
        'rapporti': value.rapporti === undefined ? undefined : (value.rapporti.map(_1.RapportoCompletoDtoToJSON)),
        'parametri': value.parametri,
    };
}
exports.WidgetWrapDtoToJSON = WidgetWrapDtoToJSON;
