"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.IndirizzoDtoToJSON = exports.IndirizzoDtoFromJSONTyped = exports.IndirizzoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function IndirizzoDtoFromJSON(json) {
    return IndirizzoDtoFromJSONTyped(json, false);
}
exports.IndirizzoDtoFromJSON = IndirizzoDtoFromJSON;
function IndirizzoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'via': !runtime_1.exists(json, 'via') ? undefined : json['via'],
        'codiceCap': !runtime_1.exists(json, 'codiceCap') ? undefined : json['codiceCap'],
        'comune': !runtime_1.exists(json, 'comune') ? undefined : json['comune'],
        'siglaProvincia': !runtime_1.exists(json, 'siglaProvincia') ? undefined : json['siglaProvincia'],
        'codicePaeseDomicilio': !runtime_1.exists(json, 'codicePaeseDomicilio') ? undefined : json['codicePaeseDomicilio'],
        'codicePaeseResidenza': !runtime_1.exists(json, 'codicePaeseResidenza') ? undefined : json['codicePaeseResidenza'],
        'ripetitivo': !runtime_1.exists(json, 'ripetitivo') ? undefined : _1.RipetitivoDtoFromJSON(json['ripetitivo']),
        'ripOrdOriginario': !runtime_1.exists(json, 'ripOrdOriginario') ? undefined : _1.RipetitivoDtoFromJSON(json['ripOrdOriginario']),
        'ripetBeneficiario': !runtime_1.exists(json, 'ripetBeneficiario') ? undefined : _1.RipetitivoDtoFromJSON(json['ripetBeneficiario']),
        'ripetBenOriginario': !runtime_1.exists(json, 'ripetBenOriginario') ? undefined : _1.RipetitivoDtoFromJSON(json['ripetBenOriginario']),
    };
}
exports.IndirizzoDtoFromJSONTyped = IndirizzoDtoFromJSONTyped;
function IndirizzoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'via': value.via,
        'codiceCap': value.codiceCap,
        'comune': value.comune,
        'siglaProvincia': value.siglaProvincia,
        'codicePaeseDomicilio': value.codicePaeseDomicilio,
        'codicePaeseResidenza': value.codicePaeseResidenza,
        'ripetitivo': _1.RipetitivoDtoToJSON(value.ripetitivo),
        'ripOrdOriginario': _1.RipetitivoDtoToJSON(value.ripOrdOriginario),
        'ripetBeneficiario': _1.RipetitivoDtoToJSON(value.ripetBeneficiario),
        'ripetBenOriginario': _1.RipetitivoDtoToJSON(value.ripetBenOriginario),
    };
}
exports.IndirizzoDtoToJSON = IndirizzoDtoToJSON;
