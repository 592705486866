"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OperazioniControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class OperazioniControllerApi extends runtime.BaseAPI {
    /**
     * salva un operazione preferita
     */
    addPreferitaRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.operazioniPreferiteDto === null || requestParameters.operazioniPreferiteDto === undefined) {
                throw new runtime.RequiredError('operazioniPreferiteDto', 'Required parameter requestParameters.operazioniPreferiteDto was null or undefined when calling addPreferita.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/operazioni/preferite/add`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.OperazioniPreferiteDtoToJSON(requestParameters.operazioniPreferiteDto),
            });
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * salva un operazione preferita
     */
    addPreferita(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.addPreferitaRaw(requestParameters);
        });
    }
    /**
     * Restituisce una lista di operazioni preferiti
     */
    operazioniPreferitiRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/operazioni/preferiti`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.GestionePreferitiDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce una lista di operazioni preferiti
     */
    operazioniPreferiti() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.operazioniPreferitiRaw();
            return yield response.value();
        });
    }
}
exports.OperazioniControllerApi = OperazioniControllerApi;
