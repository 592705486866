"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CorrispondenzaFondiDtoToJSON = exports.CorrispondenzaFondiDtoFromJSONTyped = exports.CorrispondenzaFondiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function CorrispondenzaFondiDtoFromJSON(json) {
    return CorrispondenzaFondiDtoFromJSONTyped(json, false);
}
exports.CorrispondenzaFondiDtoFromJSON = CorrispondenzaFondiDtoFromJSON;
function CorrispondenzaFondiDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'ndg': !runtime_1.exists(json, 'ndg') ? undefined : json['ndg'],
        'intestazione': !runtime_1.exists(json, 'intestazione') ? undefined : json['intestazione'],
        'cartacea': !runtime_1.exists(json, 'cartacea') ? undefined : json['cartacea'],
        'rapporti': !runtime_1.exists(json, 'rapporti') ? undefined : json['rapporti'],
    };
}
exports.CorrispondenzaFondiDtoFromJSONTyped = CorrispondenzaFondiDtoFromJSONTyped;
function CorrispondenzaFondiDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'ndg': value.ndg,
        'intestazione': value.intestazione,
        'cartacea': value.cartacea,
        'rapporti': value.rapporti,
    };
}
exports.CorrispondenzaFondiDtoToJSON = CorrispondenzaFondiDtoToJSON;
