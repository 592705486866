import ButtonText2 from "component/buttonText2/buttonText2.component";
import React from "react";

interface Props {
  descrizione: string;
  icon?: string;
  onClick: any;
  disabled?: boolean;
}

const ItemEsito: React.FC<Props> = (props: Props) => {
  const { descrizione, onClick, icon, disabled } = props;

  const onItemClick = () => {
    onClick();
  };

  return (
    <ButtonText2
      descrizione={descrizione}
      onClick={() => onClick && onItemClick()}
      imageOnLeft={true}
      img={icon}
      disabled={disabled}
    />
  );
};

export default ItemEsito;
