"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CatalogoProdottiControllerApi = void 0;
const runtime = require("../runtime");
/**
 *
 */
class CatalogoProdottiControllerApi extends runtime.BaseAPI {
    /**
     * Restituisce l\'elenco dei prodotti.
     */
    getProdottiRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.lingua === null || requestParameters.lingua === undefined) {
                throw new runtime.RequiredError('lingua', 'Required parameter requestParameters.lingua was null or undefined when calling getProdotti.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/public/catalogoprodotti/elenco/{lingua}`.replace(`{${"lingua"}}`, encodeURIComponent(String(requestParameters.lingua))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.TextApiResponse(response);
        });
    }
    /**
     * Restituisce l\'elenco dei prodotti.
     */
    getProdotti(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getProdottiRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce l\'elenco MOCK dei prodotti.
     */
    getProdottiMockRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.abi === null || requestParameters.abi === undefined) {
                throw new runtime.RequiredError('abi', 'Required parameter requestParameters.abi was null or undefined when calling getProdottiMock.');
            }
            if (requestParameters.lingua === null || requestParameters.lingua === undefined) {
                throw new runtime.RequiredError('lingua', 'Required parameter requestParameters.lingua was null or undefined when calling getProdottiMock.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/public/catalogoprodotti/elenco/test/{abi}/{lingua}`.replace(`{${"abi"}}`, encodeURIComponent(String(requestParameters.abi))).replace(`{${"lingua"}}`, encodeURIComponent(String(requestParameters.lingua))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.TextApiResponse(response);
        });
    }
    /**
     * Restituisce l\'elenco MOCK dei prodotti.
     */
    getProdottiMock(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getProdottiMockRaw(requestParameters);
            return yield response.value();
        });
    }
}
exports.CatalogoProdottiControllerApi = CatalogoProdottiControllerApi;
