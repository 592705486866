"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DisposizioneVirtualeChiaveDtoToJSON = exports.DisposizioneVirtualeChiaveDtoFromJSONTyped = exports.DisposizioneVirtualeChiaveDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function DisposizioneVirtualeChiaveDtoFromJSON(json) {
    return DisposizioneVirtualeChiaveDtoFromJSONTyped(json, false);
}
exports.DisposizioneVirtualeChiaveDtoFromJSON = DisposizioneVirtualeChiaveDtoFromJSON;
function DisposizioneVirtualeChiaveDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codiceIstituto': !runtime_1.exists(json, 'codiceIstituto') ? undefined : json['codiceIstituto'],
        'codiceUtente': !runtime_1.exists(json, 'codiceUtente') ? undefined : json['codiceUtente'],
        'funzione': !runtime_1.exists(json, 'funzione') ? undefined : json['funzione'],
        'codiceDisposizione': !runtime_1.exists(json, 'codiceDisposizione') ? undefined : json['codiceDisposizione'],
    };
}
exports.DisposizioneVirtualeChiaveDtoFromJSONTyped = DisposizioneVirtualeChiaveDtoFromJSONTyped;
function DisposizioneVirtualeChiaveDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codiceIstituto': value.codiceIstituto,
        'codiceUtente': value.codiceUtente,
        'funzione': value.funzione,
        'codiceDisposizione': value.codiceDisposizione,
    };
}
exports.DisposizioneVirtualeChiaveDtoToJSON = DisposizioneVirtualeChiaveDtoToJSON;
