"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GestoreTelefonicoDtoToJSON = exports.GestoreTelefonicoDtoFromJSONTyped = exports.GestoreTelefonicoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function GestoreTelefonicoDtoFromJSON(json) {
    return GestoreTelefonicoDtoFromJSONTyped(json, false);
}
exports.GestoreTelefonicoDtoFromJSON = GestoreTelefonicoDtoFromJSON;
function GestoreTelefonicoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'denominazione': !runtime_1.exists(json, 'denominazione') ? undefined : json['denominazione'],
        'codiceSia': !runtime_1.exists(json, 'codiceSia') ? undefined : json['codiceSia'],
    };
}
exports.GestoreTelefonicoDtoFromJSONTyped = GestoreTelefonicoDtoFromJSONTyped;
function GestoreTelefonicoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'denominazione': value.denominazione,
        'codiceSia': value.codiceSia,
    };
}
exports.GestoreTelefonicoDtoToJSON = GestoreTelefonicoDtoToJSON;
