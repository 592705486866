"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DomiciliazioneAmministrativaDtoToJSON = exports.DomiciliazioneAmministrativaDtoFromJSONTyped = exports.DomiciliazioneAmministrativaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function DomiciliazioneAmministrativaDtoFromJSON(json) {
    return DomiciliazioneAmministrativaDtoFromJSONTyped(json, false);
}
exports.DomiciliazioneAmministrativaDtoFromJSON = DomiciliazioneAmministrativaDtoFromJSON;
function DomiciliazioneAmministrativaDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'indicativo': !runtime_1.exists(json, 'indicativo') ? undefined : json['indicativo'],
        'descrizione': !runtime_1.exists(json, 'descrizione') ? undefined : json['descrizione'],
        'indirizzo': !runtime_1.exists(json, 'indirizzo') ? undefined : _1.IndirizzoDatiAnagraficiDtoFromJSON(json['indirizzo']),
    };
}
exports.DomiciliazioneAmministrativaDtoFromJSONTyped = DomiciliazioneAmministrativaDtoFromJSONTyped;
function DomiciliazioneAmministrativaDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'indicativo': value.indicativo,
        'descrizione': value.descrizione,
        'indirizzo': _1.IndirizzoDatiAnagraficiDtoToJSON(value.indirizzo),
    };
}
exports.DomiciliazioneAmministrativaDtoToJSON = DomiciliazioneAmministrativaDtoToJSON;
