"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetEsitoFilialeVirtuale = exports.getCatalogoTemplateFilialeVirtuale = exports.filialeVirtualePDF = exports.setFilialeVirtualeResetRiepilogo = exports.setFilialeVirtualeResetStep = exports.setFilialeVirtualeTipo = exports.setTemplateFilialeVirtualeAction = exports.setFilialeVirtualeStep = exports.setFilialeVirtualeResetForm = exports.setFormSubmittedFilialeVirtuale = exports.getTemplateFilialeVirtuale = exports.setFilialeVirtualeTemplate = exports.setFilialeVirtualeValueTemplate = exports.eliminaRichiestaFilialeVirtuale = exports.controlloRichiestaFilialeVirtuale = exports.resetDettaglioFilialeVirtuale = exports.getDettaglioRichiestaFilialeVirtuale = exports.getRapportiFilialeVirtuale = exports.getContattiFilialeVirtuale = exports.autorizzaFilialeVirtuale = exports.getInfoAutenticazioneFilialeVirtuale = exports.getInfoAutenticazioneReset = exports.resetFiltriFilialeVirtuale = exports.setFilialeVirtualeRichiestaA = exports.setFilialeVirtualeRichiestaDa = exports.setFilialeVirtualeDescrizione = exports.getElencoRichiesteUtente = exports.getElencoTemplateFilialeVirtuale = exports.getParametriFilialeVirtuale = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const catalogoProdotti_selectors_1 = require("../catalogoProdotti/catalogoProdotti.selectors");
const general_1 = require("../general");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
const filialeVirtuale_selectors_1 = require("./filialeVirtuale.selectors");
/* get parametri filiale virtuale - START */
function getParametriFilialeVirtualetStart() {
    return {
        type: __1.EReduxActionTypes.FILIALE_VIRTUALE_GET_PARAMETRI_START
    };
}
function getParametriFilialeVirtualeSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.FILIALE_VIRTUALE_GET_PARAMETRI_SUCCESS,
        payload
    };
}
function generalError(value, showGenericModalError, sendErrorToMatomo) {
    return async (dispatch) => {
        dispatch((0, general_1.setError)(value, showGenericModalError));
        !!sendErrorToMatomo && sendErrorToMatomo('FILIALE_VIRTUALE', `error_code FILIALE_VIRTUALE ${value.status} / ${value.message} / ${value.timestamp}`);
    };
}
const getParametriFilialeVirtuale = () => async (dispatch, _, extraArguments) => {
    dispatch((0, general_1.setLoader)(true));
    dispatch(getParametriFilialeVirtualetStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/assegni_parametri.json')).then(parametri => {
            dispatch(getParametriFilialeVirtualeSuccess((0, api_rest_1.ParametriDtoFromJSON)(parametri)));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.FilialeVirtualeControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametriFilialeVirtuale().then((response) => {
                dispatch(getParametriFilialeVirtualeSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_1.setLoader)(false));
            });
        });
    }
};
exports.getParametriFilialeVirtuale = getParametriFilialeVirtuale;
/* get parametri filiale virtuale - FINE */
/* get elenco template - INIZIO */
function getElencoTemplateStart() {
    return {
        type: __1.EReduxActionTypes.FILIALE_VIRTUALE_GET_ELENCO_TEMPLATE_START
    };
}
function getElencoTemplateSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.FILIALE_VIRTUALE_GET_ELENCO_TEMPLATE_SUCCESS,
        payload
    };
}
/**
 *
 */
const getElencoTemplateFilialeVirtuale = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_1.setLoader)(true));
    dispatch(getElencoTemplateStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/assegni_list.json')).then(parametri => {
            dispatch(getElencoTemplateSuccess((0, api_rest_1.ElencoTemplateFilialeDtoFromJSON)(parametri)));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.FilialeVirtualeControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).listaTemplateFilialeVirtuale().then((response) => {
                dispatch(getElencoTemplateSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_1.setLoader)(false));
            });
        });
    }
};
exports.getElencoTemplateFilialeVirtuale = getElencoTemplateFilialeVirtuale;
/* get elenco template - FINE */
/* get elenco richieste utente - INIZIO */
function getElencoRichiesteUtenteStart() {
    return {
        type: __1.EReduxActionTypes.FILIALE_VIRTUALE_GET_ELENCO_RICHIESTE_START
    };
}
function getElencoRichiesteUtenteSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.FILIALE_VIRTUALE_GET_ELENCO_RICHIESTE_SUCCESS,
        payload
    };
}
const getElencoRichiesteUtente = () => async (dispatch, getState, extraArguments) => {
    const state = getState().filialeVirtuale;
    const filtroElencoRichiesteDto = (0, filialeVirtuale_selectors_1.buildFilterElencoRichiesteUtente)(state);
    dispatch((0, general_1.setLoader)(true));
    dispatch(getElencoRichiesteUtenteStart());
    if (extraArguments.mock) {
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            const request = {
                elencoRichiesteInDto: filtroElencoRichiesteDto
            };
            new api_rest_1.FilialeVirtualeControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).elencoRichieste(request).then((response) => {
                dispatch(getElencoRichiesteUtenteSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_1.setLoader)(false));
            });
        });
    }
};
exports.getElencoRichiesteUtente = getElencoRichiesteUtente;
/* get elenco richieste utente - FINE */
// FILTRI
function setFilialeVirtualeDescrizione(payload) {
    return {
        type: __1.EReduxActionTypes.FILIALE_VIRTUALE_DESCRIZIONE,
        payload
    };
}
exports.setFilialeVirtualeDescrizione = setFilialeVirtualeDescrizione;
function setFilialeVirtualeRichiestaDa(payload) {
    return {
        type: __1.EReduxActionTypes.FILIALE_VIRTUALE_DATA_RICHIESTA_DA,
        payload
    };
}
exports.setFilialeVirtualeRichiestaDa = setFilialeVirtualeRichiestaDa;
function setFilialeVirtualeRichiestaA(payload) {
    return {
        type: __1.EReduxActionTypes.FILIALE_VIRTUALE_DATA_RICHIESTA_A,
        payload
    };
}
exports.setFilialeVirtualeRichiestaA = setFilialeVirtualeRichiestaA;
function resetFiltriFilialeVirtuale() {
    return {
        type: __1.EReduxActionTypes.FILIALE_VIRTUALE_RESET_FILTRI
    };
}
exports.resetFiltriFilialeVirtuale = resetFiltriFilialeVirtuale;
/* Info autenticazione filiale virtuale - START */
function getInfoAutenticazioneStart() {
    return {
        type: __1.EReduxActionTypes.FILIALE_VIRTUALE_GET_INFO_AUTENTICAZIONE_START
    };
}
function getInfoAutenticazioneSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.FILIALE_VIRTUALE_GET_INFO_AUTENTICAZIONE_SUCCESS,
        payload
    };
}
function getInfoAutenticazioneReset() {
    return {
        type: __1.EReduxActionTypes.FILIALE_VIRTUALE_RESET_INFO_AUTENTICAZIONE
    };
}
exports.getInfoAutenticazioneReset = getInfoAutenticazioneReset;
const getInfoAutenticazioneFilialeVirtuale = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_1.setLoader)(true));
    dispatch(getInfoAutenticazioneStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/assegni_list.json')).then((infoAutenticazione) => {
            dispatch(getInfoAutenticazioneSuccess(infoAutenticazione));
        });
    }
    else {
        const requestParameters = {
            idDispositiva,
            chiamataUscente,
            eseguiInit,
            offline: !!offline
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.FilialeVirtualeControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).informazioniAutenticazioneRichiesta(requestParameters).then((response) => {
                dispatch(getInfoAutenticazioneSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_1.setLoader)(false));
            });
        });
    }
};
exports.getInfoAutenticazioneFilialeVirtuale = getInfoAutenticazioneFilialeVirtuale;
/* Info autenticazione filiale virtuale - END */
/* Autorizza filiale virtuale - START */
function autorizzaFilialeVirtualeStart() {
    return {
        type: __1.EReduxActionTypes.FILIALE_VIRTUALE_AUTORIZZA_START
    };
}
function autorizzaFilialeVirtualeSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.FILIALE_VIRTUALE_AUTORIZZA_SUCCESS,
        payload
    };
}
const autorizzaFilialeVirtuale = (idDispositiva, credenzialeDispositivaDto) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_1.setLoader)(true));
    dispatch(autorizzaFilialeVirtualeStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/assegni_list.json')).then((infoAutenticazione) => {
            dispatch(autorizzaFilialeVirtualeSuccess(infoAutenticazione));
        });
    }
    else {
        const requestParameters = {
            credenzialeDispositivaDto,
            idDispositiva
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.FilialeVirtualeControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).autorizzaRichiesta(requestParameters).then((response) => {
                dispatch(autorizzaFilialeVirtualeSuccess(response));
                extraArguments.sendEventToMatomo('FILIALE_VIRTUALE', `esito dispositiva FILIALE_VIRTUALE ${response.stato}`, 'ESITO_DISPOSITIVA');
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error), false, extraArguments.sendEventToMatomo));
            }).finally(() => {
                dispatch((0, general_1.setLoader)(false));
            });
        });
    }
};
exports.autorizzaFilialeVirtuale = autorizzaFilialeVirtuale;
/* Autorizza filiale virtuale - END */
/* get contatti - INIZIO */
function getContattiFilialeVirtualeStart() {
    return {
        type: __1.EReduxActionTypes.FILIALE_VIRTUALE_GET_CONTATTI_START
    };
}
function getContattiFilialeVirtualeSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.FILIALE_VIRTUALE_GET_CONTATTI_SUCCESS,
        payload
    };
}
const getContattiFilialeVirtuale = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_1.setLoader)(true));
    dispatch(getContattiFilialeVirtualeStart());
    if (extraArguments.mock) {
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.FilialeVirtualeControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).contatto().then((response) => {
                dispatch(getContattiFilialeVirtualeSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_1.setLoader)(false));
            });
        });
    }
};
exports.getContattiFilialeVirtuale = getContattiFilialeVirtuale;
/* get contatti - FINE */
/* get rapporti - INIZIO */
function getRapportiFilialeVirtualeStart() {
    return {
        type: __1.EReduxActionTypes.FILIALE_VIRTUALE_GET_RAPPORTI_START
    };
}
function getRapportiFilialeVirtualeSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.FILIALE_VIRTUALE_GET_RAPPORTI_SUCCESS,
        payload
    };
}
const getRapportiFilialeVirtuale = () => async (dispatch, getState, extraArguments) => {
    var _a;
    const state = getState().filialeVirtuale;
    const idTemplate = ((_a = (0, filialeVirtuale_selectors_1.templateSelezionato)(state)) === null || _a === void 0 ? void 0 : _a.id) || 0;
    dispatch((0, general_1.setLoader)(true));
    dispatch(getRapportiFilialeVirtualeStart());
    if (extraArguments.mock) {
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            const request = {
                id: idTemplate
            };
            new api_rest_1.FilialeVirtualeControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).dettaglioTemplateFilialeVirtuale(request).then((response) => {
                dispatch(getRapportiFilialeVirtualeSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_1.setLoader)(false));
            });
        });
    }
};
exports.getRapportiFilialeVirtuale = getRapportiFilialeVirtuale;
/* get rapporti - FINE */
/* get dettaglio richiesta - INIZIO */
function getDettaglioRichiestaFilialeVirtualeStart() {
    return {
        type: __1.EReduxActionTypes.FILIALE_VIRTUALE_GET_DETTAGLIO_START
    };
}
function getDettaglioRichiestaFilialeVirtualeSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.FILIALE_VIRTUALE_GET_DETTAGLIO_SUCCESS,
        payload
    };
}
const getDettaglioRichiestaFilialeVirtuale = (idRichiesta) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_1.setLoader)(true));
    dispatch(getDettaglioRichiestaFilialeVirtualeStart());
    if (extraArguments.mock) {
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            const request = {
                id: idRichiesta
            };
            new api_rest_1.FilialeVirtualeControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).dettaglioRichiesta(request).then((response) => {
                dispatch(getDettaglioRichiestaFilialeVirtualeSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_1.setLoader)(false));
            });
        });
    }
};
exports.getDettaglioRichiestaFilialeVirtuale = getDettaglioRichiestaFilialeVirtuale;
function resetDettaglioFilialeVirtuale() {
    return {
        type: __1.EReduxActionTypes.FILIALE_VIRTUALE_RESET_DETTAGLIO
    };
}
exports.resetDettaglioFilialeVirtuale = resetDettaglioFilialeVirtuale;
/* get dettaglio richiesta - FINE */
/* salva richiesta - INIZIO */
function controlloRichiestaFilialeVirtualeStart() {
    return {
        type: __1.EReduxActionTypes.FILIALE_VIRTUALE_SALVA_RICHIESTA_START
    };
}
function controlloRichiestaFilialeVirtualeSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.FILIALE_VIRTUALE_SALVA_RICHIESTA_SUCCESS,
        payload
    };
}
const controlloRichiestaFilialeVirtuale = () => async (dispatch, getState, extraArguments) => {
    const state = getState().filialeVirtuale;
    const dettaglio = (0, filialeVirtuale_selectors_1.templateSelezionato)(state) || {};
    dispatch((0, general_1.setLoader)(true));
    dispatch(controlloRichiestaFilialeVirtualeStart());
    if (extraArguments.mock) {
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            const request = {
                dettaglioRichiestaDto: { templateCompilato: dettaglio }
            };
            new api_rest_1.FilialeVirtualeControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).controlloRichiesta(request).then((response) => {
                dispatch(controlloRichiestaFilialeVirtualeSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_1.setLoader)(false));
            });
        });
    }
};
exports.controlloRichiestaFilialeVirtuale = controlloRichiestaFilialeVirtuale;
/* salva richiesta - FINE */
/* elimina richiesta - INIZIO */
function IFilialeVirtualEliminaRichiestaStart() {
    return {
        type: __1.EReduxActionTypes.FILIALE_VIRTUALE_ELIMINA_RICHIESTA_START
    };
}
function eliminaRichiestaFilialeVirtualeSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.FILIALE_VIRTUALE_ELIMINA_RICHIESTA_SUCCESS,
        payload
    };
}
const eliminaRichiestaFilialeVirtuale = (idRichiesta) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_1.setLoader)(true));
    dispatch(IFilialeVirtualEliminaRichiestaStart());
    if (extraArguments.mock) {
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            const request = {
                id: idRichiesta
            };
            new api_rest_1.FilialeVirtualeControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).eliminaRichiesta(request).then(() => {
                dispatch(eliminaRichiestaFilialeVirtualeSuccess(idRichiesta));
                dispatch((0, general_1.addSnackbar)('filialeVirtuale.richiestaEliminata'));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_1.setLoader)(false));
            });
        });
    }
};
exports.eliminaRichiestaFilialeVirtuale = eliminaRichiestaFilialeVirtuale;
/* salva elimina - FINE */
// SET VALUE TEMPLATE
function setFilialeVirtualeValueTemplate(payload) {
    return {
        type: __1.EReduxActionTypes.FILIALE_VIRTUALE_SET_VALUE_TEMPLATE,
        payload
    };
}
exports.setFilialeVirtualeValueTemplate = setFilialeVirtualeValueTemplate;
// FORM
function setFilialeVirtualeTemplate(payload) {
    return {
        type: __1.EReduxActionTypes.FILIALE_VIRTUALE_TEMPLATE_SELEZIONATO,
        payload
    };
}
exports.setFilialeVirtualeTemplate = setFilialeVirtualeTemplate;
const getTemplateFilialeVirtuale = (tipo) => async (dispatch, getState, extraArguments) => {
    var _a;
    if (tipo !== 'SELEZIONA') {
        const state = getState().filialeVirtuale;
        const dettaglio = (0, filialeVirtuale_selectors_1.templateList)(state) || {};
        const selezionato = (dettaglio === null || dettaglio === void 0 ? void 0 : dettaglio.find((list) => (list === null || list === void 0 ? void 0 : list.motivoRichiesta) === tipo)) || {};
        dispatch(setFilialeVirtualeTemplate(selezionato));
        const selRapporti = (_a = selezionato === null || selezionato === void 0 ? void 0 : selezionato.campi) === null || _a === void 0 ? void 0 : _a.some((campo) => campo.tipoCampo === 'Elenco Rapporti');
        if (selRapporti) {
            dispatch((0, exports.getRapportiFilialeVirtuale)());
        }
    }
    else {
        dispatch(setFilialeVirtualeTemplate({}));
    }
};
exports.getTemplateFilialeVirtuale = getTemplateFilialeVirtuale;
function setFormSubmittedFilialeVirtuale(bSelect) {
    return {
        type: __1.EReduxActionTypes.FILIALE_VIRTUALE_SET_FORMSUBMITTED,
        payload: bSelect
    };
}
exports.setFormSubmittedFilialeVirtuale = setFormSubmittedFilialeVirtuale;
function setFilialeVirtualeResetForm() {
    return {
        type: __1.EReduxActionTypes.FILIALE_VIRTUALE_RESET_FORM
    };
}
exports.setFilialeVirtualeResetForm = setFilialeVirtualeResetForm;
function setFilialeVirtualeStep(payload) {
    return {
        type: __1.EReduxActionTypes.FILIALE_VIRTUALE_STEP,
        payload
    };
}
exports.setFilialeVirtualeStep = setFilialeVirtualeStep;
const setTemplateFilialeVirtualeAction = (tipo) => async (dispatch, getState, extraArguments) => {
    dispatch(setFilialeVirtualeTipo(tipo));
    dispatch((0, exports.getTemplateFilialeVirtuale)(tipo));
};
exports.setTemplateFilialeVirtualeAction = setTemplateFilialeVirtualeAction;
function setFilialeVirtualeTipo(payload) {
    return {
        type: __1.EReduxActionTypes.FILIALE_VIRTUALE_SET_TIPO,
        payload
    };
}
exports.setFilialeVirtualeTipo = setFilialeVirtualeTipo;
function setFilialeVirtualeResetStep() {
    return {
        type: __1.EReduxActionTypes.FILIALE_VIRTUALE_RESET_STEP
    };
}
exports.setFilialeVirtualeResetStep = setFilialeVirtualeResetStep;
function setFilialeVirtualeResetRiepilogo() {
    return {
        type: __1.EReduxActionTypes.FILIALE_VIRTUALE_RESET_RIEPILOGO
    };
}
exports.setFilialeVirtualeResetRiepilogo = setFilialeVirtualeResetRiepilogo;
/* download Allegato - START */
function filialeVirtualePDFStart() {
    return {
        type: __1.EReduxActionTypes.FILIALE_VIRTUALE_CONDIVIDI_PDF_START
    };
}
function filialeVirtualePDFSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.FILIALE_VIRTUALE_CONDIVIDI_PDF_SUCCESS,
        payload
    };
}
const filialeVirtualePDF = (share, title, message) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_1.setLoader)(true));
    dispatch(filialeVirtualePDFStart());
    const state = getState().filialeVirtuale;
    const dettaglio = (0, filialeVirtuale_selectors_1.buildRichiestaPDF)(state);
    if (extraArguments.mock) {
        dispatch((0, general_1.setLoader)(false));
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            const requestParameters = {
                dettaglioRichiestaDto: dettaglio || {}
            };
            new api_rest_1.FilialeVirtualeControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf')).dettaglioRichiestaPdf(requestParameters).then((response) => {
                if (extraArguments.platform === 'desktop') {
                    (0, helpers_1.hadlePdfResponse)(response, (url) => dispatch((0, general_1.popUpBlockedPdf)(url)));
                }
                if (extraArguments.platform === 'mobile') {
                    (0, helpers_1.download_sharePDF)(extraArguments, 'richiesta.pdf', response, share, title, message);
                }
                dispatch(filialeVirtualePDFSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_1.setLoader)(false));
            });
        });
    }
};
exports.filialeVirtualePDF = filialeVirtualePDF;
/* download Allegato - FINE */
/* get catalogo template - INIZIO */
function getCatalogoTemplateStart() {
    return {
        type: __1.EReduxActionTypes.FILIALE_VIRTUALE_GET_CATALOGO_TEMPLATE_START
    };
}
function getCatalogoTemplateSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.FILIALE_VIRTUALE_GET_CATALOGO_TEMPLATE_SUCCESS,
        payload
    };
}
/**
 *
 */
const getCatalogoTemplateFilialeVirtuale = (template) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_1.setLoader)(true));
    const catalogoProdottiState = getState().catalogoProdotti;
    dispatch(getCatalogoTemplateStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/assegni_list.json')).then(parametri => {
            dispatch(getElencoTemplateSuccess((0, api_rest_1.ElencoTemplateFilialeDtoFromJSON)(parametri)));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            const requestParameters = {
                codiceTemplate: template
            };
            new api_rest_1.FilialeVirtualeControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).templateCatalogoProdotti(requestParameters).then((response) => {
                dispatch(getCatalogoTemplateSuccess({ template: response, title: (0, catalogoProdotti_selectors_1.getTitleByCodiceFunzione)(catalogoProdottiState) }));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_1.setLoader)(false));
            });
        });
    }
};
exports.getCatalogoTemplateFilialeVirtuale = getCatalogoTemplateFilialeVirtuale;
/* get elenco template - FINE */
function resetEsitoFilialeVirtuale() {
    return {
        type: __1.EReduxActionTypes.FILIALE_VIRTUALE_RESET_ESITO
    };
}
exports.resetEsitoFilialeVirtuale = resetEsitoFilialeVirtuale;
