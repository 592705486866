"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRiferimentiBloccoCarte = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
function getRiferimentiBloccoCarteStart() {
    return {
        type: __1.EReduxActionTypes.BLOCCO_CARTE_GET_RIFERIMENTI_START
    };
}
function getRiferimentiBloccoCarteSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.BLOCCO_CARTE_GET_RIFERIMENTI_SUCCESS,
        payload
    };
}
function generalError(value) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value));
    };
}
const getRiferimentiBloccoCarte = () => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getRiferimentiBloccoCarteStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.RiferimentiUtiliControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).riferimentiBloccoCarte().then((response) => {
                dispatch(getRiferimentiBloccoCarteSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getRiferimentiBloccoCarte = getRiferimentiBloccoCarte;
