"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CambioDivisaDtoToJSON = exports.CambioDivisaDtoFromJSONTyped = exports.CambioDivisaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function CambioDivisaDtoFromJSON(json) {
    return CambioDivisaDtoFromJSONTyped(json, false);
}
exports.CambioDivisaDtoFromJSON = CambioDivisaDtoFromJSON;
function CambioDivisaDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codice': !runtime_1.exists(json, 'codice') ? undefined : json['codice'],
        'cambio': !runtime_1.exists(json, 'cambio') ? undefined : json['cambio'],
    };
}
exports.CambioDivisaDtoFromJSONTyped = CambioDivisaDtoFromJSONTyped;
function CambioDivisaDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codice': value.codice,
        'cambio': value.cambio,
    };
}
exports.CambioDivisaDtoToJSON = CambioDivisaDtoToJSON;
