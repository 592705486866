"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfiloControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class ProfiloControllerApi extends runtime.BaseAPI {
    /**
     * Autorizzazione per il processo di modifica credenziali
     */
    autorizzaModificaCredenzialiRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.codiceCredenziale === null || requestParameters.codiceCredenziale === undefined) {
                throw new runtime.RequiredError('codiceCredenziale', 'Required parameter requestParameters.codiceCredenziale was null or undefined when calling autorizzaModificaCredenziali.');
            }
            if (requestParameters.cambioCredenzialeRequestDto === null || requestParameters.cambioCredenzialeRequestDto === undefined) {
                throw new runtime.RequiredError('cambioCredenzialeRequestDto', 'Required parameter requestParameters.cambioCredenzialeRequestDto was null or undefined when calling autorizzaModificaCredenziali.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/profilo/credenzialimodificabili/autorizza/{codiceCredenziale}`.replace(`{${"codiceCredenziale"}}`, encodeURIComponent(String(requestParameters.codiceCredenziale))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CambioCredenzialeRequestDtoToJSON(requestParameters.cambioCredenzialeRequestDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.EsitoAutorizzazioneDisposizioneCredenzialiDtoFromJSON(jsonValue));
        });
    }
    /**
     * Autorizzazione per il processo di modifica credenziali
     */
    autorizzaModificaCredenziali(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.autorizzaModificaCredenzialiRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Autorizzazione di una disposizione di modifica profilo
     */
    autorizzaProfiloRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling autorizzaProfilo.');
            }
            if (requestParameters.credenzialeDispositivaDto === null || requestParameters.credenzialeDispositivaDto === undefined) {
                throw new runtime.RequiredError('credenzialeDispositivaDto', 'Required parameter requestParameters.credenzialeDispositivaDto was null or undefined when calling autorizzaProfilo.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/profilo/datianagrafici/autorizza/{idDispositiva}`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CredenzialeDispositivaDtoToJSON(requestParameters.credenzialeDispositivaDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.EsitoAutorizzazioneDisposizioneProfiloDtoFromJSON(jsonValue));
        });
    }
    /**
     * Autorizzazione di una disposizione di modifica profilo
     */
    autorizzaProfilo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.autorizzaProfiloRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Verifica le credenziali fornite e, in caso di successo, blocca l\'utenza
     */
    bloccoUtenzaRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.credenzialeDispositivaDto === null || requestParameters.credenzialeDispositivaDto === undefined) {
                throw new runtime.RequiredError('credenzialeDispositivaDto', 'Required parameter requestParameters.credenzialeDispositivaDto was null or undefined when calling bloccoUtenza.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/profilo/bloccoUtenza`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CredenzialeDispositivaDtoToJSON(requestParameters.credenzialeDispositivaDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.EsitoAutorizzazioneDisposizioneDtoFromJSON(jsonValue));
        });
    }
    /**
     * Verifica le credenziali fornite e, in caso di successo, blocca l\'utenza
     */
    bloccoUtenza(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.bloccoUtenzaRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Aggiornamento profilo utente
     */
    controlloAggiornaProfiloRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.datiAnagraficiDto === null || requestParameters.datiAnagraficiDto === undefined) {
                throw new runtime.RequiredError('datiAnagraficiDto', 'Required parameter requestParameters.datiAnagraficiDto was null or undefined when calling controlloAggiornaProfilo.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/profilo/datianagrafici/controllo`,
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.DatiAnagraficiDtoToJSON(requestParameters.datiAnagraficiDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.DatiAnagraficiDtoFromJSON(jsonValue));
        });
    }
    /**
     * Aggiornamento profilo utente
     */
    controlloAggiornaProfilo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.controlloAggiornaProfiloRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce le credenziali modificabili per l\'utente
     */
    credenzialiModificabiliRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/profilo/credenzialimodificabili`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.CredenzialiDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce le credenziali modificabili per l\'utente
     */
    credenzialiModificabili() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.credenzialiModificabiliRaw();
            return yield response.value();
        });
    }
    /**
     * Restituisce il dettaglio del profilo utente.
     */
    dettaglioProfiloRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/profilo/dettaglio`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ProfiloAnagrafeDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce il dettaglio del profilo utente.
     */
    dettaglioProfilo() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.dettaglioProfiloRaw();
            return yield response.value();
        });
    }
    /**
     * Estende la sessione  utente di altri 5 minuti
     */
    extendSessionRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/profilo/extendsession`,
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Estende la sessione  utente di altri 5 minuti
     */
    extendSession() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.extendSessionRaw();
        });
    }
    /**
     * Restituisce le informazioni relative alla tipologia di autenticazionea
     */
    getBloccoUtenzaRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.eseguiInit !== undefined) {
                queryParameters['eseguiInit'] = requestParameters.eseguiInit;
            }
            if (requestParameters.chiamataUscente !== undefined) {
                queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/profilo/bloccoUtenza`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce le informazioni relative alla tipologia di autenticazionea
     */
    getBloccoUtenza(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getBloccoUtenzaRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce una lista con 3 obj per verificare se le notifiche sono state lette
     */
    getNotificheRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/profilo/menu/notifiche`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.NotificaMenuDtoFromJSON));
        });
    }
    /**
     * Restituisce una lista con 3 obj per verificare se le notifiche sono state lette
     */
    getNotifiche() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getNotificheRaw();
            return yield response.value();
        });
    }
    /**
     * Restituisce i parametri applicabili ai dati dell\'utente.
     */
    getParametriDatiAnagraficiRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/profilo/datipersonali/parametri`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ParametriProfiloDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce i parametri applicabili ai dati dell\'utente.
     */
    getParametriDatiAnagrafici() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getParametriDatiAnagraficiRaw();
            return yield response.value();
        });
    }
    /**
     * Infoautenticazione per il processo di modifica credenziali
     */
    infoAutenticazioneModificaCredenzialiRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.codiceCredenziale === null || requestParameters.codiceCredenziale === undefined) {
                throw new runtime.RequiredError('codiceCredenziale', 'Required parameter requestParameters.codiceCredenziale was null or undefined when calling infoAutenticazioneModificaCredenziali.');
            }
            const queryParameters = {};
            if (requestParameters.chiamataUscente !== undefined) {
                queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
            }
            if (requestParameters.eseguiInit !== undefined) {
                queryParameters['eseguiInit'] = requestParameters.eseguiInit;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/profilo/credenzialimodificabili/infoautenticazione/{codiceCredenziale}`.replace(`{${"codiceCredenziale"}}`, encodeURIComponent(String(requestParameters.codiceCredenziale))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Infoautenticazione per il processo di modifica credenziali
     */
    infoAutenticazioneModificaCredenziali(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.infoAutenticazioneModificaCredenzialiRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva
     */
    informazioniAutenticazioneModificaProfiloRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idDispositiva === null || requestParameters.idDispositiva === undefined) {
                throw new runtime.RequiredError('idDispositiva', 'Required parameter requestParameters.idDispositiva was null or undefined when calling informazioniAutenticazioneModificaProfilo.');
            }
            const queryParameters = {};
            if (requestParameters.chiamataUscente !== undefined) {
                queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
            }
            if (requestParameters.eseguiInit !== undefined) {
                queryParameters['eseguiInit'] = requestParameters.eseguiInit;
            }
            if (requestParameters.offline !== undefined) {
                queryParameters['offline'] = requestParameters.offline;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/profilo/infoautenticazione/{idDispositiva}`.replace(`{${"idDispositiva"}}`, encodeURIComponent(String(requestParameters.idDispositiva))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Informazioni sull\'autenticazione dispositiva
     */
    informazioniAutenticazioneModificaProfilo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.informazioniAutenticazioneModificaProfiloRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Rimuove la sessione  utente
     */
    logoutRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/profilo/logout`,
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Rimuove la sessione  utente
     */
    logout() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.logoutRaw();
        });
    }
    /**
     * Lettura dell\'alberatura menu dell\'utente
     */
    menuRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/profilo/menu`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ProfiloMenuDtoFromJSON(jsonValue));
        });
    }
    /**
     * Lettura dell\'alberatura menu dell\'utente
     */
    menu() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.menuRaw();
            return yield response.value();
        });
    }
    /**
     * Lettura parametri per il profilo
     */
    parametriProfiloRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/profilo/parametri`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ParametriProfiloDtoFromJSON(jsonValue));
        });
    }
    /**
     * Lettura parametri per il profilo
     */
    parametriProfilo() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.parametriProfiloRaw();
            return yield response.value();
        });
    }
    /**
     * Permette all\'utente di salvare le preferenze, es. lingua del sito. In caso di operazione riusciuta con successo non viene restituito alcun body di risposta.
     */
    preferenzeUtenteRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.preferenzeUtenteDto === null || requestParameters.preferenzeUtenteDto === undefined) {
                throw new runtime.RequiredError('preferenzeUtenteDto', 'Required parameter requestParameters.preferenzeUtenteDto was null or undefined when calling preferenzeUtente.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/profilo/preferenze`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.PreferenzeUtenteDtoToJSON(requestParameters.preferenzeUtenteDto),
            });
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Permette all\'utente di salvare le preferenze, es. lingua del sito. In caso di operazione riusciuta con successo non viene restituito alcun body di risposta.
     */
    preferenzeUtente(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.preferenzeUtenteRaw(requestParameters);
        });
    }
    /**
     * Lettura del profilo utente
     */
    profiloRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.mask !== undefined) {
                queryParameters['mask'] = requestParameters.mask;
            }
            if (requestParameters.myBank !== undefined) {
                queryParameters['myBank'] = requestParameters.myBank;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/profilo`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ProfiloDtoFromJSON(jsonValue));
        });
    }
    /**
     * Lettura del profilo utente
     */
    profilo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.profiloRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Recupera dati filiale.
     */
    recuperaDatiFilialeRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/profilo/datifiliale`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.DatiFilialeDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera dati filiale.
     */
    recuperaDatiFiliale() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.recuperaDatiFilialeRaw();
            return yield response.value();
        });
    }
    /**
     * Restituisce le informazioni relative alla tipologia di autenticazione
     */
    sbloccaUtenzaRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/profilo/sbloccoUtenza`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Restituisce le informazioni relative alla tipologia di autenticazione
     */
    sbloccaUtenza() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.sbloccaUtenzaRaw();
        });
    }
    /**
     * Recupera la lista di tags per lingua
     */
    tagsRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.lingua !== undefined) {
                queryParameters['lingua'] = requestParameters.lingua;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/profilo/tags`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.TagDtoFromJSON));
        });
    }
    /**
     * Recupera la lista di tags per lingua
     */
    tags(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.tagsRaw(requestParameters);
            return yield response.value();
        });
    }
}
exports.ProfiloControllerApi = ProfiloControllerApi;
