enum Colors {

  white = '#fff', // white
  transparentWhite = '#ffffff00',
  lightestgrey = '#FBFBFB', // alabaster
  lightgrey = '#F6F6F6', // concrete
  lightgreyVariant = '#F0F0F0', // alluminium
  lightgreyWithOpacity = 'rgba(63, 63, 63, 0.8)',
  grey = '#e3e3e3', // mercury
  silver = '#c7c7c7', // silver
  accessibilityGrey = '#595959',
  mediumgrey = '#9D9D9D', // nobel
  mediumdarkgrey = '#d8d8d8',
  darkgrey = '#3F3F3F', // asphalt
  primary = '#DF0025', // monza
  black = '#161616', // black
  transparentBlack = '#00000000',
  negative = '#B2001C',
  positive = '#419715',
  primaryWithOpacity = 'rgba(223, 0, 37, 0.05)',
  green = '#2b9e1b',
  lightPrimary = '#f5b3be',

  text = '#181818', // nero usato per i testi
  negativeAmount = '#B2001C',
  positiveAmount = '#006731',
  error = '#df0514',

  inputColor = '#444',
  inputBorderColor = '#444',

  gradientA = '#dfdfdf',

  darkBackground = '#161616', // black

  dark = '#202020',

  backgroundIntestazioneF24 = '#a0dceb',
  backgroundDettaglioF24 = '#d7eff5',

  backgroundApp = white,
  statusBar = primary,
  separatorColor = mediumgrey,

  switchgrey = '#C9D8DA',

  chartVariant1 = '#60935D',
  chartVariant2 = '#BAB700',
  chartVariant3 = '#BBDFC5',
  chartVariant4 = '#9BB291',

  chartVariant5 = '#60935d',
  chartVariant6 = '#b2001c',
  chartVariant7 = '#22c065',
  chartVariant8 = '#d85400',

  chartVariant9 = '#006633',
  chartVariant10 = '#93bf1f',
  chartVariant11 = '#2b9e1b',

  chartVariant12 = '#2260b6',
  chartVariant13 = '#fad063',
  chartVariant14 = '#711322',
  chartVariant15 = '#5f49b1',
  chartVariant16 = '#f9bd21',
  chartVariant17 = '#419698',

  rifiutato = '#2b78e4',
  accettato = '#009e0f',

  //DASHBOARD
  capitaleVersatoPrestiti = '#1484ae',
  capitaleResiduoPrestiti = '#47b7e1',
  interessiVersatiPrestiti = '#ae1457',
  interessiResiduiPrestiti = '#e1478a',
  capitaleVersatoMutui = '#c7971a',
  capitaleResiduoMutui = '#fad063',
  interessiVersatiMutui = '#2260b6',
  interessiResiduiMutui = '#6aa0ec',

  pianoAmmortamento = '#55b149',

  skeletonBackground = '#F0F0F0',
  skeletonHighlight = '#FFD6DD',
  skeletonDarkBackground = '#5F5F5F',
  skeletonDarkHighlight = '#8E3C4A',

  legendaAssegniNoPresente = '#FFFFFF',
  legendaAssegniPagato = '#009E0F',
  legendaAssegniAnnullato = '#000000',
  legendaAssegniRespinto = '#CF2A27',
  legendaAssegniBloccato = '#FF9900',
  legendaAssegniManoCliente = '#2B78E4',

  //BILANCIO
  U000000001 = "#ae1457", //Commissioni bancarie uscita,
  E000000001 = "#9bd6dc", //Commissioni bancarie entrata
  U000000002 = "#a7a7a7", //Assegni uscita
  E000000002 = "#49906d", //Assegni entrata
  U000000003 = "#c6495d", //Bonifico uscita
  E000000003 = "#57c0c3", //Bonifico entrata
  U000000004 = "#ff6500", //POS Uscita
  E000000004 = "#85c57c", //POS entrata
  U000000005 = "#e1cb47", //Addebiti e altri pagamenti uscita
  E000000005 = "#062e30", //Addebiti e altri pagamenti entrata
  U000000006 = "#ff6500", //Ricariche uscita
  E000000006 = "#4f5da8", //Ricariche entrata
  U000000007 = "#978b99", //Titoli uscita
  E000000007 = "#39ecf1", //Titoli entrata
  U000000008 = "#e1478a", //Ammortamenti uscita
  E000000008 = "#3a19e8", //Ammortamenti entrata
  ALTRO = "#646464",      //colore altro grafico bilancio
  profiloAMifid = "#5ECDD9",
  profiloBMifid = "#1A2042",
  profiloCMifid = "#CA3864",
  profiloDMifid = "#DFBD6E",
  profiloEMifid = "#ED702D",
  bannerGreen = "#d0e9d3"
};

export default Colors;
