"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatrimonioControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class PatrimonioControllerApi extends runtime.BaseAPI {
    /**
     * Lettura riepilogo patrimonio
     */
    getRiepilogoPatrimonioRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/patrimonio/riepilogo`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.RiepilogoPatrimonioDtoFromJSON(jsonValue));
        });
    }
    /**
     * Lettura riepilogo patrimonio
     */
    getRiepilogoPatrimonio() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getRiepilogoPatrimonioRaw();
            return yield response.value();
        });
    }
    /**
     * Export in PDF riepilogo patrimonio
     */
    riepilogoPatrimonioPdfRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.riepilogoPatrimonioDto === null || requestParameters.riepilogoPatrimonioDto === undefined) {
                throw new runtime.RequiredError('riepilogoPatrimonioDto', 'Required parameter requestParameters.riepilogoPatrimonioDto was null or undefined when calling riepilogoPatrimonioPdf.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/patrimonio/export/pdf`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.RiepilogoPatrimonioDtoToJSON(requestParameters.riepilogoPatrimonioDto),
            });
            return new runtime.BlobApiResponse(response);
        });
    }
    /**
     * Export in PDF riepilogo patrimonio
     */
    riepilogoPatrimonioPdf(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.riepilogoPatrimonioPdfRaw(requestParameters);
            return yield response.value();
        });
    }
}
exports.PatrimonioControllerApi = PatrimonioControllerApi;
