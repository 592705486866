"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfiloDtoToJSON = exports.ProfiloDtoFromJSONTyped = exports.ProfiloDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ProfiloDtoFromJSON(json) {
    return ProfiloDtoFromJSONTyped(json, false);
}
exports.ProfiloDtoFromJSON = ProfiloDtoFromJSON;
function ProfiloDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'datiUtente': !runtime_1.exists(json, 'datiUtente') ? undefined : _1.ProfiloDatiUtenteDtoFromJSON(json['datiUtente']),
        'rapporti': !runtime_1.exists(json, 'rapporti') ? undefined : (json['rapporti'].map(_1.RapportoDtoFromJSON)),
        'customerAwarenessList': !runtime_1.exists(json, 'customerAwarenessList') ? undefined : (json['customerAwarenessList'].map(_1.CustomerAwarenessRestDtoFromJSON)),
        'abilitazioni': !runtime_1.exists(json, 'abilitazioni') ? undefined : (json['abilitazioni'].map(_1.ProfiloAbilitazioneDtoFromJSON)),
        'dataUltimoAccesso': !runtime_1.exists(json, 'dataUltimoAccesso') ? undefined : (new Date(json['dataUltimoAccesso'])),
        'categories': !runtime_1.exists(json, 'categories') ? undefined : (json['categories'].map(_1.CategoryDtoFromJSON)),
        'tags': !runtime_1.exists(json, 'tags') ? undefined : json['tags'],
        'adeguataVerificaRapporti': !runtime_1.exists(json, 'adeguataVerificaRapporti') ? undefined : _1.AdeguataVerificaRapportiDtoFromJSON(json['adeguataVerificaRapporti']),
        'snap': !runtime_1.exists(json, 'snap') ? undefined : json['snap'],
        'readOnlyCampiData': !runtime_1.exists(json, 'readOnlyCampiData') ? undefined : json['readOnlyCampiData'],
        'ultimoAccesso': !runtime_1.exists(json, 'ultimoAccesso') ? undefined : _1.UltimoAccessoOutDtoFromJSON(json['ultimoAccesso']),
    };
}
exports.ProfiloDtoFromJSONTyped = ProfiloDtoFromJSONTyped;
function ProfiloDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'datiUtente': _1.ProfiloDatiUtenteDtoToJSON(value.datiUtente),
        'rapporti': value.rapporti === undefined ? undefined : (value.rapporti.map(_1.RapportoDtoToJSON)),
        'customerAwarenessList': value.customerAwarenessList === undefined ? undefined : (value.customerAwarenessList.map(_1.CustomerAwarenessRestDtoToJSON)),
        'abilitazioni': value.abilitazioni === undefined ? undefined : (value.abilitazioni.map(_1.ProfiloAbilitazioneDtoToJSON)),
        'dataUltimoAccesso': value.dataUltimoAccesso === undefined ? undefined : (value.dataUltimoAccesso.toISOString()),
        'categories': value.categories === undefined ? undefined : (value.categories.map(_1.CategoryDtoToJSON)),
        'tags': value.tags,
        'adeguataVerificaRapporti': _1.AdeguataVerificaRapportiDtoToJSON(value.adeguataVerificaRapporti),
        'snap': value.snap,
        'readOnlyCampiData': value.readOnlyCampiData,
        'ultimoAccesso': _1.UltimoAccessoOutDtoToJSON(value.ultimoAccesso),
    };
}
exports.ProfiloDtoToJSON = ProfiloDtoToJSON;
