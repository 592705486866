import { currencyFormat } from "helpers/utilities";
import React from "react";
import { useTranslation } from "react-i18next";

const AssicurazioniTable: React.FC<iProps> = (props: iProps) => {
  const { t } = useTranslation();
  const { data } = props;


  const buildNoItemMesage = (noItemsText: any) => {
    if (noItemsText && noItemsText.length) {
      noItemsText.map((item: any) => {
        return <p>{item}</p>;
      });
    }
  };

  return (
    <div className="assicurazioniTable">
      <div className="assicurazioniTable-wraper" ><p className="assicurazioniTable-text"> {data.intestatario}</p> <p className="assicurazioniTable-text">{data.iban} </p></div>
      <p className="assicurazioniTable-text">{`${currencyFormat(data.controvalore)} EUR`}</p>
    </div>
  );
};

interface iProps {
  data?: any;
}

export default AssicurazioniTable;


