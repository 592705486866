"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CorrispondenzaDtoToJSON = exports.CorrispondenzaDtoFromJSONTyped = exports.CorrispondenzaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function CorrispondenzaDtoFromJSON(json) {
    return CorrispondenzaDtoFromJSONTyped(json, false);
}
exports.CorrispondenzaDtoFromJSON = CorrispondenzaDtoFromJSON;
function CorrispondenzaDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'servizio': !runtime_1.exists(json, 'servizio') ? undefined : json['servizio'],
        'descrizioneServizio': !runtime_1.exists(json, 'descrizioneServizio') ? undefined : json['descrizioneServizio'],
        'filiale': !runtime_1.exists(json, 'filiale') ? undefined : json['filiale'],
        'conto': !runtime_1.exists(json, 'conto') ? undefined : json['conto'],
        'rapporto': !runtime_1.exists(json, 'rapporto') ? undefined : json['rapporto'],
        'intestazione': !runtime_1.exists(json, 'intestazione') ? undefined : json['intestazione'],
        'cartacea': !runtime_1.exists(json, 'cartacea') ? undefined : json['cartacea'],
        'online': !runtime_1.exists(json, 'online') ? undefined : json['online'],
        'email': !runtime_1.exists(json, 'email') ? undefined : json['email'],
    };
}
exports.CorrispondenzaDtoFromJSONTyped = CorrispondenzaDtoFromJSONTyped;
function CorrispondenzaDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'servizio': value.servizio,
        'descrizioneServizio': value.descrizioneServizio,
        'filiale': value.filiale,
        'conto': value.conto,
        'rapporto': value.rapporto,
        'intestazione': value.intestazione,
        'cartacea': value.cartacea,
        'online': value.online,
        'email': value.email,
    };
}
exports.CorrispondenzaDtoToJSON = CorrispondenzaDtoToJSON;
