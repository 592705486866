"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.redirectLoginMyBank = exports.annullaLoginMyBank = exports.setMyBankLoginType = exports.loginTotalReset = exports.updateStatusSelfReset = exports.startSelfReset = exports.getSelfResetStatus = exports.setNascondiModaleEnrollmentPerSessioneScaduta = exports.loginTransactionStart = exports.tokenReset = exports.refreshTokenOauth2 = exports.logoutOauth2 = exports.setRipetiNuovaPasswordCambioPasswordObbligatorio = exports.setNuovaPasswordCambioPasswordObbligatorio = exports.setVecchiaPasswordCambioPasswordObbligatorio = exports.cambioPasswordObbligatorio = exports.setStepAzioniSmart = exports.setPinAzioniSmart = exports.enrollmentSetSms = exports.enrollmentSetRipetiPin = exports.enrollmentSetPin = exports.setOtpHardwareLogin = exports.setOtpLogin = exports.setVisualizzaQR = exports.setPinLogin = exports.postEnrollment = exports.resetFormEnrollment = exports.getTokenMobile = exports.getToken = exports.StartAuthorizeWeb = exports.setLoginWebsocketClosed = exports.closeLoginWebsocket = exports.setLoginWebsocketOpened = exports.websocket = exports.consentApprovedPost = exports.consentRequestedGet = exports.strongAuthVerify = exports.strongAuthInit = exports.setLoginStep = exports.setLoginNascondiDatiSensibili = exports.setLoginPassword = exports.setLoginUsername = exports.loginOauth2 = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const abiConfiguration_1 = require("../general/abiConfiguration");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
const login_types_1 = require("./login.types");
// METODO ESPOSTO SU BFF - INIZIO
function getAuthenticationContextStart() {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGIN_GET_AUTHENTICATION_CONTEXT_START
    };
}
function getAuthenticationContextSuccess(payload) {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGIN_GET_AUTHENTICATION_CONTEXT_SUCCESS,
        payload
    };
}
function generalError(value, showGenericModalError) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value, showGenericModalError));
    };
}
const loginOauth2 = () => async (dispatch, getState, extraArguments) => {
    dispatch(getAuthenticationContextStart());
    dispatch((0, general_actions_1.setLoader)(true));
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        const lingua = getState().i18n.currentLanguage;
        const request = {
            oauthAuthorizeIn: {
                oauthState: undefined,
                lang: lingua
            }
        };
        new api_rest_1.AuthenticationControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments)).oauthAuthorizeMobile(request).then(async (response) => {
            if (response.context) {
                dispatch(getAuthenticationContextSuccess(response));
                dispatch(authenticationStart());
            }
            else {
                dispatch(generalError(await (0, helpers_1.formatError)({})));
            }
        }).catch(async (error) => {
            dispatch(generalError(await (0, helpers_1.formatError)(error)));
        }).finally(() => {
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
};
exports.loginOauth2 = loginOauth2;
// METODO ESPOSTO SU BFF - FINE
function authenticationStartStart() {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGIN_AUTHENTICATION_START_START
    };
}
function authenticationStartSuccess(payload) {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGIN_AUTHENTICATION_START_SUCCESS,
        payload
    };
}
function authenticationStartError(payload) {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGIN_AUTHENTICATION_START_ERROR,
        payload
    };
}
const authenticationStart = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(authenticationStartStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        let requestParameters = {
            authenticationStartRequestDto: {
                username: getState().login.formLogin.username,
                password: getState().login.formLogin.password,
                identityStoreId: api_rest_1.IdentityStoreIdEnum.LDAP,
                authenticationContext: getState().login.authentication_context,
                options: {
                    offline: true,
                    phoneAbroad: true,
                    rsaMinifiedQrcode: true
                }
            }
        };
        new api_rest_1.AuthenticationServiceControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments)).authenticationStart(requestParameters).then((response) => {
            // new LoginApi(getConfiguration(ConfigurationOauth2, extraArguments, '', undefined, undefined, 'oauth2')).authenticationStart(requestParameters).then((response: AuthenticationResponse) => {
            // const mock = { "session_id": "KWERS29X1RQ4PQIKUFWBLI0JEZCZ3BS86W6SRH2NFHO7LKI1WZFK9CGYC6PU8YMF", "auth_complete": false, "consent_required": false, "password_change": false, "session_timeout": new Date(), "strong_auth_type": StrongAuthType.OTP, "mandatory_action": undefined, "reset_token": false };
            // dispatch(authenticationStartSuccess(mock));
            dispatch(authenticationStartSuccess(response));
        }).catch(async (error) => {
            dispatch(generalError(await (0, helpers_1.formatError)(error, true)));
            dispatch(authenticationStartError(await (0, helpers_1.decodeError)(error)));
        }).finally(() => {
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
};
function setLoginUsername(txt) {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGIN_SET_USERNAME,
        payload: txt
    };
}
exports.setLoginUsername = setLoginUsername;
function setLoginPassword(txt) {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGIN_SET_PASSWORD,
        payload: txt
    };
}
exports.setLoginPassword = setLoginPassword;
function setLoginNascondiDatiSensibili(b) {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGIN_SET_NASCONDI_DATI_SENSIBILI,
        payload: b
    };
}
exports.setLoginNascondiDatiSensibili = setLoginNascondiDatiSensibili;
function setLoginStep(step) {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGIN_SET_STEP,
        payload: step
    };
}
exports.setLoginStep = setLoginStep;
function strongAuthInitStart() {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGIN_STRONG_AUTH_INIT_START
    };
}
function strongAuthInitSuccess(payload) {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGIN_STRONG_AUTH_INIT_SUCCESS,
        payload
    };
}
function strongAuthInitError(payload) {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGIN_STRONG_AUTH_INIT_ERROR,
        payload
    };
}
const strongAuthInit = (chiamataUscente = false, offline = false) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(strongAuthInitStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        let requestParameters = {
            sessionId: getState().login.authenticationResponse.sessionId,
            strongAuthInitRequestDto: {
                options: {
                    app2app: false,
                    channel: api_rest_1.ChannelEnum.MOBILE,
                    offline: offline,
                    phoneAbroad: chiamataUscente,
                    rsaMinifiedQrcode: false
                }
            }
        };
        new api_rest_1.AuthenticationServiceControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments)).authenticationStrongauth(requestParameters).then((response) => {
            // new LoginApi(getConfiguration(ConfigurationOauth2, extraArguments, '', undefined, undefined, 'oauth2')).strongAuthInit(requestParameters).then((response: StrongAuthInfo) => {
            // const mock = { "strong_auth_type": StrongAuthType.OTP, "async": false, "notification_websocket_uri": undefined, "strong_auth_timeout": new Date(), "token_info": { "token_type": "VASCO", "token_model": "DP270", "software_token": false, "expecting_tokencode": false, "expecting_next_tokencode": false, "expecting_pin": false, "expecting_new_pin": false, "expecting_signature": false, "qrcode": { "image": '', "content_type": '' }, "push_notif_sent": false, "values_to_be_typed": undefined }, "phone_info": { "country_code": undefined, "number": undefined, "qrcode": { "image": '', "content_type": '' }, "otp": undefined, "timestamp_start": new Date(), "timestamp_exp": new Date(), "calling_from_server": false, "authorization_code": undefined } };
            // dispatch(strongAuthInitSuccess(mock));
            dispatch(strongAuthInitSuccess(response));
        }).catch(async (error) => {
            dispatch(generalError(await (0, helpers_1.formatError)(error, true)));
            dispatch(strongAuthInitError(await (0, helpers_1.decodeError)(error)));
        }).finally(() => {
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
};
exports.strongAuthInit = strongAuthInit;
function strongAuthVerifyStart() {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGIN_STRONG_AUTH_VERIFY_START
    };
}
function strongAuthVerifySuccess(payload) {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGIN_STRONG_AUTH_VERIFY_SUCCESS,
        payload
    };
}
function strongAuthVerifyError() {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGIN_STRONG_AUTH_VERIFY_ERROR
    };
}
const strongAuthVerify = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(strongAuthVerifyStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        const loginState = getState().login;
        let requestParameters = {
            sessionId: loginState.authenticationResponse.sessionId,
            strongAuthVerifyRequestDto: {
                strongAuthType: loginState.strongAuthInfo.strongAuthType,
                tokenSignature: loginState.formLogin.otp || undefined,
                tokencode: loginState.formLogin.otpHardware
            }
        };
        // new LoginApi(getConfiguration(ConfigurationOauth2, extraArguments, '', undefined, undefined, 'oauth2')).strongAuthVerify(requestParameters).then((response: AuthenticationResponse) => {
        new api_rest_1.AuthenticationServiceControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments)).authenticationStrongauthVerify(requestParameters).then((response) => {
            dispatch(strongAuthVerifySuccess(response));
        }).catch(async (error) => {
            // dispatch(setLoginWebsocketClosed(false));
            dispatch(strongAuthVerifyError());
            dispatch(generalError(await (0, helpers_1.formatError)(error, true), false));
        }).finally(() => {
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
};
exports.strongAuthVerify = strongAuthVerify;
function consentRequestedGetStart() {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGIN_CONSENT_REQUESTED_GET_START
    };
}
function consentRequestedGetSuccess(payload) {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGIN_CONSENT_REQUESTED_GET_SUCCESS,
        payload
    };
}
const consentRequestedGet = (chiamataUscente) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(consentRequestedGetStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        let requestParameters = {
            sessionId: getState().login.authenticationResponse.sessionId
        };
        // new LoginApi(getConfiguration(ConfigurationOauth2, extraArguments, '', undefined, undefined, 'oauth2')).consentRequestedGet(requestParameters).then((response: ScopeList) => {
        new api_rest_1.AuthenticationServiceControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments)).authenticationConsentRequestedScope(requestParameters).then((response) => {
            dispatch(consentRequestedGetSuccess(response));
        }).catch(async (error) => {
            dispatch(generalError(await (0, helpers_1.formatError)(error, true)));
        }).finally(() => {
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
};
exports.consentRequestedGet = consentRequestedGet;
function consentApprovedPostStart() {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGIN_CONSENT_APPROVED_POST_START
    };
}
function consentApprovedPostSuccess() {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGIN_CONSENT_APPROVED_POST_SUCCESS
    };
}
const consentApprovedPost = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(consentApprovedPostStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        let requestParameters = {
            sessionId: getState().login.authenticationResponse.sessionId,
            scopeListDto: getState().login.scopeList
        };
        // new LoginApi(getConfiguration(ConfigurationOauth2, extraArguments, '', undefined, undefined, 'oauth2')).consentApprovedPost(requestParameters).then(() => {
        new api_rest_1.AuthenticationServiceControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments)).authenticationConsent(requestParameters).then(() => {
            dispatch(consentApprovedPostSuccess());
        }).catch(async (error) => {
            dispatch(generalError(await (0, helpers_1.formatError)(error, true)));
        }).finally(() => {
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
};
exports.consentApprovedPost = consentApprovedPost;
const websocket = () => async (dispatch, getState, extraArguments) => {
    let websocketUri = getState().login.strongAuthInfo.notificationWebsocketUri;
    if (websocketUri) {
        const ws = new WebSocket(websocketUri);
        ws.onopen = () => {
            console.log('WS onopen', websocketUri, 'connection opened');
            dispatch(setLoginWebsocketOpened(ws));
            // ws.send('something'); // send a message
        };
        ws.onmessage = (e) => {
            console.log('WS onmessage', websocketUri, 'a message was received', e);
            console.log(e.data);
        };
        ws.onerror = (e) => {
            console.log('WS onerror', websocketUri, 'an error occurred', e);
            // console.log(e.message);
        };
        ws.onclose = (e) => {
            console.log('WS onclose', websocketUri, 'connection closed', e);
            console.log(e.code, e.reason);
            if (getState().login.formLogin.websocket) {
                dispatch(setLoginWebsocketClosed(true));
            }
        };
    }
};
exports.websocket = websocket;
function setLoginWebsocketOpened(ws) {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGIN_WEBSOCKET_OPENED,
        payload: ws
    };
}
exports.setLoginWebsocketOpened = setLoginWebsocketOpened;
const closeLoginWebsocket = () => async (dispatch, getState, extraArguments) => {
    const formLoginState = getState().login.formLogin;
    if (formLoginState.websocket) {
        dispatch(setLoginWebsocketClosed(false));
        // formLoginState.websocket.close();
    }
};
exports.closeLoginWebsocket = closeLoginWebsocket;
function setLoginWebsocketClosed(closed) {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGIN_SET_WEBSOCKET_CLOSED,
        payload: closed
    };
}
exports.setLoginWebsocketClosed = setLoginWebsocketClosed;
// METODO ESPOSTO SU BFF - INIZIO
function getTokenStart() {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGIN_GET_TOKEN_START
    };
}
function getTokenSuccess(response) {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGIN_GET_TOKEN_SUCCESS,
        payload: response
    };
}
function resetLoginFlow() {
    return {
        type: login_types_1.EReduxLoginActionTypes.RESET_LOGIN_FLOW
    };
}
const StartAuthorizeWeb = (state) => async (dispatch, getState, extraArguments) => {
    // Disabilitazione temporanea del controllo referrer
    // let referrer, publicSite;
    // try {
    //   referrer = document.referrer ? (new URL(document.referrer)).origin : undefined;
    //   publicSite = (new URL(Environments[getCustomEnv() || selectedEnv].publicSite)).origin;
    // } catch { }
    // if (state.incomingState === "public" && (referrer !== publicSite || !referrer)) {
    //   dispatch(generalError(await formatError("common.errors.access_denied")));
    // } else {
    dispatch((0, general_actions_1.setLoader)(true));
    const lang = state.lang || getState().i18n.currentLanguage || "it";
    const oauth2_base_url = __1.Environments[(0, __1.getCustomEnv)() || __1.selectedEnv].oauth2;
    const scope = encodeURI(__1.Environments[(0, __1.getCustomEnv)() || __1.selectedEnv].scope);
    const redirectUri = `${location.protocol}//${location.host}`;
    const nonce = (0, helpers_1.getRandomCode)(20);
    state.nonce = nonce;
    const stateCode = `${state.incomingState || ""}${(0, helpers_1.getRandomCode)(10)}`;
    extraArguments.setItem(stateCode, JSON.stringify(state), true).then(() => {
        const client_id = __1.Environments[(0, __1.getCustomEnv)() || __1.selectedEnv].clientId;
        const link = document.createElement('a');
        link.referrerPolicy = 'no-referrer';
        link.href = `${oauth2_base_url}/oauth/authorize?client_id=${client_id}&scope=${scope}&response_type=code&language=${lang}&domain=retail&state=${stateCode}&nonce=${nonce}&redirect_uri=${redirectUri}/oauthredirect`;
        link.click();
    });
};
exports.StartAuthorizeWeb = StartAuthorizeWeb;
const getToken = (codegrant) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getTokenStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        const requestParameters = {
            codeGrantReqIn: {
                codegrant: codegrant,
                redirectUri: location.hostname === 'localhost' ? `${location.protocol}//${location.host}/oauthredirect` : undefined // ${location.protocol}//
            }
        };
        new api_rest_1.AuthenticationControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments)).oauthToken(requestParameters).then((response) => {
            dispatch(getTokenSuccess(response));
        }).catch(async (error) => {
            dispatch(resetLoginFlow());
            dispatch(generalError(await (0, helpers_1.formatError)(error)));
        }).finally(() => {
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
};
exports.getToken = getToken;
const getTokenMobile = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getTokenStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        const authenticationResponse = getState().login.authenticationResponse;
        const sessionId = authenticationResponse.sessionId;
        if (authenticationResponse.consentRequired) {
            const LoginApiController = new api_rest_1.AuthenticationServiceControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments));
            const requestParametersConsentRequestedGetRequest = {
                sessionId: sessionId
            };
            LoginApiController.authenticationConsentRequestedScope(requestParametersConsentRequestedGetRequest).then((scopeList) => {
                const requestParametersConsentApprovedPostRequest = {
                    scopeListDto: scopeList,
                    sessionId: sessionId
                };
                LoginApiController.authenticationConsent(requestParametersConsentApprovedPostRequest).then(() => {
                    const requestParameters = {
                        sessionReqIn: {
                            sessionId: sessionId,
                            info: getState().login.info
                        }
                    };
                    new api_rest_1.AuthenticationControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments)).oauthTokenMobile(requestParameters).then((response) => {
                        dispatch(getTokenSuccess(response));
                    }).catch(async (error) => {
                        dispatch(generalError(await (0, helpers_1.formatError)(error)));
                    }).finally(() => {
                        dispatch((0, general_actions_1.setLoader)(false));
                    });
                }).catch(async (error) => {
                    dispatch(generalError(await (0, helpers_1.formatError)(error, true)));
                    dispatch((0, general_actions_1.setLoader)(false));
                });
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error, true)));
                dispatch((0, general_actions_1.setLoader)(false));
            });
        }
        else {
            const requestParameters = {
                sessionReqIn: {
                    sessionId: sessionId,
                    info: getState().login.info
                }
            };
            new api_rest_1.AuthenticationControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments)).oauthTokenMobile(requestParameters).then((response) => {
                dispatch(getTokenSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error, true)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        }
    }
};
exports.getTokenMobile = getTokenMobile;
// METODO ESPOSTO SU BFF - FINE
function resetFormEnrollment() {
    return {
        type: login_types_1.EReduxLoginActionTypes.RESET_FORM_ENROLLMENT
    };
}
exports.resetFormEnrollment = resetFormEnrollment;
function postEnrollmentStart() {
    return {
        type: login_types_1.EReduxLoginActionTypes.POST_ENROLLMENT_START
    };
}
function postEnrollmentSuccess() {
    return {
        type: login_types_1.EReduxLoginActionTypes.POST_ENROLLMENT_SUCCESS
    };
}
const postEnrollment = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(postEnrollmentStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        let requestParameters = {
            sessionId: getState().login.authenticationResponse.sessionId,
            step: '1',
            tokenSoftwareActivationRequestDto: {
                // activationCode: '',
                appCode: abiConfiguration_1.package_id,
                pin: getState().login.formEnrollment.pin
            }
        };
        // new EnrollmentApi(getConfiguration(ConfigurationOauth2, extraArguments, '', undefined, undefined, 'oauth2')).enrollmentStep(requestParameters).then(() => {
        new api_rest_1.AuthenticationServiceControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments)).enrollmentStep(requestParameters).then(() => {
            dispatch(postEnrollmentSuccess());
        }).catch(async (error) => {
            dispatch(generalError(await (0, helpers_1.formatError)(error, true)));
        }).finally(() => {
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
};
exports.postEnrollment = postEnrollment;
function setPinLogin(pin) {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGIN_SET_PIN,
        payload: pin
    };
}
exports.setPinLogin = setPinLogin;
function setVisualizzaQR(visualizzaQR) {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGIN_SET_VISUALIZZA_QR,
        payload: visualizzaQR
    };
}
exports.setVisualizzaQR = setVisualizzaQR;
function setOtpLogin(otp) {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGIN_SET_OTP,
        payload: otp
    };
}
exports.setOtpLogin = setOtpLogin;
function setOtpHardwareLogin(otp) {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGIN_SET_OTP_HARDWARE,
        payload: otp
    };
}
exports.setOtpHardwareLogin = setOtpHardwareLogin;
function enrollmentSetPin(pin) {
    return {
        type: login_types_1.EReduxLoginActionTypes.ENROLLMENT_SET_PIN,
        payload: pin
    };
}
exports.enrollmentSetPin = enrollmentSetPin;
function enrollmentSetRipetiPin(pin) {
    return {
        type: login_types_1.EReduxLoginActionTypes.ENROLLMENT_SET_RIPETI_PIN,
        payload: pin
    };
}
exports.enrollmentSetRipetiPin = enrollmentSetRipetiPin;
function enrollmentSetSms(sms) {
    return {
        type: login_types_1.EReduxLoginActionTypes.ENROLLMENT_SET_SMS,
        payload: sms
    };
}
exports.enrollmentSetSms = enrollmentSetSms;
function setPinAzioniSmart(pin) {
    return {
        type: login_types_1.EReduxLoginActionTypes.AZIONI_SMART_SET_PIN,
        payload: pin
    };
}
exports.setPinAzioniSmart = setPinAzioniSmart;
function setStepAzioniSmart(step) {
    return {
        type: login_types_1.EReduxLoginActionTypes.AZIONI_SMART_SET_STEP,
        payload: step
    };
}
exports.setStepAzioniSmart = setStepAzioniSmart;
function cambioPasswordObbligatorioStart() {
    return {
        type: login_types_1.EReduxLoginActionTypes.CAMBIO_PASSWORD_OBBLIGATORIO_START
    };
}
function cambioPasswordObbligatorioSuccess(payload) {
    return {
        type: login_types_1.EReduxLoginActionTypes.CAMBIO_PASSWORD_OBBLIGATORIO_SUCCESS,
        payload
    };
}
const cambioPasswordObbligatorio = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(cambioPasswordObbligatorioStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        let requestParameters = {
            sessionId: getState().login.authenticationResponse.sessionId,
            changePasswordRequestDto: {
                passwordNew: getState().login.formCambioPasswordObbligatorio.nuovaPassword,
                passwordOld: getState().login.formCambioPasswordObbligatorio.vecchiaPassword
            }
        };
        // new CredentialApi(getConfiguration(ConfigurationOauth2, extraArguments, '', undefined, undefined, 'oauth2')).credentialChange(requestParameters).then((response: AuthenticationResponse) => {
        new api_rest_1.AuthenticationServiceControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments)).credentialChange(requestParameters).then((response) => {
            dispatch(cambioPasswordObbligatorioSuccess(response));
        }).catch(async (error) => {
            dispatch(generalError(await (0, helpers_1.formatError)(error, true)));
        }).finally(() => {
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
};
exports.cambioPasswordObbligatorio = cambioPasswordObbligatorio;
function setVecchiaPasswordCambioPasswordObbligatorio(password) {
    return {
        type: login_types_1.EReduxLoginActionTypes.CAMBIO_PASSWORD_OBBLIGATORIO_SET_VECCHIA_PASSWORD,
        payload: password
    };
}
exports.setVecchiaPasswordCambioPasswordObbligatorio = setVecchiaPasswordCambioPasswordObbligatorio;
function setNuovaPasswordCambioPasswordObbligatorio(password) {
    return {
        type: login_types_1.EReduxLoginActionTypes.CAMBIO_PASSWORD_OBBLIGATORIO_SET_NUOVA_PASSWORD,
        payload: password
    };
}
exports.setNuovaPasswordCambioPasswordObbligatorio = setNuovaPasswordCambioPasswordObbligatorio;
function setRipetiNuovaPasswordCambioPasswordObbligatorio(password) {
    return {
        type: login_types_1.EReduxLoginActionTypes.CAMBIO_PASSWORD_OBBLIGATORIO_SET_RIPETI_NUOVA_PASSWORD,
        payload: password
    };
}
exports.setRipetiNuovaPasswordCambioPasswordObbligatorio = setRipetiNuovaPasswordCambioPasswordObbligatorio;
function logoutOauth2Start() {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGOUT_OAUTH2_START
    };
}
function logoutOauth2Success() {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGOUT_OAUTH2_SUCCESS
    };
}
function resetAll() {
    return {
        type: __1.EReduxActionTypes.RESET_ALL
    };
}
const logoutOauth2 = () => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(logoutOauth2Start());
    extraArguments.getItem('jwt').then((jwt) => {
        new api_rest_1.ProfiloControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).logout().finally(() => {
            extraArguments.setItem('jwt', '').then(() => {
                dispatch(logoutOauth2Success());
                dispatch(resetAll());
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    });
};
exports.logoutOauth2 = logoutOauth2;
// METODO ESPOSTO SU BFF - INIZIO
function refreshTokenOauth2Start() {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGIN_REFRESH_TOKEN_START
    };
}
function refreshTokenOauth2Success(response) {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGIN_REFRESH_TOKEN_SUCCESS,
        payload: response
    };
}
function refreshTokenOauth2Error() {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGIN_REFRESH_TOKEN_ERROR
    };
}
const refreshTokenOauth2 = (refreshToken) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(refreshTokenOauth2Start());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        const requestParameters = {
            refreshTokenIn: {
                refreshToken
            }
        };
        new api_rest_1.AuthenticationControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments)).oauthTokenRefresh(requestParameters).then((response) => {
            dispatch(refreshTokenOauth2Success(response));
        }).catch(async (error) => {
            const errorObject = await (0, helpers_1.decodeError)(error);
            if ((errorObject === null || errorObject === void 0 ? void 0 : errorObject.details) === "INVALID_GRANT") {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
                dispatch(refreshTokenOauth2Error());
            }
            else {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }
        }).finally(() => {
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
};
exports.refreshTokenOauth2 = refreshTokenOauth2;
// METODO ESPOSTO SU BFF - FINE
function tokenResetStart() {
    return {
        type: login_types_1.EReduxLoginActionTypes.TOKEN_RESET_START
    };
}
function tokenResetSuccess() {
    return {
        type: login_types_1.EReduxLoginActionTypes.TOKEN_RESET_SUCCESS
    };
}
const tokenReset = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(tokenResetStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        let requestParameters = {
            sessionId: getState().login.authenticationResponse.sessionId
        };
        // new TokenApi(getConfiguration(ConfigurationOauth2, extraArguments, '', undefined, undefined, 'oauth2')).tokenReset(requestParameters).then(() => {
        new api_rest_1.AuthenticationServiceControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments)).tokenReset(requestParameters).then(() => {
            dispatch(tokenResetSuccess());
        }).catch(async (error) => {
            dispatch(generalError(await (0, helpers_1.formatError)(error, true)));
        }).finally(() => {
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
};
exports.tokenReset = tokenReset;
/* nuova start transaction */
function loginTransactionStartStart() {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGIN_TRANSACTION_START_START
    };
}
function loginTransactionStartSuccess(response) {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGIN_TRANSACTION_START_SUCCESS,
        payload: response
    };
}
const loginTransactionStart = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(loginTransactionStartStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        const code1 = (0, __1.makeid)(6);
        const code2 = (0, __1.makeid)(6);
        let requestParameters = {
            xUser: getState().login.formLogin.username,
            transactionStartInputDto: {
                lang: getState().i18n.currentLanguage,
                description: {
                    title: "TOKENSOFTWAREENROLLMENT",
                    values: [
                        {
                            "key": "PNjson.op",
                            "value": "TOKENSOFTWAREENROLLMENT"
                        },
                        {
                            "key": "PNjson.data",
                            "value": code1
                        }
                    ]
                },
                shortDescription: {
                    title: "TOKENSOFTWAREENROLLMENT",
                    values: [
                        {
                            "key": "PNjson.op",
                            "value": "TOKENSOFTWAREENROLLMENT"
                        },
                        {
                            "key": "PNjson.data",
                            "value": code1
                        }
                    ]
                },
                tokenInputValue: code2
            }
        };
        new api_rest_1.AuthenticationServiceControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments)).loginTransactionStart(requestParameters).then((result) => {
            dispatch(loginTransactionStartSuccess(result.callbackUrl || ''));
        }).catch(async (error) => {
            dispatch(generalError(await (0, helpers_1.formatError)(error, true)));
        }).finally(() => {
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
};
exports.loginTransactionStart = loginTransactionStart;
function setNascondiModaleEnrollmentPerSessioneScaduta(payload) {
    return {
        type: login_types_1.EReduxLoginActionTypes.SELF_RESET_NASCONDI_MODALE_ENROLLMENT_PER_SESSIONE_SCADUTA,
        payload
    };
}
exports.setNascondiModaleEnrollmentPerSessioneScaduta = setNascondiModaleEnrollmentPerSessioneScaduta;
function getSelfResetStatusStart() {
    return {
        type: login_types_1.EReduxLoginActionTypes.SELF_RESET_GET_STATUS_START
    };
}
function getSelfResetStatusSuccess(payload) {
    return {
        type: login_types_1.EReduxLoginActionTypes.SELF_RESET_GET_STATUS_SUCCESS,
        payload
    };
}
const getSelfResetStatus = (vendorId) => async (dispatch, getState, extraArguments) => {
    const login = getState().login;
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getSelfResetStatusStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        let requestParameters = {
            oauthSrToken: login.srInfo || '',
            selfResetStatusRequestDto: {
                codiceUtente: login.formLogin.username,
                vendorId: vendorId,
            }
        };
        new api_rest_1.SelfResetControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments))
            .getSelfResetStatus(requestParameters)
            .then((result) => {
            dispatch(getSelfResetStatusSuccess(result));
        }).catch(async (error) => {
            const errore = await (0, helpers_1.decodeError)(error);
            dispatch(generalError(await (0, helpers_1.formatError)(error, true)));
            if ((errore === null || errore === void 0 ? void 0 : errore.message) == 'InvalidAuthenticationException') {
                dispatch(setNascondiModaleEnrollmentPerSessioneScaduta(true));
            }
        }).finally(() => {
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
};
exports.getSelfResetStatus = getSelfResetStatus;
function startSelfResetStart() {
    return {
        type: login_types_1.EReduxLoginActionTypes.SELF_RESET_START_START
    };
}
function startSelfResetSuccess() {
    return {
        type: login_types_1.EReduxLoginActionTypes.SELF_RESET_START_SUCCESS,
    };
}
const startSelfReset = (vendorId) => async (dispatch, getState, extraArguments) => {
    const login = getState().login;
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(startSelfResetStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        let requestParameters = {
            oauthSrToken: login.srInfo || '',
            selfResetStatusRequestDto: {
                codiceUtente: login.formLogin.username,
                vendorId: vendorId,
            }
        };
        new api_rest_1.SelfResetControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments))
            .startSelfReset(requestParameters)
            .then(() => {
            dispatch(startSelfResetSuccess());
        }).catch(async (error) => {
            dispatch(generalError(await (0, helpers_1.formatError)(error, true)));
        }).finally(() => {
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
};
exports.startSelfReset = startSelfReset;
function updateStatusSelfResetStart() {
    return {
        type: login_types_1.EReduxLoginActionTypes.SELF_RESET_UPDATE_STATUS_START
    };
}
function updateStatusSelfResetSuccess() {
    return {
        type: login_types_1.EReduxLoginActionTypes.SELF_RESET_UPDATE_STATUS_SUCCESS
    };
}
const updateStatusSelfReset = (vendorId, stato) => async (dispatch, getState, extraArguments) => {
    const login = getState().login;
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(updateStatusSelfResetStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        let requestParameters = {
            oauthSrToken: login.srInfo || '',
            selfResetUpdateStatoRequestDto: {
                codiceUtente: login.formLogin.username,
                vendorId: vendorId,
                stato: stato
            }
        };
        new api_rest_1.SelfResetControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments))
            .updateStatusSelfReset(requestParameters)
            .then(() => {
            dispatch(updateStatusSelfResetSuccess());
        }).catch(async (error) => {
            dispatch(generalError(await (0, helpers_1.formatError)(error, true)));
        }).finally(() => {
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
};
exports.updateStatusSelfReset = updateStatusSelfReset;
function loginTotalReset() {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGIN_TOTAL_RESET
    };
}
exports.loginTotalReset = loginTotalReset;
function setMyBankLoginType(payload) {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGIN_SET_MYBANK_LOGIN_TYPE,
        payload
    };
}
exports.setMyBankLoginType = setMyBankLoginType;
function annullaLoginMyBankStart() {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGIN_ANNULLA_LOGIN_MYBANK_START
    };
}
function annullaLoginMyBankSuccess(payload) {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGIN_ANNULLA_LOGIN_MYBANK_SUCCESS,
        payload
    };
}
const annullaLoginMyBank = (token) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(annullaLoginMyBankStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        const request = {
            myBankToken: token
        };
        new api_rest_1.PublicGenericControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments))
            .annullaLoginMyBank(request)
            .then((response) => {
            dispatch(annullaLoginMyBankSuccess(response));
        }).catch(async (error) => {
            dispatch(generalError(await (0, helpers_1.formatError)(error, true)));
        }).finally(() => {
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
};
exports.annullaLoginMyBank = annullaLoginMyBank;
function redirectLoginMyBankStart() {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGIN_REDIRECT_LOGIN_MYBANK_START
    };
}
function redirectLoginMyBankStartSuccess(payload) {
    return {
        type: login_types_1.EReduxLoginActionTypes.LOGIN_REDIRECT_LOGIN_MYBANK_SUCCESS,
        payload
    };
}
const redirectLoginMyBank = (token) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(redirectLoginMyBankStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        const request = {
            type: getState().login.loginMyBankType || api_rest_1.LoginMyBankRedirectTypeEnum.retail,
            token: token
        };
        new api_rest_1.PublicGenericControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments))
            .loginMyBankRedirect(request)
            .then((response) => {
            dispatch(redirectLoginMyBankStartSuccess(response));
        }).catch(async (error) => {
            dispatch(generalError(await (0, helpers_1.formatError)(error, true)));
        }).finally(() => {
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
};
exports.redirectLoginMyBank = redirectLoginMyBank;
