"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ASPSPDtoToJSON = exports.ASPSPDtoFromJSONTyped = exports.ASPSPDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function ASPSPDtoFromJSON(json) {
    return ASPSPDtoFromJSONTyped(json, false);
}
exports.ASPSPDtoFromJSON = ASPSPDtoFromJSON;
function ASPSPDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'aspspId': !runtime_1.exists(json, 'aspspId') ? undefined : json['aspspId'],
        'name': !runtime_1.exists(json, 'name') ? undefined : json['name'],
        'status': !runtime_1.exists(json, 'status') ? undefined : json['status'],
        'globalConsents': !runtime_1.exists(json, 'globalConsents') ? undefined : json['globalConsents'],
        'providerIdList': !runtime_1.exists(json, 'providerIdList') ? undefined : json['providerIdList'],
        'iconImage64': !runtime_1.exists(json, 'iconImage64') ? undefined : json['iconImage64'],
    };
}
exports.ASPSPDtoFromJSONTyped = ASPSPDtoFromJSONTyped;
function ASPSPDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'aspspId': value.aspspId,
        'name': value.name,
        'status': value.status,
        'globalConsents': value.globalConsents,
        'providerIdList': value.providerIdList,
        'iconImage64': value.iconImage64,
    };
}
exports.ASPSPDtoToJSON = ASPSPDtoToJSON;
