"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.InfoCCIAADtoToJSON = exports.InfoCCIAADtoFromJSONTyped = exports.InfoCCIAADtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function InfoCCIAADtoFromJSON(json) {
    return InfoCCIAADtoFromJSONTyped(json, false);
}
exports.InfoCCIAADtoFromJSON = InfoCCIAADtoFromJSON;
function InfoCCIAADtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'dataIscrizioneLegale': !runtime_1.exists(json, 'dataIscrizioneLegale') ? undefined : (new Date(json['dataIscrizioneLegale'])),
        'provinciaSedeLegale': !runtime_1.exists(json, 'provinciaSedeLegale') ? undefined : json['provinciaSedeLegale'],
        'numeroIscrizioneLegale': !runtime_1.exists(json, 'numeroIscrizioneLegale') ? undefined : json['numeroIscrizioneLegale'],
        'dataIscrizioneAmministrativa': !runtime_1.exists(json, 'dataIscrizioneAmministrativa') ? undefined : (new Date(json['dataIscrizioneAmministrativa'])),
        'provinciaAmministrativa': !runtime_1.exists(json, 'provinciaAmministrativa') ? undefined : json['provinciaAmministrativa'],
        'numeroIscrizioneAmministrativa': !runtime_1.exists(json, 'numeroIscrizioneAmministrativa') ? undefined : json['numeroIscrizioneAmministrativa'],
    };
}
exports.InfoCCIAADtoFromJSONTyped = InfoCCIAADtoFromJSONTyped;
function InfoCCIAADtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'dataIscrizioneLegale': value.dataIscrizioneLegale === undefined ? undefined : (value.dataIscrizioneLegale.toISOString()),
        'provinciaSedeLegale': value.provinciaSedeLegale,
        'numeroIscrizioneLegale': value.numeroIscrizioneLegale,
        'dataIscrizioneAmministrativa': value.dataIscrizioneAmministrativa === undefined ? undefined : (value.dataIscrizioneAmministrativa.toISOString()),
        'provinciaAmministrativa': value.provinciaAmministrativa,
        'numeroIscrizioneAmministrativa': value.numeroIscrizioneAmministrativa,
    };
}
exports.InfoCCIAADtoToJSON = InfoCCIAADtoToJSON;
