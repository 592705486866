"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MeseBilancioDtoToJSON = exports.MeseBilancioDtoFromJSONTyped = exports.MeseBilancioDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function MeseBilancioDtoFromJSON(json) {
    return MeseBilancioDtoFromJSONTyped(json, false);
}
exports.MeseBilancioDtoFromJSON = MeseBilancioDtoFromJSON;
function MeseBilancioDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'mese': !runtime_1.exists(json, 'mese') ? undefined : json['mese'],
        'meseNum': !runtime_1.exists(json, 'meseNum') ? undefined : json['meseNum'],
        'entrate': !runtime_1.exists(json, 'entrate') ? undefined : json['entrate'],
        'uscite': !runtime_1.exists(json, 'uscite') ? undefined : json['uscite'],
        'somma': !runtime_1.exists(json, 'somma') ? undefined : json['somma'],
        'vociEntrata': !runtime_1.exists(json, 'vociEntrata') ? undefined : (json['vociEntrata'].map(_1.VoceCategoriaDtoFromJSON)),
        'vociUscita': !runtime_1.exists(json, 'vociUscita') ? undefined : (json['vociUscita'].map(_1.VoceCategoriaDtoFromJSON)),
    };
}
exports.MeseBilancioDtoFromJSONTyped = MeseBilancioDtoFromJSONTyped;
function MeseBilancioDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'mese': value.mese,
        'meseNum': value.meseNum,
        'entrate': value.entrate,
        'uscite': value.uscite,
        'somma': value.somma,
        'vociEntrata': value.vociEntrata === undefined ? undefined : (value.vociEntrata.map(_1.VoceCategoriaDtoToJSON)),
        'vociUscita': value.vociUscita === undefined ? undefined : (value.vociUscita.map(_1.VoceCategoriaDtoToJSON)),
    };
}
exports.MeseBilancioDtoToJSON = MeseBilancioDtoToJSON;
