"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DettaglioLibrettoDiRisparmioDtoToJSON = exports.DettaglioLibrettoDiRisparmioDtoFromJSONTyped = exports.DettaglioLibrettoDiRisparmioDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function DettaglioLibrettoDiRisparmioDtoFromJSON(json) {
    return DettaglioLibrettoDiRisparmioDtoFromJSONTyped(json, false);
}
exports.DettaglioLibrettoDiRisparmioDtoFromJSON = DettaglioLibrettoDiRisparmioDtoFromJSON;
function DettaglioLibrettoDiRisparmioDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'totale': !runtime_1.exists(json, 'totale') ? undefined : json['totale'],
        'segno': !runtime_1.exists(json, 'segno') ? undefined : json['segno'],
        'dataContabile': !runtime_1.exists(json, 'dataContabile') ? undefined : (new Date(json['dataContabile'])),
        'dataValuta': !runtime_1.exists(json, 'dataValuta') ? undefined : (new Date(json['dataValuta'])),
        'descrizione': !runtime_1.exists(json, 'descrizione') ? undefined : json['descrizione'],
        'causaleMovimento': !runtime_1.exists(json, 'causaleMovimento') ? undefined : json['causaleMovimento'],
        'flagMovimentoGiorno': !runtime_1.exists(json, 'flagMovimentoGiorno') ? undefined : json['flagMovimentoGiorno'],
        'descrizioneAggiuntiva': !runtime_1.exists(json, 'descrizioneAggiuntiva') ? undefined : json['descrizioneAggiuntiva'],
    };
}
exports.DettaglioLibrettoDiRisparmioDtoFromJSONTyped = DettaglioLibrettoDiRisparmioDtoFromJSONTyped;
function DettaglioLibrettoDiRisparmioDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'totale': value.totale,
        'segno': value.segno,
        'dataContabile': value.dataContabile === undefined ? undefined : (value.dataContabile.toISOString()),
        'dataValuta': value.dataValuta === undefined ? undefined : (value.dataValuta.toISOString()),
        'descrizione': value.descrizione,
        'causaleMovimento': value.causaleMovimento,
        'flagMovimentoGiorno': value.flagMovimentoGiorno,
        'descrizioneAggiuntiva': value.descrizioneAggiuntiva,
    };
}
exports.DettaglioLibrettoDiRisparmioDtoToJSON = DettaglioLibrettoDiRisparmioDtoToJSON;
