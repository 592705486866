"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.StrongAuthInfoTokenInfoDtoToJSON = exports.StrongAuthInfoTokenInfoDtoFromJSONTyped = exports.StrongAuthInfoTokenInfoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function StrongAuthInfoTokenInfoDtoFromJSON(json) {
    return StrongAuthInfoTokenInfoDtoFromJSONTyped(json, false);
}
exports.StrongAuthInfoTokenInfoDtoFromJSON = StrongAuthInfoTokenInfoDtoFromJSON;
function StrongAuthInfoTokenInfoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'expectingNewPin': !runtime_1.exists(json, 'expectingNewPin') ? undefined : json['expectingNewPin'],
        'expectingNextTokencode': !runtime_1.exists(json, 'expectingNextTokencode') ? undefined : json['expectingNextTokencode'],
        'expectingPin': !runtime_1.exists(json, 'expectingPin') ? undefined : json['expectingPin'],
        'expectingSignature': !runtime_1.exists(json, 'expectingSignature') ? undefined : json['expectingSignature'],
        'expectingTokencode': !runtime_1.exists(json, 'expectingTokencode') ? undefined : json['expectingTokencode'],
        'pushNotifSent': !runtime_1.exists(json, 'pushNotifSent') ? undefined : json['pushNotifSent'],
        'qrcode': !runtime_1.exists(json, 'qrcode') ? undefined : _1.TokenInfoQrcodeDtoFromJSON(json['qrcode']),
        'softwareToken': !runtime_1.exists(json, 'softwareToken') ? undefined : json['softwareToken'],
        'tokenModel': !runtime_1.exists(json, 'tokenModel') ? undefined : json['tokenModel'],
        'tokenType': !runtime_1.exists(json, 'tokenType') ? undefined : json['tokenType'],
        'valuesToBeTyped': !runtime_1.exists(json, 'valuesToBeTyped') ? undefined : json['valuesToBeTyped'],
    };
}
exports.StrongAuthInfoTokenInfoDtoFromJSONTyped = StrongAuthInfoTokenInfoDtoFromJSONTyped;
function StrongAuthInfoTokenInfoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'expectingNewPin': value.expectingNewPin,
        'expectingNextTokencode': value.expectingNextTokencode,
        'expectingPin': value.expectingPin,
        'expectingSignature': value.expectingSignature,
        'expectingTokencode': value.expectingTokencode,
        'pushNotifSent': value.pushNotifSent,
        'qrcode': _1.TokenInfoQrcodeDtoToJSON(value.qrcode),
        'softwareToken': value.softwareToken,
        'tokenModel': value.tokenModel,
        'tokenType': value.tokenType,
        'valuesToBeTyped': value.valuesToBeTyped,
    };
}
exports.StrongAuthInfoTokenInfoDtoToJSON = StrongAuthInfoTokenInfoDtoToJSON;
