"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParametriBonificoDtoToJSON = exports.ParametriBonificoDtoFromJSONTyped = exports.ParametriBonificoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ParametriBonificoDtoFromJSON(json) {
    return ParametriBonificoDtoFromJSONTyped(json, false);
}
exports.ParametriBonificoDtoFromJSON = ParametriBonificoDtoFromJSON;
function ParametriBonificoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'rapporti': !runtime_1.exists(json, 'rapporti') ? undefined : (json['rapporti'].map(_1.RapportoCompletoDtoFromJSON)),
        'parametri': !runtime_1.exists(json, 'parametri') ? undefined : json['parametri'],
        'rapportiDestinazioneGiroconto': !runtime_1.exists(json, 'rapportiDestinazioneGiroconto') ? undefined : (json['rapportiDestinazioneGiroconto'].map(_1.RapportoCompletoDtoFromJSON)),
        'motivazioniPagamento': !runtime_1.exists(json, 'motivazioniPagamento') ? undefined : (json['motivazioniPagamento'].map(_1.MotivazionePagamentoDtoFromJSON)),
        'ibanDeposito': !runtime_1.exists(json, 'ibanDeposito') ? undefined : json['ibanDeposito'],
        'chiaveMultilinguaCausale': !runtime_1.exists(json, 'chiaveMultilinguaCausale') ? undefined : json['chiaveMultilinguaCausale'],
    };
}
exports.ParametriBonificoDtoFromJSONTyped = ParametriBonificoDtoFromJSONTyped;
function ParametriBonificoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'rapporti': value.rapporti === undefined ? undefined : (value.rapporti.map(_1.RapportoCompletoDtoToJSON)),
        'parametri': value.parametri,
        'rapportiDestinazioneGiroconto': value.rapportiDestinazioneGiroconto === undefined ? undefined : (value.rapportiDestinazioneGiroconto.map(_1.RapportoCompletoDtoToJSON)),
        'motivazioniPagamento': value.motivazioniPagamento === undefined ? undefined : (value.motivazioniPagamento.map(_1.MotivazionePagamentoDtoToJSON)),
        'ibanDeposito': value.ibanDeposito,
        'chiaveMultilinguaCausale': value.chiaveMultilinguaCausale,
    };
}
exports.ParametriBonificoDtoToJSON = ParametriBonificoDtoToJSON;
