"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OpeningHoursToJSON = exports.OpeningHoursFromJSONTyped = exports.OpeningHoursFromJSON = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
function OpeningHoursFromJSON(json) {
    return OpeningHoursFromJSONTyped(json, false);
}
exports.OpeningHoursFromJSON = OpeningHoursFromJSON;
function OpeningHoursFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'open_now': !(0, api_rest_1.exists)(json, 'open_now') ? undefined : json['open_now']
    };
}
exports.OpeningHoursFromJSONTyped = OpeningHoursFromJSONTyped;
function OpeningHoursToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'open_now': value.open_now
    };
}
exports.OpeningHoursToJSON = OpeningHoursToJSON;
