"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.autorizzaRevocaBonificoSepa = exports.getInfoRevocaBonifico = exports.getInfoRevocaSmartCard = exports.getInfoRevocaBonificoDF = exports.getInfoRevocaBonificoSepa = exports.setBonificoSepaRevocaSetStep = exports.setProvenienzaRevocaBonificoSepa = exports.setRiepilogoRevocaBonificoSepa = exports.getOneclickBonifico = exports.getOneclickSmartCard = exports.getOneclickBonificoDF = exports.getOneclickBonificoSepa = exports.resetBonificoSepa = exports.setFormSubmittedBonifico = exports.setAccordionDatiAggiuntiviOpened = exports.setBonificoSepaSetRapportoSelezionato = exports.setBonificoSepaIBANInt = exports.setBonificoSepaIntestatario = exports.setBonificoSepaSetStep = exports.setBonificoSepaSetOrdinanteEff = exports.setBonificoSepaSetAlias1Click = exports.setBonificoSepaSetSave1Click = exports.setBonificoSepaSetDescrizione = exports.setBonificoSepaSetDataEsec = exports.setBonificoSepaSetMotivazionePagamento = exports.setBonificoSepaSetCodiceFiscalePIVAAmministratore = exports.setBonificoSepaSetCodiceFiscalePIVABeneficiario = exports.setBonificoSepaSetCodiceFiscalePIVAOrdinanti = exports.setBonificoSepaSetImport = exports.setBonificoSepaIdContattoBeneficiario = exports.setBonificoSepaSetAddWhiteList = exports.setBonificoSepaSetAddRubrica = exports.setBonificoSepaSetIBAN = exports.setBonificoSepaSetBeneficiario = exports.autorizzaBonifico = exports.autorizzaSmartCard = exports.autorizzaBonificoDF = exports.autorizzaBonificoSepa = exports.getInfoDispositivaBonifico = exports.getInfoDispositivaSmartCard = exports.getInfoDispositivaBonificoDF = exports.getInfoDispositivaBonificoSepa = exports.controlloBonifico = exports.controlloSmartCard = exports.controlloBonificoDF = exports.controlloBonificoSepa = exports.controlloSmartCardAction = exports.controlloBonificoDFAction = exports.controlloBonificoSepaAction = exports.getParametriBonificoSepa = void 0;
exports.setBonificoDFSetCheckboxPagamentoSpeseSuPartiComuniCondominiali = exports.modificaMassimaleBonificoInsta = exports.modificaMassimaleBonificoInstaSuccess = exports.modificaMassimaleBonificoInstaStart = exports.resetBonificoSepaInfoDispositiva = exports.setBonificoSepaUsernamePSD2 = exports.getParametriRicaricaCartaConto = exports.resetBonificoSepaIstantaneoError = exports.setBonificoSepaSetIstantaneo = exports.resetEsitoBonificoSepa = exports.shouldUpdateBozzaBonificoDF = exports.shouldUpdateBozzaBonificoSepa = exports.shouldUpdateSmartCard = exports.resetRiepilogoBonificoSepa = exports.getBonificoFromBozza = exports.delBozzaBonificoSepa = exports.getBozzaBonifico = exports.getBozzaSmartCard = exports.getBozzaBonificoDF = exports.getBozzaBonificoSepa = exports.getBozzeBonifico = exports.resetBozzeBonificoSepa = exports.getBozzeSmartCard = exports.getBozzeBonificoDF = exports.getBozzeBonificoSepa = exports.resetBonificoSepaRevoca = exports.autorizzaRevocaBonifico = exports.autorizzaRevocaSmartCard = exports.autorizzaRevocaBonificoDF = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const general_types_1 = require("../general/general.types");
const helpers_1 = require("../helpers");
const psd2_actions_1 = require("../psd2/psd2.actions");
const bonifico_selectors_1 = require("./bonifico.selectors");
const moment = require("moment");
/* get parametri - START */
function getParametriBonificoSepaStart() {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_GET_PARAMETRI_START
    };
}
function getParametriBonificoSepaSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_GET_PARAMETRI_SUCCESS,
        payload
    };
}
function getParametriPerRipetiBonificoSepaSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_GET_PARAMETRI_PER_RIPETI_SUCCESS,
        payload
    };
}
function generalError(value, showGenericModalError, sendErrorToMatomo, dispositiva, nomeEvento) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value, showGenericModalError));
        (!!dispositiva && sendErrorToMatomo) && sendErrorToMatomo(dispositiva, `code_error ${dispositiva.toUpperCase()} ${value.status} / ${value.message} / ${value.timestamp}`, nomeEvento);
    };
}
const getParametriBonificoSepa = (ripeti = false, psd2In = false) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getParametriBonificoSepaStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/parametri_bonifico.json')).then(parametri => {
            dispatch(ripeti ? getParametriPerRipetiBonificoSepaSuccess((0, api_rest_1.ParametriBonificoDtoFromJSON)(parametri)) : getParametriBonificoSepaSuccess((0, api_rest_1.ParametriBonificoDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const parametriBonificoRequest = {
            psd2in: psd2In
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BonificoControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametriBonifico(parametriBonificoRequest).then((response) => {
                var _a;
                dispatch(ripeti ? getParametriPerRipetiBonificoSepaSuccess(response) : getParametriBonificoSepaSuccess(response));
                ((_a = response.rapporti) === null || _a === void 0 ? void 0 : _a.length) === 0 && dispatch((0, general_actions_1.setNoRapporti)({ type: general_types_1.TypeNoRapporti.BONIFICI_SEPA }));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getParametriBonificoSepa = getParametriBonificoSepa;
/* get parametri - FINE */
/* controllo bonifico - START */
function controlloBonificoSepaStart() {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_CONTROLLO_START
    };
}
function controlloBonificoSepaSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_CONTROLLO_SUCCESS,
        payload
    };
}
function controlloBonificoSepaIstantaneoError(value) {
    return async (dispatch) => {
        dispatch(setBonificoSepaIstantaneoError(value));
    };
}
const controlloBonificoSepaAction = () => async (dispatch, getState, extraArguments) => {
    controlloBonificoAction('bonificosepa')(dispatch, getState, extraArguments);
};
exports.controlloBonificoSepaAction = controlloBonificoSepaAction;
const controlloBonificoDFAction = () => async (dispatch, getState, extraArguments) => {
    controlloBonificoAction('bonificofiscale')(dispatch, getState, extraArguments);
};
exports.controlloBonificoDFAction = controlloBonificoDFAction;
const controlloSmartCardAction = (prefissoCausale) => async (dispatch, getState, extraArguments) => {
    controlloBonificoAction('cartacontodispocategorie', prefissoCausale)(dispatch, getState, extraArguments);
};
exports.controlloSmartCardAction = controlloSmartCardAction;
const controlloBonificoAction = (funzioneDispositiva, prefissoCausale) => async (dispatch, getState, extraArguments) => {
    const bonificoSepa = getState().bonificoSepa;
    dispatch(setFormSubmittedBonifico(true));
    let selectorFormValid;
    let request;
    switch (funzioneDispositiva) {
        case 'bonificosepa': {
            selectorFormValid = bonifico_selectors_1.formValid;
            request = bonifico_selectors_1.buildBonificoSepa;
            break;
        }
        case 'bonificofiscale': {
            selectorFormValid = bonifico_selectors_1.formDFValid;
            request = bonifico_selectors_1.buildBonificoDF;
            break;
        }
        case 'cartacontodispocategorie': {
            selectorFormValid = bonifico_selectors_1.formSmartCardValid;
            request = bonifico_selectors_1.buildSmartCard;
            break;
        }
        default: {
            selectorFormValid = bonifico_selectors_1.formValid;
            request = bonifico_selectors_1.buildBonificoSepa;
        }
    }
    if (selectorFormValid(bonificoSepa)) {
        dispatch((0, general_actions_1.setLoader)(true));
        dispatch(controlloBonificoSepaStart());
        if (extraArguments.mock) {
            Promise.resolve().then(() => require('../../mock/controllo_bonifico.json')).then(parametri => {
                dispatch(controlloBonificoSepaSuccess((0, api_rest_1.BonificoSepaDtoFromJSON)(parametri)));
                dispatch((0, general_actions_1.setLoader)(false));
            });
        }
        else {
            const controlloBonificoSepaRequest = {
                bonificoSepaDto: request(bonificoSepa),
                funzioneDispositiva
            };
            if (prefissoCausale) {
                controlloBonificoSepaRequest.bonificoSepaDto.causale = prefissoCausale + ' ' + controlloBonificoSepaRequest.bonificoSepaDto.causale;
            }
            extraArguments.getItem('jwt').then((jwt) => {
                new api_rest_1.BonificoControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).controlloBonifico(controlloBonificoSepaRequest).then((response) => {
                    dispatch(controlloBonificoSepaSuccess(response));
                }).catch(async (error) => {
                    var _a, _b;
                    const decodedError = await (0, helpers_1.decodeError)(error);
                    if ((_b = (_a = decodedError === null || decodedError === void 0 ? void 0 : decodedError._links) === null || _a === void 0 ? void 0 : _a.url) === null || _b === void 0 ? void 0 : _b.href) {
                        dispatch(controlloBonificoPSD2WebViewLink(decodedError._links.url.href));
                    }
                    else {
                        const formattedError = await (0, helpers_1.formatError)(decodedError, false, true);
                        if (formattedError.message === 'C_ERRORE_SPECIFICO_BONIFICO_SEPA_IP-CNORE' && bonificoSepa.form.istantaneo) {
                            dispatch(controlloBonificoSepaIstantaneoError(formattedError));
                        }
                        else {
                            dispatch(generalError(formattedError));
                        }
                    }
                }).finally(() => {
                    dispatch((0, general_actions_1.setLoader)(false));
                });
            });
        }
    }
};
const controlloBonificoSepa = (bonifico) => async (dispatch, getState, extraArguments) => {
    (0, exports.controlloBonifico)(bonifico, 'bonificosepa')(dispatch, getState, extraArguments);
};
exports.controlloBonificoSepa = controlloBonificoSepa;
const controlloBonificoDF = (bonifico) => async (dispatch, getState, extraArguments) => {
    (0, exports.controlloBonifico)(bonifico, 'bonificofiscale')(dispatch, getState, extraArguments);
};
exports.controlloBonificoDF = controlloBonificoDF;
const controlloSmartCard = (bonifico, prefissoCausale) => async (dispatch, getState, extraArguments) => {
    (0, exports.controlloBonifico)(bonifico, 'cartacontodispocategorie', prefissoCausale)(dispatch, getState, extraArguments);
};
exports.controlloSmartCard = controlloSmartCard;
const controlloBonifico = (bonifico, funzioneDispositiva, prefissoCausale) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(controlloBonificoSepaStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/controllo_bonifico.json')).then(parametri => {
            dispatch(controlloBonificoSepaSuccess((0, api_rest_1.BonificoSepaDtoFromJSON)(parametri)));
        });
    }
    else {
        const controlloBonificoSepaRequest = {
            bonificoSepaDto: {
                ...bonifico,
                causale: ((prefissoCausale || "") + ' ' + bonifico.causale).trim(),
                datiEsecuzioneBonifico: {
                    importoCommissioni: 0,
                    importoTotale: 0,
                    dataAddebito: new Date(),
                    dataEsecuzioneAddebito: new Date(),
                    dataValuta: new Date(),
                    dataRegolamento: new Date(),
                    denominazioneBanca: '',
                    denominazioneSportello: '',
                    revocabile: false,
                    riferimentoOperazione: '',
                    urgente: false
                }
            },
            funzioneDispositiva: funzioneDispositiva
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BonificoControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).controlloBonifico(controlloBonificoSepaRequest).then((response) => {
                dispatch(controlloBonificoSepaSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.controlloBonifico = controlloBonifico;
function controlloBonificoPSD2WebViewLink(link) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_PSD2_WEBVIEW_LINK_CONTROLLO,
        payload: link
    };
}
/* controllo bonifico - FINE */
/* get info dispositiva bonifico - START */
function getInfoDispositivaBonificoSepaStart() {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_GET_INFO_DISPOSITIVA_START
    };
}
function getInfoDispositivaBonificoSepaSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_GET_INFO_DISPOSITIVA_SUCCESS,
        payload
    };
}
const getInfoDispositivaBonificoSepa = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, getState, extraArguments) => {
    (0, exports.getInfoDispositivaBonifico)(idDispositiva, 'bonificosepa', eseguiInit, chiamataUscente, offline)(dispatch, getState, extraArguments);
};
exports.getInfoDispositivaBonificoSepa = getInfoDispositivaBonificoSepa;
const getInfoDispositivaBonificoDF = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, getState, extraArguments) => {
    (0, exports.getInfoDispositivaBonifico)(idDispositiva, 'bonificofiscale', eseguiInit, chiamataUscente, offline)(dispatch, getState, extraArguments);
};
exports.getInfoDispositivaBonificoDF = getInfoDispositivaBonificoDF;
const getInfoDispositivaSmartCard = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, getState, extraArguments) => {
    (0, exports.getInfoDispositivaBonifico)(idDispositiva, 'cartacontodispocategorie', eseguiInit, chiamataUscente, offline)(dispatch, getState, extraArguments);
};
exports.getInfoDispositivaSmartCard = getInfoDispositivaSmartCard;
const getInfoDispositivaBonifico = (idDispositiva, funzioneDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getInfoDispositivaBonificoSepaStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/autenticazione-bonifico.json')).then(parametri => {
            dispatch(getInfoDispositivaBonificoSepaSuccess((0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const getInfoDispositivaBonificoSepaRequest = {
            idDispositiva,
            funzioneDispositiva: funzioneDispositiva,
            chiamataUscente,
            eseguiInit,
            offline: !!offline
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BonificoControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).informazioniAutenticazioneBonifico(getInfoDispositivaBonificoSepaRequest).then((response) => {
                dispatch(getInfoDispositivaBonificoSepaSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getInfoDispositivaBonifico = getInfoDispositivaBonifico;
/* get info dispositiva bonifico - FINE */
/* autorizza bonifico - START */
function autorizzaBonificoSepaStart() {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_AUTORIZZA_START
    };
}
function autorizzaBonificoSepaSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_AUTORIZZA_SUCCESS,
        payload
    };
}
const autorizzaBonificoSepa = (idDispositiva, credenziale, bonifico) => async (dispatch, getState, extraArguments) => {
    (0, exports.autorizzaBonifico)(idDispositiva, credenziale, bonifico, 'bonificosepa')(dispatch, getState, extraArguments);
};
exports.autorizzaBonificoSepa = autorizzaBonificoSepa;
const autorizzaBonificoDF = (idDispositiva, credenziale, bonifico) => async (dispatch, getState, extraArguments) => {
    (0, exports.autorizzaBonifico)(idDispositiva, credenziale, bonifico, 'bonificofiscale')(dispatch, getState, extraArguments);
};
exports.autorizzaBonificoDF = autorizzaBonificoDF;
const autorizzaSmartCard = (idDispositiva, credenziale, bonifico) => async (dispatch, getState, extraArguments) => {
    (0, exports.autorizzaBonifico)(idDispositiva, credenziale, bonifico, 'cartacontodispocategorie')(dispatch, getState, extraArguments);
};
exports.autorizzaSmartCard = autorizzaSmartCard;
const autorizzaBonifico = (idDispositiva, credenziale, bonifico, funzioneDispositiva) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(autorizzaBonificoSepaStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/autorizza_bonifico.json')).then(parametri => {
            dispatch(autorizzaBonificoSepaSuccess((0, api_rest_1.EsitoAutorizzazioneDisposizioneBonificoDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const getInfoDispositivaBonificoSepaRequest = {
            idDispositiva,
            credenzialeDispositivaDto: credenziale,
            funzioneDispositiva: funzioneDispositiva
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BonificoControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).autorizzaBonifico(getInfoDispositivaBonificoSepaRequest).then((response) => {
                dispatch(autorizzaBonificoSepaSuccess(response));
                extraArguments.sendEventToMatomo(funzioneDispositiva.toUpperCase(), `esito dispositiva ${funzioneDispositiva.toLocaleUpperCase()} ${response.stato}`, 'ESITO_DISPOSITIVA');
            }).catch(async (error) => {
                var _a, _b;
                dispatch((0, psd2_actions_1.setPaymentPollingActive)(false));
                const decodedError = await (0, helpers_1.decodeError)(error);
                if ((_b = (_a = decodedError === null || decodedError === void 0 ? void 0 : decodedError._links) === null || _a === void 0 ? void 0 : _a.url) === null || _b === void 0 ? void 0 : _b.href) {
                    dispatch(controlloBonificoPSD2WebViewLink(decodedError._links.url.href));
                }
                else {
                    dispatch(generalError(await (0, helpers_1.formatError)(decodedError, false, true), false, extraArguments.sendEventToMatomo, funzioneDispositiva.toUpperCase(), 'ESITO_DISPOSITIVA'));
                }
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.autorizzaBonifico = autorizzaBonifico;
/* get info dispositiva bonifico - FINE */
function setBonificoSepaSetBeneficiario(txt) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_BENEFICIARIO,
        payload: txt
    };
}
exports.setBonificoSepaSetBeneficiario = setBonificoSepaSetBeneficiario;
function setBonificoSepaSetIBAN(txt) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_IBAN,
        payload: txt
    };
}
exports.setBonificoSepaSetIBAN = setBonificoSepaSetIBAN;
function setBonificoSepaSetAddRubrica(b) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_ADD_RUBRICA,
        payload: b
    };
}
exports.setBonificoSepaSetAddRubrica = setBonificoSepaSetAddRubrica;
function setBonificoSepaSetAddWhiteList(b) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_ADD_WHITELIST,
        payload: b
    };
}
exports.setBonificoSepaSetAddWhiteList = setBonificoSepaSetAddWhiteList;
function setBonificoSepaIdContattoBeneficiario(id) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_SET_ID_CONTATTO_BENEFICIARIO,
        payload: id
    };
}
exports.setBonificoSepaIdContattoBeneficiario = setBonificoSepaIdContattoBeneficiario;
function setBonificoSepaSetImport(i) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_IMPORTO,
        payload: i
    };
}
exports.setBonificoSepaSetImport = setBonificoSepaSetImport;
function setBonificoSepaSetCodiceFiscalePIVAOrdinanti(i, cfpi) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_CF_PI_ORDINANTI,
        payload: cfpi,
        index: i
    };
}
exports.setBonificoSepaSetCodiceFiscalePIVAOrdinanti = setBonificoSepaSetCodiceFiscalePIVAOrdinanti;
function setBonificoSepaSetCodiceFiscalePIVABeneficiario(cfpi) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_CF_PI_BENEFICIARIO,
        payload: cfpi
    };
}
exports.setBonificoSepaSetCodiceFiscalePIVABeneficiario = setBonificoSepaSetCodiceFiscalePIVABeneficiario;
function setBonificoSepaSetCodiceFiscalePIVAAmministratore(cfpi) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_CF_PI_AMMINISTRATORE,
        payload: cfpi
    };
}
exports.setBonificoSepaSetCodiceFiscalePIVAAmministratore = setBonificoSepaSetCodiceFiscalePIVAAmministratore;
function setBonificoSepaSetMotivazionePagamento(motivazione) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_MOTIVAZIONE_PAGAMENTO,
        payload: motivazione
    };
}
exports.setBonificoSepaSetMotivazionePagamento = setBonificoSepaSetMotivazionePagamento;
function setBonificoSepaSetDataEsec(txt) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_DATA_ESEC,
        payload: txt,
    };
}
exports.setBonificoSepaSetDataEsec = setBonificoSepaSetDataEsec;
function setBonificoSepaSetDescrizione(txt) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_DESCRIZIONE,
        payload: txt
    };
}
exports.setBonificoSepaSetDescrizione = setBonificoSepaSetDescrizione;
function setBonificoSepaSetSave1Click(b) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_SAVE_1CLICK,
        payload: b
    };
}
exports.setBonificoSepaSetSave1Click = setBonificoSepaSetSave1Click;
function setBonificoSepaSetAlias1Click(txt) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_ALIAS_1CLICK,
        payload: txt
    };
}
exports.setBonificoSepaSetAlias1Click = setBonificoSepaSetAlias1Click;
function setBonificoSepaSetOrdinanteEff(txt) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_ORDINANTE_EFF,
        payload: txt
    };
}
exports.setBonificoSepaSetOrdinanteEff = setBonificoSepaSetOrdinanteEff;
function setBonificoSepaSetStepAction(i) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_STEP,
        payload: i
    };
}
const setBonificoSepaSetStep = (i) => async (dispatch, _, extraArguments) => {
    dispatch(setBonificoSepaSetStepAction(i));
    if (extraArguments.platform == 'mobile') {
        dispatch((0, general_actions_1.setBottomTabBarVisible)(i != 2));
    }
};
exports.setBonificoSepaSetStep = setBonificoSepaSetStep;
function setBonificoSepaIntestatario(txt) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_INTESTATARIO,
        payload: txt
    };
}
exports.setBonificoSepaIntestatario = setBonificoSepaIntestatario;
function setBonificoSepaIBANInt(txt) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_IBAN_INT,
        payload: txt
    };
}
exports.setBonificoSepaIBANInt = setBonificoSepaIBANInt;
function setBonificoSepaSetRapportoSelezionato(rapportoSelezionato) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_SET_RAPPORTO_SELEZIONATO,
        payload: rapportoSelezionato
    };
}
exports.setBonificoSepaSetRapportoSelezionato = setBonificoSepaSetRapportoSelezionato;
function setAccordionDatiAggiuntiviOpened(opened) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_ACCORDION_DATI_AGGIUNTIVI_OPENED,
        payload: opened
    };
}
exports.setAccordionDatiAggiuntiviOpened = setAccordionDatiAggiuntiviOpened;
function setFormSubmittedBonifico(bSelect) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_FORM_SUBMITTED,
        payload: bSelect
    };
}
exports.setFormSubmittedBonifico = setFormSubmittedBonifico;
function resetBonificoSepa() {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_RESET
    };
}
exports.resetBonificoSepa = resetBonificoSepa;
/* get oneclick bonifico sepa - START */
function getOneclickBonificoSepaStart() {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_GET_ONECLICK_START
    };
}
function getOneclickBonificoSepaSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_GET_ONECLICK_SUCCESS,
        payload
    };
}
const getOneclickBonificoSepa = () => async (dispatch, getState, extraArguments) => {
    (0, exports.getOneclickBonifico)('bonificosepa')(dispatch, getState, extraArguments);
};
exports.getOneclickBonificoSepa = getOneclickBonificoSepa;
const getOneclickBonificoDF = () => async (dispatch, getState, extraArguments) => {
    (0, exports.getOneclickBonifico)('bonificofiscale')(dispatch, getState, extraArguments);
};
exports.getOneclickBonificoDF = getOneclickBonificoDF;
const getOneclickSmartCard = () => async (dispatch, getState, extraArguments) => {
    (0, exports.getOneclickBonifico)('CARTACONTODISPOCATEGORIE')(dispatch, getState, extraArguments);
};
exports.getOneclickSmartCard = getOneclickSmartCard;
const getOneclickBonifico = (funzioneDispositiva) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getOneclickBonificoSepaStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/oneclick.json')).then(parametri => {
            dispatch(getOneclickBonificoSepaSuccess((0, api_rest_1.OperazioniOneClickDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const getOneclickBonificoSepaRequest = {
            operazioniOneClickInputDto: {},
            funzioneDispositiva: funzioneDispositiva
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BonificoControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).operazioniOneClickBonifico(getOneclickBonificoSepaRequest).then((response) => {
                dispatch(getOneclickBonificoSepaSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getOneclickBonifico = getOneclickBonifico;
/* get oneclick bonifico sepa - FINE */
function setRiepilogoRevocaBonificoSepa(riepilogo) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_SET_RIEPILOGO_REVOCA,
        payload: riepilogo
    };
}
exports.setRiepilogoRevocaBonificoSepa = setRiepilogoRevocaBonificoSepa;
function setProvenienzaRevocaBonificoSepa(provenienza) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_SET_PROVENIENZA,
        payload: provenienza
    };
}
exports.setProvenienzaRevocaBonificoSepa = setProvenienzaRevocaBonificoSepa;
function setBonificoSepaRevocaSetStepAction(i) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_STEP_REVOCA,
        payload: i
    };
}
const setBonificoSepaRevocaSetStep = (i) => async (dispatch, _, extraArguments) => {
    dispatch(setBonificoSepaRevocaSetStepAction(i));
    if (extraArguments.platform == 'mobile') {
        dispatch((0, general_actions_1.setBottomTabBarVisible)(i != 1));
    }
};
exports.setBonificoSepaRevocaSetStep = setBonificoSepaRevocaSetStep;
/* get info revoca - START */
const getInfoRevocaBonificoSepa = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, getState, extraArguments) => {
    (0, exports.getInfoRevocaBonifico)(idDispositiva, 'bonificosepa_annullo', eseguiInit, chiamataUscente, offline)(dispatch, getState, extraArguments);
};
exports.getInfoRevocaBonificoSepa = getInfoRevocaBonificoSepa;
const getInfoRevocaBonificoDF = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, getState, extraArguments) => {
    (0, exports.getInfoRevocaBonifico)(idDispositiva, 'bonificofiscale_annullo', eseguiInit, chiamataUscente, offline)(dispatch, getState, extraArguments);
};
exports.getInfoRevocaBonificoDF = getInfoRevocaBonificoDF;
const getInfoRevocaSmartCard = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, getState, extraArguments) => {
    (0, exports.getInfoRevocaBonifico)(idDispositiva, 'cartacontodispocategorie_annullo', eseguiInit, chiamataUscente, offline)(dispatch, getState, extraArguments);
};
exports.getInfoRevocaSmartCard = getInfoRevocaSmartCard;
function getInfoRevocaBonificoSepaStart() {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_GET_INFO_REVOCA_START
    };
}
function getInfoRevocaBonificoSepaSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_GET_INFO_REVOCA_SUCCESS,
        payload
    };
}
const getInfoRevocaBonifico = (idDispositiva, funzioneDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getInfoRevocaBonificoSepaStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/autenticazione-bonifico.json')).then(parametri => {
            dispatch(getInfoRevocaBonificoSepaSuccess((0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const requestParameters = {
            idDispositiva,
            funzioneDispositiva,
            chiamataUscente,
            eseguiInit,
            offline: !!offline
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BonificoControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).informazioniAutenticazioneRevoca(requestParameters).then((response) => {
                dispatch(getInfoRevocaBonificoSepaSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getInfoRevocaBonifico = getInfoRevocaBonifico;
/* get info revoca - FINE */
/* autorizza revoca - START */
const autorizzaRevocaBonificoSepa = (idDispositiva, credenziale) => async (dispatch, getState, extraArguments) => {
    (0, exports.autorizzaRevocaBonifico)(idDispositiva, credenziale, 'bonificosepa_annullo')(dispatch, getState, extraArguments);
};
exports.autorizzaRevocaBonificoSepa = autorizzaRevocaBonificoSepa;
const autorizzaRevocaBonificoDF = (idDispositiva, credenziale) => async (dispatch, getState, extraArguments) => {
    (0, exports.autorizzaRevocaBonifico)(idDispositiva, credenziale, 'bonificofiscale_annullo')(dispatch, getState, extraArguments);
};
exports.autorizzaRevocaBonificoDF = autorizzaRevocaBonificoDF;
const autorizzaRevocaSmartCard = (idDispositiva, credenziale) => async (dispatch, getState, extraArguments) => {
    (0, exports.autorizzaRevocaBonifico)(idDispositiva, credenziale, 'cartacontodispocategorie_annullo')(dispatch, getState, extraArguments);
};
exports.autorizzaRevocaSmartCard = autorizzaRevocaSmartCard;
function autorizzaRevocaBonificoSepaStart() {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_AUTORIZZA_REVOCA_START
    };
}
function autorizzaRevocaBonificoSepaSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_AUTORIZZA_REVOCA_SUCCESS,
        payload
    };
}
const autorizzaRevocaBonifico = (idDispositiva, credenziale, funzioneDispositiva) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(autorizzaRevocaBonificoSepaStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/autorizza_bonifico.json')).then(parametri => {
            dispatch(autorizzaRevocaBonificoSepaSuccess((0, api_rest_1.EsitoAutorizzazioneDisposizioneBonificoDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const requestParameters = {
            idDispositiva,
            credenzialeDispositivaDto: credenziale,
            funzioneDispositiva
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BonificoControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).operazioneAutenticataBonifico(requestParameters).then((response) => {
                dispatch(autorizzaRevocaBonificoSepaSuccess(response));
                extraArguments.sendEventToMatomo(`${funzioneDispositiva.toUpperCase()}_REVOCA`, `esito revoca dispositiva ${funzioneDispositiva.toUpperCase()}_REVOCA ${response.stato}`, 'REVOCA_DISPOSITIVA');
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error), false, extraArguments.sendEventToMatomo, `${funzioneDispositiva.toUpperCase()}_REVOCA`, 'REVOCA_DISPOSITIVA'));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.autorizzaRevocaBonifico = autorizzaRevocaBonifico;
function resetBonificoSepaRevoca() {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_RESET_REVOCA
    };
}
exports.resetBonificoSepaRevoca = resetBonificoSepaRevoca;
/* get autorizza revoca - FINE */
/* get bozze bonifico sepa - START */
const getBozzeBonificoSepa = (BozzeInDto) => async (dispatch, getState, extraArguments) => {
    (0, exports.getBozzeBonifico)(BozzeInDto, 'bonificosepa')(dispatch, getState, extraArguments);
};
exports.getBozzeBonificoSepa = getBozzeBonificoSepa;
const getBozzeBonificoDF = (BozzeInDto) => async (dispatch, getState, extraArguments) => {
    (0, exports.getBozzeBonifico)(BozzeInDto, 'bonificofiscale')(dispatch, getState, extraArguments);
};
exports.getBozzeBonificoDF = getBozzeBonificoDF;
const getBozzeSmartCard = (BozzeInDto) => async (dispatch, getState, extraArguments) => {
    (0, exports.getBozzeBonifico)(BozzeInDto, 'cartacontodispocategorie')(dispatch, getState, extraArguments);
};
exports.getBozzeSmartCard = getBozzeSmartCard;
function getBozzeBonificoSepaStart() {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_GET_BOZZE_START
    };
}
function getBozzeBonificoSepaSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_GET_BOZZE_SUCCESS,
        payload
    };
}
function resetBozzeBonificoSepa() {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_RESET_BOZZE
    };
}
exports.resetBozzeBonificoSepa = resetBozzeBonificoSepa;
const getBozzeBonifico = (request, funzioneDispositiva) => async (dispatch, getState, extraArguments) => {
    var _a;
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getBozzeBonificoSepaStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/oneclick.json')).then(parametri => {
            // dispatch(getBozzeBonificoSepaSuccess(BonificoSepaDtoFromJSON(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        let mappedFunzione = [];
        switch (funzioneDispositiva) {
            case 'bonificosepa':
                mappedFunzione = ['ORDI'];
                break;
            case 'bonificofiscale':
                mappedFunzione = (_a = (0, bonifico_selectors_1.motivazioniPagamento)(getState().bonificoSepa)) === null || _a === void 0 ? void 0 : _a.filter((motivazione => { var _a; return motivazione.naturaMovimento === 'SCT' && ((_a = motivazione.codice) === null || _a === void 0 ? void 0 : _a.startsWith('FIS')); })).map((motivazione) => motivazione.codice);
                break;
            case 'cartacontodispocategorie':
                mappedFunzione = ['RICA'];
                break;
            default:
                mappedFunzione = ['ORDI'];
        }
        const getBozzeBonificoSepaRequest = {
            bozzeInDto: {
                ...request,
                motivazionePagamento: mappedFunzione
            },
            funzioneDispositiva
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BonificoControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).bozzeBonifico(getBozzeBonificoSepaRequest).then((response) => {
                dispatch(getBozzeBonificoSepaSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getBozzeBonifico = getBozzeBonifico;
/* get bozze bonifico sepa - FINE */
/* get bozza bonifico sepa - START */
const getBozzaBonificoSepa = (id) => async (dispatch, getState, extraArguments) => {
    (0, exports.getBozzaBonifico)(id, 'bonificosepa')(dispatch, getState, extraArguments);
};
exports.getBozzaBonificoSepa = getBozzaBonificoSepa;
const getBozzaBonificoDF = (id) => async (dispatch, getState, extraArguments) => {
    (0, exports.getBozzaBonifico)(id, 'bonificofiscale')(dispatch, getState, extraArguments);
};
exports.getBozzaBonificoDF = getBozzaBonificoDF;
const getBozzaSmartCard = (id) => async (dispatch, getState, extraArguments) => {
    (0, exports.getBozzaBonifico)(id, 'cartacontodispocategorie')(dispatch, getState, extraArguments);
};
exports.getBozzaSmartCard = getBozzaSmartCard;
function getBozzaBonificoSepaStart() {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_GET_BOZZA_START
    };
}
function getBozzaBonificoSepaSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_GET_BOZZA_SUCCESS,
        payload
    };
}
const getBozzaBonifico = (id, funzioneDispositiva) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getBozzaBonificoSepaStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/oneclick.json')).then(parametri => {
            // dispatch(getBozzeBonificoSepaSuccess(BonificoSepaDtoFromJSON(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const getBozzaBonificoSepaRequest = {
            id: id,
            funzioneDispositiva
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BonificoControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).bozzaBonifico(getBozzaBonificoSepaRequest).then((response) => {
                dispatch(getBozzaBonificoSepaSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getBozzaBonifico = getBozzaBonifico;
/* get bozza bonifico sepa - FINE */
/* add bozza bonifico sepa - START */
function addBozzaBonificoStart() {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_ADD_BOZZA_START
    };
}
function addBozzaBonificoSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_ADD_BOZZA_SUCCESS,
        payload
    };
}
const addBozzaBonificoAction = (funzioneDispositiva) => async (dispatch, getState, extraArguments) => {
    const bonificoSepa = getState().bonificoSepa;
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(addBozzaBonificoStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/oneclick.json')).then(parametri => {
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        let request = null;
        let successLabel = '';
        switch (funzioneDispositiva) {
            case 'bonificosepa':
                request = (0, bonifico_selectors_1.buildBonificoSepa)(bonificoSepa);
                successLabel = 'bonifico.nuovaBozzaBonificoSalvata';
                break;
            case 'bonificofiscale':
                request = (0, bonifico_selectors_1.buildBonificoDF)(bonificoSepa);
                successLabel = 'bonifico.nuovaBozzaBonificoSalvata';
                break;
            case 'cartacontodispocategorie':
                request = (0, bonifico_selectors_1.buildSmartCard)(bonificoSepa);
                successLabel = 'ricaricaCartaConto.nuovaBozzaRicaricaCartaContoSalvata';
                break;
            default:
                request = (0, bonifico_selectors_1.buildBonificoSepa)(bonificoSepa);
                successLabel = 'bonifico.nuovaBozzaBonificoSalvata';
        }
        const addBozzaBonificoSepaRequest = {
            bonificoSepaDto: {
                ...request,
                bozza: {
                    dataUltimaModifica: moment().toDate()
                }
            },
            funzioneDispositiva
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BonificoControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).salvaBozzaBonifico(addBozzaBonificoSepaRequest).then((response) => {
                dispatch(addBozzaBonificoSuccess(response));
                dispatch((0, general_actions_1.addSnackbar)(successLabel));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
const addBozzaBonificoSepaAction = () => async (dispatch, getState, extraArguments) => {
    addBozzaBonificoAction('bonificosepa')(dispatch, getState, extraArguments);
};
const addBozzaBonificoDFAction = () => async (dispatch, getState, extraArguments) => {
    addBozzaBonificoAction('bonificofiscale')(dispatch, getState, extraArguments);
};
const addBozzaSmartCardAction = () => async (dispatch, getState, extraArguments) => {
    addBozzaBonificoAction('cartacontodispocategorie')(dispatch, getState, extraArguments);
};
/* add bozza bonifico sepa - FINE */
/* del bozza bonifico sepa - START */
function delBozzaBonificoSepaStart() {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_DEL_BOZZA_START
    };
}
function delBozzaBonificoSepaSuccess(id) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_DEL_BOZZA_SUCCESS,
        payload: id
    };
}
const delBozzaBonificoSepa = (id) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(delBozzaBonificoSepaStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/oneclick.json')).then(parametri => {
            // dispatch(getBozzeBonificoSepaSuccess(BonificoSepaDtoFromJSON(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        const delBozzaBonificoSepaRequest = {
            id
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BonificoControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).eliminaBozzaBonifico(delBozzaBonificoSepaRequest).then(() => {
                dispatch(delBozzaBonificoSepaSuccess(id));
                dispatch((0, general_actions_1.addSnackbar)('bonifico.bozzaEliminataConSuccesso'));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.delBozzaBonificoSepa = delBozzaBonificoSepa;
/* del bozza bonifico sepa - FINE */
/* get bonifico from bozza - START */
function getBonificoFromBozza(payload, noBozza = false) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_GET_BONIFICO_FROM_BOZZA,
        payload,
        noBozza
    };
}
exports.getBonificoFromBozza = getBonificoFromBozza;
/* get bonifico from bozza - FINE */
function resetRiepilogoBonificoSepa() {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_RESET_RIEPILOGO
    };
}
exports.resetRiepilogoBonificoSepa = resetRiepilogoBonificoSepa;
/* update bozza bonifico sepa - START */
function updateBozzaBonificoStart() {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_UPDATE_BOZZA_START
    };
}
function updateBozzaBonificoSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_UPDATE_BOZZA_SUCCESS,
        payload
    };
}
const updateBozzaBonificoAction = (funzioneDispositiva) => async (dispatch, getState, extraArguments) => {
    var _a;
    const bonificoSepa = getState().bonificoSepa;
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(updateBozzaBonificoStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/oneclick.json')).then(parametri => {
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        let request = null;
        let successLabel = '';
        switch (funzioneDispositiva) {
            case 'bonificosepa':
                request = (0, bonifico_selectors_1.buildBonificoSepa)(bonificoSepa);
                successLabel = 'bonifico.bozzaBonificoAggiornata';
                break;
            case 'bonificofiscale':
                request = (0, bonifico_selectors_1.buildBonificoDF)(bonificoSepa);
                successLabel = 'bonifico.bozzaBonificoAggiornata';
                break;
            case 'cartacontodispocategorie':
                request = (0, bonifico_selectors_1.buildSmartCard)(bonificoSepa);
                successLabel = 'ricaricaCartaConto.bozzaRicaricaCartaContoAggiornata';
                break;
            default:
                request = (0, bonifico_selectors_1.buildBonificoSepa)(bonificoSepa);
                successLabel = 'bonifico.bozzaBonificoAggiornata';
        }
        const idCurrentBozza = ((_a = bonificoSepa.currentBozza) === null || _a === void 0 ? void 0 : _a.id) || 0;
        const updateBozzaBonificoSepaRequest = {
            id: idCurrentBozza,
            bonificoSepaDto: {
                id: idCurrentBozza,
                ...request,
                bozza: {
                    dataUltimaModifica: moment().toDate()
                }
            },
            funzioneDispositiva
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BonificoControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).updateBozzaBonifico(updateBozzaBonificoSepaRequest).then((response) => {
                dispatch(updateBozzaBonificoSuccess(response));
                dispatch((0, general_actions_1.addSnackbar)(successLabel));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
const updateBozzaBonificoSepaAction = () => async (dispatch, getState, extraArguments) => {
    updateBozzaBonificoAction('bonificosepa')(dispatch, getState, extraArguments);
};
const updateBozzaBonificoDFAction = () => async (dispatch, getState, extraArguments) => {
    updateBozzaBonificoAction('bonificofiscale')(dispatch, getState, extraArguments);
};
const updateBozzaSmartCardAction = () => async (dispatch, getState, extraArguments) => {
    updateBozzaBonificoAction('cartacontodispocategorie')(dispatch, getState, extraArguments);
};
/* update bozza bonifico sepa - FINE */
const shouldUpdateSmartCard = () => async (dispatch, getState, extraArguments) => {
    const state = getState().bonificoSepa;
    if ((0, bonifico_selectors_1.checkUpdateBozzaSmartCard)(state)) {
        dispatch(updateBozzaSmartCardAction());
    }
    else {
        dispatch(addBozzaSmartCardAction());
    }
};
exports.shouldUpdateSmartCard = shouldUpdateSmartCard;
const shouldUpdateBozzaBonificoSepa = () => async (dispatch, getState, extraArguments) => {
    const state = getState().bonificoSepa;
    if ((0, bonifico_selectors_1.checkUpdateBozzaBonificoSepa)(state)) {
        dispatch(updateBozzaBonificoSepaAction());
    }
    else {
        dispatch(addBozzaBonificoSepaAction());
    }
};
exports.shouldUpdateBozzaBonificoSepa = shouldUpdateBozzaBonificoSepa;
const shouldUpdateBozzaBonificoDF = () => async (dispatch, getState, extraArguments) => {
    const state = getState().bonificoSepa;
    if ((0, bonifico_selectors_1.checkUpdateBozzaBonificoDF)(state)) {
        dispatch(updateBozzaBonificoDFAction());
    }
    else {
        dispatch(addBozzaBonificoDFAction());
    }
};
exports.shouldUpdateBozzaBonificoDF = shouldUpdateBozzaBonificoDF;
// Reset Esito
function resetEsitoBonificoSepa() {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_RESET_ESITO
    };
}
exports.resetEsitoBonificoSepa = resetEsitoBonificoSepa;
function setBonificoSepaSetIstantaneo(istantaneo) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_SET_ISTANTANEO,
        payload: istantaneo
    };
}
exports.setBonificoSepaSetIstantaneo = setBonificoSepaSetIstantaneo;
function setBonificoSepaIstantaneoError(payload) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_ISTANTANEO_ERROR,
        payload
    };
}
function resetBonificoSepaIstantaneoError() {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_ISTANTANEO_RESET_ERROR
    };
}
exports.resetBonificoSepaIstantaneoError = resetBonificoSepaIstantaneoError;
function getParametriRicaricaCartaContoStart() {
    return {
        type: __1.EReduxActionTypes.CARTA_CONTO_GET_PARAMETRI_START
    };
}
function getParametriRicaricaCartaContoSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.CARTA_CONTO_GET_PARAMETRI_SUCCESS,
        payload
    };
}
function getParametriPerRipetiRicaricaCartaContoSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.CARTA_CONTO_GET_PARAMETRI_PER_RIPETI_SUCCESS,
        payload
    };
}
const getParametriRicaricaCartaConto = (ripeti) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getParametriRicaricaCartaContoStart());
    if (extraArguments.mock) {
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.CartaContoControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametriDispoCartaConto().then(async (response) => {
                var _a;
                dispatch(ripeti ? getParametriPerRipetiRicaricaCartaContoSuccess(response) : getParametriRicaricaCartaContoSuccess(response));
                ((_a = response.rapporti) === null || _a === void 0 ? void 0 : _a.length) === 0 && dispatch((0, general_actions_1.setNoRapporti)({ type: general_types_1.TypeNoRapporti.RICARICA_SMARTCARD }));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getParametriRicaricaCartaConto = getParametriRicaricaCartaConto;
function setBonificoSepaUsernamePSD2(payload) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_SET_USERNAME_PSD2,
        payload
    };
}
exports.setBonificoSepaUsernamePSD2 = setBonificoSepaUsernamePSD2;
function resetBonificoSepaInfoDispositiva() {
    return {
        type: __1.EReduxActionTypes.BONIFICO_SEPA_RESET_INFO_DISPOSITIVA
    };
}
exports.resetBonificoSepaInfoDispositiva = resetBonificoSepaInfoDispositiva;
/** modifica massimale  - START */
function modificaMassimaleBonificoInstaStart() {
    return {
        type: __1.EReduxActionTypes.MODIFICA_MASSIMALE_BONIFICO_ISTA_START
    };
}
exports.modificaMassimaleBonificoInstaStart = modificaMassimaleBonificoInstaStart;
function modificaMassimaleBonificoInstaSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.MODIFICA_MASSIMALE_BONIFICO_ISTA_SUCCESS,
        payload
    };
}
exports.modificaMassimaleBonificoInstaSuccess = modificaMassimaleBonificoInstaSuccess;
const modificaMassimaleBonificoInsta = (valore, rapporti) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(modificaMassimaleBonificoInstaStart());
    const parametriBonificoRequest = {
        modificaMassimaleSepaIstantInDto: { valore: valore != undefined ? valore : 1500, rapporti }
    };
    extraArguments.getItem('jwt').then((jwt) => {
        new api_rest_1.LimitiOperativiControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).modificaMassimaliBonificoIstantaneo(parametriBonificoRequest)
            .then((response) => {
            dispatch(modificaMassimaleBonificoInstaSuccess(response));
        }).catch(async (error) => {
            dispatch(generalError(await (0, helpers_1.formatError)(error)));
        }).finally(() => {
            dispatch((0, general_actions_1.setLoader)(false));
        });
    });
};
exports.modificaMassimaleBonificoInsta = modificaMassimaleBonificoInsta;
/** modifica massimale  - FINE */
function setBonificoDFSetCheckboxPagamentoSpeseSuPartiComuniCondominiali(bool) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_DF_SET_CHECKBOX_PAGAMENTO_SPESE_SU_PARTI_COMUNI_CONDOMINIALI,
        payload: bool
    };
}
exports.setBonificoDFSetCheckboxPagamentoSpeseSuPartiComuniCondominiali = setBonificoDFSetCheckboxPagamentoSpeseSuPartiComuniCondominiali;
