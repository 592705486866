"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssicurazioniContoDtoToJSON = exports.AssicurazioniContoDtoFromJSONTyped = exports.AssicurazioniContoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function AssicurazioniContoDtoFromJSON(json) {
    return AssicurazioniContoDtoFromJSONTyped(json, false);
}
exports.AssicurazioniContoDtoFromJSON = AssicurazioniContoDtoFromJSON;
function AssicurazioniContoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'intestatario': !runtime_1.exists(json, 'intestatario') ? undefined : json['intestatario'],
        'iban': !runtime_1.exists(json, 'iban') ? undefined : json['iban'],
        'capitaleAssicurato': !runtime_1.exists(json, 'capitaleAssicurato') ? undefined : json['capitaleAssicurato'],
        'controvalore': !runtime_1.exists(json, 'controvalore') ? undefined : json['controvalore'],
    };
}
exports.AssicurazioniContoDtoFromJSONTyped = AssicurazioniContoDtoFromJSONTyped;
function AssicurazioniContoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'intestatario': value.intestatario,
        'iban': value.iban,
        'capitaleAssicurato': value.capitaleAssicurato,
        'controvalore': value.controvalore,
    };
}
exports.AssicurazioniContoDtoToJSON = AssicurazioniContoDtoToJSON;
