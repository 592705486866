"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DossierAccountLevelBODtoToJSON = exports.DossierAccountLevelBODtoFromJSONTyped = exports.DossierAccountLevelBODtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function DossierAccountLevelBODtoFromJSON(json) {
    return DossierAccountLevelBODtoFromJSONTyped(json, false);
}
exports.DossierAccountLevelBODtoFromJSON = DossierAccountLevelBODtoFromJSON;
function DossierAccountLevelBODtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'accountCode': !runtime_1.exists(json, 'accountCode') ? undefined : json['accountCode'],
        'bankCode': !runtime_1.exists(json, 'bankCode') ? undefined : json['bankCode'],
        'branchCode': !runtime_1.exists(json, 'branchCode') ? undefined : json['branchCode'],
        'childrenByInstrumentType': !runtime_1.exists(json, 'childrenByInstrumentType') ? undefined : json['childrenByInstrumentType'],
        'ctvAcquisto': !runtime_1.exists(json, 'ctvAcquisto') ? undefined : json['ctvAcquisto'],
        'ctvMercato': !runtime_1.exists(json, 'ctvMercato') ? undefined : json['ctvMercato'],
        'dataByInstrumentType': !runtime_1.exists(json, 'dataByInstrumentType') ? undefined : (runtime_1.mapValues(json['dataByInstrumentType'], _1.DossierDataBODtoFromJSON)),
        'message': !runtime_1.exists(json, 'message') ? undefined : json['message'],
        'peso': !runtime_1.exists(json, 'peso') ? undefined : json['peso'],
        'plusMinusEuro': !runtime_1.exists(json, 'plusMinusEuro') ? undefined : json['plusMinusEuro'],
        'plusMinusPerc': !runtime_1.exists(json, 'plusMinusPerc') ? undefined : json['plusMinusPerc'],
        'userId': !runtime_1.exists(json, 'userId') ? undefined : json['userId'],
    };
}
exports.DossierAccountLevelBODtoFromJSONTyped = DossierAccountLevelBODtoFromJSONTyped;
function DossierAccountLevelBODtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'accountCode': value.accountCode,
        'bankCode': value.bankCode,
        'branchCode': value.branchCode,
        'childrenByInstrumentType': value.childrenByInstrumentType,
        'ctvAcquisto': value.ctvAcquisto,
        'ctvMercato': value.ctvMercato,
        'dataByInstrumentType': value.dataByInstrumentType === undefined ? undefined : (runtime_1.mapValues(value.dataByInstrumentType, _1.DossierDataBODtoToJSON)),
        'message': value.message,
        'peso': value.peso,
        'plusMinusEuro': value.plusMinusEuro,
        'plusMinusPerc': value.plusMinusPerc,
        'userId': value.userId,
    };
}
exports.DossierAccountLevelBODtoToJSON = DossierAccountLevelBODtoToJSON;
