"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SaldoCartaDtoToJSON = exports.SaldoCartaDtoFromJSONTyped = exports.SaldoCartaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function SaldoCartaDtoFromJSON(json) {
    return SaldoCartaDtoFromJSONTyped(json, false);
}
exports.SaldoCartaDtoFromJSON = SaldoCartaDtoFromJSON;
function SaldoCartaDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'rapporto': !runtime_1.exists(json, 'rapporto') ? undefined : _1.ProfiloRapportoDtoFromJSON(json['rapporto']),
        'saldo': !runtime_1.exists(json, 'saldo') ? undefined : _1.SaldoDtoFromJSON(json['saldo']),
        'pan': !runtime_1.exists(json, 'pan') ? undefined : json['pan'],
        'numeroCartaDebito': !runtime_1.exists(json, 'numeroCartaDebito') ? undefined : json['numeroCartaDebito'],
        'dataAttivazione': !runtime_1.exists(json, 'dataAttivazione') ? undefined : (new Date(json['dataAttivazione'])),
        'dataEstinzione': !runtime_1.exists(json, 'dataEstinzione') ? undefined : (new Date(json['dataEstinzione'])),
        'idDispReale': !runtime_1.exists(json, 'idDispReale') ? undefined : json['idDispReale'],
        'codiceProdotto': !runtime_1.exists(json, 'codiceProdotto') ? undefined : json['codiceProdotto'],
        'releaseProdotto': !runtime_1.exists(json, 'releaseProdotto') ? undefined : json['releaseProdotto'],
        'dataScadenza': !runtime_1.exists(json, 'dataScadenza') ? undefined : (new Date(json['dataScadenza'])),
        'tipologiaCarta': !runtime_1.exists(json, 'tipologiaCarta') ? undefined : json['tipologiaCarta'],
        'panInternazionale': !runtime_1.exists(json, 'panInternazionale') ? undefined : json['panInternazionale'],
    };
}
exports.SaldoCartaDtoFromJSONTyped = SaldoCartaDtoFromJSONTyped;
function SaldoCartaDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'rapporto': _1.ProfiloRapportoDtoToJSON(value.rapporto),
        'saldo': _1.SaldoDtoToJSON(value.saldo),
        'pan': value.pan,
        'numeroCartaDebito': value.numeroCartaDebito,
        'dataAttivazione': value.dataAttivazione === undefined ? undefined : (value.dataAttivazione.toISOString()),
        'dataEstinzione': value.dataEstinzione === undefined ? undefined : (value.dataEstinzione.toISOString()),
        'idDispReale': value.idDispReale,
        'codiceProdotto': value.codiceProdotto,
        'releaseProdotto': value.releaseProdotto,
        'dataScadenza': value.dataScadenza === undefined ? undefined : (value.dataScadenza.toISOString()),
        'tipologiaCarta': value.tipologiaCarta,
        'panInternazionale': value.panInternazionale,
    };
}
exports.SaldoCartaDtoToJSON = SaldoCartaDtoToJSON;
