"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DatiCartaAcquistoDtoStatoEnum = exports.DatiCartaAcquistoDtoToJSON = exports.DatiCartaAcquistoDtoFromJSONTyped = exports.DatiCartaAcquistoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function DatiCartaAcquistoDtoFromJSON(json) {
    return DatiCartaAcquistoDtoFromJSONTyped(json, false);
}
exports.DatiCartaAcquistoDtoFromJSON = DatiCartaAcquistoDtoFromJSON;
function DatiCartaAcquistoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nomeCognome': !runtime_1.exists(json, 'nomeCognome') ? undefined : json['nomeCognome'],
        'codiceFiscale': !runtime_1.exists(json, 'codiceFiscale') ? undefined : json['codiceFiscale'],
        'personaPoliticamenteEsposta': !runtime_1.exists(json, 'personaPoliticamenteEsposta') ? undefined : json['personaPoliticamenteEsposta'],
        'cartaCondizioniList': !runtime_1.exists(json, 'cartaCondizioniList') ? undefined : _1.CartaCondizioniDtoFromJSON(json['cartaCondizioniList']),
        'rapportoDiAppoggio': !runtime_1.exists(json, 'rapportoDiAppoggio') ? undefined : _1.RapportoCompletoDtoFromJSON(json['rapportoDiAppoggio']),
        'professione': !runtime_1.exists(json, 'professione') ? undefined : json['professione'],
        'tae': !runtime_1.exists(json, 'tae') ? undefined : json['tae'],
        'provenienzaFondi': !runtime_1.exists(json, 'provenienzaFondi') ? undefined : (json['provenienzaFondi'].map(_1.ProvenienzaFondiReqDtoFromJSON)),
        'scopoPrevalente': !runtime_1.exists(json, 'scopoPrevalente') ? undefined : json['scopoPrevalente'],
        'consensoResponseList': !runtime_1.exists(json, 'consensoResponseList') ? undefined : (json['consensoResponseList'].map(_1.ConsensoResponseFromJSON)),
        'indirizzoSpedizione': !runtime_1.exists(json, 'indirizzoSpedizione') ? undefined : _1.IndirizzoDatiAnagraficiDtoFromJSON(json['indirizzoSpedizione']),
        'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
        'dataCreazione': !runtime_1.exists(json, 'dataCreazione') ? undefined : json['dataCreazione'],
    };
}
exports.DatiCartaAcquistoDtoFromJSONTyped = DatiCartaAcquistoDtoFromJSONTyped;
function DatiCartaAcquistoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'nomeCognome': value.nomeCognome,
        'codiceFiscale': value.codiceFiscale,
        'personaPoliticamenteEsposta': value.personaPoliticamenteEsposta,
        'cartaCondizioniList': _1.CartaCondizioniDtoToJSON(value.cartaCondizioniList),
        'rapportoDiAppoggio': _1.RapportoCompletoDtoToJSON(value.rapportoDiAppoggio),
        'professione': value.professione,
        'tae': value.tae,
        'provenienzaFondi': value.provenienzaFondi === undefined ? undefined : (value.provenienzaFondi.map(_1.ProvenienzaFondiReqDtoToJSON)),
        'scopoPrevalente': value.scopoPrevalente,
        'consensoResponseList': value.consensoResponseList === undefined ? undefined : (value.consensoResponseList.map(_1.ConsensoResponseToJSON)),
        'indirizzoSpedizione': _1.IndirizzoDatiAnagraficiDtoToJSON(value.indirizzoSpedizione),
        'stato': value.stato,
        'dataCreazione': value.dataCreazione,
    };
}
exports.DatiCartaAcquistoDtoToJSON = DatiCartaAcquistoDtoToJSON;
/**
* @export
* @enum {string}
*/
var DatiCartaAcquistoDtoStatoEnum;
(function (DatiCartaAcquistoDtoStatoEnum) {
    DatiCartaAcquistoDtoStatoEnum["PRATICA_INSERITA"] = "PRATICA_INSERITA";
    DatiCartaAcquistoDtoStatoEnum["IN_ATTESA_DI_FIRMA"] = "IN_ATTESA_DI_FIRMA";
    DatiCartaAcquistoDtoStatoEnum["PRATICA_FIRMATA"] = "PRATICA_FIRMATA";
    DatiCartaAcquistoDtoStatoEnum["PRATICA_ANNULLATA"] = "PRATICA_ANNULLATA";
    DatiCartaAcquistoDtoStatoEnum["PRATICA_IN_VALUTAZIONE"] = "PRATICA_IN_VALUTAZIONE";
    DatiCartaAcquistoDtoStatoEnum["PRATICA_ACCETTATA"] = "PRATICA_ACCETTATA";
    DatiCartaAcquistoDtoStatoEnum["PRATICA_RIFIUTATA"] = "PRATICA_RIFIUTATA";
    DatiCartaAcquistoDtoStatoEnum["PRATICA_CONCLUSA_NEGATIVAMENTE"] = "PRATICA_CONCLUSA_NEGATIVAMENTE";
})(DatiCartaAcquistoDtoStatoEnum = exports.DatiCartaAcquistoDtoStatoEnum || (exports.DatiCartaAcquistoDtoStatoEnum = {}));
