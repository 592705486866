import React from "react"


interface Props {
  option: string
  selected: boolean
  onPress?: (selectedOption: string) => void,
  interalCircleStyle?: string,
  externalCircleStyle?: string,
  textStyle?: string,
  className?: string,
  disabled?: boolean
}

const BankRadioButton: React.FC<Props> = (props: Props) => {


  const {
    option,
    selected,
    onPress,
    interalCircleStyle,
    externalCircleStyle,
    textStyle,
    className,
    disabled
  } = props


  return (
    <div className={className} onClick={() => onPress && !disabled && onPress(option)}>
      <div className="radio-container">
        <div className={`radio-externalCircle ${externalCircleStyle}`}>
          {selected && (
            <div className={`radio-internalCircle ${disabled && "disabled"} ${interalCircleStyle}`} />
          )}
        </div>
        <div className="radio-textContainer">
          <div className={`radio-text ${textStyle}`}>{option}</div>
        </div>
      </div>
    </div>)
}

export default BankRadioButton