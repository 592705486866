"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetOperazioniPreferite = exports.setOperazioniPreferiti = exports.getOperazioniPreferiti = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
function generalError(value) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value));
    };
}
function getOperazioniPreferitiStart() {
    return {
        type: __1.EReduxActionTypes.OPERAZIONI_GET_PREFERITI_START
    };
}
function getOperazioniPreferitiSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.OPERAZIONI_GET_PREFERITI_SUCCESS,
        payload
    };
}
const getOperazioniPreferiti = (loaderEnabled = true) => async (dispatch, _, extraArguments) => {
    loaderEnabled && dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getOperazioniPreferitiStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/operazioni_preferiti.json')).then(parametri => {
            dispatch(getOperazioniPreferitiSuccess((0, api_rest_1.GestionePreferitiDtoFromJSON)(parametri)));
            loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.OperazioniControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .operazioniPreferiti().then((response) => {
                dispatch(getOperazioniPreferitiSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getOperazioniPreferiti = getOperazioniPreferiti;
function setOperazioniPreferitiStart() {
    return {
        type: __1.EReduxActionTypes.OPERAZIONI_SET_PREFERITI_START
    };
}
function setOperazioniPreferitiSuccess() {
    return {
        type: __1.EReduxActionTypes.OPERAZIONI_SET_PREFERITI_SUCCESS
    };
}
const setOperazioniPreferiti = (operazioniPreferite) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(setOperazioniPreferitiStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        const request = {
            operazioniPreferiteDto: {
                operazioniPreferite: operazioniPreferite
            }
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.OperazioniControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .addPreferita(request).then((_) => {
                dispatch(setOperazioniPreferitiSuccess());
                dispatch((0, exports.getOperazioniPreferiti)());
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.setOperazioniPreferiti = setOperazioniPreferiti;
function resetOperazioniPreferite() {
    return {
        type: __1.EReduxActionTypes.OPERAZIONI_RESET_PREFERITI
    };
}
exports.resetOperazioniPreferite = resetOperazioniPreferite;
