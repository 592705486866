"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhotoToJSON = exports.PhotoFromJSONTyped = exports.PhotoFromJSON = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
function PhotoFromJSON(json) {
    return PhotoFromJSONTyped(json, false);
}
exports.PhotoFromJSON = PhotoFromJSON;
function PhotoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'height': !(0, api_rest_1.exists)(json, 'height') ? undefined : json['height'],
        'html_attributions': !(0, api_rest_1.exists)(json, 'html_attributions') ? undefined : json['html_attributions'],
        'photo_reference': !(0, api_rest_1.exists)(json, 'photo_reference') ? undefined : json['photo_reference'],
        'width': !(0, api_rest_1.exists)(json, 'width') ? undefined : json['width']
    };
}
exports.PhotoFromJSONTyped = PhotoFromJSONTyped;
function PhotoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'height': value.height,
        'html_attributions': value.html_attributions,
        'photo_reference': value.photo_reference,
        'width': value.width
    };
}
exports.PhotoToJSON = PhotoToJSON;
