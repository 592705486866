"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MovimentoCarteRicaricabileDtoToJSON = exports.MovimentoCarteRicaricabileDtoFromJSONTyped = exports.MovimentoCarteRicaricabileDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function MovimentoCarteRicaricabileDtoFromJSON(json) {
    return MovimentoCarteRicaricabileDtoFromJSONTyped(json, false);
}
exports.MovimentoCarteRicaricabileDtoFromJSON = MovimentoCarteRicaricabileDtoFromJSON;
function MovimentoCarteRicaricabileDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'dataMovimento': !runtime_1.exists(json, 'dataMovimento') ? undefined : (new Date(json['dataMovimento'])),
        'dataContabile': !runtime_1.exists(json, 'dataContabile') ? undefined : (new Date(json['dataContabile'])),
        'dataValuta': !runtime_1.exists(json, 'dataValuta') ? undefined : (new Date(json['dataValuta'])),
        'descrizione': !runtime_1.exists(json, 'descrizione') ? undefined : json['descrizione'],
        'nomeEsercente': !runtime_1.exists(json, 'nomeEsercente') ? undefined : json['nomeEsercente'],
        'importo': !runtime_1.exists(json, 'importo') ? undefined : json['importo'],
        'controvaloreEuro': !runtime_1.exists(json, 'controvaloreEuro') ? undefined : json['controvaloreEuro'],
        'valuta': !runtime_1.exists(json, 'valuta') ? undefined : json['valuta'],
    };
}
exports.MovimentoCarteRicaricabileDtoFromJSONTyped = MovimentoCarteRicaricabileDtoFromJSONTyped;
function MovimentoCarteRicaricabileDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'dataMovimento': value.dataMovimento === undefined ? undefined : (value.dataMovimento.toISOString()),
        'dataContabile': value.dataContabile === undefined ? undefined : (value.dataContabile.toISOString()),
        'dataValuta': value.dataValuta === undefined ? undefined : (value.dataValuta.toISOString()),
        'descrizione': value.descrizione,
        'nomeEsercente': value.nomeEsercente,
        'importo': value.importo,
        'controvaloreEuro': value.controvaloreEuro,
        'valuta': value.valuta,
    };
}
exports.MovimentoCarteRicaricabileDtoToJSON = MovimentoCarteRicaricabileDtoToJSON;
