"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkUpdateBozzaBollettinoPostale = exports.formValid1ClickBollettinoPostaleBianco = exports.tipoSelectedBollettinoPostale = exports.disableSalvaBozzaBollettinoPostalePremarcato = exports.formValidForBozzaBollettinoPostale = exports.buildBollettinoPostalePremarcato = exports.buildBollettinoPostaleBianco = exports.formValidBollettinoPostaleBianco = exports.esitoRevocaBollettinoPostale = exports.riepilogoRevocaBollettinoPostale = exports.infoRevocaBollettinoPostale = exports.formRevocaBollettinoPostale = exports.oneClickBollettinoPostale = exports.currentBozzaBollettinoPostale = exports.infoAutenticazioneBollettinoPostale = exports.esitoBollettinoPostale = exports.riepilogoBollettinoPostale = exports.bozzeBollettinoPostale = exports.disableSalvaBozzaBollettinoPostaleBianco = exports.formValidBollettinoPostale = exports.formBollettinoPostale = exports.parametriBollettinoPostale = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const reselect_1 = require("reselect");
const bollettinoPostaleReducer = (state) => state;
exports.parametriBollettinoPostale = (0, reselect_1.createSelector)(bollettinoPostaleReducer, (state) => {
    return state.parametri;
});
exports.formBollettinoPostale = (0, reselect_1.createSelector)(bollettinoPostaleReducer, (state) => {
    return state.form;
});
exports.formValidBollettinoPostale = (0, reselect_1.createSelector)(bollettinoPostaleReducer, (state) => {
    let formValid = false;
    if (!state.form.idUtenzaError &&
        !state.form.importoError &&
        !state.form.esecutoreError &&
        !state.form.indirizzoError &&
        !state.form.provinciaError &&
        !state.form.cittaError &&
        !state.form.capError &&
        !state.form.numeroBollettinoError &&
        !state.form.tipoDocumentoError &&
        !state.form.dataPagamentoError) {
        formValid = true;
    }
    return formValid;
});
exports.disableSalvaBozzaBollettinoPostaleBianco = (0, reselect_1.createSelector)(bollettinoPostaleReducer, (state) => {
    let isBozzaAccepted = !state.form.bozzaSalvata &&
        (state.form.rapportoSelezionato && state.form.rapportoSelezionato.rapporto && state.form.rapportoSelezionato.rapporto.id) &&
        (state.form.causale && !state.form.causaleError) &&
        (state.form.intestatario && !state.form.intestatarioError) &&
        ((state.form.idUtenza && !state.form.idUtenzaError) ||
            (state.form.importo && !state.form.importoError) ||
            (state.form.intestatario && !state.form.intestatarioError) ||
            (state.form.causale && !state.form.causaleError) ||
            (state.form.esecutore && !state.form.esecutoreError) ||
            (state.form.indirizzo && !state.form.indirizzoError) ||
            (state.form.provincia && !state.form.provinciaError) ||
            (state.form.citta && !state.form.cittaError) ||
            (state.form.cap && !state.form.capError));
    isBozzaAccepted = isBozzaAccepted && !!state.form.idUtenza ? !state.form.idUtenzaError : isBozzaAccepted;
    isBozzaAccepted = isBozzaAccepted && !!state.form.importo ? !state.form.importoError : isBozzaAccepted;
    isBozzaAccepted = isBozzaAccepted && !!state.form.intestatario ? !state.form.intestatarioError : isBozzaAccepted;
    isBozzaAccepted = isBozzaAccepted && !!state.form.causale ? !state.form.causaleError : isBozzaAccepted;
    isBozzaAccepted = isBozzaAccepted && !!state.form.esecutore ? !state.form.esecutoreError : isBozzaAccepted;
    isBozzaAccepted = isBozzaAccepted && !!state.form.indirizzo ? !state.form.indirizzoError : isBozzaAccepted;
    isBozzaAccepted = isBozzaAccepted && !!state.form.provincia ? !state.form.provinciaError : isBozzaAccepted;
    isBozzaAccepted = isBozzaAccepted && !!state.form.citta ? !state.form.cittaError : isBozzaAccepted;
    isBozzaAccepted = isBozzaAccepted && !!state.form.cap ? !state.form.capError : isBozzaAccepted;
    return !isBozzaAccepted;
});
exports.bozzeBollettinoPostale = (0, reselect_1.createSelector)(bollettinoPostaleReducer, (state) => {
    return state.bozzeBollettinoPostale ? state.bozzeBollettinoPostale.sort((a, b) => b.id - a.id) : [];
});
exports.riepilogoBollettinoPostale = (0, reselect_1.createSelector)(bollettinoPostaleReducer, (state) => {
    return state.riepilogo;
});
exports.esitoBollettinoPostale = (0, reselect_1.createSelector)(bollettinoPostaleReducer, (state) => {
    return state.esito;
});
exports.infoAutenticazioneBollettinoPostale = (0, reselect_1.createSelector)(bollettinoPostaleReducer, (state) => {
    return state.infoAutenticazioneBollettinoPostale;
});
exports.currentBozzaBollettinoPostale = (0, reselect_1.createSelector)(bollettinoPostaleReducer, (state) => {
    return state.currentBozza;
});
exports.oneClickBollettinoPostale = (0, reselect_1.createSelector)(bollettinoPostaleReducer, (state) => {
    return state.oneclick;
});
exports.formRevocaBollettinoPostale = (0, reselect_1.createSelector)(bollettinoPostaleReducer, (state) => {
    return state.formRevoca;
});
exports.infoRevocaBollettinoPostale = (0, reselect_1.createSelector)(bollettinoPostaleReducer, (state) => {
    return state.infoRevoca;
});
exports.riepilogoRevocaBollettinoPostale = (0, reselect_1.createSelector)(bollettinoPostaleReducer, (state) => {
    return state.riepilogoRevoca;
});
exports.esitoRevocaBollettinoPostale = (0, reselect_1.createSelector)(bollettinoPostaleReducer, (state) => {
    return state.esitoRevoca;
});
exports.formValidBollettinoPostaleBianco = (0, reselect_1.createSelector)(bollettinoPostaleReducer, (state) => {
    let formValid = false;
    if (!state.form.idUtenzaError &&
        !state.form.importoError &&
        !state.form.esecutoreError &&
        !state.form.indirizzoError &&
        !state.form.provinciaError &&
        !state.form.cittaError &&
        !state.form.capError &&
        !state.form.tipoDocumentoError &&
        !state.form.dataPagamentoError &&
        !state.form.intestatarioError &&
        !state.form.causaleError) {
        formValid = true;
    }
    return formValid;
});
exports.buildBollettinoPostaleBianco = (0, reselect_1.createSelector)(bollettinoPostaleReducer, (state) => {
    var _a, _b, _c, _d, _e, _f, _g;
    return {
        idUtenza: state.form.idUtenza,
        revocabile: true,
        tipologia: api_rest_1.BollettinoPostaleDtoTipologiaEnum.BIANCO,
        causale: state.form.causale,
        citta: state.form.citta,
        provincia: state.form.provincia,
        importo: state.form.importo
            ? Number(state.form.importo.toString().replace(',', '.'))
            : 0,
        contoAddebito: ((_b = (_a = state.form.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.id)
            ? state.form.rapportoSelezionato.rapporto.id
            : 0,
        contoAddebitoIban: ((_d = (_c = state.form.rapportoSelezionato) === null || _c === void 0 ? void 0 : _c.rapporto) === null || _d === void 0 ? void 0 : _d.iban) || '',
        ordinanteIntestazione: (_g = (_f = (_e = state.form.rapportoSelezionato) === null || _e === void 0 ? void 0 : _e.rapporto) === null || _f === void 0 ? void 0 : _f.intestazione) !== null && _g !== void 0 ? _g : '',
        dataPagamento: new Date(state.form.dataPagamento),
        esecutore: state.form.esecutore,
        cap: state.form.cap,
        // numeroBollettino: state.form.numeroBollettino,
        indirizzo: state.form.indirizzo,
        intestatario: state.form.intestatario,
        tipoDocumento: state.form.tipoDocumento,
        idBozzaCollegata: state.currentBozza.id,
        aggiungi1click: state.form.salva1Click,
        alias1Click: state.form.alias1Click,
        idOneClickCollegata: state.form.idOneClickCollegata
    };
});
exports.buildBollettinoPostalePremarcato = (0, reselect_1.createSelector)(bollettinoPostaleReducer, (state) => {
    var _a, _b;
    return {
        idUtenza: state.form.idUtenza,
        revocabile: true,
        tipologia: api_rest_1.BollettinoPostaleDtoTipologiaEnum.PREMARCATO,
        citta: state.form.citta,
        provincia: state.form.provincia,
        importo: state.form.importo
            ? Number(state.form.importo.toString().replace(',', '.'))
            : 0,
        contoAddebito: ((_b = (_a = state.form.rapportoSelezionato) === null || _a === void 0 ? void 0 : _a.rapporto) === null || _b === void 0 ? void 0 : _b.id)
            ? state.form.rapportoSelezionato.rapporto.id
            : 0,
        dataPagamento: new Date(state.form.dataPagamento),
        esecutore: state.form.esecutore,
        cap: state.form.cap,
        numeroBollettino: state.form.numeroBollettino,
        indirizzo: state.form.indirizzo,
        tipoDocumento: state.form.tipoDocumento,
        idBozzaCollegata: state.currentBozza.id
    };
});
exports.formValidForBozzaBollettinoPostale = (0, reselect_1.createSelector)(bollettinoPostaleReducer, (state) => {
    let formValid = false;
    if (!state.form.idUtenzaError ||
        !state.form.importoError ||
        !state.form.esecutoreError ||
        !state.form.intestatarioError ||
        !state.form.causaleError ||
        !state.form.indirizzoError ||
        !state.form.provinciaError ||
        !state.form.cittaError ||
        !state.form.capError) {
        formValid = true;
    }
    return formValid;
});
exports.disableSalvaBozzaBollettinoPostalePremarcato = (0, reselect_1.createSelector)(bollettinoPostaleReducer, (state) => {
    let isBozzaAccepted = !state.form.bozzaSalvata &&
        (state.form.rapportoSelezionato && state.form.rapportoSelezionato.rapporto && state.form.rapportoSelezionato.rapporto.id) &&
        (state.form.numeroBollettino && !state.form.numeroBollettinoError) &&
        (state.form.tipoDocumento && !state.form.tipoDocumentoError) &&
        (!!state.form.idUtenza ||
            !!state.form.importo ||
            !!state.form.causale ||
            !!state.form.esecutore ||
            !!state.form.indirizzo ||
            !!state.form.provincia ||
            !!state.form.citta ||
            !!state.form.cap);
    isBozzaAccepted = isBozzaAccepted && !!state.form.idUtenza ? !state.form.idUtenzaError : isBozzaAccepted;
    isBozzaAccepted = isBozzaAccepted && !!state.form.importo ? !state.form.importoError : isBozzaAccepted;
    isBozzaAccepted = isBozzaAccepted && !!state.form.causale ? !state.form.causaleError : isBozzaAccepted;
    isBozzaAccepted = isBozzaAccepted && !!state.form.esecutore ? !state.form.esecutoreError : isBozzaAccepted;
    isBozzaAccepted = isBozzaAccepted && !!state.form.indirizzo ? !state.form.indirizzoError : isBozzaAccepted;
    isBozzaAccepted = isBozzaAccepted && !!state.form.provincia ? !state.form.provinciaError : isBozzaAccepted;
    isBozzaAccepted = isBozzaAccepted && !!state.form.citta ? !state.form.cittaError : isBozzaAccepted;
    isBozzaAccepted = isBozzaAccepted && !!state.form.cap ? !state.form.capError : isBozzaAccepted;
    return !isBozzaAccepted;
});
exports.tipoSelectedBollettinoPostale = (0, reselect_1.createSelector)(bollettinoPostaleReducer, (state) => {
    return state.tipoBollettinoPostaleSelected;
});
exports.formValid1ClickBollettinoPostaleBianco = (0, reselect_1.createSelector)(bollettinoPostaleReducer, (state) => {
    let formValid = false;
    if (!state.form.idUtenzaError &&
        !state.form.importoError &&
        !state.form.esecutoreError &&
        !state.form.indirizzoError &&
        !state.form.provinciaError &&
        !state.form.cittaError &&
        !state.form.capError &&
        !state.form.tipoDocumentoError &&
        !state.form.intestatarioError &&
        !state.form.causaleError) {
        formValid = true;
    }
    return formValid;
});
exports.checkUpdateBozzaBollettinoPostale = (0, reselect_1.createSelector)(bollettinoPostaleReducer, (state) => {
    var _a;
    return !!((_a = state.currentBozza) === null || _a === void 0 ? void 0 : _a.id);
});
