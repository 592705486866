"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContattoSudtirolPasDtoToJSON = exports.ContattoSudtirolPasDtoFromJSONTyped = exports.ContattoSudtirolPasDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function ContattoSudtirolPasDtoFromJSON(json) {
    return ContattoSudtirolPasDtoFromJSONTyped(json, false);
}
exports.ContattoSudtirolPasDtoFromJSON = ContattoSudtirolPasDtoFromJSON;
function ContattoSudtirolPasDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'seq': !runtime_1.exists(json, 'seq') ? undefined : json['seq'],
        'pan': !runtime_1.exists(json, 'pan') ? undefined : json['pan'],
        'isDefault': !runtime_1.exists(json, 'isDefault') ? undefined : json['isDefault'],
        'etichetta': !runtime_1.exists(json, 'etichetta') ? undefined : json['etichetta'],
        'dispensaCredenziale': !runtime_1.exists(json, 'dispensaCredenziale') ? undefined : json['dispensaCredenziale'],
    };
}
exports.ContattoSudtirolPasDtoFromJSONTyped = ContattoSudtirolPasDtoFromJSONTyped;
function ContattoSudtirolPasDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'seq': value.seq,
        'pan': value.pan,
        'isDefault': value.isDefault,
        'etichetta': value.etichetta,
        'dispensaCredenziale': value.dispensaCredenziale,
    };
}
exports.ContattoSudtirolPasDtoToJSON = ContattoSudtirolPasDtoToJSON;
