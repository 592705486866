"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.datiGraficoTitoloQuotato = exports.dettaglioTitoloQuotato = exports.elencoTitoliQuotati = exports.formTitoliQuotati = exports.mercatiTitoliQuotati = void 0;
const reselect_1 = require("reselect");
const titoliQuotati_types_1 = require("./titoliQuotati.types");
const moment = require("moment");
const helpers_1 = require("../helpers");
const titoliQuotatiReducer = (state) => state;
exports.mercatiTitoliQuotati = (0, reselect_1.createSelector)(titoliQuotatiReducer, (state) => {
    return state.mercati;
});
exports.formTitoliQuotati = (0, reselect_1.createSelector)(titoliQuotatiReducer, (state) => {
    return state.form;
});
exports.elencoTitoliQuotati = (0, reselect_1.createSelector)(titoliQuotatiReducer, (state) => {
    return state.titoli;
});
exports.dettaglioTitoloQuotato = (0, reselect_1.createSelector)(titoliQuotatiReducer, (state) => {
    return state.titoliQuotatiDettaglio;
});
exports.datiGraficoTitoloQuotato = (0, reselect_1.createSelector)(titoliQuotatiReducer, (state) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    const now = state.titoliQuotatiDettaglio.aggiornamento || (new Date());
    const giorno = (_b = (_a = state.titoliQuotatiDettaglio.datiIntradayTitolo) === null || _a === void 0 ? void 0 : _a.dati) === null || _b === void 0 ? void 0 : _b.map(d => ({ ...d, x: d.data ? moment(d.data).format("x") : "-" }));
    const ora1 = giorno === null || giorno === void 0 ? void 0 : giorno.filter(d => (d.data || now) <= moment(now).subtract(1, "hours").toDate());
    const ora4 = giorno === null || giorno === void 0 ? void 0 : giorno.filter(d => (d.data || now) <= moment(now).subtract(4, "hours").toDate());
    const titoloMercatoIta = (0, helpers_1.validationIsinMercatoItaliano)((_c = state.titoliQuotatiDettaglio.schedaTitolo.codiceIsin) !== null && _c !== void 0 ? _c : '');
    return {
        ...state.titoliQuotatiDettaglio,
        datiGiornoPresenti: giorno ? !!(giorno === null || giorno === void 0 ? void 0 : giorno.length) : undefined,
        datiOraPresenti: ora1 ? !!(ora1 === null || ora1 === void 0 ? void 0 : ora1.length) : undefined,
        dati4OrePresenti: ora4 ? !!(ora4 === null || ora4 === void 0 ? void 0 : ora4.length) : undefined,
        graficoCorrente: titoloMercatoIta ? (state.titoliQuotatiDettaglio.tipoGrafico === titoliQuotati_types_1.TipoGraficoTitoliQuotati.Ore1 ? ora1 :
            state.titoliQuotatiDettaglio.tipoGrafico === titoliQuotati_types_1.TipoGraficoTitoliQuotati.Ore4 ? ora4 :
                state.titoliQuotatiDettaglio.tipoGrafico === titoliQuotati_types_1.TipoGraficoTitoliQuotati.Giorni1 ? giorno :
                    (_j = (_h = (_g = (state.titoliQuotatiDettaglio.tipoGrafico === titoliQuotati_types_1.TipoGraficoTitoliQuotati.Settimana1 ? (_d = state.titoliQuotatiDettaglio.datiSettimana) === null || _d === void 0 ? void 0 : _d.dati :
                        state.titoliQuotatiDettaglio.tipoGrafico === titoliQuotati_types_1.TipoGraficoTitoliQuotati.Mese1 ? (_e = state.titoliQuotatiDettaglio.datiMese) === null || _e === void 0 ? void 0 : _e.dati :
                            (_f = state.titoliQuotatiDettaglio.datiAnno) === null || _f === void 0 ? void 0 : _f.dati)) === null || _g === void 0 ? void 0 : _g.map(d => ({ data: d.data, valore: d.prezzoRif || d.prezzoUff || d.ultimoPrezzo || d.apertura || d.chiusura }))) === null || _h === void 0 ? void 0 : _h.sort((a, b) => (a.data && b.data && a.data < b.data ? -1 : 1))) === null || _j === void 0 ? void 0 : _j.map(d => ({ ...d, x: d.data ? moment(d.data).format("x") : "-" }))) : (_q = (_p = (_o = (state.titoliQuotatiDettaglio.tipoGrafico === titoliQuotati_types_1.TipoGraficoTitoliQuotatiEsteri.Settimana1 ? (_k = state.titoliQuotatiDettaglio.datiSettimana) === null || _k === void 0 ? void 0 : _k.dati :
            state.titoliQuotatiDettaglio.tipoGrafico === titoliQuotati_types_1.TipoGraficoTitoliQuotatiEsteri.Mese1 ? (_l = state.titoliQuotatiDettaglio.datiMese) === null || _l === void 0 ? void 0 : _l.dati :
                (_m = state.titoliQuotatiDettaglio.datiAnno) === null || _m === void 0 ? void 0 : _m.dati)) === null || _o === void 0 ? void 0 : _o.map(d => ({ data: d.data, valore: d.prezzoRif || d.prezzoUff || d.ultimoPrezzo || d.apertura || d.chiusura }))) === null || _p === void 0 ? void 0 : _p.sort((a, b) => (a.data && b.data && a.data < b.data ? -1 : 1))) === null || _q === void 0 ? void 0 : _q.map(d => ({ ...d, x: d.data ? moment(d.data).format("x") : "-" }))
    };
});
