"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EReduxSessioniDiFirmaActionTypes = void 0;
var EReduxSessioniDiFirmaActionTypes;
(function (EReduxSessioniDiFirmaActionTypes) {
    EReduxSessioniDiFirmaActionTypes["RECUPERA_SESSIONI_DI_FIRMA_START"] = "RECUPERA_SESSIONI_DI_FIRMA_START";
    EReduxSessioniDiFirmaActionTypes["RECUPERA_SESSIONI_DI_FIRMA_SUCCESS"] = "RECUPERA_SESSIONI_DI_FIRMA_SUCCESS";
    EReduxSessioniDiFirmaActionTypes["RECUPERA_SESSIONI_DI_FIRMA_ERROR"] = "RECUPERA_SESSIONI_DI_FIRMA_ERROR";
    EReduxSessioniDiFirmaActionTypes["SESSIONI_DI_FIRMA_GET_URL_START"] = "SESSIONI_DI_FIRMA_ERROR_GET_URL";
    EReduxSessioniDiFirmaActionTypes["SESSIONI_DI_FIRMA_GET_URL_SUCCESS"] = "SESSIONI_DI_FIRMA_GET_URL_SUCCESS";
    EReduxSessioniDiFirmaActionTypes["SESSIONI_DI_FIRMA_SET_SHOW_MODAL"] = "SESSIONI_DI_FIRMA_SET_SHOW_MODAL";
    EReduxSessioniDiFirmaActionTypes["SESSIONI_DI_FIRMA_SET_JOB"] = "SESSIONI_DI_FIRMA_SET_JOB";
    EReduxSessioniDiFirmaActionTypes["SESSIONI_DI_FIRMA_RESET_SELEZIONATO"] = "SESSIONI_DI_FIRMA_RESET_SELEZIONATO";
    EReduxSessioniDiFirmaActionTypes["SESSIONI_DI_FIRMA_SET_PUSH_URL"] = "SESSIONI_DI_FIRMA_SET_PUSH_URL";
    EReduxSessioniDiFirmaActionTypes["SESSIONI_DI_FIRMA_RESET_PUSH_URL"] = "SESSIONI_DI_FIRMA_RESET_PUSH_URL";
    EReduxSessioniDiFirmaActionTypes["SESSIONI_DI_FIRMA_RESET_URL_JOB"] = "SESSIONI_DI_FIRMA_RESET_URL_JOB";
})(EReduxSessioniDiFirmaActionTypes = exports.EReduxSessioniDiFirmaActionTypes || (exports.EReduxSessioniDiFirmaActionTypes = {}));
