"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModelloF24RevocaRequestInToJSON = exports.ModelloF24RevocaRequestInFromJSONTyped = exports.ModelloF24RevocaRequestInFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ModelloF24RevocaRequestInFromJSON(json) {
    return ModelloF24RevocaRequestInFromJSONTyped(json, false);
}
exports.ModelloF24RevocaRequestInFromJSON = ModelloF24RevocaRequestInFromJSON;
function ModelloF24RevocaRequestInFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'credenziale': !runtime_1.exists(json, 'credenziale') ? undefined : _1.CredenzialeDispositivaDtoFromJSON(json['credenziale']),
        'annoInserimento': !runtime_1.exists(json, 'annoInserimento') ? undefined : json['annoInserimento'],
        'progressivoInserimento': !runtime_1.exists(json, 'progressivoInserimento') ? undefined : json['progressivoInserimento'],
        'tipologia': !runtime_1.exists(json, 'tipologia') ? undefined : json['tipologia'],
        'codiceRapporto': !runtime_1.exists(json, 'codiceRapporto') ? undefined : json['codiceRapporto'],
    };
}
exports.ModelloF24RevocaRequestInFromJSONTyped = ModelloF24RevocaRequestInFromJSONTyped;
function ModelloF24RevocaRequestInToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'credenziale': _1.CredenzialeDispositivaDtoToJSON(value.credenziale),
        'annoInserimento': value.annoInserimento,
        'progressivoInserimento': value.progressivoInserimento,
        'tipologia': value.tipologia,
        'codiceRapporto': value.codiceRapporto,
    };
}
exports.ModelloF24RevocaRequestInToJSON = ModelloF24RevocaRequestInToJSON;
