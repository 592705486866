"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TitoliScadenzaDtoToJSON = exports.TitoliScadenzaDtoFromJSONTyped = exports.TitoliScadenzaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function TitoliScadenzaDtoFromJSON(json) {
    return TitoliScadenzaDtoFromJSONTyped(json, false);
}
exports.TitoliScadenzaDtoFromJSON = TitoliScadenzaDtoFromJSON;
function TitoliScadenzaDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'titoli': !runtime_1.exists(json, 'titoli') ? undefined : (json['titoli'].map(_1.TitoloScadenzaDtoFromJSON)),
        'totaleCVT': !runtime_1.exists(json, 'totaleCVT') ? undefined : json['totaleCVT'],
        'totaleTitoli': !runtime_1.exists(json, 'totaleTitoli') ? undefined : json['totaleTitoli'],
    };
}
exports.TitoliScadenzaDtoFromJSONTyped = TitoliScadenzaDtoFromJSONTyped;
function TitoliScadenzaDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'titoli': value.titoli === undefined ? undefined : (value.titoli.map(_1.TitoloScadenzaDtoToJSON)),
        'totaleCVT': value.totaleCVT,
        'totaleTitoli': value.totaleTitoli,
    };
}
exports.TitoliScadenzaDtoToJSON = TitoliScadenzaDtoToJSON;
