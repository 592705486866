"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cercaTitoliReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const helpers_1 = require("../helpers");
const initialState = {
    titoli: (0, api_rest_1.DatiTitoliConMercatoDtoFromJSON)({}),
    parametri: (0, api_rest_1.ParametriFinanzaDtoFromJSON)({}),
    form: {
        showPreferitoModalError: false,
        accordionFiltriOpened: true,
        onlyPreferred: false,
        mercato: '',
        isin: '',
        descrizione: '',
        tipo: 0,
        errorIsin: '',
        titoloSelezionato: (0, api_rest_1.DatiTitoloConMercatoDtoFromJSON)({}),
        offset: 0,
        descrizioneError: ''
    }
};
const cercaTitoliReducer = (state = initialState, action) => {
    switch (action.type) {
        case __1.EReduxActionTypes.CERCA_TITOLI_GET_FORM_PARAMETERS_SUCCESS:
            return {
                ...initialState,
                parametri: action.payload
            };
        case __1.EReduxActionTypes.CERCA_TITOLI_GET_TITOLI_SUCCESS:
            return {
                ...state,
                titoli: !action.offset
                    ? action.payload
                    : {
                        ...state.titoli,
                        datiTitoli: (state.titoli.datiTitoli || []).concat(action.payload.datiTitoli || [])
                    }
            };
        case __1.EReduxActionTypes.SET_CERCA_TITOLI_ISIN:
            return {
                ...state,
                form: {
                    ...state.form,
                    isin: action.payload,
                    errorIsin: (0, helpers_1.validationIsin)(action.payload, 3)
                }
            };
        case __1.EReduxActionTypes.SET_CERCA_TITOLI_MERCATO:
            return {
                ...state,
                form: {
                    ...state.form,
                    mercato: action.payload
                }
            };
        case __1.EReduxActionTypes.SET_CERCA_TITOLI_DESCRIZIONE:
            return {
                ...state,
                form: {
                    ...state.form,
                    descrizione: action.payload,
                    descrizioneError: (0, helpers_1.validationNumeroCaratteriDescrizioneCercaTitoli)(action.payload, 20)
                }
            };
        case __1.EReduxActionTypes.SET_CERCA_TITOLI_TIPO:
            return {
                ...state,
                form: {
                    ...state.form,
                    tipo: action.payload
                }
            };
        case __1.EReduxActionTypes.CERCA_TITOLI_RESET_FORM:
            return {
                ...state,
                form: {
                    ...state.form,
                    isin: initialState.form.isin,
                    accordionFiltriOpened: initialState.form.accordionFiltriOpened,
                    onlyPreferred: initialState.form.onlyPreferred,
                    mercato: initialState.form.mercato,
                    descrizione: initialState.form.descrizione,
                    tipo: initialState.form.tipo,
                    offset: initialState.form.offset
                }
            };
        case __1.EReduxActionTypes.CERCA_TITOLI_SET_ACCORDION_FILTRI_OPENED:
            return {
                ...state,
                form: {
                    ...state.form,
                    accordionFiltriOpened: action.payload
                }
            };
        case __1.EReduxActionTypes.CERCA_TITOLI_AGGIUNGI_PREFERITO_SUCCESS:
            return {
                ...state,
                titoli: action.payload
            };
        case __1.EReduxActionTypes.CERCA_TITOLI_IS_PREFERRED_SUCCESS:
            return {
                ...state,
                form: {
                    ...state.form,
                    isPreferred: action.payload
                }
            };
        case __1.EReduxActionTypes.CERCA_TITOLI_SET_RAPPORTO_SELEZIONATO:
            return {
                ...state,
                form: {
                    ...state.form,
                    rapportoSelezionato: action.payload
                }
            };
        case __1.EReduxActionTypes.CERCA_TITOLI_SET_ONLY_PREFERRED:
            return {
                ...state,
                form: {
                    ...state.form,
                    onlyPreferred: action.payload
                }
            };
        case __1.EReduxActionTypes.CERCA_TITOLI_SET_SHOW_PREFERITO_MODAL:
            return {
                ...state,
                form: {
                    ...state.form,
                    showPreferitoModalError: action.payload
                }
            };
        case __1.EReduxActionTypes.CERCA_TITOLI_SET_TITOLO_SELEZIONATO:
            return {
                ...state,
                form: {
                    ...state.form,
                    titoloSelezionato: action.payload
                }
            };
        case __1.EReduxActionTypes.CERCA_TITOLI_SET_OFFSET:
            return {
                ...state,
                form: {
                    ...state.form,
                    offset: action.payload
                }
            };
        case __1.EReduxActionTypes.RESET_ALL: // action invocata dalla logout
            return {
                ...initialState
            };
        default:
            return state;
    }
};
exports.cercaTitoliReducer = cercaTitoliReducer;
