"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildPCT = exports.formPCTValid = exports.esitoPCT = exports.infoDispositivaPCT = exports.riepilogoPCT = exports.listiniPCT = exports.formPCT = exports.parametriPCT = void 0;
const reselect_1 = require("reselect");
const pctReducer = (state) => state;
exports.parametriPCT = (0, reselect_1.createSelector)(pctReducer, (state) => {
    return state.parametri;
});
exports.formPCT = (0, reselect_1.createSelector)(pctReducer, (state) => {
    return state.form;
});
exports.listiniPCT = (0, reselect_1.createSelector)(pctReducer, (state) => {
    return state.listini;
});
exports.riepilogoPCT = (0, reselect_1.createSelector)(pctReducer, (state) => {
    return state.riepilogo;
});
exports.infoDispositivaPCT = (0, reselect_1.createSelector)(pctReducer, (state) => {
    return state.infoDispositiva;
});
exports.esitoPCT = (0, reselect_1.createSelector)(pctReducer, (state) => {
    return state.esito;
});
exports.formPCTValid = (0, reselect_1.createSelector)(pctReducer, (state) => {
    let formValid = false;
    if (!state.form.importoError) {
        formValid = true;
    }
    return formValid;
});
exports.buildPCT = (0, reselect_1.createSelector)(pctReducer, (state) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43, _44, _45, _46, _47, _48, _49, _50, _51, _52, _53, _54, _55, _56, _57, _58, _59, _60, _61, _62, _63, _64, _65, _66, _67, _68, _69, _70, _71, _72, _73, _74, _75;
    return {
        codiceRapporto: (_a = state.form.rapportoSelezionato.rapporto) === null || _a === void 0 ? void 0 : _a.id,
        codiceTitolo: (_d = (_c = (_b = state.riepilogo) === null || _b === void 0 ? void 0 : _b.pct) === null || _c === void 0 ? void 0 : _c.codiceTitolo) !== null && _d !== void 0 ? _d : state.form.listinoSelezionato.codiceTitolo,
        descrizioneTitolo: (_g = (_f = (_e = state.riepilogo) === null || _e === void 0 ? void 0 : _e.pct) === null || _f === void 0 ? void 0 : _f.descrizioneTitolo) !== null && _g !== void 0 ? _g : state.form.listinoSelezionato.descrizioneTitolo,
        dataValutaPronti: (_k = (_j = (_h = state.riepilogo) === null || _h === void 0 ? void 0 : _h.pct) === null || _j === void 0 ? void 0 : _j.dataValutaPronti) !== null && _k !== void 0 ? _k : state.form.listinoSelezionato.dataValutaPronti,
        dataValutaTermine: (_o = (_m = (_l = state.riepilogo) === null || _l === void 0 ? void 0 : _l.pct) === null || _m === void 0 ? void 0 : _m.dataValutaTermine) !== null && _o !== void 0 ? _o : state.form.listinoSelezionato.dataValutaTermine,
        giorniDurata: (_u = (_r = (_q = (_p = state.riepilogo) === null || _p === void 0 ? void 0 : _p.pct) === null || _q === void 0 ? void 0 : _q.giorniDurata) !== null && _r !== void 0 ? _r : (_t = (_s = state.riepilogo) === null || _s === void 0 ? void 0 : _s.pct) === null || _t === void 0 ? void 0 : _t.giorniDurata) !== null && _u !== void 0 ? _u : state.form.listinoSelezionato.giorniDurata,
        importoMin: (_x = (_w = (_v = state.riepilogo) === null || _v === void 0 ? void 0 : _v.pct) === null || _w === void 0 ? void 0 : _w.importoMin) !== null && _x !== void 0 ? _x : state.form.listinoSelezionato.importoMin,
        importoMax: (_0 = (_z = (_y = state.riepilogo) === null || _y === void 0 ? void 0 : _y.pct) === null || _z === void 0 ? void 0 : _z.importoMax) !== null && _0 !== void 0 ? _0 : state.form.listinoSelezionato.importoMax,
        rendimento: (_3 = (_2 = (_1 = state.riepilogo) === null || _1 === void 0 ? void 0 : _1.pct) === null || _2 === void 0 ? void 0 : _2.rendimento) !== null && _3 !== void 0 ? _3 : state.form.listinoSelezionato.rendimento,
        divisa: (_6 = (_5 = (_4 = state.riepilogo) === null || _4 === void 0 ? void 0 : _4.pct) === null || _5 === void 0 ? void 0 : _5.rendimento) !== null && _6 !== void 0 ? _6 : state.form.listinoSelezionato.divisa,
        chiaveRipartenza: (_9 = (_8 = (_7 = state.riepilogo) === null || _7 === void 0 ? void 0 : _7.pct) === null || _8 === void 0 ? void 0 : _8.chiaveRipartenza) !== null && _9 !== void 0 ? _9 : state.form.listinoSelezionato.chiaveRipartenza,
        importo: (_12 = (_11 = (_10 = state.riepilogo) === null || _10 === void 0 ? void 0 : _10.pct) === null || _11 === void 0 ? void 0 : _11.importo) !== null && _12 !== void 0 ? _12 : state.form.importo,
        quantitaNominale: (_15 = (_14 = (_13 = state.riepilogo) === null || _13 === void 0 ? void 0 : _13.pct) === null || _14 === void 0 ? void 0 : _14.quantitaNominale) !== null && _15 !== void 0 ? _15 : state.form.listinoSelezionato.quantitaNominale,
        tassoLordo: (_18 = (_17 = (_16 = state.riepilogo) === null || _16 === void 0 ? void 0 : _16.pct) === null || _17 === void 0 ? void 0 : _17.tassoLordo) !== null && _18 !== void 0 ? _18 : state.form.listinoSelezionato.tassoLordo,
        tassoNetto: (_21 = (_20 = (_19 = state.riepilogo) === null || _19 === void 0 ? void 0 : _19.pct) === null || _20 === void 0 ? void 0 : _20.tassoNetto) !== null && _21 !== void 0 ? _21 : state.form.listinoSelezionato.tassoNetto,
        ritenutePT: (_24 = (_23 = (_22 = state.riepilogo) === null || _22 === void 0 ? void 0 : _22.pct) === null || _23 === void 0 ? void 0 : _23.ritenutePT) !== null && _24 !== void 0 ? _24 : state.form.listinoSelezionato.ritenutePT,
        utile: (_27 = (_26 = (_25 = state.riepilogo) === null || _25 === void 0 ? void 0 : _25.pct) === null || _26 === void 0 ? void 0 : _26.utile) !== null && _27 !== void 0 ? _27 : state.form.listinoSelezionato.utile,
        valutaPronti: (_30 = (_29 = (_28 = state.riepilogo) === null || _28 === void 0 ? void 0 : _28.pct) === null || _29 === void 0 ? void 0 : _29.valutaPronti) !== null && _30 !== void 0 ? _30 : state.form.listinoSelezionato.valutaPronti,
        valutaTermine: (_33 = (_32 = (_31 = state.riepilogo) === null || _31 === void 0 ? void 0 : _31.pct) === null || _32 === void 0 ? void 0 : _32.valutaTermine) !== null && _33 !== void 0 ? _33 : state.form.listinoSelezionato.valutaTermine,
        prezzoUnitarioPronti: (_36 = (_35 = (_34 = state.riepilogo) === null || _34 === void 0 ? void 0 : _34.pct) === null || _35 === void 0 ? void 0 : _35.prezzoUnitarioPronti) !== null && _36 !== void 0 ? _36 : state.form.listinoSelezionato.prezzoUnitarioPronti,
        prezzoUnitarioTermine: (_39 = (_38 = (_37 = state.riepilogo) === null || _37 === void 0 ? void 0 : _37.pct) === null || _38 === void 0 ? void 0 : _38.prezzoUnitarioTermine) !== null && _39 !== void 0 ? _39 : state.form.listinoSelezionato.prezzoUnitarioTermine,
        speseEffettivePronti: (_42 = (_41 = (_40 = state.riepilogo) === null || _40 === void 0 ? void 0 : _40.pct) === null || _41 === void 0 ? void 0 : _41.speseEffettivePronti) !== null && _42 !== void 0 ? _42 : state.form.listinoSelezionato.speseEffettivePronti,
        speseEffettiveTermine: (_45 = (_44 = (_43 = state.riepilogo) === null || _43 === void 0 ? void 0 : _43.pct) === null || _44 === void 0 ? void 0 : _44.speseEffettiveTermine) !== null && _45 !== void 0 ? _45 : state.form.listinoSelezionato.speseEffettiveTermine,
        totalePronti: (_48 = (_47 = (_46 = state.riepilogo) === null || _46 === void 0 ? void 0 : _46.pct) === null || _47 === void 0 ? void 0 : _47.totalePronti) !== null && _48 !== void 0 ? _48 : state.form.listinoSelezionato.totalePronti,
        totaleTermine: (_51 = (_50 = (_49 = state.riepilogo) === null || _49 === void 0 ? void 0 : _49.pct) === null || _50 === void 0 ? void 0 : _50.totaleTermine) !== null && _51 !== void 0 ? _51 : state.form.listinoSelezionato.totaleTermine,
        pgEsito: (_54 = (_53 = (_52 = state.riepilogo) === null || _52 === void 0 ? void 0 : _52.pct) === null || _53 === void 0 ? void 0 : _53.pgEsito) !== null && _54 !== void 0 ? _54 : state.form.listinoSelezionato.pgEsito,
        abilitaPulsanteCosti: (_57 = (_56 = (_55 = state.riepilogo) === null || _55 === void 0 ? void 0 : _55.pct) === null || _56 === void 0 ? void 0 : _56.abilitaPulsanteCosti) !== null && _57 !== void 0 ? _57 : state.form.listinoSelezionato.abilitaPulsanteCosti,
        abilitaPdfKiid: (_60 = (_59 = (_58 = state.riepilogo) === null || _58 === void 0 ? void 0 : _58.pct) === null || _59 === void 0 ? void 0 : _59.abilitaPdfKiid) !== null && _60 !== void 0 ? _60 : state.form.listinoSelezionato.abilitaPdfKiid,
        tabellaCosti: (_63 = (_62 = (_61 = state.riepilogo) === null || _61 === void 0 ? void 0 : _61.pct) === null || _62 === void 0 ? void 0 : _62.tabellaCosti) !== null && _63 !== void 0 ? _63 : state.form.listinoSelezionato.tabellaCosti,
        numeroDossier: (_66 = (_65 = (_64 = state.riepilogo) === null || _64 === void 0 ? void 0 : _64.pct) === null || _65 === void 0 ? void 0 : _65.numeroDossier) !== null && _66 !== void 0 ? _66 : state.form.listinoSelezionato.numeroDossier,
        intestatarioDossier: (_69 = (_68 = (_67 = state.riepilogo) === null || _67 === void 0 ? void 0 : _67.pct) === null || _68 === void 0 ? void 0 : _68.intestatarioDossier) !== null && _69 !== void 0 ? _69 : state.form.listinoSelezionato.intestatarioDossier,
        dataOperazione: (_72 = (_71 = (_70 = state.riepilogo) === null || _70 === void 0 ? void 0 : _70.pct) === null || _71 === void 0 ? void 0 : _71.dataOperazione) !== null && _72 !== void 0 ? _72 : state.form.listinoSelezionato.dataOperazione,
        importoStimato: (_75 = (_74 = (_73 = state.riepilogo) === null || _73 === void 0 ? void 0 : _73.pct) === null || _74 === void 0 ? void 0 : _74.importoStimato) !== null && _75 !== void 0 ? _75 : state.form.listinoSelezionato.importoStimato
    };
});
