"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetNews = exports.resetFormNews = exports.setNewsSelezionato = exports.setNewsDenominazione = exports.setNewsPeriodo = exports.getDettaglioNews = exports.getElencoNews = exports.getParametriNews = void 0;
const general_actions_1 = require("../general/general.actions");
const api_rest_1 = require("@sparkasse/api-rest");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
const news_types_1 = require("./news.types");
// Get Parametri Cerca Titoli - Start
function getParametriNewsStart() {
    return {
        type: news_types_1.EReduxNewsActionTypes.NEWS_GET_PARAMETRI_START
    };
}
function getParametriNewsSuccess(payload) {
    return {
        type: news_types_1.EReduxNewsActionTypes.NEWS_GET_PARAMETRI_SUCCESS,
        payload
    };
}
function generalError(value) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value));
    };
}
const getParametriNews = () => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getParametriNewsStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametriQuotazioni().then((response) => {
                dispatch(getParametriNewsSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getParametriNews = getParametriNews;
// Get Parametri news - Fine
// Get elenco news - Start
function getElencoNewsStart() {
    return {
        type: news_types_1.EReduxNewsActionTypes.NEWS_GET_NEWS_START
    };
}
function getElencoNewsSuccess(payload, offset) {
    return {
        type: news_types_1.EReduxNewsActionTypes.NEWS_GET_NEWS_SUCCESS,
        payload,
        offset
    };
}
const getElencoNews = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getElencoNewsStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        const request = {};
        if (extraArguments.platform === 'mobile') {
            request.offset = (getState().news.form.offset);
        }
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getElencoNews(request).then((response) => {
                dispatch(getElencoNewsSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getElencoNews = getElencoNews;
// Get elenco news - Fine
// Get dettaglio news - Start
function getDettaglioNewsStart() {
    return {
        type: news_types_1.EReduxNewsActionTypes.NEWS_GET_DETTAGLIO_NEWS_START
    };
}
function getDettaglioNewsSuccess(payload) {
    return {
        type: news_types_1.EReduxNewsActionTypes.NEWS_GET_DETTAGLIO_NEWS_SUCCESS,
        payload,
    };
}
const getDettaglioNews = () => async (dispatch, getState, extraArguments) => {
    var _a;
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getDettaglioNewsStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        const newsState = getState().news;
        const request = {
            idNews: (_a = newsState.form.newsSelezionato.idNews) !== null && _a !== void 0 ? _a : '',
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getDettaglioNews(request).then((response) => {
                dispatch(getDettaglioNewsSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getDettaglioNews = getDettaglioNews;
// Get dettaglio news - Fine
/* set form values - START */
function setNewsPeriodo(txt) {
    return {
        type: news_types_1.EReduxNewsActionTypes.SET_NEWS_PERIODO,
        payload: txt
    };
}
exports.setNewsPeriodo = setNewsPeriodo;
function setNewsDenominazione(txt) {
    return {
        type: news_types_1.EReduxNewsActionTypes.SET_NEWS_DENOMINAZIONE,
        payload: txt
    };
}
exports.setNewsDenominazione = setNewsDenominazione;
function setNewsSelezionato(titolo) {
    return {
        type: news_types_1.EReduxNewsActionTypes.NEWS_SET_NEWS_SELEZIONATO,
        payload: titolo
    };
}
exports.setNewsSelezionato = setNewsSelezionato;
// export function setRicercaOffset(offset: number): ITitoliSetOffset {
//   return {
//     type: EReduxActionTypes.CERCA_TITOLI_SET_OFFSET,
//     payload: offset
//   };
// }
/* set form values - Fine */
// Reset Form Titoli Quotati
function resetFormNews() {
    return {
        type: news_types_1.EReduxNewsActionTypes.NEWS_RESET_FORM
    };
}
exports.resetFormNews = resetFormNews;
function resetNews() {
    return {
        type: news_types_1.EReduxNewsActionTypes.NEWS_RESET
    };
}
exports.resetNews = resetNews;
// end Reset Form Titoli Quotati
