"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RipetitivoSospendiRequestInToJSON = exports.RipetitivoSospendiRequestInFromJSONTyped = exports.RipetitivoSospendiRequestInFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function RipetitivoSospendiRequestInFromJSON(json) {
    return RipetitivoSospendiRequestInFromJSONTyped(json, false);
}
exports.RipetitivoSospendiRequestInFromJSON = RipetitivoSospendiRequestInFromJSON;
function RipetitivoSospendiRequestInFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'credenziale': !runtime_1.exists(json, 'credenziale') ? undefined : _1.CredenzialeDispositivaDtoFromJSON(json['credenziale']),
        'codiceDisposizione': !runtime_1.exists(json, 'codiceDisposizione') ? undefined : json['codiceDisposizione'],
        'codiceRapporto': !runtime_1.exists(json, 'codiceRapporto') ? undefined : json['codiceRapporto'],
        'statoDisposizione': !runtime_1.exists(json, 'statoDisposizione') ? undefined : json['statoDisposizione'],
        'dataInizio': !runtime_1.exists(json, 'dataInizio') ? undefined : (new Date(json['dataInizio'])),
        'dataFine': !runtime_1.exists(json, 'dataFine') ? undefined : (new Date(json['dataFine'])),
    };
}
exports.RipetitivoSospendiRequestInFromJSONTyped = RipetitivoSospendiRequestInFromJSONTyped;
function RipetitivoSospendiRequestInToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'credenziale': _1.CredenzialeDispositivaDtoToJSON(value.credenziale),
        'codiceDisposizione': value.codiceDisposizione,
        'codiceRapporto': value.codiceRapporto,
        'statoDisposizione': value.statoDisposizione,
        'dataInizio': value.dataInizio === undefined ? undefined : (value.dataInizio.toISOString()),
        'dataFine': value.dataFine === undefined ? undefined : (value.dataFine.toISOString()),
    };
}
exports.RipetitivoSospendiRequestInToJSON = RipetitivoSospendiRequestInToJSON;
