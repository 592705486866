"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mavReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const helpers_1 = require("../helpers");
const mav_types_1 = require("./mav.types");
const moment = require("moment");
const initialState = {
    parametri: (0, api_rest_1.ParametriMavDtoFromJSON)({}),
    form: (0, mav_types_1.FormMavDtoFromJSONTyped)({}),
    esito: (0, api_rest_1.EsitoAutorizzazioneDisposizioneMavDtoFromJSON)({}),
    riepilogo: (0, api_rest_1.MavDtoFromJSON)({}),
    riepilogoRevoca: (0, api_rest_1.MavDtoFromJSON)({}),
    formRevoca: (0, mav_types_1.FormRevocaMavDtoFromJSON)({}),
    infoRevoca: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
    esitoRevoca: (0, api_rest_1.EsitoAutorizzazioneDisposizioneMavDtoFromJSON)({}),
    bozzeMav: (0, api_rest_1.MavsDtoFromJSON)({}),
    oneclick: (0, api_rest_1.OperazioniOneClickDtoFromJSON)({}),
    infoAutenticazioneMav: (0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)({}),
    currentBozza: (0, api_rest_1.MavDtoFromJSON)({})
};
let rapportoSelezionato;
let newBozze;
let dataEsec;
let dataNow;
const mavReducer = (state = initialState, action) => {
    var _a, _b;
    switch (action.type) {
        case __1.EReduxActionTypes.MAV_GET_PARAMETRI_SUCCESS:
            return {
                ...initialState,
                parametri: action.payload
            };
        case __1.EReduxActionTypes.MAV_CONTROLLO_SUCCESS:
            return {
                ...state,
                riepilogo: action.payload
            };
        case __1.EReduxActionTypes.MAV_AUTORIZZA_SUCCESS:
            if (action.payload.stato === 'OK') {
                return {
                    ...state,
                    //riepilogo: initialState.riepilogo,
                    esito: action.payload
                };
            }
            else {
                return {
                    ...state,
                    infoAutenticazioneMav: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoAutenticazioneMav, action.payload.informazioniAutenticazioneDispositiva),
                    esito: action.payload
                };
            }
        case __1.EReduxActionTypes.MAV_IMPORTO:
            return {
                ...state,
                form: {
                    ...state.form,
                    importo: action.payload,
                    importoDirty: true,
                    importoError: (0, helpers_1.validationField)(action.payload, 0),
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.MAV_CODICE_MAV:
            return {
                ...state,
                form: {
                    ...state.form,
                    codiceMAV: action.payload,
                    codiceMAVDirty: true,
                    codiceMAVError: (0, helpers_1.validationCodiceMav)(action.payload, true),
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.MAV_SET_RAPPORTO_SELEZIONATO:
            return {
                ...state,
                form: {
                    ...state.form,
                    rapportoSelezionato: action.payload,
                    bozzaSalvata: false
                }
            };
        case __1.EReduxActionTypes.MAV_FORM_SUBMITTED:
            return {
                ...state,
                form: {
                    ...state.form,
                    formSubmitted: action.payload
                }
            };
        case __1.EReduxActionTypes.MAV_STEP:
            return {
                ...state,
                form: {
                    ...state.form,
                    step: action.payload
                }
            };
        case __1.EReduxActionTypes.MAV_RESET:
            return {
                ...initialState
            };
        case __1.EReduxActionTypes.MAV_DATA_ESEC:
            return {
                ...state,
                form: {
                    ...state.form,
                    dataEsecuzione: action.payload,
                    dataEsecuzioneDirty: true,
                    dataEsecuzioneError: (0, helpers_1.validationFieldDate)(action.payload, [], true, '')
                }
            };
        case __1.EReduxActionTypes.MAV_SET_RIEPILOGO_REVOCA:
            return {
                ...state,
                formRevoca: initialState.formRevoca,
                riepilogoRevoca: action.payload
            };
        case __1.EReduxActionTypes.MAV_SET_PROVENIENZA:
            return {
                ...state,
                formRevoca: {
                    ...state.formRevoca,
                    provenienza: action.payload
                }
            };
        case __1.EReduxActionTypes.MAV_STEP_REVOCA:
            return {
                ...state,
                formRevoca: {
                    ...state.formRevoca,
                    step: action.payload
                }
            };
        case __1.EReduxActionTypes.MAV_GET_INFO_REVOCA_SUCCESS:
            return {
                ...state,
                infoRevoca: action.payload,
                esito: initialState.esito
            };
        case __1.EReduxActionTypes.MAV_AUTORIZZA_REVOCA_SUCCESS:
            if (action.payload.stato === 'OK') {
                return {
                    ...state,
                    esitoRevoca: action.payload
                };
            }
            else {
                return {
                    ...state,
                    parametri: initialState.parametri,
                    riepilogo: initialState.riepilogo,
                    esito: initialState.esito,
                    form: initialState.form,
                    riepilogoRevoca: initialState.riepilogoRevoca,
                    infoRevoca: (0, helpers_1.getInfoAutenticazioneDispositivaCommon)(state.infoRevoca, action.payload.informazioniAutenticazioneDispositiva),
                    esitoRevoca: action.payload
                };
            }
        case __1.EReduxActionTypes.MAV_SET_BOZZASALVATA:
            return {
                ...state,
                form: {
                    ...state.form,
                    bozzaSalvata: action.payload
                }
            };
        case __1.EReduxActionTypes.MAV_GET_BOZZE_SUCCESS:
            return {
                ...state,
                bozzeMav: action.payload
            };
        case __1.EReduxActionTypes.MAV_GET_BOZZA_SUCCESS:
            rapportoSelezionato = (0, helpers_1.findInArrayRapporti)(state.parametri && state.parametri.rapporti, action.payload.contoAddebito);
            dataEsec = ((_b = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.datiPagamento) === null || _b === void 0 ? void 0 : _b.dataScadenza) || new Date();
            dataNow = new Date();
            return {
                ...state,
                form: {
                    ...state.form,
                    importo: action.payload.importo,
                    importoDirty: false,
                    importoError: (0, helpers_1.validationField)(action.payload.importo, 0),
                    codiceMAV: action.payload.numeroBollettino,
                    codiceMAVDirty: false,
                    codiceMAVError: (0, helpers_1.validationCodiceMav)(action.payload.numeroBollettino || '', true),
                    rapportoSelezionato: rapportoSelezionato,
                    dataEsecuzione: moment(dataEsec > dataNow ? dataEsec : dataNow).format('YYYY-MM-DD'),
                    dataEsecuzioneDirty: false,
                    dataEsecuzioneError: '',
                },
                currentBozza: action.payload
            };
        case __1.EReduxActionTypes.MAV_GET_MAV_FROM_BOZZA:
            rapportoSelezionato = (0, helpers_1.findInArrayRapporti)(state.parametri && state.parametri.rapporti, action.payload.contoAddebito);
            const currDate = new Date().getTime();
            return {
                ...state,
                form: {
                    ...state.form,
                    importo: action.payload.importo,
                    importoDirty: false,
                    importoError: (0, helpers_1.validationField)(action.payload.importo, 0),
                    codiceMAV: action.payload.numeroBollettino,
                    codiceMAVDirty: false,
                    codiceMAVError: (0, helpers_1.validationCodiceMav)(action.payload.numeroBollettino || '', true),
                    rapportoSelezionato: rapportoSelezionato,
                    dataEsecuzione: moment(currDate).format('YYYY-MM-DD'),
                    dataEsecuzioneDirty: true,
                    dataEsecuzioneError: (0, helpers_1.validationFieldDate)(currDate, '', true, '')
                },
                currentBozza: action.noBozza ? initialState.currentBozza : action.payload
            };
        case __1.EReduxActionTypes.MAV_ADD_BOZZA_SUCCESS:
            return {
                ...state,
                bozzeMav: {
                    ...state.bozzeMav,
                    mavs: state.bozzeMav && state.bozzeMav.mavs
                        ? [...state.bozzeMav.mavs, action.payload]
                        : []
                },
                form: {
                    ...state.form,
                    bozzaSalvata: true
                },
                currentBozza: action.payload
            };
        case __1.EReduxActionTypes.MAV_DEL_BOZZA_SUCCESS:
            return {
                ...state,
                bozzeMav: {
                    ...state.bozzeMav,
                    mavs: state.bozzeMav && state.bozzeMav.mavs
                        ? state.bozzeMav.mavs.filter((val) => val.id !== action.payload)
                        : []
                },
                form: {
                    ...state.form,
                    bozzaSalvata: state.currentBozza.id === action.payload ? false : state.form.bozzaSalvata
                },
                currentBozza: state.currentBozza.id === action.payload ? initialState.currentBozza : state.currentBozza
            };
        case __1.EReduxActionTypes.MAV_UPDATE_BOZZA_SUCCESS:
            return {
                ...state,
                bozzeMav: {
                    ...state.bozzeMav,
                    mavs: state.bozzeMav && state.bozzeMav.mavs
                        ? state.bozzeMav.mavs.map((mav) => mav.id === action.payload.id ? action.payload : mav)
                        : []
                },
                form: {
                    ...state.form,
                    bozzaSalvata: true
                }
            };
        case __1.EReduxActionTypes.MAV_GET_ONECLICK_SUCCESS:
            return {
                ...state,
                oneclick: action.payload
            };
        case __1.EReduxActionTypes.MAV_GET_INFO_AUTENTICAZIONE_MAV_SUCCESS:
            return {
                ...state,
                infoAutenticazioneMav: action.payload,
                esito: initialState.esito
            };
        case __1.EReduxActionTypes.MAV_RESET_CURRENT_BOZZA: {
            return {
                ...state,
                currentBozza: initialState.currentBozza
            };
        }
        case __1.EReduxActionTypes.RESET_ALL: // action invocata dalla logout
            return {
                ...initialState
            };
        default:
            return state;
    }
};
exports.mavReducer = mavReducer;
exports.default = exports.mavReducer;
