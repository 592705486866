"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * API BFF Customer Web Collaboration
 * API BFF Customer Web Collaboration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventUpdateFilterRequestToJSON = exports.EventUpdateFilterRequestFromJSONTyped = exports.EventUpdateFilterRequestFromJSON = void 0;
const runtime_1 = require("../runtime");
function EventUpdateFilterRequestFromJSON(json) {
    return EventUpdateFilterRequestFromJSONTyped(json, false);
}
exports.EventUpdateFilterRequestFromJSON = EventUpdateFilterRequestFromJSON;
function EventUpdateFilterRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'end': !runtime_1.exists(json, 'end') ? undefined : json['end'],
        'note': !runtime_1.exists(json, 'note') ? undefined : json['note'],
        'start': !runtime_1.exists(json, 'start') ? undefined : json['start'],
    };
}
exports.EventUpdateFilterRequestFromJSONTyped = EventUpdateFilterRequestFromJSONTyped;
function EventUpdateFilterRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'end': value.end,
        'note': value.note,
        'start': value.start,
    };
}
exports.EventUpdateFilterRequestToJSON = EventUpdateFilterRequestToJSON;
