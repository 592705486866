"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetSessioniDiFirmaUrlJob = exports.resetSessioniDiFirmaPushUrl = exports.setSessioniDiFirmaPushUrl = exports.resetSessioniDiFirmaJobSelezionato = exports.setSessioniDiFirmaJobSelezionato = exports.setSessioniDiFirmaShowModal = exports.getSessioniDiFirmaURL = exports.recuperaSessioniDiFirma = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
const firma_digitale_selectors_1 = require("./firma-digitale.selectors");
function generalError(value) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value));
    };
}
// RECUPERA SESSIONI DI FIRMA
function recuperaSessioniDiFirmaStart() {
    return {
        type: __1.EReduxSessioniDiFirmaActionTypes.RECUPERA_SESSIONI_DI_FIRMA_START
    };
}
function recuperaSessioniDiFirmaSuccess(payload) {
    return {
        type: __1.EReduxSessioniDiFirmaActionTypes.RECUPERA_SESSIONI_DI_FIRMA_SUCCESS,
        payload
    };
}
const recuperaSessioniDiFirma = () => async (dispatch, getState, extraArguments) => {
    if (!!getState().firmaDigitale.pushUrl) {
        return;
    }
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(recuperaSessioniDiFirmaStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        const requestParameters = {
            isSessionComplete: false
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.FirmaDigitalePrivateControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).recuperaJobsUtente(requestParameters).then((response) => {
                dispatch(recuperaSessioniDiFirmaSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.recuperaSessioniDiFirma = recuperaSessioniDiFirma;
// RECUPERA URL
function getSessioniDiFirmaURLStart() {
    return {
        type: __1.EReduxSessioniDiFirmaActionTypes.SESSIONI_DI_FIRMA_GET_URL_START
    };
}
function getSessioniDiFirmaURLSuccess(payload) {
    return {
        type: __1.EReduxSessioniDiFirmaActionTypes.SESSIONI_DI_FIRMA_GET_URL_SUCCESS,
        payload
    };
}
const getSessioniDiFirmaURL = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getSessioniDiFirmaURLStart());
    const state = getState().firmaDigitale;
    const jobSelezionato = (0, firma_digitale_selectors_1.jobSelezionatoSessioniDiFirma)(state);
    if (extraArguments.mock) {
    }
    else {
        const requestParameters = {
            jobId: (jobSelezionato === null || jobSelezionato === void 0 ? void 0 : jobSelezionato.jobId) || ''
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.FirmaDigitalePrivateControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).generateJobUrl(requestParameters).then((response) => {
                dispatch(getSessioniDiFirmaURLSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getSessioniDiFirmaURL = getSessioniDiFirmaURL;
// SETTING VARI
function setSessioniDiFirmaShowModal(show) {
    return {
        type: __1.EReduxSessioniDiFirmaActionTypes.SESSIONI_DI_FIRMA_SET_SHOW_MODAL,
        payload: show
    };
}
exports.setSessioniDiFirmaShowModal = setSessioniDiFirmaShowModal;
function setSessioniDiFirmaJobSelezionato(job) {
    return {
        type: __1.EReduxSessioniDiFirmaActionTypes.SESSIONI_DI_FIRMA_SET_JOB,
        payload: job
    };
}
exports.setSessioniDiFirmaJobSelezionato = setSessioniDiFirmaJobSelezionato;
function resetSessioniDiFirmaJobSelezionato() {
    return {
        type: __1.EReduxSessioniDiFirmaActionTypes.SESSIONI_DI_FIRMA_RESET_SELEZIONATO,
    };
}
exports.resetSessioniDiFirmaJobSelezionato = resetSessioniDiFirmaJobSelezionato;
function setSessioniDiFirmaPushUrl(url) {
    return {
        type: __1.EReduxSessioniDiFirmaActionTypes.SESSIONI_DI_FIRMA_SET_PUSH_URL,
        payload: url
    };
}
exports.setSessioniDiFirmaPushUrl = setSessioniDiFirmaPushUrl;
function resetSessioniDiFirmaPushUrl() {
    return {
        type: __1.EReduxSessioniDiFirmaActionTypes.SESSIONI_DI_FIRMA_RESET_PUSH_URL,
    };
}
exports.resetSessioniDiFirmaPushUrl = resetSessioniDiFirmaPushUrl;
function resetSessioniDiFirmaUrlJob() {
    return {
        type: __1.EReduxSessioniDiFirmaActionTypes.SESSIONI_DI_FIRMA_RESET_URL_JOB,
    };
}
exports.resetSessioniDiFirmaUrlJob = resetSessioniDiFirmaUrlJob;
