"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchedaTitoloTrading2DtoToJSON = exports.SchedaTitoloTrading2DtoFromJSONTyped = exports.SchedaTitoloTrading2DtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function SchedaTitoloTrading2DtoFromJSON(json) {
    return SchedaTitoloTrading2DtoFromJSONTyped(json, false);
}
exports.SchedaTitoloTrading2DtoFromJSON = SchedaTitoloTrading2DtoFromJSON;
function SchedaTitoloTrading2DtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'mercato': !runtime_1.exists(json, 'mercato') ? undefined : json['mercato'],
        'codice': !runtime_1.exists(json, 'codice') ? undefined : json['codice'],
        'desc': !runtime_1.exists(json, 'desc') ? undefined : json['desc'],
        'tipologia': !runtime_1.exists(json, 'tipologia') ? undefined : json['tipologia'],
        'codiceIsin': !runtime_1.exists(json, 'codiceIsin') ? undefined : json['codiceIsin'],
        'codiceAudiotel': !runtime_1.exists(json, 'codiceAudiotel') ? undefined : json['codiceAudiotel'],
        'settoreMerce': !runtime_1.exists(json, 'settoreMerce') ? undefined : json['settoreMerce'],
        'societaEmittente': !runtime_1.exists(json, 'societaEmittente') ? undefined : json['societaEmittente'],
        'valoreNominale': !runtime_1.exists(json, 'valoreNominale') ? undefined : json['valoreNominale'],
        'quantitaMin': !runtime_1.exists(json, 'quantitaMin') ? undefined : json['quantitaMin'],
        'numTitoliQuotati': !runtime_1.exists(json, 'numTitoliQuotati') ? undefined : json['numTitoliQuotati'],
        'composizione': !runtime_1.exists(json, 'composizione') ? undefined : (json['composizione'].map(_1.SchedaStrumentoItemDtoFromJSON)),
    };
}
exports.SchedaTitoloTrading2DtoFromJSONTyped = SchedaTitoloTrading2DtoFromJSONTyped;
function SchedaTitoloTrading2DtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'mercato': value.mercato,
        'codice': value.codice,
        'desc': value.desc,
        'tipologia': value.tipologia,
        'codiceIsin': value.codiceIsin,
        'codiceAudiotel': value.codiceAudiotel,
        'settoreMerce': value.settoreMerce,
        'societaEmittente': value.societaEmittente,
        'valoreNominale': value.valoreNominale,
        'quantitaMin': value.quantitaMin,
        'numTitoliQuotati': value.numTitoliQuotati,
        'composizione': value.composizione === undefined ? undefined : (value.composizione.map(_1.SchedaStrumentoItemDtoToJSON)),
    };
}
exports.SchedaTitoloTrading2DtoToJSON = SchedaTitoloTrading2DtoToJSON;
