import { elencoAppuntamenti } from "@sparkasse/commons";
import { navigateToPage } from "component/history/history.component";
import BankIcon from "component/Icon/icon.component";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { AppState } from "../../reducers";

const SnackBarAppuntamenti: React.FC<Props> = (props: Props) => {

  const {
    elencoAppuntamenti
  } = props;

  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex', flexDirection: 'row', flex: 1, backgroundColor: '#515151', borderRadius: 3, marginBottom: 8, cursor: 'pointer' }} className="generalView-snackBarAppuntamenti" onClick={() => navigateToPage('menu.appuntamenti')}>
      <div style={{ backgroundColor: '#515151' }}>
        <BankIcon title='navigazione_info_tooltip' white />
      </div>
      <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
        <div style={{ color: 'white', display: 'flex', flex: 1, wordBreak: 'break-word' }}>{t(elencoAppuntamenti.length == 1 ? 'consulenzaVirtuale.snackbar.appuntamentiReminderSingolo' : 'consulenzaVirtuale.snackbar.appuntamentiReminderMulti', { numAppuntamenti: elencoAppuntamenti.length })}</div>
      </div>
      <div style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
        <BankIcon title='freccetta-destra' white />
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  elencoAppuntamenti: elencoAppuntamenti(state.collaboration)
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({}, dispatch);

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export default connect(mapStateToProps, mapDispatchToProps)(SnackBarAppuntamenti);
