"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPosizioneTitoli = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_1 = require("../general");
const general_configuration_1 = require("../general/general.configuration");
const zainettoFiscale_types_1 = require("./zainettoFiscale.types");
function getPosizioneTitoliStart() {
    return {
        type: zainettoFiscale_types_1.EReduxZainettoFiscaleActionTypes.ZAINETTO_FISCALE_GETPOSIZIONE_TITOLI_START
    };
}
;
function getPosizioneTitoliSuccess(payload) {
    return {
        type: zainettoFiscale_types_1.EReduxZainettoFiscaleActionTypes.ZAINETTO_FISCALE_GETPOSIZIONE_TITOLI_SUCCESS,
        payload
    };
}
;
const getPosizioneTitoli = () => async (dispatch, _, extraArguments) => {
    dispatch((0, general_1.setLoader)(true));
    dispatch(getPosizioneTitoliStart());
    if (extraArguments.mock) {
        console.log('servizi mockati');
        dispatch((0, general_1.setLoader)(false));
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.PosizioneTitoliControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getPosizioneTitoli().then((response) => {
                dispatch(getPosizioneTitoliSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, __1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_1.setLoader)(false));
            });
        });
    }
};
exports.getPosizioneTitoli = getPosizioneTitoli;
function generalError(value, showGenericModalError) {
    return async (dispatch) => {
        dispatch((0, general_1.setError)(value, showGenericModalError));
    };
}
;
