"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.multilinguaGenesys = exports.resources = exports.currentLanguage = void 0;
const reselect_1 = require("reselect");
const i18nReducer = (state) => state;
exports.currentLanguage = (0, reselect_1.createSelector)(i18nReducer, (state) => {
    return state.currentLanguage;
});
exports.resources = (0, reselect_1.createSelector)(i18nReducer, (state) => {
    return state.resources;
});
exports.multilinguaGenesys = (0, reselect_1.createSelector)(i18nReducer, (state) => {
    return state.multilinguaGenesys;
});
