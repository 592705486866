"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setDataMassima = exports.setAccordionFiltriOpened = exports.esportaElencoFinanzaEXCEL = exports.esportaElencoFinanzaPDF = exports.resetAllFinanza = exports.resetCausaleFinanza = exports.setPeriodoFinanza = exports.setCausaleFinanza = exports.setDataFinaleFinanza = exports.setRapportoSelezionatoFinanza = exports.setTipoGraficoTitoliFinanza = exports.setTitoloSelezionatoFinanza = exports.getDatiStoriciTitolo = exports.getDatiIntradayTitolo = exports.getMFUrl = exports.getTitoliInScadenzaFinanza = exports.getParametriAvanzatiFinanza = exports.getParametriTradingFinanza = exports.getParametriFinanza = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
const finanza_selectors_1 = require("./finanza.selectors");
const finanza_types_1 = require("./finanza.types");
const moment = require("moment");
const MILANO_FINANZA_NON_ACCETTATO = "TODO_VERIFICARE_ERRORE_SPECIFICO";
function getParametriFinanzaStart() {
    return {
        type: __1.EReduxActionTypes.FINANZA_GET_PARAMETRI_START
    };
}
function getParametriFinanzaSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.FINANZA_GET_PARAMETRI_SUCCESS,
        payload
    };
}
function generalError(value) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value));
    };
}
const getParametriFinanza = () => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getParametriFinanzaStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/parametri_titoli.json')).then(parametri => {
            dispatch(getParametriFinanzaSuccess((0, api_rest_1.ParametriFinanzaDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametriTitoliScadenza().then((response) => {
                dispatch(getParametriFinanzaSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getParametriFinanza = getParametriFinanza;
function getParametriTradingStart() {
    return {
        type: __1.EReduxActionTypes.FINANZA_GET_PARAMETRI_TRADING_START
    };
}
function getParametriTradingSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.FINANZA_GET_PARAMETRI_TRADING_SUCCESS,
        payload
    };
}
const getParametriTradingFinanza = () => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getParametriTradingStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/parametri_titoli.json')).then(parametri => {
            dispatch(getParametriTradingSuccess((0, api_rest_1.ParametriFinanzaDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametriTrading().then((response) => {
                dispatch(getParametriTradingSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getParametriTradingFinanza = getParametriTradingFinanza;
function getParametriAvanzatiFinanzaStart() {
    return {
        type: __1.EReduxActionTypes.FINANZA_GET_PARAMETRI_AVANZATI_START
    };
}
function getParametriAvanzatiFinanzaSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.FINANZA_GET_PARAMETRI_AVANZATI_SUCCESS,
        payload
    };
}
const getParametriAvanzatiFinanza = (ordineParametriAvanzatiIsinInDto) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getParametriAvanzatiFinanzaStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/parametri_movimenti.json')).then(parametri => {
            dispatch(getParametriAvanzatiFinanzaSuccess((0, api_rest_1.OrdineParametriDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            const requestIn = {
                ordineParametriAvanzatiIsinInDto
            };
            new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametriAvanzati(requestIn).then((response) => {
                dispatch(getParametriAvanzatiFinanzaSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getParametriAvanzatiFinanza = getParametriAvanzatiFinanza;
function getTitoliInScadenzaFinanzaStart() {
    return {
        type: __1.EReduxActionTypes.FINANZA_GET_TITOLI_IN_SCADENZA_START
    };
}
function getTitoliInScadenzaFinanzaSuccess(payload, offset) {
    return {
        type: __1.EReduxActionTypes.FINANZA_GET_TITOLI_IN_SCADENZA_SUCCESS,
        payload,
        offset
    };
}
const getTitoliInScadenzaFinanza = (filtro) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getTitoliInScadenzaFinanzaStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/titoli_scadenza.json')).then(response => {
            dispatch(getTitoliInScadenzaFinanzaSuccess((0, api_rest_1.TitoliScadenzaDtoFromJSON)({}), 0));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            const requestIn = filtro || (0, finanza_selectors_1.formFinanzaAsFiltroTitoli)(getState().finanza);
            const requestTitoliScadenza = {
                titoliInScadenzaFiltriInDto: requestIn
            };
            new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).titoliScadenza(requestTitoliScadenza).then((response) => {
                dispatch(getTitoliInScadenzaFinanzaSuccess(response, filtro === null || filtro === void 0 ? void 0 : filtro.offset));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getTitoliInScadenzaFinanza = getTitoliInScadenzaFinanza;
function getMFUrlStart() {
    return {
        type: __1.EReduxActionTypes.FINANZA_GET_MFURL_START
    };
}
function getMFUrlSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.FINANZA_GET_MFURL_SUCCESS,
        payload
    };
}
const getMFUrl = () => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getMFUrlStart());
    if (extraArguments.mock) {
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).urlMilanoFinanza().then((response) => {
                dispatch(getMFUrlSuccess(response.mfUrl));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getMFUrl = getMFUrl;
/* get url MF - FINE */
function getDatiIntradayTitoloStart() {
    return {
        type: __1.EReduxActionTypes.FINANZA_GET_DATI_INTRADAY_TITOLO_START
    };
}
function getDatiIntradayTitoloSuccess(payload, aggiornamento) {
    return {
        type: __1.EReduxActionTypes.FINANZA_GET_DATI_INTRADAY_TITOLO_SUCCESS,
        payload,
        aggiornamento
    };
}
function getDatiIntradayTitoloError() {
    return {
        type: __1.EReduxActionTypes.FINANZA_GET_DATI_INTRADAY_TITOLO_ERROR
    };
}
const getDatiIntradayTitolo = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getDatiIntradayTitoloStart());
    if (extraArguments.mock) {
    }
    else {
        const now = moment();
        const titoloSelezionato = (0, finanza_selectors_1.datiGraficoMF)(getState().finanza).titoloSelezionato;
        if ((titoloSelezionato === null || titoloSelezionato === void 0 ? void 0 : titoloSelezionato.codiceIsin) && (titoloSelezionato === null || titoloSelezionato === void 0 ? void 0 : titoloSelezionato.mercatoEsterno)) {
            const requestParameters = {
                datiIntradayTitoloInDto: {
                    codiceTitolo: titoloSelezionato.codiceIsin,
                    mercato: titoloSelezionato.mercatoEsterno
                }
            };
            extraArguments.getItem('jwt').then((jwt) => {
                new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).datiIntradayTitolo(requestParameters).then((response) => {
                    dispatch(getDatiIntradayTitoloSuccess(response, now.toDate()));
                }).catch(async (error) => {
                    var _a;
                    if (((_a = error.error) === null || _a === void 0 ? void 0 : _a.error) === MILANO_FINANZA_NON_ACCETTATO) {
                        dispatch(getDatiIntradayTitoloError());
                    }
                    else {
                        dispatch(generalError(await (0, helpers_1.formatError)(error)));
                    }
                }).finally(() => {
                    dispatch((0, general_actions_1.setLoader)(false));
                });
            });
        }
    }
};
exports.getDatiIntradayTitolo = getDatiIntradayTitolo;
function getDatiStoriciTitoloStart() {
    return {
        type: __1.EReduxActionTypes.FINANZA_GET_DATI_STORICI_TITOLO_START
    };
}
function getDatiStoriciTitoloSuccess(payload, aggiornamento) {
    return {
        type: __1.EReduxActionTypes.FINANZA_GET_DATI_STORICI_TITOLO_SUCCESS,
        payload,
        aggiornamento
    };
}
function getDatiStoriciTitoloError() {
    return {
        type: __1.EReduxActionTypes.FINANZA_GET_DATI_STORICI_TITOLO_ERROR
    };
}
const getDatiStoriciTitolo = (tipo) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getDatiStoriciTitoloStart());
    if (extraArguments.mock) {
    }
    else {
        const titoloSelezionato = (0, finanza_selectors_1.datiGraficoMF)(getState().finanza).titoloSelezionato;
        const tipoGrafico = tipo || (0, finanza_selectors_1.datiGraficoMF)(getState().finanza).tipoGrafico;
        const now = moment();
        if ((titoloSelezionato === null || titoloSelezionato === void 0 ? void 0 : titoloSelezionato.codiceIsin) && (titoloSelezionato === null || titoloSelezionato === void 0 ? void 0 : titoloSelezionato.mercatoEsterno)) {
            const requestParameters = {
                datiIntradayTitoloInDto: {
                    codiceTitolo: titoloSelezionato.codiceIsin,
                    mercato: titoloSelezionato.mercatoEsterno,
                    dataFinale: now.toDate(),
                    dataIniziale: (tipoGrafico === finanza_types_1.TipoGraficoTitoli.Settimana1 ? now.subtract(7, "days") :
                        tipoGrafico === finanza_types_1.TipoGraficoTitoli.Mese1 ? now.subtract(1, "months") :
                            now.subtract(12, "months")).toDate()
                }
            };
            extraArguments.getItem('jwt').then((jwt) => {
                new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).datiStoriciTitolo(requestParameters).then((response) => {
                    dispatch(getDatiStoriciTitoloSuccess(response, now.toDate()));
                }).catch(async (error) => {
                    var _a;
                    if (((_a = error.error) === null || _a === void 0 ? void 0 : _a.error) === MILANO_FINANZA_NON_ACCETTATO) {
                        dispatch(getDatiStoriciTitoloError());
                    }
                    else {
                        dispatch(generalError(await (0, helpers_1.formatError)(error)));
                    }
                }).finally(() => {
                    dispatch((0, general_actions_1.setLoader)(false));
                });
            });
        }
    }
};
exports.getDatiStoriciTitolo = getDatiStoriciTitolo;
function setTitoloSelezionatoFinanza(titolo) {
    return {
        type: __1.EReduxActionTypes.FINANZA_SET_TITOLO_SELEZIONATO,
        payload: titolo
    };
}
exports.setTitoloSelezionatoFinanza = setTitoloSelezionatoFinanza;
function setTipoGraficoTitoliFinanza(tipo) {
    return {
        type: __1.EReduxActionTypes.FINANZA_SET_TIPO_GRAFICO_TITOLI,
        payload: tipo
    };
}
exports.setTipoGraficoTitoliFinanza = setTipoGraficoTitoliFinanza;
function setRapportoSelezionatoFinanza(rapporto) {
    return {
        type: __1.EReduxActionTypes.FINANZA_SET_RAPPORTO_SELEZIONATO,
        payload: rapporto
    };
}
exports.setRapportoSelezionatoFinanza = setRapportoSelezionatoFinanza;
function setDataFinaleFinanza(value) {
    return {
        type: __1.EReduxActionTypes.FINANZA_SET_DATA_FINALE,
        payload: value
    };
}
exports.setDataFinaleFinanza = setDataFinaleFinanza;
function setCausaleFinanza(value) {
    return {
        type: __1.EReduxActionTypes.FINANZA_SET_CAUSALE,
        payload: value
    };
}
exports.setCausaleFinanza = setCausaleFinanza;
function setPeriodoFinanza(value) {
    return {
        type: __1.EReduxActionTypes.FINANZA_SET_PERIODO,
        payload: value
    };
}
exports.setPeriodoFinanza = setPeriodoFinanza;
function resetCausaleFinanza() {
    return {
        type: __1.EReduxActionTypes.FINANZA_RESET_CAUSALE
    };
}
exports.resetCausaleFinanza = resetCausaleFinanza;
function resetAllFinanza() {
    return {
        type: __1.EReduxActionTypes.FINANZA_RESET_ALL
    };
}
exports.resetAllFinanza = resetAllFinanza;
function esportaElencoFinanzaPDFStart() {
    return {
        type: __1.EReduxActionTypes.FINANZA_ESPORTA_PDF_ELENCO_START
    };
}
function esportaElencoFinanzaPDFSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.FINANZA_ESPORTA_PDF_ELENCO_SUCCESS,
        payload
    };
}
const esportaElencoFinanzaPDF = (share, title, message, filtro) => async (dispatch, getState, extraArguments) => {
    dispatch(esportaElencoFinanzaPDFStart());
    dispatch((0, general_actions_1.setLoader)(true));
    if (extraArguments.mock) {
    }
    else {
        const datiIn = filtro || (0, finanza_selectors_1.formFinanzaAsFiltroTitoli)(getState().finanza);
        const request = {
            type: api_rest_1.StampaTitoliScadenzaTypeEnum.pdf,
            titoliInScadenzaFiltriInDto: datiIn
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf')).stampaTitoliScadenza(request).then((response) => {
                if (extraArguments.platform === 'desktop') {
                    (0, helpers_1.hadlePdfResponse)(response, (url) => dispatch((0, general_actions_1.popUpBlockedPdf)(url)));
                }
                if (extraArguments.platform === 'mobile') {
                    (0, helpers_1.download_sharePDF)(extraArguments, 'elenco.pdf', response, share, title, message);
                }
                dispatch(esportaElencoFinanzaPDFSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.esportaElencoFinanzaPDF = esportaElencoFinanzaPDF;
function esportaElencoFinanzaEXCELStart() {
    return {
        type: __1.EReduxActionTypes.FINANZA_ESPORTA_EXCEL_ELENCO_START
    };
}
function esportaElencoFinanzaEXCELSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.FINANZA_ESPORTA_EXCEL_ELENCO_SUCCESS,
        payload
    };
}
const esportaElencoFinanzaEXCEL = (share, title, message, filtro) => async (dispatch, getState, extraArguments) => {
    dispatch(esportaElencoFinanzaEXCELStart());
    dispatch((0, general_actions_1.setLoader)(true));
    if (extraArguments.mock) {
    }
    else {
        const datiIn = filtro || (0, finanza_selectors_1.formFinanzaAsFiltroTitoli)(getState().finanza);
        const request = {
            type: api_rest_1.StampaTitoliScadenzaTypeEnum.excel,
            titoliInScadenzaFiltriInDto: datiIn
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.FinanzaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/vnd.ms-excel')).stampaTitoliScadenza(request).then((response) => {
                if (extraArguments.platform === 'desktop') {
                    (0, helpers_1.hadleExcelResponse)(response, 'elenco.xlsx');
                }
                if (extraArguments.platform === 'mobile') {
                    (0, helpers_1.download_shareXLS)(extraArguments, 'elenco.xlsx', response, share);
                }
                dispatch(esportaElencoFinanzaEXCELSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.esportaElencoFinanzaEXCEL = esportaElencoFinanzaEXCEL;
function setAccordionFiltriOpened(opened) {
    return {
        type: __1.EReduxActionTypes.FINANZA_SET_ACCORDION_FILTRI_OPENED,
        payload: opened
    };
}
exports.setAccordionFiltriOpened = setAccordionFiltriOpened;
function setDataMassima(date) {
    return {
        type: __1.EReduxActionTypes.FINANZA_SET_DATA_MASSIMA,
        payload: date
    };
}
exports.setDataMassima = setDataMassima;
