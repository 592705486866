"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormRevocaCBillDtoFromJSON = exports.FormCBillDtoDtoFromJSONTyped = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const helpers_1 = require("../helpers");
function FormCBillDtoDtoFromJSONTyped(json) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'rapportoSelezionato': !(0, helpers_1.exists)(json, 'rapportoSelezionato') ? (0, api_rest_1.RapportoCompletoDtoFromJSON)({}) : json.rapportoSelezionato,
        'codiceBiller': !(0, helpers_1.exists)(json, 'codiceBiller') ? undefined : json.codiceBiller,
        'codiceBillerDirty': !(0, helpers_1.exists)(json, 'codiceBillerDirty') ? false : json.codiceBillerDirty,
        'codiceBillerError': !(0, helpers_1.exists)(json, 'codiceBillerError') ? 'invalid.required' : json.codiceBillerError,
        'importo': !(0, helpers_1.exists)(json, 'importo') ? undefined : json.importo,
        'importoDirty': !(0, helpers_1.exists)(json, 'importoDirty') ? false : json.importoDirty,
        'importoError': !(0, helpers_1.exists)(json, 'importoError') ? 'invalid.required' : json.importoError,
        'facilitatore': !(0, helpers_1.exists)(json, 'facilitatore') ? undefined : json.facilitatore,
        'facilitatoreDirty': !(0, helpers_1.exists)(json, 'facilitatoreDirty') ? false : json.facilitatoreDirty,
        'facilitatoreError': !(0, helpers_1.exists)(json, 'facilitatoreError') ? 'invalid.required' : json.facilitatoreError,
        'intestazioneBiller': !(0, helpers_1.exists)(json, 'intestazioneBiller') ? undefined : json.intestazioneBiller,
        'intestazioneBillerDirty': !(0, helpers_1.exists)(json, 'intestazioneBillerDirty') ? false : json.intestazioneBillerDirty,
        'intestazioneBillerError': !(0, helpers_1.exists)(json, 'intestazioneBillerError') ? 'invalid.required' : json.intestazioneBillerError,
        'nominativoCliente': !(0, helpers_1.exists)(json, 'nominativoCliente') ? undefined : json.nominativoCliente,
        'nominativoClienteDirty': !(0, helpers_1.exists)(json, 'nominativoClienteDirty') ? false : json.nominativoClienteDirty,
        'nominativoClienteError': !(0, helpers_1.exists)(json, 'nominativoClienteError') ? 'invalid.required' : json.nominativoClienteError,
        'alias1Click': !(0, helpers_1.exists)(json, 'alias1Click') ? undefined : json.alias1Click,
        'alias1ClickDirty': !(0, helpers_1.exists)(json, 'alias1ClickDirty') ? false : json.alias1ClickDirty,
        'alias1ClickError': !(0, helpers_1.exists)(json, 'alias1ClickError') ? '' : json.alias1ClickError,
        'step': !(0, helpers_1.exists)(json, 'step') ? 0 : json.step,
        'bozzaSalvata': !(0, helpers_1.exists)(json, 'bozzaSalvata') ? false : json.bozzaSalvata,
        'formSubmitted': !(0, helpers_1.exists)(json, 'formSubmitted') ? false : json.formSubmitted,
        'salva1Click': !(0, helpers_1.exists)(json, 'salva1Click') ? undefined : json.salva1Click,
        'tipoVeicolo': !(0, helpers_1.exists)(json, 'tipoVeicolo') ? undefined : json.tipoVeicolo,
        'tipoVeicoloDirty': !(0, helpers_1.exists)(json, 'tipoVeicoloDirty') ? false : json.tipoVeicoloDirty,
        'tipoVeicoloError': !(0, helpers_1.exists)(json, 'tipoVeicoloError') ? 'invalid.required' : json.tipoVeicoloError,
        'targa': !(0, helpers_1.exists)(json, 'targa') ? undefined : json.targa,
        'targaDirty': !(0, helpers_1.exists)(json, 'targaDirty') ? false : json.targaDirty,
        'targaError': !(0, helpers_1.exists)(json, 'targaError') ? 'invalid.required' : json.targaError,
        'nome': !(0, helpers_1.exists)(json, 'nome') ? undefined : json.nome,
        'nomeDirty': !(0, helpers_1.exists)(json, 'nomeDirty') ? false : json.nomeDirty,
        'nomeError': !(0, helpers_1.exists)(json, 'nomeError') ? '' : json.nomeError,
        'aggiungiRubrica': !(0, helpers_1.exists)(json, 'aggiungiRubrica') ? undefined : json.aggiungiRubrica,
        'affidabile': !(0, helpers_1.exists)(json, 'affidabile') ? undefined : json.affidabile,
        'ripeti': !(0, helpers_1.exists)(json, 'ripeti') ? undefined : json.ripeti,
        'descrizioneBiller': !(0, helpers_1.exists)(json, 'descrizioneBiller') ? undefined : json.descrizioneBiller,
        'descrizioneBillerDirty': !(0, helpers_1.exists)(json, 'descrizioneBillerDirty') ? false : json.descrizioneBillerDirty,
        'descrizioneBillerError': !(0, helpers_1.exists)(json, 'descrizioneBillerError') ? '' : json.descrizioneBillerError,
        'numeroFattura': !(0, helpers_1.exists)(json, 'numeroFattura') ? undefined : json.numeroFattura,
        'numeroFatturaDirty': !(0, helpers_1.exists)(json, 'numeroFatturaDirty') ? false : json.numeroFatturaDirty,
        'numeroFatturaError': !(0, helpers_1.exists)(json, 'numeroFatturaError') ? 'invalid.required' : json.numeroFatturaError,
        'contattoSelezionato': !(0, helpers_1.exists)(json, 'contattoSelezionato') ? undefined : json.contattoSelezionato,
        'isBillerSelected': !(0, helpers_1.exists)(json, 'affidabile') ? undefined : json.isBillerSelected
    };
}
exports.FormCBillDtoDtoFromJSONTyped = FormCBillDtoDtoFromJSONTyped;
function FormRevocaCBillDtoFromJSON(json) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'step': !(0, helpers_1.exists)(json, 'step') ? 0 : json.step,
        'provenienza': !(0, helpers_1.exists)(json, 'step') ? undefined : json.step
    };
}
exports.FormRevocaCBillDtoFromJSON = FormRevocaCBillDtoFromJSON;
