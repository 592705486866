"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.searchResults = exports.listaFilialiAtm = void 0;
const reselect_1 = require("reselect");
const ricercaFilialeAtmReducer = (state) => state;
exports.listaFilialiAtm = (0, reselect_1.createSelector)(ricercaFilialeAtmReducer, (state) => {
    return state.listaFilialiAtm;
});
exports.searchResults = (0, reselect_1.createSelector)(ricercaFilialeAtmReducer, (state) => {
    return state.searchResults;
});
