"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.VariazioneNexiRequestDtoToJSON = exports.VariazioneNexiRequestDtoFromJSONTyped = exports.VariazioneNexiRequestDtoFromJSON = void 0;
function VariazioneNexiRequestDtoFromJSON(json) {
    return VariazioneNexiRequestDtoFromJSONTyped(json, false);
}
exports.VariazioneNexiRequestDtoFromJSON = VariazioneNexiRequestDtoFromJSON;
function VariazioneNexiRequestDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'value': json['value'],
    };
}
exports.VariazioneNexiRequestDtoFromJSONTyped = VariazioneNexiRequestDtoFromJSONTyped;
function VariazioneNexiRequestDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'value': value.value,
    };
}
exports.VariazioneNexiRequestDtoToJSON = VariazioneNexiRequestDtoToJSON;
