"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RigaSezioneInpsDtoToJSON = exports.RigaSezioneInpsDtoFromJSONTyped = exports.RigaSezioneInpsDtoFromJSON = void 0;
function RigaSezioneInpsDtoFromJSON(json) {
    return RigaSezioneInpsDtoFromJSONTyped(json, false);
}
exports.RigaSezioneInpsDtoFromJSON = RigaSezioneInpsDtoFromJSON;
function RigaSezioneInpsDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codiceSede': json['codiceSede'],
        'causaleContributo': json['causaleContributo'],
        'matricolaInps': json['matricolaInps'],
        'periodoDaMese': json['periodoDaMese'],
        'periodoDaAnno': json['periodoDaAnno'],
        'periodoAMese': json['periodoAMese'],
        'periodoAAnno': json['periodoAAnno'],
        'importoDebito': json['importoDebito'],
        'importoCredito': json['importoCredito'],
    };
}
exports.RigaSezioneInpsDtoFromJSONTyped = RigaSezioneInpsDtoFromJSONTyped;
function RigaSezioneInpsDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codiceSede': value.codiceSede,
        'causaleContributo': value.causaleContributo,
        'matricolaInps': value.matricolaInps,
        'periodoDaMese': value.periodoDaMese,
        'periodoDaAnno': value.periodoDaAnno,
        'periodoAMese': value.periodoAMese,
        'periodoAAnno': value.periodoAAnno,
        'importoDebito': value.importoDebito,
        'importoCredito': value.importoCredito,
    };
}
exports.RigaSezioneInpsDtoToJSON = RigaSezioneInpsDtoToJSON;
