"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetEsitoBonificoDeposito = exports.autorizzaRevocaBonificoDeposito = exports.getInfoRevocaBonificoDeposito = exports.setBonificoDepositoRevocaSetStep = exports.setProvenienzaRevocaBonificoDeposito = exports.setRiepilogoRevocaBonificoDeposito = exports.resetRiepilogoBonificoDeposito = exports.resetBonificoDeposito = exports.setBonificoDepositoSetFormSubmitted = exports.setBonificoDepositoSetStep = exports.setBonificoDepositoSetCausale = exports.setBonificoDepositoSetDataEsecuzione = exports.setBonificoDepositoSetImporto = exports.setBonificoDepositoSetIntestatario = exports.setBonificoDepositoSetContoAccredito = exports.setBonificoDepositoSetRapportoSelezionato = exports.autorizzaBonificoDeposito = exports.getInfoDispositivaBonificoDeposito = exports.controlloBonificoDeposito = exports.getParametriBonificoDeposito = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const general_types_1 = require("../general/general.types");
const helpers_1 = require("../helpers");
const bonifico_deposito_selectors_1 = require("./bonifico-deposito.selectors");
/* get parametri - START */
function getParametriBonificoDepositoStart() {
    return {
        type: __1.EReduxActionTypes.BONIFICO_DEPOSITO_GET_PARAMETRI_START
    };
}
function getParametriBonificoDepositoSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_DEPOSITO_GET_PARAMETRI_SUCCESS,
        payload
    };
}
function getParametriPerRipetiBonificoDepositoSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_DEPOSITO_GET_PARAMETRI_PER_RIPETI_SUCCESS,
        payload
    };
}
function generalError(value, showGenericModalError) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value, showGenericModalError));
    };
}
const getParametriBonificoDeposito = (ripeti) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getParametriBonificoDepositoStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/parametri_bonifico_deposito.json')).then(parametri => {
            dispatch(ripeti ? getParametriPerRipetiBonificoDepositoSuccess((0, api_rest_1.ParametriBonificoDtoFromJSON)(parametri)) : getParametriBonificoDepositoSuccess((0, api_rest_1.ParametriBonificoDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BonificoDepositoControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametriDeposito().then((response) => {
                var _a;
                dispatch(ripeti ? getParametriPerRipetiBonificoDepositoSuccess(response) : getParametriBonificoDepositoSuccess(response));
                ((_a = response.rapporti) === null || _a === void 0 ? void 0 : _a.length) === 0 && dispatch((0, general_actions_1.setNoRapporti)({ type: general_types_1.TypeNoRapporti.TRASFERIMENTO_BONIFICO }));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getParametriBonificoDeposito = getParametriBonificoDeposito;
/* get parametri - FINE */
/* controllo bonifico - START */
function controlloBonificoDepositoStart() {
    return {
        type: __1.EReduxActionTypes.BONIFICO_DEPOSITO_CONTROLLO_START
    };
}
function controlloBonificoDepositoSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_DEPOSITO_CONTROLLO_SUCCESS,
        payload
    };
}
const controlloBonificoDeposito = () => async (dispatch, getState, extraArguments) => {
    const bonificoDeposito = getState().bonificoDeposito;
    dispatch(setBonificoDepositoSetFormSubmitted(true));
    if ((0, bonifico_deposito_selectors_1.formBonificoDepositoValid)(bonificoDeposito)) {
        dispatch((0, general_actions_1.setLoader)(true));
        dispatch(controlloBonificoDepositoStart());
        if (extraArguments.mock) {
        }
        else {
            const req = {
                bonificoSepaDto: (0, bonifico_deposito_selectors_1.buildBonificoDeposito)(bonificoDeposito)
            };
            extraArguments.getItem('jwt').then((jwt) => {
                new api_rest_1.BonificoDepositoControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).controllaBonificoDeposito(req).then((response) => {
                    dispatch(controlloBonificoDepositoSuccess(response));
                }).catch(async (error) => {
                    dispatch(generalError(await (0, helpers_1.formatError)(error)));
                }).finally(() => {
                    dispatch((0, general_actions_1.setLoader)(false));
                });
            });
        }
    }
};
exports.controlloBonificoDeposito = controlloBonificoDeposito;
/* controllo bonifico - FINE */
/* get info dispositiva bonifico - START */
function getInfoDispositivaBonificoDepositoStart() {
    return {
        type: __1.EReduxActionTypes.BONIFICO_DEPOSITO_GET_INFO_DISPOSITIVA_START
    };
}
function getInfoDispositivaBonificoDepositoSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_DEPOSITO_GET_INFO_DISPOSITIVA_SUCCESS,
        payload
    };
}
const getInfoDispositivaBonificoDeposito = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getInfoDispositivaBonificoDepositoStart());
    if (extraArguments.mock) {
    }
    else {
        const req = {
            idDispositiva,
            chiamataUscente,
            eseguiInit,
            offline: !!offline
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BonificoDepositoControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).informazioniAutenticazioneBonificoDeposito(req).then((response) => {
                dispatch(getInfoDispositivaBonificoDepositoSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getInfoDispositivaBonificoDeposito = getInfoDispositivaBonificoDeposito;
/* get info dispositiva bonifico - FINE */
/* autorizza bonifico - START */
function autorizzaBonificoDepositoStart() {
    return {
        type: __1.EReduxActionTypes.BONIFICO_DEPOSITO_AUTORIZZA_START
    };
}
function autorizzaBonificoDepositoSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_DEPOSITO_AUTORIZZA_SUCCESS,
        payload
    };
}
const autorizzaBonificoDeposito = (idDispositiva, credenziale, bonifico) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(autorizzaBonificoDepositoStart());
    if (extraArguments.mock) {
    }
    else {
        const req = {
            idDispositiva,
            credenzialeDispositivaDto: credenziale
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BonificoDepositoControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).autorizzaBonificoDeposito(req).then((response) => {
                dispatch(autorizzaBonificoDepositoSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error), false));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.autorizzaBonificoDeposito = autorizzaBonificoDeposito;
/* get info dispositiva bonifico - FINE */
function setBonificoDepositoSetRapportoSelezionato(rapportoSelezionato) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_DEPOSITO_SET_RAPPORTO_SELEZIONATO,
        payload: rapportoSelezionato
    };
}
exports.setBonificoDepositoSetRapportoSelezionato = setBonificoDepositoSetRapportoSelezionato;
function setBonificoDepositoSetContoAccredito(contoAccredito) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_DEPOSITO_CONTO_ACCREDITO,
        payload: contoAccredito
    };
}
exports.setBonificoDepositoSetContoAccredito = setBonificoDepositoSetContoAccredito;
function setBonificoDepositoSetIntestatario(value) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_DEPOSITO_INTESTATARIO,
        payload: value
    };
}
exports.setBonificoDepositoSetIntestatario = setBonificoDepositoSetIntestatario;
function setBonificoDepositoSetImporto(i) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_DEPOSITO_IMPORTO,
        payload: i
    };
}
exports.setBonificoDepositoSetImporto = setBonificoDepositoSetImporto;
function setBonificoDepositoSetDataEsecuzione(txt) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_DEPOSITO_DATA_ESECUZIONE,
        payload: txt
    };
}
exports.setBonificoDepositoSetDataEsecuzione = setBonificoDepositoSetDataEsecuzione;
function setBonificoDepositoSetCausale(txt) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_DEPOSITO_CAUSALE,
        payload: txt
    };
}
exports.setBonificoDepositoSetCausale = setBonificoDepositoSetCausale;
function setBonificoDepositoSetStepAction(i) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_DEPOSITO_STEP,
        payload: i
    };
}
const setBonificoDepositoSetStep = (i) => async (dispatch, getState, extraArguments) => {
    dispatch(setBonificoDepositoSetStepAction(i));
    if (extraArguments.platform == 'mobile') {
        dispatch((0, general_actions_1.setBottomTabBarVisible)(i != 2));
    }
};
exports.setBonificoDepositoSetStep = setBonificoDepositoSetStep;
function setBonificoDepositoSetFormSubmitted(bSelect) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_DEPOSITO_FORM_SUBMITTED,
        payload: bSelect
    };
}
exports.setBonificoDepositoSetFormSubmitted = setBonificoDepositoSetFormSubmitted;
function resetBonificoDeposito() {
    return {
        type: __1.EReduxActionTypes.BONIFICO_DEPOSITO_RESET
    };
}
exports.resetBonificoDeposito = resetBonificoDeposito;
function resetRiepilogoBonificoDeposito() {
    return {
        type: __1.EReduxActionTypes.BONIFICO_DEPOSITO_RESET_RIEPILOGO
    };
}
exports.resetRiepilogoBonificoDeposito = resetRiepilogoBonificoDeposito;
function setRiepilogoRevocaBonificoDeposito(riepilogo) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_DEPOSITO_SET_RIEPILOGO_REVOCA,
        payload: riepilogo
    };
}
exports.setRiepilogoRevocaBonificoDeposito = setRiepilogoRevocaBonificoDeposito;
function setProvenienzaRevocaBonificoDeposito(provenienza) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_DEPOSITO_SET_PROVENIENZA,
        payload: provenienza
    };
}
exports.setProvenienzaRevocaBonificoDeposito = setProvenienzaRevocaBonificoDeposito;
function setBonificoDepositoRevocaSetStepAction(i) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_DEPOSITO_STEP_REVOCA,
        payload: i
    };
}
const setBonificoDepositoRevocaSetStep = (i) => async (dispatch, getState, extraArguments) => {
    dispatch(setBonificoDepositoRevocaSetStepAction(i));
    if (extraArguments.platform == 'mobile') {
        dispatch((0, general_actions_1.setBottomTabBarVisible)(i != 1));
    }
};
exports.setBonificoDepositoRevocaSetStep = setBonificoDepositoRevocaSetStep;
/* get info revoca - START */
function getInfoRevocaBonificoDepositoStart() {
    return {
        type: __1.EReduxActionTypes.BONIFICO_DEPOSITO_GET_INFO_REVOCA_START
    };
}
function getInfoRevocaBonificoDepositoSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_DEPOSITO_GET_INFO_REVOCA_SUCCESS,
        payload
    };
}
const getInfoRevocaBonificoDeposito = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getInfoRevocaBonificoDepositoStart());
    if (extraArguments.mock) {
    }
    else {
        const req = {
            idDispositiva,
            chiamataUscente,
            eseguiInit,
            offline: !!offline
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BonificoDepositoControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).informazioniAutenticazioneRevocaDeposito(req).then((response) => {
                dispatch(getInfoRevocaBonificoDepositoSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getInfoRevocaBonificoDeposito = getInfoRevocaBonificoDeposito;
/* get info revoca - FINE */
/* autorizza revoca - START */
function autorizzaRevocaBonificoDepositoStart() {
    return {
        type: __1.EReduxActionTypes.BONIFICO_DEPOSITO_AUTORIZZA_REVOCA_START
    };
}
function autorizzaRevocaBonificoDepositoSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.BONIFICO_DEPOSITO_AUTORIZZA_REVOCA_SUCCESS,
        payload
    };
}
const autorizzaRevocaBonificoDeposito = (idDispositiva, credenziale, funzioneDispositiva) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(autorizzaRevocaBonificoDepositoStart());
    if (extraArguments.mock) {
    }
    else {
        const req = {
            idDispositiva,
            credenzialeDispositivaDto: credenziale
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.BonificoDepositoControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).operazioneAutenticataBonificoDeposito(req).then((response) => {
                dispatch(autorizzaRevocaBonificoDepositoSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error), false));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.autorizzaRevocaBonificoDeposito = autorizzaRevocaBonificoDeposito;
/* get autorizza revoca - FINE */
// Reset Esito
function resetEsitoBonificoDeposito() {
    return {
        type: __1.EReduxActionTypes.BONIFICO_DEPOSITO_RESET_ESITO
    };
}
exports.resetEsitoBonificoDeposito = resetEsitoBonificoDeposito;
