"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setBackgroundDate = exports.modificaPinSuccess = exports.getTokenHub = exports.modificaPinHub = exports.resetCurrentCodiceFunzione = exports.getTour = exports.FORCE_OPEN_KEY = exports.setHiddenTourHashes = exports.setPreferenzeUtente = exports.autorizzaModificaPassword = exports.autorizzaModificaPasswordMobile = exports.getInfoDispositivaModificaPassword = exports.credenzialiModificabiliModificaPassword = exports.setModificaPasswordStep = exports.setImpostaPINStep = exports.setConfermaPinModificaPin = exports.setNuovoPinModificaPin = exports.setVecchioPinModificaPin = exports.setConfermaPasswordModificaPassword = exports.setNuovoPasswordModificaPassword = exports.setVecchiaPasswordModificaPassword = exports.autorizzaImpostaAlias = exports.getInfoDispositivaImpostaAlias = exports.controlloAggiornaProfilo = exports.setImpostaAliasStep = exports.resetGestioneCredenziali = exports.setFormSubmittedGestioneCredenziali = exports.setRapportiGestioneCredenziali = exports.setIndirizzoDomicilioGestioneCredenziali = exports.setIndirizzoResidenzaGestioneCredenziali = exports.setTelefonoFissoGestioneCredenziali = exports.setEmailGestioneCredenziali = exports.setCellulareGestioneCredenziali = exports.setModoAutenticazioneGestioneCredenziali = exports.setCodiceAliasGestioneCredenziali = exports.setIdVirtualeGestioneCredenziali = exports.resetIntervalliInattivi = exports.resetIntervalliInattiviFunction = exports.incrementaIntervalli = exports.setIntervalliSessione = exports.estendiSessione = exports.getParametriProfilo = exports.getProfilo = exports.getInfoDispositivaBloccoUtente = exports.autorizzaBloccoUtente = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_1 = require("../general");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
const menu_1 = require("../menu");
const profilo_selectors_1 = require("./profilo.selectors");
/* autorizza blocco utente - START */
function autorizzaBloccoUtenteStart() {
    return {
        type: __1.EReduxActionTypes.PROFILO_AUTORIZZA_BLOCCO_START
    };
}
function autorizzaBloccoUtenteSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.PROFILO_AUTORIZZA_BLOCCO_SUCCESS,
        payload
    };
}
function generalError(value, showGenericModalError) {
    return async (dispatch) => {
        dispatch((0, general_1.setError)(value, showGenericModalError));
    };
}
const autorizzaBloccoUtente = (credenziale) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_1.setLoader)(true));
    dispatch(autorizzaBloccoUtenteStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/autorizza_rav.json')).then(parametri => {
            dispatch(autorizzaBloccoUtenteSuccess((0, api_rest_1.EsitoAutorizzazioneDisposizioneDtoFromJSON)(parametri)));
            dispatch((0, general_1.setLoader)(false));
        });
    }
    else {
        const autorizzaBloccoUtenzaRequest = {
            credenzialeDispositivaDto: credenziale
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.ProfiloControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).bloccoUtenza(autorizzaBloccoUtenzaRequest).then((response) => {
                dispatch(autorizzaBloccoUtenteSuccess(response));
                dispatch((0, general_1.setLoader)(false));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
                dispatch((0, general_1.setLoader)(false));
            }).finally(() => {
                if (extraArguments.platform == 'mobile') {
                    dispatch((0, general_1.setLoader)(false));
                }
            });
        });
    }
};
exports.autorizzaBloccoUtente = autorizzaBloccoUtente;
/* autorizza blocco utente - FINE */
/* get info autenticazione blocco utente - START */
function getInfoDispositivaBloccoUtenteStart() {
    return {
        type: __1.EReduxActionTypes.PROFILO_GET_INFO_DISPOSITIVA_START
    };
}
function getInfoDispositivaBloccoUtenteSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.PROFILO_GET_INFO_DISPOSITIVA_SUCCESS,
        payload
    };
}
const getInfoDispositivaBloccoUtente = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_1.setLoader)(true));
    dispatch(getInfoDispositivaBloccoUtenteStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/autenticazione-bonifico.json')).then(parametri => {
            dispatch(getInfoDispositivaBloccoUtenteSuccess((0, api_rest_1.InformazioniAutenticazioneDispositivaDtoFromJSON)(parametri)));
            dispatch((0, general_1.setLoader)(false));
        });
    }
    else {
        const requestParameters = {
            chiamataUscente,
            eseguiInit,
            // offline: !!offline
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.ProfiloControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).getBloccoUtenza(requestParameters).then((response) => {
                dispatch(getInfoDispositivaBloccoUtenteSuccess(response));
                dispatch((0, general_1.setLoader)(false));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
                dispatch((0, general_1.setLoader)(false));
            }).finally(() => {
                if (extraArguments.platform == 'mobile') {
                    dispatch((0, general_1.setLoader)(false));
                }
            });
        });
    }
};
exports.getInfoDispositivaBloccoUtente = getInfoDispositivaBloccoUtente;
/* get info autenticazione blocco utente - FINE */
function getProfiloStart() {
    return {
        type: __1.EReduxActionTypes.PROFILO_GET_PROFILO_START
    };
}
function getProfiloSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.PROFILO_GET_PROFILO_SUCCESS,
        payload
    };
}
const getProfilo = (mask, myBank) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_1.setLoader)(true));
    dispatch(getProfiloStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/profilo.json')).then(response => {
            dispatch(getProfiloSuccess((0, api_rest_1.ProfiloDtoFromJSON)(response)));
            dispatch((0, general_1.setLoader)(false));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            const request = {
                mask,
                myBank
            };
            new api_rest_1.ProfiloControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .profilo(request).then((response) => {
                dispatch(getProfiloSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
                dispatch((0, general_1.setPostLoginError)(true));
            }).finally(() => {
                dispatch((0, general_1.setLoader)(false));
            });
        });
    }
};
exports.getProfilo = getProfilo;
/* get parametri profilo - START */
function getParametriProfiloStart() {
    return {
        type: __1.EReduxActionTypes.PROFILO_GET_PARAMETRI_PROFILO_START
    };
}
function getParametriProfiloSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.PROFILO_GET_PARAMETRI_PROFILO_SUCCESS,
        payload
    };
}
const getParametriProfilo = () => async (dispatch, _, extraArguments) => {
    dispatch((0, general_1.setLoader)(true));
    dispatch(getParametriProfiloStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/parametri_profilo.json')).then(response => {
            dispatch(getParametriProfiloSuccess((0, api_rest_1.ParametriProfiloDtoFromJSON)(response)));
            dispatch((0, general_1.setLoader)(false));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.ProfiloControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .parametriProfilo().then((response) => {
                dispatch(getParametriProfiloSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_1.setLoader)(false));
            });
        });
    }
};
exports.getParametriProfilo = getParametriProfilo;
/* extendSession - START */
function estendiSessioneStart() {
    return {
        type: __1.EReduxActionTypes.PROFILO_ESTENDI_SESSIONE_START
    };
}
function estendiSessioneSuccess(inizioSessione) {
    return {
        type: __1.EReduxActionTypes.PROFILO_ESTENDI_SESSIONE_SUCCESS,
        payload: inizioSessione
    };
}
function estendiSessioneError(value) {
    return async (dispatch) => {
        dispatch((0, general_1.setError)(value));
    };
}
const estendiSessione = () => async (dispatch, _, extraArguments) => {
    dispatch(estendiSessioneStart());
    if (extraArguments.mock) {
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            if (!!jwt) {
                new api_rest_1.ProfiloControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).extendSession().then(() => {
                    dispatch(estendiSessioneSuccess(new Date()));
                    // }).catch(async (error: IGeneralState) => {
                    //   dispatch(estendiSessioneError(await formatError(error)));
                });
            }
        });
    }
};
exports.estendiSessione = estendiSessione;
function setIntervalliSessione(i) {
    return {
        type: __1.EReduxActionTypes.PROFILO_SET_INTERVALLI_SESSIONE,
        payload: i
    };
}
exports.setIntervalliSessione = setIntervalliSessione;
function incrementaIntervalli() {
    return {
        type: __1.EReduxActionTypes.PROFILO_INCREMENTA_INTERVALLI
    };
}
exports.incrementaIntervalli = incrementaIntervalli;
function resetIntervalliInattiviFunction() {
    return {
        type: __1.EReduxActionTypes.PROFILO_RESET_INTERVALLI_INATTIVI
    };
}
exports.resetIntervalliInattiviFunction = resetIntervalliInattiviFunction;
const resetIntervalliInattivi = (force = false) => async (dispatch, getState, extraArguments) => {
    if (force || extraArguments.platform === 'mobile' || (extraArguments.platform === 'desktop' && !(0, profilo_selectors_1.infoSessione)(getState().profilo).sessioneInattiva)) {
        dispatch(resetIntervalliInattiviFunction());
    }
};
exports.resetIntervalliInattivi = resetIntervalliInattivi;
/* Gestione credenziali - START */
/* set form values - START */
function setIdVirtualeGestioneCredenziali(idVirtuale) {
    return {
        type: __1.EReduxActionTypes.PROFILO_GESTIONE_CREDENZIALI_SET_ID_VIRTUALE,
        payload: idVirtuale
    };
}
exports.setIdVirtualeGestioneCredenziali = setIdVirtualeGestioneCredenziali;
function setCodiceAliasGestioneCredenziali(codiceAlias) {
    return {
        type: __1.EReduxActionTypes.PROFILO_GESTIONE_CREDENZIALI_SET_CODICE_ALIAS,
        payload: codiceAlias
    };
}
exports.setCodiceAliasGestioneCredenziali = setCodiceAliasGestioneCredenziali;
function setModoAutenticazioneGestioneCredenziali(modoAutenticazione) {
    return {
        type: __1.EReduxActionTypes.PROFILO_GESTIONE_CREDENZIALI_SET_MODO_AUTENTICAZIONE,
        payload: modoAutenticazione
    };
}
exports.setModoAutenticazioneGestioneCredenziali = setModoAutenticazioneGestioneCredenziali;
function setCellulareGestioneCredenziali(modoAutenticazione) {
    return {
        type: __1.EReduxActionTypes.PROFILO_GESTIONE_CREDENZIALI_SET_CELULLARE,
        payload: modoAutenticazione
    };
}
exports.setCellulareGestioneCredenziali = setCellulareGestioneCredenziali;
function setEmailGestioneCredenziali(email) {
    return {
        type: __1.EReduxActionTypes.PROFILO_GESTIONE_CREDENZIALI_SET_EMAIL,
        payload: email
    };
}
exports.setEmailGestioneCredenziali = setEmailGestioneCredenziali;
function setTelefonoFissoGestioneCredenziali(telefonoFisso) {
    return {
        type: __1.EReduxActionTypes.PROFILO_GESTIONE_CREDENZIALI_SET_TELEFONO_FISSO,
        payload: telefonoFisso
    };
}
exports.setTelefonoFissoGestioneCredenziali = setTelefonoFissoGestioneCredenziali;
function setIndirizzoResidenzaGestioneCredenziali(indirizzoResidenza) {
    return {
        type: __1.EReduxActionTypes.PROFILO_GESTIONE_CREDENZIALI_SET_INDIRIZZO_RESIDENZA,
        payload: indirizzoResidenza
    };
}
exports.setIndirizzoResidenzaGestioneCredenziali = setIndirizzoResidenzaGestioneCredenziali;
function setIndirizzoDomicilioGestioneCredenziali(indirizzoDomicilio) {
    return {
        type: __1.EReduxActionTypes.PROFILO_GESTIONE_CREDENZIALI_SET_INDIRIZZO_DOMICILIO,
        payload: indirizzoDomicilio
    };
}
exports.setIndirizzoDomicilioGestioneCredenziali = setIndirizzoDomicilioGestioneCredenziali;
function setRapportiGestioneCredenziali(rapporti) {
    return {
        type: __1.EReduxActionTypes.PROFILO_GESTIONE_CREDENZIALI_SET_RAPPORTI,
        payload: rapporti
    };
}
exports.setRapportiGestioneCredenziali = setRapportiGestioneCredenziali;
function setFormSubmittedGestioneCredenziali(form) {
    return {
        type: __1.EReduxActionTypes.PROFILO_GESTIONE_CREDENZIALI_FORM_SUBMITTED,
        payload: form
    };
}
exports.setFormSubmittedGestioneCredenziali = setFormSubmittedGestioneCredenziali;
function resetGestioneCredenziali() {
    return {
        type: __1.EReduxActionTypes.PROFILO_GESTIONE_CREDENZIALI_RESET
    };
}
exports.resetGestioneCredenziali = resetGestioneCredenziali;
/* set form values - FINE */
/* imposta alias - START */
/* set step alias -START*/
function setImpostaAliasStep(stepImpostaAlias) {
    return {
        type: __1.EReduxActionTypes.PROFILO_IMPOSTA_ALIAS_STEP,
        payload: stepImpostaAlias
    };
}
exports.setImpostaAliasStep = setImpostaAliasStep;
/* set step alias -FINE */
/* imposta alias controllo - START */
function controlloAggiornaProfiloImpostaAliasStart() {
    return {
        type: __1.EReduxActionTypes.PROFILO_IMPOSTA_ALIAS_CONTROLLO_START
    };
}
function controlloAggiornaProfiloImpostaAliasSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.PROFILO_IMPOSTA_ALIAS_CONTROLLO_SUCCESS,
        payload
    };
}
const controlloAggiornaProfilo = (datiAnagrafici) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_1.setLoader)(true));
    dispatch(controlloAggiornaProfiloImpostaAliasStart());
    if (extraArguments.mock) {
    }
    else {
        const datiIn = datiAnagrafici || (0, profilo_selectors_1.formDatiAnagraficiAsFilter)(getState().profilo);
        const request = {
            datiAnagraficiDto: datiIn
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.ProfiloControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .controlloAggiornaProfilo(request).then((response) => {
                dispatch(controlloAggiornaProfiloImpostaAliasSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_1.setLoader)(false));
            });
        });
    }
};
exports.controlloAggiornaProfilo = controlloAggiornaProfilo;
/* imposta alias controllo - FINE */
/* imposta alias info dispositiva - START */
function getInfoDispositivaImpostaAliasStart() {
    return {
        type: __1.EReduxActionTypes.PROFILO_IMPOSTA_ALIAS_GET_INFO_DISPOSITIVA_START
    };
}
function getInfoDispositivaImpostaAliasSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.PROFILO_IMPOSTA_ALIAS_GET_INFO_DISPOSITIVA_SUCCESS,
        payload
    };
}
const getInfoDispositivaImpostaAlias = (idDispositiva, eseguiInit, chiamataUscente, offline) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_1.setLoader)(true));
    dispatch(getInfoDispositivaImpostaAliasStart());
    if (extraArguments.mock) {
    }
    else {
        const request = {
            idDispositiva: idDispositiva,
            chiamataUscente,
            eseguiInit,
            offline: !!offline
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.ProfiloControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .informazioniAutenticazioneModificaProfilo(request).then((response) => {
                dispatch(getInfoDispositivaImpostaAliasSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_1.setLoader)(false));
            });
        });
    }
};
exports.getInfoDispositivaImpostaAlias = getInfoDispositivaImpostaAlias;
/* imposta alias info dispositiva - FINE */
/* imposta alias autorizza - FINE */
function autorizzaImpostaAliasStart() {
    return {
        type: __1.EReduxActionTypes.PROFILO_IMPOSTA_ALIAS_AUTORIZZA_START
    };
}
function autorizzaImpostaAliasSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.PROFILO_IMPOSTA_ALIAS_AUTORIZZA_SUCCESS,
        payload
    };
}
const autorizzaImpostaAlias = (idDispositiva, credenziale) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_1.setLoader)(true));
    dispatch(autorizzaImpostaAliasStart());
    if (extraArguments.mock) {
    }
    else {
        const request = {
            idDispositiva: idDispositiva,
            credenzialeDispositivaDto: credenziale
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.ProfiloControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .autorizzaProfilo(request).then((response) => {
                dispatch(autorizzaImpostaAliasSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error), false));
            }).finally(() => {
                dispatch((0, general_1.setLoader)(false));
            });
        });
    }
};
exports.autorizzaImpostaAlias = autorizzaImpostaAlias;
/* imposta alias autorizza - FINE */
/* modifica password - START */
/* modifica password set values */
function setVecchiaPasswordModificaPassword(oldPassword) {
    return {
        type: __1.EReduxActionTypes.PROFILO_MODIFICA_PASSWORD_SET_VECCHIA_PASSWORD,
        payload: oldPassword
    };
}
exports.setVecchiaPasswordModificaPassword = setVecchiaPasswordModificaPassword;
function setNuovoPasswordModificaPassword(newPassword) {
    return {
        type: __1.EReduxActionTypes.PROFILO_MODIFICA_PASSWORD_SET_NUOVO_PASSWORD,
        payload: newPassword
    };
}
exports.setNuovoPasswordModificaPassword = setNuovoPasswordModificaPassword;
function setConfermaPasswordModificaPassword(confirmPassword) {
    return {
        type: __1.EReduxActionTypes.PROFILO_MODIFICA_PASSWORD_SET_CONFERMA_PASSWORD,
        payload: confirmPassword
    };
}
exports.setConfermaPasswordModificaPassword = setConfermaPasswordModificaPassword;
function setVecchioPinModificaPin(oldPin) {
    return {
        type: __1.EReduxActionTypes.PROFILO_MODIFICA_PIN_SET_VECCHIO_PIN,
        payload: oldPin
    };
}
exports.setVecchioPinModificaPin = setVecchioPinModificaPin;
function setNuovoPinModificaPin(newPin) {
    return {
        type: __1.EReduxActionTypes.PROFILO_MODIFICA_PIN_SET_NUOVO_PIN,
        payload: newPin
    };
}
exports.setNuovoPinModificaPin = setNuovoPinModificaPin;
function setConfermaPinModificaPin(confirmPin) {
    return {
        type: __1.EReduxActionTypes.PROFILO_MODIFICA_PIN_SET_CONFERMA_PIN,
        payload: confirmPin
    };
}
exports.setConfermaPinModificaPin = setConfermaPinModificaPin;
function setImpostaPINStep(stepImpostaPIN) {
    return {
        type: __1.EReduxActionTypes.PROFILO_IMPOSTA_PIN_STEP,
        payload: stepImpostaPIN
    };
}
exports.setImpostaPINStep = setImpostaPINStep;
/* set step alias -START*/
function setModificaPasswordStep(stepModificaPassword) {
    return {
        type: __1.EReduxActionTypes.PROFILO_MODIFICA_PASSWORD_STEP,
        payload: stepModificaPassword
    };
}
exports.setModificaPasswordStep = setModificaPasswordStep;
/* set step alias -FINE */
/* controllo modifica password - START */
function credenzialiModificabiliModificaPasswordStart() {
    return {
        type: __1.EReduxActionTypes.PROFILO_MODIFICA_PASSWORD_CREDENZIALI_MODIFICABILI_START
    };
}
function credenzialiModificabiliModificaPasswordSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.PROFILO_MODIFICA_PASSWORD_CREDENZIALI_MODIFICABILI_SUCCESS,
        payload
    };
}
const credenzialiModificabiliModificaPassword = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_1.setLoader)(true));
    dispatch(credenzialiModificabiliModificaPasswordStart());
    if (extraArguments.mock) {
        dispatch((0, general_1.setLoader)(false));
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.ProfiloControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .credenzialiModificabili().then((response) => {
                dispatch(credenzialiModificabiliModificaPasswordSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_1.setLoader)(false));
            });
        });
    }
};
exports.credenzialiModificabiliModificaPassword = credenzialiModificabiliModificaPassword;
/* controllo modifica password - FINE */
/* info dispositiva modifica password - START */
function getInfoDispositivaModificaPasswordStart() {
    return {
        type: __1.EReduxActionTypes.PROFILO_MODIFICA_PASSWORD_GET_INFO_DISPOSITIVA_START
    };
}
function getInfoDispositivaModificaPasswordSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.PROFILO_MODIFICA_PASSWORD_GET_INFO_DISPOSITIVA_SUCCESS,
        payload
    };
}
const getInfoDispositivaModificaPassword = (codiceCredenziale, eseguiInit, chiamataUscente, offline) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_1.setLoader)(true));
    dispatch(getInfoDispositivaModificaPasswordStart());
    if (extraArguments.mock) {
    }
    else {
        const request = {
            codiceCredenziale: codiceCredenziale,
            chiamataUscente,
            eseguiInit,
            // offline: !!offline
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.ProfiloControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .infoAutenticazioneModificaCredenziali(request).then((response) => {
                dispatch(getInfoDispositivaModificaPasswordSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_1.setLoader)(false));
            });
        });
    }
};
exports.getInfoDispositivaModificaPassword = getInfoDispositivaModificaPassword;
/* info dispositiva modifica password - FINE */
/* autorizza modifica password - START */
function autorizzaModificaPasswordStart() {
    return {
        type: __1.EReduxActionTypes.PROFILO_MODIFICA_PASSWORD_AUTORIZZA_START
    };
}
function autorizzaModificaPasswordSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.PROFILO_MODIFICA_PASSWORD_AUTORIZZA_SUCCESS,
        payload
    };
}
const autorizzaModificaPasswordMobile = (codiceCredenziale) => async (dispatch, getState, extraArguments) => {
    const requestIn = (0, profilo_selectors_1.credenzialiModificaPasswordAsFiltro)(getState().profilo);
    (0, exports.autorizzaModificaPassword)(codiceCredenziale, {}, requestIn)(dispatch, getState, extraArguments);
};
exports.autorizzaModificaPasswordMobile = autorizzaModificaPasswordMobile;
const autorizzaModificaPassword = (codiceCredenziale, any = {}, cambioCredenzialeRequestDto) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_1.setLoader)(true));
    dispatch(autorizzaModificaPasswordStart());
    if (extraArguments.mock) {
    }
    else {
        const request = {
            codiceCredenziale: codiceCredenziale,
            cambioCredenzialeRequestDto: cambioCredenzialeRequestDto
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.ProfiloControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .autorizzaModificaCredenziali(request).then((response) => {
                dispatch(autorizzaModificaPasswordSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error), false));
            }).finally(() => {
                dispatch((0, general_1.setLoader)(false));
            });
        });
    }
};
exports.autorizzaModificaPassword = autorizzaModificaPassword;
/* autorizza modifica password - FINE */
/* modifica password - FINE */
/* set preferenze utente - START */
function setPreferenzeUtenteStart() {
    return {
        type: __1.EReduxActionTypes.PROFILO_SET_PREFERENZE_UTENTE_START
    };
}
function setPreferenzeUtenteSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.PROFILO_SET_PREFERENZE_UTENTE_SUCCESS,
        payload
    };
}
const setPreferenzeUtente = () => async (dispatch, getState, extraArguments) => {
    const state = getState().i18n;
    const preferenzeUtenteRequest = {
        preferenzeUtenteDto: {
            mappaPreferenze: {
                'LINGUA_PREFERENZA_UTENTE': state.currentLanguage.toUpperCase()
            }
        }
    };
    dispatch((0, general_1.setLoader)(true));
    dispatch(setPreferenzeUtenteStart());
    if (extraArguments.mock) {
        dispatch((0, general_1.setLoader)(false));
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.ProfiloControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .preferenzeUtente(preferenzeUtenteRequest).then(() => {
                dispatch(setPreferenzeUtenteSuccess(preferenzeUtenteRequest.preferenzeUtenteDto));
                dispatch((0, general_1.resetPostCambioLingua)());
                dispatch((0, menu_1.getMenu)());
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_1.setLoader)(false));
            });
        });
    }
};
exports.setPreferenzeUtente = setPreferenzeUtente;
/* set preferenze utente - FINE */
/* set tour - START */
function setHiddenTourHashes(hashes) {
    return {
        type: __1.EReduxActionTypes.PROFILO_SET_HIDDEN_TOUR_HASHES,
        payload: hashes
    };
}
exports.setHiddenTourHashes = setHiddenTourHashes;
function getTourStart() {
    return {
        type: __1.EReduxActionTypes.PROFILO_GET_TOUR_START
    };
}
function getTourSuccess(codiceFunzione, tour) {
    return {
        type: __1.EReduxActionTypes.PROFILO_GET_TOUR_SUCCESS,
        codiceFunzione: codiceFunzione,
        tour: tour
    };
}
exports.FORCE_OPEN_KEY = '#ForceOpen#';
const getTour = (codiceFunzione, forceOpen) => async (dispatch, getState, extraArguments) => {
    const profilo = getState().profilo;
    const tourAvailable = profilo.tour.tour[codiceFunzione];
    if (tourAvailable) {
        dispatch(getTourSuccess((forceOpen ? exports.FORCE_OPEN_KEY : '') + codiceFunzione, tourAvailable));
        return;
    }
    dispatch((0, general_1.setLoader)(true));
    dispatch(getTourStart());
    const request = {
        codiceFunzione: codiceFunzione
    };
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/tour.json')).then(response => {
            dispatch(getTourSuccess((forceOpen ? exports.FORCE_OPEN_KEY : '') + codiceFunzione, (0, api_rest_1.TourDtoFromJSON)(response)));
            dispatch((0, general_1.setLoader)(false));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.TourContestualeControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt))
                .getTourContestuale(request).then((response) => {
                dispatch(getTourSuccess((forceOpen ? exports.FORCE_OPEN_KEY : '') + codiceFunzione, response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_1.setLoader)(false));
            });
        });
    }
};
exports.getTour = getTour;
function resetCurrentCodiceFunzione() {
    return {
        type: __1.EReduxActionTypes.PROFILO_RESET_CURRENT_CODICE_FUNZIONE
    };
}
exports.resetCurrentCodiceFunzione = resetCurrentCodiceFunzione;
function modificaPinHubStart() {
    return {
        type: __1.EReduxActionTypes.PROFILO_MODIFICA_PIN_HUB_START
    };
}
function modificaPinHubSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.PROFILO_MODIFICA_PIN_HUB_SUCCESS,
        payload: payload.bloccoDatiBinario
    };
}
const modificaPinHub = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_1.setLoader)(true));
    dispatch(modificaPinHubStart());
    if (extraArguments.mock) {
    }
    else {
        const stateProfilo = getState().profilo;
        var d = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        const request = {
            modificaTokenPinDto: {
                idServerChiamante: general_1.package_id,
                idOperazione: uuid,
                idApplicazioneChiamante: '00000000-89ABCDEF-01234567-89ABCDEF',
                authorization: 'Bearer ' + stateProfilo.jwtCambioPin,
                body: {
                    nuovoPin: stateProfilo.form.newPIN,
                    // vecchioPin: stateProfilo.form.oldPIN
                },
                idSessioneChiamante: String(Math.random() * 100000000000000000)
                // identityStore: ModificaPinTokenUserExtIdentityStoreEnum.LDAP
            }
        };
        new api_rest_1.AuthenticationServiceControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments)).attivitaUtenteTokenPin(request).then((response) => {
            dispatch(modificaPinHubSuccess(response));
        }).catch(async (error) => {
            dispatch(generalError(await (0, helpers_1.formatError)(error)));
        }).finally(() => {
            dispatch((0, general_1.setLoader)(false));
        });
    }
};
exports.modificaPinHub = modificaPinHub;
function getTokenHubStart() {
    return {
        type: __1.EReduxActionTypes.PROFILO_GET_TOKEN_HUB_START
    };
}
function getTokenHubSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.PROFILO_GET_TOKEN_HUB_SUCCESS,
        payload
    };
}
const getTokenHub = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_1.setLoader)(true));
    dispatch(getTokenHubStart());
    if (extraArguments.mock) {
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.AutenticazioneParzialeControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).generaJwt().then((response) => {
                dispatch(getTokenHubSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_1.setLoader)(false));
            });
        });
    }
};
exports.getTokenHub = getTokenHub;
function resetRiepilogoModificaPin() {
    return {
        type: __1.EReduxActionTypes.RESET_RIEPILOGO_MODIFICA_PIN,
    };
}
const modificaPinSuccess = () => async (dispatch, getState, extraArguments) => {
    dispatch(setImpostaPINStep(1));
    dispatch(resetRiepilogoModificaPin());
};
exports.modificaPinSuccess = modificaPinSuccess;
function setBackgroundDate(payload) {
    return {
        type: __1.EReduxActionTypes.PROFILO_SET_BACKGROUND_DATE,
        payload
    };
}
exports.setBackgroundDate = setBackgroundDate;
