"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContattiControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class ContattiControllerApi extends runtime.BaseAPI {
    /**
     * La creazione di nuovo contatto in rubrica.
     */
    addContattoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.contattoDto === null || requestParameters.contattoDto === undefined) {
                throw new runtime.RequiredError('contattoDto', 'Required parameter requestParameters.contattoDto was null or undefined when calling addContatto.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/rubrica/contatti/add`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ContattoDtoToJSON(requestParameters.contattoDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ContattoDtoFromJSON(jsonValue));
        });
    }
    /**
     * La creazione di nuovo contatto in rubrica.
     */
    addContatto(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.addContattoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Informazioni sull\'autenticazione contatto
     */
    authenticationInformationContattoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idContatto === null || requestParameters.idContatto === undefined) {
                throw new runtime.RequiredError('idContatto', 'Required parameter requestParameters.idContatto was null or undefined when calling authenticationInformationContatto.');
            }
            if (requestParameters.codiceFunzione === null || requestParameters.codiceFunzione === undefined) {
                throw new runtime.RequiredError('codiceFunzione', 'Required parameter requestParameters.codiceFunzione was null or undefined when calling authenticationInformationContatto.');
            }
            const queryParameters = {};
            if (requestParameters.chiamataUscente !== undefined) {
                queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
            }
            if (requestParameters.eseguiInit !== undefined) {
                queryParameters['eseguiInit'] = requestParameters.eseguiInit;
            }
            if (requestParameters.offline !== undefined) {
                queryParameters['offline'] = requestParameters.offline;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/rubrica/contatti/infoautenticazione/{idContatto}/{codiceFunzione}`.replace(`{${"idContatto"}}`, encodeURIComponent(String(requestParameters.idContatto))).replace(`{${"codiceFunzione"}}`, encodeURIComponent(String(requestParameters.codiceFunzione))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Informazioni sull\'autenticazione contatto
     */
    authenticationInformationContatto(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.authenticationInformationContattoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Informazioni sull\'autenticazione elimina Contatto
     */
    authenticationInformationEliminaContattoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idContatto === null || requestParameters.idContatto === undefined) {
                throw new runtime.RequiredError('idContatto', 'Required parameter requestParameters.idContatto was null or undefined when calling authenticationInformationEliminaContatto.');
            }
            const queryParameters = {};
            if (requestParameters.chiamataUscente !== undefined) {
                queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
            }
            if (requestParameters.eseguiInit !== undefined) {
                queryParameters['eseguiInit'] = requestParameters.eseguiInit;
            }
            if (requestParameters.offline !== undefined) {
                queryParameters['offline'] = requestParameters.offline;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/rubrica/contatti/infoautenticazione/{idContatto}/delete`.replace(`{${"idContatto"}}`, encodeURIComponent(String(requestParameters.idContatto))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Informazioni sull\'autenticazione elimina Contatto
     */
    authenticationInformationEliminaContatto(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.authenticationInformationEliminaContattoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Informazioni sull\'autenticazione modifica contatto
     */
    authenticationInformationModificaContattoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idContatto === null || requestParameters.idContatto === undefined) {
                throw new runtime.RequiredError('idContatto', 'Required parameter requestParameters.idContatto was null or undefined when calling authenticationInformationModificaContatto.');
            }
            if (requestParameters.codiceFunzione === null || requestParameters.codiceFunzione === undefined) {
                throw new runtime.RequiredError('codiceFunzione', 'Required parameter requestParameters.codiceFunzione was null or undefined when calling authenticationInformationModificaContatto.');
            }
            const queryParameters = {};
            if (requestParameters.chiamataUscente !== undefined) {
                queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
            }
            if (requestParameters.eseguiInit !== undefined) {
                queryParameters['eseguiInit'] = requestParameters.eseguiInit;
            }
            if (requestParameters.offline !== undefined) {
                queryParameters['offline'] = requestParameters.offline;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/rubrica/contatti/infoautenticazione/{idContatto}/{codiceFunzione}/modifica`.replace(`{${"idContatto"}}`, encodeURIComponent(String(requestParameters.idContatto))).replace(`{${"codiceFunzione"}}`, encodeURIComponent(String(requestParameters.codiceFunzione))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Informazioni sull\'autenticazione modifica contatto
     */
    authenticationInformationModificaContatto(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.authenticationInformationModificaContattoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Autorizzazione di una aggiunta di contatto
     */
    authorizeContattoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idContatto === null || requestParameters.idContatto === undefined) {
                throw new runtime.RequiredError('idContatto', 'Required parameter requestParameters.idContatto was null or undefined when calling authorizeContatto.');
            }
            if (requestParameters.codiceFunzione === null || requestParameters.codiceFunzione === undefined) {
                throw new runtime.RequiredError('codiceFunzione', 'Required parameter requestParameters.codiceFunzione was null or undefined when calling authorizeContatto.');
            }
            if (requestParameters.credenzialeDispositivaDto === null || requestParameters.credenzialeDispositivaDto === undefined) {
                throw new runtime.RequiredError('credenzialeDispositivaDto', 'Required parameter requestParameters.credenzialeDispositivaDto was null or undefined when calling authorizeContatto.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/rubrica/contatti/autorizza/{idContatto}/{codiceFunzione}`.replace(`{${"idContatto"}}`, encodeURIComponent(String(requestParameters.idContatto))).replace(`{${"codiceFunzione"}}`, encodeURIComponent(String(requestParameters.codiceFunzione))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CredenzialeDispositivaDtoToJSON(requestParameters.credenzialeDispositivaDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.EsitoAutorizzazioneDisposizioneContattoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Autorizzazione di una aggiunta di contatto
     */
    authorizeContatto(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.authorizeContattoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Autorizzazione di modifica contatto
     */
    authorizeModificaContattoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idContatto === null || requestParameters.idContatto === undefined) {
                throw new runtime.RequiredError('idContatto', 'Required parameter requestParameters.idContatto was null or undefined when calling authorizeModificaContatto.');
            }
            if (requestParameters.codiceFunzione === null || requestParameters.codiceFunzione === undefined) {
                throw new runtime.RequiredError('codiceFunzione', 'Required parameter requestParameters.codiceFunzione was null or undefined when calling authorizeModificaContatto.');
            }
            if (requestParameters.credenzialeDispositivaDto === null || requestParameters.credenzialeDispositivaDto === undefined) {
                throw new runtime.RequiredError('credenzialeDispositivaDto', 'Required parameter requestParameters.credenzialeDispositivaDto was null or undefined when calling authorizeModificaContatto.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/rubrica/contatti/autorizza/{idContatto}/{codiceFunzione}/modifica`.replace(`{${"idContatto"}}`, encodeURIComponent(String(requestParameters.idContatto))).replace(`{${"codiceFunzione"}}`, encodeURIComponent(String(requestParameters.codiceFunzione))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CredenzialeDispositivaDtoToJSON(requestParameters.credenzialeDispositivaDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.EsitoAutorizzazioneDisposizioneContattoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Autorizzazione di modifica contatto
     */
    authorizeModificaContatto(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.authorizeModificaContattoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce tutte le linformazioni relative ad un contatto.
     */
    dettagliContattoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling dettagliContatto.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/rubrica/contatti/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ContattoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce tutte le linformazioni relative ad un contatto.
     */
    dettagliContatto(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.dettagliContattoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce la lista dei contatti in funzione dei filtri in ingresso
     */
    elencoContattiRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.filtroListaContattiDto === null || requestParameters.filtroListaContattiDto === undefined) {
                throw new runtime.RequiredError('filtroListaContattiDto', 'Required parameter requestParameters.filtroListaContattiDto was null or undefined when calling elencoContatti.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/rubrica/contatti`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.FiltroListaContattiDtoToJSON(requestParameters.filtroListaContattiDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ContattiDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce la lista dei contatti in funzione dei filtri in ingresso
     */
    elencoContatti(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.elencoContattiRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce rubrica light
     */
    getContattiRubricaLightRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.filtroListaContattiDto === null || requestParameters.filtroListaContattiDto === undefined) {
                throw new runtime.RequiredError('filtroListaContattiDto', 'Required parameter requestParameters.filtroListaContattiDto was null or undefined when calling getContattiRubricaLight.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/rubrica/light`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.FiltroListaContattiDtoToJSON(requestParameters.filtroListaContattiDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ContattiDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce rubrica light
     */
    getContattiRubricaLight(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getContattiRubricaLightRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Modifica di una contatto
     */
    modificaContattoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.contattoDto === null || requestParameters.contattoDto === undefined) {
                throw new runtime.RequiredError('contattoDto', 'Required parameter requestParameters.contattoDto was null or undefined when calling modificaContatto.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/rubrica/contatti/controllo`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ContattoDtoToJSON(requestParameters.contattoDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ContattoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Modifica di una contatto
     */
    modificaContatto(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.modificaContattoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Autorizzazione di una eliminazione Contatto
     */
    operazioneAutenticataEliminaContattoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.removeContattoReqInDto === null || requestParameters.removeContattoReqInDto === undefined) {
                throw new runtime.RequiredError('removeContattoReqInDto', 'Required parameter requestParameters.removeContattoReqInDto was null or undefined when calling operazioneAutenticataEliminaContatto.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/rubrica/contatti/autorizza/delete`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.RemoveContattoReqInDtoToJSON(requestParameters.removeContattoReqInDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.EsitoAutorizzazioneDisposizioneContattoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Autorizzazione di una eliminazione Contatto
     */
    operazioneAutenticataEliminaContatto(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.operazioneAutenticataEliminaContattoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce i parametri applicabili alla gestione dei contatti in rubrica e l\'elenco delle provice usato per la scelta della provincia nell\'impostazione di un indirizzo durante la creazione di un nuovo contatto.
     */
    parametriContattiRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/rubrica/parametri`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ParametriContattiDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce i parametri applicabili alla gestione dei contatti in rubrica e l\'elenco delle provice usato per la scelta della provincia nell\'impostazione di un indirizzo durante la creazione di un nuovo contatto.
     */
    parametriContatti() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.parametriContattiRaw();
            return yield response.value();
        });
    }
}
exports.ContattiControllerApi = ContattiControllerApi;
