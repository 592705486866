import React, { ReactFragment } from "react";

interface Props {
  index: number
  selectedIndex: number
  custom?: {
    allowHtmlMainText?: boolean
    info0?: string
    info1?: string
    cta?: ReactFragment
  }
}

const ItemCarousel: React.FC<Props> = (props: Props) => {

  const {
    index,
    selectedIndex,
    custom
  } = props;

  return (
    <div
      className={`carousel-item-view ${selectedIndex !== index ? "" : "carousel-item-view-selected"}`}
      aria-selected={selectedIndex === index}
      aria-hidden={selectedIndex === index ? "false" : "true"}
      tabIndex={selectedIndex === index ? 0 : -1}
    >

      {!!custom?.info0 &&
        <div className="item-carousel-superscript">
          <span>
            {custom?.info0}
          </span>
        </div>
      }

      <div className="item-carousel-row-container">

        <div className="carousel-alias-container">

          {custom?.allowHtmlMainText && custom?.info1
            ? <span dangerouslySetInnerHTML={{ __html: custom?.info1 || "" }}></span>
            : <span
              className={
                selectedIndex !== index
                  ? "txtInactive-item-carousel txtAlias-item-carousel"
                  : "txtAlias-item-carousel"
              }
            >
              {custom?.info1}
            </span>
          }

          <span
            className={
              selectedIndex !== index
                ? "carouselIban txtInactive-item-carousel txtIBAN-item-carousel"
                : "carouselIban txtIBAN-item-carousel"
            }
          >
            {custom?.cta}
          </span>

        </div>

      </div>
    </div>
  );
};

export default ItemCarousel;
