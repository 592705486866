"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiltroFinanziamentiDtoToJSON = exports.FiltroFinanziamentiDtoFromJSONTyped = exports.FiltroFinanziamentiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function FiltroFinanziamentiDtoFromJSON(json) {
    return FiltroFinanziamentiDtoFromJSONTyped(json, false);
}
exports.FiltroFinanziamentiDtoFromJSON = FiltroFinanziamentiDtoFromJSON;
function FiltroFinanziamentiDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codiceRapporto': !runtime_1.exists(json, 'codiceRapporto') ? undefined : json['codiceRapporto'],
        'progressivoRapporto': !runtime_1.exists(json, 'progressivoRapporto') ? undefined : json['progressivoRapporto'],
        'pianoType': !runtime_1.exists(json, 'pianoType') ? undefined : json['pianoType'],
        'rateType': !runtime_1.exists(json, 'rateType') ? undefined : json['rateType'],
        'dataIniziale': !runtime_1.exists(json, 'dataIniziale') ? undefined : (new Date(json['dataIniziale'])),
        'dataFinale': !runtime_1.exists(json, 'dataFinale') ? undefined : (new Date(json['dataFinale'])),
    };
}
exports.FiltroFinanziamentiDtoFromJSONTyped = FiltroFinanziamentiDtoFromJSONTyped;
function FiltroFinanziamentiDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codiceRapporto': value.codiceRapporto,
        'progressivoRapporto': value.progressivoRapporto,
        'pianoType': value.pianoType,
        'rateType': value.rateType,
        'dataIniziale': value.dataIniziale === undefined ? undefined : (value.dataIniziale.toISOString()),
        'dataFinale': value.dataFinale === undefined ? undefined : (value.dataFinale.toISOString()),
    };
}
exports.FiltroFinanziamentiDtoToJSON = FiltroFinanziamentiDtoToJSON;
