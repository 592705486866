"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RigaSezioneRegioniDtoToJSON = exports.RigaSezioneRegioniDtoFromJSONTyped = exports.RigaSezioneRegioniDtoFromJSON = void 0;
function RigaSezioneRegioniDtoFromJSON(json) {
    return RigaSezioneRegioniDtoFromJSONTyped(json, false);
}
exports.RigaSezioneRegioniDtoFromJSON = RigaSezioneRegioniDtoFromJSON;
function RigaSezioneRegioniDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codiceRegione': json['codiceRegione'],
        'codiceTributo': json['codiceTributo'],
        'infoTributo': json['infoTributo'],
        'annoRiferimento': json['annoRiferimento'],
        'importoDebito': json['importoDebito'],
        'importoCredito': json['importoCredito'],
    };
}
exports.RigaSezioneRegioniDtoFromJSONTyped = RigaSezioneRegioniDtoFromJSONTyped;
function RigaSezioneRegioniDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codiceRegione': value.codiceRegione,
        'codiceTributo': value.codiceTributo,
        'infoTributo': value.infoTributo,
        'annoRiferimento': value.annoRiferimento,
        'importoDebito': value.importoDebito,
        'importoCredito': value.importoCredito,
    };
}
exports.RigaSezioneRegioniDtoToJSON = RigaSezioneRegioniDtoToJSON;
