"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AspspDetailDtoToJSON = exports.AspspDetailDtoFromJSONTyped = exports.AspspDetailDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function AspspDetailDtoFromJSON(json) {
    return AspspDetailDtoFromJSONTyped(json, false);
}
exports.AspspDetailDtoFromJSON = AspspDetailDtoFromJSON;
function AspspDetailDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'aspspId': !runtime_1.exists(json, 'aspspId') ? undefined : json['aspspId'],
        'businessName': !runtime_1.exists(json, 'businessName') ? undefined : json['businessName'],
        'status': !runtime_1.exists(json, 'status') ? undefined : json['status'],
        'aspspProductList': !runtime_1.exists(json, 'aspspProductList') ? undefined : (json['aspspProductList'].map(_1.AspspProductDtoFromJSON)),
    };
}
exports.AspspDetailDtoFromJSONTyped = AspspDetailDtoFromJSONTyped;
function AspspDetailDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'aspspId': value.aspspId,
        'businessName': value.businessName,
        'status': value.status,
        'aspspProductList': value.aspspProductList === undefined ? undefined : (value.aspspProductList.map(_1.AspspProductDtoToJSON)),
    };
}
exports.AspspDetailDtoToJSON = AspspDetailDtoToJSON;
