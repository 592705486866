"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.accettazioniMessaggiAssistenteVocale = exports.disableRoutingOnBlockingMessages = exports.comunicazioneSelezionata = exports.numeroMessaggiNonLettiMessaggi = exports.comunicazioniMessaggi = exports.bloccanti = exports.bloccantiMessaggi = exports.accettazioniMessaggi = exports.funzionalitaAccettata = exports.formMessaggi = void 0;
const reselect_1 = require("reselect");
const messaggiReducer = (state) => state;
exports.formMessaggi = (0, reselect_1.createSelector)(messaggiReducer, (state) => {
    return state.form;
});
exports.funzionalitaAccettata = (0, reselect_1.createSelector)(messaggiReducer, (state) => {
    return state.accettazioni && !state.accettazioni.some(m => !m.letto) ?
        state.form.funzionalitaAccettazione : undefined;
});
exports.accettazioniMessaggi = (0, reselect_1.createSelector)(messaggiReducer, (state) => {
    return state.accettazioni;
});
exports.bloccantiMessaggi = (0, reselect_1.createSelector)(messaggiReducer, (state) => {
    var _a;
    return (_a = state.bloccanti) === null || _a === void 0 ? void 0 : _a.filter(b => !b.letto);
});
exports.bloccanti = (0, reselect_1.createSelector)(messaggiReducer, (state) => {
    return state.bloccanti;
});
exports.comunicazioniMessaggi = (0, reselect_1.createSelector)(messaggiReducer, (state) => {
    return state.comunicazioni;
});
exports.numeroMessaggiNonLettiMessaggi = (0, reselect_1.createSelector)(messaggiReducer, (state) => {
    return state.numeroMessaggiNonLetti;
});
exports.comunicazioneSelezionata = (0, reselect_1.createSelector)(messaggiReducer, (state) => {
    return state.comunicazioneSelezionata;
});
/**
 * disabilita routing quando sono presenti dei messaggi bloccanti
 */
exports.disableRoutingOnBlockingMessages = (0, reselect_1.createSelector)(messaggiReducer, (state) => {
    return (state.bloccanti && state.bloccanti.length > 0);
});
exports.accettazioniMessaggiAssistenteVocale = (0, reselect_1.createSelector)(messaggiReducer, (state) => {
    return state.accettazioniAssistenteVocale;
});
