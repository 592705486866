"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetWidgets = exports.getParametriWidget = exports.salvaWidgets = exports.getWidgetsUtente = void 0;
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const api_rest_1 = require("@sparkasse/api-rest");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
/* get widgets utente - START */
function getWidgetsUtenteStart() {
    return {
        type: __1.EReduxActionTypes.GET_WIDGETS_UTENTE_START
    };
}
function getWidgetsUtenteSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.GET_WIDGETS_UTENTE_SUCCESS,
        payload
    };
}
function generalError(value) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value));
    };
}
const getWidgetsUtente = (loaderEnabled = true) => async (dispatch, getState, extraArguments) => {
    loaderEnabled && dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getWidgetsUtenteStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/widgets_utente.json')).then(parametri => {
            dispatch(getWidgetsUtenteSuccess((0, api_rest_1.WidgetsDtoFromJSON)(parametri)));
            loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.WidgetControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).widgetsUtente().then((response) => {
                dispatch(getWidgetsUtenteSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getWidgetsUtente = getWidgetsUtente;
/* get widgets utente - FINE */
/* salva widgets - START */
function salvaWidgetsStart() {
    return {
        type: __1.EReduxActionTypes.SALVA_WIDGETS_START
    };
}
function salvaWidgetsSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.SALVA_WIDGETS_SUCCESS,
        payload
    };
}
const salvaWidgets = (widgets, loaderEnabled = true) => async (dispatch, _, extraArguments) => {
    loaderEnabled && dispatch((0, general_actions_1.setLoader)(true));
    dispatch(salvaWidgetsStart());
    if (extraArguments.mock) {
        dispatch(salvaWidgetsSuccess({ widgets: widgets }));
        loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            // metodo per garantire che non vengano inviati widget duplicati
            const unique = [...new Map(widgets.map((m) => [m.widgetId, m])).values()];
            unique.map((m, index) => m.order = index);
            const request = {
                widgetsDto: {
                    widgets: unique
                        .map(w => {
                        var _a, _b, _c, _d, _e, _f, _g;
                        return ({
                            ...w,
                            option: ((_a = w.option) === null || _a === void 0 ? void 0 : _a.toggle) !== undefined || ((_b = w.option) === null || _b === void 0 ? void 0 : _b.interval) || ((_c = w.option) === null || _c === void 0 ? void 0 : _c.codicerapporto) ? {
                                toggle: ((_d = w.option) === null || _d === void 0 ? void 0 : _d.toggle) !== undefined ? (_e = w.option) === null || _e === void 0 ? void 0 : _e.toggle : true,
                                interval: ((_f = w.option) === null || _f === void 0 ? void 0 : _f.interval) || 0,
                                codicerapporto: ((_g = w.option) === null || _g === void 0 ? void 0 : _g.codicerapporto) || ''
                            } : undefined
                        });
                    })
                        .sort((a, b) => (a.order || 0) - (b.order || 0))
                }
            };
            new api_rest_1.WidgetControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).salvaWidgets(request).then((response) => {
                dispatch(salvaWidgetsSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.salvaWidgets = salvaWidgets;
/* salva widgets - FINE */
/* salva widgets - START */
function getParametriWidgetStart() {
    return {
        type: __1.EReduxActionTypes.GET_PARAMETRI_WIDGET_START
    };
}
function getParametriWidgetSuccess(payload) {
    return {
        type: __1.EReduxActionTypes.GET_PARAMETRI_WIDGET_SUCCESS,
        payload
    };
}
const getParametriWidget = () => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(getParametriWidgetStart());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/parametri_widget.json')).then(parametri => {
            dispatch(getParametriWidgetSuccess((0, api_rest_1.WidgetWrapDtoFromJSON)(parametri)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.WidgetControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).parametriWidget().then((response) => {
                dispatch(getParametriWidgetSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.getParametriWidget = getParametriWidget;
/* salva widgets - FINE */
function resetWidgets() {
    return {
        type: __1.EReduxActionTypes.WIDGETS_RESET
    };
}
exports.resetWidgets = resetWidgets;
