import React from "react";
import img1Click from "../../assets/icons/profile/gestione_rapporti/1click_white.svg";

const ItemOneclick: React.FC<Props> = (props: Props) => {
  return (
    <button
      className="item-one-click-button oneClickView-item-oneClick"
      onClick={props.click}
      aria-label={`Operazione: ${props.descrizione}`}
      style={{ backgroundColor: "transparent", border: "none", padding: 0, cursor: "pointer", }}
      tabIndex={0}
    >
      <p className="txtOneClick-item-oneClick">{props.descrizione}</p>
      <div className="iconView-item-oneClick">
        <img
          src={img1Click}
          alt=""
          style={{ height: 44 }}
        />
      </div>
    </button>
  );
};

interface Props {
  descrizione: string;
  click: () => void
}

export default ItemOneclick;
