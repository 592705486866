import { PagamentoTributiDto } from "@sparkasse/commons";
import { currencyFormat, dateFormat } from "helpers/utilities";
import React from "react";
import BankIcon from "../Icon/icon.component";


const ItemTributi: React.FC<Props> = (props: Props) => {

  const getIcon = (pag: PagamentoTributiDto): string => {
    let icon = '';
    switch (pag.tipoPagamento) {
      case 'BOI': {
        icon = 'pagare_bonifici_bonifici';
        break;
      }
      case 'BOBA': {
        icon = 'pagare_bolletini_bollettino_freccia';
        break;
      }
      case 'F24s': {
        icon = 'pagare_tasse_f24_semplificato';
        break;
      }
    }
    return icon;
  }
  return (
    <div className="item-one-click-button oneClickView-item-oneClick" onClick={() => props.click(props.pagamento)}>
      <div className="d-flex w-100 p-2">
        <div className="w-50">
          <p className="data-scadenza-label mb-1">
            {dateFormat(props.pagamento.scadenza)}
          </p>
          <p>
            {props.pagamento.descrizione}
          </p>
        </div>
        <div className="w-50 d-flex row-reverse">
          <p className="m-0">
            <span className="bold importo-tributo-label">{currencyFormat(props.pagamento.importo)}</span>
            <span className="valuta-importo-tributo"> EUR </span>
          </p>
        </div>
      </div>
      <div className="iconView-item-oneClick">
        <BankIcon
          title={getIcon(props.pagamento)}
          white
        />
      </div>
    </div>
  );
};

interface Props {
  pagamento: PagamentoTributiDto;
  click: Function;
}
export default ItemTributi;
