"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateDtoToJSON = exports.TemplateDtoFromJSONTyped = exports.TemplateDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function TemplateDtoFromJSON(json) {
    return TemplateDtoFromJSONTyped(json, false);
}
exports.TemplateDtoFromJSON = TemplateDtoFromJSON;
function TemplateDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'titolo': !runtime_1.exists(json, 'titolo') ? undefined : json['titolo'],
        'titoloFormattato': !runtime_1.exists(json, 'titoloFormattato') ? undefined : json['titoloFormattato'],
        'motivoRichiesta': !runtime_1.exists(json, 'motivoRichiesta') ? undefined : json['motivoRichiesta'],
        'descrizione': !runtime_1.exists(json, 'descrizione') ? undefined : json['descrizione'],
        'immagine': !runtime_1.exists(json, 'immagine') ? undefined : json['immagine'],
        'campi': !runtime_1.exists(json, 'campi') ? undefined : (json['campi'].map(_1.CampoDtoFromJSON)),
        'categoria': !runtime_1.exists(json, 'categoria') ? undefined : json['categoria'],
        'lingua': !runtime_1.exists(json, 'lingua') ? undefined : json['lingua'],
        'codiceTemplate': !runtime_1.exists(json, 'codiceTemplate') ? undefined : json['codiceTemplate'],
    };
}
exports.TemplateDtoFromJSONTyped = TemplateDtoFromJSONTyped;
function TemplateDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'titolo': value.titolo,
        'titoloFormattato': value.titoloFormattato,
        'motivoRichiesta': value.motivoRichiesta,
        'descrizione': value.descrizione,
        'immagine': value.immagine,
        'campi': value.campi === undefined ? undefined : (value.campi.map(_1.CampoDtoToJSON)),
        'categoria': value.categoria,
        'lingua': value.lingua,
        'codiceTemplate': value.codiceTemplate,
    };
}
exports.TemplateDtoToJSON = TemplateDtoToJSON;
