import { currentLanguage, Environments, getBloccantiMessaggi, getCustomEnv, getMultilingua, getParametriPrelogin, initI18nAction, nextRoute, parametriPrelogin, profiloParametri, resources, selectedAbi, selectedEnv, setI18nSetCurrentLanguage } from "@sparkasse/commons";
import history, { WEBPAGE } from "component/history/history.component";
import IconList from "component/Icon/iconlist.component";
import { initI18n } from "i18n";
import { getItem, setItem } from "index";
import Loading from "pages/loading/loading.view";
import React, { useEffect } from "react";
import ReactGA from 'react-ga';
import { connect } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import "./App.scss";
import Login from "./pages/login/login.view";
import LoginMyBank from "./pages/loginMyBank/loginMyBank.view";
import Logout from "./pages/logout/logout.view";
import LoginCorporate from "./pages/pageCorporate";
import SwitchPage from "./pages/switchPage";
import { AppState } from "./reducers";

const DEFAULT_LANGUAGE = "it";

const PiwikReactRouter = require('piwik-react-router');

/* 
  siteId matomo

  Sparkasse WEB : 1
  Sparkasse WEB NPE : 2
  Civibank WEB : 3
  Civibank WEB NPE : 4
 */
const dashboardId: { [key: string]: number } = {
  'PROD_06045': 1,
  'DEV_06045': 2,
  'QA_06045': 2,
  'ADS_06045': 2,
  'PROD_05484': 3,
  'DEV_05484': 4,
  'QA_05484': 4,
  'ADS_05484': 4,
}
export const piwik = PiwikReactRouter({
  url: 'https://on-banking.sparkasse.it/analytics/',
  siteId: dashboardId[`${getCustomEnv() || selectedEnv}_${selectedAbi}`],
});

const App: React.FC<IProps> = (props) => {

  useEffect(() => {

    const googleAnalyticsId = Environments[selectedEnv].googleAnalyticsId;
    if (googleAnalyticsId) {
      ReactGA.initialize(googleAnalyticsId);
    }

    props.initI18nAction();

    const preloginEnabled =
      history.location.pathname != '/'
      && history.location.pathname != '/login';

    preloginEnabled && props.getParametriPrelogin();
  }, []);

  useEffect(() => {
    if (props.parametriPrelogin) {
      // 1. LANG
      if (Object.keys(props.parametriPrelogin).length > 0) {
        const linguaPrelogin = selectedAbi === '06045' ? props.parametriPrelogin.lingua as string | undefined : 'IT';
        if (linguaPrelogin) {
          getItem('language', true).then(savedLanguage => {
            const linguaBrowser = savedLanguage || navigator.language;
            const linguaBrowserShort = linguaBrowser.length >= 2 ? linguaBrowser.substring(0, 2).toLowerCase() : undefined;
            const lingua = (linguaBrowserShort === 'it' || (linguaBrowserShort === 'de' && selectedAbi === '06045') ? linguaBrowserShort : linguaPrelogin || DEFAULT_LANGUAGE).toLowerCase();
            if (lingua) {
              setItem('language', lingua, true);
              props.setI18nSetCurrentLanguage(lingua);
              if (lingua === props.currentLanguage) {
                // 2. LANG
                props.getMultilingua(false);
              }
            }
            return lingua;
          });
        }
      }
    }
  }, [props.parametriPrelogin]);

  useEffect(() => {
    if (props.currentLanguage && props.parametriPrelogin) {
      // 2. LANG
      props.getMultilingua(false);
    }
  }, [props.currentLanguage]);

  useEffect(() => {
    const linguaDefault = selectedAbi === '06045' ? (props.profiloParametri?.parametri?.["LINGUA_DEFAULT"] as string | undefined)?.toLowerCase() : 'it';
    if (linguaDefault && linguaDefault !== props.currentLanguage) {
      setItem('language', linguaDefault, true);
      props.setI18nSetCurrentLanguage(linguaDefault);
    }
  }, [props.profiloParametri]);

  useEffect(() => {
    if (props.resources?.[props.currentLanguage]) {
      // 3. LANG
      initI18n(props.currentLanguage ?? DEFAULT_LANGUAGE, props.resources);
    }
  }, [props.resources]);

  return (
    <Router history={piwik.connectToHistory(history)}>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/oauthredirect" component={Login} />
        <Route exact path="/mybank" component={Login} />
        <Route exact path="/login-mybank" component={LoginMyBank} />
        <Route exact path="/login-mybank-corporate" component={LoginCorporate} />

        {/*
          Landing page per le webhook di enrollment psd2, rinnovi psd2 e di pagamenti psd2
        */}
        <Route exact path="/psdredirect" component={Loading} />

        {/*
          Landing page per le webhook di firma digitale
        */}
        <Route exact path="/firmadigitale" component={Loading} />

        {/* <Route exact path={WEBPAGE.OnboardingAdmin.url} component={OnboardingAdmin} /> */}

        <Route exact path="/home" component={SwitchPage} />
        <Route exact path="/bonifico-sepa" component={SwitchPage} />
        <Route exact path="/bonifico-detrazione-fiscale" component={SwitchPage} />
        <Route exact path="/bonifico-deposito" component={SwitchPage} />
        <Route exact path="/bonifico-estero" component={SwitchPage} />
        <Route exact path="/bonifico-ripetitivo" component={SwitchPage} />
        <Route exact path="/saldo-e-movimenti" component={SwitchPage} />
        <Route exact path="/saldo-e-movimenti-deposito" component={SwitchPage} />
        <Route exact path="/pagamenti-effettuati" component={SwitchPage} />
        <Route exact path="/bonifici-ripetitivi" component={SwitchPage} />
        <Route exact path="/revoca-bonifico-sepa" component={SwitchPage} />
        <Route exact path="/revoca-bonifico-detrazione-fiscale" component={SwitchPage} />
        <Route exact path="/revoca-bonifico-ripetitivo" component={SwitchPage} />
        <Route exact path="/archivio-bozze" component={SwitchPage} />
        <Route exact path="/mav" component={SwitchPage} />
        <Route exact path="/rav" component={SwitchPage} />
        <Route exact path="/revoca-mav" component={SwitchPage} />
        <Route exact path="/revoca-rav" component={SwitchPage} />
        <Route exact path="/ricarica-telefonica" component={SwitchPage} />
        <Route exact path="/operazioni-one-click" component={SwitchPage} />
        <Route exact path="/rubrica-beneficiari" component={SwitchPage} />
        <Route exact path="/revoca-bollettino-bianco" component={SwitchPage} />
        <Route exact path="/revoca-bollettino-premarcato" component={SwitchPage} />
        <Route exact path="/revoca-bonifico-deposito" component={SwitchPage} />
        <Route exact path="/revoca-bonifico-versamento-deposito" component={SwitchPage} />
        <Route exact path="/bollettino-bancario-freccia" component={SwitchPage} />
        <Route exact path="/revoca-bollettino-freccia" component={SwitchPage} />
        <Route exact path="/bollettino-bianco" component={SwitchPage} />
        <Route exact path="/bollettino-premarcato" component={SwitchPage} />
        <Route exact path="/bollo-aci" component={SwitchPage} />
        <Route exact path="/assegni" component={SwitchPage} />
        <Route exact path="/f24-ordinario" component={SwitchPage} />
        <Route exact path="/f24-accise" component={SwitchPage} />
        <Route exact path="/f24-elide" component={SwitchPage} />
        <Route exact path="/f24-semplificato" component={SwitchPage} />
        <Route exact path="/cbill" component={SwitchPage} />
        <Route exact path="/revoca-f24-ordinario" component={SwitchPage} />
        <Route exact path="/revoca-f24-accise" component={SwitchPage} />
        <Route exact path="/revoca-f24-elide" component={SwitchPage} />
        <Route exact path="/revoca-f24-semplificato" component={SwitchPage} />
        <Route exact path="/mandati-sdd" component={SwitchPage} />
        <Route exact path="/anatocismo" component={SwitchPage} />
        <Route exact path="/modifica-bonifico-ripetitivo" component={SwitchPage} />
        <Route exact path="/riattiva-bonifico-ripetitivo" component={SwitchPage} />
        <Route exact path="/sospendi-bonifico-ripetitivo" component={SwitchPage} />
        <Route exact path="/libretti-risparmio" component={SwitchPage} />
        <Route exact path="/ricevute-bancarie" component={SwitchPage} />
        <Route exact path="/polizze" component={SwitchPage} />
        <Route exact path="/piano-mutui" component={SwitchPage} />
        <Route exact path="/piano-prestiti" component={SwitchPage} />
        <Route exact path="/blocco-utente" component={SwitchPage} />
        <Route exact path="/bancomat-pay" component={SwitchPage} />
        <Route exact path="/carta-credito" component={SwitchPage} />
        <Route exact path="/conto-deposito" component={SwitchPage} />
        <Route exact path="/massimali-utente" component={SwitchPage} />
        <Route exact path="/time-deposit" component={SwitchPage} />
        <Route exact path="/nuovo-time-deposit" component={SwitchPage} />
        <Route exact path="/estingui-time-deposit" component={SwitchPage} />
        <Route exact path="/scadenza-titoli" component={SwitchPage} />
        <Route exact path="/bancomat-pay-massimali" component={SwitchPage} />
        <Route exact path="/bancomat-pay-attivazione" component={SwitchPage} />
        <Route exact path="/bancomat-pay-disattiva" component={SwitchPage} />
        <Route exact path="/documenti" component={SwitchPage} />
        <Route exact path="/contratti" component={SwitchPage} />
        <Route exact path="/ricarica-alto-adige-pass" component={SwitchPage} />
        <Route exact path="/estingui-time-deposit" component={SwitchPage} />
        <Route exact path="/bancomat-pay-gestione-rapporti" component={SwitchPage} />
        <Route exact path="/variazione-canali" component={SwitchPage} />
        <Route exact path="/blocco-carte" component={SwitchPage} />
        <Route exact path="/elenco-rapporti" component={SwitchPage} />
        <Route exact path="/dettaglio-rapporto" component={SwitchPage} />
        <Route exact path="/consensi-terze-parti" component={SwitchPage} />
        <Route exact path="/rapporti-predefiniti" component={SwitchPage} />
        <Route exact path="/gestione-credenziali" component={SwitchPage} />
        <Route exact path="/imposta-alias" component={SwitchPage} />
        <Route exact path="/modifica-password" component={SwitchPage} />
        <Route exact path="/modifica-pin" component={SwitchPage} />
        <Route exact path="/cerca-titolo" component={SwitchPage} />
        <Route exact path="/dati-personali" component={SwitchPage} />
        <Route exact path="/questionario-adeguata-verifica" component={SwitchPage} />
        <Route exact path="/aggiorna-questionario-adeguata-verifica" component={SwitchPage} />


        {/*
          HOTFIX 11/03/22 (MARRA)
          Nascondere i pulsanti di MODIFICA CONTATTI per prevenire fishing Sparkasse
        */}
        {/* <Route exact path="/modifica-contatti" component={SwitchPage} /> */}
        <Route exact path="/modifica-residenza" component={SwitchPage} />
        <Route exact path="/cerca-titoli-preferiti" component={SwitchPage} />
        <Route exact path="/consensi-revoca" component={SwitchPage} />
        <Route exact path="/dati-filiale" component={SwitchPage} />
        <Route exact path="/post-login-messages" component={SwitchPage} />
        <Route exact path="/bonifico-my-bank" component={SwitchPage} />
        <Route exact path="/bilancio" component={SwitchPage} />
        <Route exact path="/notifica-push" component={SwitchPage} />
        <Route exact path="/patrimonio" component={SwitchPage} />
        <Route exact path="/faq" component={SwitchPage} />
        <Route exact path="/time-deposit-box" component={SwitchPage} />
        <Route exact path="/contatti-filiale-virtuale" component={SwitchPage} />
        <Route exact path="/estingui-storico-ordine" component={SwitchPage} />
        <Route exact path="/nuova-richiesta-filiale-virtuale" component={SwitchPage} />
        <Route exact path="/icons-list" component={IconList} />
        <Route exact path="/acquista-titolo" component={SwitchPage} />
        <Route exact path="/gestione-polizze-aquisto" component={SwitchPage} />
        <Route exact path="/pct-sottoscrizione" component={SwitchPage} />
        <Route exact path="/vendita-titolo" component={SwitchPage} />
        <Route exact path="/dossier-titoli" component={SwitchPage} />
        <Route exact path="/rapporti-estinti" component={SwitchPage} />
        <Route exact path="/elenco-richieste-filiale-virtuale" component={SwitchPage} />

        <Route path={WEBPAGE.Anatocismo.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.ArchivioBozze.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.Assegni.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.BancomatPay.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.BancomatPay.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.BancomatPayDisattiva.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.BancomatPayGestioneRapporti.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.BancomatPayMassimali.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.Bilancio.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.BloccoCarte.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.BloccoUtente.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.BollettinoBancarioFreccia.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.BollettinoBianco.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.BollettinoPremarcato.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.BolloAci.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.BonificiRipetitivi.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.BonificoDeposito.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.BonificoVersamentoDeposito.url} exact component={SwitchPage} />

        <Route path={WEBPAGE.BonificoDetrazioneFiscale.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.BonificoEstero.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.BonificoMyBank.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.BonificoRipetitivo.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.BonificoSepa.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.CartaCredito.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.Cbill.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.CercaTitoliPreferiti.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.CercaTitolo.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.ConsensiRevoca.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.ConsensiTerzeParti.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.Contratti.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.DatiFiliale.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.DatiPersonali.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.Dettaglio.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.DettaglioRapporto.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.Documenti.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.ElencoRapporti.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.ContattiFilialeVirtuale.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.ElencoSmartcard.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.EstinguiStoricoOrdine.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.EstinguiTimeDeposit.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.F24Accise.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.F24Elide.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.F24Ordinario.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.F24Semplificato.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.Faq.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.GestioneCredenziali.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.Home.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.ImpostaAlias.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.LibrettiRisparmio.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.MandatiSdd.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.MassimaliUtente.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.Mav.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.Mercato.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.ModificaBonificoRipetitivo.url} exact component={SwitchPage} />
        {/*
          HOTFIX 11/03/22 (MARRA)
          Nascondere i pulsanti di MODIFICA CONTATTI per prevenire fishing Sparkasse
        */}
        {/* <Route path={WEBPAGE.ModificaContatti.url} exact component={SwitchPage} /> */}
        <Route path={WEBPAGE.ModificaPassword.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.ModificaPin.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.ModificaResidenza.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.NotificePush.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.NuovoTimeDeposit.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.OperazioniOneClick.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.PagamentiEffettuati.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.DettaglioPagamento.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.PagamentiEffettuatiRapportiEstinti.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.DettaglioPagamentoRapportiEstinti.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.Patrimonio.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.PianoMutui.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.PianoPrestiti.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.Polizze.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.PostLoginMessages.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.RapportiPredefiniti.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.Rav.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.RevocaBollettinoBianco.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.RevocaBollettinoFreccia.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.RevocaBollettinoPremarcato.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.RevocaBonificoDeposito.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.RevocaBonificoDetrazioneFiscale.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.RevocaBonificoRipetitivo.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.RevocaBonificoSepa.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.RevocaF24Accise.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.RevocaF24Elide.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.RevocaF24Ordinario.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.RevocaF24Semplificato.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.RevocaMav.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.RevocaRav.url} component={SwitchPage} />
        <Route path={WEBPAGE.RiattivaBonificoRipetitivo.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.RicaricaSmartcard.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.RicaricaAltoAdigePass.url} component={SwitchPage} />
        <Route path={WEBPAGE.RicaricaTelefonica.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.RicevuteBancarie.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.RubricaBeneficiari.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.SaldoEMovimenti.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.SaldoEMovimentiDeposito.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.ScadenzaTitoli.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.SospendiBonificoRipetitivo.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.StoricoOrdine.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.DettaglioOrdine.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.TimeDeposit.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.TimeDepositBox.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.VariazioneCanali.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.GestionePolizze.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.GestionePolizzeAquisto.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.PrestitoCofidis.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.PrestitoNuovo.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.RichiediFidoIsiDispo.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.ElencoTributiLocali.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.ElencoPagamentiContoTerzi.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.DettaglioTributiLocali.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.PrestitoCofidis.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.PrestitoNuovo.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.PctSottoscrizione.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.PolizzeInvestimento.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.AcquistaTitolo.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.VenditaTitolo.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.CatalogoProdotti.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.DettaglioCatalogoProdotti.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.DossierTitoli.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.Rendimenti.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.FirmaProposte.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.NuovoContatto.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.DettaglioContatto.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.ModificaContatto.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.GestionePolizzeAquistoPet.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.GestionePolizzeAquistoTravel.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.Mifid.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.MifidAggiornamento.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.ElencoAppuntamenti.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.NuovoAppuntamento.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.GestioneAppuntamento.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.FondiPaperless.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.AggiornaDocumento.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.ElencoRichiesteFilialeVirtualeView.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.QuestionarioAdeguataVerifica.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.ElencoUltimiAccessi.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.ZainettoFiscale.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.CartaRicaricabile.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.CartaRicaricabileMovimenti.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.CartaNexiDebit.url} exact component={SwitchPage} />
        <Route path={WEBPAGE.AcquistoNexiDebit.url} exact component={SwitchPage} />

        {/* to be 404 */}
        <Route exact path="*" component={Login} />
        {/* if we want to always redirect to Cedacri login
          <Redirect exact from="*" to={{ pathname: "/", search: "?state=public" }} />
        */}
      </Switch>
    </Router>
  );
};

const mapStateToProps = (state: AppState) => ({
  nextRoute: nextRoute(state.general),
  resources: resources(state.i18n),
  currentLanguage: currentLanguage(state.i18n),
  parametriPrelogin: parametriPrelogin(state.prelogin),
  profiloParametri: profiloParametri(state.profilo)
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      initI18nAction,
      getMultilingua,
      getBloccantiMessaggi,
      getParametriPrelogin,
      setI18nSetCurrentLanguage
    },
    dispatch
  );

export interface IProps extends ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps> { };

export default connect(mapStateToProps, mapDispatchToProps)(App);
