"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.InfoNascitaDtoToJSON = exports.InfoNascitaDtoFromJSONTyped = exports.InfoNascitaDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function InfoNascitaDtoFromJSON(json) {
    return InfoNascitaDtoFromJSONTyped(json, false);
}
exports.InfoNascitaDtoFromJSON = InfoNascitaDtoFromJSON;
function InfoNascitaDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'data': !runtime_1.exists(json, 'data') ? undefined : (new Date(json['data'])),
        'luogo': !runtime_1.exists(json, 'luogo') ? undefined : json['luogo'],
        'provincia': !runtime_1.exists(json, 'provincia') ? undefined : json['provincia'],
        'localita': !runtime_1.exists(json, 'localita') ? undefined : json['localita'],
    };
}
exports.InfoNascitaDtoFromJSONTyped = InfoNascitaDtoFromJSONTyped;
function InfoNascitaDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'data': value.data === undefined ? undefined : (value.data.toISOString()),
        'luogo': value.luogo,
        'provincia': value.provincia,
        'localita': value.localita,
    };
}
exports.InfoNascitaDtoToJSON = InfoNascitaDtoToJSON;
