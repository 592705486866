"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OnBoardSessionEntityToJSON = exports.OnBoardSessionEntityFromJSONTyped = exports.OnBoardSessionEntityFromJSON = void 0;
const runtime_1 = require("../runtime");
function OnBoardSessionEntityFromJSON(json) {
    return OnBoardSessionEntityFromJSONTyped(json, false);
}
exports.OnBoardSessionEntityFromJSON = OnBoardSessionEntityFromJSON;
function OnBoardSessionEntityFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'ttl': !runtime_1.exists(json, 'ttl') ? undefined : json['ttl'],
        'status': !runtime_1.exists(json, 'status') ? undefined : json['status'],
        'ndg': !runtime_1.exists(json, 'ndg') ? undefined : json['ndg'],
        'codiceUtente': !runtime_1.exists(json, 'codiceUtente') ? undefined : json['codiceUtente'],
    };
}
exports.OnBoardSessionEntityFromJSONTyped = OnBoardSessionEntityFromJSONTyped;
function OnBoardSessionEntityToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'ttl': value.ttl,
        'status': value.status,
        'ndg': value.ndg,
        'codiceUtente': value.codiceUtente,
    };
}
exports.OnBoardSessionEntityToJSON = OnBoardSessionEntityToJSON;
