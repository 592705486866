"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormPNSDtoFromJSONTyped = void 0;
const helpers_1 = require("../helpers");
function FormPNSDtoFromJSONTyped(json) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'registrationID': !(0, helpers_1.exists)(json, 'registrationID') ? undefined : json.registrationID
    };
}
exports.FormPNSDtoFromJSONTyped = FormPNSDtoFromJSONTyped;
