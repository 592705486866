"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mutuiReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const initialState = {
    parametri: (0, api_rest_1.ParametriMutuiDtoFromJSON)({}),
    form: {},
    elencoProgressiviMutui: [],
    rateMoraMutui: (0, api_rest_1.RateFinanziamentoDtoFromJSON)({}),
    pianoAmmortamentoMutui: (0, api_rest_1.RateFinanziamentoDtoFromJSON)({}),
    ratePagateMutui: (0, api_rest_1.RateFinanziamentoDtoFromJSON)({}),
    rateResidueMutui: (0, api_rest_1.RateFinanziamentoDtoFromJSON)({}),
    dashboardMutui: (0, api_rest_1.FinanziamentiDashboardDtoFromJSON)({})
};
const mutuiReducer = (state = initialState, action) => {
    switch (action.type) {
        case __1.EReduxActionTypes.MUTUI_GET_PARAMETRI_SUCCESS:
            return {
                ...initialState,
                parametri: action.payload
            };
        case __1.EReduxActionTypes.MUTUI_GET_ELENCO_PROGERSSIVI_SUCCESS:
            return {
                ...state,
                elencoProgressiviMutui: action.payload
            };
        case __1.EReduxActionTypes.MUTUI_SET_RAPPORTO_SELEZIONATO:
            return {
                ...state,
                form: {
                    ...state.form,
                    rapportoSelezionato: action.payload
                }
            };
        case __1.EReduxActionTypes.MUTUI_GET_PIANO_AMMORTAMENTO_SUCCESS:
            return {
                ...state,
                pianoAmmortamentoMutui: action.payload
            };
        case __1.EReduxActionTypes.MUTUI_GET_RATE_MORA_SUCCESS:
            return {
                ...state,
                rateMoraMutui: action.payload
            };
        case __1.EReduxActionTypes.MUTUI_RATE_RESIDUE_SUCCESS:
            return {
                ...state,
                rateResidueMutui: action.payload
            };
        case __1.EReduxActionTypes.MUTUI_GET_RATE_PAGATE_SUCCESS:
            return {
                ...state,
                ratePagateMutui: action.payload
            };
        case __1.EReduxActionTypes.MUTUI_GET_DASHBOARD_SUCCESS:
            return {
                ...state,
                dashboardMutui: action.payload
            };
        case __1.EReduxActionTypes.MUTUI_RESET_RATE:
            return {
                ...state,
                ratePagateMutui: initialState.ratePagateMutui,
                rateMoraMutui: initialState.rateMoraMutui,
                rateResidueMutui: initialState.rateResidueMutui
            };
        case __1.EReduxActionTypes.MUTUI_RESET_DASHBOARD:
            return {
                ...state,
                dashboardMutui: initialState.dashboardMutui
            };
        case __1.EReduxActionTypes.MUTUI_RESET:
            return {
                ...initialState
            };
        case __1.EReduxActionTypes.GENERAL_REFRESH_WIDGETS: // action invocata tramite pull to refresh
            return {
                ...state,
                parametri: initialState.parametri,
                dashboardMutui: initialState.dashboardMutui
            };
        case __1.EReduxActionTypes.RESET_ALL: // action invocata dalla logout
            return {
                ...initialState
            };
        default:
            return state;
    }
};
exports.mutuiReducer = mutuiReducer;
exports.default = exports.mutuiReducer;
