"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DatesToJSON = exports.DatesFromJSONTyped = exports.DatesFromJSON = void 0;
const runtime_1 = require("../runtime");
function DatesFromJSON(json) {
    return DatesFromJSONTyped(json, false);
}
exports.DatesFromJSON = DatesFromJSON;
function DatesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'emissione': !runtime_1.exists(json, 'emissione') ? undefined : (new Date(json['emissione'])),
        'decorrenza': !runtime_1.exists(json, 'decorrenza') ? undefined : (new Date(json['decorrenza'])),
        'scadenza': !runtime_1.exists(json, 'scadenza') ? undefined : (new Date(json['scadenza'])),
        'aggiornamento': !runtime_1.exists(json, 'aggiornamento') ? undefined : (new Date(json['aggiornamento'])),
    };
}
exports.DatesFromJSONTyped = DatesFromJSONTyped;
function DatesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'emissione': value.emissione === undefined ? undefined : (value.emissione.toISOString()),
        'decorrenza': value.decorrenza === undefined ? undefined : (value.decorrenza.toISOString()),
        'scadenza': value.scadenza === undefined ? undefined : (value.scadenza.toISOString()),
        'aggiornamento': value.aggiornamento === undefined ? undefined : (value.aggiornamento.toISOString()),
    };
}
exports.DatesToJSON = DatesToJSON;
