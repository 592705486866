"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.profiloUltimoAccesso = exports.nominativoUtente = exports.datiUtente = exports.bloccoDatiBinario = exports.jwtCambioPin = exports.preferenzeUtente = exports.profiloTour = exports.infoSessione = exports.bannerProfiloParametri = exports.profiloParametri = exports.formValidModificaPIN = exports.credenzialiModificaPINAsFiltro = exports.formValidModificaPassword = exports.credenzialiModificaPassword = exports.credenzialiModificaPasswordAsFiltro = exports.disposizioneVirtualeGestioneCredenzialiImpostaAlias = exports.formDatiAnagraficiAsFilter = exports.esitoModificaPassword = exports.infoDispositivaModificaPassword = exports.esitoImpostaAlias = exports.infoDispositivaImpostaAlias = exports.formGestioneCredenziali = exports.profilo = exports.infoDispositivaBloccoUtente = exports.esitoBloccoUtente = void 0;
const reselect_1 = require("reselect");
const lunghezzaIntervalliVerificaAttivita = 15;
const intervalliVerificaPrimaDiScadenza = 4;
const profiloReducer = (state) => state;
exports.esitoBloccoUtente = (0, reselect_1.createSelector)(profiloReducer, (state) => {
    return state.esito;
});
exports.infoDispositivaBloccoUtente = (0, reselect_1.createSelector)(profiloReducer, (state) => {
    return state.infoDispositiva;
});
exports.profilo = (0, reselect_1.createSelector)(profiloReducer, (state) => {
    return state.profilo;
});
exports.formGestioneCredenziali = (0, reselect_1.createSelector)(profiloReducer, (state) => {
    return state.form;
});
exports.infoDispositivaImpostaAlias = (0, reselect_1.createSelector)(profiloReducer, (state) => {
    return state.infoDispositivaImpostaAlias;
});
exports.esitoImpostaAlias = (0, reselect_1.createSelector)(profiloReducer, (state) => {
    return state.esitoImpostaAlias;
});
exports.infoDispositivaModificaPassword = (0, reselect_1.createSelector)(profiloReducer, (state) => {
    return state.infoDispositivaModificaPassword;
});
exports.esitoModificaPassword = (0, reselect_1.createSelector)(profiloReducer, (state) => {
    return state.esitoModificaPassword;
});
exports.formDatiAnagraficiAsFilter = (0, reselect_1.createSelector)(profiloReducer, (state) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    return {
        idVirtuale: (_a = state.form) === null || _a === void 0 ? void 0 : _a.idVirtuale,
        codiceAlias: (_b = state.form) === null || _b === void 0 ? void 0 : _b.codiceAlias,
        modoAutenticazione: (_c = state.form) === null || _c === void 0 ? void 0 : _c.modoAutenticazione,
        cellulare: (_d = state.form) === null || _d === void 0 ? void 0 : _d.cellulare,
        email: (_e = state.form) === null || _e === void 0 ? void 0 : _e.email,
        telefonoFisso: (_f = state.form) === null || _f === void 0 ? void 0 : _f.telefonoFisso,
        indirizzoResidenza: (_g = state.form) === null || _g === void 0 ? void 0 : _g.indirizzoResidenza,
        indirizzoDomicilio: (_h = state.form) === null || _h === void 0 ? void 0 : _h.indirizzoDomicilio,
        rapporti: (_j = state.form) === null || _j === void 0 ? void 0 : _j.rapporti
    };
});
exports.disposizioneVirtualeGestioneCredenzialiImpostaAlias = (0, reselect_1.createSelector)(profiloReducer, (state) => {
    return state.disposizioneVirtualeGestioneCredenziali;
});
exports.credenzialiModificaPasswordAsFiltro = (0, reselect_1.createSelector)(profiloReducer, (state) => {
    return {
        vecchiaCredenziale: state.form.oldPassword,
        nuovaCredenziale: state.form.newPassword,
        confermaNuovaCredenziale: state.form.confirmPassword,
        credenziali: state.form.credentials
    };
});
exports.credenzialiModificaPassword = (0, reselect_1.createSelector)(profiloReducer, (state) => {
    return state.credenziali;
});
exports.formValidModificaPassword = (0, reselect_1.createSelector)(profiloReducer, (state) => {
    let formValid = false;
    if (!state.form.oldPasswordError &&
        !state.form.newPasswordError &&
        !state.form.confirmPasswordError) {
        formValid = true;
    }
    return formValid;
});
exports.credenzialiModificaPINAsFiltro = (0, reselect_1.createSelector)(profiloReducer, (state) => {
    return {
        vecchiaCredenziale: state.form.oldPIN,
        nuovaCredenziale: state.form.newPIN,
        confermaNuovaCredenziale: state.form.confirmPIN,
        credenziali: state.form.credentials
    };
});
exports.formValidModificaPIN = (0, reselect_1.createSelector)(profiloReducer, (state) => {
    let formValid = false;
    if (!state.form.oldPINError &&
        !state.form.newPINError &&
        !state.form.confirmPINError) {
        formValid = true;
    }
    return formValid;
});
exports.profiloParametri = (0, reselect_1.createSelector)(profiloReducer, (state) => {
    return state.parametri;
});
exports.bannerProfiloParametri = (0, reselect_1.createSelector)(profiloReducer, (state) => {
    var _a, _b, _c;
    return ((_c = (_b = (_a = state.parametri) === null || _a === void 0 ? void 0 : _a.parametri) === null || _b === void 0 ? void 0 : _b['WCM.CONF']) === null || _c === void 0 ? void 0 : _c.banners) || [];
});
exports.infoSessione = (0, reselect_1.createSelector)(profiloReducer, (state) => {
    const { lunghezzaSessione, intervalloCorrente, intervalliInattivi } = state.infoSessione;
    const _intervalloSessioneScaduta = lunghezzaSessione / lunghezzaIntervalliVerificaAttivita; // 300 / 15 = 20
    const _intervalloSessioneInScadenza = _intervalloSessioneScaduta - intervalliVerificaPrimaDiScadenza; // 20 - 4 = 16
    return {
        ...state.infoSessione,
        lunghezzaIntervalli: lunghezzaIntervalliVerificaAttivita,
        intervalloSessioneScaduta: _intervalloSessioneScaduta,
        intervalloSessioneInScadenza: _intervalloSessioneInScadenza,
        sessioneInScadenza: (intervalloCorrente || 0) >= _intervalloSessioneInScadenza,
        sessioneInattiva: (intervalliInattivi || 0) >= _intervalloSessioneInScadenza && (intervalliInattivi || 0) < _intervalloSessioneScaduta,
        logoutSessione: (intervalliInattivi || 0) >= _intervalloSessioneScaduta,
    };
});
exports.profiloTour = (0, reselect_1.createSelector)(profiloReducer, (state) => {
    return state.tour;
});
exports.preferenzeUtente = (0, reselect_1.createSelector)(profiloReducer, (state) => {
    return state.preferenzeUtente;
});
exports.jwtCambioPin = (0, reselect_1.createSelector)(profiloReducer, (state) => {
    return state.jwtCambioPin;
});
exports.bloccoDatiBinario = (0, reselect_1.createSelector)(profiloReducer, (state) => {
    return state.bloccoDatiBinario;
});
exports.datiUtente = (0, reselect_1.createSelector)(profiloReducer, (state) => {
    var _a;
    return (_a = state.profilo) === null || _a === void 0 ? void 0 : _a.datiUtente;
});
exports.nominativoUtente = (0, reselect_1.createSelector)(profiloReducer, (state) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    return ((_d = (_c = (_b = (_a = state.profilo) === null || _a === void 0 ? void 0 : _a.datiUtente) === null || _b === void 0 ? void 0 : _b.datiPersonali) === null || _c === void 0 ? void 0 : _c.anagrafe) === null || _d === void 0 ? void 0 : _d.nomeIntestazione) + ' ' + ((_h = (_g = (_f = (_e = state.profilo) === null || _e === void 0 ? void 0 : _e.datiUtente) === null || _f === void 0 ? void 0 : _f.datiPersonali) === null || _g === void 0 ? void 0 : _g.anagrafe) === null || _h === void 0 ? void 0 : _h.cognomeIntestazione);
});
exports.profiloUltimoAccesso = (0, reselect_1.createSelector)(profiloReducer, (state) => {
    var _a;
    return (_a = state.profilo) === null || _a === void 0 ? void 0 : _a.ultimoAccesso;
});
