"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParametriLimitiOperativiDtoToJSON = exports.ParametriLimitiOperativiDtoFromJSONTyped = exports.ParametriLimitiOperativiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function ParametriLimitiOperativiDtoFromJSON(json) {
    return ParametriLimitiOperativiDtoFromJSONTyped(json, false);
}
exports.ParametriLimitiOperativiDtoFromJSON = ParametriLimitiOperativiDtoFromJSON;
function ParametriLimitiOperativiDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nomeIntestazione': !runtime_1.exists(json, 'nomeIntestazione') ? undefined : json['nomeIntestazione'],
        'cognomeIntestazione': !runtime_1.exists(json, 'cognomeIntestazione') ? undefined : json['cognomeIntestazione'],
        'parametri': !runtime_1.exists(json, 'parametri') ? undefined : json['parametri'],
    };
}
exports.ParametriLimitiOperativiDtoFromJSONTyped = ParametriLimitiOperativiDtoFromJSONTyped;
function ParametriLimitiOperativiDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'nomeIntestazione': value.nomeIntestazione,
        'cognomeIntestazione': value.cognomeIntestazione,
        'parametri': value.parametri,
    };
}
exports.ParametriLimitiOperativiDtoToJSON = ParametriLimitiOperativiDtoToJSON;
