"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assegniReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const assegni_types_1 = require("./assegni.types");
const initialState = {
    assegni: (0, api_rest_1.AssegniItaliaDtoFromJSON)({}),
    carnetSelezionato: undefined,
    parametri: (0, api_rest_1.ParametriAssegniDtoFromJSON)({}),
    filter: (0, assegni_types_1.FilterAssegniFromJSONTyped)({}),
    pdfAssegni: undefined,
    pdfDettaglioAssegno: undefined,
    excelAssegni: undefined
};
const assegniReducer = (state = initialState, action) => {
    switch (action.type) {
        case __1.EReduxActionTypes.ASSEGNI_MOSTRA_CARNET_ESAURITI:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    mostraCarnetEsauriti: action.payload
                }
            };
        case __1.EReduxActionTypes.GET_ASSEGNI_SUCCESS:
            return {
                ...state,
                assegni: action.payload
            };
        case __1.EReduxActionTypes.ASSEGNI_GET_PARAMETRI_SUCCESS:
            return {
                ...state,
                parametri: action.payload
            };
        case __1.EReduxActionTypes.ASSEGNI_CARNET_SELEZIONATO:
            return {
                ...state,
                carnetSelezionato: action.payload
            };
        case __1.EReduxActionTypes.ASSEGNI_RESET_CARNET_SELEZIONATO:
            return {
                ...state,
                carnetSelezionato: undefined,
            };
        case __1.EReduxActionTypes.ASSEGNI_RESET_FILTRI:
            return {
                ...state,
                filter: initialState.filter
            };
        case __1.EReduxActionTypes.ASSEGNI_RAPPORTO_SELEZIONATO:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    rapportoSelezionato: action.payload
                }
            };
        case __1.EReduxActionTypes.ASSEGNI_ESPORTA_PDF_SUCCESS:
            return {
                ...state,
                pdfAssegni: action.payload
            };
        case __1.EReduxActionTypes.ASSEGNI_ESPORTA_DETTAGLIO_PDF_SUCCESS:
            return {
                ...state,
                pdfDettaglioAssegno: action.payload
            };
        case __1.EReduxActionTypes.ASSEGNI_ESPORTA_EXCEL_SUCCESS:
            return {
                ...state,
                excelAssegni: action.payload
            };
        case __1.EReduxActionTypes.ASSEGNI_RESET:
            return {
                ...initialState
            };
        case __1.EReduxActionTypes.RESET_ALL: // action invocata dalla logout
            return {
                ...initialState
            };
        default:
            return state;
    }
};
exports.assegniReducer = assegniReducer;
