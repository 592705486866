"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DatiStoriciQuotazioneDtoToJSON = exports.DatiStoriciQuotazioneDtoFromJSONTyped = exports.DatiStoriciQuotazioneDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function DatiStoriciQuotazioneDtoFromJSON(json) {
    return DatiStoriciQuotazioneDtoFromJSONTyped(json, false);
}
exports.DatiStoriciQuotazioneDtoFromJSON = DatiStoriciQuotazioneDtoFromJSON;
function DatiStoriciQuotazioneDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'time': !runtime_1.exists(json, 'time') ? undefined : json['time'],
        'data': !runtime_1.exists(json, 'data') ? undefined : (new Date(json['data'])),
        'apertura': !runtime_1.exists(json, 'apertura') ? undefined : json['apertura'],
        'massimo': !runtime_1.exists(json, 'massimo') ? undefined : json['massimo'],
        'minimo': !runtime_1.exists(json, 'minimo') ? undefined : json['minimo'],
        'chiusura': !runtime_1.exists(json, 'chiusura') ? undefined : json['chiusura'],
        'ultimoPrezzo': !runtime_1.exists(json, 'ultimoPrezzo') ? undefined : json['ultimoPrezzo'],
        'prezzoRif': !runtime_1.exists(json, 'prezzoRif') ? undefined : json['prezzoRif'],
        'prezzoUff': !runtime_1.exists(json, 'prezzoUff') ? undefined : json['prezzoUff'],
        'volume': !runtime_1.exists(json, 'volume') ? undefined : json['volume'],
        'controvalore': !runtime_1.exists(json, 'controvalore') ? undefined : json['controvalore'],
    };
}
exports.DatiStoriciQuotazioneDtoFromJSONTyped = DatiStoriciQuotazioneDtoFromJSONTyped;
function DatiStoriciQuotazioneDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'time': value.time,
        'data': value.data === undefined ? undefined : (value.data.toISOString()),
        'apertura': value.apertura,
        'massimo': value.massimo,
        'minimo': value.minimo,
        'chiusura': value.chiusura,
        'ultimoPrezzo': value.ultimoPrezzo,
        'prezzoRif': value.prezzoRif,
        'prezzoUff': value.prezzoUff,
        'volume': value.volume,
        'controvalore': value.controvalore,
    };
}
exports.DatiStoriciQuotazioneDtoToJSON = DatiStoriciQuotazioneDtoToJSON;
