"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RicaricaCarteDtoStatoDisposizioneEnum = exports.RicaricaCarteDtoToJSON = exports.RicaricaCarteDtoFromJSONTyped = exports.RicaricaCarteDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function RicaricaCarteDtoFromJSON(json) {
    return RicaricaCarteDtoFromJSONTyped(json, false);
}
exports.RicaricaCarteDtoFromJSON = RicaricaCarteDtoFromJSON;
function RicaricaCarteDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'contoAddebito': json['contoAddebito'],
        'pan': json['pan'],
        'importo': json['importo'],
        'divisa': !runtime_1.exists(json, 'divisa') ? undefined : json['divisa'],
        'condivisione': !runtime_1.exists(json, 'condivisione') ? undefined : (json['condivisione'].map(_1.CondivisioneDtoFromJSON)),
        'statoDisposizione': !runtime_1.exists(json, 'statoDisposizione') ? undefined : json['statoDisposizione'],
        'dataOraCompilazione': !runtime_1.exists(json, 'dataOraCompilazione') ? undefined : (new Date(json['dataOraCompilazione'])),
        'dataOraRisposta': !runtime_1.exists(json, 'dataOraRisposta') ? undefined : (new Date(json['dataOraRisposta'])),
        'rrn': !runtime_1.exists(json, 'rrn') ? undefined : json['rrn'],
        'annoRrn': !runtime_1.exists(json, 'annoRrn') ? undefined : json['annoRrn'],
        'commissioneOperazione': !runtime_1.exists(json, 'commissioneOperazione') ? undefined : json['commissioneOperazione'],
        'commissioneServizio': !runtime_1.exists(json, 'commissioneServizio') ? undefined : json['commissioneServizio'],
        'totaleAddebito': !runtime_1.exists(json, 'totaleAddebito') ? undefined : json['totaleAddebito'],
        'importoResiduo': !runtime_1.exists(json, 'importoResiduo') ? undefined : json['importoResiduo'],
        'divisaResiduo': !runtime_1.exists(json, 'divisaResiduo') ? undefined : json['divisaResiduo'],
        'statoCarta': !runtime_1.exists(json, 'statoCarta') ? undefined : json['statoCarta'],
        'rrnVerifica': !runtime_1.exists(json, 'rrnVerifica') ? undefined : json['rrnVerifica'],
        'idRichiesta': !runtime_1.exists(json, 'idRichiesta') ? undefined : json['idRichiesta'],
        'dataOraAutorizzazione': !runtime_1.exists(json, 'dataOraAutorizzazione') ? undefined : (new Date(json['dataOraAutorizzazione'])),
        'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
        'esito': !runtime_1.exists(json, 'esito') ? undefined : json['esito'],
        'tipoCarta': json['tipoCarta'],
        'nomeTitolare': !runtime_1.exists(json, 'nomeTitolare') ? undefined : json['nomeTitolare'],
        'bozza': !runtime_1.exists(json, 'bozza') ? undefined : _1.BozzaDtoFromJSON(json['bozza']),
        'inviato': !runtime_1.exists(json, 'inviato') ? undefined : _1.InviatoDtoFromJSON(json['inviato']),
        'idBozzaCollegata': !runtime_1.exists(json, 'idBozzaCollegata') ? undefined : json['idBozzaCollegata'],
        'idOneClickCollegata': !runtime_1.exists(json, 'idOneClickCollegata') ? undefined : json['idOneClickCollegata'],
        'aggiungiRubrica': !runtime_1.exists(json, 'aggiungiRubrica') ? undefined : json['aggiungiRubrica'],
        'aggiungi1click': !runtime_1.exists(json, 'aggiungi1click') ? undefined : json['aggiungi1click'],
        'aggiungiWhitelist': !runtime_1.exists(json, 'aggiungiWhitelist') ? undefined : json['aggiungiWhitelist'],
        'contoAddebitoAlias': !runtime_1.exists(json, 'contoAddebitoAlias') ? undefined : json['contoAddebitoAlias'],
        'contoAddebitoIntestazione': !runtime_1.exists(json, 'contoAddebitoIntestazione') ? undefined : json['contoAddebitoIntestazione'],
        'iban': !runtime_1.exists(json, 'iban') ? undefined : json['iban'],
    };
}
exports.RicaricaCarteDtoFromJSONTyped = RicaricaCarteDtoFromJSONTyped;
function RicaricaCarteDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'contoAddebito': value.contoAddebito,
        'pan': value.pan,
        'importo': value.importo,
        'divisa': value.divisa,
        'condivisione': value.condivisione === undefined ? undefined : (value.condivisione.map(_1.CondivisioneDtoToJSON)),
        'statoDisposizione': value.statoDisposizione,
        'dataOraCompilazione': value.dataOraCompilazione === undefined ? undefined : (value.dataOraCompilazione.toISOString()),
        'dataOraRisposta': value.dataOraRisposta === undefined ? undefined : (value.dataOraRisposta.toISOString()),
        'rrn': value.rrn,
        'annoRrn': value.annoRrn,
        'commissioneOperazione': value.commissioneOperazione,
        'commissioneServizio': value.commissioneServizio,
        'totaleAddebito': value.totaleAddebito,
        'importoResiduo': value.importoResiduo,
        'divisaResiduo': value.divisaResiduo,
        'statoCarta': value.statoCarta,
        'rrnVerifica': value.rrnVerifica,
        'idRichiesta': value.idRichiesta,
        'dataOraAutorizzazione': value.dataOraAutorizzazione === undefined ? undefined : (value.dataOraAutorizzazione.toISOString()),
        'stato': value.stato,
        'esito': value.esito,
        'tipoCarta': value.tipoCarta,
        'nomeTitolare': value.nomeTitolare,
        'bozza': _1.BozzaDtoToJSON(value.bozza),
        'inviato': _1.InviatoDtoToJSON(value.inviato),
        'idBozzaCollegata': value.idBozzaCollegata,
        'idOneClickCollegata': value.idOneClickCollegata,
        'aggiungiRubrica': value.aggiungiRubrica,
        'aggiungi1click': value.aggiungi1click,
        'aggiungiWhitelist': value.aggiungiWhitelist,
        'contoAddebitoAlias': value.contoAddebitoAlias,
        'contoAddebitoIntestazione': value.contoAddebitoIntestazione,
        'iban': value.iban,
    };
}
exports.RicaricaCarteDtoToJSON = RicaricaCarteDtoToJSON;
/**
* @export
* @enum {string}
*/
var RicaricaCarteDtoStatoDisposizioneEnum;
(function (RicaricaCarteDtoStatoDisposizioneEnum) {
    RicaricaCarteDtoStatoDisposizioneEnum["NON_AUTORIZZATO"] = "NON_AUTORIZZATO";
    RicaricaCarteDtoStatoDisposizioneEnum["IN_CORSO"] = "IN_CORSO";
    RicaricaCarteDtoStatoDisposizioneEnum["INVIATO"] = "INVIATO";
    RicaricaCarteDtoStatoDisposizioneEnum["ESITO_INCERTO"] = "ESITO_INCERTO";
    RicaricaCarteDtoStatoDisposizioneEnum["REVOCATO"] = "REVOCATO";
})(RicaricaCarteDtoStatoDisposizioneEnum = exports.RicaricaCarteDtoStatoDisposizioneEnum || (exports.RicaricaCarteDtoStatoDisposizioneEnum = {}));
