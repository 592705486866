"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setBannerSelezionatoFunzionalitaSenzaImmagine = exports.setBannerSelezionatoFunzionalitaConImmagine = exports.setBannerSenzaImmagine = exports.setBannerConImmagine = exports.suddivisioneBanner = exports.setVisibilitaBanner = exports.controlloBannerDaNascondere = exports.aggiungiBannerDaNascondere = exports.setBannerSelezionato = exports.getBannerPerFunzionalita = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
function getBannerStart() {
    return {
        type: __1.EReduxActionTypes.BANNER_GET_BANNER_START
    };
}
function getBannerSuccess(payload, funzionalita) {
    return {
        type: __1.EReduxActionTypes.BANNER_GET_BANNER_SUCCESS,
        payload,
        funzionalita
    };
}
function getBannerError(value) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value));
    };
}
const getBannerPerFunzionalita = (funzionalita, loaderEnabled = true) => async (dispatch, getState, extraArguments) => {
    const _banners = (0, __1.bannerProfiloParametri)(getState().profilo);
    const _idList = _banners.filter(b => b.type === 'BANNER' && b.vincoloFunzione === funzionalita).map(b => b.id);
    if (_idList === null || _idList === void 0 ? void 0 : _idList.length) {
        loaderEnabled && dispatch((0, general_actions_1.setLoader)(true));
        dispatch(getBannerStart());
        if (extraArguments.mock) {
            loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
        }
        else {
            const request = {
                bannerIdsDto: {
                    idList: _idList
                }
            };
            extraArguments.getItem('jwt').then((jwt) => {
                new api_rest_1.BannerControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).bannerByIds(request).then((response) => {
                    dispatch(getBannerSuccess(response, funzionalita));
                }).catch(async (error) => {
                    dispatch(getBannerError(await (0, helpers_1.formatError)(error)));
                }).finally(() => {
                    loaderEnabled && dispatch((0, general_actions_1.setLoader)(false));
                });
            });
        }
    }
};
exports.getBannerPerFunzionalita = getBannerPerFunzionalita;
function setBannerSelezionato(bannerSelezionato) {
    return {
        type: __1.EReduxActionTypes.BANNER_SET_BANNER_SELEZIONATO,
        payload: bannerSelezionato
    };
}
exports.setBannerSelezionato = setBannerSelezionato;
function aggiungiBannerDaNascondere(pagina) {
    return {
        type: __1.EReduxActionTypes.BANNER_SET_BANNER_NASCOSTO,
        payload: pagina
    };
}
exports.aggiungiBannerDaNascondere = aggiungiBannerDaNascondere;
const controlloBannerDaNascondere = (pagina) => async (dispatch, getState, _) => {
    var _a;
    const bannerDaNascondere = (_a = getState().banner) === null || _a === void 0 ? void 0 : _a.bannerDaNascondere;
    if (bannerDaNascondere.length > 0) {
        bannerDaNascondere.forEach(item => {
            if (item == pagina) {
                dispatch(setVisibilitaBanner(false));
            }
            else {
                dispatch(setVisibilitaBanner(true));
            }
        });
    }
    else {
        dispatch(setVisibilitaBanner(true));
    }
};
exports.controlloBannerDaNascondere = controlloBannerDaNascondere;
function setVisibilitaBanner(visibilita) {
    return {
        type: __1.EReduxActionTypes.BANNER_SET_VISIBILITA,
        payload: visibilita
    };
}
exports.setVisibilitaBanner = setVisibilitaBanner;
const suddivisioneBanner = (pagina) => async (dispatch, getState, _) => {
    var _a;
    const bannerPerFunzionalita = (_a = getState().banner) === null || _a === void 0 ? void 0 : _a.bannerPerFunzionalita;
    const bannerConImmagine = bannerPerFunzionalita[pagina].filter(banner => banner.immagine);
    const bannerSenzaImmagine = bannerPerFunzionalita[pagina].filter(banner => !banner.immagine);
    dispatch(setBannerConImmagine(bannerConImmagine));
    dispatch(setBannerSenzaImmagine(bannerSenzaImmagine));
};
exports.suddivisioneBanner = suddivisioneBanner;
function setBannerConImmagine(banners) {
    return {
        type: __1.EReduxActionTypes.BANNER_SET_BANNER_CON_IMMAGINE,
        payload: banners
    };
}
exports.setBannerConImmagine = setBannerConImmagine;
function setBannerSenzaImmagine(banners) {
    return {
        type: __1.EReduxActionTypes.BANNER_SET_BANNER_SENZA_IMMAGINE,
        payload: banners
    };
}
exports.setBannerSenzaImmagine = setBannerSenzaImmagine;
function setBannerSelezionatoFunzionalitaConImmagine(banner) {
    return {
        type: __1.EReduxActionTypes.BANNER_SET_BANNER_CON_IMMAGINE_SELEZIONATO,
        payload: banner
    };
}
exports.setBannerSelezionatoFunzionalitaConImmagine = setBannerSelezionatoFunzionalitaConImmagine;
function setBannerSelezionatoFunzionalitaSenzaImmagine(banner) {
    return {
        type: __1.EReduxActionTypes.BANNER_SET_BANNER_SENZA_IMMAGINE_SELEZIONATO,
        payload: banner
    };
}
exports.setBannerSelezionatoFunzionalitaSenzaImmagine = setBannerSelezionatoFunzionalitaSenzaImmagine;
