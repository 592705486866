import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import img1Click from "../../assets/icons/chiudi.svg";

interface Props {
  id: number | string;
  descrizione: string;
  onCloseSnackbar: Function;
}

let timeout: NodeJS.Timeout;
const tempoTotale: number = 3000;
const intervallo: number = 500;

const ItemSnackbar: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();

  const [width, setWidth] = useState(100);
  const [counter, setCounter] = useState(0);
  const { onCloseSnackbar, descrizione, id } = props;

  const increment = () => {
    setCounter((counter) => counter + intervallo);
  };

  useEffect(() => {
    setWidth(100 - (counter * 100 / tempoTotale));
    if (width <= 0) {
      onActionClose();
    }
  }, [counter]);

  timeout && clearInterval(timeout);
  timeout = setInterval(increment, intervallo);

  const onActionClose = () => {
    clearInterval(timeout);
    onCloseSnackbar(id);
  }

  return (
    <div className="view-item-snackbar">
      <div className="viewBody-item-snackbar">
        <p className="txt-item-snackbar">{t(`${descrizione}`)}</p>
        <div className="iconView-item-snackbar general-button " onClick={onActionClose}>
          <img src={img1Click} alt="logo" />
        </div>
      </div>
      <div className="view-counter" style={{
        width: `${width}%`,
        transition: width >= 0 && width < 100 && tempoTotale ? `width ${intervallo / 1000}s linear` : "none"
      }} />
    </div>
  );
};

export default ItemSnackbar;
