"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormEstintiDtoFromJSON = exports.FormPagamentiDtoFromJSON = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const helpers_1 = require("../helpers");
function FormPagamentiDtoFromJSON(json) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'importo': !(0, helpers_1.exists)(json, 'importo') ? undefined : json.importo,
        'importoError': !(0, helpers_1.exists)(json, 'importoError') ? 'invalid.required' : json.importoError,
        'descrizione': !(0, helpers_1.exists)(json, 'descrizione') ? undefined : json.descrizione,
        'descrizioneError': !(0, helpers_1.exists)(json, 'descrizioneError') ? 'invalid.required' : json.descrizioneError,
        'dataDa': !(0, helpers_1.exists)(json, 'dataDa') ? new Date() : json.dataDa,
        'dataDaError': !(0, helpers_1.exists)(json, 'dataDaError') ? '' : json.dataDaError,
        'dataA': !(0, helpers_1.exists)(json, 'dataA') ? new Date() : json.dataA,
        'dataAError': !(0, helpers_1.exists)(json, 'dataAError') ? '' : json.dataAError,
        'periodo': !(0, helpers_1.exists)(json, 'periodo') ? undefined : json.periodo,
        'tipologie': !(0, helpers_1.exists)(json, 'tipologie') ? [] : json.tipologie,
        'tipologieError': !(0, helpers_1.exists)(json, 'tipologieError') ? '' : json.tipologieError,
        'stati': !(0, helpers_1.exists)(json, 'stati') ? [] : json.stati,
        'rapportoSelezionato': !(0, helpers_1.exists)(json, 'rapportoSelezionato') ? (0, api_rest_1.RapportoCompletoDtoFromJSON)({}) : json.rapportoSelezionato,
        'formSubmitted': !(0, helpers_1.exists)(json, 'formSubmitted') ? false : json.formSubmitted,
        'codiceFiscale': !(0, helpers_1.exists)(json, 'codiceFiscale') ? undefined : json.codiceFiscale,
        'codiceFiscaleError': !(0, helpers_1.exists)(json, 'codiceFiscaleError') ? 'invalid.required' : json.codiceFiscaleError,
        'codiceFiscaleDirty': !(0, helpers_1.exists)(json, 'codiceFiscaleDirty') ? false : json.codiceFiscaleDirty,
        'formDirty': !(0, helpers_1.exists)(json, 'formDirty') ? false : json.formDirty,
        'accordionFiltriOpened': !(0, helpers_1.exists)(json, 'accordionFiltriOpened') ? false : json.accordionFiltriOpened,
        'conto': !(0, helpers_1.exists)(json, 'conto') ? undefined : json.conto,
        'offset': !(0, helpers_1.exists)(json, 'offset') ? undefined : json.offset,
        'listLoader': !(0, helpers_1.exists)(json, 'listLoader') ? false : json.listLoader
    };
}
exports.FormPagamentiDtoFromJSON = FormPagamentiDtoFromJSON;
function FormEstintiDtoFromJSON(json) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'dataDa': !(0, helpers_1.exists)(json, 'dataDa') ? new Date() : json.dataDa,
        'dataDaError': !(0, helpers_1.exists)(json, 'dataDaError') ? '' : json.dataDaError,
        'dataA': !(0, helpers_1.exists)(json, 'dataA') ? new Date() : json.dataA,
        'dataAError': !(0, helpers_1.exists)(json, 'dataAError') ? '' : json.dataAError,
        'tipologie': !(0, helpers_1.exists)(json, 'tipologie') ? [] : json.tipologie,
        'tipologieError': !(0, helpers_1.exists)(json, 'tipologieError') ? '' : json.tipologieError,
        'rapportoSelezionato': !(0, helpers_1.exists)(json, 'rapportoSelezionato') ? (0, api_rest_1.RapportoDtoFromJSON)({}) : json.rapportoSelezionato,
        'formSubmitted': !(0, helpers_1.exists)(json, 'formSubmitted') ? false : json.formSubmitted,
        'formDirty': !(0, helpers_1.exists)(json, 'formDirty') ? false : json.formDirty,
        'accordionFiltriOpened': !(0, helpers_1.exists)(json, 'accordionFiltriOpened') ? true : json.accordionFiltriOpened,
        'offset': !(0, helpers_1.exists)(json, 'offset') ? undefined : json.offset,
        'listLoader': !(0, helpers_1.exists)(json, 'listLoader') ? false : json.listLoader
    };
}
exports.FormEstintiDtoFromJSON = FormEstintiDtoFromJSON;
