"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GestioneNotificheControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class GestioneNotificheControllerApi extends runtime.BaseAPI {
    /**
     * Restituisce i dati delle configurazioni notifiche sms alert dell\'utente. Se l\'oggetto e\' null il tab Notifiche non viene visualizzato, perche\' l\'utente risulta non abilitato ad SMSACQUISTO.
     */
    gestioneNotificheRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/gestionenotifiche`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.SmsAlertDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce i dati delle configurazioni notifiche sms alert dell\'utente. Se l\'oggetto e\' null il tab Notifiche non viene visualizzato, perche\' l\'utente risulta non abilitato ad SMSACQUISTO.
     */
    gestioneNotifiche() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.gestioneNotificheRaw();
            return yield response.value();
        });
    }
    /**
     * Informazioni sull\'autenticazione modifiche delle notifiche
     */
    informazioniModificaNotificheRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.chiamataUscente !== undefined) {
                queryParameters['chiamataUscente'] = requestParameters.chiamataUscente;
            }
            if (requestParameters.eseguiInit !== undefined) {
                queryParameters['eseguiInit'] = requestParameters.eseguiInit;
            }
            if (requestParameters.offline !== undefined) {
                queryParameters['offline'] = requestParameters.offline;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/gestionenotifiche/infoautenticazione`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.InformazioniAutenticazioneDispositivaDtoFromJSON(jsonValue));
        });
    }
    /**
     * Informazioni sull\'autenticazione modifiche delle notifiche
     */
    informazioniModificaNotifiche(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.informazioniModificaNotificheRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Autorizza modifiche delle notifiche
     */
    modificaNotificheRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.gestioneNotificheRequestIn === null || requestParameters.gestioneNotificheRequestIn === undefined) {
                throw new runtime.RequiredError('gestioneNotificheRequestIn', 'Required parameter requestParameters.gestioneNotificheRequestIn was null or undefined when calling modificaNotifiche.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/gestionenotifiche/autorizza`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.GestioneNotificheRequestInToJSON(requestParameters.gestioneNotificheRequestIn),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.EsitoAutorizzazioneDisposizioneSmsAlertDtoFromJSON(jsonValue));
        });
    }
    /**
     * Autorizza modifiche delle notifiche
     */
    modificaNotifiche(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.modificaNotificheRaw(requestParameters);
            return yield response.value();
        });
    }
}
exports.GestioneNotificheControllerApi = GestioneNotificheControllerApi;
