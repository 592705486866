"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetUpdateOnboardingNecessario = exports.setUpdateOnboardingNecessario = exports.updateOnboarding = exports.checkOnboarding = exports.inviaNotificaPushOnboarding = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
const onboarding_types_1 = require("./onboarding.types");
function generalError(value) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value, false));
    };
}
function inviaNotificaPushOnboardingStart() {
    return {
        type: onboarding_types_1.EReduxOnboardingActionTypes.ONBOARDING_INVIA_NOTIFICA_PUSH_START
    };
}
function inviaNotificaPushOnboardingSuccess(payload) {
    return {
        type: onboarding_types_1.EReduxOnboardingActionTypes.ONBOARDING_INVIA_NOTIFICA_PUSH_SUCCESS,
        payload
    };
}
const inviaNotificaPushOnboarding = (ndg) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(inviaNotificaPushOnboardingStart());
    if (extraArguments.mock) {
    }
    else {
        const request = {
            ndg
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.NotificationControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).sendNotifichePOCOnBoard(request).then((response) => {
                dispatch(inviaNotificaPushOnboardingSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.inviaNotificaPushOnboarding = inviaNotificaPushOnboarding;
function checkOnboardingStart() {
    return {
        type: onboarding_types_1.EReduxOnboardingActionTypes.ONBOARDING_CHECK_START
    };
}
function checkOnboardingSuccess(payload) {
    return {
        type: onboarding_types_1.EReduxOnboardingActionTypes.ONBOARDING_CHECK_SUCCESS,
        payload
    };
}
const checkOnboarding = (ndgtoken) => async (dispatch, _, extraArguments) => {
    // dispatch(setLoader(true))
    dispatch(checkOnboardingStart());
    if (extraArguments.mock) {
    }
    else {
        const request = {
            ndgtoken
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.NotificationControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).checkNotifichePOCOnBoard(request).then((response) => {
                dispatch(checkOnboardingSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                // dispatch(setLoader(false));
            });
        });
    }
};
exports.checkOnboarding = checkOnboarding;
function updateOnboardingStart() {
    return {
        type: onboarding_types_1.EReduxOnboardingActionTypes.ONBOARDING_UPDATE_START
    };
}
function updateOnboardingSuccess(payload) {
    return {
        type: onboarding_types_1.EReduxOnboardingActionTypes.ONBOARDING_UPDATE_SUCCESS,
        payload
    };
}
const updateOnboarding = (ndgtoken) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(updateOnboardingStart());
    if (extraArguments.mock) {
    }
    else {
        const request = {
            ndgtoken
        };
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.NotificationControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).updatePOCOnBoard(request).then((response) => {
                dispatch(updateOnboardingSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.updateOnboarding = updateOnboarding;
function setUpdateOnboardingNecessario(payload) {
    return {
        type: onboarding_types_1.EReduxOnboardingActionTypes.ONBOARDING_SET_UPDATE_NECESSARIO,
        payload
    };
}
exports.setUpdateOnboardingNecessario = setUpdateOnboardingNecessario;
function resetUpdateOnboardingNecessario() {
    return {
        type: onboarding_types_1.EReduxOnboardingActionTypes.ONBOARDING_RESET_UPDATE_NECESSARIO
    };
}
exports.resetUpdateOnboardingNecessario = resetUpdateOnboardingNecessario;
