"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiltroListaBonificiPeriodiciDtoToJSON = exports.FiltroListaBonificiPeriodiciDtoFromJSONTyped = exports.FiltroListaBonificiPeriodiciDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function FiltroListaBonificiPeriodiciDtoFromJSON(json) {
    return FiltroListaBonificiPeriodiciDtoFromJSONTyped(json, false);
}
exports.FiltroListaBonificiPeriodiciDtoFromJSON = FiltroListaBonificiPeriodiciDtoFromJSON;
function FiltroListaBonificiPeriodiciDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'dataDa': (new Date(json['dataDa'])),
        'dataA': (new Date(json['dataA'])),
        'importoDa': !runtime_1.exists(json, 'importoDa') ? undefined : json['importoDa'],
        'importoA': !runtime_1.exists(json, 'importoA') ? undefined : json['importoA'],
        'stato': !runtime_1.exists(json, 'stato') ? undefined : json['stato'],
        'numeroRisultati': !runtime_1.exists(json, 'numeroRisultati') ? undefined : json['numeroRisultati'],
        'ordinamento': !runtime_1.exists(json, 'ordinamento') ? undefined : json['ordinamento'],
        'beneficiario': !runtime_1.exists(json, 'beneficiario') ? undefined : json['beneficiario'],
        'causale': !runtime_1.exists(json, 'causale') ? undefined : json['causale'],
        'offset': !runtime_1.exists(json, 'offset') ? undefined : json['offset'],
        'elencoStato': !runtime_1.exists(json, 'elencoStato') ? undefined : json['elencoStato'],
    };
}
exports.FiltroListaBonificiPeriodiciDtoFromJSONTyped = FiltroListaBonificiPeriodiciDtoFromJSONTyped;
function FiltroListaBonificiPeriodiciDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'dataDa': (value.dataDa.toISOString()),
        'dataA': (value.dataA.toISOString()),
        'importoDa': value.importoDa,
        'importoA': value.importoA,
        'stato': value.stato,
        'numeroRisultati': value.numeroRisultati,
        'ordinamento': value.ordinamento,
        'beneficiario': value.beneficiario,
        'causale': value.causale,
        'offset': value.offset,
        'elencoStato': value.elencoStato,
    };
}
exports.FiltroListaBonificiPeriodiciDtoToJSON = FiltroListaBonificiPeriodiciDtoToJSON;
