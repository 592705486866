"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViaDatiAnagraficiDtoToJSON = exports.ViaDatiAnagraficiDtoFromJSONTyped = exports.ViaDatiAnagraficiDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function ViaDatiAnagraficiDtoFromJSON(json) {
    return ViaDatiAnagraficiDtoFromJSONTyped(json, false);
}
exports.ViaDatiAnagraficiDtoFromJSON = ViaDatiAnagraficiDtoFromJSON;
function ViaDatiAnagraficiDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'tipo': !runtime_1.exists(json, 'tipo') ? undefined : json['tipo'],
        'nome': !runtime_1.exists(json, 'nome') ? undefined : json['nome'],
        'numeroDomicilio': !runtime_1.exists(json, 'numeroDomicilio') ? undefined : json['numeroDomicilio'],
    };
}
exports.ViaDatiAnagraficiDtoFromJSONTyped = ViaDatiAnagraficiDtoFromJSONTyped;
function ViaDatiAnagraficiDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'tipo': value.tipo,
        'nome': value.nome,
        'numeroDomicilio': value.numeroDomicilio,
    };
}
exports.ViaDatiAnagraficiDtoToJSON = ViaDatiAnagraficiDtoToJSON;
