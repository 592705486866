"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimeDepositFromRevocaFromJSONTyped = exports.TimeDepositFormTypeFromJSONTyped = exports.FilterTimeDepositFromJSONTyped = void 0;
const helpers_1 = require("../helpers");
function FilterTimeDepositFromJSONTyped(json) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'stato': !(0, helpers_1.exists)(json, 'stato') ? 'A' : json.stato,
        'rapportoSelezionato': !(0, helpers_1.exists)(json, 'rapportoSelezionato') ? undefined : json.rapportoSelezionato,
        'dataDecorrenzaDa': !(0, helpers_1.exists)(json, 'dataDecorrenzaDa') ? undefined : json.dataDecorrenzaDa,
        'dataDecorrenzaA': !(0, helpers_1.exists)(json, 'dataDecorrenzaA') ? undefined : json.dataDecorrenzaA,
        'dataScadenzaDa': !(0, helpers_1.exists)(json, 'dataScadenzaDa') ? undefined : json.dataScadenzaDa,
        'dataScadenzaA': !(0, helpers_1.exists)(json, 'dataScadenzaA') ? undefined : json.dataScadenzaA,
        'formDirty': !(0, helpers_1.exists)(json, 'formDirty') ? false : json.formDirty,
        'periodo': !(0, helpers_1.exists)(json, 'periodo') ? undefined : json.periodo
    };
}
exports.FilterTimeDepositFromJSONTyped = FilterTimeDepositFromJSONTyped;
function TimeDepositFormTypeFromJSONTyped(json) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        rapportoSelezionato: !(0, helpers_1.exists)(json, 'rapportoSelezionato') ? undefined : json.rapportoSelezionato,
        listinoSelezionato: !(0, helpers_1.exists)(json, 'listinoSelezionato') ? undefined : json.listinoSelezionato,
        importoDeposito: !(0, helpers_1.exists)(json, 'importoDeposito') ? undefined : json.importoDeposito,
        importoDepositoDirty: !(0, helpers_1.exists)(json, 'importoDepositoDirty') ? false : json.importoDepositoDirty,
        importoDepositoError: !(0, helpers_1.exists)(json, 'importoDepositoError') ? '' : json.importoDepositoError,
        step: !(0, helpers_1.exists)(json, 'step') ? 0 : json.step
    };
}
exports.TimeDepositFormTypeFromJSONTyped = TimeDepositFormTypeFromJSONTyped;
function TimeDepositFromRevocaFromJSONTyped(json) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        step: !(0, helpers_1.exists)(json, 'step') ? 0 : json.step
    };
}
exports.TimeDepositFromRevocaFromJSONTyped = TimeDepositFromRevocaFromJSONTyped;
