"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.JobDocumentDtoToJSON = exports.JobDocumentDtoFromJSONTyped = exports.JobDocumentDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function JobDocumentDtoFromJSON(json) {
    return JobDocumentDtoFromJSONTyped(json, false);
}
exports.JobDocumentDtoFromJSON = JobDocumentDtoFromJSON;
function JobDocumentDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
        'hash256': !runtime_1.exists(json, 'hash256') ? undefined : json['hash256'],
        'documentGroup': !runtime_1.exists(json, 'documentGroup') ? undefined : json['documentGroup'],
        'type': !runtime_1.exists(json, 'type') ? undefined : json['type'],
        'linkedPages': !runtime_1.exists(json, 'linkedPages') ? undefined : json['linkedPages'],
        'occField': !runtime_1.exists(json, 'occField') ? undefined : json['occField'],
        'indexOrder': !runtime_1.exists(json, 'indexOrder') ? undefined : json['indexOrder'],
        'metadataValue': !runtime_1.exists(json, 'metadataValue') ? undefined : _1.JobDocumentMetadataDtoFromJSON(json['metadataValue']),
        'attributesValue': !runtime_1.exists(json, 'attributesValue') ? undefined : _1.JobDocumentAttributesValueDtoFromJSON(json['attributesValue']),
        'tipologia': !runtime_1.exists(json, 'tipologia') ? undefined : json['tipologia'],
        'label': !runtime_1.exists(json, 'label') ? undefined : json['label'],
    };
}
exports.JobDocumentDtoFromJSONTyped = JobDocumentDtoFromJSONTyped;
function JobDocumentDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'description': value.description,
        'hash256': value.hash256,
        'documentGroup': value.documentGroup,
        'type': value.type,
        'linkedPages': value.linkedPages,
        'occField': value.occField,
        'indexOrder': value.indexOrder,
        'metadataValue': _1.JobDocumentMetadataDtoToJSON(value.metadataValue),
        'attributesValue': _1.JobDocumentAttributesValueDtoToJSON(value.attributesValue),
        'tipologia': value.tipologia,
        'label': value.label,
    };
}
exports.JobDocumentDtoToJSON = JobDocumentDtoToJSON;
