"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.notificePushReducer = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const initialState = {
    netInsurance: (0, api_rest_1.SubscriptionOkFromJSON)({}),
    showModal: false
};
const notificePushReducer = (state = initialState, action) => {
    switch (action.type) {
        case __1.EReduxActionTypes.NOTIFICE_PUSH_SEND_NET_INSURANCE_SUCCESS:
            return {
                ...state,
                netInsurance: action.payload
            };
        case __1.EReduxActionTypes.NOTIFICHE_PUSH_SET_SHOW_MODAL:
            return {
                ...state,
                showModal: action.payload
            };
        case __1.EReduxActionTypes.RESET_ALL: // action invocata dalla logout
            return {
                ...initialState
            };
        default:
            return state;
    }
};
exports.notificePushReducer = notificePushReducer;
