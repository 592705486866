"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.IndirizzoSportelloDtoToJSON = exports.IndirizzoSportelloDtoFromJSONTyped = exports.IndirizzoSportelloDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function IndirizzoSportelloDtoFromJSON(json) {
    return IndirizzoSportelloDtoFromJSONTyped(json, false);
}
exports.IndirizzoSportelloDtoFromJSON = IndirizzoSportelloDtoFromJSON;
function IndirizzoSportelloDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'via': !runtime_1.exists(json, 'via') ? undefined : json['via'],
        'localita': !runtime_1.exists(json, 'localita') ? undefined : json['localita'],
        'zip': !runtime_1.exists(json, 'zip') ? undefined : json['zip'],
        'comune': !runtime_1.exists(json, 'comune') ? undefined : json['comune'],
        'provincia': !runtime_1.exists(json, 'provincia') ? undefined : json['provincia'],
    };
}
exports.IndirizzoSportelloDtoFromJSONTyped = IndirizzoSportelloDtoFromJSONTyped;
function IndirizzoSportelloDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'via': value.via,
        'localita': value.localita,
        'zip': value.zip,
        'comune': value.comune,
        'provincia': value.provincia,
    };
}
exports.IndirizzoSportelloDtoToJSON = IndirizzoSportelloDtoToJSON;
